<template>
    <div
        id="mobile-bottom-pane"
        :class="componentName === '' ? 'hide-pane' : 'show-pane'"
    >
        <div id="pane-header">
            <button @click="$emit('close')" class="close-button">
                <img src="../../../assets/arrow_left.svg" class="" />
                <span id="back-label">Back</span>
            </button>
        </div>
        <component
            id="pane-content"
            v-bind:is="componentName"
            v-bind="dynamicProperties"
            @close="$emit('close')"
            @clearResults="
                () => {
                    $emit('clearResults');
                }
            "
            @clearHighlight="
                () => {
                    $emit('clearHighlight');
                }
            "
            @zoomTo="
                (feature) => {
                    $emit('zoomTo', feature);
                }
            "
            @highlight="
                (feature) => {
                    $emit('highlight', feature);
                }
            "
            @reverseLookup="
                (layerID, featureID) => {
                    $emit('reverseLookup', layerID, featureID);
                }
            "
            @updateClickFunction="updateClickFunction"
        />
    </div>
</template>

<script>
export default {
    name: 'MobileBottomPane',
    components: {
        ContentTable: () =>
            import('../../components/EmergencyManagement/ContentTable.vue'),
        MapIdentifyResults: () =>
            import('../../components/CommonComponents/MapIdentifyResults.vue'),
        CommonOperatingPicture: () =>
            import(
                '../../components/EmergencyManagement/CommonOperatingPicture.vue'
            ),
        // using custom mobile components since this is a short-term patch while we move to vue3
        DrawPane: () => import('../../components/Mobile/MobileDraw.vue'),
        MeasurePane: () => import('../../components/Mobile/MobileMeasure.vue'),
        PrintPane: () =>
            import('../../components/EmergencyManagement/PrintPane.vue'),
        AttributePane: () =>
            import('../../components/EmergencyManagement/AttributePane.vue'),
    },
    props: {
        componentName: { type: String, required: false, default: '' },
        identifyResults: { type: Array, required: false, default: () => [] },
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {
        updateClickFunction(clickFunction) {
            this.$emit('updateClickFunction', clickFunction);
        },
    },
    computed: {
        dynamicProperties() {
            if (this.componentName === 'MapIdentifyResults') {
                return { identifiedDataJson: this.identifyResults };
            } else if (
                ['PrintPane', 'DrawPane', 'MeasurePane'].includes(
                    this.componentName
                )
            ) {
                return { showPane: true };
            } else {
                return {};
            }
        },
    },
};
</script>

<style scoped>
#mobile-bottom-pane {
    background-color: white;
    transition: 300ms;
    height: 80vh;
    bottom: 0px;
    position: absolute;
    z-index: 10;
    width: 100%;
    pointer-events: all;
}

#pane-header {
    padding: 16px;
    background-color: rgb(222, 222, 222);
    color: black;
}

#back-label {
    position: relative;
    top: 1px;
    padding-left: 4px;
}

#pane-content {
    justify-content: start;
    align-content: baseline;
    display: grid;
    padding: 0px 0px 16px 16px;
    overflow: auto;
    width: 100%;
}
.show-pane {
    transform: translateY(0);
}
.hide-pane {
    transform: translateY(100%);
}

.close-button {
    border: 0;
    background-color: inherit;
}
</style>
