<template>
    <l-control :position="position">
        <button
            type="button"
            class="btn btn-white"
            @click="goToUserLocation"
            ref="getMyLocation"
        >
            <slot name="myLocationIcon">Get my location</slot>
        </button>
    </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet';

export default {
    name: 'LControlMyLocation',
    components: {
        LControl,
    },
    props: {
        position: {
            type: String,
            default: 'topright',
        },
        mapRef: {
            required: true,
            type: String,
        },
    },
    methods: {
        goToUserLocation() {
            this.$parent.$parent.$refs[this.mapRef].mapObject.locate({
                setView: true,
                maxZoom: 15,
            });
        },
    },
};
</script>

<style></style>
