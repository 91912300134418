<template>
    <l-control
        class="zoomControlWrapper"
        :position="position"
        @ready="zoomReady"
    >
        <button
            :disabled="disabled"
            class="plus btn Icons"
            @click="increaseZoom"
        >
            <slot name="plusIcon">+</slot></button
        ><br />
        <button
            :disabled="disabled"
            class="minus btn Icons"
            @click="decreaseZoom"
        >
            <slot name="minusIcon">-</slot></button
        ><br />
    </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet';

export default {
    name: 'LControlZoom',
    components: {
        LControl,
    },
    props: {
        position: {
            type: String,
            default: 'topright',
        },
        disabled: { type: Boolean, required: false, default: false },
    },
    mounted() {
        this.controlOptions = {
            position: this.position,
        };
        this.$emit('ready', this.mapObject);
    },
    methods: {
        increaseZoom() {
            if (this.$parent.$parent.zoom < this.$parent.$parent.maxZoom) {
                this.$parent.$parent.zoom++;
            }
        },
        // removes empty geometries and removes previous measure results
        decreaseZoom() {
            if (this.$parent.$parent.zoom > this.$parent.$parent.minZoom) {
                this.$parent.$parent.zoom--;
            }
        },
        zoomReady() {},
    },
};
</script>

<style scoped>
.zoomControlWrapper {
    background-color: '#00000000';
}
.Icons {
    width: 45px;
    height: 45px;
    background: white !important;
    border-bottom: 1px black !important;
    padding: 0 !important;
}
.plus {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.minus {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}
</style>
