<template lang="html">
    <b-row class="my-3 col portalbarMain">
        <div
            id="outersearchinput"
            :class="disableSearchIcon ? 'clickNotAllowed' : ''"
        >
            <img
                class="pointer ml-2 mt-3"
                src="../assets/search-outline.svg"
                v-if="
                    !search && !showSearchIcon && !hidetableSelect
                        ? true
                        : false
                "
                @click="searchOpen"
            />
            <input
                ref="dataset"
                spellcheck="false"
                id="searchFilter"
                class="test"
                v-if="search || showSearchIcon ? true : false"
                v-model="search"
                @click="searchinputenter()"
                @input="searchinputenter"
                :class="search || showSearchIcon ? 'test' : 'searchinputList'"
                type="search"
                placeholder="Search for datasets"
            />
            <span class="grey-bg pointer" v-if="searchClose" @click="closeBar"
                ><img src="../assets/close.svg"
            /></span>
            <span class=" mx-2 m-none">&nbsp;</span>
        </div>
        <div
            id="portalbar"
            class="blue-color mb-2 pl-0 pr-2 pb-2 border mr-6 m-shadow  map"
            :class="hidetableSelect ? 'w-100' : ''"
        >
            <a class="pr-0">
                <img
                    id="map-view-icon"
                    :src="mapViewHover"
                    @mouseover="mapHover = true"
                    @mouseleave="mapHover = false"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    title="Map view"
                    class="border-right px-3 py-2 mapIcon"
                    @click="toggleMap"
                />
            </a>
            <router-link to="/tableselectiontool" class="pr-0">
                <img
                    src="../assets/ghd_list_blue.svg"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    title="Table select tool"
                    class="border-right bg-grey px-3 py-2 tableIcon"
                    @click="navigatetoDataset"
                />
            </router-link>
            <!-- freeze tool -->
            <div class="d-none">
                <b-button
                    rounded
                    id="forceReset"
                    @click="handleReset()"
                    variant="primary"
                >
                    Force Reset
                </b-button>
            </div>
            <a
                v-if="hidetableSelect && toolsData"
                :class="disableDocIcon ? 'clickNotAllowed' : ''"
                @click="modalShow = !modalShow"
            >
                <img
                    id="freeze-tool-icon"
                    v-b-tooltip.hover
                    title="Freeze columns and rows"
                    class="border-right"
                    v-b-modal.modal-2
                    :src="pictureHover"
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                />
                <b-modal
                    size="lg"
                    title="Freeze Tool"
                    ref="modal-2"
                    id="modal-2"
                    ok-title="Freeze"
                    cancel-title="Reset "
                    hide-header
                    hide-footer
                    @ok="freeze"
                    @cancel="handleReset"
                >
                    <div class="text-right cursor-pointer">
                        <img
                            class="pointer"
                            src="../assets/close_icon.svg"
                            @click="closeModal"
                        />
                    </div>
                    <h5 class="my-2 title text-center">Freeze grid</h5>
                    <div class="manage-form">
                        <div class="content">
                            <CustomFloatingLabel
                                :config="{
                                    label: 'Number of columns',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                                v-bind:label="floatLabelAdd"
                            >
                                <input
                                    autocomplete="off"
                                    ref="col"
                                    type="number"
                                    id="col"
                                    v-model="col"
                                    class="normal_input"
                                    min="0"
                                />
                            </CustomFloatingLabel>

                            <CustomFloatingLabel
                                :config="{
                                    label: 'Number of rows',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                                v-bind:label="floatLabelAdd"
                            >
                                <input
                                    id="row"
                                    ref="row"
                                    autocomplete="off"
                                    type="number"
                                    v-model="row"
                                    class="normal_input"
                                    min="0"
                                />
                            </CustomFloatingLabel>
                            <b-button
                                id="freeze-btn"
                                :disabled="freezeBtnState"
                                class="applyButton assignedApply"
                                @click="freeze()"
                            >
                                Freeze
                            </b-button>
                            <b-button
                                id="reset-btn"
                                :disabled="freezeBtnState"
                                class="cancelButton"
                                @click="forceReset"
                            >
                                Reset
                            </b-button>
                        </div>
                    </div>
                </b-modal>
            </a>
            <!-- <a @click="showReportingTool" v-if="showReportingMenu" > -->
            <a
                v-if="hidetableSelect && reportingToolDisplay"
                :class="disableDocIcon ? 'clickNotAllowed' : ''"
                @click="showReportingTool"
            >
                <img
                    src="../assets/ghd_word.svg"
                    class="border-right px-3 py-2 docIcon"
                    v-b-tooltip.hover
                    title="Reporting"
                />
            </a>
            <a
                @click="dataToolTab('data-post')"
                v-if="hidetableSelect && dataSetTools.includes(1)"
                :class="disablePostingIcon ? 'clickNotAllowed' : ''"
            >
                <img
                    src="../assets/dpt_bw_icon.svg"
                    class="border-right px-3 py-2 dataPostingIcon"
                    v-b-tooltip.hover
                    title="Data post"
                />
            </a>
            <div
                id="outersearchinput"
                :class="disableSearchIcon ? 'clickNotAllowed' : ''"
            >
                <input
                    ref="dataset"
                    spellcheck="false"
                    id="searchFilter"
                    class="test"
                    v-if="search || showSearchIcon ? true : false"
                    v-model="search"
                    @click="searchinputenter()"
                    @input="searchinputenter"
                    :class="
                        search || showSearchIcon ? 'test' : 'searchinputList'
                    "
                    type="search"
                    placeholder="Search for datasets"
                />
                <span
                    class="grey-bg pointer"
                    v-if="searchClose"
                    @click="closeBar"
                    ><img src="../assets/close.svg"
                /></span>
                <span class=" mx-2 m-none">&nbsp;</span>
            </div>
            <!-- freeze tool -->

            <div v-if="reportingTool" class="reporting-block">
                <div class="reportingTool pb-1">
                    <div class="pl-3 pt-3">
                        <p class="d-inline report-heading">Reporting</p>
                        <span
                            class="float-right mr-3"
                            @click="showReportingTool"
                        >
                            <a class="p-0"><img src="../assets/close.svg"/></a>
                        </span>
                    </div>
                    <!-- where to handle which icons/text appear in the reporting pane -->
                    <div v-for="(item, index) in removeTools" :key="index">
                        <div
                            v-if="
                                item.ToolName != 'Data Posting Tool' &&
                                    item.ToolName != 'Table select tool' &&
                                    item.ToolName != '3D' &&
                                    item.ToolName != 'PowerBI'
                            "
                            v-bind:class="{
                                titleBlock: item.ToolName === 'Title Block',
                                graphing: item.ToolName === 'X/Y Scatter Plot',
                                graphingBorder:
                                    item.ToolName === 'X/Y Scatter Plot',
                                dataset: item.ToolName === 'Dataset Export',
                                contouringIcon: item.ToolName === 'Contour',
                            }"
                            class="row align-items-cente my-3 mx-3 mb-2"
                        >
                            <!-- Contouring -->
                            <span v-if="item.ToolName === 'Contour'">
                                <img
                                    src="../assets/icon_contouring.svg"
                                    class="w-75 postingIcon"
                                />
                            </span>
                            <!-- Dataset Export -->
                            <span
                                v-if="item.ToolName === 'Dataset Export'"
                                @click="dataExportTab"
                            >
                                <div>
                                    <img
                                        class="postingIcon icon-top"
                                        src="../assets/icon_dataset.svg"
                                    />
                                </div>
                            </span>
                            <!-- X/Y Scatter Plot -->
                            <span
                                v-b-toggle.collapse-1
                                v-if="item.ToolName === 'X/Y Scatter Plot'"
                                @click="getAttributes(item.ToolId)"
                            >
                                <img
                                    src="../assets/icon_graphing.svg"
                                    class="w-75 postingIcon"
                                />
                            </span>
                            <!-- title block -->
                            <span
                                v-if="item.ToolName === 'Title Block'"
                                @clickedTitleBlock="showTitleBlockAccess"
                            >
                                <img
                                    src="../assets/icon_title_block.svg"
                                    class="w-75 postingIcon"
                                />
                            </span>
                            <!-- small tags handle the text for each button -->
                            <small
                                class="toolReport pl-2 report-link"
                                @click="dataToolTab('contour')"
                                v-if="item.ToolName === 'Contour'"
                            >
                                {{ item.ToolName }}
                            </small>
                            <small
                                class="toolReport pl-2 report-link"
                                @click="navigateToTool(item.ToolName)"
                                v-if="
                                    item.ToolName != 'PowerBI' &&
                                        item.ToolName != '3D' &&
                                        item.ToolName != 'X/Y Scatter Plot' &&
                                        item.ToolName != 'Contour'
                                "
                            >
                                {{ item.ToolName }}
                            </small>
                            <small
                                v-b-toggle.collapse-1
                                class="toolReport pl-2 report-link"
                                v-if="item.ToolName === 'X/Y Scatter Plot'"
                                @click="getAttributes(item.ToolId)"
                            >
                                <div
                                    v-if="item.ToolName === 'X/Y Scatter Plot'"
                                >
                                    Graphing
                                </div>
                            </small>
                            <div
                                v-b-toggle.collapse-1
                                variant="primary"
                                class="icon graphingIcon"
                                v-if="item.ToolName === 'X/Y Scatter Plot'"
                            >
                                <img
                                    v-if="visible"
                                    :class="visible ? null : 'collapsed'"
                                    :aria-expanded="visible ? 'true' : 'false'"
                                    aria-controls="collapse-4"
                                    @click="minusClick"
                                    src="../assets/minus.svg"
                                />
                                <img
                                    class="plusSign"
                                    v-if="!visible"
                                    :class="visible ? null : 'collapsed'"
                                    :aria-expanded="visible ? 'true' : 'false'"
                                    aria-controls="collapse-4"
                                    @click="getAttributes(item.ToolId)"
                                    src="../assets/plus_sign.svg"
                                />
                            </div>
                            <b-collapse
                                v-if="item.ToolName === 'X/Y Scatter Plot'"
                                id="collapse-1"
                                class="w-100"
                            >
                                <b-card>
                                    <ul class="p-0 m-0">
                                        <li
                                            @click="
                                                openChartElement(GenerateChart)
                                            "
                                        >
                                            X/Y Scatter Plot
                                        </li>
                                    </ul>
                                </b-card>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div>
                <span class="switchlabel m-none positionRelative">
                    <label class="router-link-active switch mr-2">
                        <input
                            type="checkbox"
                            v-model="currentSwitchState"
                            @input="showSatate"
                            checked
                        />
                        <span class="slider round"></span>
                    </label>
                    <label>
                        <a href="#"
                            ><img
                                id="searchBtn"
                                class="filtericonM desk-none mr-2 mt-0"
                                src="../assets/filter1.svg"
                        /></a>
                    </label>
                    <span class="m-none">Open selection in new tab</span></span
                >

                <router-link to="/"> </router-link>
            </div> -->
        </div>
    </b-row>
</template>

<script>
import { datasetService } from '../services/dataset.service';
import tableselectiontool from './projects/TableSelectTool.vue';
// import { commonService } from "../services/common.service";
import CustomFloatingLabel from './CustomFloatingLabel';

export default {
    name: 'PortalBarTool',
    components: { CustomFloatingLabel },
    props: {
        showReportingMenu: Boolean,
        hidetableSelect: Boolean,
        DPShow: Boolean,
        disableDocIcon: Boolean,
        disableSearchIcon: Boolean,
        disablePostingIcon: Boolean,
        toolsData: Array,
        dataPostingTool: Boolean,
        reportingToolDisplay: Boolean,
        search: String,
        dataSetTools: {
            type: Array,
            default: () => [],
        },
    },
    // updated(){
    //   this.search = this.searchGrid;
    // },
    data() {
        return {
            resetBtn: true,
            col: 0,
            row: 0,
            snowflake_black: require('../assets/snowflake_icon_black.png'),
            snowflake_blue: require('../assets/snowflake_icon_blue.png'),
            freeseImg: require('../assets/snowflake_icon_black.png'),
            hover: false,
            mapHover: false,
            currentSwitchState: false,
            reportingTool: false,
            optionsTools: [],
            allowedTools: [],
            showPage: true,
            visible: false,
            searchClose: false,
            mapview_black: require('../assets/map_view.svg'),
            mapview_blue: require('../assets/map_view-blue.svg'),
            showSearchIcon: null,
        };
    },
    created() {
        this.$root.$refs.C = this;
    },
    mounted() {
        this.pagePermit();
    },
    methods: {
        closeModal() {
            this.$refs['modal-2'].hide();
        },
        openModel() {
            this.$refs['modal-2'].show();
        },
        toggleMap() {
            if (this.$store.state.projects.mapExpanded) {
                this.$store.commit('projects/setMapExpandedOff');
            } else {
                this.$store.commit('projects/setMapExpandedOn');
            }
            this.$bvModal.show('modal-screen');
        },
        freeze() {
            this.row = document.getElementById('row').value;
            this.col = document.getElementById('col').value;
            document.getElementById('freeze-tool-icon').style.backgroundColor =
                '#eaeaea';
            this.freeseImg = this.snowflake_blue;

            if (this.row != 0 || this.col != 0) {
                this.freezeBtn = true;
                this.resetBtn = false;
                tableselectiontool.methods.freezeHelper(this.row, this.col);
                this.closeModal();
            } else {
                alert('Number of rows or columns must be greater than 0.');
            }
        },
        forceReset() {
            document.getElementById('forceReset').click();
        },
        changeImage() {
            if (this.imgIdx === 0) {
                this.imgIdx = 1;
                this.imgSource = require('../assets/snowflake_blue_gray.jpeg');
            } else {
                this.imgIdx = 0;
                this.imgSource = require('../assets/snowflake_black.jpeg');
            }
        },
        handleReset() {
            this.row = 0;
            this.col = 0;
            this.freezeBtn = false;
            this.resetBtn = true;
            this.freeseImg = this.snowflake_black;
            document.getElementById('freeze-tool-icon').style.backgroundColor =
                'transparent';
            tableselectiontool.methods.resetHelper();
        },
        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            // let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('DatasetExport')) {
                    this.allowedTools.push('Dataset Export');
                }

                if (element.PageName.includes('TitleBlock')) {
                    this.allowedTools.push('Title Block');
                }
                if (element.PageName.includes('GenerateChartElement')) {
                    this.allowedTools.push('X/Y Scatter Plot');
                }
            });
        },
        showSatate() {
            this.$root.$emit('clickedFilterData', !this.currentSwitchState);
            this.$emit('clickedFilter', this.currentSwitchState);
        },
        searchiconclick() {
            let search = document.querySelector('#outersearchinput');
            let portal = document.querySelector('#portalbar');
            search.style.width = search.style.width == '3%' ? '27%' : '3%';
            portal.style.width =
                portal.style.width == '94.5%' ? '70.5%' : '94.5%';
            if (search.style.width == '3%') {
                document.querySelector('#outersearchinput').style.borderColor =
                    'transparent';
                document.querySelector(
                    '#outersearchinput .e-input'
                ).style.display = 'none';
            } else {
                document.querySelector('#outersearchinput').style.borderColor =
                    'rgba(0, 0, 0, 0.2)';
                document.querySelector(
                    '#outersearchinput .e-input'
                ).style.display = 'block';
            }
        },
        closeBar(args) {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.search = null;
            this.$root.$refs.B.searchinputenter(args);
            let portal = document.querySelector('#portalbar');
            portal.style.width = '95.9%';
            // this.$refs.grid.$el.ej2_instances[0].search();
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            let portal = document.querySelector('#portalbar');
            portal.style.width = '75.7%';
            let search = document.querySelector('#searchFilter');
            search.style.width = '27%';
            // this.$nextTick(() => {
            //   this.$refs.dataset.focus()
            // })
        },
        searchinputenter(args) {
            this.$root.$refs.B.searchinputenter(args);
        },
        showReportingTool() {
            this.reportingTool = !this.reportingTool;
        },
        dataExportTab() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('DatasetExport')) {
                    vm.showPage = true;
                }
            });
            this.$emit('clicked', this.currentSwitchState);
            this.$emit('datasetexportDash', this.showPage);

            // this.$emit('renderFunctiontable', 'dataExportTab');
            this.reportingTool = !this.reportingTool;
            this.$root.$refs.B.dataExportTab();
        },
        showTitleBlockAccess() {
            this.$root.$refs.B.showTitleBlockAccess();
        },
        navigateToTool(toolname) {
            this.$emit('clicked', this.currentSwitchState);
            this.reportingTool = !this.reportingTool;
            this.$root.$refs.B.navigateToTool(toolname);
        },
        getAttributes(id) {
            this.visible = !this.visible;
            datasetService
                .getToolattributesUsingId(id)
                .then((res) => (this.optionsTools = res.data));
        },
        navigateTools() {
            this.$emit('navigateTools');
        },
        minusClick() {
            this.visible = false;
            this.$forceUpdate();
        },
        dataToolTab(DP_Show) {
            this.$emit('clicked', this.currentSwitchState);
            //add grid history
            this.$emit('navigateTools');
            this.$root.$refs.B.dataToolTab(DP_Show);
        },
        openChartElement(GenerateChart) {
            this.visible = false;
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('GenerateChartElement')) {
                    vm.showPage = true;
                }
            });
            GenerateChart = this.showPage;
            this.$emit('clicked', this.currentSwitchState);
            this.reportingTool = !this.reportingTool;

            this.$emit('navigateTools');

            this.$root.$refs.B.openChartElement(GenerateChart);

            // this.$emit('GenerateChart', GenerateChart);
            // this.$emit('renderFunctiontable', 'openChartElement');
        },
        navigatetoDataset() {
            this.$root.$refs.B.navigatetoDataset();
        },
    },
    computed: {
        freezeBtnState() {
            let v =
                parseInt(this.row) > 0 || parseInt(this.col) > 0 ? false : true;
            return parseInt(this.row) > 0 || parseInt(this.col) > 0
                ? false
                : true;
        },
        removeTools() {
            let toolIds = [1, 3, 4];
            let tempTooldData = [...this.toolsData];
            tempTooldData.forEach((tool, indx) => {
                if (toolIds.includes(tool.ToolId)) {
                    if (!this.dataSetTools.includes(tool.ToolId)) {
                        tempTooldData.splice(indx, 1);
                    }
                }
            });
            return tempTooldData;
        },
        pictureHover() {
            if (this.hover == true || this.freezeBtn == true) {
                return this.snowflake_blue;
            } else {
                return this.snowflake_black;
            }
        },
        mapViewHover() {
            if (this.mapHover == true || this.freezeBtn == true) {
                return this.mapview_blue;
            } else {
                return this.mapview_black;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
#freeze-tool-icon:hover,
#freeze-tool-icon:active,
#freeze-tool-icon:focus {
    background-color: #eaeaea;
}
#map-view-icon:hover,
#map-view-icon:active,
#map-view-icon:focus {
    background-color: #eaeaea;
}

.assignedApply,
.cancelButton {
    padding: 14px 23.46px;
}

.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
    cursor: pointer;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #ffffff;
}

.cancelButton,
.cancelButton:focus,
.cancelButton:active,
.cancelButton:hover {
    background: transparent;
    color: #006395 !important;
    border: none;
    float: right;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent !important;
}

.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}

#outersearchinput {
    height: 0px;
}

// #outersearchinput {
//     height: 40px !important;
//     padding-top: 10px;
// }

// #outersearchinput {
//     width: 20px;
//     border-color: white;
//     height: 25px;
// }

// #outersearchinput .e-input {
//     display: none;
//     padding: 0px;
// }

// .portalbarMain .border {
//     height: 47px;
//     // padding-top: 3px !important;
//     border-radius: 4px;
//     width: 987px;
// }
.e-search-icon::before {
    content: '\e993';
    position: relative;
    top: -4px;
}

.e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
    background-color: #000 !important;
}

// .border {
//     height: 48px;
//     border-radius: 4px;
//     width: 987px;
// }

.icons a {
    color: #c2c2c2 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.sub_header_bg img {
    margin-bottom: 3px;
}

.icons a {
    color: #c2c2c2 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.sub_header_bg img {
    margin-bottom: 3px;
}

.switchlabel {
    color: #161616;
    font-size: 14px;
    margin-top: 6px;
    float: right;
    top: -43px;
}

label {
    display: inline-block;
    // margin-bottom: 0.5rem;
}

input[type='radio'],
input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider.round {
    border-radius: 34px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 20px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

.slider.round:before {
    border-radius: 50%;
}

.desk-none {
    display: none;
}

img {
    vertical-align: middle;
    border-style: none;
}

.text-right.icons {
    max-width: 100px;
}

.icon-right {
    position: absolute;
    right: 0;
    top: 0;
}

.list-icon {
    width: 48px;
    height: 48px;
    float: left;
}

.b-icon.bi {
    display: inline-block;
    overflow: visible;
    vertical-align: -0.15em;
}

.bi-geo-alt-fill {
    margin-top: 12px;
}

.bi-geo-alt-fill,
.bi-list-ul {
    cursor: pointer;
}

.portalbar a img {
    padding: 12px !important;
}

.tableIcon {
    padding: 12px !important;
    margin-bottom: 6px;
}

.portalbarMain {
    border-bottom: 1px solid #d4d4d4;
    padding: 0 0 15px 0;
    margin-left: auto;
    margin-right: auto;
}

.tableIcon,
.dataPostingIcon,
.docIcon {
    height: 46px;
}

.tableIcon:hover,
.dataPostingIcon:hover,
.docIcon:hover {
    background-color: #eaeaea;
}
.mapView {
    color: #006395;
}
.dataPostingIcon {
    padding: 12px 14px !important;
    margin-bottom: 6px;
}

.docIcon {
    padding: 12px 16px !important;
    margin-bottom: 6px;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.clickNotAllowed {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
}

#searchFilter {
    height: 48px;
}

.mr-6 {
    min-width: 50% !important;
}

input.test {
    width: 510px !important;
}

.grey-bg {
    background-color: #e5e5e5;
    padding: 13px 20px;
    padding-bottom: 16px;
    width: 48px;
    height: 48px;
}
.contouringIcon {
    margin: 16px 15px;
    height: 48px;
}
.contouringIcon small:hover {
    border: 3px solid #3296ca;
    height: 48px;
    padding-top: 10px;
}
.contouringIcon small {
    padding-top: 10px;
}
.contouringIcon span {
    background-color: #3296ca;
    height: 48px;
    width: 48px;
    text-align: center;
}
.mapIcon {
    height: 46px;
    width: 50px;
}
</style>
