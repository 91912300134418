import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const dataSourceService = {
    getDataSources,
    getDataSourceDBType,
    dataSetsByDataSource,
    deleteDataSource,
    testConnection,
    createDataSource,
    editDataSource,
    updateDataSource,
    getDuplicateDataSource,
    getDataSourcesProjectList,
};
function getDataSources() {
    // return axios.get(apiConstants.getDataSourcesList)
    return request({ url: apiConstants.getDataSourcesList });
}

function getDataSourceDBType() {
    // return axios.get(apiConstants.getDataSourceDBType)
    return request({ url: apiConstants.getDataSourceDBType });
}

function dataSetsByDataSource(id) {
    // return axios.get(apiConstants.dataSetsByDataSource+"?$expand=Datasets&$filter=DataSourceID eq "+id)
    return request({
        url:
            apiConstants.dataSetsByDataSource +
            '?$expand=Datasets&$filter=DataSourceID eq ' +
            id,
    });
}

function deleteDataSource(id) {
    // return axios.delete(apiConstants.deleteDataSource+"/DeleteDatasource?id="+id)
    return request({
        url: apiConstants.deleteDataSource + '/DeleteDatasource?id=' + id,
        method: 'delete',
    });
}

function testConnection(data) {
    // return axios.post(apiConstants.testConnection, data)
    return request({
        url: apiConstants.testConnection,
        data: data,
        method: 'post',
    });
}

function createDataSource(data) {
    // return axios.post(apiConstants.createDataSource, data)
    return request({
        url: apiConstants.createDataSource,
        data: data,
        method: 'post',
    });
}

function editDataSource(id) {
    // return axios.get(apiConstants.editDataSource+'/'+id)
    return request({ url: apiConstants.editDataSource + '/' + id });
}

function updateDataSource(id, data) {
    // return axios.put(apiConstants.updateDataSource+'?id='+id, data)
    return request({
        url: apiConstants.updateDataSource + '?id=' + id,
        data: data,
        method: 'put',
    });
}

function getDuplicateDataSource(dataSource) {
    // return axios.get(apiConstants.getDuplicateDataSource+'?Name='+dataSource)
    return request({
        url: apiConstants.getDuplicateDataSource + '?Name=' + dataSource,
    });
}

function getDataSourcesProjectList() {
    // return axios.get(apiConstants.getDataSourcesProjectList)
    return request({ url: apiConstants.getDataSourcesProjectList });
}
