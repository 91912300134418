<template>
    <div class="userlist mt-3">
        <b-row class="m-0">
            <b-col cols="12" class="border-top pb-2 m-shadow m-b-10">
                <b-row class="m-0 mt-2 m-mrg-top-20">
                    <input
                        ref="usersSearch"
                        spellcheck="false"
                        id="searchFilter"
                        v-if="filterUserSearch || showSearchIcon ? true : false"
                        v-model="filterUserSearch"
                        @click="searchinputenter()"
                        @input="searchinputenter"
                        :class="
                            filterUserSearch || showSearchIcon
                                ? 'test'
                                : 'searchinputList'
                        "
                        type="search"
                        placeholder="Start typing to search for someone"
                    />
                    <span class="grey-bg" v-if="searchClose" @click="closeBar"
                        ><img src="../../assets/close.svg"
                    /></span>
                    <b-col cols="0" class="noPadding mt-2 m-mt-2">
                        <img
                            class="pointer  ml-2  "
                            src="../../assets/search-outline.svg"
                            v-if="
                                !searchQuery && !showSearchIcon ? true : false
                            "
                            @click="searchOpen"
                        />

                        <span class="border-right list ml-2">&nbsp;</span>
                    </b-col>

                    <b-col
                        sm="2"
                        md="2"
                        lg="2"
                        class="filterDrop mal-1 pt-1 text-left ml-0 flt-icon mt-2"
                    >
                        <button class="btn p-0 border-0" @click="filterClick">
                            <a href="#" class="pl-2 ml-0 pr-0"
                                ><img
                                    class="m-filter"
                                    src="../../assets/tooltip.svg"
                            /></a>
                            <span class="blue-text pl-2 m-none">Filter</span>
                        </button>

                        <b-list-group-item
                            class="filter-group left-align p-0 filter-m"
                            v-if="filterShow"
                        >
                            <div class="mx-4 mt-3 filter-sec">
                                <div class="mb-1 float-left">
                                    Filter by
                                </div>
                                <div class="text-right">
                                    <a href="javascript:void(0)">
                                        <b-icon
                                            icon="x"
                                            font-scale="2"
                                            @click="closeFilter"
                                        >
                                        </b-icon>
                                    </a>
                                </div>
                                <div class="mt-3">
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Country Name',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="CountryID"
                                            id="filter"
                                            class="mb-3"
                                            :options="countries"
                                            text-field="CountryName"
                                            value-field="CountryId"
                                            @change="stateByCountry(CountryID)"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Country</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                </div>
                                <div>
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'State Name',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="StateId"
                                            id="filter1"
                                            class="mb-3"
                                            :options="states"
                                            text-field="StateName"
                                            value-field="StateId"
                                            @change="cityByState(StateId)"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >State</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                </div>
                                <div></div>
                                <div class="d-flex">
                                    <b-button
                                        class="mb-3"
                                        v-on:click="filterUser"
                                        >Apply filters</b-button
                                    >
                                </div>
                            </div>
                        </b-list-group-item>
                        <!-- </div> -->
                    </b-col>

                    <b-col
                        lg="4"
                        sm="4"
                        md="9"
                        class="m-top filterBar filter-items"
                        v-if="enableFilter"
                    >
                        <b-row class="m-mrgy-30">
                            <div v-if="CountryID">
                                <span
                                    class="filteredItem px-2 py-1 text-center"
                                >
                                    {{ countries[CountryID - 1].CountryName }}
                                    <b-icon
                                        icon="x"
                                        font-scale="1"
                                        @click="backFilter('country')"
                                    ></b-icon>
                                </span>
                            </div>
                            <div v-if="StateId">
                                <span
                                    class="filteredItem px-2 py-1 text-center"
                                >
                                    {{ findState }}

                                    <b-icon
                                        icon="x"
                                        font-scale="1"
                                        @click="backFilter('state')"
                                    ></b-icon>
                                </span>
                            </div>

                            <div class="removeFilter">
                                <a
                                    href="javascript:void(0)"
                                    v-if="
                                        enableFilter && (CountryID || StateId)
                                    "
                                    class="remove-link"
                                    @click="filterReset"
                                    >Remove all filters</a
                                >
                            </div>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div :class="enableFilter ? 'user-tbl' : ''">
            <ejs-grid
                id="tblUser"
                :dataSource="enableFilter ? filterItems : users"
                class="sortingenabled"
                ref="grid"
                :allowPaging="true"
                :allowSorting="true"
                :commandClick="gridRowData"
                :load="pageCenter"
                :dataBound="dataBound"
                :sortSettings="sortOptions"
                :gridSettings="gridSettings"
                :pageSettings="pageSettings"
                :allowResizing="true"
            >
                <e-columns>
                    <e-column
                        headerText="Edit"
                        title="Edit"
                        width="80"
                        :commands="editCommands"
                    ></e-column>
                    <e-column
                        field="FirstName"
                        textAlign="Center"
                        headerText="First Name"
                        width="150"
                    ></e-column>
                    <e-column
                        field="LastName"
                        headerText="Last Name"
                        width="150"
                    ></e-column>
                    <e-column
                        field="Address"
                        headerText="Address"
                        width="150"
                    ></e-column>
                    <e-column
                        field="CityName"
                        headerText="City"
                        width="100"
                    ></e-column>
                    <e-column
                        field="State.StateName"
                        headerText="State"
                        width="150"
                    ></e-column>
                    <e-column
                        field="PostalCode"
                        headerText="Zip/Postal Code"
                        width="150"
                    ></e-column>
                    <e-column
                        field="EmailId"
                        headerText="Email Address"
                        width="150"
                    ></e-column>
                    <e-column
                        headerText="Delete"
                        title="Delete"
                        width="61"
                        :commands="deleteCommands"
                        freeze="Right"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>

        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />

        <b-modal
            ref="my-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right">
                    <img src="../../assets/close_icon.svg" @click="hideModal" />
                </div>
                <h5 class="mt-2">Delete User "{{ modalFirstName }}"</h5>
                <small
                    >Are you sure you want to delete this user? This action
                    cannot be undone.</small
                ><br />
                <div class="mt-3 mb-4">
                    <a @click="hideModal" class="text-primary"
                        >No, don't delete</a
                    >
                    <button
                        class="buttonDelete ml-4"
                        @click="deleteUser(modalUserId)"
                    >
                        Delete user
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '../../router';
import { commonService } from '../../services/common.service';
import { userService } from '../../services/users.service';
import CustomFloatingLabel from '../CustomFloatingLabel';
import Vue from 'vue';
import {
    GridPlugin,
    Sort,
    Page,
    Edit,
    CommandColumn,
    Toolbar,
    Search,
    Freeze,
    Resize,
} from '@syncfusion/ej2-vue-grids';
// import AccessPermissionModal from '../AccessPermissionModal';
// import dashboard from '../../views/Dashboard'
import ToastMessages from '../ToastMessages.vue';
Vue.use(GridPlugin);

export default {
    data() {
        return {
            sortOptions: {
                columns: [
                    { field: 'FirstName', direction: 'Ascending' },
                    { field: 'LastName', direction: 'Ascending' },
                    { field: 'Address', direction: 'Descending' },
                    { field: 'CityName', direction: 'Descending' },
                    { field: 'State.StateName', direction: 'Descending' },
                    { field: 'PostalCode', direction: 'Descending' },
                    { field: 'PostalCode', direction: 'Descending' },
                    { field: 'EmailId', direction: 'Descending' },
                ],
            },
            modalUserId: '',
            modalFirstName: '',

            // totalRows: 10,
            currentPage: 1,
            perPage: 15,
            pageOptions: [5, 10, 15],
            search: null,
            filterShow: false,
            applyfilter: false,
            countries: null,
            CountryID: null,
            StateId: null,
            CityId: null,
            states: null,
            countryName: '',
            countryId: '',
            stateName: '',
            stateId: '',
            cities: null,
            cityName: '',
            userSource: [],
            filterToggle: false,
            filterItems: [],
            users: [],
            enableFilter: false,
            showSuccess: false,
            showFailure: false,
            successToastMessage: '',
            failureToastMessage: '',
            pageSettings: { pageSize: 10 },
            findState: null,
            // findCity: null,
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            deleteCommands: [
                {
                    type: 'Delete',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-delete e-icons',
                    },
                },
            ],
            showPage: true,
            thisfilename: null,
            showSearchIcon: false,
            searchClose: false,
            filterUserSearch: null,
        };
    },
    components: {
        ToastMessages,
        CustomFloatingLabel,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Edit,
            CommandColumn,
            Toolbar,
            Search,
            Freeze,
            Resize,
        ],
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    created() {
        this.getAllUser();
        this.countryList();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.Pagination) {
                this.pageSettings.pageSize = this.$store.state.store.portalsettings.Pagination;
            }
        }
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        getAllUser() {
            userService.getAllUsers().then((res) => {
                if (res) {
                    this.users = res.data;
                    this.totalRows = this.users.length;
                    // this.$refs.grid.ej2Instances.refresh();
                }
            });
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            this.thisfilename = this.$route.matched[0].components.default.__file
                .replace(/^.*[\\/]/, '')
                .split('.')
                .slice(0, -1)
                .join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes(vm.thisfilename)) {
                    vm.showPage = true;
                }
            });
            this.$emit('clickedUserList', this.showPage);
        },
        pageCenter: function() {
            this.$refs.grid.ej2Instances.pagerModule.element.align = 'center';
        },
        dataBound: function() {
            this.$refs.grid.ej2Instances.pagerModule.pagerObj.pagerMessageModule.hideMessage();
        },

        focusSearch() {
            this.$nextTick(() => {
                this.$refs.usersSearch.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.usersSearch.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.filterUserSearch = null;
            this.$refs.grid.$el.ej2_instances[0].search();
        },

        searchinputenter(args) {
            if (args) {
                this.$refs.grid.$el.ej2_instances[0].search(
                    args.currentTarget.value
                );
                this.showSearchIcon = false;
                if (!this.filterUserSearch) {
                    this.searchClose = false;
                }
            }
        },
        filterClick() {
            this.filterShow = !this.filterShow;
        },
        gridRowData: function(args) {
            //if (args.commandColumn.type === "Delete") {
            if (args.target.getAttribute('title') === 'Delete') {
                this.modalUserId = args.rowData.UserId;
                this.modalFirstName = args.rowData.FirstName;
                this.$refs['my-modal'].show();
            }
            //if (args.commandColumn.type === "Edit") {
            if (args.target.getAttribute('title') === 'Edit') {
                //
                router.push({ path: `/edituser/${args.rowData.UserId}` });
            }
        },
        deleteUser(userId) {
            userService.deleteUser(userId).then((res) => {
                setTimeout(() => {
                    if (res.data.StatusCode === 200) {
                        this.hideModal();
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage = 'User deleted successfully';
                        setTimeout(() => {
                            this.showSuccess = false;
                            this.getAllUser();
                        }, 5000);
                    } else {
                        this.hideModal();
                        this.showSuccess = false;
                        this.showFailure = true;
                        this.failureToastMessage =
                            'Something went wrong.Please try again later.';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                }, 1000);
                this.$refs['my-modal'].hide();
            });
        },
        // closeSuccessToast(){
        //   this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        closeFilter() {
            this.filterShow = false;
        },
        filterUser() {
            this.enableFilter = true;
            this.filterShow = false;
            this.filterItems = this.users;
            if (
                document.getElementById('filter').value &&
                document.getElementById('filter1').value
            ) {
                this.filterItems = this.filterItems.filter(
                    (item) =>
                        item.CountryId ==
                            document.getElementById('filter').value &&
                        item.StateId == document.getElementById('filter1').value
                    // item.CityName.toLowerCase() == document.getElementById('filter2').value.toLowerCase()
                );
            }
            // else if(document.getElementById('filter').value && document.getElementById('filter1').value){
            //   this.filterItems = this.users.items.filter((item)=>
            //     item.CountryId == document.getElementById('filter').value &&
            //     item.StateId == document.getElementById('filter1').value
            //   )
            // }
            // else if(document.getElementById('filter1').value && document.getElementById('filter2').value){
            //   this.filterItems = this.users.items.filter((item)=>
            //     item.StateId == document.getElementById('filter1').value &&
            //     item.CityName.toLowerCase() == document.getElementById('filter2').value.toLowerCase()
            //   )
            // }
            // else if(document.getElementById('filter').value && document.getElementById('filter2').value){
            //   this.filterItems = this.users.items.filter((item)=>
            //     item.CountryId == document.getElementById('filter').value &&
            //     item.CityName.toLowerCase() == document.getElementById('filter2').value.toLowerCase()
            //   )
            // }
            else if (document.getElementById('filter').value) {
                this.filterItems = this.users.filter(
                    (item) =>
                        item.CountryId ==
                        document.getElementById('filter').value
                );
            } else if (document.getElementById('filter1').value) {
                this.filterItems = this.users.filter(
                    (item) =>
                        item.StateId == document.getElementById('filter1').value
                );
            }
            // else {
            //    this.filterItems = this.users.items.filter((item)=>
            //     item.CityName.toLowerCase() == document.getElementById('filter2').value.toLowerCase()
            //   )
            // }

            this.states.filter((item) => {
                if (item.StateId == this.StateId) {
                    this.findState = item.StateName;
                }
            });
            // this.cities.filter((item)=>{if(item.CityName == this.CityName){this.findCity = item.CityName}});
            // this.findCity = document.getElementById('filter2').value;
        },
        filterReset() {
            this.enableFilter = false;
            this.CountryID = null;
            this.StateId = null;
            // this.CityId = null;
        },

        showModal(userDetails) {
            this.modalUserId = userDetails.UserId;
            this.modalFirstName = userDetails.FirstName;
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },

        editUser(userId) {
            router.push({ path: `/edituser/${userId}` });
        },

        countryList() {
            commonService
                .getCountries()
                .then((res) => (this.countries = res.data));
        },
        stateByCountry(countryId) {
            // let country = this.countries.find((e) => e.CountryId === this.countryId);
            // this.countryName = country.CountryName;

            commonService
                .getStates(countryId)
                .then((res) => (this.states = res.data));
        },
        cityByState(StateId) {
            // let state = this.states.find((e) => e.StateId === this.stateId);
            // this.stateName = state.StateAbbrev;
            commonService
                .getCities(StateId)
                .then((res) => (this.cities = res.data));
        },
        filter() {
            this.filterShow = true;
            this.projectList = false;
        },
        applyFilter() {
            this.filterItems = this.users;
            let vm = this;
            if (this.CountryID) {
                this.filterItems = this.filterItems.filter(
                    (item) => item.CountryId === vm.CountryID
                );
            }

            if (this.StateId) {
                this.filterItems = this.filterItems.filter(
                    (item) => item.StateId === vm.StateId
                );
            }
            // if (this.CityId) {
            //   this.filterItems = this.filterItems.filter(
            //     (item) => item.CityId === vm.CityId
            //   );
            // }

            this.applyfilter = true;
            this.filterShow = false;
        },
        removeFilter() {
            this.applyfilter = false;
            this.countryName = '';
            this.stateName = '';
            this.cityName = '';
            this.stateId = '';
            this.countryId = '';
        },
        backFilter(item) {
            if (!this.StateId && !this.CountryID) {
                this.applyfilter = false;
                this.CountryName = '';
                this.StateName = '';
                // this.CityName = "";
                this.StateId = null;
                this.CountryID = null;
            }
            if (item === 'country') {
                this.CountryName = '';
                this.CountryID = null;
            }
            if (item === 'state') {
                this.StateName = '';
                this.StateId = null;
            }
            // if (item === "city") {
            //   this.CityName = "";
            //   this.CityId = "";
            // }
            this.filterShow = false;
            this.applyFilter();
            //this.projectList = true;
        },
    },
    computed: {
        ...mapState({
            users: (state) => state.users.users,
            totalRows: (state) => state.users.users.items?.length,
            deleteStatusCode: (state) => state.users.remove.StatusCode,
        }),
    },
};
</script>
<style lang="scss" scoped>
.fixed_col {
    position: absolute;
    top: auto;
    right: 10%;
    width: 100px !important;

    border-bottom: 0px !important;
}

table th {
    border-bottom: 3px solid black !important;
}
.table > tbody > tr:first-child > td {
    border: none;
}

th.fixed_col {
    border-bottom: 3px solid black !important;
    border-top: 0px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    height: 47px;
    // margin-top: 20px;
}

.button-group .btn-secondary {
    background-color: transparent !important ;
    border-color: transparent !important ;
}
.button-dropdown .btn-secondary {
    background-color: transparent !important;
    border-color: transparent !important;
}

.button-dropdown .dropdown-toggle {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.show a.option {
    border-bottom: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f3f3f3 !important;
    margin-left: 2px;
    padding-left: 10px;
    padding-right: 11px;
    border: 1px solid #dee2e6 !important;
    box-shadow: 0 0 0 0 transparent !important;
}
.button-dropdown .btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    box-shadow: 0 0 0 0 transparent !important;
}
.button-dropdown .dropdown-menu {
    transform: translate3d(-143px, 36px, 0px) !important;
    border-top-right-radius: 0px !important;
    background-color: #f3f3f3 !important;
    border: 1px solid #dee2e6 !important;
}

.button-dropdown .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: transparent !important;
}
.zip_postal {
    min-width: 150px;
}

.address_width {
    width: 280px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.state_width {
    width: auto;
}
.email_width {
    min-width: 120px;
}

.edit-text {
    margin-top: -13px;
    display: block;
    position: absolute;
    // margin-left: 30px;
}

.test_width {
    width: 733px !important;
}

.edit {
    margin: -10px 0 -10px 0;
    padding: 8px 8px 8px 13px;
}
.delete {
    padding: 8px 8px 8px 13px;
    margin: -10px 0 -10px 0;
    a:hover {
        color: white !important;
    }
}
.edit:hover {
    background-color: #e0e0e0;
    padding: 8px 8px 8px 13px;
}
.delete:hover {
    background-color: red;
    padding: 8px 8px 8px 13px;
}

a {
    //padding: 8px 8px 8px 13px;
    font: normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.16px;
    color: #525252;
    opacity: 1;

    text-align: left;
    /* border-bottom:1px solid; */
    cursor: pointer;
}

button.buttonDelete {
    background-color: #da1e27;
    color: #eee;
    padding: 7px 15px;
    border: none;
}

.modal-content {
    width: 97% !important;
    border-radius: 0px !important;
}
.email_width {
    min-width: 200px;
}

.filteredItem {
    background-color: #eaeaea;
    width: auto;
    padding: 0px 10x;
    margin-right: 10px;
}
.removeFilter a {
    color: #006395 !important;
}
// td.e-rowcell:nth-child(6){
//   color:black !important;
//   text-decoration: none !important;
// }
.e-search-icon::before {
    content: '\e993';
    top: 0px !important;
}

.filterSelect {
    width: 250px !important;
}
.e-grid .e-edit::before {
    content: url('../../assets/edit.svg') !important;
}
.e-grid .e-delete::before {
    content: url('../../assets/close_icon.svg') !important;
}
.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: White !important;
    border: none !important;
    text-align: center !important;
}
.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
    background-color: #f5f5f5 !important;
}
.e-grid .e-gridcontent tr td:first-child {
    text-align: left !important;
}
.e-grid .e-gridcontent tr td:last-child {
    text-align: center !important;
}
.filterBar {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 45px;
}
input.searchinputList {
    /* background: url("../../../assets/search-outline.svg") no-repeat !important;  border: none; */
    background: none !important;
    margin-right: -151px;
    padding-left: 25px;
    /* border-bottom: 1px solid #ccc; */
    width: 180px;
    margin-bottom: 10px;
    top: 3px;
    position: relative;
    cursor: pointer;
    z-index: 0;
}
input.test {
    margin: 0 0px 0 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    //margin-right: -20px!important;
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.filter-group.filter-m {
    left: 0 !important;
    top: 40px !important;
}
.mal-1 {
    margin-left: -10px !important;
}
</style>
