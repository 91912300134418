var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"attributeModal",attrs:{"id":"attributesModal","title":"Attributes Pane","centered":"","hide-footer":""},on:{"hide":function($event){return _vm.setShowAttributeModal(false)}}},[_c('form',{staticClass:"modalBody large"},[_c('custom-floating-label',{attrs:{"config":{
                label: 'Type',
                name: 'wrapper',
                line: false,
                scale: false,
            }}},[_c('div',{staticClass:"d-flex justidy-content-between"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.drawingAttributes.geomLocType),expression:"drawingAttributes.geomLocType"}],staticClass:"attributeEntry mb-4",attrs:{"disabled":"","type":"text"},domProps:{"value":(_vm.drawingAttributes.geomLocType)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.drawingAttributes, "geomLocType", $event.target.value)}}}),(
                        !['', '-', '---'].includes(
                            _vm.drawingAttributes.geomLocType
                        )
                    )?_c('img',{staticClass:"align-middle my-2",attrs:{"onerror":"handleImageError","src":_vm.iconSource(_vm.drawingAttributes.geomLocType),"width":"30","height":"30"}}):_vm._e()])]),_c('custom-floating-label',{attrs:{"config":{
                label: 'Label',
                name: 'wrapper',
                line: false,
                scale: false,
            }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.drawingAttributes.geomLocName),expression:"drawingAttributes.geomLocName"}],staticClass:"attributeEntry mb-4",attrs:{"type":"text"},domProps:{"value":(_vm.drawingAttributes.geomLocName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.drawingAttributes, "geomLocName", $event.target.value)}}})]),_c('custom-floating-label',{attrs:{"config":{
                label: 'Description',
                name: 'wrapper',
                line: false,
                scale: false,
            }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.drawingAttributes.geomLocDesc),expression:"drawingAttributes.geomLocDesc"}],staticClass:"attributeEntry mb-4",attrs:{"type":"text"},domProps:{"value":(_vm.drawingAttributes.geomLocDesc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.drawingAttributes, "geomLocDesc", $event.target.value)}}})]),_c('custom-floating-label',{attrs:{"config":{
                label: 'Comment',
                name: 'wrapper',
                line: false,
                scale: false,
            }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.drawingAttributes.geomComment),expression:"drawingAttributes.geomComment"}],staticClass:"attributeEntry mb-4",attrs:{"type":"text"},domProps:{"value":(_vm.drawingAttributes.geomComment)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.drawingAttributes, "geomComment", $event.target.value)}}})])],1),_c('div',{staticClass:"modalButtonRow"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditButtons),expression:"showEditButtons"}],staticClass:"modalButton deleteButton",on:{"click":_vm.deleteFromDatabase}},[_vm._v(" Delete geometry ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditButtons),expression:"showEditButtons"}],staticClass:"modalButton",on:{"click":_vm.editGeometry}},[_vm._v(" Edit geometry ")]),_c('button',{staticClass:"modalButton",on:{"click":_vm.sendToDatabase}},[_vm._v(" Save to database ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }