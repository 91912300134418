<template>
    <div class="GHD header">
        <b-navbar toggleable="lg" class="bg_head">
            <b-container class="bv-example-row space">
                <b-navbar-toggle
                    v-if="!isMobile"
                    target="nav-collapse"
                ></b-navbar-toggle>
                <b-navbar-brand
                    href="#"
                    class="logo-spacing"
                    @click="navigateDashboard"
                >
                    <img class="img-fluid" src="../assets/ghd_logo_black.png" />
                </b-navbar-brand>
                <div
                    v-if="!isMobile"
                    class="version-toggle-preview d-flex ml-auto"
                    right
                >
                    <span
                        class="version d-none d-sm-none d-md-block d-lg-block d-xl-block my-auto"
                        >Try the new ADAPT 2.0 (Preview)</span
                    >
                    <span
                        class="version-small d-sm-block d-md-none d-lg-none d-xl-none pl-1 my-auto"
                        >V2.0</span
                    >
                    <label class="switch">
                        <input
                            type="checkbox"
                            id="customSwitch"
                            @click="navigateToOneMap"
                        />
                        <span class="slider round"></span>
                    </label>
                </div>
                <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    class="m-icon ml-2"
                    v-if="userDetails"
                >
                    <template #button-content class="btn-right">
                        <img
                            src="../assets/user_dark.svg"
                            class="my-auto float-left"
                        />
                    </template>
                    <CommonHeader></CommonHeader>
                </b-dropdown>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>
import CommonHeader from './CommonHeader.vue';
import { userService } from '../../services/users.service';
import router from '../../router';
import { mapActions, mapMutations } from 'vuex';
export default {
    components: {
        CommonHeader,
    },
    data() {
        return {
            userDetails: '',
        };
    },
    created() {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    },
    methods: {
        navigateDashboard: function() {
            this.clearSelectedProjects();
            this.setUserVersion(1);
            router.push(`/dashboard`);
        },
        ...mapActions('projects', {
            clearSelectedProjects: 'clearSelectedProjects',
        }),
        ...mapMutations('store', ['setUserVersion']),
        navigateToOneMap() {
            this.setUserVersion(2);
            userService.setUserUiVersion('2');
            router.push(`/map`);
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 768;
        },
    },
};
</script>

<style scoped>
.bg_head {
    background-color: #eaeaea;
    max-height: 85px;
}
::v-deep .dropdown .nav-link {
    color: rgb(87, 87, 87) !important;
}
.logo-spacing {
    padding: 0px 0 9px 0;
}
.navbar-toggler.not-collapsed {
    display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        position: relative;
        top: -18px;
        flex-basis: auto;
    }
}
.profile {
    margin-left: 10px !important;
}

.version-toggle-preview label {
    margin-bottom: 0 !important;
    margin-left: 4px;
}
</style>
