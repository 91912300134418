<template>
    <div class="GHD header ">
        <b-navbar toggleable="lg" class="bg_head">
            <b-container class="bv-example-row space" fluid>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-navbar-brand
                    href="#"
                    class="logo-spacing mr-0"
                    @click="navigateDashboard"
                >
                    <img
                        src="../assets/ghd_logo_black.png"
                        alt="default logo"
                    />
                </b-navbar-brand>
                <b-navbar-nav>
                    <div class="ml-2 mr-1">
                        <img
                            v-if="
                                selectedProjects.length &&
                                    selectedProjects[0].Logo
                            "
                            class="client-logo px-1"
                            :src="clientLogo"
                            alt="client logo"
                        /></div
                ></b-navbar-nav>
                <ProjectsInformationHeaderVue></ProjectsInformationHeaderVue>
                <b-navbar-nav right class="ml-auto dashboard-header align-top">
                    <li
                        class="version-toggle d-flex ml-2"
                        v-if="showVersionToggle"
                    >
                        <span
                            class="version d-none d-sm-none d-md-block d-lg-block d-xl-block my-auto"
                            >Try the new ADAPT 2.0 (Preview)</span
                        >
                        <span
                            class="version-small d-sm-block d-md-none d-lg-none d-xl-none pl-1 my-auto"
                            >V2.0</span
                        >
                        <label class="switch">
                            <input
                                type="checkbox"
                                id="customSwitch"
                                checked
                                @click="navigateToClassicProjects"
                            />
                            <span class="slider round"></span>
                        </label>
                    </li>
                    <b-nav-item-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        class="notifications-wrapper m-icon ml-2  my-auto"
                    >
                        <template #button-content>
                            <img
                                @click="setNotificationBellOff()"
                                :src="getBellIconSrc()"
                                class="notifications-icon"
                            />
                        </template>
                        <recent-notifications></recent-notifications>
                    </b-nav-item-dropdown>
                    <b-nav-item-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                        class="user-icon m-icon ml-2  my-auto"
                        v-if="userDetails"
                    >
                        <template #button-content>
                            <img src="../assets/user_dark.svg" class="prof" />
                        </template>
                        <CommonHeader></CommonHeader>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>
import CommonHeader from './CommonHeader.vue';
import ProjectsInformationHeaderVue from './ProjectsInformationHeader.vue';
import RecentNotifications from './RecentNotifications.vue';
import { userService } from '../services/users.service';
import router from '../router';
import { mapState, mapActions, mapMutations } from 'vuex';
export default {
    components: {
        CommonHeader,
        ProjectsInformationHeaderVue,
        RecentNotifications,
    },
    props: {
        showVersionToggle: { type: Boolean, default: true, required: false },
    },
    data() {
        return {
            userDetails: '',
        };
    },
    created() {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    },
    methods: {
        navigateDashboard: function() {
            this.setUserVersion(2);
            router.push(`/dashboard`);
        },
        ...mapActions('projects', {
            clearSelectedProjects: 'clearSelectedProjects',
        }),
        ...mapMutations('common', ['setNotificationBellOff']),
        ...mapMutations('store', ['setUserVersion']),
        navigateToClassicProjects() {
            this.setUserVersion(1);
            userService.setUserUiVersion('1');
            router.push(`/classic/project/mapview`);
        },
        getBellIconSrc() {
            if (this.notificationBellRinging) {
                return require('../assets/bell-icon-ringing.svg');
            } else {
                return require('../assets/bell-icon.svg');
            }
        },
    },
    computed: {
        ...mapState('projects', ['selectedProjects']),
        ...mapState('common', ['notificationQueue', 'notificationBellRinging']),
        clientLogo() {
            return `data:image/png;base64,${this.selectedProjects[0].Logo}`;
        },
    },
    watch: {
        notificationBellRinging(newVal) {
            this.getBellIconSrc();
        },
    },
};
</script>

<style scoped>
.bg_head {
    background: #eaeaea 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #d1d1d1;
    z-index: 5;
    height: 65px;
}
::v-deep .dropdown .nav-link {
    color: rgb(87, 87, 87) !important;
}
.logo-spacing {
    padding: 0px 0 0px 0;
}
.navbar-toggler.not-collapsed {
    display: none;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        position: relative;
        top: -18px;
        flex-basis: auto;
    }
}
.prof {
    width: 52px;
}
.grid {
    width: 29;
}
.navbar-nav {
    display: flex;
    flex-direction: row;
}
.header .navbar-nav {
    padding: 0px 0 0px 0;
}
.img-fluid {
    max-height: 64px;
}
@media only screen and (max-width: 640px) {
    .client-logo {
        display: none;
    }
}
.notifications-icon {
    width: 42px;
    height: 45px;
}
</style>
<style>
.header .navbar-nav .dropdown-menu {
    z-index: 1001;
    position: absolute;
    top: 50px;
}

.header .nav-link {
    padding: 0;
}
.GHD .user-icon .dropdown-menu {
    min-width: auto !important;
    right: 65% !important;
    left: inherit;
    text-align: center;
}
.GHD .notifications-wrapper .dropdown-menu {
    min-width: auto !important;
    right: 0 !important;
    left: inherit;
    padding: 0 !important;
    top: 54px;
}
.client-logo {
    max-height: 40px !important;
    max-width: 70px !important;
}

.version-toggle label {
    margin-bottom: 0 !important;
    margin-left: 4px;
}

.version-toggle-preview label {
    margin-bottom: 0 !important;
    margin-left: 4px;
}
</style>
