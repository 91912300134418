<template>
    <svg
        v-show="show"
        width="12px"
        height="12px"
        viewBox="0 0 12"
        class="invalid-icon"
    >
        <circle
            cx="6"
            cy="6"
            r="4.5"
            stroke="#dc3545"
            stroke-linejoin="round"
            fill="transparent"
        />
        <path stroke-linejoin="round" d="M5.8 3.6h.4L6 6.5z" stroke="#dc3545" />
        <circle cx="6" cy="8.2" r=".6" fill="#dc3545" stroke="none" />
    </svg>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    name: 'InvalidIcon',
};
</script>

<style scoped>
.invalid-icon {
    transform: scale(1.5);
    position: relative;
    float: right;
    bottom: 54px;
}
</style>
