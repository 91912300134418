var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"Que-btn"},[(_vm.reviewItem.length)?_c('small',{staticClass:"text-primary reviewQueueButton",on:{"click":_vm.reviewQueue}},[_vm._v("Review queue")]):_vm._e(),(!_vm.reviewItem.length)?_c('small',{staticClass:"text-secondary reviewQueueButton"},[_vm._v("Review queue")]):_vm._e(),(_vm.reviewItem.length)?_c('div',[_c('b-button',{staticClass:"buttonSelect queueChange ml-2",attrs:{"rounded":""},on:{"click":function($event){return _vm.queueProjectChanges()}}},[_vm._v(" Queue changes ")])],1):_vm._e(),(!_vm.reviewItem.length)?_c('div',[(_vm.groupIds.length || _vm.toolIds.length)?_c('b-button',{staticClass:"buttonSelect queueChange ml-3",attrs:{"rounded":""},on:{"click":function($event){return _vm.queueProjectChanges()}}},[_vm._v(" Queue changes ")]):_vm._e(),(!_vm.groupIds.length || !_vm.toolIds.length)?_c('b-button',{staticClass:"buttonSelect disableButton queueChange ml-3",attrs:{"rounded":""}},[_vm._v(" Queue changes ")]):_vm._e()],1):_vm._e()]),_c('b-row',{staticClass:"access-blk"},[_c('b-col',{staticClass:"mt-2"},[_c('div',{staticClass:"card bg-light mb-3 border-grey"},[_c('div',{staticClass:"card-header"},[_vm._v("Groups")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"internal-body"},[_c('b-row',{staticClass:"row1"},[(
                                    !_vm.searchGroup && !_vm.showSearchgroup
                                        ? true
                                        : false
                                )?_c('img',{staticClass:"pointer ml-2 my-3",attrs:{"src":require("../../../assets/search-outline.svg")},on:{"click":_vm.searchOpenGroup,"mouseover":_vm.searchOpenGroup}}):_vm._e(),(
                                    !_vm.searchGroup && !_vm.showSearchgroup
                                        ? true
                                        : false
                                )?_c('span',{staticClass:"blue-text ml-2 mb-1 searchPlaceholder",on:{"click":_vm.searchOpenGroup,"mouseover":_vm.searchOpenGroup}},[_vm._v("Search")]):_vm._e(),(
                                    _vm.searchGroup || _vm.showSearchgroup
                                        ? true
                                        : false
                                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchGroup),expression:"searchGroup"}],ref:"groups",class:_vm.searchGroup || _vm.showSearchgroup
                                        ? 'test'
                                        : 'searchinputList',attrs:{"spellcheck":"false","id":"searchFilter","type":"search","placeholder":"Search"},domProps:{"value":(_vm.searchGroup)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchGroup=$event.target.value}}}):_vm._e(),(_vm.searchCloseGroup)?_c('span',{staticClass:"grey-bg pointer",on:{"click":_vm.closeBarGroup}},[_c('img',{attrs:{"src":require("../../../assets/close.svg")}})]):_vm._e()]),_c('div',{staticClass:"header"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allSelected),expression:"allSelected"}],staticClass:"pl-3 ml-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allSelected)?_vm._i(_vm.allSelected,null)>-1:(_vm.allSelected)},on:{"click":_vm.selectAllGroups,"change":function($event){var $$a=_vm.allSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allSelected=$$c}}}}),_c('span',{staticClass:"portal-lbl"},[_vm._v("Group name")])]),_c('b-row',{staticClass:"showProjectList tools"},[_c('table',{staticClass:"table",attrs:{"striped":"","id":"groupTable","per-page":_vm.perPage}},[_c('tbody',{attrs:{"per-page":_vm.perPage}},_vm._l((_vm.groupFilteredList),function(gname,index){return _c('tr',{key:index,attrs:{"value":gname.UserGroupName}},[_c('td',[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 accordian-header",attrs:{"header-tag":"header","role":"tab"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.groupIds),expression:"groupIds"}],staticClass:"checkbox-allocate",attrs:{"type":"checkbox"},domProps:{"value":gname.UserGroupID,"checked":Array.isArray(_vm.groupIds)?_vm._i(_vm.groupIds,gname.UserGroupID)>-1:(_vm.groupIds)},on:{"click":function($event){return _vm.checkedGroup(
                                                                gname.UserGroupID
                                                            )},"change":function($event){var $$a=_vm.groupIds,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=gname.UserGroupID,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.groupIds=$$a.concat([$$v]))}else{$$i>-1&&(_vm.groupIds=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.groupIds=$$c}}}}),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                                            'imagedetails-accordion' +
                                                                index
                                                        ),expression:"\n                                                            'imagedetails-accordion' +\n                                                                index\n                                                        "}],staticClass:"accordion-button",attrs:{"block":""}},[_c('span',[_vm._v(_vm._s(gname.UserGroupName)+" ")])])],1),_c('b-collapse',{attrs:{"id":'imagedetails-accordion' +
                                                            index,"accordion":'imagedetails-accordion' +
                                                            index,"role":"tabpanel"},model:{value:(
                                                        _vm.collapseStatesGroups[
                                                            index
                                                        ]
                                                    ),callback:function ($$v) {_vm.$set(_vm.collapseStatesGroups, 
                                                            index
                                                        , $$v)},expression:"\n                                                        collapseStatesGroups[\n                                                            index\n                                                        ]\n                                                    "}},[_c('b-card-body',{staticClass:"contentCollapse"},[_c('table',{staticClass:"text-card",attrs:{"striped":""}},[_c('tbody',[(
                                                                        gname.Tool &&
                                                                            gname
                                                                                .Tool
                                                                                .length
                                                                    )?_c('tr',[_c('b-row',{staticClass:"row1"},[(
                                                                                !_vm.searchToolNameModel &&
                                                                                !_vm.showSearchgroupsInner
                                                                                    ? true
                                                                                    : false
                                                                            )?_c('img',{staticClass:"pointer ml-2 my-3",attrs:{"src":require("../../../assets/search-outline.svg")},on:{"click":function($event){return _vm.searchOpengroupsInner(
                                                                                    index
                                                                                )},"mouseover":function($event){return _vm.searchOpengroupsInner(
                                                                                    index
                                                                                )}}}):_vm._e(),(
                                                                                !_vm.searchToolNameModel &&
                                                                                !_vm.showSearchgroupsInner
                                                                                    ? true
                                                                                    : false
                                                                            )?_c('span',{staticClass:"blue-text ml-2 mb-1 searchPlaceholder",on:{"click":function($event){return _vm.searchOpengroupsInner(
                                                                                    index
                                                                                )},"mouseover":function($event){return _vm.searchOpengroupsInner(
                                                                                    index
                                                                                )}}},[_vm._v("Search")]):_vm._e(),(
                                                                                _vm.searchToolNameModel ||
                                                                                _vm.showSearchgroupsInner
                                                                                    ? true
                                                                                    : false
                                                                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                                _vm.searchToolNameModel
                                                                            ),expression:"\n                                                                                searchToolNameModel\n                                                                            "}],ref:"groupsInner",refInFor:true,staticClass:"width357",class:_vm.searchToolNameModel ||
                                                                                _vm.showSearchgroupsInner
                                                                                    ? 'test'
                                                                                    : 'searchinputList',attrs:{"spellcheck":"false","id":"searchFilter","type":"search","placeholder":"Search"},domProps:{"value":(
                                                                                _vm.searchToolNameModel
                                                                            )},on:{"input":function($event){if($event.target.composing){ return; }
                                                                                _vm.searchToolNameModel
                                                                            =$event.target.value}}}):_vm._e(),(
                                                                                _vm.searchClosegroupsInner
                                                                            )?_c('span',{staticClass:"grey-bg pointer",on:{"click":_vm.closeBargroupsInner}},[_c('img',{attrs:{"src":require("../../../assets/close.svg")}})]):_vm._e()])],1):_vm._e(),_vm._l((_vm.$options.filters.filterByProperty(
                                                                        gname.Tool,
                                                                        '',
                                                                        'ToolName',
                                                                        _vm.searchToolNameModel
                                                                    )),function(portg,index){return _c('tr',{key:index,attrs:{"value":portg.Tool}},[(
                                                                            portg
                                                                        )?_c('td',{},[(
                                                                                portg.IsIncluded &&
                                                                                    _vm.userGroupToolsExclude[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ].indexOf(
                                                                                        portg.ToolID
                                                                                    ) ==
                                                                                        -1
                                                                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            ),expression:"\n                                                                                userGroupTools[\n                                                                                    gname\n                                                                                        .UserGroupID\n                                                                                ]\n                                                                            "}],staticClass:"slash-checkbox",attrs:{"type":"checkbox"},domProps:{"value":portg.ToolID,"checked":Array.isArray(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            )?_vm._i(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            ,portg.ToolID)>-1:(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            )},on:{"click":function($event){return _vm.childCheck(
                                                                                    gname.UserGroupID,
                                                                                    portg.ToolID,
                                                                                    $event,
                                                                                    false
                                                                                )},"change":function($event){var $$a=
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=portg.ToolID,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.userGroupTools, 
                                                                                    gname
                                                                                        .UserGroupID
                                                                                , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.userGroupTools, 
                                                                                    gname
                                                                                        .UserGroupID
                                                                                , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.userGroupTools, 
                                                                                    gname
                                                                                        .UserGroupID
                                                                                , $$c)}}}}):_vm._e(),(
                                                                                !portg.IsIncluded ||
                                                                                    _vm.userGroupToolsExclude[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ].indexOf(
                                                                                        portg.ToolID
                                                                                    ) >
                                                                                        -1
                                                                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            ),expression:"\n                                                                                userGroupTools[\n                                                                                    gname\n                                                                                        .UserGroupID\n                                                                                ]\n                                                                            "}],attrs:{"type":"checkbox","disabled":_vm.userGroupToolsExclude[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ].indexOf(
                                                                                    portg.ToolID
                                                                                ) >
                                                                                    -1 &&
                                                                                    !portg.IsIncluded},domProps:{"value":portg.ToolID,"checked":Array.isArray(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            )?_vm._i(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            ,portg.ToolID)>-1:(
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            )},on:{"click":function($event){return _vm.childCheck(
                                                                                    gname.UserGroupID,
                                                                                    portg.ToolID,
                                                                                    $event,
                                                                                    true
                                                                                )},"change":function($event){var $$a=
                                                                                _vm.userGroupTools[
                                                                                    gname
                                                                                        .UserGroupID
                                                                                ]
                                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=portg.ToolID,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.userGroupTools, 
                                                                                    gname
                                                                                        .UserGroupID
                                                                                , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.userGroupTools, 
                                                                                    gname
                                                                                        .UserGroupID
                                                                                , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.userGroupTools, 
                                                                                    gname
                                                                                        .UserGroupID
                                                                                , $$c)}}}}):_vm._e(),(
                                                                                portg.IsIncluded &&
                                                                                    _vm.userGroupToolsExclude[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ].indexOf(
                                                                                        portg.ToolID
                                                                                    ) ==
                                                                                        -1
                                                                            )?_c('span',{staticClass:"width68 pl-4 ml-1 mt-1 slash-checkbox1"},[_c('img',{attrs:{"src":require("../../../assets/exclude_black.svg")}})]):_vm._e(),(
                                                                                !portg.IsIncluded ||
                                                                                    _vm.userGroupToolsExclude[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ].indexOf(
                                                                                        portg.ToolID
                                                                                    ) >
                                                                                        -1
                                                                            )?_c('span',[(
                                                                                    !portg.IsIncluded &&
                                                                                        _vm.userGroupToolsExclude[
                                                                                            gname
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            portg.ToolID
                                                                                        ) >
                                                                                            -1
                                                                                )?_c('span',{staticClass:"width68 pl-4 ml-1 mt-1",on:{"click":function($event){return _vm.childExcludeCheck(
                                                                                        gname.UserGroupID,
                                                                                        portg.ToolID,
                                                                                        'Groups',
                                                                                        portg
                                                                                    )}}},[_c('img',{attrs:{"src":require("../../../assets/exclude_red.svg")}})]):_vm._e(),(
                                                                                    portg.IsIncluded &&
                                                                                        _vm.userGroupToolsExclude[
                                                                                            gname
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            portg.ToolID
                                                                                        ) >
                                                                                            -1
                                                                                )?_c('span',{staticClass:"width68 pl-4 mt-1",on:{"click":function($event){return _vm.childExcludeCheck(
                                                                                        gname.UserGroupID,
                                                                                        portg.ToolID,
                                                                                        'Groups',
                                                                                        portg
                                                                                    )}}},[_c('img',{attrs:{"src":require("../../../assets/exclude_black.svg")}})]):_vm._e()]):_vm._e(),_c('span',{staticClass:"slash-text"},[_vm._v(_vm._s(portg.ToolName))])]):_vm._e()])})],2)])])],1)],1)],1)])}),0)])])],1)]),(_vm.pageOfGroups.length > 0)?_c('div',{staticClass:"card-footer"},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"groupTable"},on:{"change":_vm.collapseAllGroups},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()])]),_c('b-col',{staticClass:"mt-2 pl-2 ml-5"},[_c('div',{staticClass:"card bg-light mb-3 border-grey maxWidth499"},[_c('div',{staticClass:"card-header"},[_vm._v("Tools")]),_c('div',{staticClass:"card-body tools-blog"},[_c('div',{staticClass:"internal-body"},[_c('b-row',{staticClass:"row1"},[(
                                    !_vm.searchGroupTools && !_vm.showSearchTools
                                        ? true
                                        : false
                                )?_c('img',{staticClass:"pointer ml-2 my-3",attrs:{"src":require("../../../assets/search-outline.svg")},on:{"click":_vm.searchOpenTools,"mouseover":_vm.searchOpenTools}}):_vm._e(),(
                                    !_vm.searchGroupTools && !_vm.showSearchTools
                                        ? true
                                        : false
                                )?_c('span',{staticClass:"blue-text ml-2 mb-1 searchPlaceholder",on:{"click":_vm.searchOpenTools,"mouseover":_vm.searchOpenTools}},[_vm._v("Search")]):_vm._e(),(
                                    _vm.searchGroupTools || _vm.showSearchTools
                                        ? true
                                        : false
                                )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchGroupTools),expression:"searchGroupTools"}],ref:"tools",class:_vm.searchGroupTools || _vm.showSearchTools
                                        ? 'test'
                                        : 'searchinputList',attrs:{"spellcheck":"false","id":"searchFilter","type":"search","placeholder":"Search"},domProps:{"value":(_vm.searchGroupTools)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchGroupTools=$event.target.value}}}):_vm._e(),(_vm.searchCloseTools)?_c('span',{staticClass:"grey-bg pointer",on:{"click":_vm.closeBarTools}},[_c('img',{attrs:{"src":require("../../../assets/close.svg")}})]):_vm._e()]),_c('div',{staticClass:"header exclusion"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.allSelectedTools),expression:"allSelectedTools"}],staticClass:"ml-2",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.allSelectedTools)?_vm._i(_vm.allSelectedTools,null)>-1:(_vm.allSelectedTools)},on:{"click":_vm.selectAllTools,"change":function($event){var $$a=_vm.allSelectedTools,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.allSelectedTools=$$a.concat([$$v]))}else{$$i>-1&&(_vm.allSelectedTools=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.allSelectedTools=$$c}}}}),_c('h5',[_vm._v("Exclude")]),_c('h5',[_vm._v("Name")])]),_c('b-row',{staticClass:"showProjectList"},[_c('table',{staticClass:"table",attrs:{"id":"ToolsTable","striped":""}},[_c('tbody',_vm._l((_vm.toolsFilteredList),function(tt,index){return _c('tr',{key:index,attrs:{"value":tt.ToolName}},[_c('td',[_c('b-card',{staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0 accordian-header",attrs:{"header-tag":"header","role":"tab"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.toolIds),expression:"toolIds"}],staticClass:"checkbox-allocate",attrs:{"type":"checkbox","disabled":_vm.toolIdsExclude.indexOf(
                                                                tt.ToolId
                                                            ) > -1},domProps:{"value":tt.ToolId,"checked":Array.isArray(_vm.toolIds)?_vm._i(_vm.toolIds,tt.ToolId)>-1:(_vm.toolIds)},on:{"click":function($event){return _vm.checkedTool(
                                                                tt.ToolId
                                                            )},"change":function($event){var $$a=_vm.toolIds,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=tt.ToolId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.toolIds=$$a.concat([$$v]))}else{$$i>-1&&(_vm.toolIds=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.toolIds=$$c}}}}),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                                                            'imagedetails-accordion1' +
                                                                index
                                                        ),expression:"\n                                                            'imagedetails-accordion1' +\n                                                                index\n                                                        "}],staticClass:"accordion-button access-btn",attrs:{"block":""}},[_c('span',[_vm._v(_vm._s(tt.ToolName))])]),(
                                                            _vm.toolIdsExclude.indexOf(
                                                                tt.ToolId
                                                            ) == -1
                                                        )?_c('span',{staticClass:"pl-4 ml-4 mt-2 icon-include",on:{"click":function($event){return _vm.checkedExcludeTool(
                                                                tt.ToolId
                                                            )}}},[_c('img',{attrs:{"src":require("../../../assets/exclude_black.svg")}})]):_vm._e(),(
                                                            _vm.toolIdsExclude.indexOf(
                                                                tt.ToolId
                                                            ) > -1
                                                        )?_c('span',{staticClass:"pl-4 ml-4 mt-2 icon-include",on:{"click":function($event){return _vm.checkedExcludeTool(
                                                                tt.ToolId
                                                            )}}},[_c('img',{attrs:{"src":require("../../../assets/exclude_red.svg")}})]):_vm._e()],1),_c('b-collapse',{attrs:{"id":'imagedetails-accordion1' +
                                                            index,"accordion":'imagedetails-accordion1' +
                                                            index,"role":"tabpanel"},model:{value:(
                                                        _vm.collapseStatesTools[
                                                            index
                                                        ]
                                                    ),callback:function ($$v) {_vm.$set(_vm.collapseStatesTools, 
                                                            index
                                                        , $$v)},expression:"\n                                                        collapseStatesTools[\n                                                            index\n                                                        ]\n                                                    "}},[_c('b-card-body',{staticClass:"contentCollapse"},[_c('table',{staticClass:"text-card",attrs:{"striped":""}},[_c('tbody',[(
                                                                        tt.UserGroup &&
                                                                            tt
                                                                                .UserGroup
                                                                                .length
                                                                    )?_c('tr',[_c('b-row',{staticClass:"row1"},[(
                                                                                !_vm.searchToolUserGroupNameModel &&
                                                                                !_vm.showSearchtoolsInner
                                                                                    ? true
                                                                                    : false
                                                                            )?_c('img',{staticClass:"pointer ml-2 my-3",attrs:{"src":require("../../../assets/search-outline.svg")},on:{"click":function($event){return _vm.searchOpentoolsInner(
                                                                                    index
                                                                                )},"mouseover":function($event){return _vm.searchOpentoolsInner(
                                                                                    index
                                                                                )}}}):_vm._e(),(
                                                                                !_vm.searchToolUserGroupNameModel &&
                                                                                !_vm.showSearchtoolsInner
                                                                                    ? true
                                                                                    : false
                                                                            )?_c('span',{staticClass:"blue-text ml-2 mb-1 searchPlaceholder",on:{"click":function($event){return _vm.searchOpentoolsInner(
                                                                                    index
                                                                                )},"mouseover":function($event){return _vm.searchOpentoolsInner(
                                                                                    index
                                                                                )}}},[_vm._v("Search")]):_vm._e(),(
                                                                                _vm.searchToolUserGroupNameModel ||
                                                                                _vm.showSearchtoolsInner
                                                                                    ? true
                                                                                    : false
                                                                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                                _vm.searchToolUserGroupNameModel
                                                                            ),expression:"\n                                                                                searchToolUserGroupNameModel\n                                                                            "}],ref:"toolsInner",refInFor:true,staticClass:"width357",class:_vm.searchToolUserGroupNameModel ||
                                                                                _vm.showSearchtoolsInner
                                                                                    ? 'test'
                                                                                    : 'searchinputList',attrs:{"spellcheck":"false","id":"searchFilter","type":"search","placeholder":"Search"},domProps:{"value":(
                                                                                _vm.searchToolUserGroupNameModel
                                                                            )},on:{"input":function($event){if($event.target.composing){ return; }
                                                                                _vm.searchToolUserGroupNameModel
                                                                            =$event.target.value}}}):_vm._e(),(
                                                                                _vm.searchClosetoolsInner
                                                                            )?_c('span',{staticClass:"grey-bg pointer",on:{"click":_vm.closeBartoolsInner}},[_c('img',{attrs:{"src":require("../../../assets/close.svg")}})]):_vm._e()])],1):_vm._e(),_vm._l((_vm.$options.filters.filterByProperty(
                                                                        tt.UserGroup,
                                                                        '',
                                                                        'UserGroupName',
                                                                        _vm.searchToolUserGroupNameModel
                                                                    )),function(t){return _c('tr',{key:t.UserGroupName,attrs:{"value":t.UserGroupName}},[_c('td',{},[(
                                                                                t.IsIncluded &&
                                                                                    _vm.toolUserGroupsExclude[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ].indexOf(
                                                                                        t.UserGroupID
                                                                                    ) ==
                                                                                        -1
                                                                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            ),expression:"\n                                                                                toolUserGroups[\n                                                                                    tt\n                                                                                        .ToolId\n                                                                                ]\n                                                                            "}],staticClass:"slash-checkbox",attrs:{"type":"checkbox"},domProps:{"value":t.UserGroupID,"checked":Array.isArray(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            )?_vm._i(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            ,t.UserGroupID)>-1:(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            )},on:{"click":function($event){return _vm.childCheck(
                                                                                    t.UserGroupID,
                                                                                    tt.ToolId,
                                                                                    $event,
                                                                                    false
                                                                                )},"change":function($event){var $$a=
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=t.UserGroupID,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.toolUserGroups, 
                                                                                    tt
                                                                                        .ToolId
                                                                                , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.toolUserGroups, 
                                                                                    tt
                                                                                        .ToolId
                                                                                , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.toolUserGroups, 
                                                                                    tt
                                                                                        .ToolId
                                                                                , $$c)}}}}):_vm._e(),(
                                                                                _vm.toolUserGroupsExclude[
                                                                                    tt
                                                                                        .ToolId
                                                                                ].indexOf(
                                                                                    t.UserGroupID
                                                                                ) >
                                                                                    -1 ||
                                                                                    !t.IsIncluded
                                                                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            ),expression:"\n                                                                                toolUserGroups[\n                                                                                    tt\n                                                                                        .ToolId\n                                                                                ]\n                                                                            "}],staticClass:"slash-checkbox",attrs:{"type":"checkbox","disabled":_vm.toolUserGroupsExclude[
                                                                                    tt
                                                                                        .ToolId
                                                                                ].indexOf(
                                                                                    t.UserGroupID
                                                                                ) >
                                                                                    -1 &&
                                                                                    !t.IsIncluded},domProps:{"value":t.UserGroupID,"checked":Array.isArray(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            )?_vm._i(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            ,t.UserGroupID)>-1:(
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            )},on:{"click":function($event){return _vm.childCheck(
                                                                                    t.UserGroupID,
                                                                                    tt.ToolId,
                                                                                    $event,
                                                                                    true
                                                                                )},"change":function($event){var $$a=
                                                                                _vm.toolUserGroups[
                                                                                    tt
                                                                                        .ToolId
                                                                                ]
                                                                            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=t.UserGroupID,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.toolUserGroups, 
                                                                                    tt
                                                                                        .ToolId
                                                                                , $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.toolUserGroups, 
                                                                                    tt
                                                                                        .ToolId
                                                                                , $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.toolUserGroups, 
                                                                                    tt
                                                                                        .ToolId
                                                                                , $$c)}}}}):_vm._e(),(
                                                                                t.IsIncluded &&
                                                                                    _vm.toolUserGroupsExclude[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ].indexOf(
                                                                                        t.UserGroupID
                                                                                    ) ==
                                                                                        -1
                                                                            )?_c('span',{staticClass:"width68 pl-4 ml-1 mt-1 slash-checkbox1"},[_c('img',{attrs:{"src":require("../../../assets/exclude_black.svg")}})]):_vm._e(),(
                                                                                !t.IsIncluded ||
                                                                                    _vm.toolUserGroupsExclude[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ].indexOf(
                                                                                        t.UserGroupID
                                                                                    ) >
                                                                                        -1
                                                                            )?_c('span',[(
                                                                                    !t.IsIncluded &&
                                                                                        _vm.toolUserGroupsExclude[
                                                                                            tt
                                                                                                .ToolId
                                                                                        ].indexOf(
                                                                                            t.UserGroupID
                                                                                        ) >
                                                                                            -1
                                                                                )?_c('span',{staticClass:"width68 pl-4 ml-1 mt-1",on:{"click":function($event){return _vm.childExcludeCheck(
                                                                                        t.UserGroupID,
                                                                                        tt.ToolId,
                                                                                        'Tools',
                                                                                        t
                                                                                    )}}},[_c('img',{attrs:{"src":require("../../../assets/exclude_red.svg")}})]):_vm._e(),(
                                                                                    t.IsIncluded &&
                                                                                        _vm.toolUserGroupsExclude[
                                                                                            tt
                                                                                                .ToolId
                                                                                        ].indexOf(
                                                                                            t.UserGroupID
                                                                                        ) >
                                                                                            -1
                                                                                )?_c('span',{staticClass:"width68 pl-4 mt-1",on:{"click":function($event){return _vm.childExcludeCheck(
                                                                                        t.UserGroupID,
                                                                                        tt.ToolId,
                                                                                        'Tools',
                                                                                        t
                                                                                    )}}},[_c('img',{attrs:{"src":require("../../../assets/exclude_black.svg")}})]):_vm._e()]):_vm._e(),_c('span',{staticClass:"slash-text"},[_vm._v(_vm._s(t.UserGroupName))])])])})],2)])])],1)],1)],1)])}),0)])])],1)]),(_vm.pageOfGroupTools.length > 0)?_c('div',{staticClass:"card-footer"},[_c('b-pagination',{attrs:{"total-rows":_vm.rowsTools,"per-page":_vm.perPage,"aria-controls":"ToolsTable"},on:{"change":_vm.collapseAllTools},model:{value:(_vm.currentPageTools),callback:function ($$v) {_vm.currentPageTools=$$v},expression:"currentPageTools"}})],1):_vm._e()])])],1),_c('b-modal',{ref:"dataset-modal",staticClass:"model_style",attrs:{"hide-header":"","hide-footer":"","centered":"","size":"lg"}},[(!_vm.reviewItem.length)?_c('div',{staticClass:"d-block text-center"},[_c('div',{staticClass:"text-right cursor-pointer"},[_c('img',{attrs:{"src":require("../../../assets/close_icon.svg")},on:{"click":_vm.hideModal}})]),_vm._v(" There is no item to review. ")]):_vm._e(),(_vm.reviewItem.length)?_c('div',{staticClass:"d-block"},[_c('div',{staticClass:"text-right cursor-pointer"},[_c('img',{attrs:{"src":require("../../../assets/close_icon.svg")},on:{"click":_vm.hideModal}})]),_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"my-2"},[_vm._v("Change queue")]),_c('small',[_vm._v("Review "+_vm._s(_vm.reviewItem.length)+" changes pending before commiting the changes.")])]),_c('div',{staticClass:"my-3 tblScroll"},[_c('table',{staticClass:"table table-striped dataSetModal"},[_c('tbody',[_c('tr',[_c('th'),_c('th',[_vm._v("Access")]),_c('th'),_c('th')]),_vm._l((_vm.reviewItem),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.groupName))]),_c('td',[(!item.isIncluded && item.isActive)?_c('span',[_c('img',{attrs:{"src":require("../../../assets/exclude_red.svg")}})]):(
                                        item.isActive && item.isIncluded
                                    )?_c('span',[_c('img',{attrs:{"src":require("../../../assets/icon_tick.svg")}})]):_c('span',[(!item.isActive)?_c('span',[_c('img',{attrs:{"src":require("../../../assets/close.svg")}})]):_vm._e(),(
                                            item.isIncluded &&
                                                item.hasOwnProperty(
                                                    _vm.isIncluded
                                                )
                                        )?_c('span',[_c('img',{attrs:{"src":require("../../../assets/exclude_black.svg")}})]):_vm._e()])]),_c('td',[_vm._v(_vm._s(item.toolName))]),_c('td',{staticClass:"deleteCell",on:{"click":function($event){return _vm.delQueue(
                                        index,
                                        item.UserGroupID,
                                        item.ToolId
                                    )}}},[_vm._v(" Delete from queue  "),_c('img',{attrs:{"src":require("../../../assets/close.svg")}})])])})],2)])]),_c('div',{staticClass:"mt-3 mb-4 text-center"},[_c('a',{staticClass:"text-primary",on:{"click":_vm.clearQueue}},[_vm._v("Clear queue ")]),_c('button',{staticClass:"buttonSelect ml-3",on:{"click":_vm.commitChanges}},[_vm._v(" Commit changes ")])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }