<template>
    <div>
        <b-container fluid class="p-0">
            <Header v-bind:showVersionToggle="false" />
            <b-container
                class="bv-example-row dashboard"
                @permissionAuth="showPortalDash"
            >
                <b-row>
                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="accessManagement"
                    >
                        <b-overlay class="w-100">
                            <button
                                id="accessManage"
                                class="text-center w-100"
                                @click="navigateAccessManagement()"
                            >
                                <img
                                    v-bind="accessmanagement"
                                    alt="Access Management"
                                    class="dashboardIcon"
                                    src="../assets/access_management.svg"
                                />
                                <h5>Access management</h5>
                            </button>
                        </b-overlay>
                    </b-col>
                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="dataset"
                    >
                        <b-overlay class="w-100">
                            <button
                                id="datasetDef"
                                class="text-center w-100"
                                @click="navigateDataSetDefinition"
                            >
                                <img
                                    v-bind="datasetdefination"
                                    alt="Dataset"
                                    class="dashboardIcon"
                                    src="../assets/dataset.svg"
                                />
                                <h5>Datasets</h5>
                            </button>
                        </b-overlay>
                    </b-col>
                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="mngDataSource"
                    >
                        <b-overlay class="w-100">
                            <button
                                id="dataSource"
                                class="text-center w-100"
                                @click="managedatasource"
                            >
                                <img
                                    v-bind="configuremap"
                                    alt="Datasource"
                                    class="dashboardIcon"
                                    src="../assets/datasource.svg"
                                />
                                <h5>Datasources</h5>
                            </button>
                        </b-overlay>
                    </b-col>
                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="navProject"
                    >
                        <b-overlay class="w-100">
                            <button
                                id="selectproject"
                                class="text-center w-100"
                                @click="navigateProject"
                            >
                                <img
                                    v-bind="contacttool"
                                    alt="Project"
                                    class="dashboardIcon"
                                    src="../assets/project.svg"
                                />
                                <h5>Projects</h5>
                            </button>
                        </b-overlay>
                    </b-col>
                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="navProjectList"
                    >
                        <b-overlay class="w-100">
                            <button
                                class="text-center w-100"
                                @click="navigateProjectList"
                            >
                                <img
                                    v-bind="datasourcedefination"
                                    alt="Project management"
                                    class="dashboardIcon"
                                    src="../assets/project_management.svg"
                                />
                                <h5>Project management</h5>
                            </button>
                        </b-overlay>
                    </b-col>
                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="navUsers"
                    >
                        <b-overlay class="w-100">
                            <button
                                id="contactTool"
                                class="text-center w-100"
                                @click="navigateUsers"
                            >
                                <img
                                    v-bind="contacttool"
                                    alt="User"
                                    class="dashboardIcon"
                                    src="../assets/users.svg"
                                />
                                <h5>Users</h5>
                            </button>
                        </b-overlay>
                    </b-col>

                    <b-col
                        class="col-sm-6 col-md-4 col-lg-3 d-flex justify-content-center"
                        v-if="naveTracker"
                    >
                        <b-overlay class="w-100">
                            <button
                                id="eTrackerButtonId"
                                class="text-center w-100"
                                @click="navigateeTracker"
                            >
                                <img
                                    v-bind="etracker"
                                    alt="eTracker"
                                    class="dashboardIcon"
                                    src="../assets/eTracker.svg"
                                />
                                <h5>eTracker</h5>
                            </button>
                        </b-overlay>
                    </b-col>
                </b-row>
            </b-container>
            <AccessPermissionModal ref="permission-modal" />
            <Footer />
        </b-container>
    </div>
</template>

<script>
import Header from '../components/Header';
import Footer from '../components/Footer';
import router from './../router';
// import {  mapActions } from "vuex";
import { mapActions, mapState, mapMutations } from 'vuex';
import AccessPermissionModal from '../components/AccessPermissionModal';
// import userrightmanagement from '../components/AccessManagement/UserRightManagement'
import { commonService } from '../services/common.service';
import { userService } from '../services';
// import { store } from '../store/store'

export default {
    name: 'dashboard',
    components: {
        Header,
        Footer,
        AccessPermissionModal,
    },
    data() {
        return {
            selectproject: {
                blank: true,
                blankColor: '#3296CB',
                width: 92,
                height: 92,
                class: 'm1',
            },
            contacttool: {
                blank: true,
                blankColor: '#418AC6',
                width: 92,
                height: 92,
                class: 'm1',
            },
            configuremap: {
                blank: true,
                blankColor: '#547BBF',
                width: 92,
                height: 92,
                class: 'm1',
            },
            datasetdefination: {
                blank: true,
                blankColor: '#666DB9',
                width: 92,
                height: 92,
                class: 'm1',
            },
            datasourcedefination: {
                blank: true,
                blankColor: '#7D5BB1',
                width: 92,
                height: 92,
                class: 'm1',
            },
            projectmanagement: {
                blank: true,
                blankColor: '#9E42A8',
                width: 92,
                height: 92,
                class: 'm1',
            },
            tableselecttool: {
                blank: true,
                blankColor: '#B033A1',
                width: 92,
                height: 92,
                class: 'm1',
            },
            accessmanagement: {
                blank: true,
                blankColor: '#B033A1',
                width: 92,
                height: 92,
                class: 'm1',
            },
            etracker: {
                blank: true,
                blankColor: '#B033A1',
                width: 92,
                height: 92,
                class: 'm1',
            },

            showCard: null,
            accessManagement: null,
            dataset: null,
            mngDataSource: null,
            navProject: null,
            navProjectList: null,
            navUsers: null,
            sPage: true,
            isAdmin: null,
            naveTracker: null,
        };
    },
    created() {
        // this.cityList();
        // this.stateList();
        localStorage.removeItem('aggregateOption');
        localStorage.removeItem('postingStatus');
        userService.getUserUiVersion().then((res) => {
            this.setUserVersion(res.data);
        });
        let redirectPath = this.$store.state.store.goToPage;
        if (redirectPath !== null) {
            this.$store.commit('store/clearGoToPage');
            router.push(redirectPath);
        }
    },
    async mounted() {
        // const showEmmitted = this.$root.$on('permissionAuth', showpage => {
        //   return showpage
        // });
        //
        this.getPermissions();
        this.getPortalSettings();
        this.pageP();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
            getPortalSettings: 'getPortalSettings',
        }),
        ...mapActions('users', {
            remove: 'deleteUser',
            removeSingleUser: 'removeSingleUser',
        }),
        ...mapMutations('store', ['setUserVersion']),
        hidePermissionModal() {
            router.push(`/dashboard`);
            this.$refs['permission-modal'].hide();
        },

        showPortalDash(value) {
            if (!value) {
                this.$refs['permission-modal'].showPermissionModal();
            }
        },

        pageP() {
            commonService
                .getPermissions()
                .then((response) => {
                    this.showCard = response.data.AccessPagesInfoLst;
                    if (this.showCard) {
                        // let vm = this;
                        this.showCard.forEach((element) => {
                            if (
                                element.PageName.toLowerCase().includes(
                                    'Access management'.toLowerCase()
                                )
                            ) {
                                this.accessManagement = true;
                            }
                            if (
                                element.PageName.toLowerCase() ==
                                'Dataset management'.toLowerCase()
                            ) {
                                this.dataset = true;
                            }
                            if (
                                element.PageName.toLowerCase().includes(
                                    'DataSource management'.toLowerCase()
                                )
                            ) {
                                this.mngDataSource = true;
                            }
                            if (
                                element.PageName.toLowerCase().includes(
                                    'Project Management'.toLowerCase()
                                )
                            ) {
                                this.navProjectList = true;
                            }
                            if (
                                element.PageName.toLowerCase().includes(
                                    'Project selection'.toLowerCase()
                                )
                            ) {
                                this.navProject = true;
                            }
                            if (
                                element.PageName.toLowerCase().includes(
                                    'User Management'.toLowerCase()
                                )
                            ) {
                                this.navUsers = true;
                            }
                            if (
                                element.PageName.toLowerCase().includes(
                                    'eTracker'.toLowerCase()
                                )
                            ) {
                                this.naveTracker = true;
                            }
                        });
                        this.isAdmin = this.showCard.some(
                            (e) => e.PageType === 'Admin'
                        );
                        if (!this.isAdmin) {
                            this.navigateProject();
                        }
                    }
                })
                .catch(function(error) {});
        },
        showPermissionModalDash() {
            this.$refs['permission-modal'].showPermissionModal();
        },
        navigateProject: function() {
            if (this.userVersion == '1') {
                var screenWidth = window.innerWidth;
                if (screenWidth < 1100) {
                    router.push(`/classic/project/listview`);
                } else {
                    router.push(`/classic/project/mapview`);
                }
            } else {
                router.push(`/map`);
            }
        },
        navigateUsers() {
            router.push(`/users`);
        },
        navigateDataSetDefinition() {
            router.push(`/datasetdefinition/selectdatasources`);
        },
        navigateProjectList() {
            router.push(`/projectlist`);
        },
        navigateAccessManagement() {
            router.push(`/userrightmanagement`);
        },
        managedatasource() {
            router.push(`/managedatasource`);
        },
        navigateClassicDashboard() {
            router.push(`/dashboard`);
        },
        navigateeTracker() {
            router.push('/eTracker');
        },
    },
    computed: {
        ...mapState('store', ['userVersion']),
    },
};
</script>

<style scoped>
.card {
    min-height: 293px;
}

.card_bg {
    background-color: #f7f7f7 !important;
    border: 0 !important;
    border-radius: 0 !important;
}
.card-body {
    border: 1px dashed #e4e4e4;
}

.buttonSelect {
    background-color: #006395;
    padding: 10px 18px;
    border: 0px !important;
}

h5 {
    color: #757678;
    font-weight: normal;
    font-size: 18px;
}

.bar-image {
    width: 35px;
    position: absolute;
    left: 43%;
}

.dash-bg .bg-head {
    background-color: #1d1d1d !important;
}

.dashboard {
    margin-top: 2rem;
}

.dashboard .dashboardIcon {
    margin-top: 2.5rem;
}

.dashboard h5 {
    margin-top: 2.7rem;
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
}
</style>
