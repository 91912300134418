<template>
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="list-view" v-else>
        <b-container fluid class="listview sub_header_bg">
            <b-container class="p-0">
                <b-row class="mb-3">
                    <b-col cols="12 my-1">
                        <a @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a href="#" class="floar-left ml-1 back"
                                >Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="12" sm="6" md="6" lg="10" class="m-p-0">
                        <h1 class="">Projects</h1>
                    </b-col>

                    <b-col cols="5" sm="3" md="3" lg="1" class="button-clear">
                        <b-button
                            v-if="initialMapIndex && initialMapIndex.length"
                            class="m-0  text-primary"
                            @click="clearSelection"
                            variant="light"
                        >
                            Clear selection </b-button
                        ><b-button
                            v-else
                            class="m-0  subheader-smalltext"
                            @click="clearSelection"
                            variant="light"
                        >
                            Clear selection
                        </b-button>
                    </b-col>
                    <b-col
                        cols="5"
                        sm="3"
                        md="3"
                        lg="1"
                        class="text-right m-right"
                        :to="{ path: '/filteroptions' }"
                    >
                        <b-button
                            rounded
                            class="buttonSelect"
                            @click="checkProjectType"
                            v-if="showOpenProject"
                            :disabled="!initialMapIndex.length"
                        >
                            Open projects
                        </b-button>
                    </b-col>
                </b-row>
                <b-modal
                    id="projects-modal"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideReviewModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select only one project to proceed.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in selectedProjectsArr"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="projects.ProjectID"
                                                v-model="selectedRadio"
                                            >
                                            </b-form-radio>
                                            <span>{{
                                                projects.ProjectName
                                            }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a class="text-primary" @click="hideReviewModal"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="selectedRadio <= 0"
                                @click="navigateProjectSelection"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
            </b-container>
        </b-container>

        <b-container class="p-0 list-container">
            <div v-if="!isMobile" class="filter border-bottom py-3 mb-3">
                <b-col cols="1" class="text-right icons p-0 clearfix icon-view">
                    <div class="map-v border-right">
                        <button class="map-icon-btn" @click="navigateMapView">
                            <img
                                src="../../assets/map_view_grey.svg"
                                v-b-tooltip.hover
                                title="Map view"
                                f
                                class="px-2"
                            />
                        </button>
                    </div>
                </b-col>
            </div>
            <section id="filter-popup">
                <b-row class="m-0">
                    <b-col cols="12" class="border-bottom">
                        <b-row class="m-0 pb-3 my-1">
                            <input
                                spellcheck="false"
                                id="searchFilter"
                                class=""
                                v-if="
                                    searchQuery || showSearchIcon ? true : false
                                "
                                v-model="searchQuery"
                                @click="searchinputenter()"
                                @input="searchinputenter"
                                :class="
                                    filter || showSearchIcon
                                        ? 'test'
                                        : 'searchinputList'
                                "
                                type="search"
                                placeholder="Search for projects"
                            />
                            <span
                                class="grey-bg pointer"
                                v-if="searchClose"
                                @click="closeBar"
                                ><img src="../../assets/close.svg"
                            /></span>
                            <b-col cols="0" class="noPadding mt-2">
                                <img
                                    class="pointer ml-2"
                                    src="../../assets/search-outline.svg"
                                    v-if="
                                        !searchQuery && !showSearchIcon
                                            ? true
                                            : false
                                    "
                                    @click="searchOpen"
                                />
                                <span class="border-right list ml-2"
                                    >&nbsp;</span
                                >
                                <!-- <b-icon icon="filter" font-scale="1.2" class="ml-3"></b-icon> -->
                            </b-col>
                            <b-col
                                cols="2"
                                sm="2"
                                md="2"
                                lg="1"
                                class="filterDrop p-0 text-left m-l-15 col-sm-2 col-md-2 col-lg-1 mt-2"
                            >
                                <a
                                    href="#"
                                    class="pl-2 ml-2 pr-0"
                                    @click="filter"
                                    ><img src="../../assets/filter1.svg"
                                /></a>
                                <span class="blue-text pl-2" @click="filter"
                                    >Filter</span
                                >

                                <b-list-group-item
                                    class="filter-group left-align p-0 w-220"
                                    v-if="filterShow"
                                >
                                    <div class="ml-3">
                                        <div class="mb-1 float-left filter-by">
                                            Filter by
                                        </div>
                                        <div class="text-right">
                                            <a href="javascript:void(0)">
                                                <b-icon
                                                    icon="x"
                                                    font-scale="2"
                                                    @click="closeFilter"
                                                >
                                                </b-icon>
                                            </a>
                                        </div>
                                        <div class="mt-3 filter-select">
                                            <b-form-select
                                                v-model="countryId"
                                                @change="stateByCountry()"
                                                id="slectListCountry"
                                            >
                                                <option disabled value=""
                                                    >Country</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in countries"
                                                    :key="index"
                                                    v-bind:value="
                                                        item.CountryId
                                                    "
                                                >
                                                    {{ item.CountryName }}
                                                </option>
                                            </b-form-select>
                                        </div>
                                        <div>
                                            <b-form-select
                                                v-model="stateId"
                                                @change="cityByState()"
                                                id="selectListState"
                                            >
                                                <option disabled value=""
                                                    >State/Province</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in states"
                                                    :key="index"
                                                    v-bind:value="item.StateId"
                                                >
                                                    {{ item.StateName }}
                                                </option>
                                            </b-form-select>
                                        </div>
                                        <div>
                                            <b-form-select
                                                v-model="cityName"
                                                id="selectListCity"
                                            >
                                                <option disabled value=""
                                                    >City</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in cities"
                                                    :key="index"
                                                    v-bind:value="item.CityName"
                                                >
                                                    {{ item.CityName }}
                                                </option>
                                            </b-form-select>
                                        </div>
                                        <div>
                                            <b-form-select
                                                v-model="projectName"
                                                id="selectListProject"
                                            >
                                                <option disabled value=""
                                                    >Project Name</option
                                                >
                                                <option
                                                    v-for="(projects,
                                                    index) in projects"
                                                    :key="index"
                                                    v-bind:value="
                                                        projects.ProjectName
                                                    "
                                                >
                                                    {{ projects.ProjectName }}
                                                </option>
                                            </b-form-select>
                                        </div>
                                        <b-button
                                            id="applyView"
                                            class="mb-3"
                                            @click="applyFilter"
                                            >Apply filters</b-button
                                        >
                                    </div>
                                </b-list-group-item>
                            </b-col>
                            <b-col
                                cols="3"
                                class="pl-4  malfont"
                                v-if="applyfilter && !filterShow"
                            >
                                <b-row>
                                    <div v-if="countryName">
                                        <span
                                            class="filteredItem px-3 py-1 mr-2 text-center"
                                        >
                                            {{ countryName }}
                                            <b-icon
                                                icon="x"
                                                font-scale="1"
                                                @click="backFilter('country')"
                                            ></b-icon>
                                        </span>
                                    </div>
                                    <div v-if="stateName">
                                        <span
                                            class="filteredItem px-3 py-1 mr-2 text-center"
                                        >
                                            {{ stateName }}
                                            <b-icon
                                                icon="x"
                                                font-scale="1"
                                                @click="backFilter('state')"
                                            ></b-icon>
                                        </span>
                                    </div>
                                    <div v-if="cityName">
                                        <span
                                            class="filteredItem px-3 py-1 mr-2 text-center"
                                        >
                                            {{ cityName }}
                                            <b-icon
                                                icon="x"
                                                font-scale="1"
                                                @click="backFilter('city')"
                                            ></b-icon>
                                        </span>
                                    </div>
                                    <div v-if="projectName">
                                        <span
                                            class="filteredItem px-3 py-1 mr-2 text-center"
                                        >
                                            {{ projectName }}
                                            <b-icon
                                                icon="x"
                                                font-scale="1"
                                                @click="backFilter('project')"
                                            ></b-icon>
                                        </span>
                                    </div>
                                    <div
                                        class="removeFilter ml-2 "
                                        v-if="
                                            applyfilter &&
                                                !filterShow &&
                                                (countryName ||
                                                    stateName ||
                                                    cityName ||
                                                    projectName)
                                        "
                                    >
                                        <div
                                            @click="removeFilter"
                                            class="pointer link-blue"
                                        >
                                            Remove all filters
                                        </div>
                                    </div>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </section>
            <section id="project-list">
                <b-row class="m-0">
                    <b-col cols="12" class="p-0">
                        <b-list-group class="li-view">
                            <span v-if="projects">
                                <b-list-group-item
                                    class="p-0 project-list-item rel-position"
                                    v-for="(projects, index) in applyfilter
                                        ? projectsSource
                                        : filteredItems"
                                    :key="index"
                                    @click="
                                        currentSite(
                                            projects.ProjectID,
                                            projects
                                        )
                                    "
                                    v-bind:class="{
                                        'list-group-item-secondary border-dark': initialMapIndex.includes(
                                            projects.ProjectID
                                        ),
                                    }"
                                >
                                    <span
                                        class="float-right activeCheck"
                                        v-if="
                                            initialMapIndex.includes(
                                                projects.ProjectID
                                            )
                                        "
                                    >
                                        <img
                                            class="check-mark"
                                            src="../../assets/site_tick.svg"
                                        />
                                    </span>
                                    <h5 :id="'listProjectName' + index">
                                        {{ projects.ProjectName
                                        }}<span class="location">
                                            {{ projects.City }},
                                            {{ projects.StateName }},
                                            {{ projects.CountryName }}</span
                                        >
                                    </h5>
                                    <!-- <p class="list-subhead">
                {{ projects.City }}, {{ projects.State.StateName }},
                {{ projects.Country.CountryName }}
              </p> -->
                                    <div class="clearfix"></div>
                                    <p class="">
                                        {{ projects.Comments }}
                                    </p>
                                </b-list-group-item>
                            </span>
                        </b-list-group>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import router from '../../../router';
import { mapState, mapActions } from 'vuex';
import { projectService } from '../../services/project.service';
import AccessPermissionModal from '../AccessPermissionModal';
import { commonService } from '../../services/common.service';
export default {
    data() {
        return {
            initialMapIndex: [],
            filterShow: false,
            applyfilter: false,
            countries: null,
            states: null,
            countryName: '',
            countryId: '',
            stateName: '',
            stateId: '',
            cities: null,
            cityName: '',
            projectName: '',
            showPage: true,
            projectsSource: [],
            searchQuery: null,
            searchClose: false,
            showOpenProject: false,
            showSearchIcon: false,
            projLocations: [],
            currentSiteID: null,
            selectedRadio: 0,
        };
    },
    components: {
        AccessPermissionModal,
    },
    computed: {
        ...mapState({
            projects: function(state) {
                const data = state.projects.selectedProjects;

                data.forEach((element) => {
                    if (!this.initialMapIndex.includes(element.ProjectID)) {
                        this.initialMapIndex.push(element.ProjectID);
                    }
                });
                return state.projects.projectsWithGeo.items;
            },
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
        isMobile() {
            return window.innerWidth <= 768;
        },
        filteredItems() {
            if (this.searchQuery) {
                return this.projects.filter((item) => {
                    return (
                        item.ProjectName.toLowerCase().indexOf(
                            this.searchQuery.toLowerCase()
                        ) > -1
                    );
                });
            } else {
                return this.projects;
            }
        },
    },
    created() {
        this.getAllProjects();
        this.countryList();
    },
    mounted() {
        commonService.initializeReportingService();
        this.selectedIndex = localStorage.getItem('scrollIndex');
        this.pagePermit();
        this.selectedProjectsArr.forEach((project) => {
            this.initialMapIndex.push(project.ProjectID);
            this.projLocations.push({
                siteid: project.ProjectID,
                coord: [project.Latitude, project.Longitude],
                ProjectName: project.ProjectName,
                City: project.City,
                State: project.StateName,
                Country: project.CountryName,
            });
        });
        this.resetStore();
    },

    methods: {
        ...mapActions('projects', {
            getAllProjects: 'getAllWithGeo',
            selectProjects: 'selectProjects',
            deselectProject: 'deselectProject',
            clearSelectedProjects: 'clearSelectedProjects',
        }),
        ...mapActions('emergencyManagement', ['resetStore']),
        countryList() {
            commonService
                .getCountries()
                .then((res) => (this.countries = res.data));
        },
        pagePermit() {
            setTimeout(() => {
                var element = this.$el.querySelector(
                    '#listProjectName' + this.selectedIndex
                );
                if (element !== null) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }, 1000);
            this.showPage = true;
            this.showOpenProject = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('ListView')) {
                    vm.showPage = true;
                } else if (element.PageName.includes('SelectedProjects')) {
                    vm.showOpenProject = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        stateByCountry() {
            let country = this.countries.find(
                (e) => e.CountryId === this.countryId
            );
            this.countryName = country.CountryName;

            commonService
                .getStates(this.countryId)
                .then((res) => (this.states = res.data));
        },
        cityByState() {
            let state = this.states.find((e) => e.StateId === this.stateId);
            this.stateName = state.StateName;
            commonService
                .getCities(this.stateId)
                .then((res) => (this.cities = res.data));
        },
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        navigateMapView: function() {
            this.clearSelectedProjects();
            router.push(`/classic/project/mapview`);
        },
        clearSelection() {
            this.clearSelectedProjects();
            this.initialMapIndex = [];
        },
        checkProjectType() {
            localStorage.setItem('loading', true);
            localStorage.setItem('scrollIndex', this.selectedIndex);
            let selectedProjects = this.$store.state.projects.selectedProjects;
            if (
                selectedProjects.length == 1 &&
                !['Emergency management', 'Emergency response'].includes(
                    selectedProjects[0].ProjectTypeName
                )
            ) {
                let defaultBaseMap = selectedProjects[0].DefaultBaseMapName;
                if (defaultBaseMap == null) {
                    defaultBaseMap = 'topo';
                }
                let payload = 'topo';
                if (defaultBaseMap.toLowerCase().includes('topo')) {
                    payload = 'topo';
                } else if (defaultBaseMap.toLowerCase().includes('imagery')) {
                    payload = 'imagery';
                } else if (defaultBaseMap.toLowerCase().includes('open')) {
                    payload = 'open';
                } else if (defaultBaseMap.toLowerCase().includes('nomap')) {
                    payload = 'none';
                }
                this.$store.commit('projects/updateBaseMap', payload);
                this.checkIfNeedToClearStoredProjects();
            } else if (
                selectedProjects.length == 1 &&
                ['Emergency management', 'Emergency response'].includes(
                    selectedProjects[0].ProjectTypeName
                )
            ) {
                this.resetStore();
                if (window.innerWidth > 768) {
                    router.push(`/classic/MainMap`);
                } else {
                    router.push('/classic/MobileMap');
                }
            } else if (selectedProjects.length > 1) {
                let defaultBaseMapArr = [];
                let EMyes = false;
                selectedProjects.forEach((proj) => {
                    defaultBaseMapArr.push(proj.DefaultBaseMapName);
                    if (
                        ['Emergency management', 'Emergency response'].includes(
                            proj.ProjectTypeName
                        )
                    ) {
                        EMyes = true;
                    }
                });
                let uniqueBaseMaps = [...new Set(defaultBaseMapArr)];
                if (uniqueBaseMaps.length == 1) {
                    let defaultBaseMap = uniqueBaseMaps[0];
                    if (defaultBaseMap == null) {
                        defaultBaseMap = 'topo';
                    }
                    let payload = 'topo';
                    if (defaultBaseMap.toLowerCase().includes('topo')) {
                        payload = 'topo';
                    } else if (
                        defaultBaseMap.toLowerCase().includes('imagery')
                    ) {
                        payload = 'imagery';
                    } else if (defaultBaseMap.toLowerCase().includes('nomap')) {
                        payload = 'none';
                    }
                    this.$store.commit('projects/updateBaseMap', payload);
                } else {
                    this.$store.commit('projects/updateBaseMap', 'topo');
                }
                if (EMyes) {
                    this.$bvModal.show('projects-modal');
                } else {
                    this.checkIfNeedToClearStoredProjects();
                    router.push(`/classic/project/selectedprojects`);
                }
            }
        },
        navigateProjectSelection() {
            let selectedProjects = this.$store.state.projects.selectedProjects;
            let ProjID = this.selectedRadio;
            localStorage.setItem('loading', true);
            let userSelectedProject = selectedProjects.filter((proj) => {
                return proj.ProjectID == ProjID;
            });
            if (
                !['Emergency management', 'Emergency response'].includes(
                    userSelectedProject[0].ProjectTypeName
                )
            ) {
                this.checkIfNeedToClearStoredProjects();
                // keep this if we do want to select multiple CAR projects
                this.clearSelectedEMProjects();
                // this.clearSelectedProjects();
                this.setSelectedProjects(userSelectedProject[0]);
                router.push(`/classic/project/selectedprojects`);
            } else if (
                ['Emergency management', 'Emergency response'].includes(
                    userSelectedProject[0].ProjectTypeName
                )
            ) {
                this.clearSelectedProjects();
                this.setSelectedProjects(userSelectedProject[0]);
                this.resetStore();
                router.push(`/classic/MainMap`);
            }
        },
        checkIfNeedToClearStoredProjects() {
            //get project ID lists from current selected projects in mapview, and projects that are posted to main map
            let currentSelectedProjects = this.$store.state.projects.selectedProjects.map(
                (x) => x.ProjectID
            );
            let inMapProjects = this.$store.state.projects.mapData.inMapProjects.map(
                (x) => x.ProjectID
            );
            //compare these two lists, and notify user if a inMapProject is no longer in selected projects list
            let postedLayers = this.$store.state.projects.mapData.geojsonLayers
                .posted;
            let projectRemoval = false;
            inMapProjects.forEach((project) => {
                if (!currentSelectedProjects.includes(project)) {
                    projectRemoval = true;
                    if (postedLayers.length == 0) {
                        this.$store.state.projectLayers.projects.splice(
                            this.$store.state.projectLayers.projects.findIndex(
                                (x) => x.ProjectID == project.ProjectID
                            ),
                            1
                        );
                    }
                }
            });
            if (projectRemoval && postedLayers.length > 0) {
                this.$refs['message-modal'].openModal();
            } else {
                localStorage.setItem('loading', true);
                this.$store.commit('projects/addProjectToInMapProjects');
                router.push(`/classic/project/selectedprojects`);
            }
        },
        currentSite(clickedProjectID, project) {
            projectService.getProjectLogo(project.ProjectID).then((res) => {
                project.Logo = res.data;
                if (
                    this.projLocations
                        .map((p) => p.siteid)
                        .includes(clickedProjectID)
                ) {
                    // this.clearSelectedProjects();
                    this.deselectProject(clickedProjectID);
                    this.initialMapIndex.splice(
                        this.initialMapIndex.indexOf(clickedProjectID),
                        1
                    );
                    this.currentSiteID = null;
                    for (var i = 0; i < this.projLocations.length; i++) {
                        if (this.projLocations[i].siteid == clickedProjectID) {
                            this.projLocations.splice(i, 1);
                        }
                    }
                } else {
                    this.selectProjects(project);
                    this.zoom = 15;
                    this.currentSiteID = clickedProjectID;
                    this.projLocations.push({
                        siteid: clickedProjectID,
                        coord: [project.Latitude, project.Longitude],
                        ProjectName: project.ProjectName,
                        City: project.City,
                        State: project.StateName,
                        Country: project.CountryName,
                    });
                    this.initialMapIndex.push(clickedProjectID);
                }
            });
        },
        filter() {
            this.filterShow = true;
            this.projectList = false;
            document.querySelector('#outersearchinput .e-input').style.display =
                'none';
            document.querySelector('#outersearchinput').style.width = '20px';
            document.querySelector('#outersearchinput').style.borderColor =
                'transparent';
        },
        applyFilter() {
            this.projectsSource = this.projects;
            if (this.countryName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.Country.CountryName === this.countryName
                );
            }

            if (this.stateName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.StateName === this.stateName
                );
            }
            if (this.cityName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.City === this.cityName
                );
            }
            if (this.projectName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.ProjectName === this.projectName
                );
            }

            this.applyfilter = true;
            this.filterShow = false;
        },
        removeFilter() {
            this.applyfilter = false;
            this.countryName = '';
            this.stateName = '';
            this.cityName = '';
            this.projectName = '';
            this.stateId = '';
            this.countryId = '';
            this.states = [];
            this.cities = [];
        },
        backFilter(item) {
            if (item === 'country') {
                this.countryName = '';
                this.countryId = '';
            }
            if (item === 'project') {
                this.projectName = '';
            }
            if (item === 'state') {
                this.stateName = '';
                this.stateId = '';
                if (this.countryName == '') {
                    this.states = [];
                }
                if (this.cityName == '') {
                    this.cities = [];
                }
            }
            if (item === 'city') {
                this.cityName = '';
                if (this.stateId == '') {
                    this.cities = [];
                }
            }
            if (
                !this.stateName &&
                !this.cityName &&
                !this.projectName &&
                !this.countryName
            ) {
                this.applyfilter = false;
                this.countryName = '';
                this.stateName = '';
                this.cityName = '';
                this.projectName = '';
                this.stateId = '';
                this.countryId = '';
            }
            this.filterShow = false;
            this.applyFilter();
            //this.projectList = true;
        },
        closeFilter() {
            setTimeout(() => {
                this.filterShow = false;
            });
        },
        searchiconclick() {
            document.querySelector('#outersearchinput').style.width = '300px';
            document.querySelector('#outersearchinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outersearchinput .e-input').style.display =
                'block';
            this.closeFilter();
        },
        hideReviewModal() {
            this.$bvModal.hide('projects-modal');
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.usersSearch.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.searchQuery = null;
        },
        searchinputenter(args) {
            if (args) {
                this.showSearchIcon = false;
                if (!this.searchQuery) {
                    this.searchClose = false;
                }
            }
        },
    },
};
</script>

<style scoped>
.buttonSelect {
    background-color: #006395;
    padding: 11px 10px;
    color: white;
    min-width: 100px !important;
    /* margin-right: 15px; */
}

h5 {
    font-size: 22px;
    color: #000000de;
}

p {
    font-size: 16px;
    color: #1d1d1d;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
    position: relative;
    left: 220px;
    top: -22px;
}

.list-group-item {
    padding: 8px 10px !important;
    position: absolute;
    /* top: 60px; */
    cursor: pointer;
}

.list-view {
    display: flex;
    flex-direction: column;
    height: 90vh;
}

#project-list {
    height: 85%;
    overflow-y: auto;
}

.list-group-item:first-child {
    border-radius: 0px !important;
}

.form-control {
    display: block;
    width: 50% !important;
    height: calc(1em + 0.75rem + 2px);

    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5;
    color: #000000 !important;
    background-color: #ced4da !important;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}

.filteredItem {
    background-color: #eaeaea;
    width: auto;
    padding: 5px 10px !important;
    margin-right: 4px;
    color: #1d1d1d;
    font-weight: bold;
    font-size: 14px;
}

.removeFilter a {
    color: #006395 !important;
}

#outersearchinput .e-input {
    padding: 0 !important;
}

.filterDrop .filter-group {
    width: 279px !important;
    height: 407px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
}

.filterDrop .filter-group .custom-select {
    width: 226px !important;
    height: 48px;
}

.filter-by {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    padding: 20px 0;
}

#mapApply,
#applyView {
    height: 48px;
    width: 123px;
}

input.test {
    width: 464px !important;
    margin: 0 !important;
}

.list-group-item:hover,
.list-group-item:focus {
    background-image: url(../../assets/icon_tick_lg1.svg);
    background-repeat: no-repeat;
    /* background-position-x: 248px;
  background-position-y: 27px; */
    background-blend-mode: color-burn;
    background-color: #f8f9fa;
    background-size: 27px;
    background-position: right;
    background-position-y: 8px;
    background-origin: content-box;
}

.activeCheck {
    margin-top: 8px;
}

@media only screen and (max-width: 769px) {
    h1 {
        padding-left: 10px;
    }
    button {
        /* background-color: #006395; */
        color: #eee;
        border: none;
        padding: 0px;
    }

    .button-clear {
        margin-left: 0px;
        padding-left: 0px;
    }
    .listview.sub_header_bg {
        height: 150px !important;
    }
    .list-view {
        top: 66px;
    }
    #searchFilter {
        max-width: 57vw;
    }
    .list-container {
        height: 75%;
    }
}

@media only screen and (min-width: 768px) {
    .listview.sub_header_bg {
        height: 115px !important;
    }
    button {
        /* background-color: #006395; */
        color: #eee;
        border: none;
        padding: 7px;
    }
    .list-container {
        height: 80%;
    }
}
</style>
