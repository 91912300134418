<template>
    <div>
        <div>
            <div class="docUpload">
                <b-button
                    class="buttonSelect nextbutton"
                    rounded
                    :disabled="validateUpload"
                    @click="loadHistory"
                >
                    Load
                </b-button>
            </div>
            <div class="cancel-p">
                <p
                    class="text-center sm-cancel pr-2 m-0 rounded-circle"
                    @click="removeHistory"
                >
                    Remove
                </p>
            </div>
            <b-row>
                <b-col>
                    <portal-bar :hideSearch="hideSearch"></portal-bar>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <ejs-grid
                        :dataSource="savedTableData"
                        :rowSelected="dataSetRowSelected"
                        :commandClick="gridRowData"
                    >
                        <e-columns>
                            <e-column
                                headerText="Edit"
                                title="Edit"
                                width="50"
                                :commands="editCommands"
                            ></e-column>
                            <e-column
                                field="CreationDate"
                                headerText="Date Created"
                            ></e-column>
                            <e-column field="Name" headerText="Name"></e-column>
                            <e-column
                                field="Description"
                                headerText="Description"
                            ></e-column>
                            <!-- <e-column field='Source' headerText='Source' ></e-column> -->
                            <!-- <e-column field='Tool' headerText='Tool' ></e-column>
                            <e-column field='Category' headerText='Category' ></e-column> -->
                            <e-column
                                field="DateLastUsed"
                                headerText="Date Used"
                            ></e-column>
                        </e-columns>
                    </ejs-grid>
                </b-col>
            </b-row>
        </div>
        <div>
            <b-modal
                size="lg"
                title="Freeze Tool"
                ref="modal-1"
                id="modal-1"
                ok-title="Freeze"
                cancel-title="Reset "
                hide-header
                hide-footer
                @ok="SaveFilter"
                @cancel="handleReset"
            >
                <div class="text-right cursor-pointer">
                    <img
                        class="pointer"
                        src="../../assets/close_icon.svg"
                        @click="closeModal"
                    />
                </div>
                <h5 class="my-2 title text-center">Save filters</h5>
                <div class="manage-form">
                    <div class="content">
                        <CustomFloatingLabel
                            :config="{
                                label: 'Filter name',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                            v-bind:label="floatLabelAdd"
                        >
                            <input
                                id="filterName"
                                ref="filterName"
                                autocomplete="off"
                                type="text"
                                v-model="filterName"
                                class="normal_input"
                                min="0"
                            />
                        </CustomFloatingLabel>
                        <CustomFloatingLabel
                            :config="{
                                label: 'Filter description',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                            v-bind:label="floatLabelAdd"
                        >
                            <input
                                id="filterDescription"
                                ref="filterDescription"
                                autocomplete="off"
                                type="text"
                                v-model="filterDescription"
                                class="normal_input"
                                min="0"
                            />
                        </CustomFloatingLabel>
                        <CustomFloatingLabel
                            :config="{
                                label: 'Category',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                            v-bind:label="floatLabelAdd"
                        >
                            <input
                                id="Category"
                                ref="Category"
                                autocomplete="off"
                                type="text"
                                v-model="filterCategory"
                                class="normal_input"
                                min="0"
                            />
                        </CustomFloatingLabel>
                        <b-button
                            id="freeze-btn-portal"
                            class="applyButton assignedApply"
                            @click="SaveFilter()"
                        >
                            Save
                        </b-button>
                        <b-button
                            id="reset-btn--portal"
                            class="cancelButton"
                            @click="forceReset"
                        >
                            Reset
                        </b-button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import PortalBar from '../PortalBar';
import Vue from 'vue';
import { datasetService } from '../../services/dataset.service';
import router from '../../router';
import moment from 'moment';
import CustomFloatingLabel from '../CustomFloatingLabel';
import {
    GridPlugin,
    Sort,
    Page,
    Filter,
    Freeze,
    CommandColumn,
    Search,
    Resize,
    Toolbar,
    Grid,
    Reorder,
    ExcelExport,
} from '@syncfusion/ej2-vue-grids';
Vue.use(GridPlugin);
export default {
    name: 'SavedWork',
    data() {
        return {
            selectedUserPortalOptionID: null,
            savedTableData: [],
            selectedRow: null,
            dateFormat: 'MM/dd/yyyy hh:mm:ss',
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            filterName: '',
            filterDescription: '',
            filterCategory: '',
            editUserPortalId: 0,
        };
    },
    components: {
        PortalBar,
        CustomFloatingLabel,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Filter,
            Freeze,
            CommandColumn,
            Search,
            Toolbar,
            Grid,
            Resize,
            ExcelExport,
        ],
    },
    created() {},
    mounted() {
        this.loadTable();
    },
    methods: {
        closeModal() {
            this.$refs['modal-1'].hide();
        },
        SaveFilter() {
            let details = {
                UserPortalOptionID: this.editUserPortalId,
                Name: this.filterName,
                Description: this.filterDescription,
                Category: this.filterCategory,
            };

            datasetService
                .UpdateUserPortalOptionsDetails(details)
                .then((res) => {
                    this.loadTable();
                    this.$refs['modal-1'].hide();
                })
                .catch((err) => {});
        },
        gridRowData(args) {
            this.filterName = args.rowData.Name;
            this.filterDescription = args.rowData.Description;
            this.filterCategory = args.rowData.Category;
            this.editUserPortalId = args.rowData.UserPortalOptionID;
            this.$refs['modal-1'].show();
        },
        getUTCDate(dateParm) {
            var stillUtc = moment.utc(dateParm).toDate();
            return (
                moment(stillUtc)
                    .local()
                    .format('MM/DD/yyyy') +
                ' ' +
                moment(stillUtc)
                    .local()
                    .format('LT')
            );
        },
        loadTable() {
            datasetService
                .getSavedWork(localStorage.getItem('projectId'))
                .then((res) => {
                    res.data.forEach((itm) => {
                        itm.CreationDate = this.getUTCDate(itm.CreationDate);
                        if (itm.DateLastUsed) {
                            itm.DateLastUsed = this.getUTCDate(
                                itm.DateLastUsed
                            );
                        }
                    });
                    this.savedTableData = res.data.sort((a, b) => {
                        return (
                            new Date(b.CreationDate) - new Date(a.CreationDate)
                        );
                    });
                })
                .catch((err) => {});
        },
        dataSetRowSelected(args) {
            this.selectedUserPortalOptionID = args.data.UserPortalOptionID;
            this.selectedRow = this.createArgs(args);
        },
        createArgs(args) {
            let status = null || {};
            let availableFilterArr = [];
            let gridFilters = [];
            let tableRowSelectArgs = null || {};
            let UserPortalOptionID = args.data.UserPortalOptionID;
            args.data.DatasetFilter.forEach((element) => {
                if (!element.isGridFilter) {
                    status[element.ColumnName] = element.ColumnNameValues
                        ? element.ColumnNameValues.split('~~')
                        : [element.FilterValue];
                    let tempObj = {
                        ColumnName: element.ColumnName,
                        filterCondition: element.FilterCondition
                            ? element.FilterCondition
                            : null,
                        filterValue: element.FilterValue
                            ? element.FilterValue
                            : null,
                    };
                    availableFilterArr.push(tempObj);
                } else {
                    let tempObj = {};
                    if (element.GridFilterType === 'Menu') {
                        tempObj = {
                            field: element.ColumnName,
                            operator: element.FilterCondition,
                            predicate: element.FilterPredicate,
                            value: element.FilterValue,
                            filterType: element.GridFilterType,
                        };
                        gridFilters.push(tempObj);
                    } else {
                        let TempValueArray = element.ColumnNameValues.split(
                            '~~'
                        );
                        TempValueArray.forEach((col) => {
                            tempObj = {
                                field: element.ColumnName,
                                operator: element.FilterCondition,
                                predicate: element.FilterPredicate,
                                value: col,
                                filterType: element.GridFilterType,
                            };
                            gridFilters.push(tempObj);
                        });
                    }
                }
            });
            tableRowSelectArgs['data'] = {
                DataSourceId: args.data.DataSourceId,
                CreationDate: args.data.CreationDate,
                DatasetAlias: args.data.DatasetAlias,
                DatasetName: args.data.DatasetName,
                DatasetId: args.data.DatasetId,
                LegacyProjectIds: [...args.data.LegacyProjectIds],
                Tools: [],
                Type: 'Table',
                filterName: args.data.Name,
                filterDescription: args.data.Description,
                filterCategory: args.data.Category,
            };
            return {
                status,
                availableFilterArr,
                gridFilters,
                tableRowSelectArgs,
                UserPortalOptionID,
            };
        },
        loadHistory() {
            datasetService
                .updateSavedWork(this.selectedUserPortalOptionID)
                .then((res) => {})
                .catch((err) => {});
            router.push({
                name: `tableselectiontoolLoadHistory`,
                params: {
                    args: JSON.stringify(this.selectedRow),
                    isSavedWork: true,
                },
            });
        },
        removeHistory() {
            datasetService
                .deleteSaveWork(this.selectedUserPortalOptionID)
                .then((res) => {
                    this.loadTable();
                })
                .catch((err) => {});
        },
    },
};
</script>

<style></style>
