import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';
import { toolbarClick } from '@syncfusion/ej2-vue-grids';
export const projectService = {
    getAll,
    getProjectLayers,
    getProjectLayersList,
    getProjectLayerById,
    getAllWithGeo,
    getStyleBlock,
    getDataPostingToolData,
    getDataPostToolData,
    getDataPostToolData_2,
    getDataPostingToolDataJSON,
    deleteProject,
    createProject,
    editProject,
    getTableName,
    updateDirectory,
    getDirectoryManageAttribute,
    updateProject,
    getContourAttributes,
    selectField,
    selectAggregateField,
    createDirectory,
    cloneDirectory,
    createUploadedDirectory,
    uploadDocuments,
    getDirectories,
    getAssignedAttributes,
    getAllProjectList,
    getStyleType,
    getStyleSize,
    getStyleColor,
    getStyleStyle,
    editProjectMapping,
    getDataCoordinates,
    getListofProjectDirectories,
    GetDirectoryManageAttribute,
    GetDocumentMetadataDetails,
    GetDirectoryMetadataDetails,
    GetDocumentEditMetadataDetails,
    UpdateDocumentMetadataDetails,
    AzureFileAttributeSearch,
    GetProjectType,
    GetBaseMapTypes,
    GetContourLayer,
    GetContourStyle,
    SaveContourStyle,
    UpdateTblDocuments,
    GetWMSExtent,
    GetContoursExtent,
    getProjectWMSExtent,
    getERDataExtent,
    GetNSEsriToken,
    UploadFMTemplate,
    Upload3DModel,
    getProjectLogo,
    getProjectsByUser,
    getProjectExtent,
    getContourStatus,
    CanPrintMapbook,
    CanAccessProject,
    GetProjectInfo,
    getGeowebcacheToken,
    getGeoserverAuthkeys,
    GetExternalAppContent,
};

function getAll() {
    return request({
        url: apiConstants.getProjectDetails,
    });
}
function getAllProjectList() {
    return request({ url: apiConstants.getProjectList });
}
function getAllWithGeo() {
    return axios.get(apiConstants.getAllWithGeo);
}
function getProjectsByUser() {
    return request({
        url: apiConstants.getProjectsByUser,
    });
}
function getDirectoryManageAttribute(id) {
    return request({
        url: apiConstants.getDirectoryManageAttribute + '?id=' + id,
    });
}

function getStyleBlock() {
    return request({ url: apiConstants.getStyleBlock });
}
function getStyleType() {
    return request({ url: apiConstants.getStyleType });
}
function getStyleSize() {
    return request({ url: apiConstants.getStyleSize });
}
function getStyleColor() {
    return request({ url: apiConstants.getStyleColor });
}
function getStyleStyle() {
    return request({ url: apiConstants.getStyleStyle });
}
function getDataPostingToolData(
    datasetId,
    aggregateData,
    fieldName,
    filterOptions
) {
    return request({
        url:
            apiConstants.getDataPostingToolData +
            '?dataSetId=' +
            datasetId +
            '&aggregateColumns=' +
            aggregateData +
            '&displayColumn=' +
            fieldName +
            '&xCol=Geom_stx&yCol=Geom_sty',
        data: filterOptions,
        method: 'post',
    });
}
function getDataPostToolData(
    datasetId,
    aggregateData,
    fieldName,
    filterOptions
) {
    return request({
        url:
            apiConstants.getDataPostToolData +
            '?dataSetId=' +
            datasetId +
            '&aggregateColumns=' +
            aggregateData +
            '&displayColumn=' +
            fieldName,
        data: filterOptions,
        method: 'post',
    });
}

function getDataPostToolData_2(
    datasetId,
    aggregateData,
    fieldName,
    projectIds,
    filterOptions
) {
    return request({
        url:
            apiConstants.getDataPostToolData +
            '?dataSetId=' +
            datasetId +
            '&aggregateColumns=' +
            aggregateData +
            '&displayColumn=' +
            fieldName +
            '&projectIds=' +
            projectIds,
        data: filterOptions,
        method: 'post',
        headers: { 'api-version': '2.0' },
    });
}
function getDataPostingToolDataJSON(datasetId, aggregateData, fieldName) {
    return request({
        url:
            apiConstants.getDataPostingToolDataJSON +
            '?dataSetId=' +
            datasetId +
            '&aggregateColumns=' +
            aggregateData +
            '&displayColumn=' +
            fieldName +
            '&xCol=Geom_stx&yCol=Geom_sty',
    });
}
function getDataCoordinates(dataSetId, columnName) {
    return request({
        url:
            apiConstants.getDataCoordinates +
            '?dataSetID=' +
            dataSetId +
            '&columnName=' +
            columnName,
    });
}

function deleteProject(projectId) {
    return request({
        url: apiConstants.deleteProject + '/' + projectId,
        method: 'delete',
    });
}

function getProjectLayers(projectId, visible) {
    if (visible !== undefined) {
        return request({
            url:
                apiConstants.getProjectLayers +
                '?projectIDs=' +
                projectId +
                '&visible=' +
                visible,
        });
    } else {
        return request({
            url: apiConstants.getProjectLayers + '?projectIDs=' + projectId,
        });
    }
}
function getProjectLayersList(projectId) {
    return request({
        url: apiConstants.getProjectLayersList + '?projectIDs=' + projectId,
    });
}
function getProjectLayerById(projectId, datasetId) {
    return request({
        url:
            apiConstants.getProjectLayerById +
            '?projectId=' +
            projectId +
            '&datasetId=' +
            datasetId,
    });
}
function createProject(projectData, clone) {
    return request({
        url: apiConstants.createProject + '?clone=' + clone,
        data: projectData,
        method: 'post',
    });
}

function editProject(projectId) {
    return request({
        url:
            apiConstants.editProject +
            '?$expand=ProjectAttributeMappings($filter=IsActive eq true)&$filter=projectid eq ' +
            projectId,
    });
}

function editProjectMapping(attributeMappings) {
    return request({
        url: apiConstants.editProjectMapping + '?id=' + attributeMappings,
    });
}

function getTableName(dataSourceId) {
    return request({ url: apiConstants.getTableName + '/' + dataSourceId });
}

function updateProject(projectId, data) {
    return request({
        url: apiConstants.updateProject + '/' + projectId,
        method: 'put',
        data: data,
    });
}
function getContourAttributes(datasetId, AttributeName) {
    let str = encodeURIComponent(AttributeName);
    return request({
        url:
            apiConstants.datasetToolattributes +
            `?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/AttributeName%20eq%20%27${str}%27%20and%20DatasetColumn/DatasetId%20eq%20${datasetId}%20and%20ToolAttribute/ToolID%20eq%203`,
    });
}
function selectField(datasetId, fieldType, toolName) {
    fieldType = fieldType.replace(' ', '%20');
    if (toolName) {
        return request({
            url:
                apiConstants.datasetToolattributes +
                '?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/AttributeName%20eq%20%27' +
                fieldType +
                '%27%20and%20DatasetColumn/DatasetId%20eq ' +
                datasetId +
                '%20and%20ToolName%20eq%20%27' +
                toolName +
                '%27',
        });
    } else {
        return request({
            url:
                apiConstants.datasetToolattributes +
                '?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/AttributeName%20eq%20%27' +
                fieldType +
                '%27%20and%20DatasetColumn/DatasetId%20eq ' +
                datasetId,
        });
    }
}
function selectAggregateField(datasetId) {
    return request({
        url:
            apiConstants.datasetToolattributes +
            '?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/AttributeName%20eq%20%27Aggregate%20options%27%20and%20DatasetColumn/DatasetId%20eq ' +
            datasetId,
    });
}

function updateDirectory(directoryID, data) {
    return request({
        url: apiConstants.updateDirectory + '?directoryID=' + directoryID,
        data: data,
        method: 'put',
    });
}

//upload document API
function uploadDocuments(documents) {
    return request({
        url: apiConstants.uploadDocument,
        data: documents,
        method: 'post',
    });
}

function createDirectory(data) {
    return request({
        url: apiConstants.createDirectory,
        data: data,
        method: 'post',
    });
}

function cloneDirectory(data) {
    return request({
        url: apiConstants.cloneDirectory,
        data: data,
        method: 'post',
    });
}

function createUploadedDirectory(dirName, projectId) {
    return request({
        url:
            apiConstants.createUploadedDirectory +
            '?dirName=' +
            dirName +
            '&ProjectId=' +
            projectId,
    });
}

function UpdateTblDocuments(dirName, projectId, fileName) {
    return request({
        url:
            apiConstants.updateTblDocuments +
            '?dirName=' +
            dirName +
            '&ProjectId=' +
            projectId +
            '&fileName=' +
            fileName,
    });
}

function getDirectories() {
    return request({ url: apiConstants.getListofDirectories });
}

function getListofProjectDirectories(id) {
    return request({
        url: apiConstants.getListofProjectDirectories + '?id=' + id,
    });
}

function getAssignedAttributes(id) {
    return request({
        url:
            apiConstants.getAssignattributes +
            '?$expand=AttributeValues&$filter=DirectoryAttributeID eq ' +
            id,
    });
}

function GetDirectoryManageAttribute(id) {
    return request({
        url: apiConstants.GetDirectoryManageAttribute + '?id=' + id,
    });
}

function GetDocumentMetadataDetails(directoryID, filename) {
    return request({
        url:
            apiConstants.GetDocumentMetadataDetails +
            '?DirectoryID=' +
            directoryID +
            '&FileName=' +
            filename,
    });
}

function GetDirectoryMetadataDetails(args) {
    return request({
        url: apiConstants.GetDirectoryMetadataDetails,
        data: args,
        method: 'post',
    });
}

function GetDocumentEditMetadataDetails(filename, path, DirectoryId) {
    return request({
        url:
            apiConstants.GetDocumentEditMetadataDetails +
            '?FileName=' +
            filename +
            '&Path=' +
            path +
            '&DirectoryId=' +
            DirectoryId,
    });
}

function UpdateDocumentMetadataDetails(filename, projectid, data) {
    return request({
        url:
            apiConstants.UpdateDocumentMetadataDetails +
            '?FileName=' +
            filename +
            '&ProjectId=' +
            projectid,
        data: data,
        method: 'post',
    });
}
function AzureFileAttributeSearch(data) {
    return request({
        url: apiConstants.AzureFileAttributeSearch,
        data: data,
        method: 'post',
    });
}
function GetProjectType() {
    return request({ url: apiConstants.GetProjectType });
}
function GetBaseMapTypes() {
    return request({ url: apiConstants.GetBasemapTypes });
}

function GetContourLayer(jobID) {
    return request({
        url: apiConstants.getContourLayer + '?jobID=' + jobID,
        method: 'get',
    });
}

function GetContourStyle(jobID) {
    return request({
        url: apiConstants.getContourStyle + '?jobID=' + jobID,
        method: 'get',
    });
}

function SaveContourStyle(jobID, data) {
    return request({
        url: apiConstants.saveContourStyle + '?jobID=' + jobID,
        data: data,
        method: 'put',
    });
}

function GetWMSExtent(layername) {
    return request({
        url: apiConstants.GetWMSExtent + '?layername=' + layername,
        method: 'get',
    });
}

function GetContoursExtent(jobID) {
    return request({
        url: apiConstants.GetContoursExtent + '?jobID=' + jobID,
        method: 'get',
    });
}

function UploadFMTemplate(data) {
    return request({
        url: apiConstants.UploadFMTemplate,
        method: 'post',
        data: data,
    });
}

function GetNSEsriToken() {
    return request({ url: apiConstants.getNSEsriToken });
}

function Upload3DModel(data) {
    return request({
        url: apiConstants.Upload3DModel,
        data: data,
        method: 'post',
    });
}

function getProjectWMSExtent(projectId) {
    return request({
        url: apiConstants.getProjectWMSExtent + '?projectId=' + projectId,
        method: 'get',
    });
}

function getERDataExtent(projectId) {
    return request({
        url: apiConstants.getERDataExtent + '?projectId=' + projectId,
        method: 'get',
    });
}

function getProjectLogo(projectId) {
    return request({
        url: apiConstants.getProjectLogo + '?projectId=' + projectId,
        method: 'get',
    });
}

function getProjectExtent(projectId) {
    return request({
        url: apiConstants.getProjectExtent + '?projectId=' + projectId,
        method: 'get',
    });
}

function getContourStatus(jobID) {
    return request({
        url: apiConstants.getContourStatus + '?jobID=' + jobID,
        method: 'get',
    });
}

function CanPrintMapbook(projectId) {
    return request({
        url: apiConstants.CanPrintMapbook + '?projectId=' + projectId,
    });
}

function CanAccessProject(projectId) {
    return request({
        url: apiConstants.CanAccessProject + '?projectId=' + projectId,
    });
}

function GetProjectInfo(projectId) {
    return request({
        url: apiConstants.GetProjectInfo + '?projectId=' + projectId,
    });
}

function getGeowebcacheToken() {
    return request({
        url: apiConstants.getGeowebcacheToken,
    });
}

function getGeoserverAuthkeys(projectId) {
    return request({
        url: apiConstants.getGeoserverAuthkeys + '?projectIDs=' + projectId,
    });
}

function GetExternalAppContent(data) {
    return request({
        url: apiConstants.externalAppContent,
        data,
        method: 'post',
    });
}
