export const CUSTOM_DATE_FILTERS = [
    'Last12hours',
    'Last24hours',
    'Last48hours',
    'Last3days',
    'Lastweek',
    'Lastmonth',
    'Lastquarter',
    'Lastyear',
    'Alltime',
];

export const dateFunctions = {
    calcDataManagerDateFilter,
};

function calcDataManagerDateFilter(filter, asISOString = false) {
    let currentDate = new Date();
    if (filter === 'Last12hours') {
        currentDate.setHours(currentDate.getHours() - 12);
    } else if (filter === 'Last24hours') {
        currentDate.setDate(currentDate.getDate() - 1);
    } else if (filter === 'Last48hours') {
        currentDate.setDate(currentDate.getDate() - 2);
    } else if (filter === 'Last3days') {
        currentDate.setDate(currentDate.getDate() - 3);
    } else if (filter === 'Lastweek') {
        currentDate.setDate(currentDate.getDate() - 7);
    } else if (filter === 'Lastmonth') {
        currentDate.setMonth(currentDate.getMonth() - 1);
    } else if (filter === 'Lastquarter') {
        currentDate.setMonth(currentDate.getMonth() - 3);
    } else if (filter === 'Lastyear') {
        currentDate.setFullYear(currentDate.getFullYear() - 1);
    } else if (filter === 'Alltime') {
        currentDate = new Date(0);
    }
    if (asISOString) {
        return currentDate.toISOString();
    } else {
        return currentDate;
    }
}
