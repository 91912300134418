import { v4 as uuidv4 } from 'uuid';
const state = {
    selectedFilters: [], // [{dataSetId:number,projectId:number,selectedFilters[], freezeVals:{row:'',col:''}}]
    historyFilters: [],
    currentGridFilter: {},
    currentStateUUID: uuidv4(),
    openOtherSideTool: uuidv4(),
    gridFilter: {},
    secondLevelFilter: [],
    dataGridProjectId: '',
    selectedDatasets: [], //[{ projectId: '', datasetIds: [] }]
    groupedTables: [],
};

const actions = {
    setGridFilters({ commit }, filters) {
        commit('mutateSetGridFilters', filters);
    },
    setHistoryFilters({ commit }, filters) {
        commit('mutateHistoryFilters', filters);
    },
    setLastUsedDate({ commit }, index) {
        commit('mutateLastUsedDate', index);
    },
    setCurrentGridFilter({ commit }, filters) {
        commit('mutateSetCurrentGridFilter', filters);
        commit('mutateSetGridFilters', filters);
    },
    clearCurrentGridFilter({ commit }, filters) {
        commit('mutateClearGridFilters');
    },
    setCurrentUUID({ commit }) {
        commit('mutateCurrentUUID');
    },
    setOpenOtherSideTool({ commit }) {
        commit('mutateOpenOtherSideTool');
    },
    setFilter({ commit }, filter) {
        commit('mutateFilter', filter);
    },
    setSecondFilter({ commit }, secondFilter) {
        commit('mutateSecondFilter', secondFilter);
    },
    setDataGridProjectId({ commit }, projectId) {
        commit('mutateDataGridProjectId', projectId);
    },
    addSelectedDatasets({ commit }, val) {
        commit('mutateAddSelectedDatasets', val);
    },
    removeSelectedDatasets({ commit }, val) {
        commit('mutateRemoveSelectedDatasets', val);
    },
    setFreezeVals({ commit }, val) {
        commit('mutateSetFreezeVals', val);
    },
    clearGridFilterColumn({ commit }, val) {
        commit('mutateClearGridFilterColumn', val);
    },
};

const mutations = {
    setGroupedTables(state, payload) {
        state.groupedTables = payload;
    },
    mutateSetGridFilters(state, value) {
        if (state.selectedFilters) {
            let filterIndx = state.selectedFilters.findIndex(
                (filter) =>
                    filter.dataSetId === value.dataSetId &&
                    filter.projectId === value.projectId
            );
            if (filterIndx !== -1) {
                'freezeVals' in state.selectedFilters[filterIndx] &&
                    delete state.selectedFilters[filterIndx]['freezeVals'];
                state.selectedFilters[filterIndx].selectedFilters =
                    value.selectedFilters;
            } else {
                state.selectedFilters.push(value);
            }
        }
    },
    mutateHistoryFilters(state, value) {
        value['unSortedIndex'] = state.historyFilters.length;
        state.historyFilters.push(value);
    },
    mutateLastUsedDate(state, value) {
        state.historyFilters.forEach((el, indx) => {
            if (value === indx) {
                el['DateLastUsed'] = new Date();
            }
        });
    },
    mutateSetCurrentGridFilter(state, value) {
        state.currentGridFilter = value;
    },
    mutateClearGridFilters(state) {
        state.currentGridFilter = null;
    },
    mutateOpenOtherSideTool(state) {
        state.openOtherSideTool = uuidv4();
    },
    mutateCurrentUUID(state) {
        state.currentStateUUID = uuidv4();
    },
    mutateFilter(state, filter) {
        state.gridFilter = filter;
    },
    mutateSecondFilter(state, filter) {
        state.secondLevelFilter = filter;
    },
    mutateDataGridProjectId(state, projectId) {
        state.dataGridProjectId = projectId;
    },

    mutateAddSelectedDatasets(state, { projectId, datasetId }) {
        const selectedProject = state.selectedDatasets.find(
            (dataset) => dataset.projectId === projectId
        );

        if (!selectedProject) {
            state.selectedDatasets.push({ projectId, datasetIds: [datasetId] });
        } else {
            const datasetIndex = selectedProject.datasetIds.indexOf(datasetId);
            if (datasetIndex === -1) {
                selectedProject.datasetIds.push(datasetId);
            }
        }
    },
    mutateRemoveSelectedDatasets(state, { projectId, datasetId }) {
        const selectedProjectIndex = state.selectedDatasets.findIndex(
            (dataset) => dataset.projectId === projectId
        );

        if (selectedProjectIndex !== -1) {
            const selectedProject =
                state.selectedDatasets[selectedProjectIndex];
            const datasetIndex = selectedProject.datasetIds.indexOf(datasetId);

            if (datasetIndex !== -1) {
                selectedProject.datasetIds.splice(datasetIndex, 1);
            }

            if (selectedProject.datasetIds.length === 0) {
                state.selectedDatasets.splice(selectedProjectIndex, 1);
            }
        }
    },
    mutateSetFreezeVals(state, value) {
        if (state.selectedFilters) {
            let filterIndx = state.selectedFilters.findIndex(
                (filter) =>
                    filter.dataSetId === value.dataSetId &&
                    filter.projectId === value.projectId
            );
            if (filterIndx !== -1) {
                state.selectedFilters[filterIndx].freezeVals = value.freezeVals;
            } else {
                state.selectedFilters.push(value);
            }
        }
    },
    mutateClearGridFilterColumn(state, value) {
        if (state.selectedFilters) {
            let filterIndx = state.selectedFilters.findIndex((filter) => {
                filter.dataSetId == value.dataSetId &&
                    filter.projectId == value.projectId;
            });
            if (filterIndx !== -1) {
                state.selectedFilters[filterIndx].selectedFilters.forEach(
                    (element) => {
                        let index = element.findIndex(
                            (filter) => filter.field == value.field
                        );
                        if (index !== -1) {
                            element.splice(index, 1);
                        }
                    }
                );
                'freezeVals' in state.selectedFilters[filterIndx] &&
                    delete state.selectedFilters[filterIndx]['freezeVals'];
                state.selectedFilters[filterIndx].selectedFilters =
                    value.selectedFilters;
            }
        }
    },
};

const getters = {
    getGridFilters: (state) => (payLoad) => {
        return state.selectedFilters.find((item) => {
            if (
                item.dataSetId === payLoad.dataSetId &&
                item.projectId === payLoad.projectId
            ) {
                return item;
            }
        });
    },
    getSelectedDatasets: (state) => (projectId) => {
        const selectedProject = state.selectedDatasets.find(
            (dataset) => dataset.projectId === projectId
        );
        if (selectedProject) {
            return selectedProject.datasetIds;
        } else {
            return [];
        }
    },
};

const namespaced = true;

export const gridFilters = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
