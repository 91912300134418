<template>
    <div id="app" style="height: 100%;">
        <div class="control_wrapper">
            <treeview :zoomLevel="zoomLevel" />
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import treeview from './TableOfContents/treeview.vue';

export default {
    components: { treeview },
    props: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {
        ...mapActions('emergencyManagement', ['updateLayerStructNode']),
        emitNodeChecked(node) {
            var visibility = {};
            node.data.forEach((row) => {
                // q: why does it emit the bool as a string? a: because there is a mixed state
                visibility[row.id] = row.isChecked === 'true';
            });
            this.updateLayerStructNode(visibility);
        },
    },
    computed: {
        ...mapState('emergencyManagement', {
            layersStruct: 'layersStructValues',
        }),
        ...mapState({
            zoomLevel: function(state) {
                return state.projects.mapData.mapZoom;
            },
        }),
    },
};
</script>
<style>
/* @import "../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
  @import "../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css"; */
.control_wrapper {
    height: auto;
    margin: auto;
    overflow: auto;
    border-radius: 3px;
    max-height: none !important;
    border: none !important;
    padding-top: 7px;
}
.custom .e-list-item .e-fullrow {
    height: 72px;
}

.custom .e-list-item .e-list-text {
    line-height: normal;
}

.eimage {
    float: left;
    padding: 11px 16px 11px 0;
}
.wmsURL {
    color: black;
    font-size: 16px;
}
.ename {
    font-size: 16px;
    padding-left: 8px;
}
.ejob {
    font-size: 14px;
    opacity: 0.87;
}
.e-icon-wrapper .ename {
    color: black !important;
}
.legendImg {
    padding-top: 1px;
    height: auto;
    /* width: 20px; */
}
.e-treeview .e-list-item div.e-icons::before {
    bottom: 0 !important;
}
</style>
