import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const reportingService = {
    getTitleBlock,
    getDataSetColumn,
    updateTitleBlock,
    saveTitleBlock,
    updateTitleBlockImage,
    saveTitleBlockImage,
    submitContourRequest,
    getContourValueRange,
};

function getTitleBlock(dataSetId) {
    // return axios.get(apiConstants.getTitleBlock+'?datasetId='+dataSetId)
    return request({
        url: apiConstants.getTitleBlock + '?datasetId=' + dataSetId,
    });
}

function getDataSetColumn(dataSetId) {
    // return axios.get(apiConstants.getDataSetBlock+'?$filter=DatasetId eq '+ dataSetId)
    return request({
        url:
            apiConstants.getDataSetBlock +
            '?$filter=DatasetId eq ' +
            dataSetId +
            ' and isActive eq true',
    });
}

function updateTitleBlock(titleBlock, titleBlockId) {
    // return axios.put(apiConstants.updateTitleBlock +'/'+titleBlockId, titleBlock)
    return request({
        url: apiConstants.updateTitleBlock + '/' + titleBlockId,
        data: titleBlock,
        method: 'put',
    });
}
function saveTitleBlock(titleBlock) {
    // return axios.post(apiConstants.updateTitleBlock, titleBlock)
    return request({
        url: apiConstants.updateTitleBlock,
        data: titleBlock,
        method: 'post',
    });
}
function updateTitleBlockImage(titleBlock, imageId) {
    if (imageId === 0) {
        // return axios.post(apiConstants.updateTitleBlockImage, titleBlock )
        return request({
            url: apiConstants.updateTitleBlockImage,
            data: titleBlock,
            method: 'post',
        });
    } else {
        // return axios.put(apiConstants.updateTitleBlockImage +'/'+imageId, titleBlock )
        return request({
            url: apiConstants.updateTitleBlockImage + '/' + imageId,
            data: titleBlock,
            method: 'put',
        });
    }
}
function saveTitleBlockImage(titleBlock) {
    // return axios.post(apiConstants.addTitleBlockImage, titleBlock)
    return request({
        url: apiConstants.addTitleBlockImage,
        data: titleBlock,
        method: 'post',
    });
}

function getContourValueRange(
    datasetId,
    aggregateData,
    fieldName,
    projectIds,
    filterOptions
) {
    return request({
        url:
            apiConstants.getContourMinMaxValues +
            '?dataSetId=' +
            datasetId +
            '&aggregateColumns=' +
            aggregateData +
            '&displayColumn=' +
            fieldName +
            '&projectIds=' +
            projectIds,
        data: filterOptions,
        method: 'post',
    });
}

function submitContourRequest(
    datasetId,
    aggregateData,
    fieldName,
    projectIds,
    filterOptions
) {
    return request({
        url:
            apiConstants.SubmitContourRequest +
            '?dataSetId=' +
            datasetId +
            '&aggregateColumns=' +
            aggregateData +
            '&displayColumn=' +
            fieldName +
            '&projectIds=' +
            projectIds,
        data: filterOptions,
        method: 'post',
    });
}