<template>
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="users" v-else>
        <Header v-bind:showVersionToggle="false" />

        <b-container fluid class="user sub_header_bg">
            <b-container class="p-0">
                <b-row class="mb-3 m-none">
                    <b-col cols="12 my-1">
                        <a href="javascript:void(0)" @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a href="#" class="floar-left ml-1 back">
                                Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left py-2 m-pt-0" align-v="center">
                    <b-col cols="7" class="pt-2 pl-3 m-p-0">
                        <h1 class="">Users</h1>
                    </b-col>
                    <!-- <b-col cols="6">
            <p class="float-right pl-3 m-0">Clear selection</p>
          </b-col> -->
                    <b-col
                        cols="5"
                        class="text-right m-p-0 btn-top-user"
                        v-if="showAddUser"
                    >
                        <b-button
                            rounded
                            id="addNewUser"
                            class="buttonSelect"
                            @click="addUser"
                        >
                            Add user
                        </b-button>
                    </b-col>
                </b-row>

                <!-- <b-row class="pt-1">
          <div class="borderBlue ml-3 m-left-0">
            <h5>Users</h5>
          </div>
        </b-row> -->
            </b-container>
        </b-container>

        <b-container class="p-0">
            <!-- <div class="border-top mt-5 ml-2">
        <b-row class="py-3">
          <b-col cols="8" class="blue-color p-0">
            <a href="#" class="mx-3"
              ><img src="../../assets/search-outline.svg"
            /></a>
            <span class="border-right">&nbsp;</span>
            <a href="#" class="ml-3 mr-2"
              ><img src="../../assets/tooltip.svg"
            /></a>
            <span class="font-weight-300">Filter</span>
          </b-col>
        </b-row>
      </div> -->
            <UserList @clickedUserList="listUsers" />
            <router-view></router-view>
        </b-container>
        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../../router';
import Header from '../Header';
import Footer from '../Footer';
import UserList from '../users/UserList';
import AccessPermissionModal from '../AccessPermissionModal';
//import dashboard from "../../views/Dashboard";

export default {
    data() {
        return {
            showPage: true,
            showAddUser: false,
            emittedVal: 'Test this',
        };
    },
    components: {
        Header,
        Footer,
        UserList,
        AccessPermissionModal,
        // dashboard,
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        pagePermit() {
            this.showPage = true;
            this.showAddUser = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('users')) {
                    vm.showPage = true;
                }
                if (element.PageName.includes('AddUser')) {
                    vm.showAddUser = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        addUser: function() {
            this.removeSingleUser();
            router.push(`/adduser`);
        },
        ...mapActions('users', {
            removeSingleUser: 'removeSingleUser',
        }),
        listUsers(value) {
            if (!value) {
                this.showPage = value;
                setTimeout(() => {
                    this.$refs['permission-modal'].showPermissionModal();
                });
            }
        },
    },
};
</script>

<style scoped>
/* .buttonSelect {
  background-color: #006395;
  padding: 10px 18px;
  width:150px;
} */
.borderBlue {
    border-bottom: 5px solid rgba(0, 99, 149, 1);
    /* width: 55px !important; */
}

a {
    text-decoration: none !important;
    font-size: 14px;
}

a span {
    color: black;
}
@media only screen and (min-width: 768px) {
    .user.sub_header_bg {
        height: 120px !important;
    }
}
</style>
