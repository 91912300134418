<template>
    <div>
        <div
            class="input__container"
            :class="[
                containerClasses,
                selectContent ? 'input__container--content' : '',
            ]"
            :style="inputContainerStyle"
        >
            <div class="slot-container" ref="input-container">
                <slot @focus="focus" @blur="blur" class="input"> </slot>
            </div>
            <label class="label__placeholder" :for="labelName">{{
                config.label
            }}</label>
            <label
                class="label__active"
                :class="activeLabelClasses"
                :style="activeLabelStyle"
                :for="labelName"
                >{{ config.label }}</label
            >
        </div>
        <span class="errorMsg" v-show="this.hasError"
            ><small>{{ this.config.validationMessage }}</small></span
        >
    </div>
</template>

<script>
export default {
    name: 'FloatingLabel',
    props: {
        config: {
            line: false,
            name: 'wrapper',
            scale: false,
        },
        editUserParams1: {
            type: String,
            required: false,
            default: '',
        },
        errorOnBlank: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    created() {},
    computed: {
        activeLabelClasses() {
            return {
                'label__active--canscale': this.settings.scale,
            };
        },
        containerClasses() {
            let classes = {
                'has-line': this.settings.line,
                'input__container--focus': this.hasFocus,
                'input__container--content':
                    this.hasContent ||
                    (this.$slots.default[0].tag == 'input' &&
                        this.$slots.default[0].data.domProps.value != '' &&
                        this.$slots.default[0].data.domProps.value),
                'has-error': this.hasError,
            };
            if (this.settings.hasError) {
                classes[this.settings.classes.error] = true;
            }
            return classes;
        },
        labelName() {
            if (this.config.name !== undefined) {
                return this.config.name;
            }
            return this.config.label.toLowerCase();
        },
        labelColor() {
            if (!this.settings.hasError) {
                return this.hasFocus
                    ? this.settings.color.focusColor
                    : this.settings.color.blurredColor;
            } else {
                return this.settings.color.errorColor;
            }
        },
        activeLabelStyle() {
            return {
                top: this.settings.labelOffset.top + 'px',
                left: this.settings.labelOffset.left + 'px',
                color: this.labelColor,
            };
        },
        inputContainerStyle() {
            return {
                height: this.settings.height + 'px',
            };
        },
        settings() {
            return Object.assign({}, this.defaultSettings, this.config);
        },
    },
    methods: {
        focus() {
            this.hasFocus = true;
            this.$emit('focus');
        },
        input(event) {
            this.hasFocus = true;
            this.hasContent = event.target.value !== '';
            this.$emit('input');
        },
        isValidEmail() {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            return pattern.test(this.$slots.default[0].data.domProps.value);
        },
        blur() {
            this.hasFocus = false;
            if (this.errorOnBlank) {
                if (!this.hasContent) {
                    this.hasError = true;
                } else {
                    if (
                        this.$slots.default[0].data.attrs.type == 'email' &&
                        !this.isValidEmail()
                    ) {
                        this.hasError = true;
                    } else {
                        this.hasError = false;
                    }
                }
                /*if is email
                        {
                            if not valid email or not has content
                            {
                                hasError = true
                            }
                        }
                    */
            }
            this.$emit('blur');
        },
    },
    mounted() {
        this.formElement = this.$refs['input-container'].querySelector(
            'input, select'
        );
        if (this.formElement) {
            this.formElement.addEventListener('input', this.input);
            this.formElement.addEventListener('blur', this.blur);
            this.formElement.addEventListener('focus', this.focus);
            if (
                this.formElement.value != '' &&
                this.formElement.value != null
            ) {
                this.hasContent = true;
                this.selectContent = true;
            } else {
                this.hasContent = false;
                this.selectContent = false;
            }
            if (this.formElement.type === 'select-one') {
                if (
                    this.formElement.value == '' ||
                    this.formElement.value == null
                ) {
                    this.hasContent = false;
                } else {
                    this.hasContent = true;
                }
                this.settings.scale = false;
                this.settings.hasClearButton = false;
                this.selectContent = true;
            }
        }
    },
    data() {
        return {
            defaultSettings: {
                classes: {
                    error: 'has-error',
                },
                width: 475,
                hasClearButton: true,
                line: true,
                scale: true,
                labelOffset: {
                    top: 2,
                    left: 8,
                },
                color: {
                    focusColor: '#626262',
                    // lineColor: '#128CED',
                    blurredColor: '#626262',
                    errorColor: '#ff0000',
                },
            },
            hasFocus: false,
            hasContent: false,
            selectContent: false,
            hasError: false,
        };
    },
};
</script>

<style scoped lang="scss">
@import '../assets/style/_variables.scss';

@media screen and (max-width: 769px) {
    .input__container {
        position: relative;
        padding: 0 8px;
        width: calc(90vw - 48px);
        transition: 0.2s cubic-bezier($easeInOutCubic);
        .slot-container {
            height: 107%;
            input {
                height: 100%;
                font-size: 16px;
                padding: 0 0;
                border: 0;
                padding-left: 10px;
                display: block;
                width: 100%;
                font-family: var(--ffr);
                color: #1d1d1d;
                position: relative;
                background-color: transparent;
                transition: 0.2s cubic-bezier($easeInOutCubic);
            }
            select {
                height: 100%;
                font-size: 16px;
                padding: 0 0;
                // border: 0;
                display: block;
                width: 100%;
                color: #1d1d1d;
                position: relative;
                background-color: transparent;
                transition: 0.2s cubic-bezier($easeInOutCubic);
            }
        }
        & + .input__container:before {
            display: none;
        }
        // Is Focused
        &.input__container--focus {
            .accessibility__icon {
                transform: scaleX(1);
            }
        }

        // Has content
        &.input__container--content {
            .character-counter-container {
                height: 32px;
            }
            label {
                &.label__placeholder {
                    opacity: 0;
                }
            }
            .label__active {
                opacity: 1;
                &.label__active--canscale {
                    transform: translate3d(0, 0px, 0) scale(0.85);
                }
            }
            input,
            .label__placeholder {
                transform: translate3d(0, 6px, 0);
                font-family: var(--ffr);
                //z-index: -1;
                // width: 94% !important;
            }
        }
        &.has-error {
            border: 1px solid #ff0000 !important;
        }
    }
}

@media screen and (min-width: 768px) {
    .input__container {
        position: relative;
        padding: 0 8px;
        width: 475px;
        transition: 0.2s cubic-bezier($easeInOutCubic);
        .slot-container {
            height: 107%;
            input {
                height: 100%;
                font-size: 16px;
                padding: 0 0;
                border: 0;
                padding-left: 10px;
                display: block;
                width: 100%;
                font-family: var(--ffr);
                color: #1d1d1d;
                position: relative;
                background-color: transparent;
                transition: 0.2s cubic-bezier($easeInOutCubic);
            }
            select {
                height: 100%;
                font-size: 16px;
                padding: 0 0;
                // border: 0;
                display: block;
                width: 100%;
                color: #1d1d1d;
                position: relative;
                background-color: transparent;
                transition: 0.2s cubic-bezier($easeInOutCubic);
            }
        }
        & + .input__container:before {
            display: none;
        }
        // Is Focused
        &.input__container--focus {
            .accessibility__icon {
                transform: scaleX(1);
            }
            // .character-counter-container {
            //     height: 32px;

            // }
            // label {
            //     &.label__placeholder {
            //         opacity: 0;
            //     }
            // }
            // .label__active {
            //     opacity: 1;
            //     &.label__active--canscale {
            //         transform: translate3d(0, 0px, 0) scale(.85);
            //     }
            // }
            // input, .label__placeholder {
            //     transform: translate3d(0, 6px, 0);

            // }
        }

        // Has content
        &.input__container--content {
            .character-counter-container {
                height: 32px;
            }
            label {
                &.label__placeholder {
                    opacity: 0;
                }
            }
            .label__active {
                opacity: 1;
                &.label__active--canscale {
                    transform: translate3d(0, 0px, 0) scale(0.85);
                }
            }
            input,
            .label__placeholder {
                transform: translate3d(0, 6px, 0);
                font-family: var(--ffr);
                //z-index: -1;
                // width: 94% !important;
            }
        }
        &.has-error {
            border: 1px solid #ff0000 !important;
        }
    }
}
input:focus {
    outline: none;
}
label {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    user-select: none;
    transition: 0.2s cubic-bezier($easeInOutCubic);

    &.label__placeholder {
        top: 50%;
        transform: translate(0, -50%);
        color: #626262;
        left: 17px;
        font-size: 16px;
        font-family: var(--ffr);
        font-weight: normal;
    }
    &.label__active {
        font-size: 12px;
        color: $color__200;
        font-weight: $font__weight--bold;
        line-height: 16px;
        opacity: 0;
        //padding-left:10px;
        transform: translate3d(0, 3px, 0);
        transform-origin: 0 0;
        font-family: var(--ffr);
    }
    &.label__character-counter {
        font-size: $font__size--xs;
        line-height: 16px;
        color: $color__200;
        position: absolute;
        left: 0;
        bottom: -14px;
        display: inline-block;
        top: auto;
        opacity: 0;
    }
}
.errorMsg {
    color: #f00;
}
</style>
