<template>
    <div class="please-wait-spinner">
        <div class="spinner-border loadingIcon" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="loading-text">
            Please wait...
        </div>
    </div>
</template>

<script>
export default {
    name: 'PleaseWaitSpinner',
    props: {},
    data() {
        return {};
    },
};
</script>

<style scoped>
.loadingIcon {
    left: 36.5%;
    position: relative;
    z-index: 2;
    display: block;
}
.loading-text {
    /* padding-top: 10px; */
    position: relative;
    z-index: 2;
}
.please-wait-spinner {
    z-index: 2;
    position: absolute;
    left: 46.5%;
    top: 46.5%;
    display: block;
}
.spinner-border {
    margin-bottom: 2px;
}
</style>
