import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const dashboardService = {
    getDashboardParams,
    getReportFilter,
};

function getDashboardParams(reportId) {
    return request({
        url: apiConstants.getDashboardParams + '?datasetId=' + reportId,
        method: 'get',
    });
}
function getReportFilter(projectId) {
    return request({
        url: apiConstants.getReportFilter + '?projectId=' + projectId,
        method: 'get',
    });
}
