import Vue from 'vue';
const state = {
    sampleLat: null,
    sampleLong: null,
    getCoordinatesFlag: false,
    refreshMap: false,
};

const actions = {
    setDCRefreshMap({ commit }, value) {
        commit('mutateSetRefreshMap', value);
    },
};

const mutations = {
    setSampleLat(state, latitude) {
        state.sampleLat = latitude;
    },
    setSampleLong(state, longitude) {
        state.sampleLong = longitude;
    },
    setGetCoordinatesFlag(state) {
        state.getCoordinatesFlag = true;
    },
    clearGetCoordinatesFlag(state) {
        state.getCoordinatesFlag = false;
    },
    mutateSetRefreshMap(state, value) {
        state.refreshMap = value;
    },
};

const getters = {
    dataCollectionRefreshMap: (state) => {
        return state.refreshMap;
    },
};

const namespaced = true;

export const dataCollection = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
