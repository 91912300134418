<template>
    <div v-show="isVisible">
        <span class="legendSymbol" v-html="symbol"></span>
        <span class="legendText">{{ layerName }}</span>
        <span v-hide="layerFormat == 'posted' && layer.isWMS">
            ({{ featureCount }})</span
        >
    </div>
</template>

<script>
import { mapLayerFunctions } from '../../../utilities/mapLayerFunctions.js';
import { mapMarkerFunctions } from '../../../utilities/mapMarkerFunctions';
export default {
    name: 'JsonLegendEntry',
    props: {
        // want the object with the data that is being rendered
        layer: {
            required: true,
            type: Object,
        },
        // whether or not to show the label for an item, can set false to hide labels
        showEntry: {
            required: false,
            type: Boolean,
            default: true,
        },
        layerFormat: {
            required: true,
            type: String, // 'project' or 'posted'
        },
        featureCount: {
            required: false,
            type: Number,
        },
    },
    computed: {
        symbol() {
            if (this.layer.isWMS) {
                return mapLayerFunctions.getLineIcon(
                    this.layer.ContourOptions.LineColor
                        ? this.layer.ContourOptions.LineColor
                        : this.layer.ContourOptions.LineColour,
                    this.layer.ContourOptions.LineWidth,
                    this.layer.ContourOptions.LineStyle &&
                        this.layer.ContourOptions.LineStyle.toLowerCase() ==
                            'dashed',
                    this.layer.ContourOptions.LineOpacity
                );
            } else if (this.layer.Geometry.features.length) {
                switch (this.layer.Geometry.features[0].geometry.type) {
                    case 'Point':
                        return mapMarkerFunctions.getIcon(
                            this.layer.Symbology,
                            this.layer.SymbologyColor
                                ? this.layer.SymbologyColor
                                : this.layer.SymbologyColour,
                            this.layer.PointOutlineColor
                                ? this.layer.PointOutlineColor
                                : this.layer.PointOutlineColour
                        );

                    case 'LineString':
                    case 'MultiLineString':
                        return mapLayerFunctions.getLineIcon(
                            this.layer.LineColor
                                ? this.layer.LineColor
                                : this.layer.LineColour,
                            this.layer.LineWidth,
                            this.layer.LineStyle &&
                                this.layer.LineStyle.toLowerCase() == 'dashed',
                            this.layer.LineOpacity
                        );
                    case 'Polygon':
                    case 'MultiPolygon':
                        return mapLayerFunctions.getPolygonIcon(
                            this.layer.PolygonColor
                                ? this.layer.PolygonColor
                                : this.layer.PolygonColour,
                            this.layer.PolygonBorderColor
                                ? this.layer.PolygonBorderColor
                                : this.layer.PolygonBorderColour,
                            this.layer.PolygonWidth
                        );
                    default:
                        return mapMarkerFunctions.getIcon('circle', '#000');
                }
            }
            // return a circle if no features so the v-hide is correctly offset
            return mapMarkerFunctions.getIcon('circle', '#000');
        },
        layerName() {
            return this.layer.DisplayName !== undefined &&
                this.layer.DisplayName.length > 0
                ? this.layer.DisplayName
                : this.layer.ContourOptions.DisplayName;
        },
        isVisible() {
            // project and posted layers have different capitalization for Visible
            return (
                this.showEntry &&
                this.showLegendLayer &&
                (this.layer.visible || this.layer.Visible)
            );
        },
        showLegendLayer() {
            if (this.layerFormat == 'posted') {
                return this.layer.visible || this.layer.Visible;
            } else if (
                this.layerFormat == 'project' &&
                this.featureCount == 0
            ) {
                return false;
            } else {
                return true;
            }
        },
    },
};
</script>

<style scoped>
.legendSymbol {
    padding-left: 5px;
}
.legendText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    padding-left: 8px;
}
</style>
<style>
#legendPane .legendSymbol svg {
    width: 13px;
    height: 13px;
}
</style>
