var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"no-padding no-margin",attrs:{"xs":"auto","sm":"auto","md":"auto","lg":"auto","xl":"auto"}},[_c('b-container',{staticClass:"frame-container",attrs:{"fluid":"true"}},[_c('b-row',{staticClass:"d-flex viewer-row no-padding no-margin"},[(_vm.side == 'left')?_c('b-col',{staticClass:"frame no-padding frame-buttonbar left",class:'taskbar-buttons_' + _vm.side,attrs:{"id":"buttonbar-left"}},[_c('draggable-tools',{attrs:{"draggable":".item","group":"items"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.updateButtonsArr(_vm.side)}},model:{value:(_vm.taskbarButtons),callback:function ($$v) {_vm.taskbarButtons=$$v},expression:"taskbarButtons"}},_vm._l((_vm.taskbarButtons),function(item){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(
                            !(
                                item.ProjectDependent &&
                                item.ProjectList === ''
                            )
                        ),expression:"\n                            !(\n                                item.ProjectDependent &&\n                                item.ProjectList === ''\n                            )\n                        "}],key:item.ToolID,class:_vm.projectOptionClass(
                                item.ProjectDependent,
                                item.ProjectList
                            ),attrs:{"id":item.ToolID,"disabled":item.ProjectDependent &&
                                _vm.projectNotSelected(item.ProjectList)},on:{"click":function($event){return _vm.taskbarButtonClick(item, _vm.side)}}},[_c('img',{staticClass:"pb-1",attrs:{"width":"26","src":_vm.toolIconPath(item.ToolName)}}),_vm._v(" "+_vm._s(item.ToolName)+" ")])}),0)],1):_vm._e(),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayedTool !== ''),expression:"displayedTool !== ''"}],staticClass:"frame frame-sidebar no-padding",style:(_vm.dynamicSidebarWidth),attrs:{"id":'sidebar-' + _vm.side}},[_c(_vm.componentName,_vm._b({tag:"component",on:{"close":function($event){return _vm.close()},"openProjects":function($event){return _vm.$emit('openProjects')},"navigateToDataTool":function($event){return _vm.navigateToDataTool()}}},'component',_vm.currentProperties,false))],1),(_vm.side == 'right')?_c('b-col',{staticClass:"frame no-padding frame-buttonbar right",class:'taskbar-buttons_' + _vm.side,attrs:{"cols":"2","id":"buttonbar-right"}},[_c('draggable-tools',{attrs:{"draggable":".item","group":"items"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.updateButtonsArr(_vm.side)}},model:{value:(_vm.taskbarButtons),callback:function ($$v) {_vm.taskbarButtons=$$v},expression:"taskbarButtons"}},_vm._l((_vm.taskbarButtons),function(item){return _c('button',{directives:[{name:"show",rawName:"v-show",value:(
                            !(
                                item.ProjectDependent &&
                                item.ProjectList === ''
                            )
                        ),expression:"\n                            !(\n                                item.ProjectDependent &&\n                                item.ProjectList === ''\n                            )\n                        "}],key:item.ToolID,class:_vm.projectOptionClass(
                                item.ProjectDependent,
                                item.ProjectList
                            ),attrs:{"id":item.ToolID,"disabled":item.ProjectDependent &&
                                _vm.projectNotSelected(item.ProjectList)},on:{"click":function($event){return _vm.taskbarButtonClick(item, _vm.side)}}},[_c('img',{staticClass:"pb-1",attrs:{"width":"26","src":_vm.toolIconPath(item.ToolName)}}),_vm._v(" "+_vm._s(item.ToolName)+" ")])}),0)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }