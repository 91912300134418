<template>
    <div>
        <div>
            <div class="Que-btn">
                <small
                    v-if="reviewItem.length"
                    class="text-primary reviewQueueButton"
                    @click="reviewQueue"
                    >Review queue</small
                >
                <small
                    v-if="!reviewItem.length"
                    class="text-secondary reviewQueueButton"
                    >Review queue</small
                >
                <span v-if="reviewItem.length">
                    <b-button
                        rounded
                        class="buttonSelect queueChange ml-3"
                        @click="queueProjectChanges()"
                    >
                        Queue changes
                    </b-button>
                </span>
                <span v-if="!reviewItem.length">
                    <b-button
                        v-if="groupIds.length || userIds.length"
                        rounded
                        class="buttonSelect queueChange ml-3"
                        @click="queueProjectChanges()"
                    >
                        Queue changes
                    </b-button>
                    <b-button
                        v-if="!groupIds.length || !userIds.length"
                        rounded
                        class="buttonSelect disableButton queueChange ml-3"
                    >
                        Queue changes
                    </b-button>
                </span>
            </div>
            <b-row class="access-blk">
                <b-col class="mt-2">
                    <div class="card bg-light mb-3 border-grey">
                        <div class="card-header">Groups</div>
                        <div class="card-body">
                            <div class="internal-body">
                                <b-row class="row1">
                                    <img
                                        class="pointer ml-2 my-3"
                                        src="../../../assets/search-outline.svg"
                                        v-if="
                                            !searchProject && !showSearchIcon
                                                ? true
                                                : false
                                        "
                                        @click="searchOpen"
                                        @mouseover="searchOpen"
                                    />
                                    <span
                                        class="blue-text ml-2 mb-1 searchPlaceholder"
                                        v-if="
                                            !searchProject && !showSearchIcon
                                                ? true
                                                : false
                                        "
                                        @click="searchOpen"
                                        @mouseover="searchOpen"
                                        >Search</span
                                    >
                                    <input
                                        ref="user"
                                        spellcheck="false"
                                        id="searchFilter"
                                        class=""
                                        v-if="
                                            searchProject || showSearchIcon
                                                ? true
                                                : false
                                        "
                                        v-model="searchProject"
                                        :class="
                                            searchProject || showSearchIcon
                                                ? 'test'
                                                : 'searchinputList'
                                        "
                                        type="search"
                                        placeholder="Start typing and hit enter to search"
                                    />
                                    <span
                                        class="grey-bg pointer"
                                        v-if="searchClose"
                                        @click="closeBar"
                                        ><img src="../../../assets/close.svg"
                                    /></span>
                                </b-row>
                                <div class="header">
                                    <input
                                        type="checkbox"
                                        class="pl-3 ml-2"
                                        @click="selectAllGroups"
                                        v-model="allSelected"
                                    /><span class="portal-lbl">Name</span>
                                    <!-- <b-form-checkbox
                    id="checkbox-1"
                    type="checkbox"
                    @click="selectAllGroups"
                    v-model="allSelected"
                    class="pl-3 ml-2"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                    >
                    Name
                    </b-form-checkbox> -->
                                </div>
                                <b-row class="showProjectList">
                                    <table
                                        class="table"
                                        striped
                                        id="groupTable"
                                        :per-page="perPage"
                                        :current-page="currentPage"
                                    >
                                        <!-- <thead>
                                    <tr>
                                    
                                    <th scope="col">
                                    </tr>
                                </thead> -->
                                        <tbody>
                                            <tr
                                                v-for="(u,
                                                index) in groupUsersFilteredList"
                                                :key="index"
                                                :value="u.UserGroupName"
                                            >
                                                <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                                <td>
                                                    <b-card
                                                        no-body
                                                        class="mb-1"
                                                    >
                                                        <b-card-header
                                                            header-tag="header"
                                                            class="p-0 accordian-header"
                                                            role="tab"
                                                            ><input
                                                                type="checkbox"
                                                                class="checkbox-allocate"
                                                                @click="
                                                                    checkedGroups(
                                                                        u.UserGroupID
                                                                    )
                                                                "
                                                                :value="
                                                                    u.UserGroupID
                                                                "
                                                                v-model="
                                                                    groupIds
                                                                "
                                                            />
                                                            <b-button
                                                                block
                                                                v-b-toggle="
                                                                    'imagedetails-accordion' +
                                                                        index
                                                                "
                                                                class="accordion-button"
                                                            >
                                                                <span>{{
                                                                    u.UserGroupName
                                                                }}</span></b-button
                                                            >
                                                        </b-card-header>

                                                        <b-collapse
                                                            :id="
                                                                'imagedetails-accordion' +
                                                                    index
                                                            "
                                                            :accordion="
                                                                'imagedetails-accordion' +
                                                                    index
                                                            "
                                                            role="tabpanel"
                                                            v-model="
                                                                collapseStates[
                                                                    index
                                                                ]
                                                            "
                                                        >
                                                            <b-card-body
                                                                class="contentCollapse"
                                                            >
                                                                <table
                                                                    class="text-card"
                                                                    striped
                                                                >
                                                                    <tbody>
                                                                        <tr
                                                                            v-if="
                                                                                u.UserGroupDetails &&
                                                                                    u
                                                                                        .UserGroupDetails
                                                                                        .length
                                                                            "
                                                                        >
                                                                            <b-row
                                                                                class="row1"
                                                                            >
                                                                                <img
                                                                                    class="pointer ml-2 my-3"
                                                                                    src="../../../assets/search-outline.svg"
                                                                                    v-if="
                                                                                        !searchGroupEmailModel &&
                                                                                        !showSearchIconGroups
                                                                                            ? true
                                                                                            : false
                                                                                    "
                                                                                    @click="
                                                                                        searchOpenGroups(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                    @mouseover="
                                                                                        searchOpenGroups(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                />
                                                                                <span
                                                                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                    v-if="
                                                                                        !searchGroupEmailModel &&
                                                                                        !showSearchIconGroups
                                                                                            ? true
                                                                                            : false
                                                                                    "
                                                                                    @click="
                                                                                        searchOpenGroups(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                    @mouseover="
                                                                                        searchOpenGroups(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                    >Search</span
                                                                                >
                                                                                <input
                                                                                    ref="userInner"
                                                                                    spellcheck="false"
                                                                                    id="searchFilter"
                                                                                    class="w-357"
                                                                                    v-if="
                                                                                        searchGroupEmailModel ||
                                                                                        showSearchIconGroups
                                                                                            ? true
                                                                                            : false
                                                                                    "
                                                                                    v-model="
                                                                                        searchGroupEmailModel
                                                                                    "
                                                                                    :class="
                                                                                        searchGroupEmailModel ||
                                                                                        showSearchIconGroups
                                                                                            ? 'test'
                                                                                            : 'searchinputList'
                                                                                    "
                                                                                    type="search"
                                                                                    placeholder="Start typing and hit enter to search"
                                                                                />
                                                                                <span
                                                                                    class="grey-bg pointer"
                                                                                    v-if="
                                                                                        searchCloseGroups
                                                                                    "
                                                                                    @click="
                                                                                        closeBarGroups
                                                                                    "
                                                                                    ><img
                                                                                        src="../../../assets/close.svg"
                                                                                /></span>
                                                                                <!-- <span class="border-right ml-2">&nbsp;</span> -->
                                                                            </b-row>
                                                                        </tr>
                                                                        <tr
                                                                            v-for="ku in $options.filters.filterByProperty(
                                                                                u.UserGroupDetails,
                                                                                '',
                                                                                'UserName',
                                                                                searchGroupEmailModel
                                                                            )"
                                                                            :key="
                                                                                ku.UserName
                                                                            "
                                                                            :value="
                                                                                ku.UserId
                                                                            "
                                                                        >
                                                                            <td
                                                                                class=""
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    class="slash-checkbox"
                                                                                    checked
                                                                                    @click="
                                                                                        childCheck(
                                                                                            u.UserGroupID,
                                                                                            ku.UserId,
                                                                                            $event
                                                                                        )
                                                                                    "
                                                                                />
                                                                                <span
                                                                                    class="marginLeft25"
                                                                                    >{{
                                                                                        ku.UserName
                                                                                    }}</span
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-row>
                            </div>
                        </div>
                        <div
                            class="card-footer"
                            v-if="groupUserList.length > 0"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="groupTable"
                                @change="collapseAll"
                            ></b-pagination>
                        </div>
                    </div>
                </b-col>

                <b-col class="mt-2 pl-2 ml-5">
                    <div class="card bg-light mb-3 border-grey maxWidth499">
                        <div class="card-header">Users</div>
                        <div class="card-body">
                            <div class="internal-body">
                                <b-row class="row1">
                                    <img
                                        class="pointer ml-2 my-3"
                                        src="../../../assets/search-outline.svg"
                                        v-if="
                                            !searchProjectDatasets &&
                                            !showSearchIconUsers
                                                ? true
                                                : false
                                        "
                                        @click="searchOpenUsers"
                                        @mouseover="searchOpenUsers"
                                    />
                                    <span
                                        class="blue-text ml-2 mb-1 searchPlaceholder"
                                        v-if="
                                            !searchProjectDatasets &&
                                            !showSearchIconUsers
                                                ? true
                                                : false
                                        "
                                        @click="searchOpenUsers"
                                        @mouseover="searchOpenUsers"
                                        >Search</span
                                    >
                                    <input
                                        ref="groups"
                                        spellcheck="false"
                                        id="searchFilter"
                                        class=""
                                        v-if="
                                            searchProjectDatasets ||
                                            showSearchIconUsers
                                                ? true
                                                : false
                                        "
                                        v-model="searchProjectDatasets"
                                        :class="
                                            searchProjectDatasets ||
                                            showSearchIconUsers
                                                ? 'test'
                                                : 'searchinputList'
                                        "
                                        type="search"
                                        placeholder="Start typing and hit enter to search"
                                    />
                                    <span
                                        class="grey-bg pointer"
                                        v-if="searchCloseUsers"
                                        @click="closeBarUsers"
                                        ><img src="../../../assets/close.svg"
                                    /></span>
                                </b-row>
                                <div class="header">
                                    <input
                                        type="checkbox"
                                        class="pl-3 ml-2"
                                        @click="selectAllUsers"
                                        v-model="allSelectedUsers"
                                    />
                                    <span class="portal-lbl">Name</span>
                                </div>
                                <b-row class="showProjectList">
                                    <table
                                        class="table"
                                        id="userTable"
                                        :items="usersFilteredList.userName"
                                        :per-page="perPageUser"
                                        :current-page="currentPageUser"
                                        striped
                                    >
                                        <tbody>
                                            <tr
                                                v-for="(ug,
                                                index) in usersFilteredList"
                                                :key="index"
                                                :value="ug.UserName"
                                            >
                                                <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                                <td>
                                                    <b-card
                                                        no-body
                                                        class="mb-1"
                                                    >
                                                        <b-card-header
                                                            header-tag="header"
                                                            class="p-0 accordian-header"
                                                            role="tab"
                                                            ><input
                                                                type="checkbox"
                                                                class="checkbox-allocate"
                                                                v-model="
                                                                    userIds
                                                                "
                                                                @click="
                                                                    checkedUsers(
                                                                        ug.UserID
                                                                    )
                                                                "
                                                                :value="
                                                                    ug.UserID
                                                                "
                                                            />
                                                            <b-button
                                                                block
                                                                v-b-toggle="
                                                                    'imagedetails-accordion1' +
                                                                        index
                                                                "
                                                                class="accordion-button"
                                                            >
                                                                <span>{{
                                                                    ug.UserName
                                                                }}</span></b-button
                                                            >
                                                        </b-card-header>

                                                        <b-collapse
                                                            :id="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            :accordion="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            role="tabpanel"
                                                            v-model="
                                                                collapseStatesUsers[
                                                                    index
                                                                ]
                                                            "
                                                        >
                                                            <b-card-body
                                                                class="contentCollapse"
                                                            >
                                                                <table
                                                                    class="text-card"
                                                                    striped
                                                                >
                                                                    <tbody>
                                                                        <tr
                                                                            v-if="
                                                                                ug.UserGroups &&
                                                                                    ug
                                                                                        .UserGroups
                                                                                        .length
                                                                            "
                                                                        >
                                                                            <b-row
                                                                                class="row1"
                                                                            >
                                                                                <img
                                                                                    class="pointer ml-2 my-3"
                                                                                    src="../../../assets/search-outline.svg"
                                                                                    v-if="
                                                                                        !searchUserGroupNameModel &&
                                                                                        !showSearchIconUsersInner
                                                                                            ? true
                                                                                            : false
                                                                                    "
                                                                                    @click="
                                                                                        searchOpenUsersInner(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                    @mouseover="
                                                                                        searchOpenUsersInner(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                />
                                                                                <span
                                                                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                    v-if="
                                                                                        !searchUserGroupNameModel &&
                                                                                        !showSearchIconUsersInner
                                                                                            ? true
                                                                                            : false
                                                                                    "
                                                                                    @click="
                                                                                        searchOpenUsersInner(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                    @mouseover="
                                                                                        searchOpenUsersInner(
                                                                                            index
                                                                                        )
                                                                                    "
                                                                                    >Search</span
                                                                                >
                                                                                <input
                                                                                    ref="groupInner"
                                                                                    spellcheck="false"
                                                                                    id="searchFilter"
                                                                                    class="w-357"
                                                                                    v-if="
                                                                                        searchUserGroupNameModel ||
                                                                                        showSearchIconUsersInner
                                                                                            ? true
                                                                                            : false
                                                                                    "
                                                                                    v-model="
                                                                                        searchUserGroupNameModel
                                                                                    "
                                                                                    :class="
                                                                                        searchUserGroupNameModel ||
                                                                                        showSearchIconUsersInner
                                                                                            ? 'test'
                                                                                            : 'searchinputList'
                                                                                    "
                                                                                    type="search"
                                                                                    placeholder="Start typing and hit enter to search"
                                                                                />
                                                                                <span
                                                                                    class="grey-bg pointer"
                                                                                    v-if="
                                                                                        showSearchIconUsersInner
                                                                                    "
                                                                                    @click="
                                                                                        closeBarUsersInner
                                                                                    "
                                                                                    ><img
                                                                                        src="../../../assets/close.svg"
                                                                                /></span>
                                                                            </b-row>
                                                                        </tr>
                                                                        <tr
                                                                            v-for="gg in $options.filters.filterByProperty(
                                                                                ug.UserGroups,
                                                                                '',
                                                                                'UserGroupName',
                                                                                searchUserGroupNameModel
                                                                            )"
                                                                            :key="
                                                                                gg.UserGroupName
                                                                            "
                                                                            :value="
                                                                                gg.UserGroupName
                                                                            "
                                                                        >
                                                                            <td
                                                                                class=""
                                                                            >
                                                                                <input
                                                                                    type="checkbox"
                                                                                    class="slash-checkbox"
                                                                                    checked
                                                                                    @click="
                                                                                        childCheck(
                                                                                            gg.UserGroupID,
                                                                                            ug.UserID,
                                                                                            $event
                                                                                        )
                                                                                    "
                                                                                />
                                                                                <span
                                                                                    class="marginLeft25"
                                                                                    >{{
                                                                                        gg.UserGroupName
                                                                                    }}</span
                                                                                >
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-row>

                                <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="userGroupList"
                    @changePage="onChangeGroupUserPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                            </div>
                        </div>
                        <div
                            class="card-footer"
                            v-if="userGroupList.length > 0"
                        >
                            <b-pagination
                                v-model="currentPageUser"
                                :total-rows="rowsUser"
                                :per-page="perPageUser"
                                aria-controls="userTable"
                                @change="collapseAllUsers"
                            ></b-pagination>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-modal
                ref="users-modal"
                class="model_style"
                hide-header
                hide-footer
                centered
                size="lg"
            >
                <div class="d-block text-center" v-if="!reviewItem.length">
                    <div class="text-right cursor-pointer">
                        <img
                            src="../../../assets/close_icon.svg"
                            @click="hideModal"
                        />
                    </div>
                    There is no item to review.
                </div>
                <div class="d-block" v-if="reviewItem.length">
                    <div class="text-right cursor-pointer">
                        <img
                            src="../../../assets/close_icon.svg"
                            @click="hideModal"
                        />
                    </div>
                    <div class="text-center">
                        <h4 class="my-2">Change queue</h4>
                        <small
                            >Review {{ reviewItem.length }} changes pending
                            before commiting the changes.</small
                        >
                    </div>
                    <div class="my-3 tblScroll">
                        <table class="table table-striped dataSetModal">
                            <tbody>
                                <tr>
                                    <th></th>
                                    <th>Access</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr
                                    v-for="(item, index) in reviewItem"
                                    :key="index"
                                >
                                    <td>{{ item.groupName }}</td>
                                    <td v-if="item.isActive">
                                        <img
                                            src="../../../assets/icon_tick.svg"
                                        />
                                    </td>
                                    <td v-if="!item.isActive">
                                        <img src="../../../assets/close.svg" />
                                    </td>
                                    <td>{{ item.userName }}</td>
                                    <td
                                        class="deleteCell"
                                        @click="delQueue(index)"
                                    >
                                        Delete from queue&nbsp;
                                        <img src="../../../assets/close.svg" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-3 mb-4 text-center">
                        <a class="text-primary" @click="clearQueue"
                            >Clear queue
                        </a>
                        <button
                            class="buttonSelect ml-3"
                            @click="commitChanges"
                        >
                            Commit changes
                        </button>
                    </div>
                </div>
            </b-modal>
            <ToastMessages
                :messageTitle="messageTitle"
                :showSuccess="showSuccess"
                :showFailure="showFailure"
                :failureToastMessage="failureToastMessage"
                :successToastMessage="successToastMessage"
            />
            <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../../assets/success_tick.svg" class="mr-3"/>
               <span v-if="reviewItem.length" class="grey-txt"><b>Changes queued </b><br/>{{successToastValue}}<br/>All the requested changes to be inserted into the queue.</span>
               <span v-if="!reviewItem.length" class="grey-txt">{{successToastValue}}</span>
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../../assets/failure_cross.svg" class="mr-3"/>
                {{failureToastValue}}
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
        </div>
    </div>
</template>

<script>
// import router from "../../router";

import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
// import JwPagination from "jw-vue-pagination";
// Vue.component("jw-pagination", JwPagination);
import ToastMessages from '../../ToastMessages.vue';
import { mapActions, mapMutations } from 'vuex';

// const customLabels = {
//   first: "<<",
//   last: ">>",
//   previous: "<",
//   next: ">",
// };

export default {
    components: {
        ToastMessages,
    },
    props: ['groupCreated'],
    watch: {
        groupCreated: function(newVal, oldVal) {
            // watch it
            this.getGroupUser();
            this.getUsersGroup();
        },
    },
    data() {
        return {
            allSelected: false,
            allSelectedUsers: false,
            userIds: [],
            groupIds: [],
            searchText: true,
            searchTextData: true,
            showTextData: false,
            // customLabels,
            showText: false,
            searchProject: '',
            searchProjectDatasets: '',
            perPage: 5,
            perPageUser: 6,
            groupUserList: [],
            groupUserListFiltered: [],
            pageOfItems: [],
            userGroupList: [],
            userGroupListFiltered: [],
            pageOfGroupUsers: [],
            reviewItem: [],
            // successToast: false,
            // failureToast:false,
            // successToastValue:'',
            // failureToastValue:'',
            showPage: false,
            thisfilename: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            // perPage: 3,
            currentPage: 1,
            currentPageUser: 1,
            searchGroupEmailInput: true,
            searchGroupEmailModel: '',
            searchUserGroupNameInput: true,
            searchUserGroupNameModel: '',
            collapseStates: [],
            collapseStatesUsers: [],
            showSearchIcon: false,
            searchClose: false,
            showSearchIconGroups: false,
            searchCloseGroups: false,
            showSearchIconUsers: false,
            searchCloseUsers: false,
            searchCloseUsersInner: false,
            showSearchIconUsersInner: false,
        };
    },
    created() {
        this.getGroupUser();
        this.getUsersGroup();
        this.getNewGroup();
    },
    mounted() {
        //  this.getPermissions();
        this.pagePermit();
    },
    beforeDestroy() {
        localStorage.removeItem('newGroupName');
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('GAUsers')) {
                    vm.showPage = true;
                    return true;
                }
            });
            this.$emit('clickedUser', this.showPage);
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.user.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.searchProject = null;
        },

        searchOpenGroups(index) {
            this.searchCloseGroups = true;
            this.showSearchIconGroups = true;
            this.$nextTick(() => {
                this.$refs.userInner[index].focus();
            });
        },
        closeBarGroups() {
            this.searchCloseGroups = !this.searchCloseGroups;
            this.showSearchIconGroups = false;
            this.searchGroupEmailModel = null;
        },

        searchOpenUsers() {
            this.searchCloseUsers = !this.searchCloseUsers;
            this.showSearchIconUsers = true;
            this.$nextTick(() => {
                this.$refs.groups.focus();
            });
        },
        closeBarUsers() {
            this.searchCloseUsers = !this.searchCloseUsers;
            this.showSearchIconUsers = false;
            this.searchProjectDatasets = null;
        },

        searchOpenUsersInner(index) {
            this.searchCloseUsersInner = !this.searchCloseUsersInner;
            this.showSearchIconUsersInner = true;
            this.$nextTick(() => {
                this.$refs.groupInner[index].focus();
            });
        },
        closeBarUsersInner() {
            this.searchCloseUsersInner = !this.searchCloseUsersInner;
            this.showSearchIconUsersInner = false;
            this.searchUserGroupNameModel = null;
        },
        // searchinputenter(args) {
        //     if(args){
        //     this.showSearchIcon = false;
        //     if(!this.searchProject){
        //         this.searchClose = false;
        //         }
        //     }
        // },
        collapseAll() {
            this.collapseStates = this.groupUserList.map(
                (x) => (x.open = false)
            );
        },
        collapseAllUsers() {
            this.collapseStatesUsers = this.userGroupList.map(
                (x) => (x.open = false)
            );
        },
        reviewQueue() {
            this.$refs['users-modal'].show();
        },
        getNewGroup() {
            if (localStorage.getItem('newGroupName')) {
                this.searchText = false;
                this.showText = true;
                this.searchClose = true;
                this.showSearchIcon = true;
                this.$nextTick(() => {
                    this.$refs.user.focus();
                });
                this.searchProject = localStorage.getItem('newGroupName');
            } else {
                this.searchProject = '';
            }
        },
        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                data.push({
                    UserGroupID: item.groupID,
                    UserID: item.userId,
                    isActive: item.isActive,
                    IsIncluded: true,
                });
            });

            managementService
                .addOrUpdateUserToUserGroup(data)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.setToastMessage({
                            showSuccess: true,
                            showFailure: false,
                            successToastMessage: res.data.Message,
                            failureToastMessage: null,
                            messageTitle: null,
                        });

                        this.reviewItem = [];
                        this.getGroupUser();
                        this.getUsersGroup();
                        this.groupIds = [];
                        this.userIds = [];
                        this.$refs['users-modal'].hide();
                    } else if (res.data.StatusCode === 500) {
                        this.$refs['users-modal'].hide();

                        this.setToastMessage({
                            showSuccess: false,
                            showFailure: true,
                            successToastMessage: null,
                            failureToastMessage: res.data.Message,
                            messageTitle: null,
                        });
                    }
                })
                .catch((err) => {
                    if (err) {
                        this.$refs['users-modal'].hide();

                        this.setToastMessage({
                            showSuccess: false,
                            showFailure: true,
                            successToastMessage: null,
                            failureToastMessage: 'Something went wrong',
                            messageTitle: null,
                        });
                    }
                });
        },
        clearQueue() {
            this.groupIds = [];
            this.userIds = [];
            this.reviewItem = [];
            this.$refs['users-modal'].hide();
        },
        closeSuccessToast() {
            this.successToast = false;
        },
        closeFailureToast() {
            this.failureToast = false;
        },
        hideModal() {
            this.$refs['users-modal'].hide();
        },
        delQueue(index) {
            let removeItem = this.reviewItem[index];
            this.reviewItem.splice(index, 1);
            let groupIdFound = this.reviewItem.findIndex(
                (item) => item.groupID == removeItem.groupID
            );
            if (groupIdFound == -1) {
                let groupId = this.groupIds.findIndex(
                    (item) => item == removeItem.groupID
                );
                this.groupIds.splice(groupId, 1);
            }
            let userIdFound = this.reviewItem.findIndex(
                (item) => item.userId == removeItem.userId
            );
            if (userIdFound == -1) {
                let userId = this.userIds.findIndex(
                    (item) => item == removeItem.userId
                );
                this.userIds.splice(userId, 1);
            }

            /*if(!this.reviewItem.length){
                this.$refs["users-modal"].hide();
            }*/
        },
        searchGroupPage(search) {
            this.groupUserList = this.userGroupList.filter((item) => {
                return item.UserGroupName.includes(search);
            });
        },
        childCheck(groupId, userId, event) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.groupID == groupId && item.userId == userId;
            });
            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    this.reviewItem.splice(unCheckedItem, 1);
                }
            } else {
                let groupName = this.groupUserListFiltered.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                //
                let toolName = this.userGroupListFiltered.filter((item) => {
                    return item.UserID == userId;
                });
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return item.groupID == groupId && item.userId == userId;
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            groupID: groupId,
                            userId: userId,
                            groupName: groupName[0].UserGroupName,
                            userName: toolName[0].UserName,
                            isActive: false,
                        });
                    }
                }
            }
        },

        queueProjectChanges() {
            if (
                (this.groupIds.length && this.userIds.length) ||
                this.reviewItem.length
            ) {
                // this.reviewItem=[]
                let selectedGroups = {};
                this.groupIds.forEach((groupItem) => {
                    let selectedUsers = [];
                    this.userIds.forEach((userItem) => {
                        selectedUsers.push(userItem);
                    });
                    selectedGroups[groupItem] = selectedUsers;
                });
                for (let props in selectedGroups) {
                    let groupName = this.groupUserListFiltered.filter(
                        (item) => {
                            return item.UserGroupID == props;
                        }
                    );
                    var vm = this;
                    for (let i = 0; i < selectedGroups[props].length; i++) {
                        let userName = vm.userGroupListFiltered.filter(
                            (subItem) => {
                                return (
                                    subItem.UserID == selectedGroups[props][i]
                                );
                            }
                        );
                        let exist = vm.reviewItem.findIndex(
                            (item) =>
                                item.groupID == props &&
                                item.userId == selectedGroups[props][i]
                        );
                        if (exist === -1) {
                            vm.reviewItem.push({
                                groupID: props,
                                userId: selectedGroups[props][i],
                                groupName: groupName[0].UserGroupName,
                                userName: userName[0].UserName,
                                isActive: true,
                            });
                        }
                    }
                }
                /* if(this.groupIds.length && this.userIds.length){
                    this.groupIds.forEach((groupItem)=>{
                    let groupName = this.groupUserList.filter((item)=>{
                        return item.UserGroupID == groupItem;
                    })
                    // 
                    this.userIds.forEach((userItem)=>{
                        let userName = this.userGroupList.filter((subItem)=>{
                            return subItem.UserId == userItem;
                        })

                       this.reviewItem.push({
                            "groupID":groupItem,
                            "userId":userItem,
                            "groupName": groupName[0].UserGroupName,
                            "userName": userName[0].UserName,
                            "isActive": true
                        })

                        this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.groupID)))
                            .map(id=>{
                                return this.reviewItem.find(a=> a.groupID ===id)
                        })
                    })
                    })
                }*/

                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';

                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    successToastMessage: null,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: null,
                });
            }
        },
        selectAllGroups(u) {
            this.groupIds = [];
            if (!this.allSelected) {
                for (u in this.groupUserList) {
                    this.groupIds.push(this.groupUserList[u].UserGroupID);
                }
            }
        },
        selectAllUsers(ug) {
            this.userIds = [];
            if (!this.allSelectedUsers) {
                for (ug in this.userGroupList) {
                    this.userIds.push(this.userGroupList[ug].UserID);
                }
            }
        },
        checkedGroups(id) {
            if (this.groupIds.indexOf(id) > -1) {
                this.groupIds.splice(this.groupIds.indexOf(id), 1);
                this.allSelected = false;
            } else {
                this.groupIds.push(id);
            }
        },
        checkedUsers(id) {
            if (this.userIds.indexOf(id) > -1) {
                this.userIds.splice(this.userIds.indexOf(id), 1);
                this.allSelectedUsers = false;
            } else {
                this.userIds.push(id);
            }
        },
        getGroupUser() {
            managementService.getGroupUser().then((res) => {
                this.groupUserList = res.data;
                this.groupUserListFiltered = res.data;
            });
        },
        getUsersGroup() {
            managementService.getUsersGroup().then((res) => {
                this.userGroupList = res.data;
                this.userGroupListFiltered = res.data;
            });
        },
        onChangegroupUserPage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        addSearchText() {
            if (this.searchProject.length == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextData() {
            if (this.searchProjectDatasets.length == 0) {
                this.searchTextData = true;
                this.showTextData = false;
            }
        },
        removeSearchTextData() {
            this.searchTextData = false;
            this.showTextData = true;
        },
        // selectAll(p) {
        //     this.projectuserIds = [];

        //     if (!this.allSelected) {
        //         for (p in this.groupUserList) {
        //         this.projectuserIds.push(this.groupUserList[p].id);
        //         }
        //     }
        // },
        onChangeGroupUserPage(pageOfGroupUsers) {
            this.pageOfGroupUsers = pageOfGroupUsers;
        },

        checkedBox() {},
    },
    computed: {
        groupUsersFilteredList() {
            let vm = this;
            if (vm.searchProject) {
                // return vm.groupUserList.filter((post) => {
                //     return (
                //         post.UserGroupName.toLowerCase().includes(vm.searchProject.toLowerCase())
                //     );
                // });
                vm.groupUserList = vm.groupUserListFiltered.filter((post) => {
                    return post.UserGroupName.toLowerCase().includes(
                        vm.searchProject.toLowerCase()
                    );
                });
                return vm.groupUserList.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                );
            } else {
                // return this.groupUserListFiltered;
                vm.groupUserList = vm.groupUserListFiltered;
                return vm.groupUserList.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                );
            }
        },
        // filteredItems() {
        //     if (this.searchQuery) {
        //         return this.markerSettings[0].dataSource.filter((item) => {
        //         return (
        //             item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
        //         );
        //         });
        //     } else {
        //         return this.markerSettings[0].dataSource;
        //     }
        // },
        usersFilteredList() {
            let vm = this;
            if (vm.searchProjectDatasets) {
                vm.userGroupList = vm.userGroupListFiltered.filter((post) => {
                    return post.UserName.toLowerCase().includes(
                        vm.searchProjectDatasets.toLowerCase()
                    );
                });
                return vm.userGroupList.slice(
                    (vm.currentPageUser - 1) * vm.perPageUser,
                    vm.currentPageUser * vm.perPageUser
                );
            } else {
                vm.userGroupList = vm.userGroupListFiltered;
                return vm.userGroupList.slice(
                    (vm.currentPageUser - 1) * vm.perPageUser,
                    vm.currentPageUser * vm.perPageUser
                );
            }

            // return this.userGroupList.filter((post) => {
            //     return post.UserName.toLowerCase().includes(
            //     this.searchProjectDatasets.toLowerCase()
            //     );
            // });
        },
        rows() {
            return this.groupUserList.length;
        },
        rowsUser() {
            return this.userGroupList.length;
        },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   color: black;
//   text-align: left;
//   span {
//     margin-left: 25px;
//     margin-left: 30px;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/blackminus.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   //width: 388px;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
.paginationStyle {
    border: none;
    /* margin: 0px auto !important; */
    width: 200px;
    margin: 0px;
    padding: 0px;
    //display: contents !important;
}
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
    color: #1d1d1d !important;
}
// input.test {
//   width: 411px;
// }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 405px;
}
// .buttonSelect.queueChange{
//     position: absolute;
//     right: 20px;
//     top: -100px;
// }
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .row1 {
        border-bottom: 3px solid #eaeaea;
        // padding-top: 10px;
        // h6.blue {
        //   color: #006395 !important;
        // }
    }
    .showProjectList {
        overflow: auto;
        height: 300px;
        width: 457px;
    }
    .header {
        padding: 10px;
        border-bottom: 3px solid;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
// .reviewQueueButton{
//     position: absolute;
//     top:-85px;
//     right:175px;
//     cursor: pointer;
// }
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
.table-striped tbody tr:first-child {
    background-color: white;
}
.userrightmanagement .width357 {
    width: 357px !important;
}
</style>
