<template>
    <b-container class="d-p-0 m-0 test">
        <p class="mt-2 col-md-8 py-3 regular-text data-normal">
            Define the fields which would be available for the user to include
            in the dataset form view filter mode, the order of these fields,and
            a header/grouping of these fields so that the filtered information
            can be shown in logical order.
        </p>
        <div class="col-md-8 p-0 icon-hide tree-blk">
            <div class="ml-10 mt-3">
                <div id="app" class="mb-4">
                    <h3>Show in form view</h3>
                    <ejs-treeview
                        ref="treeview"
                        id="treeview"
                        :fields="treeViewFeilds"
                        allowDragAndDrop="true"
                        cssClass="customTree"
                        :nodeTemplate="NewTemplate"
                        :nodeDragStop="showDragStop"
                    ></ejs-treeview>
                </div>
                <div id="app1" class="mb-4 hidden-block">
                    <!-- treeview1 -->
                    <h3>Hidden in form view</h3>
                    <ejs-treeview
                        ref="treeview"
                        id="treeview-hidden"
                        cssClass="custom"
                        :fields="treeViewFeildsHidden"
                        allowDragAndDrop="true"
                        :nodeTemplate="NewTemplate"
                        :nodeDragStop="nodeDragStop1"
                    ></ejs-treeview>
                </div>
                <div id="app2" class="mb-3 treedelete">
                    <h3>Create a new header group</h3>
                    <ejs-treeview
                        ref="treeview"
                        id="newHeaders"
                        :fields="newHeader"
                        cssClass="custom"
                        allowDragAndDrop="true"
                        :nodeDragStop="nodeDragStopHeader"
                        :nodeTemplate="Template"
                    ></ejs-treeview>
                </div>
            </div>
        </div>
        <span class="e-icons headerDots e-custom px-1 icon-left"></span>
        <span class="badge flt-option">
            <CustomFloatingLabel
                :config="{
                    label: 'Header group name',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input
                    type="text"
                    class="normal_input"
                    autocomplete="off"
                    v-model="headerGroupInput"
                />
            </CustomFloatingLabel>
        </span>
        <small class="headerError" v-if="xssPassed">
            Only numbers, letters, hypens, periods, spaces and underscores are
            allowed</small
        >
        <small class="headerError" v-if="emptyHeader"
            >Please do not add empty header</small
        >
        <small class="headerError" v-if="headerPresent"
            >Header already exists</small
        >
        <div>
            <button
                class="normal_input headergroup bottom-m-100"
                @click="addNewHeader"
                autocomplete="off"
            >
                Add a header group +
            </button>
        </div>
    </b-container>
</template>

<script>
import { TreeViewPlugin } from '@syncfusion/ej2-vue-navigations';
import CustomFloatingLabel from '../../../CustomFloatingLabel';
import { commonService } from '../../../../services/common.service';
import _ from 'lodash';
import Vue from 'vue';
Vue.use(TreeViewPlugin);
export default {
    name: 'datasetcolumnsNew',
    components: {
        CustomFloatingLabel,
    },
    props: {
        selectedColoumns: Array,
        showFilterOptions: Array,
        hiddenFilterOptions: Array,
        filterCols: Array,
        dataSetCols: Array,
    },
    data() {
        var demoVue = Vue.component('demo', {
            template:
                "<div><span class='e-icons e-custom' v-on:click='iconclick'></span><input class='textbox' type='text' v-model='data.name' required readonly /></div>",
            data() {
                return {
                    data: {},
                };
            },
            methods: {
                iconclick: () => {},
            },
        });
        var deleteView = Vue.component('demo', {
            template:
                "<div><span class='e-icons e-custom'></span><input class='textbox' type='text' v-model='data.name' required readonly /><span class='e-icons e-delete button' v-on:click='deleteNode'></span></div>",
            data() {
                return {
                    data: {},
                };
            },
            methods: {
                deleteNode: () => {
                    var treevalidate = document.getElementById('newHeaders')
                        .ej2_instances[0];
                    var targetNodeId = treevalidate.selectedNodes[0];
                    treevalidate.removeNodes([targetNodeId]);
                    var index = treevalidate.treeData.findIndex(
                        (p) => p.id == [targetNodeId][0]
                    );
                    treevalidate.treeData.splice(index, 1);
                },
            },
        });
        return {
            showDataSource: [],
            hiddenDataSource: [],
            newHeaderList: [],
            headerGroupInput: '',
            xssPassed: false,
            emptyHeader: false,
            headerPresent: false,
            isFromHiddenAll: false,
            treeViewFeilds: {
                dataSource: null,
                id: 'id',
                text: 'name',
                parentID: 'pid',
                hasChildren: 'hasChild',
                child: 'child',
            },
            NewTemplate: function() {
                return {
                    template: demoVue,
                };
            },
            Template: function() {
                return {
                    template: deleteView,
                };
            },
            nodeDragStopHeader: () => {
                var tree = document.getElementById('treeview').ej2_instances[0];
                this.$nextTick(() => {
                    this.createTreeView(tree.getTreeData());
                });
            },
        };
    },
    methods: {
        createEditHiddenTreeData() {
            //findout all hidden feilds
            let tempFilterCol = [...this.filterCols];
            let tempdtSetColumn = [...this.dataSetCols];

            let allHiddenCols = tempFilterCol.filter((col) => {
                return !col.IsVisible;
            });
            allHiddenCols.forEach((col, indx) => {
                this.hiddenDataSource.push({
                    id: indx.toString(),
                    name: col.ColumnName,
                    hasChild: true,
                    expanded: true,
                });
            });
            this.$nextTick(() => {
                this.emitHiddenShowArray();
            });
        },
        createEditTreeData() {
            let tempFilterCol = [...this.filterCols];
            let tempdtSetColumn = [...this.dataSetCols];
            let sortOrderArr = [];

            tempFilterCol.forEach((fCol) => {
                let name = tempdtSetColumn.find(
                    (col) => col.DatasetColumnId === fCol.DatasetColumnId
                ).ColumnName;
                fCol.ColumnName = name;
            });
            let tempFilterArray = tempFilterCol.filter(
                (item) => item.IsVisible
            );
            tempFilterArray.forEach((fCol, index) => {
                if (fCol.DatasetFilterGroup.GroupName) {
                    if (
                        sortOrderArr.findIndex((itm) => {
                            return (
                                itm.name === fCol.DatasetFilterGroup.GroupName
                            );
                        }) === -1
                    ) {
                        //take all childs
                        let childs = tempFilterArray.filter(
                            (itm) =>
                                fCol.DatasetFilterGroup.GroupName ===
                                itm.DatasetFilterGroup.GroupName
                        );
                        let tmpChildArray = [];
                        let tempObj = {};
                        tempObj.id = fCol.DatasetFilterGroup.SortOrder.toString();
                        tempObj.SortOrder = fCol.DatasetFilterGroup.SortOrder;
                        tempObj.name = fCol.DatasetFilterGroup.GroupName;
                        tempObj.hasChild = true;
                        tempObj.expanded = true;
                        childs.forEach((itm, idx) => {
                            let tempChild = {};
                            tempChild.id = `${tempObj.id}_${idx}`;
                            tempChild.name = itm.ColumnName;
                            tempChild.SortOrder = idx;
                            tmpChildArray.push(tempChild);
                        });
                        tempObj.child = tmpChildArray;
                        sortOrderArr.push(tempObj);
                    }
                } else {
                    let tempObj = {};
                    tempObj.id = `${fCol.SortOrder}`;
                    tempObj.SortOrder = fCol.SortOrder;
                    tempObj.name = fCol.ColumnName;
                    sortOrderArr.push(tempObj);
                }
            });
            this.$nextTick(() => {
                this.deleteAll();
                if (document.getElementById('treeview')) {
                    document
                        .getElementById('treeview')
                        .ej2_instances[0].addNodes(
                            sortOrderArr.sort((a, b) => {
                                return a.SortOrder - b.SortOrder;
                            }),
                            null
                        );
                }
                this.emitHiddenShowArray();
            });
        },
        addNewHeader() {
            var treevalidate = document.getElementById('newHeaders')
                .ej2_instances[0];
            var treevalidateArray = treevalidate.getTreeData();
            var targetNodeId = treevalidate.selectedNodes[0];
            var TreeInstance = document.getElementById('treeview')
                .ej2_instances[0];
            var TreeInstanceArray = TreeInstance.getTreeData();
            var exists = treevalidate.treeData.some((field) => {
                return field.name === this.headerGroupInput;
            });
            var existsShow = TreeInstance.treeData.some((field) => {
                return field.name === this.headerGroupInput;
            });
            if (
                this.headerGroupInput != '' &&
                this.headerGroupInput != null &&
                !exists &&
                !existsShow
            ) {
                if (commonService.xssValidation(this.headerGroupInput)) {
                    this.xssPassed = true;
                    this.headerPresent = false;
                    this.emptyHeader = false;
                } else {
                    this.xssPassed = false;
                    let index;
                    if (TreeInstanceArray) {
                        if (treevalidateArray.length > 0) {
                            const idArr = treevalidateArray.map((item) => {
                                return item.id;
                            });
                            var maxVal = Math.max.apply(Math, idArr);
                            index = maxVal + 1;
                        } else {
                            index = TreeInstanceArray.length + 1;
                        }
                    } else {
                        index = 0;
                    }
                    var nodeId = index;
                    var item = {
                        id: nodeId.toString(),
                        name: this.headerGroupInput,
                        hasChild: true,
                        child: [],
                    };
                    treevalidate.addNodes([item], targetNodeId, null);
                    this.emptyHeader = false;
                    this.headerPresent = false;
                }
            } else if (this.headerGroupInput == '') {
                this.xssPassed = false;
                this.emptyHeader = true;
                this.headerPresent = false;
            } else if (exists || existsShow) {
                this.headerPresent = true;
                this.emptyHeader = false;
                this.xssPassed = false;
            }
        },
        showDragStop: function(args) {
            var TreeInstance = document.getElementById('treeview')
                .ej2_instances[0];
            var TreeInstanceArray = TreeInstance.getTreeData();
            console.log(TreeInstanceArray);
            this.$nextTick(() => {
                this.createTreeView(TreeInstanceArray);
            });
        },
        nodeDragStop1: function(args) {},
        createTreeView(treeData) {
            let tempMainArr = [];
            treeData.forEach((treeEl, index) => {
                let tempObj = {};
                if (!treeEl.pid) {
                    tempObj.SortOrder = index;
                    tempObj.name = treeEl.name;
                    tempObj.child = [];
                    if (treeEl.child?.length > 0) {
                        tempObj.hasChild = true;
                        tempObj.expanded = true;
                        treeEl.child.forEach((child, inx) => {
                            let tempChild = {};
                            tempChild.name = child.name;
                            tempChild.id = `${index}_${inx + 1}`;
                            tempChild.SortOrder = inx;
                            tempObj.child.push(tempChild);
                        });
                    } else if (
                        treeData.filter((itm) => {
                            return itm.pid === treeEl.id;
                        }).length > 0
                    ) {
                        tempObj.hasChild = true;
                        tempObj.expanded = true;
                        let childs = treeData.filter((itm) => {
                            return itm.pid === treeEl.id;
                        });
                        childs.forEach((child, inx) => {
                            let tempChild = {};
                            tempChild.name = child.name;
                            tempChild.id = `${index}_${inx + 1}`;
                            tempChild.SortOrder = inx;
                            tempObj.child.push(tempChild);
                        });
                    }
                    tempObj.id = `${index}`;
                    tempMainArr.push(tempObj);
                }
            });
            this.deleteAll();
            if (document.getElementById('treeview')) {
                document
                    .getElementById('treeview')
                    .ej2_instances[0].addNodes(tempMainArr, null);

                var treevalidate = document.getElementById('treeview-hidden')
                    .ej2_instances[0];
                var treevalidateArray = treevalidate.getTreeData();
                this.$emit(
                    'setFilterOptionArrays',
                    tempMainArr,
                    treevalidateArray
                );
            }
        },
        //TODO:refactor deleteAll deleteHiddenTreeAll
        deleteAll() {
            let toDelete = [];
            if (document.getElementById('treeview')) {
                let tepTree = document.getElementById('treeview')
                    .ej2_instances[0];
                tepTree.getTreeData().forEach((item) => {
                    toDelete.push(item.id);
                });
                document
                    .getElementById('treeview')
                    .ej2_instances[0].removeNodes(toDelete, null);
            }
        },
        deleteHiddenTreeAll() {
            let toDelete = [];
            if (document.getElementById('treeview-hidden')) {
                let tepTree = document.getElementById('treeview-hidden')
                    .ej2_instances[0];
                tepTree.getTreeData().forEach((item) => {
                    toDelete.push(item.id);
                });
                document
                    .getElementById('treeview-hidden')
                    .ej2_instances[0].removeNodes(toDelete, null);
            }
        },
        addToShowTree(array) {
            let tempArray = [];
            array.forEach((item, index) => {
                let tempItm = {
                    SortOrder: index,
                    id: `${index}`,
                    name: item.name,
                };
                tempArray.push(tempItm);
            });
            this.$nextTick(() => {
                this.createTreeView([...tempArray]);
            });
        },
        emitHiddenShowArray() {
            var showArray = document
                .getElementById('treeview')
                .ej2_instances[0].getTreeData();
            var hiddenArray = document
                .getElementById('treeview-hidden')
                .ej2_instances[0].getTreeData();
            this.$emit('setFilterOptionArrays', showArray, hiddenArray);
        },
    },
    computed: {
        treeViewFeildsHidden() {
            return {
                dataSource: this.hiddenDataSource,
                id: 'id',
                text: 'name',
                parentID: 'pid',
                hasChildren: 'hasChild',
                child: 'child',
            };
        },
        newHeader() {
            return {
                dataSource: this.newHeaderList,
                id: 'id',
                parentID: 'pid',
                text: 'name',
                hasChildren: 'hasChild',
            };
        },
    },
    mounted: function() {
        // this.addToShowTree(
        //   this.showFilterOptions ? this.showFilterOptions : this.selectedColoumns
        // );

        if (this.filterCols.length === 0 && this.dataSetCols.length === 0) {
            var treeObj = document.getElementById('treeview').ej2_instances[0];
            let tempArray = [];
            this.selectedColoumns.forEach((item, index) => {
                let tempItm = {
                    SortOrder: index,
                    id: `${index}`,
                    name: item.name,
                };
                tempArray.push(tempItm);
            });
            treeObj.addNodes([...tempArray]);
            this.$nextTick(() => {
                this.emitHiddenShowArray();
            });
        } else {
            this.createEditTreeData();
            this.createEditHiddenTreeData();
        }
    },
    watch: {
        selectedColoumns: function(newVal, oldVal) {
            if (
                this.filterCols.length > 0 &&
                oldVal.length < 1 &&
                !this.isFromHiddenAll
            ) {
                this.createEditTreeData();
                this.createEditHiddenTreeData();
            } else {
                this.isFromHiddenAll = false;
                if (newVal.length > oldVal.length) {
                    let difference = newVal.filter((object1) => {
                        return !oldVal.some((object2) => {
                            return object1.name === object2.name;
                        });
                    });
                    var treevalidate = document.getElementById('treeview')
                        .ej2_instances[0];
                    var treevalidateArray = treevalidate.getTreeData();
                    var treeObj = document.getElementById('treeview')
                        .ej2_instances[0];
                    let tempArray = [];
                    difference.forEach((item, index) => {
                        let tempItm = {
                            SortOrder: treevalidateArray.length,
                            id: `${treevalidateArray.length}`,
                            name: item.name,
                        };
                        tempArray.push(tempItm);
                    });
                    treeObj.addNodes([...tempArray], treeObj.selectedNodes[0]);
                    this.$nextTick(() => {
                        this.emitHiddenShowArray();
                    });
                } else {
                    if (newVal.length === 0) {
                        this.deleteAll();
                        this.deleteHiddenTreeAll();
                        this.emitHiddenShowArray();
                        this.isFromHiddenAll = true;
                    } else {
                        let differenceOld = _.differenceBy(
                            oldVal,
                            newVal,
                            'name'
                        );
                        let treevalidate1 = document.getElementById('treeview')
                            .ej2_instances[0];
                        //find the node id of the node to deleted
                        //TODO:if new value.length === 0, clear the trees
                        let treeData = document
                            .getElementById('treeview')
                            .ej2_instances[0].getTreeData();

                        let nodeToDelete = '';
                        treeData.forEach((col) => {
                            if (col.hasChild) {
                                col.child.forEach((childCol) => {
                                    if (
                                        childCol.name === differenceOld[0].name
                                    ) {
                                        nodeToDelete = childCol.id;
                                    }
                                });
                            } else {
                                if (col.name === differenceOld[0].name) {
                                    nodeToDelete = col.id;
                                }
                            }
                        });
                        treevalidate1.removeNodes([nodeToDelete]);
                        this.$nextTick(() => {
                            var tree = document.getElementById('treeview')
                                .ej2_instances[0];
                            this.createTreeView(tree.getTreeData());
                            this.emitHiddenShowArray();
                        });
                    }
                }
            }
        },
    },
};
</script>

<style>
@import '../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
</style>

<style scoped>
.test {
    cursor: move !important;
}
.e-treeview .e-icons.e-icon-collapsible::before {
    content: url('../../../../assets/Overflow Menu.svg') !important;
    border: 2px solid red !important;
}
.e-treeview .e-list-item.e-hover > .e-fullrow,
.e-treeview .e-list-item.e-active > .e-fullrow {
    background-color: #eee;
}
.headerDots:before {
    font-size: 13px !important;
}
.e-custom:before {
    content: '\e330';
}
.e-custom {
    padding: 5px;
}

.e-delete:before {
    content: '\eb00';
}
.b .badge {
    text-align: left;
    padding: 0 !important;
}
.badge.tree-input {
    padding-left: 0 !important;
}
.headerError {
    display: block;
    position: relative;
    top: -20px;
    left: 5px;
    color: #f00;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    width: 520px;
    text-align: center;
}

#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    white-space: nowrap;
}
#treeview {
    min-height: 100px;
}

#treeview-hidden {
    min-height: 100px;
}
.textbox {
    border-radius: 2px;
    border: 1px solid #d6d0d0;
    height: 20px;
    width: 230px;
}
.icon-left {
    position: relative;
    float: left;
    margin-top: 15px;
    margin-left: -5px;
}
</style>
