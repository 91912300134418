import Vue from 'vue';
import { projectService } from '../services';

const state = {
    projects: [],
};

const actions = {
    toggleProjectLayerLabels({ commit }, val) {
        commit('toggleProjectLayerLabels', val);
    },
    updateProjectVisibility({ commit }, val) {
        commit('updateVisibility', val);
    },
    toggleProjectLayerClusteredAction({ commit }, val) {
        commit('toggleProjectLayerClustered', val);
    },
    appendInvisibleProjectGeometryToMapDataAction({ commit }, val) {
        commit('appendInvisibleProjectGeometryToMapData', val);
    },
};

const mutations = {
    addProjectLayerData(state, payload) {
        state.projects = payload;
    },
    clearMapData(state) {
        state.projects = [];
    },
    updateVisibility(state, val) {
        //index is which project
        //idx is which layer within project[index]
        state.projects[val.index].ProjectLayers[val.idx].visible = !state
            .projects[val.index].ProjectLayers[val.idx].visible;
    },
    updateWMSVisibility(state, val) {
        let index = val.index;
        let idx = val.idx;
        state.projects[index].WMSLayer[idx].visible = !state.projects[index]
            .WMSLayer[idx].visible;
    },
    toggleProjectLayerLabels(state, val) {
        let index = val.index;
        let idx = val.idx;
        //index is which project
        //idx is which layer within index project
        let dataSource = state.projects[index].ProjectLayers[idx].Geometry;
        if (
            !dataSource.labelVisibleIndex &&
            state.projects[index].ProjectLayers[idx].ShowLabels
        ) {
            for (let i = 0; i < dataSource.features[0].properties.length; i++) {
                if (dataSource.features[0].properties[i].tooltip) {
                    dataSource.labelVisibleIndex = i;
                }
            }
        }
        dataSource.features.forEach((geom) => {
            geom.properties[dataSource.labelVisibleIndex].tooltip = !geom
                .properties[dataSource.labelVisibleIndex].tooltip;
        });
    },
    toggleProjectLayerClustered(state, val) {
        let index = val.index;
        let idx = val.idx;
        let layer = state.projects[index].ProjectLayers[idx];
        layer.clustered = !layer.clustered;
        //index is which project
        //idx is which layer within index project

        Vue.set(state.projects[index].ProjectLayers, idx, layer);
    },
    appendInvisibleProjectGeometryToMapData(state, val) {
        val.forEach((proj, index) => {
            //index is which project
            if (proj.ProjectLayers) {
                proj.ProjectLayers.forEach((lyr, idx) => {
                    //idx is which layer in project[index]
                    if (
                        lyr.Geometry.visible == false &&
                        lyr.Geometry.features.length > 0
                    ) {
                        state.projects[index].ProjectLayers[
                            idx
                        ].Geometry.features = [...lyr.Geometry.features];
                        state.projects[index].ProjectLayers[
                            idx
                        ].geomDataLoaded = true;
                    }
                });
            }
        });
    },
};

const getters = {
    projectLyrCount: (state) => state.projects.length,

    projectLyrs: (state) => {
        var output = [];
        state.projects.forEach((proj) => {
            if (proj.ProjectLayers.length > 0) {
                proj.ProjectLayers.forEach((lyr) => {
                    if (!lyr.clustered && lyr.Geometry.visible) {
                        output.push(lyr.Geometry);
                    }
                });
            }
        });
        return output;
    },
    projectClusterLyrs: (state) => {
        var output = [];
        state.projects.forEach((proj) => {
            if (proj.ProjectLayers.length > 0) {
                proj.ProjectLayers.forEach((lyr) => {
                    if (lyr.clustered && lyr.Geometry.visible) {
                        output.push(lyr.Geometry);
                    }
                });
            }
        });
        return output;
    },
    tocProjectLayers: (state) => {
        var tocProjects = [];
        try {
            var wmsLyrId = 0;
            state.projects.forEach((proj) => {
                var layers = [];
                proj.ProjectLayers.forEach((lyr) => {
                    lyr.isWMS = false;
                    layers.push(lyr);
                });
                proj.WMSLayer.forEach((wms) => {
                    if (wms.TreeStruct == 'Node') {
                        wms.isWMS = true;
                        wms.LyrId = wmsLyrId;
                        layers.push(wms);
                    }
                });
                tocProjects.push({
                    ProjectName: proj.ProjectName,
                    Layers: layers,
                });
            });
        } catch (error) {
            //nothing
        }
        return tocProjects;
    },

    wmsLayers: (state) => {
        var allWMSLayers = [];
        try {
            state.projects.forEach((proj) => {
                proj.WMSLayer.forEach((wmsLyr) => {
                    if (wmsLyr.TreeStruct != 'Branch') {
                        allWMSLayers.push(wmsLyr);
                    }
                });
            });
        } catch (error) {
            //nothing
        }
        return allWMSLayers;
    },
};

const namespaced = true;

export const projectLayers = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
