<template>
    <l-control
        class="custom-control-mouseCoords"
        :position="position"
        @ready="mouseCoordsReady"
    >
        {{ this.mouseCoords }}
    </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet';

/**
 * Add any custom component as a leaflet control
 */
export default {
    name: 'LControlMouseCoords',
    components: {
        LControl,
    },
    mixins: [],
    props: {
        position: {
            type: String,
            default: 'bottomleft',
        },
        mouseCoords: {
            type: String,
            default: '0, 0',
        },
    },
    data() {
        return {};
    },
    mounted() {
        this.controlOptions = {
            position: this.position,
            mouseCoords: this.mouseCoords,
        };
        // this.mapObject = new LControl(this.controlOptions);
        // propsBinder(this, this.mapObject, this.$options.props);
        // this.mapObject.addTo(this.$parent.mapObject);
        this.$emit('ready', this.mapObject);
    },
    render() {
        return null;
    },
    methods: {
        mouseCoordsReady() {},
    },
};
</script>

<style>
.custom-control-mouseCoords {
    display: block;
    color: black;
    font-size: 14px;
    font-weight: bold;
    text-shadow: -2px 0 white, 0 2px white, 2px 0 white, 0 -2px white;
}
</style>
