var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showPage)?_c('div',[_c('AccessPermissionModal',{ref:"permission-modal"})],1):_c('div',{staticClass:"chartelement"},[(!_vm.generateGraph)?_c('b-button',{staticClass:"mt-2 backbtn btn-post",on:{"click":function($event){return _vm.backtoOption()}}},[_vm._v(" Back to graph options ")]):_vm._e(),_c('div',{class:_vm.displayGraphOptions},[_c('b-col',{staticClass:"text-right"},[(_vm.scatter)?_c('b-button',{staticClass:"buttonSelect m-none",attrs:{"rounded":"","disabled":_vm.xaxis == '' || _vm.yaxis == '' || _vm.series == ''},on:{"click":_vm.goToScatter}},[_vm._v("Generate graph")]):_vm._e()],1),(_vm.hideheader != 1)?_c('div',[_c('div',[_c('div',[_c('portal-bar-tool',{attrs:{"showReportingMenu":_vm.showReportingMenu,"hidetableSelect":_vm.hidetableSelect},on:{"clickedFilter":_vm.clickedFilter}}),_c('div',[_c('b-form-group',[_c('div',{staticClass:" division chart-new col-lg-6 col-md-7 p-0"},[_c('h5',{staticClass:"my-2 medium-text"},[_vm._v(" Elements to graph ")]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                        label: 'X-axis field',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    },"label":_vm.floatLabelEdit}},[_c('b-form-select',{model:{value:(_vm.xaxis),callback:function ($$v) {_vm.xaxis=$$v},expression:"xaxis"}},_vm._l((_vm.xaxisData),function(ele){return _c('option',{key:ele},[_vm._v(_vm._s(ele))])}),0)],1),_c('CustomFloatingLabel',{staticClass:"FloatingTool mb-0",attrs:{"config":{
                                        label: 'Y-axis field',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }}},[_c('b-form-select',{on:{"change":function($event){return _vm.minMaxInterval(_vm.yAxisChecked)}},scopedSlots:_vm._u([{key:"first",fn:function(){return undefined},proxy:true}],null,false,2469720915),model:{value:(_vm.yaxis),callback:function ($$v) {_vm.yaxis=$$v},expression:"yaxis"}},_vm._l((_vm.yaxisData),function(ele){return _c('option',{key:ele},[_vm._v(_vm._s(ele))])}),0)],1),_c('hr'),_c('CustomFloatingLabel',{staticClass:"mt-3",attrs:{"config":{
                                        label: 'X-axis label',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.axisDetails[0].axisAlias),expression:"axisDetails[0].axisAlias"}],attrs:{"name":"yaxi","autocomplete":"off"},domProps:{"value":(_vm.axisDetails[0].axisAlias)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.axisDetails[0], "axisAlias", $event.target.value)}}})]),_c('CustomFloatingLabel',{attrs:{"config":{
                                        label: 'Y-axis label',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.axisDetails[1].axisAlias),expression:"axisDetails[1].axisAlias"}],attrs:{"name":"yaxi","autocomplete":"off"},domProps:{"value":(_vm.axisDetails[1].axisAlias)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.axisDetails[1], "axisAlias", $event.target.value)}}})]),(
                                        _vm.editTitleBlockBtn &&
                                            _vm.titleBlockDisplay
                                    )?_c('button',{staticClass:"editblock mt-4",on:{"click":_vm.goToXYAxisEdit}},[_vm._v(" Edit X-Y axis ")]):_vm._e(),_c('hr'),_c('div',{staticClass:"yAxisRange"},[_c('div',{staticClass:"lblZeroAxis"},[_vm._v("Zero axis")]),_c('label',{staticClass:"switch mx-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.yAxisChecked),expression:"yAxisChecked"}],attrs:{"type":"checkbox","disabled":_vm.yaxis == ''},domProps:{"checked":Array.isArray(_vm.yAxisChecked)?_vm._i(_vm.yAxisChecked,null)>-1:(_vm.yAxisChecked)},on:{"click":function($event){return _vm.minMaxInterval(
                                                    !_vm.yAxisChecked
                                                )},"change":function($event){var $$a=_vm.yAxisChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.yAxisChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.yAxisChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.yAxisChecked=$$c}}}}),_c('span',{staticClass:"slider round",class:_vm.yaxis == ''
                                                    ? 'sliderDisable'
                                                    : ''})]),_c('CustomFloatingLabel',{staticClass:"mr-1 ml-4",attrs:{"config":{
                                            label: 'Min',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rangeValues.minValue),expression:"rangeValues.minValue"}],staticClass:"normal_input",attrs:{"type":"number","autocomplete":"off"},domProps:{"value":(_vm.rangeValues.minValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rangeValues, "minValue", $event.target.value)}}})]),_c('CustomFloatingLabel',{staticClass:"mx-1",attrs:{"config":{
                                            label: 'Max',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rangeValues.maxValue),expression:"rangeValues.maxValue"}],staticClass:"normal_input",attrs:{"type":"number","autocomplete":"off"},domProps:{"value":(_vm.rangeValues.maxValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rangeValues, "maxValue", $event.target.value)}}})]),_c('CustomFloatingLabel',{staticClass:"mx-1",attrs:{"config":{
                                            label: 'Interval',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rangeValues.interval),expression:"rangeValues.interval"}],staticClass:"normal_input",attrs:{"type":"number","min":"0.01","step":"0.01","oninput":"validity.valid||(value='');","autocomplete":"off"},domProps:{"value":(_vm.rangeValues.interval)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.rangeValues, "interval", $event.target.value)}}})])],1),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                        label: 'Series field',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }}},[_c('b-form-select',{on:{"change":function($event){return _vm.onChange($event, _vm.index, 'main')}},scopedSlots:_vm._u([{key:"first",fn:function(){return undefined},proxy:true}],null,false,2469720915),model:{value:(_vm.series),callback:function ($$v) {_vm.series=$$v},expression:"series"}},_vm._l((_vm.seriesData),function(ele){return _c('option',{key:ele},[_vm._v(" "+_vm._s(ele))])}),0)],1),_vm._l((_vm.elementSeries),function(s,index){return _c('div',{key:index,staticClass:"select-container multipleSeries"},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                            label: 'Series field',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }}},[_c('b-form-select',{staticClass:"small_drop",on:{"change":function($event){return _vm.onChange(
                                                    $event,
                                                    index,
                                                    'rare'
                                                )}},model:{value:(s.value),callback:function ($$v) {_vm.$set(s, "value", $$v)},expression:"s.value"}},[_c('option',{attrs:{"selected":"","value":"","disabled":""}},[_vm._v("Series")]),_vm._l((_vm.seriesData),function(ele){return _c('option',{key:ele,domProps:{"value":ele},on:{"change":_vm.getSerisOpt}},[_vm._v(_vm._s(ele))])})],2)],1),_c('a',{staticClass:"delete",on:{"click":function($event){return _vm.deleteSeriesElement(index)}}})],1)}),_c('input',{staticClass:"normal_input headergroup",attrs:{"type":"text","value":"Add another series field +","autocomplete":"off"},on:{"click":function($event){return _vm.addHeaderGrop()}}}),_c('p'),_c('h5',{staticClass:"my-4 medium-text"},[_vm._v(" Graph options ")]),(_vm.graphOptions.length == 0)?_c('div',{staticClass:"grey-txt"},[_vm._v(" Select elements to graph before selecting graph options. ")]):_c('div',{staticClass:"regular-text xy-check"},[_vm._v(" Secondary axis scales "),(_vm.graphOptions.length > 0)?_c('b-form-radio-group',{attrs:{"id":"radio-group-axissds","name":"sub-component"},on:{"change":function($event){_vm.columnOpen = _vm.graph;
                                            _vm.onChangeCheckBox(_vm.index);}},model:{value:(_vm.selectedAxis),callback:function ($$v) {_vm.selectedAxis=$$v},expression:"selectedAxis"}},[_c('b-form-radio',{attrs:{"name":"index-radio","value":null},model:{value:(_vm.selectedAxis),callback:function ($$v) {_vm.selectedAxis=$$v},expression:"selectedAxis"}},[_vm._v("No secondary axis")])],1):_vm._e(),_vm._l((_vm.graphOptions),function(graph,index){return _c('div',{key:index,staticClass:"pt-2"},[_c('b-form-radio-group',{attrs:{"id":"radio-group-axis","name":"sub-component"},on:{"change":function($event){_vm.columnOpen = graph;
                                                _vm.onChangeCheckBox(index);}},model:{value:(_vm.selectedAxis),callback:function ($$v) {_vm.selectedAxis=$$v},expression:"selectedAxis"}},[_c('b-form-radio',{attrs:{"name":"index-radio","value":graph},model:{value:(_vm.selectedAxis),callback:function ($$v) {_vm.selectedAxis=$$v},expression:"selectedAxis"}},[_vm._v(_vm._s(graph))])],1),(
                                                _vm.showradioCheckbox &&
                                                    _vm.checkboxOptions != 0 &&
                                                    _vm.columnOpen == graph
                                            )?_c('div',{attrs:{"id":"nav"}},[_c('ul',{staticClass:"xy-radio"},_vm._l((_vm.checkboxOptions[
                                                        index
                                                    ]),function(coption,index){return _c('li',{key:index},[_c('b-form-checkbox',{staticClass:"cbox",attrs:{"name":"index","value":coption},on:{"change":function($event){return _vm.secondarySelected(
                                                                coption
                                                            )}},model:{value:(_vm.graphname),callback:function ($$v) {_vm.graphname=$$v},expression:"graphname"}},[_vm._v(" sxx "+_vm._s(coption)+" ")])],1)}),0)]):_vm._e()],1)}),_c('hr'),_c('h5',{staticClass:"my-4 medium-text"},[_vm._v(" Select an option to handle null values ")]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                            label: 'Null Handling',
                                            scale: false,
                                        }}},[_c('b-form-select',{model:{value:(_vm.nullHandler),callback:function ($$v) {_vm.nullHandler=$$v},expression:"nullHandler"}},[_c('option',{attrs:{"value":"Zero"}},[_vm._v("Plot nulls as zero")]),_c('option',{attrs:{"value":"Drop"}},[_vm._v(" Do not plot nulls, connect line to the next valid point ")]),_c('option',{attrs:{"value":"Gap"}},[_vm._v(" Do not plot nulls, disconnect line between valid points ")])])],1),_c('hr'),_c('h5',{staticClass:"my-4 medium-text"},[_vm._v(" Select column to show multiple graphs ")]),_vm._l((_vm.multipleGraphsArray),function(s,index){return _c('div',{key:index,staticClass:"select-container multipleSeries"},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                                label: 'Multiple graphs',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }}},[_c('b-form-select',{scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('option',{attrs:{"value":"null"}},[_vm._v("No multiple graphs")])]},proxy:true}],null,true),model:{value:(s.value),callback:function ($$v) {_vm.$set(s, "value", $$v)},expression:"s.value"}},_vm._l((_vm.seriesData),function(ele){return _c('option',{key:ele},[_vm._v(" "+_vm._s(ele))])}),0)],1),(index > 0)?_c('a',{staticClass:"delete",on:{"click":function($event){return _vm.deleteMultiGraphCols(index)}}}):_vm._e()],1)}),_c('input',{staticClass:"normal_input headergroup",attrs:{"type":"text","value":"Add another column +","autocomplete":"off"},on:{"click":function($event){return _vm.addMultigraphCol()}}}),_c('hr'),_c('b-form-checkbox',{staticClass:"cbox pl-4",model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Logarithmic scale ")])],2),_c('br'),_c('h5',{staticClass:"medium-text"},[_vm._v(" Title block preview ")]),_c('div',{staticClass:"grey-txt"},[_vm._v(" Default settings have been applied. ")]),_c('div',{staticClass:"grey-txt"},[_vm._v(" To modify the style, select \"edit title block\". ")]),_c('b-row',[(_vm.titleBlock[0].text)?_c('div',{staticClass:"block-2 mt-4",attrs:{"id":"editBlock"}},[_c('div',{staticClass:"preview_logo"},[_c('div',{staticClass:"side_block pb-0 pt-3 px-3 row m-0 d-flex justify-content-between"},[_c('div',{staticClass:"col-xs-4 col-xs-offset-4 vmiddle w-100-left"},[(_vm.blobPath)?_c('img',{staticClass:"img_title",attrs:{"src":_vm.blobPath,"id":"scatterImage"}}):_vm._e()]),_c('div',{staticClass:"col"},_vm._l((_vm.titleBlock),function(item,index){return _c('div',{key:index,staticClass:"line_block -xs-4"},[_c('div',{style:({
                                                                fontSize:
                                                                    item.textSize +
                                                                    'px',
                                                                color:
                                                                    item.textColor,
                                                                fontFamily:
                                                                    item.textType,
                                                                fontWeight:
                                                                    item.textStyle,
                                                            }),attrs:{"id":'previewDatasetGraph' +
                                                                    index}},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0),_c('div',{staticClass:"footer-section bor-t-1"},[_c('div',{staticClass:"d-flexbox float-left"},[_vm._v(" "+_vm._s(_vm.footerText)+" ")])])])])]):_vm._e()]),(
                                        _vm.editTitleBlockBtn &&
                                            _vm.titleBlockDisplay
                                    )?_c('button',{staticClass:"editblock mt-4",on:{"click":_vm.gotoTitleblock}},[_vm._v(" Edit title block ")]):_vm._e(),_c('h5',{staticClass:"mt-4 medium-text"},[_vm._v(" Template formats ")]),_c('p',{staticClass:"mb-3"},[_vm._v("Page layout")]),_c('b-form-radio-group',{staticClass:"bottom-p-125",attrs:{"id":"radio-group-1","name":"radio-sub-component-2"},model:{value:(_vm.selectedLayout),callback:function ($$v) {_vm.selectedLayout=$$v},expression:"selectedLayout"}},[_c('b-form-radio',{attrs:{"name":"some-radios-2","value":"portrait"},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}},[_vm._v("Portrait")]),_c('b-form-radio',{attrs:{"name":"some-radios-2","value":"landscape"},model:{value:(_vm.selectedRadio),callback:function ($$v) {_vm.selectedRadio=$$v},expression:"selectedRadio"}},[_vm._v("Landscape")])],1)],2)])],1)],1)])]):_vm._e()],1),(_vm.displayGraph)?_c('div',{attrs:{"id":"scatterPlotDiv"}},[_c('scatter-plot',{attrs:{"logarithamic":_vm.status,"orientation":_vm.selectedRadio,"secondaryAxis":_vm.secondaryAxisSelections,"graphDetails":_vm.graphDetails,"seriesMultiple":_vm.seriesMultiple,"filteredColumnId":_vm.filteredColumnId,"columnNameSeries":_vm.columnNameSeries,"axisDetails":_vm.axisDetails,"nullHandler":_vm.nullHandler}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }