<template>
    <div id="mobile-modal">
        <div id="mobile-modal-header">
            {{ componentName.replace(/([A-Z])/g, ' $1').trim() }}
            <button id="mobile-modal-close" @click="closeModal">
                <img
                    id="mobile-modal-close-icon"
                    src="../../../assets/close-icon.svg"
                    alt="Close"
                />
            </button>
        </div>
        <div id="mobile-modal-body">
            <component
                id="modal-content"
                v-bind:is="componentName"
                v-bind="toolProps"
                @close="closeModal"
            />
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
    name: 'MobileModal',
    components: {
        DrawPane: () =>
            import('../../components/EmergencyManagement/DrawPane.vue'),
        MeasurePane: () =>
            import('../../components/EmergencyManagement/MeasurePane.vue'),
        PrintPane: () =>
            import('../../components/EmergencyManagement/PrintPane.vue'),
        AttributePane: () =>
            import('../../components/EmergencyManagement/AttributePane.vue'),
    },
    props: {
        componentName: { type: String, required: false, default: '' },
        toolProps: { type: Object, required: false, default: () => {} },
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        closeModal() {
            this.$emit('close');
            if (this.componentName === 'AttributePane') {
                this.setShowAttributeModal(false);
            }
        },
    },
    computed: {},
};
</script>

<style scoped>
#mobile-modal {
    z-index: 10;
    position: absolute;
    bottom: 10vh;
    left: 5vw;
    background-color: white;
    border: 1px solid black;
    transition: 300ms;
    height: 70vh;
    width: 90vw;
    /* padding: 16px; */
}

#mobile-modal-header {
    padding: 16px 16px 8px 16px;
    border-bottom: 1px solid grey;
    height: 3em;
    width: 100%;
}

#mobile-modal-title {
    float: left;
    position: relative;
    top: 4px;
}

#mobile-modal-close {
    float: right;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    margin: 5px;
    top: -1px;
    position: relative;
    height: 15px;
    width: 15px;
}

#mobile-modal-close-icon {
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    position: absolute;
}

#mobile-modal-body {
    padding: 8px 16px 16px 16px;
    height: 90%;
    /* max-height: calc(70vh - 32px - 3em); */
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
