<template>
    <div id="event-info-desktop">
        <div class="eventDescriptionOverflow">
            {{ eventDescription }}
        </div>
        <div>{{ eventLocation }}</div>
        <b-row class="eventLog">
            <b-col>
                <b-button
                    v-if="userIsAdmin"
                    class="btn btn-eventLog"
                    @click="createNewLog"
                    >Create New Log Entry</b-button
                >
            </b-col>
            <b-col lg="6" class="p-0">
                <weather
                    class="weather-container"
                    displayMode="simple"
                    :lat="eventLat"
                    :long="eventLong"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Weather from '../../CommonComponents/Weather.vue';
export default {
    name: 'EventInfoDesktop',
    components: {
        Weather,
    },
    props: {
        eventDescription: {
            type: String,
            required: true,
        },
        eventLocation: {
            type: String,
            required: true,
        },
        eventLat: {
            type: Number,
            required: true,
        },
        eventLong: {
            type: Number,
            required: true,
        },
        userIsAdmin: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        createNewLog() {
            this.$emit('createNewLog');
        },
    },
};
</script>
<style scoped></style>
