<template>
    <l-feature-group
        ref="vector-layer-group"
        layer-type="overlay"
        name="Vector Layers"
    >
        <l-circle-marker
            v-if="
                vector_start_point.Latitude !== 0 &&
                    vector_start_point.Longitude !== 0
            "
            :lat-lng="[
                vector_start_point.Latitude,
                vector_start_point.Longitude,
            ]"
            :radius="6"
            fillColor="#00cc00"
            :fillOpacity="1"
            color="#00cc00"
        />
        <l-circle-marker
            v-if="vectors.length > 1"
            :lat-lng="cumulative_vector.end_point.as_coordinates"
            :radius="6"
            fillColor="#ff8000"
            :fillOpacity="1"
            color="#ff8000"
        />
        <l-polyline
            v-for="(vector, idx) in vectors"
            :key="'vector-line-' + idx"
            :lat-lngs="vector.as_json.geometry.coordinates"
            :color="vector.color"
            :weight="3"
        />
        <l-marker
            v-for="(vector, idx) in vectors"
            :key="'vector-endpoint-' + idx"
            :latLng="vector.end_point.as_coordinates"
            :icon="endpointArrowIcon(vector.color, vector.angle)"
        />
        <l-polyline
            v-if="vectors.length > 1"
            :lat-lngs="cumulative_vector.coordinates"
            color="#ff8000"
            zIndex="1000000"
        />
    </l-feature-group>
</template>

<script>
import { mapState } from 'vuex';
import { LFeatureGroup, LCircleMarker, LPolyline, LMarker } from 'vue2-leaflet';
import { divIcon, marker } from 'leaflet';

export default {
    name: 'VectorFeatures',
    components: {
        LFeatureGroup,
        LCircleMarker,
        LPolyline,
        LMarker,
    },
    methods: {
        endpointArrowIcon(color, angle) {
            const angle_radians = angle * (Math.PI / 180);
            return divIcon({
                className: 'vector-endpoint-icon',
                iconSize: [20, 20],
                iconAnchor: [
                    10 + 10 * Math.sin(angle_radians),
                    10 - 10 * Math.cos(angle_radians),
                ],
                // iconAnchor: [10, 10],
                html: `<svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" style="rotate: ${angle}deg">
                <polygon points="10,1 17.5,19 2.5,19" style="fill:${color};stroke:${color};stroke-width:3;" />
            </svg>`,
            });
        },
    },
    computed: {
        ...mapState('emergencyManagement', [
            'vector_start_point',
            'vectors',
            'cumulative_vector',
        ]),
    },
};
</script>
