<template>
    <div v-if="!showPage">
        <access-permission-modal ref="permission-modal" />
    </div>
    <div class="mapviews" v-else>
        <b-container fluid class="map sub_header_bg">
            <b-container class="p-0">
                <b-row class="mb-3">
                    <b-col cols="12 my-1" class="m-none">
                        <a @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" />
                            <a href="#" class="floar-left ml-1 back">
                                Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="4" class="pt-2 m-pt-2 m-p-0">
                        <h1 class="">Projects</h1>
                    </b-col>
                    <b-col cols="4" md="5" lg="6" class="button-clear">
                        <b-button
                            v-if="initialMapIndex && initialMapIndex.length"
                            class="
                float-right
                
                m-0 
                text-primary 
              "
                            variant="light"
                            @click="clearSelection"
                        >
                            Clear selection </b-button
                        ><b-button
                            v-else
                            class="float-right m-0 subheader-smalltext"
                            @click="clearSelection"
                            variant="light"
                        >
                            Clear selection
                        </b-button>
                    </b-col>
                    <b-col cols="2" lg="2" md="3" class="text-right m-right">
                        <b-button
                            rounded
                            class="buttonSelect"
                            @click="checkProjectType"
                            v-if="showOpenProject"
                            :disabled="!initialMapIndex.length"
                        >
                            Open projects
                        </b-button>
                    </b-col>
                </b-row>
                <b-modal
                    id="projects-modal"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideReviewModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select only one project to proceed.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in selectedProjectsArr"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="projects.ProjectID"
                                                v-model="selectedRadio"
                                            >
                                            </b-form-radio>
                                            <span>{{
                                                projects.ProjectName
                                            }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a class="text-primary" @click="hideReviewModal"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="selectedRadio <= 0"
                                @click="navigateProjectSelection"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
            </b-container>
        </b-container>

        <b-container class="p-0 li-view">
            <div class="filter border-bottom py-3 mb-3">
                <b-col
                    cols="1"
                    class="text-right icons p-0 clearfix icon-view col-12"
                >
                    <div class="map-v border-right">
                        <router-link to="/classic/project/mapview">
                            <button class="map-icon-btn">
                                <img
                                    src="../../assets/map_view.svg"
                                    v-b-tooltip.hover
                                    title="Map view"
                                    class="px-2"
                                />
                            </button>
                        </router-link>
                    </div>
                    <div class="map-v">
                        <router-link to="/classic/project/listview">
                            <button class="map-icon-btn">
                                <img
                                    src="../../assets/list_view_grey.svg"
                                    v-b-tooltip.hover
                                    title="List view"
                                    class="lite-grey pl-3"
                                />
                            </button>
                        </router-link>
                    </div>
                </b-col>
            </div>
            <div
                class="pt-2 col-12 desk-none"
                v-if="applyfilter && !filterShow"
            >
                <div class="pb-3">
                    <div
                        class="bg-1"
                        v-if="countryName || stateName || cityName"
                    >
                        <b-row class="pl-3" v-if="applyfilter"> </b-row>
                    </div>
                    <b-row class="ml-0" v-if="applyfilter">
                        <div class="px-0 py-1 filterItem" v-if="countryName">
                            <span class="filteredItem-m px-3 mr-2 text-center">
                                {{ countryName }}
                                <b-icon
                                    icon="x"
                                    font-scale="1"
                                    @click="backFilter('country')"
                                >
                                </b-icon>
                            </span>
                        </div>
                        <div
                            class="px-0 py-1 filterItem rel-position"
                            v-if="stateName"
                        >
                            <span class="filteredItem-m px-3 mr-2 text-center">
                                {{ stateName }}
                                <b-icon
                                    icon="x"
                                    font-scale="1"
                                    @click="backFilter('state')"
                                >
                                </b-icon>
                            </span>
                        </div>
                        <div
                            class="px-0 py-1 filterItem rel-position"
                            v-if="cityName"
                        >
                            <span class="filteredItem-m px-3 mr-2 text-center">
                                {{ cityName }}
                                <b-icon
                                    icon="x"
                                    font-scale="1"
                                    @click="backFilter('city')"
                                >
                                </b-icon>
                            </span>
                        </div>
                        <div
                            class="px-0 py-1 filterItem rel-position"
                            v-if="projectName"
                        >
                            <span class="filteredItem-m px-3 mr-2 text-center">
                                {{ projectName }}
                                <b-icon
                                    icon="x"
                                    font-scale="1"
                                    @click="backFilter('project')"
                                >
                                </b-icon>
                            </span>
                        </div>
                    </b-row>
                </div>
            </div>

            <!-- Begins  here  -->
            <div class="col-12 pb-2 m-b-10">
                <b-row class="my-2 mx-0">
                    <input
                        ref="projectsSearch"
                        spellcheck="false"
                        id="searchFilter"
                        class=""
                        v-if="searchQuery || showSearchIcon ? true : false"
                        v-model="searchQuery"
                        @click="searchinputenter()"
                        @input="searchinputenter"
                        :class="
                            searchQuery || showSearchIcon
                                ? 'test'
                                : 'searchinputList'
                        "
                        type="search"
                        placeholder="Search for projects"
                    />
                    <span
                        class="grey-bg pointer"
                        v-if="searchClose"
                        @click="closeBar"
                        ><img src="../../assets/close.svg"
                    /></span>
                    <b-col cols="0" class="noPadding mt-2">
                        <img
                            class="pointer  ml-2  "
                            src="../../assets/search-outline.svg"
                            v-if="
                                !searchQuery && !showSearchIcon ? true : false
                            "
                            @click="searchOpen"
                        />

                        <span class="border-right list ml-2">&nbsp;</span>
                    </b-col>
                    <b-col style="min-width: 400px;max-width:400px">
                        <div class="sort-input-row">
                            <span class="sort-label">Sort By:</span>
                            <b-form-select
                                v-model="sortOrder"
                                :options="sortOptions"
                                @change="sortProjects"
                                class="sort-select"
                            ></b-form-select>
                            <b-form-select
                                v-model="sortDirection[sortOrder]"
                                :options="sortDirectionOptions[sortOrder]"
                                @change="sortProjects"
                                class="sort-select"
                            ></b-form-select>
                        </div>
                    </b-col>
                    <b-col
                        @click="filter"
                        cols="3"
                        class="filterDrop p-0 text-left m-l-15 flt-icon mt-2"
                    >
                        <a href="javascript:void(0)" class="iconFilter">
                            <a href="#" class="pl-2 ml-2 pr-0"
                                ><img src="../../assets/filter1.svg"
                            /></a>
                            <span class="blue-text pl-2">Filter</span>

                            <b-list-group-item
                                class="filter-group left-align p-0 filter-box overflow z-9999"
                                v-if="filterShow"
                            >
                                <div class="ml-3 mt-1">
                                    <div class="mb-1 float-left filter-by">
                                        Filter by
                                    </div>

                                    <div class="text-right pr-3">
                                        <a href="javascript:void(0)">
                                            <b-icon
                                                icon="x"
                                                font-scale="2"
                                                @click="closeFilter"
                                            >
                                            </b-icon>
                                        </a>
                                    </div>
                                    <div class="mt-2">
                                        <b-form-select
                                            v-model="countryId"
                                            @change="stateByCountry()"
                                            id="selectMapcountry"
                                        >
                                            <option
                                                disabled
                                                id="selectCountry"
                                                value=""
                                            >
                                                Country
                                            </option>
                                            <option
                                                v-for="(item,
                                                index) in countries"
                                                :key="index + '_countries'"
                                                v-bind:value="item.CountryId"
                                            >
                                                {{ item.CountryName }}
                                            </option>
                                        </b-form-select>
                                    </div>
                                    <div>
                                        <b-form-select
                                            v-model="stateId"
                                            @change="cityByState()"
                                            id="selectMapState"
                                        >
                                            <option
                                                disabled
                                                id="selectState"
                                                value=""
                                            >
                                                State/Province
                                            </option>
                                            <option
                                                v-for="(item, index) in states"
                                                :key="index + '_states'"
                                                v-bind:value="item.StateId"
                                            >
                                                {{ item.StateName }}
                                            </option>
                                        </b-form-select>
                                    </div>
                                    <div>
                                        <b-form-select
                                            v-model="cityName"
                                            id="selectMapCity"
                                        >
                                            <option
                                                disabled
                                                id="selectCity"
                                                value=""
                                            >
                                                City
                                            </option>
                                            <option
                                                v-for="(item, index) in cities"
                                                :key="index + '_cities'"
                                                v-bind:value="item.CityName"
                                            >
                                                {{ item.CityName }}
                                            </option>
                                        </b-form-select>
                                    </div>
                                    <div>
                                        <b-form-select
                                            v-model="projectName"
                                            id="selectListProject"
                                        >
                                            <option disabled value=""
                                                >Project Name</option
                                            >
                                            <option
                                                v-for="(projects,
                                                index) in projects.items"
                                                :key="index + '_projName'"
                                                v-bind:value="
                                                    projects.ProjectName
                                                "
                                            >
                                                {{ projects.ProjectName }}
                                            </option>
                                        </b-form-select>
                                    </div>
                                    <b-button
                                        id="mapApply"
                                        class="mb-3"
                                        @click="applyFilter"
                                        >Apply filters</b-button
                                    >
                                </div>
                            </b-list-group-item>
                        </a>
                    </b-col>
                </b-row>
            </div>

            <!-- Ends here  -->
            <!-- <content-table/> -->
            <b-row class="mb-5 m-0 left-shadow">
                <b-col
                    lg="3"
                    md="12"
                    sm="12"
                    class="border p-0 h-440 leftToolbar"
                    v-if="!mapExtend"
                >
                    <div class="col-12 bg-2" v-if="applyfilter && !filterShow">
                        <div class="">
                            <div v-if="countryName || stateName || cityName">
                                <b-row
                                    class="pl-3 pt-2 pb-1"
                                    v-if="applyfilter"
                                >
                                    Filtering By
                                </b-row>
                            </div>
                            <b-row class="ml-0" v-if="applyfilter">
                                <div class="px-0 py-1 pb-3" v-if="countryName">
                                    <span
                                        class="filteredItem px-3 py-1 mr-2 text-center"
                                    >
                                        {{ countryName }}
                                        <b-icon
                                            icon="x"
                                            font-scale="1"
                                            @click="backFilter('country')"
                                        >
                                        </b-icon>
                                    </span>
                                </div>
                                <div
                                    class="px-0 py-1 rel-position"
                                    v-if="stateName"
                                >
                                    <span
                                        class="filteredItem px-3 py-1 mr-2 text-center"
                                    >
                                        {{ stateName }}
                                        <b-icon
                                            icon="x"
                                            font-scale="1"
                                            @click="backFilter('state')"
                                        >
                                        </b-icon>
                                    </span>
                                </div>
                                <div
                                    class="px-0 py-1 rel-position"
                                    v-if="cityName"
                                >
                                    <span
                                        class="filteredItem px-3 py-1 mr-2 text-center"
                                    >
                                        {{ cityName }}
                                        <b-icon
                                            icon="x"
                                            font-scale="1"
                                            @click="backFilter('city')"
                                        >
                                        </b-icon>
                                    </span>
                                </div>
                                <div
                                    class="px-0 py-1 rel-position"
                                    v-if="projectName"
                                >
                                    <span
                                        class="filteredItem px-3 py-1 mr-2 text-center"
                                    >
                                        {{ projectName }}
                                        <b-icon
                                            icon="x"
                                            font-scale="1"
                                            @click="backFilter('project')"
                                        >
                                        </b-icon>
                                    </span>
                                </div>
                            </b-row>
                            <b-row>
                                <b-col cols="12" class="" v-if="applyfilter">
                                    <div
                                        class="removeFilter pt-2 mb-2 d-flex justify-content-left mafont "
                                        v-if="
                                            applyfilter &&
                                                !filterShow &&
                                                (countryName ||
                                                    stateName ||
                                                    cityName)
                                        "
                                    >
                                        <div
                                            @click="removeFilter"
                                            class="remove-link mafont"
                                        >
                                            Remove all filters
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>

                    <b-list-group class="filterDrop overflow" id="projectsList">
                        <b-list-group-item
                            class="filter-group p-0"
                            v-if="filterShow"
                        >
                        </b-list-group-item>
                        <span v-if="projects.items">
                            <b-list-group-item
                                id="project_List"
                                v-for="(projects, index) in filteredItems"
                                :key="index + '_applyfilter'"
                                href="#"
                                @click="
                                    currentSite(projects.ProjectID, projects)
                                "
                                @mouseover="hoverAction(index)"
                                @mouseleave="unhoverAction(index)"
                                class="text-dark list-heading"
                                v-bind:class="{
                                    '': initialMapIndex.includes(
                                        projects.ProjectID
                                    ),
                                }"
                            >
                                <b-row
                                    class="overflow"
                                    @click="setIndex(index)"
                                >
                                    <b-col cols="11">
                                        <h5 :id="'mapProject' + index">
                                            {{ projects.ProjectName }}
                                        </h5>
                                        <p class="mt-2">
                                            {{ projects.City }},
                                            {{ projects.StateName }},
                                            {{ projects.CountryName }}
                                        </p>
                                    </b-col>
                                    <span
                                        v-if="
                                            initialMapIndex.includes(
                                                projects.ProjectID
                                            )
                                        "
                                    >
                                        <img
                                            class="iconCheck"
                                            src="../../assets/icon_tick_lg1.svg"
                                        />
                                    </span>
                                </b-row>
                            </b-list-group-item>
                        </span>
                    </b-list-group>
                </b-col>
                <b-col class="p-0 full-view" lg="9" md="12" sm="12">
                    <a
                        href="#"
                        v-if="!mapExtend"
                        @click="mapExtendSize()"
                        class="mr-2 map_arrow"
                        ><img src="../../assets/map_toggle_left.svg"
                    /></a>
                    <a
                        href="#"
                        v-if="mapExtend"
                        @click="mapExtendSize()"
                        class="mr-2 map_arrow"
                        ><img src="../../assets/map_toggle_right.svg"
                    /></a>
                    <div id="mapContainer" v-bind:class="mapClass">
                        <b-modal
                            id="modal-screen"
                            ok-only
                            hide-footer
                            hide-header
                            @hide="modalClose"
                            modal-class="modalFullscreen"
                        >
                            <div class="modalScreen">
                                <!-- insert modal content in here -->
                                <l-map
                                    ref="lmapBig"
                                    :zoom="expandedZoom"
                                    :center="expandedCenter"
                                    :options="{ zoomControl: false }"
                                    v-bind:max-zoom="16"
                                    :v-if="mapIsExpanded"
                                    @update:zoom="updateZoom"
                                    @update:center="updateCenter"
                                >
                                    <l-control-layers
                                        position="topright"
                                    ></l-control-layers>
                                    <l-control-scale
                                        :unitSystem="scalebarUnits"
                                        v-bind:max-width="135"
                                    />
                                    <l-control position="bottomleft">
                                        <a>
                                            <img
                                                src="../../assets/minimize.svg"
                                                class="icon-40"
                                                @click="modalClose"
                                            />
                                        </a>
                                    </l-control>
                                    <l-tile-layer
                                        v-for="baseMap in baseMaps"
                                        :key="baseMap.name"
                                        :name="baseMap.name"
                                        :visible="baseMap.visible"
                                        :attribution="baseMap.attribution"
                                        :url="baseMap.url"
                                        layer-type="base"
                                        :options="baseMapOptions"
                                    ></l-tile-layer>
                                    <!-- <l-marker-cluster
                    :options="clusterOptions"> -->
                                    <l-marker
                                        v-for="(item, index) in projLocations"
                                        :lat-lng="item.coord"
                                        :key="index + '_projLocations'"
                                        @ready="getProjectLocationBounds"
                                    >
                                        <l-tooltip>
                                            <span class="font-l">
                                                {{ item.ProjectName }}
                                            </span>
                                        </l-tooltip>
                                        <l-popup>
                                            <table
                                                class="table table-striped table-sm mt-3"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <td>Country</td>
                                                        <td>
                                                            {{ item.Country }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>State</td>
                                                        <td>
                                                            {{ item.State }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>City</td>
                                                        <td>{{ item.City }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Project Name</td>
                                                        <td>
                                                            {{
                                                                item.ProjectName
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </l-popup>
                                    </l-marker>
                                    <!-- </l-marker-cluster> -->
                                    <l-marker-cluster
                                        ref="expandedprojLocationsAllMC"
                                        :options="clusterOptions"
                                    >
                                        <l-marker
                                            v-for="(item,
                                            index) in projLocationsAll"
                                            :lat-lng="item.coord"
                                            :key="index + '_projLocationsAll'"
                                            @click="setActiveProj(index)"
                                            @ready="getProjectLocationBounds"
                                        >
                                            <l-icon
                                                icon-url="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png"
                                            >
                                            </l-icon>
                                            <l-tooltip
                                                ><span class="font-l">{{
                                                    item.ProjectName
                                                }}</span></l-tooltip
                                            >
                                        </l-marker>
                                    </l-marker-cluster>
                                    <l-control-zoom
                                        position="bottomright"
                                    ></l-control-zoom>
                                </l-map>
                            </div>
                        </b-modal>
                        <!-- end code for screen resize modal -->
                        <!-- <span v-bind:class="mapClass"> -->
                        <l-map
                            ref="lmapSmall"
                            :zoom="zoom"
                            :center="center"
                            :options="{ zoomControl: false }"
                            v-bind:max-zoom="16"
                            :v-if="!mapIsExpanded"
                            @update:zoom="updateExpandedZoom"
                            @update:center="updateExpandedCenter"
                        >
                            <l-control-scale
                                :unitSystem="scalebarUnits"
                                v-bind:max-width="135"
                            />
                            <!-- triggers map resize modal -->
                            <l-control position="bottomleft">
                                <a v-b-modal.modal-screen>
                                    <img
                                        src="../../assets/maximize.svg"
                                        class="icon-35"
                                        @click="resizeModal"
                                    />
                                </a>
                            </l-control>
                            <!-- end triggers map resize modal -->
                            <l-control-layers
                                position="topright"
                            ></l-control-layers>
                            <l-control
                                class="print-map-control"
                                position="topright"
                            >
                                <b-button class="printButton" @click="print">
                                    <img
                                        src="../../assets/printer-outline-with-paper.svg"
                                        class="icon-32"
                                    />
                                </b-button>
                            </l-control>
                            <l-tile-layer
                                v-for="baseMap in baseMaps"
                                :key="baseMap.name"
                                :name="baseMap.name"
                                :visible="baseMap.visible"
                                :attribution="baseMap.attribution"
                                :url="baseMap.url"
                                layer-type="base"
                                :options="baseMapOptions"
                            ></l-tile-layer>
                            <!-- <l-marker-cluster
                    :options="clusterOptions"> -->
                            <l-marker
                                v-for="(item, index) in projLocations"
                                :lat-lng="item.coord"
                                :key="index + '_projLocations'"
                                @ready="getBounds"
                            >
                                <!-- :icon="hoverIcon" -->
                                <l-tooltip>
                                    <span class="font-l">
                                        {{ item.ProjectName }}
                                    </span>
                                </l-tooltip>
                                <l-popup>
                                    <table
                                        class="table table-striped table-sm mt-3"
                                    >
                                        <tbody>
                                            <tr>
                                                <td>Country</td>
                                                <td>{{ item.Country }}</td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>{{ item.State }}</td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td>{{ item.City }}</td>
                                            </tr>
                                            <tr>
                                                <td>Project Name</td>
                                                <td>{{ item.ProjectName }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </l-popup>
                            </l-marker>
                            <!-- </l-marker-cluster> -->
                            <l-marker
                                :lat-lng="projectHoveredCoord"
                                :visible="showHoverMarker"
                                :icon="hoverIcon"
                                :options="{ zIndexOffset: 99 }"
                            >
                            </l-marker>
                            <l-marker-cluster
                                ref="projLocationsAllMC"
                                :options="clusterOptions"
                            >
                                <l-marker
                                    v-for="(item, index) in projLocationsAll"
                                    :lat-lng="item.coord"
                                    :key="index + '_projLocationsAll'"
                                    :icon="unselectedIcon"
                                    @click="
                                        zoomLayer(index);
                                        setActiveProj(index);
                                    "
                                    @ready="getBounds"
                                >
                                    <l-tooltip
                                        ><span class="font-l">{{
                                            item.ProjectName
                                        }}</span></l-tooltip
                                    >
                                </l-marker>
                            </l-marker-cluster>
                            <l-control-zoom
                                position="bottomright"
                            ></l-control-zoom>
                        </l-map>
                        <!-- </span> -->
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <img
            id="referenceCircleImage"
            src="../../assets/circle.svg"
            class="d-none"
        />
        <img
            id="referenceSiteTickImage"
            src="../../assets/icon_tick_lg1.svg"
            class="d-none"
        />
        <message-modal
            ref="message-modal"
            title="New project view"
            :text="clearProjectsMsg"
            @confirm="confirmRemovePosted"
        />
    </div>
</template>

<script>
import router from '../../../router';
import { mapState, mapActions } from 'vuex';
//fix the leaflet marker issue

import { Icon, latLngBounds, icon } from 'leaflet';
import LControlScale from '../../components/EmergencyManagement/LeafletScale.vue';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import MessageModal from '../CommonComponents/MessageModal.vue';
import { projectService } from '../../services/project.service';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('../../../assets/leaflet_markers/marker-icon-2x-blue.png'),
    iconUrl: require('../../../assets/leaflet_markers/marker-icon-blue.png'),
    shadowUrl: require('../../../assets/leaflet_markers/marker-shadow.png'),
});
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    LControlLayers,
    LPopup,
    LControlZoom,
    LControl,
} from 'vue2-leaflet';
import { commonService } from '../../services/common.service';
import AccessPermissionModal from '../AccessPermissionModal.vue';

export default {
    name: 'Projects',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LTooltip,
        LControlLayers,
        LPopup,
        LControlZoom,
        LControl,
        LControlScale,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        MessageModal,
        AccessPermissionModal,
    },
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 4,
            center: [37.579, -93.912],
            mapIsExpanded: false,
            expandedZoom: 3,
            expandedCenter: [37.579, -93.912],
            baseMaps: [
                {
                    name: 'Satellite_Imagery',
                    visible: false,
                    url:
                        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'NoMap',
                    visible: false,
                    url: '',
                    attribution: '',
                },
            ],
            projLocations: [],
            currentSiteID: null,
            initialMapIndex: [],
            mapExtend: false,
            filterShow: false,
            filterShowMod: false,
            filterApplied: false,
            searchApplied: false,
            projectList: true,
            applyfilter: false,
            layerType: 'OSM',
            urlTemplate:
                'https://tiles.wmflabs.org/bw-mapnik/level/tileX/tileY.png',
            mapBounds: null,
            animationDelay: 0,
            zoomSettings: {
                zoomFactor: 2,
                maxZoom: 21,
                enable: true,
                pinchZooming: true,
                enablePanning: true,
                mouseWheelZoom: true,
                toolbars: ['Zoom', 'ZoomIn', 'ZoomOut', 'Pan', 'Reset'],
            },
            countryName: '',
            countryId: '',
            stateName: '',
            stateId: '',
            cities: null,
            cityName: '',
            projectName: '',
            countries: null,
            states: null,
            filterMap: true,
            searchQuery: null,
            showSearchIcon: false,
            showPage: false,
            showOpenProject: false,
            searchClose: false,
            projectHovered: null,
            projectHoveredCoord: [0, 0],
            showHoverMarker: false,
            selectedRadio: 0,
            unselectedIcon: icon({
                iconUrl: require('../../../assets/leaflet_markers/marker-icon-grey.png'),
                iconSize: [25, 41],
                iconAnchor: [12.5, 41],
            }),
            hoverIcon: icon({
                iconUrl: require('../../../assets/leaflet_markers/marker-icon-black.png'),
                iconSize: [30, 49.2],
                iconAnchor: [15, 49.2],
            }),
            mapClass: 'mapView-map-box',
            // mapClass:'map-box',
            clusterOptions: {
                disableClusteringAtZoom: 17,
                showCoverageOnHover: false,
                chunkedLoading: true,
            },
            triggerModal: false,
            selectedIndex: '',
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
            // adding this since there are 2 scale bars, can change them both here
            scalebarUnits: 'both', // options are 'metric','imperial','both'

            sortOptions: ['Project Name', 'Date Created'],
            sortDirectionOptions: {
                'Project Name': ['A -> Z', 'Z -> A'],
                'Date Created': ['New -> Old', 'Old -> New'],
            },
            sortOrder: 'Project Name',
            sortDirection: {
                'Project Name': 'A -> Z',
                'Date Created': 'New -> Old',
            },
        };
    },
    mounted() {
        // mock api call to reportingservice server
        commonService.initializeReportingService();
        this.selectedIndex = localStorage.getItem('scrollIndex');
        this.pagePermit();
        //handles highlight of projects after navigation back to mapview
        this.selectedProjectsArr.forEach((project) => {
            this.initialMapIndex.push(project.ProjectID);
            this.projLocations.push({
                siteid: project.ProjectID,
                coord: [project.Latitude, project.Longitude],
                ProjectName: project.ProjectName,
                City: project.City,
                State: project.StateName,
                Country: project.CountryName,
            });
        });
        // if the layers don't load in time, getbounds will fail, halting this function execution
        try {
            this.getBounds();
        } catch (e) {
            e;
        }
        this.resetStore();
    },
    created() {
        this.getAllProjects();
        // setTimeout(() => this.$refs.maps.ej2Instances.refresh(), 500);
        this.countryList();
    },

    methods: {
        ...mapActions('projects', {
            getAllProjects: 'getAllWithGeo',
            selectProjects: 'selectProjects',
            deselectProject: 'deselectProject',
            clearSelectedProjects: 'clearSelectedProjects',
            clearSelectedEMProjects: 'clearSelectedEMProjects',
            setSelectedProjects: 'selectProjects',
        }),
        ...mapActions('emergencyManagement', ['resetStore']),
        zoomLayer: function(index) {
            setTimeout(() => {
                var elmnt = this.$el.querySelector('#mapProject' + index);
                elmnt.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 100);
        },
        setActiveProj: function(index) {
            this.currentSite(
                this.projects.items[index].ProjectID,
                this.projects.items[index]
            );
        },
        hoverAction: function(index) {
            //
            if (this.projectHovered !== this.projects.items[index]) {
                this.projectHovered = this.projects.items[index];
                this.projectHoveredCoord = [
                    this.projects.items[index].Latitude,
                    this.projects.items[index].Longitude,
                ];
                this.showHoverMarker = true;
            }
        },
        unhoverAction: function(index) {
            this.projectHovered = null;
            this.projectHoveredCoord = [0, 0];
            this.showHoverMarker = false;
        },
        getProjectLocationBounds() {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            //
            this.projLocations.forEach((projLocation) => {
                let xCoord = projLocation.coord[1];
                let yCoord = projLocation.coord[0];
                minX = Math.min(minX, parseFloat(xCoord));
                maxX = Math.min(maxX, parseFloat(xCoord));
                minY = Math.min(minY, parseFloat(yCoord));
                maxY = Math.min(maxY, parseFloat(yCoord));
            });
            this.mapBounds = latLngBounds([
                [minY, minX],
                [maxY, maxX],
            ]);
            //
        },
        updateZoom(zoom) {
            if (zoom < 16) {
                this.zoom = zoom + 1;
            }
        },
        updateCenter(center) {
            this.center = center;
        },
        updateExpandedCenter(center) {
            this.expandedCenter = center;
        },
        updateExpandedZoom(zoom) {
            if (this.zoom > 0) {
                this.expandedZoom = zoom - 1;
            }
        },

        getBrowserType() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (
                (navigator.userAgent.indexOf('Edg') ||
                    navigator.userAgent.indexOf('Edge')) != -1
            ) {
                return 'Edge';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                //IF IE > 10
                return 'IE';
            } else {
                return 'unknown';
            }
        },
        // print the modal view, since it is larger, then we don't need more map handling code
        print() {
            this.mapClass = 'mapView-map-box-print';
            this.resizeModal();
            const modal = document.getElementById('mapContainer');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');
            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }

            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            var userBrowser = this.getBrowserType();
            var topMargin = '0';
            var leftMargin = '0';
            switch (userBrowser) {
                case 'Chrome':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
                case 'Firefox':
                    topMargin = '0.5in';
                    leftMargin = '0';
                    break;
                case 'Edge':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
            }
            css =
                '@page { size: landscape; } @media print {#mapContainer{position:absolute; height:7.5in; width:10in; top:' +
                topMargin +
                ';left:' +
                leftMargin +
                ';}';
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            setTimeout(function() {
                window.print();
            }, 500);
            window.onafterprint = () => {
                this.mapClass = 'mapView-map-box';
            };
            this.modalClose();
        },
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        pagePermit() {
            setTimeout(() => {
                var element = this.$el.querySelector(
                    '#mapProject' + this.selectedIndex
                );
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 1000);
            this.showPage = true;
            this.showOpenProject = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        navigateListView: function() {
            this.clearSelectedProjects();
            router.push(`/classic/project/listview`);
        },
        clearSelection() {
            localStorage.setItem('scrollIndex', 0);
            this.clearSelectedProjects();
            this.projLocations = [];
            this.initialMapIndex = [];
            this.getBounds();
            setTimeout(() => {
                var elmnt = this.$el.querySelector('#mapProject' + 0);
                elmnt.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 100);
        },
        hideReviewModal() {
            this.$bvModal.hide('projects-modal');
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.searchApplied = true;

            //resize window based on whether search bar, filter or both are open
            if (this.searchApplied & this.filterApplied) {
                document.querySelector('#projectsList').style.height = '360px';
            } else {
                document.querySelector('#projectsList').style.height = '440px';
            }
            //resize window based on whether search bar, filter or both are open
            this.$nextTick(() => {
                this.$refs.projectsSearch.focus();
            });
        },
        closeBar() {
            //resize window based on whether search bar, filter or both are open
            if (this.searchApplied & this.filterApplied) {
                document.querySelector('#projectsList').style.height = '360px';
            } else {
                document.querySelector('#projectsList').style.height = '440px';
            }
            this.searchApplied = false;
            //resize window based on whether search bar, filter or both are open

            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.searchQuery = null;
            this.$refs.grid.$el.ej2_instances[0].search();
        },
        checkProjectType() {
            localStorage.setItem('loading', true);
            localStorage.setItem('scrollIndex', this.selectedIndex);
            let selectedProjects = this.$store.state.projects.selectedProjects;
            if (
                selectedProjects.length == 1 &&
                !['Emergency management', 'Emergency response'].includes(
                    selectedProjects[0].ProjectTypeName
                )
            ) {
                let defaultBaseMap = selectedProjects[0].DefaultBaseMapName;
                if (defaultBaseMap == null) {
                    defaultBaseMap = 'topo';
                }
                let payload = 'topo';
                if (defaultBaseMap.toLowerCase().includes('topo')) {
                    payload = 'topo';
                } else if (defaultBaseMap.toLowerCase().includes('imagery')) {
                    payload = 'imagery';
                } else if (defaultBaseMap.toLowerCase().includes('open')) {
                    payload = 'open';
                } else if (defaultBaseMap.toLowerCase().includes('nomap')) {
                    payload = 'none';
                }
                this.$store.commit('projects/updateBaseMap', payload);
                this.checkIfNeedToClearStoredProjects();
            } else if (
                selectedProjects.length == 1 &&
                ['Emergency management', 'Emergency response'].includes(
                    selectedProjects[0].ProjectTypeName
                )
            ) {
                this.resetStore();
                if (window.innerWidth > 768) {
                    router.push(`/classic/MainMap`);
                } else {
                    router.push(`/classic/MobileMap`);
                }
            } else if (selectedProjects.length > 1) {
                let defaultBaseMapArr = [];
                let EMyes = false;
                selectedProjects.forEach((proj) => {
                    defaultBaseMapArr.push(proj.DefaultBaseMapName);
                    if (
                        ['Emergency management', 'Emergency response'].includes(
                            proj.ProjectTypeName
                        )
                    ) {
                        EMyes = true;
                    }
                });
                let uniqueBaseMaps = [...new Set(defaultBaseMapArr)];
                if (uniqueBaseMaps.length == 1) {
                    let defaultBaseMap = uniqueBaseMaps[0];
                    if (defaultBaseMap == null) {
                        defaultBaseMap = 'topo';
                    }
                    let payload = 'topo';
                    if (defaultBaseMap.toLowerCase().includes('topo')) {
                        payload = 'topo';
                    } else if (
                        defaultBaseMap.toLowerCase().includes('imagery')
                    ) {
                        payload = 'imagery';
                    } else if (defaultBaseMap.toLowerCase().includes('nomap')) {
                        payload = 'none';
                    }
                    this.$store.commit('projects/updateBaseMap', payload);
                } else {
                    this.$store.commit('projects/updateBaseMap', 'topo');
                }
                if (EMyes) {
                    this.$bvModal.show('projects-modal');
                } else {
                    this.checkIfNeedToClearStoredProjects();
                    router.push(`/classic/project/selectedprojects`);
                }
            }
        },
        navigateProjectSelection() {
            let selectedProjects = this.$store.state.projects.selectedProjects;
            let ProjID = this.selectedRadio;
            localStorage.setItem('loading', true);
            let userSelectedProject = selectedProjects.filter((proj) => {
                return proj.ProjectID == ProjID;
            });
            if (
                !['Emergency management', 'Emergency response'].includes(
                    userSelectedProject[0].ProjectTypeName
                )
            ) {
                this.checkIfNeedToClearStoredProjects();
                // keep this if we do want to select multiple CAR projects
                this.clearSelectedEMProjects();
                // this.clearSelectedProjects();
                this.setSelectedProjects(userSelectedProject[0]);
                router.push(`/classic/project/selectedprojects`);
            } else if (
                ['Emergency management', 'Emergency response'].includes(
                    userSelectedProject[0].ProjectTypeName
                )
            ) {
                this.clearSelectedProjects();
                this.setSelectedProjects(userSelectedProject[0]);
                this.resetStore();
                router.push(`/classic/MainMap`);
            }
        },
        checkIfNeedToClearStoredProjects() {
            //get project ID lists from current selected projects in mapview, and projects that are posted to main map
            let currentSelectedProjects = this.$store.state.projects.selectedProjects.map(
                (x) => x.ProjectID
            );
            let inMapProjects = this.$store.state.projects.mapData.inMapProjects.map(
                (x) => x.ProjectID
            );
            //compare these two lists, and notify user if a inMapProject is no longer in selected projects list
            let postedLayers = this.$store.state.projects.mapData.geojsonLayers
                .posted;
            let projectRemoval = false;
            inMapProjects.forEach((project) => {
                if (!currentSelectedProjects.includes(project)) {
                    projectRemoval = true;
                    if (postedLayers.length == 0) {
                        this.$store.state.projectLayers.projects.splice(
                            this.$store.state.projectLayers.projects.findIndex(
                                (x) => x.ProjectID == project.ProjectID
                            ),
                            1
                        );
                    }
                }
            });
            if (projectRemoval && postedLayers.length > 0) {
                this.$refs['message-modal'].openModal();
            } else {
                localStorage.setItem('loading', true);
                this.$store.commit('projects/addProjectToInMapProjects');
                router.push(`/classic/project/selectedprojects`);
            }
        },
        getBounds() {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            //
            if (this.projLocations.length > 0) {
                this.projLocations.find((location) => {
                    minX = Math.min(minX, parseFloat(location.coord[1]));
                    maxX = Math.max(maxX, parseFloat(location.coord[1]));
                    minY = Math.min(minY, parseFloat(location.coord[0]));
                    maxY = Math.max(maxY, parseFloat(location.coord[0]));
                });
            } else {
                this.projLocationsAll.find((location) => {
                    minX = Math.min(minX, parseFloat(location.coord[1]));
                    maxX = Math.max(maxX, parseFloat(location.coord[1]));
                    minY = Math.min(minY, parseFloat(location.coord[0]));
                    maxY = Math.max(maxY, parseFloat(location.coord[0]));
                });
            }
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            let zoomCushion = 0.15; // zoomCushion*100 is the %buffer
            let markerBounds = latLngBounds([
                [minY - yRange * zoomCushion, minX - xRange * zoomCushion],
                [maxY + yRange * zoomCushion, maxX + xRange * zoomCushion],
            ]);
            if (this.projLocations.length > 0) {
                this.$refs.lmapSmall.mapObject.fitBounds(markerBounds, {
                    animate: true,
                    duration: 1.5,
                });
            } else {
                this.$refs.lmapSmall.mapObject.fitBounds(markerBounds, {
                    animate: true,
                    duration: 0.25,
                });
            }
        },
        toggleBasemap() {
            let basemaplayerID = Object.keys(
                this.$refs.lmapSmall.mapObject._layers
            )[0];
            // get current basemap of large map
            let basemaplayer = this.$refs.lmapSmall.mapObject._layers[
                basemaplayerID
            ]._url;
            if (basemaplayer.includes('Topo')) {
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = true;
                this.baseMaps[2].visible = false;
            } else if (basemaplayer.includes('Imagery')) {
                this.baseMaps[0].visible = true;
                this.baseMaps[2].visible = false;
                this.baseMaps[1].visible = false;
            } else {
                //no map
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[2].visible = true;
            }
        },
        currentSite(clickedProjectID, project) {
            projectService.getProjectLogo(project.ProjectID).then((res) => {
                project.Logo = res.data;
                if (
                    this.projLocations
                        .map((p) => p.siteid)
                        .includes(clickedProjectID)
                ) {
                    // this.clearSelectedProjects();
                    this.deselectProject(clickedProjectID);
                    this.initialMapIndex.splice(
                        this.initialMapIndex.indexOf(clickedProjectID),
                        1
                    );
                    this.currentSiteID = null;
                    for (var i = 0; i < this.projLocations.length; i++) {
                        if (this.projLocations[i].siteid == clickedProjectID) {
                            this.projLocations.splice(i, 1);
                        }
                    }
                    this.getBounds();
                } else {
                    this.selectProjects(project);
                    this.zoom = 15;
                    this.currentSiteID = clickedProjectID;
                    this.projLocations.push({
                        siteid: clickedProjectID,
                        coord: [project.Latitude, project.Longitude],
                        ProjectName: project.ProjectName,
                        City: project.City,
                        State: project.StateName,
                        Country: project.CountryName,
                    });
                    this.getBounds();
                    this.initialMapIndex.push(clickedProjectID);
                }
            });
        },
        mapExtendSize() {
            this.mapExtend = !this.mapExtend;
            if (this.mapExtend == true) {
                this.mapClass = 'mapView-map-box-expanded';
            }
            setTimeout(() => {
                this.$refs.lmapSmall.mapObject.invalidateSize();
            }, 100);
        },
        alert(item) {
            alert('this is ' + JSON.stringify(item));
        },
        resizeModal() {
            this.mapIsExpanded = true;
            this.toggleBasemap();
            setTimeout(() => {
                this.$refs.lmapSmall.mapObject.invalidateSize();
                this.$refs.lmapBig.mapObject.invalidateSize();
            }, 100);
        },
        modalClose() {
            this.mapIsExpanded = false;
            this.$bvModal.hide('modal-screen');
            //
            let basemaplayerID = Object.keys(
                this.$refs.lmapBig.mapObject._layers
            )[0];
            let basemaplayer = this.$refs.lmapBig.mapObject._layers[
                basemaplayerID
            ]._url;
            if (basemaplayer.includes('Topo')) {
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = true;
                this.baseMaps[2].visible = false;
            } else if (basemaplayer.includes('Imagery')) {
                this.baseMaps[0].visible = true;
                this.baseMaps[2].visible = false;
                this.baseMaps[1].visible = false;
            } else {
                //no map
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[2].visible = true;
            }
        },
        filter() {
            // if(this.filterMap){
            this.filterShow = true;
            this.projectList = false;
            document.querySelector('#outerserachinput .e-input').style.display =
                'none';
            document.querySelector('#outerserachinput').style.width = '20px';
            document.querySelector('#outerserachinput').style.borderColor =
                'transparent';
            // }
        },
        applyFilter() {
            // setTimeout(()=>{
            this.projectsSource = this.projects.items;

            //resize window based on whether search bar, filter or both are open
            this.filterApplied = true;
            if (this.filterApplied & this.searchApplied) {
                document.querySelector('#projectsList').style.height = '360px';
            } else {
                document.querySelector('#projectsList').style.height = '360px';
            }
            //resize window based on whether search bar, filter or both are open
            // });
            if (this.countryName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.CountryName === this.countryName
                );
            }

            if (this.stateName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.StateName === this.stateName
                );
            }

            if (this.cityName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.City === this.cityName
                );
            }

            if (this.projectName) {
                this.projectsSource = this.projectsSource.filter(
                    (item) => item.ProjectName === this.projectName
                );
            }
            this.applyfilter = true;
            this.filterShow = false;
            //this.filterMap = false;
            this.closeFilter();
        },
        // removeFilter(){
        //   this.applyfilter = false;
        // },
        removeFilter() {
            this.applyfilter = false;
            this.countryName = '';
            this.stateName = '';
            this.cityName = '';
            this.projectName = '';
            this.stateId = '';
            this.countryId = '';
            this.states = [];
            this.cities = [];
            //resize window based on whether search bar, filter or both are open
            if (this.searchApplied & this.filterApplied) {
                document.querySelector('#projectsList').style.height = '440px';
            } else {
                document.querySelector('#projectsList').style.height = '440px';
            }
            this.filterApplied = false;
            //resize window based on whether search bar, filter or both are open
        },
        backFilter(item) {
            this.filterShow = false;
            if (item === 'country') {
                this.countryName = '';
                this.countryId = '';
            }
            if (item === 'project') {
                this.projectName = '';
            }
            if (item === 'state') {
                this.stateName = '';
                this.stateId = '';
                if (this.countryName == '') {
                    this.states = [];
                }
                if (this.cityName == '') {
                    this.cities = [];
                }
            }
            if (item === 'city') {
                this.cityName = '';
                if (this.stateId == '') {
                    this.cities = [];
                }
            }
            if (
                !this.stateName &&
                !this.cityName &&
                !this.projectName &&
                !this.countryName
            ) {
                this.applyfilter = false;
                this.countryName = '';
                this.stateName = '';
                this.cityName = '';
                this.projectName = '';
                this.stateId = '';
                this.countryId = '';
                this.states = [];
                this.cities = [];
            }
            this.filterShow = false;
            this.applyFilter();

            //resize window based on whether search bar, filter or both are open
            if (this.searchApplied & this.filterApplied) {
                document.querySelector('#projectsList').style.height = '440px';
            } else {
                document.querySelector('#projectsList').style.height = '440px';
            }
            this.filterApplied = false;
            //resize window based on whether search bar, filter or both are open

            //this.projectList = true;
        },
        closeFilter() {
            setTimeout(() => {
                this.filterShow = false;
            });
        },
        // backFilter() {
        //   this.filterShow = false;
        //   this.projectList = true;
        //   this.applyfilter = false;
        // }

        countryList() {
            commonService
                .getCountries()
                .then((res) => (this.countries = res.data));
        },
        stateByCountry() {
            let country = this.countries.find(
                (e) => e.CountryId === this.countryId
            );
            this.countryName = country.CountryName;
            commonService
                .getStates(this.countryId)
                .then((res) => (this.states = res.data));
        },
        cityByState() {
            let state = this.states.find((e) => e.StateId === this.stateId);
            this.stateName = state.StateName;
            commonService
                .getCities(this.stateId)
                .then((res) => (this.cities = res.data));
        },
        searchiconclick() {
            document.querySelector('#outerserachinput').style.width = '215px';
            document.querySelector('#outerserachinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outerserachinput .e-input').style.display =
                'block';
            this.closeFilter();
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.projectsSearch.focus();
            });
        },
        searchinputenter(args) {
            if (args) {
                this.$refs.grid.$el.ej2_instances[0].search(
                    args.currentTarget.value
                );
                this.showSearchIcon = false;
                if (!this.searchQuery) {
                    this.searchClose = false;
                }
            }
        },
        confirmRemovePosted(value) {
            if (value) {
                localStorage.removeItem('layerDetails');
                localStorage.setItem('loading', true);
                this.$store.commit('projects/clearMapData');
                this.$store.commit('projectLayers/clearMapData');
                this.$store.commit('projects/addProjectToInMapProjects');
                router.push(`/classic/project/selectedprojects`);
            }
        },
        setIndex(index) {
            this.selectedIndex = index;
        },
    },
    computed: {
        ...mapState({
            projects: function(state) {
                return state.projects.projectsWithGeo;
            },
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
            projLocationsAll: function() {
                let coordArry = [];
                // creates an array of points that are not selected since we don't want to have
                //  the blue marker overlapping the grey marker
                try {
                    this.projects.items.forEach((item) => {
                        if (
                            !this.projLocations
                                .map((p) => p.siteid)
                                .includes(item.ProjectID)
                        ) {
                            coordArry.push({
                                ProjectID: item.ProjectID,
                                coord: [item.Latitude, item.Longitude],
                                ProjectName: item.ProjectName,
                                City: item.City,
                                State: item.StateName,
                                Country: item.CountryName,
                            });
                        }
                    });
                } catch (error) {
                    //nothing
                }
                return coordArry;
            },
            markerSettings: function(state) {
                //
                if (
                    state.projects.projectsWithGeo &&
                    state.projects.projectsWithGeo.items
                ) {
                    const data = state.projects.projectsWithGeo.items;
                    const markersData = [];
                    data.forEach((element, index) => {
                        markersData.push({
                            id: index,
                            name: element.ProjectName,
                            projectId: element.ProjectID,
                            latitude: element.Latitude,
                            longitude: element.Longitude,
                            element: element,
                            shape: 'Image',
                            color: 'blue',
                            visible: true,

                            imageUrl: document.getElementById(
                                'referenceCircleImage'
                            ).src,
                        });
                    });
                    let markerSettings = [
                        {
                            visible: true,
                            animationDelay: 0,
                            dataSource: markersData,
                            shapeValuePath: 'shape',
                            imageUrlValuePath: 'imageUrl',
                            colorValuePath: 'color',
                            tooltipSettings: {
                                visible: true,
                                valuePath: 'name',
                            },
                        },
                    ];
                    this.getBounds();
                    /*data.forEach((element, index) => {
          markersData.push({
            dataSource:[{
              id: index,
              name: element.ProjectName,
             // latitude: element.Latitude,
              //longitude: element.Longtitude,
              latitude: 49.95121990866204,
              longitude: 18.468749999999998,
              element:element,
              shape:'Image',
              color:'blue',

            }],
              visible: true,
                   animationDelay:0,
                  shape:'Image',

              imageUrl:document.getElementById('referenceCircleImage').src,
                   // shapeValuePath:'shape',
                   // imageUrlValuePath: "imageUrl",
                    colorValuePath:'color',
                    tooltipSettings: {
                        visible: true,
                        valuePath: 'name'
                    }

          })
        });*/

                    // setting one site as the current initially messes with the selection afterwards
                    // setTimeout(() => {
                    //   let selectedProjects = state.projects.selectedProjects;
                    //
                    // selectedProjects.forEach((element) => {
                    //   if (!this.initialMapIndex.includes(element.ProjectID)) {
                    //     // this.initialMapIndex.push(element.ProjectID)
                    //     this.currentSite(element.ProjectID, element);
                    //   }
                    // });
                    // }, 500);
                    return markerSettings;
                }
            },
        }),
        filteredItems() {
            let projectList = this.projects.items;
            let sortedProjectList = projectList.sort((a, b) => {
                if (this.sortOrder === 'Project Name') {
                    if (this.sortDirection['Project Name'] === 'A -> Z') {
                        return a.ProjectName.localeCompare(b.ProjectName);
                    } else {
                        return b.ProjectName.localeCompare(a.ProjectName);
                    }
                } else {
                    if (this.sortDirection['Date Created'] === 'New -> Old') {
                        return b.ProjectID - a.ProjectID;
                    } else {
                        return a.ProjectID - b.ProjectID;
                    }
                }
            });
            if (this.searchQuery) {
                return sortedProjectList.filter((item) => {
                    return (
                        item.ProjectName.toLowerCase().indexOf(
                            this.searchQuery.toLowerCase()
                        ) > -1
                    );
                });
            } else {
                return sortedProjectList;
            }
        },
        mapWidth: function() {
            if (this.mapExtend) {
                return '1140px';
            } else {
                return '100%';
            }
        },
        clearProjectsMsg() {
            let projects = this.$store.state.projectLayers.projects;
            if (Object.keys(projects).length != 0) {
                return (
                    'User posted layers for ' +
                    projects[Object.keys(projects)[0]].ProjectName +
                    ' will be cleared. Continue?'
                );
            } else {
                return '';
            }
        },
    },
};
</script>

<style>
.list-group-item button {
    background-color: #006395;
    color: #eee;
    border: none;
    padding: 7px;
}

.list-group-item-secondary {
    background-color: #eaeaea !important;
}

h5 {
    font-size: 18px;
    color: var(--black);
}

p {
    font-size: 16px;
}

.filterDrop .filter-group {
    width: 269px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
}

.filterDrop .filter-group > div {
    height: 407px !important;
}

.filter-group p {
    background-color: black;
    color: #eee;
    padding: 10px;
}

select {
    width: 160px;
    height: 30px;
    margin-bottom: 15px !important;
    color: #393939;
    font-size: 14px !important;
}

.form-control {
    display: block;
    width: 80% !important;
    height: calc(1em + 0.75rem + 2px);
    font-size: 0.8rem !important;
    font-weight: 600 !important;
    line-height: 1.5;
    color: #495057;
    background-color: rgb(255, 255, 255) !important;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0rem !important;
}

.filter-group p a {
    color: #eee !important;
}

.map_arrow {
    position: absolute;
    /* left: -13px; */
    z-index: 999;
}

.filteredItem {
    background-color: #fff;
    width: auto;
    padding: 0.375rem 0.75rem !important;
    margin-right: 4px;
    color: #1d1d1d;
    font-weight: bold;
    font-size: 14px;
    /* border: 1px solid #ced4da; */
    padding: 6px !important;
}

.removeFilter {
    float: right;
    margin-left: 0 !important;
}

.removeFilter > div {
    background-color: #fff;
    padding: 5px;
    font-weight: 500;
    margin: 0 !important;
}

.removeFilter a {
    color: #006395 !important;
}

#outerserachinput {
    width: 20px;
    border-color: white;
    height: 25px;
}

#outerserachinput .e-input {
    display: none;
    padding: 0px;
}

/*search design code*/

.iconFilter {
    vertical-align: sub;
}

.filter-by {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    padding: 20px 0;
}

.filter-box {
    z-index: 99;
    position: absolute !important;
    width: 210px;
    /* left:-42px; 
  top:67px; */
    top: 40px !important;
    left: -51px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px !important;
    opacity: 1;
    overflow-y: hidden !important;
}

.filterDrop .filter-group .custom-select {
    width: 226px !important;
    height: 48px;
}

#mapApply {
    height: 48px;
    width: 123px;
}

input.test {
    width: 199px !important;
    margin: 0 !important;
    margin: 0 !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 30px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
}

.filterItem {
    line-height: 1.9;
}

.wide-map {
    z-index: 0;
}

.overflow {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 440px;
}

.topPadding {
    top: 8px;
}

.iconCheck {
    position: absolute;
    top: 27px;
    left: 248px;
}

.modalScreen {
    height: 85vh;
    z-index: 999999;
}

.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}

.filters {
    font-size: 14px;
}

.noPadding {
    padding: 0 !important;
}

input.searchinputList {
    /* background: url("../../../assets/search-outline.svg") no-repeat !important;  border: none; */
    background: none !important;
    margin-right: -151px;
    padding-left: 25px;
    /* border-bottom: 1px solid #ccc; */
    width: 180px;
    margin-bottom: 10px;
    top: 3px;
    position: relative;
    cursor: pointer;
    z-index: 0;
}

input.test {
    margin: 0 0px 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    /* width:90px!important; */
    width: 464px !important;
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.mr-6 {
    min-width: 100% !important;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    background-image: url(../../assets/icon_tick_lg1.svg);
    background-repeat: no-repeat;
    background-position-x: 248px;
    background-position-y: 27px;
    background-blend-mode: color-burn;
}

@media only screen and (min-width: 768px) {
    .map.sub_header_bg {
        height: 115px !important;
    }
}

.mapView-map-box {
    height: 440px;
}

.mapView-map-box-expanded {
    height: 440px;
}

.mapView-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }

    #lmapSmall {
        opacity: 1;
        height: 10in;
        width: 15in;
    }

    /* #mapContainer{
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
  } */
    /* .mapView-map-box-print{
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
  } */
}

.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}

.applyButtonSelect {
    background: #006395;
    color: white;
    border: none;
    cursor: pointer;
}

.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}

.model_style {
    width: 900px !important;
}

/* styles for slightly narrower screens */
@media (max-width: 1100px) {
    /* .leftToolbar {
        display: none;
    } */

    .col-9 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .map_arrow {
        display: none;
    }
}

/* styles for ipad layout */
@media (max-width: 830px) {
    /* .leftToolbar {
        display: none;
    } */

    .col-9 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .map_arrow {
        display: none;
    }

    .full-view {
        /* min-width: 720px !important; */
        /* width: 720px !important; */
        left: auto !important;
    }
}
#searchFilter {
    width: 300px !important;
}
.sort-label {
    text-wrap: nowrap;
    margin-top: 4px;
    margin-right: 2px;
}

.sort-input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3px;
    width: 100%;
    max-width: 400px;
    height: 2.5em;
}
.sort-select {
    width: 100%;
    height: 2.5em;
    margin: 0 !important;
}
</style>
