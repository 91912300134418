<!-- 
    *This component shows the Three most recent queue notifications
 -->
<template>
    <div class="recentNotifications-container">
        <div v-if="notificationQueue.length == 0">
            <b-col cols="12 p-0 notification my-1"
                ><p class="px-4 notifications-empty">
                    No new notifications available.
                </p></b-col
            >
        </div>
        <div v-else class="notifications-container">
            <div
                class="notification-messages "
                :style="{ maxHeight: containerHeight }"
            >
                <div v-for="(service, index) in notificationQueue" :key="index">
                    <div
                        class="queueToastMsg"
                        :ref="'queueToastMsg-' + index"
                        :class="{
                            startToastMsg: service.queueState === 'start',
                            progressToastMsg: service.queueState === 'progress',
                            completeToastMsg: service.queueState === 'complete',
                            errorToastMsg: service.queueState === 'error',
                            queuedToastMsg: service.queueState === 'queue',
                        }"
                    >
                        <b-col cols="12 p-0 notification my-1">
                            <b-col cols="2" class="float-left">
                                <img
                                    :src="getImgSrc(service.queueState)"
                                    class="mr-2 stateImg"
                                />
                            </b-col>
                            <b-col cols="8" class="float-left">
                                <div>
                                    <p class="queue-msg">
                                        <span class="queue-head">
                                            {{ service.queueService }}</span
                                        >
                                        {{ service.queueMessage }}
                                    </p>
                                    <span
                                        class="queue-link"
                                        v-html="service.downloadLink"
                                        v-if="service.downloadLink !== ''"
                                    ></span>
                                </div> </b-col
                            ><b-col cols="2" class="float-left text-center">
                                <img
                                    @click="
                                        removeQueueToast(service.queueService)
                                    "
                                    src="../assets/close_big.svg"
                                    class="ml-1 stateImg m-1 pointer"
                                />
                            </b-col>
                        </b-col>
                    </div>
                </div>
            </div>
            <div class="notification-clear">
                <b-button
                    @click="clearNotifications()"
                    class="mx-2"
                    variant="outline-secondary"
                >
                    CLEAR
                </b-button>
                <b-button
                    @click="expandNotifications()"
                    class="mx-2"
                    variant="outline-secondary"
                >
                    {{ isExpanded ? 'Collapse' : 'Expand' }}
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
export default {
    name: 'RecentNotifications',
    data() {
        return {
            containerHeight: '172px',
            isExpanded: false,
        };
    },

    methods: {
        ...mapMutations('common', [
            'removeQueueMessage',
            'clearNotificationQueue',
            'setNotificationBellOff',
        ]),
        getImgSrc(queueState) {
            switch (queueState) {
                case 'start':
                    return require('../assets/start.svg');
                case 'progress':
                    return require('../assets/progress.svg');
                case 'complete':
                    return require('../assets/complete.svg');
                case 'error':
                    return require('../assets/error.svg');
                case 'queue':
                    return require('../assets/queue.svg');
                default:
                    return '';
            }
        },
        removeQueueToast(queueService) {
            this.removeQueueMessage(queueService);
        },
        clearNotifications() {
            this.clearNotificationQueue();
            this.setNotificationBellOff();
        },
        expandNotifications() {
            this.isExpanded = !this.isExpanded;

            this.containerHeight =
                this.isExpanded && this.notificationQueue.length !== 3
                    ? 'fit-content'
                    : '172px';
        },
    },
    created() {
        this.setNotificationBellOff();
    },
    computed: {
        ...mapState('common', ['notificationQueue']),
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
    },
    watch: {
        async ProjectID(newProjectID, oldProjectID) {
            if (newProjectID != oldProjectID) {
                this.clearNotifications();
                this.setNotificationBellOff();
            }
        },
    },
};
</script>
<style scoped>
.recentNotifications-container {
    width: 380px !important;
}
.notification-messages {
    overflow-y: auto;
    min-height: 54px;
}
.notification-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    background-color: #f8f8f8;
}
.notification-clear button {
    width: 115px;
}
.notification {
    height: 52px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.startToastMsg {
    border-left: 6px solid #ffc107;
}
.progressToastMsg {
    border-left: 6px solid #17a2b8;
}
.completeToastMsg {
    border-left: 6px solid #28a745;
}
.errorToastMsg {
    border-left: 6px solid #dc3545;
}
.queuedToastMsg {
    border-left: 6px solid #9d9d9d;
}
.title,
.notifications-empty {
    line-height: 50px;
    text-align: center;
}
.title {
    font-weight: 550;
    font-size: 1rem;
}
</style>
