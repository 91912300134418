<template>
    <div class="userrightmanagement" v-if="showPage">
        <Header v-bind:showVersionToggle="false" />
        <b-container fluid class="sub_header_bg">
            <b-container class="p-0 container-management">
                <div class="parallax-wrapper">
                    <b-row class="mb-3">
                        <b-col cols="12">
                            <a @click="navigateDashboard">
                                <img
                                    src="../../assets/arrow_left.svg"
                                    class=""
                                />
                                <a href="#" class="floar-left ml-1 back">
                                    Back to dashboard</a
                                >
                            </a>
                        </b-col>
                    </b-row>

                    <b-row class="vh-10 text-left" align-v="center">
                        <b-col cols="4" class="pt-2">
                            <h1 class="">Access management</h1>
                        </b-col>

                        <b-col
                            class="text-right"
                            v-if="
                                !addNewGroup &&
                                    !editUserv &&
                                    !queueChangev &&
                                    step == 0
                            "
                        >
                            <b-button
                                rounded
                                class="buttonSelect"
                                @click="showaddGroup()"
                            >
                                Add new group
                            </b-button>
                        </b-col>
                        <b-col
                            class="text-right "
                            v-if="addNewGroup && step == 0"
                        >
                            <span v-if="validateInput">
                                <span class="mr-3" disabled>
                                    Save and manage users
                                </span>
                            </span>
                            <span v-if="!validateInput">
                                <a
                                    class="blue mr-3"
                                    @click="addGroups('manageUsers')"
                                >
                                    Save and manage users
                                </a>
                            </span>

                            <b-button
                                :disabled="validateInput"
                                rounded
                                class="buttonSelect"
                                @click="addGroups('save')"
                            >
                                Save
                            </b-button>
                        </b-col>
                        <b-col class="text-right" v-if="editUserv && step == 0">
                            <span v-if="validateInput">
                                <span class="mr-3" disabled>
                                    Save and manage users
                                </span>
                            </span>
                            <span v-if="!validateInput">
                                <a
                                    class="blue mr-3"
                                    @click="editGroups('manageUsers')"
                                >
                                    Save and manage users
                                </a>
                            </span>
                            <b-button
                                rounded
                                class="buttonSelect"
                                @click="editGroups()"
                                :disabled="validateInput"
                            >
                                Save changes
                            </b-button>
                        </b-col>
                        <b-col class="text-left" v-if="queueChangev">
                            <!-- <b-button
              rounded
              class="buttonSelect ml-3"
              @click="queueProjectChanges()"
            >
              Queue Changes
            </b-button> -->
                        </b-col>
                    </b-row>

                    <!-- <b-row class="pt-2">
          <div class="borderBlue mt-3 ml-4">
            <h5 class="tab_head">Group Management</h5>
          </div>
        </b-row> -->

                    <div class="positionRelative accessManagementTabs">
                        <b-tabs
                            v-model="step"
                            active-nav-item-class="borderBlue"
                            active-tab-class="font-weight-bold borderBlue "
                            content-class=""
                        >
                            <b-tab
                                class="heading borderBlue"
                                title="Group management"
                                @click="queueReverse('Group management')"
                            >
                                <template
                                    v-if="selectedTab == 'Group management'"
                                >
                                    <div v-if="!addNewGroup && !editUserv">
                                        <b-row>
                                            <a
                                                class="heading my-3 positionAbsolute"
                                                v-if="!sortToggle"
                                                @click="sortingName()"
                                            >
                                                <span>Sort by</span>
                                                <span class="light-grey"
                                                    >Alphabetical A - Z</span
                                                >
                                                <img
                                                    src="../../assets/chevron-down-outlines.svg"
                                                />
                                            </a>
                                            <a
                                                class="heading my-3 positionAbsolute"
                                                v-if="sortToggle"
                                                @click="sortingName()"
                                            >
                                                <span>Sort by</span>
                                                <span class="light-grey"
                                                    >Alphabetical Z - A</span
                                                >
                                                <img
                                                    src="../../assets/chevron-up-outline.svg"
                                                />
                                            </a>
                                        </b-row>
                                        <b-row
                                            v-if="!sortToggle"
                                            class="pt-5 mt-2 card-head"
                                        >
                                            <b-col
                                                cols="4"
                                                v-for="(l, index) in orderBy(
                                                    list,
                                                    'UserGroupName',
                                                    1
                                                )"
                                                :key="index"
                                            >
                                                <div class="mb-5">
                                                    <b-card
                                                        class="border box-shadow card groupheader"
                                                        :title="l.UserGroupName"
                                                    >
                                                        <b-button-group
                                                            class="button-group dotted-btn positionAbsolute UserGroupName1"
                                                        >
                                                            <b-button
                                                                class="button-dropdown editGroup edit-btn"
                                                            >
                                                                <b-dropdown
                                                                    right
                                                                    class="edit"
                                                                >
                                                                    <template
                                                                        slot="button-content"
                                                                    >
                                                                        <a
                                                                            class="option"
                                                                            ><img
                                                                                class="edit-dots"
                                                                                src="../../assets/edit_user.svg"
                                                                        /></a>
                                                                    </template>
                                                                    <b-dropdown-item
                                                                        class="edit"
                                                                        @click="
                                                                            editUser(
                                                                                l.UserGroupID
                                                                            )
                                                                        "
                                                                        >Edit
                                                                        group</b-dropdown-item
                                                                    >
                                                                    <!-- <b-dropdown-divider></b-dropdown-divider> -->
                                                                    <b-dropdown-item
                                                                        class="delete"
                                                                        @click="
                                                                            showModal(
                                                                                l
                                                                            )
                                                                        "
                                                                    >
                                                                        Delete
                                                                        group
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </b-button>
                                                        </b-button-group>

                                                        <b-card-text
                                                            class="groupdescription"
                                                        >
                                                            {{
                                                                l.UserGroupDescription
                                                            }}
                                                        </b-card-text>
                                                    </b-card>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-row
                                            v-if="sortToggle"
                                            class="pt-5 mt-2 card-head"
                                        >
                                            <b-col
                                                cols="4"
                                                v-for="(l, index) in orderBy(
                                                    list,
                                                    'UserGroupName',
                                                    -1
                                                )"
                                                :key="index"
                                            >
                                                <div class="mb-5">
                                                    <b-card
                                                        class="border box-shadow card groupheader"
                                                        :title="l.UserGroupName"
                                                    >
                                                        <b-button-group
                                                            class="button-group dotted-btn positionRelative UserGroupName2"
                                                        >
                                                            <b-button
                                                                class="button-dropdown editGroup edit-btn"
                                                            >
                                                                <b-dropdown
                                                                    right
                                                                    class="edit"
                                                                >
                                                                    <template
                                                                        slot="button-content"
                                                                    >
                                                                        <a
                                                                            class="option"
                                                                            ><img
                                                                                class="edit-dots"
                                                                                src="../../assets/edit_user.svg"
                                                                        /></a>
                                                                    </template>
                                                                    <b-dropdown-item
                                                                        class="edit"
                                                                        @click="
                                                                            editUser(
                                                                                l.UserGroupID
                                                                            )
                                                                        "
                                                                        >Edit
                                                                        group</b-dropdown-item
                                                                    >
                                                                    <!-- <b-dropdown-divider></b-dropdown-divider> -->
                                                                    <b-dropdown-item
                                                                        class="delete"
                                                                        @click="
                                                                            showModal(
                                                                                l
                                                                            )
                                                                        "
                                                                    >
                                                                        Delete
                                                                        group
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </b-button>
                                                        </b-button-group>

                                                        <b-card-text
                                                            class="groupdescription"
                                                        >
                                                            {{
                                                                l.UserGroupDescription
                                                            }}
                                                        </b-card-text>
                                                    </b-card>
                                                </div>
                                            </b-col>
                                        </b-row>

                                        <b-modal
                                            ref="my-modal"
                                            class="model_style"
                                            hide-header
                                            hide-footer
                                            centered
                                        >
                                            <div class="d-block text-center">
                                                <div
                                                    class="text-right cursor-pointer"
                                                >
                                                    <img
                                                        src="../../assets/close_icon.svg"
                                                        @click="hideModal"
                                                    />
                                                </div>
                                                <h5 class="mt-2">
                                                    Delete group "{{
                                                        modalGroupId
                                                    }}"
                                                </h5>
                                                <p>
                                                    This will remove
                                                    {{ userCount }} users from
                                                    the group.
                                                </p>

                                                <br />
                                                <div class="mt-3 mb-4 delModal">
                                                    <a
                                                        class="text-primary mr-5"
                                                        @click="hideModal"
                                                        >No, don't delete</a
                                                    >
                                                    <button
                                                        class="buttonDelete"
                                                        @click="
                                                            deleteGroups(
                                                                modalID
                                                            ),
                                                                backtoGroups()
                                                        "
                                                    >
                                                        Delete group
                                                    </button>
                                                </div>
                                            </div>
                                        </b-modal>
                                    </div>
                                    <div v-if="addNewGroup" class="ml-2 mt-4">
                                        <h5
                                            for="input-invalid"
                                            class="grpDetail"
                                        >
                                            Group details
                                        </h5>

                                        <b-form-group class="md-4" role="group">
                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Group name',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    validated('UserGroupName')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'UserGroupName'
                                                        )
                                                    "
                                                    name="UserGroupName"
                                                    v-model="
                                                        $v.addGroup
                                                            .UserGroupName
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="userGroupName-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="userGroupName-feedback"
                                                >
                                                    Please enter user group name
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>
                                        </b-form-group>

                                        <b-form-group class="md-4" role="group">
                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Group description',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    validated(
                                                        'UserGroupDescription'
                                                    )
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'UserGroupDescription'
                                                        )
                                                    "
                                                    name="UserGroupDescription"
                                                    v-model="
                                                        $v.addGroup
                                                            .UserGroupDescription
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="userGroupDescription-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="userGroupDescription-feedback"
                                                >
                                                    Please enter user group
                                                    description
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>
                                        </b-form-group>
                                    </div>
                                    <div v-if="editUserv" class="ml-2 mt-4">
                                        <h5 for="input-invalid">
                                            Group details
                                        </h5>
                                        <b-form-group class="md-4" role="group">
                                            <CustomFloatingLabel
                                                :class="
                                                    groupDetailsPresent &&
                                                    !editGroup.UserGroupName
                                                        ? 'fieldErr'
                                                        : ''
                                                "
                                                :config="{
                                                    label: 'Group name',
                                                }"
                                                v-bind:label="floatLabelEdit"
                                            >
                                                <input
                                                    autocomplete="off"
                                                    v-model="
                                                        editGroup.UserGroupName
                                                    "
                                                    type="text"
                                                    class="inputbox box normal_input"
                                                />
                                            </CustomFloatingLabel>
                                            <span
                                                class="errManage"
                                                v-if="groupDetailsPresent"
                                            >
                                                <div
                                                    v-if="
                                                        !editGroup.UserGroupName
                                                    "
                                                    class="errorMsg_input"
                                                >
                                                    <span
                                                        ><img
                                                            src="../../assets/Icon_Warning_Filled_16.svg"
                                                    /></span>
                                                    <small
                                                        class="errorTextColor"
                                                        >Please enter group
                                                        name</small
                                                    >
                                                </div>
                                            </span>
                                        </b-form-group>
                                        <b-form-group class="md-4" role="group">
                                            <CustomFloatingLabel
                                                :class="
                                                    groupDetailsPresent &&
                                                    !editGroup.UserGroupDescription
                                                        ? 'fieldErr'
                                                        : ''
                                                "
                                                :config="{
                                                    label: 'Group description',
                                                }"
                                                v-bind:label="floatLabelEdit"
                                            >
                                                <input
                                                    autocomplete="off"
                                                    v-model="
                                                        editGroup.UserGroupDescription
                                                    "
                                                    type="text"
                                                    class="inputbox box normal_input"
                                                />
                                            </CustomFloatingLabel>
                                            <span
                                                class="errManage"
                                                v-if="groupDetailsPresent"
                                            >
                                                <div
                                                    v-if="
                                                        !editGroup.UserGroupDescription
                                                    "
                                                    class="errorMsg_input"
                                                >
                                                    <span
                                                        ><img
                                                            src="../../assets/Icon_Warning_Filled_16.svg"
                                                    /></span>
                                                    <small
                                                        class="errorTextColor"
                                                        >Please enter group
                                                        description</small
                                                    >
                                                </div>
                                            </span>
                                        </b-form-group>
                                    </div>
                                </template>
                            </b-tab>

                            <b-tab
                                title="Group access"
                                @click="queueChange('Group access')"
                            >
                                <template v-if="selectedTab == 'Group access'">
                                    <b-col class="p-0">
                                        <div class="tabs-bg">
                                            <b-tabs
                                                active-nav-item-class="borderBlue dark-line"
                                                active-tab-class="font-weight-bold borderBlue "
                                                content-class=""
                                                class="content-tabs pad-bot-150"
                                            >
                                                <b-tab
                                                    title="Users"
                                                    @click="
                                                        GAToolsDash(
                                                            GAUserShow,
                                                            'Users'
                                                        )
                                                    "
                                                    active
                                                >
                                                    <GAUsers
                                                        v-if="
                                                            selectedSubTab ==
                                                                'Users'
                                                        "
                                                        @clickedUser="
                                                            showDashUser
                                                        "
                                                        :groupCreated="
                                                            newGroupCreated
                                                        "
                                                    ></GAUsers>
                                                </b-tab>
                                                <b-tab
                                                    title="Groups"
                                                    @click="
                                                        GAToolsDash(
                                                            GAGroupsShow,
                                                            'Groups'
                                                        )
                                                    "
                                                >
                                                    <GAGroups
                                                        v-if="
                                                            selectedSubTab ==
                                                                'Groups'
                                                        "
                                                        @clickedGroups="
                                                            showDashGroups
                                                        "
                                                        :groupCreated="
                                                            newGroupCreated
                                                        "
                                                    ></GAGroups>
                                                </b-tab>
                                                <b-tab
                                                    title="Portal"
                                                    @click="
                                                        GAToolsDash(
                                                            GAPortalShow,
                                                            'Portal'
                                                        )
                                                    "
                                                >
                                                    <GAPortal
                                                        v-if="
                                                            selectedSubTab ==
                                                                'Portal'
                                                        "
                                                        @clickedPortal="
                                                            showDashPortal
                                                        "
                                                        :groupCreated="
                                                            newGroupCreated
                                                        "
                                                    ></GAPortal>
                                                </b-tab>
                                                <b-tab
                                                    title="Datasets"
                                                    @click="
                                                        GAToolsDash(
                                                            GADatasetShow,
                                                            'Datasets'
                                                        )
                                                    "
                                                >
                                                    <GADatasets
                                                        v-if="
                                                            selectedSubTab ==
                                                                'Datasets'
                                                        "
                                                        @clickedDataset="
                                                            showDashDataset
                                                        "
                                                        :groupCreated="
                                                            newGroupCreated
                                                        "
                                                    ></GADatasets>
                                                </b-tab>
                                                <b-tab
                                                    title="Tools"
                                                    @click="
                                                        GAToolsDash(
                                                            GAToolsShow,
                                                            'Tools'
                                                        )
                                                    "
                                                >
                                                    <GATools
                                                        v-if="
                                                            selectedSubTab ==
                                                                'Tools'
                                                        "
                                                        @clicked="showDash"
                                                        :groupCreated="
                                                            newGroupCreated
                                                        "
                                                    ></GATools>
                                                </b-tab>
                                            </b-tabs>
                                        </div>
                                    </b-col>
                                </template>
                            </b-tab>

                            <!-- -------------------------------------------- Tab 2 Ends Here-------------------------------------------- -->

                            <b-tab
                                title="Projects"
                                @click="queueChange('Project access')"
                            >
                                <template
                                    v-if="selectedTab == 'Project access'"
                                >
                                    <div class="tabs-bg">
                                        <b-tabs
                                            active-nav-item-class="borderBlue dark-line"
                                            active-tab-class="font-weight-bold borderBlue "
                                            content-class=""
                                            class="content-tabs pad-bot-150"
                                        >
                                            <b-tab
                                                title="Datasets"
                                                @click="
                                                    GAToolsDash(
                                                        datasetShow,
                                                        'Datasets'
                                                    )
                                                "
                                                active
                                            >
                                                <Datasets
                                                    v-if="
                                                        selectedSubTab ==
                                                            'Datasets'
                                                    "
                                                    @clickedDatasets="
                                                        showDashDatasetProject
                                                    "
                                                ></Datasets>
                                            </b-tab>

                                            <b-tab
                                                title="Tools"
                                                @click="
                                                    GAToolsDash(
                                                        toolsShow,
                                                        'Tools'
                                                    )
                                                "
                                            >
                                                <ProjectTools
                                                    v-if="
                                                        selectedSubTab ==
                                                            'Tools'
                                                    "
                                                    @clickedTools="
                                                        showDashToolsProject
                                                    "
                                                ></ProjectTools>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </template>
                            </b-tab>

                            <!-- -------------------------------------------- Tab 3 Ends Here-------------------------------------------- -->
                        </b-tabs>
                    </div>
                </div>
            </b-container>
        </b-container>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :messageTitle="messageTitle"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
            @hideToast="hideToast"
        />
        <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../assets/success_tick.svg" class="mr-3 tick"/>
                <div style="display:inline-block;"><b>Success</b></div><br>
                 <p class="grey-txt">{{successToastValue}}</p>
                <img src="../../assets/close_big.svg" class="mx-4 toastLogo" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../assets/failure_cross.svg" class="mr-3"/>
                failed <br/> {{failureToastValue}}
                <img src="../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
        <Footer />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>
<script>
import router from '../../router';
import Header from '../Header';
import Footer from '../Footer';
import Datasets from './ProjectAccess/Datasets';
import ProjectTools from './ProjectAccess/ProjectTools';
import GADatasets from './GroupAccess/GADatasets';
import GAUsers from './GroupAccess/GAUsers';
import GATools from './GroupAccess/GATools';
import GAGroups from './GroupAccess/GAGroups';
import GAPortal from './GroupAccess/GAPortal';
import CustomFloatingLabel from '../CustomFloatingLabel';

// import { managementService } from "../../services/management.service";
import { userGroupService } from '../../services/userGroup.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
import AccessPermissionModal from '../AccessPermissionModal';
//import dashboard from '../../views/Dashboard';
import { mapState, mapActions, mapMutations } from 'vuex';
import ToastMessages from '../ToastMessages.vue';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import CustomValidateInput from '../CustomValidateInput';

Vue.component('jw-pagination', JwPagination);

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    mixins: [Vue2Filters.mixin],
    components: {
        Header,
        Footer,
        Datasets,
        ProjectTools,
        GADatasets,
        GAPortal,
        GAGroups,
        GAUsers,
        GATools,
        AccessPermissionModal,
        CustomFloatingLabel,
        // dashboard,
        ToastMessages,
        CustomValidateInput,
    },
    validations() {
        return {
            addGroup: {
                UserGroupName: {
                    required,
                },
                UserGroupDescription: {
                    required,
                },
            },
        };
    },
    data() {
        return {
            serachText: true,
            customLabels,
            selected: [],
            allSelected: false,
            perPage: 6,
            userCount: 0,
            projectDatasetIds: [],
            newGroupCreated: false,
            step: 0,
            // projectToolsList: [],
            // toolsList: [],
            // datasetList: [],
            // projectList: [],
            // pageOfItems: [],
            // pageOfProjectDataset: [],
            // pageOfProjectTools: [],
            // pageOfToolList: [],
            projectName: '',
            addNewGroup: false,
            editUserv: false,
            queueChangev: false,
            modalGroupId: '',
            modalID: '',
            list: [],
            sortToggle: false,
            addGroup: {
                UserGroupName: null,
                UserGroupDescription: null,
            },
            editGroup: {
                UserGroupName: null,
                UserGroupDescription: null,
                UserGroupID: null,
            },
            // successToast: false,
            //   failureToast:false,
            //   successToastValue:'',
            //   failureToastValue:'',
            showPage: true,
            thisfilename: null,
            GAToolsShow: true,
            GAPortalShow: true,
            GAUserShow: true,
            GADatasetShow: true,
            GAGroupsShow: true,
            datasetShow: true,
            toolsShow: true,
            showfromChildPage: true,
            selectedTab: 'Group management',
            selectedSubTab: '',
            groupDetailsPresent: false,
            // searchProject: "",
            // searchProjectTools: "",
            // searchProjectDatasets: "",
            // searchToolsList: "",
        };
    },
    computed: {
        ...mapState('common', [
            'showSuccess',
            'showFailure',
            'successToastMessage',
            'failureToastMessage',
            'messageTitle',
        ]),
        validated() {
            return (status) => {
                const { $dirty } = this.$v.addGroup[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
        // projectFilteredList() {
        //   return this.pageOfItems.filter((post) => {
        //     return post.ProjectName.toLowerCase().includes(
        //       this.searchProject.toLowerCase()
        //     );
        //   });
        // },
        // datasetsFilteredList() {
        //   return this.pageOfProjectDataset.filter((post) => {
        //     return post.DatasetName.toLowerCase().includes(
        //       this.searchProjectDatasets.toLowerCase()
        //     );
        //   });
        // },
        // toolsProjectFiltered() {
        //   return this.pageOfProjectTools.filter((post) => {
        //     return post.ProjectName.toLowerCase().includes(
        //       this.searchProjectTools.toLowerCase()
        //     );
        //   });
        // },

        // toolsFilteredList() {
        //   return this.pageOfToolList.filter((post) => {
        //     return post.ToolName.toLowerCase().includes(
        //       this.searchToolsList.toLowerCase()
        //     );
        //   });
        // },
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
        if (this.showPage) {
            this.listofAccessManagement();
        }
    },
    created() {
        // this.getProjects();
        // this.getDataset();
        // this.getProjectsTool();
        // this.getTools();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        hideToast() {
            this.setToastMessage({
                showFailure: false,
            });
            this.setToastMessage({
                showSuccess: false,
            });
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.addGroup[name];
            return $dirty ? !$error : null;
        },
        pagePermit() {
            this.showPage = true;

            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('Access management')) {
                    vm.showPage = true;
                }
            });

            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        showDash(value) {
            this.GAToolsShow = value;
        },
        showDashPortal(value) {
            this.GAPortalShow = value;
        },
        showDashUser(value) {
            this.GAUserShow = value;
        },
        showDashDataset(value) {
            this.GADatasetShow = value;
        },
        showDashGroups(value) {
            this.GAGroupsShow = value;
        },
        showDashDatasetProject(value) {
            this.datasetShow = value;
        },
        showDashToolsProject(value) {
            this.toolsShow = value;
        },

        GAToolsDash(showGA, subTabName) {
            this.selectedSubTab = subTabName;
            this.showPage = true;

            // if(!showGA){
            //   setTimeout(() =>{
            //     this.$refs["permission-modal"].showPermissionModal();
            //   });
            //   this.showPage = showGA;
            // }
        },
        hidePermissionModal() {
            router.push(`/dashboard`);
            this.$refs['permission-modal'].hide();
        },
        listofAccessManagement() {
            userGroupService
                .getUserGroupsforAccessManagement()
                .then((res) => (this.list = res.data));
        },
        closeSuccessToast() {
            this.successToast = false;
        },
        closeFailureToast() {
            this.failureToast = false;
        },
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        showModal(list) {
            userGroupService
                .countUserGroup(list.UserGroupID)
                .then((res) => (this.userCount = res.data));
            this.modalGroupId = list.UserGroupName;
            this.modalID = list.UserGroupID;
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },
        showaddGroup() {
            this.addNewGroup = true;
        },
        backtoGroups() {
            this.newGroupCreated = !this.newGroupCreated;
            this.addNewGroup = false;
            this.editUserv = false;
        },
        editUser(UserGroupID) {
            this.editUserv = true;
            let groupDetails = this.list.filter(
                (item) => item.UserGroupID == UserGroupID
            );
            this.editGroup.UserGroupID = groupDetails[0].UserGroupID;
            this.editGroup.UserGroupName = groupDetails[0].UserGroupName;
            this.editGroup.UserGroupDescription =
                groupDetails[0].UserGroupDescription;
        },
        queueChange(tabName) {
            this.selectedTab = tabName;
            this.selectedSubTab =
                tabName == 'Group access' ? 'Users' : 'Datasets';
            this.queueChangev = true;
        },
        queueReverse(tabName) {
            this.listofAccessManagement();
            this.selectedTab = tabName;
            this.queueChangev = false;
        },

        sortingName() {
            this.sortToggle = !this.sortToggle;
        },
        editGroups(action) {
            this.groupDetailsPresent = false;
            if (
                !this.editGroup.UserGroupName ||
                !this.editGroup.UserGroupDescription
            ) {
                this.groupDetailsPresent = true;
                this.$forceUpdate();
            }
            if (!this.groupDetailsPresent) {
                const data = {
                    UserGroupID: this.editGroup.UserGroupID,
                    UserGroupName: this.editGroup.UserGroupName,
                    UserGroupDescription: this.editGroup.UserGroupDescription,
                };

                userGroupService
                    .editGroups(data, this.editGroup.UserGroupID)
                    .then((res) => {
                        if (res.data.StatusCode === 202) {
                            if (action == 'manageUsers') {
                                this.step = 1;
                                this.selectedTab = 'Group access';
                                this.selectedSubTab = 'Users';
                            } else {
                                this.step = 0;
                            }
                            this.editUserv = false;
                            this.listofAccessManagement();

                            this.setToastMessage({
                                showSuccess: true,
                                showFailure: false,
                                successToastMessage:
                                    'User group updated successfully',
                                messageTitle: this.messageTitle,
                            });
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            this.setToastMessage({
                                showSuccess: false,
                                showFailure: true,
                                failureToastMessage: 'Something went wrong',
                                messageTitle: this.messageTitle,
                            });
                        }
                    });
                this.backtoGroups();
            }
        },

        addGroups(action) {
            this.$v.addGroup.$touch();
            if (this.$v.addGroup.$anyError) {
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    failureToastMessage:
                        'Not all required fields are complete. Please check all sections, then retry.',
                    messageTitle: this.messageTitle,
                });

                return;
            }

            this.groupDetailsPresent = false;
            if (
                !this.addGroup.UserGroupName ||
                !this.addGroup.UserGroupDescription
            ) {
                this.groupDetailsPresent = true;
                this.$forceUpdate();
            }
            if (!this.groupDetailsPresent) {
                const data = {
                    UserGroupName: this.addGroup.UserGroupName,
                    UserGroupDescription: this.addGroup.UserGroupDescription,
                };

                userGroupService
                    .addGroups(data)
                    .then((res) => {
                        if (res.data.StatusCode === 201) {
                            this.newGroupCreated = !this.newGroupCreated;
                            if (action == 'manageUsers') {
                                localStorage.setItem(
                                    'newGroupName',
                                    this.addGroup.UserGroupName
                                );
                                this.step = 1;
                                this.selectedTab = 'Group access';
                                this.selectedSubTab = 'Users';
                            } else {
                                this.step = 0;
                            }
                            this.listofAccessManagement();
                            this.addGroup.UserGroupName = null;
                            this.addGroup.UserGroupDescription = null;

                            this.setToastMessage({
                                showSuccess: true,
                                showFailure: false,
                                successToastMessage: 'New group created.',
                                messageTitle: null,
                            });
                        } else if (res.data.StatusCode === 500) {
                            //nothing
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            this.setToastMessage({
                                showSuccess: false,
                                showFailure: true,
                                failureToastMessage:
                                    'Group name already exists',
                                messageTitle: null,
                            });
                        }
                    });
                this.backtoGroups();
            }
        },
        deleteGroups(modalID) {
            userGroupService.deleteGroups(modalID).then((res) => {
                if (res.data.StatusCode === 200) {
                    this.hideModal();
                    this.newGroupCreated = !this.newGroupCreated;
                    setTimeout(() => {
                        this.listofAccessManagement();
                    }, 5000);

                    this.setToastMessage({
                        showSuccess: true,
                        showFailure: false,
                        successToastMessage: 'Group  deleted successfully',
                        messageTitle: null,
                    });
                }
            });
        },
        //  selectAll(p) {
        //           this.projectDatasetIds = [];
        //  },
        queueProjectChanges() {
            this.queueChangev = true;
        },
        // checkedBox() {},
        // removeSearchText() {
        //   this.serachText = false;
        //   this.showText = true;
        // },
        // addSearchText() {
        //   this.serachText = true;
        //   this.showText = false;
        // },
        // onChangePage(pageOfItems) {
        //   this.pageOfItems = pageOfItems;
        // },
        // onChangeProjectDatasetPage(pageOfProjectDataset) {
        //   this.pageOfProjectDataset = pageOfProjectDataset;
        // },
        // onChangeProjectToolsPage(pageOfProjectTools) {
        //   this.pageOfProjectTools = pageOfProjectTools;
        // },
        // onChangeToolListPage(pageOfToolList) {
        //   this.pageOfToolList = pageOfToolList;
        // },
        // onChangeProjectList(test){
        //   this.test = test;
        // },
        // selectAll(p) {
        //   this.projectDatasetIds = [];

        //   if (!this.allSelected) {
        //     for (p in this.projectList) {
        //       this.projectDatasetIds.push(this.projectList[p].id);
        //     }
        //   }
        // },
        // getProjects() {
        //   managementService
        //     .getProjects()
        //     .then((res) => (this.projectList = res.data));
        // },
        // getDataset() {
        //   managementService
        //     .getDataset()
        //     .then((res) => (this.datasetList = res.data));
        // },
        // getProjectsTool() {
        //   managementService
        //     .getProjectsTool()
        //     .then((res) => (this.projectToolsList = res.data));
        // },
        // getTools() {
        //   managementService.getTools().then((res) => (this.toolsList = res.data));
        // },
    },
};
</script>

<style lang="scss" scoped>
.bg-light {
    border: 1px solid #bfbebf !important;
    max-width: 453px;
}

.card-header {
    background-color: #fff !important;
}

.card-header .accordian-header {
    background-color: transparent !important;
    border-bottom: none !important;
}
.form-check-input {
    position: relative;
    margin-top: 0rem;
    margin-left: 0rem;
}
.table td {
    vertical-align: middle !important;
    height: 55px;
}
.box {
    width: 499px;
    height: 48px;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
}
button.buttonDelete {
    background-color: #da1e27;
    color: #eee;
    padding: 7px 15px;
    border: none;
}

// .box-shadow {
//   box-shadow: 0px 3px 6px #00000029;
//   color: grey;
// }
.accordion-button {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
    span {
        margin-left: 48px;
        margin-left: 30px;
        display: block;
        width: 310px;
        // margin-top: -25px;
    }
}
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../assets/plusIcon.svg') no-repeat right 0.75rem center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../assets/minusIcon.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
.contentCollapse {
    background-color: #f9f9f9 !important;
    //width: 388px;
}
.accordian-header {
    background-color: transparent !important;
    border-bottom: none !important;
    .checkbox-allocate {
        position: absolute;
        left: 5px;
        top: 11px;
    }
}

.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .row1 {
        border-bottom: 3px solid #eaeaea;
        padding-top: 10px;
        padding-bottom: 10px !important;
        // h6.blue {
        //   color: #006395 !important;
        // }
    }
    .showProjectList {
        overflow: auto;
        height: 300px;
        width: 457px;
    }
    .header {
        padding: 10px 6px;
        border-bottom: 3px solid;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
.paginationStyle {
    border: none;
    /* margin: 0px auto !important; */
    width: 100% !important;
    margin-left: 12% !important;
    padding: 0px;
    display: inherit !important;
    //display: contents !important;
}
input.searchinput {
    background: url('../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
    color: #006395 !important;
}
input.test {
    width: 411px;
}
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 395px;
}
.editGroup {
    background: transparent;
    border: none;
}
.editGroup:hover {
    background: transparent;
    border: none;
}

.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: transparent !important;
    border: none;
}
// .btn-secondary{
//   color: #fff;
//     background-color: transparent !important;
//     border:none;
// }
.show > .btn-secondary.dropdown-toggle:hover {
    color: #fff;
    background-color: transparent !important;
    border: none;
}

.heading {
    color: #000000de;
}

.borderBlue {
    border-bottom: 5px solid #006395;
    width: auto;
}
.borderblue h5 {
    font-size: 18px !important;
    color: #161616 !important;
}
.groupdescription {
    font-weight: 400 !important;
    color: #000000de;
}
.groupheader {
    color: #000000de;
    width: 373px;
    height: 255px;
}
.box-shadow {
    box-shadow: 0px 3px 6px #00000029 !important;
    color: grey;
    border: 0 !important;
    border-radius: 0rem !important;
}

.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 98px;
    width: 486px;
    border-left: 6px solid #00db52;
    display: block;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.successToastMsg img.toastLogo {
    float: right;
    top: 0;
    position: relative;
    margin-top: -42px;
    margin-right: 10px;
}
.option {
    vertical-align: top;
}
.tick {
    position: relative;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    top: 32px;
}
.successToastMsg div {
    top: 21px;
    position: relative;
    left: 5px;
    font-size: 14px;
    color: #515151;
}
.successToastMsg p {
    position: relative;
    top: 21px;
    left: 47px;
    font-size: 14px;
    color: #515151;
}
// .card-text:last-child{
//   bottom: 50px !important;
//   position: relative !important;
// }
.grpDetail {
    color: #161616;
    font-size: 18px;
}
.errManage {
    color: #f00;
    // float: left;
    top: -20px;
    position: relative;
    display: block;
}
.errorMsg_input {
    position: relative;
    width: 499px;
}

.errorMsg_input span {
    float: right;
    position: relative;
    top: -40px;
    right: 15px;
    /* right: 88px; */
}
.fieldErr {
    border: 1px solid #f00;
    border-radius: 3px;
}
.accessManagementTabs {
    top: 14px;
}
.heading .heading {
    right: 0;
}
.UserGroupName1 {
    top: 10px;
    right: 0px;
}
.UserGroupName2 {
    bottom: 50px;
    left: 266px;
}
.invalid-text-custom {
    position: relative;
    float: left;
    font-weight: 400;
}
</style>
