<template>
    <div class="my-custom-floating-label">
        <FloatingLabel
            @focus="focus = true"
            @blur="focus = false"
            v-bind:editUserParams1="editUserParams"
            :config="config"
            :errorOnBlank="errorOnBlank"
        >
            <slot></slot>
        </FloatingLabel>
    </div>
</template>

<script>
import FloatingLabel from '../components/FloatingLabel';
export default {
    // props: ['config', 'editUserParams', 'errorOnBlank'],
    name: 'CustomFloatingLabel',
    props: {
        config: {
            line: false,
            name: 'wrapper',
            scale: false,
        },
        editUserParams: {
            type: String,
            required: false,
            default: '',
        },
        errorOnBlank: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        FloatingLabel,
    },
    created() {},
};
</script>
<style lang="scss">
.my-custom-floating-label {
    .input__container {
        border-radius: 3px;
        box-shadow: 0 0 0 1px #c6c6c6;
        .slot-container {
            position: relative;
            height: 48px;
        }
        &.input__container--focus {
            box-shadow: 0 0 0 2px #c6c6c6;
        }
    }
}
.label__placeholder {
    top: 50%;
    transform: translate(0, -50%);
    color: #626262;
    left: 17px;
    font-family: var(--ffr);
}
</style>
