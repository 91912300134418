<template lang="html">
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <b-container class="browse-files" v-else>
        <div id="app">
            <portal-bar :hideSearch="hideSearch"></portal-bar>
            <ejs-filemanager
                :id="fileManagerId"
                ref="file_instance"
                :contextMenuSettings="contextMenuSettings"
                :ajaxSettings="ajaxSettings"
                :menuClick="menuClick"
                :beforeSend="beforeSend"
                :fileOpen="fileOpen"
                :beforeDownload="beforeDownload"
                :toolbarSettings="toolSettings"
                :allowMultiSelection="allowMultiSelection"
                :uploadSettings="{ autoClose: true, maxFileSize: 1073741824 }"
                :menuOpen="menuOpen"
                :beforeImageLoad="beforeImageLoad"
                :success="success"
                :navigationPaneSettings="navigationPaneSettings"
                :allowDragAndDrop="allowDragAndDrop"
                :created="created"
                class="my-4"
            >
            </ejs-filemanager>
            <span class="manage">
                <b-modal
                    ref="attribute-modal"
                    class="model_style manage-model-block"
                    hide-header
                    hide-footer
                    centered
                    size="lg"
                >
                    <div class="manageModel">
                        <div class="text-right cursor-pointer">
                            <img
                                class="pointer"
                                src="../../assets/close_icon.svg"
                                @click="hideModal"
                            />
                        </div>
                        <h5 class="my-2 title text-center">
                            {{ attributeTitle }}
                        </h5>
                        <div class="manage-form">
                            <CustomFloatingLabel
                                class="input__container input__container--content directoryAssign grey-textbox"
                                :config="{
                                    label: 'Directory',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="text"
                                    autocomplete="off"
                                    v-model="this.testName"
                                    class="normal_input"
                                    :disabled="filledBox"
                                />
                            </CustomFloatingLabel>

                            <div class="assignAttr" v-if="newDirectory">
                                <div class="content">
                                    <!-- <CustomFloatingLabel :config="{label: 'Directory', name: 'wrapper', line: false, scale: false}"> -->

                                    <CustomFloatingLabel
                                        v-if="firstTextValue"
                                        :config="{
                                            label: 'Attribute Name',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <input
                                            type="text"
                                            autocomplete="off"
                                            v-model="attribute.AttributeName"
                                            class="normal_input"
                                            @input="errattributeName = false"
                                            @keypress="nameKeydown($event)"
                                        />
                                    </CustomFloatingLabel>
                                    <!-- <CustomFloatingLabel
                    v-if="assignedValue"
                    :config="{
                            label: 'Attribute Name1',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                          }"
                  >
                    <input
                      type="text"
                      autocomplete="off"
                      v-model="attributeAssignedName"
                      class="normal_input"
                    />
                  </CustomFloatingLabel> -->
                                    <small
                                        class="errManage"
                                        v-if="errattributeName"
                                        >Please enter Attribute name</small
                                    >
                                    <div
                                        class="input-group-prepend normal_inputButton"
                                    >
                                        <button
                                            class="graphic"
                                            v-bind:class="{
                                                white: !clicked,
                                                blue: clicked,
                                            }"
                                            @click="activeFreeText"
                                            placeholder="Free Text"
                                            value="Free Text"
                                            type="text"
                                            readonly
                                        >
                                            Free Text
                                        </button>

                                        <button
                                            class="graphic"
                                            v-bind:class="{
                                                whiteAssigned: !clicked2,
                                                blueAssigned: clicked2,
                                                white: !clicked,
                                            }"
                                            @click="activeAssignedvalue"
                                            type="button"
                                            value="assigned"
                                            readonly
                                        >
                                            Assigned Value
                                        </button>
                                    </div>
                                    <div
                                        v-if="assignedValue"
                                        class="assignedTabs row"
                                    >
                                        <div
                                            v-for="(assignedTextValue,
                                            index) in attribute.AttributeValues"
                                            :key="index"
                                            class="pad-t-17"
                                        >
                                            <!-- {{ assignedTextValue }} -->

                                            <b-form-input
                                                type="text"
                                                autocomplete="off"
                                                @input="emptyAttrVal = false"
                                                v-model="
                                                    assignedTextValue.AttributeValueText
                                                "
                                                class="normal_input input-content col-10"
                                                :class="{
                                                    initAssinged: index == 0,
                                                }"
                                            ></b-form-input>
                                            <a
                                                class="delete attribute col-2"
                                                v-if="index != 0"
                                                @click="deleteAttribute(index)"
                                            ></a>
                                            <span v-if="emptyAttrVal">
                                                <small
                                                    class="errManage"
                                                    v-if="
                                                        assignedTextValue.AttributeValueText ==
                                                            ''
                                                    "
                                                    >Please enter Attribute
                                                    value</small
                                                >
                                            </span>
                                        </div>
                                        <!-- <input
                      type="text"
                      class="headergroup"
                      @click="addAttribute()"
                      value="Add another value +"
                      autocomplete="off"
                    /> -->
                                        <button
                                            @click="addAttribute()"
                                            type="button"
                                            class="headergroup btn right btnEdit btn-secondary"
                                        >
                                            Add another value +
                                        </button>
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="attribute.isMultiSelect"
                                            name="checkbox-1"
                                        >
                                            Allow multiple values to be selected
                                        </b-form-checkbox>
                                    </div>
                                    <b-row
                                        class="main-button"
                                        v-if="viewButton"
                                    >
                                        <b-button
                                            class="cancelButton"
                                            @click="cancelAction"
                                        >
                                            Cancel
                                        </b-button>

                                        <b-button
                                            v-if="oldAttributeButton"
                                            class="applyButton assignedApply"
                                            @click="applyAttribute()"
                                        >
                                            Apply attributes
                                        </b-button>
                                    </b-row>
                                </div>
                            </div>

                            <div class="contentEdit" v-if="appliedAttribute">
                                <!-- <div
                              v-for="(newAttribute, index) in addAttributeArray"
                              :key="index"
                            > -->
                                <div>
                                    <!-- <CustomFloatingLabel
                                class="input__container input__container--content directoryAssign"
                                :config="{
                                  label: 'Directory',
                                  name: 'wrapper',
                                  line: false,
                                  scale: false,
                                }"
                                style="background-color: #DDDDDD !important;border:1px solid #DDDDDD !important;margin-left:115px;"
                              >
                             
                                <input
                                  type="text"
                                  autocomplete="off"
                                 
                                  class="normal_input"
                                  :disabled="filledBox"
                                /><p style="margin-top:-26px">{{testName}}</p>
                              </CustomFloatingLabel> -->
                                    <div class="assigned-data">
                                        <!-- <b-row class="first-row p-2" v-if="attributeName != null && attributeName !=''"> -->
                                        <b-row
                                            v-for="(preview,
                                            index) in managePreview"
                                            :key="index"
                                            class="first-row p-2"
                                        >
                                            <b-col class="p-0" lg="3" md="3">
                                                <h5>
                                                    {{ preview.AttributeName }}
                                                </h5>
                                            </b-col>
                                            <b-col lg="6" md="6">
                                                <ul>
                                                    <li
                                                        v-for="(val,
                                                        indexVal) in preview.AttributeValues"
                                                        :key="indexVal"
                                                    >
                                                        {{
                                                            val.AttributeValueText
                                                        }}
                                                    </li>
                                                </ul>
                                                <small
                                                    v-if="preview.isMultiSelect"
                                                    >Multiple values can be
                                                    selected</small
                                                >
                                            </b-col>
                                            <b-col class="p-0" lg="3" md="3">
                                                <img
                                                    @click="editFreeText(index)"
                                                    class="pointer"
                                                    src="../../assets/edit.svg"
                                                />
                                                <!-- <span class="e-icons e-search"></span> -->
                                                <img
                                                    @click="
                                                        deleteAttrPreview(index)
                                                    "
                                                    class="pointer"
                                                    src="../../assets/delete_icon-dark.svg"
                                                />
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                                <button
                                    type="text"
                                    class="headergroup assign-header"
                                    autocomplete="off"
                                    @click="addNewAttribute"
                                >
                                    Add another attribute +
                                </button>
                                <b-row class="main-button">
                                    <b-button
                                        v-if="editCancel"
                                        class="cancelButton"
                                        @click="hideModal"
                                    >
                                        Cancel
                                    </b-button>
                                    <b-button
                                        v-else
                                        class="cancelButton"
                                        @click="cancelEdit"
                                    >
                                        Cancel
                                    </b-button>
                                    <b-button
                                        class="applyButton"
                                        @click="saveAttribute"
                                        v-if="!editAttribute"
                                    >
                                        Save attribute
                                    </b-button>
                                    <b-button
                                        class="applyButton"
                                        @click="saveAttribute"
                                        v-if="editAttribute"
                                    >
                                        Update attribute
                                    </b-button>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </b-modal>
                <ToastMessages
                    :showSuccess="showSuccess"
                    :showFailure="showFailure"
                    :failureToastMessage="failureToastMessage"
                    :successToastMessage="successToastMessage"
                />
                <!-- <div id="sToast" class="successToastMsg" v-if="successToast">
          <img src="../../assets/success_tick.svg" class="mr-3" />
          Success <br />
          Documents uploaded
          <img
            src="../../assets/close_big.svg"
            class="ml-3"
            @click="closeSuccessToast"
          />
        </div>
        <div class="failureToastMsg" v-if="failureToast">
          <img src="../../assets/failure_cross.svg" class="mr-3" />
          Failure <br />
          Something went wrong.Please try again later.
          <img
            src="../../assets/close_big.svg"
            class="ml-3"
            @click="closeFailureToast"
          />
        </div> -->
            </span>
            <span class="manage">
                <b-modal
                    ref="view-modal"
                    class="model_style manage-model-block"
                    hide-header
                    hide-footer
                    centered
                    size="lg"
                >
                    <div class="manageModel">
                        <div class="text-right cursor-pointer">
                            <img
                                class="pointer"
                                src="../../assets/close_icon.svg"
                                @click="closeModal"
                            />
                        </div>
                        <h5
                            v-if="viewAttributes.length > 0"
                            class="mb-3 title text-center"
                        >
                            View attributes
                        </h5>
                        <div class="view-form">
                            <b-container fluid>
                                <span v-if="viewAttributes.length > 0">
                                    <span
                                        v-for="(view,
                                        viewIndex) in viewAttributes"
                                        :key="viewIndex"
                                    >
                                        <b-row class="my-2">
                                            <b-col sm="4">
                                                <label>{{
                                                    view.attribute
                                                }}</label>
                                            </b-col>
                                            <b-col sm="8">
                                                <span
                                                    v-for="(val,
                                                    indexVal) in view.attrVal"
                                                    :key="indexVal"
                                                >
                                                    <p class="read-only">
                                                        {{ val }}
                                                    </p>
                                                </span>
                                            </b-col>
                                        </b-row>
                                        <hr />
                                    </span>
                                </span>
                                <span v-else>
                                    <h4 class="noAttribute">
                                        No attributes available
                                    </h4>
                                </span>
                                <!-- 
                <div class="main-button">
                                <b-button class="cancelButton"> Cancel </b-button>

                                <b-button                     
                                  class="applyButton assignedApply"
                                >
                                  Save attributes
                                </b-button>
                              </div> -->
                            </b-container>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    ref="file-modal"
                    class="model_style manage-model-block"
                    id="file-attribute"
                    hide-header
                    hide-footer
                    centered
                    size="lg"
                    ><div class="manageModel">
                        <div class="text-right cursor-pointer">
                            <img
                                class="pointer"
                                src="../../assets/close_icon.svg"
                                @click="closeFileModal"
                            />
                        </div>
                        <h5
                            class="my-2 title text-center"
                            v-if="filtered1.length > 0"
                        >
                            File attributes
                        </h5>
                        <span v-if="filtered1.length > 0">
                            <div class="file-attr-w100">
                                <div id="Grid"></div>
                            </div>
                        </span>
                        <span v-else>
                            <h4 class="noAttribute">
                                No attributes available
                            </h4>
                        </span>
                    </div>
                </b-modal>
                <span id="editModal">
                    <b-modal
                        ref="edit-modal"
                        id="edit-Modal"
                        class="model_style modal-md manage-model-block"
                        hide-header
                        hide-footer
                        centered
                        size="lg"
                    >
                        <div class="manageModel">
                            <div class="text-right cursor-pointer">
                                <img
                                    class="pointer"
                                    src="../../assets/close_icon.svg"
                                    @click="closeEditModal"
                                />
                            </div>
                            <h5
                                class="my-2 title text-center"
                                v-if="editFileAttributes.length > 0"
                            >
                                Edit attributes
                            </h5>
                            <!-- <span v-if="editFileAttributes.length > 0"> -->
                            <div id="attrContent">
                                <div
                                    v-for="(editAttr,
                                    indexEdit) in editFileAttributes"
                                    :key="indexEdit"
                                    class="document-upload"
                                >
                                    <div
                                        v-if="
                                            editAttr.AttributeName ==
                                                'DocKeyword' ||
                                                editAttr.AttributeName ==
                                                    'DocDescription'
                                        "
                                    >
                                        <div
                                            v-if="
                                                editAttr.AttributeName ==
                                                    'DocKeyword'
                                            "
                                            class="attrBox mb-2"
                                        >
                                            <CustomFloatingLabel
                                                :config="{
                                                    label:
                                                        'Keywords (optional)',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <input
                                                    type="text"
                                                    autocomplete="off"
                                                    v-model="
                                                        editAttr.FreeTextVal
                                                    "
                                                    class="normal_input enterAddress"
                                                />
                                            </CustomFloatingLabel>
                                        </div>
                                        <div
                                            v-if="
                                                editAttr.AttributeName ==
                                                    'DocDescription'
                                            "
                                            class="attrBox mb-2"
                                        >
                                            <textarea
                                                id="enterComments"
                                                v-model="editAttr.FreeTextVal"
                                                placeholder="Description (optional)"
                                            ></textarea>
                                            <small class="docSmall"
                                                >Keywords help with searching.
                                                Separate keywords with
                                                comma.</small
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-for="(editAttr,
                                    indexEdit) in editFileAttributes"
                                    :key="indexEdit"
                                    class="document-upload"
                                >
                                    <div
                                        v-if="
                                            editAttr.AttributeName !=
                                                'DocKeyword' &&
                                                editAttr.AttributeName !=
                                                    'DocDescription'
                                        "
                                    >
                                        <div
                                            v-if="editAttr.IsFreeText"
                                            class="attrBox mb-2"
                                        >
                                            <h6>
                                                {{ editAttr.AttributeName }}
                                            </h6>
                                            <CustomFloatingLabel
                                                :config="{
                                                    label: 'Attribute value',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                v-bind:editUserParams="1"
                                                class="mb-0"
                                            >
                                                <input
                                                    type="text"
                                                    v-model="
                                                        editAttr.FreeTextVal
                                                    "
                                                    autocomplete="off"
                                                    class="normal_input"
                                                />
                                            </CustomFloatingLabel>
                                        </div>
                                        <div v-else class="attrBox mb-2">
                                            <h6>
                                                {{ editAttr.AttributeName }}
                                            </h6>
                                            <div
                                                v-if="editAttr.IsMultiSelect"
                                                class="multiBox"
                                            >
                                                <div
                                                    v-for="(permission,
                                                    permIndex) in editAttr.AttributeValueTransact"
                                                    :key="permIndex"
                                                >
                                                    <b-form-checkbox
                                                        v-model="
                                                            permission.AttributeValueTextSelection
                                                        "
                                                    >
                                                        {{
                                                            permission.AttributeValueText
                                                        }}
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <span
                                                    v-for="(answers,
                                                    pr) in editAttr.AttributeValueTransact"
                                                    :key="pr"
                                                >
                                                    <input
                                                        class="singleSelect"
                                                        type="radio"
                                                        :value="
                                                            answers.AttributeValueID
                                                        "
                                                        :checked="
                                                            answers.AttributeValueID ==
                                                                editAttr.selectRadio
                                                        "
                                                        @change="
                                                            getSelected(
                                                                answers.AttributeValueID,
                                                                indexEdit,
                                                                pr
                                                            )
                                                        "
                                                    />
                                                    <label
                                                        class="ml-2"
                                                        for="one"
                                                        >{{
                                                            answers.AttributeValueText
                                                        }}</label
                                                    >
                                                    <br />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <b-row class="float-right mt-4 col-6">
                                <b-button
                                    class="cancelButton"
                                    @click="closeEditModal"
                                >
                                    Cancel
                                </b-button>
                                <b-button
                                    class="applyButton"
                                    @click="saveEditDirectoryAttribute"
                                >
                                    Save attribute
                                </b-button>
                            </b-row>
                            <!-- </span>
                  <span v-else>
                      <h4 class="noAttribute">No Attributes available</h4>
                  </span> -->
                        </div>
                    </b-modal>
                </span>
            </span>
        </div>
    </b-container>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
// import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
// import { UploaderPlugin } from "@syncfusion/ej2-vue-inputs";
// import { AccordionPlugin } from '@syncfusion/ej2-vue-navigations';
// import { TreeView } from "@syncfusion/ej2-navigations";
// import { CheckBoxPlugin, classNames } from "@syncfusion/ej2-vue-buttons";
//import {config} from "../../../config";
// Vue.use(AccordionPlugin);
// Vue.use(CheckBoxPlugin);
// Vue.use(UploaderPlugin);
// Vue.use(ButtonPlugin);
// Vue.use(classNames);
import CustomFloatingLabel from '../CustomFloatingLabel';
import {
    FileManagerPlugin,
    NavigationPane,
    Toolbar,
    DetailsView,
} from '@syncfusion/ej2-vue-filemanager';
import PortalBar from '../PortalBar';
import { projectService } from '../../services/project.service';
import ToastMessages from '../ToastMessages.vue';
import AccessPermissionModal from '../AccessPermissionModal';
import { commonService } from '../../services/common.service';
import {
    GridPlugin,
    Page,
    Sort,
    Filter,
    Search,
    Freeze,
    Grid,
    CommandColumn,
    Resize,
} from '@syncfusion/ej2-vue-grids';
Vue.use(GridPlugin);
Vue.use(FileManagerPlugin);
export default {
    name: 'BrowseFiles',
    props: {
        getProject: Number,
        tabIndex: Number,
        markerSettings: Object,
    },
    data() {
        return {
            editFreeTextContent: false,
            testName: '',
            filledBox: true,
            clicked: false,
            clicked2: false,
            enterAttribute: [{ value: '' }],
            appliedAttribute: false,
            newDirectory: true,
            newAttribute: {
                directoryItem: '',
                attributeAssignedName: '',
                attributeName: '',
            },
            assignedValue: false,
            viewButton: false,
            oldAttributeButton: true,
            attributeCollection: [],
            addAttributeArray: [],
            editAssignedAttribute: false,
            directoryItems: null,
            // attributeName: "",
            attribute: {
                AttributeName: null,
                AttributeValues: [{ AttributeValueText: '' }],
                isMultiSelect: false,
            },
            firstTextValue: false,
            // successToast: false,
            // failureToast: false,
            hideSearch: true,
            attrVal: [],
            enterAttributeCount: 0,
            errattributeName: false,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            showPage: false,
            managePreview: [],
            fileManagerId: '',
            fileDetails: [],
            updateAttr: false,
            editAttribute: false,
            directoryID: '',
            updateIndex: null,
            viewAttributes: [],
            editCancel: false,
            emptyAttrVal: false,
            allowMultiSelection: false,
            filterDisplayFields: [],
            attributeTitle: null,
            selectedRow: 0,
            grid: '',
            filtered1: [],
            displayFields: [],
            filterOptions: { type: 'Excel' },
            frozeColumns: 2,
            options: { pageSizes: true },
            sortOptionsTbl: {
                columns: [],
            },
            editFileAttributes: [],
            filenameDirectory: null,
            currFileDetails: [],
            allowDragAndDrop: true,
            path: null,
            toolSettings: {
                items: [
                    'Refresh',
                    'View',
                    'Details',
                    'SortBy',
                    'NewFolder',
                    'Delete',
                    'Rename',
                ],
                visible: true,
            },
            permissions: 4,
            contextMenuSettings: {
                file: [
                    'Open',
                    '|',
                    'Details',
                    '|',
                    'Download',
                    '|',
                    'View attributes',
                    '|',
                    'File attributes',
                    '|',
                    'Rename',
                    '|',
                    'NewFolder',
                    '|',
                    'Delete',
                ],
                folder: [
                    'Open',
                    '|',
                    'Details',
                    '|',
                    'File attributes',
                    '|',
                    'Manage attributes',
                    '|',
                    'Rename',
                    '|',
                    'NewFolder',
                    '|',
                    'Delete',
                ],
                layout: ['Details', 'View', 'SelectAll', 'NewFolder'],
                visible: true,
            },
        };
    },
    components: {
        PortalBar,
        CustomFloatingLabel,
        ToastMessages,
        AccessPermissionModal,
    },
    provide: {
        filemanager: [NavigationPane, DetailsView, Toolbar, CommandColumn],
        grid: [Page, Sort, Filter, Resize, Grid],
    },
    created() {
        // this.pagePermit();
        this.fileManagerId = 'file-manager' + this.tabIndex;
        this.displayFile();
        this.directoryPermissions();
    },
    mounted: function() {
        this.pagePermit();
    },
    computed: {
        ProjectID() {
            return parseInt(
                this.$store.state.projects.selectedProjects[0].ProjectID
            );
        },
        ajaxSettings() {
            let output = {
                url:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureFileOperations',
                getImageUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureGetImage?projectID=' +
                    this.ProjectID,
                uploadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureUpload?projectID=' +
                    this.ProjectID,
                downloadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
            };
            return output;
        },
    },
    methods: {
        ...mapActions('projects', {
            selectProjects: 'selectProjects',
            getAllSelectedProjects: 'getSelectedProjects',
            clearSelectedProjects: 'clearSelectedProjects',
        }),
        created() {
            //refresh the layout
            setTimeout(() => {
                this.$refs.file_instance.refreshLayout();
            }, 1000);
            if (this.path === '/') {
                //root folder
                this.allowDragAndDrop = false;
            } else {
                //not root folder
                this.allowDragAndDrop = true;
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
            }
        },
        pagePermit() {
            this.showPage = false;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            let files = [];
            let folders = [];
            let layouts = [];
            data.forEach((element) => {
                if (
                    element.PageName.includes(
                        'Document Management - File Manager'
                    )
                ) {
                    vm.showPage = true;
                }
                if (
                    element.PageName.includes(
                        'Document Management - Manage Attributes'
                    )
                ) {
                    folders.push('Manage attributes');
                }
                if (
                    element.PageName.includes(
                        'Document Management - Edit attributes'
                    )
                ) {
                    // files.push("Edit attributes")
                }
                if (
                    element.PageName.includes(
                        'Document Management - View attributes'
                    )
                ) {
                    files.push('View attributes');
                }
                if (element.PageName.includes('Document Management - Open')) {
                    folders.push('Open');
                }
                if (element.PageName.includes('Document Management - Delete')) {
                    files.push('Delete');
                    folders.push('Delete');
                }
                if (
                    element.PageName.includes(
                        'Document Management - File attributes'
                    )
                ) {
                    folders.push('File attributes');
                    files.push('File attributes');
                }
                if (
                    element.PageName.includes('Document Management - NewFolder')
                ) {
                    files.push('NewFolder');
                    folders.push('NewFolder');
                    layouts.push('NewFolder');
                }
            });
            this.navigationPaneSettings = true;
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        directoryPermissions() {
            if (this.permissions === 7) {
                //full read/write/delete permissions
                this.contextMenuSettings = {
                    file: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'Download',
                        '|',
                        'View attributes',
                        '|',
                        'File attributes',
                        '|',
                        'Rename',
                        '|',
                        'NewFolder',
                        '|',
                        'Delete',
                    ],
                    folder: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'File attributes',
                        '|',
                        'Manage attributes',
                        '|',
                        'Rename',
                        '|',
                        'NewFolder',
                        '|',
                        'Delete',
                    ],
                    layout: ['Details', 'View', 'SelectAll', 'NewFolder'],
                    visible: true,
                };
            } else if (this.permissions === 6) {
                //read/write permissions
                this.contextMenuSettings = {
                    file: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'Download',
                        '|',
                        'View attributes',
                        '|',
                        'File attributes',
                        '|',
                        'Rename',
                        '|',
                        'NewFolder',
                    ],
                    folder: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'File attributes',
                        '|',
                        'Manage attributes',
                        '|',
                        'Rename',
                        '|',
                        'NewFolder',
                    ],
                    layout: ['Details', 'View', 'SelectAll', 'NewFolder'],
                    visible: true,
                };
            } else if (this.permissions === 4) {
                //read permissions only
                this.contextMenuSettings = {
                    file: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'Download',
                        '|',
                        'View attributes',
                        '|',
                        'File attributes',
                    ],
                    folder: ['Open', '|', 'Details', '|', 'File attributes'],
                    layout: ['Details', 'View', 'Refresh'],
                    visible: true,
                };
            } else if (this.permissions === 2) {
                //write permissions only
                this.contextMenuSettings = {
                    file: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'Download',
                        '|',
                        'View attributes',
                        '|',
                        'File attributes',
                        '|',
                        'Rename',
                        '|',
                        'NewFolder',
                    ],
                    folder: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'File attributes',
                        '|',
                        'Manage attributes',
                        '|',
                        'Rename',
                        '|',
                        'NewFolder',
                    ],
                    layout: ['Details', 'View', 'SelectAll', 'NewFolder'],
                    visible: true,
                };
            } else if (this.permissions === 1) {
                //delete permissions only
                this.contextMenuSettings = {
                    file: [
                        'Open',
                        '|',
                        'Details',
                        '|',
                        'Download',
                        '|',
                        'Delete',
                    ],
                    folder: ['Open', '|', 'Details', '|', 'Delete'],
                    layout: ['Details', 'View', 'SelectAll'],
                    visible: true,
                };
            }
        },
        success(args) {
            if (Array.isArray(args.result.files)) {
                this.permissions = args.result.files[0].permissions;
            }
            this.permissions = 7;
            this.directoryPermissions();
            if (args.action == 'read') {
                var container = document.getElementsByClassName('e-address')[0];
                var element = document.getElementsByClassName(
                    'e-custom-search'
                )[0];
                if (element === undefined) {
                    var span = document.createElement('span');
                    var input = document.createElement('input');
                    input.type = 'text';
                    input.id = 'input';
                    span.setAttribute('class', 'e-custom-search');
                    span.appendChild(input);
                    var btn = document.createElement('button');
                    btn.innerHTML = 'Search';
                    span.appendChild(btn);
                    container.appendChild(span);
                    btn.onclick = function() {
                        // Get the FileManager Instances.
                        //
                        this.fileManagerId = 'file-manager' + this.tabIndex;
                        var filemanagerInstance = document.getElementById(
                            this.fileManagerId
                        ).ej2_instances[0];
                        var input =
                            '*' + document.getElementById('input').value + '*';
                        // Maintain the search value
                        // var objectValue = { searchString: input };
                        var objectValue = {
                            action: 'filter',
                            path: '/',
                            searchString: input,
                            showHiddenItems: false,
                            caseSensitive: false,
                            data: [],
                            directoryID: 0,
                            parentID: 0,
                        };
                        // Send the search value to the controller side.
                        filemanagerInstance.filterFiles(objectValue);
                    };
                }
            }
        },
        displayFile() {
            setTimeout(function() {
                document
                    .getElementById(this.fileManagerId)
                    .ej2_instances[0].refreshLayout();
            }, 3500);
        },

        menuOpen: function(args) {
            for (var i = 0; i < args.items.length; i++) {
                if (args.items[i].text === 'Manage attributes') {
                    args.items[i].iconCss = 'e-icons e-settings';
                }
                if (args.items[i].text === 'File attributes') {
                    args.items[i].iconCss = 'e-icons e-file';
                }
                if (args.items[i].text === 'View attributes') {
                    args.items[i].iconCss = 'e-icons e-display-for-review';
                }
            }
            if (args.target.offsetParent.classList.contains('e-treeview')) {
                var index = args.items.findIndex(
                    (p) => p.text == 'Manage attributes'
                );
                args.items.splice(index, 1);
                var index1 = args.items.findIndex(
                    (p) => p.text == 'File attributes'
                );
                args.items.splice(index1, 1);
                var index2 = args.items.findIndex((p) => p.text == 'Delete');
                args.items.splice(index2, 1);
            }
        },
        // Displaying alert for custom menu in menuClick event
        menuClick: function(args) {
            if (args.fileDetails.length) {
                this.testName = args.fileDetails[0].name;
            }
            if (
                args.item.text === 'Manage attributes' ||
                args.item.text === 'Edit attributes'
            ) {
                this.errattributeName = false;
                this.fileDetails = args.fileDetails[0];
                this.directoryID = args.fileDetails[0].directoryID;
                if (this.directoryID) {
                    projectService
                        .getDirectoryManageAttribute(this.directoryID)
                        .then((res) => {
                            if (res.data.length) {
                                this.editAttribute = true;
                                this.editCancel = true;
                                for (let i = 0; i < res.data.length; i++) {
                                    let attributeValues = [];
                                    for (
                                        let j = 0;
                                        j < res.data[i].AttributeValue.length;
                                        j++
                                    ) {
                                        attributeValues.push({
                                            AttributeValueText:
                                                res.data[i].AttributeValue[j]
                                                    .AttributeValueText,
                                            AttributeValueID:
                                                res.data[i].AttributeValue[j]
                                                    .AttributeValueID,
                                        });
                                    }
                                    this.managePreview.push({
                                        AttributeName:
                                            res.data[i].AttributeName,
                                        AttributeValues: attributeValues,
                                        isMultiSelect:
                                            res.data[i].IsMultiSelect,
                                        isFreeText: res.data[i].IsFreeText,
                                        DirectoryAttributeID:
                                            res.data[i].DirectoryAttributeID,
                                        projectId: this.fileDetails.projectId,
                                        DirectoryID: res.data[i].DirectoryID,
                                    });
                                }
                                this.appliedAttribute = true;
                                this.newDirectory = false;
                            } else {
                                this.appliedAttribute = false;
                                this.newDirectory = true;
                                this.editAttribute = false;
                            }
                        });
                }
                if (args.item.text === 'Manage attributes') {
                    this.attributeTitle = 'Manage attributes';
                } else if (args.item.text === 'Edit attributes') {
                    this.attributeTitle = 'Edit attributes';
                }

                this.$refs['attribute-modal'].show();

                this.attributeAssignedName = null;
                this.attributeName = null;
                this.assignedValue = false;
                this.firstTextValue = true;
                this.clicked = false;
                this.clicked2 = false;
                this.viewButton = false;
                // this.this.attrVal = [];
                this.enterAttribute = [{ value: '' }];
                this.attribute = {
                    AttributeName: null,
                    AttributeValues: [{ AttributeValueText: '' }],
                    isMultiSelect: false,
                };
                this.managePreview = [];
            } else if (args.item.text === 'View attributes') {
                this.fileDetails = args.fileDetails[0];
                var filename = this.fileDetails.name;
                projectService
                    .GetDocumentMetadataDetails(this.directoryID, filename)
                    .then((res) => {
                        this.viewAttributes = [];
                        for (var key in res.data) {
                            var splitView = res.data[key].split('|');
                            const newArr = splitView.filter((a) => a);
                            this.viewAttributes.push({
                                attribute: key,
                                attrVal: newArr,
                            });
                        }
                    });
                this.$refs['view-modal'].show();
            } else if (args.item.text === 'File attributes') {
                if (args.fileDetails[0].isFile) {
                    this.fileDetails = args.fileDetails[0];

                    this.filenameDirectory = this.fileDetails.name;
                    this.pathDirectoryEdit =
                        this.fileDetails.projectId +
                        this.fileDetails.filterPath;
                    this.DirectoryIdDirectoryEdit = this.directoryID;
                    this.getFileAttributes();
                    this.$refs['edit-modal'].show();
                } else {
                    args.fileDetails[0].path =
                        args.fileDetails[0].filterPath +
                        args.fileDetails[0].name +
                        '/';
                    args.fileDetails[0].data = [
                        {
                            projectId: args.fileDetails[0].projectId,
                            directoryID: args.fileDetails[0].directoryID,
                            path: args.fileDetails[0].path,
                            action: args.fileDetails[0].action,
                            newName: args.fileDetails[0].newName,
                            names: args.fileDetails[0].names,
                            targetPath: args.fileDetails[0].targetPath,
                            name: args.fileDetails[0].name,
                            size: args.fileDetails[0].size,
                            previousName: args.fileDetails[0].previousName,
                            targetData: args.fileDetails[0].targetData,
                            dateModified: args.fileDetails[0].dateModified,
                            dateCreated: args.fileDetails[0].dateCreated,
                            hasChild: args.fileDetails[0].hasChild,
                            isFile: args.fileDetails[0].isFile,
                            type: args.fileDetails[0].type,
                            id: args.fileDetails[0].id,
                            filterPath: args.fileDetails[0].filterPath,
                            filterId: args.fileDetails[0].filterId,
                            uploadFiles: args.fileDetails[0].uploadFiles,
                            caseSensitive: args.fileDetails[0].caseSensitive,
                            searchString: args.fileDetails[0].searchString,
                            showHiddenItems:
                                args.fileDetails[0].showHiddenItems,
                            iconClass: args.fileDetails[0].iconClass,
                            nodeId: args.fileDetails[0].nodeId,
                            parentID: args.fileDetails[0].parentID,
                            selected: args.fileDetails[0].selected,
                            icon: args.fileDetails[0].icon,
                            data: [{}],
                            renameFiles: args.fileDetails[0].renameFiles,
                        },
                    ];
                    this.currFileDetails = args.fileDetails[0];
                    this.getFileDetails();
                    this.$refs['file-modal'].show();
                }
            } else if (args.item.text === 'Open') {
                this.directoryID = args.fileDetails[0].directoryID;
            } else if (args.item.text === 'Rename') {
                this.DirectoryID = args.fileDetails[0].directoryID;
            }
        },
        nameKeydown(e) {
            let char = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z0-9_]+$/.test(char)) return true;
            else e.preventDefault();
        },
        getFileDetails() {
            projectService
                .GetDirectoryMetadataDetails(this.currFileDetails)
                .then((res) => {
                    this.filtered1 = res.data;
                    this.displayFields = [];
                    const data1 = this.$store.state.store.posts
                        .AccessPagesInfoLst;
                    // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
                    let displayEdit = true;
                    data1.forEach((element) => {
                        if (
                            element.PageName.includes(
                                'Document Management - Edit attributes'
                            )
                        ) {
                            this.displayFields.push(
                                {
                                    headerText: 'Edit',
                                    width: '70px',
                                    commands: [
                                        {
                                            buttonOption: {
                                                id: 'edit1',
                                                iconCss: ' e-icons e-edit',
                                                cssClass: 'e-flat',
                                            },
                                        },
                                    ],
                                },
                                {
                                    field: 'FileName',
                                    headerText: 'File name',
                                    type: '',
                                }
                            );
                            displayEdit = false;
                            this.frozeColumns = 1;
                        }
                    });
                    if (displayEdit) {
                        this.displayFields.push({
                            field: 'FileName',
                            headerText: 'File name',
                            type: '',
                        });
                    }
                    for (var i = 0; i < res.data.length; i++) {
                        if (Object.entries(res.data[i].Attributes).length > 0) {
                            for (const [key, value] of Object.entries(
                                res.data[i].Attributes
                            )) {
                                var columnExists = this.displayFields.some(
                                    function(field) {
                                        return field.field === key;
                                    }
                                );
                                if (columnExists != true) {
                                    if (key == 'DocKeyword') {
                                        this.displayFields.push({
                                            field: key,
                                            headerText: 'Keyword',
                                            type: value,
                                        });
                                    } else if (key == 'DocDescription') {
                                        this.displayFields.push({
                                            field: key,
                                            headerText: 'Description',
                                            type: value,
                                        });
                                    }
                                }
                            }
                        }
                    }

                    for (var k = 0; k < res.data.length; k++) {
                        if (Object.entries(res.data[k].Attributes).length > 0) {
                            for (const [key, value] of Object.entries(
                                res.data[k].Attributes
                            )) {
                                var columnExists1 = this.displayFields.some(
                                    function(field) {
                                        return field.field === key;
                                    }
                                );
                                if (columnExists1 != true) {
                                    if (
                                        key != 'DocKeyword' ||
                                        key != 'DocDescription'
                                    ) {
                                        this.displayFields.push({
                                            field: key,
                                            headerText: key,
                                            type: value,
                                        });
                                    }
                                }
                            }
                        }
                    }

                    let vm = this;
                    for (let s = 0; s < vm.filtered1.length; s++) {
                        for (var t = 0; t < vm.displayFields.length; t++) {
                            if (
                                !(
                                    vm.displayFields[t].field in
                                    vm.filtered1[s].Attributes
                                )
                            ) {
                                vm.filtered1[s].Attributes[
                                    vm.displayFields[t].field
                                ] = '';
                            } else {
                                if (
                                    vm.filtered1[s].Attributes[
                                        vm.displayFields[t].field
                                    ].indexOf('|') > -1
                                ) {
                                    var splitVal = vm.filtered1[s].Attributes[
                                        vm.displayFields[t].field
                                    ].split('|');
                                    const newArr = splitVal.filter((a) => a);
                                    if (splitVal.length > 1) {
                                        vm.filtered1[s].Attributes[
                                            vm.displayFields[t].field
                                        ] = newArr.join(', ');
                                    }
                                }
                            }
                        }
                    }

                    this.filterDisplayFields = vm.filtered1.map((item) => {
                        return item.Attributes;
                    });
                    for (let d = 0; d < this.filterDisplayFields.length; d++) {
                        this.filterDisplayFields[d]['Edit'] = 'Edit';
                        this.filterDisplayFields[d]['FileName'] =
                            vm.filtered1[d].FileName;
                        this.filterDisplayFields[d]['Path'] =
                            vm.filtered1[d].Path;
                        this.filterDisplayFields[d]['DirectoryId'] =
                            vm.filtered1[d].DirectoryId;
                    }
                    setTimeout(
                        function() {
                            this.createDataGrid();
                        }.bind(this),
                        50
                    );
                });
        },
        createDataGrid: function() {
            if (!document.getElementById('Grid').classList.contains('e-grid')) {
                Grid.Inject(
                    Page,
                    Sort,
                    Freeze,
                    Search,
                    Toolbar,
                    CommandColumn,
                    Grid,
                    Resize
                );
                for (var i = 0; i < this.displayFields.length; i++) {
                    if (this.displayFields[i].headerText != 'Edit') {
                        this.sortOptionsTbl.columns.push({
                            field: this.displayFields[i].field,
                            direction: 'Ascending',
                        });
                    }
                }
                this.grid = new Grid({
                    selectionSettings: { persistSelection: true },
                    filterSettings: this.filterOptions,
                    class: 'sortingenabled filterGrid',
                    allowPaging: true,
                    allowSorting: true,
                    allowResizing: true,
                    dataBound: this.dataBound,
                    frozenColumns: this.frozeColumns,
                    actionComplete: this.actionComplete,
                    pageSettings: this.options,
                    sortSettings: this.sortOptionsTbl,
                    load: this.onLoad,
                    commandClick: this.commandClick,
                });
                this.grid.appendTo('#Grid');
            }
            this.grid.dataSource = this.filterDisplayFields;
            setTimeout(() => {
                this.grid.columns = this.displayFields;
                // this.grid.refreshColumns();
            }, 100);
        },
        getFileAttributes() {
            projectService
                .GetDocumentEditMetadataDetails(
                    this.filenameDirectory,
                    this.pathDirectoryEdit,
                    this.DirectoryIdDirectoryEdit
                )
                .then((res) => {
                    this.editFileAttributes = res.data;
                    var emptyAttr = ['DocKeyword', 'DocDescription'];
                    if (this.editFileAttributes.length > 0) {
                        for (let temp in emptyAttr) {
                            if (
                                !this.editFileAttributes.some(
                                    (person) =>
                                        person.AttributeName === emptyAttr[temp]
                                )
                            ) {
                                this.editFileAttributes.push({
                                    AttributeName: emptyAttr[temp],
                                    AttributeValueTransact: [],
                                    DirectoryAttributeID: 0,
                                    DirectoryID: this.DirectoryIdDirectoryEdit,
                                    FreeTextVal: '',
                                    IsFreeText: true,
                                    IsMultiSelect: false,
                                });
                            }
                        }

                        for (let v in this.editFileAttributes) {
                            if (
                                !this.editFileAttributes[v].IsFreeText &&
                                !this.editFileAttributes[v].IsMultiSelect
                            ) {
                                let getIndex = this.editFileAttributes[
                                    v
                                ].AttributeValueTransact.findIndex(
                                    (item) =>
                                        item.AttributeValueTextSelection == true
                                );
                                this.editFileAttributes[v][
                                    'selectRadio'
                                ] = this.editFileAttributes[
                                    v
                                ].AttributeValueTransact[
                                    getIndex
                                ].AttributeValueID;
                            } else {
                                this.editFileAttributes[v][
                                    'selectRadio'
                                ] = null;
                            }
                        }
                    } else {
                        for (let temp in emptyAttr) {
                            this.editFileAttributes.push({
                                AttributeName: emptyAttr[temp],
                                AttributeValueTransact: [],
                                DirectoryAttributeID: 0,
                                DirectoryID: this.DirectoryIdDirectoryEdit,
                                FreeTextVal: '',
                                IsFreeText: true,
                                IsMultiSelect: false,
                            });
                        }
                    }
                });
        },
        commandClick: function(args) {
            this.filenameDirectory = args.rowData.FileName;
            this.pathDirectoryEdit = args.rowData.Path;
            this.DirectoryIdDirectoryEdit = args.rowData.DirectoryId;
            this.getFileAttributes();
            this.$refs['edit-modal'].show();
        },
        onLoad: function() {
            this.grid.columns = this.displayFields;
        },
        saveEditDirectoryAttribute() {
            for (let temp in this.editFileAttributes) {
                this.editFileAttributes[temp].ProjectId = localStorage.getItem(
                    'browseProjectID'
                );
            }
            var projectid = localStorage.getItem('browseProjectID');
            this.editFileAttributes.forEach((a) => delete a.selectRadio);
            projectService
                .UpdateDocumentMetadataDetails(
                    this.filenameDirectory,
                    projectid,
                    this.editFileAttributes
                )
                .then((res) => {
                    if (res.status == 200) {
                        this.showSuccess = true;
                        this.successToastMessage = res.data;
                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 5000);
                        this.getFileDetails();
                        this.$refs['edit-modal'].hide();
                    }
                });
        },
        getSelected(value1, indexParent, index) {
            this.editFileAttributes[indexParent].selectRadio = value1;
            for (let temp in this.editFileAttributes[indexParent]
                .AttributeValueTransact) {
                if (temp == index) {
                    this.editFileAttributes[indexParent].AttributeValueTransact[
                        temp
                    ].AttributeValueTextSelection = true;
                } else {
                    this.editFileAttributes[indexParent].AttributeValueTransact[
                        temp
                    ].AttributeValueTextSelection = false;
                }
            }
            this.$forceUpdate();
        },
        pageCenter: function(args) {},
        dataBound: function(args) {},
        actionComplete: function(args) {},
        onCreate() {
            this.$refs.file_instance.refreshLayout();
        },
        // toolbarClick: function (args) {
        //   if (args.item.text === "Download") {
        //     this.directoryID = args.fileDetails[0].directoryID;
        //   }
        // },
        beforeDownload: function(args) {
            var data = args.data.data[0];
            var path = data.filterPath + data.name + '/';
            let names = args.data.names;
            data.data = [
                {
                    projectId: data.projectId,
                    directoryID: data.directoryID,
                    path: path,
                    action: data.action,
                    newName: data.newName,
                    names: args.data.names,
                    targetPath: data.targetPath,
                    name: data.name,
                    size: data.size,
                    previousName: data.previousName,
                    targetData: data.targetData,
                    dateModified: data.dateModified,
                    dateCreated: data.dateCreated,
                    hasChild: data.hasChild,
                    isFile: data.isFile,
                    type: data.type,
                    id: data.id,
                    filterPath: data.filterPath,
                    filterId: data.filterId,
                    uploadFiles: data.uploadFiles,
                    caseSensitive: data.caseSensitive,
                    searchString: data.searchString,
                    showHiddenItems: data.showHiddenItems,
                    iconClass: data.iconClass,
                    nodeId: data.nodeId,
                    parentID: data.parentID,
                    selected: data.selected,
                    icon: data.icon,
                    data: [{}],
                    renameFiles: data.renameFiles,
                },
            ];
            args.data = data;

            args.cancel = true;
            var arr = [];
            arr.push(this.data);

            /*var obj = {
        action: "download",
        path: path,
        names: names,
        data: data,
      }; */
            var xhr = new XMLHttpRequest();
            xhr.open(
                'POST',
                process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
                true
            );
            xhr.responseType = 'blob';
            xhr.onload = function() {
                if (this.status === 200) {
                    var name = '';
                    // Getting file name from content-disposition header
                    let header = xhr.getResponseHeader('Content-Disposition');
                    if (header && header.indexOf('attachment') !== -1) {
                        var regex = /name[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = regex.exec(header);
                        if (matches != null && matches[1])
                            name = matches[1].replace(/['"]/g, '');
                    }
                    //saving the file locally using anchor tag

                    var blob = new Blob([this.response], {
                        type: xhr.getResponseHeader('Content-Type'),
                    });
                    var anchorUrl = window.URL.createObjectURL(blob);
                    if (name) {
                        if (!name.toLowerCase().endsWith('.url')) {
                            var anchor = document.createElement('a');
                            anchor.href = anchorUrl;
                            anchor.download = name;
                            anchor.click();
                        }
                    } else {
                        window.location = anchorUrl;
                    }
                    setTimeout(async function() {
                        switch (args.data.type.toLowerCase()) {
                            case '.url':
                                fetch(anchorUrl)
                                    .then((response) => response.text())
                                    .then((data) => {
                                        data.split('\n').forEach((element) => {
                                            if (element.startsWith('URL=')) {
                                                window.open(
                                                    element.substring(4),
                                                    '_blank'
                                                );
                                            }
                                        });
                                    });
                                break;
                            case '.pdf':
                            case '.txt':
                                var type =
                                    args.data.type === '.pdf'
                                        ? 'application/pdf'
                                        : 'text/plain';
                                var blobFIle = await fetch(anchorUrl)
                                    .then((r) => r.blob())
                                    .then(
                                        (blobFile) =>
                                            new File(
                                                [blobFile],
                                                'fileNameGoesHere',
                                                {
                                                    type: type,
                                                }
                                            )
                                    );
                                var ad = URL.createObjectURL(blobFIle);
                                window.open(ad, '_blank');
                                break;
                            default:
                                URL.revokeObjectURL(anchorUrl);
                        }
                    }, 100);
                }
            };
            var fdata = new FormData();
            fdata.append('downloadInput', JSON.stringify(data));
            //Custom Header Added to XHR
            let token = commonService.getCookie('accessToken');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.send(fdata);
        },
        fileOpen: function(args) {
            this.directoryID = args.fileDetails.directoryID;
            if (args.module == 'LargeIconsView' && !args.fileDetails.isFile) {
                this.directoryID = args.fileDetails.directoryID;
            } else if (args.fileDetails.isFile) {
                this.$refs.file_instance.downloadFiles();
            }
        },
        beforeImageLoad: function(args) {
            let token = commonService.getCookie('accessToken');
            args.imageUrl = args.imageUrl + '&access_token=' + token;
        },
        beforeSend: function(args) {
            //set the upload path
            let data = JSON.parse(args.ajaxSettings.data);
            if (Array.isArray(data)) {
                this.path = data[0].path;
            } else {
                this.path = data.path;
            }
            if (this.path === '/') {
                //do not allow upload button to appear at root folder
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                    ],
                    visible: true,
                };
                //do not allow drag and drop of existing files/folders at root
                this.allowDragAndDrop = false;
                //do not allow drag and drop upload of new files/folders at root
                if (args.action == 'Upload') {
                    args.cancel = true;
                    this.failureToastMessage =
                        'Please select a directory to upload';
                    this.showFailure = true;
                    this.showSuccess = false;
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                }
                setTimeout(() => {
                    //if no path is specified, change syncfusion text to match
                    let emptyMainDiv = document.querySelector(
                        '.e-empty-content'
                    );
                    let emptySubDiv = document.querySelector(
                        '.e-empty-inner-content'
                    );
                    emptyMainDiv.innerHTML = 'Please create a folder';
                    emptySubDiv.innerHTML = '';
                }, 800);
            } else if (
                this.permissions === 2 ||
                this.permissions === 4 ||
                this.permissions === 6
            ) {
                //permissions do not allow for file deletion
                //folder selected, access to upload and drag and drop
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
                this.allowDragAndDrop = true;
            } else {
                //folder selected, access to upload and drag and drop
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
                this.allowDragAndDrop = true;
            }

            let token = commonService.getCookie('accessToken');
            data['ProjectId'] = this.ProjectID;
            data['DirectoryID'] = this.DirectoryID;

            args.ajaxSettings.beforeSend = function(args) {
                //Setting authorization header
                args.httpRequest.setRequestHeader(
                    'Authorization',
                    'Bearer ' + token
                );
            };
            var fileData = JSON.parse(args.ajaxSettings.data);

            if (data.action != undefined) {
                //insert read actions in here
                if (fileData.length > 0) {
                    data.data[0].data = [
                        {
                            filterPath: fileData.data[0].filterPath,
                            path: fileData.data[0].filterPath,
                            directoryID: fileData.data[0].directoryID,
                        },
                    ];
                    if (fileData.data[0].filterPath != '') {
                        if (fileData.data[0].directoryID == 0) {
                            data['parentID'] = parseInt(
                                fileData.data[0].parentID
                            );
                        } else {
                            data['parentID'] = this.directoryID;
                        }
                    }
                }
            } else {
                var path = data[0].path;

                data['DirectoryID'] = this.directoryID;
                data['path'] = path;
                var uploadData = JSON.parse(data[2].data);
                uploadData.projectId = this.ProjectID;
                uploadData.directoryID = this.directoryID;
                uploadData.path = path;

                data[2] = [
                    {
                        projectId: this.ProjectID,
                        directoryID: this.directoryID,
                        path: path,
                        action: data[1].action,
                        newName: data.newName,
                        // names: args.data.names,
                        targetPath: data.targetPath,
                        name: data.name,
                        size: data.size,
                        previousName: data.previousName,
                        targetData: data.targetData,
                        dateModified: data.dateModified,
                        dateCreated: data.dateCreated,
                        hasChild: data.hasChild,
                        isFile: data.isFile,
                        type: data.type,
                        id: data.id,
                        filterPath: data.filterPath,
                        filterId: data.filterId,
                        uploadFiles: data.uploadFiles,
                        caseSensitive: data.caseSensitive,
                        searchString: data.searchString,
                        showHiddenItems: data.showHiddenItems,
                        iconClass: data.iconClass,
                        nodeId: data.nodeId,
                        parentID: data.parentID,
                        selected: data.selected,
                        icon: data.icon,
                        data: [{}],
                        renameFiles: data.renameFiles,
                    },
                ];
                // data[2] = uploadData;
                //
            }
            if (fileData.action != 'filter') {
                this.dataToSearch = data;
            } else {
                data.data = this.dataToSearch.data;
                if (this.dataToSearch.parentID) {
                    data.parentID = this.dataToSearch.parentID;
                }
                if (this.dataToSearch.directoryID) {
                    data.directoryID = this.dataToSearch.directoryID;
                }
                data.path = /*localStorage.getItem('browseProjectID')+*/ this.dataToSearch.path;
                // data.data[0].filterPath = this.dataToSearch.path;
                // data.data[0].type = "File Folder";
            }
            // Add custom parameter in ajax settings
            args.ajaxSettings.data = JSON.stringify(data);
            if (fileData.action == 'filter') {
                projectService.AzureFileAttributeSearch(data).then((res) => {});
            }
        },
        hideModal() {
            this.editCancel = false;
            this.emptyAttrVal = false;
            this.$refs['attribute-modal'].hide();
        },
        closeModal() {
            this.errattributeName = false;
            this.$refs['view-modal'].hide();
        },
        closeFileModal() {
            this.$refs['file-modal'].hide();
        },
        closeEditModal() {
            this.$refs['edit-modal'].hide();
        },
        applyAttribute(args) {
            if (
                this.attribute.AttributeName != '' &&
                this.attribute.AttributeName != null
            ) {
                //  this.testName = args.fileDetails[0].name;
                // if(!this.isFreeText && )
                if (!this.isFreeText && this.attribute.AttributeValues != []) {
                    for (
                        let y = 0;
                        y < this.attribute.AttributeValues.length;
                        y++
                    ) {
                        if (
                            this.attribute.AttributeValues[y]
                                .AttributeValueText == ''
                        ) {
                            this.emptyAttrVal = true;
                        }
                    }
                }
                if (!this.emptyAttrVal) {
                    this.attribute[
                        'DirectoryID'
                    ] = this.fileDetails.directoryID;
                    this.attribute['projectId'] = this.fileDetails.projectId;
                    this.attribute['isFreeText'] = this.isFreeText;
                    if (this.isFreeText) {
                        this.attribute.AttributeValues = [];
                        this.attribute.isMultiSelect = false;
                    }
                    if (this.updateAttr) {
                        this.managePreview[this.updateIndex] = this.attribute;
                    } else {
                        this.managePreview.push(this.attribute);
                    }

                    this.updateAttr = false;

                    // if(this.attribute.AttributeName == null || this.attribute.AttributeName == ""){
                    //   this.errattributeName = true;
                    // }else{
                    this.newName = args;

                    // this.testName = ""

                    // this.attributeCollection.push(this.attributeName);
                    // this.addAttributeArray.push(this.addNewAttribute);

                    this.appliedAttribute = true;
                    this.newDirectory = false;
                    this.enterAttributeCount = this.enterAttribute.length;
                }
            } else {
                this.errattributeName = true;
            }
        },
        editFreeText(index) {
            // this.editFreeTextContent = false;
            this.editCancel = false;
            this.appliedAttribute = false;
            this.newDirectory = true;
            this.attribute = this.managePreview[index];
            this.updateAttr = true;
            this.updateIndex = index;
            if (this.attribute.isFreeText) {
                this.activeFreeText();
            } else {
                this.activeAssignedvalue();
            }
        },
        deleteAttrPreview(index) {
            this.managePreview.splice(index, 1);
        },
        showChanges() {
            this.editAssignedAttribute = false;
            this.appliedAttribute = true;
            this.editFreeTextContent = false;
        },
        saveAttribute1() {
            this.attrVal['AttributeValueText'] = [];
            for (var i = 0; i < this.enterAttribute.length; i++) {
                // this.attrVal.push({AttributeValueText:this.enterAttribute[i].value});
                this.attrVal.AttributeValueText.push(
                    this.enterAttribute[i].value
                );
            }
        },
        saveAttribute() {
            // this.attrVal = this.enterAttribute.map((item)=>{
            //   return item.value
            // });
            // this.attrVal["AttributeValueText"]=[];
            // for(var i=0;i<this.enterAttribute.length;i++){
            //   this.attrVal.push({AttributeValueText:this.enterAttribute[i].value});
            //   // this.attrVal.AttributeValueText.push(this.enterAttribute[i].value);
            // }
            // this.enterAttribute
            //   const data = {
            //       AttributeName: this.firstTextValue
            //       ? this.attributeName
            //       : this.attributeAssignedName,
            //       DirectoryID: 1,

            //       isFreeText: this.firstTextValue ? true : false,
            //       isMultiSelect: this.assignedValue ? true : false,
            //       // AttributeValues:JSON.stringify(this.attrVal),
            //       AttributeValues:this.attrVal,
            //       // AttributeValues: [
            //       //   {
            //       //       AttributeValueText: "test123",
            //       //   },
            //       // ],
            //       // AttributeValue: this.attrVal,
            //   };
            for (let r = 0; r < this.managePreview.length; r++) {
                if (this.managePreview[r].isFreeText) {
                    this.managePreview[r].AttributeValues = [];
                }
            }
            // this.firstTextValue;

            if (!this.editAttribute) {
                projectService
                    .createDirectory(this.managePreview)
                    .then((res) => {
                        if (res.data.StatusCode === 201) {
                            this.successToastMessage =
                                'Attributes added successfully';
                            this.showFailure = false;
                            this.showSuccess = true;
                            setTimeout(() => {
                                this.showSuccess = false;
                            }, 5000);
                        }
                        if (res.data.StatusCode === 500) {
                            this.successToastMessage =
                                'Attributes already found';
                            this.showFailure = false;
                            this.showSuccess = true;
                            setTimeout(() => {
                                this.showSuccess = false;
                            }, 5000);
                        }
                    })

                    .catch((err) => {
                        if (err) {
                            this.failureToastMessage =
                                'Something went wrong. Please try again later';
                            this.showFailure = true;
                            this.showSuccess = false;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        }
                    });
            } else {
                projectService
                    .updateDirectory(this.directoryID, this.managePreview)
                    .then((res) => {
                        if (res.data.StatusCode === 202) {
                            this.successToastMessage =
                                'Attributes updated successfully';
                            this.showFailure = false;
                            this.showSuccess = true;
                            setTimeout(() => {
                                this.showSuccess = false;
                            }, 5000);
                        }
                    })

                    .catch((err) => {
                        if (err) {
                            this.failureToastMessage =
                                'Something went wrong. Please try again later';
                            this.showFailure = true;
                            this.showSuccess = false;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        }
                    });
            }
            this.$refs['attribute-modal'].hide();
        },
        cancelEdit() {
            // this.appliedAttribute = true;
            this.editFreeTextContent = false;
            this.appliedAttribute = false;
            this.newDirectory = true;
            this.editAssignedAttribute = false;
            this.attributeName = '';
            this.assignedValue = false;
            this.enterAttribute = [{ value: '' }];
            this.firstTextValue = true;
            this.activeFreeText();
        },
        // manage Attribute
        addNewAttribute() {
            this.editCancel = false;
            this.attribute = {
                AttributeName: null,
                AttributeValues: [{ AttributeValueText: '' }],
                isMultiSelect: false,
            };
            this.viewButton = true;
            this.oldAttributeButton = true;
            this.newDirectory = true;
            // this.oldAttributeButton = false;
            // this.addAtrributeButton = true;
            this.directoryItems = null;
            this.attributeAssignedName = '';
            // this.attributeName = "";
            this.appliedAttribute = false;
            this.activeFreeText();
        },
        activeFreeText() {
            this.clicked = true;
            this.clicked2 = false;
            this.assignedValue = false;
            this.firstTextValue = true;
            this.viewButton = true;
            this.isFreeText = true;
        },
        activeAssignedvalue() {
            this.clicked = false;
            this.clicked2 = true;
            this.assignedValue = true;
            // this.firstTextValue = false;
            // this.assignedValue = false;
            this.firstTextValue = true;
            this.viewButton = true;
            this.isFreeText = false;
        },
        deleteAttribute(index) {
            // this.enterAttribute.splice(index,1);
            this.attribute.AttributeValues.splice(index, 1);
            this.emptyAttrVal = false;
        },
        addAttribute() {
            // this.enterAttribute.push({ value: "" });
            this.attribute.AttributeValues.push({ AttributeValueText: '' });
        },
        editAssignAttribute() {
            this.editAssignedAttribute = true;
            this.appliedAttribute = false;
            this.clicked = false;
            this.clicked2 = true;
        },
        cancelAction() {
            this.appliedAttribute = false;
            this.newDirectory = true;
            this.resetAttributes();
            if (this.managePreview.length > 0) {
                this.editAssignedAttribute = false;
                this.appliedAttribute = true;
                this.appliedAttribute = true;
                this.newDirectory = false;
            }
        },
        resetAttributes() {
            this.attribute = {
                AttributeName: null,
                AttributeValues: [{ AttributeValueText: '' }],
                isMultiSelect: false,
            };
            this.activeFreeText();
            this.clicked = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-layouts/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-grids/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-vue-filemanager/styles/bootstrap4.css';

.my-custom-floating-label {
    .input__container {
        width: 400px;
    }
}
.breadcrumb {
    background-color: white !important;
}
.text-right.icons {
    //margin-top: -10px;
    max-width: 100px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}

.open-toggle {
    float: right;
    margin-top: 8px;
}
.border {
    height: 48px;
    padding-top: 3px !important;
    border-radius: 8px;
    //left: 5px;
    /* width: 987px; */
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}
.childCheckBox {
    padding-left: 20px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.leaflet-popup-content-wrapper {
    background-color: black !important;
    padding: 1px !important;
    min-width: 100px !important;
    margin: 0 !important;
    color: #eee !important;
    border-radius: 0 !important;
}
.leaflet-popup-content {
    margin: 3px 6px !important;
}
.leaflet-popup-tip-container {
    display: none;
}
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}
.leaflet-control-attribution a {
    display: none;
}

.leaflet-control-container {
    display: none;
}
.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    left: 1px;
    top: 12px;
    z-index: 9999999;
}
/* .border-right {
  margin-left: -36px !important;
} */

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
    padding: 15px 5px;
    font-size: 16px !important;
}

.DocManagement {
    height: auto !important;
}

.reportingTool p.wide-50 {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    width: 120px !important;
    height: 49px;
    opacity: 1 !important;
}

.titleBlock {
    margin: 16px 15px;
    height: 48px;
    font-size: 16px !important;
}
.titleBlock small {
    font-size: 16px !important;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
}
.titleBlock small:hover {
    border: 3spx solid #b034a1;
    height: 48px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 48px;
    width: 48px;
    text-align: center;
}

.graphing {
    margin: 16px 15px;
    height: 48px;
}

.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.graphing small {
    font-size: 16px !important;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d;
    padding-top: 10px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 48px;
}
.graphing span {
    background-color: #8456b0;
    height: 48px;
    width: 48px;
    text-align: center;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.directoryAssign {
    background: #dddddd;
    //margin-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.directoryItem {
    background-color: white !important;
    height: 48px !important;
    width: 499px !important;
    margin-left: 48px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -10px;
}
.test {
    margin-bottom: 20px;
}
.row1 {
    margin-bottom: 20px;
}
.searchinputIcon {
    background-color: #eaeaea;
    width: 197px;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
}
button#__BVID__31__BV_toggle_ {
    background: none !important;
    border: none !important;
}
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}

.dataset {
    margin: 10px 15px;
    height: 48px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 48px;
}
.dataset span {
    background-color: #5878be;
    height: 48px;
    width: 48px;
    text-align: center;
}
.graphic {
    text-align: center;
    width: 253px;
    text-align: center;
    height: 48px;
    border: none !important;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #c6c6c6;
    border: 1px solid #c6c6c6 !important;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
}
.blue {
    color: #fff !important;
    background-color: #000 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
// .graphic:active {
//   color: #fff !important;
//   border-color: #626262;
//   background-color: #000;
// }
.input-content {
    width: 515px !important;
    background-color: white !important;
    border-radius: 4px !important;
    border: 1px solid #c6c6c6 !important;
    font-size: 16px !important;
    color: #1d1d1d !important;
    font-family: var(--ffr) !important;
    height: 48px;
    font-weight: normal !important;
    /* margin-top: 23px; */
    //margin-left: 104px;
}
.graphic:hover {
    border-color: #626262;
    background-color: #000;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 28px;
    /* margin-left: 24px; */
    margin-top: 0px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}
.delete.attribute {
    margin-top: -48px;
    margin-right: 17px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.assignedTabs {
    width: 499px;
    margin: auto;
    .normal_input.smallTabs {
        width: 408px !important;
    }
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    // width: 35%;
    float: right;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        //margin-left: 312px;
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        cursor: pointer;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: auto;
    //   margin-top: 30px;
    // margin-left: 127px;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}

.cancelButton {
    background: transparent;
    color: #006395;
    border: none;
    float: left;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
}

.selectedGraphictool {
    color: #fff !important;
    border-color: #626262;
    background-color: #000;
}
.contouring {
    margin: 16px 15px;
    height: 48px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 48px;
}
.contouring span {
    background-color: #3296ca;
    height: 48px;
    width: 48px;
    text-align: center;
}

.mapToolAttribute {
    width: 225px;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 40%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.normal_select {
    width: 475px;
    height: 52px;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 3px;
}
.normal_select:focus {
    border: 1px solid #c6c6c6 !important;
}
.mapToolAttribute h6 {
    color: #006395;
    font-weight: bold;
}
.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;
    p {
        text-align: center;

        width: 248px;
        margin-left: 128px;
        font-size: 18px;
        color: #000;
        a {
            font-size: 20px;
            color: #006395 !important;
        }
    }
}

.uploadButton {
    background-color: #3296ca;
    width: 83px;

    margin-top: 10px;
    border-radius: 3px;
}
.uploadicon {
    height: 56px;
    width: 82px;
}
.iconplacing {
    //padding-left: 200px;
    padding-top: 13px;
}
.attrTabs .tabs {
    border: 2px solid #eaeaea !important;
    margin-bottom: 135px;
}
.content {
    // background-color: #eaeaea !important;
    min-height: auto;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    //padding: 30px;
    //text-align: center;
}

.my-custom-floating-label {
    background-color: white !important;
    // width: 400px !important;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    height: 48px;
    text-align: center;
    margin-top: 20px;
    font: normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 1px solid #6e6e6e;
    // margin-top: 67px;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.reportingTool span img {
    margin-top: 13px;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.uploadToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 450px;
    border-left: 6px solid #933d9b;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}

.assignAttr img {
    margin-top: 10%;
}

.tabs {
    padding-top: 10px;
}

ul.e-upload-files {
    border-top: none !important;
    margin-top: 70px !important;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

.card-header {
    margin-bottom: 20px;
    background: transparent;
}
#enterComments {
    width: 475px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    padding: 10px 15px;
}
.custom-select {
    background: #fff url('../../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
}
.custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 5%;
}
#enterComments {
    width: 475px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 10px 15px;
}
.custom-select {
    background: #fff url('../../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
    background: #eaeaea url('../../assets/big_plus.svg') no-repeat right 0.75rem
        center !important;
}
.btn-info:focus,
.btn-info.focus {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:hover {
    color: #000;
    background-color: #eaeaea;
}
.normal_inputButton {
    height: 48px !important;
    border-radius: 3px;
    //margin-left: 104px;
    width: 499px;
    margin: auto;
}
button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../../assets/minus_sign_big.svg') no-repeat right
        0.75rem center !important;
}

.dragdrop {
    margin-left: 140px;
    text-align: center;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000;
}
// .nextbutton {
//   margin-right: 50px;
// }

.nextbutton:disabled:hover {
    background-color: #eaeaea !important;
}

//style for file manager
.file-overview .sample-container {
    margin: 10px 10px 10px 10px;
}
//listing file in toast message after document upload
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}
.applyButtonSelect {
    background: #006395;
    color: white;
    border: none;
    cursor: pointer;
}
.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}
.model_style {
    width: 900px !important;
}
.modal-dialog {
    max-width: 700px !important;
}
button:disabled,
button[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
input:disabled,
input[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
div#__BVID__62___BV_modal_backdrop_ {
    opacity: 1 !important;
    background-color: #8a8a8a !important;
}
.e-file-container {
    align-items: last baseline !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name {
    padding: 0 !important;
}
.e-list-text {
    color: #fff !important;
}
.e-hover {
    color: #000;
}

#file-manager {
    display: block !important;
    //border: 1px solid red !important;
}

#file-manager_layout {
    display: block !important;
}
.upload-doc .custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 30px;
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
#outersearchinput {
    height: 40px !important;
    padding-top: 5px;
}
.icon-right {
    position: absolute;
    right: 0;
}
.subheader-smalltext {
    margin-right: -80px;
}
.mAttribute {
    width: 130px !important;
    height: 20px !important;
    background-color: transparent !important;
}
.wrapper {
    width: 1140px;
    margin: 0 auto;
}
.map-v {
    width: 1140px;
}
.applyButton {
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #ffffff;
}
.assignedApply,
.cancelButton {
    padding: 14px 23.46px;
}

.title {
    font: normal normal normal 25px/33px IBM Plex Sans;
    letter-spacing: 0px;
    color: #161616;
}
.manage-form .btn-secondary {
    padding: 14px 23.46px;
}
.editButton {
    background: #626262 0% 0% no-repeat padding-box;
    border-radius: 2px;
    width: 56px;
    height: 30px;
    padding: 0 !important;
    font: normal normal medium 14px/24px IBM Plex Sans;
    letter-spacing: 0.1px;
    color: #ffffff;
}
h5 {
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    font: 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #373a3c;
    font-weight: 500;
}
.contentEdit ul {
    margin-bottom: 0.25rem !important;
}
.contentEdit li {
    text-align: left;
    font: normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #373a3c;
}
.contentEdit small {
    text-align: left;
    font: italic normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #626262;
}
.model-body {
    padding-bottom: 40px;
}
.errManage {
    color: #f00;
    float: left;
    top: -15px;
    position: relative;
}
.assignedTabs .errManage {
    top: 0 !important;
}
.selectedGraphictool {
    background-color: #000 !important;
    color: #fff;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.read-only {
    background-color: #eee;
    padding: 5px 10px;
    color: #1d1d1d;
    font-weight: normal;
    display: inline-block;
    min-width: 200px;
    margin: 5px;
}
.noAttribute {
    text-align: center;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
    color: #5e5e5e;
    font-size: 24px;
}
.initAssinged {
    width: 598px !important;
    border-radius: 4px !important;
}
#Grid {
    width: 100% !important;
}
.attrBox {
    border: 1px solid #ccc;
    padding: 10px;
    width: 499px;
    border-radius: 5px;
    background-color: #fbfbfb;
    margin: 0 auto;
}
.attrBox label {
    font-size: 14px;
}
.singleSelect {
    position: relative;
    top: 2px;
}
.multiBox {
    font-size: 14px;
    display: grid;
}
.multiBox .custom-checkbox {
    margin-bottom: 8px;
}
.cancelButton:hover {
    background-color: transparent;
    color: #006395;
}
#attrContent {
    max-height: 400px;
    overflow-y: auto;
}

.e-icons {
    color: #00ffff;
    font-size: 26px;
}
.e-search:before {
    content: '\e84e';
}

@media (min-width: 992px) {
    .modal-dialog {
        width: 730px;
    }
}
</style>
