<template>
    <l-map
        class="positionRelative lMapControl mb-3"
        ref="lmap2"
        :zoom="zoom"
        :center="center"
        :options="{ zoomControl: false }"
        @click="mapClick"
        v-bind:max-zoom="21"
        @baselayerchange="mapLayerChanged"
        @update:bounds="updateBounds"
    >
        <l-control-layers position="topright"></l-control-layers>
        <l-control-address-search
            v-bind:alwaysExpanded="true"
            :options="geosearchOptions"
            v-bind:maxZoomLevel="16"
        ></l-control-address-search>
        <l-tile-layer
            v-for="baseMap in baseMaps"
            :key="baseMap.name"
            :name="baseMap.name"
            :visible="baseMap.visible"
            :attribution="baseMap.attribution"
            :url="baseMap.url"
            layer-type="base"
            :options="baseMapOptions"
        ></l-tile-layer>
        <l-polygon :lat-lngs="initialExtent" color="red" opacity="1" />
        <l-control-zoom position="bottomright"></l-control-zoom>
        <l-marker :visible="markerVisible" :lat-lng="center"></l-marker>
    </l-map>
</template>

<script>
import {
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPolygon,
} from 'vue2-leaflet';

import LControlAddressSearch from '../../EmergencyManagement/LeafletAddressSearch.vue';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { mapState } from 'vuex';

export default {
    name: 'ProjectMgmtMap',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControlLayers,
        LControlAddressSearch,
        LControlZoom,
        LPolygon,
    },
    props: {
        baseMaps: Object,
        project: {
            type: Object,
            required: true,
            default: () => {
                return {
                    ProjectID: null,
                    ProjectName: null,
                    City: null,
                    Comments: null,
                    CountryID: null,
                    StateID: null,
                    ProjectAttributeMappings: null,
                    IsActive: null,
                    XCoord: 0,
                    YCoord: 0,
                };
            },
        },
        editItem: {
            type: Object,
            required: true,
            default: () => {
                return {
                    ProjectName: null,
                    ProjectDesc: null,
                    CountryID: null,
                    StateId: null,
                    CityName: null,
                    Latitude: 0,
                    Longitude: 0,
                };
            },
        },
        initialExtent: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data() {
        return {
            zoom: 4,
            geosearchOptions: {
                position: 'topleft',
                provider: new OpenStreetMapProvider(),
                keepResult: true,
                showMarker: false,
                showPopup: false,
                searchLabel: 'Search Map',
            },
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
        };
    },
    methods: {
        mapClick(e) {
            this.$emit('mapClick', e);
        },
        mapLayerChanged(e) {
            this.$emit('layerChanged', e);
        },
        updateBounds(bounds) {
            this.$emit('newMapBounds', bounds);
        },
    },
    computed: {
        center() {
            let center = [];
            if (this.project.Latitude && this.project.Longitude) {
                center = [this.project.Latitude, this.project.Longitude];
            } else if (this.editItem.XCoord && this.editItem.YCoord) {
                center = [this.editItem.YCoord, this.editItem.XCoord];
            } else {
                center = [37.13, -100.13];
            }
            return center;
        },
        markerVisible() {
            let m = [];
            if (this.project.Latitude && this.project.Longitude) {
                m = true;
            } else if (this.editItem.XCoord && this.editItem.YCoord) {
                m = true;
            } else {
                m = false;
            }
            return m;
        },
    },
};
</script>

<style scoped>
.lMapControl {
    height: 400px;
    width: 500px;
}
</style>
