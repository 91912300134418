<template>
    <div>
        <div
            class="legend"
            v-show="true"
            v-for="(clegend, idx) in legendColorRange"
            v-bind:key="'Color_' + idx"
        >
            <i :style="getColor(clegend.style)"></i>
            <input
                type="checkbox"
                id="clegend.min"
                :value="clegend.id"
                v-model="checkedColor"
            />
            <label for="clegend.min"> {{ clegend.description }}</label>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'ColorLegend',
    data() {
        return {
            checkedColor: [],
        };
    },
    methods: {
        ...mapActions('dreams', ['setShowColorRange']),
        getColor(value) {
            return 'background:' + value;
        },
    },
    created() {
        this.checkedColor = this.selectedLegendColor;
    },
    watch: {
        checkedColor(newCheckedColor, oldCheckedColor) {
            this.setShowColorRange(newCheckedColor);
        },
    },
    computed: {
        ...mapState('dreams', {
            legendColorRange: (state) => state.colorRange,
            selectedLegendColor: (state) => state.showColorRange,
        }),
    },
};
</script>

<style scoped>
.legend {
    line-height: 18px;
    padding-left: 5px;
    color: #555;
}
.legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
}
.legendText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    padding-left: 8px;
}
</style>
