<template>
    <div
        v-show="showPane"
        ref="drawPane"
        :style="drawPaneStyle"
        class="buttonContainer"
    >
        <!-- row for the radiobuttons to change between tabs -->
        <div id="radioRow">
            <span class="radio-group">
                <input
                    class="radioButton"
                    id="icsRadio"
                    type="radio"
                    value="ics"
                    v-model="drawPaneTool"
                />
                <label class="label" for="icsRadio">Draw Tool</label>
            </span>
            <span class="radio-group">
                <input
                    class="radioButton"
                    id="drawRadio"
                    type="radio"
                    value="draw"
                    v-model="drawPaneTool"
                />
                <label class="label" for="drawRadio">Whiteboard Tool</label>
            </span>
            <span class="radio-group">
                <input
                    class="radioButton"
                    id="bufferRadio"
                    type="radio"
                    value="buffer"
                    v-model="drawPaneTool"
                />
                <label class="label" for="bufferRadio">Create Buffer</label>
            </span>
        </div>
        <!-- graphic layer mark-up tab -->
        <div v-show="drawPaneTool == 'draw'">
            <div class="descText">
                Please use the following buttons to select a geometry type to
                draw. While the button is blue, click on the map to place a
                point or vertex. Double click to finish the geometry.
            </div>
            <div class="d-flex justify-content-between rowBtns">
                <button
                    class="btn"
                    v-bind:class="
                        clickFunction == 'point' ? 'btn-blue' : 'btn-gray'
                    "
                    @click="drawPoint(false)"
                >
                    Draw Point</button
                ><br />
                <button
                    class="btn"
                    v-bind:class="
                        clickFunction == 'line' ? 'btn-blue' : 'btn-gray'
                    "
                    @click="drawLine(false)"
                >
                    Draw Line</button
                ><br />
                <button
                    class="btn"
                    v-bind:class="
                        clickFunction == 'polygon' ? 'btn-blue' : 'btn-gray'
                    "
                    @click="drawPolygon(false)"
                >
                    Draw Polygon</button
                ><br />
                <!-- <button @click="showAttributeModal">show att. modal</button><br /> -->
            </div>
            <div class="d-flex justify-content-end rowBtns">
                <button
                    v-if="clickFunction != ''"
                    class="btn btn-clear btn-buffer"
                    @click="cancelDraw()"
                >
                    Cancel</button
                ><br />
                <button
                    class="btn btn-clear btn-buffer"
                    @click="clearGeometry()"
                >
                    Clear</button
                ><br />
            </div>
        </div>

        <!-- ics pane -->
        <div v-show="drawPaneTool == 'ics'">
            <div class="descText">
                Please use the following drop-downs to select a layer to add a
                geometry feature and populate its attributes
            </div>
            <div class="EM">
                <custom-floating-label
                    :config="{
                        label: 'Editing Type',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="selectedDrawCategory" class="drawInput">
                        <option
                            v-for="(group, index) in drawCategories"
                            :key="index"
                            :value="group"
                        >
                            {{ group }}
                        </option>
                    </select>
                </custom-floating-label>
            </div>
            <div class="EM">
                <custom-floating-label
                    v-show="showSecondSelect"
                    :config="{
                        label: 'ER Sub Types',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="selectedLocGroup" class="drawInput">
                        <option
                            v-for="(shape, index) in locGroups"
                            :key="index"
                            :value="shape"
                        >
                            {{ shape }}
                        </option>
                    </select>
                </custom-floating-label>
            </div>
            <div class="EM">
                <custom-floating-label
                    v-show="showThirdSelect"
                    :config="{
                        label: 'ICS Sub Types',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="selectedLocType" class="drawInput">
                        <option
                            v-for="(type, index) in locTypes"
                            :key="index"
                            :value="type"
                        >
                            {{ type }}
                        </option>
                    </select>
                </custom-floating-label>
            </div>
            <div class="d-flex justify-content-center">
                <button
                    class="btn btn-gray btn-draw"
                    v-show="showDrawButton"
                    @click="startDraw"
                >
                    Start drawing
                </button>
            </div>
        </div>
        <div v-show="drawPaneTool == 'buffer'">
            <div class="descText">
                Use the buffer tool to estimate distances from a point. Enter
                desired distance(s) in Distance 1 box (Distance 2 is optional),
                click the Create button, then click on the map where the center
                of the buffer is desired. Press clear to remove the buffer
            </div>
            <div
                style="padding-top: 10px;"
                class="d-flex justify-content-start buffer"
            >
                <custom-floating-label
                    :config="{
                        label: 'Buffer Distance 1',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input v-model.number="bufferDistance1" type="number" />
                </custom-floating-label>
                <custom-floating-label
                    style="padding-left: 20px;"
                    :config="{
                        label: 'Buffer Distance 2',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input v-model.number="bufferDistance2" type="number" />
                </custom-floating-label>
            </div>
            <div class="EM" style="padding-top: 10px;">
                <custom-floating-label
                    :config="{
                        label: 'Buffer Distance Unit',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <select v-model="bufferUnit" class="drawInput">
                        <option
                            v-for="(unit, index) in bufferUnits"
                            :key="index"
                            :value="unit.toLowerCase()"
                        >
                            {{ unit }}
                        </option>
                    </select>
                </custom-floating-label>
                <div class="d-flex justify-content-center">
                    <button
                        class="btn btn-gray btn-buffer"
                        v-on:click="createBufferPoint"
                    >
                        Create Buffer
                    </button>
                    <button
                        class="btn btn-clear btn-buffer"
                        v-on:click="clearBuffers"
                    >
                        Clear
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import CustomFloatingLabel from '../CustomFloatingLabel.vue';
import { spatialFunctions } from '../../utilities/spatialFunctions';
import { spatialService } from '../../services/spatial.services.js';

/**
 * Add any custom component as a leaflet control
 */
export default {
    name: 'DrawPane',
    components: {
        CustomFloatingLabel,
    },
    mixins: [],
    props: {
        showPane: {
            type: Boolean,
            default: false,
            required: true,
        },
        midXPosition: {
            type: Number,
            default: 0,
            required: false,
        },
        projectId: {
            type: Number,
            default: 0,
            required: true,
        },
        clickFunction: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            drawTypes: {},
            drawTypeLookup: {},
            drawPaneTool: 'ics',
            width: 400,
            selectedDrawCategory: '---',
            selectedLocGroup: '---',
            selectedLocType: '---',
            bufferUnit: 'meters',
            bufferUnits: ['Meters', 'Kilometers', 'Feet', 'Miles'],
            bufferDistance1: null,
            bufferDistance2: null,
            saveDrawing: false,
            startPointRadius: 2,

            drawShapeColor: '#0000cc',
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateDrawPanePosition);
        this.$parent.$on('map/click', this.handleMouseClick);

        this.$parent.$on('map/dblClick', this.handleMouseDblClick);
        spatialService.getDrawTypes(this.projectId).then((res) => {
            this.drawTypes = res.data;
        });
        // this.formatDrawTypesStruct()
        this.$parent.$emit('ready', this.mapObject);
    },
    render() {
        return null;
    },
    methods: {
        ...mapActions('emergencyManagement', [
            'setDrawingAttributes',
            'activateERLayers',
        ]),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        toggleBufferEntries() {
            this.showBufferEntries = !this.showBufferEntries;
        },
        convertDistanceToMeters(distance, unit) {
            const conversions = {
                meters: 1,
                kilometers: 1000,
                feet: 0.3048,
                miles: 1609.34,
            };
            let convertedDistance = distance * conversions[unit.toLowerCase()];
            return convertedDistance.toFixed(6);
        },
        startDraw() {
            if (this.$parent.leftSidebarOpen == true) {
                this.$parent.toggleLeftSidebar();
            }
            if (this.$parent.rightSidebarOpen == true) {
                this.$parent.toggleRightSidebar();
            }
            this.$parent.paneVisibility['draw'] = false;
            this.activateERLayers();
            if (
                this.selectedLocGroup.toLowerCase().includes('point') ||
                this.selectedLocGroup == 'ICS'
            ) {
                this.drawPoint(true);
            } else if (this.selectedLocGroup.toLowerCase().includes('line')) {
                this.drawLine(true);
            } else if (
                this.selectedLocGroup.toLowerCase().includes('polygon')
            ) {
                this.drawPolygon(true);
            }
        },
        cleanupShapes(pastDrawing) {
            if (['line', 'polygon'].includes(pastDrawing)) {
                this.$parent.drawLines.pop();
                var i = this.$parent.drawLines.length - 1;
                if (i >= 0) {
                    while (i--) {
                        if (
                            this.$parent.drawLines.at(i).geometry.coordinates[0]
                                .length < 2
                        ) {
                            this.$parent.drawLines.splice(i, 1);
                        }
                    }
                }
            }
            if (pastDrawing == 'polygon') {
                this.$parent.drawPolygons.pop();

                i = this.$parent.drawPolygons.length - 1;
                if (i >= 0) {
                    while (i--) {
                        if (
                            this.$parent.drawPolygons.at(i).geometry
                                .coordinates[0][0].length < 3
                        ) {
                            this.$parent.drawPolygons.splice(i, 1);
                        }
                    }
                }
            }
        },
        createBufferPoint() {
            if (
                ((typeof this.bufferDistance1 === 'number' &&
                    this.bufferDistance1 > 0) ||
                    this.bufferDistance1 === null) &&
                ((typeof this.bufferDistance2 === 'number' &&
                    this.bufferDistance2 > 0) ||
                    this.bufferDistance2 === null)
            ) {
                this.$emit('updateClickFunction', 'buffer');
            } else {
                alert('Please enter valid buffer distance');
            }
        },
        clearBuffers() {
            this.$parent.bufferMarkers = this.$parent.bufferMarkers.filter(
                (feature) => {
                    !feature.id.startsWith('B');
                }
            );
        },
        cancelDraw() {
            this.$emit('updateClickFunction', '');
        },
        clearGeometry() {
            this.$parent.drawMarkers = this.$parent.drawMarkers.filter(
                (feature) => !feature.id.startsWith('C')
            );
            this.$parent.drawLines = this.$parent.drawLines.filter(
                (feature) => !feature.id.startsWith('L')
            );
            this.$parent.drawPolygons = this.$parent.drawPolygons.filter(
                (feature) => !feature.id.startsWith('P')
            );
        },
        drawPoint(save) {
            this.saveDrawing = save;
            this.cleanupShapes(this.clickFunction);
            this.$emit('updateClickFunction', 'point');
        },
        drawLine(save) {
            this.saveDrawing = save;
            this.cleanupShapes(this.clickFunction);
            this.$emit('updateClickFunction', 'line');
            this.$parent.drawLines.push({
                id: 'L' + this.$parent.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.drawShapeColor,
                },
            });
        },
        drawPolygon(save) {
            this.saveDrawing = save;
            this.cleanupShapes(this.clickFunction);
            this.$emit('updateClickFunction', 'polygon');
            this.$parent.drawLines.push({
                id: 'L' + this.$parent.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.drawShapeColor,
                },
            });
            this.$parent.drawPolygons.push({
                id: 'P' + this.$parent.drawPolygons.length,
                geometry: {
                    coordinates: [[]],
                    type: 'Polygon',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonColor: this.drawShapeColor,
                    PolygonBorderColour: this.drawShapeColor,
                    PolygonTransparency: 0.8,
                },
            });
        },
        handleMouseClick(event) {
            //
            var [lat, lng] = spatialFunctions.validateCoordinates(event);
            let markerFeature = {
                // using letters and the count to create unique values between different object arrays
                //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                //    but not errors that will cause issues in the actual execution/functionality
                id: 'C' + this.$parent.drawMarkers.length,
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lng],
                    coordSys: 'LatLong',
                },
                properties: {},
                styling: {
                    SymbologyColour: this.drawShapeColor,
                    SymbologySize: 1,
                    PolygonColor: this.drawShapeColor,
                    PolygonTransparency: 0,
                },
            };
            switch (this.clickFunction) {
                case 'point':
                    this.$parent.drawMarkers.push(markerFeature);
                    // only want one point at a time
                    this.$emit('updateClickFunction', '');
                    if (this.saveDrawing) {
                        {
                            this.showAttributeModal(
                                this.$parent.drawMarkers.at(-1)
                            );
                        }
                    }
                    break;

                case 'line':
                    // if it is the first point, draw a point
                    if (
                        this.$parent.drawLines.at(-1).geometry.coordinates
                            .length == 0
                    ) {
                        this.$parent.drawMarkers.push(markerFeature);
                    } else if (
                        // remove the starting point if this is the 2nd point
                        this.$parent.drawLines.at(-1).geometry.coordinates
                            .length == 1
                    ) {
                        this.$parent.drawMarkers.pop();
                    }
                    this.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.push([lat, lng]);
                    break;

                case 'polygon':
                    // if it is the first point, draw a point
                    if (
                        this.$parent.drawPolygons.at(-1).geometry.coordinates[0]
                            .length == 0
                    ) {
                        this.$parent.drawMarkers.push(markerFeature);
                        this.$parent.drawPolygons
                            .at(-1)
                            .geometry.coordinates.push([lat, lng]);
                    } else if (
                        // remove the starting point if this is the 2nd point
                        this.$parent.drawLines.at(-1).geometry.coordinates
                            .length == 1
                    ) {
                        this.$parent.drawMarkers.pop();
                    }
                    this.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.push([lat, lng]);
                    this.$parent.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0].push([lat, lng]);
                    break;
                case 'buffer':
                    var buffers = [
                        {
                            id: 'B0',
                            geometry: {
                                coordinates: [
                                    event.latlng.lat,
                                    event.latlng.lng,
                                ],
                            },
                            styling: {
                                PolygonBorderColour: 'black',
                                PolygonColour: 'black',
                                // PolygonStyle: '1000',
                                PolygonTransparency: 0,
                                SymbologySize: 0.1,
                            },
                        },
                    ];
                    if (this.bufferDistance1 > 0) {
                        buffers.push({
                            id: 'B1',
                            geometry: {
                                coordinates: [
                                    event.latlng.lat,
                                    event.latlng.lng,
                                ],
                            },
                            styling: {
                                PolygonBorderColour: 'black',
                                PolygonColour: 'dark grey',
                                PolygonStyle: '10,10',
                                PolygonTransparency: 0,
                                SymbologySize: this.convertDistanceToMeters(
                                    parseFloat(this.bufferDistance1),
                                    this.bufferUnit
                                ),
                            },
                        });
                    }
                    if (this.bufferDistance2 > 0) {
                        buffers.push({
                            id: 'B2',
                            geometry: {
                                coordinates: [
                                    event.latlng.lat,
                                    event.latlng.lng,
                                ],
                            },
                            styling: {
                                PolygonBorderColour: 'black',
                                PolygonColour: 'dark grey',
                                PolygonStyle: '10,10',
                                PolygonTransparency: 0,
                                SymbologySize: this.convertDistanceToMeters(
                                    parseFloat(this.bufferDistance2),
                                    this.bufferUnit
                                ),
                            },
                        });
                    }
                    this.$parent.bufferMarkers = buffers;
                    this.$emit('updateClickFunction', '');
                    break;
            }
        },
        // eslint-disable-next-line no-unused-vars
        handleMouseDblClick(event) {
            var wktGeom = '';
            switch (this.clickFunction) {
                case 'point':
                    this.$emit('updateClickFunction', '');
                    return;
                case 'line':
                    this.$emit('updateClickFunction', '');
                    // get rid of the vertex added from the 2nd click in the double
                    this.$parent.drawLines.at(-1).geometry.coordinates.pop();
                    if (this.saveDrawing) {
                        this.showAttributeModal(this.$parent.drawLines.at(-1));
                        this.saveDrawing = false;
                    }
                    break;

                case 'polygon':
                    this.$parent.drawLines.pop();
                    // get rid of the vertex added from the 2nd click in the double click
                    this.$parent.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0].pop();
                    this.$parent.drawPolygons.at(
                        -1
                    ).styling.PolygonTransparency = 0;
                    this.$emit('updateClickFunction', '');
                    if (this.saveDrawing) {
                        this.showAttributeModal(
                            this.$parent.drawPolygons.at(-1)
                        );
                        this.saveDrawing = false;
                    }
                    break;
            }
        },
        showAttributeModal(feature) {
            this.$emit('openAttributeModal');
            let attributes = {
                properties: [
                    {
                        label: 'geoID',
                        value: 0,
                    },
                    {
                        label: 'filler',
                        value: 0,
                    },
                    {
                        label: 'geomLocName',
                        value: null,
                    },
                    {
                        label: 'geomLocType',
                        value: this.drawTypes[this.selectedDrawCategory][
                            this.selectedLocGroup
                        ][this.selectedLocType],
                    },
                    {
                        label: 'geomLocDesc',
                        value: null,
                    },
                    {
                        label: 'geomComment',
                        value: null,
                    },
                ],
                geometry: { ...feature.geometry },
                projectID: this.projectId,
            };
            this.setDrawingAttributes(attributes);
            this.setShowAttributeModal(true);
        },
        drawReady() {},
        formatDrawTypesStruct(response) {
            // load response for dropdowns
        },
    },
    computed: {
        drawPaneStyle: function() {
            let screenWidth = window.innerWidth;
            if (screenWidth <= 768) {
                return {
                    position: 'relative',
                    zIndex: 2,
                    width: 'fit-content',
                    top: 0,
                    left: 0,
                };
            } else {
                return {
                    position: 'absolute',
                    zIndex: 2,
                    // top: "6vh",
                    // height: "230px",
                    width: this.width + 'px',
                    left:
                        Math.max(
                            Math.round(this.midXPosition - this.width / 2),
                            53
                        ) + 'px',
                };
            }
        },
        drawCategories: function() {
            return Object.keys(this.drawTypes);
        },
        showSecondSelect: function() {
            return this.selectedDrawCategory != '---';
        },
        locGroups: function() {
            if (this.selectedDrawCategory == '---') {
                return [];
            } else {
                return Object.keys(this.drawTypes[this.selectedDrawCategory]);
            }
        },
        showThirdSelect: function() {
            return (
                this.selectedDrawCategory != '---' &&
                this.selectedLocGroup != '---'
            );
        },
        locTypes: function() {
            if (
                this.selectedDrawCategory == '---' ||
                this.selectedLocGroup == '---'
            ) {
                return [];
            }
            return Object.keys(
                this.drawTypes[this.selectedDrawCategory][this.selectedLocGroup]
            );
        },
        showDrawButton: function() {
            return (
                this.selectedLocType != '---' && this.selectedLocType != null
            );
        },
    },
    watch: {
        selectedDrawCategory: function(newVal, oldVal) {
            if (newVal != oldVal) {
                this.selectedLocGroup = this.locGroups[0];
            }
        },
        selectedLocGroup: function(newVal, oldVal) {
            if (newVal != oldVal) {
                this.selectedLocType = this.locTypes[0];
            }
        },
    },
};
</script>

<style scoped>
.btn-gray {
    background-color: gray !important;
    color: white;
    /* height: 42px; */
}
.btn-gray:focus {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:active {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:hover {
    background-color: darkgrey;
}
.btn-blue {
    background-color: rgb(77, 194, 233) !important;
}

.drawInput {
    border: none !important;
    padding-top: 15px !important;
}
.buttonContainer {
    background-color: white;
}

@media screen and (min-width: 768px) {
    .buttonContainer {
        padding: 20px;
        border: solid 1px darkgray;
    }
    .radioButton {
        display: inline-block;
    }
    #radioRow {
        display: flex;
        justify-content: space-around;
        padding-bottom: 5px;
    }
    .btn-clear {
        border: 1px solid gray;
    }
    .btn-buffer {
        margin-left: 10px;
    }
    .btn-draw {
        margin-top: 7px;
    }
    .label {
        margin-left: 5px;
    }
    .rowBtns {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .icsInput {
        width: 330px;
        border-radius: 4px;
        margin-left: 15px;
        margin-top: 8px;
        margin-bottom: 0 !important;
    }
    .inputBuffer {
        margin-top: 5px;
        border-radius: 4px;
        width: 100%;
    }
    .descText {
        padding-top: 5px;
        padding-left: 15px;
        padding-right: 10px;
        padding-bottom: 10px;
    }
    .drawInput {
        border: none !important;
        padding-top: 15px !important;
    }
    .EM {
        padding-left: 15px;
    }
    .buffer {
        padding-left: 15px;
    }
    .topPadding {
        padding-top: 15px;
    }
}
@media screen and (max-width: 769px) {
    .buttonContainer {
        background-color: white;
        padding-bottom: 16px;
    }
    #radioRow {
        width: 100%;
    }
    .radio-group {
        display: block;
        float: left;
        width: 100%;
    }

    .input__container {
        border: 1px solid #c6c6c6;
        width: 75vw !important;
    }
    .input__container .input__container.input__container--content {
        border: 1px solid #c6c6c6;
        width: 75vw !important;
    }

    .my-custom-floating-label {
        border: 1px solid #c6c6c6;
    }

    .EM {
        height: auto;
        max-height: 30vh;
        max-width: 75vw;
    }
    .EM .my-custom-floating-label .input__container {
        border: 1px solid #c6c6c6;
        width: 80%;
    }

    .descText {
        width: 72vw;
    }
    select {
        width: 72vw;
        height: 30px;
        margin-bottom: 15px !important;
        color: #393939;
        font-size: 14px !important;
    }
    .drawInput {
        max-width: 72vw;
    }
}
</style>
