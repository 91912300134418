import Vue from 'vue';
const state = {
    activeMapTool: '',
    polylineLength: 0,
    polygonArea: 0,
    editGeometryGeom: {},
    bufferMarkers: [],
    drawMarkers: [],
    drawLines: [],
    editVertices: [],
    editArray: '',
    editIndex: null,
    drawPolygons: [],
    clickCoords: { lat: null, lng: null },
    showICSAttributeModal: false,
    isEditingICSAttributes: false,
    hasDoubleClicked: false,
    openSidebarFlag: '',
    closeSidebars: false,
    identifyResultsData: [],
    zoomToGeom: {},
    zoomToExtent: {},
    drawingProjectID: null,
    reclusterProject: false,
    toolLevels: {},
    contourInProgress: null,
    identifyPayload: {},
    rightTool: '',
    leftTool: '',
    projectsPanelOpen: false,
    isEditingPostedLayer: false,
};

const actions = {
    setMapTool({ commit }, toolName) {
        commit('mutateMapTool', toolName);
    },
    clearMapTool({ commit }) {
        commit('mutateMapTool', '');
    },
    handleClick({ commit }, clickCoords) {
        commit('mutateClickCoords', clickCoords);
    },
    setBufferMarkers({ commit }, buffers) {
        commit('mutateBufferMarkers', buffers);
    },
    clearBufferMarkers({ commit }) {
        commit('mutateBufferMarkers', []);
    },
    // want to only remove buffers added in the draw tool
    clearDrawBuffers({ commit, state }) {
        let nonDrawBuffers = state.bufferMarkers.filter(
            (feature) => !feature.id.startsWith('B')
        );
        commit('mutateBufferMarkers', nonDrawBuffers);
    },
    // want to only remove the identify buffer
    clearIdBuffer({ commit, state }) {
        let nonIdBuffers = state.bufferMarkers.filter(
            (feature) => feature.id !== 'IdBuffer'
        );
        commit('mutateBufferMarkers', nonIdBuffers);
    },
    // removes unfinished lines and/or unclosed polygons if the user
    //  changes the clickfunction mid-drawing
    removeUnfinishedShapes({ commit, state }, drawType) {
        if (['line', 'polygon'].includes(drawType)) {
            let drawLines = state.drawLines;
            // remove the last element
            drawLines.pop();
            var i = drawLines.length - 1;
            // for each element in the array, if it has fewer than 2 vertices, remove it
            //  lines require >= 2 vertices to be a valid geometry
            if (i >= 0) {
                while (i--) {
                    if (drawLines.at(i).geometry.coordinates[0].length < 2) {
                        drawLines.splice(i, 1);
                    }
                }
            }
            commit('mutateDrawLines', drawLines);
        }
        if (drawType == 'polygon') {
            let drawPolygons = state.drawPolygons;
            drawPolygons.pop();

            i = drawPolygons.length - 1;
            // for each element in the array, if it has fewer than 3 vertices, remove it
            //  polygons require >= 3 vertices to be a valid geometry
            if (i >= 0) {
                while (i--) {
                    if (
                        drawPolygons.at(i).geometry.coordinates[0][0].length < 3
                    ) {
                        drawPolygons.splice(i, 1);
                    }
                }
            }
            commit('mutateDrawPolygons', drawPolygons);
        }
    },
    clearWhiteboardGeoms({ commit, dispatch }) {
        let prefixes = { point: 'C', line: 'L', polygon: 'P' };
        dispatch('filterGeoms', prefixes);
    },
    clearHighlightGeom({ commit, dispatch }) {
        let prefixes = { point: 'H', line: 'H', polygon: 'H' };
        dispatch('filterGeoms', prefixes);
    },
    clearEditingGeom({ commit, dispatch }) {
        let prefixes = { point: 'CE', line: 'LE', polygon: 'PE' };
        dispatch('filterGeoms', prefixes);
    },
    // takes a prefixes object and filters the draw type arrays for the prefixes
    //  makes it easier to remove different sets withough having to use this full function every time
    filterGeoms({ commit, state }, prefixes) {
        let drawMarkers = state.drawMarkers;
        let drawLines = state.drawLines;
        let drawPolygons = state.drawPolygons;

        drawMarkers = state.drawMarkers.filter(
            (feature) => !feature.id.startsWith(prefixes.point)
        );
        drawLines = state.drawLines.filter(
            (feature) => !feature.id.startsWith(prefixes.line)
        );
        drawPolygons = state.drawPolygons.filter(
            (feature) => !feature.id.startsWith(prefixes.polygon)
        );

        commit('mutateDrawMarkers', drawMarkers);
        commit('mutateDrawLines', drawLines);
        commit('mutateDrawPolygons', drawPolygons);
    },
    setZoomToGeom({ commit }, geometry) {
        commit('mutateZoomToGeom', geometry);
    },
    clearZoomToGeom({ commit }) {
        commit('mutateZoomToGeom', '');
    },
    setZoomToExtent({ commit }, extent) {
        commit('mutateZoomToExtent', extent);
    },
    clearZoomToExtent({ commit }) {
        commit('mutateZoomToExtent', '');
    },
    appendToEditVertices({ commit, state }, vertex) {
        let editVertices = state.editVertices;
        editVertices.push(vertex);
        commit('mutateEditVertices', editVertices);
    },
    clearEditVertices({ commit }) {
        commit('mutateEditVertices', []);
    },
    setEditIndex({ commit }, value) {
        commit('mutateEditIndex', value);
    },
    clearEditIndex({ commit }) {
        commit('mutateEditIndex', null);
    },
    setIdentifyPayload({ commit }, payload) {
        commit('mutateIdentifyPayload', payload);
    },
    clearIdentifyPayload({ commit }) {
        commit('mutateIdentifyPayload', {});
    },
    updateTool({ commit, state }, { side, tool }) {
        commit('setTool', { side, tool });
        const projectsPanelOpen =
            state.rightTool === 'Projects' || state.leftTool === 'Projects';
        commit('setProjectsPanelOpen', projectsPanelOpen);
    },
};

const mutations = {
    mutateShowAttributeModal(state, value) {
        state.showAttributeModal = value;
    },
    mutateClickCoords(state, coords) {
        state.clickCoords = coords;
    },
    mutateMapTool(state, tool) {
        state.activeMapTool = tool;
    },
    mutateBufferMarkers(state, buffers) {
        state.bufferMarkers = buffers;
    },
    addDrawMarker(state, marker) {
        state.drawMarkers.push(marker);
    },
    addDrawLine(state, line) {
        state.drawLines.push(line);
    },
    addDrawPolygon(state, polygon) {
        state.drawPolygons.push(polygon);
    },
    addDrawBuffers(state, geometries) {
        // concat doesn't work inplace, have to assign it
        state.bufferMarkers = state.bufferMarkers.concat(geometries);
    },
    addIdBuffer(state, geometry) {
        // could just use push, using concat for consistency with above mutation
        state.bufferMarkers = state.bufferMarkers.concat(geometry);
    },
    pushLineVertex(state, vertex) {
        state.drawLines.at(-1).geometry.coordinates.push(vertex);
    },
    pushPolygonVertex(state, vertex) {
        state.drawPolygons.at(-1).geometry.coordinates[0].push(vertex);
    },
    mutateDrawMarkers(state, points) {
        state.drawMarkers = points;
    },
    mutateDrawLines(state, lines) {
        state.drawLines = lines;
    },
    mutateDrawPolygons(state, polygons) {
        state.drawPolygons = polygons;
    },
    mutateHasDoubleClicked(state, status) {
        state.hasDoubleClicked = status;
    },
    mutateDrawingProjectID(state, payload) {
        state.drawingProjectID = payload;
    },
    mutateOpenSidebarFlag(state, value) {
        state.openSidebarFlag = value;
    },
    mutateCloseSidebars(state, value) {
        state.closeSidebars = value;
    },
    mutateIdentifyResultsData(state, dataJson) {
        state.identifyResultsData = dataJson;
    },
    mutateZoomToGeom(state, geometry) {
        state.zoomToGeom = geometry;
    },
    mutateReclusterProject(state, status) {
        state.reclusterProject = status;
    },
    mutateZoomToExtent(state, extent) {
        state.zoomToExtent = extent;
    },
    mutateToolLevels(state, payload) {
        state.toolLevels = payload;
    },
    mutateEditVertices(state, payload) {
        state.editVertices = payload;
    },
    mutateEditArray(state, payload) {
        state.editArray = payload;
    },
    mutateEditIndex(state, payload) {
        state.editIndex = payload;
    },
    mutateIdentifyPayload(state, payload) {
        state.identifyPayload = payload;
    },
    setTool(state, { side, tool }) {
        state[side + 'Tool'] = tool;
    },
    setProjectsPanelOpen(state, value) {
        state.projectsPanelOpen = value;
    },
    setContourInProgress(state, jobId) {
        state.contourInProgress = jobId;
    },
    clearContourInProgress(state) {
        state.contourInProgress = null;
    },
    setIsEditingPostedLayer(state, value) {
        state.isEditingPostedLayer = value;
    },
};

const getters = {
    getShowAttributeModal: (state) => state.showAttributeModal,
    whiteboardFeatureCount: (state) => {
        let markerCount = state.drawMarkers.filter((feature) =>
            feature.id.startsWith('C')
        ).length;
        let lineCount = state.drawLines.filter((feature) =>
            feature.id.startsWith('L')
        ).length;
        let polygonCount = state.drawPolygons.filter((feature) =>
            feature.id.startsWith('P')
        ).length;
        return markerCount + lineCount + polygonCount;
    },
    highlightFeatureCount: (state) => {
        let markerCount = state.drawMarkers.filter((feature) =>
            feature.id.startsWith('H')
        ).length;
        let lineCount = state.drawLines.filter((feature) =>
            feature.id.startsWith('H')
        ).length;
        let polygonCount = state.drawPolygons.filter((feature) =>
            feature.id.startsWith('H')
        ).length;
        return markerCount + lineCount + polygonCount;
    },
    measureFeatureCount: (state) => {
        let markerCount = state.drawMarkers.filter((feature) =>
            feature.id.startsWith('M')
        ).length;
        let lineCount = state.drawLines.filter((feature) =>
            feature.id.startsWith('M')
        ).length;
        let polygonCount = state.drawPolygons.filter((feature) =>
            feature.id.startsWith('M')
        ).length;
        return markerCount + lineCount + polygonCount;
    },
    map3dFeatureCount: (state) => {
        let markerCount = state.drawMarkers.filter((feature) =>
            feature.id.startsWith('3D')
        ).length;
        let lineCount = state.drawLines.filter((feature) =>
            feature.id.startsWith('3D')
        ).length;
        let polygonCount = state.drawPolygons.filter((feature) =>
            feature.id.startsWith('3D')
        ).length;
        return markerCount + lineCount + polygonCount;
    },
};

const namespaced = true;

export const oneMap = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
