import Vue from 'vue';
import { projectService } from '../services';
import store from '../store';

const state = {
    projects: {},
    projectLyrs: {},
    projectClusterLyrs: {},
    projectLayersReady: false,
    projectLayerAdded: false
};

const actions = {
    toggleProjectLayerLabels({ commit }, val) {
        commit('toggleProjectLayerLabels', val);
    },
    updateProjectVisibility({ commit }, val) {
        commit('updateVisibility', val);
    },
    toggleProjectLayerClusteredAction({ commit }, val) {
        commit('toggleProjectLayerClustered', val);
    },
    appendInvisibleProjectGeometryToMapDataAction({ commit }, val) {
        commit('appendInvisibleProjectGeometryToMapData', val);
    },
    //updateProjectLayerData on getProjectlayerbyid after getting the Geometry and styling
    updateProjectLayerData({ commit }, val) {
        commit('updateProjectLayerData', val);
    },
};

const mutations = {
    //addProjectLayerData after getProjectlayersList api
    addProjectLayerData(state, payload) {
        const projectID = payload[0].ProjectID;
        state.projects[projectID] = payload[0];
    },
    updateProjectLayerData(state, payload) {
        const layer = payload.layer;
        const projectID = payload.projectID;
        state.projects[projectID].projectDataLayers[layer.DatasetID] = layer;

    },
    //setProjectLyrs, store layers for map ,First time loading the layers
    setProjectLyrs(state, payload) {
        state.projectLayerAdded = false

        const layer = payload.layer;
        const projectID = payload.projectID;
        const key = `${projectID}_${layer.DatasetID}`;
        if (layer.visible) {
            if (!layer.Clustered) {
                state.projectLyrs[key] = layer.Geometry;
            } else {
                state.projectClusterLyrs[key] = layer.Geometry;
            }

            state.projectLayerAdded = true

        } state.projectLayerAdded = false

    },
    clearMapData(state) {
        state.projects = {};
        state.projectLyrs = {};
        state.projectClusterLyrs = {};
    },
    async updateVisibility(state, payload) {
        const { datasetID, projectID } = payload;
        const key = `${projectID}_${datasetID}`;

        let clusterIsChecked =
            state.projects[projectID].projectDataLayers[datasetID].Clustered;
        state.projects[projectID].projectDataLayers[
            datasetID
        ].visible = !state.projects[projectID].projectDataLayers[
            datasetID
        ].visible;

        var IsVisible =
            state.projects[projectID].projectDataLayers[datasetID]
                .visible;
        if (!IsVisible) {
            if (clusterIsChecked) {
                delete state.projectClusterLyrs[key];
            } else {
                delete state.projectLyrs[key];
            }
        } else {
            if (clusterIsChecked) {
                state.projectClusterLyrs[key] =
                    state.projects[projectID].projectDataLayers[
                        datasetID
                    ].Geometry;
            } else {
                state.projectLyrs[key] =
                    state.projects[projectID].projectDataLayers[
                        datasetID
                    ].Geometry;
            }
        }
    },
    updateWMSVisibility(state, val) {
        let index = val.index;
        let idx = val.idx;
        state.projects[index].WMSLayer[idx].visible = !state.projects[index]
            .WMSLayer[idx].visible;
    },

    toggleProjectLayerLabels(state, payload) {
        const { datasetID, projectID } = payload;

        const key = `${projectID}_${datasetID}`;
        let dataSource =
            state.projects[projectID].projectDataLayers[datasetID].Geometry;
        if (
            !dataSource.labelVisibleIndex &&
            state.projects[projectID].projectDataLayers[datasetID].ShowLabels
        ) {
            for (let i = 0; i < dataSource.features[0].properties.length; i++) {
                if (dataSource.features[0].properties[i].tooltip) {
                    dataSource.labelVisibleIndex = i;
                }
            }
        }
        dataSource.features.forEach((geom) => {
            geom.properties[dataSource.labelVisibleIndex].label = !geom
                .properties[dataSource.labelVisibleIndex].label;
        });
    },
    async toggleProjectLayerClustered(state, payload) {
        const { datasetID, projectID } = payload;

        const key = `${projectID}_${datasetID}`;
        const layer = state.projects[projectID].projectDataLayers[datasetID];
        let clusterIsChecked =
            state.projects[projectID].projectDataLayers[datasetID].Clustered;
        state.projects[projectID].projectDataLayers[
            datasetID
        ].Clustered = !state.projects[projectID].projectDataLayers[datasetID]
            .Clustered;
        if (layer.visible) {
            if (clusterIsChecked) {
                state.projectLyrs[key] = state.projectClusterLyrs[key];
                delete state.projectClusterLyrs[key];
            } else {
                state.projectClusterLyrs[key] = state.projectLyrs[key];
                delete state.projectLyrs[key];
            }
        }
    },
    appendInvisibleProjectGeometryToMapData(state, val) {
        val.forEach((proj, index) => {
            //index is which project
            if (proj.ProjectLayers) {
                proj.ProjectLayers.forEach((lyr, idx) => {
                    //idx is which layer in project[index]
                    if (
                        lyr.visible == false &&
                        lyr.Geometry.features.length > 0
                    ) {
                        state.projects[index].ProjectLayers[
                            idx
                        ].Geometry.features = [...lyr.Geometry.features];
                        state.projects[index].ProjectLayers[
                            idx
                        ].geomDataLoaded = true;
                    }
                });
            }
        });
    },
    appendDreamsLayerData(state, payload) {
        state.projects[0].ProjectLayers = state.projects[0].ProjectLayers.concat(
            payload[0].ProjectLayers
        );
    },
};

const getters = {
    projectLyrCount: (state) => state.projects.length,
    identifyProjectLayers: (state) => {
        var allProjectLayers = [];
        try {
            Object.values(state.projects).forEach((proj) => {
                let projInfo = {
                    ProjectName: proj.ProjectName,
                    ProjectLayers: [],
                    WMSLayers: [],
                };
                proj.ProjectLayers.forEach((pLyr) => {
                    if (!pLyr.Clustered && pLyr.visible) {
                        if (pLyr.ProjectLayerID != undefined)
                            projInfo.ProjectLayers.push(pLyr.ProjectLayerID);
                    }
                });
                proj.WMSLayer.forEach((wmsLyr) => {
                    if (wmsLyr.MapLayerID != undefined)
                        projInfo.WMSLayers.push(wmsLyr.MapLayerID);
                });
                projInfo.WMSLayers = [...new Set(projInfo.WMSLayers)]; //this will set unique MapLayerID
                allProjectLayers.push(projInfo);
            });
        } catch (error) {
            //nothing
        }
        return allProjectLayers;
    },

    legendProjectLayers: (state) => {
        let legendProjectLayers = [];
        var isProjectLayersReady = state.projectLayersReady;
        if (Object.keys(state.projects).length > 0) {
            Object.values(state.projects).forEach((proj) => {
                if (
                    Object.keys(proj).includes('projectDataLayers') &&
                    Object.keys(proj.projectDataLayers).length > 0
                ) {
                    Object.values(proj.projectDataLayers).forEach((lyr) => {
                        if (lyr.Geometry && lyr.visible) {
                            legendProjectLayers.push(lyr);
                        }
                    });
                }
            });
        } return legendProjectLayers;

    },

    classic_tocProjectLayers: (state) => {
        var tocProjects = [];
        try {
            var wmsLyrId = 0;
            Object.values(state.projects).forEach((proj) => {
                var layers = [];
                proj.ProjectLayers.forEach((lyr) => {
                    lyr.isWMS = false;
                    layers.push(lyr);
                });
                proj.WMSLayer.forEach((wms) => {
                    if (wms.TreeStruct == 'Node') {
                        wms.isWMS = true;
                        wms.LyrId = wmsLyrId;
                        layers.push(wms);
                    }
                });
                tocProjects.push({
                    ProjectName: proj.ProjectName,
                    Layers: layers,
                });
            });
        } catch (error) {
            //nothing
        }
        return tocProjects;
    },

    wmsLayers: (state) => {
        var allWMSLayers = [];
        try {
            Object.values(state.projects).forEach((proj) => {
                proj.WMSLayer.forEach((wmsLyr) => {
                    if (wmsLyr.TreeStruct != 'Branch') {
                        allWMSLayers.push(wmsLyr);
                    }
                });
            });
        } catch (error) {
            //nothing
        }
        return allWMSLayers;
    },
};

const namespaced = true;

export const projectLayers = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
