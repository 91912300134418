<template>
    <div v-if="showPage">
        <Header />
        <b-container fluid class="tableselect sub_header_bg p-0">
            <b-container class="p-0">
                <b-row class="mb-3 pt-1" v-if="showDataset">
                    <b-col cols="12">
                        <a @click="navigatetoDataset">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <span class="floar-left ml-1"
                                >Back to datasets</span
                            >
                        </a>
                    </b-col>
                </b-row>
                <b-row class="mb-3 pt-1" v-else>
                    <b-col cols="12">
                        <a @click="navigatetoselectedProjects">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <span class="floar-left ml-1">
                                Back to selected projects</span
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="6" class="pt-2">
                        <h1 v-if="!showDataset && !hidetableSelect">
                            {{ selectedProjectsList }}
                        </h1>
                        <h1 v-else class="">{{ datasetNameHeader }}</h1>
                    </b-col>
                    <b-col cols="6" class="pt-2">
                        <b-button
                            v-if="hidetableSelect && isRender && step == 0"
                            rounded
                            @click="openModel"
                            class="buttonSelect rightAdjustment"
                        >
                            Save filters</b-button
                        >
                    </b-col>
                </b-row>
                <b-card no-body>
                    <b-tabs
                        class="selecting portalTabs tabs title-b"
                        active-nav-item-class=" pl-0 pr-0 borderBlue"
                        v-on:activate-tab="selectedTab"
                        v-on:changed="activateTab"
                        v-model="step"
                    >
                        <b-tab title="Datasets" v-if="showTableSelect">
                            <b-container class="d-p-0">
                                <div class="pb-3">
                                    <portal-bar-tool
                                        :showReportingMenu="showReportingMenu"
                                        :hidetableSelect="hidetableSelect"
                                        :dataPostingTool="dataPostingTool"
                                        :reportingToolDisplay="
                                            reportingToolDisplay
                                        "
                                        :toolsData="toolsData"
                                        :dataSetTools="dataSetToolsAvailable"
                                        :search="searchGrid"
                                        @clicked="showSwitch"
                                        @showReorting="showDataPosting"
                                        @datasetexportDash="showDatasetexport"
                                        @navigateTools="navigateTools"
                                    ></portal-bar-tool>
                                    <div v-if="downloadDoc">
                                        <div
                                            class="spinner-border visibilityIcon"
                                            role="status"
                                        >
                                            <span class="sr-only"
                                                >Loading...</span
                                            >
                                        </div>
                                        <div class="visibilityText">
                                            Please wait...
                                        </div>
                                    </div>
                                    <div
                                        v-if="hidetableSelect"
                                        class="my-3 px-3 d-none"
                                    >
                                        Enter no.of column:<input
                                            id="freezecolinput"
                                            type="number"
                                            class="mx-2"
                                            autocomplete="off"
                                            min="0"
                                            @keydown="preventNegative"
                                        />
                                        Enter no.of row:<input
                                            id="freezerowinput"
                                            type="number"
                                            class="mx-2"
                                            autocomplete="off"
                                            min="0"
                                            @keydown="preventNegative"
                                        />

                                        <b-button
                                            rounded
                                            id="freeze-btn"
                                            @click="btnclick"
                                            variant="primary"
                                            size="sm"
                                        >
                                            Freeze</b-button
                                        >
                                        <b-button
                                            rounded
                                            id="reset-btn"
                                            @click="resetBtnClick"
                                            size="sm"
                                            variant="outline-primary"
                                        >
                                            Reset</b-button
                                        >
                                        <br />
                                        <span v-if="freeze">
                                            <small class="color-1"
                                                >Enter max
                                                {{
                                                    Object.keys(
                                                        this.displayFields
                                                    ).length - 1
                                                }}
                                                columns and 7 rows to
                                                freeze</small
                                            ></span
                                        >
                                    </div>

                                    <div v-if="filterLoading && !isRender">
                                        <div
                                            class="spinner-border visibilityIcon"
                                            role="status"
                                        >
                                            <span class="sr-only"
                                                >Loading...</span
                                            >
                                        </div>
                                        <div class="visibilityText">
                                            Please wait...
                                        </div>
                                    </div>
                                    <div v-if="hidetableSelect">
                                        <div
                                            v-if="isRender"
                                            class="pb-5 flt-tbl bottom-p-125"
                                        >
                                            <div
                                                id="Grid"
                                                ref="Grid"
                                                :dataSource="data"
                                                :dataBound="dataBound"
                                            ></div>
                                            <!-- :editSettings="editSettings" -->
                                            <!--div style="font-size: 12px;">({{tableRowCount}} items)</div>-->
                                        </div>
                                    </div>
                                    <div
                                        v-if="!hidetableSelect && openNewTable"
                                        class="table-select bottom-p-75"
                                    >
                                        <ejs-grid
                                            :dataSource="tools"
                                            class="sortingenabled tableSelectGrid"
                                            ref="grid"
                                            :allowPaging="true"
                                            :allowSorting="true"
                                            :rowSelected="dataSetRowSelected"
                                            :load="pageCenter"
                                            :dataBound="dataBound"
                                            :gridSettings="gridSettings"
                                            :pageSettings="options"
                                            :allowResizing="true"
                                        >
                                            <e-columns>
                                                <e-column
                                                    v-if="currentSwitchState"
                                                    headerText="Filter"
                                                    width="50"
                                                    :commands="filterCommands"
                                                >
                                                </e-column>
                                                <e-column
                                                    v-if="!currentSwitchState"
                                                    headerText="Filter"
                                                    width="50"
                                                    :commands="filterCommands"
                                                >
                                                </e-column>
                                                <e-column
                                                    field="DisplayName"
                                                    headerText="Dataset name"
                                                    width="150"
                                                ></e-column>
                                                <e-column
                                                    field="DatasetDesc"
                                                    headerText="Description"
                                                    width="200"
                                                ></e-column>
                                                <e-column
                                                    headerText="Tools"
                                                    :valueAccessor="valueAccess"
                                                    width="100"
                                                    :disableHtmlEncode="false"
                                                ></e-column>
                                            </e-columns>
                                        </ejs-grid>
                                    </div>
                                </div>
                            </b-container>
                        </b-tab>
                        <b-tab
                            v-for="t in tooltab"
                            :key="'dyn-tab-' + t"
                            title="Data posting tool"
                            :active="tooltab.indexOf(t) == tooltab.length - 1"
                        >
                            <template #title>
                                Data posting tool
                                <img
                                    src="../../assets/close.svg"
                                    class=""
                                    @click="closeTabD(t)"
                                />
                            </template>
                            <portal-bar-tool
                                :showReportingMenu="showReportingMenu"
                                :hidetableSelect="hidetableSelect"
                                :dataPostingTool="dataPostingTool"
                                :dataSetTools="dataSetToolsAvailable"
                                :reportingToolDisplay="reportingToolDisplay"
                                :toolsData="toolsData"
                                :search="searchGrid"
                                @clicked="showSwitch"
                                @showReorting="showDataPosting"
                                @datasetexportDash="showDatasetexport"
                            ></portal-bar-tool>
                            <template>
                                <data-posting-tool
                                    @clickedState="clickedState"
                                    :aggregateOptionFromTable="aggregateOption"
                                    :showReportingMenu="showReportingMenu"
                                    :hidetableSelect="hidetableSelect"
                                    @resForbiddenPosting="resForbiddenPosting"
                                >
                                </data-posting-tool>
                            </template>
                        </b-tab>

                        <b-tab
                            v-for="z in reportingTab"
                            :key="'dyn-tab-' + z"
                            title="X-Y scatter plot"
                            :active="
                                reportingTab.indexOf(z) ==
                                    reportingTab.length - 1
                            "
                            @click="renderTitleblock()"
                        >
                            <template #title>
                                X-Y scatter plot &nbsp;
                                <img
                                    src="../../assets/close.svg"
                                    class="close-icon"
                                    @click="closeTabScatter(z)"
                                />
                            </template>

                            <template>
                                <p
                                    v-if="scatterCancel"
                                    class="float-right cancel-link-chart pointer blue-text chart-link cancel-hide"
                                    @click="closeTabScatter(z)"
                                >
                                    Cancel
                                </p>
                                <chartelement
                                    ref="childComponent"
                                    @generateGraph="generateGraph"
                                    :backFromGraph="backFromGraph"
                                    :renderTitle="renderTitle"
                                    :titleBlockDisplay="titleBlockDisplay"
                                    :showReportingMenu="showReportingMenu"
                                    :hidetableSelect="hidetableSelect"
                                    @currentEditTitleState="
                                        currentEditTitleState
                                    "
                                    :filteredColumnId="filteredColumnId"
                                    @resForbidden="resForbidden"
                                    :axisDetails="axisDetails"
                                ></chartelement>
                            </template>
                        </b-tab>
                        <!-- Render Tabs, supply a unique `key` to each tab -->
                        <b-tab
                            v-for="i in tabs"
                            :key="'dyn-tab-' + i"
                            @click="showThisTab(i)"
                            active
                        >
                            <template #title>
                                {{ i + ' filter options' }}
                                <img
                                    src="../../assets/close.svg"
                                    class=""
                                    @click="closeTab(i)"
                                />
                            </template>
                            <portal-bar-tool
                                :showReportingMenu="showReportingMenu"
                                :disableSearchIcon="true"
                                :hidetableSelect="hidetableSelect"
                                :dataPostingTool="dataPostingTool"
                                :dataSetTools="dataSetToolsAvailable"
                                :reportingToolDisplay="reportingToolDisplay"
                                :toolsData="toolsData"
                                :search="searchGrid"
                                @clicked="showSwitch"
                                @showReorting="showDataPosting"
                                @datasetexportDash="showDatasetexport"
                            >
                            </portal-bar-tool>

                            <FilterOption
                                v-if="currentSwitchState"
                                @columnIds="columnIds"
                                @appliedFilters="appliedFilters"
                                @hidetableSelect="applyFilter"
                                @openNewTable="openNewTable"
                                :hideheader="1"
                                :tabIndex="i"
                                :showReportingMenu="showReportingMenu"
                                :hidetableSelect="hidetableSelect"
                                @sortedColumns="sortedColumns"
                                :satusHistory="satusHistory"
                                :selectedFiltersHistory="selectedFiltersHistory"
                                :selectedGridFilters="selectedGridFilters"
                            ></FilterOption>

                            <FilterOption
                                v-else
                                @columnIds="columnIds"
                                @appliedFilters="appliedFilters"
                                @hidetableSelect="applyFilter"
                                :tabIndex="i"
                                :showReportingMenu="showReportingMenu"
                                :hidetableSelect="hidetableSelect"
                                @sortedColumns="sortedColumns"
                                :hideheader="0"
                                :satusHistory="satusHistory"
                                :selectedFiltersHistory="selectedFiltersHistory"
                                :selectedGridFilters="selectedGridFilters"
                                @setFilterOptionsHistory="
                                    setFilterOptionsHistory
                                "
                            >
                            </FilterOption>
                        </b-tab>
                        <b-tab
                            v-for="z in exportDatasettab"
                            :key="'dyn-tab-' + z"
                            title="Dataset export"
                            :active="
                                exportDatasettab.indexOf(z) ==
                                    exportDatasettab.length - 1
                            "
                        >
                            <template #title>
                                Dataset export &nbsp;
                                <img
                                    src="../../assets/close.svg"
                                    class="close-icon"
                                    @click="closeTabDataSetExport(z)"
                                />
                            </template>
                            <template>
                                <datasetexport
                                    @makeExcel="makeExcel"
                                    @makeCsv="makeCsv"
                                    :dataSource="dataSource"
                                    :filteredData="filteredData"
                                    :showReportingMenu="showReportingMenu"
                                    :hidetableSelect="hidetableSelect"
                                    @resForbiddenExport="resForbiddenExport"
                                >
                                </datasetexport>
                            </template>
                        </b-tab>
                        <b-tab
                            v-for="b in titleBlocktab"
                            :key="'dyn-tab-' + b"
                            title="Title block"
                            :active="
                                titleBlocktab.indexOf(b) ==
                                    titleBlocktab.length - 1
                            "
                        >
                            <template #title>
                                Title block &nbsp;
                                <img
                                    src="../../assets/close.svg"
                                    class="close-icon"
                                    @click="closeTabTitleBlock(b)"
                                />
                            </template>
                            <template>
                                <!-- <b-col class="title-m-view">-->
                                <p
                                    class="float-right cancel-link"
                                    @click="closeTabTitleBlock(b)"
                                >
                                    Cancel
                                </p>
                                <!-- </b-col> -->
                                <TitleBlock
                                    :renderTitle1="renderTitle1"
                                    @redirectScatter="redirectScatter"
                                    @closetitleBlock="closeTabTitle"
                                    @resForbiddenTitle="resForbiddenTitle"
                                ></TitleBlock>
                                <!-- <datasetexport> </datasetexport> -->
                            </template>
                        </b-tab>
                        <b-tab
                            v-for="b in xyTitleBlocktab"
                            :key="'dyn-tab-' + b"
                            title="X-Y axis edit"
                            :active="
                                xyTitleBlocktab.indexOf(b) ==
                                    xyTitleBlocktab.length - 1
                            "
                        >
                            <template #title>
                                X-Y axis edit &nbsp;
                                <img
                                    src="../../assets/close.svg"
                                    class="close-icon"
                                    @click="closeXYAxistab(b)"
                                />
                            </template>
                            <template>
                                <p
                                    class="float-right cancel-link"
                                    @click="closeXYAxistab(b)"
                                >
                                    Cancel
                                </p>
                                <axis-edit
                                    :axisDetails="axisDetails"
                                    @redirectToScatterTab="redirectToScatterTab"
                                ></axis-edit>
                            </template>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-container>
        </b-container>

        <b-modal
            id="modal-screen"
            ok-only
            hide-footer
            hide-header
            @hide="modalClose"
            modal-class="modalFullscreen"
        >
            <div class="modalScreen">
                <!-- insert modal content in here -->
                <modal-leaflet-map> </modal-leaflet-map>
            </div>
        </b-modal>
        <b-modal
            no-close-on-backdrop="false"
            ref="error-modal"
            content-class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right cursor-pointer">
                    <img
                        class="rounded-circle"
                        src="../../assets/close_icon.svg"
                        @click="hideErrorModal"
                    />
                </div>
                <h5><img src="../../assets/alert-circle.svg" /></h5>
                <h4 class="mt-2">{{ errorMessage }}</h4>
            </div>
        </b-modal>
        <b-modal
            size="lg"
            title="Freeze Tool"
            ref="modal-1"
            id="modal-1"
            ok-title="Freeze"
            cancel-title="Reset "
            hide-header
            hide-footer
            @ok="SaveFilter"
            @cancel="handleReset"
        >
            <div class="text-right cursor-pointer">
                <img
                    class="pointer"
                    src="../../assets/close_icon.svg"
                    @click="closeModal"
                />
            </div>
            <h5 class="my-2 title text-center">Save filters</h5>
            <div class="manage-form">
                <div class="content">
                    <CustomFloatingLabel
                        :config="{
                            label: 'Filter name',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="filterName"
                            ref="filterName"
                            autocomplete="off"
                            type="text"
                            v-model="filterName"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Filter description',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="filterDescription"
                            ref="filterDescription"
                            autocomplete="off"
                            type="text"
                            v-model="filterDescription"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Category',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        v-bind:label="floatLabelAdd"
                    >
                        <input
                            id="Category"
                            ref="Category"
                            autocomplete="off"
                            type="text"
                            v-model="filterCategory"
                            class="normal_input"
                            min="0"
                        />
                    </CustomFloatingLabel>
                    <b-button
                        id="freeze-btn-portal"
                        class="applyButton assignedApply"
                        @click="SaveFilter()"
                    >
                        Save
                    </b-button>
                    <b-button
                        id="reset-btn--portal"
                        class="cancelButton"
                        @click="forceReset"
                    >
                        Reset
                    </b-button>
                </div>
            </div>
        </b-modal>
        <Footer />
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :successToastMessage="successToastMessage"
            :failureToastMessage="failureToastMessage"
        />

        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import router from '../../router';
import Header from '../Header';
import Footer from '../Footer';
import { mapActions, mapState } from 'vuex';
import FilterOption from './FilterOptions';
import DataPostingTool from './DataPostingTool';
import { datasetService } from '../../services/dataset.service';
import { commonService } from '../../services/common.service';
import chartelement from './GenerateChartElement';
import Vue from 'vue';
//import { L10n, setCulture } from "@syncfusion/ej2-base";
import { Menu } from '@syncfusion/ej2-navigations';
//import dashboard from "../../views/Dashboard";
import AccessPermissionModal from '../AccessPermissionModal';
import TitleBlock from '../projects/TitleBlock';
import ToastMessages from '../ToastMessages';
import ModalLeafletMap from '../CommonComponents/ModalLeafletMap.vue';
// import PortalBarTool from '../projects/PortalBarTool';
import AxisEdit from './AxisEdit.vue';
import CustomFloatingLabel from '../CustomFloatingLabel';
import {
    GridPlugin,
    Sort,
    Page,
    Filter,
    Freeze,
    CommandColumn,
    Search,
    Resize,
    Toolbar,
    Grid,
    Reorder,
    ExcelExport,
} from '@syncfusion/ej2-vue-grids';
import datasetexport from './DatasetExport';
import PortalBarTool from '../PortalBarTool.vue';
import { DateRangePicker } from '@syncfusion/ej2-calendars';
import _ from 'lodash';

Vue.use(GridPlugin);
export default {
    name: 'tableselectiontool',
    components: {
        Header,
        Footer,
        FilterOption,
        DataPostingTool,
        chartelement,
        datasetexport,
        // dashboard,
        AccessPermissionModal,
        TitleBlock,
        PortalBarTool,
        ToastMessages,
        ModalLeafletMap,
        AxisEdit,
        CustomFloatingLabel,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Filter,
            Freeze,
            CommandColumn,
            Search,
            Toolbar,
            Grid,
            Resize,
            ExcelExport,
        ],
    },
    async created() {
        this.tableDisplay();
        this.$root.$refs.B = this;

        this.selectedProjects = JSON.parse(
            localStorage.getItem('selectedProjects')
        );
        if (this.$route.params.id) {
            this.projectIds = this.$route.params.id;
        } else {
            this.projectIds = this.selectedProjects.toString();
        }
        localStorage.setItem('projectId', this.projectIds);
        if (this.$route.params.tool) {
            this.datatool = this.$route.params.tool;
        }
        // this.getTools();
        await this.getToolsData(this.projectIds);
        this.newToolSelectionTab();
        this.applyFilterNewTab();
        if (localStorage.getItem('openXYGraph')) {
            setTimeout(() => {
                this.step = 1;
            }, 100);
            this.openXYGraph();
        }
        if (this.$route.params.args) {
            let historyArgs = JSON.parse(this.$route.params.args);
            if (historyArgs) {
                this.satusHistory = historyArgs.status;
                this.selectedFiltersHistory = historyArgs.availableFilterArr;
                this.selectedGridFilters = historyArgs.gridFilters;
                this.userPortalOptionID = historyArgs.UserPortalOptionID;
                if (this.userPortalOptionID !== 0) {
                    this.filterName =
                        historyArgs.tableRowSelectArgs.data.filterName;
                    this.filterDescription =
                        historyArgs.tableRowSelectArgs.data.filterDescription;
                    this.filterCategory =
                        historyArgs.tableRowSelectArgs.data.filterCategory;
                }

                this.dataSetRowSelected(historyArgs.tableRowSelectArgs);
            }
        }
    },
    data() {
        return {
            filterLoading: false,
            hidetableSelect: false,
            openNewTable: true,
            dataPostingTool: false,
            reportingToolDisplay: false,
            titleBlockDisplay: false,
            formatOptions: { type: 'dateTime', format: 'MM/dd/yyyy hh:mm:ss' },
            sortOptions: {
                columns: [
                    { field: 'DisplayName', direction: 'Ascending' },
                    { field: 'DatasetDesc', direction: 'Descending' },
                    { field: 'Type', direction: 'Descending' },
                    { field: 'CreationDate', direction: 'Descending' },
                ],
            },
            sortOptionsTbl: {
                columns: [],
            },
            tabs: [],
            step: 0,
            titleBlockTabNum: -1,
            datasetExportTab: -1,
            dataPostTab: -1,
            scatterPlotTab: -1,
            contouringTabCount: -1,
            editAxisTabNum: -1,
            selectedProjects: [],
            tools: [],
            projectIds: '',
            tooltab: [],
            reportingTab: [],
            toolsData: [],
            dataSetToolsAvailable: [],
            dataSetColumnAlias: [],
            tabCounter: 0,
            toolbarcounter: 0,
            reportingTabcounter: 0,
            exportDatasettab: [],
            titleBlocktab: [],
            contouringTab: [],
            xyTitleBlocktab: [],
            displayFields: [],
            exportDatasetcounter: 0,
            titleBlockcounter: 0,
            contouringCounter: 0,
            isRender: false,
            currentSwitchState: false,
            reportingTool: false,
            showReportingMenu: false,
            freezeButton: true,
            freeze: false,
            resetFrezeButton: false,
            firstLvlFilter: [],
            dateValue: [],
            cTemplate: () => {
                return {
                    template: Vue.component('columnTemplate', {
                        template: `<div :class="dataTypeClass">
            <a  v-on:click="hyperlinkClick">{{cData}}</a>
            </div>`,
                        data: () => {
                            return {
                                data: {},
                            };
                        },
                        computed: {
                            cData: function() {
                                var value = this.data[this.data.column.field];
                                return value;
                            },
                            dataTypeClass: function() {
                                var value = this.data.DocHyperlinkSource;
                                return value;
                            },
                        },
                        methods: {
                            hyperlinkClick: (args) => {
                                this.downloadDoc = true;
                                var grid = document.getElementById('Grid')
                                    .ej2_instances[0];
                                var rowInfo = grid.getRowInfo(
                                    args.target.closest('td')
                                );
                                var cellValue =
                                    rowInfo.rowData[rowInfo.column.field];
                                let hyperNumber = rowInfo.column.field.includes(
                                    '_'
                                )
                                    ? rowInfo.column.field.split('_')
                                    : 0;
                                let path =
                                    hyperNumber != 0
                                        ? 'DocHyperlinkPath_' + hyperNumber[1]
                                        : 'DocHyperlinkPath';
                                var pathValue = rowInfo.rowData[path];
                                let source =
                                    hyperNumber != 0
                                        ? 'DocHyperlinkSource_' + hyperNumber[1]
                                        : 'DocHyperlinkSource';
                                var sourceValue = rowInfo.rowData[source];
                                let filename = cellValue;
                                if (sourceValue === 'Blob') {
                                    commonService
                                        .getDocumentHyperLinkSource(
                                            filename,
                                            sourceValue,
                                            pathValue
                                        )
                                        .then((res) => {
                                            let arrayBuffer = this.base64ToArrayBuffer(
                                                res.data
                                            );
                                            var fileURL = window.URL.createObjectURL(
                                                new Blob([arrayBuffer])
                                            );
                                            var fileLink = document.createElement(
                                                'a'
                                            );
                                            fileLink.href = fileURL;
                                            let filenameExt = filename.split(
                                                '.'
                                            );
                                            fileLink.setAttribute(
                                                'download',
                                                filenameExt[0] +
                                                    '.' +
                                                    filenameExt[1]
                                            );
                                            document.body.appendChild(fileLink);
                                            fileLink.click();
                                        });
                                } else if (sourceValue === 'Web') {
                                    let url = pathValue;
                                    window.open(url, '_blank');
                                } else {
                                    commonService
                                        .getDocumentHyperLink(
                                            pathValue + '\\' + filename
                                        )
                                        .then((res) => {
                                            let arrayBuffer = this.base64ToArrayBuffer(
                                                res.data
                                            );
                                            var fileURL = window.URL.createObjectURL(
                                                new Blob([arrayBuffer])
                                            );
                                            var fileLink = document.createElement(
                                                'a'
                                            );
                                            fileLink.href = fileURL;
                                            let filenameExt = filename.split(
                                                '.'
                                            );
                                            fileLink.setAttribute(
                                                'download',
                                                filenameExt[0] +
                                                    '.' +
                                                    filenameExt[1]
                                            );
                                            document.body.appendChild(fileLink);
                                            fileLink.click();
                                        });
                                }
                                setTimeout(() => {
                                    this.downloadDoc = false;
                                }, 2000);
                            },
                        },
                    }),
                };
            },
            dataSetId: '',
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            deleteCommands: [
                {
                    type: 'Delete',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-delete e-icons',
                    },
                },
            ],
            filterCommands: [
                {
                    type: 'Filter',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-filter e-icons',
                    },
                },
            ],
            datatool: false,
            contourtool: false,
            totalRows: 100,
            currentPage: 1,
            perPage: 25,
            pageOptions: [5, 10, 15],
            dataset_name: [],
            showOptions: false,
            visible: false,
            optionsTools: [],
            filtered1: [],
            fltrOption: [],
            checkedOpt: [],
            afterFilter: [],

            // toolbarOptions: ["Search"],
            pageSettings: { pageSizes: true, pageSize: 9 },
            filterOptions: {
                type: 'CheckBox',
            },
            filter1: '',
            filter: {
                type: 'CheckBox',
            },
            flag: false,
            filterDialogCol: '',
            curOpt: '',
            successToast: false,
            successToastFilter: false,
            resetFreezeButton: null,
            DPShow: true,
            ContourShow: true,
            GenerateChart: true,
            showPage: true,
            thisfilename: null,
            showTitle: true,
            showDSExport: true,
            closeThis: false,
            showDataset: false,
            datasetNameHeader: localStorage.getItem('displayName'),
            frozeColumns: 0,
            frozeRows: 0,
            options: { pageSize: 50 },
            pageSettingsNew: { pageSizes: [12, 50, 100, 200], pageCount: 4 },
            height: 510,
            gridSettings: {
                rowHeight: 60,
            },
            showTableSelect: true,

            currentSwitch: false,
            successToastMessage: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            datasetIdfrmTitle: null,
            currentstate: false,
            showTools: false,
            filteredData: [],
            dataSource: [],
            secondLvlFilter: [],
            filteredColumnId: [],
            filteredsortedColumns: [],
            renderTitle: false,
            renderTitle1: false,
            backFromGraph: false,
            scatterCancel: true,
            dateRangEnabled: false,
            aggregateOption: [],
            errorMessage: '',
            searchGrid: '',
            axisDetails: [
                {
                    axisName: 'primaryXAxis',
                    axisAlias: '',
                    useDefaultStyle: true,
                    style: {
                        fontWeight: 'Normal',
                        color: '#000000',
                        size: '10',
                        fontFamily: 'Arial',
                    },
                },
                {
                    axisName: 'primaryYAxis',
                    axisAlias: '',
                    useDefaultStyle: true,
                    style: {
                        fontWeight: 'Normal',
                        color: '#000000',
                        size: '10',
                        fontFamily: 'Arial',
                    },
                },
            ],
            satusHistory: null,
            selectedFiltersHistory: [],
            downloadDoc: false,
            gridFiltersHistory: [], // get grid filters
            selectedGridFilters: [], // set grid filters
            userPortalOptionID: 0,
            filterTypeArray: [],
            filterDescription: '',
            filterName: '',
            filterOptionsHistory: null,
            filterCategory: '',
        };
    },

    mounted() {
        this.totalRows = this.tools.length;

        // this.getPermissions();
        this.pagePermit();
    },
    beforeDestroy() {
        localStorage.removeItem('currentSwitch');
        localStorage.removeItem('openNewTable');
        localStorage.removeItem('hidetableSelect');
        localStorage.removeItem('openXYGraph');
        localStorage.removeItem('FilteredDataExport');
    },
    methods: {
        setFilterOptionsHistory(dataTosave) {
            this.filterOptionsHistory = dataTosave;
        },
        openModel() {
            this.$refs['modal-1'].show();
        },
        SaveFilter() {
            this.filterOptionsHistory.Name = this.filterName;
            this.filterOptionsHistory.Description = this.filterDescription;
            this.filterOptionsHistory.Category = this.filterCategory;
            this.filterOptionsHistory.CreationDate = new Date();
            this.filterOptionsHistory.UserPortalOptionID = this.userPortalOptionID;

            let DatasetFilter = [];
            let distinctField = _.uniqBy(this.gridFiltersHistory, 'field').map(
                (itm) => itm.field
            );

            distinctField.forEach((col) => {
                let filterVal = this.gridFiltersHistory
                    .filter((fil) => fil.field === col)
                    .map((fil) => fil.value)
                    .join('~~');
                let filterType = this.gridFiltersHistory.filter(
                    (fil) => fil.field === col
                )[0].filterType;

                let temp = {
                    ColumnName: col,
                    ColumnNameValues:
                        filterType === 'CheckBox' ? filterVal : null,
                    FilterCondition: this.gridFiltersHistory.filter(
                        (fil) => fil.field === col
                    )[0].operator,
                    FilterPredicate: this.gridFiltersHistory.filter(
                        (fil) => fil.field === col
                    )[0].predicate,
                    FilterValue:
                        filterType === 'Menu'
                            ? this.gridFiltersHistory.filter(
                                  (fil) => fil.field === col
                              )[0].value
                            : null,
                    GridFilterType: filterType,
                    CreationDate: new Date(),
                    IsActive: 1,
                    isGridFilter: true,
                };
                DatasetFilter.push(temp);
            });

            this.filterOptionsHistory.DatasetFilter = [
                ...this.filterOptionsHistory.DatasetFilter,
                ...DatasetFilter,
            ];
            datasetService
                .savefilters(this.filterOptionsHistory)
                .then((res) => {
                    this.$refs['modal-1'].hide();
                })
                .catch((err) => {
                    alert('error');
                });
        },
        handleReset() {
            this.filterDescription = '';
            this.filterName = '';
            this.filterCategory = '';
        },
        forceReset() {
            this.filterDescription = '';
            this.filterName = '';
            this.filterCategory = '';
        },
        closeModal() {
            this.$refs['modal-1'].hide();
        },
        navigateTools() {
            //if the param exists -> its loaded from history table
            //take historyArgs from router params
            //else take the the first item from history
            let historyArgs;
            let tempFilterData = JSON.parse(
                localStorage.getItem('filterHistory')
            );

            if (this.$route.params.args) {
                historyArgs = JSON.parse(this.$route.params.args);
            } else {
                historyArgs = tempFilterData[0];
            }

            tempFilterData.forEach((col) => {
                if (col.uuid === historyArgs.uuid) {
                    let distinctField = _.uniqBy(
                        this.gridFiltersHistory,
                        'field'
                    ).map((itm) => itm.field);

                    col.gridFilters = [...this.gridFiltersHistory];
                }
            });
            localStorage.setItem(
                'filterHistory',
                JSON.stringify(tempFilterData)
            );
        },
        valueAccess(field, data, column) {
            var imagesContext = require.context(
                '../../assets/',
                false,
                /\.svg$/
            );
            let toReturn = '';
            if (data.Tools.includes(1)) {
                toReturn += `<img style="float:left;margin-left:15px" src=${imagesContext(
                    './' + 'dpt_bw_icon' + '.svg'
                )} v-b-tooltip.hover title="Data post">`;
            }
            if (data.Tools.includes(4)) {
                toReturn += `<img style="float:left;margin-left:15px" src=${imagesContext(
                    './' + 'icon_graphing_black' + '.svg'
                )} v-b-tooltip.hover title="Scatter Plot">`;
            }
            if (data.Tools.includes(3)) {
                toReturn += `<img style="float:left;margin-left:15px;width: 32px;" src=${imagesContext(
                    './' + 'icon_contouring_black' + '.svg'
                )} v-b-tooltip.hover title="Contour">`;
            }
            return toReturn;
        },
        freezeHelper(row, col) {
            document.querySelector('#freezecolinput').value = col;
            document.querySelector('#freezerowinput').value = row;
            document.getElementById('freeze-btn').click();
        },
        modalClose() {
            this.$bvModal.hide('modal-screen');
            this.$store.commit('projects/setMapExpandedOff');
        },
        resetHelper(col, row) {
            document.getElementById('reset-btn').click();
        },
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapActions('tools', {
            getTools: 'getTools',
        }),
        ...mapActions('filteroptions', {
            getAllFilterOptions: 'getAllFilterOptions',
        }),
        tableDisplay() {
            this.hidetableSelect = false;
            this.openNewTable = true;
        },
        base64ToArrayBuffer(base64) {
            const binaryString = window.atob(base64); // Comment this if not using base64
            const bytes = new Uint8Array(binaryString.length);
            return bytes.map((byte, i) => binaryString.charCodeAt(i));
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('TableSelectTool')) {
                    vm.showPage = true;
                }
            });
            if (!this.showPage) {
                this.$refs['permission-modal'].showPermissionModal();
            }
        },
        newToolSelectionTab() {
            if (this.$route.name == 'datasetexport') {
                this.showTableSelect = false;
                this.exportDatasettab.push(this.exportDatasetcounter++);
            } else if (this.$route.name == 'TitleBlock') {
                this.showTableSelect = false;
                this.titleBlocktab.push(this.titleBlockcounter++);
            } else if (this.$route.name == 'datapostingtools') {
                this.showTableSelect = false;
                this.tooltab.push(this.toolbarcounter++);
            } else if (this.$route.name == 'chartelement') {
                this.showTableSelect = false;
                this.reportingTab.push(this.reportingTabcounter++);
            } else if (this.$route.name == 'dataExportTab') {
                this.showTableSelect = false;
                this.exportDatasettab.push(this.exportDatasetcounter++);
            } else if (this.$route.name == 'contouringTab') {
                this.showTableSelect = false;
                this.contouringTab.push(this.contouringCounter++);
            }
        },
        generateGraph(value) {
            this.scatterCancel = value;
        },
        showSwitch(value) {
            this.currentSwitchState = value;
        },
        closeTabTitle(value) {
            this.closeThis = value;
        },
        showDataPosting(value) {
            this.DPShow = value;
        },
        showGenerateChart(value) {
            this.GenerateChart = value;
        },
        showTitleBlockAccess(value) {
            this.showTitle = value;
        },
        showDatasetexport(value) {
            this.showDSExport = value;
        },
        pageCenter: function() {
            //this.$refs.grid.ej2Instances.pagerModule.element.align = 'center' ;
            if (this.$refs.grid) {
                this.$refs.grid.ej2Instances.pagerModule.element.align =
                    'center';
            } else if (this.grid) {
                this.grid.pagerModule.element.align = 'center';
            }
        },
        renderPopup(value) {
            this.showPage = value;
            this.$refs['permission-modal'].showPermissionModal();
        },
        getTools() {
            if (localStorage.getItem('toolsdata')) {
                this.toolsData = JSON.parse(localStorage.getItem('toolsdata'));
            }
        },
        dataBound: function() {
            if (this.$refs.grid) {
                this.$refs.grid.ej2Instances.pagerModule.pagerObj.pagerMessageModule.hideMessage();
            } else if (this.grid) {
                if (this.flag) {
                    var grid = this.grid;
                    this.flag = false;
                    var col = grid.getColumnByField(this.filterDialogCol);
                    var colHeader = grid.getColumnHeaderByField(
                        this.filterDialogCol
                    );
                    this.filterDialogCol = '';
                    grid.filterModule.filterDialogOpen(col, colHeader);
                }
            }
        },
        dataBoundGrid: function() {
            this.grid.autoFitColumns();

            this.grid.filterModule.customOperators.dateOperator = [
                { value: 'equal', text: 'Equal' },
                { value: 'greaterthan', text: 'Greater Than' },
                { value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
                { value: 'lessthan', text: 'Less Than' },
                { value: 'lessthanorequal', text: 'Less Than Or Equal' },
                { value: 'notequal', text: 'Not Equal' },
                { value: 'between', text: 'Between' },
            ];
            this.grid.filterModule.customOperators.datetimeOperator = [
                { value: 'equal', text: 'Equal' },
                { value: 'greaterthan', text: 'Greater Than' },
                { value: 'greaterthanorequal', text: 'Greater Than Or Equal' },
                { value: 'lessthan', text: 'Less Than' },
                { value: 'lessthanorequal', text: 'Less Than Or Equal' },
                { value: 'notequal', text: 'Not Equal' },
                { value: 'between', text: 'Between' },
            ];
            if (this.$refs.grid) {
                this.$refs.grid.ej2Instances.pagerModule.pagerObj.pagerMessageModule.hideMessage();
            } else if (this.grid) {
                if (this.flag) {
                    var grid = this.grid;
                    this.flag = false;
                    var col = grid.getColumnByField(this.filterDialogCol);
                    var colHeader = grid.getColumnHeaderByField(
                        this.filterDialogCol
                    );
                    this.filterDialogCol = '';
                    grid.filterModule.filterDialogOpen(col, colHeader);
                }
            }

            this.grid.on('filter-menu-close', function(args) {
                if (
                    args.target &&
                    args.target.parentElement &&
                    args.target.parentElement.classList.contains('e-footer')
                ) {
                    // prevent the filter menu close action when click the footer buttons in dateRangePicker
                    //args.cancel = true;
                }
            });
        },
        alignFilterSortIcon: function(args) {
            var gridinstances = this.grid;
            var headercells = gridinstances.element.querySelectorAll(
                '.e-headercell'
            );
            for (var i = 0; i < headercells.length; i++) {
                var cellstyle = headercells[i]
                    .querySelector('.e-headertext')
                    .getBoundingClientRect();
                var pxc = cellstyle.width;
                //change the filtericon margin
                var filtermenudiv = headercells[i].querySelector(
                    '.e-filtermenudiv.e-icons.e-icon-filter'
                );
                if (
                    filtermenudiv &&
                    headercells[i].classList.contains('e-rightalign') === false
                ) {
                    filtermenudiv.style.position = 'absolute';
                    filtermenudiv.style.left = pxc + 40 + 'px';
                }

                // change the sorticon margin
                var sortfilterdiv = headercells[i].querySelector(
                    '.e-sortfilterdiv.e-icons'
                );
                if (
                    sortfilterdiv &&
                    headercells[i].classList.contains('e-rightalign') === false
                ) {
                    sortfilterdiv.style.position = 'absolute';
                    sortfilterdiv.style.left = pxc + 'px';
                }
            }
        },
        gridFilterData(args) {
            this.showDataset = true;
            localStorage.setItem('headerHide', 0);
            localStorage.setItem('datasetid', args.rowData.DatasetId);
            localStorage.setItem('datasourceId', args.rowData.DataSourceId);
            localStorage.setItem('tablename', args.rowData.DatasetName);
            localStorage.setItem(
                'datasetNameHeader',
                args.rowData.DisplayName
                    ? args.rowData.DisplayName
                    : args.rowData.DatasetAlias
            );
            localStorage.setItem(
                'displayNameHeader',
                args.rowData.DisplayName
                    ? args.rowData.DisplayName
                    : args.rowData.DatasetAlias
            );
            // this.dataset_name = args.rowData.DatasetAlias;
            this.dataset_name = args.rowData.DisplayName;
            this.datasetNameHeader = localStorage.getItem('displayNameHeader');

            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({ name: 'filteroptions' });
                window.open(routeData.href, '_blank');
            } else {
                this.tabs.push(this.dataset_name);
            }
        },
        createDataGrid: function() {
            Grid.Inject(
                Freeze,
                Filter,
                Page,
                Sort,
                Search,
                Toolbar,
                Resize,
                Reorder,
                ExcelExport
            );
            for (var i = 0; i < this.displayFields.length; i++) {
                this.sortOptionsTbl.columns.push({
                    field: this.displayFields[i].field,
                    direction: 'Ascending',
                });
            }
            var vm = this;
            var cnt = 0;
            this.displayFields.forEach((col) => {
                col['SortOrder'] = cnt;
                cnt++;
            });
            vm.displayFields.sort(function(a, b) {
                return (
                    a.SortOrder - b.SortOrder || a.field.localeCompare(b.field)
                );
            });
            vm.displayFields.forEach(function(v) {
                delete v.SortOrder;
            });
            this.grid = new Grid({
                allowSorting: true,
                allowResizing: true,
                allowExcelExport: true,
                selectionSettings: { persistSelection: true },
                filterSettings: this.filterOptions,
                allowFiltering: true,
                class: 'sortingenabled filterGrid',
                dataBound: this.dataBoundGrid,
                actionBegin: this.actionBegin,
                actionComplete: this.actionComplete,
                frozenColumns: this.frozeColumns,
                frozenRows: this.frozeRows,
                pageSettings: this.options,
                height: 520,
                allowReordering: true,
                allowPaging: true,
            });

            document.querySelector('#Grid').innerHTML = '';
            this.grid.appendTo('#Grid');
            this.grid.dataSource = this.filtered1;

            setTimeout(() => {
                this.grid.pageSettings = this.pageSettingsNew;
                this.grid.columns = this.displayFields;
                this.grid.refreshColumns();

                if (!this.selectedGridFilters) return;

                let distinctField = _.uniqBy(
                    this.selectedGridFilters,
                    'field'
                ).map((itm) => itm.field);
                distinctField.forEach((distCol) => {
                    let tempFilterArr = this.selectedGridFilters.filter(
                        (filCol) => filCol.field === distCol
                    );
                    let value = tempFilterArr.map((col) => col.value);
                    var colItem = this.grid.getColumnByField(distCol);
                    colItem.filter = { type: tempFilterArr[0].filterType };

                    this.grid.filterByColumn(
                        tempFilterArr[0].field,
                        tempFilterArr[0].operator,
                        value,
                        tempFilterArr[0].predicate
                    );
                });
            }, 200);
        },
        actionBegin: function(args) {
            if (args.requestType == 'filterchoicerequest') {
                args.filterChoiceCount = this.tableRowCount; // change the filterchoicecount value as you need (the filter check list shown based on this value )
            }
            var col = this.grid.getColumnByField(args.currentFilteringColumn);
            if (
                args.requestType === 'filtering' &&
                args.action != 'clearFilter' &&
                col &&
                (col.type === 'date' || col.type === 'datetime')
            ) {
                var datevalue = this.dateValue;
                if (
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist') &&
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist')
                        .ej2_instances[0].value === 'between'
                ) {
                    args.cancel = true;
                    setTimeout(() => {
                        var vm = this;
                        vm.dateRangEnabled = true;
                        vm.grid.filterByColumn(
                            args.currentFilteringColumn,
                            'greaterthanorequal',
                            datevalue[0]
                        );
                    }, 1000);
                } else {
                    if (
                        document.querySelector(
                            '.e-flm_optrdiv .e-dropdownlist'
                        ) &&
                        document.querySelector('.e-flm_optrdiv .e-dropdownlist')
                            .ej2_instances[0].value !== 'between'
                    ) {
                        args.cancel = true;
                        setTimeout(() => {
                            this.grid.filterByColumn(
                                args.currentFilterObject.field,
                                args.currentFilterObject.operator,
                                args.currentFilterObject.value
                            );
                        });
                    }
                }

                if (this.dateRangEnabled) {
                    this.dateRangEnabled = false;
                    args.columns.push({
                        actualFilterValue: {},
                        actualOperator: {},
                        field: args.currentFilteringColumn,
                        ignoreAccent: false,
                        isForeignKey: false,
                        matchCase: false,
                        operator: 'lessthanorequal',
                        predicate: 'and',
                        uid: this.grid.getColumnByField(
                            args.currentFilteringColumn
                        ).uid,
                        value: datevalue[1],
                    });
                }
            } else if (args.action == 'clearFilter') {
                this.grid.removeFilteredColsByField(args.currentFilterColumn);
            } else {
                if (
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist') &&
                    document.querySelector('.e-flm_optrdiv .e-dropdownlist')
                        .ej2_instances[0].value !== 'between'
                ) {
                    args.cancel = true;
                    setTimeout(() => {
                        this.grid.filterByColumn(
                            args.currentFilterObject.field,
                            args.currentFilterObject.operator,
                            args.currentFilterObject.value
                        );
                    });
                }
            }
        },
        makeExcel() {
            this.$refs.Grid.ej2_instances[0].excelExport();
        },
        makeCsv() {
            this.$refs.Grid.ej2_instances[0].csvExport();
        },
        resForbidden(value) {
            if (value == 'ForbiddenSQLInjection') {
                this.errorMessage = '403 Forbidden';
                this.$refs['error-modal'].show();
                this.reportingTab = [];
                this.step = 0;
            }
        },
        resForbiddenExport(value) {
            if (value == 'ForbiddenSQLInjection') {
                this.errorMessage = '403 Forbidden';
                this.$refs['error-modal'].show();
                this.exportDatasettab = [];
                this.step = 0;
            }
        },
        resForbiddenPosting(value) {
            if (value == 'ForbiddenSQLInjection') {
                this.errorMessage = '403 Forbidden';
                this.$refs['error-modal'].show();
                this.tooltab = [];
                this.step = 0;
            }
        },
        resForbiddenTitle(value) {
            if (value == 'ForbiddenSQLInjection') {
                this.errorMessage = '403 Forbidden';
                this.$refs['error-modal'].show();
                this.tooltab = [];
                this.step = 0;
            }
        },
        dataSetRowSelected(args) {
            this.showReportingMenu = true;
            this.showDataset = true;
            this.dataSetToolsAvailable = args.data.Tools;
            localStorage.setItem('clickedRow', JSON.stringify(args));
            localStorage.setItem('headerHide', 0);
            localStorage.removeItem('layerDetails');
            localStorage.setItem('datasetid', args.data.DatasetId);
            localStorage.setItem('datasourceId', args.data.DataSourceId);
            localStorage.setItem('tablename', args.data.DatasetName);
            localStorage.setItem(
                'datasetName',
                args.data.DisplayName
                    ? args.data.DisplayName
                    : args.data.DatasetAlias
            );
            localStorage.setItem(
                'displayName',
                args.data.DisplayName
                    ? args.data.DisplayName
                    : args.data.DatasetAlias
            );
            localStorage.setItem('legacyProject', args.data.LegacyProjectIds);
            //this.dataset_name = args.data.DatasetAlias;
            this.dataset_name = args.data.DisplayName;
            // this.datasetNameHeader = this.dataset_name;
            if (this.dataset_name != null) {
                //this.datasetNameHeader = this.dataset_name;
                this.datasetNameHeader = this.dataset_name;
            } else {
                this.dataset_name = args.data.DisplayName
                    ? args.data.DisplayName
                    : args.data.DatasetAlias;
                this.datasetNameHeader = this.dataset_name;
            }
            var datasetId = args.data.DatasetId;
            var projectIds = JSON.parse(
                localStorage.getItem('selectedProjects')
            );

            datasetService
                .getFilterOptionsTable(datasetId, projectIds)
                .then((res) => {
                    if (res.data.StatusCode == 500) {
                        if (
                            res.data.Message ==
                                'Legacy field does not exist.' ||
                            res.data.Message ==
                                'Legacy field not linked to project.'
                        ) {
                            this.errorMessage = res.data.Message;
                            // setTimeout(()=>{
                            this.$refs['error-modal'].show();
                        } else {
                            this.failureToastMessage = res.data.Message;
                            this.showFailure = true;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        }
                    } else if (
                        res.data.StatusCode == 200 ||
                        res.status == 200
                    ) {
                        if (this.currentSwitchState) {
                            this.toolsList(
                                localStorage.getItem('projectId'),
                                localStorage.getItem('datasetid')
                            );
                            let routeData = this.$router.resolve({
                                name: 'filteroptions',
                            });
                            window.open(routeData.href, '_blank');
                        } else {
                            this.toolsList(
                                localStorage.getItem('projectId'),
                                localStorage.getItem('datasetid')
                            );
                            this.tabs.push(this.dataset_name);
                            setTimeout(() => {
                                this.step =
                                    this.tooltab.length +
                                    this.reportingTab.length +
                                    this.tabs.length;
                            });
                        }
                    }
                })
                .catch((err) => {
                    this.failureToastMessage = err.response.data.Message;
                    this.showFailure = true;
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                });
        },
        searchiconclick() {
            let search = document.querySelector('#outersearchinput');
            let portal = document.querySelector('#portalbar');
            search.style.width = search.style.width == '3%' ? '27%' : '3%';
            portal.style.width =
                portal.style.width == '94.5%' ? '70.5%' : '94.5%';
            if (search.style.width == '3%') {
                document.querySelector('#outersearchinput').style.borderColor =
                    'transparent';
                document.querySelector(
                    '#outersearchinput .e-input'
                ).style.display = 'none';
            } else {
                document.querySelector('#outersearchinput').style.borderColor =
                    'rgba(0, 0, 0, 0.2)';
                document.querySelector(
                    '#outersearchinput .e-input'
                ).style.display = 'block';
            }
        },
        searchinputenter(args) {
            if (this.hidetableSelect) {
                this.grid.searchModule.search(args.currentTarget.value);
            } else {
                this.$refs.grid.$el.ej2_instances[0].search(
                    args.currentTarget.value
                );
            }
        },
        renderTitleblock() {
            this.$refs.childComponent[0].getTitleBlock();
        },
        redirectScatter(value) {
            setTimeout(() => {
                this.step = 1;
                this.$refs.childComponent[0].getTitleBlock();
            }, 100);
            //this.titleBlocktab = [];
        },
        redirectToScatterTab() {
            this.step = this.tooltab.length + this.reportingTab.length;
        },
        openXYGraph() {
            this.hidetableSelect = true;
            this.openNewTable = false;
            this.showDataset = true;
            this.datasetNameHeader = localStorage.getItem('displayName');
            this.tabs.push(localStorage.getItem('datasetName'));
            this.applyFilter(true);
            this.reportingTab.push(this.reportingTabcounter++);
            this.step = 1;
            this.backFromGraph = true;
            localStorage.removeItem('openXYGraph');
        },
        gridRowData: function(args) {
            var splitVal = args.data.dochyperlink.split('.');
            if (splitVal[1] === 'pdf') {
                const url = 'http://www.africau.edu/images/default/sample.pdf';
                const filename = url
                    .substring(url.lastIndexOf('/') + 1)
                    .split('?')[0];
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
                    a.download = filename; // Set the file name.
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                };
                xhr.open('GET', url);
                xhr.send();
            } else if (splitVal[1] === 'svg') {
                const url =
                    'https://dev.w3.org/SVG/tools/svgweb/samples/svg-files/opensearch.svg';
                const filename = url
                    .substring(url.lastIndexOf('/') + 1)
                    .split('?')[0];
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
                    a.download = filename; // Set the file name.
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                };
                xhr.open('GET', url);
                xhr.send();
            } else if (splitVal[1] === 'txt') {
                const url = 'https://www.w3.org/TR/PNG/iso_8859-1.txt';
                const filename = url
                    .substring(url.lastIndexOf('/') + 1)
                    .split('?')[0];
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
                    a.download = filename; // Set the file name.
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                };
                xhr.open('GET', url);
                xhr.send();
            } else if (splitVal[1] === 'docx') {
                const url =
                    'https://calibre-ebook.com/downloads/demos/demo.docx';
                const filename = url
                    .substring(url.lastIndexOf('/') + 1)
                    .split('?')[0];
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(xhr.response); // xhr.response is a blob
                    a.download = filename; // Set the file name.
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                };
                xhr.open('GET', url);
                xhr.send();
            }
        },
        openOptions() {
            this.visible = true;
            this.showOptions = true;
        },
        hideErrorModal() {
            this.$refs['error-modal'].hide();
        },
        openDynamicGridTable() {
            this.displayFields = [];
            this.datasetNameHeader = localStorage.getItem('displayName');

            datasetService
                .getDataTypeColumn(localStorage.getItem('datasetid'))
                .then((res) => {
                    for (const [key, value] of Object.entries(res.data)) {
                        let foundAlias = this.dataSetColumnAlias.filter(
                            (item) => item.DatasetColumn === key
                        );

                        if (
                            (key.includes('hyperlink') ||
                                key.includes('Hyperlink')) &&
                            !key.includes('Path') &&
                            !key.includes('Source')
                        ) {
                            this.displayFields.push({
                                field: key,
                                headerText: foundAlias[0].DatasetColumnAliasName
                                    ? foundAlias[0].DatasetColumnAliasName
                                    : key,
                                template: this.cTemplate,
                                minWidth: 170,
                                maxWidth: 600,
                            });
                        } else if (
                            key.includes('Date') ||
                            key.includes('date')
                        ) {
                            this.displayFields.push({
                                field: key,
                                headerText: foundAlias[0].DatasetColumnAliasName
                                    ? foundAlias[0].DatasetColumnAliasName
                                    : key,
                                format: this.formatOptions,
                                type: 'datetime',
                                minWidth: 170,
                                maxWidth: 600,
                            });
                        } else {
                            this.displayFields.push({
                                field: key,
                                headerText: foundAlias[0].DatasetColumnAliasName
                                    ? foundAlias[0].DatasetColumnAliasName
                                    : key,
                                type: value,
                                minWidth: 170,
                                maxWidth: 600,
                            });
                        }
                    }
                });
            var dataFilter = JSON.parse(
                localStorage.getItem('filterDataToSend')
            );
            datasetService
                .filteredData(dataFilter.datastring)
                .then((res) => {
                    if (res.data.StatusCode == 500) {
                        if (
                            res.data.Message ==
                                'Legacy field does not exist.' ||
                            res.data.Message ==
                                'Legacy field not linked to project.'
                        ) {
                            this.errorMessage = res.data.Message;
                            this.$refs['error-modal'].show();
                        } else {
                            this.failureToastMessage = res.data.Message;
                            this.showFailure = true;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        }
                    } else if (res.status == 200) {
                        this.filtered1 = res.data;
                        this.dataSource = res.data;
                        if (this.filtered1.length > 0) {
                            for (
                                let i = 0;
                                i < Object.keys(this.filtered1[0]).length;
                                i++
                            ) {
                                if (
                                    Object.keys(this.filtered1[0])[i].includes(
                                        'hyperlinksource'
                                    ) ||
                                    Object.keys(this.filtered1[0])[i].includes(
                                        'hyperlinkpath'
                                    )
                                ) {
                                    let foundAlias = this.dataSetColumnAlias.filter(
                                        (item) =>
                                            item.DatasetColumn ===
                                            Object.keys(this.filtered1[0])[i]
                                    );
                                    this.displayFields.push({
                                        field: Object.keys(this.filtered1[0])[
                                            i
                                        ],
                                        headerText: foundAlias[0]
                                            .DatasetColumnAliasName
                                            ? foundAlias[0]
                                                  .DatasetColumnAliasName
                                            : Object.keys(this.filtered1[0])[i],
                                        type: 'string',
                                        minWidth: 170,
                                        maxWidth: 600,
                                    });
                                }
                            }
                            for (let i = 0; i < this.filtered1.length; i++) {
                                for (
                                    let j = 0;
                                    j < Object.keys(this.filtered1[0]).length;
                                    j++
                                ) {
                                    if (
                                        Object.keys(this.filtered1[0])[
                                            j
                                        ].includes('Date') ||
                                        Object.keys(this.filtered1[0])[
                                            j
                                        ].includes('date')
                                    ) {
                                        this.filtered1[i][
                                            Object.keys(this.filtered1[0])[j]
                                        ] = new Date(
                                            this.filtered1[i][
                                                Object.keys(this.filtered1[0])[
                                                    j
                                                ]
                                            ]
                                        );
                                    }
                                }
                            }
                        }
                        let vm = this;

                        if (vm.filteredData.datastring[0].ColumnName) {
                            (this.searchGrid = ''),
                                (vm.successToastMessage =
                                    'Selected filters have been applied.');
                            vm.showSuccess = true;
                            setTimeout(() => {
                                vm.showSuccess = false;
                            }, 5000);
                        }
                        this.isRender = true;
                        setTimeout(
                            function() {
                                if (this.isRender) {
                                    this.createDataGrid();
                                }
                            }.bind(this),
                            50
                        );
                    }
                })
                .catch((err) => {
                    if (err) {
                        this.failureToastMessage = err.response.data.Message;
                        if (
                            this.failureToastMessage == 'ForbiddenSQLInjection'
                        ) {
                            this.errorMessage = '403 Forbidden';
                            this.$refs['error-modal'].show();
                            setTimeout(() => {
                                this.step =
                                    this.tooltab.length +
                                    this.reportingTab.length +
                                    this.tabs.length;
                                this.hidetableSelect = false;
                                this.openNewTable = true;
                            });
                        } else {
                            this.showFailure = true;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        }
                    }
                });
        },
        appliedFilters(value) {
            this.filteredData = value;
            // this.filteredColumnId = JSON.stringify(localStorage.getItem("columnIds"));
        },
        columnIds(value) {
            this.filteredColumnId = value;
        },
        sortedColumns(value) {
            this.filteredsortedColumns = value;
        },
        applyFilter(value) {
            this.filterLoading = true;
            this.hidetableSelect = value;
            this.step = 0;
            this.openNewTable = false;
            this.isRender = false;
            this.datasetNameHeader = localStorage.getItem('displayName');
            datasetService
                .getDatasetColumnAliasName(localStorage.getItem('datasetid'))
                .then((res) => {
                    if (res) {
                        this.dataSetColumnAlias = res.data;
                        this.openDynamicGridTable();
                    }
                });
        },
        applyFilterNewTab() {
            this.currentSwitch = localStorage.getItem('currentSwitch');
            if (this.currentSwitch) {
                this.hidetableSelect = true;
                this.filterLoading = false;
                this.openNewTable = false;
                this.datasetNameHeader = localStorage.getItem('displayName');
                this.step = 0;
                this.checkedOpt = JSON.parse(localStorage.getItem('chkOpt'));

                datasetService
                    .getDatasetColumnAliasName(
                        localStorage.getItem('datasetid')
                    )
                    .then((res) => {
                        if (res) {
                            this.dataSetColumnAlias = res.data;
                            this.openDynamicGridTable();
                        }
                    });
            }
        },
        appendGridFiltersToStorage() {},
        actionComplete: function(args) {
            if (args.columns) {
                this.gridFiltersHistory = args.columns.map((col) => {
                    let tempType = this.filterTypeArray.find(
                        (itm) => itm.field === col.field
                    );
                    return {
                        field: col.field,
                        operator: col.operator,
                        predicate: col.predicate,
                        value: col.value,
                        filterType: tempType ? tempType.filterType : 'CheckBox', //set the default
                    };
                });
            }
            if (args.requestType === 'filterafteropen') {
                var obj = document.getElementsByClassName(
                    'e-flmenu e-control e-dialog e-lib e-filter-popup e-popup e-popup-open'
                );
                var grid = this.grid;
                var span = document.createElement('div');
                var filterLable = document.createElement('label');
                filterLable.innerText = 'Filter';
                span.appendChild(filterLable);
                var typeImage = new Image();
                var imagesContext = require.context(
                    '../FilterTypeIcon/images/',
                    false,
                    /\.svg$/
                );
                if (args.columnType === 'string') {
                    typeImage.src = imagesContext('./' + 'text' + '.svg');
                } else if (args.columnType === 'number') {
                    typeImage.src = imagesContext('./' + 'numbers' + '.svg');
                } else if (args.columnType === 'datetime') {
                    typeImage.src = imagesContext('./' + 'date' + '.svg');
                }
                typeImage.classList.add('filter-type-img');

                span.appendChild(typeImage);
                span.classList.add('filter');

                var close = document.createElement('span');
                // close.innerHTML ="<img @click='closeFilter' src='../../assets/close_big.svg' />";
                var dlgObject = args.filterModel.dialogObj
                    ? args.filterModel.dialogObj.element
                    : args.filterModel.dlgObj.element;
                if (dlgObject.querySelector('.e-numerictextbox')) {
                    dlgObject.querySelector(
                        '.e-numerictextbox'
                    ).ej2_instances[0].format = '#.#######';
                }
                if (dlgObject.querySelector('.e-autocomplete')) {
                    dlgObject.querySelector(
                        '.e-autocomplete'
                    ).ej2_instances[0].autofill = false;
                }
                if (
                    dlgObject.getElementsByClassName('customclass').length === 0
                ) {
                    var div = document.createElement('div');
                    div.classList.add('customclass');
                    var ul = document.createElement('ul');
                    div.appendChild(ul);
                    div.prepend(close);
                    div.prepend(span);
                    ul.style.width = '230px';
                    var menuItems = [
                        {
                            text: dlgObject.classList.contains(
                                'e-checkboxfilter'
                            )
                                ? 'CheckBox'
                                : 'Menu',
                            items: [{ text: 'CheckBox' }, { text: 'Menu' }],
                        },
                    ];
                    let menuObj = new Menu(
                        {
                            items: menuItems,
                            showItemOnClick: true,
                            select: this.menuSelect.bind(this),
                        },
                        ul
                    );
                    dlgObject.insertBefore(div, dlgObject.firstElementChild);
                }
                if (
                    dlgObject.querySelector('.e-datetimepicker') ||
                    dlgObject.querySelector('.e-datepicker')
                ) {
                    dlgObject.querySelector(
                        '.e-flm_optrdiv .e-dropdownlist'
                    ).ej2_instances[0].select = function(args) {
                        if (args.itemData.value === 'between') {
                            dlgObject.getElementsByClassName(
                                'e-flmenu-valuediv'
                            )[0].style.display = 'none';
                            dlgObject.getElementsByClassName(
                                'customdaterange'
                            )[0].style.display = 'block';
                        } else {
                            dlgObject.getElementsByClassName(
                                'e-flmenu-valuediv'
                            )[0].style.display = 'block';
                            dlgObject.getElementsByClassName(
                                'customdaterange'
                            )[0].style.display = 'none';
                        }

                        // get the min and max value of dateRangePicker
                        var currentpredicate =
                            grid.filterModule.filterModule.filterObj
                                .actualPredicate[args.columnName];
                        var min;
                        var max;
                        if (currentpredicate && currentpredicate.length === 2) {
                            for (var i = 0; i < currentpredicate.length; i++) {
                                if (
                                    currentpredicate[i].operator ===
                                    'greaterthanorequal'
                                ) {
                                    min = currentpredicate[i].value;
                                }
                                if (
                                    currentpredicate[i].operator ===
                                    'lessthanorequal'
                                ) {
                                    max = currentpredicate[i].value;
                                }
                            }
                            // bind the value to the dateRangePicker
                            daterangepicker.value = [min, max];
                            // select the Between operator
                            var flmOptr = dlgObject.querySelector(
                                '.e-flm_optrdiv .e-dropdownlist'
                            ).ej2_instances[0];
                            flmOptr.value = 'between';
                            // hide the datePicker
                            dlgObject.getElementsByClassName(
                                'e-flmenu-valuediv'
                            )[0].style.display = 'none';
                            // show the dateRangePicker
                            div1.style.display = 'block';
                        }
                    };
                    var div1 = document.createElement('div');
                    div1.classList.add('customdaterange');
                    div1.style.display = 'none';
                    var input = document.createElement('input');
                    div1.appendChild(input);
                    input.style.width = '230px';
                    let daterangepicker = new DateRangePicker({
                        //sets the place holder
                        placeholder: 'Select Range',
                        change: this.change,
                    });
                    daterangepicker.appendTo(input);
                    dlgObject
                        .getElementsByClassName('e-flmenu-maindiv')[0]
                        .appendChild(div1);
                    var currentpredicate =
                        grid.filterModule.filterModule.filterObj
                            .actualPredicate[args.columnName];
                    var min;
                    var max;
                    if (currentpredicate && currentpredicate.length === 2) {
                        for (var i = 0; i < currentpredicate.length; i++) {
                            if (
                                currentpredicate[i].operator ===
                                'greaterthanorequal'
                            ) {
                                min = currentpredicate[i].value;
                            }
                            if (
                                currentpredicate[i].operator ===
                                'lessthanorequal'
                            ) {
                                max = currentpredicate[i].value;
                            }
                        }
                        daterangepicker.value = [min, max];
                        var flmOptr = dlgObject.querySelector(
                            '.e-flm_optrdiv .e-dropdownlist'
                        ).ej2_instances[0];
                        flmOptr.value = 'between';
                        dlgObject.getElementsByClassName(
                            'e-flmenu-valuediv'
                        )[0].style.display = 'none';
                        div1.style.display = 'block';
                    }
                }
            }
            if (this.grid.filterSettings.columns.length > 0) {
                this.secondLvlFilter = this.grid.filterSettings.columns.map(
                    (item) => {
                        if (this.filteredColumnId[item.field]) {
                            return {
                                ColumnName: item.field,
                                ColumnNameValues: [],
                                Columnid: this.filteredColumnId[item.field],
                                filter: localStorage.getItem('legacyProject'),
                                filterColName: 'SiteCode',
                                filterCondition: item.operator,
                                filterConditionColName: item.field,
                                filterValue: item.value,
                            };
                        } else {
                            return {
                                ColumnName: item.field,
                                ColumnNameValues: [],
                                Columnid: null,
                                filter: localStorage.getItem('legacyProject'),
                                filterColName: 'SiteCode',
                                filterCondition: item.operator,
                                filterConditionColName: item.field,
                                filterValue: item.value,
                            };
                        }
                    }
                );
            } else {
                this.secondLvlFilter = [];
            }

            if (args.action == 'filter' && args.requestType == 'filtering') {
                this.grid.filterModule.filterModule.closeDialog();
            }
        },

        change: function(e) {
            if (e != undefined && e.value) {
                var grid = document.getElementsByClassName('e-grid')[0]
                    .ej2_instances[0];
                this.dateValue[0] = e.value[0];
                this.dateValue[1] = e.value[1]; //get date values from date range picker
                grid.filterSettings.columns = [
                    {
                        value: this.dateValue[0],
                        operator: 'greaterthanorequal',
                        field: 'CollectionDate',
                        predicate: 'and',
                    },
                    {
                        value: this.dateValue[1],
                        operator: 'lessthanorequal',
                        field: 'CollectionDate',
                        predicate: 'and',
                    },
                ];
            }
        },
        upsert(array, element) {
            const i = array.findIndex(
                (_element) => _element.field === element.field
            );
            if (i > -1) array[i] = element;
            else array.push(element);
        },
        menuSelect: function(args) {
            if (args.item.items.length === 0) {
                var grid = this.grid;
                this.filterDialogCol = grid.filterModule.fltrDlgDetails.field;
                var col = grid.getColumnByField(this.filterDialogCol);

                let tempObj = {
                    field: this.filterDialogCol,
                    filterType: args.item.text,
                };

                this.upsert(this.filterTypeArray, tempObj);

                if (args.item.text === 'Menu') {
                    grid.filterModule.filterModule.closeDialog();

                    this.flag = true;
                    col.filter = {
                        type: 'Menu',
                    };
                    grid.refreshColumns();
                }
                if (args.item.text === 'CheckBox') {
                    this.flag = true;
                    col.filter = {
                        type: 'CheckBox',
                    };
                    grid.filterModule.filterModule.closeDialog();
                    grid.refreshColumns();
                }
            }
        },

        getToolsData(ids) {
            return new Promise((resolve) => {
                datasetService
                    .getTableselectools(ids)
                    .then((res) => {
                        let tools = res.data;
                        if (tools) {
                            for (let i = 0; i < tools.length; i++) {
                                for (let j = 0; j < tools[i].length; j++) {
                                    this.tools.push({
                                        DataSourceId: tools[i][j].DataSourceId,
                                        DatasetId: tools[i][j].DatasetId,
                                        DatasetAlias: tools[i][j].DisplayName
                                            ? tools[i][j].DisplayName
                                            : tools[i][j].DatasetAlias,
                                        DatasetName: tools[i][j].DatasetName,
                                        DatasetDesc: tools[i][j].DatasetDesc,
                                        CreationDate: tools[i][j].CreationDate,
                                        ModifiedDate: tools[i][j].ModifiedDate,
                                        DisplayName:
                                            tools[i][j].DisplayName ||
                                            tools[i][j].DatasetAlias,
                                        Type: tools[i][j].Type,
                                        LegacyProjectIds:
                                            tools[i][j].LegacyProjectIds,
                                        Tools: tools[i][j].Tools,
                                    });
                                }
                            }

                            resolve('resolved');
                        }
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },

        btnclick() {
            this.freezeButton = false;
            this.resetFreezeButton = true;
            let colcount = parseInt(
                document.querySelector('#freezecolinput').value
            );
            let rowcount = parseInt(
                document.querySelector('#freezerowinput').value
            );
            if (
                colcount >= 0 &&
                colcount <= Object.keys(this.displayFields).length - 1
            ) {
                this.grid.frozenColumns = colcount;
            } else {
                //nothing
            }
            if (!this.freeze) {
                if (rowcount >= 0) {
                    this.grid.frozenRows = rowcount;
                } else {
                    this.grid.frozenColumns = 0;
                }
            }
        },
        resetBtnClick() {
            this.freezeButton = true;
            this.freeze = false;
            this.resetFreezeButton = false;
            document.querySelector('#freezecolinput').value = '';
            document.querySelector('#freezerowinput').value = '';
            this.grid.frozenColumns = 0;
            this.grid.frozenRows = 0;
        },
        activateTab(currentTabs) {
            if (currentTabs.length === 2) {
                currentTabs[0].active = false;
                currentTabs[1].active = true;
            }
        },
        selectedTab(newTabIndex) {
            if (newTabIndex) {
                this.datatool = true;
                this.manageHeader = false;
            } else {
                this.datatool = false;
                this.manageHeader = true;
            }
        },

        navigateDataSetComumn() {
            router.push(`/datasetdefinition/edit/datasetcolumns`);
        },
        navigateDataPostingTool() {
            router.push(`/datapostingtool`);
        },
        navigatetoselectedProjects() {
            router.push(`/project/selectedprojects`);
        },
        showReportingTool() {
            this.reportingTool = !this.reportingTool;
        },
        toolsList() {
            commonService
                .getTools(
                    localStorage.getItem('projectId'),
                    localStorage.getItem('datasetid')
                )
                .then((res) => {
                    this.toolsData = res.data;
                    this.toolsData.forEach((element) => {
                        if (
                            element.ToolName == 'Data Posting Tool' &&
                            this.dataSetToolsAvailable.includes(1)
                        ) {
                            this.dataPostingTool = true;
                        }
                        if (
                            element.ToolName == 'Title Block' ||
                            element.ToolName == 'Dataset Export' ||
                            element.ToolName == 'X/Y Scatter Plot'
                        ) {
                            this.reportingToolDisplay = true;
                        }
                        if (element.ToolName == 'Title Block') {
                            this.titleBlockDisplay = true;
                        }
                        element.imagePath =
                            '../../' +
                            element.IconPath +
                            '/' +
                            element.IconName;
                    });
                });
        },

        navigateToTool(tool) {
            this.showDataset = true;
            if (tool === 'Title Block') {
                if (this.showTitle) {
                    this.titleblockTabOpen();
                    // router.push(`/titleblock/${this.dataSetId}');
                } else {
                    this.$refs['permission-modal'].showPermissionModal();
                    this.showPage = this.showTitle;
                }
            }
            if (tool === 'Dataset Export') {
                if (!this.showDSExport) {
                    this.$refs['permission-modal'].showPermissionModal();
                    this.showPage = this.showDSExport;
                } else {
                    this.dataExportTab();
                }
            }
        },
        openTitleBlock: function() {
            this.showDataset = false;
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({ name: 'titleblock' });
                window.open(routeData.href, '_blank');
            } else {
                router.push(`/titleblock`);
            }
        },
        dataExportTab() {
            this.showDataset = true;
            this.getAllFilters();
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({
                    name: 'datasetexport',
                    params: { id: this.projectIds },
                });
                window.open(routeData.href, '_blank');
            } else {
                if (this.datasetExportTab === -1) {
                    this.exportDatasettab.push(this.exportDatasetcounter++);
                    setTimeout(() => {
                        this.step =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length;
                        this.datasetExportTab =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length;
                    });
                } else {
                    this.step =
                        this.tooltab.length +
                        this.reportingTab.length +
                        this.tabs.length +
                        this.exportDatasettab.length;
                }
            }
        },
        titleblockTabOpen() {
            this.getAllFilters();
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({ name: 'TitleBlock' });
                window.open(routeData.href, '_blank');
            } else {
                if (this.titleBlockTabNum === -1) {
                    this.titleBlocktab.push(this.titleBlockcounter++);
                    setTimeout(() => {
                        this.step =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length +
                            this.titleBlocktab.length;
                        this.titleBlockTabNum =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length +
                            this.titleBlocktab.length;
                    });
                } else {
                    this.step =
                        this.tooltab.length +
                        this.reportingTab.length +
                        this.tabs.length +
                        this.exportDatasettab.length +
                        this.titleBlocktab.length;
                }
            }
        },
        currentEditTitleState(value) {
            this.currentstate = value;
        },
        editTitleblockTabOpen() {
            this.getAllFilters();
            if (this.currentstate) {
                let routeData = this.$router.resolve({
                    name: 'TitleBlock',
                    params: { id: localStorage.getItem('datasetid') },
                });
                window.open(routeData.href, '_blank');
            } else {
                if (this.titleBlockTabNum === -1) {
                    this.titleBlocktab.push(this.titleBlockcounter++);
                    this.renderTitle1 = true;
                    setTimeout(() => {
                        this.step =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length +
                            this.titleBlocktab.length;
                        this.titleBlockTabNum =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length +
                            this.titleBlocktab.length;
                    }, 100);
                } else {
                    this.step =
                        this.tooltab.length +
                        this.reportingTab.length +
                        this.tabs.length +
                        this.exportDatasettab.length +
                        this.titleBlocktab.length;
                    this.renderTitle1 = true;
                }
            }
        },
        editXYAxisTabOpen() {
            if (this.editAxisTabNum === -1) {
                this.xyTitleBlocktab.push(1);
                setTimeout(() => {
                    this.step =
                        this.tooltab.length +
                        this.reportingTab.length +
                        this.tabs.length +
                        this.exportDatasettab.length +
                        this.titleBlocktab.length +
                        this.xyTitleBlocktab.length;
                    this.editAxisTabNum =
                        this.tooltab.length +
                        this.reportingTab.length +
                        this.tabs.length +
                        this.exportDatasettab.length +
                        this.titleBlocktab.length +
                        this.xyTitleBlocktab.length;
                }, 100);
            } else {
                this.step =
                    this.tooltab.length +
                    this.reportingTab.length +
                    this.tabs.length +
                    this.exportDatasettab.length +
                    this.titleBlocktab.length +
                    this.xyTitleBlocktab.length;
            }
        },
        clickedState(value, value1) {
            this.aggregateOption = value1;
            localStorage.setItem(
                'aggregateOption',
                JSON.stringify(this.aggregateOption)
            );
        },
        dataToolTab(value) {
            this.getAllFilters();
            if (!value) {
                this.showPage = value;
                this.$refs['permission-modal'].showPermissionModal();
            } else if (value == 'data-post') {
                this.datatool = true;
                if (
                    this.currentSwitchState ||
                    localStorage.getItem('postingStatus') == 'true'
                ) {
                    let routeData = this.$router.resolve({
                        name: 'datapostingtools',
                        params: { id: this.projectIds, tool: this.datatool },
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    if (this.dataPostTab === -1) {
                        this.tooltab.push(this.toolbarcounter++);

                        setTimeout(() => {
                            this.step = this.tooltab.length;
                            this.dataPostTab = this.tooltab.length;
                        }, 100);
                    } else {
                        this.step = this.tooltab.length;
                    }
                }
            } else if (value == 'contour') {
                this.contourtool = true;
                if (
                    this.currentSwitchState ||
                    localStorage.getItem('postingStatus') == 'true'
                ) {
                    let routeData = this.$router.resolve({
                        name: 'contouringtools',
                        params: { id: this.projectIds, tool: this.contourtool },
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    if (this.contouringTabCount === -1) {
                        this.contouringTab.push(1);
                        setTimeout(() => {
                            this.step =
                                this.tooltab.length +
                                this.reportingTab.length +
                                this.tabs.length +
                                this.exportDatasettab.length +
                                this.titleBlocktab.length +
                                this.contouringTab.length;
                            this.contouringTabCount =
                                this.tooltab.length +
                                this.reportingTab.length +
                                this.tabs.length +
                                this.exportDatasettab.length +
                                this.titleBlocktab.length +
                                this.contouringTab.length;
                        });
                    } else {
                        this.step =
                            this.tooltab.length +
                            this.reportingTab.length +
                            this.tabs.length +
                            this.exportDatasettab.length +
                            this.titleBlocktab.length +
                            this.contouringTab.length;
                    }
                }
            } else {
                // this.showPage = DPShow;
                this.$refs['permission-modal'].showPermissionModal();
            }
        },
        getAllFilters() {
            this.firstLvlFilter = JSON.parse(
                localStorage.getItem('filterDataToSend')
            );
            let CumulativeArr = [];

            const arrayHashmap = this.secondLvlFilter.reduce((obj, item) => {
                if (obj[item.ColumnName]) {
                    obj[item.ColumnName].ColumnNameValues.push(
                        item.filterValue
                    );
                    if (obj[item.ColumnName].filterValue != '') {
                        obj[item.ColumnName].ColumnNameValues = [];
                    }
                } else {
                    obj[item.ColumnName] = { ...item };
                    obj[item.ColumnName].ColumnNameValues.push(
                        item.filterValue
                    );

                    if (obj[item.ColumnName].filterCondition == 'notequal') {
                        obj[item.ColumnName].filterCondition = 'notin';
                        obj[item.ColumnName].filterValue = '';
                    } else if (
                        obj[item.ColumnName].filterCondition == 'equal'
                    ) {
                        obj[item.ColumnName].filterCondition = 'in';
                        obj[item.ColumnName].filterValue = '';
                    }
                    if (obj[item.ColumnName].filterValue != '') {
                        obj[item.ColumnName].ColumnNameValues = [];
                    }

                    CumulativeArr.push(obj);
                }
                return obj;
            }, {});

            const mergedArray = Object.values(arrayHashmap);

            if (mergedArray && mergedArray.length > 0) {
                mergedArray.forEach((element) => {
                    this.firstLvlFilter.datastring.push(element);
                });
            }
            localStorage.setItem(
                'gridFilter',
                JSON.stringify(this.firstLvlFilter.datastring)
            );
        },
        openChartElement(GenerateChart) {
            this.showDataset = true;
            this.getAllFilters();
            this.showGenerateChart(GenerateChart);

            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({ name: 'chartelement' });
                window.open(routeData.href, '_blank');
            } else {
                if (!GenerateChart) {
                    this.reportingTab.push(this.reportingTabcounter++);
                    this.showPage = GenerateChart;
                    this.$refs['permission-modal'].showPermissionModal();
                } else {
                    if (this.scatterPlotTab === -1) {
                        this.reportingTab.push(this.reportingTabcounter++);
                        setTimeout(() => {
                            this.step =
                                this.tooltab.length + this.reportingTab.length;
                            this.scatterPlotTab =
                                this.tooltab.length + this.reportingTab.length;
                        });
                    } else {
                        this.step =
                            this.tooltab.length + this.reportingTab.length;
                    }
                }
            }
        },
        openNewTab(datasetname) {
            localStorage.setItem('headerHide', 1);
            localStorage.setItem(
                'datasetName',
                datasetname.item.DisplayName
                    ? datasetname.item.DisplayName
                    : datasetname.item.DatasetAlias
            );
            localStorage.setItem('datasetid', datasetname.item.DatasetId);
            localStorage.setItem('datasourceId', datasetname.item.DataSourceId);
            localStorage.setItem('tablename', datasetname.item.DatasetName);
            let routeData = this.$router.resolve({ name: 'filteroptions' });
            window.open(routeData.href, '_blank');
        },
        closeTab(x) {
            this.showDataset = true;
            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i] === x) {
                    this.tabs.splice(i, 1);
                }
            }
            this.step = 0;
        },
        closeTabDataSetExport(z) {
            this.showDataset = true;
            this.datasetExportTab = -1;
            for (let i = 0; i < this.exportDatasettab.length; i++) {
                if (this.exportDatasettab[i] === z) {
                    this.exportDatasettab.splice(i, 1);
                }
            }
            this.step = 0;
        },
        closeTabTitleBlock(b) {
            this.showDataset = true;
            this.titleBlockTabNum = -1;
            for (let i = 0; i < this.titleBlocktab.length; i++) {
                if (this.titleBlocktab[i] === b) {
                    this.titleBlocktab.splice(i, 1);
                }
            }
            this.step = 0;
        },
        closeXYAxistab(b) {
            this.showDataset = true;
            this.editAxisTabNum = -1;
            for (let i = 0; i < this.xyTitleBlocktab.length; i++) {
                if (this.xyTitleBlocktab[i] === b) {
                    this.xyTitleBlocktab.splice(i, 1);
                }
            }
            this.step = 0;
        },
        closeTabD(y) {
            this.showDataset = true;
            this.dataPostTab = -1;
            // this.datatool = false;
            for (let j = 0; j < this.tooltab.length; j++) {
                if (this.tooltab[j] === y) {
                    this.tooltab.splice(j, 1);
                }
            }
            this.step = 0;
        },
        closeTabScatter(z) {
            this.scatterPlotTab = -1;
            this.showDataset = true;
            for (let k = 0; k < this.reportingTab.length; k++) {
                if (this.reportingTab[k] === z) {
                    this.reportingTab.splice(k, 1);
                }
            }
            this.backFromGraph = false;
            this.step = 0;
        },
        closeTabContour(c) {
            this.showDataset = true;
            this.contouringTabCount = -1;
            for (let m = 0; m < this.contouringTab.length; m++) {
                if (this.contouringTab[m] === c) {
                    this.contouringTab.splice(m, 1);
                }
            }
            this.step = 0;
        },
        closeSuccessToast() {
            this.successToastFilter = false;
        },
        showDataTab() {
            this.showDataset = true;
            this.datatool = true;
        },
        showThisTab(t) {
            this.datasetNameHeader = t;
            this.showDataset = true;
            PortalBarTool.methods.forceReset();
        },
        navigatetoDataset() {
            // this.closeTab(this.dataset_name);
            this.tabs = [];
            this.reportingTab = [];
            this.tooltab = [];
            this.titleBlocktab = [];
            this.exportDatasettab = [];
            this.contouringTab = [];
            this.hidetableSelect = false;
            this.openNewTable = true;
            this.showDataset = false;
            this.isRender = !this.isRender;
            this.filterLoading = false;
            PortalBarTool.methods.forceReset();
            this.scatterPlotTab = -1;
            this.titleBlockTabNum = -1;
            this.datasetExportTab = -1;
            this.dataPostTab = -1;
            this.scatterPlotTab = -1;
            this.contouringTabCount = -1;
        },
        preventNegative(e) {
            setTimeout(() => {
                if (
                    !(
                        (e.keyCode > 95 && e.keyCode < 106) ||
                        (e.keyCode > 47 && e.keyCode < 58) ||
                        e.keyCode === 8
                    )
                ) {
                    return false;
                }
                return true;
            }, 2000);
        },
    },
    computed: {
        ...mapState({
            filteroptions: (state) => state.getAllFilterOptions.filteroptions,
            projectMapLayers: (state) => state.projectLayers.projects,
        }),
        tableRowCount() {
            if (this.filtered1) {
                return this.filtered1.length;
            } else {
                return 0;
            }
        },
        selectedProjectsList() {
            let projectsList = '';
            this.projectMapLayers.forEach((item, index) => {
                var project = item.ProjectName.replaceAll('"', '');
                if (index >= 1) {
                    projectsList = projectsList + ', ' + project;
                } else {
                    projectsList = project;
                }
            });
            return projectsList;
        },
    },
};
</script>
<style lang="scss" scoped>
.fixed_col {
    position: absolute;
    top: auto;
    right: 10%;
    width: 100px !important;
    border-bottom: 0px !important;
}

//search design code
.e-search-icon::before {
    content: '\e993';
    top: -11px !important;
    left: 10px;
}

.scatter-close {
    top: 36px !important;
    /* right: 0px; */
    left: 275px;
    position: absolute;
}

#outersearchinput {
    border-color: white;
    width: 3%;
    height: 31px;
}

#outersearchinput .e-input {
    display: none;
    // padding:20px 10px;
}

#portalbar {
    width: 70% !important;
    border-radius: 4px;
}
</style>
<style>
@import url(https://cdn.syncfusion.com/ej2/material.css);

.filterlink {
    cursor: pointer;
}

a.router-link-active {
    color: black !important;
}

.table-data .dropdown-menu.show {
    display: block;
    margin-left: 23px;
}

.e-grid .e-content {
    background-color: #fff;
    /* margin-left: 13px !important; */
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
    flex-basis: auto !important;
}

.blue-color a {
    padding: 8px 8px 8px 3px;
}

::marker {
    text-decoration: none !important;
}

/* css for switch */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.date_width {
    width: 202px !important;
}

.data_width {
    width: 118px !important;
}

.reportingTool {
    width: 279px;
    min-height: auto !important;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
    font-family: var(--ff) !important;
}

.reportingTool .report-heading {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
}

.titleBlock {
    margin: 10px 15px;
    height: 48px;
    cursor: pointer;
}

.titleBlock small {
    border: 2px solid transparent;
    height: 48px;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
    opacity: 1;
}

.titleBlock small:hover {
    border: 3px solid #b034a1;
    height: 48px;
}

.titleBlock span {
    background-color: #b034a1;
    height: 48px;
    width: 48px;
    text-align: center;
    padding: 10px;
}

.graphing {
    margin: 10px 15px;
    height: auto;
    border: 3px solid transparent !important;
}

.graphing small {
    border: 2px solid transparent;
    height: 48px;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
    opacity: 1;
}

.graphingBorder:hover {
    border: 3px solid #8456b0 !important;
}

/* .graphing:hover {
  border: 2px solid #8456b0 !important; */
/* height: 30px; */
/* }  */
.graphing span {
    background-color: #8456b0;
    height: 48px;
    width: 48px;
    text-align: center;
    /* padding: 10px; */
}

.dataset {
    margin: 10px 15px;
    height: 48px;
}

.dataset small {
    border: 2px solid transparent;
    height: 48px;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
    opacity: 1;
}

.dataset small:hover {
    border: 3px solid #5878be;
    height: 48px;
}

.dataset span {
    background-color: #5878be;
    height: 48px;
    width: 48px;
    text-align: center;
    padding: 10px;
}

.contouring {
    margin: 10px 15px;
    height: 48px;
}

.contouring {
    margin: 10px 15px;
    height: 48px;
}

.contouring small {
    border: 2px solid transparent;
    height: 48px;
    font-size: 16px;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
    opacity: 1;
}

.contouring small:hover {
    border: 3px solid #3296ca;
    height: 48px;
}

.mapToolAttribute {
    width: 225px;
    height: 175px;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
}

.mapToolAttribute h6 {
    color: #006395;
    font-weight: bold;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 20px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.my-custom-floating-label {
    margin-bottom: 20px;
    display: inline-flex;
}

.normal_input {
    height: 48px !important;
}

.textarea_input {
    height: 95px !important;
}

.card {
    border: none !important;
    background-color: transparent !important;
}

.nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
    font-size: 18px;
    font-weight: normal;
    /* margin-bottom: 0.5rem; */
    margin-top: 0;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: transparent !important;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    /* width:200px;*/
    font-size: 18px;
    font-weight: 500;
    /* margin-bottom: 0.5rem; */
    margin-top: 0;
}

.switchlabel {
    color: #161616;
    font-size: 14px;
    margin-top: 6px;
    float: right;
}

.last_col {
    width: 120px;
    position: absolute;
    top: auto;
    border-bottom: 0px !important;
}

th.last_col {
    border-bottom: 3px solid black !important;
    border-top: 0px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    height: 47px;
}

input.searchinput {
    background: url('../../assets/search-outline.svg') no-repeat !important;
    border: none;

    margin-top: 8px;
}

ul li {
    list-style: none;
    font-size: 80%;
    font-weight: 400;
}

.icon {
    /* border:1px solid red; */
    /* width: 175px; */
    text-align: right;
    /* margin-top: -25px; */
    z-index: 99999999;
}

.sortingenabled {
    border: none;
}

.filterGrid td.e-rowcell:nth-child(6) {
    color: blue !important;
    text-decoration: underline;
    cursor: pointer;
}

.e-grid .e-edit::before {
    content: url('../../assets/edit.svg') !important;
}

.e-grid .e-filter::before {
    content: url('../../assets/filter.svg') !important;
    left: 30px !important;
}

.e-grid .e-delete::before {
    content: url('../../assets/close_icon.svg') !important;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: White !important;
    border: none !important;
    text-align: center !important;
}

.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
    background-color: #f5f5f5 !important;
}

.e-input-group .e-input-group-icon {
    color: #006395 !important;
}

.e-input-group .e-input-group-icon:hover {
    /* color: #fff!important; */
    background-color: transparent !important;
}

.e-grid .e-gridcontent tr td:first-child,
.e-grid .e-gridcontent tr td:last-child {
    text-align: left !important;
}

#collapse-1 li {
    padding: 0.5rem 0.5rem 0.5rem 4rem;
    cursor: pointer;
    font-size: 16px;
    color: #000000de !important;
}

#collapse-1 li:hover {
    background: rgba(234, 234, 234, 0.5);
}

#collapse-1 .card-body {
    padding: 0;
}

.reportingTool .align-items-cente {
    border: 2px solid transparent;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
    border-color: #000 !important;
    background-color: #000 !important;
    border-radius: 0 !important;
}

.e-checkbox-wrapper .e-frame.e-stop,
.e-css.e-checkbox-wrapper .e-frame.e-stop {
    color: transparent !important;
}

.e-btn.e-flat.e-primary {
    background-color: #006395 !important;
    padding: 11px 10px !important;
    border-radius: 3px;
    border: 0px !important;
    color: #fff !important;
    text-transform: capitalize;
}

.e-dialog .e-btn {
    color: #006395 !important;
    text-transform: capitalize;
}

.e-grid .e-dialog.e-checkboxfilter {
    box-shadow: 0px 3px 6px #00000029 !important;
    -webkit-box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
}

.e-dialog .e-footer-content {
    text-align: left;
}

.e-checkbox-wrapper .e-frame,
.e-css.e-checkbox-wrapper .e-frame {
    border: 1px solid #000;
    border-radius: 0px;
}

.e-input-group:not(.e-disabled):not(.e-float-icon-left)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after {
    background-color: #000 !important;
}

.e-grid .e-checkboxlist {
    scrollbar-width: thin;
    scrollbar-color: #626262 #c6c6c6;
}

.e-grid .e-checkboxlist:-webkit-scrollbar {
    width: 3px;
}

.e-grid .e-checkboxlist:-webkit-scrollbar-track {
    background: transparent;
}

.e-grid .e-checkboxlist:-webkit-scrollbar-thumb {
    background-color: #000;
    border-radius: 6px;
    border: 3px solid transparent;
}

/* .filterGrid td.e-rowcell:nth-child(6){
  color:#fff!important;
} */

.graphingIcon {
    position: absolute;
    right: 25px;
}

.graphingIcon img {
    margin-top: 16px;
    margin-right: 11px;
}

.postingIcon {
    margin-top: 8px;
}

.toolReport {
    width: 192px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #1d1d1d;
}

#collapse-1 ul li {
    height: 42px;
}

.e-grid .e-row.e-altrow {
    background: rgba(234, 234, 234, 0.5);
}

.e-grid .e-row {
    background: #fff;
}

.cancel-link {
    float: right !important;
    margin-top: -107px !important;
    margin-right: 120px !important;
    z-index: 100;
    position: relative;
}

.cancel-link-chart {
    float: right !important;
    margin-top: -88px !important;
    margin-right: 175px !important;
    z-index: 100;
    position: relative;
}

#spinner {
    width: 900px;
    height: 500px;
}

.e-checkboxfilter .e-checkboxlist div:not(:first-child) {
    margin-left: 20px;
}

.e-dialog.e-checkboxfilter .e-checkboxfiltertext {
    width: 145px;
}

#grid-column89-flmdlg {
    background-color: #000 !important;
}

.e-grid .e-flmenu .e-popup-open {
    background-color: #000 !important;
}

.model_style {
    max-width: 100% !important;
}

.flt-tbl .e-pager .e-pagesizes {
    bottom: 42px !important;
}

.e-pager div.e-parentmsgbar {
    top: -50px !important;
    padding-top: 0 !important;
}

.e-pager .e-pagerconstant {
    margin: 0 0 11px 0 !important;
}

.e-input-group.e-control-wrapper.e-ddl .e-input[readonly],
.e-float.input.e-control-wrapper.e-ddl input[readonly] {
    text-align: right;
    position: relative;
    top: 4px;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon,
.e-input-group.e-disabled.e-ddl .e-control.e-dropdownlist ~ .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-disabled.e-ddl .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-ddl .e-input-group-icon {
    top: 3px;
}

input.e-input,
.e-input-group input.e-input,
.e-input-group.e-control-wrapper input.e-input,
textarea.e-input,
.e-input-group textarea.e-input,
.e-input-group.e-control-wrapper textarea.e-input {
    padding: 0 !important;
}

.e-control.e-dropdownlist .e-input-group.e-ddl .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-disabled.e-ddl .e-input-group-icon,
.e-input-group.e-disabled.e-ddl .e-control.e-dropdownlist ~ .e-input-group-icon,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon {
    padding: 0px !important;
}

.e-grid .e-icon-filter.e-filtered::before {
    content: url('../../assets/filter-fill.svg') !important;
    left: 30px !important;
}

.flt-tbl .e-grid .e-icon-filter::before {
    left: 0px !important;
}

.flt-tbl .e-grid .e-icon-ascending::before {
    left: 55px !important;
}

.flt-tbl .e-grid .e-icon-descending::before {
    left: 55px !important;
}

.e-grid .e-filtered::before {
    color: #006395;
}

/* .flt-tbl .e-pager .e-pagerdropdown {
    width: 43px !important;
} */

.filter-type-img {
    position: relative;
    float: right;
    margin-right: 17px;
}

.iconColor {
    color: black !important;
    margin-left: 10px;
    margin-right: 3px;
    margin-bottom: 2px;
}

.iconColor:hover,
.iconColor:active {
    color: #006395 !important;
}

div[id^='ej2_dropdownlist_'] {
    width: 80px !important;
}

.visibilityIcon {
    position: absolute;
    left: 46.5%;
    top: 400px;
    z-index: 2;
}

.visibilityText {
    position: absolute;
    left: 44%;
    top: 430px;
    z-index: 2;
}

.Blob {
    color: purple !important;
}

.NetworkDrive {
    color: purple !important;
}

.Web {
    color: #006395 !important;
}

.rightAdjustment {
    position: relative;
    float: right;
}

.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}

.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
    cursor: pointer;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #ffffff;
}

.assignedApply,
.cancelButton {
    padding: 14px 23.46px;
}

.cancelButton,
.cancelButton:focus,
.cancelButton:active,
.cancelButton:hover {
    background: transparent;
    color: #006395 !important;
    border: none;
    float: right;
    box-shadow: none;
    cursor: pointer;
    background-color: transparent !important;
}

.btn-to-top {
    width: 60px;
    height: 60px;
    padding: 10px 16px;
    border-radius: 50%;
    font-size: 22px;
    line-height: 22px;
}
</style>
