<template>
    <div id="vector-sidebar">
        <div id="vector-header">
            <span
                id="latitude-input"
                class="form-floating custom-form-floating inline-input"
            >
                <input
                    type="float"
                    class="form-control custom-floating-label"
                    :class="{
                        'is-invalid': !validLatitude,
                        'is-valid': validLatitude,
                    }"
                    id="floatingInput"
                    placeholder="Latitude"
                    v-model="start_point.Latitude"
                    aria-describedby="start-point-lat-feedback"
                    @input="update_start_point"
                />
                <label for="floatingInput">Latitude</label>
                <!-- <span v-show="!validLatitude" class="invalid-feedback">
                Value must be between -90 and 90.</span
            > -->
            </span>
            <span
                id="longitude-input"
                class="form-floating custom-form-floating inline-input"
            >
                <input
                    type="number"
                    class="form-control custom-floating-label"
                    :class="{
                        'is-invalid': !validLongitude,
                        'is-valid': validLongitude,
                    }"
                    id="floatingInput"
                    placeholder="Longitude"
                    v-model="start_point.Longitude"
                    aria-describedby="start-point-lon-feedback"
                    @input="update_start_point"
                />
                <label for="floatingInput">Longitude</label>
                <!-- <span v-show="!validLongitude" class="invalid-feedback">
                Value must be between -90 and 90.</span
            > -->
            </span>
            <button
                id="select-start-btn"
                class="vector-btn"
                @click="select_start_point"
                :class="
                    activeMapTool == 'vectorStartPoint'
                        ? 'btn-selected'
                        : 'btn-unselected'
                "
            >
                Select point on map
            </button>
            <span class="form-floating custom-form-floating">
                <input
                    type="number"
                    class="form-control custom-floating-label"
                    :class="{
                        'is-invalid': planning_time <= 0,
                        'is-valid': planning_time > 0,
                    }"
                    id="floatingInput"
                    placeholder="Hours"
                    aria-describedby="planning-time-feedback"
                    v-model="planning_time"
                    @input="update_planning_time"
                />
                <label for="floatingInput">Planning hours</label>
                <!-- <span v-show="planning_time <= 0" class="invalid-feedback">
                Value must be greater than 0.
            </span> -->
            </span>
            <div v-if="all_types_valid" id="endpoint" class="header-msg">
                Endpoint:
                {{ cumulative_vector.end_point.y.toFixed(6) }},
                {{ cumulative_vector.end_point.x.toFixed(6) }}
            </div>
            <div v-else id="validation-error-msg" class="header-msg error-msg">
                Repeated vector types are not allowed.
            </div>
        </div>
        <div id="vector-list">
            <div v-for="(vector, index) in vectors" :key="index">
                <div
                    class="vector-item"
                    :class="{ 'red-outline': !vector.type_is_valid }"
                >
                    <div class="vector-item-header">
                        <h4>Vector {{ index + 1 }}</h4>
                    </div>
                    <div class="vector-interacts">
                        <div class="row">
                            <div class="col-2">
                                <button
                                    title="Move vector up"
                                    class="nav-buttons"
                                    @click="move_vector_up(index)"
                                    :disabled="index == 0"
                                    :class="{
                                        disabled: index == 0,
                                    }"
                                >
                                    <img src="@/assets/arrow-up.png" />
                                </button>
                            </div>
                            <div class="col-10 vector-inputs">
                                <div class="form-floating custom-form-floating">
                                    <select
                                        id="vector-type"
                                        class="form-select"
                                        :class="{
                                            'is-invalid': !vector.type_is_valid,
                                            'is-valid': vector.type_is_valid,
                                        }"
                                        v-model="vector.type"
                                        @change="validate_vector_type"
                                    >
                                        <option
                                            v-for="vector_type in vector_types"
                                            :key="vector_type"
                                            :value="vector_type"
                                            >{{ vector_type }}</option
                                        >
                                    </select>
                                    <label for="vector-type">Vector type</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <button
                                    title="Delete vector"
                                    class="nav-buttons delete-btn"
                                    @click="remove_vector(index)"
                                >
                                    <img src="@/assets/delete.svg" />
                                </button>
                            </div>
                            <div class="col-10 vector-inputs">
                                <span
                                    class="form-floating custom-form-floating inline-input"
                                >
                                    <select
                                        id="vector-direction"
                                        class="form-select"
                                        v-model="vector.direction"
                                        @change="
                                            set_vector_direction(
                                                vector,
                                                index,
                                                $event.target.value
                                            )
                                        "
                                    >
                                        <option
                                            v-for="direction in Object.keys(
                                                vector_directions
                                            )"
                                            :key="direction"
                                            :value="direction"
                                        >
                                            {{ direction }}
                                        </option>
                                    </select>
                                    <label for="vector-direction"
                                        >Direction</label
                                    >
                                </span>
                                <span
                                    id="degrees-input"
                                    class="form-floating custom-form-floating inline-input"
                                >
                                    <input
                                        :ref="'vectorDegreesInput-' + index"
                                        value="0"
                                        type="number"
                                        class="form-control custom-floating-label"
                                        :class="{
                                            disabled: !vector.show_direction_input,
                                        }"
                                        id="floatingInput"
                                        placeholder="Degrees"
                                        :disabled="!vector.show_direction_input"
                                        @input="
                                            set_vector_degrees(
                                                vector,
                                                index,
                                                $event.target.value
                                            )
                                        "
                                    />
                                    <label
                                        for="floatingInput"
                                        :class="{
                                            disabled: !vector.show_direction_input,
                                        }"
                                        >Degrees</label
                                    >
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <button
                                    title="Move vector down"
                                    class="nav-buttons"
                                    @click="move_vector_down(index)"
                                    :disabled="index == vectors.length - 1"
                                    :class="{
                                        disabled: index == vectors.length - 1,
                                    }"
                                >
                                    <img src="@/assets/arrow-down.png" />
                                </button>
                            </div>
                            <div class="col-10 vector-inputs">
                                <span
                                    class="form-floating inline-input custom-form-floating"
                                >
                                    <input
                                        type="number"
                                        class="form-control custom-floating-label"
                                        id="vector-speed"
                                        placeholder="Velocity"
                                        v-model="vector.velocity"
                                        @input="
                                            update_vector_velocity(
                                                index,
                                                $event.target.value
                                            )
                                        "
                                    />
                                    <label for="floatingInput">Velocity</label>
                                </span>
                                <div
                                    id="vector-speed-unit"
                                    class="form-floating inline-input custom-form-floating"
                                >
                                    <select
                                        class="form-select"
                                        v-model="vector.speed_unit"
                                        @change="
                                            update_vector_velocity_units(
                                                index,
                                                $event.target.value
                                            )
                                        "
                                    >
                                        <option
                                            v-for="unit in vector_units"
                                            :key="unit"
                                            :value="unit"
                                            >{{ unit }}</option
                                        >
                                    </select>
                                    <label for="vector-direction"
                                        >Velocity units</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="vector-footer">
            <span
                id="remove-all-vectors"
                class="clear-text"
                @click="remove_all_vectors"
            >
                Remove All Vectors
            </span>
            <button id="add-vector" class="vector-btn" @click="add_new_vector">
                Add Vector
            </button>
        </div>
    </div>
</template>

<script>
import { Point, Line, Vector } from '@/utilities/geometry.js';
import { mapState } from 'vuex';
import { spatialFunctions } from '@/utilities/spatialFunctions';

export default {
    name: 'VectorPlanning',
    props: {},
    components: {},
    data() {
        return {
            planning_time: 1,
            vector_types: ['Wind', 'Current', 'Tide', 'Other'],
            vector_directions: {
                N: 0,
                NE: 45,
                E: 90,
                SE: 135,
                S: 180,
                SW: 225,
                W: 270,
                NW: 315,
                Degrees: -1,
            },
            vector_units: ['m/s', 'knots', 'ft/s', 'km/h', 'mph'],

            all_types_valid: true,
        };
    },
    created() {
        // set the starting point to the project's location until a new value is entered
        this.start_point.Latitude = this.$store.state.projects.selectedProjects[0].Latitude.toFixed(
            6
        );
        this.start_point.Longitude = this.$store.state.projects.selectedProjects[0].Longitude.toFixed(
            6
        );
        this.$store.commit(
            'emergencyManagement/setCumulativeVector',
            new Line(
                new Point(
                    this.start_point.Longitude,
                    this.start_point.Latitude
                ),
                new Point(this.start_point.Longitude, this.start_point.Latitude)
            )
        );
    },
    methods: {
        convert_to_meters_per_second(value, unit) {
            if (unit == 'm/s') {
                return value;
            } else if (unit == 'knots') {
                return value * 0.514444;
            } else if (unit == 'ft/s') {
                return value * 0.3048;
            } else if (unit == 'km/h') {
                return value * 0.277778;
            } else if (unit == 'mph') {
                return value * 0.44704;
            }
        },
        remove_all_vectors() {
            this.$store.commit('emergencyManagement/setVectors', []);
            this.$store.commit(
                'emergencyManagement/setCumulativeVector',
                new Line(
                    new Point(
                        this.start_point.Longitude,
                        this.start_point.Latitude
                    ),
                    new Point(
                        this.start_point.Longitude,
                        this.start_point.Latitude
                    )
                )
            );
        },
        add_new_vector() {
            var new_vector;
            if (this.vectors.length == 0) {
                new_vector = new Vector(
                    new Point(
                        this.start_point.Longitude,
                        this.start_point.Latitude
                    ),
                    1,
                    this.vector_units[0],
                    this.planning_time,
                    0,
                    this.vector_types[0]
                );
            } else {
                let previous_vector = this.vectors.at(-1);
                new_vector = new Vector(
                    new Point(
                        previous_vector.end_point.x,
                        previous_vector.end_point.y
                    ),
                    1,
                    this.vector_units[0],
                    this.planning_time,
                    0,
                    this.vector_types[0]
                );
            }
            this.vectors.push(new_vector);
            this.cumulative_vector.end_point = new Point(
                new_vector.end_point.x,
                new_vector.end_point.y
            );
            this.validate_vector_type();
        },
        update_next_vectors(start_idx) {
            let idx = start_idx;
            while (idx < this.vectors.length - 1) {
                let next_vector = this.vectors[idx + 1];
                next_vector.start_point = new Point(
                    this.vectors[idx].end_point.x,
                    this.vectors[idx].end_point.y
                );
                idx++;
            }
            if (this.vectors.length > 0) {
                this.cumulative_vector.end_point = new Point(
                    this.vectors.at(-1).end_point.x,
                    this.vectors.at(-1).end_point.y
                );
            } else {
                this.cumulative_vector.end_point = new Point(
                    this.start_point.Longitude,
                    this.start_point.Latitude
                );
            }
        },
        set_vector_direction(vector, idx, direction) {
            if (direction == 'Degrees') {
                vector.show_direction_input = true;
            } else {
                vector.show_direction_input = false;
                vector.set_angle(this.vector_directions[direction]);
                this.$refs[
                    'vectorDegreesInput-' + idx
                ][0].value = this.vector_directions[direction];
            }
            this.update_next_vectors(idx);
        },
        set_vector_degrees(vector, idx, direction) {
            vector.set_angle(direction);
            this.update_next_vectors(idx);
        },
        update_vector_velocity(idx, value) {
            this.vectors[idx].velocity = value;
            this.update_next_vectors(idx);
        },
        update_vector_velocity_units(idx, unit) {
            this.vectors[idx].speed_unit = unit;
            this.update_next_vectors(idx);
        },
        update_planning_time() {
            this.vectors.forEach((vector) => {
                vector.hours = this.planning_time;
            });
            this.update_next_vectors(0);
        },
        // update the start point of the cumulative vector and all the vectors
        update_start_point() {
            let new_start_point = new Point(
                this.start_point.Longitude,
                this.start_point.Latitude
            );
            if (this.vectors.length > 0) {
                this.vectors[0].start_point = new_start_point;
            }
            this.cumulative_vector.start_point = new_start_point;
            this.update_next_vectors(0);
        },
        // move a vector from one index to another
        move_vector(arr, fromIndex, toIndex) {
            var element = arr.splice(fromIndex, 1)[0];
            arr.splice(toIndex, 0, element);
            this.$store.commit('emergencyManagement/setVectors', arr);
            // when we re-order, reset all the start points
            this.update_start_point();
            this.validate_vector_type();
        },
        // wrapper for move_vector to move the vector up - earlier in the list
        move_vector_up(index) {
            if (index > 0) {
                this.move_vector(this.vectors, index, index - 1);
            }
        },
        // wrapper for move_vector to move the vector down - later in the list
        move_vector_down(index) {
            if (index < this.vectors.length - 1) {
                this.move_vector(this.vectors, index, index + 1);
            }
        },
        // remove a vector from the list
        remove_vector(index) {
            this.vectors.splice(index, 1);
            this.$store.commit('emergencyManagement/setVectors', this.vectors);
            this.update_start_point();
            this.validate_vector_type();
        },
        select_start_point() {
            this.$store.dispatch('oneMap/setMapTool', 'vectorStartPoint');
        },
        handleMouseClick(lat, lng) {
            if (this.activeMapTool == 'vectorStartPoint') {
                this.$store.dispatch('oneMap/clearMapTool');
                this.start_point.Latitude = parseFloat(lat).toFixed(6);
                this.start_point.Longitude = parseFloat(lng).toFixed(6);
                this.update_start_point();
            }
        },
        validate_vector_type() {
            let vector_type_count = {};
            let invalid_count = 0;
            this.vector_types.forEach((vector_type) => {
                vector_type_count[vector_type] = 0;
            });
            this.vectors.forEach((vector) => {
                vector_type_count[vector.type] += 1;
                if (
                    vector_type_count[vector.type] > 1 &&
                    vector.type !== 'Other'
                ) {
                    vector.type_is_valid = false;
                    invalid_count += 1;
                } else {
                    vector.type_is_valid = true;
                }
            });
            if (invalid_count) {
                this.all_types_valid = false;
            } else {
                this.all_types_valid = true;
            }
        },
    },
    computed: {
        ...mapState('emergencyManagement', {
            vectors: 'vectors',
            start_point: 'vector_start_point',
            cumulative_vector: 'cumulative_vector',
        }),
        ...mapState('oneMap', ['clickCoords', 'activeMapTool']),
        validLatitude() {
            return (
                this.start_point.Latitude >= -90 &&
                this.start_point.Latitude <= 90
            );
        },
        validLongitude() {
            return (
                this.start_point.Longitude >= -180 &&
                this.start_point.Longitude <= 180
            );
        },
    },
    watch: {
        // when the coordinates are clicked, send the coordinates to the handler
        async clickCoords(newCoords) {
            let [lat, lng] = spatialFunctions.validateCoordinates({
                latlng: newCoords,
            });
            await this.handleMouseClick(lat, lng);
        },
    },
};
</script>

<style scoped>
@import '../../../../assets/style/bootstrap.min.css';
.custom-floating-label {
    border-radius: 3px !important;
    border: 1px solid rgb(198, 198, 198);
    height: 80%;
    max-height: 60px;
    width: 90% !important;
}

.custom-form-floating {
    left: 5%;
    font-size: 16px;
    width: 100% !important;
}

.disabled {
    pointer-events: none !important;
    opacity: 0.25 !important;
}

.form-select {
    width: 90%;
    /* height: 40px; */
}

.inline-input {
    width: 50% !important;
    display: inline-block !important;
}

#select-start-btn {
    margin-left: 5%;
    width: 90%;
    margin-bottom: 8px;
}

#degrees-input,
#vector-speed-unit,
#longitude-input {
    left: 0%;
}

.vector-btn {
    color: white;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    padding: 6px 12px;
    display: block;
}

#add-vector {
    background-color: rgb(0, 99, 149);
}

.vector-item {
    border: 1px solid rgb(198, 198, 198);
    margin: 5px;
    padding: 5px;
    border-radius: 4px;
}
.red-outline {
    border: 3px solid red !important;
    padding: 3px !important;
}

.vector-interacts {
    display: grid;
    width: 100%;
}
.nav-buttons {
    background-color: white;
    width: 50px;
    height: 58px;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 4px;
}
.delete-btn {
    border: 2px solid red;
}

.clear-text {
    height: 20px;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 0.14px;
    color: #006395;
    cursor: pointer;
    font-size: 0.9rem;
    transform: translateY(50%);
}

.header-msg {
    padding-top: 8px;
    padding-left: 5%;
    font: normal normal 600 16px/24px IBM Plex Sans;
    font-size: 1.2rem;
}

.error-msg {
    color: red;
}

#vector-sidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    background-color: white;
    border-left: 1px solid rgb(198, 198, 198);
    /* padding: 10px; */
    overflow-y: none;
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    bottom: 0;
}

#vector-header {
    background-color: inherit;
    border-bottom: 1px solid rgb(198, 198, 198);
    width: 100%;
    left: 0;
    position: relative;
    display: block;
    padding: 10px 0;
    z-index: 5;
}

#vector-list {
    background-color: inherit;
    width: 100%;
    overflow-y: auto;
    padding: 8px;
    border-bottom: 1px solid rgb(198, 198, 198);
    flex: 1;
    position: relative;
    display: block;
    z-index: 1;
}

#vector-footer {
    background-color: inherit;
    width: 100%;
    position: relative;
    border-top: 1px solid rgb(198, 198, 198);
    bottom: 0;
    left: 0;
    padding: 12px;
    z-index: 5;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>
