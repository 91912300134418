<template>
    <div id="menu-tool">
        <button class="menu-tool-button" @click="$emit('click')">
            <slot name="icon" class="menu-tool-icon">i</slot>
            <span class="menu-tool-label">{{ label }}</span>
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'MobileTopMenu',
    props: {
        label: { type: String, required: true },
    },
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {},
    computed: {},
};
</script>

<style scoped>
#mobile-top-menu {
    transition: 300ms;
}
#menu-tool {
    padding-top: 16px;
}

.menu-tool-button {
    color: rgb(173, 171, 171);
    top: 15px;
    border: none;
    background-color: black;
    border-radius: 0px !important;
}
.menu-tool-button:hover {
    color: white;
}

.menu-tool-icon {
    height: 30px;
}

.menu-tool-label {
    padding-left: 10px;
}
</style>
