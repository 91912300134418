import { render, staticRenderFns } from "./ManageAttributes.vue?vue&type=template&id=51655986&scoped=true&lang=html&"
import script from "./ManageAttributes.vue?vue&type=script&lang=js&"
export * from "./ManageAttributes.vue?vue&type=script&lang=js&"
import style0 from "./ManageAttributes.vue?vue&type=style&index=0&id=51655986&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51655986",
  null
  
)

export default component.exports