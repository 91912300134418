<template lang="html">
    <b-row class="my-3 mx-0 p-0 sml">
        <b-col
            cols="10"
            id="portalbar"
            class="text-left icons project border p-0 m-shadow m-align portal-right"
        >
            <a
                v-if="tableselect"
                @click="openTableselectTool"
                class="border-right"
            >
                <img
                    src="../assets/ghd_list.svg"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    title="Datasets"
                    class="px-3 py-2"
                />
            </a>
            <a v-if="EMMap" @click="openEMMap" class="border-right">EM</a>
            <a @click="showDocumentMgmt" class="border-right ml-3">
                <img
                    src="../assets/file.svg"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    title="Document management"
                    class="mr-3"
                />
            </a>
            <a v-if="powerBI" @click="showPowerBI" class="border-right">
                <img
                    src="../assets/powerbi_portal_bold.svg"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    style="height: 38px;"
                    title="PowerBI"
                    class="px-3 py-2"
                />
            </a>
            <a @click="showHistory" class="border-right">
                <img
                    src="../assets/folder-close.svg"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    style="height: 38px;"
                    title="Retrieve work"
                    class="px-3 py-2"
                />
            </a>
            <a v-if="visual" @click="showVisual" class="border-right">
                <img
                    src="../assets/cube_thin.svg"
                    font-scale="1.5"
                    v-b-tooltip.hover
                    style="height: 44px;"
                    title="3D Visualization"
                    class="px-3 py-2"
                />
            </a>
            <div v-if="documentUpload" class="positionAbsolute documentUpload">
                <div class="reportingTool shadow DocManagement">
                    <div class="pl-3 py-1">
                        <p class="wide-50">Document management</p>
                        <span
                            class="float-right mr-3"
                            @click="closeDocumentupload"
                        >
                            <a class="p-0 doc-close"
                                ><img src="../assets/close.svg"
                            /></a>
                        </span>
                    </div>
                    <div
                        v-if="uploadDoc"
                        class="titleBlock row align-items-cente"
                        @click="showModal"
                    >
                        <span class="spanPadding">
                            <img src="../assets/upload-cloud.svg" class="" />
                        </span>
                        <small class="col pl-2"> Upload document </small>
                    </div>
                    <div
                        class="graphing row align-items-cente"
                        @click="uplodDirectory"
                        v-if="DirectoryUpload"
                    >
                        <span>
                            <img src="../assets/folder-plus.svg" class="" />
                        </span>
                        <small class="col pl-2"> Upload directory </small>
                    </div>
                    <div
                        v-if="browseFl"
                        class="contouring row align-items-cente"
                        @click="browseFiles"
                    >
                        <span>
                            <img src="../assets/file-text.svg" class="" />
                        </span>
                        <small class="col pl-2"> File manager </small>
                    </div>
                    <div
                        class="contouring row align-items-cente"
                        @click="manageAttribute"
                        v-if="cloneDir"
                    >
                        <span>
                            <img
                                src="../assets/manage_attribute.svg"
                                class=""
                            />
                        </span>
                        <small class="col pl-2" @click="selectManageAttribute"
                            ><span class="mAttribute">Clone directory</span>
                        </small>
                    </div>
                </div>
            </div>
            <div v-if="powerBIOpen" class="positionAbsolute powerBIPane">
                <div class="reportingTool shadow DocManagement">
                    <div class="pl-3 py-1">
                        <p class="wide-50">Power BI</p>
                        <span class="float-right mr-3" @click="closePowerBI">
                            <a class="p-0 doc-close"
                                ><img src="../assets/close.svg"
                            /></a>
                        </span>
                    </div>
                    <div
                        v-for="(reports, r) in reportNames"
                        :key="r"
                        v-bind:value="reports"
                    >
                        <div
                            v-for="(report, rep) in reports"
                            :key="rep"
                            v-bind:value="report"
                        >
                            <div
                                v-if="uploadDoc"
                                class="titleBlock row align-items-cente "
                                @click="addPowerBIReportsCall(report.DatasetId)"
                            >
                                <span class="spanPadding">
                                    <img
                                        src="../assets/powerbi_portal_bold.svg"
                                        class="powerBIWhite"
                                    />
                                </span>
                                <small class="col pl-2">
                                    {{ report.DisplayName }}
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="histroyOpen" class="positionAbsolute historyPane">
                <div class="reportingTool shadow DocManagement">
                    <div class="pl-3 py-1">
                        <p class="wide-50">Retrieve work</p>
                        <span class="float-right mr-3" @click="closeHistory">
                            <a class="p-0 doc-close"
                                ><img src="../assets/close.svg"
                            /></a>
                        </span>
                    </div>
                    <div
                        v-if="uploadDoc"
                        class="titleBlock row align-items-cente"
                        @click="openSavedWork"
                    >
                        <span class="spanPadding">
                            <img
                                src="../assets/folder-close.svg"
                                class="powerBIWhite"
                            />
                        </span>
                        <small class="col pl-2"> Saved work </small>
                    </div>
                    <div
                        class="graphing row align-items-cente"
                        @click="openHistroy"
                    >
                        <span>
                            <img
                                src="../assets/history.svg"
                                class="powerBIWhite"
                            />
                        </span>
                        <small class="col pl-2"> History </small>
                    </div>
                </div>
            </div>
            <div v-if="visualOpen" class="positionAbsolute visualPane">
                <div class="reportingTool shadow DocManagement">
                    <div class="pl-3 py-1">
                        <p class="wide-50">3D Visualization</p>
                        <span class="float-right mr-3" @click="closeVisual">
                            <a class="p-0 doc-close"
                                ><img src="../assets/close.svg"
                            /></a>
                        </span>
                    </div>
                    <div
                        v-if="uploadDoc"
                        class="titleBlock row align-items-cente"
                        @click="addPowerBIReportsCall"
                    >
                        <span class="spanPadding">
                            <img src="../assets/upload-cloud.svg" class="" />
                        </span>
                        <small class="col pl-2"> Sample Report </small>
                    </div>
                    <div
                        class="titleBlock row align-items-cente"
                        @click="start3DVisDraw"
                    >
                        <span class="spanPadding">
                            <img src="../assets/upload-cloud.svg" class="" />
                        </span>
                        <small class="col pl-2">
                            Upload 3D model
                        </small>
                    </div>
                    <div>
                        click this button, and then click on the map to draw a
                        polygon, double click to finish drawing and submit a EVS
                        model.
                    </div>
                </div>
            </div>
            <!-- <span class="switchlabel pt-2 mr-3 mt-0 m-none">
        <label class="router-link-active switch ml-4">
          <input type="checkbox" v-model="currentSwitchState" checked />
          <span class="slider round"></span>
        </label>
        <span class="m-none pl-1">Open selection in new tab ff</span></span  -->
            <!-- > -->
            <router-link to="/"> </router-link>
        </b-col>
        <b-col cols="2" class="m-none px-0 d-flex justify-content-center">
            <b-col
                class="list-icon border-right d-flex justify-content-center"
                v-b-tooltip.hover
                title="Map view"
            >
                <b-icon
                    icon="geo-alt-fill"
                    font-scale="1.5"
                    style="height: 20px; width: 20px;"
                    @click="viewChange"
                ></b-icon>
            </b-col>
            <b-col
                class="list-icon d-flex justify-content-center"
                v-b-tooltip.hover
                title="List view"
            >
                <b-icon
                    icon="list-ul"
                    font-scale="1.2"
                    @click="viewChange"
                ></b-icon>
            </b-col>
        </b-col>
    </b-row>
</template>

<script>
import router from '../../router';
import { datasetService } from '../services/dataset.service';
import { commonService } from '../services/common.service';
export default {
    name: 'PortalBar',
    props: {
        markerSettings: Number,
        hideSearch: Boolean,
    },
    data() {
        return {
            searchQuery: null,
            mapView: true,
            currentSwitchState: false,
            DirectoryUpload: false,
            documentUpload: false,
            saveManageAttribute: true,
            openProjectSection: false,
            tableselect: false,
            uploadDoc: false,
            cloneDir: false,
            browseFl: false,
            toolsData: [],
            tableSelectTool: false,
            EMMap: false,
            powerBI: false,
            powerBIOpen: false,
            histroyOpen: false,
            Visual: false,
            visualOpen: false,
            reportNames: null,
        };
    },
    mounted() {
        this.pagePermit();
        this.toolsList(localStorage.getItem('projectId'), 0);
        this.getReports();
    },
    methods: {
        toolsList() {
            let selectedProjects = this.$store.state.projects.selectedProjects.map(
                (id) => id.ProjectID
            );
            commonService.getTools(selectedProjects, 0).then((res) => {
                this.toolsData = res.data;
                this.toolsData.forEach((element) => {
                    if (element.ToolName == 'Data' || element.ToolName == 'Table select tool') {
                        this.tableselect = true;
                    } else if (element.ToolName == 'Emergency Management') {
                        this.EMMap = true;
                    } else if (element.ToolName == 'PowerBI') {
                        this.powerBI = true;
                    } else if (element.ToolName == '3D') {
                        this.visual = true;
                    }
                });
            });
        },
        searchiconclick() {
            document.querySelector('#outersearchinput').style.width = '300px';
            document.querySelector('#outersearchinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outersearchinput .e-input').style.display =
                'block';
            this.closeFilter();
        },
        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;

            if (data) {
                data.forEach((element) => {
                    if (
                        element.PageName.includes(
                            'Documents - Directory Upload'
                        )
                    ) {
                        this.DirectoryUpload = true;
                    }
                    if (element.PageName.includes('Documents - Upload')) {
                        this.uploadDoc = true;
                    }
                    if (
                        element.PageName.includes('Documents - Clone directory')
                    ) {
                        this.cloneDir = true;
                    }
                    if (
                        element.PageName.includes(
                            'Document Management - File Manager'
                        )
                    ) {
                        this.browseFl = true;
                    }
                });
            }
        },
        openTableselectTool: function() {
            this.selectedProjects = this.$store.state.projects.selectedProjects.map(
                (id) => id.ProjectID
            );
            localStorage.setItem(
                'selectedProjects',
                JSON.stringify(this.selectedProjects)
            );
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({
                    name: 'tableselectiontool',
                });
                window.open(routeData.href, '_blank');
            } else {
                router.push(`/classic/tableselectiontool`);
            }
        },
        openEMMap: function() {
            router.push(`/classic/MainMap/`);
        },
        showDocumentMgmt() {
            this.documentUpload = !this.documentUpload;
            if (this.visualOpen) {
                this.closeVisual();
            }
            if (this.powerBIOpen) {
                this.closePowerBI();
            }
            if (this.histroyOpen) {
                this.closeHistory();
            }
        },
        showPowerBI() {
            this.powerBIOpen = !this.powerBIOpen;
            if (this.visualOpen) {
                this.closeVisual();
            }
            if (this.documentUpload) {
                this.closeDocumentupload();
            }
            if (this.histroyOpen) {
                this.closeHistory();
            }
        },
        getReports() {
            let reports = null;
            datasetService
                .getPowerBIDatasets(localStorage.getItem('projectId'))
                .then((res) => {
                    new Promise((resolve) => {
                        reports = res.data;
                        resolve(reports);
                    }).then((reports) => {
                        this.reportNames = reports;
                    });
                })
                .catch((err) => {});
        },
        showHistory() {
            this.histroyOpen = !this.histroyOpen;
            if (this.visualOpen) {
                this.closeVisual();
            }
            if (this.documentUpload) {
                this.closeDocumentupload();
            }
            if (this.powerBIOpen) {
                this.closePowerBI();
            }
        },
        showVisual() {
            this.visualOpen = !this.visualOpen;
            if (this.powerBIOpen) {
                this.closePowerBI();
            }
            if (this.documentUpload) {
                this.closeDocumentupload();
            }
            if (this.histroyOpen) {
                this.closeHistory();
            }
        },
        closeDocumentupload() {
            this.documentUpload = false;
        },
        closePowerBI() {
            this.powerBIOpen = false;
        },
        closeHistory() {
            this.histroyOpen = false;
        },
        closeVisual() {
            this.visualOpen = false;
        },
        showModal() {
            this.$emit('clicked', this.currentSwitchState);
            this.documentUpload = !this.documentUpload;
            this.$root.$refs.A.showModal();
        },
        addPowerBIReportsCall(reportId) {
            this.$root.$refs.A.addPowerBIReports();
            this.powerBIOpen = false;
            localStorage.setItem('reportId', reportId);
        },
        openHistroy() {
            this.$root.$refs.A.addHistoryTable();
            if (this.powerBIOpen) {
                this.closePowerBI();
            }
            if (this.documentUpload) {
                this.closeDocumentupload();
            }
            if (this.visualOpen) {
                this.closeVisual();
            }
        },
        openSavedWork() {
            this.$root.$refs.A.addSavedWork();
        },
        uplodDirectory() {
            //this.$emit('clicked', this.currentSwitchState);
            this.documentUpload = !this.documentUpload;
            this.$root.$refs.A.uplodDirectory(this.currentSwitchState);
        },
        browseFiles() {
            this.documentUpload = !this.documentUpload;
            this.$root.$refs.A.browseFiles(this.currentSwitchState);
        },
        selectManageAttribute() {
            this.$emit('clicked', this.currentSwitchState);
            this.$root.$refs.A.selectManageAttribute();
        },
        manageAttribute() {
            this.documentUpload = !this.documentUpload;
            this.$emit('clicked', this.currentSwitchState);
            this.$root.$refs.A.manageAttribute(this.currentSwitchState);
        },

        viewChange() {
            this.$parent.$parent.$parent.viewChange();
        },
        start3DVisDraw: function() {
            this.$emit('ThreeDVisDraw', this.visualOpen);
            this.visualOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.my-custom-floating-label {
    .input__container {
        width: 400px;
    }
}
.breadcrumb {
    background-color: white !important;
}
.text-right.icons {
    //margin-top: -10px;
    max-width: 100px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}
.saveManageButton {
    margin-top: -107px;
}
.cancelManage {
    position: absolute;
    /* text-align: right !important; */
    top: -33px;
    left: -97px;
    a {
        color: #006395 !important;
        font-size: 16px !important;
    }
}
.text-right.saveManage {
    position: absolute;
    top: -40px;
}
.open-toggle {
    float: right;
    margin-top: 8px;
}
.border {
    height: 48px;
    padding-top: 3px !important;
    border-radius: 4px;
    //left: 5px;
    /* width: 987px; */
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}
.childCheckBox {
    padding-left: 20px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.leaflet-popup-content-wrapper {
    background-color: black !important;
    padding: 1px !important;
    min-width: 100px !important;
    margin: 0 !important;
    color: #eee !important;
    border-radius: 0 !important;
}
.leaflet-popup-content {
    margin: 3px 6px !important;
}
.leaflet-popup-tip-container {
    display: none;
}
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}
.leaflet-control-attribution a {
    display: none;
}

.leaflet-control-container {
    display: none;
}
.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    left: 1px;
    top: 12px;
    z-index: 9999999;
}
/* .border-right {
  margin-left: -36px !important;
} */

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
    padding: 15px 5px;
    font-size: 16px !important;
}

.DocManagement {
    height: auto !important;
}

.reportingTool p.wide-50 {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    width: 120px !important;
    height: 49px;
    opacity: 1 !important;
}

.titleBlock {
    margin: 16px 15px;
    height: 48px;
    font-size: 16px !important;
}
.titleBlock small {
    font-size: 16px !important;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
}
.titleBlock small:hover {
    border: 3spx solid #b034a1;
    height: 48px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 48px;
    width: 48px;
    text-align: center;
}

.graphing {
    margin: 16px 15px;
    height: 48px;
}

.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.graphing small {
    font-size: 16px !important;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d;
    padding-top: 10px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 48px;
}
.graphing span {
    background-color: #8456b0;
    height: 48px;
    width: 48px;
    text-align: center;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.directoryAssign {
    background: #dddddd;
    //margin-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.directoryItem {
    background-color: white !important;
    height: 48px !important;
    width: 499px !important;
    margin-left: 48px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -10px;
}
.test {
    margin-bottom: 20px;
}
.row1 {
    margin-bottom: 20px;
}
.searchinputIcon {
    background-color: #eaeaea;
    width: 197px;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
}
.arrayLength {
    position: relative;
    left: 115px;
    right: 45px;
    top: 14px;
    font-weight: bold;
}

button#__BVID__31__BV_toggle_ {
    background: none !important;
    border: none !important;
}
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}

.dataset {
    margin: 10px 15px;
    height: 48px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 48px;
}
.dataset span {
    background-color: #5878be;
    height: 48px;
    width: 48px;
    text-align: center;
}
.graphic {
    text-align: center;
    width: 253px;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
}
.blue {
    color: #fff !important;
    background-color: #000 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
}
.graphic:hover {
    border-color: #626262;
    background-color: #000;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 28px;
    margin-top: 0px;
    background: url('../assets/delete_icon.svg') no-repeat right !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.assignedTabs {
    width: 499px;
    margin: auto;
    .normal_input.smallTabs {
        width: 408px !important;
    }
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 35%;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        cursor: pointer;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: 154px;
    //   margin-top: 30px;
    // margin-left: 127px;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}

.cancelButton {
    background: transparent;
    color: #006395;
    border: none;
    float: left;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
}

.selectedGraphictool {
    color: #fff !important;
    border-color: #626262;
    background-color: #000;
}
.contouring {
    margin: 16px 15px;
    height: 48px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 48px;
}
.contouring span {
    background-color: #3296ca;
    height: 48px;
    width: 48px;
    text-align: center;
}

.mapToolAttribute {
    width: 225px;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 40%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.normal_select {
    width: 475px;
    height: 52px;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 3px;
}
.normal_select:focus {
    border: 1px solid #c6c6c6 !important;
}
.mapToolAttribute h6 {
    color: #006395;
    font-weight: bold;
}
.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;
    p {
        text-align: center;

        width: 248px;
        margin-left: 128px;
        font-size: 18px;
        color: #000;
        a {
            font-size: 20px;
            color: #006395 !important;
        }
    }
}

.uploadButton {
    background-color: #3296ca;
    width: 83px;

    margin-top: 10px;
    border-radius: 3px;
}
.uploadicon {
    height: 56px;
    width: 82px;
}
.iconplacing {
    //padding-left: 200px;
    padding-top: 13px;
}
.attrTabs .tabs {
    border: 2px solid #eaeaea !important;
    margin-bottom: 135px;
}
.content {
    // background-color: #eaeaea !important;
    min-height: 350px;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    //padding: 30px;
    //text-align: center;
}
.content-clone {
    background-color: #eaeaea !important;
    min-height: 350px;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    padding: 30px;
    text-align: center;
}
.my-custom-floating-label {
    background-color: white !important;
    // width: 400px !important;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    height: 48px;
    text-align: center;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    // margin-top: 67px;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.reportingTool span img {
    margin-top: 13px;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.uploadToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 450px;
    border-left: 6px solid #933d9b;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}

.assignAttr img {
    margin-top: 10%;
}

.tabs {
    padding-top: 10px;
}

ul.e-upload-files {
    border-top: none !important;
    margin-top: 70px !important;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

button.btn.btn-secondary.btn-block.not-collapsed {
    background: #626262 url('../assets/minusIcon.svg') no-repeat right 0.75rem
        center !important;
    color: #fff;
    text-align: left;
    border-radius: 0;
    border: none;
    height: 50px !important;
    // width: 516px;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    // width: 516px;
    border-radius: 0;
    border: none;
    color: #000;
    background: #fff url('../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
    height: 50px !important;
}
.card-header {
    margin-bottom: 20px;
    background: transparent;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
}
.custom-select {
    background: #fff url('../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
}
.custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 5%;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
}
.custom-select {
    background: #fff url('../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
    background: #eaeaea url('../assets/big_plus.svg') no-repeat right 0.75rem
        center !important;
}
.btn-info:focus,
.btn-info.focus {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:hover {
    color: #000;
    background-color: #eaeaea;
}
.normal_inputButton {
    height: 48px !important;
    border-radius: 3px;
    //margin-left: 104px;
    width: 499px;
    margin: auto;
}
button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../assets/minus_sign_big.svg') no-repeat right
        0.75rem center !important;
}

.dragdrop {
    margin-left: 140px;
    text-align: center;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000;
}
// .nextbutton {
//   margin-right: 50px;
// }

.nextbutton:disabled:hover {
    background-color: #eaeaea !important;
}
// Manage attribute style
.saveManage {
    button {
        width: 80px;
    }
}
.cancelManage {
    position: absolute;
    /* text-align: right !important; */
    top: -33px;
    left: -82px;
    a {
        color: #006395 !important;
        font-size: 16px !important;
    }
}
.text-right.saveManage {
    position: absolute;
    top: -40px;
}
.childCheckBox {
    padding-left: 20px;
    overflow-x: auto;
    height: 130px;
}
.legacyBox {
    height: 261px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -10px;
}
.searchinputIcon {
    background-color: #eaeaea;
    width: 197px;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
// .arrayLength {
//   position: absolute;
//   /* left: 20px; */
//   right: 84px;
//   top: 107px;
//   font-weight: bold;
// }
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #c6c6c6;
}
.blue {
    color: #fff !important;
    background-color: #161616 !important;
    border: 0 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #c6c6c6;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
    /* margin-top: 23px; */
    margin-bottom: 20px;
    border-radius: 3px !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 50%;
    float: right;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        /*margin-left: 312px;*/
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        /*margin-right: -295px;*/
    }
}
.main-button {
    margin-right: 0;
    /*margin-left: -56px;*/
    margin-top: 20px;
    width: 215px;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        /*margin-left: 312px;*/
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}

.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
    li {
        border-top: none !important;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: 154px;
    //  margin-top: 30px;
    // margin-left: 127px;
}
.contentEdit {
    width: 498px;
    text-align: center;
    //margin-left: 132px;
    margin-top: 30px;
}
.title {
    text-align: center;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    // display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.successToastMsg p {
    display: inline;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #626262 url('../assets/minusIcon.svg') no-repeat right 0.75rem
        center !important;
    color: #fff;
    text-align: left;
    border-radius: 0;
    border: none;
    // width: 516px;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    // width: 516px;
    border-radius: 0;
    border: none;
    color: #000;
    background: #fff url('../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}

.card-header {
    margin-bottom: 20px;
    background: transparent;
}
//manage attribute style ended
//style for file manager
.file-overview .sample-container {
    margin: 10px 10px 10px 10px;
}
//listing file in toast message after document upload
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}
.applyButtonSelect {
    background: #006395;
    color: white;
    border: none;
    cursor: pointer;
}
.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}
.model_style {
    width: 900px !important;
}
.modal-dialog {
    max-width: 700px !important;
}
button:disabled,
button[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
input:disabled,
input[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
div#__BVID__62___BV_modal_backdrop_ {
    opacity: 1 !important;
    background-color: #8a8a8a !important;
}
.e-file-container {
    align-items: last baseline !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name {
    padding: 0 !important;
}
.e-list-text {
    color: #fff !important;
}
.e-hover {
    color: #000;
}

#file-manager {
    display: block !important;
    border: 1px solid red !important;
}

#file-manager_layout {
    display: block !important;
}
.upload-doc .custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 30px;
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
#outersearchinput {
    height: 40px !important;
    padding-top: 5px;
}
.icon-right {
    position: absolute;
    right: 0;
    top: 0;
}
.subheader-smalltext {
    margin-right: -80px;
}
.mAttribute {
    width: 130px !important;
    height: 20px !important;
    background-color: transparent !important;
}
#portalbar {
    width: 90% !important;
    border-radius: 4px;
    z-index: 99;
    background: white;
}
.documentUpload {
    left: 70px;
    top: 51px;
    z-index: 99;
}
.powerBIPane {
    left: 110px;
    top: 51px;
    z-index: 99;
}
.historyPane {
    left: 163px;
    top: 51px;
    z-index: 99;
}
.full-screen-icon {
    width: 23px;
    height: 23px;
    position: relative;
    top: 5px;
    left: 10px;
    color: #000000;
}
.visualPane {
    left: 200px;
    top: 51px;
    z-index: 99;
}
.powerBIWhite {
    filter: invert(100%) sepia(0%) saturate(7443%) hue-rotate(314deg)
        brightness(119%) contrast(92%);
}
td {
    border-top: none;
}
</style>
