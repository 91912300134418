<template>
    <div class="control-section" v-if="showPage">
        <div><Project_BackToTop></Project_BackToTop></div>
        <div align="center" class="scatter" id="modalInvoice">
            <button
                class="e-info buttonSelect m-none trans-none exportButton"
                @click="print"
            >
                Export
            </button>
            <div
                class="multipleCharts py-5"
                v-for="(item, indexSub) of seriesSort(seriesData)"
                :key="indexSub"
            >
                <ejs-chart
                    :ref="'chart' + indexSub"
                    :height="height"
                    class="display-blk chartcontainer"
                    :theme="theme"
                    align="center"
                    :id="'chartcontainer' + indexSub"
                    :title="title"
                    :primaryXAxis="primaryXAxis"
                    :primaryYAxis="primaryYAxis"
                    :tooltip="tooltip"
                    :width="width"
                    :axes="axes"
                    :chartArea="chartArea"
                    :margin="margin"
                    :tooltipRender="renderTooltip"
                >
                    <div
                        v-if="editBlock"
                        id="editAnnotation"
                        class="block-2 mb-3 col-12 scatterEditBlock"
                        ref="infoBox"
                    >
                        <div id="editPanel">
                            <div
                                class="content"
                                v-html="plotLabel[indexSub]"
                            ></div>
                        </div>
                    </div>
                    <e-series-collection>
                        <e-series
                            v-for="(series, index) in item"
                            :key="index"
                            :name="series.F"
                            :dataSource="series.Coordinate"
                            type="Line"
                            xName="x"
                            yName="y"
                            width="2"
                            :marker="marker"
                            :emptyPointSettings="emptyPointSettings"
                        >
                        </e-series>

                        <!-- {{ secondarySeriesData[indexSub] }}zxlkzcxc
            <e-series
              v-for="(series, index) in secondarySeriesData[indexSub]"
              :key="index"
              :dataSource="series.Coordinate"
              type="Line"
              xName="x"
              yName="y"
              yAxisName="yAxis"
              :name="series.F"
              width="2"
              :marker="marker"
            >
            </e-series> -->
                    </e-series-collection>
                </ejs-chart>
            </div>
        </div>
        <!-- <AccessPermissionModal ref="permission-modal"/> -->
    </div>

    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>
<script>
import Vue from 'vue';
//import { Browser } from '@syncfusion/ej2-base';
import {
    ChartPlugin,
    LineSeries,
    Legend,
    DataLabel,
    Tooltip,
    DateTime,
    Logarithmic,
    ChartAnnotation,
    Export,
} from '@syncfusion/ej2-vue-charts';
import { datasetService } from '../../services/dataset.service';
import router from '../../router';
import { mapActions } from 'vuex';
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
// import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
import jsPDF from 'jspdf';
import AccessPermissionModal from '../AccessPermissionModal';
import 'lodash.product';
import _ from 'lodash';
import Project_BackToTop from './manage/Project_BackToTop.vue';

Vue.use(ChartPlugin);
Vue.use(ButtonPlugin);
Vue.use(jsPDF);
let selectedTheme = location.hash.split('/')[1];
selectedTheme = selectedTheme ? selectedTheme : 'Material';
let theme = (
    selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
).replace(/-dark/i, 'Dark');

export default Vue.extend({
    name: 'scatterplot',
    data() {
        return {
            filtered1: [],
            seriesData: [],
            secondarySeriesData: [],
            theme: theme,
            primaryXAxis: {
                valueType: 'DateTime',
                labelFormat: 'MM/dd/yyyy',
                majorGridLines: { width: 0 },
                title: this.axisDetails[0].axisAlias,
                titleStyle: this.axisDetails[0].style,
            },
            chartArea: {
                border: {
                    width: 2,
                },
            },
            //Initializing Primary Y Axis
            legendSettings: {
                visible: true,
            },
            width: '100%',
            height: '100%',
            marker: {
                visible: true,
                height: 10,
                width: 10,
                shape: 'Diamond',
            },
            tooltip: {
                enable: true,
            },
            //secondary Axis creation code
            axes: [
                {
                    majorGridLines: { width: 0 },
                    rowIndex: 0,
                    opposedPosition: true,
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },

                    name: 'yAxis',
                    minorTickLines: { width: 0 },
                    rangePadding: 'None',
                    minimum: 0,
                    maximum: 100,
                    interval: 5,
                },
            ],
            // title: "Scatter Plot",
            seriesname: '',
            datasetid: null,
            // secondaryAxis:null,
            margin: { bottom: 250 },
            footerText: '',
            editBlock: '',
            showPage: true,
            thisfilename: null,
            // graphDetails:[],
            filterOptions: [],
            ditinctValuesArray: [],
            modal: '',
            cloned: '',
            enableCanvas: true,
            chartId: 'container',
            emptyPointSettings: {
                mode: this.nullHandler,
                border: { color: '', width: 1 },
            },
        };
    },
    provide: {
        chart: [
            LineSeries,
            Legend,
            Tooltip,
            DateTime,
            Logarithmic,
            ChartAnnotation,
            Export,
            DataLabel,
        ],
    },
    components: {
        AccessPermissionModal,
        Project_BackToTop,
    },
    props: {
        logarithamic: Boolean,
        orientation: String,
        secondaryAxis: Object,
        graphDetails: Object,
        seriesMultiple: String,
        filteredColumnId: Object,
        columnNameSeries: Object,
        axisDetails: Object,
        nullHandler: String,
        isAutomaticYScale: Boolean,
    },
    created() {
        this.displayGraphs();
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('scatterPlot')) {
                    vm.showPage = true;
                }
            });
            // this.$emit("scatterplotShow", this.showPage)
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        async displayGraphs() {
            //let this.seriesData
            if (this.logarithamic) {
                this.primaryYAxis.valueType = 'Logarithmic';
                //  this.primaryYAxis.minimum= 0.0001;
            }
            this.filtered1 = JSON.parse(
                localStorage.getItem('afterFilterData')
            );
            //  this.orientation=localStorage.getItem('orientation');
            if (this.orientation == 'portrait') {
                this.width = '65%';
                this.height = '850';
            } else {
                this.width = '100%';
                this.height = '800';
            }
            this.datasetid = localStorage.getItem('datasetid');
            //  this.secondaryAxis = JSON.parse(localStorage.getItem('secondaryAxis'));
            //  this.graphDetails = JSON.parse(localStorage.getItem('graphDetails'));
            this.filterOptions = JSON.parse(localStorage.getItem('gridFilter'));

            if (this.seriesMultiple.length > 0) {
                this.ditinctValuesArray = [];

                for (let i = 0; i < this.seriesMultiple.length; i++) {
                    await this.getDistinctValues(this.seriesMultiple[i]);
                }

                let product = _.product(...this.ditinctValuesArray);

                product.forEach((prod, index) => {
                    let prevFilter = JSON.parse(
                        localStorage.getItem('gridFilter')
                    );
                    prod.forEach((itm, indx) => {
                        prevFilter.push({
                            ColumnName: this.columnNameSeries[
                                this.seriesMultiple[indx]
                            ],
                            ColumnNameValues: [itm],
                            Columnid: this.filteredColumnId[
                                this.seriesMultiple[indx]
                            ],
                            filter: localStorage.getItem('legacyProject'),
                            filterColName: 'SiteCode',
                            filterCondition: null,
                            filterConditionColName: null,
                            filterValue: null,
                            dataSetId: this.datasetid,
                            projectId: JSON.parse(
                                localStorage.getItem('selectedProjects')
                            ),
                        });
                    });
                    datasetService
                        .getScatterplotData(
                            this.datasetid,
                            this.graphDetails.xaxisdata,
                            this.graphDetails.yaxisdata,
                            this.graphDetails.seriesdata,
                            prevFilter
                        )
                        .then((res) => {
                            this.seriesData.push(this.sortedResData(res.data));
                            if (
                                this.ditinctValuesArray[index].length ==
                                    this.seriesData.length &&
                                this.secondaryAxis
                            ) {
                                this.processSecondaryAxis();
                            }
                        });
                });
            } else {
                datasetService
                    .getScatterplotData(
                        this.datasetid,
                        this.graphDetails.xaxisdata,
                        this.graphDetails.yaxisdata,
                        this.graphDetails.seriesdata,
                        this.filterOptions
                    )
                    .then((res) => {
                        this.seriesData.push(this.sortedResData(res.data));
                        if (this.secondaryAxis) {
                            this.processSecondaryAxis(0);
                        }
                    });
            }
            if (localStorage.getItem('editBlock')) {
                this.editBlock = localStorage.getItem('editBlock');
            } else {
                this.editBlock = '';
            }
        },
        getDateformated(d) {
            var d1 = new Date(d),
                month = '' + (d1.getMonth() + 1),
                day = '' + d1.getDate(),
                year = d1.getFullYear();

            return [year, month, day].join('/');
        },
        getDistinctValues(seriesCol) {
            return new Promise((resolve, rejected) => {
                datasetService
                    .GetDistinctFilteredvalues(
                        localStorage.getItem('datasetid'),
                        seriesCol,
                        this.filterOptions
                    )
                    .then((res) => {
                        this.ditinctValuesArray.push(res.data);
                        resolve('resolved');
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },
        processSecondaryAxis() {
            var scaleIndex = localStorage.getItem('scaleIndex');
            var primaryseries = [];
            let vm = this;
            //  let seriesIndex = index;
            vm.seriesData.forEach((temp, index) => {
                vm.secondaryAxis.forEach((ele) => {
                    let cp = temp.filter((item) => {
                        var splitF = item.F.split(':');
                        return splitF[scaleIndex] === ele;
                    });

                    if (vm.secondarySeriesData[index] == undefined) {
                        vm.secondarySeriesData.push({});
                    }
                    if (cp[0]) {
                        vm.secondarySeriesData[index] = cp[0];
                    }
                });
            });

            vm.secondaryAxis.forEach((ele) => {
                vm.seriesData = vm.seriesData.filter((item) => {
                    var splitF = item.F.split(':');
                    return splitF[scaleIndex] != ele;
                });

                // primaryseries.push(cp[0]);
            });

            // vm.seriesData = primaryseries;
            //
        },
        //  navigatetoProjectlist() {
        //      localStorage.setItem("openXYGraph", true);
        //       router.push(`/classic/tableselectiontool');
        //     },
        loaded: function(args) {
            var imageEle = document.getElementById('scatterImage');
            var imageUrl = imageEle.src;
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = function() {
                canvas.height = img.height;
                canvas.width = img.width;
                ctx.drawImage(img, 0, 0);
                var dataURL = canvas.toDataURL('image/png');
                imageEle.setAttribute('src', dataURL); // assign data url
                canvas = null;
            };
            img.src = imageUrl;
        },
        print() {
            // this.$refs.chartModal.print();

            const modal = document.getElementById('modalInvoice');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');

            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }

            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            if (this.orientation == 'portrait') {
                css =
                    '@page { size: portrait; }#print .chartcontainer {transform: translateY(18%);}';
            } else {
                css =
                    '@page { size: landscape; }#print .chartcontainer {transform: translateY(11%);}';
            }
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);
            window.print();
        },
        onChange() {
            var layout = localStorage.getItem('orientation');
            var annot = document.getElementById('editAnnotation');
            annot.setAttribute('style', 'position:absolute; bottom:0;');
            var annotationEle = annot.querySelectorAll('[id*="editPanel"]');
            // document.getElementById("editAnnotation").querySelector('[class*="side_block"]').setAttribute("style", "border: 1px solid #dee2e6;display: block;border-radius: 3px; padding:0; width: 700px;");
            if (layout == 'portrait') {
                document
                    .getElementById('editAnnotation')
                    .querySelector('[class*="side_block"]')
                    .setAttribute(
                        'style',
                        'border: 1px solid #dee2e6;display: block;border-radius: 3px; padding:0; width: 700;'
                    );
            } else {
                document
                    .getElementById('editAnnotation')
                    .querySelector('[class*="side_block"]')
                    .setAttribute(
                        'style',
                        'border: 1px solid #dee2e6;display: block;border-radius: 3px; padding:0; width: 99.5%;'
                    );
            }

            // var cSpan= document.getElementById("editAnnotation").querySelector('span');
            // for (i = 0; i < cSpan.length; i++) {
            //   var y2 =  cSpan[i];
            //  y2.setAttribute("style", "font-size: 14px;padding-top: 10px;padding-right: 5px;");
            // }

            for (var i = 0; i < annotationEle.length; i++) {
                var annotation = annotationEle[i];
                var svg = document.querySelector('#chartcontainer_svg');
                var foreign = document.createElementNS(
                    'http://www.w3.org/2000/svg',
                    'foreignObject'
                );
                foreign.setAttribute(
                    'width',
                    (annotation.getBoundingClientRect().width + 10).toString()
                );
                foreign.setAttribute(
                    'height',
                    (annotation.getBoundingClientRect().height + 80).toString()
                );

                foreign.setAttribute('y', 500);
                // annotation.setAttribute("style", "z-index:1000;display:block;:padding:0;border-top:1px solid;overflow:visible;position:relative;bottom:30px;top:370px;width:700px")
                // foreign.setAttribute("x", svg.width.animVal.value - 740);
                if (layout == 'portrait') {
                    foreign.setAttribute('x', svg.width.animVal.value - 740);
                    annotation.setAttribute(
                        'style',
                        'z-index:1000;display:block;:padding:0;border-top:1px solid;overflow:visible;position:relative;bottom:30px;top:370px;width:780px'
                    );
                } else {
                    // foreign.setAttribute("x", svg.width.animVal.value - 1000);
                    annotation.setAttribute(
                        'style',
                        'z-index:1000;display:block;:padding:0;border-top:1px solid;overflow:visible;position:relative;bottom:30px;top:370px;width:99.5%'
                    );
                }

                foreign.innerHTML = annotation.innerHTML;
                svg.setAttribute(
                    'style',
                    'position:relative;background:#fff; z-index:-1;background:#fff;overflow:visible;'
                );
                svg.appendChild(foreign);
            }
            var svgData = new XMLSerializer().serializeToString(svg);
            var canvas = document.createElement('canvas');
            document.body.appendChild(canvas);
            var svgSize = svg.getBoundingClientRect();
            canvas.width = svgSize.width;
            canvas.height = svgSize.height;
            var ctx = canvas.getContext('2d');
            var img = document.createElement('img');
            img.setAttribute(
                'src',
                'data:image/svg+xml;base64,' + btoa(svgData)
            );
            img.setAttribute('style', 'padding:100px');
            img.onload = function() {
                ctx.drawImage(img, 0, 0);
                var imgData = canvas.toDataURL('image/jpeg', 1);
                var pdf = new jsPDF({
                    orientation: layout,
                    unit: 'in',
                    format: [15, 8.264],
                });
                pdf.addImage(imgData, 'JPEG', 0.3, 0.3);
                pdf.save('chart.pdf');
                canvas.remove();
            };
        },
        getIntervalDecimalCount() {
            var myInterval = localStorage.getItem('interval');
            var decIndex = myInterval.indexOf('.');
            if (decIndex == -1) {
                return 'n0';
            } else {
                var nDecimals = myInterval.length - decIndex - 1;
                return 'n' + nDecimals;
            }
        },
        renderTooltip: function(args) {
            args.text =
                'Date : ' +
                '<b>' +
                `${args.data.pointX.getMonth() + 1}` +
                '/' +
                args.data.pointX.getDate() +
                '/' +
                args.data.pointX.getFullYear() +
                '</b>' +
                '<br>' +
                'Value: ' +
                '<b>' +
                args.data.pointY +
                '</b>';
        },
        seriesSort(seriesData) {
            if (this.seriesMultiple.length > 0) {
                let tempDataArray = [...seriesData];
                let seriesArray = [...this.graphDetails.seriesdata.split(',')];
                tempDataArray.forEach((dataItem, index) => {
                    let tempSeries = dataItem[0].F.split(':');
                    seriesArray.forEach((item, index) => {
                        dataItem[item] = tempSeries[index].toLowerCase();
                    });
                });

                var temp = _.sortBy(tempDataArray, seriesArray);

                return this.removeEmptyFromSeriesData(temp);
            } else {
                let tempArray = [...seriesData];
                if (Number(tempArray[0][0].F.split('-')[1])) {
                    tempArray.sort((a, b) => {
                        let temp_a = Number(a[0].F.split('-')[1]);
                        let temp_b = Number(b[0].F.split('-')[1]);
                        return temp_a - temp_b;
                    });
                }
                return this.removeEmptyFromSeriesData(tempArray);
            }
        },
        removeEmptyFromSeriesData(seriesData) {
            seriesData.forEach((seriesItems) => {
                seriesItems.forEach((series) => {
                    series.Coordinate.forEach((cordinate) => {
                        if (cordinate.y === '') {
                            cordinate.y = null;
                        }
                    });
                });
            });
            return seriesData;
        },
        sortedResData(data) {
            let tempArray = [...data];

            let seriesArray = [...this.graphDetails.seriesdata.split(',')];
            //sort by name
            tempArray.forEach((dataItem, index) => {
                let tempSeries = dataItem.F.split(':');
                seriesArray.forEach((item, index) => {
                    dataItem[item] = tempSeries[index].toLowerCase();
                });
            });

            var temp = _.sortBy(tempArray, seriesArray);
            tempArray = [...temp];
            //sort dates
            tempArray.forEach((item) => {
                item.Coordinate.sort(function(a, b) {
                    var dateA = new Date(a.x);
                    var dateB = new Date(b.x);
                    return dateA - dateB;
                });
            });
            return tempArray.map((itm) => {
                return {
                    Coordinate: itm.Coordinate,
                    F: itm.F,
                    TitleBlock: itm.TitleBlock,
                };
            });
        },
        multipleArraySort(data) {
            let tempDataArray = [...this.seriesData, ...data];
            tempDataArray.forEach((dataItem) => {
                let tempSeries = dataItem.F.split(':');
                this.seriesMultiple.forEach((item, index) => {
                    dataItem[item] = tempSeries[index];
                });
            });
            var temp = _.sortBy(tempDataArray, this.seriesMultiple);
            this.seriesData = [...temp];
        },
    },
    computed: {
        primaryYAxis() {
            let toRetun = {};
            if (this.seriesMultiple.length > 0 && this.isAutomaticYScale) {
                toRetun = {
                    // valueType: 'Double',
                    // labelFormat: this.getIntervalDecimalCount(),
                    majorTickLines: {
                        width: 0,
                    },
                    lineStyle: {
                        width: 0,
                    },
                    title: this.axisDetails[1].axisAlias,
                    titleStyle: this.axisDetails[1].style,
                };
            } else {
                toRetun = {
                    valueType: 'Double',
                    labelFormat: this.getIntervalDecimalCount(),
                    majorTickLines: {
                        width: 0,
                    },
                    lineStyle: {
                        width: 0,
                    },
                    title: this.axisDetails[1].axisAlias,
                    titleStyle: this.axisDetails[1].style,
                    rangePadding: 'None',
                    minimum: parseFloat(localStorage.getItem('minValue')),
                    maximum: parseFloat(localStorage.getItem('maxValue')),
                    interval: parseFloat(localStorage.getItem('interval')),
                };
            }
            return toRetun;
        },
        plotLabel() {
            //calculate label for each plot.
            //if one plot for all locations, keep it as is.
            //if multiple plots, replace the location name string containing all locations with individual location name
            let label = [];
            if (this.seriesMultiple) {
                // non-lookbehind regex
                // let r2 = [];
                let r2 = this.editBlock.match(/>[^<>]+/g);
                let r3 = [];
                r2.forEach((e) => {
                    r3.push(e.slice(1));
                });
                if (r3) {
                    let oldLabels = r3;
                    let tempSortArray = [...this.seriesData];
                    let seriesArray = [
                        ...this.graphDetails.seriesdata.split(','),
                    ];
                    tempSortArray.forEach((dataItem, index) => {
                        let tempSeries = dataItem[0].F.split(':');
                        seriesArray.forEach((item, index) => {
                            dataItem[item] = tempSeries[index].toLowerCase();
                        });
                    });

                    var temp = _.sortBy(tempSortArray, seriesArray);
                    temp.forEach((plotData) => {
                        let dataValues = Object.values(plotData[0].TitleBlock);
                        let newLabel = dataValues.toString();
                        let previousTitle = this.editBlock;
                        /*
                logic to handle title block when multiple graphs are displayed
                use the previously constructed title block html including styling and labels
                use regex to get the data part outside of the html tags, example array below
                ['new title:&nbsp;', 'Tetrachloroethene, cis-1,2-Dichloroethene, trans-1…ifluorotrichloroethane (CFC-113), Trichloroethene', ',&nbsp;', 'MW-001, MW-002, MW-003']
                use data from API return to replace the html string if a match is found. ['Tetrachloroethene', 'MW-001'] 
                if regex_array[i] contains data_array[j] -> html_block.replace(regex_array[i], data_array[j])
              */
                        oldLabels.forEach((lbl) => {
                            dataValues.forEach((val) => {
                                if (lbl.indexOf(val) !== -1) {
                                    previousTitle = previousTitle.replace(
                                        lbl,
                                        val
                                    );
                                }
                            });
                        });
                        label.push(previousTitle);
                    });
                } else {
                    label.push(this.editBlock);
                }
            } else {
                label.push(this.editBlock);
            }

            return label;
        },
    },
});
</script>
<style>
.scatter.modal-dialog {
    max-width: none !important;
}

.exportButton {
    display: block;
}

@media screen {
    #print {
        display: none;
    }
}

@media print {
    .exportButton {
        display: none !important;
    }
    body * {
        display: none;
    }
    #print,
    #print * {
        display: block;
    }
    #print {
        position: absolute;
        left: 0;
        top: 0;
    }
    #printPageButton {
        display: none;
    }
    #print .block-2 .preview_logo .side_block {
        box-shadow: none !important;
    }
    #print #chartcontainer {
        height: 100%;
        position: relative;
        top: 50%;
    }
    #editAnnotation span {
        display: inline !important;
    }
}
#chartcontainer {
    margin-top: 25px;
}
#chartcontainer_svg {
    overflow: visible;
}
#editAnnotation {
    float: right;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
}
.scatter .buttonSelect {
    position: absolute;
    top: -47px;
    right: 0px;
}
.cancel-link-chart {
    float: right !important;
    margin-top: -88px !important;
    margin-right: 175px !important;
    z-index: 100;
    position: relative;
}

#chartSpline_Series_0_Point_2_Symbol {
    -webkit-animation: opac 1s ease-out infinite;
    animation: opac 1s ease-out infinite;
}

#chartSpline_Series_2_Point_0_Symbol {
    -webkit-animation: opac 1s ease-out infinite;
    animation: opac 1s ease-in-out infinite;
}

@keyframes opac {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 10px;
    }
}
@page {
    margin: 0mm auto;
}
</style>
