<template>
    <div>
        <Header v-bind:showVersionToggle="false" />

        <router-view></router-view>

        <Footer />
    </div>
</template>

<script>
// import router from '../../router';
import Header from '../Header';
import Footer from '../Footer';

export default {
    name: 'datasources',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            SelectDataSources: false,
            dataSetList: false,
            addDataSet: false,
            dataSetColumns: false,
            showHeaderSelectDataSources: false,
            showHeaderdataSetList: false,
            showHeaderaddDataSet: false,
            showHeaderdataSetColumns: false,
        };
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        // navigateDataSetList(){
        //   router.push(`/datasetdefinition/datasetlist`)
        // }
        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('SelectDataSources')) {
                    vm.SelectDataSources = true;
                }
                if (element.PageName.includes('DataSetList')) {
                    vm.dataSetList = true;
                }
                if (element.PageName.includes('AddDataSet')) {
                    vm.addDataSet = true;
                }

                if (
                    this.$route.name === 'selectdatasources' &&
                    this.SelectDataSources
                ) {
                    (this.showHeaderSelectDataSources = true),
                        (this.showHeaderdataSetList = false),
                        (this.showHeaderaddDataSet = false),
                        (this.showHeaderdataSetColumns = false);
                }
                if (
                    this.$route.name === 'selectdatasources' &&
                    !this.SelectDataSources
                ) {
                    (this.showHeaderSelectDataSources = false),
                        (this.showHeaderdataSetList = false),
                        (this.showHeaderaddDataSet = false),
                        (this.showHeaderdataSetColumns = false);
                }
                if (this.$route.name === 'datasetlist' && !this.dataSetList) {
                    (this.showHeaderSelectDataSources = false),
                        (this.showHeaderdataSetList = false),
                        (this.showHeaderaddDataSet = false),
                        (this.showHeaderdataSetColumns = false);
                }
                if (this.$route.name === 'adddataset' && !this.addDataSet) {
                    (this.showHeaderSelectDataSources = false),
                        (this.showHeaderdataSetList = false),
                        (this.showHeaderaddDataSet = false),
                        (this.showHeaderdataSetColumns = false);
                }
                if (
                    this.$route.name === 'datasetcolumns' &&
                    !this.dataSetColumns
                ) {
                    (this.showHeaderSelectDataSources = false),
                        (this.showHeaderdataSetList = false),
                        (this.showHeaderaddDataSet = false),
                        (this.showHeaderdataSetColumns = false);
                }
            });
        },
    },
};
</script>

<style></style>
