<template>
    <!-- code to access EM Main Map through button -->

    <div class="full-height">
        <b-row class="topToolbar d-flex align-items-center">
            <b-col cols="2" class="d-flex justify-content-start">
                <a class="float-left pl-2"
                    ><img :src="projectLogo" class="logoStyle"
                /></a>
            </b-col>
            <b-col cols="8" class="d-flex justify-content-center heightInherit">
                <span
                    class="d-flex align-items-center btn-hover"
                    ref="drawBorder"
                >
                    <button
                        @click="togglePane('draw', true)"
                        ref="drawButton"
                        type="button"
                        class="btn btn-exit"
                    >
                        <img class="pb-1" src="../assets/draw_white.svg" />Draw
                    </button>
                </span>
                <span
                    class="d-flex align-items-center btn-hover"
                    ref="measureBorder"
                >
                    <button
                        @click="togglePane('measure', true)"
                        ref="measureButton"
                        type="button"
                        class="btn btn-exit"
                    >
                        <img class="pb-1" src="../assets/measure_icon.svg" />
                        Measure
                    </button>
                </span>
                <span class="d-flex align-items-center btn-hover">
                    <button
                        type="button"
                        class="btn btn-exit"
                        @click="openDataGridModal"
                    >
                        <img
                            class="pb-1"
                            src="../assets/gridviewer_white.svg"
                        />
                        Data Grid Viewer
                    </button>
                </span>
                <!-- modal content for additional layers -->
                <b-modal
                    id="modal-dg"
                    hide-footer
                    title="Data Grid Viewer"
                    modal-class="modalFullscreen"
                >
                    <div class="modalScreen">
                        <dynamic-data-grid
                            :tbl="EMDataGrid"
                            @zoomTo="zoomTo"
                            @highlight="highlightGeometry"
                            @close="hideDataGrid"
                        />
                    </div>
                </b-modal>
                <b-modal
                    id="modal-revLook"
                    ref="modal-revLook"
                    hide-footer
                    title="Receptor Lookup Results Viewer"
                    modal-class="modalFullscreen"
                >
                    <div class="modalScreen">
                        <dynamic-data-grid
                            :tbl="reverseLookupTable"
                            @zoomTo="zoomTo"
                            @highlight="highlightGeometry"
                        />
                    </div>
                </b-modal>
                <b-modal
                    id="modal-powerbi"
                    ref="modal-powerbi"
                    hide-footer
                    title="Emergency Response PowerBI Dashboard"
                    modal-class="modalFullscreen"
                    v-if="projectType == 'Emergency response'"
                >
                    <div class="modalScreen">
                        <PowerBI :showPortalBar="false"></PowerBI>
                    </div>
                </b-modal>
                <!-- modal content -->
                <span
                    class="d-flex align-items-center btn-hover"
                    ref="printBorder"
                >
                    <button
                        @click="togglePane('print', true)"
                        ref="printButton"
                        type="button"
                        class="btn btn-exit"
                    >
                        <img class="pb-1" src="../assets/print_white.svg" />
                        Print
                    </button>
                </span>
                <span
                    class="d-flex align-items-center btn-hover"
                    v-if="projectType == 'Emergency response'"
                >
                    <button
                        @click="openPowerBiDashboard"
                        ref="powerbiButton"
                        type="button"
                        class="btn btn-exit"
                    >
                        <img
                            class="pb-1 powerIcon"
                            src="../assets/powerbi_portal_white.svg"
                        />
                        PowerBI
                    </button>
                </span>
            </b-col>
            <b-col cols="2" class="d-flex justify-content-end heightInherit">
                <!-- <div class="version-toggle-preview ml-auto" right>
                    <span>Try the new ADAPT 2.0 (Preview)</span>
                    <label class="switch">
                        <input
                            type="checkbox"
                            id="customSwitch"
                            @click="navigateToOneMap"
                        />
                        <span class="slider round"></span>
                    </label>
                </div> -->
                <span class="d-flex align-items-center btn-hover">
                    <!-- <button @click="navigateToOneMap">Preview Version</button> -->
                    <button
                        type="button"
                        class="btn btn-exit"
                        @click="navigateMapView"
                    >
                        <img class="pb-1" src="../assets/exit_white.svg" /> Exit
                    </button>
                </span>
            </b-col>
        </b-row>
        <b-container fluid class="fill-height">
            <b-row class="rowHeight">
                <b-col cols="12" class="mapWidth">
                    <div id="leftSidenav" class="leftSidebar EM">
                        <b-tabs active-nav-item-class="navHeader" justified>
                            <b-tab
                                title="Map Layers"
                                active
                                style="height: 100%"
                                ref="tocTab"
                            >
                                <!-- keep this here to handle the idetnify Icon, glitches when there isn't another active icon on the screen -->
                                <img
                                    style="display: none"
                                    class="img-layers identifyActive pb-1"
                                    src="../assets/identify_active.svg"
                                />
                                <b-card>
                                    <content-table />
                                </b-card>
                            </b-tab>
                            <b-tab
                                title-link-class="navHeader"
                                title="Attributes"
                                ref="attributeTab"
                            >
                                <b-card>
                                    <map-identify-results
                                        ref="identifyResults"
                                        :identifiedDataJson="identifyAttributes"
                                        :isEditing="isEditingGeometry"
                                        :hasHighlight="hasHighlight"
                                        @zoomTo="zoomTo"
                                        @highlight="highlightGeometry"
                                        @reverseLookup="reverseLookupQuery"
                                        @clearResults="clearIdentifyResults"
                                        @clearHighlight="clearHighlight"
                                    />
                                </b-card>
                            </b-tab>
                            <b-tab
                                title-link-class="navHeader"
                                title="GeoVectors"
                                ref="geoVectorTab"
                            >
                                <div
                                    id="vector-wrapper"
                                    style="height: calc(100% - 6vh - 47px); width: 100%; top: 47px; position: absolute;"
                                >
                                    <vector-planning />
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <div style="height: 100%" id="main">
                        <span
                            class="leftToolBar"
                            id="leftToolBar"
                            @click="toggleLeftSidebar"
                        >
                            <img
                                src="../assets/maplayers.svg"
                                id="mapLayers"
                                class="mapLayers"
                            />
                            <img
                                v-if="!leftSidebarOpen"
                                src="../assets/EM_arrow_right.svg"
                                id="sidebarToggle"
                                class="sidebarToggle"
                            />
                            <img
                                v-if="leftSidebarOpen"
                                src="../assets/EM_arrow_left.svg"
                                id="sidebarToggle"
                                class="sidebarToggle"
                            />
                        </span>
                        <draw-pane
                            ref="drawPane"
                            :showPane="paneVisibility['draw']"
                            :midXPosition="drawButtonMidX"
                            :projectId="projectID"
                            :clickFunction="clickFunction"
                            @updateClickFunction="updateClickFunction"
                        ></draw-pane>
                        <measure-pane
                            ref="measurePane"
                            :showPane="paneVisibility['measure']"
                            :midXPosition="measureButtonMidX"
                            :clickFunction="clickFunction"
                            @updateClickFunction="updateClickFunction"
                        ></measure-pane>
                        <print-pane
                            ref="printPane"
                            :showPane="paneVisibility['print']"
                            :midXPosition="printButtonMidX"
                            :mapCenter="center"
                            :mapZoom="zoom"
                            :mapLayers="layersStruct"
                            :mapExtent="mapBounds"
                            :mapSize="mapSize"
                            :ProjectID="projectID"
                        ></print-pane>
                        <attribute-modal
                            ref="attributePane"
                            :showEditButtons="isEditingAttributes"
                            :projectID="projectID"
                            @updateGeometryLayer="updateERDataEditTime"
                            @deleteGeometry="updateERDataEditTime"
                            @editGeometry="startEditGeometry"
                        />
                        <l-map
                            ref="lmap"
                            class="mapClass"
                            :options="mapOptions"
                            :min-zoom="minZoom"
                            :max-zoom="maxZoom"
                            :zoom.sync="zoom"
                            :center.sync="center"
                            :class="{
                                identifyCursor: isIdentifyActive,
                                drawCursor:
                                    ['polygon', 'line', 'point'].includes(
                                        clickFunction
                                    ) ||
                                    clickFunction.startsWith('measure') ||
                                    activeMapTool == 'vectorStartPoint',
                            }"
                            @mousemove="updateMouseCoords"
                            @ready="initializeMap"
                            @click="handleClick"
                            @dblclick="handleDblClick"
                            @update:bounds="updateBounds"
                            @update:zoom="updateZoom"
                            @update:center="updateCenter"
                        >
                            <l-control position="topleft">
                                <button
                                    type="button"
                                    ref="layersButton"
                                    class="btn btn-layers"
                                    @click="togglePane('layers')"
                                >
                                    <img
                                        v-if="!mapLayersOpen"
                                        class="img-layers"
                                        src="../assets/layers_white.svg"
                                    />
                                    <img
                                        v-if="mapLayersOpen"
                                        class="img-layers"
                                        src="../assets/layers_arrow_white.svg"
                                    />
                                </button>
                            </l-control>
                            <l-control-address-search
                                :isExpanded="paneVisibility['addressSearch']"
                                @toggleSearchBar="togglePane('addressSearch')"
                            ></l-control-address-search>
                            <l-control-identify
                                position="topleft"
                                :isActive="clickFunction == 'identify'"
                                :mapLayers="wmsLayers"
                                :mapZoom="zoom"
                                @identifyCompleted="populateAttributesPane"
                                @identifyIcon="toggleIdentifyIcon"
                                @clearIdBuffer="clearIdBuffer"
                            >
                                <template v-slot:identifyIcon>
                                    <img
                                        v-if="clickFunction == 'identify'"
                                        class="img-layers pb-1"
                                        src="../assets/identify_active.svg"
                                    />
                                    <img
                                        v-else
                                        class="img-layers pb-1"
                                        src="../assets/identify.svg"
                                    />
                                </template>
                            </l-control-identify>
                            <l-control-zoom position="topleft">
                                <template v-slot:plusIcon
                                    ><img
                                        class="img-layers pb-1"
                                        src="../assets/EM_plus.svg"
                                /></template>
                                <template v-slot:minusIcon
                                    ><img
                                        class="img-layers pt-1"
                                        src="../assets/EM_minus.svg"
                                /></template>
                            </l-control-zoom>
                            <l-control-edit
                                position="topright"
                                :editing="isEditingGeometry"
                                :editingGeometry="editGeometry"
                                @updateGeometry="updateEditGeometry"
                                @stopGeometryEdit="clearEditGeometry"
                            >
                            </l-control-edit>
                            <!-- <l-control position= "topleft" >
                            <button
                                type="button"
                                class="btn btn-white"
                                >
                                <img  class="img-layers" src="../assets/findmylocation.svg"/>
                            </button> 
                        </l-control> -->
                            <l-control
                                position="topleft"
                                v-if="showSearchAndZoom"
                            >
                                <button
                                    type="button"
                                    class="btn btn-white"
                                    @click="togglePane('searchAndZoom')"
                                >
                                    <img
                                        class="img-layers"
                                        src="../assets/searchmilepost.svg"
                                    />
                                </button>
                            </l-control>
                            <search-and-zoom
                                v-if="showSearchAndZoom"
                                ref="searchandzoom"
                                :showPane="paneVisibility['searchAndZoom']"
                                @noQuery="showSearchAndZoom = false"
                            >
                            </search-and-zoom>
                            <l-control-mouse-coords
                                :mouseCoords="mouseCoords"
                            />
                            <l-control-attribution
                                :position="attributionPosition"
                                :prefix="attributionPrefix"
                            />
                            <l-control-scale
                                :unitSystem="scalebarUnits"
                                v-bind:max-width="135"
                            />
                            <l-control position="bottomleft">
                                <button
                                    type="button"
                                    ref="legendButton"
                                    class="btn btn-white"
                                    @click="togglePane('legend')"
                                >
                                    <img
                                        class="img-layers"
                                        src="../assets/legend.svg"
                                    />
                                </button>
                            </l-control>
                            <legend-pane
                                ref="legendPane"
                                :showPane="paneVisibility['legend']"
                                :wmsLayers="wmsLayers"
                                :bbox="mapBounds"
                                :mapSize="mapSize"
                                :scalebarUnits="scalebarUnits"
                                :zoomLevel="zoom"
                            >
                            </legend-pane>
                            <map-layers
                                ref="layersPane"
                                :showPane="paneVisibility['layers']"
                                :mapLayers="wmsLayers"
                            >
                            </map-layers>
                            <l-tile-layer
                                v-for="referenceLayer in baseMapReferences"
                                :key="referenceLayer.name"
                                :name="referenceLayer.name"
                                :visible="selectedBasemap == 1"
                                :attribution="referenceLayer.attribution"
                                :url="referenceLayer.url"
                                layer-type="base"
                            ></l-tile-layer>
                            <l-tile-layer
                                :key="baseMap.name"
                                :name="baseMap.name"
                                :visible="baseMap.visible"
                                :attribution="baseMap.attribution"
                                :url="baseMap.url"
                                layer-type="base"
                                :options="baseMapOptions"
                            ></l-tile-layer>
                            <!-- WMTS requests -->
                            <l-tile-layer
                                v-for="wmts in WMTS_requests"
                                :key="wmts.name"
                                :name="wmts.name"
                                :visible="wmts.visible"
                                :attribution="wmts.attribution"
                                :url="wmts.wmts_url"
                                :tileSize="2048"
                                layer-type="overlay"
                            ></l-tile-layer>
                            <!-- WMS requests -->
                            <l-wms-tile-layer
                                v-for="wms in WMS_requests"
                                :ref="'wms-layer-' + wms.name.replace(/ /g, '')"
                                :key="wms.id"
                                :name="wms.name"
                                :base-url="wms.url"
                                :visible="wms.isChecked"
                                :layers="wms.layers"
                                :styles="wms.styles"
                                :options="wms.options"
                                :format="wms.format"
                                :opacity="wms.opacity"
                                :z-index="wms.zIndex"
                                :tileSize="wms.tileSize"
                                :transparent="wms.transparent"
                                :attribution="wms.attribution"
                                layer-type="overlay"
                            ></l-wms-tile-layer>
                            <vector-features />
                            <l-feature-group
                                ref="editFeatureGroup"
                                layer-type="overlay"
                                name="Edit Layer"
                            >
                                <!-- circle is for the buffer(s) tool, circles maintain their correct radius, 
                                circlemarkers depend on the zoom level-->
                                <l-circle
                                    v-for="marker in bufferMarkers"
                                    :key="marker.id"
                                    :lat-lng="marker.geometry.coordinates"
                                    :radius="
                                        parseFloat(marker.styling.SymbologySize)
                                    "
                                    :color="marker.styling.PolygonBorderColour"
                                    :fillColor="marker.styling.PolygonColour"
                                    :dashArray="marker.styling.PolygonStyle"
                                />
                                <l-marker
                                    v-for="marker in editVertices"
                                    :key="marker.id"
                                    :lat-lng="marker.geometry.coordinates"
                                    :icon="marker.icon"
                                    v-bind:draggable="true"
                                    @drag="
                                        editVertexDrag(
                                            marker.id,
                                            $event.target._latlng
                                        )
                                    "
                                />
                                <!-- circle markers radius is in pixels, vs circles which are in meters -->
                                <l-circle-marker
                                    v-for="marker in drawMarkers"
                                    :key="marker.id"
                                    :lat-lng="marker.geometry.coordinates"
                                    :radius="marker.styling.SymbologySize"
                                    :fillColor="marker.styling.SymbologyColour"
                                    :fillOpacity="
                                        1 - marker.styling.PolygonTransparency
                                    "
                                    :color="marker.styling.SymbologyColour"
                                />
                                <l-polyline
                                    v-for="line in drawLines"
                                    :key="line.id"
                                    :lat-lngs="line.geometry.coordinates"
                                    :color="line.styling.PolygonBorderColour"
                                />
                                <l-polygon
                                    v-for="polygon in drawPolygons"
                                    :key="polygon.id"
                                    :lat-lngs="polygon.geometry.coordinates[0]"
                                    :color="polygon.styling.PolygonBorderColour"
                                    :fillColor="
                                        polygon.styling.PolygonFillColour
                                    "
                                    :opacity="
                                        1 - polygon.styling.PolygonTransparency
                                    "
                                />
                            </l-feature-group>
                        </l-map>
                        <span
                            class="rightToolBar"
                            id="rightToolBar"
                            @click="toggleRightSidebar"
                        >
                            <img
                                src="../assets/event_panel.svg"
                                class="eventPanel"
                            />
                            <img
                                v-if="!rightSidebarOpen"
                                src="../assets/EM_arrow_left.svg"
                                class="sidebarToggle"
                            />
                            <img
                                v-if="rightSidebarOpen"
                                src="../assets/EM_arrow_right.svg"
                                class="sidebarToggle"
                            />
                        </span>
                    </div>
                    <div id="rightSidenav" class="rightSidebar">
                        <common-operating-picture
                            @zoomTo="zoomTo"
                            @fmResize="toggleRightSidebar"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// import { marker, latLngBounds, circleMarker, divIcon} from "leaflet";
import { latLngBounds } from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Vue from 'vue';
import {
    LCircle,
    LCircleMarker,
    LControl,
    LControlAttribution,
    LFeatureGroup,
    LMap,
    LMarker,
    LPolygon,
    LPolyline,
    LTileLayer,
    LWMSTileLayer,
} from 'vue2-leaflet';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import router from '../../router';
import AttributeModal from '../components/CommonComponents/AttributeModal.vue';
import MapIdentifyResults from '../components/CommonComponents/MapIdentifyResults.vue';
import LControlEdit from '../components/CommonComponents/Mapping/LeafletGeometryEdit.vue';
import LControlIdentify from '../components/CommonComponents/Mapping/LeafletIdentifyControl.vue';
import LControlZoom from '../components/CommonComponents/Mapping/LeafletZoomControl.vue';
import CommonOperatingPicture from '../components/EmergencyManagement/CommonOperatingPicture.vue';
import ContentTable from '../components/EmergencyManagement/ContentTable.vue';
import DrawPane from '../components/EmergencyManagement/DrawPane.vue';
import LControlAddressSearch from '../components/EmergencyManagement/LeafletAddressSearch.vue';
import LControlMouseCoords from '../components/EmergencyManagement/LeafletMouseCoordinates.vue';
import LControlScale from '../components/EmergencyManagement/LeafletScale.vue';
import LegendPane from '../components/EmergencyManagement/LegendPane.vue';
import MapLayers from '../components/EmergencyManagement/MapLayers.vue';
import MeasurePane from '../components/EmergencyManagement/MeasurePane.vue';
import PrintPane from '../components/EmergencyManagement/PrintPane.vue';
import { spatialFunctions } from '../utilities/spatialFunctions.js';
Vue.component('modal', {
    template: '#modal-template',
});
import SearchAndZoom from '../components/EmergencyManagement/SearchandZoom.vue';
import DynamicDataGrid from '../components/CommonComponents/DynamicDataGrid.vue';
import PowerBI from '../components/PowerBI/PowerBI.vue';
import { datasetService } from '../services/dataset.service';
import { spatialService } from '../services/spatial.services';
// import { LMap, LTileLayer, LMarker, LControlLayers, LControlZoom, LControl } from 'vue2-leaflet'
import {
    CommandColumn,
    Filter,
    Freeze,
    Grid,
    Page,
    Resize,
    Search,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-vue-grids';
import { mapLayerFunctions } from '../../utilities/mapLayerFunctions';
import { projectService } from '../services/project.service';
import VectorPlanning from '../../components/projects/layers/vectors/VectorPlanning.vue';
import VectorFeatures from '@/components/projects/layers/vectors/VectorFeatures.vue';

// import { SidebarComponent, SidebarPlugin } from '@syncfusion/ej2-vue-navigations'

// Vue.component(SidebarPlugin.name, SidebarComponent)

export default {
    components: {
        LMap,
        LTileLayer,
        LControl,
        LCircle,
        LCircleMarker,
        LPolyline,
        LControlAttribution,
        LPolygon,
        LFeatureGroup,
        LControlZoom,
        LControlAddressSearch,
        LControlIdentify,
        DrawPane,
        MeasurePane,
        PrintPane,
        LControlMouseCoords,
        LControlScale,
        AttributeModal,
        MapLayers,
        CommonOperatingPicture,
        ContentTable,
        MapIdentifyResults,
        LegendPane,
        'l-wms-tile-layer': LWMSTileLayer,
        LControlEdit,
        LMarker,
        SearchAndZoom,
        DynamicDataGrid,
        PowerBI,
        VectorPlanning,
        VectorFeatures,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Filter,
            Freeze,
            CommandColumn,
            Search,
            Toolbar,
            Grid,
            Resize,
        ],
    },
    name: 'mappanel',
    data() {
        return {
            max_tile_zoom: 14,
            mapOptions: {
                preferCanvas: false,
                zoomControl: false,
                dragging: true,
                attributionControl: false,
                doubleClickZoom: false,
            },
            geosearchOptions: {
                position: 'topleft',
                provider: new OpenStreetMapProvider(),
                keepResult: true,
                showMarker: false,
                showPopup: false,
                searchLabel: 'Search Map',
            },
            isLayersModalVisible: false,
            isEditingAttributes: false,
            leftSidebarOpen: false,
            rightSidebarOpen: false,
            mapLayersOpen: false,
            isDataGridOpen: false,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            mapBounds: {
                _northEast: { lat: 68.813939, lng: -29.970703 },
                _southWest: { lat: -11.011305, lng: -172.88086 },
            },
            mouseCoords: '',
            clickFunction: '',
            polylineLength: 0,
            polygonArea: 0,
            editGeometryGeom: {},
            bufferMarkers: [],
            drawMarkers: [],
            drawLines: [],
            editVertices: [],
            drawPolygons: [],
            isEditingGeometry: false,
            editArray: null,
            editIndex: null,
            editGeometry: null,
            drawButtonMidX: 0,
            // the legend is separate since we can have it and other top-panes visible at the same time
            showLegendPane: false,
            printButtonMidX: 0,
            measureButtonMidX: 0,
            identifyAttributes: [],
            showRadar: false,
            isIdentifyActive: false,
            zoom: 10,
            minZoom: 1,
            maxZoom: 21,
            center: { lat: 43.372394, lng: -79.472351 },
            paneVisibility: {
                draw: false,
                measure: false,
                print: false,
                layers: false,
                searchAndZoom: false,
                legend: false,
                mapLayers: false,
                addressSearch: false,
            },
            // the legend is separate since we can have it and other top-panes visible at the same time
            attributionPosition: 'bottomright',
            attributionPrefix: 'GHD',
            baseMaps: [
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'Satellite_Imagery',
                    visible: true,
                    url:
                        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
            ],
            baseMapReferences: [
                {
                    name: 'transportation',
                    url:
                        'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
                    attribution: '',
                },
                {
                    name: 'boundaries_and_places',
                    url:
                        'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
                    attribution: '',
                },
            ],
            showSearchAndZoom: true,
            selectedBasemap: 0,
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 29,
            },
            fileManagerOpen: false,
            scalebarUnits: 'both', // both, metric, imperial
            openMenuTab: 'Event Overview',
            reverseLookupTable: [],
            datasetList: [],
            EMDataGrid: {},
            PowerBIReportID: null,
            NSToken: null,
            erUpdateTime: 0,
            lastReverseLookup: {
                layerID: -1,
                featureID: -1,
            },
            authKeyInterval: null,
            gwcTokenInterval: null,
        };
    },
    methods: {
        ...mapActions('emergencyManagement', [
            'setProjectID',
            'buildLayersStruct',
        ]),
        ...mapActions('common', ['setForceRefreshMap']),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        navigateToOneMap() {
            router.push(`/map`);
        },
        /**
         * Calculates the number of milliseconds until midnight.
         *
         * @returns {number} The number of milliseconds until midnight.
         */
        calculateMilliSecondsUntilMidnight() {
            // get the current time
            const now = new Date();

            const nowUTC = new Date(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds()
            );
            const midnightUTC = new Date(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate() + 1,
                0,
                0,
                0
            );
            const millisecondsUntilMidnight = midnightUTC - nowUTC;
            return millisecondsUntilMidnight;
        },
        startEditGeometry(feature) {
            this.setShowAttributeModal(false);
            this.editGeometry = {
                id: 'E1',
                geometry: feature.geometry,
                styling: {
                    PolygonBorderColour: 'green',
                    PolygonTransparency: 0.8,
                },
            };
            this.isEditingGeometry = true;
            this.isEditingAttributes = true;
        },
        refreshERLayers() {
            this.erLayerRefs.forEach((lyrRef) => {
                if (this.$refs['wms-layer-' + lyrRef].length) {
                    this.$refs['wms-layer-' + lyrRef][0].mapObject.wmsParams[
                        'editTime'
                    ] = this.erUpdateTime;
                    this.$refs['wms-layer-' + lyrRef][0].mapObject.redraw();
                }
            });
        },
        updateERDataEditTime(geometry) {
            switch (geometry.geomType) {
                case 'POLYGON':
                    this.drawPolygons.pop();
                    break;
                case 'LINESTRING':
                    this.drawLines.pop();
                    break;
                case 'POINT':
                    this.drawMarkers.pop();
                    break;
            }
            setTimeout(() => {
                this.erUpdateTime = Math.floor(Date.now() / 1000);
                this.refreshERLayers();
            }, 50);
        },
        updateMapSize() {
            setTimeout(() => {
                this.$store.commit(
                    'projects/mutateMapSize',
                    this.$refs.lmap.mapObject._size
                );
            }, 500);
        },
        updateBounds(bounds) {
            this.mapBounds = bounds;
            this.$store.commit('projects/updateMapExtent', bounds);
        },
        updateCenter(center) {
            this.$store.commit('projects/updateMapCenter', center);
        },
        updateZoom(zoom) {
            this.$store.commit('projects/updateMapZoom', zoom);
        },
        zoomTo(feature) {
            this.$refs.lmap.mapObject.fitBounds(
                latLngBounds(spatialFunctions.calculateBounds(feature)),
                {
                    maxZoom: 17,
                }
            );
        },
        highlightGeometry(feature) {
            this.clearHighlight();
            feature.styling = {
                SymbologyColour: '#d4fc16',
                SymbologySize: 3,
                PolygonColour: '#d4fc16',
                PolygonTransparency: 0.2,
            };
            switch (feature.geometry.type) {
                case 'Point':
                    feature.id = 'H' + this.drawMarkers.length;
                    this.drawMarkers.push(feature);
                    break;
                case 'LineString':
                    feature.id = 'H' + this.drawLines.length;
                    this.drawLines.push(feature);
                    break;
                case 'Polygon':
                    feature.id = 'H' + this.drawPolygons.length;
                    this.drawPolygons.push(feature);
                    break;
            }
        },
        clearIdentifyResults() {
            this.identifyAttributes = [];
            this.clearIdBuffer();
        },
        clearIdBuffer() {
            this.bufferMarkers = this.bufferMarkers.filter((feature) => {
                feature.id != 'idBuffer';
            });
        },
        clearHighlight() {
            this.drawMarkers = this.drawMarkers.filter(
                (feature) => !feature.id.startsWith('H')
            );
            this.drawLines = this.drawLines.filter(
                (feature) => !feature.id.startsWith('H')
            );
            this.drawPolygons = this.drawPolygons.filter(
                (feature) => !feature.id.startsWith('H')
            );
        },
        updateEditGeometry(coordinates) {
            switch (this.editArray) {
                case 'drawMarkers':
                    this.editVertices[
                        this.editIndex
                    ].geometry.coordinates = coordinates;
                    break;
                case 'drawLines':
                    this.drawLines[
                        this.editIndex
                    ].geometry.coordinates = coordinates;
                    break;
                case 'drawPolygons':
                    this.drawPolygons[
                        this.editIndex
                    ].geometry.coordinates = coordinates;
                    break;
            }
            this.editGeometry.vertices = coordinates;
        },
        toggleLeftSidebar() {
            var screenWidth = window.innerWidth;
            if (screenWidth > 1200) {
                // large screen
                if (this.leftSidebarOpen == true) {
                    try {
                        document.getElementById('leftSidenav').style.width =
                            '0';
                        document.getElementById('leftToolBar').style.left = '0';
                        document.getElementById('main').style.marginLeft = '0';
                    } catch (e) {
                        //nothing
                    }
                } else {
                    try {
                        document.getElementById('leftSidenav').style.width =
                            '405px';
                        document.getElementById('leftToolBar').style.left =
                            '405px';
                        document.getElementById('main').style.marginLeft =
                            '405px';
                    } catch (e) {
                        //nothing
                    }
                }
                this.leftSidebarOpen = !this.leftSidebarOpen;
            } else if (1000 < screenWidth && screenWidth < 1200) {
                // small screen
                if (this.leftSidebarOpen == true) {
                    try {
                        document.getElementById('leftSidenav').style.width =
                            '0';
                        document.getElementById('leftToolBar').style.left = '0';
                        document.getElementById('main').style.marginLeft = '0';
                    } catch (e) {
                        //nothing
                    }
                } else {
                    try {
                        document.getElementById('leftSidenav').style.width =
                            '350px';
                        document.getElementById('leftToolBar').style.left =
                            '350px';
                        document.getElementById('main').style.marginLeft =
                            '350px';
                    } catch (e) {
                        //nothing
                    }
                }
                if (this.rightSidebarOpen == true) {
                    this.toggleRightSidebar();
                }
                this.leftSidebarOpen = !this.leftSidebarOpen;
            } else {
                // ipad screen
                if (this.leftSidebarOpen == true) {
                    try {
                        document.getElementById('leftSidenav').style.width =
                            '0';
                        document.getElementById('leftToolBar').style.left = '0';
                        document.getElementById('main').style.marginLeft = '0';
                    } catch (e) {
                        //nothing
                    }
                } else {
                    try {
                        document.getElementById('leftSidenav').style.width =
                            '300px';
                        document.getElementById('leftToolBar').style.left =
                            '300px';
                        document.getElementById('main').style.marginLeft =
                            '300px';
                    } catch (e) {
                        //nothing
                    }
                }
                if (this.rightSidebarOpen == true) {
                    this.toggleRightSidebar();
                }
                this.leftSidebarOpen = !this.leftSidebarOpen;
            }
        },
        toggleRightSidebar(payload) {
            var screenWidth = window.innerWidth;
            //file manager is the set tool open and needs to reopen to full size
            if (
                payload == event &&
                this.openMenuTab == 'File Manager' &&
                this.rightSidebarOpen == false
            ) {
                document.getElementById('rightSidenav').style.width = '805px';
                document.getElementById('rightToolBar').style.right = '805px';
                document.getElementById('main').style.marginRight = '805px';
                this.rightSidebarOpen = !this.rightSidebarOpen;
            } else if (payload == event) {
                if (this.rightSidebarOpen == true) {
                    document.getElementById('rightSidenav').style.width = '0';
                    document.getElementById('rightToolBar').style.right = '0';
                    document.getElementById('main').style.marginRight = '0';
                    this.rightSidebarOpen = !this.rightSidebarOpen;
                } else {
                    document.getElementById('rightSidenav').style.width =
                        '505px';
                    document.getElementById('rightToolBar').style.right =
                        '505px';
                    document.getElementById('main').style.marginRight = '505px';
                    this.rightSidebarOpen = !this.rightSidebarOpen;
                }
            } else if (payload == 'File Manager') {
                this.openMenuTab = payload;
                //need to handle the resize here
                if (this.rightSidebarOpen == true) {
                    document.getElementById('rightSidenav').style.width =
                        '805px';
                    document.getElementById('rightToolBar').style.right =
                        '805px';
                    document.getElementById('main').style.marginRight = '805px';
                    this.fileManagerOpen = true;
                    this.rightSidebarOpen = true;
                } else {
                    document.getElementById('rightSidenav').style.width =
                        '805px';
                    document.getElementById('rightToolBar').style.right =
                        '805px';
                    document.getElementById('main').style.marginRight = '805px';
                }
            } else {
                this.openMenuTab = payload;
                if (this.rightSidebarOpen == true) {
                    //changing from file manager to other tool, don't set the sidebar to closed
                    document.getElementById('rightSidenav').style.width =
                        '505px';
                    document.getElementById('rightToolBar').style.right =
                        '505px';
                    document.getElementById('main').style.marginRight = '505px';
                } else {
                    document.getElementById('rightSidenav').style.width =
                        '505px';
                    document.getElementById('rightToolBar').style.right =
                        '505px';
                    document.getElementById('main').style.marginRight = '505px';
                    this.rightSidebarOpen = !this.rightSidebarOpen;
                }
            }
            if (payload === false) {
                this.rightSidebarOpen = false;
                document.getElementById('rightSidenav').style.width = '0';
                document.getElementById('rightToolBar').style.right = '0';
                document.getElementById('main').style.marginRight = '0';
            }
        },
        navigateMapView() {
            var screenWidth = window.innerWidth;
            if (screenWidth < 1100) {
                router.push(`/classic/project/listview`);
            } else {
                router.push(`/classic/project/mapview`);
            }
        },
        updateClickFunction(value) {
            this.clickFunction = value;
        },
        showLayersModal() {
            this.isLayersModalVisible = true;
            this.mapLayersOpen = true;
        },
        closeLayersModal() {
            this.isLayersModalVisible = false;
            this.mapLayersOpen = false;
        },
        updateMouseCoords(event) {
            var lng = event.latlng.lng;
            while (lng > 180) {
                lng -= 360;
            }
            while (lng < -180) {
                lng += 360;
            }
            this.mouseCoords =
                event.latlng.lat.toFixed(6) + ' ' + lng.toFixed(6);
        },
        initializeMap() {
            this.initializeMouseCoords();
            this.updateMapSize();
            window.addEventListener('resize', this.updateMapSize);
        },
        initializeMouseCoords() {
            this.mouseCoords = this.center[0] + ' ' + this.center[1];
        },
        handleClick(event) {
            if (this.clickFunction !== '') {
                this.$emit('map/click', event);
            } else if (this.activeMapTool == 'vectorStartPoint') {
                this.$store.commit('oneMap/mutateClickCoords', event.latlng);
            }
        },
        handleDblClick(event) {
            if (this.clickFunction !== '') {
                this.$emit('map/dblClick', event);
            }
        },
        clearEditGeometry(geom) {
            this.updateIdentifiedGeometry(geom);
            this.isEditingGeometry = false;
            this.setShowAttributeModal(true);
        },
        editVertexDrag(id, coords) {
            const updatedVertex = {
                id: id,
                lat: coords.lat,
                lng: coords.lng,
            };
            this.$emit('editVertex/updateLatLng', updatedVertex);
        },
        // update position of all drawn panes on the map that rely on the topbar
        updatePanePositions() {
            this.drawButtonMidX = this.getButtonMidX('drawButton');
            this.measureButtonMidX = this.getButtonMidX('measureButton');
            this.printButtonMidX = this.getButtonMidX('printButton');
        },
        // moved the math to a separate function to make it more readable and more easily callable
        getButtonMidX(buttonRef) {
            return Math.round(
                this.$refs[buttonRef].getBoundingClientRect().left +
                    (this.$refs[buttonRef].getBoundingClientRect().right -
                        this.$refs[buttonRef].getBoundingClientRect().left) /
                        2
            );
        },
        togglePane(triggeringPane, closeSidebars = false) {
            this.updatePanePositions();
            // the legend pane is far away from the others, can just toggle it independently
            Object.entries(this.paneVisibility).map(([key, value]) => {
                // just want to toggle the calling pane, not always show or hide
                if (key != triggeringPane) {
                    this.paneVisibility[key] = false;
                    //add the active class and remove hover class
                    try {
                        this.$refs[key + 'Border'].classList.add('btn-hover');
                        this.$refs[key + 'Border'].classList.remove(
                            'btn-active'
                        );
                    } catch (e) {
                        e;
                    }
                } else {
                    this.paneVisibility[key] = !value;
                    //add the hover class and remove active class
                    try {
                        if (
                            this.$refs[key + 'Border'].classList.contains(
                                'btn-active'
                            )
                        ) {
                            this.$refs[key + 'Border'].classList.add(
                                'btn-hover'
                            );
                            this.$refs[key + 'Border'].classList.remove(
                                'btn-active'
                            );
                        } else {
                            this.$refs[key + 'Border'].classList.add(
                                'btn-active'
                            );
                            this.$refs[key + 'Border'].classList.remove(
                                'btn-hover'
                            );
                        }
                    } catch (e) {
                        e;
                    }
                }
            });
            // don't always close the sidebars
            if (closeSidebars) {
                if (this.leftSidebarOpen == true) {
                    this.toggleLeftSidebar();
                }
                if (this.rightSidebarOpen == true) {
                    this.toggleRightSidebar(false);
                }
            }
        },
        openDataGrid() {
            this.isDataGridOpen = true;
        },
        closeDataGrid() {
            this.isDataGridOpen = false;
        },
        // updates the geometry information for the edited feature
        updateIdentifiedGeometry(geom) {
            this.identifyAttributes.forEach((result) => {
                result.Geometry.forEach((feature) => {
                    feature.properties.forEach((prop) => {
                        if (
                            prop.label == 'GeoID' &&
                            prop.value == this.drawingAttributes.geoID
                        ) {
                            feature.geometry.coordinates =
                                geom.geometry.coordinates;
                            feature.geometry.coordSys = 'WebMerc';
                        }
                    });
                });
            });
            this.identifiedGeometry = geom;
        },
        populateAttributesPane(identifyResults) {
            this.identifyAttributes = identifyResults.filter(function(result) {
                return result !== null;
            });
            this.$refs.attributeTab.activate();
            if (!this.leftSidebarOpen) {
                this.toggleLeftSidebar();
            }
        },
        reverseLookupQuery(layerID, featureID) {
            // if it is the same as the last reverse lookup query,
            //   just show the modal, don't need to query it all again
            if (
                layerID != this.lastReverseLookup.layerID &&
                featureID != this.lastReverseLookup.featureID
            ) {
                this.lastReverseLookup = {
                    layerID: layerID,
                    featureID: featureID,
                };
                spatialService.reverseLookup(layerID, featureID).then((res) => {
                    let tbls = {};
                    Object.keys(res.data).forEach((tbl) => {
                        tbls[tbl] = {
                            data: res.data[tbl],
                            exportOption: ['ExcelExport'],
                            hasGeometry: true,
                        };
                    });
                    this.reverseLookupTable = tbls;
                    this.$refs['modal-revLook'].show();
                });
            } else {
                this.$refs['modal-revLook'].show();
            }
        },
        toggleIdentifyIcon() {
            this.isIdentifyActive = !this.isIdentifyActive;
        },
        async openDataGridModal() {
            this.EMDataGrid = {};
            let tableSelectTools = await datasetService.getTableselectools(
                this.projectID
            );
            this.datasetList = tableSelectTools.data;
            let counter = 0;
            for (let i = 0; i < this.datasetList.length; i++) {
                if (this.datasetList[i][0].DatasetType == 'StoredProcedure') {
                    let payload = {
                        DatasetId: this.datasetList[i][0].DatasetId,
                        Parameters: { '@projectid': this.projectID },
                    };
                    let StoredProcedureResults = await datasetService.getStoredProcedureResults(
                        payload
                    );
                    this.datasetList[i][0].datasetDetails =
                        StoredProcedureResults.data;
                    if (StoredProcedureResults.data.length > 0) {
                        //add logic to conditionally set excel/pdf export options, exportOption spelling needs to match exactly like syncfusion doc: ['ExcelExport', 'PdfExport']
                        //add logic to get data from api to set hasGeometry property
                        this.EMDataGrid[
                            counter + '|' + this.datasetList[i][0].DisplayName
                        ] = {
                            data: StoredProcedureResults.data,
                            exportOption: ['ExcelExport'],
                            hasGeometry: true,
                        };
                        //temp logic, to be removed when api provides exportOption and hasGeometry data;
                        // if (
                        //     this.datasetList[i][0].DisplayName == 'FieldNotes'
                        // ) {
                        //     this.EMDataGrid[
                        //         counter +
                        //             '|' +
                        //             this.datasetList[i][0].DisplayName
                        //     ].exportOption.push('PdfExport');
                        //     this.EMDataGrid[
                        //         counter +
                        //             '|' +
                        //             this.datasetList[i][0].DisplayName
                        //     ].hasGeometry = false;
                        // }
                        counter++;
                    }
                } else {
                    let payload = [
                        {
                            dataSetId: this.datasetList[i][0].DatasetId,
                            projectId: [this.projectID],
                        },
                    ];
                    let filteredData = await datasetService.GetExternalDataSorted(
                        payload
                    );
                    this.datasetList[i][0].datasetDetails = filteredData.data;
                    if (filteredData.data.length > 0) {
                        this.EMDataGrid[
                            counter + '|' + this.datasetList[i][0].DisplayName
                        ] = {
                            data: filteredData.data,
                            exportOption: ['ExcelExport'],
                            hasGeometry: true,
                        };
                        counter++;
                    }
                }
            }
            this.$bvModal.show('modal-dg');
        },
        hideDataGrid() {
            this.$bvModal.hide('modal-dg');
        },
        openPowerBiDashboard() {
            let projectID1 = this.projectID;
            // projectID1 = 12889; //remove this line later
            datasetService
                .getPowerBIDatasets(projectID1)
                .then((res) => {
                    if (res.data.length > 0) {
                        this.PowerBIReportID = res.data[0][0].DatasetId;
                        localStorage.setItem('reportId', this.PowerBIReportID);

                        this.$bvModal.show('modal-powerbi');
                    } else {
                        //use a toaster message to notify user when no PowerBi dashboard is available
                    }
                })
                .catch((err) => {});
        },
        async getNSTokenFromApi() {
            let apiReturn = await projectService.GetNSEsriToken();
            this.NSToken = apiReturn.data.token;
        },
        startERDataRefresh() {
            //refresh weather data every 20 minutes
            this.erUpdateTime = Math.floor(Date.now() / 1000);
            this.refreshERData = setInterval(() => {
                spatialService
                    .hasNewDrawings(
                        this.selectedProject.ProjectID,
                        this.erUpdateTime
                    )
                    .then((res) => {
                        let thereIsNewData = res.data;
                        if (thereIsNewData) {
                            this.erUpdateTime = Math.floor(Date.now() / 1000);
                            this.refreshERLayers();
                        }
                    });
            }, 30000);
        },
        zoomToERExtent(bufferPercentage = 10) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            if (this.selectedProjectsArr.length > 0) {
                projectService
                    .getERDataExtent(this.selectedProjectsArr[0].ProjectID)
                    .then((res) => {
                        if (!Object.keys(res.data).length) {
                            projectService
                                .getProjectExtent(
                                    this.selectedProjectsArr[0].ProjectID
                                )
                                .then((res) => {
                                    let projectExtent = res.data;
                                    minX = Math.min(minX, projectExtent.minX);
                                    maxX = Math.max(maxX, projectExtent.maxX);
                                    minY = Math.min(minY, projectExtent.minY);
                                    maxY = Math.max(maxY, projectExtent.maxY);
                                    this.zoomToBounds(
                                        minX,
                                        minY,
                                        maxX,
                                        maxY,
                                        bufferPercentage
                                    );
                                });
                        } else {
                            let erDataExtent = res.data;
                            minX = Math.min(minX, erDataExtent.minX);
                            maxX = Math.max(maxX, erDataExtent.maxX);
                            minY = Math.min(minY, erDataExtent.minY);
                            maxY = Math.max(maxY, erDataExtent.maxY);
                            this.zoomToBounds(
                                minX,
                                minY,
                                maxX,
                                maxY,
                                bufferPercentage
                            );
                        }
                    });
            }
        },
        zoomToBounds(minX, minY, maxX, maxY, bufferPercentage) {
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            var mapBounds = latLngBounds([
                [
                    spatialFunctions.correctLatitude(
                        minY - (yRange * bufferPercentage) / 100
                    ),
                    spatialFunctions.correctLongitude(
                        minX - (xRange * bufferPercentage) / 100
                    ),
                ],
                [
                    spatialFunctions.correctLatitude(
                        maxY + (yRange * bufferPercentage) / 100
                    ),
                    spatialFunctions.correctLongitude(
                        maxX + (xRange * bufferPercentage) / 100
                    ),
                ],
            ]);
            this.$refs.lmap.mapObject.fitBounds(mapBounds, { maxZoom: 16 });
        },
        /**
         * Updates the Geoserver authentication keys.
         * Retrieves the authentication keys from the project service based on the selected projects.
         * Dispatches an action to update the authentication keys in the emergencyManagement store module.
         * Calculates the refresh time for the authentication keys.
         * @returns {Promise<void>} A promise that resolves when the authentication keys are updated.
         */
        async updateGeoserverAuthkeys() {
            const authkeys = await projectService.getGeoserverAuthkeys(
                this.projectID
            );
            this.$store.commit('projects/setAuthKeys', authkeys.data.AuthKeys);
            if (authkeys.data.Expiry !== null) {
                this.$store.dispatch(
                    'emergencyManagement/updateAuthkeys',
                    authkeys.data
                );
                this.$store.commit(
                    'projects/setAuthKeys',
                    authkeys.data.AuthKeys
                );
                this.calculateAuthkeyRefresh(authkeys.data.Expiry);
                return authkeys.data;
            }
        },

        /**
         * Calculates the refresh interval for the authentication key, and sets the interval to refresh the authkeys
         * @param {string} authkeyExpiry - The expiration date of the authentication key.
         */
        calculateAuthkeyRefresh(authkeyExpiry) {
            if (this.authKeyInterval == null) {
                const now = new Date();
                const nowUTC = new Date(
                    now.getUTCFullYear(),
                    now.getUTCMonth(),
                    now.getUTCDate(),
                    now.getUTCHours(),
                    now.getUTCMinutes(),
                    now.getUTCSeconds()
                );
                const expirationUTC = new Date(Date.parse(authkeyExpiry));
                if (expirationUTC && nowUTC) {
                    this.authKeyInterval = setInterval(() => {
                        clearInterval(this.authKeyInterval);
                        this.authKeyInterval = null;
                        this.updateGeoserverAuthkeys();
                    }, expirationUTC - nowUTC);
                }
            }
        },
        getAllLayersDetails() {
            /**
             * Fetches geoserver authentication keys for selected projects and then retrieves spatial layers for each project.
             * @param {Array} projects - The array of selected projects.
             */
            this.updateGeoserverAuthkeys().then((authkeys) => {
                mapLayerFunctions.getSpatialLayers(this.projectID, authkeys, {
                    ver: this.version,
                    user: this.$store.state.store.posts.UserID,
                });
            });
        },
    },
    created() {
        // gets the geowebcache token for the first time
        projectService.getGeowebcacheToken().then((res) => {
            this.$store.commit(
                'emergencyManagement/setGeowebcacheToken',
                res.data
            );
        });
        /**
         * Sets up a timer to periodically fetch a Geowebcache token and update the store.
         * The timer is set to run every 24 hours starting at midnight.
         * adds 1 second to ensure that it gets the token for the new day
         */
        let intervalTime = 1000 * 60 * 60 * 24;
        let delay = this.calculateMilliSecondsUntilMidnight() + 1000;
        this.gwcTokenInterval = setTimeout(() => {
            projectService.getGeowebcacheToken().then((res) => {
                this.$store.commit(
                    'emergencyManagement/setGeowebcacheToken',
                    res.data
                );
            });
            setInterval(() => {
                projectService.getGeowebcacheToken().then((res) => {
                    this.$store.commit(
                        'emergencyManagement/setGeowebcacheToken',
                        res.data
                    );
                });
            }, intervalTime);
        }, delay);
    },
    mounted() {
        this.setProjectID(this.projectID);
        setTimeout(() => {
            this.getAllLayersDetails();
            this.zoomToERExtent();
            this.setShowAttributeModal(false); // I cannot find where this is set to true, so we set it to false here
            if (!this.leftSidebarOpen) {
                this.toggleLeftSidebar();
            }
            window.addEventListener('resize', this.updatePanePositions);
            document.getElementById('app').style.paddingBottom = 0;

            this.startERDataRefresh();
            //if current project is a NS project, call the get NS Esri Token api
            //need better logic to check if current project is a NS project, with client data
            if (
                this.selectedProject.ProjectName.startsWith('Norfolk Southern')
            ) {
                this.getNSTokenFromApi();
            }
        }, 500);
    },
    beforeDestroy() {
        clearInterval(this.refreshERData);
        clearInterval(this.gwcTokenInterval);
        clearInterval(this.authKeyInterval);
    },
    computed: {
        ...mapState('emergencyManagement', {
            drawingAttributes: 'drawingAttributes',
            layersStruct: 'layersStructValues',
            geowebcacheToken: 'geowebcacheToken',
        }),
        ...mapGetters('emergencyManagement', {
            erLayerRefs: 'getERLayerNames',
        }),
        ...mapGetters('common', {
            getForceRefreshMap: 'getForceRefreshMap',
        }),
        ...mapState('projects', {
            selectedProject: (state) => state.selectedProjects[0],
            selectedProjectsArr: (state) => state.selectedProjects,
        }),
        ...mapState('oneMap', ['activeMapTool']),
        projectLogo: function() {
            return `data:image/png;base64,${this.selectedProject.Logo}`;
        },
        projectID: function() {
            return this.selectedProject.ProjectID;
        },
        projectType: function() {
            return this.selectedProject.ProjectTypeName;
        },
        clientPrintLogo: function() {
            return this.selectedProject.ClientPrintLogo;
        },
        baseMap: function() {
            return this.baseMaps[this.selectedBasemap];
        },
        wmsLayers: function() {
            var layers = [
                {
                    url:
                        'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/wms',
                    name: 'NOAA Radar Base Reflectivity',
                    isChecked: this.showRadar,
                    format: 'image/png',
                    layers: 'conus_bref_qcd',
                    transparent: true,
                    attribution: 'National Weather Service',
                    opacity: 0.6,
                },
            ];
            try {
                this.layersStruct.forEach((layer) => {
                    if (layer.layers != '' && layer.url != '') {
                        layers.push(layer);
                    }
                });
            } catch (e) {
                return [];
            }
            return layers;
        },
        mapSize: function() {
            try {
                return this.$refs.lmap.mapObject.getSize();
            } catch (e) {
                return { x: window.innerWidth, y: window.innerHeight };
            }
        },
        hasHighlight: function() {
            return Boolean(
                this.drawMarkers.filter((feature) => feature.id.startsWith('H'))
                    .length ||
                    this.drawLines.filter((feature) =>
                        feature.id.startsWith('H')
                    ).length ||
                    this.drawPolygons.filter((feature) =>
                        feature.id.startsWith('H')
                    ).length
            );
        },
        WMS_requests: function() {
            var layers = [
                {
                    url:
                        'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/wms',
                    name: 'NOAA Radar Base Reflectivity',
                    isChecked: this.showRadar,
                    format: 'image/png',
                    layers: 'conus_bref_qcd',
                    transparent: true,
                    attribution: 'National Weather Service',
                    opacity: 0.6,
                },
            ];
            try {
                this.layersStruct.forEach((layer) => {
                    if (
                        layer.url &&
                        layer.isChecked &&
                        (this.zoom > this.max_tile_zoom ||
                            (this.zoom <= this.max_tile_zoom &&
                                layer.externalCache == false))
                    ) {
                        if (layer.internalCache) {
                            layers.push({
                                name: layer.name,
                                url: layer.url,
                                layers: layer.layers,
                                isChecked: layer.isChecked,
                                attribution: layer.attribution,
                                transparent: layer.transparent,
                                maxZoom: layer.maxZoom,
                                opacity: layer.opacity,
                                format: layer.format,
                                styles: layer.styles,
                                tileSize: 512,
                                zIndex: layer.zIndex,
                                minZoom: layer.minZoom,
                                options: {
                                    CQL_Filter: layer.options.CQL_Filter,
                                    authkey: layer.options.authkey,
                                    editTime: layer.options.editTime,
                                    height: 512,
                                    width: 512,
                                    tileSize: 512,
                                    maxNativeZoom: layer.options.maxNativeZoom,
                                    maxZoom: layer.options.maxZoom,
                                    tiled: layer.internalCache,
                                },
                            });
                        } else {
                            layers.push(layer);
                        }
                    }
                });
            } catch (e) {
                return [];
            }
            return layers;
        },
        WMTS_requests: function() {
            var layers = [];
            // No tile requests past zoom level 15
            if (this.zoom > this.max_tile_zoom) {
                return [];
            }
            // Add requests for tile layers
            this.layersStruct.forEach((layer) => {
                if (
                    layer.layers != '' &&
                    layer.url != '' &&
                    layer.isChecked &&
                    layer.externalCache &&
                    layer.minZoom <= this.zoom &&
                    this.zoom <= layer.maxZoom
                ) {
                    layer.wmts_url =
                        'https://geowebcache.adapt.ghd-digital.com/geowebcache/service/wmts/rest/' +
                        layer.layers +
                        '/' +
                        layer.styles +
                        '/' +
                        'EPSG:3857:2048/EPSG:3857:2048:{z}/{y}/{x}' +
                        '?format=' +
                        layer.format +
                        '&token=' +
                        this.geowebcacheToken;
                    layers.push(layer);
                }
            });
            return layers;
        },
    },
    watch: {
        getForceRefreshMap(newVal) {
            if (newVal) {
                this.layersStruct.forEach((lyrRef) => {
                    if (lyrRef.name.includes('Emergency Response')) {
                        let fullRef =
                            'wms-layer-' + lyrRef.name.replace(/\s/g, '');
                        if (this.$refs[fullRef]?.[0]) {
                            this.$refs[fullRef][0].mapObject.setParams(
                                { forceUpdate: Date.now() },
                                false
                            );
                        }
                    }
                });
                this.setForceRefreshMap(false);
            }
        },
    },
};
</script>

<style scoped>
/* @import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css"; */
.modalScreen {
    height: 80vh;
    z-index: 999999;
}
.modalDatagrid .modal-dialog {
    max-width: none !important;
    padding: 25px;
}

.rightSidebar {
    height: 94vh; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 6vh;
    right: 0;
    background-color: white; /* Black*/
    /* overflow-x: hidden; Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.leftSidebar {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    left: 0;
    background-color: white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: 0.25s;
}
#leftToolBar {
    transition: 0.25s;
}
#rightToolBar {
    transition: 0.25s;
}
.mapClass {
    z-index: 0; /* I needed this to be 0 to draw panes on top of it */
    cursor: grab;
}
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}
.layersModal {
    position: absolute;
    height: 400px;
    width: 300px;
    left: 200px !important;
    top: 200px !important;
    background-color: #000000;
    border-radius: 6px;
    padding-top: 20px;
}
.dataGrid {
    position: relative;
    top: 80px;
    left: 50%;
    height: 400px;
    width: 300px;
    border-radius: 6px;
    background-color: white;
}
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}
.btn-close {
    background-color: white;
    width: 40px;
    border-radius: 6px;
}
.btn-exit {
    color: rgb(173, 171, 171);
    top: 15px;
    border: none;
    background-color: black;
    border-radius: 0px !important;
}
.btn-exit:hover {
    color: white;
}
.btn-layers {
    background-color: black;
    width: 45px;
    height: 45px;
    padding-left: 6px !important;
}
.btn-white {
    background-color: white;
    height: 45px;
    width: 45px;
    padding-left: 6px !important;
}
.btn-zoom {
    background-color: white;
    width: 45px;
    height: 90px;
    padding-left: 6px !important;
}
.btn-filter {
    background-color: white;
    border-radius: 6px;
    border: none !important;
    height: 38px;
}
.topToolbar {
    background-color: #000000;
    height: 6vh;
}
.rowHeight {
    height: 100%;
}
.full-height {
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}
.sideBars {
    background-color: rgb(226, 220, 220);
    /* overflow-y: hidden; */
    height: 100%;
}
.leftToolBar {
    position: absolute;
    left: 0;
    width: 55px;
    z-index: 2;
    border: solid 1px rgb(196, 196, 196);
    height: 100%;
    background-color: white;
}
.mapWidth {
    padding-right: 55px !important;
    padding-left: 55px !important;
}
.mapLayers {
    padding-top: 15px;
    padding-left: 11px;
}
.img-layers {
    height: 30px;
    width: 30px;
}
.eventPanel {
    padding-top: 15px;
    padding-left: 13px;
}
.rightToolBar {
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;
    border: solid 1px rgb(196, 196, 196);
    z-index: 2;
    height: 100%;
    background-color: white;
}
.mapCols {
    height: 100%;
    width: 100%;
}
.sidebarToggle {
    position: relative;
    top: 50%;
    padding-left: 10px;
}
.fill-height {
    height: 94vh;
    flex: 1;
}
.modalLayers {
    left: 25px;
    background-color: #000000;
    height: 200px;
}
.myClass .div {
    position: absolute;
    top: 20px;
    left: 20px;
}
.myClass .modal-dialog .modal-content {
    background-color: black;
}
.dataBtn {
    background-color: black;
    border-radius: 6px;
    width: 48px;
    height: 48px;
}
.rightArrow {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    height: 100%;
    right: 0;
}
.imageToggle {
    width: 30px;
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    opacity: 0.7;
}

.leaflet-right .leaflet-control {
    margin-right: 35px;
}
.control-wrapper {
    border: none !important;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 45px;
}
/* .tabs{
    height: 100%;
} */
.tab-content {
    height: 100%;
}
.heightInherit {
    height: inherit;
}
.btn-hover:hover {
    border-bottom: solid 4px gray;
}
.btn-hover:focus {
    border-bottom: solid 4px gray;
}
.identifyActive {
    z-index: 99;
    background-color: black;
}
.btn-active {
    border-bottom: solid 4px lightgray;
}
.drawCursor {
    cursor: crosshair !important;
}
.identifyCursor {
    cursor: pointer !important;
}
.vue2leaflet-map {
    overflow: hidden;
}
.logoStyle {
    max-height: 40px;
}
.tab-pane {
    padding-bottom: 80px;
}
</style>

<style>
/* this has to be outside of the scoped style, I don't know why, it just has to */
.leaflet-control-attribution {
    font-size: 8px !important;
}
.powerIcon {
    height: 30px;
    width: 30px;
}

.version-toggle label {
    margin-bottom: 0 !important;
    margin-left: 4px;
}
.version-toggle-preview label {
    margin-bottom: 0 !important;
    margin-left: 4px;
}
</style>
