import Vue from 'vue';
import { projectService } from '../services';
import { apiConstants } from '../common/apiConst';

const state = {
    projects: {},
    projectsWithGeo: {},
    selectedProjects: [],
    remove: {},
    mapExpanded: false,
    selectedLayerAccordion: 'project',
    mapData: {
        inMapProjects: [],
        mapCenter: { lat: 40.68, lng: -74.028 },
        mapZoom: 10,
        expandedMapZoom: 11,
        contourWMSLyrs: [],
        baseMaps: [
            {
                name: 'Satellite_Imagery',
                visible: false,
                url:
                    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                attribution:
                    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            },
            {
                name: 'TopoMap',
                visible: true,
                url:
                    'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                attribution:
                    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            },
            {
                name: 'NoMap',
                visible: false,
                url: '',
                attribution: '',
            },
        ],
        markers: [],
        markersClusters: [],
        geojsonLayers: {
            posted: [],
        },
        deletedPostedLayerIds: [],
    },
    projSelectionMultiple: false,
    gridDatasetId: '',
    authkeys: {},
    updateContourLayersFlag: -1,
};

const actions = {
    getAll({ commit }) {
        commit('getAllRequest');

        projectService.getAll().then(
            (projects) => commit('getAllSuccess', projects),
            (error) => commit('getAllFailure', error)
        );
    },
    getAllWithGeo({ commit }) {
        commit('getAllGeoRequest');
        projectService.getAllWithGeo().then(
            (projectsWithGeo) => commit('getAllGeoSuccess', projectsWithGeo),
            (error) => commit('getAllGeoFailure', error)
        );
    },
    selectProjects({ commit }, projects) {
        commit('setSelectedProjects', projects);
    },
    getSelectedProjects({ commit }) {
        commit('getAllSelectedProjects');
    },
    deselectProject({ commit }, projectID) {
        commit('deselectProject', projectID);
    },
    clearSelectedProjects({ commit }) {
        commit('removeSelectedProjects');
    },
    deleteProject({ commit }, projectId) {
        commit('deleteRequest');
        projectService.deleteProject(projectId).then(
            (remove) => commit('deleteSuccess', remove),
            (error) => commit('deleteFailure', error)
        );
    },
    togglePostedLayerLabels({ commit }, idx) {
        commit('togglePostedLayerLabels', idx);
    },
    updatePostedVisibility({ commit }, idx) {
        commit('updatePostedVisibility', idx);
    },
    deletePostedLayer({ commit, getters }, payload) {
        let id = payload.id;
        let lyrType = payload.lyrType;
        // if the id is greater than the length of the posted layers, it is a contour layer
        // have to offset the id value so it is correct for the other array
        if (lyrType == 'contour') {
            commit('deleteContourLayer', id);
        } else {
            commit('deletePostedLayer', id);
        }
    },
    updateSelectedLayerAccordion({ commit }, accordion) {
        commit('updateSelectedLayerAccordion', accordion);
        projectService.getAllWithGeo().then(
            (projectsWithGeo) => commit('getAllGeoSuccess', projectsWithGeo),
            (error) => commit('getAllGeoFailure', error)
        );
    },
    clearSelectedEMProjects({ commit }) {
        commit('removeSelectedEMProjects');
    },
    baseMaps({ commit }, maps) {
        commit('setBaseMaps', maps);
    },
    togglePostedLayerClusteredAction({ commit }, val) {
        commit('togglePostedLayerClustered', val);
    },
    datasetIdChanged({ commit }, gridDatasetId) {
        commit('gridDatasetIdChanged', gridDatasetId);
    },
};

const mutations = {
    getAllRequest(state) {
        state.projects = { loading: true };
    },
    getAllSuccess(state, projects) {
        // state.projects = { items: projects.data.ProjectDetails };
        state.projects = { items: projects.data };
    },
    getAllFailure(state, error) {
        state.projects = { error };
    },
    // if project not in list, appends it, else skips
    setSelectedProjects(state, projects) {
        var exists = state.selectedProjects.some(
            (field) => field.ProjectID === projects.ProjectID
        );
        if (!exists && projects !== undefined) {
            state.selectedProjects.push(projects);
        } else {
            //nothing
        }
    },
    addProjectToInMapProjects(state) {
        state.mapData.inMapProjects = state.selectedProjects.slice();
    },
    getAllSelectedProjects(state) {
        return state.projects;
    },
    deselectProject(state, removeProjectID) {
        state.selectedProjects.splice(
            state.selectedProjects.findIndex(
                (project) => project.ProjectID === removeProjectID
            ),
            1
        );
    },
    //Get project list with Geo location
    getAllGeoRequest(state) {
        state.projectsWithGeo = { loading: true };
    },
    getAllGeoSuccess(state, projectsWithGeo) {
        if (state.selectedProjects[0]) {
            let newSelectedData = projectsWithGeo.data.find(
                (item) => state.selectedProjects[0].ProjectID === item.ProjectID
            );
            state.selectedProjects = [newSelectedData];
        }
        state.projectsWithGeo = { items: projectsWithGeo.data };
    },
    getAllGeoFailure(state, error) {
        state.projectsWithGeo = { error };
    },
    //delete project
    deleteRequest(state) {
        state.remove = { loading: true };
    },
    deleteFailure(state, error) {
        state.remove = { error };
    },
    //mutation functions for mapData
    addMarker(state, payload) {
        state.mapData.markers = payload;
    },
    addMarkerClusters(state, payload) {
        state.mapData.markersClusters = payload;
    },
    addPostedLayerData(state, payload) {
        state.mapData.geojsonLayers.posted.filter(
            (i) => !state.mapData.deletedPostedLayerIds?.includes(i.PostingId)
        );
        let newPostedTableDataArr = [
            ...state.mapData.geojsonLayers.posted,
            ...payload,
        ];
        state.mapData.geojsonLayers.posted = [
            ...new Map(
                newPostedTableDataArr.map((item) => [item.id, item])
            ).values(),
        ];
    },
    // args are state and {editIdx: int, editDataset: dataset{}}
    updatePostedLayer(state, payload) {
        Vue.set(
            state.mapData.geojsonLayers.posted,
            payload.editIdx,
            payload.editDataset
        );
    },
    duplicatePostedLayer(state, payload) {
        state.mapData.geojsonLayers.posted.splice(
            payload.editIdx + 1,
            0,
            payload.editDataset
        );
    },
    deletePostedLayer(state, lyrId) {
        let lyrIdx = state.mapData.geojsonLayers.posted.findIndex((lyr) => {
            return lyr.postingId == lyrId;
        });
        state.mapData.deletedPostedLayerIds.push(lyrId);
        state.mapData.geojsonLayers.posted.splice(lyrIdx, 1);
    },
    deleteContourLayer(state, lyrIdx) {
        state.mapData.contourWMSLyrs.splice(lyrIdx, 1);
    },
    clearMapData(state) {
        state.mapData.markers = [];
        state.mapData.markersClusters = [];
        state.mapData.geojsonLayers.posted = [];
        state.mapData.inMapProjects = [];
        state.mapData.expandedMapExtent = null;
        state.mapData.contourWMSLyrs = [];
    },
    updateMapCenter(state, payload) {
        let center = { lat: 0, lng: 0 };
        center.lat = parseFloat(payload.lat.toFixed(6));
        center.lng = parseFloat(payload.lng.toFixed(6));
        state.mapData.mapCenter = center;
    },
    updateMapZoom(state, payload) {
        state.mapData.mapZoom = payload;
    },
    updateMapExtent(state, payload) {
        state.mapData.mapExtent = payload;
    },
    updateBaseMap(state, payload) {
        if (payload == 'topo') {
            state.mapData.baseMaps[0].visible = false;
            state.mapData.baseMaps[1].visible = true;
            state.mapData.baseMaps[2].visible = false;
        } else if (payload == 'imagery') {
            state.mapData.baseMaps[0].visible = true;
            state.mapData.baseMaps[2].visible = false;
            state.mapData.baseMaps[1].visible = false;
        } else {
            //no map
            state.mapData.baseMaps[0].visible = false;
            state.mapData.baseMaps[1].visible = false;
            state.mapData.baseMaps[2].visible = true;
        }
    },
    setMapExpandedOn(state) {
        state.mapExpanded = true;
        state.mapData.expandedMapZoom =
            state.mapData.mapZoom > 0
                ? state.mapData.mapZoom - 1
                : state.mapData.mapZoom;
    },
    setMapExpandedOff(state) {
        state.mapExpanded = false;
        state.mapData.mapZoom =
            state.mapData.expandedMapZoom < 20
                ? state.mapData.expandedMapZoom + 1
                : state.mapData.expandedMapZoom;
    },
    updatePostedVisibility(state, index) {
        let layer = state.mapData.geojsonLayers.posted[index];
        layer.MarkerSettings[0].dataSource.visible = !layer.MarkerSettings[0]
            .dataSource.visible;

        Vue.set(state.mapData.geojsonLayers.posted, index, layer);
    },
    removeSelectedProjects(state) {
        return (state.selectedProjects = []);
    },
    removeSelectedEMProjects(state) {
        state.selectedProjects = state.selectedProjects.filter((project) => {
            return project.ProjectTypeName !== 'Emergency management';
        });
    },
    setBaseMaps(state, maps) {
        state.mapData.baseMap = [...maps];
    },
    deleteSuccess(state, remove) {
        //
        state.remove = {
            StatusCode: remove.status,
            // StatusCode: remove.data.StatusCode
        };
    },
    togglePostedLayerLabels(state, index) {
        let dataSource =
            state.mapData.geojsonLayers.posted[index].MarkerSettings[0]
                .dataSource;
        if (!dataSource.labelVisibleIndex) {
            for (let i = 0; i < dataSource.features[0].properties.length; i++) {
                if (dataSource.features[0].properties[i].label) {
                    dataSource.labelVisibleIndex = i;
                }
            }
        }
        dataSource.features.forEach((geom) => {
            geom.properties[dataSource.labelVisibleIndex].label = !geom
                .properties[dataSource.labelVisibleIndex].label;
        });
    },
    togglePostedLayerClustered(state, val) {
        let index = val.index;
        let layer = state.mapData.geojsonLayers.posted[index];
        layer.clustered = !layer.clustered;
        Vue.set(state.mapData.geojsonLayers.posted, index, layer);
    },
    updateSelectedLayerAccordion(state, accordion) {
        //
        state.selectedLayerAccordion = accordion;
        //
    },
    addContourLayer(state, payload) {
        var jobID = payload.jobID;
        var geoserver_workspace = '';
        switch (apiConstants.api_url) {
            case 'http://localhost:54654/api/':
                geoserver_workspace = 'adapt_dev';
                break;
            case 'https://coreservicesapi-dev.adapt.ghd.com/api/':
                geoserver_workspace = 'adapt_dev';
                break;
            case 'https://coreservicesapi-qa.adapt.ghd.com/api/':
                geoserver_workspace = 'adapt_qa';
                break;
            case 'https://coreservicesapi-uat.adapt.ghd.com/api/':
                geoserver_workspace = 'adapt_uat';
                break;
            case 'https://coreservicesapi.adapt.ghd.com/api/':
                geoserver_workspace = 'adapt_prd';
                break;
            default:
                geoserver_workspace = 'adapt_dev';
                break;
        }
        let geoserver_address =
            'https://geoserver.adapt.ghd-digital.com/geoserver';
        state.mapData.contourWMSLyrs.push({
            id: jobID + 10000,
            url: geoserver_address + '/wms',
            visible: true,
            format: 'image/png',
            layers: geoserver_workspace + ':vw_Contour',
            transparent: true,
            tileSize: 256,
            attribution: '',
            styles: geoserver_workspace + ':ADAPT_Contour',
            jobID: jobID,
            options: {
                tiled: false,
                tms: true,
                authkey: state.authkeys[geoserver_workspace],
                CQL_FILTER: 'JobID = ' + jobID,
                maxNativeZoom: 22,
                maxZoom: 29,
            },
            legend:
                geoserver_address +
                '/wms?REQUEST=GetLegendGraphic&VERSION=1.1.1&FORMAT=image/png&Layer=' +
                geoserver_workspace +
                ':vw_Contour&authkey=' +
                state.authkeys[geoserver_workspace],
            ContourOptions: payload.ContourOptions,
        });
    },
    updateContour(state, payload) {
        let jobID = payload.jobID;
        let contourOptions = payload.options;
        let layerIndex = state.mapData.contourWMSLyrs.findIndex(
            (e) => e.jobID == jobID
        );
        let layer = state.mapData.contourWMSLyrs[layerIndex];
        layer.ContourOptions = contourOptions;
        Vue.set(state.mapData.contourWMSLyrs, layerIndex, layer);
    },
    setAuthKeys(state, keys) {
        state.authkeys = keys;
    },
    mutateProjSelectionMultiple(state, value) {
        state.projSelectionMultiple = value;
    },
    datasetIdChanged(state, gridDatasetId) {
        state.gridDatasetId = gridDatasetId;
    },
    mutateMapSize(state, size) {
        state.mapData.mapSize = size;
    },
    updatePostedWMSVisibility(state, item) {
        let layer = state.mapData.contourWMSLyrs.find((e) => {
            return e.id === item.id;
        });
        layer.visible = !layer.visible;
        Vue.set(state.mapData.contourWMSLyrs, item, layer);
    },
    deleteAllPostedLayers(state) {
        state.mapData.deletedPostedLayerIds.push(
            ...state.mapData.geojsonLayers.posted.map((i) => i.postingId)
        );
        state.mapData.geojsonLayers.posted = [];
        state.mapData.contourWMSLyrs = [];
    },
    setUpdateContourLayersFlag(state, flag) {
        state.updateContourLayersFlag = flag;
    },
};

const getters = {
    postedLyrCount: (state) => state.mapData.geojsonLayers.posted.length,
    // below getters separate the layers to Clustered or not Clustered
    postedLyrs: (state) => {
        var output = [];
        state.mapData.geojsonLayers.posted.forEach((lyr) => {
            if (!lyr.Clustered && lyr.Visible) {
                output.push(lyr.MarkerSettings[0].dataSource);
            }
        });
        return output;
    },
    postedClusterLyrs: (state) => {
        var output = [];
        state.mapData.geojsonLayers.posted.forEach((lyr) => {
            if (lyr.Clustered && lyr.Visible) {
                output.push(lyr.MarkerSettings[0].dataSource);
            }
        });
        return output;
    },
    tocPostedLayers: (state) => {
        var tocPosted = [];
        var filteredState = state.mapData.geojsonLayers.posted.filter(
            (i) => !state.mapData.deletedPostedLayerIds?.includes(i.PostingId)
        );
        try {
            var wmsLyrId = 0;
            filteredState.forEach((lyr) => {
                lyr.isWMS = false;
                tocPosted.push(lyr);
            });
            state.mapData.contourWMSLyrs.forEach((lyr) => {
                lyr.isWMS = true;
                lyr.LyrId = wmsLyrId;
                tocPosted.push(lyr);
            });
        } catch (error) {
            //nothing
        }

        return tocPosted;
    },
    selectedProjectsTitle: (state) => {
        return state.selectedProjects
            .map((p) => p.ProjectName.replaceAll('"', ''))
            .join(', ');
    },

    ProjectID: (state) => {
        if (state.selectedProjects.length) {
            return state.selectedProjects[0].ProjectID.toString();
        } else {
            return '0';
        }
    },
    getGridDatasetId: (state) => {
        return parseInt(state.gridDatasetId);
    },
    selectedProjects: (state) => {
        if (state.selectedProjects.length) {
            return state.selectedProjects;
        } else {
            return [];
        }
    },
    getContourLayerRefs: (state) => {
        let contourLayerRefs = [];
        state.mapData.contourWMSLyrs.forEach((lyr) => {
            contourLayerRefs.push('contourWMSLayer' + lyr.jobID);
        });
        return contourLayerRefs;
    },
    getSelectedProject: (state) => {
        return state.selectedProjects;
    },
};

const namespaced = true;

export const projects = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
