import axios from 'axios';
import { commonService } from '../services/common.service';
import store from './../store';
import router from './../router';

export function request(config) {
    const instance = axios.create({
        // timeout: 5000 //and other settings available can be used here;
    });
    instance.interceptors.request.use(
        (config) => {
            let token = commonService.getCookie('accessToken');
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (err) => {}
    );

    instance.interceptors.response.use(
        (res) => {
            //reset token renew count to 0 when a good response is made
            store.commit('common/resetCount');
            return res;
        },
        async (err) => {
            //

            const originalRequest = err.config;
            //perform token renew request and replay original request, but up to 10 times
            if (
                err.response.status === 401 &&
                store._vm.$msalInstance.getAllAccounts()[0] &&
                store.state.common.tokenRefreshCount < 15
            ) {
                store.dispatch('common/incrementTokenRenewCount');
                await store.dispatch('common/renewToken');
                return instance(originalRequest);
            }
            //clear accessToken cookie and send user to Login page
            else if (
                err.response.status === 401 &&
                store._vm.$msalInstance.getAllAccounts()[0] &&
                store.state.common.tokenRefreshCount >= 15
            ) {
                document.cookie =
                    'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                router.push(`/`);
                return Promise.reject(err);
            } else if (err.response.status === 403) {
                return Promise.reject(err);
            } else {
                return Promise.reject(err);
            }
        }
    );

    return instance(config);
}
