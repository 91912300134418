//import { config } from "../../config";
export const apiConstants = {
    api_url: process.env.VUE_APP_API_URI,
    addOrUpdateUserGroupPortalPermission:
        process.env.VUE_APP_API_URI +
        'PortalPermissions/AddOrUpdateUserGroupPortalPermission',
    getDataTypeColumn:
        process.env.VUE_APP_API_URI +
        'DatasetColumns/GetDatasetTypeDescription',
    getEditingDataSet:
        process.env.VUE_APP_API_URI + 'Datasets/GetEditingDataset',
    getProjectLayerOrder:
        process.env.VUE_APP_API_URI + 'Datasets/GetProjectLayerOrder',
    updateLayerOrder: process.env.VUE_APP_API_URI + 'Datasets/PutLayerOrder',
    getNetworkFile:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Documents/GetNetworkFile',
    getNetworkFileSource:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Documents/DownLoadFileFromBlob',
    getDirectoryManageAttribute:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'DirectoryAttributes/GetDirectoryManageAttribute',
    getProjectDetails: process.env.VUE_APP_API_URI + 'Projects',
    getProjectList: process.env.VUE_APP_API_URI + 'Projects',
    getAllWithGeo: process.env.VUE_APP_API_URI + 'Projects/Getprojectbyuserid',
    deleteProject: process.env.VUE_APP_API_URI + 'Projects',
    getUserList: process.env.VUE_APP_API_URI + 'Users',
    createUser: process.env.VUE_APP_API_URI + 'Users',
    updateUser: process.env.VUE_APP_API_URI + 'Users',
    deleteUser: process.env.VUE_APP_API_URI + 'Users',
    getUserById: process.env.VUE_APP_API_URI + 'Users',
    getDatasetColumnAliasName:
        process.env.VUE_APP_API_URI +
        'DatasetColumns/GetDatasetColumnAliasName',
    getCompanyList: process.env.VUE_APP_API_URI + 'Companies',
    getDirectoryChild:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Directories/GetDirectoryChild',
    getDirectoryName:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Directories/ProjectDirectory',
    //getProjectName:process.env.VUE_APP_API_URI+"Authorizations/MappedProjectDataSets",
    getProjectName:
        process.env.VUE_APP_API_URI +
        'ProjectDataSetMapping/MappedProjectDatasets?$orderby=ProjectName',
    getDatasetName:
        process.env.VUE_APP_API_URI +
        'ProjectDataSetMapping/MappedDatasetsProject?$orderby=DatasetAlias',
    // getDatasetName:process.env.VUE_APP_API_URI+"Authorizations/MappedDataSetProjects",
    //getProjectToolName:process.env.VUE_APP_API_URI+"Authorizations/MappedProjectTools?$orderby=ProjectName",
    getProjectToolName:
        process.env.VUE_APP_API_URI +
        'ProjectDataSetMapping/MappedProjectTools?$orderby=ProjectName',
    //getToolName:process.env.VUE_APP_API_URI+"Authorizations/MappedToolProjects?$orderby=ToolName",
    getToolName:
        process.env.VUE_APP_API_URI +
        'ProjectDataSetMapping/MappedToolsProject?$orderby=ToolName',
    // getGroupsToolName:process.env.VUE_APP_API_URI+"Authorizations/MappedUserGroupTools?$orderby=UserGroupName",
    // getGroupsToolName:process.env.VUE_APP_API_URI+"UserGroups?$expand=Authorizations($expand=Tool)&$orderby=UserGroupName",
    getGroupsToolName:
        process.env.VUE_APP_API_URI +
        'Authorizations/MappedUserGroupsToolNew?$orderby=UserGroupName',
    //getToolsGroupName:process.env.VUE_APP_API_URI+"Authorizations/MappedToolUserGroups?$orderby=ToolName",
    getToolsGroupName:
        process.env.VUE_APP_API_URI +
        'Authorizations/MappedToolUserGroupsNew?$orderby=ToolName',

    // getUsersGroupName:process.env.VUE_APP_API_URI+"Users?$expand=UserGroupDetails($expand=UserGroup)&$orderby=UserName",
    getUsersGroupName:
        process.env.VUE_APP_API_URI +
        'Users/GetUsersWithAssociatedGroups?$orderby=UserName',

    // getGroupUserName:process.env.VUE_APP_API_URI+"UserGroups?$expand=UserGroupDetails($expand=user)&$orderby=UserGroupName",
    getGroupUserName:
        process.env.VUE_APP_API_URI +
        'UserGroups/GetUserGroupUsers?$orderby=UserGroupName',

    // getGroupDatasetName:process.env.VUE_APP_API_URI+"Authorizations/MappedUserGroupProjectDataSets?$orderby=UserGroupName",
    // getDatasetsGroupName:process.env.VUE_APP_API_URI+"Authorizations/MappedProjectDataSetUserGroups?$orderby=DatasetName",
    getGroupDatasetName:
        process.env.VUE_APP_API_URI +
        'UserAuthorizations/MappedUserGroupProjectDataSets',
    getDatasetsGroupName:
        process.env.VUE_APP_API_URI +
        'UserAuthorizations/MappedProjectDataSetUserGroups',
    // getGroupPortalName:process.env.VUE_APP_API_URI+"PortalPermissions/GetMappedUserGroupPortalPermission?$orderby=UserGroupName",
    // getGroupPortalName:process.env.VUE_APP_API_URI+"UserGroups?$expand=PortalPermissions($expand=Portalpage)&$orderby=UserGroupName",
    getGroupPortalName:
        process.env.VUE_APP_API_URI +
        'PortalPermissions/GetMappedUserGroupPortalPermission?$orderby=UserGroupName',
    getPortalGroupName:
        process.env.VUE_APP_API_URI +
        'PortalPermissions/GetMappedPortalPermissionUserGroup?$orderby=PortalPageName',
    // getGroupList:process.env.VUE_APP_API_URI+"UserGroups?$expand=PortalPermissions($expand=Portalpage)&$orderby=UserGroupName",
    getGroupList:
        process.env.VUE_APP_API_URI +
        'UserGroups/MappedGroupUserGroups?$orderby=UserGroupName',
    // getGroupofGroupsName:process.env.VUE_APP_API_URI+"UserGroups?$expand=ChildUserGroup",
    getGroupofGroupsName:
        process.env.VUE_APP_API_URI +
        'UserGroups/MappedGroupChildUserGroups?$orderby=UserGroupName',
    getCorporationList: process.env.VUE_APP_API_URI + 'Corporations',
    getCoutryList: process.env.VUE_APP_API_URI + 'Countries',
    getStateList: process.env.VUE_APP_API_URI + 'States',
    getCityList: process.env.VUE_APP_API_URI + 'Cities',
    getDatasetList: process.env.VUE_APP_API_URI + 'Datasets',
    getDataSourcesList: process.env.VUE_APP_API_URI + 'Datasources',
    getDataSourcesProjectList:
        process.env.VUE_APP_API_URI + 'DataSources/GetDataSourceProject',
    addDatasetexternal:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDatasets',
    deleteDataset: process.env.VUE_APP_API_URI + 'Datasets',
    tableselectToollist:
        process.env.VUE_APP_API_URI + 'Datasets/ProjectDatasets',
    getDataSourcesProjectListFiltered:
        process.env.VUE_APP_API_URI + 'Datasets/ProjectDatasets',
    getFilterOption: process.env.VUE_APP_API_URI + 'DatasetFilterColumns',
    filterDatasetColLegacy:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDistinctTableSelectColumnValues',
    filterDatasetCol:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDistinctColumnValues',
    createUploadedDirectory:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/CreateUploadedDirectory',
    updateTblDocuments:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/UpdateTblDocuments',
    // filteredData:process.env.VUE_APP_API_URI+"ExternalDataSource/GetDataSetDatas",
    filteredData:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetExternalDataSetDatasCondition',
    GetExternalDataSorted:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetExternalDataSorted',
    updateDirectory:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'DirectoryAttributes/UpdateDirectoryAttribute',
    createDirectory:
        process.env.VUE_APP_DOCUMENT_API_URI + 'DirectoryAttributes',
    cloneSelectedDirectories:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/CloneSelectedDirectories',
    cloneDirectory:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Directories/CloneDirectories',

    saveAssociation: process.env.VUE_APP_API_URI + 'Authorizations',
    // saveAssociationProjectTool:process.env.VUE_APP_API_URI+"Authorizations",

    defineDatasetColumns:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetTableColumns',
    getProcedureParameters:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetProcedureParameters',
    getTitleBlock:
        process.env.VUE_APP_REPORTING_API_URI + 'TitleBlocks/Dataset',
    getDataSetBlock: process.env.VUE_APP_API_URI + 'DatasetColumns',
    getStyleBlock: process.env.VUE_APP_API_URI + 'Styles',
    getStyleSize:
        process.env.VUE_APP_REPORTING_API_URI + 'TitleBlocks/GetFontSize',
    getStyleStyle:
        process.env.VUE_APP_REPORTING_API_URI + 'TitleBlocks/GetFontStyle',
    getStyleColor:
        process.env.VUE_APP_API_URI +
        'Styles?$apply=groupby((StyleColor))&$orderby=StyleColor',
    getStyleType:
        process.env.VUE_APP_REPORTING_API_URI + 'TitleBlocks/GetFontType',
    getDataPostingToolData:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDataPostingToolData',
    getDataPostToolData:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDataPostToolData',
    getDataPostingToolDataJSON:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDataPostingToolData',
    selectField: process.env.VUE_APP_API_URI + 'FieldName',
    // createDirectory:process.env.VUE_APP_API_URI+"DirectoryAttributes",
    getToolattributes: process.env.VUE_APP_API_URI + 'Tools',
    getDatatypes: process.env.VUE_APP_API_URI + 'DatatypeSets',
    updateTitleBlock: process.env.VUE_APP_REPORTING_API_URI + 'TitleBlocks',
    addTitleBlockImage: process.env.VUE_APP_API_URI + 'Images',
    updateTitleBlockImage: process.env.VUE_APP_API_URI + 'Images',
    legacyProjectInfo:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetTableDatas',
    legacyProjectInfoFiltered:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDistinctColumnValues',
    // getTools:process.env.VUE_APP_API_URI+"Tools",
    getTools: process.env.VUE_APP_API_URI + 'Tools/GetToolsAccessbyuserid',
    createProject: process.env.VUE_APP_API_URI + 'Projects',
    editProject: process.env.VUE_APP_API_URI + 'Projects',
    getProjectLayers:
        process.env.VUE_APP_REPORTING_API_URI +
        'ProjectLayers/GetProjectLayers',
    editProjectMapping:
        process.env.VUE_APP_API_URI +
        'ProjectAttributeMappings/GetProjectAttributeMappingsAlias',
    getTableName: process.env.VUE_APP_API_URI + 'DataSources',
    updateProject: process.env.VUE_APP_API_URI + 'Projects',
    getDataSourceDBType:
        process.env.VUE_APP_API_URI + 'DataSources/DataBaseType',
    dataSetsByDataSource: process.env.VUE_APP_API_URI + 'Datasources',
    deleteDataSource: process.env.VUE_APP_API_URI + 'DataSources',
    datasetToolattributes:
        process.env.VUE_APP_MAPPING_API_URI + 'DatasetToolAttributes',
    testConnection: process.env.VUE_APP_API_URI + 'DataSources/TestConnection',
    createDataSource: process.env.VUE_APP_API_URI + 'DataSources/AddDatasource',
    editDataSource: process.env.VUE_APP_API_URI + 'DataSources',
    updateDataSource:
        process.env.VUE_APP_API_URI + 'DataSources/UpdateDataSource',
    getDuplicateDataSource:
        process.env.VUE_APP_API_URI + 'DataSources/DuplicateDataSourceExist',
    getAggregateOption:
        process.env.VUE_APP_MAPPING_API_URI +
        'AggregateOptions?$orderby=AggregateOptions&$apply=groupby((AggregateOptions))',
    getAggregateOptionPortal:
        process.env.VUE_APP_MAPPING_API_URI +
        'AggregateOptions?$orderby=AggregateOptions',
    getAggregateOptionByType:
        process.env.VUE_APP_MAPPING_API_URI +
        'AggregateOptions/AggregateOptionByDataType',
    savePortalSettings: process.env.VUE_APP_API_URI + 'PortalSettings',
    getSavedPortalsettings:
        process.env.VUE_APP_API_URI + 'PortalSettings/GetUserPortalSetting',
    // uploadDocument:process.env.VUE_APP_API_URI+"Documents",
    uploadDocument:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'Documents/AddDocumentsAttributeToAzure',
    saveDataSet: process.env.VUE_APP_API_URI + 'Datasets',
    editDataSet: process.env.VUE_APP_API_URI + 'Datasets',
    updateDataset: process.env.VUE_APP_API_URI + 'Datasets',
    getListofDirectories: process.env.VUE_APP_DOCUMENT_API_URI + 'Directories',
    getListofProjectDirectories:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Directories/ProjectDirectory',
    getAssignattributes:
        process.env.VUE_APP_DOCUMENT_API_URI + 'DirectoryAttributes',
    GetDirectoryManageAttribute:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'DirectoryAttributes/GetDirectoryManageAttribute',
    GetDocumentMetadataDetails:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'Documents/GetDocumentMetadataDetails',
    GetDocumentEditMetadataDetails:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/GetDocumentEditMetadataDetails',
    GetDirectoryMetadataDetails:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/GetDirectoryMetadataDetails',
    UpdateDocumentMetadataDetails:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/UpdateDocumentMetadataDetails',
    getUserGroups: process.env.VUE_APP_API_URI + 'UserGroups',
    deleteGroups: process.env.VUE_APP_API_URI + 'UserGroups',
    countUserGroups: process.env.VUE_APP_API_URI + 'UserGroups/CountUserGroup',
    addGroups: process.env.VUE_APP_API_URI + 'UserGroups',
    editGroups: process.env.VUE_APP_API_URI + 'UserGroups',
    // getScatterPlot:process.env.VUE_APP_API_URI+"ExternalDataSource/GetScatterPlot",
    getScatterPlot:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetScatterPlotCondition',
    getDataDownload:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'Documents/DownLoadFileFromBlobSource',
    getDownloadFromNetworkDrive:
        process.env.VUE_APP_API_URI + 'HyperLink/DownloadFileFromNetworkDrive',
    getPermissions: process.env.VUE_APP_API_URI + 'Account/Login',
    convertGDBtoSQL: process.env.VUE_APP_API_URI + 'GDBtoSQL/ConvertGDBtoSQL',
    deleteFromSQL: process.env.VUE_APP_API_URI + 'GDBtoSQL',
    addOrUpdateUserToUserGroup:
        process.env.VUE_APP_API_URI + 'UserGroups/AddOrUpdateUserToUserGroup',
    addOrUpdateProjectTool:
        process.env.VUE_APP_API_URI +
        'ProjectDataSetMapping/AddOrUpdateProjectTool',
    addOrUpdateProjectDataSet:
        process.env.VUE_APP_API_URI +
        'ProjectDataSetMapping/AddOrUpdateProjectDataSet',
    addOrUpdateTools:
        process.env.VUE_APP_API_URI + 'Authorizations/AddOrUpdateTools',
    updateUserGroupsMapping:
        process.env.VUE_APP_API_URI + 'UserGroups/UpdateUserGroupsMapping',
    addOrUpdateUsersAuthorizations:
        process.env.VUE_APP_API_URI +
        'UserAuthorizations/AddOrUpdateUsersAuthorizations',
    getminMaxInterval:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetExternalDataSetDataGraph',
    GetDistinctFilteredvalues:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetDistinctFilteredvalues',
    getDataCoordinates:
        process.env.VUE_APP_REPORTING_API_URI +
        'ProjectLayers/GetDataCoordinates',
    AzureFileAttributeSearch:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'AzureProvider/AzureFileAttributeSearch',
    validateDrawing:
        process.env.VUE_APP_MAPPING_API_URI + 'Drawing/ValidateGeometry',
    drawingSaveToDatabase:
        process.env.VUE_APP_MAPPING_API_URI + 'Drawing/SaveToDatabase',
    drawingDeleteFromDatabase:
        process.env.VUE_APP_MAPPING_API_URI + 'Drawing/DeleteFromDatabase',
    mapLayers: process.env.VUE_APP_MAPPING_API_URI + 'MapLayers',
    getEventLog: process.env.VUE_APP_MAPPING_API_URI + 'EventLog/GetEventLog',
    updateEventInfo: process.env.VUE_APP_MAPPING_API_URI + 'Event/UpdateEvent',
    updateEventLog:
        process.env.VUE_APP_MAPPING_API_URI + 'EventLog/UpdateEventLog',
    createEventLog:
        process.env.VUE_APP_MAPPING_API_URI + 'EventLog/SaveToDatabase',
    deleteEventLog:
        process.env.VUE_APP_MAPPING_API_URI + 'EventLog/DeleteEventLog',
    getEventLogGeometry:
        process.env.VUE_APP_MAPPING_API_URI + 'EventLog/GetGeometry',
    queryLookup: process.env.VUE_APP_MAPPING_API_URI + 'QueryLookup',
    downloadFile:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'Documents/DownloadFileByDocumentID',
    downloadThumbnail:
        process.env.VUE_APP_DOCUMENT_API_URI +
        'Documents/DownloadThumbnailByDocumentID',
    GetProjectType: process.env.VUE_APP_API_URI + 'ProjectType/GetProjectType',
    GetBasemapTypes: process.env.VUE_APP_API_URI + 'Projects/GetDefaultBaseMap',
    getDashboardParams:
        process.env.VUE_APP_REPORTING_API_URI + 'PowerBI/GetDashboardParams',
    reverseLookupQuery: process.env.VUE_APP_MAPPING_API_URI + 'ReverseLookup',
    reverseLookupGeom:
        process.env.VUE_APP_MAPPING_API_URI + 'ReverseLookup/GetFeatureExtent',
    getSavedWork:
        process.env.VUE_APP_API_URI + 'UserPortalOptions/UserPortalOption',
    saveFilters: process.env.VUE_APP_API_URI + 'UserPortalOptions',
    UpdateUserPortalOptionsDetails:
        process.env.VUE_APP_API_URI + 'UserPortalOptions',
    updateSavedWork: process.env.VUE_APP_API_URI + 'UserPortalOptions',
    getPowerBIDatasets:
        process.env.VUE_APP_API_URI + 'Datasets/PowerBIDatasets',
    getStoredProcedureResults:
        process.env.VUE_APP_REPORTING_API_URI +
        'StoredProcedure/GetStoredProcedureResults',
    GetWMSExtent:
        process.env.VUE_APP_REPORTING_API_URI + 'ProjectLayers/GetWMSExtent',
    getContourLayer:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetContourLayer',
    SubmitContourRequest:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetContourData',
    queueMapbookRequest:
        process.env.VUE_APP_MAPPING_API_URI + 'Mapbook/QueueMapbook',
    getMapbookStatus:
        process.env.VUE_APP_MAPPING_API_URI + 'Mapbook/GetMapbookStatus',
    getContourStyle:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/GetContourStyle',
    saveContourStyle:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/EditContourLayer',
    exportAccessDB:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/ExportToAccessDB',
    getDrawTypes: process.env.VUE_APP_MAPPING_API_URI + 'Drawing/GetDrawTypes',
    UploadFMTemplate:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Documents/UploadFiles',
    createQRCode:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Documents/CreateQRCode',
    getNSEsriToken:
        process.env.VUE_APP_DOCUMENT_API_URI + 'ClientToken/GetNSEsriToken',
    Upload3DModel:
        process.env.VUE_APP_DOCUMENT_API_URI + 'Visualization3d/Upload3DModel',
    getReportFilter:
        process.env.VUE_APP_REPORTING_API_URI + 'PowerBI/GetReportFilter',
    initializeReportingService:
        process.env.VUE_APP_REPORTING_API_URI +
        'ExternalDataSource/InitializeReporting',
    getEventLogPositions:
        process.env.VUE_APP_MAPPING_API_URI + 'EventLog/GetEventLogPositions',
    getEventID: process.env.VUE_APP_MAPPING_API_URI + 'EventLog/GetEventID',
    deleteEventLogFile:
        process.env.VUE_APP_MAPPING_API_URI + 'EventLog/DeleteEventLogFile',
    setProjectInitialExtent:
        process.env.VUE_APP_API_URI + 'Projects/SetProjectExtent',
    getProjectInitialExtent:
        process.env.VUE_APP_API_URI + 'Projects/GetProjectExtent',
    clearProjectInitialExtent:
        process.env.VUE_APP_API_URI + 'Projects/ClearProjectExtent',
    initializeRequest: process.env.VUE_APP_API_URI + 'Queue/InitializeRequest',
    getStatus: process.env.VUE_APP_API_URI + 'Queue/GetStatus',
    getQueue: process.env.VUE_APP_API_URI + 'Queue',
    getDocument: process.env.VUE_APP_DOCUMENT_API_URI + 'Documents',
};
