// import Vue from "vue";
// import App from "./App.vue";
// import router from "./router";
// import store from "./store";

// Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount("#app");
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueFloatLabel from 'vue-float-label';
import './utilities/filters';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
//import msal from "vue-msal";
//import {config} from "../config";
import 'leaflet/dist/leaflet.css';
import './assets/style/global.scss';
import VueNestable from 'vue-nestable';
import { PublicClientApplication } from '@azure/msal-browser';

Vue.prototype.$msalInstance = new PublicClientApplication({
    auth: {
        clientId: process.env.VUE_APP_CLIENT_ID,
        redirectUri: process.env.VUE_APP_REDIRECT_URI,
        postLogoutRedirectUri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,

        authority: `https://login.microsoftonline.com/${process.env.VUE_APP_TENANT_ID}`,
    },
    cache: {
        cacheLocation: 'localStorage',
    },
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(VueFloatLabel);
Vue.use(VueNestable);

Vue.config.productionTip = false;
/*Vue.use(msal, {
  auth: {
    clientId: config.clientId,
    tenantId: config.tenantId,
    redirectUri:config.redirectUri,
    postLogoutRedirectUri:config.postLogoutRedirectUri
  },
  request: {
    scopes: [
      "user.read",
      "openid",
      "profile",
      "api://dd6993d6-7c4e-4a4c-a0b6-88b89bc52dab/access_as_user",
    ],
  },
});*/

/*import axios from 'axios';
axios.interceptors.request.use(function(config) {
  
  
      config.headers.Authorization = `Bearer test`;
  
  return config;
}, function(err) {
  return Promise.reject(err);
});*/

/*import VueResource from 'vue-resource';

// telling vue.js to use this package
Vue.use(VueResource);

Vue.http.interceptors.push(function(request,next){

  // modifying request headers
  
  request.headers.set('X-CSRF-TOKEN', 'TOKEN');
  request.headers.set('Authorization', 'Bearer TOKEN');

  next(function(response){
    //logging the response body
    
});
})*/

new Vue({
    BootstrapVue,
    Vuelidate,
    store,
    router,
    render: (h) => h(App),
}).$mount('#app');
