import Vuex from 'vuex';
import Vue from 'vue';
import { commonService } from '../services/common.service';
import { userService } from '../services/users.service';
Vue.use(Vuex);
const state = {
    posts: [],
    portalsettings: [],
    userVersion: 2,
    goToPage: null,
};
const mutations = {
    SET_POSTS(state, posts) {
        state.posts = posts;
    },
    SET_ERROR(state, error) {
        state.error = error;
    },
    getPortalSettings(state, test) {
        state.portalsettings = test;
    },
    // getPermissionRequest(state) {
    //     state.posts = { loading: true};
    // },
    setUserVersion(state, version) {
        state.userVersion = version;
    },
    setGoToPage(state, projectID) {
        state.goToPage = projectID;
    },
    clearGoToPage(state) {
        state.goToPage = null;
    },
};
const actions = {
    getPermissions({ commit }) {
        //  commit('getPermissionRequest');
        //try {
        commonService
            .getPermissions()
            // axios.get('http://localhost:54654/api/Account/Login')
            .then((response) => {
                commit('SET_POSTS', response.data);
            })
            .catch(function(error) {
                commit('SET_ERROR', error.response.data);
            });
    },
    getPortalSettings({ commit }) {
        userService
            .getSavedaggregate()
            .then((res) => {
                commit('getPortalSettings', res.data[0]);
            })
            .catch(function(error) {
                commit('SET_ERROR', error.res.data);
            });
    },
};
const getters = {
    posts: (state) => state.posts,
    portalsettings: (state) => state.portalsettings,
};
export const store = {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
