import Vue from 'vue';

Vue.filter('filterByProperty', (arr, parentKey, childKey, value) => {
    if (arr && childKey && value) {
        value = value.toLowerCase();
        return arr.filter((item) => {
            if (parentKey) {
                if (item[parentKey]) {
                    return item[parentKey][childKey]
                        .toLowerCase()
                        .includes(value);
                }
            } else {
                return item[childKey].toLowerCase().includes(value);
            }
        });
    }
    return arr;
});
