<template>
    <div>
        <b-card class="eventText">
            <span v-bind:style="colorBand"></span>
            <b-container>
                <b-row class="noWrap">
                    <b-col
                        class="d-flex align-items-center cols paddingLeft"
                        cols="4"
                        v-if="attachmentImages.length"
                    >
                        <img
                            v-for="(item, index) in attachmentImages"
                            :key="index"
                            :src="item.src"
                            class="fileImage img-fluid"
                        />
                        <span class="icon-hover">
                            <img
                                @click="openPhoto()"
                                src="../../assets/fullscreen-white.svg"
                            />
                        </span>
                        <b-modal
                            size="lg"
                            centered
                            hide-footer
                            :title="eventLogItem.EventLogLocation"
                            :id="'photoModal' + eventLogItem.EventLogID"
                        >
                            <div class="d-flex justify-content-center">
                                <img
                                    class="w-100"
                                    v-for="(item, index) in attachmentImages"
                                    :key="index"
                                    :src="item.src"
                                />
                            </div>
                        </b-modal>
                    </b-col>
                    <b-col class="cols">
                        <div class="eventDesc">
                            <div class="date">
                                Date:
                                {{ eventLogItem.EventTS.replace('T', ' ') }}
                            </div>
                            <div class="content">
                                {{ eventLogItem.LogContent }}
                            </div>
                            <!-- <span @click="toggleContent(eventLogItem)" 
                :id="'more' + eventLogItem.EventLogID" 
                class="more"
              >
              </span> -->
                        </div>
                        <div>
                            <span class="personnelName">{{
                                eventLogItem.PersonnelName
                            }}</span
                            >, {{ eventLogItem.ICSPosition }}
                        </div>
                        <div>Location: {{ eventLogItem.EventLogLocation }}</div>
                        <a
                            v-for="(item, index) in attachmentFiles"
                            :key="index"
                            @click="downloadFile(item)"
                        >
                            {{ item.filename }}
                        </a>
                        <span class="btns">
                            <button
                                class="btn btn-side"
                                v-if="userIsAdmin"
                                @click="editLog"
                            >
                                <img src="../../assets/EM_edit.svg" />
                            </button>
                            <button
                                class="btn btn-side btn-zoom"
                                v-if="eventLogItem.GeoID"
                                @click="zoomTo"
                            >
                                <img
                                    src="../../assets/search-outline-grey.svg"
                                />
                            </button>
                            <button
                                class="btn btn-side btn-delete"
                                v-if="userIsAdmin"
                                @click="deleteLog"
                            >
                                <img
                                    width="30px"
                                    height="30px"
                                    src="../../assets/delete_icon-dark.svg"
                                />
                            </button>
                        </span>
                        <b-modal
                            :id="
                                'modal-editEventLog-' + eventLogItem.EventLogID
                            "
                            centered
                            hide-footer
                            title="Edit Existing Log Entry"
                        >
                            <event-log-modal
                                :editContent="eventLogItem"
                                @updateCOP="$emit('updateCOP')"
                            ></event-log-modal>
                        </b-modal>
                    </b-col>
                </b-row>
            </b-container>
        </b-card>
    </div>
</template>

<script>
import EventLogModal from './EventLogModal.vue';
import { eventLogService } from '../../services/eventlog.service';
import { commonService } from '../../services';
export default {
    components: { EventLogModal },
    name: 'EventLogItem',
    props: {
        eventLogItem: {
            type: Object,
        },
    },
    data() {
        return {
            userIsAdmin: true, //need to be set dynamically
        };
    },
    computed: {
        attachmentFiles: function() {
            let files = [];
            try {
                this.eventLogItem.Files.forEach((item) => {
                    if (item.filetype == 'document') {
                        files.push(item);
                    }
                });
            } catch (error) {
                //nothing
            }
            return files;
        },
        attachmentImages: function() {
            let files = [];
            try {
                this.eventLogItem.Files.forEach((item) => {
                    if (item.filetype == 'image') {
                        files.push(item);
                    }
                });
            } catch (error) {
                //nothing
            }
            return files;
        },
        dataSentToEdit: function() {
            let output = [];
            output = [
                {
                    label: 'EventLogID',
                    value: this.eventLogItem.EventLogID,
                    display: false,
                },
                {
                    label: 'Description',
                    value: this.eventLogItem.LogContent,
                    display: true,
                    inputType: 'textarea',
                },
                {
                    label: 'Personnel',
                    value: this.eventLogItem.PersonnelName,
                    display: true,
                },
                {
                    label: 'ICS Role',
                    value: this.eventLogItem.ICSPosition,
                    display: true,
                    inputType: 'select',
                },
                {
                    label: 'Timestamp',
                    value: this.eventLogItem.EventTS,
                    display: true,
                    inputType: 'datetime-local',
                },
                {
                    label: 'Location',
                    value: this.eventLogItem.EventLogLocation,
                    display: true,
                },
                {
                    label: 'GeoID',
                    value: this.eventLogItem.GeoID,
                    display: false,
                },
                {
                    label: 'Files',
                    value: this.eventLogItem.Files,
                    display: false,
                },
            ];
            return output;
        },
        colorBand() {
            return {
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: '4px',
                'border-radius': '4px',
                'background-color': this.eventLogItem.Color,
            };
        },
    },
    methods: {
        downloadFile(item) {
            commonService.downloadThumbnailByID(item.documentID, item.filename);
        },
        zoomTo() {
            //in map component, list to event and zoom to geometry;
            eventLogService.getGeometry(this.eventLogItem.GeoID).then((res) => {
                this.$emit('zoomToGeometry', res.data);
            });
        },
        deleteLog() {
            let userSelect = confirm('Sure to delete this log?');
            if (userSelect) {
                //call API to delete this log
                eventLogService
                    .deleteEventLog(this.eventLogItem.EventLogID)
                    .then((res) => {
                        try {
                            if (res.data.Message.indexOf('success') > 0) {
                                alert('Log deleted');
                                //refresh event logs;
                                this.$emit('updateCOP');
                            }
                        } catch (error) {
                            //nothing
                        }
                    });
            }
        },
        editLog() {
            //open modal to handle attribute and geometry edit
            this.$bvModal.show(
                'modal-editEventLog-' + this.eventLogItem.EventLogID
            );
        },
        saveEditToSQL(item) {
            //convert key value pair array into an object for database submit;
            let payload = {};
            item.forEach((attr) => {
                payload[attr.label] = attr.value;
            });
            if (payload.GeoID == null) {
                delete payload['GeoID'];
            }
            payload = commonService.objectToFormData(payload);
            this.$bvModal.hide('eventlog' + this.eventLogItem.EventLogID);
            eventLogService.updateEventLog(payload).then((res) => {
                //
                try {
                    if (res.data.Message.indexOf('success') > 0) {
                        alert('Log updated');
                        //refresh event logs;
                        this.$parent.getCOPData();
                    }
                } catch (error) {
                    //nothing
                }
            });
        },
        editGeometry(item) {},
        openPhoto() {
            commonService
                .downloadDocumentByID(
                    this.eventLogItem.Files[0].documentID,
                    this.eventLogItem.Files[0].filename,
                    'image'
                )
                .then((resp) => {
                    this.attachmentImages[0].src =
                        'data:image/jpg;base64, ' + resp;
                });
            this.$bvModal.show('photoModal' + this.eventLogItem.EventLogID);
        },
    },
};
</script>

<style scoped>
.rowStyle {
    border-radius: 4px !important;
    width: 90% !important;
}
.col {
    padding-left: 0;
}
.container {
    padding-left: 0;
    padding-right: 0;
}
.row {
    margin-left: 0;
    margin-right: 0;
}
.btn {
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 40px;
    /* need correct font-family */
}
.btn-side {
    width: 40px;
    background-color: transparent;
}
.btn-zoom {
    padding-right: 1px;
}
.btn-delete {
    padding-right: 5px;
}
.eventDesc .more::before {
    color: black;
    padding-left: 5px;
    cursor: pointer;
    text-decoration: underline;
    /* content: 'View More...'; */
}
/* .eventDesc.active .more::before{
  content: 'View Less...' !important;
} */
.eventDesc.active .content {
    height: auto;
}
.card {
    border: solid 1px rgb(189, 189, 189) !important;
    margin-bottom: 10px;
}
.btns {
    position: absolute;
    right: 15px;
    top: -20px;
    height: 100%;
    width: 4px;
    padding: 0;
}
.eventText {
    font-size: 15px;
    width: auto;
    color: rgb(129, 129, 129);
}
.eventDesc {
    padding-bottom: 5px;
}
.content {
    position: relative;
    /* height: 25px; */
    overflow: hidden;
}
.personnelName {
    font-weight: bold;
    color: black;
}
.modal-dialog .modal-body {
    padding: 25px 25px 25px 25px !important;
}
.icon-hover {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    left: 26%;
}
.icon-hover:hover {
    opacity: 1;
}
.fileImage {
    opacity: 1;
    display: block;
}
.date {
    font-weight: bold;
    color: black;
}
.noWrap {
    flex-wrap: nowrap;
}
.paddingLeft {
    padding-left: 9px !important;
}

/* left border colors */
.ES_EV_Emergency_Coordination_Director {
    background-color: fuchsia;
}
.ES_EV_Operations_Section_Chief {
    background-color: white;
}
.ES_EV_Finance_Admin_Section {
    background-color: green;
}
.ES_EV_Documentation_Unit_Leader {
    background-color: blue;
}
.ES_EV_Finance_Section {
    background-color: green;
}
.ES_EV_Resource_Unit {
    background-color: yellow;
}
.ES_EV_Planning_Section {
    background-color: #1c74bb;
}
.ES_EV_Update_Logging {
    background-color: black;
    font-weight: bold;
}
.ES_EV_WWCO-EOC {
    background-color: fuchsia;
}
.ES_EV_Exercise_Setup {
    background-color: transparent;
}
</style>
