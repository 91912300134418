<template>
    <div
        v-if="showPane"
        ref="legendPane"
        :style="legendPaneStyle"
        class="legendPane"
    >
        <!-- the parameter in the directive is the min file height to be allowd, anything less is hidden -->
        <div
            v-hide-small-images="15"
            v-for="(legendURL, index) in legendURLs"
            :ref="'legendImgDiv-' + index"
            :key="index"
            class="imgDiv"
        >
            <img
                :src="legendURL"
                class="legendImg"
                @load="recalculateVisibleLegend"
            />
        </div>
        <span id="noLayerText" v-show="!visibleLegend"
            >No layers in visible extent</span
        >
        <b-divider></b-divider>
        <div class="ml-2 mt-3" style="border-top: 1px solid black ;">
            <b-form-checkbox
                :id="'checkbox-box'"
                v-model="setting"
                :name="'checkbox-box'"
                :value="true"
                :unchecked-value="false"
            >
                <div class="mt-1">Always show counts</div>
            </b-form-checkbox>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';

// added a directive to hide divs that contain an img element that is less than x pixels in height,
//  x is specified as the binding, see the example in the template above for usage
Vue.directive('hide-small-images', {
    inserted: function(el, binding, vnode) {
        const images = el.querySelectorAll('img');
        const checkImageSizes = () => {
            images.forEach((img) => {
                if (img.clientHeight < binding.value) {
                    el.style.display = 'none';
                } else {
                    el.style.display = 'block';
                }
            });
        };
        checkImageSizes();
        vnode.context.$nextTick(() => {
            checkImageSizes();
        });
    },
    update: function(el, binding, vnode) {
        el.style.display = '';
        const images = el.querySelectorAll('img');
        const checkImageSizes = () => {
            images.forEach((img) => {
                if (img.clientHeight < binding.value) {
                    el.style.display = 'none';
                }
            });
        };
        checkImageSizes();
        vnode.context.$nextTick(() => {
            checkImageSizes();
        });
    },
});

export default {
    props: {
        showPane: {
            type: Boolean,
            default: false,
            required: true,
        },
        wmsLayers: {
            type: Array,
            default: () => [],
            required: true,
        },
        bbox: {
            type: Object,
            default: () => {},
            required: true,
        },
        mapSize: {},
        scalebarUnits: {
            type: String,
            required: false,
            default: 'Metric',
        },
        zoomLevel: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            width: 270,
            visibleLegend: false,
            setting: false,
        };
    },
    mounted() {
        this.visibleLegend = this.legendURLs.length > 0;
    },
    methods: {
        // need to recalculate if the 'No layers in extent' message should be shown
        recalculateVisibleLegend() {
            if (!this.showPane) {
                return;
            }
            this.visibleLegend = true;
            if (
                this.legendURLs.length == 0 ||
                this.$refs['legendPane'].clientHeight < 10
            ) {
                this.visibleLegend = false;
                return;
            }
            // check all of the legend image divs, if any are visible, don't show the 'No layers' text
            for (var i = 0; i < this.legendURLs.length; i++) {
                if (
                    this.$refs['legendImgDiv-' + i] &&
                    this.$refs['legendImgDiv-' + i].length > 0
                ) {
                    if (
                        this.$refs['legendImgDiv-' + i][0].style.display == ''
                    ) {
                        this.visibleLegend = true;
                        return;
                    }
                }
            }
            this.visibleLegend = false;
            return;
        },
    },
    computed: {
        // scalebar both: 32.188
        // scalebar single: 12.094
        legendPaneStyle: function() {
            return {
                position: 'absolute',
                zIndex: 9999,
                bottom:
                    this.scalebarUnits.toLowerCase() == 'both'
                        ? 77 + 'px'
                        : 62 + 'px',
                width: this.width + 'px',
                left: '64px',
            };
        },
        bboxURL: function() {
            return (
                this.bbox._southWest.lng +
                ',' +
                this.bbox._southWest.lat +
                ',' +
                this.bbox._northEast.lng +
                ',' +
                this.bbox._northEast.lat
            );
        },
        legendURLs: function() {
            let urls = [];
            this.wmsLayers.forEach((layer) => {
                if (
                    layer.isChecked &&
                    layer.maxZoom >= this.zoomLevel &&
                    layer.minZoom <= this.zoomLevel
                ) {
                    var countMatched;
                    if (this.setting) {
                        countMatched = true;
                    } else {
                        if (this.$store.state.projects.mapData.mapZoom > 13) {
                            countMatched = true;
                        } else {
                            countMatched = false;
                        }
                    }
                    var legendURL =
                        layer.legend.replace('http://', 'https://') +
                        `&LEGEND_OPTIONS=countMatched:${countMatched};hideEmptyRules:true;forceLabels:on;fontAntiAliasing:true&SRS=EPSG:4326&bbox=` +
                        this.bboxURL +
                        '&srcwidth=' +
                        this.mapSize.x +
                        '&srcheight=' +
                        this.mapSize.y;
                    if (layer.options.CQL_Filter) {
                        legendURL += '&CQL_FILTER=' + layer.options.CQL_Filter;
                    }
                    urls.push(legendURL);
                }
            });
            return urls;
        },
    },
    watch: {
        // recalculate when the extent changes if features are now in or out
        bbox() {
            this.$nextTick(() => {
                this.recalculateVisibleLegend();
            });
        },
        // recalculate when the list of legend URLs updates, we may have added layers
        legendURLs(newURLs) {
            this.visibleLegend = Boolean(newURLs.length);
            // if there are not any URLs, we don't have to calculate bounding box intersects
            if (newURLs.length) {
                this.$nextTick(() => {
                    this.recalculateVisibleLegend();
                });
            }
        },
    },
};
</script>
<style scoped>
.legendPane {
    border-radius: 4px;
    width: 300px;
    background-color: white;
    max-height: 64%;
    height: auto;
    overflow-y: scroll;
    border: solid 1px darkgray;
}

.imgDiv {
    /* this is to hide the empty entries since it is line-height (1.5) * font-size (0) */
    font-size: 0px !important;
}
.btn-layers {
    color: black;
    background-color: white;
    border: none !important;
}

/* tried to match the font in the images from geoserver */
#noLayerText {
    padding-left: 15px;
    font-size: 14px;
}
</style>
