<template>
    <b-container>
        <div>
            <b-row class="mt-3">
                <b-col cols="auto" class="mr-auto">
                    <span
                        >*Note that empty directories will not be created</span
                    >
                </b-col>
                <b-col cols="auto">
                    <b-button
                        class="buttonSelect nextbutton"
                        rounded
                        id="uploadbtn"
                        :disabled="!withUploadFiles"
                    >
                        Upload
                    </b-button>
                </b-col>
            </b-row>

            <b-row class="mt-3">
                <b-col>
                    <div id="dropTarget" class="outer-wrapper">
                        <b-icon-cloud-arrow-up
                            class="uploadicon"
                        ></b-icon-cloud-arrow-up>
                        <span id="dropElement"
                            >Drag and drop folder to upload</span
                        >
                        <span>Or</span>
                        <ejs-uploader
                            ref="uploadObj"
                            id="defaultfileupload"
                            name="UploadFiles"
                            :asyncSettings="path"
                            :dropArea="dropElement"
                            :directoryUpload="directory"
                            :autoUpload="false"
                            :success="onSuccess"
                            :failure="onFailure"
                            :uploading="onFileUpload"
                            :selected="onFileSelect"
                            maxFileSize="1073741824"
                        ></ejs-uploader>
                    </div>
                </b-col>
            </b-row>
        </div>

        <SingleProjectModal
            :isShow="showProjectSelectModal"
            @setSelectedProject="setSelectedProject"
        ></SingleProjectModal>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :showConnecting="showConnecting"
            :failureToastMessage="failureToastMessage"
            :connectingToastMessage="connectingToastMessage"
            :successToastMessage="successToastMessage"
        />
    </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SingleProjectModal from '../Modals/SingleProjectModal.vue';
import ToastMessages from '../ToastMessages.vue';
import { commonService } from '../../services/common.service';
import { projectService } from '../../services/project.service';
import Vue from 'vue';
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
Vue.use(UploaderPlugin);

export default {
    name: 'DirectoryUpload',
    components: {
        SingleProjectModal,
        ToastMessages,
    },
    data() {
        let ajaxSettings = {
            url:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureFileOperations',
            getImageUrl:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureGetImage',
            uploadUrl:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureUpload',
            downloadUrl:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureDownload',
        };
        return {
            path: {
                saveUrl: ajaxSettings.uploadUrl,
                removeUrl:
                    'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
            },
            directory: true,
            dropElement: '#dropTarget',
            showProjectSelectModal: false,
            selectedProject: null,
            withUploadFiles: false,
            showSuccess: false,
            showFailure: false,
            showConnecting: false,
            failureToastMessage: null,
            connectingToastMessage: null,
            successToastMessage: null,
            uploadedDirectoryName: [],
            counter: 0,
            uploadedFilesName: [],
            filesUpdatedCounter: 0,
            uploadStarted: false,
        };
    },

    mounted: function() {
        document.getElementById('uploadbtn').onclick = (args) => {
            this.$refs.uploadObj.upload(this.$refs.uploadObj.getFilesData());
        };
    },
    created() {
        if (this.selectedProjectsArr.length > 1) {
            this.showProjectSelectModal = true;
        } else {
            this.selectedProject = this.selectedProjectsArr[0].ProjectID;
        }
    },
    methods: {
        setSelectedProject(projID) {
            this.selectedProject = projID;
            this.showProjectSelectModal = false;
        },

        onFileUpload: function(args) {
            let updirname = args.fileData.name.substring(
                0,
                args.fileData.name.lastIndexOf('/')
            );
            if (!this.uploadedDirectoryName.includes(updirname)) {
                this.uploadedDirectoryName.push(updirname);
            }
            // add addition data as key-value pair.
            let token = commonService.getCookie('accessToken');
            args.currentRequest.setRequestHeader(
                'Authorization',
                'Bearer ' + token
            );
            args.customFormData = [
                { Action: 'save' },
                { Path: '/' },
                { ProjectId: this.ProjectID },
                { UploadType: 'uploadDirectory' },
            ];
            this.uploadedDirectoryName = [
                ...new Set(this.uploadedDirectoryName),
            ];
            this.uploadedFilesName.push({
                directory: args.fileData.name.substring(
                    0,
                    args.fileData.name.lastIndexOf('/')
                ),
                fileName: args.fileData.name.split('/').pop(),
            });
        },
        onSuccess: async function(args) {
            if (args.response.statusCode == 200) {
                while (this.uploadedDirectoryName.length > this.counter) {
                    //handle top level directory
                    if (this.counter == 0 && this.uploadStarted == false) {
                        this.uploadStarted = true;
                        let splitDirectory = this.uploadedDirectoryName[
                            this.counter
                        ].split('/');
                        await this.createUploadedDirectory(splitDirectory[0]);
                    }
                    await this.createUploadedDirectory(
                        this.uploadedDirectoryName[this.counter]
                    );
                    this.counter++;
                }
                while (
                    this.filesUpdatedCounter < this.uploadedFilesName.length
                ) {
                    projectService
                        .UpdateTblDocuments(
                            this.uploadedFilesName[this.filesUpdatedCounter]
                                .directory,
                            this.ProjectID,
                            this.uploadedFilesName[this.filesUpdatedCounter]
                                .fileName
                        )
                        .then((res) => {});
                    this.filesUpdatedCounter++;
                }

                this.withUploadFiles = false;
                this.successToastMessage = 'Directory uploaded';
                this.showSuccess = true;
                this.showFailure = false;
                setTimeout(() => {
                    this.showSuccess = false;
                }, 5000);
            }
            this.uploadStarted = false;
        },
        onFailure: function(args) {
            if (args.response.statusCode == 500) {
                this.failureToastMessage =
                    'Something went wrong. Please try again later.';
                this.showFailure = true;
                this.showSuccess = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
            } else if (args.response.statusCode == 406) {
                this.fileListDetails = [];
                this.filesDetails = [];
                this.withUploadFiles = false;
                this.failureToastMessage = 'Directory already exists.';
                this.showFailure = true;
                this.showSuccess = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
            }
        },

        onFileSelect(args) {
            this.withUploadFiles = true;
        },

        createUploadedDirectory(directoryName) {
            return new Promise((resolve) => {
                projectService
                    .createUploadedDirectory(directoryName, this.ProjectID)
                    .then((res) => {
                        resolve('resolved');
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },
    },
    computed: {
        ...mapState({
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
            ...mapGetters('projects', { ProjectID: 'ProjectID' }),
        }),
        ...mapGetters('projects', { ProjectID: 'ProjectID' }),
    },
};
</script>

<style>
.outer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 195px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
}
</style>
