import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const userGroupService = {
    addGroups,
    editGroups,
    deleteGroups,
    countUserGroup,
    getUserGroupsforAccessManagement,
};

// let userDetails=JSON.parse(localStorage.getItem('userDetails'))
function addGroups(groupData) {
    // return axios.post(apiConstants.addGroups, groupData)
    return request({
        url: apiConstants.addGroups,
        data: groupData,
        method: 'post',
    });
}

function editGroups(groupData, groupId) {
    // return axios.put(apiConstants.editGroups +'/'+groupId, groupData,)
    return request({
        url: apiConstants.editGroups + '/' + groupId,
        data: groupData,
        method: 'put',
    });
}

function deleteGroups(groupId) {
    // return axios.delete(apiConstants.deleteGroups +'/'+ groupId)
    return request({
        url: apiConstants.deleteGroups + '/' + groupId,
        method: 'delete',
    });
}

function countUserGroup(groupId) {
    // return axios.get(apiConstants.countUserGroups +'?id=' +groupId)
    return request({ url: apiConstants.countUserGroups + '?id=' + groupId });
}

function getUserGroupsforAccessManagement() {
    // return axios.get(apiConstants.getUserGroups)
    return request({ url: apiConstants.getUserGroups });
}
