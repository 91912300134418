import store from '../store';
export const dreamsFunctions = {
    getShapeLayers,
    getHydraulicsBase,
    getHydraulicsSecondary,
    getShapeAttribute,
    clearMap,
    getSecondaryLayers,
    getRiskModel,
    hasLineLayer,
    getColorRange,
    getResetRisks,
    getPresetRange,
};

let lyrIdLine = 20;
let lyrIdPoint = 30;
function styleProjectLayers(projectLayers) {
    projectLayers.forEach((lyr) => {
        let projStyling = {
            FontColour: lyr.FontColour,
            FontRotation: lyr.FontRotation,
            FontSize: lyr.FontSize,
            FontStyle: lyr.FontStyle,
            FontTransparency: lyr.FontTransparency,
            FontType: lyr.FontType,
            LineStyle: lyr.LineStyle,
            PolygonColour: lyr.PolygonColour,
            PolygonStyle: lyr.PolygonStyle,
            PolygonTransparency: lyr.PolygonTransparency,
            PolygonBorderColour: lyr.PolygonBorderColour,
            PolygonWidth: lyr.PolygonWidth,
            Symbology: lyr.Symbology,
            SymbologyColour: lyr.SymbologyColour,
            LineWidth: 5,
            LineColour: lyr.LineColour,
            Opacity: 100,
        };
        lyr.Geometry.visible = true;
        let adaptLayerProperties = [
            {
                name: 'Geometry',
                value: '',
                tooltip: true,
                popup: true,
                label: false,
            },
        ];
        lyr.Geometry.features.forEach((geom) => {
            geom.styling = projStyling;
            geom.popupContent = geom.properties;
            geom.properties = adaptLayerProperties;
            geom.isDream = true;
            //geom.RiskModel = true;
            geom.ThreeDModel = false;
        });
    });
    return projectLayers;
}

function styleDreamsLayers(
    projectLayers,
    property,
    range,
    selectedLegend,
    opacity = 100
) {
    let adaptLayerProperties = [
        {
            name: 'Geometry',
            value: '',
            tooltip: true,
            popup: true,
            label: false,
        },
    ];
    projectLayers.forEach((lyr) => {
        if (lyr.Geometry.features[0].geometry.type === 'LineString') {
            let projStyling = {
                LineWidth: lyr.LineWidth,
                LineColour: lyr.LineColour,
                Opacity: opacity,
            };

            lyr.Geometry.visible = true;

            lyr.Geometry.features.forEach(function(feature, index) {
                feature.styling = projStyling;
                let newStyle = getStyleWithOpacity(
                    range,
                    feature.popupContent[property],
                    selectedLegend
                );
                if (newStyle != null) feature.styling = newStyle;
                feature.properties = adaptLayerProperties;
                feature.isDream = true;
                feature.ThreeDModel = false;
            });
        }
    });

    return projectLayers;
}

function getStyleWithOpacity(ranges, value, selectedLegend) {
    for (let j = 0; j < ranges.length; j++) {
        const range = ranges[j];

        if (value >= range.min && value <= range.max) {
            return {
                LineWidth: 5,
                LineColour: range.style,
                Opacity: selectedLegend.includes(range.id) ? 100 : 0,
            };
        }
    }

    return null;
}
function getStyleForValue(ranges, value) {
    for (let j = 0; j < ranges.length; j++) {
        const range = ranges[j];

        if (value >= range.min && value <= range.max) {
            return range.style;
        }
    }

    return null;
}

function getShapeLayers(geojson, projectid) {
    let shapeLayers = [];
    for (const layer in geojson) {
        if (geojson[layer].features.length > 0) {
            switch (geojson[layer].features[0].geometry.type) {
                case 'LineString':
                    shapeLayers.push(setLineStyle(geojson[layer], layer));
                    break;
                case 'Polygon':
                    shapeLayers.push(setPolygonStyle(geojson[layer], layer));
                    break;
                default:
                    //Point
                    shapeLayers.push(setPointStyle(geojson[layer], layer));
            }
        } else {
            shapeLayers.push(setPointStyle(geojson[layer], layer));
        }
    }

    let projectDataLayers = [
        {
            ProjectID: projectid,
            ProjectName: 'Dreams',
            ProjectLayers: shapeLayers,
            WMSLayer: [],
        },
    ];
    projectDataLayers.forEach((proj) => {
        if (
            (proj.ProjectLayers == undefined ||
                proj.ProjectLayers.length == 0) &&
            (proj.WMSLayer.length == undefined || proj.WMSLayer.length == 0)
        ) {
            const emptyLayers = [
                {
                    DisplayName: 'No layers defined. Upload shape files.',
                    Geometry: {
                        features: [],
                        type: 'FeatureCollection',
                        visible: true,
                    },
                },
            ];
            proj.ProjectLayers = emptyLayers;
        }

        proj.ProjectLayers.forEach((projLyr) => {
            try {
                projLyr.ShowLabels =
                    projLyr.Geometry.features[0].properties[0].tooltip;
            } catch (e) {
                e;
            }
        });
        proj.projectDataLayers = styleProjectLayers(proj.ProjectLayers);
    });
    projectDataLayers.forEach((item) => {
        item.ProjectLayers.forEach((proj) => {
            let dataSource = proj.Geometry;
            proj.geomDataLoaded = proj.Geometry.visible;
            if (proj.Geometry.features.length > 0) {
                dataSource.labelVisibleIndex = 0;
                if (!dataSource.labelVisibleIndex) {
                    for (
                        let i = 0;
                        i < dataSource.features[0].properties.length;
                        i++
                    ) {
                        if (dataSource.features[0].properties[i].tooltip) {
                            dataSource.labelVisibleIndex = i;
                        }
                    }
                }
            }
            if (
                proj.Geometry.features.length &&
                proj.Geometry.features[0].geometry.type == 'Point'
            ) {
                proj.Clustered = true;
            } else {
                proj.Clustered = false;
            }
        });
    });

    store.commit('projects/clearMapData');
    store.commit('projectLayers/clearMapData');
    store.commit('projectLayers/addProjectLayerData', projectDataLayers);
}

function styleHydraulics(projectLayers, property, range, opacity) {
    Object.values(projectLayers).forEach((lyr) => {
        if (
            lyr.features.length > 0 &&
            lyr.features[0].geometry.type === 'LineString'
        ) {
            let projStyling = {
                LineWidth: lyr.LineWidth,
                LineColour: lyr.LineColour,
                Opacity: opacity,
                lyrorder: lyr.lyrorder,
            };

            lyr.visible = true;

            lyr.features.forEach(function(feature, index) {
                feature.styling = projStyling;
                let newStyle = getStyleForValue(
                    range,
                    feature.popupContent[property]
                );
                if (newStyle != null) {
                    feature.styling = {
                        LineWidth: 5,
                        LineColour: newStyle,
                        Opacity: opacity,
                        lyrorder: lyr.lyrorder,
                    };
                }
            });
        }
    });

    return projectLayers;
}
function getHydraulicsBase(geojson, property, range) {
    let shapeLayers = [];
    for (const layer in geojson) {
        shapeLayers.push(geojson[layer]);
    }

    let projectDataLayers = [
        {
            ProjectName: 'Dreams',
            ProjectLayers: shapeLayers,
            WMSLayer: [],
        },
    ];
    projectDataLayers.forEach((proj) => {
        proj.projectDataLayers = styleHydraulics(
            proj.ProjectLayers,
            property,
            range,
            100
        );
    });

    store.commit('projects/clearMapData');
    store.commit('projectLayers/clearMapData');
    store.commit('projectLayers/addProjectLayerData', projectDataLayers);
}
function getHydraulicsSecondary(geojson, property, range, opacity = 100) {
    let shapeLayers = [];
    for (const layer in geojson) {
        shapeLayers.push(geojson[layer]);
    }

    let projectDataLayers = [
        {
            ProjectName: 'Dreams',
            ProjectLayers: shapeLayers,
            WMSLayer: [],
        },
    ];
    projectDataLayers.forEach((proj) => {
        proj.projectDataLayers = styleHydraulics(
            proj.ProjectLayers,
            property,
            range,
            opacity
        );
    });

    store.commit('projectLayers/appendDreamsLayerData', projectDataLayers);
}
function getSecondaryLayers(geojson) {
    let shapeLayers = [];
    for (const layer in geojson) {
        if (geojson[layer].features.length > 0) {
            switch (geojson[layer].features[0].geometry.type) {
                case 'LineString':
                    shapeLayers.push(
                        setLineStyle(geojson[layer], 'Model B - ' + layer)
                    );
                    break;
                case 'Polygon':
                    shapeLayers.push(
                        setPolygonStyle(geojson[layer], 'Model B - ' + layer)
                    );
                    break;
                default:
                    //Point
                    shapeLayers.push(
                        setPointStyle(geojson[layer], 'Model B - ' + layer)
                    );
            }
        } else {
            shapeLayers.push(
                setPointStyle(geojson[layer], 'Model B - ' + layer)
            );
        }
    }

    let projectDataLayers = [
        {
            ProjectName: 'Dreams',
            ProjectLayers: shapeLayers,
            WMSLayer: [],
        },
    ];
    projectDataLayers.forEach((proj) => {
        if (
            (proj.ProjectLayers == undefined ||
                proj.ProjectLayers.length == 0) &&
            (proj.WMSLayer.length == undefined || proj.WMSLayer.length == 0)
        ) {
            const emptyLayers = [
                {
                    DisplayName: 'No layers defined. Upload shape files.',
                    Geometry: {
                        features: [],
                        type: 'FeatureCollection',
                        visible: true,
                    },
                },
            ];
            proj.ProjectLayers = emptyLayers;
        }

        proj.ProjectLayers.forEach((projLyr) => {
            try {
                projLyr.ShowLabels =
                    projLyr.Geometry.features[0].properties[0].tooltip;
            } catch (e) {
                e;
            }
        });
        proj.projectDataLayers = styleProjectLayers(proj.ProjectLayers);
    });
    projectDataLayers.forEach((item) => {
        item.ProjectLayers.forEach((proj) => {
            let dataSource = proj.Geometry;
            proj.geomDataLoaded = proj.Geometry.visible;
            if (proj.Geometry.features.length > 0) {
                dataSource.labelVisibleIndex = 0;
                if (!dataSource.labelVisibleIndex) {
                    for (
                        let i = 0;
                        i < dataSource.features[0].properties.length;
                        i++
                    ) {
                        if (dataSource.features[0].properties[i].tooltip) {
                            dataSource.labelVisibleIndex = i;
                        }
                    }
                }
            }
            if (
                proj.Geometry.features.length &&
                proj.Geometry.features[0].geometry.type == 'Point'
            ) {
                proj.Clustered = true;
            } else {
                proj.Clustered = false;
            }
        });
    });

    store.commit('projectLayers/appendDreamsLayerData', projectDataLayers);
}

function setPointStyle(data, layer) {
    let nodesLayer = {
        DatasetID: 0,
        DisplayName: layer,
        FontColour: '#000000ff',
        FontRotation: '0°',
        FontSize: 9,
        FontStyle: 'Normal',
        FontTransparency: 0,
        FontType: 'Arial',
        Geometry: data,
        LabelPosition: null,
        LineColour: '#000000ff',
        LineStyle: null,
        LineWidth: 1,
        lyrid: lyrIdPoint,
        lyrorder: 0,
        Opacity: 100,
        PolygonBorderColour: '#000000ff',
        PolygonColour: '#000000ff',
        PolygonStyle: null,
        PolygonTransparency: 0,
        PolygonWidth: 1,
        Position: 'topCenter',
        Symbology: null,
        SymbologyColour: '#000000ff',
        isWMS: false,
    };
    lyrIdPoint++;
    return nodesLayer;
}
function setLineStyle(data, layer) {
    let linksLayer = {
        DatasetID: 0,
        DisplayName: layer,
        FontColour: '#000000ff',
        FontRotation: null,
        FontSize: 9,
        FontStyle: 'Normal',
        FontTransparency: 0,
        FontType: 'Arial',
        Geometry: data,
        LineColour: '#0000ffff',
        LineStyle: null,
        LineWidth: 3,
        lyrid: lyrIdLine,
        lyrorder: lyrIdLine,
        PolygonBorderColour: '#000000ff',
        PolygonColour: '#000000ff',
        PolygonStyle: null,
        PolygonTransparency: 0,
        PolygonWidth: 0,
        Position: 'topCenter',
        Symbology: 'Circle',
        SymbologyColour: '#000000ff',
        isWMS: false,
    };
    lyrIdLine++;
    return linksLayer;
}
function setPolygonStyle(data, layer) {
    let returnLayer = {
        DatasetID: 0,
        DisplayName: layer,
        FontColour: '',
        FontRotation: '',
        FontSize: '',
        FontStyle: '',
        FontTransparency: '',
        FontType: '',
        Geometry: data,
        LayerOrder: 0,
        LineColour: '',
        LineStyle: '',
        LineWidth: '',
        PolygonBorderColour: '#000000',
        PolygonColour: '#0A1172',
        PolygonStyle: 'Solid',
        PolygonTransparency: '50',
        PolygonWidth: '1',
        Position: '',
        ProjectLayerID: 0,
        Symbology: '',
        SymbologyColour: '',
        isWMS: false,
    };

    return returnLayer;
}

function getShapeAttribute(arr, property) {
    let extractedValue = arr.map((item) => item.popupContent[property]);
    return extractedValue;
}

function clearMap() {
    store.commit('projects/clearMapData');
    store.commit('projectLayers/clearMapData');
}

function getRiskModel(geojson, property, range, selectedLegend) {
    let shapeLayers = [];
    for (const layer in geojson) {
        if (geojson[layer].features.length > 0) {
            switch (geojson[layer].features[0].geometry.type) {
                case 'LineString':
                    shapeLayers.push(setLineStyle(geojson[layer], layer));
                    break;
            }
        }
    }

    let projectDataLayers = [
        {
            ProjectName: 'Dreams',
            ProjectLayers: shapeLayers,
            WMSLayer: [],
        },
    ];
    projectDataLayers.forEach((proj) => {
        if (
            (proj.ProjectLayers == undefined ||
                proj.ProjectLayers.length == 0) &&
            (proj.WMSLayer.length == undefined || proj.WMSLayer.length == 0)
        ) {
            const emptyLayers = [
                {
                    DisplayName: 'No layers defined. Upload shape files.',
                    Geometry: {
                        features: [],
                        type: 'FeatureCollection',
                        visible: true,
                    },
                },
            ];
            proj.ProjectLayers = emptyLayers;
        }

        proj.ProjectLayers.forEach((projLyr) => {
            try {
                projLyr.ShowLabels =
                    projLyr.Geometry.features[0].properties[0].tooltip;
            } catch (e) {
                e;
            }
        });
        proj.projectDataLayers = styleDreamsLayers(
            proj.ProjectLayers,
            property,
            range,
            selectedLegend,
            100
        );
    });
    projectDataLayers.forEach((item) => {
        item.ProjectLayers.forEach((proj) => {
            let dataSource = proj.Geometry;
            proj.geomDataLoaded = proj.Geometry.visible;
            if (proj.Geometry.features.length > 0) {
                dataSource.labelVisibleIndex = 0;
                if (!dataSource.labelVisibleIndex) {
                    for (
                        let i = 0;
                        i < dataSource.features[0].properties.length;
                        i++
                    ) {
                        if (dataSource.features[0].properties[i].tooltip) {
                            dataSource.labelVisibleIndex = i;
                        }
                    }
                }
            }
            proj.Clustered = false;
        });
    });

    store.commit('projects/clearMapData');
    store.commit('projectLayers/clearMapData');
    store.commit('projectLayers/addProjectLayerData', projectDataLayers);
}

function hasLineLayer(geojson) {
    let withLineString = false;
    for (const layer in geojson) {
        if (geojson[layer].features.length > 0) {
            if (geojson[layer].features[0].geometry.type === 'LineString') {
                withLineString = true;
            }
        }
    }
    return withLineString;
}

function getColorRange(geojson, column) {
    let range = [];
    let colors = ['#00b050', '#92d050', '#ffff00', '#ffc000', '#ff0000'];
    let displayLayer = '';

    for (const layer in geojson) {
        if (geojson[layer].features.length > 0) {
            if (geojson[layer].features[0].geometry.type === 'LineString') {
                displayLayer = geojson[layer];
            }
        }
    }

    if (displayLayer !== '') {
        let allRows = dreamsFunctions.getShapeAttribute(
            displayLayer.features,
            column
        );
        let min = Math.min(...allRows);
        let max = Math.max(...allRows);

        let increment = (max - min) / 5;
        let rangemin = min;
        let rangemax = 0;
        for (let i = 0; i < 5; i++) {
            if (i > 0) rangemin += increment;
            rangemax = rangemin + increment;
            range.push({ min: rangemin, max: rangemax, style: colors[i] });
        }
    }

    return range;
}

function getResetRisks(geojson) {
    let projectDataLayers = [
        {
            ProjectName: 'Dreams',
            ProjectLayers: [geojson],
            WMSLayer: [],
        },
    ];
    store.commit('projectLayers/addProjectLayerData', projectDataLayers);
}

function getPresetRange(geojson, column, preset) {
    let range = [];
    let colors = ['#00b050', '#92d050', '#ffff00', '#ffc000', '#ff0000'];
    let displayLayer = '';

    for (const layer in geojson) {
        if (geojson[layer].features.length > 0) {
            if (geojson[layer].features[0].geometry.type === 'LineString') {
                displayLayer = geojson[layer];
            }
        }
    }

    if (displayLayer !== '') {
        let allRows = dreamsFunctions.getShapeAttribute(
            displayLayer.features,
            column
        );
        let min = Math.min(...allRows);
        let max = Math.max(...allRows);

        let rangemin = min;
        let rangemax = 0;
        for (let i = 0; i < preset.length; i++) {
            if (i == 0) {
                rangemin = min >= preset[i].max ? preset[i].max - 1 : min;
            } else {
                rangemin = preset[i].min;
            }
            if (i == preset.length - 1) {
                //rangemax = max;
                rangemax = max < preset[i].max ? preset[i].min + 1 : max;
            } else {
                rangemax = preset[i].max;
            }
            range.push({
                id: preset[i].id,
                min: rangemin,
                max: rangemax,
                style: colors[i],
                description: preset[i].description,
            });
        }
    }

    return range;
}
