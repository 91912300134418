import { datasetService } from '../../../../services/dataset.service';
import { projectService } from '../../../../services/project.service';

export const DatasetColumnsUtils = {
    getInitParams,
    doInitLoad,
    getDatasetColumns,
    getDatasetColumnsForRequest,
    getTodayDate,
    getDataCoordinates,
    doSPInitLoad,
    validateInput
};

function getInitParams(dataSetID, dataSourceId, tablename, dataSetType) {
    let param = {};
    //TODO : once edit procedures available change and refactor
    if (dataSetID) {
        if (dataSetType === 'PowerBI') {
            param = {
                dataSourceId: dataSourceId,
                dataSetId: dataSetID ? dataSetID : null,
            };
        } else {
            param = {
                dataSourceId: dataSourceId,
                tableName: tablename,
                dataSetId: dataSetID ? dataSetID : null,
            };
        }
    } else {
        //if the datasource is file geodatabase type is null (procedures are not applicable)
        if (
            dataSetType === 'BASE TABLE' ||
            dataSetType === 'VIEW' ||
            !dataSetType
        ) {
            param = {
                dataSourceId: dataSourceId,
                tableName: tablename,
                dataSetId: dataSetID ? dataSetID : null,
            };
        } else if (dataSetType === 'PROCEDURE') {
            param = {
                dataSourceId: dataSourceId,
                ProcedureName: tablename.split('.')[1],
                dataSetId: dataSetID ? dataSetID : null,
            };
        } else {
            param = {
                dataSourceId: dataSourceId,
                ProcedureName: tablename,
                dataSetId: dataSetID ? dataSetID : null,
            };
        }
    }

    return param;
}

async function doInitLoad(DatasetType, params) {
    let toReturn = {
        coloumns: [],
        isGeometryAvailable: false,
        attributes: [],
        datatypes: [],
        geometryColumns: [],
    };
    if (DatasetType !== 'PowerBI') {
        if (params.tableName && !params.tableName.includes('dbo.sp_')) {
            const {
                coloumns,
                isGeometryAvailable,
                geometryColumns,
            } = await getDatasetColumns(params);
            toReturn.coloumns = coloumns.sort((a, b) => {
                return parseInt(a.order) - parseInt(b.order);
            });
            toReturn.isGeometryAvailable = isGeometryAvailable;
            toReturn.geometryColumns = geometryColumns;
            toReturn.attributes = await getAttributes();
            toReturn.datatypes = await getDatatypes();
        }
    }
    return toReturn;
}
async function doSPInitLoad(DatasetType, params, datasetColoumns) {
    let toReturn = {
        coloumns: [],
        isGeometryAvailable: false,
        attributes: [],
        datatypes: [],
        geometryColumns: [],
    };

    const {
        coloumns,
        isGeometryAvailable,
        geometryColumns,
    } = await getSPdatasetColoumns(datasetColoumns);
    toReturn.coloumns = coloumns.sort((a, b) => {
        return parseInt(a.order) - parseInt(b.order);
    });
    toReturn.isGeometryAvailable = isGeometryAvailable;
    toReturn.geometryColumns = geometryColumns;
    toReturn.datatypes = await getDatatypes();

    return toReturn;

}
async function getSPdatasetColoumns(spcoloumns) {
    let coloumns = [];
    let geometryColumns = [];
    let isGeometryAvailable = false;
    spcoloumns.forEach((element, index) => {
        const dataType = element.Datatype || element.DataType;

        if (dataType.includes('geometry')) {
            isGeometryAvailable = true;
            geometryColumns.push({
                id: index,
                name: element.ColumnName,
                dataType: 'geometry',
            });
        }
        else {
            coloumns.push({
                id: index,
                order: element.SortOrder,
                name: element.ColumnName,
                IsShown: element.IsHidden == 'False' ? true : false,
                IsVisible: element.IsHidden == 'False' ? true : false,
                fixed: false,
                dataType: dataType,
            });
        }
    });
    return { coloumns, isGeometryAvailable, geometryColumns };

}
async function getDatasetColumns(params) {
    let coloumns = [];
    let geometryColumns = [];
    let isGeometryAvailable = false;
    await datasetService.defineDataset(params).then((res) => {
        res.data.forEach((element, index) => {
            if (element.DataType === 'geometry') {
                isGeometryAvailable = true;
                geometryColumns.push({
                    id: index,
                    name: element.TableColumnName || element.ParameterName,
                    dataType: element.DataType,
                });
            } else {
                coloumns.push({
                    id: index,
                    order: element.OrdinalPosition,
                    name: element.TableColumnName || element.ParameterName,
                    IsShown: element.IsHidden == 'False' ? true : false,
                    IsVisible: element.IsHidden == 'False' ? true : false,
                    fixed: false,
                    dataType: element.DataType,
                });
            }
        });
    });

    return { coloumns, isGeometryAvailable, geometryColumns };
}

async function getAttributes() {
    let attributes = [];
    await datasetService.getAttributes().then((res) => {
        attributes = [...res.data];
    });

    attributes.forEach((att) => {
        let tempAtt = att.ToolAttributes.map((itm) => {
            return {
                AttributeName: itm.AttributeName,
                DatatypeSetID: itm.DatatypeSetID,
                ToolAttributeId: itm.ToolAttributeId,
                ToolId: itm.ToolId,
                AllowAttributeNameValue: null,
                isRemoveAllow: false,
            };
        });
        att.allowableToolAttributes = [...tempAtt];
        att.ToolAttributes.forEach((toolAtt) => {
            toolAtt['AttributeNameValue'] = null;
        });
    });
    return attributes;
}

async function getDatatypes() {
    let datatypes = [];
    await datasetService.getDataTypes().then((res) => {
        datatypes = [...res.data];
    });
    return datatypes;
}

function getDatasetColumnsForRequest(
    selectedColoumns,
    showFilterOptions,
    hiddenFilterOptions,
    dataSetId,
    attributes,
    DatasetTools,
    geometryColumns,
    allDataSetColoumns,
    isStoredProcedure
) {
    let dataSetCols = [];

    selectedColoumns.forEach((col) => {
        var colitem = {
            DatasetId: dataSetId ? dataSetId : 0,
            ColumnName: col.name,
            Datatype: col.dataType,
            IsActive: 1,
            SortOrder: parseInt(
                allDataSetColoumns.find((dcol) => dcol.name === col.name).order
            ),
            ColumnAlias: allDataSetColoumns.find(
                (dcol) => dcol.name === col.name
            ).ColumnAlias,
            ColumnDescription: col.description,
            DatasetFilterColumns: [],
            DatasetToolAttributes: [],
        };
        let tempCol = {};
        if (!isStoredProcedure) {
            //add filter array
            showFilterOptions.forEach((filterCol) => {
                if (col.name === filterCol.name) {
                    tempCol.SortOrder = filterCol.SortOrder;
                    tempCol.IsVisible = true;
                    tempCol.DatasetFilterGroup = {
                        GroupName: '',
                        SortOrder: 0,
                    };
                } else {
                    if (filterCol.child) {
                        let child = filterCol.child.find(
                            (kid) => kid.name === col.name
                        );
                        if (child) {
                            tempCol.SortOrder = child.SortOrder;
                            tempCol.IsVisible = true;
                            tempCol.DatasetFilterGroup = {
                                GroupName: filterCol.name,
                                SortOrder: filterCol.SortOrder,
                            };
                        }
                    }
                }
            });
            //add hidden array
            hiddenFilterOptions.forEach((filterCol) => {
                if (col.name === filterCol.name) {
                    tempCol.SortOrder = col.SortOrder;
                    tempCol.IsVisible = false;
                    tempCol.DatasetFilterGroup = {
                        GroupName: '',
                        SortOrder: 0,
                    };
                }
            });
            colitem.DatasetFilterColumns.push(tempCol);
        }
        //set attributes DatasetTools
        attributes.forEach((att) => {
            if (DatasetTools.includes(att.ToolId)) {
                att.ToolAttributes.forEach((toolAtt) => {
                    if (toolAtt.AttributeNameValue === col.name) {
                        colitem.DatasetToolAttributes.push({
                            ToolAttributeId: toolAtt.ToolAttributeId,
                            AttributeType: 'Default',
                        });
                    }
                });
                att.allowableToolAttributes.forEach((allowAtt) => {
                    if (allowAtt.AllowAttributeNameValue === col.name) {
                        colitem.DatasetToolAttributes.push({
                            ToolAttributeId: allowAtt.ToolAttributeId,
                            AttributeType: 'Allowable',
                            SortOrder:lastIndexByProperty(attributes, allowAtt),
                        });
                    }
                });
            }
        });
        dataSetCols.push(colitem);
    });
    geometryColumns.forEach((col) => {
        var colitem = {
            DatasetId: dataSetId ? dataSetId : 0,
            ColumnName: col.name,
            Datatype: col.dataType,
            IsActive: 1,
            SortOrder: col.order,
            ColumnAlias: col.ColumnAlias,
            ColumnDescription: col.description,
            DatasetFilterColumns: [],
            DatasetToolAttributes: [],
        };
        dataSetCols.push(colitem);
    });
    return dataSetCols.sort((a, b) => {
        return parseInt(a.SortOrder) - parseInt(b.SortOrder);
    });
}

function lastIndexByProperty(attributes, allowAtt) {
    let crossTabAttr = attributes.find(item => item.ToolName === "Standard Crosstab").allowableToolAttributes;
    let filterAry = crossTabAttr.filter(item => item.AttributeName === allowAtt.AttributeName)
    let index = filterAry.findIndex(item => item.AllowAttributeNameValue === allowAtt.AllowAttributeNameValue)
    return index; 
}

function getTodayDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;
    return today;
}

async function getDataCoordinates(DataSetId, displayField) {
    const cordinatesOutPut = await projectService.getDataCoordinates(
        DataSetId,
        displayField
    );
    return cordinatesOutPut.data;
}
function validateInput(Name, Value, DataType) {
    switch (DataType) {
        case 'int':
            if (!Number.isInteger(Number(Value))) {
                return Name + ' must be an integer.';
            }
            break;
        case 'decimal':
            if (isNaN(Number(Value))) {
                return Name + ' must be a decimal number.';
            }
            break;
        case 'float':
            if (isNaN(Number(Value))) {
                return Name + ' must be a floating-point number.';
            }
            break;
        case 'real':
            if (isNaN(Number(Value))) {
                return Name + ' must be a real number.';
            }
            break;
        case 'bigint':
            if (!Number.isInteger(Number(Value))) {
                return Name + ' must be a bigint.';
            }
            break;
        case 'bit':
            if (Value !== '0' && Value !== '1') {
                return Name + ' must be a bit (0 or 1).';
            }
            break;
        case 'varchar':
        case 'char':
        case 'nvarchar':
            break;
        case 'date':
        case 'datetime':
        case 'smalldatetime':
        case 'time':
            if (isNaN(new Date(Value))) {
                return Name + ' must be a Date.';
            }
            break;
        case 'geometry':
            break;
        default:
            // Unknown data type
            return 'Unknown data type.';
    }
    return ''; // Return an empty string if validation passes
}
