<template>
    <div id="mobile-top-menu">
        <menu-tool label="Draw" @click="$emit('setModalTool', 'DrawPane')"
            ><template v-slot:icon
                ><img src="../../assets/draw_white.svg"/></template
        ></menu-tool>
        <menu-tool label="Measure" @click="$emit('setModalTool', 'MeasurePane')"
            ><template v-slot:icon
                ><img src="../../assets/measure_icon.svg"/></template
        ></menu-tool>
        <!-- <menu-tool label="Data Grid Viewer"
            ><template v-slot:icon
                ><img src="../../assets/gridviewer_white.svg"/></template
        ></menu-tool> -->
        <menu-tool label="Print" @click="$emit('setModalTool', 'PrintPane')"
            ><template v-slot:icon
                ><img src="../../assets/print_white.svg"/></template
        ></menu-tool>
        <menu-tool label="Exit" @click="exitMap"
            ><template v-slot:icon
                ><img src="../../assets/exit_white.svg"/></template
        ></menu-tool>
    </div>
</template>

<script>
import router from '../../../router';
import MenuTool from './MenuTool.vue';
export default {
    name: 'MobileTopMenu',
    components: {
        MenuTool,
    },
    props: {},
    data() {
        return {};
    },
    created() {},
    mounted() {},
    methods: {
        exitMap() {
            router.push('/classic/project/listview');
        },
    },
    computed: {},
};
</script>

<style scoped>
#mobile-top-menu {
    height: fit-content;
    background-color: inherit;
    color: inherit;
    width: 100%;
    position: absolute;
    top: 64px;
    z-index: 4;
    border-top: 1px solid white;
    padding: 0px 16px 16px 16px;
    transition: 300ms;
}
</style>
