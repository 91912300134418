<template>
    <div 
        :style="cssVars"
        :class="['back-to-top-container', {'go-top': isTop}]">
            <p
                @click="scrollToTop()">Back to top</p>
        <div 
            @click="scrollToTop()" 
            :class="['back-to-top-btn', {'go-top': isTop}]"
        >
            <img src="../../../assets/circle-double-up.svg" alt="Back to top">    
        </div>
    </div>
</template>

<script>
export default {
    name: 'BackToTop',
    data (){
        return {
            isTop: false
        }
    },
    methods: {
        scrollToTop() {
            window.scroll({behavior: 'smooth', left:0, top:0});
        },
    },
    computed: {
        cssVars() {
            return {
                '--btnsizing': 34 + 'px' , //Controls button size when on large screens
                '--btnsizingsmall': 34 + 'px' //Controls button size when on small screens //22
            }
        }
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 500){
                that.isTop = true
            } else {
                that.isTop = false
            }
        })
    }
}
</script>

<style lang="scss" scoped>

.back-to-top-container {
    position: fixed;
    text-align: center;
    bottom: -100px;
    right: 80px;
    z-index: 4;
    width: var(--btnsizing);
    visibility: hidden;
    transition: 0.6s;
    
    p {
        position: absolute;
        cursor: pointer;
        right: 0;
        left: 0;
        top: 0px;
        text-align: center;
        font-size: 12px;
        margin-left: auto;
        margin-right: auto;
        transition: 0.6s;
    }

    &.go-top {
        opacity: 1;
        visibility: visible;
        bottom: 70px;
    }

    &:hover {
        transition: 0.6s;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
        
    }

    &:hover .back-to-top-btn {
        transform: translateY(-5px);
    }

    &:hover p {
        visibility: visible;
        transform: translateY(-5px);
    }
}

.back-to-top-btn {
    position: absolute;
    cursor: pointer;
    bottom: -100px;
    right: 0px;
    color: #000000;
    z-index: 4;
    width: var(--btnsizing);
    text-align: center;
    height: var(--btnsizing);
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    transition: 0.6s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    p {
        position: absolute;
        right: 0;
        left: 0;
        top: 45%;
        transform: translateY(-5px);
        text-align: center;
        //font-size: 10px;
        margin-left: auto;
        margin-right: auto;
    }

    img {
        position: absolute;
        right: 0;
        left: 0;
        top: 45%;
        transform: translateY(-45%);
        transition: 0.6s;
        margin-left: auto;
        margin-right: auto;
        height: var(--btnsizing);
        width: var(--btnsizing);
    }
    &.go-top {
        opacity: 1;
        visibility: visible;
        bottom: 0px;
    }
    &:hover {
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
    }
}

@media only screen and (max-width: 800px) {

    .back-to-top-container {
    position: fixed;
    text-align: center;
    bottom: -100px;
    right: 40px;
    z-index: 4;
    width: var(--btnsizingsmall);
    visibility: hidden;
    transition: 0.6s;
    
        p {
            position: absolute;
            cursor: pointer;
            right: 0;
            left: 0;
            text-align: center;
            font-size: 12px; //9px
            margin-left: auto;
            margin-right: auto;
            transition: 0.6s;
        }

        &.go-top {
            opacity: 1;
            visibility: visible;
            bottom: 60px;
        }

        &:hover {
            box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
            transform: translateY(-5px);
        }

        &:hover .back-to-top-btn {
            transform: translateY(-5px);
        }

        &:hover p {
            visibility: visible;
            transform: translateY(-5px);
        }
    }

    .back-to-top-btn {
    position: absolute;
    cursor: pointer;
    bottom: -100px;
    right: 0px;
    color: #000000;
    z-index: 4;
    width: var(--btnsizingsmall);
    text-align: center;
    height: var(--btnsizingsmall);
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    transition: .6s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

        p {
            position: absolute;
            right: 0;
            left: 0;
            top: 45%;
            transform: translateY(-5);
            text-align: center;
            font-size: 10px;
            margin-left: auto;
            margin-right: auto;
        }

        img {
            position: absolute;
            right: 0;
            left: 0;
            top: 45%;
            transform: translateY(-45%);
            transition: 0.6s;
            margin-left: auto;
            margin-right: auto;
            height: var(--btnsizingsmall);
            width: var(--btnsizingsmall);
        }

        &.go-top {
            opacity: 1;
            visibility: visible;
            bottom: 0px;
        }
        &:hover {
            transition: .6s;
            box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
            transform: translateY(-5px);
        }
    }
}
</style>