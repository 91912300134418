import Vue from 'vue';
import { projectService } from '../services';
import { apiConstants } from '../common/apiConst.js';
import store from './index.js';

const state = {
    projects: {},
    projectsWithGeo: {},
    selectedProjects: [],
    remove: {},
    mapExpanded: false,
    selectedLayerAccordion: 'project',
    mapData: {
        inMapProjects: [],
        mapCenter: { lat: 40.68, lng: -74.028 },
        mapZoom: 10,
        expandedMapZoom: 11,
        contourWMSLyrs: [],
        baseMaps: [
            {
                name: 'Satellite_Imagery',
                visible: false,
                url:
                    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                attribution:
                    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            },
            {
                name: 'TopoMap',
                visible: true,
                url:
                    'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                attribution:
                    'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
            },
            {
                name: 'NoMap',
                visible: false,
                url: '',
                attribution: '',
            },
        ],
        markers: [],
        markersClusters: [],
        geojsonLayers: {
            posted: [],
        },
        deletedPostedLayerIds: [],
    },
    authkeys: {},
};

const actions = {
    getAll({ commit }) {
        commit('getAllRequest');

        projectService.getAll().then(
            (projects) => commit('getAllSuccess', projects),
            (error) => commit('getAllFailure', error)
        );
    },
    getAllWithGeo({ commit }) {
        commit('getAllGeoRequest');
        projectService.getAllWithGeo().then(
            (projectsWithGeo) => commit('getAllGeoSuccess', projectsWithGeo),
            (error) => commit('getAllGeoFailure', error)
        );
    },
    selectProjects({ commit }, projects) {
        commit('setSelectedProjects', projects);
    },
    getSelectedProjects({ commit }) {
        commit('getAllSelectedProjects');
    },
    deselectProject({ commit }, projectID) {
        commit('deselectProject', projectID);
    },
    clearSelectedProjects({ commit }) {
        commit('removeSelectedProjects');
    },
    deleteProject({ commit }, projectId) {
        commit('deleteRequest');
        projectService.deleteProject(projectId).then(
            (remove) => commit('deleteSuccess', remove),
            (error) => commit('deleteFailure', error)
        );
    },
    togglePostedLayerLabels({ commit }, idx) {
        commit('togglePostedLayerLabels', idx);
    },
    updatePostedVisibility({ commit }, idx) {
        commit('updatePostedVisibility', idx);
    },
    deletePostedLayer({ commit, getters }, id) {
        // if the id is greater than the length of the posted layers, it is a contour layer
        // have to offset the id value so it is correct for the other array
        if (id >= getters.postedLyrCount) {
            id -= getters.postedLyrCount;
            commit('deleteContourLayer', id);
        } else {
            commit('deletePostedLayer', id);
        }
    },
    updateSelectedLayerAccordion({ commit }, accordion) {
        commit('updateSelectedLayerAccordion', accordion);
        projectService.getAllWithGeo().then(
            (projectsWithGeo) => commit('getAllGeoSuccess', projectsWithGeo),
            (error) => commit('getAllGeoFailure', error)
        );
    },
    clearSelectedEMProjects({ commit }) {
        commit('removeSelectedEMProjects');
    },
    baseMaps({ commit }, maps) {
        commit('setBaseMaps', maps);
    },
    togglePostedLayerClusteredAction({ commit }, val) {
        commit('togglePostedLayerClustered', val);
    },
};

const mutations = {
    getAllRequest(state) {
        state.projects = { loading: true };
    },
    getAllSuccess(state, projects) {
        // state.projects = { items: projects.data.ProjectDetails };
        state.projects = { items: projects.data };
    },
    getAllFailure(state, error) {
        state.projects = { error };
    },
    // if project not in list, appends it, else skips
    setSelectedProjects(state, projects) {
        var exists = state.selectedProjects.some(
            (field) => field.ProjectID === projects.ProjectID
        );
        if (!exists) {
            state.selectedProjects.push(projects);
        } else {
            //nothing
        }
    },
    addProjectToInMapProjects(state) {
        state.mapData.inMapProjects = state.selectedProjects.slice();
    },
    getAllSelectedProjects(state) {
        return state.projects;
    },
    deselectProject(state, removeProjectID) {
        state.selectedProjects.splice(
            state.selectedProjects.findIndex(
                (project) => project.ProjectID === removeProjectID
            ),
            1
        );
    },
    //Get project list with Geo location
    getAllGeoRequest(state) {
        state.projectsWithGeo = { loading: true };
    },
    getAllGeoSuccess(state, projectsWithGeo) {
        // state.projects = { items: projects.data.ProjectDetails };
        state.projectsWithGeo = { items: projectsWithGeo.data };
    },
    getAllGeoFailure(state, error) {
        state.projectsWithGeo = { error };
    },
    //delete project
    deleteRequest(state) {
        state.remove = { loading: true };
    },
    deleteFailure(state, error) {
        state.remove = { error };
    },
    //mutation functions for mapData
    addMarker(state, payload) {
        state.mapData.markers = payload;
    },
    addMarkerClusters(state, payload) {
        state.mapData.markersClusters = payload;
    },
    addPostedLayerData(state, payload) {
        state.mapData.geojsonLayers.posted.filter(
            (i) => !state.mapData.deletedPostedLayerIds?.includes(i.postingId)
        );
        let newPostedTableDataArr = [
            ...state.mapData.geojsonLayers.posted,
            ...payload,
        ];
        state.mapData.geojsonLayers.posted = [
            ...new Map(
                newPostedTableDataArr.map((item) => [item.id, item])
            ).values(),
        ];
    },
    // args are state and {editIdx: int, editDataset: dataset{}}
    updatePostedLayer(state, payload) {
        Vue.set(
            state.mapData.geojsonLayers.posted,
            payload.editIdx,
            payload.editDataset
        );
    },
    duplicatePostedLayer(state, payload) {
        state.mapData.geojsonLayers.posted.splice(
            payload.editIdx + 1,
            0,
            payload.editDataset
        );
    },
    deletePostedLayer(state, lyrIdx) {
        var selectedLayer =
            state.mapData.geojsonLayers.posted[lyrIdx].postingId;
        state.mapData.deletedPostedLayerIds.push(selectedLayer);
        state.mapData.geojsonLayers.posted.splice(lyrIdx, 1);
    },
    deleteContourLayer(state, lyrIdx) {
        state.mapData.contourWMSLyrs.splice(lyrIdx, 1);
    },
    clearMapData(state) {
        state.mapData.markers = [];
        state.mapData.markersClusters = [];
        state.mapData.geojsonLayers.posted = [];
        state.mapData.inMapProjects = [];
        state.mapData.expandedMapExtent = null;
    },
    updateMapCenter(state, payload) {
        let center = { lat: 0, lng: 0 };
        center.lat = parseFloat(payload.lat.toFixed(6));
        center.lng = parseFloat(payload.lng.toFixed(6));
        state.mapData.mapCenter = center;
    },
    updateMapZoom(state, payload) {
        if (state.mapExpanded) {
            state.mapData.expandedMapZoom = payload;
        } else {
            state.mapData.mapZoom = payload;
        }
    },
    updateMapExtent(state, payload) {
        if (state.mapExpanded) {
            state.mapData.expandedMapExtent = payload;
        } else {
            state.mapData.mapExtent = payload;
        }
    },
    updateBaseMap(state, payload) {
        if (payload == 'topo') {
            state.mapData.baseMaps[0].visible = false;
            state.mapData.baseMaps[1].visible = true;
            state.mapData.baseMaps[2].visible = false;
        } else if (payload == 'imagery') {
            state.mapData.baseMaps[0].visible = true;
            state.mapData.baseMaps[2].visible = false;
            state.mapData.baseMaps[1].visible = false;
        } else {
            //no map
            state.mapData.baseMaps[0].visible = false;
            state.mapData.baseMaps[1].visible = false;
            state.mapData.baseMaps[2].visible = true;
        }
    },
    setMapExpandedOn(state) {
        state.mapExpanded = true;
        state.mapData.expandedMapZoom =
            state.mapData.mapZoom > 0
                ? state.mapData.mapZoom - 1
                : state.mapData.mapZoom;
    },
    setMapExpandedOff(state) {
        state.mapExpanded = false;
        state.mapData.mapZoom =
            state.mapData.expandedMapZoom < 20
                ? state.mapData.expandedMapZoom + 1
                : state.mapData.expandedMapZoom;
    },
    updatePostedVisibility(state, index) {
        let layer = state.mapData.geojsonLayers.posted[index];
        layer.markerSettings11[0].dataSource.visible = !layer
            .markerSettings11[0].dataSource.visible;

        Vue.set(state.mapData.geojsonLayers.posted, index, layer);
    },
    removeSelectedProjects(state) {
        return (state.selectedProjects = []);
    },
    removeSelectedEMProjects(state) {
        state.selectedProjects = state.selectedProjects.filter((project) => {
            return project.ProjectTypeName !== 'Emergency management';
        });
    },
    setBaseMaps(state, maps) {
        state.mapData.baseMap = [...maps];
    },
    deleteSuccess(state, remove) {
        //
        state.remove = {
            StatusCode: remove.status,
            // StatusCode: remove.data.StatusCode
        };
    },
    togglePostedLayerLabels(state, index) {
        let dataSource =
            state.mapData.geojsonLayers.posted[index].markerSettings11[0]
                .dataSource;
        if (!dataSource.labelVisibleIndex) {
            for (let i = 0; i < dataSource.features[0].properties.length; i++) {
                if (dataSource.features[0].properties[i].label) {
                    dataSource.labelVisibleIndex = i;
                }
            }
        }
        dataSource.features.forEach((geom) => {
            geom.properties[dataSource.labelVisibleIndex].label = !geom
                .properties[dataSource.labelVisibleIndex].label;
        });
    },
    togglePostedLayerClustered(state, val) {
        let index = val.index;
        let layer = state.mapData.geojsonLayers.posted[index];
        layer.clustered = !layer.clustered;
        Vue.set(state.mapData.geojsonLayers.posted, index, layer);
    },
    updateSelectedLayerAccordion(state, accordion) {
        //
        state.selectedLayerAccordion = accordion;
        //
    },
    addContourLayer(state, payload) {
        console.log('add contour layer classic');
    },
    setAuthKeys(state, keys) {
        state.authkeys = keys;
    },
};

const getters = {
    postedLyrCount: (state) => state.mapData.geojsonLayers.posted.length,
    // below getters separate the layers to clustered or not clustered
    postedLyrs: (state) => {
        var output = [];
        state.mapData.geojsonLayers.posted.forEach((lyr) => {
            if (!lyr.clustered && lyr.visible) {
                output.push(lyr.markerSettings11[0].dataSource);
            }
        });
        return output;
    },
    postedClusterLyrs: (state) => {
        var output = [];
        state.mapData.geojsonLayers.posted.forEach((lyr) => {
            if (lyr.clustered && lyr.visible) {
                output.push(lyr.markerSettings11[0].dataSource);
            }
        });
        return output;
    },
    tocPostedLayers: (state) => {
        var tocPosted = [];
        var filteredState = state.mapData.geojsonLayers.posted.filter(
            (i) => !state.mapData.deletedPostedLayerIds?.includes(i.postingId)
        );
        try {
            var wmsLyrId = 0;
            filteredState.forEach((lyr) => {
                lyr.isWMS = false;
                tocPosted.push(lyr);
            });
            state.mapData.contourWMSLyrs.forEach((lyr) => {
                lyr.isWMS = true;
                lyr.LyrId = wmsLyrId;
                tocPosted.push(lyr);
            });
        } catch (error) {
            //nothing
        }
        return tocPosted;
    },
    selectedProjectsTitle: (state) => {
        return state.selectedProjects
            .map((p) => p.ProjectName.replaceAll('"', ''))
            .join(', ');
    },
};

const namespaced = true;

export const projects = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
