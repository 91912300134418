<template>
    <div>
        <draggable-div
            :style="legendPaneStyle"
            :rightBound="parentBounds.width"
            :bottomBound="parentBounds.height"
            v-bind:allowToggleMain="true"
            class="legendPane"
            @mouseDragStart="onDragStart"
            @mouseDragStop="onDragEnd"
            :settings="settings"
        >
            <template v-slot:header>
                <span class="legendHeader  py-2">
                    <span class="legendTitle">{{ title }}</span>
                </span>
            </template>
            <template v-slot:main>
                <div
                    id="legendPane"
                    ref="legendPane"
                    class="legendPaneBody py-2"
                    @mousewheel="disableMapZoom"
                    @mouseleave="enableMapZoom"
                >
                    <!-- wms layer legends -->
                    <div v-if="wmsFirst">
                        <div
                            v-for="(urls, index) in wmsLegendURLs"
                            :key="'WMS_' + index"
                            class="imgDiv"
                        >
                            <img
                                class="legend-filter-img"
                                v-if="urls.legend.includes(' AND ')"
                                src="../../../assets/filter-fill.svg"
                            />
                            <div v-else class="legend-filter-img" />
                            <img :src="urls.legend" />
                        </div>
                    </div>
                    <!-- project layer legend -->

                    <json-legend-entry
                        v-for="(layer, idx) in tocProjectLayersInView"
                        v-bind:key="'Proj_' + idx"
                        :layer="layer"
                        :layer-format="'project'"
                        v-bind:showEntry="true"
                        class="jsonLegendEntry"
                        :featureCount="layer.count"
                    />
                    <!-- posted layer legend -->
                    <json-legend-entry
                        v-for="(layer, idx) in tocPostedLayersInView"
                        v-bind:key="'Post_' + idx"
                        :layer="layer"
                        :layer-format="'posted'"
                        v-bind:showEntry="true"
                        class="jsonLegendEntry"
                        :featureCount="layer.count"
                    />

                    <!-- wms layer legends -->
                    <div v-if="!wmsFirst">
                        <div
                            v-for="(urls, index) in wmsLegendURLs"
                            :key="'WMS_' + index"
                            class="imgDiv"
                        >
                            <img
                                class="legend-filter-img"
                                v-if="urls.legend.includes(' AND ')"
                                src="../../../assets/filter-fill.svg"
                            />
                            <div v-else class="legend-filter-img" />
                            <img :style="'display: inline'" :src="urls.count" />
                        </div>
                    </div>
                    <div
                        class="noLayersText"
                        v-show="
                            wmsLegendURLs.length == 0 &&
                                tocProjectLayersInView.length == 0 &&
                                tocPostedLayersInView.length == 0
                        "
                    >
                        No layers in visible extent
                    </div>
                </div>
            </template>

            <template v-slot:footer>
                <span class="legendPaneFooter">
                    <span class="helpText">Click and drag to reposition</span>
                </span>
            </template>
        </draggable-div>
    </div>
</template>
<script>
import JsonLegendEntry from './JsonLegendEntry';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import ColorLegend from './ColorLegend';
import { mapState, mapGetters } from 'vuex';
import DraggableDiv from '../../CommonComponents/DraggableWrapper.vue';
import { getLayerGeometryType } from '../../../utilities/mapLayerFunctions';
import axios from 'axios';

export default {
    components: {
        JsonLegendEntry,
        DraggableDiv,
    },
    props: {
        title: {
            type: String,
            default: 'Legend',
            required: false,
        },
        wmsLayers: {
            type: Array,
            default: () => [],
            required: true,
        },
        bbox: {
            type: Object,
            default: () => {},
            required: true,
        },
        scalebarUnits: {
            type: String,
            required: false,
            default: 'Metric',
        },
        wmsFirst: {
            type: Boolean,
            required: false,
            default: false,
        },
        zoomLevel: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {
            width: 217,
            parentBounds: null,
            settings: [{ label: 'Always show counts', status: false }],
        };
    },
    created() {
        this.parentBounds = this.$parent.$el.getBoundingClientRect();
    },
    methods: {
        onDragStart() {
            this.$emit('dragStart');
        },
        onDragEnd() {
            this.$emit('dragEnd');
        },
        disableMapZoom() {
            this.$parent.mapObject.scrollWheelZoom.disable();
        },
        enableMapZoom() {
            this.$parent.mapObject.scrollWheelZoom.enable();
        },
        checkInside(point, box) {
            let viewBox = box.split(',').map(Number);
            let viewBoxY = [viewBox[1], viewBox[3]];
            let viewBoxX = [viewBox[0], viewBox[2]];

            let maxY = Math.max(viewBoxY[0], viewBoxY[1]);
            let minY = Math.min(viewBoxY[0], viewBoxY[1]);

            let maxX = Math.max(viewBoxX[0], viewBoxX[1]);
            let minX = Math.min(viewBoxX[0], viewBoxX[1]);

            let x = point[0];
            let y = point[1];

            let inside = false;
            if (x > minX && x < maxX && y > minY && y < maxY) {
                inside = !inside;
            }
            return inside;
        },
        async getFeatureCount(url) {
            let features = await axios.get(url);
            // this will extract a string that is any integer following 'numberOfFeatures="' and parse it as an int
            let featureCount = parseInt(
                features.match(/(?<=numberOfFeatures=")[0-9]+/g)
            );
            return featureCount;
        },
    },
    computed: {
        ...mapGetters('projectLayers', ['legendProjectLayers']),
        ...mapGetters('projects', ['tocPostedLayers']),
        ...mapGetters('dreams', ['dreamsModel']),
        ...mapState('projects', {
            mapSize: (state) => state.mapData.mapSize,
        }),
        legendPaneStyle: function() {
            return {
                position: 'absolute',
                zIndex: 9999,
                width: this.width + 'px',
                left: 'calc(100% - 250px)',
                top: '50px',
            };
        },
        bboxURL: function() {
            return (
                this.bbox._southWest.lng +
                ',' +
                this.bbox._southWest.lat +
                ',' +
                this.bbox._northEast.lng +
                ',' +
                this.bbox._northEast.lat
            );
        },
        count_BBOX: function() {
            return (
                'BBOX(Geometry,' +
                spatialFunctions.lng2meters(this.bbox._southWest.lng) +
                ',' +
                spatialFunctions.lat2meters(this.bbox._southWest.lat) +
                ',' +
                spatialFunctions.lng2meters(this.bbox._northEast.lng) +
                ',' +
                spatialFunctions.lat2meters(this.bbox._northEast.lat) +
                ')'
            );
        },
        wmsLegendURLs: function() {
            let urls = [];
            this.wmsLayers.forEach((layer) => {
                if (
                    layer.isChecked &&
                    layer.maxZoom >= this.zoomLevel &&
                    layer.minZoom <= this.zoomLevel
                ) {
                    let legend_options =
                        'legend_options=forceLabels:on;fontAntiAliasing:true;';
                    // if (this.zoomLevel >= 14) {
                    //     legend_options += 'countMatched:true;';
                    // }
                    if (
                        layer.layers
                            .toLowerCase()
                            .includes('vw_er_emergencyresponse') ||
                        layer.layers.toLowerCase().endsWith('er_ics') ||
                        !layer.layers.toLowerCase().includes(':vw_er_')
                    ) {
                        legend_options += 'hideEmptyRules:true;';
                    }

                    var countMatched;
                    if (this.settings[0].status) {
                        countMatched = true;
                    } else {
                        if (this.$store.state.projects.mapData.mapZoom > 13) {
                            countMatched = true;
                        } else {
                            countMatched = false;
                        }
                    }
                    var count_url =
                        layer.legend.replace('http://', 'https://') +
                        `&LEGEND_OPTIONS=countMatched:${countMatched};hideEmptyRules:true;forceLabels:on;fontAntiAliasing:true&SRS=EPSG:4326&bbox=` +
                        this.bboxURL +
                        '&srcwidth=' +
                        this.mapSize.x +
                        '&srcheight=' +
                        this.mapSize.y;
                    if (layer.options.CQL_Filter) {
                        count_url += '&CQL_FILTER=' + layer.options.CQL_Filter;
                    }

                    if (layer.options.CQL_Filter !== null) {
                        count_url += ' AND ' + layer.options.CQL_Filter;
                    }
                    urls.push({
                        legend:
                            layer.legend.replace('http://', 'https://') +
                            '&hideEmptyRules:true&SRS=EPSG:4326&' +
                            legend_options +
                            '&bbox=' +
                            this.bboxURL +
                            '&srcwidth=' +
                            this.mapSize.x +
                            '&srcheight=' +
                            this.mapSize.y,
                        count: count_url,
                    });
                }
            });
            return urls;
        },
        tocProjectLayersInView: function() {
            if (!this.legendProjectLayers || !this.legendProjectLayers.length) {
                return [];
            }
            let projectLayersInView = [];
            this.legendProjectLayers.forEach((layer) => {
                let count = 0;
                let isLayerInView = false;
                layer.Geometry.features.forEach((feature) => {
                    if (getLayerGeometryType(layer) == 'Point') {
                        let pointCoord = [
                            feature.geometry.coordinates[0],
                            feature.geometry.coordinates[1],
                        ];
                        if (this.checkInside(pointCoord, this.bboxURL)) {
                            isLayerInView = true;
                            count++;
                        }
                    } else if (getLayerGeometryType(layer) == 'LineString') {
                        if (
                            feature.geometry.coordinates.some((line) => {
                                let lineCoord = [line[0], line[1]];
                                return this.checkInside(
                                    lineCoord,
                                    this.bboxURL
                                );
                            })
                        ) {
                            isLayerInView = true;
                            count++;
                        }
                    } else {
                        if (
                            feature.geometry.coordinates[0].some((shape) => {
                                let shapeCoord = [shape[0], shape[1]];
                                return this.checkInside(
                                    shapeCoord,
                                    this.bboxURL
                                );
                            })
                        ) {
                            isLayerInView = true;
                            count++;
                        }
                    }
                });

                if (isLayerInView) {
                    layer.count = count;
                    projectLayersInView.push(layer);
                }
            });

            return projectLayersInView;
        },
        tocPostedLayersInView: function() {
            let postedLayersInView = [];
            this.tocPostedLayers.forEach((layer) => {
                let count = 0;
                let isLayerInView = false;
                if (layer.isWMS) {
                    if (layer.visible) {
                        postedLayersInView.push(layer);
                    }
                } else {
                    layer.Geometry.features.forEach((feature) => {
                        if (getLayerGeometryType(layer) == 'Point') {
                            let pointCoord = [
                                feature.geometry.coordinates[0],
                                feature.geometry.coordinates[1],
                            ];
                            if (this.checkInside(pointCoord, this.bboxURL)) {
                                isLayerInView = true;
                                count++;
                            }
                        } else {
                            if (
                                feature.geometry.coordinates[0].some(
                                    (shape) => {
                                        let shapeCoord = [shape[0], shape[1]];
                                        return this.checkInside(
                                            shapeCoord,
                                            this.bboxURL
                                        );
                                    }
                                )
                            ) {
                                isLayerInView = true;
                                count++;
                            }
                        }
                    });

                    if (isLayerInView) {
                        layer.count = count;
                        postedLayersInView.push(layer);
                    }
                }
            });

            return postedLayersInView;
        },
    },
};
</script>
<style scoped>
.legendPane {
    border-radius: 3px;
    width: 300px;
    bottom: 75px;
    background-color: white;
    border: solid 1px #d1d1d1;
}
#legendPane {
    overflow-y: scroll;
}
.imgDiv {
    /* this is to hide the empty entries since it is line-height (1.5) * font-size (0) */
    font-size: 0px !important;
    padding-left: 5px;
    display: block;
    text-wrap: nowrap;
}

.legend-filter-img {
    width: 20px;
    display: inline-block;
}
.btn-layers {
    color: black;
    background-color: white;
    border: none !important;
}

.jsonLegendEntry {
    padding-left: 24px;
    width: max-content;
}
/* .legendHeader {
    padding-left: 15px;
} */
.legendTitle {
    font-weight: bold;
    font-size: 16px;
}
.legendPaneFooter {
    position: relative;
}
.helpText {
    font-size: 12px;
    font-style: italic;
}
.legendPaneBody {
    max-height: 155px;
    overflow-y: scroll;
}
.noLayersText {
    padding-left: 15px;
    line-height: 19.5px !important;
}
</style>
