<template>
    <b-modal
        ref="attributeModal"
        id="attributesModal"
        title="Attributes Pane"
        centered
        hide-footer
        @hide="setShowAttributeModal(false)"
    >
        <form class="modalBody large">
            <custom-floating-label
                :config="{
                    label: 'Type',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <div class="d-flex justidy-content-between">
                    <input
                        disabled
                        type="text"
                        class="attributeEntry mb-4"
                        v-model="drawingAttributes.geomLocType"
                    />
                    <!-- if we don't have a geomLocType ('---'), don't draw the image pane, it just error's out, but sometimes loops and freezes the DevTools -->
                    <img
                        v-if="
                            !['', '-', '---'].includes(
                                drawingAttributes.geomLocType
                            )
                        "
                        class="align-middle my-2"
                        onerror="handleImageError"
                        :src="iconSource(drawingAttributes.geomLocType)"
                        width="30"
                        height="30"
                    />
                </div>
            </custom-floating-label>
            <custom-floating-label
                :config="{
                    label: 'Label',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input
                    type="text"
                    class="attributeEntry mb-4"
                    v-model="drawingAttributes.geomLocName"
                />
            </custom-floating-label>
            <custom-floating-label
                :config="{
                    label: 'Description',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input
                    type="text"
                    class="attributeEntry mb-4"
                    v-model="drawingAttributes.geomLocDesc"
                />
            </custom-floating-label>
            <custom-floating-label
                :config="{
                    label: 'Comment',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input
                    type="text"
                    class="attributeEntry mb-4"
                    v-model="drawingAttributes.geomComment"
                />
            </custom-floating-label>
        </form>
        <div class="modalButtonRow">
            <button
                class="modalButton deleteButton"
                v-show="showEditButtons"
                @click="deleteFromDatabase"
            >
                Delete geometry
            </button>
            <button
                class="modalButton"
                v-show="showEditButtons"
                @click="editGeometry"
            >
                Edit geometry
            </button>
            <button class="modalButton" @click="sendToDatabase">
                Save to database
            </button>
        </div>
    </b-modal>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import CustomFloatingLabel from '../../CustomFloatingLabel.vue';
import { spatialService } from '../../../services/spatial.services';
import hide from '../../../directives/hide.js';
import { mapLayerFunctions } from '../../../utilities/mapLayerFunctions';

export default {
    name: 'IcsAttributeModal',
    components: {
        CustomFloatingLabel,
    },
    watch: {
        // if the stored showAttributeModal changes, show or hide, depending on the value
        showAttributeModal: function(val) {
            if (val === true) {
                this.$refs.attributeModal.show();
            } else {
                this.$refs.attributeModal.hide();
            }
        },
    },
    props: {},
    mounted() {},

    data() {
        return {
            blobPath:
                process.env.VUE_APP_BLOB_PATH + 'resources/icons/ERIcons/',
        };
    },
    methods: {
        ...mapActions('oneMap', ['setMapTool']),
        ...mapActions('common', ['setForceRefreshMap']),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        editGeometry(feature) {
            this.$emit('editGeometry', this.drawingAttributes);
        },
        // function to recall the last identify, used when editing data or deleting features
        resendIdentify() {
            if (this.identifyPayload.projectID) {
                mapLayerFunctions
                    .makeIdentifyRequest(this.identifyPayload)
                    .then((res) => {
                        this.$store.commit(
                            'oneMap/mutateIdentifyResultsData',
                            res
                        );
                    });
            }
        },
        iconSource(blobName) {
            return this.blobPath + blobName + '.svg';
        },
        handleImageError(event) {
            event.target.src = `${this.blobPath}Not-Available.svg`;
        },
        // save the new/edited feature to the database, then recall the identify to update the attributes,
        //  or remove the geometry if it is now out of the identify circle
        async sendToDatabase() {
            var geomJson = {
                GeoID: this.drawingAttributes.geoID,
                ProjectID: this.drawingAttributes.projectID,
                Geometry: this.drawingAttributes.geomWKT,
                GeoType: this.drawingAttributes.geomLocType,
                GeoName: this.drawingAttributes.geomLocName,
                GeoDescription: this.drawingAttributes.geomLocDesc,
                Comment: this.drawingAttributes.geomComment,
            };

            await this.saveToDatabase(geomJson);
            this.forceMapUpdate();
        },
        saveToDatabase(geomJson) {
            spatialService.saveToDatabase(geomJson).then((res) => {
                if (res.status == 200) {
                    this.setShowAttributeModal(false);
                    this.$emit('updateGeometryLayer', {
                        geomType: this.drawingAttributes.geomWKT.substring(
                            0,
                            this.drawingAttributes.geomWKT.indexOf(' ')
                        ),
                    });
                    this.resendIdentify();
                    alert('Feature successfully sent to database');
                } else {
                    alert('Error inserting feature to database');
                }
            });
        },
        // delete the identified feature from the database, then recall the identify without the deleted feature
        async deleteFromDatabase() {
            await spatialService
                .deleteFromDatabase(this.drawingAttributes.geoID)
                .then((res) => {
                    if (res.status == 200) {
                        this.setShowAttributeModal(false);
                        this.$emit('deleteGeometry', {
                            geomType: this.drawingAttributes.geomWKT.substring(
                                0,
                                this.drawingAttributes.geomWKT.indexOf(' ')
                            ),
                        });
                        this.resendIdentify();
                        alert('Feature successfully deleted from database');
                    } else {
                        alert('Error deleting feature from database');
                    }
                });
            this.forceMapUpdate();
        },
        forceMapUpdate() {
            this.setForceRefreshMap(true);
        },
    },
    computed: {
        ...mapState('emergencyManagement', ['drawingAttributes']),
        ...mapState('oneMap', {
            projectID: 'drawingProjectID',
            identifyPayload: 'identifyPayload',
        }),
        ...mapGetters('emergencyManagement', {
            showAttributeModal: 'getShowAttributeModal',
        }),
        showEditButtons() {
            return this.drawingAttributes.geoID != 0;
        },
    },
};
</script>

<style>
.modalBody {
    justify-content: center;
}
.attributeEntry {
    height: 48px !important;
    border-radius: 0;
    background-color: #fff;
    border-left: 0;
    border-right: 0;
    padding-left: 45px;
    border-top: 1px solid #dddddd;
    background: no-repeat;
    width: 90%;
}

.modalButtonRow {
    margin-top: 10px;
    display: flex;
    height: 10%;
    justify-content: space-around;
}
.modalButton {
    color: white;
    width: 25%;
    background-color: gray;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-color: gray;
}
.deleteButton {
    color: rgb(255, 20, 20) !important;
    border-color: rgb(255, 20, 20) !important;
    background-color: white !important;
}

@media screen and (min-width: 768px) {
    .input__container {
        width: 450px !important;
    }
}
</style>
