<template>
    <div class="projectlist" v-if="showPage">
        <Header />
        <b-modal id="filemaker-upload" title="FileMaker Upload" hide-footer>
            <p style="font-size: 24px; color: red; font-weight: bold;">
                Project ID: {{ projectID }}
            </p>
            <p class="my-2">
                Use Project ID above to setup the FileMaker ER template.
            </p>
            <p class="my-2">Please select a file to upload</p>
            <b-form-file
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            ></b-form-file>
            <div class="my-2 d-flex justify-content-end">
                <b-button class="mx-1" variant="danger" @click="cancel()">
                    Cancel
                </b-button>
                <b-button
                    class="mx-1"
                    variant="outline-secondary"
                    @click="uploadFMTemplate(file)"
                >
                    Upload
                </b-button>
            </div>
        </b-modal>
        <b-container fluid class="sub_header_bg">
            <b-container class="list-m">
                <b-row class="mb-3">
                    <b-col cols="12">
                        <a href="javascript:void(0)" @click="navigateDashboard">
                            <img
                                src="../../../assets/arrow_left.svg"
                                class=""
                            />
                            <a href="#" class="floar-left ml-1 back">
                                Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>
                <b-row
                    class="vh-10 text-left"
                    align-v="center"
                    v-if="manageHeader"
                >
                    <b-col cols="12" md="4" lg="4" class="pt-2">
                        <h1 class="">Project Management</h1>
                    </b-col>
                    <b-col class="p-0" v-if="isClicked">
                        <!-- <p class="text-right pl-3 m-0 clearSelect m-none">Clear selection</p> -->
                    </b-col>
                    <b-col
                        class="text-right pl-0 open-btn m-none"
                        md="8"
                        lg="8"
                        cols="3"
                    >
                        <span
                            v-if="!newtab.length || activatedTabIndex == 0"
                            id="addIcon"
                            class="addProject mt-1"
                            @click="
                                isAddProject = true;
                                newProject();
                            "
                        >
                            <img src="../../../assets/add_icon.svg" />
                        </span>
                        <b-button
                            rounded
                            class="buttonSelect ml-3 openProj"
                            v-if="isClicked"
                            @click="
                                isAddProject = false;
                                openProjects(selectedItem, false, false, null);
                            "
                        >
                            Open project
                        </b-button>
                    </b-col>
                </b-row>
                <b-row
                    class="vh-10 text-left"
                    align-v="center"
                    v-if="saveHeader"
                >
                    <b-col cols="3" lg="8" md="6" class="pt-2">
                        <h1 class="">Projects</h1>
                    </b-col>
                    <b-col cols="3" lg="2" md="3" class="p-0 m-none">
                        <p
                            v-if="!isAddProject"
                            class="text-right pl-3 m-0 cancelProj"
                            @click="cancelJob('edit')"
                        >
                            Cancel
                        </p>
                        <p
                            v-else
                            class="text-right pl-3 m-0 cancelProj"
                            @click="cancelJob('add')"
                        >
                            Cancel
                        </p>
                    </b-col>
                    <b-col
                        cols="6"
                        lg="2"
                        md="3"
                        class="text-right pl-0 m-none"
                    >
                        <b-button
                            rounded
                            class="buttonSelect saveProj"
                            v-if="isAddProject"
                            @click="saveProject"
                        >
                            Save
                        </b-button>
                        <b-button
                            rounded
                            class="buttonSelect ml-3 saveProj"
                            v-else
                            @click="editProject"
                        >
                            Save changes
                        </b-button>
                    </b-col>
                </b-row>
                <b-card no-body class="pro-list">
                    <b-tabs
                        class="tabs pro-list pt-4"
                        v-model="activatedTabIndex"
                        active-nav-item-class="pl-0 pr-0 borderBlue"
                        v-on:activate-tab="selectedTab"
                        v-on:changed="activateTab"
                    >
                        <b-tab class="" title="Manage projects">
                            <b-container class="d-p-0">
                                <div class="mt-2 ml-1 m-shadow m-flt">
                                    <b-row
                                        class="my-2 positionAbsolute marginAuto"
                                    >
                                        <div>
                                            <div class="float-left h-45">
                                                <img
                                                    class="pointer ml-2"
                                                    src="../../../assets/search-outline.svg"
                                                    v-if="
                                                        !filter &&
                                                        !showSearchIcon
                                                            ? true
                                                            : false
                                                    "
                                                    @click="searchOpen"
                                                />
                                                <input
                                                    ref="projectlistSearch"
                                                    spellcheck="false"
                                                    id="searchFilter"
                                                    class="test"
                                                    v-if="
                                                        filter || showSearchIcon
                                                            ? true
                                                            : false
                                                    "
                                                    v-model="filter"
                                                    @click="searchinputenter()"
                                                    @input="searchinputenter"
                                                    :class="
                                                        filter || showSearchIcon
                                                            ? 'test'
                                                            : 'searchinputList'
                                                    "
                                                    type="search"
                                                    placeholder="Search for projects"
                                                />
                                                <span
                                                    class="grey-bg pointer"
                                                    v-if="searchClose"
                                                    @click="closeBar"
                                                    ><img
                                                        src="../../../assets/close.svg"
                                                /></span>
                                                <span
                                                    class="border-right list ml-2"
                                                    >&nbsp;</span
                                                >
                                            </div>
                                            <div
                                                class="float-left filter-block1"
                                            >
                                                <a
                                                    href="#"
                                                    class="ml-3 mr-2 m-position filterBox"
                                                    @click="filterdata"
                                                >
                                                    <img
                                                        src="../../../assets/tooltip.svg"
                                                    />
                                                </a>
                                                <span
                                                    class="font-weight-300 pointer m-none filterBox"
                                                    @click="filterdata"
                                                    >Filter</span
                                                >

                                                <b-list-group-item
                                                    v-clickoutside="hide"
                                                    class="
                          filter-group
                          left-align
                          p-0
                          filter-box
                          hide-on-clickoutside
                        "
                                                    v-if="filterShow"
                                                >
                                                    <div class="ml-4 mt-3">
                                                        <div
                                                            class="mb-1 float-left"
                                                        >
                                                            Filter by
                                                        </div>
                                                        <div
                                                            class="text-right pr-3"
                                                        >
                                                            <a
                                                                href="javascript:void(0)"
                                                            >
                                                                <b-icon
                                                                    icon="x"
                                                                    font-scale="2"
                                                                    @click="
                                                                        backFilter
                                                                    "
                                                                >
                                                                </b-icon>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <CustomFloatingLabel
                                                                class="FloatingTool"
                                                                :config="{
                                                                    label:
                                                                        'Country',
                                                                    name:
                                                                        'wrapper',
                                                                    line: false,
                                                                    scale: false,
                                                                }"
                                                            >
                                                                <b-form-select
                                                                    v-model="
                                                                        countryId
                                                                    "
                                                                    @change="
                                                                        stateByCountryFilter()
                                                                    "
                                                                    class="filterSelect custom-select"
                                                                    id="filterCountry"
                                                                >
                                                                    <option
                                                                        disabled
                                                                        value=""
                                                                        >Country</option
                                                                    >
                                                                    <option
                                                                        v-for="(item,
                                                                        index) in countries"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        v-bind:value="
                                                                            item.CountryId
                                                                        "
                                                                    >
                                                                        {{
                                                                            item.CountryName
                                                                        }}
                                                                    </option>
                                                                </b-form-select>
                                                            </CustomFloatingLabel>
                                                        </div>
                                                        <div class="mt-2">
                                                            <CustomFloatingLabel
                                                                class="FloatingTool"
                                                                :config="{
                                                                    label:
                                                                        'State',
                                                                    name:
                                                                        'wrapper',
                                                                    line: false,
                                                                    scale: false,
                                                                }"
                                                            >
                                                                <b-form-select
                                                                    v-model="
                                                                        StateId
                                                                    "
                                                                    @change="
                                                                        cityByStateFilter()
                                                                    "
                                                                    class="filterSelect custom-select"
                                                                    id="filterSelect"
                                                                >
                                                                    <option
                                                                        disabled
                                                                        value=""
                                                                        >State</option
                                                                    >
                                                                    <option
                                                                        v-for="(item,
                                                                        index) in states"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        v-bind:value="
                                                                            item.StateId
                                                                        "
                                                                    >
                                                                        {{
                                                                            item.StateName
                                                                        }}
                                                                    </option>
                                                                </b-form-select>
                                                            </CustomFloatingLabel>
                                                        </div>
                                                        <div class="mt-2">
                                                            <CustomFloatingLabel
                                                                class="FloatingTool"
                                                                :config="{
                                                                    label:
                                                                        'State',
                                                                    name:
                                                                        'wrapper',
                                                                    line: false,
                                                                    scale: false,
                                                                }"
                                                            >
                                                                <b-form-select
                                                                    v-model="
                                                                        CityName
                                                                    "
                                                                    class="filterSelect custom-select"
                                                                    id="filterSelect"
                                                                >
                                                                    <option
                                                                        disabled
                                                                        value=""
                                                                        >City</option
                                                                    >
                                                                    <option
                                                                        v-for="(item,
                                                                        index) in cities"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        v-bind:value="
                                                                            item.CityName
                                                                        "
                                                                    >
                                                                        {{
                                                                            item.CityName
                                                                        }}
                                                                    </option>
                                                                </b-form-select>
                                                            </CustomFloatingLabel>
                                                        </div>

                                                        <b-button
                                                            class="mb-3"
                                                            id="applyProjectList"
                                                            @click="applyFilter"
                                                            >Apply
                                                            filters</b-button
                                                        >
                                                    </div>
                                                </b-list-group-item>
                                            </div>
                                        </div>
                                        <div
                                            class="ml-4 mt-2 m-none"
                                            v-if="applyfilter"
                                        >
                                            <div
                                                class="float-left sub-pill"
                                                v-if="countryId"
                                            >
                                                <span
                                                    class="filteredItemProject px-3 py-1 text-center"
                                                    id="crossFirst"
                                                >
                                                    {{ countryName }}
                                                    <b-icon
                                                        icon="x"
                                                        font-scale="1"
                                                        @click="
                                                            backFilter(
                                                                'countryId'
                                                            )
                                                        "
                                                    ></b-icon>
                                                </span>
                                            </div>
                                            <div
                                                class="float-left"
                                                v-if="StateId"
                                            >
                                                <span
                                                    class="filteredItemProject px-3 py-1 text-center"
                                                    id="crossFirst"
                                                >
                                                    {{ stateName }}
                                                    <b-icon
                                                        icon="x"
                                                        font-scale="1"
                                                        @click="
                                                            backFilter(
                                                                'StateId'
                                                            )
                                                        "
                                                    ></b-icon>
                                                </span>
                                            </div>
                                            <div
                                                class="float-left"
                                                v-if="CityName"
                                            >
                                                <span
                                                    class="filteredItemProject px-3 py-1 text-center"
                                                    id="crossFirst"
                                                >
                                                    {{ CityName }}
                                                    <b-icon
                                                        icon="x"
                                                        font-scale="1"
                                                        @click="
                                                            backFilter(
                                                                'CityName'
                                                            )
                                                        "
                                                    ></b-icon>
                                                </span>
                                            </div>

                                            <div
                                                class="removeFilter float-left"
                                            >
                                                <a
                                                    @click="removeFilter"
                                                    v-if="removeProjectFilter"
                                                >
                                                    Remove all filters
                                                </a>
                                            </div>
                                        </div>
                                    </b-row>
                                    <div
                                        class="ml-4 mt-4 desk-none mb-2"
                                        v-if="applyfilter"
                                    >
                                        <div
                                            class="float-left"
                                            v-if="countryId"
                                        >
                                            <span
                                                class="filteredItemProject px-3 py-1 text-center"
                                                id="crossFirst"
                                            >
                                                {{ countryName }}
                                                <b-icon
                                                    icon="x"
                                                    font-scale="1"
                                                    @click="
                                                        backFilter('countryId')
                                                    "
                                                ></b-icon>
                                            </span>
                                        </div>
                                        <div class="float-left" v-if="StateId">
                                            <span
                                                class="filteredItemProject px-3 py-1 text-center"
                                                id="crossFirst"
                                            >
                                                {{ stateName }}
                                                <b-icon
                                                    icon="x"
                                                    font-scale="1"
                                                    @click="
                                                        backFilter('StateId')
                                                    "
                                                ></b-icon>
                                            </span>
                                        </div>
                                        <div class="float-left" v-if="CityName">
                                            <span
                                                class="filteredItemProject px-3 py-1 text-center"
                                                id="crossFirst"
                                            >
                                                {{ CityName }}
                                                <b-icon
                                                    icon="x"
                                                    font-scale="1"
                                                    @click="
                                                        backFilter('CityName')
                                                    "
                                                ></b-icon>
                                            </span>
                                        </div>

                                        <div class="removeFilter float-left">
                                            <a
                                                @click="removeFilter"
                                                v-if="removeProjectFilter"
                                            >
                                                Remove all filters
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <b-container
                                    class="m-0 list-m pb-5 d-p-0 bottom-m-125"
                                >
                                    <b-col
                                        cols="12"
                                        class="p-0 mt-2 project-list clear-left"
                                    >
                                        <ejs-grid
                                            id="tblUser"
                                            height="510"
                                            :enableInfiniteScrolling="true"
                                            :dataSource="
                                                applyfilter
                                                    ? projectFiltered
                                                    : projects
                                            "
                                            class="sortingenabled"
                                            ref="grid"
                                            :allowSorting="true"
                                            :commandClick="gridRowData"
                                            :rowSelected="projectRowSelected"
                                            :sortSettings="sortOptions"
                                            :allowResizing="true"
                                        >
                                            <e-columns>
                                                <e-column
                                                    field="ProjectName"
                                                    width="400"
                                                    textAlign="Center"
                                                    headerText="Project Name"
                                                ></e-column>
                                                -->
                                                <e-column
                                                    field="CountryName"
                                                    width="200"
                                                    textAlign="Center"
                                                    headerText="Country"
                                                ></e-column>
                                                <e-column
                                                    field="StateName"
                                                    width="200"
                                                    textAlign="Center"
                                                    headerText="State"
                                                ></e-column>
                                                <e-column
                                                    field="City"
                                                    width="200"
                                                    textAlign="Center"
                                                    headerText="City"
                                                ></e-column>
                                                <e-column
                                                    field="Delete"
                                                    width="60"
                                                    freeze="Right"
                                                    :commands="actionCommands"
                                                    headerText="Delete"
                                                ></e-column>
                                            </e-columns>
                                        </ejs-grid>
                                    </b-col>
                                </b-container>
                            </b-container>
                        </b-tab>
                        <b-tab
                            v-for="(i, index) in tabs"
                            :key="'dyn-tab-' + i.ProjectName"
                            @click="
                                isAddProject = false;
                                openProjects(i, true, null, index);
                            "
                            active
                        >
                            <template #title>
                                {{
                                    i.isClone
                                        ? 'Clone ' + i.ProjectName
                                        : 'Edit ' + i.ProjectName
                                }}
                                <!-- {{i}} -->
                                <img
                                    src="../../../assets/close.svg"
                                    @click="closeTab(i.ProjectName)"
                                />
                            </template>
                            <b-container class="p-0">
                                <b-row class="mt-3">
                                    <b-col cols="3" class="p-0 bottom-m-100">
                                        <b-form>
                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Project name',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    editValidated('ProjectName')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        editValidateState(
                                                            'ProjectName'
                                                        )
                                                    "
                                                    name="ProjectName"
                                                    v-model="
                                                        $v.editItem.ProjectName
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="projectName-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="projectName-feedback"
                                                >
                                                    Please enter project name
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Project type',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                v-bind:label="floatLabelEdit"
                                            >
                                                <b-form-select
                                                    v-model="
                                                        $v.editItem
                                                            .ProjectTypeID
                                                            .$model
                                                    "
                                                    class="mb-3"
                                                    id="selectedProjectType"
                                                    :options="
                                                        projectTypeOptions
                                                    "
                                                    text-field="ProjectTypeName"
                                                    value-field="ProjectTypeID"
                                                    :state="
                                                        editValidateState(
                                                            'ProjectTypeID'
                                                        )
                                                    "
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Project
                                                            type</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="countryName-feedback"
                                                >
                                                    Please select a project type
                                                </b-form-invalid-feedback>
                                            </CustomFloatingLabel>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{ label: 'Country' }"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        v-model="
                                                            $v.editItem
                                                                .CountryID
                                                                .$model
                                                        "
                                                        :options="countries"
                                                        text-field="CountryName"
                                                        value-field="CountryId"
                                                        :state="
                                                            editValidateState(
                                                                'CountryID'
                                                            )
                                                        "
                                                        @change="
                                                            stateByCountry(
                                                                editItem.CountryID,
                                                                'edit'
                                                            )
                                                        "
                                                        aria-describedby="countryName-feedback"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >Country</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="countryName-feedback"
                                                    >
                                                        Please select a Country
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'State/Province',
                                                }"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        v-model="
                                                            $v.editItem.StateID
                                                                .$model
                                                        "
                                                        :options="states"
                                                        text-field="StateName"
                                                        value-field="StateId"
                                                        :state="
                                                            editValidateState(
                                                                'StateID'
                                                            )
                                                        "
                                                        aria-describedby="stateName-feedback"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >State/Province</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="stateName-feedback"
                                                    >
                                                        Please select a state
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <CustomValidateInput
                                                :config="{
                                                    label: 'City',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="editValidated('City')"
                                            >
                                                <b-form-input
                                                    :state="
                                                        editValidateState(
                                                            'City'
                                                        )
                                                    "
                                                    name="City"
                                                    v-model="
                                                        $v.editItem.City.$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="city-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="city-feedback"
                                                >
                                                    Please enter City
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Latitude',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    editValidated('YCoord')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        editValidateState(
                                                            'YCoord'
                                                        )
                                                    "
                                                    name="YCoord"
                                                    v-model="
                                                        $v.editItem.YCoord
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="latitude-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="latitude-feedback"
                                                >
                                                    The valid range for latitude
                                                    is between -90 and 90
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Longitude',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    editValidated('XCoord')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        editValidateState(
                                                            'XCoord'
                                                        )
                                                    "
                                                    name="XCoord"
                                                    v-model="
                                                        $v.editItem.XCoord
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="longitude-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="longitude-feedback"
                                                >
                                                    The valid range for
                                                    longitude is between -180
                                                    and 180
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Base Map',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                v-bind:label="floatLabelEdit"
                                            >
                                                <b-form-select
                                                    v-model="
                                                        editItem.DefaultBaseMapID
                                                    "
                                                    class="mb-3"
                                                    id="selectMapOverlay"
                                                    :options="mapOverlayOptions"
                                                    text-field="DefaultBaseMapName"
                                                    value-field="DefaultBaseMapID"
                                                    @change="
                                                        mapOverlayChanged(true)
                                                    "
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Base
                                                            Map</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>

                                            <h5>Set layer order</h5>
                                            <p
                                                style="width:498px;font-size:15px"
                                            >
                                                Drag layers up or down to
                                                organize the order they appear
                                                in map view
                                            </p>
                                            <b-button
                                                @click="setLayerorder"
                                                rounded
                                                size="sm"
                                                class="buttonSelect additional mb-4"
                                            >
                                                View and order layers
                                            </b-button>

                                            <div>
                                                <project-mgmt-map
                                                    :project="project"
                                                    :editItem="editItem"
                                                    :initialExtent="
                                                        initialExtent
                                                    "
                                                    :baseMaps="baseMaps"
                                                    @mapClick="mapClickEdit"
                                                    @layerChanged="layerChanged"
                                                    @newMapBounds="getMapBounds"
                                                />
                                                <div class="projExtent-buttons">
                                                    <b-button
                                                        @click="
                                                            setProjectExtent
                                                        "
                                                        rounded
                                                        size="sm"
                                                        class="buttonSelect additional mb-1 mr-1"
                                                    >
                                                        Set project initial
                                                        extent
                                                    </b-button>
                                                    <b-button
                                                        @click="
                                                            clearProjectExtent
                                                        "
                                                        rounded
                                                        size="sm"
                                                        class="clearExtent-Button additional mb-1 ml-1"
                                                    >
                                                        Clear project initial
                                                        extent
                                                    </b-button>
                                                </div>
                                            </div>

                                            <div
                                                class="innerTab mb-4 mt-3"
                                                v-if="projectInfoSection"
                                            >
                                                <b-tabs
                                                    active-nav-item-class="pl-0 pr-0 ml-1 mr-3 borderBlue"
                                                >
                                                    <b-tab
                                                        title="Select datasource"
                                                        active
                                                    >
                                                        <div
                                                            class="tabTextContent legacyInfo"
                                                        >
                                                            <b-row
                                                                class="projectSearch legacy"
                                                            >
                                                                <div
                                                                    class="float-left h-45 ml-2"
                                                                >
                                                                    <img
                                                                        class="pointer ml-3"
                                                                        src="../../../assets/search-outline.svg"
                                                                        v-if="
                                                                            !filterDataSearch &&
                                                                            !showSearchIconEdit
                                                                                ? true
                                                                                : false
                                                                        "
                                                                        @click="
                                                                            searchOpenProject
                                                                        "
                                                                    />
                                                                    <input
                                                                        ref="projectEdit"
                                                                        spellcheck="false"
                                                                        id="searchFilter"
                                                                        class="ml-3 pt-2 test"
                                                                        v-if="
                                                                            filterDataSearch ||
                                                                            showSearchIconEdit
                                                                                ? true
                                                                                : false
                                                                        "
                                                                        v-model="
                                                                            filterDataSearch
                                                                        "
                                                                        @click="
                                                                            searchinputenterSource()
                                                                        "
                                                                        @input="
                                                                            searchinputenterSource
                                                                        "
                                                                        :class="
                                                                            filterDataSearch ||
                                                                            showSearchIconEdit
                                                                                ? 'test'
                                                                                : 'searchinputList'
                                                                        "
                                                                        type="search"
                                                                        placeholder="Search for datasource"
                                                                    />
                                                                    <span
                                                                        class="grey-bg"
                                                                        v-if="
                                                                            searchCloseEdit
                                                                        "
                                                                        @click="
                                                                            closeBarEdit
                                                                        "
                                                                        ><img
                                                                            src="../../../assets/close.svg"
                                                                    /></span>
                                                                    <span
                                                                        class="border-right list ml-2"
                                                                        >&nbsp;</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="float-left mr-2 h-45 edit-filter"
                                                                >
                                                                    <span
                                                                        class="ml-2 mr-2"
                                                                        ><img
                                                                            src="../../../assets/tooltip.svg"
                                                                            @click="
                                                                                filterClick
                                                                            "
                                                                    /></span>
                                                                    <span
                                                                        class="font-weight-300 pointer"
                                                                        @click="
                                                                            filterClick
                                                                        "
                                                                        >Filter</span
                                                                    >

                                                                    <b-list-group-item
                                                                        class="
                                    filter-group
                                    left-align
                                    p-0
                                    filter-block edit
                                  "
                                                                        v-if="
                                                                            filterShowLegacy
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="ml-4 mt-3"
                                                                        >
                                                                            <div
                                                                                class="mb-1 float-left"
                                                                            >
                                                                                Filter
                                                                                by
                                                                            </div>
                                                                            <div
                                                                                class="text-right pr-3"
                                                                            >
                                                                                <a
                                                                                    href="javascript:void(0)"
                                                                                >
                                                                                    <b-icon
                                                                                        icon="x"
                                                                                        font-scale="2"
                                                                                        @click="
                                                                                            backFilterLegacy
                                                                                        "
                                                                                    >
                                                                                    </b-icon>
                                                                                </a>
                                                                            </div>
                                                                            <div
                                                                                class="mt-2 input-lbl"
                                                                            >
                                                                                <CustomFloatingLabel
                                                                                    :config="{
                                                                                        label:
                                                                                            'Datasource name',
                                                                                        name:
                                                                                            'wrapper',
                                                                                        line: false,
                                                                                        scale: false,
                                                                                    }"
                                                                                >
                                                                                    <input
                                                                                        type="text"
                                                                                        class="
                                            filterSource
                                            normal_input
                                            project
                                          "
                                                                                        v-model="
                                                                                            DataSourceNameDrop
                                                                                        "
                                                                                    />
                                                                                </CustomFloatingLabel>
                                                                                <CustomFloatingLabel
                                                                                    class="FloatingTool"
                                                                                    :config="{
                                                                                        label:
                                                                                            'Connection type',
                                                                                        name:
                                                                                            'wrapper',
                                                                                        line: false,
                                                                                        scale: false,
                                                                                    }"
                                                                                >
                                                                                    <b-form-select
                                                                                        :options="
                                                                                            datasourcesConnectionType
                                                                                        "
                                                                                        text-field="DataSourceType"
                                                                                        value-field="DataSourceType"
                                                                                        placeholder="Connection type"
                                                                                        v-model="
                                                                                            DataSourceType
                                                                                        "
                                                                                        class="filterSelect"
                                                                                    >
                                                                                        <template
                                                                                            #first
                                                                                        >
                                                                                            <b-form-select-option
                                                                                                :value="
                                                                                                    null
                                                                                                "
                                                                                                disabled
                                                                                                >Connection
                                                                                                type</b-form-select-option
                                                                                            >
                                                                                        </template>
                                                                                    </b-form-select>
                                                                                </CustomFloatingLabel>
                                                                            </div>
                                                                            <b-button
                                                                                class="mb-3"
                                                                                v-on:click="
                                                                                    applyFilterLegacy
                                                                                "
                                                                                >Apply
                                                                                filters</b-button
                                                                            >
                                                                        </div>
                                                                    </b-list-group-item>
                                                                </div>
                                                            </b-row>
                                                            <div
                                                                class="pt-2 col-12 p-0 bgGrey filteringBy"
                                                                v-if="
                                                                    filterLegacy &&
                                                                        (DataSourceType ||
                                                                            DataSourceNameDrop) &&
                                                                        filterDisplay
                                                                "
                                                            >
                                                                <div
                                                                    class="p-2"
                                                                >
                                                                    <div
                                                                        class="bgGrey"
                                                                    >
                                                                        <b-row
                                                                            class="pl-3"
                                                                            v-if="
                                                                                filterLegacy
                                                                            "
                                                                        >
                                                                            Filtering
                                                                            By
                                                                        </b-row>
                                                                    </div>
                                                                    <b-row
                                                                        class="ml-0"
                                                                        v-if="
                                                                            filterLegacy
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="px-0 py-1"
                                                                            v-if="
                                                                                DataSourceNameDrop
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="
                                          filteredItem
                                          px-3
                                          py-1
                                          mr-2
                                          text-center
                                        "
                                                                            >
                                                                                {{
                                                                                    DataSourceNameDrop
                                                                                }}
                                                                                <b-icon
                                                                                    icon="x"
                                                                                    font-scale="1"
                                                                                    @click="
                                                                                        backFilterLegacy(
                                                                                            'DataSourceName'
                                                                                        )
                                                                                    "
                                                                                >
                                                                                </b-icon>
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            class="px-0 py-1"
                                                                            v-if="
                                                                                DataSourceType
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="
                                          filteredItem
                                          px-3
                                          py-1
                                          mr-2
                                          text-center
                                        "
                                                                            >
                                                                                {{
                                                                                    DataSourceType
                                                                                }}
                                                                                <b-icon
                                                                                    icon="x"
                                                                                    font-scale="1"
                                                                                    @click="
                                                                                        backFilterLegacy(
                                                                                            'DataSourceType'
                                                                                        )
                                                                                    "
                                                                                >
                                                                                </b-icon>
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            class="removeFilter"
                                                                        >
                                                                            <a
                                                                                @click="
                                                                                    removeFilterLegacy
                                                                                "
                                                                                v-if="
                                                                                    removeLegacyFilter
                                                                                "
                                                                            >
                                                                                Remove
                                                                                all
                                                                                filters
                                                                            </a>
                                                                        </div>
                                                                    </b-row>
                                                                </div>
                                                            </div>
                                                            <hr class="mb-0" />
                                                            <b-row
                                                                class="m-0 edit-block"
                                                            >
                                                                <b-col
                                                                    cols="12"
                                                                    class="p-0"
                                                                >
                                                                    <b-list-group
                                                                        class="listGroup"
                                                                    >
                                                                        <b-list-group-item
                                                                            class="listItem"
                                                                            v-for="item in applyfilterDatasource
                                                                                ? dataSourceLegacy
                                                                                : filteredDataSource"
                                                                            :key="
                                                                                item.id
                                                                            "
                                                                            @click="
                                                                                toggleDataSelectClicked(
                                                                                    item
                                                                                )
                                                                            "
                                                                            :style="
                                                                                selectedDataSource ==
                                                                                    item.DataSourceId &&
                                                                                isClicked1
                                                                                    ? {
                                                                                          'background-color':
                                                                                              '#e6e6e6',
                                                                                          border:
                                                                                              '2px solid #212121 !important',
                                                                                      }
                                                                                    : null
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="float-right ml-3"
                                                                                v-if="
                                                                                    selectedDataSource ==
                                                                                        item.DataSourceId &&
                                                                                        isClicked1
                                                                                "
                                                                            >
                                                                                <img
                                                                                    src="../../../assets/site_tick.svg"
                                                                                />
                                                                            </span>
                                                                            <h5
                                                                                class="float-left pr-1"
                                                                            >
                                                                                {{
                                                                                    item.DataSourceName
                                                                                }}
                                                                            </h5>
                                                                            <span
                                                                                class="dtSourceType"
                                                                                >{{
                                                                                    item.DataSourceType
                                                                                }}</span
                                                                            >
                                                                            <div
                                                                                class="clearfix"
                                                                            ></div>
                                                                            <p
                                                                                class=""
                                                                            >
                                                                                {{
                                                                                    item.DataSourceDesc
                                                                                }}
                                                                            </p>
                                                                        </b-list-group-item>
                                                                    </b-list-group>
                                                                </b-col>
                                                            </b-row>
                                                        </div>
                                                    </b-tab>
                                                    <b-tab
                                                        title="Legacy project information"
                                                        v-if="isClicked1"
                                                    >
                                                        <div
                                                            class="tabTextContent legacyInfo mrg-btm"
                                                        >
                                                            <b-row
                                                                class="height337"
                                                            >
                                                                <b-col>
                                                                    <b-list-group>
                                                                        <b-list-group-item
                                                                            class="listItem"
                                                                        >
                                                                            <span
                                                                                class="float-left pt-2"
                                                                            >
                                                                                Select
                                                                                legacy
                                                                                project(s)
                                                                            </span>

                                                                            <b-button-group
                                                                                class="button-group float-right"
                                                                            >
                                                                                <b-dropdown
                                                                                    right
                                                                                >
                                                                                    <template
                                                                                        slot="button-content"
                                                                                    >
                                                                                        <a
                                                                                            class="option p-4"
                                                                                            @click="
                                                                                                getLegacyProjectInfo(
                                                                                                    'project'
                                                                                                )
                                                                                            "
                                                                                            ><img
                                                                                                src="../../../assets/search_dots.svg"
                                                                                        /></a>
                                                                                    </template>
                                                                                    <div
                                                                                        class="legacyBox"
                                                                                        v-if="
                                                                                            showDropdown
                                                                                        "
                                                                                    >
                                                                                        <div
                                                                                            v-if="
                                                                                                loading
                                                                                            "
                                                                                        >
                                                                                            <div
                                                                                                class="spinner-border loadingIcon"
                                                                                                role="status"
                                                                                            >
                                                                                                <span
                                                                                                    class="sr-only"
                                                                                                    >Loading...</span
                                                                                                >
                                                                                            </div>
                                                                                            <div
                                                                                                class="loadingText"
                                                                                            >
                                                                                                Please
                                                                                                wait...
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group has-search"
                                                                                        >
                                                                                            <input
                                                                                                type="search"
                                                                                                spellcheck="false"
                                                                                                class="searchinput searchFilter"
                                                                                                v-model="
                                                                                                    searchWord
                                                                                                "
                                                                                                @input="
                                                                                                    filterCheckBox(
                                                                                                        searchWord
                                                                                                    )
                                                                                                "
                                                                                            />
                                                                                        </div>
                                                                                        <b-form-group>
                                                                                            <template
                                                                                                #label
                                                                                            >
                                                                                                <b-form-checkbox
                                                                                                    v-model="
                                                                                                        allSelected
                                                                                                    "
                                                                                                    @change="
                                                                                                        toggleAll
                                                                                                    "
                                                                                                >
                                                                                                    {{
                                                                                                        allSelected
                                                                                                            ? 'Un-select All'
                                                                                                            : 'Select All'
                                                                                                    }}
                                                                                                </b-form-checkbox>
                                                                                            </template>
                                                                                            <b-form-checkbox-group
                                                                                                id="flavors3"
                                                                                                v-model="
                                                                                                    selected
                                                                                                "
                                                                                                :options="
                                                                                                    projectLegacyInfo
                                                                                                "
                                                                                                text-field="legacyprojectid"
                                                                                                value-field="legacyprojectid"
                                                                                                name="flavors"
                                                                                                class="ml-4"
                                                                                                @change="
                                                                                                    checkSelected(
                                                                                                        $event
                                                                                                    )
                                                                                                "
                                                                                                aria-label="Individual flavours"
                                                                                                stacked
                                                                                            ></b-form-checkbox-group>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                </b-dropdown>
                                                                            </b-button-group>
                                                                        </b-list-group-item>
                                                                        <b-list-group-item>
                                                                            <span
                                                                                class="float-left pt-2"
                                                                            >
                                                                                Select
                                                                                legacy
                                                                                project
                                                                                description(s)
                                                                            </span>

                                                                            <b-button-group
                                                                                class="button-group float-right"
                                                                            >
                                                                                <b-dropdown
                                                                                    right
                                                                                >
                                                                                    <template
                                                                                        slot="button-content"
                                                                                    >
                                                                                        <a
                                                                                            class="option p-4"
                                                                                            @click="
                                                                                                getLegacyProjectInfo(
                                                                                                    'desc'
                                                                                                )
                                                                                            "
                                                                                            ><img
                                                                                                src="../../../assets/search_dots.svg"
                                                                                        /></a>
                                                                                    </template>
                                                                                    <div
                                                                                        class="legacyBox"
                                                                                        v-if="
                                                                                            showDropdown
                                                                                        "
                                                                                    >
                                                                                        <div
                                                                                            v-if="
                                                                                                loading
                                                                                            "
                                                                                        >
                                                                                            <div
                                                                                                class="spinner-border loadingIcon"
                                                                                                role="status"
                                                                                            >
                                                                                                <span
                                                                                                    class="sr-only"
                                                                                                    >Loading...</span
                                                                                                >
                                                                                            </div>
                                                                                            <div
                                                                                                class="loadingText"
                                                                                            >
                                                                                                Please
                                                                                                wait...
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group has-search"
                                                                                        >
                                                                                            <input
                                                                                                type="search"
                                                                                                spellcheck="false"
                                                                                                v-model="
                                                                                                    searchWordDec
                                                                                                "
                                                                                                class="searchinput searchFilter"
                                                                                                @input="
                                                                                                    filterCheckBoxDec(
                                                                                                        searchWordDec
                                                                                                    )
                                                                                                "
                                                                                            />
                                                                                        </div>

                                                                                        <b-form-group>
                                                                                            <template
                                                                                                #label
                                                                                            >
                                                                                                <b-form-checkbox
                                                                                                    v-model="
                                                                                                        allSelectedDec
                                                                                                    "
                                                                                                    @change="
                                                                                                        toggleAll1
                                                                                                    "
                                                                                                >
                                                                                                    {{
                                                                                                        allSelectedDec
                                                                                                            ? 'Un-select All'
                                                                                                            : 'Select All'
                                                                                                    }}
                                                                                                </b-form-checkbox>
                                                                                            </template>
                                                                                            <b-form-checkbox-group
                                                                                                id="flavors"
                                                                                                v-model="
                                                                                                    selected.dec
                                                                                                "
                                                                                                :options="
                                                                                                    projectLegacyInfo
                                                                                                "
                                                                                                text-field="legacyprojectdesc"
                                                                                                value-field="legacyprojectdesc"
                                                                                                name="flavors"
                                                                                                class="ml-4"
                                                                                                aria-label="Individual flavours"
                                                                                                @change="
                                                                                                    check(
                                                                                                        $event
                                                                                                    )
                                                                                                "
                                                                                                stacked
                                                                                            ></b-form-checkbox-group>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                </b-dropdown>
                                                                            </b-button-group>
                                                                        </b-list-group-item>
                                                                    </b-list-group>
                                                                </b-col>
                                                            </b-row>

                                                            <b-col
                                                                class="d-flex align-items-center"
                                                            >
                                                                <b-col
                                                                    cols="6"
                                                                    lg="8"
                                                                    md="8"
                                                                    class="p-0"
                                                                >
                                                                </b-col>
                                                                <b-col
                                                                    cols="6"
                                                                    lg="4"
                                                                    class="p-0 bottom-m-100"
                                                                >
                                                                    <b-button
                                                                        rounded
                                                                        size="sm"
                                                                        class="addProject buttonSelect ml-3"
                                                                        @click="
                                                                            addProject
                                                                        "
                                                                    >
                                                                        Add to
                                                                        Project
                                                                    </b-button>
                                                                </b-col>
                                                            </b-col>
                                                        </div>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>

                                            <div
                                                class="projectEditInfo mt-3"
                                                v-if="projectEditInfo"
                                            >
                                                <div
                                                    v-for="(projItem,
                                                    index) in selectedDataSourceInfo"
                                                    :key="projItem.DataSourceId"
                                                >
                                                    <b-row class="pt-3">
                                                        <b-col
                                                            cols="5"
                                                            lg="6"
                                                            md="6"
                                                            class="dtSourceTitle"
                                                        >
                                                            <h5
                                                                class="text-dark lead"
                                                            >
                                                                {{
                                                                    projItem.DataSourceName
                                                                }}
                                                            </h5>
                                                        </b-col>
                                                        <b-col
                                                            cols="3"
                                                            lg="3"
                                                            class="text-right"
                                                        >
                                                            <b-button
                                                                class="btnEditDelete"
                                                                variant="outline-dark"
                                                                size="sm"
                                                                @click="
                                                                    deleteSelectedSource(
                                                                        projItem,
                                                                        index
                                                                    )
                                                                "
                                                                >Delete</b-button
                                                            >
                                                        </b-col>
                                                        <b-col
                                                            cols="3"
                                                            lg="3"
                                                            class=""
                                                            @click="
                                                                editAdditionaldatasource(
                                                                    projItem,
                                                                    index
                                                                )
                                                            "
                                                        >
                                                            <b-button
                                                                size="sm"
                                                                class="right btnEdit"
                                                                >Edit
                                                                <img
                                                                    src="../../../assets/edit_white.svg"
                                                                    class="ml-1"
                                                                />
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col>
                                                            <p
                                                                class="text-dark py-2"
                                                            >
                                                                {{
                                                                    projItem.DataSourceDesc
                                                                }}
                                                            </p>
                                                        </b-col>
                                                    </b-row>
                                                    <span
                                                        v-for="item in projItem.ProjectAttributeMappings"
                                                        :key="
                                                            item.legacyprojectid
                                                        "
                                                    >
                                                        <b-row
                                                            v-if="
                                                                item.legacyprojectid
                                                            "
                                                            class="
                                border-top border-bottom
                                py-2
                                bg-grey-banner
                                legacyProject
                              "
                                                        >
                                                            <b-col
                                                                cols="7"
                                                                class="margin-auto"
                                                            >
                                                                <h5
                                                                    class="text-dark projTitle"
                                                                >
                                                                    Legacy
                                                                    project
                                                                </h5>
                                                            </b-col>
                                                            <b-col cols="5">
                                                                <p
                                                                    class="text-dark py-2 font-16"
                                                                >
                                                                    {{
                                                                        item.legacyprojectid
                                                                    }}
                                                                </p>
                                                            </b-col>
                                                        </b-row>
                                                    </span>
                                                </div>
                                            </div>
                                        </b-form>
                                        <b-button
                                            v-if="
                                                selectedDataSourceInfo.length >
                                                    0
                                            "
                                            :disabled="!projectEditInfo"
                                            @click="addAdditionaldatasource"
                                            rounded
                                            size="sm"
                                            class="buttonSelect additional mb-4"
                                        >
                                            Add additional datasource +
                                        </b-button>
                                        <b-button
                                            v-else
                                            :disabled="!projectEditInfo"
                                            @click="addAdditionaldatasource"
                                            rounded
                                            size="sm"
                                            class="buttonSelect additional mb-4"
                                        >
                                            Add datasource +
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <div class="m-buttons-projectlist">
                                    <b-col cols="7" lg="7" md="7" class="p-0">
                                        <p
                                            v-if="!isAddProject"
                                            class="text-right pl-3 m-0 cancelProj"
                                            @click="cancelJob('edit')"
                                        >
                                            Cancel
                                        </p>
                                        <p
                                            v-else
                                            class="text-right pl-3 m-0 cancelProj"
                                            @click="cancelJob('add')"
                                        >
                                            Cancel
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="5"
                                        lg="5"
                                        md="5"
                                        class="pl-0 m-btn-save"
                                    >
                                        <b-button
                                            rounded
                                            class="buttonSelect saveProj ml-4"
                                            :disabled="handleBlur()"
                                            v-if="isAddProject"
                                            @click="saveProject"
                                        >
                                            Save changes
                                        </b-button>
                                        <b-button
                                            rounded
                                            class="buttonSelect ml-2 saveProj"
                                            v-else
                                            @click="editProject"
                                        >
                                            Save changes
                                        </b-button>
                                    </b-col>
                                </div>
                            </b-container>
                        </b-tab>

                        <b-tab
                            v-for="j in newtab"
                            :key="'dyn-tab1-' + j"
                            title="addProj"
                            @click="
                                isAddProject = true;
                                isAddProjectTab = false;
                                newProject();
                            "
                            active
                        >
                            <template #title>
                                Add Project
                                <img
                                    src="../../../assets/close.svg"
                                    @click="closeNewTab(j)"
                                />
                            </template>
                            <b-container class="pad-0">
                                <div class="mt-3">
                                    <b-col cols="6" class="p-0 bottom-m-125">
                                        <b-form>
                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Project name',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    validated('ProjectName')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'ProjectName'
                                                        )
                                                    "
                                                    name="ProjectName"
                                                    v-model="
                                                        $v.project.ProjectName
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="projectName-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="projectName-feedback"
                                                >
                                                    Please enter project name
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Project type',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                v-bind:label="floatLabelEdit"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        v-model="
                                                            $v.project
                                                                .ProjectTypeID
                                                                .$model
                                                        "
                                                        id="selectedProjectType"
                                                        :options="
                                                            projectTypeOptions
                                                        "
                                                        text-field="ProjectTypeName"
                                                        value-field="ProjectTypeID"
                                                        :state="
                                                            validateState(
                                                                'ProjectTypeID'
                                                            )
                                                        "
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >Project
                                                                type</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="countryName-feedback"
                                                        sty
                                                    >
                                                        Please select a project
                                                        type
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{ label: 'Country' }"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        v-model="
                                                            $v.project.CountryID
                                                                .$model
                                                        "
                                                        :options="countries"
                                                        text-field="CountryName"
                                                        value-field="CountryId"
                                                        :state="
                                                            validateState(
                                                                'CountryID'
                                                            )
                                                        "
                                                        @change="
                                                            stateByCountry(
                                                                project.CountryID,
                                                                'add'
                                                            )
                                                        "
                                                        aria-describedby="countryName-feedback"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >Country</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="countryName-feedback"
                                                    >
                                                        Please select a Country
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'State/Province',
                                                }"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        v-model="
                                                            $v.project.StateId
                                                                .$model
                                                        "
                                                        :options="states"
                                                        text-field="StateName"
                                                        value-field="StateId"
                                                        :state="
                                                            validateState(
                                                                'StateId'
                                                            )
                                                        "
                                                        aria-describedby="countryName-feedback"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >State/Province</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="countryName-feedback"
                                                    >
                                                        Please select a
                                                        State/Province
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <CustomValidateInput
                                                :config="{
                                                    label: 'City',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="validated('CityName')"
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'CityName'
                                                        )
                                                    "
                                                    name="CityName"
                                                    v-model="
                                                        $v.project.CityName
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="cityName-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="cityName-feedback"
                                                >
                                                    Please enter city
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Latitude',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="validated('Latitude')"
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'Latitude'
                                                        )
                                                    "
                                                    name="Latitude"
                                                    v-model="
                                                        $v.project.Latitude
                                                            .$model
                                                    "
                                                    aria-describedby="latitude-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="latitude-feedback"
                                                >
                                                    The valid range for latitude
                                                    is between -90 and 90
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Longitude',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    validated('Longitude')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'Longitude'
                                                        )
                                                    "
                                                    name="Longitude"
                                                    v-model="
                                                        $v.project.Longitude
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="longitude-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="longitude-feedback"
                                                >
                                                    The valid range for
                                                    longitude is between -180
                                                    and 180
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Base Map',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                v-bind:label="floatLabelEdit"
                                            >
                                                <b-form-select
                                                    v-model="
                                                        project.DefaultBaseMapID
                                                    "
                                                    class="mb-3"
                                                    id="selectMapOverlay"
                                                    :options="mapOverlayOptions"
                                                    text-field="DefaultBaseMapName"
                                                    value-field="DefaultBaseMapID"
                                                    @change="
                                                        mapOverlayChanged(false)
                                                    "
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Base
                                                            Map</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>

                                            <div>
                                                <project-mgmt-map
                                                    :project="project"
                                                    :editItem="editItem"
                                                    :initialExtent="
                                                        initialExtent
                                                    "
                                                    :baseMaps="baseMaps"
                                                    @mapClick="mapClick"
                                                    @layerChanged="layerChanged"
                                                    @newMapBounds="getMapBounds"
                                                />
                                            </div>

                                            <div
                                                class="innerTab mb-4"
                                                v-if="projectInfoSection"
                                            >
                                                <b-tabs
                                                    active-nav-item-class="pl-0 pr-0 ml-1 mr-3 borderBlue"
                                                >
                                                    <b-tab
                                                        title="Select datasource"
                                                        active
                                                    >
                                                        <div
                                                            class="tabTextContent legacyInfo"
                                                        >
                                                            <b-row
                                                                class="projectSearch legacy"
                                                            >
                                                                <div
                                                                    class="float-left h-45 inner-search ml-2"
                                                                >
                                                                    <img
                                                                        class="pointer ml-3"
                                                                        src="../../../assets/search-outline.svg"
                                                                        v-if="
                                                                            !filterDataSearch &&
                                                                            !showSearchIconAdd
                                                                                ? true
                                                                                : false
                                                                        "
                                                                        @click="
                                                                            searchOpenProjects
                                                                        "
                                                                    />
                                                                    <input
                                                                        ref="projectAdd"
                                                                        spellcheck="false"
                                                                        id="searchFilter"
                                                                        class="test"
                                                                        v-if="
                                                                            filterDataSearch ||
                                                                            showSearchIconAdd
                                                                                ? true
                                                                                : false
                                                                        "
                                                                        v-model="
                                                                            filterDataSearch
                                                                        "
                                                                        @click="
                                                                            searchinputenterSource()
                                                                        "
                                                                        @input="
                                                                            searchinputenterSource
                                                                        "
                                                                        :class="
                                                                            filterDataSearch ||
                                                                            showSearchIconAdd
                                                                                ? 'test'
                                                                                : 'searchinputList'
                                                                        "
                                                                        type="search"
                                                                        placeholder="Search for datasources"
                                                                    />
                                                                    <span
                                                                        class="grey-bg pointer"
                                                                        v-if="
                                                                            searchCloseAdd
                                                                        "
                                                                        @click="
                                                                            closeBarAdd
                                                                        "
                                                                        ><img
                                                                            src="../../../assets/close.svg"
                                                                    /></span>
                                                                    <span
                                                                        class="border-right list ml-2"
                                                                        >&nbsp;</span
                                                                    >
                                                                </div>
                                                                <div
                                                                    class="float-left h-45 ml-2"
                                                                >
                                                                    <span
                                                                        class="ml-2 mr-2"
                                                                        ><img
                                                                            src="../../../assets/tooltip.svg"
                                                                            @click="
                                                                                filterClick
                                                                            "
                                                                    /></span>
                                                                    <span
                                                                        class="font-weight-300 pointer"
                                                                        @click="
                                                                            filterClick
                                                                        "
                                                                        >Filter</span
                                                                    >
                                                                </div>
                                                            </b-row>
                                                            <b-list-group-item
                                                                class="filter-group left-align p-0"
                                                                v-if="
                                                                    filterShowLegacy
                                                                "
                                                            >
                                                                <div
                                                                    class="ml-4 mt-3"
                                                                >
                                                                    <div
                                                                        class="mb-1 float-left"
                                                                    >
                                                                        Filter
                                                                        by
                                                                    </div>
                                                                    <div
                                                                        class="text-right pr-3"
                                                                    >
                                                                        <a
                                                                            href="javascript:void(0)"
                                                                        >
                                                                            <b-icon
                                                                                icon="x"
                                                                                font-scale="2"
                                                                                @click="
                                                                                    backFilterLegacy
                                                                                "
                                                                            >
                                                                            </b-icon>
                                                                        </a>
                                                                    </div>
                                                                    <div
                                                                        class="mt-2 input-lbl"
                                                                    >
                                                                        <!-- <label for="">Datasource name</label> -->
                                                                        <CustomFloatingLabel
                                                                            :config="{
                                                                                label:
                                                                                    'Datasource name',
                                                                                name:
                                                                                    'wrapper',
                                                                                line: false,
                                                                                scale: false,
                                                                            }"
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                class="
                                          filterSource
                                          normal_input
                                          filter_input
                                        "
                                                                                v-model="
                                                                                    DataSourceNameDrop
                                                                                "
                                                                            />
                                                                        </CustomFloatingLabel>
                                                                        <!-- <label for="">Connection type</label> -->

                                                                        <CustomFloatingLabel
                                                                            class="FloatingTool"
                                                                            :config="{
                                                                                label:
                                                                                    'DataSourceType',
                                                                                name:
                                                                                    'wrapper',
                                                                                line: false,
                                                                                scale: false,
                                                                            }"
                                                                        >
                                                                            <b-form-select
                                                                                v-model="
                                                                                    DataSourceType
                                                                                "
                                                                                :options="
                                                                                    datasourcesConnectionType
                                                                                "
                                                                                text-field="DataSourceType"
                                                                                value-field="DataSourceType"
                                                                                class="filterSelect"
                                                                            >
                                                                                <template
                                                                                    #first
                                                                                >
                                                                                    <b-form-select-option
                                                                                        :value="
                                                                                            null
                                                                                        "
                                                                                        disabled
                                                                                        >Connection
                                                                                        type</b-form-select-option
                                                                                    >
                                                                                </template>
                                                                            </b-form-select>
                                                                        </CustomFloatingLabel>
                                                                    </div>
                                                                    <b-button
                                                                        class="mb-3"
                                                                        v-on:click="
                                                                            applyFilterLegacy
                                                                        "
                                                                        >Apply
                                                                        filters</b-button
                                                                    >
                                                                </div>
                                                            </b-list-group-item>
                                                            <div
                                                                class="pt-2 col-12 p-0 bgGrey filteringBy"
                                                                v-if="
                                                                    filterLegacy &&
                                                                        (DataSourceType ||
                                                                            DataSourceNameDrop) &&
                                                                        filterDisplay
                                                                "
                                                            >
                                                                <div
                                                                    class="p-2"
                                                                >
                                                                    <div
                                                                        class="bgGrey"
                                                                    >
                                                                        <b-row
                                                                            class="pl-3"
                                                                            v-if="
                                                                                filterLegacy
                                                                            "
                                                                        >
                                                                            Filtering
                                                                            By
                                                                        </b-row>
                                                                    </div>
                                                                    <b-row
                                                                        class="ml-0"
                                                                        v-if="
                                                                            filterLegacy
                                                                        "
                                                                    >
                                                                        <div
                                                                            class="px-0 py-1"
                                                                            v-if="
                                                                                DataSourceNameDrop
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="
                                          filteredItem
                                          px-3
                                          py-1
                                          mr-2
                                          text-center
                                        "
                                                                            >
                                                                                {{
                                                                                    DataSourceNameDrop
                                                                                }}
                                                                                <b-icon
                                                                                    icon="x"
                                                                                    font-scale="1"
                                                                                    @click="
                                                                                        backFilterLegacy(
                                                                                            'DataSourceName'
                                                                                        )
                                                                                    "
                                                                                >
                                                                                </b-icon>
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            class="px-0 py-1"
                                                                            v-if="
                                                                                DataSourceType
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="
                                          filteredItem
                                          px-3
                                          py-1
                                          mr-2
                                          text-center
                                        "
                                                                            >
                                                                                {{
                                                                                    DataSourceType
                                                                                }}
                                                                                <b-icon
                                                                                    icon="x"
                                                                                    font-scale="1"
                                                                                    @click="
                                                                                        backFilterLegacy(
                                                                                            'DataSourceType'
                                                                                        )
                                                                                    "
                                                                                >
                                                                                </b-icon>
                                                                            </span>
                                                                        </div>
                                                                        <div
                                                                            class="removeFilter"
                                                                        >
                                                                            <a
                                                                                @click="
                                                                                    removeFilterLegacy
                                                                                "
                                                                                v-if="
                                                                                    removeLegacyFilter
                                                                                "
                                                                            >
                                                                                Remove
                                                                                all
                                                                                filters
                                                                            </a>
                                                                        </div>
                                                                    </b-row>
                                                                </div>
                                                            </div>
                                                            <!-- <hr class="mb-0"/> -->
                                                            <b-row
                                                                class="m-0 add-block"
                                                            >
                                                                <b-col
                                                                    cols="12"
                                                                    class="p-0"
                                                                >
                                                                    <b-list-group
                                                                        class="listGroup"
                                                                    >
                                                                        <b-list-group-item
                                                                            class="listItem"
                                                                            v-for="item in applyfilterDatasource
                                                                                ? dataSourceLegacy
                                                                                : filteredDataSource"
                                                                            :key="
                                                                                item.id
                                                                            "
                                                                            @click="
                                                                                toggleDataSelectClicked(
                                                                                    item
                                                                                )
                                                                            "
                                                                            :style="
                                                                                selectedDataSource ==
                                                                                    item.DataSourceId &&
                                                                                isClicked1
                                                                                    ? {
                                                                                          'background-color':
                                                                                              '#e6e6e6',
                                                                                          border:
                                                                                              '2px solid #212121 !important',
                                                                                      }
                                                                                    : null
                                                                            "
                                                                        >
                                                                            <span
                                                                                class="float-right ml-3"
                                                                                v-if="
                                                                                    selectedDataSource ==
                                                                                        item.DataSourceId &&
                                                                                        isClicked1
                                                                                "
                                                                            >
                                                                                <img
                                                                                    src="../../../assets/site_tick.svg"
                                                                                />
                                                                            </span>

                                                                            <h5
                                                                                class="float-left pr-1"
                                                                            >
                                                                                {{
                                                                                    item.DataSourceName
                                                                                }}
                                                                            </h5>
                                                                            <span
                                                                                class="dtSourceType"
                                                                                >{{
                                                                                    item.DataSourceType
                                                                                }}</span
                                                                            >
                                                                            <div
                                                                                class="clearfix"
                                                                            ></div>
                                                                            <p
                                                                                class=""
                                                                            >
                                                                                {{
                                                                                    item.DataSourceDesc
                                                                                }}
                                                                            </p>
                                                                        </b-list-group-item>
                                                                    </b-list-group>
                                                                </b-col>
                                                            </b-row>
                                                        </div>
                                                    </b-tab>
                                                    <b-tab
                                                        title="Legacy project information"
                                                        v-if="isClicked1"
                                                    >
                                                        <div
                                                            class="tabTextContent legacyInfo mrg-btm"
                                                        >
                                                            <b-row
                                                                class="height337"
                                                            >
                                                                <b-col>
                                                                    <b-list-group>
                                                                        <b-list-group-item
                                                                            class="listItem"
                                                                        >
                                                                            <span
                                                                                class="float-left pt-2"
                                                                            >
                                                                                Select
                                                                                legacy
                                                                                project(s)
                                                                            </span>

                                                                            <b-button-group
                                                                                class="button-group float-right"
                                                                            >
                                                                                <b-dropdown
                                                                                    right
                                                                                >
                                                                                    <template
                                                                                        slot="button-content"
                                                                                    >
                                                                                        <a
                                                                                            class="option p-4"
                                                                                            @click="
                                                                                                getLegacyProjectInfo(
                                                                                                    'project'
                                                                                                )
                                                                                            "
                                                                                            ><img
                                                                                                src="../../../assets/search_dots.svg"
                                                                                        /></a>
                                                                                    </template>
                                                                                    <div
                                                                                        class="legacyBox"
                                                                                        v-if="
                                                                                            showDropdown
                                                                                        "
                                                                                    >
                                                                                        <div
                                                                                            v-if="
                                                                                                loading
                                                                                            "
                                                                                        >
                                                                                            <div
                                                                                                class="spinner-border loadingIcon"
                                                                                                role="status"
                                                                                            >
                                                                                                <span
                                                                                                    class="sr-only"
                                                                                                    >Loading...</span
                                                                                                >
                                                                                            </div>
                                                                                            <div
                                                                                                class="loadingText"
                                                                                            >
                                                                                                Please
                                                                                                wait...
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group has-search"
                                                                                        >
                                                                                            <input
                                                                                                type="search"
                                                                                                spellcheck="false"
                                                                                                v-model="
                                                                                                    searchWord
                                                                                                "
                                                                                                class="searchinput searchFilter"
                                                                                                @input="
                                                                                                    filterCheckBox(
                                                                                                        searchWord
                                                                                                    )
                                                                                                "
                                                                                            />
                                                                                        </div>
                                                                                        <b-form-group>
                                                                                            <template
                                                                                                #label
                                                                                                v-if="
                                                                                                    projectLegacyInfo.length >
                                                                                                        0
                                                                                                "
                                                                                            >
                                                                                                <b-form-checkbox
                                                                                                    v-model="
                                                                                                        allSelected
                                                                                                    "
                                                                                                    @change="
                                                                                                        toggleAll
                                                                                                    "
                                                                                                >
                                                                                                    {{
                                                                                                        allSelected
                                                                                                            ? 'Un-select All'
                                                                                                            : 'Select All'
                                                                                                    }}
                                                                                                </b-form-checkbox>
                                                                                            </template>
                                                                                            <b-form-checkbox-group
                                                                                                id="flavors2"
                                                                                                v-model="
                                                                                                    selected
                                                                                                "
                                                                                                :options="
                                                                                                    projectLegacyInfo
                                                                                                "
                                                                                                text-field="legacyprojectid"
                                                                                                value-field="legacyprojectid"
                                                                                                name="flavors"
                                                                                                class="ml-4"
                                                                                                @change="
                                                                                                    checkSelected(
                                                                                                        $event
                                                                                                    )
                                                                                                "
                                                                                                aria-label="Individual flavours"
                                                                                                stacked
                                                                                            ></b-form-checkbox-group>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                </b-dropdown>
                                                                            </b-button-group>
                                                                        </b-list-group-item>
                                                                        <b-list-group-item>
                                                                            <span
                                                                                class="float-left pt-2"
                                                                            >
                                                                                Select
                                                                                legacy
                                                                                project
                                                                                description(s)
                                                                            </span>

                                                                            <b-button-group
                                                                                class="button-group float-right"
                                                                            >
                                                                                <b-dropdown
                                                                                    right
                                                                                >
                                                                                    <template
                                                                                        slot="button-content"
                                                                                    >
                                                                                        <a
                                                                                            class="option p-4"
                                                                                            @click="
                                                                                                getLegacyProjectInfo(
                                                                                                    'desc'
                                                                                                );
                                                                                                check(
                                                                                                    $event
                                                                                                );
                                                                                            "
                                                                                            ><img
                                                                                                src="../../../assets/search_dots.svg"
                                                                                        /></a>
                                                                                    </template>
                                                                                    <div
                                                                                        class="legacyBox"
                                                                                        v-if="
                                                                                            showDropdown
                                                                                        "
                                                                                    >
                                                                                        <div
                                                                                            v-if="
                                                                                                loading
                                                                                            "
                                                                                        >
                                                                                            <div
                                                                                                class="spinner-border loadingIcon"
                                                                                                role="status"
                                                                                            >
                                                                                                <span
                                                                                                    class="sr-only"
                                                                                                    >Loading...</span
                                                                                                >
                                                                                            </div>
                                                                                            <div
                                                                                                class="loadingText"
                                                                                            >
                                                                                                Please
                                                                                                wait...
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group has-search"
                                                                                        >
                                                                                            <input
                                                                                                type="search"
                                                                                                spellcheck="false"
                                                                                                v-model="
                                                                                                    searchWordDec
                                                                                                "
                                                                                                class="searchinput searchFilter"
                                                                                                @input="
                                                                                                    filterCheckBoxDec(
                                                                                                        searchWordDec
                                                                                                    )
                                                                                                "
                                                                                            />
                                                                                        </div>
                                                                                        <b-form-group>
                                                                                            <template
                                                                                                #label
                                                                                                v-if="
                                                                                                    projectLegacyInfo.length >
                                                                                                        0
                                                                                                "
                                                                                            >
                                                                                                <b-form-checkbox
                                                                                                    v-model="
                                                                                                        allSelectedDec
                                                                                                    "
                                                                                                    @change="
                                                                                                        toggleAll1
                                                                                                    "
                                                                                                >
                                                                                                    {{
                                                                                                        allSelectedDec
                                                                                                            ? 'Un-select All'
                                                                                                            : 'Select All'
                                                                                                    }}
                                                                                                </b-form-checkbox>
                                                                                            </template>
                                                                                            <b-form-checkbox-group
                                                                                                id="flavors1"
                                                                                                v-model="
                                                                                                    selected.dec
                                                                                                "
                                                                                                :options="
                                                                                                    projectLegacyInfo
                                                                                                "
                                                                                                text-field="legacyprojectdesc"
                                                                                                value-field="legacyprojectdesc"
                                                                                                name="flavors"
                                                                                                class="ml-4"
                                                                                                @change="
                                                                                                    check(
                                                                                                        $event
                                                                                                    )
                                                                                                "
                                                                                                aria-label="Individual flavours"
                                                                                                stacked
                                                                                            ></b-form-checkbox-group>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                </b-dropdown>
                                                                            </b-button-group>
                                                                        </b-list-group-item>
                                                                    </b-list-group>
                                                                </b-col>
                                                            </b-row>

                                                            <b-row
                                                                class="d-flex align-items-center"
                                                            >
                                                                <b-col
                                                                    class="p-0"
                                                                >
                                                                </b-col>
                                                                <b-col cols="5">
                                                                    <b-button
                                                                        rounded
                                                                        size="sm"
                                                                        class="addProject m-add buttonSelect ml-3"
                                                                        @click="
                                                                            addProject
                                                                        "
                                                                    >
                                                                        Add to
                                                                        Project
                                                                    </b-button>
                                                                </b-col>
                                                            </b-row>
                                                        </div>
                                                    </b-tab>
                                                </b-tabs>
                                            </div>

                                            <div
                                                class="projectEditInfo mt-3"
                                                v-if="projectEditInfo"
                                            >
                                                <div
                                                    v-for="(projItem,
                                                    index) in selectedDataSourceInfo"
                                                    :key="projItem.DataSourceId"
                                                >
                                                    <b-row>
                                                        <b-col
                                                            cols="5"
                                                            lg="6"
                                                            md="6"
                                                            class="dtSourceTitle"
                                                        >
                                                            <h5
                                                                class="text-dark lead"
                                                            >
                                                                {{
                                                                    projItem.DataSourceName
                                                                }}
                                                            </h5>
                                                        </b-col>
                                                        <b-col
                                                            cols="3"
                                                            lg="3"
                                                            class="text-right pr-0"
                                                        >
                                                            <b-button
                                                                class="btnEditDelete"
                                                                variant="outline-dark"
                                                                size="sm"
                                                                @click="
                                                                    deleteSelectedSource(
                                                                        projItem,
                                                                        index
                                                                    )
                                                                "
                                                                >Delete</b-button
                                                            >
                                                        </b-col>
                                                        <b-col
                                                            cols="3"
                                                            lg="3"
                                                            class="pr-0"
                                                            @click="
                                                                editAdditionaldatasource(
                                                                    projItem,
                                                                    index
                                                                )
                                                            "
                                                        >
                                                            <b-button
                                                                size="sm"
                                                                class="right btnEdit"
                                                                >Edit
                                                                <img
                                                                    src="../../../assets/edit_white.svg"
                                                                    class="ml-1"
                                                                />
                                                            </b-button>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="mt-3">
                                                        <b-col>
                                                            <p
                                                                class="text-dark py-2"
                                                            >
                                                                {{
                                                                    projItem.DataSourceDesc
                                                                }}
                                                            </p>
                                                        </b-col>
                                                    </b-row>
                                                    <span
                                                        v-for="item in projItem.ProjectAttributeMappings"
                                                        :key="
                                                            item.legacyprojectid
                                                        "
                                                    >
                                                        <b-row
                                                            v-if="
                                                                item.legacyprojectid
                                                            "
                                                            class="
                                border-top border-bottom
                                py-2
                                bg-grey-banner
                                legacyProject
                              "
                                                        >
                                                            <b-col
                                                                cols="5"
                                                                class="margin-auto"
                                                            >
                                                                <h5
                                                                    class="text-dark projTitle"
                                                                >
                                                                    Legacy
                                                                    project
                                                                </h5>
                                                            </b-col>
                                                            <b-col>
                                                                <p
                                                                    class="text-dark py-2 font-16"
                                                                >
                                                                    {{
                                                                        item.legacyprojectid
                                                                    }}
                                                                </p>
                                                            </b-col>
                                                        </b-row>
                                                    </span>
                                                </div>
                                            </div>
                                            <b-button
                                                v-if="
                                                    selectedDataSourceInfo.length >
                                                        0
                                                "
                                                :disabled="!projectEditInfo"
                                                rounded
                                                @click="addAdditionaldatasource"
                                                size="sm"
                                                class="buttonSelect additional mb-4"
                                            >
                                                Add additional datasource +
                                            </b-button>
                                            <b-button
                                                v-else
                                                :disabled="!projectEditInfo"
                                                rounded
                                                @click="addAdditionaldatasource"
                                                size="sm"
                                                class="buttonSelect additional mb-4"
                                            >
                                                Add datasource +
                                            </b-button>
                                        </b-form>
                                    </b-col>
                                </div>
                                <div class="m-buttons-projectlist">
                                    <b-col cols="7" lg="7" md="7" class="p-0">
                                        <p
                                            v-if="!isAddProject"
                                            class="text-right pl-3 m-0 cancelProj"
                                            @click="cancelJob('edit')"
                                        >
                                            Cancel
                                        </p>
                                        <p
                                            v-else
                                            class="text-right pl-3 m-0 cancelProj"
                                            @click="cancelJob('add')"
                                        >
                                            Cancel
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="5"
                                        lg="5"
                                        md="5"
                                        class="pl-0 m-btn-save"
                                    >
                                        <b-button
                                            rounded
                                            class="buttonSelect ml-4 saveProj"
                                            :disabled="handleBlur()"
                                            v-if="isAddProject"
                                            @click="saveProject"
                                        >
                                            Save changes
                                        </b-button>
                                        <b-button
                                            rounded
                                            class="buttonSelect ml-3 saveProj"
                                            v-else
                                            @click="editProject"
                                        >
                                            Save changes
                                        </b-button>
                                    </b-col>
                                </div>
                            </b-container>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-container>
            <ToastMessages
                :showSuccess="showSuccess"
                :showFailure="showFailure"
                :failureToastMessage="failureToastMessage"
                :successToastMessage="successToastMessage"
            />
        </b-container>
        <b-modal
            ref="my-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <h5 class="my-4">Delete {{ projectName }}?</h5>
                <small class="my-3"
                    >Are you sure you want to delete this project? This action
                    cannot be undone.</small
                ><br />
                <div class="mt-3 mb-4 delModal">
                    <a @click="hideModal" class="text-primary"
                        >No, don't delete</a
                    >
                    <button
                        @click="deleteProject(projectId)"
                        class="deleteButton"
                    >
                        Delete project
                    </button>
                </div>
            </div>
        </b-modal>
        <Footer />
        <div class="datasetcolumns-modal">
            <b-modal
                ref="layers-modal"
                class="model_style"
                id="databox"
                hide-header
                hide-footer
                centered
            >
                <div class="d-block text-center">
                    <div class="text-right cursor-pointer">
                        <img
                            src="../../../assets/close_icon.svg"
                            @click="hideReviewModal"
                        />
                    </div>
                    <h5 class="mt-3 mb-2">Set layer order</h5>
                    <p class="sm-text mt-2">
                        Drag layers up or down to organize the order they appear
                        in map view
                    </p>
                    <div class="my-3 layer-ord">
                        <draggable
                            class="list-group"
                            tag="ul"
                            v-model="projectLayers"
                            v-bind="dragOptions"
                            :move="onMoveLayer"
                            @start="isDragging = true"
                            @end="isDragging = false"
                        >
                            <div
                                class="list-group over-cont"
                                v-for="(element, index) in projectLayers"
                                :key="index"
                            >
                                <span class="badge"
                                    ><img
                                        class="bdg-image"
                                        src="../../../assets/Overflow Menu.svg"
                                    />
                                    <input
                                        type="text"
                                        class="inputbox normal_input"
                                        autocomplete="off"
                                        v-model="element.DisplayName"
                                        disabled
                                    />
                                </span>
                            </div>
                        </draggable>
                    </div>
                    <div class="mt-3 mb-4 delModal text-center">
                        <button class="applyButton" @click="applyLayerOrder">
                            Apply order
                        </button>
                    </div>
                </div>
            </b-modal>
            <ToastMessages
                :showSuccess="showSuccess"
                :showFailure="showFailure"
                :failureToastMessage="failureToastMessage"
                :successToastMessage="successToastMessage"
            />
        </div>
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import Vue from 'vue';
import router from '../../../../router';
import Header from '../../Header';
import Footer from '../../Footer';

import draggable from 'vuedraggable';

import { mapState, mapActions } from 'vuex';
import { commonService } from '../../../services/common.service';
import { datasetService } from '../../../services/dataset.service';
import { dataSourceService } from '../../../services/datasources.service';
import { projectService } from '../../../services/project.service';
import { managementService } from '../../../services/management.service';
import AccessPermissionModal from '../../AccessPermissionModal';
import CustomFloatingLabel from '../../CustomFloatingLabel';

import { validationMixin } from 'vuelidate';
import { required, between } from 'vuelidate/lib/validators';
import CustomValidateInput from '../../CustomValidateInput';

import ProjectMgmtMap from './ProjectMgmtMap.vue';

import { Icon, latLngBounds } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
import {
    GridPlugin,
    Sort,
    Page,
    Edit,
    CommandColumn,
    Toolbar,
    Search,
    InfiniteScroll,
    Freeze,
    Resize,
} from '@syncfusion/ej2-vue-grids';
import ToastMessages from '../../ToastMessages.vue';
Vue.use(GridPlugin);
Vue.directive('clickoutside', {
    inserted: (el, binding, vnode) => {
        // assign event to the element
        el.clickOutsideEvent = function(event) {
            // here we check if the click event is outside the element and it's children
            if (!(el == event.target || el.contains(event.target))) {
                // if clicked outside, call the provided method
                vnode.context[binding.expression](event);
            }
        };
        // register click and touch events
        document.body.addEventListener('click', el.clickOutsideEvent);
        document.body.addEventListener('touchstart', el.clickOutsideEvent);
    },
    unbind: function(el) {
        // unregister click and touch events before the element is unmounted
        document.body.removeEventListener('click', el.clickOutsideEvent);
        document.body.removeEventListener('touchstart', el.clickOutsideEvent);
    },
    stopProp(event) {
        event.stopPropagation();
    },
});
export default {
    mixins: [validationMixin],
    components: {
        Header,
        Footer,
        CustomFloatingLabel,
        AccessPermissionModal,
        ToastMessages,
        CustomValidateInput,
        ProjectMgmtMap,
        draggable,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Edit,
            CommandColumn,
            Toolbar,
            Search,
            InfiniteScroll,
            Freeze,
            Resize,
        ],
    },
    validations() {
        return {
            project: {
                ProjectName: {
                    required,
                },
                CountryID: {
                    required,
                },
                ProjectTypeID: {
                    required,
                },
                StateId: {
                    required,
                },
                CityName: {
                    required,
                },
                Latitude: {
                    between: between(-90, 90),
                },
                Longitude: {
                    between: between(-180, 180),
                },
            },
            editItem: {
                ProjectName: {
                    required,
                },
                CountryID: {
                    required,
                },
                ProjectTypeID: {
                    required,
                },
                StateID: {
                    required,
                },
                City: {
                    required,
                },
                YCoord: {
                    between: between(-90, 90),
                },
                XCoord: {
                    between: between(-180, 180),
                },
            },
            form: {
                file: {
                    required,
                    requiredIf(value) {
                        return value;
                    },
                },
            },
        };
    },
    data() {
        return {
            tabIndex: 1,
            projectId: '',
            projectName: '',
            selectedSource: 0,
            // selectedItem:'',
            isClicked: false,
            isClicked1: false,
            tabs: [],
            newtab: [],
            filter: null,
            filterDataSearch: null,
            filterShow: false,
            filterShowLegacy: false,
            filterProjectName: '',
            filterProjectDesc: '',
            filterProjectCountry: '',
            filterProjectState: '',
            filterProjectCity: '',
            applyfilter: false,
            applyfilterDatasource: false,
            projectFiltered: [],
            selectedProjectType: null,
            projectTypeOptions: [],
            selectedMapOverlay: null,
            mapOverlayOptions: [],
            countries: null,
            states: null,
            validateInput: true,
            validateCountry: false,
            floatLabelAdd: null,
            floatLabelEdit: null,
            project: {
                ProjectName: null,
                ProjectDesc: null,
                ProjectTypeID: null,
                DefaultBaseMapID: null,
                CountryID: null,
                StateId: null,
                CityName: null,
                Latitude: 0,
                Longitude: 0,
            },
            selectedItem: {
                ProjectName: null,
                CountryID: null,
                StateId: null,
                CityName: null,
            },
            selectedDataSource: null,
            projectLegacyInfo: [],
            legecyProjectArray: [],
            legecyProjectDescArray: [],
            // selected: [],
            selected: {
                dec: [],
            },

            // selectedDec: [],
            allSelected: false,
            allSelectedDec: false,
            projectInfoSection: true,
            projectEditInfo: false,
            selectedDataSourceInfo: [],
            tabCounter: 0,
            // selectedProjectLegacyInfo:'',
            selectedSiteName: [],
            manageHeader: true,
            saveHeader: false,
            createProjectRes: '',
            // successToast: false,
            // failureToast:false,
            editItem: {
                ProjectID: null,
                ProjectName: null,
                ProjectTypeID: null,
                DefaultBaseMapID: null,
                ClientID: null,
                Logo: null,
                City: null,
                Comments: null,
                CountryID: null,
                StateID: null,
                ProjectAttributeMappings: null,
                IsActive: null,
                XCoord: 0,
                YCoord: 0,
                isClone: null,
            },
            tableName: '',
            showPage: false,
            assignShow: true,
            thisfilename: null,
            searchWord: null,
            legacyoptions: null,
            DataSourceNameDrop: null,
            DataSourceType: null,
            filterLegacy: false,
            removeProjectFilter: false,
            removeLegacyFilter: false,
            searchWordDec: null,
            dataSourceLegacy: [],
            isAddProject: false,
            actionCommands: [
                {
                    type: 'Delete',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-delete e-icons',
                    },
                },
            ],

            action: [{ buttonOption: { iconCss: 'e-delete e-icons' } }],
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            sortOptions: {
                columns: [
                    { field: 'ProjectName', direction: 'Ascending' },
                ],
            },
            show: true,
            showSearchIcon: false,
            cities: [],
            countryId: '',
            StateId: '',
            CityName: '',
            activatedTabIndex: 0,
            datasources: [],
            selectedProjectFiltered: [],
            decArr: [],
            projectMappng: [],
            projectMappngArr: [],
            datasourcesFiltered: [],
            filterDisplay: false,
            editMappings: [],
            showDropdown: false,
            mappingAttr: [],
            editIndex: null,
            editItemToFilter: [],
            removeIndex: false,
            datasourcesConnectionType: '',
            isAddProjectTab: true,
            searchClose: false,
            searchCloseEdit: false,
            showSearchIconEdit: false,
            searchCloseAdd: false,
            showSearchIconAdd: false,
            projectLayers: [],
            dragOptions: {
                animation: 0,
                group: 'description',
                ghostClass: 'ghost',
            },
            isDragging: false,
            loading: false,
            baseMaps: [
                {
                    name: 'Satellite_Imagery',
                    visible: false,
                    url:
                        'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'NoMap',
                    visible: false,
                    url: '',
                    attribution: '',
                },
            ],
            isClone: false,
            mapBounds: null,
            initialExtent: [],
        };
    },

    created() {
        this.countryList();
        this.getDataSourcesProjectList();
        this.getProjectTypes();
        this.getBaseMapTypes();
    },
    mounted() {
        //   this.getPermissions();
        this.pagePermit();
        if (this.showPage) {
            this.getAllProjects();
            this.getDataSources();
            this.getProjectLayerOrder();
        }
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        hide() {
            this.filterShow = false;
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.project[name];
            return $dirty ? !$error : null;
        },
        validateFile(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        getProjectLayers() {
            datasetService.getEditingDataSet('720').then((res) => {
                this.projectLayers = res.data[0].projLayerOrder;
            });
        },
        getProjectLayerOrder() {
            if (this.editItem.ProjectID) {
                datasetService
                    .getProjectLayerOrder(this.editItem.ProjectID)
                    .then((res) => {
                        this.projectLayers = res.data.projLayerOrder;
                        this.projectLayers.sort(
                            (a, b) => a.LayerOrder - b.LayerOrder
                        );
                        let i = 0;
                        this.projectLayers.forEach((element) => {
                            element.LayerOrder = i;
                            i++;
                        });
                    });
            }
        },
        setLayerorder() {
            this.getProjectLayerOrder();
            this.$refs['layers-modal'].show();
        },
        applyLayerOrder() {
            let data = this.projectLayers;
            datasetService
                .updateLayerOrder(data)
                .then((res) => {
                    if (res.data.StatusCode === 202) {
                        this.successToastMessage =
                            'Layer order updated  successfully';
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.hideReviewModal();
                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 2000);
                    }
                })
                .catch((err) => {
                    if (err) {
                        this.failureToastMessage = 'Something went wrong';
                        this.showFailure = true;
                        this.showSuccess = false;
                        this.connectingToast = false;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 500);
                    }
                });
        },
        hideReviewModal() {
            this.$refs['layers-modal'].hide();
        },
        editValidateState(name) {
            const { $dirty, $error } = this.$v.editItem[name];
            return $dirty ? !$error : null;
        },
        layerChanged(e) {
            if (this.isAddProject) {
                this.project.DefaultBaseMapID = this.mapOverlayOptions.find(
                    (item) => {
                        return e.name === item.DefaultBaseMapName;
                    }
                ).DefaultBaseMapID;
            } else {
                this.editItem.DefaultBaseMapID = this.mapOverlayOptions.find(
                    (item) => {
                        return e.name === item.DefaultBaseMapName;
                    }
                ).DefaultBaseMapID;
            }
        },
        getMapBounds(bounds) {
            this.mapBounds = bounds;
        },
        mapOverlayChanged(param) {
            let selectedOverlay = param
                ? this.mapOverlayOptions.find((item) => {
                      return (
                          this.editItem.DefaultBaseMapID ===
                          item.DefaultBaseMapID
                      );
                  }).DefaultBaseMapName
                : this.mapOverlayOptions.find((item) => {
                      return (
                          this.project.DefaultBaseMapID ===
                          item.DefaultBaseMapID
                      );
                  }).DefaultBaseMapName;

            this.baseMaps.forEach((mapItm) => {
                if (mapItm.name === selectedOverlay) {
                    mapItm.visible = true;
                } else {
                    mapItm.visible = false;
                }
            });
        },
        mapClick(e) {
            this.project.Latitude = e.latlng.lat.toFixed(6);
            let long = e.latlng.lng % 360;
            if (long < -180) {
                long = long + 360;
            } else if (long > 180) {
                long = long - 360;
            }
            this.project.Longitude = long.toFixed(6);
        },
        mapClickEdit(e) {
            let long = e.latlng.lng % 360;
            if (long < -180) {
                long = long + 360;
            } else if (long > 180) {
                long = long - 360;
            }
            this.editItem.YCoord = e.latlng.lat.toFixed(6);
            this.editItem.XCoord = long.toFixed(6);
        },
        onMoveLayer({ relatedContext, draggedContext }) {
            this.projectLayers.splice(
                relatedContext.element.LayerOrder,
                0,
                this.projectLayers.splice(
                    draggedContext.element.LayerOrder,
                    1
                )[0]
            );
            // update order property based on position in array
            this.projectLayers.forEach(function(item, index) {
                item.LayerOrder = index;
            });

            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;

            return (
                (!relatedElement || !relatedElement.fixed) &&
                !draggedElement.fixed
            );
        },
        removeSpace() {
            if (this.filter == ' ') {
                this.filter = null;
            }
        },
        deleteSelectedSource(item, index) {
            this.selectedDataSource = '';
            this.selectedSiteName = '';
            this.selected = [];
            this.selectedDec = [];
            this.selectedDataSourceInfo.splice(index, 1);
            this.projectMappng = this.projectMappng.filter(function(list_item) {
                return list_item.DataSourceId != item.DataSourceId;
            });
            if (this.selectedDataSourceInfo.length == 0) {
                this.projectInfoSection = true;
                this.projectEditInfo = false;
            }
            this.mappingArr();
            this.allSelected = false;
            this.allSelectedDec = false;
            this.legecyProjectDescArray = [];
            this.legecyProjectArray = [];
            this.isClicked = false;
            this.isClicked1 = false;
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            //   this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName == 'ProjectList') {
                    vm.showPage = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        filterClick() {
            this.applyfilterDatasource = false;
            (this.filterDisplay = false), (this.filterShowLegacy = true);
            this.filteredDataSource = false;
            document.querySelector('#outersearchinput').style.width = '35px';
            document.querySelector('#outersearchinput').style.borderColor =
                'none';
            document.querySelector('#outersearchinput .e-input').style.display =
                'none';
        },
        applyFilterLegacy() {
            this.applyfilterDatasource = true;
            this.removeLegacyFilter = true;
            this.dataSourceLegacy = this.datasources;
            let vm = this;
            if (vm.DataSourceNameDrop) {
                vm.dataSourceLegacy = vm.dataSourceLegacy.filter((item) => {
                    return item.DataSourceName.toLowerCase().includes(
                        vm.DataSourceNameDrop.toLowerCase()
                    );
                });
            }

            if (vm.DataSourceType) {
                vm.dataSourceLegacy = vm.dataSourceLegacy.filter(
                    (item) => item.DataSourceType === vm.DataSourceType
                );
            }
            this.filterLegacy = true;
            this.closeFilter();
            this.filterDisplay = true;
        },
        removeFilterLegacy() {
            this.applyfilterDatasource = false;
            this.DataSourceNameDrop = null;
            this.DataSourceType = null;
            this.applyFilterLegacy();
            this.removeLegacyFilter = false;
            this.filterLegacy = false;
            //   this.removeProjectFilter();
        },
        closeFilter() {
            setTimeout(() => {
                this.filterShowLegacy = false;
            });
        },
        backFilterLegacy(item) {
            this.filterShowLegacy = false;
            this.filterLegacy = true;
            if (item == 'DataSourceName') {
                this.DataSourceNameDrop = '';
            }
            if (item == 'DataSourceType') {
                this.DataSourceType = '';
            }
            if (this.applyfilterDatasource) {
                this.applyFilterLegacy();
            }

            if (this.DataSourceNameDrop == '' && this.DataSourceType == '') {
                this.filterLegacy = false;
            } else {
                this.filterLegacy = true;
            }
        },
        cancelJob(type) {
            // this.editItem ? this.tabs.splice(0, 1) : this.newtab.splice(0,1);
            if (type == 'edit') {
                this.tabs.splice(0, 1);
                this.newtab.splice(0, 1);
            } else if (type == 'add') {
                this.newtab.splice(0, 1);
            }
            // this.tabs.splice(0, 1);
            // this.newtab.splice(0,1);
            // this.newtab.pop();
            // this.closeTab(x);
            this.isClicked1 = false;
            this.projectInfoSection = true;
            this.projectEditInfo = false;
            this.project = {
                ProjectName: null,
                ProjectDesc: null,
                ProjectTypeID: null,
                DefaultBaseMapID: null,
                CountryID: null,
                StateId: null,
                CityName: null,
                Latitude: null,
                Longitude: null,
            };
            if (this.newtab.length === 0) {
                this.saveHeader = false;
                this.manageHeader = true;
            }
        },
        activateTab(currentTabs) {
            const length = currentTabs.length - 1;
            this.activatedTabIndex = length;
            this.selected = [];
            this.selected.dec = [];
        },
        selectedTab(newTabIndex) {
            if (newTabIndex) {
                //
                this.saveHeader = true;
                this.manageHeader = false;
            } else {
                //
                this.saveHeader = false;
                this.manageHeader = true;
            }
        },
        toggleAll(checked) {
            if (checked) {
                this.legecyProjectArray = this.projectLegacyInfo.map((item) => {
                    return item.legacyprojectid;
                });
            } else {
                this.legecyProjectArray = [];
            }

            this.selected = checked ? this.legecyProjectArray : [];
        },
        clearSelection() {
            this.selected = [];
            this.selected.dec = [];
            this.allSelected = false;
            this.allSelectedDec = false;
            this.legecyProjectDescArray = [];
            this.legecyProjectArray = [];
        },

        toggleAll1(checked) {
            if (checked) {
                this.legecyProjectDescArray = this.projectLegacyInfo.map(
                    (item) => {
                        return item.legacyprojectdesc;
                    }
                );
            } else {
                this.legecyProjectDescArray = [];
            }
            this.selected.dec = checked ? this.legecyProjectDescArray : [];
            this.$forceUpdate();
        },
        handleBlur() {
            if (
                this.project.ProjectName &&
                this.validateCountry &&
                this.project.StateId &&
                this.project.CityName
            ) {
                this.validateInput = false;
            } else {
                this.validateInput = true;
            }
            return this.validateInput;
        },
        ...mapActions('projects', {
            getAllProjects: 'getAll',
            remove: 'deleteProject',
        }),
        ...mapActions('datasources', {
            getDataSources: 'getDataSources',
        }),
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        showModal(projectDetails) {
            this.projectId = projectDetails.ProjectID;
            this.projectName = projectDetails.ProjectName;
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },
        deleteProject(projectId) {
            // this.remove(projectId);
            //     setTimeout(()=>{
            //       if(this.deleteStatusCode==200){
            //           this.getAllProjects()
            //       }
            //   },1000);
            // let vm = this;
            projectService.deleteProject(projectId).then((res) => {
                if (res.status === 200) {
                    // setTimeout(()=>{
                    this.showSuccess = true;
                    this.showFailure = false;
                    this.isClicked = false;
                    this.successToastMessage = 'Project deleted successfully';
                    setTimeout(() => {
                        this.showSuccess = false;
                        this.getAllProjects();
                    });

                    //  vm.projects=   projectService.getAll()
                    //         .then(response=>{
                    //            // vm.projects = response.data;
                    //
                    //             return vm.$store.state.projects.projects.items;
                    //         })
                    //  },2000);
                } else if (res.status === 500) {
                    this.showFailure = true;
                    this.showSuccess = false;
                    this.failureToastMessage = 'Something went wrong.';
                    setTimeout(() => {
                        this.showFailure = false;
                    });
                }
            });
            this.$refs['my-modal'].hide();
            //   this.filter=null;
        },
        toggleIsClicked(item) {
            this.isClicked = !this.isClicked;
            this.selectedSource = item.ProjectID;
            this.selectedItem = item;
        },
        openProjects(_selectedItem, isAlreadyOpen, isClone, index) {
            console.log(this.tabs);
            let selectedItem =
                index !== null
                    ? this.tabs.find((itm, inx) => {
                          return inx === index;
                      })
                    : Object.assign({}, _selectedItem);
            selectedItem.isClone =
                isClone !== null ? isClone : selectedItem.isClone;
            this.projectEditInfo = true;
            this.projectInfoSection = false;
            this.manageHeader = false;
            this.saveHeader = true;
            this.filterDataSearch = null;
            this.searchCloseEdit = false;
            this.filterShowLegacy = false;
            this.filterShow = false;
            (this.showSuccess = false), (this.selected = []);
            this.projectMappng = [];
            this.editMappings = [];
            let vm = this;
            projectService.editProject(selectedItem.ProjectID).then((res) => {
                vm.editItem = res.data[0];
                vm.editItem.isClone = selectedItem.isClone;
                vm.getProjectExtent();
                let selectedOverlay = this.mapOverlayOptions.find((item) => {
                    return (
                        vm.editItem.DefaultBaseMapID === item.DefaultBaseMapID
                    );
                });
                if (selectedOverlay) {
                    let selectedOverlayName =
                        selectedOverlay.DefaultBaseMapName;
                    this.baseMaps.forEach((mapItm) => {
                        if (mapItm.name === selectedOverlayName) {
                            mapItm.visible = true;
                        } else {
                            mapItm.visible = false;
                        }
                    });
                }
                var attributeMappings = res.data.map((item) => {
                    return item.ProjectAttributeMappings.map((mapItem) => {
                        return mapItem.ProjectAttributeMappingId;
                    });
                });

                vm.mappingAttr = [];
                vm.selectedDataSourceInfo = [];
                if (attributeMappings[0].length > 0) {
                    vm.editFirst = true;
                    projectService
                        .editProjectMapping(attributeMappings)
                        .then((res) => {
                            vm.mappingAttr = res.data;
                            vm.selectedSiteName = res.data;
                            vm.editItem.ProjectAttributeMappings =
                                vm.mappingAttr;
                            // let aw = [];
                            var dataArr = vm.mappingAttr.map((item) => {
                                return [item.DataSourceId, item];
                            });
                            var maparr = new Map(dataArr);

                            var result = [...maparr.values()];

                            result.forEach((iItem) => {
                                iItem.ProjectAttributeMappings = [];
                            });
                            vm.mappingAttr.forEach((innerItem) => {
                                for (var i = 0; i < result.length; i++) {
                                    if (
                                        innerItem.DataSourceId ==
                                        result[i].DataSourceId
                                    ) {
                                        result[i].ProjectAttributeMappings.push(
                                            innerItem
                                        );
                                    }
                                }
                            });

                            vm.selectedDataSourceInfo = result;

                            vm.projectMappng = vm.mappingAttr.map((item) => {
                                return {
                                    DataSourceId: item.DataSourceId,
                                    legacyprojectid: item.legacyprojectid,
                                    legacyprojectdesc: item.legacyprojectdesc,
                                };
                            });
                        });
                }
            });
            setTimeout(() => {
                if (!isAlreadyOpen) {
                    vm.tabs.push(selectedItem);
                }
                vm.floatLabelEdit = 'Edit';
                if (vm.editItem) {
                    //
                    const id =
                        vm.editItem.ProjectAttributeMappings[0].DataSourceId;
                    projectService.getTableName(id).then((res) => {
                        vm.tableName = res.data.ProjectTable;
                        if (vm.tableName) {
                            vm.isClicked1 = true;
                        }
                    });

                    vm.editItem.ProjectAttributeMappings.forEach((item) => {
                        vm.selected.push(item.legacyprojectid);
                    });
                }
            }, 1000);

            this.countryList();
            this.selectedStateByCountry();
            this.isAddProject = false;
        },
        closeTab(x) {
            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i]['ProjectName'] === x) {
                    this.tabs.splice(i, 1);
                }
            }
            this.isClicked1 = false;
            this.filterShow = false;
            this.filterDataSearch = null;
            this.projectInfoSection = true;
            this.filterShowLegacy = false;
            this.projectEditInfo = false;
            if (this.tabs.length === 0) {
                this.saveHeader = false;
                this.manageHeader = true;
            }
            setTimeout(() => {
                this.activatedTabIndex = 0;
            }, 100);
        },
        newProject() {
            this.baseMaps.forEach((mapItm) => {
                if (mapItm.name === 'TopoMap') {
                    mapItm.visible = true;
                } else {
                    mapItm.visible = false;
                }
            });
            this.selectedDataSourceInfo = [];
            this.isAddProject = true;
            this.saveHeader = true;
            this.manageHeader = false;
            this.filterShow = false;
            // this.filterDataSearch = null;
            this.filterShowLegacy = false;
            this.projectMappngArr = [];
            this.editMappings = [];
            this.projectMappng = [];
            if (this.isAddProjectTab) {
                this.newtab.push(this.tabCounter++);
                this.project = {
                    ProjectName: null,
                    ProjectDesc: null,
                    ProjectTypeID: null,
                    DefaultBaseMapID: this.mapOverlayOptions.find((item) => {
                        return 'TopoMap' === item.DefaultBaseMapName;
                    }).DefaultBaseMapID,
                    CountryID: null,
                    StateId: null,
                    CityName: null,
                    Latitude: null,
                    Longitude: null,
                };
            }
            this.countryList();
            if (this.tabCounter) {
                this.floatLabelAdd = 'Add';
            }
            this.projectEditInfo = false;
            this.projectInfoSection = true;
        },
        closeNewTab(x) {
            for (let i = 0; i < this.newtab.length; i++) {
                if (this.newtab[i] === x) {
                    this.newtab.splice(i, 1);
                }
                this.isClicked1 = false;
                this.filterShow = false;
                this.filterDataSearch = null;
                this.projectInfoSection = true;
                this.filterShowLegacy = false;
                this.projectEditInfo = false;
                this.project = {
                    ProjectName: null,
                    ProjectDesc: null,
                    ProjectTypeID: null,
                    CountryID: '',
                    StateId: '',
                    CityName: null,
                    Latitude: null,
                    Longitude: null,
                };
                if (this.newtab.length === 0) {
                    this.saveHeader = false;
                    this.manageHeader = true;
                }
            }
            setTimeout(() => {
                this.activatedTabIndex = 0;
            }, 100);
        },
        filterdata() {
            setTimeout(() => {
                this.filterShow = true;
            });
        },
        applyFilter() {
            this.applyfilter = true;
            this.projectFiltered = this.projects;
            // if (this.filterProjectName) {
            //     this.projectFiltered = this.projectFiltered.filter(
            //     (item) => item.ProjectName === this.filterProjectName
            //     );
            // }
            if (this.countryId) {
                this.projectFiltered = this.projectFiltered.filter(
                    (item) => item.CountryID === this.countryId
                );
            }
            if (this.StateId) {
                this.projectFiltered = this.projectFiltered.filter(
                    (item) => item.StateID === this.StateId
                );
            }
            if (this.CityName) {
                this.projectFiltered = this.projectFiltered.filter(
                    (item) =>
                        item.City.toLowerCase() === this.CityName.toLowerCase()
                );
            }
            this.removeProjectFilter = true;

            this.filterShow = false;
        },
        backFilter(item) {
            this.filterShow = false;
            //    if(item == 'filterProjectName'){
            //      this.filterProjectName = "";
            //    }
            if (item == 'countryId') {
                this.countryId = '';
            }
            if (item == 'StateId') {
                this.StateId = '';
            }
            if (item == 'CityName') {
                this.CityName = '';
            }
            this.applyFilter();
            if (
                /*this.filterProjectName == "" &&*/ this.countryId == '' &&
                this.StateId == '' &&
                this.CityName == ''
            ) {
                this.removeProjectFilter = false;
            }
        },
        removeFilter() {
            this.applyfilter = false;
            this.filterProjectName = '';
            this.filterProjectDesc = '';
            this.countryId = '';
            this.StateId = '';
            this.CityName = '';
            this.applyFilter();
            this.removeProjectFilter = false;
            this.removeProjectFilter();
        },
        countryList() {
            commonService
                .getCountries()
                .then((res) => (this.countries = res.data));
        },
        getProjectTypes() {
            projectService.GetProjectType().then((res) => {
                this.projectTypeOptions = res.data;
            });
        },
        getBaseMapTypes() {
            projectService.GetBaseMapTypes().then((res) => {
                this.mapOverlayOptions = res.data;
            });
        },
        getDataSourcesProjectList() {
            dataSourceService.getDataSourcesProjectList().then((res) => {
                this.datasources = res.data;
                var filteredConnection = this.datasources.map((item) => {
                    return item.DataSourceType;
                });
                var filteredConnection1 = [...new Set(filteredConnection)];
                var filteredConnection2 = filteredConnection1.map((item) => {
                    return { DataSourceType: item };
                });
                this.datasourcesConnectionType = filteredConnection2.filter(
                    (item) => {
                        return item.DataSourceType != null;
                    }
                );
                this.datasourcesFiltered = res.data;
            });
        },
        stateByCountry(countryId, type) {
            commonService
                .getStates(countryId)
                .then((res) => (this.states = res.data));
            this.project.StateId = '';
            if (countryId) {
                this.validateCountry = true;
            } else {
                this.validateCountry = false;
            }
            if (type == 'add') {
                if (!this.project.StateId) {
                    this.validateCountry = true;
                } else {
                    this.validateCountry = false;
                }
            }
            if (type == 'edit') {
                if (!this.editItem.StateID) {
                    this.validateCountry = true;
                } else {
                    this.validateCountry = false;
                }
            }
            this.handleBlur();
        },
        selectedStateByCountry() {
            commonService
                .getStates(this.selectedItem.CountryID)
                .then((res) => (this.states = res.data));
        },
        stateByCountryFilter() {
            let country = this.countries.find(
                (e) => e.CountryId === this.countryId
            );
            this.countryName = country.CountryName;
            commonService
                .getStates(this.countryId)
                .then((res) => (this.states = res.data));
        },
        cityByStateFilter() {
            let state = this.states.find((e) => e.StateId === this.StateId);
            this.stateName = state.StateAbbrev;
            commonService
                .getCities(this.StateId)
                .then((res) => (this.cities = res.data));
        },
        toggleDataSelectClicked(item) {
            this.isClicked1 = !this.isClicked1;
            if (this.isClicked1) {
                this.selectedDataSource = item.DataSourceId;
                // this.selectedDataSourceInfo.push(item);
                // this.getLegacyProjectInfo(item);
                this.handleBlur();
                this.selectedProjectFiltered = item;
                this.allSelected = false;
                this.selected = [];
                this.allSelectedDec = false;
                this.selected.dec = [];
            }
        },
        getLegacyProjectInfo(column) {
            this.projectLegacyInfo = [];
            this.showDropdown = true;
            this.loading = true;
            let columnNameProj = null;
            let columnNameDesc = null;
            if (column == 'project') {
                columnNameProj = this.selectedProjectFiltered.ProjectColumn;
            } else if (column == 'desc') {
                columnNameProj = this.selectedProjectFiltered.ProjectColumn;
                columnNameDesc = this.selectedProjectFiltered.ProjectDescColumn;
            }
            datasetService
                .legacyProjectInfo(
                    this.selectedProjectFiltered.DataSourceId,
                    this.selectedProjectFiltered.ProjectTable,
                    columnNameProj,
                    columnNameDesc
                )
                .then((res) => {
                    this.loading = false;
                    this.projectLegacyInfo = res.data;
                    this.legacyoptions = res.data;
                })
                .catch((err) => {
                    if (err.response.data.StatusCode == 500) {
                        if (column == 'project') {
                            this.selected = [];
                        }
                        this.showDropdown = false;
                        this.showFailure = true;
                        this.failureToastMessage = err.response.data.Message;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                    this.projectLegacyInfo = [];
                    this.legacyoptions = [];
                });
        },
        checkSelected() {
            this.selected.length == this.projectLegacyInfo.length
                ? (this.allSelected = true)
                : (this.allSelected = false);
        },
        check() {
            this.selected.dec.length == this.projectLegacyInfo.length
                ? (this.allSelectedDec = true)
                : (this.allSelectedDec = false);
        },
        mappingArr() {
            var projectInstance = [];
            var projectInfo = [];
            for (var y = 0; y < this.selectedDataSourceInfo.length; y++) {
                this.projectMappng = [];
                let vm = this;
                if (
                    vm.selectedDataSourceInfo[y].ProjectAttributeMappings
                        .length > 0
                ) {
                    projectInstance = vm.selectedDataSourceInfo[
                        y
                    ].ProjectAttributeMappings.map((item) => {
                        if (item.ProjectAttributeMappingID) {
                            return {
                                DataSourceId:
                                    vm.selectedDataSourceInfo[y].DataSourceId,
                                legacyprojectid: item.legacyprojectid,
                                ProjectAttributeMappingID:
                                    item.ProjectAttributeMappingID,
                            };
                        } else if (item.legacyprojectid) {
                            return {
                                DataSourceId:
                                    vm.selectedDataSourceInfo[y].DataSourceId,
                                legacyprojectid: item.legacyprojectid,
                            };
                        } else {
                            return {
                                DataSourceId:
                                    vm.selectedDataSourceInfo[y].DataSourceId,
                            };
                        }
                    });
                }

                projectInfo.push(projectInstance);
            }

            for (var u = 0; u < projectInfo.length; u++) {
                for (var r = 0; r < projectInfo[u].length; r++) {
                    this.projectMappng.push(projectInfo[u][r]);
                }
            }
        },
        addProject() {
            this.decArr = [];
            this.projectInfoSection = false;
            this.projectEditInfo = true;
            this.projectMappng = [];
            if (this.editIndex != null) {
                if (
                    this.selectedProjectFiltered.DataSourceId ==
                    this.editItemToFilter.DataSourceId
                ) {
                    this.selectedDataSourceInfo[
                        this.editIndex
                    ] = this.editItemToFilter;
                } else {
                    let vm = this;
                    let editduplicate = vm.selectedDataSourceInfo.filter(
                        (item) => {
                            return (
                                item.DataSourceId !=
                                vm.selectedProjectFiltered.DataSourceId
                            );
                        }
                    );
                    if (
                        editduplicate.length == vm.selectedDataSourceInfo.length
                    ) {
                        this.removeIndex = true;
                    }
                    editduplicate = editduplicate.filter((item) => {
                        return (
                            item.DataSourceId !=
                            vm.editItemToFilter.DataSourceId
                        );
                    });

                    var map = vm.mappingAttr.filter((item) => {
                        return (
                            item.DataSourceId !=
                            vm.editItemToFilter.DataSourceId
                        );
                    });
                    map = map.filter((item) => {
                        return (
                            item.DataSourceId !=
                            vm.selectedProjectFiltered.DataSourceId
                        );
                    });
                    vm.mappingAttr = map;
                    vm.selectedDataSourceInfo = editduplicate;
                    // this.removeIndex ? vm.selectedDataSourceInfo.push(this.selectedProjectFiltered): vm.selectedDataSourceInfo[vm.editIndex] = vm.selectedProjectFiltered;
                    vm.selectedDataSourceInfo.push(
                        this.selectedProjectFiltered
                    );
                    // vm.selectedDataSourceInfo[vm.editIndex] = vm.selectedProjectFiltered;
                }
            } else {
                let vm = this;
                var mapProject = vm.selectedDataSourceInfo.filter((item) => {
                    return (
                        item.DataSourceId !=
                        vm.selectedProjectFiltered.DataSourceId
                    );
                });
                this.selectedDataSourceInfo = mapProject;
                this.selectedDataSourceInfo.push(this.selectedProjectFiltered);
            }
            if (this.selected.dec) {
                for (var i = 0; i < this.selected.dec.length; i++) {
                    for (var j = 0; j < this.projectLegacyInfo.length; j++) {
                        if (
                            this.projectLegacyInfo[j].legacyprojectdesc ==
                            this.selected.dec[i]
                        ) {
                            this.decArr.push(
                                this.projectLegacyInfo[j].legacyprojectid
                            );
                        }
                    }
                }
            }
            var legacyProj = [...this.selected, ...this.decArr];

            this.decArr = [...new Set(legacyProj)];

            var mappingArr = this.decArr.map((item) => {
                return {
                    legacyprojectid: item,
                };
            });

            if (this.editIndex != null && !this.removeIndex) {
                this.selectedDataSourceInfo[
                    this.editIndex
                ].ProjectAttributeMappings = mappingArr;
                if (
                    this.selected.length == 0 &&
                    this.selected.dec.length == 0
                ) {
                    this.selectedDataSourceInfo[
                        this.editIndex
                    ].ProjectAttributeMappings = [
                        {
                            DataSourceId: this.selectedDataSourceInfo[
                                this.editIndex
                            ].DataSourceId,
                        },
                    ];
                }
            } else {
                this.selectedDataSourceInfo[
                    this.selectedDataSourceInfo.length - 1
                ].ProjectAttributeMappings = mappingArr;
                if (
                    this.selected.length == 0 &&
                    this.selected.dec.length == 0
                ) {
                    this.selectedDataSourceInfo[
                        this.selectedDataSourceInfo.length - 1
                    ].ProjectAttributeMappings = [
                        {
                            DataSourceId: this.selectedDataSourceInfo[
                                this.selectedDataSourceInfo.length - 1
                            ].DataSourceId,
                        },
                    ];
                }
            }
            this.removeIndex = false;
            this.editIndex = null;

            this.mappingArr();
        },
        addAdditionaldatasource() {
            this.projectInfoSection = true;
            this.projectEditInfo = false;
            this.selectedDataSource = null;
            this.isClicked1 = false;
        },
        editAdditionaldatasource(item, index) {
            this.projectInfoSection = true;
            this.projectEditInfo = false;
            this.editIndex = index;
            this.editItemToFilter = item;
            this.isClicked1 = true;
            this.selected.dec = [];
            // this.selectedDataSource = item.DataSourceId;
            var dataTest = this.datasources.filter((dataitem) => {
                return dataitem.DataSourceId == item.DataSourceId;
            });
            this.selected = item.ProjectAttributeMappings.map((item) => {
                return item.legacyprojectid;
            });
            this.selectedProjectFiltered = dataTest[0];
            this.selectedDataSource = this.selectedProjectFiltered.DataSourceId;
            this.checkSelected();
            this.check();
            // this.getLegacyProjectInfo(item);
            // this.handleBlur();
        },
        saveProject() {
            this.$v.project.$touch();
            if (this.$v.project.$anyError) {
                this.failureToastMessage =
                    'Not all required fields are complete. Please check all sections, then retry.';
                this.showFailure = true;
                this.showSuccess = false;
                this.connectingToast = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
                return;
            }

            this.filter = null;

            const data = {
                ProjectName: this.project.ProjectName,
                ProjectTypeID: this.project.ProjectTypeID,
                City: this.project.CityName,
                Comments: this.project.ProjectDesc,
                DefaultBaseMapID: this.project.DefaultBaseMapID,
                CountryID: this.project.CountryID,
                StateID: this.project.StateId,
                ProjectAttributeMappings: this.projectMappng,
                xcoord: this.project.Longitude,
                ycoord: this.project.Latitude,
            };
            projectService
                .createProject(data, false)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.selected = [];
                        this.selectedDec = [];
                        this.selectedDataSource = '';
                        this.allSelected = false;
                        this.allSelectedDec = false;
                        this.legecyProjectDescArray = [];
                        this.legecyProjectArray = [];
                        this.isClicked = false;
                        this.isClicked1 = false;
                        this.saveHeader = false;
                        this.manageHeader = true;
                        this.showFailure = false;
                        this.editMappings = [];
                        this.projectMappng = [];
                        this.projectMappngArr = [];
                        this.projectID = res.data.Data;
                        this.showSuccess = true;
                        this.successToastMessage =
                            'Project created successfully. Project Id is ' +
                            this.projectID;
                        let isERProject = false;
                        if (this.project.ProjectTypeID) {
                            let currentProjectTypeNameArr = this.projectTypeOptions.filter(
                                (type) => {
                                    return (
                                        type.ProjectTypeID ==
                                        this.project.ProjectTypeID
                                    );
                                }
                            );
                            if (
                                currentProjectTypeNameArr.length == 1 &&
                                currentProjectTypeNameArr[0].ProjectTypeName ==
                                    'Emergency response'
                            ) {
                                isERProject = true;
                            }
                        }
                        setTimeout(() => {
                            if (!isERProject) {
                                this.activatedTabIndex = 0;
                            }
                        }, 100);
                        setTimeout(() => {
                            this.showSuccess = false;
                            if (isERProject) {
                                this.activatedTabIndex = 1;
                                this.$bvModal.show('filemaker-upload');
                            }
                        }, 2000);
                    } else if (res.data.StatusCode === 500) {
                        this.showFailure = true;
                        this.showSuccess = false;
                        this.failureToastMessage = res.data.Message;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                })
                .catch((err) => {
                    if (err) {
                        this.showFailure = true;
                        this.showSuccess = false;
                        this.failureToastMessage = err.response.data.Message;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
        },
        editProject() {
            this.$v.editItem.$touch();
            if (this.$v.editItem.$anyError) {
                this.failureToastMessage =
                    'Not all required fields are complete. Please check all sections, then retry.';
                this.showFailure = true;
                this.showSuccess = false;
                this.connectingToast = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
                return;
            }
            this.saveHeader = true;
            this.manageHeader = false;
            const data = {
                ProjectID: this.editItem.ProjectID,
                ProjectTypeID: this.editItem.ProjectTypeID,
                ProjectName: this.editItem.ProjectName,
                City: this.editItem.City,
                ClientID: this.editItem.ClientID,
                Comments: this.editItem.Comments,
                Logo: this.editItem.Logo,
                DefaultBaseMapID: this.editItem.DefaultBaseMapID,
                CountryID: this.editItem.CountryID,
                StateID: this.editItem.StateID,
                ProjectAttributeMappings: this.projectMappng,
                IsActive: true,
                xcoord: this.editItem.XCoord,
                ycoord: this.editItem.YCoord,
            };
            if (this.editItem.isClone) {
                projectService.createProject(data, true).then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.afterProjectUpdated();
                    }
                });
            } else {
                projectService
                    .updateProject(this.editItem.ProjectID, data)
                    .then((res) => {
                        if (res.data.StatusCode === 202) {
                            this.afterProjectUpdated();
                        }
                    });
            }
        },
        afterProjectUpdated() {
            this.tabs.pop(
                this.tabs.findIndex(
                    (tab) => tab.ProjectID == this.editItem.ProjectID
                ),
                1
            );
            setTimeout(() => {
                this.activatedTabIndex = 0;
            }, 100);
            this.getAllProjects();
            this.saveHeader = false;
            this.manageHeader = true;
            this.showSuccess = true;
            this.showFailure = false;
            this.editMappings = [];
            this.projectMappng = [];
            this.projectMappngArr = [];
            this.successToastMessage = 'Project updated successfully';
            setTimeout(() => {
                this.showFailure = false;
                this.showSuccess = false;
            }, 5000);
        },
        searchiconclick() {
            document.querySelector('#searchFilter').style.width = '260px';
            this.showSearchIcon = false;
        },
        datasearchiconclick() {
            this.applyfilterDatasource = false;
            document.querySelector('#dataSourceSearch').style.width = '60%';
            document.querySelector('#dataSourceSearch').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#dataSourceSearch .e-input').style.display =
                'block';
        },
        filterCheckBox() {
            if (this.searchWord) {
                let vm = this;
                this.projectLegacyInfo = this.legacyoptions.filter((item) => {
                    return item.legacyprojectid
                        .toLowerCase()
                        .includes(vm.searchWord.toLowerCase());
                });
            } else {
                this.projectLegacyInfo = this.legacyoptions;
            }
        },
        filterCheckBoxDec() {
            if (this.searchWordDec) {
                let vm = this;
                this.projectLegacyInfo = this.legacyoptions.filter((item) => {
                    return item.legacyprojectdesc
                        .toLowerCase()
                        .includes(vm.searchWordDec.toLowerCase());
                });
            } else {
                this.projectLegacyInfo = this.legacyoptions;
            }
        },
        gridRowData: function(args) {
            if (args.commandColumn.type === 'Delete') {
                this.showModal(args.rowData);
            }
        },
        projectRowSelected(args) {
            this.isClicked = true;
            this.selectedSource = args.data.ProjectID;
            this.selectedItem = args.data;
            this.isAddProject = false;
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.projectlistSearch.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.projectlistSearch.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.filter = null;
            this.filterDataSearch = null;
            this.$refs.grid.$el.ej2_instances[0].search();
        },
        closeBarEdit() {
            this.searchCloseEdit = !this.searchCloseEdit;
            this.showSearchIconEdit = false;
            this.filterDataSearch = null;
        },
        searchOpenProject() {
            this.searchCloseEdit = !this.searchCloseEdit;
            this.showSearchIconEdit = true;
            this.$nextTick(() => {
                this.$refs.projectEdit.focus();
            });
        },
        closeBarAdd() {
            this.searchCloseAdd = !this.searchCloseAdd;
            this.showSearchIconAdd = false;
            this.filterDataSearch = null;
        },
        searchOpenProjects() {
            this.searchCloseAdd = !this.searchCloseAdd;
            this.showSearchIconAdd = true;
            this.$nextTick(() => {
                this.$refs.projectAdd.focus();
            });
        },

        searchinputenter(args) {
            if (args) {
                this.$refs.grid.$el.ej2_instances[0].search(
                    args.currentTarget.value
                );
                this.showSearchIcon = false;
                if (!this.filter) {
                    this.searchClose = false;
                }
            }
        },
        searchinputenterSource(args) {
            if (args) {
                this.applyfilterDatasource = false;
                this.showSearchIconEdit = false;
                this.showSearchIconAdd = false;
                if (!this.filterDataSearch) {
                    this.searchCloseEdit = false;
                    this.searchCloseAdd = false;
                }
            }
        },

        inputenter() {
            this.filter = '';
        },
        closeFilterDiv() {
            this.filterShow = false;
        },
        searchDatasource() {
            let vm = this;
            // if(this.filterDataSearch){
            this.datasources = this.datasourcesFiltered.filter((item) => {
                return item.DataSourceName.includes(vm.filterDataSearch);
            });
            // }
        },
        uploadFMTemplate(file) {
            const fileName = file.name;
            // var type = fileName.substring(fileName.length - 5);
            let type = fileName.split('.')[fileName.split('.').length - 1];
            if (type !== 'fmp12') {
                alert('Please upload a filemaker file. Extension .fmp12');
            } else {
                let data = new FormData();
                data.append('file', file);
                data.append('projectID', this.projectID);
                data.append('isPublic', true);
                data.append('uploadType', 'FileMaker');
                projectService
                    .UploadFMTemplate(data)
                    .then((res) => {
                        if (res.data.Message) {
                            let payload = {
                                url: res.data.Message,
                                label: fileName,
                            };
                            commonService.createQRCode(payload).then((res1) => {
                                commonService.base64ToDownload(
                                    res1.data,
                                    fileName.replace('fmp12', 'png')
                                );
                            });
                            this.successToastMessage =
                                this.project.ProjectName +
                                ' FileMaker template is uploaded, and downloading QR code.';
                            this.showSuccess = true;
                            this.showFailure = false;
                        }
                        this.$bvModal.hide('filemaker-upload');
                        this.newtab.pop();
                        this.getAllProjects();
                    })
                    .catch((err) => {});
                //filemaker file selected
            }
        },
        cancel() {
            this.$bvModal.hide('filemaker-upload');
        },
        setProjectExtent() {
            let payload = {
                projectId: this.selectedSource,
                minX: this.mapBounds._southWest.lng,
                minY: this.mapBounds._southWest.lat,
                maxX: this.mapBounds._northEast.lng,
                maxY: this.mapBounds._northEast.lat,
            };
            if (this.selectedSource) {
                managementService
                    .setProjectInitialExtent(payload)
                    .then((res) => {
                        if (res.data == true) {
                            alert('Saved!');
                            this.initialExtent = [
                                [payload.maxY, payload.minX],
                                [payload.maxY, payload.maxX],
                                [payload.minY, payload.maxX],
                                [payload.minY, payload.minX],
                            ];
                        }
                    })
                    .catch((err) => {
                        alert('Error');
                    });
            }
        },
        clearProjectExtent() {
            managementService
                .clearProjectInitialExtent(this.selectedSource)
                .then((res) => {
                    if (res.data == true) {
                        alert('Cleared!');
                        this.initialExtent = [];
                    }
                })
                .catch((err) => {
                    alert('Error');
                });
        },
        getProjectExtent() {
            if (this.selectedSource) {
                managementService
                    .getProjectInitialExtent(this.selectedSource)
                    .then((res) => {
                        console.log(res);
                        if (Object.keys(res.data).length > 0) {
                            this.initialExtent = [
                                [res.data.maxY, res.data.minX],
                                [res.data.maxY, res.data.maxX],
                                [res.data.minY, res.data.maxX],
                                [res.data.minY, res.data.minX],
                            ];
                        } else {
                            this.initialExtent = [];
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
    computed: {
        ...mapState({
            projects: (state) => state.projects.projects.items,
            deleteStatusCode: (state) => state.projects.remove.StatusCode,
        }),
        validated() {
            return (status) => {
                const { $dirty } = this.$v.project[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
        editValidated() {
            return (status) => {
                const { $dirty } = this.$v.editItem[status];
                if ($dirty && this.editValidateState(status) === true) {
                    return true;
                } else if ($dirty && this.editValidateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
        filteredItems() {
            let vm = this;
            if (this.filter) {
                return this.projects.filter((item) => {
                    return (
                        item.ProjectName.toLowerCase().indexOf(
                            vm.filter.toLowerCase()
                        ) > -1
                    );
                });
            } else {
                return this.projects;
            }
        },
        filteredDataSource() {
            let vm = this;
            if (this.filterDataSearch) {
                return this.datasources.filter((item) => {
                    return (
                        item.DataSourceName.toLowerCase().indexOf(
                            vm.filterDataSearch.toLowerCase()
                        ) > -1
                    );
                });
            } else {
                return this.datasources;
            }
        },
    },
};
</script>

<style scoped>
.addProject {
    padding: 11px 18px 16px 18px;
    cursor: pointer;
    background: #6e6e6e 0% 0% no-repeat padding-box;
    border-radius: 3px;
    width: 46px;
    height: 48px;
    padding-top: 13px;
}
.addProject:hover {
    background: #4c4c4c 0% 0% no-repeat padding-box;
}
.addProject:focus {
    background: #383838 0% 0% no-repeat padding-box;
}
.addProject:active {
    background: #383838 0% 0% no-repeat padding-box;
}

/* .cursor-pointer{
    cursor: pointer;
    color: #626262;
    font-size: 14px;
    font-family: var(--ff);
} */
.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}
.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: #006395 !important;
    border: none !important;
    text-align: center !important;
}
/* .buttonSelect {
  background-color: #006395;
  padding: 11px 19px;
  color: white;
} */
.normal_input {
    height: 48px !important;
    width: 100%;
}

input.searchinput {
    /* background: url("../../../assets/search-outline.svg") no-repeat !important;  border: none; */
    /* background: none !important; */
    margin-right: -151px;
    padding-left: 25px;
    border-bottom: 1px solid #ccc;
    width: 180px;
    margin-bottom: 10px;
    top: 3px;
    position: relative;
    cursor: pointer;
    z-index: 0;
}
input.searchinputList {
    /* background: url("../../../assets/search-outline.svg") no-repeat !important;  border: none; */
    background: none !important;
    margin-right: -151px;
    padding-left: 25px;
    /* border-bottom: 1px solid #ccc; */
    width: 180px;
    margin-bottom: 10px;
    top: 3px;
    position: relative;
    cursor: pointer;
    z-index: 0;
}
.filteredItem {
    /* background-color: #EAEAEA; */
    width: auto;
    padding: 0px 10x;
    margin-right: 10px;
}
select {
    appearance: none;
    border-radius: 0px;
    height: 48px !important;
    width: 499px;
    border-radius: 3px;
}
select.select_option {
    font-size: 16px !important;
    padding: 5px !important;
    width: 265px !important;
}
select.selectbox {
    border: 1px solid #c6c6c6;
    padding-left: 7px;
    color: -internal-light-dark(black, white);
}
.selectbox:hover {
    border: 1px solid #c6c6c6;
}
.innerTab {
    border: 1px solid #c6c6c6;
    width: 499px;
    height: auto !important;
    padding: 10px 10px 0 10px;
    background-color: white !important;
    /* margin-bottom:140px; */
}
.tabTextContent {
    background-color: #eaeaea !important;
    border: 15px solid #eaeaea;
    margin: 0px -10px;
    /* height:309px !important; */
}
.tabTextContent.legacyInfo.mrg-btm .list-group-item {
    margin: 10px 0;
    padding-right: 0;
}
.listGroup {
    overflow: auto;
    max-height: 338px;
}
.listGroup h5 {
    font: normal normal medium 18px/36px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000de;
}
.button button {
    background-color: #6e6e6e;
    margin: 20px 0px 120px 0px;
    width: 475px;
}
.legacyBox {
    width: 200px;
    /* border:1px solid #eaeaea; */
    padding: 10px;
}
.projectEditInfo {
    width: 499px;
}
/* .successToastMsg{
    position:absolute;
    bottom:50px;
    right:20px;
    background: white;
    height: 70px;
    width:350px;
    border-left: 6px solid #00DB52 ;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}

.failureToastMsg{
    position:absolute;
    bottom:50px;
    right:20px;
    background: white;
    height: 70px;
    width:350px;
    border-left: 6px solid #D23037 ;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
} */
button.deleteButton {
    background-color: #da1e27 !important;
    color: white;
    border-radius: 3px;
    border: 0;
}
/*search design code*/
.e-search-icon::before {
    content: '\e993';
    position: relative;
    top: -8px;
}
.dtSourceType {
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: 0.14px;
    color: #000000de;
    display: flex;
    min-width: 125px;
}
/* .saveProj {
  width: 131px;
  height: 48px;
  background: #006395 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal normal 16px/20px IBM Plex Sans;
  letter-spacing: 0.02px;
  color: #ffffff;
} */
.cancelProj {
    color: var(--ghd-006395);
    text-align: left;
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #006395;
    cursor: pointer;
}
/* .openProj {
  width: 127px;
  height: 48px;
  background: #006395 0% 0% no-repeat padding-box;
  border-radius: 3px;
  font: normal normal normal 16px/20px IBM Plex Sans;
  letter-spacing: 0.02px;
  color: #ffffff;
  position: relative;
} */
.clearSelect {
    font: normal normal normal 16px/20px IBM Plex Sans;
    letter-spacing: 0.02px;
    color: #006395;
    cursor: pointer;
    position: relative;
    float: inline-end;
    text-align: right;
}
#dataSourceSearch {
    border-right: 1px solid #e0e0e0;
    width: 35px;
    height: auto;
    border-bottom-color: transparent;
}
#dataSourceSearch span {
    border: 0 !important;
}
#dataSourceSearch .e-input {
    display: none;
}
.projectSearch {
    /* height: 66px; */
    padding: 12px 0;
    background-color: #fff;
    margin-left: 0;
    margin-right: 0;
    position: relative;
}
.add-block {
    top: 0px;
    position: relative;
    overflow: hidden;
}
.edit-block {
    position: relative;
}
.legacyInfo .filter-group {
    z-index: 999;
    position: absolute;
    left: 17%;
}
.list-group.listGroup {
    border-radius: 0 !important;
}
.list-group.listGroup .listItem {
    padding: 28px 15px !important;
}
.list-group.listGroup .listItem p {
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #1d1d1d;
}
.list-group.listGroup .listItem img {
    height: 27px;
    width: 27px;
}
.innerTab .tabs,
.innerTab .tabs .tab-content {
    height: 100%;
}
.additional {
    width: 498px;
    height: 48px;
    /* background: #6e6e6e; */
    border-radius: 3px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #ffffff;
}
/* .additional:hover{
    background-color: #6c757d !important;
} */
.addProject {
    border-radius: 3px;
    width: 137px;
    height: 48px;
}
.filter-block {
    left: 0 !important;
    top: 46px !important;
}
.filter-block.edit {
    top: 70px !important;
    left: 65px !important;
}
.input-lbl {
    display: grid;
}
.pointer {
    cursor: pointer;
    color: #006395;
}
.legacy .e-search-icon {
    top: 12px;
}
.removeFilter {
    cursor: pointer;
    margin: 4px 0 0 15px;
    font-size: 14px;
}
.removeFilter,
span {
    font-size: 14px;
    letter-spacing: 0.1px;
    /* vertical-align: top; */
}
.legacyInfo .clearSelect {
    right: 0 !important;
}
.filteredItemProject {
    letter-spacing: 0.1px;
    color: #000000de;
    height: 30px;
    background: #eaeaea 0% 0% no-repeat padding-box;
    border-radius: 2px;
    margin: 0 5px;
    display: flex;
}
.bi-x.b-icon.bi {
    margin-top: 3px;
}
input.test {
    border: 0 0 1px 0;
    z-index: 0;
}
.inner-search input.test {
    text-align: left;
}
input {
    border: 0;
}
.filterBox {
    z-index: 100;
    position: relative;
    cursor: pointer;
}
#flavors {
    height: 150px;
    overflow-y: auto;
}
.decList li {
    font: normal 16px/36px IBM Plex Sans;
    font-size: 100% !important;
}
.searchFilter {
    width: 100% !important;
    height: 48px;
    background-color: #f3f3f3 !important;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.16px;
    color: #626262;
    border: none !important;
    border-radius: 0 !important;
    margin-top: -10px;
    margin-right: 0 !important;
    top: 22px !important;
    background: #f3f3f3 url('../../../assets/search-outline.svg') 10px no-repeat !important;
    padding-left: 35px !important;
}
.btnEditDelete {
    width: 78px;
    height: 40px;
}
.btnEdit {
    background: #6e6e6e 0% 0% no-repeat padding-box;
    width: 95px;
    height: 40px;
}
.sub_header_bg img {
    margin-bottom: 0 !important;
}
.text-dark.lead {
    font-size: 18px;
    font-weight: 400 !important;
}
.dtSourceTitle {
    margin: auto 0;
}
.projTitle {
    color: #373a3c;
}
.font-16 {
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #373a3c;
}
.margin-auto {
    margin: auto 0;
}
.bg-grey-banner {
    background-color: #f9f9f9;
}
.input-lbl input {
    border: 0;
}
/* input.searchinput{
  background: url("../../../assets/search-outline.svg") no-repeat !important;  border: none;
  margin-right:-151px;
} */
.legacyProject {
    margin-left: 0px;
    width: 499px;
    margin-bottom: 10px;
}
.filterSource {
    border-radius: 3px;
    width: 265px !important;
    border: 1px solid #ced4da;
}
input.test {
    margin: 0 0px 0 0px !important;
    background: #f3f3f3 url(../../../assets/search-outline-grey.svg) no-repeat
        left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    /* //margin-right: -20px!important; */
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #e2e2e2;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.bgGrey {
    background-color: #e9ecef;
}
.filteringBy {
    height: auto;
    border: 1px solid #c6c6c6;
}
.height337 {
    height: 337px !important;
}
.loadingIcon {
    position: absolute;
    top: 150px;
    left: 100px;
    z-index: 2;
}
.loadingText {
    position: absolute;
    top: 180px;
    left: 72px;
    z-index: 2;
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem)
            calc(0.75em + 0.375rem) no-repeat !important;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #c6c6c6 !important;
    padding-right: calc(0.75em + 2.3125rem);
}
.model_style {
    max-width: 50% !important;
}
.layer-ord {
    height: 320px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.modal-body .list-group {
    height: 47px;
}
.modal-body .list-group:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
.modal-body .normal_input {
    height: 48px !important;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    background: no-repeat;
}
.badge {
    text-align: left;
    padding: 0 !important;
    border-top: 1px solid #dddddd;
    border-radius: 0px !important;
}
.badge.tree-input {
    padding-left: 0 !important;
}
.bdg-image {
    position: relative;
    left: 0px !important;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .inputbox {
        widows: 95% !important;
    }
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    border-radius: 3px;
    bottom: 20px;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.projExtent-buttons {
    width: 500px;
    display: flex;
    justify-content: space-between;
}

.clearExtent-Button {
    background: #6e6e6e 0% 0% no-repeat padding-box;
}
@media only screen and (max-width: 767px) {
    .projExtent-buttons {
        display: block;
    }
    .projExtent-buttons button {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}
</style>
