<template>
    <div class="outer-container">
        <div class="header-container">
            <div class="tab-container">
                <CommonTabsComponent :tabList="tabList"></CommonTabsComponent>
            </div>
            <div class="settings-container" @click="showSettings">
                <img
                    v-if="!settingsVisible"
                    src="../../../assets/settings.svg"
                    class="settings-img"
                    alt="Settings"
                />
                <img
                    v-else
                    src="../../../assets/settings-active.svg"
                    class="settings-img"
                    alt="Settings Active"
                />
                <span
                    class="img-text"
                    :class="{ 'text-active': settingsVisible }"
                    >Settings</span
                >
            </div>
        </div>
        <div
            class="settings-expand-container"
            :class="{
                'settings-expand-container-hide': !settingsVisible,
                'settings-expand-container-show': settingsVisible,
            }"
        >
            <div class="settings-item-container-parent">
                <div
                    v-for="(item, index) in settings"
                    :key="index"
                    class="settings-item-container"
                    :class="{ 'border-dotted': index % 2 === 1 }"
                >
                    <span class="stttings-name">{{ item.name }}</span>
                    <b-form-checkbox
                        class="settings-text-box"
                        v-model="item.isSelect"
                        :value="true"
                        :unchecked-value="false"
                        @change="saveProjSelectionType(settings)"
                    >
                    </b-form-checkbox>
                </div>
            </div>
        </div>
        <div class="search-container">
            <div class="search-box-container">
                <div class="search-box">
                    <img
                        src="../../../assets/search-outline.svg"
                        class="search-img"
                        alt="Search"
                    />
                    <div class="search-input-container">
                        <input
                            type="text"
                            v-model="searchText"
                            name="project-search-input"
                            placeholder="Search for projects"
                            class="search-input"
                        />
                    </div>
                </div>
                <div class="sort-input-row">
                    <span class="sort-label">Sort By:</span>
                    <b-form-select
                        v-model="sortOrder"
                        :options="sortOptions"
                        @change="sortProjects"
                    ></b-form-select>
                    <b-form-select
                        v-model="sortDirection[sortOrder]"
                        :options="sortDirectionOptions[sortOrder]"
                        @change="sortProjects"
                    ></b-form-select>
                </div>
            </div>

            <!-- <div class="filter-container">
                <img
                    src="../../../assets/filter1.svg"
                    class="filter-img"
                    alt="Filter"
                />
                <span class="filter-text">Filter</span>
            </div> -->
        </div>
        <div class="project-list-container">
            <div
                class="project-details-container"
                v-for="(item, index) in filteredList"
                :key="index"
                @click="addToSelectedProjects(item)"
                :class="{
                    'project-details-container-selected': isSelected(item),
                }"
            >
                <div class="project-details-info">
                    <span class="project-details-name">{{
                        item.ProjectName
                    }}</span>

                    <span class="project-details-location"
                        >{{ item.City }}, {{ item.StateName }},
                        {{ item.CountryName }}
                        <button
                            class="project-link"
                            @click.stop="copyProjectLink(item.ProjectID)"
                        >
                            <img
                                class="project-link-icon"
                                src="../../../assets/link-blue_icon_slanted.svg"
                                alt="Copy link to project"
                            />
                        </button>
                    </span>
                </div>
                <div v-if="isSelected(item)" class="project-details-indicator">
                    <img src="../../../assets/check-circle-icon.svg" />
                </div>
            </div>
        </div>
        <div
            class="bottom-btn-container d-flex flex-row justify-content-around px-2"
        >
            <span class="clear-text" @click="clearProjectSelections"
                >Clear selection</span
            >
            <b-button
                rounded
                class="buttonSelect open-project-btn"
                @click="openProjects()"
            >
                Open project(s)
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { mapLayerFunctions } from '../../../utilities/mapLayerFunctions';
import { projectService } from '../../../services/project.service';
import { dreamsFunctions } from '../../../utilities/dreamsFunctions';
export default {
    components: {
        CommonTabsComponent: () =>
            import(
                '../../../components/CommonComponents/CommonTabsComponent.vue'
            ),
    },
    data() {
        return {
            settingsVisible: false,
            searchText: '',
            settings: [
                { name: 'Allow multiple project selection', isSelect: false },
                //{ name: 'Additional settings', isSelect: false },
            ],
            tabList: [{ name: 'Active projects' }],
            sortOptions: ['Project Name', 'Date Created'],
            sortDirectionOptions: {
                'Project Name': ['A -> Z', 'Z -> A'],
                'Date Created': ['New -> Old', 'Old -> New'],
            },
            sortOrder: 'Project Name',
            sortDirection: {
                'Project Name': 'A -> Z',
                'Date Created': 'New -> Old',
            },
        };
    },
    methods: {
        ...mapActions('projects', {
            getAllProjects: 'getAllWithGeo',
            clearSelectedProjects: 'clearSelectedProjects',
            setSelectedProjects: 'selectProjects',
            removeSelectedProject: 'deselectProject',
        }),
        ...mapActions('emergencyManagement', {
            resetEMStore: 'resetStore',
        }),
        ...mapActions('dreams', ['clearDreams']),
        showSettings: function() {
            this.settingsVisible = !this.settingsVisible;
        },
        addToSelectedProjects(item) {
            projectService.getProjectLogo(item.ProjectID).then((res) => {
                item.Logo = res.data;
                let project = this.selectedProjectsArr.find((e) => {
                    return e.ProjectName === item.ProjectName;
                });
                if (project) {
                    this.removeSelectedProject(project.ProjectID);
                } else {
                    if (!this.settings[0].isSelect) {
                        this.clearSelectedProjects();
                        this.setSelectedProjects(item);
                    } else {
                        this.setSelectedProjects(item);
                    }
                }
            });

            this.clearProjectLayerInfo();
        },
        clearProjectSelections() {
            this.clearSelectedProjects();
            this.resetEMStore();
            this.$store.commit('gridFilters/setGroupedTables', {});
            this.$store.commit('projectLayers/clearMapData');
        },
        openProjects() {
            this.$store.commit('oneMap/mutateCloseSidebars', true);
            this.$emit('openProjects');
        },
        clearProjectLayerInfo() {
            this.resetEMStore();
            this.$store.commit('projectLayers/clearMapData');
        },
        saveProjSelectionType(settings) {
            this.$store.commit(
                'projects/mutateProjSelectionMultiple',
                settings[0].isSelect
            );
        },
        copyProjectLink(projectID) {
            let url = window.location.href.replace(
                /\?project[iI][dD]=[0-9]+/,
                ''
            );
            url += '?projectID=' + projectID;
            console.log(url);
            navigator.clipboard.writeText(url);
            alert('Project link copied to clipboard');
        },
    },
    watch: {
        ProjectId(newVal, OldValue) {
            this.clearDreams();
            dreamsFunctions.clearMap();
        },
    },
    computed: {
        ...mapState({
            allProjects: function(state) {
                return state.projects.projectsWithGeo;
            },
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
        filteredList() {
            let projectList = this.allProjects.items;
            if(projectList === undefined) {
                return [];
            }
            let sortedProjectList = projectList.sort((a, b) => {
                if (this.sortOrder === 'Project Name') {
                    if (this.sortDirection['Project Name'] === 'A -> Z') {
                        return a.ProjectName.localeCompare(b.ProjectName);
                    } else {
                        return b.ProjectName.localeCompare(a.ProjectName);
                    }
                } else {
                    if (this.sortDirection['Date Created'] === 'New -> Old') {
                        return b.ProjectID - a.ProjectID;
                    } else {
                        return a.ProjectID - b.ProjectID;
                    }
                }
            });
            if (this.searchText !== '') {
                return sortedProjectList.filter((proj) => {
                    return proj.ProjectName.toLowerCase().includes(
                        this.searchText.toLowerCase()
                    );
                });
            }
            return sortedProjectList;
        },
        isSelected() {
            return (item) =>
                this.selectedProjectsArr.findIndex(
                    (proj) => proj.ProjectName === item.ProjectName
                ) > -1;
        },
        ...mapGetters('projects', {
            ProjectId: 'ProjectID',
        }),
    },
};
</script>

<style scoped>
.outer-container {
    position: relative;
    float: left;
    height: calc(100vh - 67px);
    width: 100%;
    overflow: hidden;
    direction: ltr !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.header-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid #d1d1d1;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    z-index: 10;
}
.tab-container {
    position: relative;
    width: 75%;
    height: 59px;
}
.settings-container {
    position: relative;
    width: 25%;
    height: 59px;
    border-left: 1px solid #d1d1d1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.settings-img {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 11.75px;
    width: 19.5px;
    height: 19.5px;
}
.img-text {
    text-align: center;
    font-size: 10px;
    letter-spacing: 0px;
    color: #1d1d1d;
    opacity: 1;
}
.settings-expand-container {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    width: 100%;
    height: 70px;
    position: relative;
    float: left;
    overflow: hidden;
    transition: 0.5s linear;
}
.settings-expand-container-hide {
    opacity: 0;
    visibility: hide;
    margin-top: -70px;
}
.settings-expand-container-show {
    margin-top: 0px;
    opacity: 1;
    visibility: visible;
}
.tabContentClass {
    position: relative;
    float: left;
    margin-left: 20px;
    margin-top: 14px;
}
.text-active {
    color: #006395;
}
.settings-item-container {
    width: 90%;
    height: 50%;
}
.settings-item-container-parent {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    flex-direction: row;
    justify-content: center;
}
.stttings-name {
    position: relative;
    float: left;
    margin-top: 18px;
}
.border-dotted {
    border-top: 1px dashed #d1d1d1;
}
.settings-text-box {
    position: relative;
    float: right;
    margin-top: 20px;
}
.search-container {
    position: relative;
    float: left;
    width: 100%;
    height: 120px;
}
.search-box-container {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
}
.filter-container {
    position: relative;
    float: left;
    width: 27%;
    height: 100%;
}
.search-box {
    position: relative;
    width: 95%;
    height: 47px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
}
.search-img {
    position: relative;
    float: left;
    margin-left: 16px;
    margin-top: 11.75px;
}
.search-input-container {
    position: relative;
    float: right;
    width: 80%;
    margin-top: 7px;
    border-left: 1px solid #d1d1d1;
}

.search-input {
    position: relative;
    float: right;
    width: 95%;
    border: none;
    color: #777777;
}
.filter-img {
    position: relative;
    float: left;
    margin-left: 16px;
    margin-top: 40px;
    width: 18.42px;
    height: 17.11px;
}
.filter-text {
    position: relative;
    float: right;
    margin-right: 10px;
    margin-top: 35px;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 0.14px;
    color: #006395;
}
.project-list-container {
    position: relative;
    float: left;
    width: 100%;
    height: 79.58%;
    overflow: scroll;
}
.bottom-btn-container {
    align-self: flex-end;
    width: 100%;
    height: 100px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
}

.open-project-btn {
    position: relative;
    float: right;
    height: 48px;
    margin-top: 24px;
    font-size: 0.9rem;
    width: max-content !important;
    min-width: fit-content !important;
}

.clear-text {
    position: relative;
    float: left;
    width: 112px;
    height: 20px;
    margin-top: 38px;
    font: normal normal 600 16px/24px IBM Plex Sans;
    letter-spacing: 0.14px;
    color: #006395;
    cursor: pointer;
    font-size: 0.9rem;
}
.project-details-container {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    float: left;
    width: 100%;
    min-height: 108px;
    border: 1px solid #d1d1d1;
}
.project-details-container:hover {
    background: #0063951a;
    border: 1px solid #006395;
}
.project-details-container-selected {
    background: #0063951a;
    border: 1px solid #006395;
}
.project-details-name {
    position: relative;
    float: left;
    margin-top: 24px;
    margin-left: 20px;
    overflow-wrap: normal;
    font: normal normal 500 22px/30px IBM Plex Sans;
    letter-spacing: 0px;
    color: #212121;
    opacity: 1;
    margin-right: 44px;
}
.project-details-location {
    position: relative;
    float: left;
    margin-left: 20px;
    margin-right: 20px;
    font: normal normal normal 16px/32px IBM Plex Sans;
    letter-spacing: 0px;
    color: #555555;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding-right: 4px;
}
.project-details-indicator {
    right: 0px;
    position: absolute;
    margin-top: 7%;
    /* margin-bottom: auto; */
    margin-right: 20px;
}
.project-details-indicator img {
    float: right;
}

.project-details-info {
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    flex-flow: column wrap;
}
.project-link {
    justify-content: right;
    background-color: transparent;
    border: none;
    height: fit-content;
    width: fit-content;
    padding: 0;
    margin: 0;
}

.project-link-icon {
    height: 16px;
    width: 16px;
}

.sort-label {
    text-wrap: nowrap;
    margin-top: 4px;
    margin-right: 2px;
}

.sort-input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0px;
    width: 100%;
    height: 2.5em;
}
</style>
