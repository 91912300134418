<template>
    <!-- main Div  -->
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="chartelement" v-else>
        <b-button
            v-if="!generateGraph"
            @click="backtoOption()"
            class="mt-2 backbtn btn-post"
        >
            Back to graph options
        </b-button>
        <div v-bind:class="displayGraphOptions">
            <b-col class="text-right">
                <b-button
                    rounded
                    @click="goToScatter"
                    v-if="scatter"
                    class="buttonSelect m-none"
                    :disabled="xaxis == '' || yaxis == '' || series == ''"
                    >Generate graph</b-button
                >
            </b-col>
            <div v-if="hideheader != 1">
                <div>
                    <div>
                        <portal-bar-tool
                            :showReportingMenu="showReportingMenu"
                            :hidetableSelect="hidetableSelect"
                            @clickedFilter="clickedFilter"
                        ></portal-bar-tool>
                        <div>
                            <b-form-group>
                                <div
                                    class=" division chart-new col-lg-6 col-md-7 p-0"
                                >
                                    <h5 class="my-2 medium-text">
                                        Elements to graph
                                    </h5>
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'X-axis field',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                        v-bind:label="floatLabelEdit"
                                    >
                                        <b-form-select v-model="xaxis">
                                            <option
                                                v-for="ele in xaxisData"
                                                :key="ele"
                                                >{{ ele }}</option
                                            >
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                    <CustomFloatingLabel
                                        class="FloatingTool mb-0"
                                        :config="{
                                            label: 'Y-axis field',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            @change="
                                                minMaxInterval(yAxisChecked)
                                            "
                                            v-model="yaxis"
                                        >
                                            <template #first>
                                                <!-- <option value="">Y-axis field</option> -->
                                            </template>
                                            <option
                                                v-for="ele in yaxisData"
                                                :key="ele"
                                                >{{ ele }}</option
                                            >
                                        </b-form-select>
                                    </CustomFloatingLabel>

                                    <hr />
                                    <CustomFloatingLabel
                                        :config="{
                                            label: 'X-axis label',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                        class="mt-3"
                                    >
                                        <input
                                            v-model="axisDetails[0].axisAlias"
                                            name="yaxi"
                                            autocomplete="off"
                                        />
                                    </CustomFloatingLabel>

                                    <CustomFloatingLabel
                                        :config="{
                                            label: 'Y-axis label',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <input
                                            v-model="axisDetails[1].axisAlias"
                                            name="yaxi"
                                            autocomplete="off"
                                        />
                                    </CustomFloatingLabel>

                                    <button
                                        @click="goToXYAxisEdit"
                                        v-if="
                                            editTitleBlockBtn &&
                                                titleBlockDisplay
                                        "
                                        class="editblock mt-4"
                                    >
                                        Edit X-Y axis
                                    </button>

                                    <hr />

                                    <div class="yAxisRange">
                                        <!-- <b-form-checkbox @change="minMaxInterval" v-model="yAxisChecked"></b-form-checkbox> -->
                                        <div class="lblZeroAxis">Zero axis</div>
                                        <label class="switch mx-2">
                                            <input
                                                type="checkbox"
                                                v-model="yAxisChecked"
                                                :disabled="yaxis == ''"
                                                @click="
                                                    minMaxInterval(
                                                        !yAxisChecked
                                                    )
                                                "
                                            />
                                            <span
                                                :class="
                                                    yaxis == ''
                                                        ? 'sliderDisable'
                                                        : ''
                                                "
                                                class="slider round"
                                            ></span>
                                        </label>
                                        <CustomFloatingLabel
                                            class="mr-1 ml-4"
                                            :config="{
                                                label: 'Min',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <input
                                                type="number"
                                                v-model="rangeValues.minValue"
                                                autocomplete="off"
                                                class="normal_input"
                                            />
                                        </CustomFloatingLabel>
                                        <CustomFloatingLabel
                                            class="mx-1"
                                            :config="{
                                                label: 'Max',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <input
                                                type="number"
                                                v-model="rangeValues.maxValue"
                                                autocomplete="off"
                                                class="normal_input"
                                            />
                                        </CustomFloatingLabel>
                                        <CustomFloatingLabel
                                            class="mx-1"
                                            :config="{
                                                label: 'Interval',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <input
                                                type="number"
                                                min="0.01"
                                                step="0.01"
                                                oninput="validity.valid||(value='');"
                                                v-model="rangeValues.interval"
                                                autocomplete="off"
                                                class="normal_input"
                                            />
                                        </CustomFloatingLabel>
                                    </div>
                                    <!-- <b-form-select  v-model="series" @change="onChange($event)"> 

                    <b-form-select-option v-for="ele in seriesData" :key="ele" 
                      >{{ ele }}</b-form-select-option>
                  
    </b-form-select> -->

                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Series field',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="series"
                                            @change="
                                                onChange($event, index, 'main')
                                            "
                                        >
                                            <template #first>
                                                <!-- <option disabled value="">Series field</option> -->
                                            </template>
                                            <option
                                                v-for="ele in seriesData"
                                                :key="ele"
                                            >
                                                {{ ele }}</option
                                            >
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                    <!-- Gevindu -->
                                    <div
                                        class="select-container multipleSeries"
                                        v-for="(s, index) in elementSeries"
                                        :key="index"
                                    >
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Series field',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="s.value"
                                                class="small_drop"
                                                @change="
                                                    onChange(
                                                        $event,
                                                        index,
                                                        'rare'
                                                    )
                                                "
                                            >
                                                <option
                                                    selected
                                                    value=""
                                                    disabled
                                                    >Series</option
                                                >
                                                <option
                                                    @change="getSerisOpt"
                                                    :value="ele"
                                                    v-for="ele in seriesData"
                                                    :key="ele"
                                                    >{{ ele }}</option
                                                >
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <a
                                            class="delete"
                                            @click="deleteSeriesElement(index)"
                                        ></a>
                                    </div>
                                    <input
                                        type="text"
                                        class="normal_input headergroup"
                                        @click="addHeaderGrop()"
                                        value="Add another series field +"
                                        autocomplete="off"
                                    />
                                    <p></p>

                                    <h5 class="my-4 medium-text">
                                        Graph options
                                    </h5>
                                    <div
                                        v-if="graphOptions.length == 0"
                                        class="grey-txt"
                                    >
                                        Select elements to graph before
                                        selecting graph options.
                                    </div>

                                    <div v-else class="regular-text xy-check">
                                        Secondary axis scales

                                        <b-form-radio-group
                                            v-if="graphOptions.length > 0"
                                            v-model="selectedAxis"
                                            id="radio-group-axissds"
                                            @change="
                                                columnOpen = graph;
                                                onChangeCheckBox(index);
                                            "
                                            name="sub-component"
                                        >
                                            <b-form-radio
                                                v-model="selectedAxis"
                                                name="index-radio"
                                                :value="null"
                                                >No secondary axis</b-form-radio
                                            >
                                        </b-form-radio-group>
                                        <div
                                            v-for="(graph,
                                            index) in graphOptions"
                                            :key="index"
                                            class="pt-2"
                                        >
                                            <b-form-radio-group
                                                v-model="selectedAxis"
                                                id="radio-group-axis"
                                                @change="
                                                    columnOpen = graph;
                                                    onChangeCheckBox(index);
                                                "
                                                name="sub-component"
                                            >
                                                <b-form-radio
                                                    v-model="selectedAxis"
                                                    name="index-radio"
                                                    :value="graph"
                                                    >{{ graph }}</b-form-radio
                                                >
                                            </b-form-radio-group>
                                            <div
                                                v-if="
                                                    showradioCheckbox &&
                                                        checkboxOptions != 0 &&
                                                        columnOpen == graph
                                                "
                                                id="nav"
                                            >
                                                <ul class="xy-radio">
                                                    <li
                                                        v-for="(coption,
                                                        index) in checkboxOptions[
                                                            index
                                                        ]"
                                                        :key="index"
                                                    >
                                                        <b-form-checkbox
                                                            v-model="graphname"
                                                            @change="
                                                                secondarySelected(
                                                                    coption
                                                                )
                                                            "
                                                            class="cbox"
                                                            name="index"
                                                            :value="coption"
                                                        >
                                                            sxx {{ coption }}
                                                        </b-form-checkbox>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <hr />
                                        <h5 class="my-4 medium-text">
                                            Select an option to handle null
                                            values
                                        </h5>
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Null Handling',
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="nullHandler"
                                            >
                                                <option value="Zero"
                                                    >Plot nulls as zero</option
                                                >
                                                <option value="Drop">
                                                    Do not plot nulls, connect
                                                    line to the next valid point
                                                </option>
                                                <option value="Gap">
                                                    Do not plot nulls,
                                                    disconnect line between
                                                    valid points
                                                </option>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <hr />

                                        <!-- Gevindu -->
                                        <h5 class="my-4 medium-text">
                                            Select column to show multiple
                                            graphs
                                        </h5>
                                        <div
                                            class="select-container multipleSeries"
                                            v-for="(s,
                                            index) in multipleGraphsArray"
                                            :key="index"
                                        >
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Multiple graphs',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="s.value"
                                                >
                                                    <template #first>
                                                        <option value="null"
                                                            >No multiple
                                                            graphs</option
                                                        >
                                                    </template>
                                                    <option
                                                        v-for="ele in seriesData"
                                                        :key="ele"
                                                    >
                                                        {{ ele }}</option
                                                    >
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                            <a
                                                class="delete"
                                                v-if="index > 0"
                                                @click="
                                                    deleteMultiGraphCols(index)
                                                "
                                            ></a>
                                        </div>

                                        <input
                                            type="text"
                                            class="normal_input headergroup"
                                            @click="addMultigraphCol()"
                                            value="Add another column +"
                                            autocomplete="off"
                                        />
                                        <hr />
                                        <b-form-checkbox
                                            v-model="status"
                                            class="cbox pl-4"
                                        >
                                            Logarithmic scale
                                        </b-form-checkbox>
                                    </div>

                                    <br />
                                    <h5 class="medium-text">
                                        Title block preview
                                    </h5>
                                    <div class="grey-txt">
                                        Default settings have been applied.
                                    </div>
                                    <div class="grey-txt">
                                        To modify the style, select "edit title
                                        block".
                                    </div>
                                    <b-row>
                                        <div
                                            v-if="titleBlock[0].text"
                                            id="editBlock"
                                            class="block-2 mt-4"
                                        >
                                            <div class="preview_logo">
                                                <div
                                                    class="side_block pb-0 pt-3 px-3 row m-0 d-flex justify-content-between"
                                                >
                                                    <div
                                                        class="col-xs-4 col-xs-offset-4 vmiddle w-100-left"
                                                    >
                                                        <img
                                                            :src="blobPath"
                                                            class="img_title"
                                                            id="scatterImage"
                                                            v-if="blobPath"
                                                        />
                                                    </div>
                                                    <div class="col">
                                                        <div
                                                            class="line_block -xs-4"
                                                            v-for="(item,
                                                            index) in titleBlock"
                                                            :key="index"
                                                        >
                                                            <div
                                                                v-bind:style="{
                                                                    fontSize:
                                                                        item.textSize +
                                                                        'px',
                                                                    color:
                                                                        item.textColor,
                                                                    fontFamily:
                                                                        item.textType,
                                                                    fontWeight:
                                                                        item.textStyle,
                                                                }"
                                                                :id="
                                                                    'previewDatasetGraph' +
                                                                        index
                                                                "
                                                            >
                                                                {{ item.text }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="footer-section bor-t-1"
                                                    >
                                                        <div
                                                            class="d-flexbox float-left"
                                                        >
                                                            {{ footerText }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-row>
                                    <button
                                        @click="gotoTitleblock"
                                        v-if="
                                            editTitleBlockBtn &&
                                                titleBlockDisplay
                                        "
                                        class="editblock mt-4"
                                    >
                                        Edit title block
                                    </button>

                                    <h5 class="mt-4 medium-text">
                                        Template formats
                                    </h5>
                                    <p class="mb-3">Page layout</p>
                                    <b-form-radio-group
                                        id="radio-group-1"
                                        class="bottom-p-125"
                                        v-model="selectedLayout"
                                        name="radio-sub-component-2"
                                    >
                                        <b-form-radio
                                            name="some-radios-2"
                                            v-model="selectedRadio"
                                            value="portrait"
                                            >Portrait</b-form-radio
                                        >
                                        <b-form-radio
                                            name="some-radios-2"
                                            v-model="selectedRadio"
                                            value="landscape"
                                            >Landscape</b-form-radio
                                        >
                                    </b-form-radio-group>
                                    <!-- <hr/>
      <p>Graphs per page</p>
      <b-form-radio name="some-radios" value="A">2</b-form-radio>
      <b-form-radio name="some-radios" value="A">4</b-form-radio>
      <b-form-radio name="some-radios" value="B">6</b-form-radio>
     <p></p>
        <h5 class="mt-4 medium-text">Export options</h5>
        <b-form-checkbox
      id="checkbox-1"
      class="cbox my-2"
      v-model="status"
      name="checkbox-1"
      unchecked-value="not_accepted"
    >
     Save as PDF
    </b-form-checkbox>
     <b-form-checkbox
      id="checkbox-2"
      v-model="selected"
       class="cbox my-2"
      name="checkbox-2"
      unchecked-value="not_accepted"
    >
     Save as Excel workbook
    </b-form-checkbox>
     <b-form-checkbox
      id="checkbox-3"
      v-model="select"
       class="cbox my-2"
      name="checkbox-3"
      unchecked-value="not_accepted"
    >
       View in browser window <a href="#"><img src="../../assets/export-icon.png" /></a>
    </b-form-checkbox>-->
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="scatterPlotDiv" v-if="displayGraph">
            <scatter-plot
                :logarithamic="status"
                :orientation="selectedRadio"
                :secondaryAxis="secondaryAxisSelections"
                :graphDetails="graphDetails"
                :seriesMultiple="seriesMultiple"
                :filteredColumnId="filteredColumnId"
                :columnNameSeries="columnNameSeries"
                :axisDetails="axisDetails"
                :nullHandler="nullHandler"
            ></scatter-plot>
        </div>
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool ENDS BELOW -->
    </div>
    <!-- Main Div Of this Component end below -->
</template>
<script>
import router from '../../router';
//import { mapState, mapActions } from "vuex";
import { datasetService } from '../../services/dataset.service';
import { reportingService } from '../../services/reporting.service';
import { mapActions } from 'vuex';
import PortalBarTool from '../PortalBarTool';
import AccessPermissionModal from '../AccessPermissionModal';
import CustomFloatingLabel from '../CustomFloatingLabel';
import ScatterPlot from './scatterPlot.vue';

export default {
    components: {
        PortalBarTool,
        CustomFloatingLabel,
        AccessPermissionModal,
        ScatterPlot,
    },
    name: 'chartelement',

    props: {
        showReportingMenu: Boolean,
        hidetableSelect: Boolean,
        currentSwitchState: Boolean,
        backFromGraph: Boolean,
        titleBlockDisplay: Boolean,
        filteredColumnId: Object,
        axisDetails: Object,
    },
    data() {
        return {
            graph: null,
            hideheader: 0,
            datasetname: '',
            isClicked: false,
            status: false,
            // currentSwitchState: false,
            elements: [],
            datasourceId: '',
            tablename: '',
            blobPath: '',
            selected: null,
            yaxis: '',
            xaxis: '',
            series: '',
            elementSeries: [],
            styleSizeData: [],
            multipleGraphsArray: [{ value: null }],
            graphOptions: [],
            titleBlock: [
                {
                    text: '',
                    dataSet: [],
                    textSize: '',
                    textStyle: '',
                    textColor: '',
                    textType: '',
                    styleGHD: false,
                    TitleId: '',
                    BlockDataset: '',
                    BlockId: '',
                    DatasetId: '',
                    StyleId: '',
                },
            ],
            footerText: '',
            editBlock: '',
            showradioCheckbox: false,
            checkboxOptions: [],
            yaxisData: [],
            xaxisData: [],
            seriesData: [],
            secondaryAxisSelections: [],
            selectedRadio: 'portrait',
            thisfilename: null,
            selectedLayout: 'portrait',
            showPage: false,
            editTitleBlockBtn: false,
            scatter: false,
            clickedFilterState: false,
            yAxisChecked: true,
            // minValue: null,
            // maxValue: null,
            // interval: null,
            rangeValues: {},
            seriesExtra: [],
            filterOptions: JSON.parse(localStorage.getItem('gridFilter')),
            columnData: [],
            columnOpen: null,
            graphname: [],
            logarithamic: false,
            generateGraph: true,
            graphDetails: {},
            selectedAxis: null,
            columnNameSeries: {},
            nullHandler: 'Zero',
        };
    },
    created() {
        // this.pagePermit();
        this.hideheader = localStorage.getItem('headerHide');
        this.datasourceId = localStorage.getItem('datasourceId');
        this.tablename = localStorage.getItem('tablename');
        //this.graphictool = localStorage.getItem('graphingtool');
        //
        this.getElements();
        this.getTitleBlock();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.IsLandScape) {
                this.selectedRadio = 'landscape';
                this.selectedLayout = 'landscape';
            }
        }
    },
    // updated(){
    //     this.editBlock = document.getElementById("editBlock").innerHTML;
    //     localStorage.setItem('editBlock', this.editBlock);
    // },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        getBrowserType() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (
                (navigator.userAgent.indexOf('Edg') ||
                    navigator.userAgent.indexOf('Edge')) != -1
            ) {
                return 'Edge';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                //IF IE > 10
                return 'IE';
            } else {
                return 'unknown';
            }
        },
        print() {
            this.editBlock = !document.getElementById('editBlock')
                ? ''
                : document.getElementById('editBlock').innerHTML;
            this.graphDetails = {
                xaxisdata: this.xaxis,
                yaxisdata: this.yaxis,
                seriesdata: this.graphOptions.toString(),
                minValue: this.rangeValues.minValue,
                maxValue: this.rangeValues.maxValue,
            };
            const modal = document.getElementById('scatterPlotDiv');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');
            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }

            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            var userBrowser = this.getBrowserType();
            var topMargin = '0';
            var leftMargin = '0';
            switch (userBrowser) {
                case 'Chrome':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
                case 'Firefox':
                    topMargin = '0.5in';
                    leftMargin = '0';
                    break;
                case 'Edge':
                    topMargin = '0.1in';
                    leftMargin = '0.1in';
                    break;
            }
            css = '';
            if (this.selectedRadio == 'portrait') {
                css =
                    '@page { size: portrait; } @media print {.hide-graph{display: block;} #print .chartcontainer {transform: translateY(18%) !important;}';
            } else {
                css +=
                    '@page { size: landscape; } @media print {.hide-graph{display: block;} #print .chartcontainer {transform: translateY(11%) !important;}';
            }
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            setTimeout(function() {
                window.print();
            }, 500);
        },
        pagePermit() {
            this.showPage = true;
            this.editTitleBlockBtn = true;
            this.scatter = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('GenerateChartElement')) {
                    vm.showPage = true;
                }
                if (element.PageName.includes('TitleBlock')) {
                    vm.editTitleBlockBtn = true;
                }
                if (element.PageName.includes('scatterPlot')) {
                    vm.scatter = true;
                }
            });
            // this.$emit('clickedGenerateChart', this.showPage);
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        clickedFilter(value) {
            this.clickedFilterState = !value;
        },
        minMaxInterval(yAxisChecked) {
            this.rangeValues = {};
            if (this.yaxis != '') {
                datasetService
                    .getminMaxInterval(
                        localStorage.getItem('datasetid'),
                        this.yaxis,
                        yAxisChecked,
                        this.filterOptions
                    )
                    .then((res) => {
                        this.rangeValues = {
                            minValue: res.data[0],
                            maxValue: res.data[1],
                            interval: res.data[2],
                        };
                    });
            } else {
                this.rangeValues.minValue = this.rangeValues.maxValue = this.rangeValues.interval =
                    '';
            }
        },
        getElements() {
            datasetService
                .getDataforChartelement(localStorage.getItem('datasetid'))
                .then((res) => {
                    this.elements = res.data;
                    this.elements.forEach((element) => {
                        if (element.AttributeType == 'Default') {
                            if (
                                element.ToolAttribute.AttributeName === 'Y-axis'
                            ) {
                                this.yaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'X-axis'
                            ) {
                                this.xaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'Series'
                            ) {
                                this.seriesData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                                if (element.DatasetColumn.ColumnAlias) {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnAlias
                                    ] = element.DatasetColumn.ColumnName;
                                } else {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnName
                                    ] = element.DatasetColumn.ColumnName;
                                }
                            }
                        }
                    });
                    this.elements.forEach((element) => {
                        if (element.AttributeType != 'Default') {
                            if (
                                element.ToolAttribute.AttributeName === 'Y-axis'
                            ) {
                                this.yaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'X-axis'
                            ) {
                                this.xaxisData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                            } else if (
                                element.ToolAttribute.AttributeName === 'Series'
                            ) {
                                this.seriesData.push(
                                    element.DatasetColumn.ColumnAlias
                                        ? element.DatasetColumn.ColumnAlias
                                        : element.DatasetColumn.ColumnName
                                );
                                if (element.DatasetColumn.ColumnAlias) {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnAlias
                                    ] = element.DatasetColumn.ColumnName;
                                } else {
                                    this.columnNameSeries[
                                        element.DatasetColumn.ColumnName
                                    ] = element.DatasetColumn.ColumnName;
                                }
                            }
                        }
                    });
                    this.yaxisData = [...new Set(this.yaxisData)];
                    this.xaxisData = [...new Set(this.xaxisData)];
                    this.seriesData = [...new Set(this.seriesData)];
                    // if(this.backFromGraph){
                    //   let grphdetails = JSON.parse(localStorage.getItem('graphDetails'));
                    //   this.elementSeries = JSON.parse(localStorage.getItem("Elements"));
                    //   this.xaxis = grphdetails.xaxisdata;
                    //   this.yaxis = grphdetails.yaxisdata;
                    //   this.series = localStorage.getItem("series");
                    //   this.graphOptions = JSON.parse(localStorage.getItem("graphOptions"));
                    //   this.checkboxOptions = JSON.parse(localStorage.getItem("checkboxOptions"));
                    //   this.secondaryAxisSelections = JSON.parse(localStorage.getItem("secondaryAxis"));

                    //   this.yAxisChecked = localStorage.getItem("yAxisChecked");
                    //   // this.minMaxInterval(this.yAxisChecked);
                    //   this.rangeValues = JSON.parse(localStorage.getItem("rangeValues"));
                    //   this.selectedRadio = localStorage.getItem("selectedRadio");
                    //   this.radioSelected(1);
                    //   this.columnOpen = localStorage.getItem("columnOpen");
                    //   this.graphname = JSON.parse(localStorage.getItem("graphname"));
                    //   this.graph="Location";
                    //   this.status = localStorage.getItem("logarithamic");
                    //   this.backFromGraph = false;
                    // }else{
                    this.xaxis = this.xaxisData[0];
                    this.yaxis = this.yaxisData[0];
                    this.series = this.seriesData[0];
                    this.graphOptions[0] = this.seriesData[0];
                    datasetService
                        .GetDistinctFilteredvalues(
                            localStorage.getItem('datasetid'),
                            this.seriesData[0],
                            this.filterOptions
                        )
                        .then((res) => {
                            this.columnData = res.data;
                            this.checkboxOptions[0] = this.columnData;
                            this.checkboxOptions[0].forEach(
                                (item) => (this.cboxOptions = item.graphname)
                            );
                        })
                        .catch((err) => {
                            if (
                                err.response.data.Message ==
                                'ForbiddenSQLInjection'
                            ) {
                                this.$emit(
                                    'resForbidden',
                                    err.response.data.Message
                                );
                            }
                        });
                    let loadcheck = true;
                    this.minMaxInterval(loadcheck);
                    // }
                });
        },
        addHeaderGrop() {
            this.elementSeries.push({ value: '' });
            // this.elementSeries.push(this.series++);
        },
        addMultigraphCol() {
            this.multipleGraphsArray.push({ value: null });
        },
        deleteSeriesElement(id) {
            // this.graphOptions.splice(id);
            this.elementSeries.splice(id, 1);
            this.graphOptions.splice(id + 1, 1);
            this.checkboxOptions.splice(id + 1, 1);
            //
        },
        deleteMultiGraphCols(id) {
            this.multipleGraphsArray.splice(id, 1);
        },
        getSerisOpt(evt) {},
        onChangeCheckBox(evt) {
            if (this.selectedAxis === null) {
                this.checkboxUnselected(evt);
            } else {
                this.radioSelected(evt);
            }
        },
        radioSelected(evt) {
            this.selectedRadio = this.selectedLayout;
            localStorage.setItem('scaleIndex', evt);
            this.showradioCheckbox = true;
            this.secondaryAxisSelections = [];
            for (let p in this.checkboxOptions[0]) {
                this.graphname.splice(p, 1);
            }
        },
        checkboxUnselected(evt) {
            localStorage.setItem('scaleIndex', 0);
            this.showradioCheckbox = false;
            for (let p in this.checkboxOptions[0]) {
                this.graphname.splice(p, 1);
            }
        },
        secondarySelected(evt) {
            if (this.secondaryAxisSelections.indexOf(evt) > -1) {
                this.secondaryAxisSelections.splice(
                    this.secondaryAxisSelections.indexOf(evt),
                    1
                );
            } else {
                this.secondaryAxisSelections.push(evt);
            }
        },
        onChange(event, index, type) {
            // this.graphOptions.push(event);
            if (type == 'main') {
                this.graphOptions[0] = event;
                datasetService
                    .GetDistinctFilteredvalues(
                        localStorage.getItem('datasetid'),
                        event,
                        this.filterOptions
                    )
                    .then((res) => {
                        this.columnData = res.data;
                        this.checkboxOptions[0] = this.columnData;
                        this.checkboxOptions[0].forEach(
                            (item) => (this.cboxOptions = item.graphname)
                        );
                        // for (let p in this.checkboxOptions[0]) {
                        //   this.graphname.push(this.checkboxOptions[0][p]);
                        // }
                    });
            } else {
                this.graphOptions[index + 1] = event;
                datasetService
                    .GetDistinctFilteredvalues(
                        localStorage.getItem('datasetid'),
                        event,
                        this.filterOptions
                    )
                    .then((res) => {
                        this.columnData = res.data;
                        this.checkboxOptions[index + 1] = this.columnData;
                        this.checkboxOptions[index + 1].forEach(
                            (item) => (this.cboxOptions = item.graphname)
                        );
                        // for (let p in this.checkboxOptions[index+1]) {
                        //   this.graphname.push(this.checkboxOptions[index+1][p]);
                        // }
                    });
            }
            this.$forceUpdate();
        },

        getCodesGraphLocation() {
            datasetService
                .filterDataset()
                .then((res) => (this.siteOptions = res.data));
        },
        getTitleBlock() {
            this.titleBlock = [
                {
                    text: '',
                    dataSet: [],
                    textSize: '',
                    textStyle: '',
                    textColor: '',
                    textType: '',
                    styleGHD: false,
                    TitleId: '',
                    BlockDataset: '',
                    BlockId: '',
                    DatasetId: '',
                    StyleId: '',
                },
            ];
            reportingService
                .getTitleBlock(localStorage.getItem('datasetid'))
                .then((res) => {
                    this.titleBlockData = res.data;

                    if (this.titleBlockData.length == 0) {
                        localStorage.removeItem('editBlock');
                    } else {
                        this.titleBlockData.forEach((element, index) => {
                            if (this.titleBlock.length < 5) {
                                if (index === 0) {
                                    this.footerText = element.FooterText;
                                    this.titleBlock[index].text =
                                        element.BlockText;
                                    this.blobPath = element.BlobPath
                                        ? element.BlobPath
                                        : '';
                                    this.titleBlock[index].textSize =
                                        element.FontSize.Size;
                                    this.titleBlock[index].textStyle =
                                        element.FontStyle.Style;
                                    this.titleBlock[index].textColor =
                                        element.StyleColor;
                                    this.titleBlock[index].textType =
                                        element.FontType.Type;
                                    this.titleBlock[index].TitleBlockId =
                                        element.TitleBlockId;
                                    this.titleBlock[index].BlockDataset =
                                        element.BlockDataset;
                                    this.titleBlock[index].DatasetId =
                                        element.DatasetId;
                                    this.titleBlock[index].StyleId =
                                        element.StyleId;
                                    this.titleBlock[index].styleGHD =
                                        element.FontType.IsDefault &&
                                        element.FontStyle.IsDefault &&
                                        element.FontSize.IsDefault
                                            ? true
                                            : false;
                                } else {
                                    this.titleBlock.push({
                                        text: element.BlockText,
                                        dataSet: [],
                                        textSize: element.FontSize.Size,
                                        textStyle: element.FontStyle.Style,
                                        textColor: element.StyleColor,
                                        textType: element.FontType.Type,
                                        styleGHD:
                                            element.FontType.IsDefault &&
                                            element.FontStyle.IsDefault &&
                                            element.FontSize.IsDefault
                                                ? true
                                                : false,
                                    });
                                }

                                let datasetString = element.BlockText.match(
                                    /<<[\w\d]+>>/g
                                );
                                let datasetString1 = element.BlockText.match(
                                    /<<[\w\d]+>>/g
                                );

                                if (datasetString) {
                                    for (
                                        let r = 0;
                                        r < datasetString.length;
                                        r++
                                    ) {
                                        datasetString[r] = datasetString[
                                            r
                                        ].replace(/[<>]/g, '');
                                    }

                                    let previewContent = element.BlockText;

                                    for (
                                        let v = 0;
                                        v < datasetString.length;
                                        v++
                                    ) {
                                        datasetService
                                            .GetDistinctFilteredvalues(
                                                localStorage.getItem(
                                                    'datasetid'
                                                ),
                                                datasetString[v],
                                                this.filterOptions
                                            )
                                            .then((res) => {
                                                let columnData = res.data;
                                                previewContent = previewContent.replace(
                                                    datasetString1[v],
                                                    '<span id="new_btn' +
                                                        index +
                                                        v +
                                                        '" class="new_btn' +
                                                        index +
                                                        v +
                                                        '" contenteditable="false">' +
                                                        columnData.join(', ') +
                                                        '</span>'
                                                );
                                                document.getElementById(
                                                    'previewDatasetGraph' +
                                                        index
                                                ).innerHTML = previewContent;
                                            })
                                            .catch((err) => {
                                                if (
                                                    err.response.data.Message ==
                                                    'ForbiddenSQLInjection'
                                                ) {
                                                    this.$emit(
                                                        'resForbidden',
                                                        err.response.data
                                                            .Message
                                                    );
                                                }
                                            });
                                    }
                                }
                            }
                        });
                    }
                });
        },
        gotoTitleblock() {
            //  router.push(`/titleblock/18');
            this.$emit('currentEditTitleState', this.clickedFilterState);
            this.$root.$refs.B.editTitleblockTabOpen();
        },
        goToXYAxisEdit() {
            this.$emit('currentEditTitleState', this.clickedFilterState);
            this.$root.$refs.B.editXYAxisTabOpen();
        },
        backtoOption() {
            this.generateGraph = true;
            setTimeout(() => {
                if (document.getElementById('editBlock'))
                    document.getElementById(
                        'editBlock'
                    ).innerHTML = localStorage.getItem('editBlock')
                        ? localStorage.getItem('editBlock')
                        : '';
            }, 2000);
            this.$emit('generateGraph', this.generateGraph);
            let radioSelect = localStorage.getItem('scaleIndex');
            this.radioSelected(radioSelect);
        },
        goToScatter() {
            this.editBlock = !document.getElementById('editBlock')
                ? ''
                : document.getElementById('editBlock').innerHTML;
            localStorage.setItem('editBlock', this.editBlock);
            this.generateGraph = false;
            this.$emit('generateGraph', this.generateGraph);

            localStorage.setItem(
                'Elements',
                JSON.stringify(this.elementSeries)
            );
            localStorage.setItem('series', this.series);
            localStorage.setItem(
                'graphOptions',
                JSON.stringify(this.graphOptions)
            );
            localStorage.setItem(
                'checkboxOptions',
                JSON.stringify(this.checkboxOptions)
            );
            localStorage.setItem('columnOpen', this.columnOpen);
            // localStorage.setItem("logarithamic",this.status);
            localStorage.setItem('graphname', JSON.stringify(this.graphname));
            localStorage.setItem('yAxisChecked', this.yAxisChecked);
            localStorage.setItem(
                'rangeValues',
                JSON.stringify(this.rangeValues)
            );
            localStorage.setItem('selectedRadio', this.selectedRadio);
            //this.secondaryAxisSelections.push(this.graphname);
            this.graphDetails = {
                xaxisdata: this.xaxis,
                yaxisdata: this.yaxis,
                seriesdata: this.graphOptions.toString(),
                minValue: this.rangeValues.minValue,
                maxValue: this.rangeValues.maxValue,
            };
            localStorage.setItem('minValue', this.rangeValues.minValue);
            localStorage.setItem('maxValue', this.rangeValues.maxValue);
            localStorage.setItem('interval', this.rangeValues.interval);
            //localStorage.setItem("graphDetails", JSON.stringify(graphDetails));
            //  localStorage.setItem("xaxisdata", this.xaxis);
            //  localStorage.setItem("yaxisdata", this.yaxis);
            //  localStorage.setItem("seriesdata", this.graphOptions);
            localStorage.setItem('orientation', this.selectedRadio);
            //  localStorage.setItem("secondaryAxis",JSON.stringify(this.secondaryAxisSelections));

            // router.push(`/scatterPlot');
        },
    },
    computed: {
        // diffSeries(){
        //   let dSeries = [];
        //   dSeries.push(this.series);
        //   for(let m in this.elementSeries){
        //     if(this.elementSeries[m].value != ""){
        //       if(this.elementSeries[m].value != this.series){
        //         dSeries.push(this.elementSeries[m].value);
        //       }
        //     }
        //   }
        //   dSeries = ([...new Set(dSeries)])
        //   return dSeries;
        // }
        seriesMultiple() {
            let multiGrapArr = [];
            this.multipleGraphsArray.forEach((col) => {
                if (col.value != null) {
                    multiGrapArr.push(col.value);
                }
            });
            return multiGrapArr;
        },
        displayGraph() {
            if (this.generateGraph) {
                return false;
            } else {
                return true;
            }
        },
        displayGraphOptions() {
            if (!this.generateGraph) {
                return 'hide-graph';
            } else {
                return 'show-graph';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-layouts/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-grids/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-vue-filemanager/styles/bootstrap4.css';
.inputbox {
    width: 499px;
    height: 48px;
}
.custom-select {
    background: #fff url('../../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.normal_input {
    height: 48px !important;
    border-radius: 3px;
}

.hide-graph {
    display: none;
}

.show-graph {
    display: block;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;

    width: 500px;
    text-align: center;
    cursor: pointer;
}
.custom-control {
    display: inline;
    margin-right: 30px;
}
.cbox {
    display: block !important;
    line-height: 27px;
    padding: 10px 5px 10px 50px;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    //margin-right: 57px;
    margin-top: 0px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}
.small_drop {
    width: 440px;
}
/* style for title block preview */
.block-2 {
    .preview_logo {
        .side_block {
            // border: 1px solid #dee2e6;
            display: block;
            border-radius: 3px;
            min-height: 75px;
            height: auto;
            width: 499px;
            position: relative;
            float: left;
            box-shadow: 0 1px 1px 1px #dee2e6 !important;
            .img_title {
                width: 50px;
                height: 50px;
                object-fit: contain;
            }
            .line_block {
                text-align: right;
                span {
                    font-size: 14px;
                    padding-top: 10px;
                    padding-right: 5px;
                }
            }
            .footer-section {
                border-top: 1px solid #dee2e6;
                height: auto;
                position: relative;
                bottom: 0;
                width: 100%;
                span {
                    text-align: left;
                    font-size: 12px;
                }
                span.center {
                    text-align: center;
                }
            }
        }
    }
}

.control-section .block-2 {
    .preview_logo {
        .side_block {
            // border: 1px solid #dee2e6;
            display: block;
            border-radius: 3px;
            min-height: 75px;
            height: auto;
            width: 499px;
            box-shadow: none !important;
            .line_block {
                text-align: right;
                span {
                    font-size: 14px;
                    padding-top: 10px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.editblock {
    background-color: #006395 !important;
    padding: 11px 10px !important;
    border-radius: 3px;
    border: 0px !important;
    min-width: 135px;
    height: 48px;
    color: #fff;
    &:hover {
        background-color: #00496d !important;
    }

    &:disabled {
        background-color: #6c757d !important;
    }
    // &:focus, &:active{
    //   background-color: #014e95!important;
    // }
}

.preview {
    color: #161616;
    font-size: 18px;
}
ul li {
    list-style: none;
}
/* CSS used here will be applied after bootstrap.css */
.vmiddle {
    display: flexbox;
    vertical-align: middle;
    // float:none;
}
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    // height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}
.buttonSelect {
    position: absolute;
    top: -100px;
    right: 10px;
}
.cancel {
    position: absolute;
    top: -110px;
    right: -185px;
    color: #006395;
    cursor: pointer;
}
.lblZeroAxis {
    width: 80px;
    top: 19px;
    position: relative;
}
.switch {
    top: 10px;
}
.slider {
    width: 51px;
}
.sliderDisable {
    opacity: 0.5;
}
#editBlock {
    width: 499px;
}
.backbtn {
    position: absolute;
    right: 155px;
    top: -55px;
}
</style>
