<template>
    <div class="dataposting fullHeight">
        <b-container class="noMaxWidth fullHeight">
            <b-row class="left-shadow fullHeight">
                <!-- Below Mentioned Column will only be active if EDIT Button is clicked  -->
                <!-- Edit Project Column Ends Here .  -->
                <!-- ----------------------------------------------------------------------------------------------- -->
                <!-- Once Project Layers is active only then the below section will be visible  -->

                <!-- -------------------------------------Project Layer Ends Here---------------------------------------- -->
                <!-- Below Column handles Code of Map present on Right Hand Side in View Layer -->
                <b-col class="p-0 full-view">
                    <div
                        id="mapContainer"
                        class="fullHeight"
                        v-bind:class="mapClass"
                    >
                        <!-- regular map below -->
                        <a
                            href="#"
                            class="mr-2 map_arrow_left"
                            v-if="mapPaneVisible"
                            @click="toggleMapPane"
                            ><img src="../../assets/map_toggle_left.svg"
                        /></a>
                        <a
                            href="#"
                            class="mr-2 map_arrow_right"
                            v-else
                            @click="toggleMapPane"
                            ><img src="../../assets/map_toggle_right.svg"
                        /></a>
                        <main-leaflet-map
                            ref="mainLmap"
                            mapType="big"
                            :zoomMapExtent="mapExtent"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <!-- project layers sidepanel -->
        <div class="border p-0 accordion-item" v-if="mapPaneVisible">
            <layer-accordion
                id="LayerAccordion"
                :accordionHeight="false"
                @editLyr="openPostedEdit"
                @editContourLyr="openContourEdit"
                @updateMapExtent="updateMapExtent"
                v-show="!edit_clicked"
            />
            <!-- Below Mentioned Column will only be active if EDIT Button is clicked  -->
            <!-- --------------------------------------------------------------------------------------------- -->
            <b-col
                class="border edit-layer pl-2"
                :class="{ mainContent: edit_clicked }"
                v-show="edit_clicked"
            >
                <posted-layer-edit
                    id="PostedLayerEdit"
                    panelWidth="medium"
                    :dataset="editDataset"
                    :datasetIdx="editDatasetIdx"
                    @cancel="cancel_edit"
                    v-if="edit_clicked && edit_type == 'posted'"
                />
                <contour-layer-edit
                    id="ContourLayerEdit"
                    panelWidth="medium"
                    :jobID="editDataset.jobID"
                    @cancel="cancel_edit"
                    v-if="edit_clicked && edit_type == 'contour'"
                />
            </b-col>
        </div>
        <div v-else>
            <div>
                <b-modal
                    ref="my-modal"
                    class="model_style"
                    hide-header
                    hide-footer
                    centered
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideModal"
                            />
                        </div>
                        <h5 class="mt-2">Delete {{ modalLayerName }}</h5>
                        <small
                            >Are you sure you want to delete this layer? This
                            action cannot be undone.</small
                        ><br />
                        <div class="mt-3 mb-4 delModal">
                            <a class="text-primary" @click="hideModal"
                                >No, don't delete</a
                            >
                            <button class="del-btn ml-2" @click="deleteLayer()">
                                Delete Layer
                            </button>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool -->
    </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import router from '../../router';

import { marker, latLngBounds, circleMarker, divIcon } from 'leaflet';
// delete Icon.Default.prototype._getIconUrl;
// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });

import { userService } from '../../services/users.service';
import { projectService } from '../../services/project.service';
// import { DropDownButtonPlugin } from '@syncfusion/ej2-vue-splitbuttons';
// import { enableRipple } from '@syncfusion/ej2-base';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';
//import { MapsPlugin, Legend, DataLabel, MapsTooltip } from '@syncfuion/ej2-vue-maps';

import { commonService } from '../../services/common.service';
// import CustomFloatingLabel from "../CustomFloatingLabel";
import MainLeafletMap from '../CommonComponents/MainLeafletMap.vue';
import LayerAccordion from '../projects/layers/LayerAccordion.vue';
import PostedLayerEdit from '../CommonComponents/DataPostingTools/EditPostedLayer.vue';
import ContourLayerEdit from '../CommonComponents/DataPostingTools/ContouringEdit.vue';
//Vue.use(MapsPlugin);
Vue.use(ColorPickerPlugin);

export default {
    components: {
        // CustomFloatingLabel,
        MainLeafletMap,
        LayerAccordion,
        PostedLayerEdit,
        ContourLayerEdit,
    },
    name: 'ModalLeafletMap',
    props: {
        mapType: String,
        showReportingMenu: Boolean,
        hidetableSelect: Boolean,
        // aggreGateOptionFromTable: Array,
        currentSwitchState: Boolean,
    },
    data() {
        return {
            //project layer data, remove after api call is completed
            expandedMapBounds: null,
            clustersHaveBeenRefreshed: false,
            projectDataLayers: [],
            postedDataLayers: null,
            mapPostingLayerData: null,
            mapPaneVisible: true,
            textColor: null,
            sitecode: null,
            markerpopup: false,
            mapBounds: null,
            fillColor: '#000000',
            urlTemplate:
                'https://tiles.wmflabs.org/bw-mapnik/level/tileX/tileY.png',
            animationDelay: 0,
            postingSource: null,
            editPostingSource: null,
            colorSelected: null,
            rotation: '0°',
            editRotation: null,
            transparencyValue: 0,
            editTransparencyValue: null,
            editFieldName: '',
            editPosition: null,
            editAggregateOptionField: '',
            createLayerPage: true,
            layerDetails: {},
            aggreGateOption: [
                {
                    aggreGateField: '',
                    aggreGateType: [],
                },
            ],
            aggreGateTypeData: [],
            modalLayerName: '',
            aggregateOptionField: null,
            modalLayerId: '',
            layerId: '',
            value: 0,
            options: [],
            // validateInput: true,
            localLayer: [],
            layerList: [],
            styleStyle: 'Normal',
            editStyle: null,
            pickColor: false,
            postLayerActive: true,
            layerName: '',
            // validateInput:[],
            layerName_length: null,
            fieldName: null,
            editSymbology: null,
            symbology: null,
            styleSize: 10,
            styleColor: '#000000ff',
            editSize: null,
            styleType: 'Arial',
            overflowMenu: false,
            aggregateOption: [],
            // layerId: "",
            selected: null,
            hideheader: 0,
            aggrfieldCounter: 0,
            aggrfield: [],
            mapview: false,
            datasetname: '',
            isClicked: false,
            dataLabelPosition: null,
            edit_clicked: false,
            edit_type: '',
            projLayerActive: false,
            proj_layer_enabled: false,
            postingId: 0,
            selectedPostingId: '',
            styleData: [],
            styleSizeData: [],
            styleTypeData: [],
            styleColorData: [],
            styleStyleData: [],
            fieldData: [],
            layerEditIndex: '',
            editLayer: false,
            fieldAggregateData: [],
            fieldAggregateDataUnique: [],
            dptData: [],
            busy: true,
            showLayer: true,
            closeModal: false,
            mapExtent: [
                [-123.77, 30.72],
                [-59, 50],
            ],
            option1: { name: '1' },
            option2: { name: '2' },
            aggrfieldType: false,
            // currentSwitchState: false,
            thisfilename: null,
            reportingTool: false,
            AggregateOptions: null,
            showPage: false,
            filterOptions: [],
            editAggrTypeRevised: [],
            mapClass: 'dataPost-map-box',
            mapPopupVisible: false,
            clickedGeometry: { properties: [], hasFile: false, files: [] },
            clusterOptions: {
                disableClusteringAtZoom: 17,
                showCoverageOnHover: false,
            },
            editDataset: {},
            editDatasetIdx: -1,
        };
    },
    created() {
        // this.getAllLayer();
        //this.getStyleBlock();
        this.getStyleSize();
        this.getStyleType();
        this.getStyleStyle();
        this.selectField();
        this.selectAggregateField();
        this.allAggregateOption();

        this.aggreGateOptionFromTable = JSON.parse(
            localStorage.getItem('aggreGateOption')
        );

        if (localStorage.getItem('postingStatus') == 'true') {
            if (this.aggreGateOptionFromTable) {
                this.aggreGateOption = this.aggreGateOptionFromTable;
                this.displayMap();
            }
        }
    },
    computed: {
        ...mapState({
            postedMapLayers: (state) =>
                state.projects.mapData.geojsonLayers.posted,
        }),
        nameState() {
            return this.layerName.length > 0 ? true : false;
        },
        validateInput() {
            let disableApply = false;
            if (this.layerName == '' || this.layerName == null) {
                disableApply = true;
            }
            if (this.fieldName == null || this.fieldName == '') {
                disableApply = true;
            }
            for (let i in this.aggreGateOption) {
                if (
                    this.aggreGateOption[i].aggregateOptionField == '' ||
                    this.aggreGateOption[i].aggregateOptionField == null
                ) {
                    disableApply = true;
                } else {
                    if (this.aggreGateOption[i].aggreGateType.length > 0) {
                        for (let m in this.aggreGateOption[i].aggreGateType) {
                            if (
                                this.aggreGateOption[i].aggreGateType[m]
                                    .aggreGateTypeName ==
                                'Closest to a provided date'
                            ) {
                                if (
                                    this.aggreGateOption[i].aggreGateType[m]
                                        .aggreGateDate == ''
                                ) {
                                    disableApply = true;
                                }
                            }
                        }
                    }
                }
            }

            return disableApply;
        },
    },
    mounted: function() {
        this.$root.$on('clickedFilterData', function(value) {
            this.currentSwitchState = value;
            localStorage.setItem('postingStatus', this.currentSwitchState);
        });
    },
    methods: {
        toggleMapPane() {
            this.mapPaneVisible = !this.mapPaneVisible;
        },
        closePopup() {
            document.getElementById('closepopup').style.display = 'none';
        },
        getStyleBlock() {
            projectService
                .getStyleBlock()
                .then((res) => (this.styleData = res.data));
        },
        getStyleSize() {
            projectService.getStyleSize().then((res) => {
                this.styleSizeData = res.data;
            });
        },
        getStyleStyle() {
            projectService
                .getStyleStyle()
                .then((res) => (this.styleStyleData = res.data));
        },
        getStyleColor() {
            projectService
                .getStyleColor()
                .then((res) => (this.styleColorData = res.data));
        },
        getStyleType() {
            projectService
                .getStyleType()
                .then((res) => (this.styleTypeData = res.data));
        },
        selectField() {
            projectService
                .selectField(localStorage.getItem('datasetid'))
                .then((res) => {
                    let dataField = res.data;
                    if (dataField) {
                        dataField.forEach((item) => {
                            if (item.AttributeType === 'Default') {
                                10;
                                this.fieldName = item.DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName;
                            }
                            this.fieldData.push(
                                item.DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName
                            );
                        });
                        this.fieldData = [...new Set(this.fieldData)];
                        /* let data=this.fieldData.find((item)=>item.AttributeType==="Default");
          this.fieldName=data.DatasetColumn.ColumnName;*/
                    }
                });
        },
        selectAggregateField() {
            projectService
                .selectAggregateField(localStorage.getItem('datasetid'))
                .then((res) => {
                    this.fieldAggregateData = res.data;

                    if (this.fieldAggregateData) {
                        this.fieldAggregateData.forEach((item) => {
                            if (item.AttributeType === 'Default') {
                                this.aggreGateOption[0].aggregateOptionField = item
                                    .DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName;
                                this.addAggrType(0);
                            }
                            this.fieldAggregateDataUnique.push(
                                item.DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName
                            );
                        });
                        this.fieldAggregateDataUnique = [
                            ...new Set(this.fieldAggregateDataUnique),
                        ];
                    }
                });
        },

        allAggregateOption() {
            let vm = this;
            userService.getAggregateOptions().then((res) => {
                this.options = res.data;
            });
        },
        deleteAggr(index) {
            this.aggreGateOption.splice(index, 1);
            // this.aggrfieldType = false;
        },
        colorPicker() {
            this.pickColor = true;
        },
        //  nameState() {
        //     return this.layerName.length = 3? true : false
        //   },
        navigatetoDatasources() {
            router.push(`/project/selectedprojects`);
        },
        dataPostingCreate() {
            this.createLayerPage;
        },
        dataPosition(item) {
            this.dataLabelPosition = item;
        },
        addAggr() {
            // this.aggrfield = true;
            /* this.aggrfield.push(this.aggrfieldCounter++);
      this.overflowMenu = true;*/
            this.aggreGateOption.push({
                aggreGateField: '',
                aggreGateType: [],
            });
            this.fieldAggregateData.forEach((item) => {
                if (item.AttributeType === 'Default') {
                    this.aggreGateOption[
                        this.aggreGateOption.length - 1
                    ].aggregateOptionField = item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName;
                    this.addAggrType(this.aggreGateOption.length - 1);
                }
            });
        },
        handleBlur() {
            this.validateInput = false;
        },
        showModal(layerDetails, index) {
            // this.layerId = projectDetails.layerId;
            this.modalLayerId = index;
            this.modalLayerName = layerDetails.DisplayName;
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },
        deleteLayer() {
            //  this.showLayer=false;
            let temp = JSON.parse(localStorage.getItem('layerDetails'));
            temp.splice(this.modalLayerId, 1);
            localStorage.setItem('layerDetails', JSON.stringify(temp));
            this.layerList.splice(this.modalLayerId, 1);
            // this.remove(modalLayerName);
            this.$refs['my-modal'].hide();
        },
        editAggrType(datatype, index) {
            let dataTypeSetId;
            dataTypeSetId = this.fieldAggregateData.find(
                (item) =>
                    (item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName) === datatype
            );
            userService
                .getAggregateOptionsByType(dataTypeSetId.DatasetColumn.Datatype)
                .then((res) => {
                    this.options = res.data;
                    this.aggreGateTypeData[index] = this.options;
                    this.editAggrTypeRevised = this.aggreGateTypeData[index];
                    this.$forceUpdate();
                });
        },
        addAggrType(index) {
            //
            let dataTypeSetId;

            this.aggreGateOption[index].aggreGateType = [];
            dataTypeSetId = this.fieldAggregateData.find(
                (item) =>
                    this.aggreGateOption[index].aggregateOptionField ===
                    (item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName)
            );
            if (this.aggreGateOption[index].aggreGateType.length === 0) {
                this.aggreGateOption[index].aggreGateType.push({
                    aggreGateTypeName: '',
                    aggreGateDate: '',
                });
                /* let tempType;
     if(dataTypeSetId.ToolAttribute.DatatypeSetID!==0){
     tempType=this.options.filter((item)=> item.DatatypeSetID===dataTypeSetId.ToolAttribute.DatatypeSetID);
     }else{
     tempType=this.options;

     }*/

                userService
                    .getAggregateOptionsByType(
                        dataTypeSetId.DatasetColumn.Datatype
                    )
                    .then((res) => {
                        this.options = res.data;
                        this.aggreGateTypeData[index] = this.options;
                        if (
                            this.$store.state.store.portalsettings !== undefined
                        ) {
                            let numberPortalData = this.$store.state.store
                                .portalsettings.Number;
                            let datePortalData = this.$store.state.store
                                .portalsettings.Date;
                            let textPortalData = this.$store.state.store
                                .portalsettings.Text;
                            let result = this.options.filter(
                                (ele) =>
                                    ele.AggregateOptionID == numberPortalData ||
                                    ele.AggregateOptionID == datePortalData ||
                                    ele.AggregateOptionID == textPortalData
                            );
                            if (result.length) {
                                this.aggreGateOption[
                                    index
                                ].aggreGateType[0].aggreGateTypeName =
                                    result[0].AggregateOptions;
                                this.validateInput = false;
                            }
                        }
                        this.$forceUpdate();
                    });
            }

            /* this.selected = true;
      this.aggrfieldType = true;

      if (evt == "Result") {
        this.aggregateType = ["a"];
      } else if (evt == "ColectionDate") {
        this.aggregateType = ["a1", "b2", "c3", "d4"];
      } else if (evt == "MatrixDesc") {
        this.aggregateType = ["a5", "b6", "c6", "d6"];
      }*/
        },
        backToCreate() {
            this.mapview = !this.mapview;
            this.postLayerActive = true;
            this.aggreGateOption = [];
            this.aggreGateOption.push({
                aggreGateField: '',
                aggreGateType: [],
            });
            this.layerName = '';
            this.fieldName = null;
            this.fillColor = '#000000';
            this.symbology = null;
            this.styleSize = 10;
            this.styleStyle = 'Normal';
            this.styleType = 'Arial';
            this.transparencyValue = 0;
            this.rotation = '0°';
            this.edit_clicked = false;
            this.selectedPostingId = '';
            this.layerEditIndex = '';
            this.editLayer = false;
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [month, day, year].join('-');
        },
        displayMap() {
            this.showLayer = true;
            this.mapview = true;

            let aggregateData = [];
            this.aggreGateOption.forEach((item) => {
                if (item.aggreGateType[0].aggreGateDate) {
                    let x = this.formatDate(
                        item.aggreGateType[0].aggreGateDate
                    );
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggreGateType[0].aggreGateTypeName +
                            '(' +
                            x +
                            ')'
                    );
                } else {
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggreGateType[0].aggreGateTypeName
                    );
                }
            });
            let aggregateDataFinal = aggregateData.toString();
            this.filterOptions = JSON.parse(localStorage.getItem('gridFilter'));
            // projectService
            //   .getDataPostingToolData(
            //     localStorage.getItem("datasetid"),
            //     aggregateDataFinal,
            //     this.fieldName
            //   )
            //   .then((res) => {
            //     this.dptData = res.data;
            //     this.processData();
            //   })
            //   .catch((err) =>

            projectService
                .getDataPostToolData(
                    localStorage.getItem('datasetid'),
                    aggregateDataFinal,
                    this.fieldName,
                    this.filterOptions
                )
                .then((res) => {
                    this.postedDataLayers = res.data;
                    this.processData();
                })
                .catch((err) => {
                    if (
                        err.response.data.Message == 'ForbiddenSQLInjection' &&
                        err.response.data.StatusCode == 500
                    ) {
                        this.$emit(
                            'resForbiddenPosting',
                            err.response.data.Message
                        );
                    } else {
                        this.showFailure = true;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
        },
        convertLat(val) {
            val = val % 180;
            if (val < -90) {
                val += 180;
            } else if (val > 90) {
                val -= 180;
            }
            return parseFloat(val.toFixed(6));
        },
        convertLong(val) {
            val = val % 360;
            if (val < -180) {
                val += 360;
            } else if (val > 180) {
                val -= 360;
            }
            return parseFloat(val.toFixed(6));
        },
        openEdit(layerDetails, index) {
            this.editDataset = layerDetails;
            this.editDatasetIdx = index;
            this.edit_clicked = true;
        },
        openPostedEdit(layerDetails, index) {
            this.edit_type = 'posted';
            this.openEdit(layerDetails, index);
        },
        openContourEdit(layerDetails, index) {
            this.edit_type = 'contour';
            this.openEdit(layerDetails, index);
        },
        cancel_edit() {
            this.edit_clicked = false;
            this.postLayerActive = true;
            this.selectedPostingId = '';
            this.editDataset = {};
            this.editDatasetIdx = -1;
        },
        updateMapExtent(e) {
            this.mapExtent = e;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
.main-content {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;
    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }
    h3 {
        font-size: 22px;
    }
    .some-radios {
        font-size: 14px;
        color: #626262;
    }
    // .custom-select {
    //   background: #fff url("../../assets/arrow_down.svg") no-repeat right 0.75rem
    //     center/8px 10px !important;
    //   width:75%;
    //   height: 48px;
    // }
}
.aggr-edit,
.layer-post {
    width: 241px;
}
.main-content.edit-layer {
    max-height: 485px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    .inputbox-edit,
    .select-edit {
        width: 241px;
        border-radius: 3px;
        padding: 7px;
        height: 48px;
        border: 1px solid rgb(206, 212, 218);
    }
    .subSelect {
        width: 180px;
        float: left;
    }
    .dlt-icon {
        position: absolute;
        right: 20px;
    }
    .edit.select-edit {
        width: 203px;
        margin-left: 15px;
    }
}

// .inputbox {
//   width: 499px;
//   border-radius: 3px;
//   padding: 7px;
//   height: 48px;
//   border: 1px solid rgb(206, 212, 218);
// }
.inputbox :focus {
    border: none;
}
.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}
.inputbox-color {
    height: 48px;
    width: 100%;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}
.pencil-icon {
    position: absolute;
    left: 490px;
    margin-left: -24px;
    // margin-top: 59px;
    margin-top: 23px;
    cursor: pointer;
    width: 20px;
}
.buttonSelect {
    position: absolute;
    right: 0;
    top: -45px;
}
.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}
.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}
.aggrType {
    margin-left: 36px;
    margin-top: -23px;
    width: 400px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}
.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
.map_view_icon {
    background-color: grey;
    position: relative;
    bottom: 10px;
    border: 0 !important;
    // left: 8%;
    // float: right;
    .map_view_icon:focus {
        // outline: 1px dotted;
        outline: 0px;
    }
}
.search_element {
    background-image: url(../../assets/search_dots.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
}
.pos_layer {
    width: auto;
    background-color: #f4f4f4;
    height: 80px;
    font-size: 0.9rem;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}
.pos_layer.hover {
    background-color: #f4f4f4;
}
.dataposting-icon {
    padding: 4px 2px 16px 15px;
    margin-left: -17px;
    background: #eaeaea;
}
.posted_layer {
    background: transparent;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}
.posted_layer.hover {
    background: white;
    color: #1d1d1d;
}
.project_layer {
    background: transparent;
    color: #1d1d1d;
    width: 100%;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.project_layer_active {
    background: transparent;
    color: #1d1d1d;
    width: 100%;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.proj_layer {
    height: 43px;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}
/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}
.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}
.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}
.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}
.edit-layer .btn-secondary:focus,
.edit-layer .btn-secondary:not(:disabled):not(.disabled):active {
    color: #1d1d1d !important;
    background-color: #eaeaea !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    //right: 15px;
    position: relative;
    //bottom: -4px;
    border-radius: 0;
}
.edit-layer .border-dark {
    height: 70px;
    padding: 0px;
    text-align: center;
    width: 80px;
}
.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}
.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}
.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}
.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}
.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}
.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
// .btn-secondary{
//   height:69px;
// }
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}
.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.edit-button {
    color: #007bff !important;
    margin-top: 0px;
    //margin-left: 96px;
}
.delete-datapost {
    margin-left: none !important;
    margin-top: 9px !important;
    font-size: 14px;
    color: #817070 !important;
}
.border-right {
    height: 20px;
}
.input-value {
    display: block;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 10px;
    padding: 10px;
    margin-top: -5px;
    width: 60px;
    text-align: center;
}
.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}
.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}
.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}
.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}
.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/* ------------------------------------------Position Button Css Ends Here------------------------------- */
.proj_layer.hover {
    background-color: white;
}
.slider_value {
    position: relative;
    bottom: 155px;
    left: 380px;
}
.slider {
    left: -3px !important;
}
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}
/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}
.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}
.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    //left: 20px;
    top: 18px;
    left: 35px;
    border: 0 !important;
}
.hidebtn {
    left: 50px;
}
.dataposting .portalbarMain {
    border-block: 0 !important;
}
.layer {
    width: 240px;
    .pencil-icon {
        left: 250px;
    }
    .inputrange.edit {
        width: 125px !important;
    }
}
.accBorder {
    height: 354px;
    overflow-y: scroll;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px#dee2e6;
}
/*.inputrange{
  width:145px!important;
}*/
.input-value {
    width: 61px;
    font-size: 14px;
}
.range-field {
    width: 310px !important;
}
.edit-layer .inputrange {
    width: 125px !important;
}
.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    width: 55px;
    font-size: 12px;
}
.layer-post .select-edit:not(:first-child) {
    width: 180px !important;
}
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    background: rgb(0, 99, 149) none repeat scroll 0% 0% !important;
    color: white !important;
    border: medium none;
    white-space: nowrap;
}
.edit-layer .bottom-btn .btn-secondary:focus,
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #006395 !important;
    width: auto;
    height: auto;
    //right: 15px;
    position: relative;
    border-radius: 0.25rem;
}
.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}
.overflow {
    overflow-y: auto;
    max-height: 440px;
}
.hideText {
    margin-right: 10px;
}
.modalScreen {
    height: 85vh;
    z-index: 999999;
}
.map_arrow_left {
    position: absolute;
    left: 285px;
    z-index: 999;
}
.map_arrow_right {
    position: absolute;
    left: 0px;
    z-index: 999;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}
.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}
.dataposting {
    height: 100%;
}
.rowData {
    margin-top: 6px;
}
.backtopost {
    position: absolute;
    top: -200px;
    right: 0;
}
.card-body {
    padding: 1rem;
}
.collapsed .when-opened {
    display: none;
}
.not-collapsed .when-closed {
    display: none;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}
.dateAggregate {
    margin-top: -22px;
}

.dataPost-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }
    .map_arrow_left {
        display: none !important;
    }
    .map_arrow_right {
        display: none !important;
    }
    // #mapContainer{
    //   position: absolute;
    //   height: 7.5in;
    //   width: 10in;
    //   top: 0.5in;
    //   left: 0.5in;
    // }
    // .dataPost-map-box-print{
    //   position: absolute;
    //   height: 7.5in;
    //   width: 10in;
    //   top: 0.5in;
    //   left: 0.5in;
    // }
}
.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}
.showHide {
    font-size: 14px;
    margin-top: 11px;
}
.zoomTo {
    color: #006395 !important;
    margin-top: 9px !important;
    padding-right: 5px !important;
    margin-right: 5px !important;
}
.noMaxWidth {
    max-width: none !important;
}
.fullHeight {
    height: 100%;
}

.accordion-item {
    width: 285px;
    height: 440px;
    position: absolute;
    top: 15px;
    z-index: 999;
    background: #fff;
    overflow: hidden !important;
}
.accordion-item .show {
    left: 0px !important;
}

.btn-side {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
}
.btn-edit {
    position: absolute;
    left: 20px;
    top: -10px;
}
.btn-zoom {
    position: absolute;
    left: 25px;
    top: 27px;
}
.btn-delete {
    position: absolute;
    left: 13px;
    top: 44px;
}
.sticky {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 9999;
}
.overflow {
    max-height: 364px;
    overflow-y: scroll;
}
.edit-layer {
    max-height: 440px;
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>
