<template>
    <l-control
        class="custom-control"
        :position="position"
        @ready="editControlReady"
        v-show="editing"
    >
        <button class="btn Wide-Icon" @click="stopEditing">Stop Editing</button>
    </l-control>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { LControl } from 'vue2-leaflet';
import { divIcon } from 'leaflet';
import { spatialFunctions } from '../../../utilities/spatialFunctions';

export default {
    name: 'LControlEdit',
    components: {
        LControl,
    },
    mixins: [],
    props: {
        position: {
            type: String,
            default: 'topright',
        },
        editing: {
            type: Boolean,
            default: false,
        },
        editingGeometry: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            editShapeColour: '#FFBD33',
            editVertexSize: 5,
        };
    },
    mounted() {
        this.$emit('ready', this.mapObject);
        this.$parent.$parent.$on('editVertex/updateLatLng', this.updateVertex);
    },
    render() {
        return null;
    },
    methods: {
        ...mapActions('emergencyManagement', [
            'setDrawingAttributes',
            'updateDrawingGeometry',
        ]),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        ...mapActions('oneMap', [
            'appendToEditVertices',
            'clearEditVertices',
            'clearEditingGeom',
            'setEditIndex',
            'clearEditIndex',
        ]),
        ...mapMutations('oneMap', [
            'addDrawMarker',
            'addDrawLine',
            'addDrawPolygon',
            'mutateEditArray',
            'mutateEditVertices',
            'mutateDrawLines',
            'mutateDrawPolygons',
        ]),
        // update the geometry into a new coordinate array as the user drags the vertices around
        updateGeometry(vertex) {
            var newVertices = [];
            if (this.editingGeometry.geometry.type == 'Point') {
                // only have to return the 1 point
                return [vertex.lat, vertex.lng];
            } else if (this.editingGeometry.geometry.type == 'LineString') {
                // iterate through each of the points, when we find the new vertex,
                //  push on the new coordinates, otherwise we push the existing coordinates
                this.editingGeometry.geometry.coordinates.forEach(
                    (coordinate, i) => {
                        if (vertex.id == 'CE' + i) {
                            newVertices.push([vertex.lat, vertex.lng]);
                        } else {
                            newVertices.push(coordinate);
                        }
                    }
                );
            } else if (this.editingGeometry.geometry.type == 'Polygon') {
                // iterate through each of the points, when we find the new vertex,
                //  push on the new coordinates, otherwise we push the existing coordinates
                this.editingGeometry.geometry.coordinates[0].forEach(
                    (coordinate, i) => {
                        if (vertex.id == 'CE' + i) {
                            newVertices.push([vertex.lat, vertex.lng]);
                        } else {
                            newVertices.push(coordinate);
                        }
                    }
                );
                // if it is the first pointk, we also have to update the last point, in WKT, the first and last point in a polygon must match
                // only need to account for CE0 since we do not draw a circle at the close of the polygon
                if (vertex.id == 'CE0') {
                    newVertices[newVertices.length - 1] = newVertices[0];
                }
            }
            if (this.editingGeometry.geometry.type == 'Polygon') {
                // polygons have to be an array of coordinates
                return [newVertices];
            } else {
                return newVertices;
            }
        },
        getEditIcon() {
            return divIcon({
                className: 'ThisHasToBeSomethingToHideTheDefaultShadow',
                html:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                    this.editShapeColour +
                    '" stroke="' +
                    this.editShapeColour +
                    '"/></svg>',
                iconAnchor: [5, 10],
                iconSize: [10, 10],
            });
        },
        // create the edit point, this is used for the edit vertices when they are drawn on the map
        createPoint(pointID, coordinates) {
            return {
                id: 'CE' + pointID,
                geometry: {
                    coordinates: coordinates,
                    coordSys: 'LatLong',
                    type: 'Point',
                },
                styling: {
                    PolygonColor: this.editShapeColour,
                    PolygonTransparency: 0.8,
                    SymbologyColour: this.editShapeColour,
                    SymbologySize: this.editVertexSize,
                },
                icon: this.getEditIcon(),
            };
        },
        // update the geometry as the vertex is dragged around the map
        updateVertex(vertex) {
            let editVertices = this.editVertices;
            let drawLines = this.drawLines;
            let drawPolygons = this.drawPolygons;
            let newCoordinates = this.updateGeometry(vertex);
            switch (this.editArray) {
                case 'drawMarkers':
                    editVertices[
                        this.editIndex
                    ].geometry.coordinates = newCoordinates;
                    this.mutateEditVertices(editVertices);
                    break;
                case 'drawLines':
                    drawLines[
                        this.editIndex
                    ].geometry.coordinates = newCoordinates;
                    this.mutateDrawLines(drawLines);
                    break;
                case 'drawPolygons':
                    drawPolygons[
                        this.editIndex
                    ].geometry.coordinates = newCoordinates;
                    this.mutateDrawPolygons(drawPolygons);
                    break;
            }
            this.editingGeometry.geometry.coordinates = newCoordinates;
        },
        // when stopping editing, we have to clean up all the temp data and send the new coordinates to the store
        stopEditing() {
            let editedFeature = this.getEditedGeometries();
            this.clearEditingGeom();
            this.clearEditVertices();
            this.updateDrawingGeometry(editedFeature);
            this.$emit(
                'stopGeometryEdit',
                spatialFunctions.dd2WebMerc(editedFeature)
            );
        },
        // returns the lsat edited feature depending on the draw type
        getEditedGeometries() {
            switch (this.editArray) {
                case 'drawMarkers':
                    return this.editVertices.at(0);
                case 'drawLines':
                    return this.drawLines.at(-1);
                case 'drawPolygons':
                    return this.drawPolygons.at(-1);
            }
        },
        editControlReady() {},
    },
    computed: {
        ...mapState('oneMap', [
            'drawMarkers',
            'drawLines',
            'drawPolygons',
            'editVertices',
            'editArray',
            'editIndex',
        ]),
    },
    watch: {
        // when the store's editingGeometry changes, if there is a feature to edit, do the setup for editing
        editingGeometry: function createEditVertexCircles(editGeometry) {
            if (!Object.keys(editGeometry).includes('geometry')) {
                return;
            } else {
                // set the ID to 0, clear the previous vertices that may have been drawn in
                var pointID = 0;
                this.clearEditVertices();
                switch (editGeometry.geometry.type) {
                    case 'Point':
                        this.setEditIndex(this.drawMarkers.length);
                        this.appendToEditVertices(
                            this.createPoint(
                                pointID,
                                spatialFunctions.webMerc2DD(editGeometry, true)
                                    .geometry.coordinates
                            )
                        );
                        this.mutateEditArray('drawMarkers');

                        break;
                    case 'LineString':
                        this.setEditIndex(this.drawLines.length);
                        this.addDrawLine({
                            id: 'LE',
                            geometry: spatialFunctions.webMerc2DD(
                                editGeometry,
                                true
                            ).geometry,
                            styling: {
                                PolygonBorderColour: this.editShapeColour,
                                PolygonFillColour: this.editShapeColour,
                                PolygonTransparency: 0.8,
                            },
                            length: 0,
                        });
                        this.mutateEditArray('drawLines');

                        editGeometry.geometry.coordinates.forEach((vertex) => {
                            this.appendToEditVertices(
                                this.createPoint(pointID, vertex)
                            );

                            pointID++;
                        });
                        break;
                    case 'Polygon':
                        this.setEditIndex(this.drawPolygons.length);
                        this.addDrawPolygon({
                            id: 'PE',
                            geometry: spatialFunctions.webMerc2DD(
                                editGeometry,
                                true
                            ).geometry,
                            styling: {
                                PolygonBorderColour: this.editShapeColour,
                                PolygonFillColour: this.editShapeColour,
                                PolygonTransparency: 0.8,
                            },
                        });
                        this.mutateEditArray('drawPolygons');

                        // iterate through the coordinates that are being edited and add the vertices to the
                        editGeometry.geometry.coordinates[0]
                            .slice(0, -1)
                            .forEach((vertex) => {
                                this.appendToEditVertices(
                                    this.createPoint(pointID, vertex)
                                );
                                pointID++;
                            });
                        break;
                }
            }
        },
        editing: function(newVal) {
            // clear the edit geometries and show the attribute modal when no longer editing
            if (!newVal) {
                this.clearEditingGeom();
                this.setShowAttributeModal(true);
            }
        },
    },
};
</script>

<style>
.Wide-Icon {
    width: 100px;
    height: 45px;
    background: white !important;
    border-bottom: 1px black !important;
    padding: 0 !important;
}
</style>
