<template>
    <l-control class="emControl" :position="position" @ready="identifyReady">
        <button
            type="button"
            :class="buttonActive"
            :disabled="!enabled"
            class="btn btn-white"
            @click="identify"
        >
            <slot name="identifyIcon">i</slot>
        </button>
    </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { mapLayerFunctions } from '../../../utilities/mapLayerFunctions';
import { projectService } from '../../../services/project.service';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    name: 'LControlIdentify',
    components: {
        LControl,
    },
    props: {
        position: {
            type: String,
            default: 'topright',
            required: false,
        },
        mapLayers: {
            required: true,
            type: Array,
            default: () => [],
        },
        projLayers: {
            type: Array,
            default: () => [],
        },
        mapZoom: {
            required: false,
            type: Number,
            default: 19,
        },
        isActive: Boolean,
        enabled: Boolean,
    },
    data() {
        return {
            NSToken: null,
        };
    },
    mounted() {
        //if current project is a NS project, call the get NS Esri Token api
        //need better logic to check if current project is a NS project, with client data
        if (this.$store.state.projects.selectedProjects[0] != undefined) {
            if (
                this.$store.state.projects.selectedProjects[0].ProjectName.startsWith(
                    'Norfolk Southern'
                )
            ) {
                this.getNSTokenFromApi();
            }
        }
        this.$emit('ready', this.mapObject);
    },
    methods: {
        ...mapActions('oneMap', {
            setMapTool: 'setMapTool',
            clearMapTool: 'clearMapTool',
            clearIdBuffer: 'clearIdBuffer',
            clearHighlight: 'clearHighlightGeom',
        }),
        ...mapMutations('oneMap', ['addIdBuffer']),

        // gets a token for the NS data, needed to identify NS Asset layers
        async getNSTokenFromApi() {
            let apiReturn = await projectService.GetNSEsriToken();
            this.NSToken = apiReturn.data.token;
        },
        // function that does the identifying
        identify() {
            if (this.activeMapTool == 'identify') {
                this.clearMapTool();
            } else {
                this.setMapTool('identify');
                this.$store.commit('oneMap/mutateCloseSidebars', true);
            }
            // have to make the map change quickly to toggle the geoJson layer popups
            this.$parent.$parent.zoom++;
            this.$nextTick();
            this.$parent.$parent.zoom--;
        },
        getIdentifyProjectLayers() {
            var identifyProjectLayers = [];
            Object.keys(this.projectsObj).forEach((projectId) => {
                let project = this.projectsObj[projectId];
                let projectInfo = {
                    ProjectID: projectId === null ? 0 : parseInt(projectId),
                    ProjectLayers: [],
                    WMSLayers: [],
                };
                Object.keys(project.ProjectLayers).forEach((projLyrId) => {
                    if (
                        project.ProjectLayers[projLyrId].Geometry &&
                        project.ProjectLayers[projLyrId].visible &&
                        project.ProjectLayers[projLyrId].lyrid != null
                    ) {
                        projectInfo.ProjectLayers.push(
                            project.ProjectLayers[projLyrId].lyrid
                        );
                    }
                });

                Object.keys(this.layersStruct).forEach((layerId) => {
                    let layer = this.layersStruct[layerId];
                    if (
                        layer.isChecked &&
                        layer.url != '' &&
                        layer.layers != '' &&
                        layer.minZoom <= this.mapZoom &&
                        layer.maxZoom >= this.mapZoom &&
                        layer.mapLayerID != null
                    ) {
                        if (layer.mapLayerID)
                            projectInfo.WMSLayers.push(layer.mapLayerID);
                    }
                });
                projectInfo.WMSLayers = [...new Set(projectInfo.WMSLayers)]; //this will set unique MapLayerID
                identifyProjectLayers.push(projectInfo);
            });
            return identifyProjectLayers;
        },
        getDataPostParams() {
            let postedParams = [];
            let postedLayers = this.$store.state.projects.mapData.geojsonLayers
                .posted;
            if (postedLayers) {
                postedLayers.forEach((x) => {
                    if (x.Visible) {
                        x.DataPostParams['projectIds'] = this.ProjectID;
                        postedParams.push(x.DataPostParams);
                    }
                });
            }
            return postedParams;
        },
        // gets the arrays of visible project and WMS layers
        getIdentifyIDs() {
            //mapLayers:[{projectName: "projname" , projectLayers:[1, 2, 3], wmsLayers:[1, 2, 3]}]
            var mapLayers = [];

            var mapLayerIDs = [];
            this.mapLayers.forEach((mapLayer) => {
                // check that the layer is turned on and has a mapLayerID
                if (mapLayer.isChecked && mapLayer.mapLayerID) {
                    mapLayerIDs.push(mapLayer.mapLayerID);
                }
            });

            this.projLayers.forEach((mapLayer) => {
                let projectMapLayer = {
                    projectName: mapLayer.ProjectName,
                    projectLayers: mapLayer.ProjectLayers,
                    wmsLayers: [],
                };

                // check that the layer is turned on and has a mapLayerID by intersectoin
                let intersection = mapLayer.WMSLayers.filter((x) =>
                    mapLayerIDs.includes(x)
                );
                projectMapLayer.wmsLayers = intersection;

                mapLayers.push(projectMapLayer);
            });
            return mapLayers;
        },

        async handleMouseClick(lat, lng) {
            if (this.activeMapTool == 'identify') {
                this.clearHighlight();
                // define the geometry to use for the id buffer
                let bufferGeom = {
                    id: 'IdBuffer',
                    geometry: {
                        coordinates: [lat, lng],
                    },
                    styling: {
                        PolygonBorderColour: 'blue',
                        PolygonColour: 'light blue',
                        PolygonStyle: '10,10',
                        PolygonTransparency: 0,
                        SymbologySize: 0.3 * Math.pow(2, 22 - this.mapZoom),
                    },
                };
                this.clearIdBuffer();
                this.addIdBuffer(bufferGeom);
                this.clearMapTool();
                this.$store.commit('oneMap/mutateOpenSidebarFlag', 'Identify');
                // generate the payload for the identify endpoint
                var submitObj = {
                    type: 'Point',
                    bufferDistance: 0.3 * Math.pow(2, 22 - this.mapZoom),
                    geometry: spatialFunctions.convertToWKT({
                        geometry: {
                            coordinates: [lat, lng],
                            type: 'Point',
                            coordSys: 'LatLong',
                        },
                    }),
                    mapLayers: this.getIdentifyProjectLayers(),
                    projectID:
                        this.ProjectID == null ? 0 : parseInt(this.ProjectID),
                    dataPostParams: this.getDataPostParams(),
                };
                // save the payload to the store so we can recall it later, call
                //  identify through the mapLayerFunctions with this new payload
                this.$store.dispatch('oneMap/setIdentifyPayload', submitObj);
                mapLayerFunctions
                    .makeIdentifyRequest(submitObj, this.NSToken)
                    .then((res) => {
                        // when we get the identify results, place them in the store
                        this.$store.commit(
                            'oneMap/mutateIdentifyResultsData',
                            res
                        );
                    });
            }
        },
        identifyReady() {},
    },
    computed: {
        ...mapState('oneMap', ['activeMapTool', 'clickCoords']),
        ...mapState('projectLayers', { projectsObj: 'projects' }),
        ...mapState('emergencyManagement', ['layersStruct']),
        // true if the active map tool is 'identify', supports the style changing
        buttonActive: function() {
            return { identifyBtnActive: this.isActive };
        },
        ...mapGetters('projects', {
            ProjectID: 'ProjectID',
        }),
    },
    watch: {
        // when the coordinates are clicked, send the coordinates to the handler
        async clickCoords(newCoords) {
            let [lat, lng] = spatialFunctions.validateCoordinates({
                latlng: newCoords,
            });
            await this.handleMouseClick(lat, lng);
        },
    },
};
</script>

<style>
.identifyBtnActive {
    background-color: black !important;
}
</style>
