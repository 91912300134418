<template>
    <div id="mobile-map-top-bar">
        <div id="mobile-top-bar-content">
            <a id="client-logo"><img :src="projectLogo" class="logoStyle"/></a>
            <button class="btn-menu" @click="toggleTopMenu">
                Menu
                <img src="../../assets/list_view_grey.svg" />
            </button>
        </div>
        <mobile-top-menu
            id="mobile-top-menu"
            :class="showMenu ? 'show-menu' : 'hide-menu'"
            @setModalTool="setModalTool"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import MobileTopMenu from './MobileTopMenu.vue';
export default {
    name: 'MobileMapTopBar',
    components: {
        MobileTopMenu,
    },
    props: {
        modalTool: { type: String, default: '', required: false },
    },
    data() {
        return {
            showMenu: false,
        };
    },
    created() {},
    mounted() {},
    methods: {
        setModalTool(tool) {
            // if the tool is changing, or being set from '' to a value, hide the top menu
            if (this.modalTool !== tool) {
                this.hideTopMenu();
            }
            this.$emit('setModalTool', tool);
        },
        toggleTopMenu() {
            this.showMenu = !this.showMenu;
        },
        hideTopMenu() {
            this.showMenu = false;
        },
    },
    computed: {
        ...mapState('projects', {
            selectedProject: (state) => state.selectedProjects[0],
        }),
        projectLogo: function() {
            return `data:image/png;base64,${this.selectedProject.Logo}`;
        },
    },
};
</script>

<style scoped>
#mobile-map-top-bar {
    background-color: black;
    color: rgb(173, 171, 171);
    height: 64px;
    width: 100%;
}
#mobile-top-bar-content {
    background-color: inherit;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    z-index: 5;
}

#client-logo {
    align-self: flex-start;
}

.show-menu {
    transform: translateY(0);
}

.hide-menu {
    transform: translateY(calc(-100%));
}

.logoStyle {
    position: relative;
    top: 12px;
    max-height: 40px;
}

.btn-menu {
    align-self: flex-end;
    color: rgb(173, 171, 171);
    border: none;
    background-color: black;
    border-radius: 0px !important;
    position: relative;
    bottom: 20px;
}
.btn-menu:hover {
    color: white;
}

.btn-menu > img {
    position: relative;
    top: -2px;
}
</style>
