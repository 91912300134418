import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const eventLogService = {
    getEventLog,
    updateEventInfo,
    updateEventLog,
    createEventLog,
    deleteEventLog,
    getGeometry,
    getEventLogPositions,
    getEventID,
    deleteEventLogFile,
};

function getEventLog(id, lastCheckTime) {
    return request({
        url:
            apiConstants.getEventLog +
            '?projectID=' +
            id +
            '&lastCheckTime=' +
            lastCheckTime,
    });
}

function updateEventInfo(payload) {
    return request({
        url: apiConstants.updateEventInfo,
        data: payload,
        method: 'put',
    });
}

function updateEventLog(payload) {
    return request({
        url: apiConstants.updateEventLog,
        data: payload,
        method: 'put',
    });
}

function createEventLog(payload) {
    return request({
        url: apiConstants.createEventLog,
        data: payload,
        method: 'post',
    });
}

function deleteEventLog(id) {
    return request({
        url: apiConstants.deleteEventLog + '?eventLogID=' + id,
        method: 'delete',
    });
}

function getGeometry(geoID) {
    return request({
        url: apiConstants.getEventLogGeometry + '?geoID=' + geoID,
    });
}

function getEventLogPositions(projectID) {
    return request({
        url: apiConstants.getEventLogPositions + '?projectID=' + projectID,
    });
}

function getEventID(projectID) {
    return request({
        url: apiConstants.getEventID + '?projectID=' + projectID,
    });
}

function deleteEventLogFile(eventLogID) {
    return request({
        url: apiConstants.deleteEventLogFile + '?eventLogID=' + eventLogID,
        method: 'delete',
    });
}
