<template>
    <l-control :position="position">
        <button
            :disabled="disabled"
            type="button"
            class="btn btn-white"
            @click="goToUserLocation"
            ref="getMyLocation"
        >
            <img
                class="map-btn-icon"
                src="../../../../assets/crosshairs-icon.svg"
            />
        </button>
    </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet';

export default {
    name: 'LControlMyLocation',
    components: {
        LControl,
    },
    props: {
        position: {
            type: String,
            default: 'topright',
        },
        mapRef: {
            required: true,
            type: String,
        },
        disabled: { type: Boolean, required: false, default: false },
    },
    methods: {
        goToUserLocation() {
            this.$parent.$parent.$refs[this.mapRef].mapObject.locate({
                setView: true,
                maxZoom: 15,
            });
        },
    },
};
</script>

<style scoped>
.map-btn-icon {
    height: 24px;
}
.btn-white {
    background-color: white !important;
    height: 45px;
    width: 45px;
    padding-left: 6px !important;
}
</style>
