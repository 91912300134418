import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';
import * as XLSX from 'xlsx';
// import mammoth from 'mammoth';

export const commonService = {
    getCompanies,
    getCorporations,
    getCountries,
    getStates,
    getCities,
    getCookie,
    xssValidation,
    getDocumentHyperLink,
    getDocumentHyperLinkSource,
    getTools,
    getUserToolOption,
    updateUserToolOption,
    getDataDownload,
    getPermissions,
    getDownloadFromNetworkDrive,
    downloadDocument,
    initializeReportingService,
    objectToFormData,
    downloadDocumentByID,
    downloadThumbnailByID,
    downloadImage,
    b64ToBlob,
    base64ToDownload,
    createQRCode,
    getDocumentLinkByID,
    getDocument,
    getDownloadLink,
    downloadLink,
    base64ToArrayBuffer,
    logoutUser,
};

function initializeReportingService() {
    return request({ url: apiConstants.initializeReportingService });
}

function objectToFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
        if (typeof object[key] !== 'object') {
            formData.append(key, object[key]);
        } else {
            formData.append(key, JSON.stringify(object[key]));
        }
    });
    return formData;
}

function downloadDocumentByID(id, filename, filetype, isEventLogFile = false) {
    return request({
        url: apiConstants.downloadFile + '?documentID=' + id,
    })
        .then((res) => {
            if (res.data.Result.ResponseMessage.IsSuccessStatusCode) {
                if (filetype == 'image') {
                    return res.data.Result.Content;
                } else {
                    //
                    let arrayBuffer = base64ToArrayBuffer(
                        res.data.Result.Content
                    );
                    let fileURL = window.URL.createObjectURL(
                        new Blob([arrayBuffer])
                    );
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    /* separate the extension by the last '.', filemaker photos have time as hh.mm.ss for some reason,
                  so they download as a filetype that is just the minute value
                  Photo 2023-09-22 02.30.41 -> ..22 02.30 where the .30 is the file extension
                */
                    if (isEventLogFile) {
                        fileLink.setAttribute('download', filename);
                    } else {
                        let extension = filename.substring(
                            filename.lastIndexOf('.')
                        );
                        let basename = filename.substring(
                            0,
                            filename.lastIndexOf('.')
                        );
                        fileLink.setAttribute(
                            'download',
                            basename + '.' + extension
                        );
                    }
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            }
        })
        .catch((error) => {});
}

function downloadThumbnailByID(id, filename, filetype, isEventLogFile = false) {
    return request({
        url: apiConstants.downloadThumbnail + '?documentID=' + id,
    })
        .then((res) => {
            if (res.data.IsCompletedSuccessfully) {
                if (filetype == 'image') {
                    return res.data.Result.Content;
                } else {
                    //
                    let arrayBuffer = base64ToArrayBuffer(
                        res.data.Result.Content
                    );
                    let fileURL = window.URL.createObjectURL(
                        new Blob([arrayBuffer])
                    );
                    let fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    let filenameExt = filename.split('.');
                    fileLink.setAttribute(
                        'download',
                        filenameExt[0] + '.' + filenameExt[1]
                    );
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            }
        })
        .catch((error) => {});
}

function downloadImage(id, filename) {
    return request({
        url: apiConstants.downloadFile + '?documentID=' + id,
    }).then((res) => {
        if (res.data.Result.ResponseMessage.IsSuccessStatusCode) {
            let arrayBuffer = base64ToArrayBuffer(res.data.Result.Content);
            let fileURL = window.URL.createObjectURL(new Blob([arrayBuffer]));
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    });
}

function getDocumentLinkByID(id) {
    return request({
        url: apiConstants.downloadFile + '?documentID=' + id,
    }).then((res) => {
        if (res.data.Result.ResponseMessage.IsSuccessStatusCode) {
            return res.data.Result.Content;
        }
    });
}
function base64ToDownload(data, filename) {
    let arrayBuffer = base64ToArrayBuffer(data);
    const fileURL = window.URL.createObjectURL(new Blob([arrayBuffer]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);
    fileLink.click();
}
function downloadDocument(filename, source, path) {
    if (source == 'Blob') {
        commonService.getDataDownload(filename, path).then((res) => {
            base64ToDownload(res.data.Content, filename);
        });
    } else if (source == 'NetworkDrive') {
        commonService
            .getDocumentHyperLink(encodeURIComponent(path + '\\' + filename))
            .then((res) => {
                base64ToDownload(res.data, filename);
            });
    } else if (source == 'Web') {
        if (!path.startsWith('http')) {
            path = 'https://' + path;
        }
        window.open(path, '_blank');
    }
}
async function getDownloadLink(content, filename) {
    let downloadRef = '';
    const extension = filename.split('.').pop();
    const arrayBuffer = base64ToArrayBuffer(content);
    const blob = new Blob([arrayBuffer], {
        type: `application/${extension}`,
    });
    downloadRef = URL.createObjectURL(blob);
    return downloadRef;
}
async function downloadLink(filename, source, path) {
    try {
        const fileType = filename
            .toLowerCase()
            .split('.')
            .pop();
        const isImage = ['jpeg', 'jpg', 'png', 'svg', 'gif', 'webp'].includes(
            fileType
        );
        const isPdf = fileType === 'pdf';
        const isExcel = fileType === 'xlsx';
        const isWord = fileType === 'docx';
        const blobType = isImage
            ? `image/${fileType}`
            : isPdf
            ? 'application/pdf'
            : isExcel || isWord
            ? 'text/html'
            : '';

        if (source === 'Blob' && !isExcel && !isWord) {
            const res = await commonService.getDataDownload(filename, path);
            const arrayBuffer = base64ToArrayBuffer(res.data.Content);
            const blob = new Blob([arrayBuffer], { type: blobType });
            return URL.createObjectURL(blob);
        } else if (source === 'NetworkDrive') {
            const res = await commonService.getDocumentHyperLink(
                encodeURIComponent(`${path}\\${filename}`)
            );
            const arrayBuffer = base64ToArrayBuffer(res.data);
            const blob = new Blob([arrayBuffer], { type: blobType });
            return URL.createObjectURL(blob);
        }
        return null;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}
function b64ToBlob(b64String, mimeString) {
    var byteString = Buffer.from(b64String, 'base64').toString('binary');
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
}

function getCompanies() {
    // return axios.get(apiConstants.getCompanyList)
    return request({ url: apiConstants.getCompanyList });
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
        return parts
            .pop()
            .split(';')
            .shift();
}
function xssValidation(value) {
    var regexp1 = new RegExp('^[0-9A-Za-z_ .-]+$');
    if (!regexp1.test(value)) {
        return true;
    } else {
        return false;
    }
}
function getCorporations() {
    // return axios.get(apiConstants.getCorporationList)
    return request({ url: apiConstants.getCorporationList });
}
function getCountries() {
    // return axios.get(apiConstants.getCoutryList)
    return request({ url: apiConstants.getCoutryList });
}
function getDocumentHyperLink(filename) {
    // return axios.get(apiConstants.getNetworkFile + '?FileName=' + filename)
    return request({
        url: apiConstants.getNetworkFile + '?FileName=' + filename,
    });
}
function getDocumentHyperLinkSource(filename, source, path) {
    // return axios.get(apiConstants.getNetworkFileSource + '/' + filename + '/' + source + '?path=' + path)
    return request({
        url:
            apiConstants.getNetworkFileSource +
            '/' +
            filename +
            '/' +
            source +
            '?path=' +
            path,
    });
}
function getStates(countryId) {
    // return axios.get(apiConstants.getStateList + '?$filter=CountryId eq ' + countryId)
    return request({
        url: apiConstants.getStateList + '?$filter=CountryId eq ' + countryId,
    });
}

function getCities(stateId) {
    // return axios.get(apiConstants.getCityList + '?$filter=stateId eq ' + stateId)
    return request({
        url: apiConstants.getCityList + '?$filter=stateId eq ' + stateId,
    });
}

function getTools(Projects, datasetid = 0) {
    // return axios.get(apiConstants.getTools + '?Projects=' + Projects + '&DataSetId=' + datasetid)
    return request({
        url:
            apiConstants.getTools +
            '?Projects=' +
            Projects +
            '&DataSetId=' +
            datasetid,
    });
}
function getUserToolOption() {
    // return axios.get(apiConstants.getUserToolOption)
    return request({
        url: apiConstants.getUserToolOption,
        method: 'get',
    });
}
function updateUserToolOption(payload) {
    return request({
        url: apiConstants.updateUserToolOption,
        data: payload,
        method: 'put',
    });
}
// function getTools(){
//     return axios
//         .get(apiConstants.getTools + '?Projects=12616&datasetId=253' )
// }
function getDataDownload(fileName, path) {
    // return axios.get(apiConstants.getDataDownload + '/'+fileName+'/' +path)
    return request({
        url: apiConstants.getDataDownload + '/' + fileName + '/' + path,
    });
}

function getDownloadFromNetworkDrive(path) {
    // return axios.get(apiConstants.getDownloadFromNetworkDrive + '/'+ path)
    return request({
        url: apiConstants.getDownloadFromNetworkDrive + '/' + path,
    });
}

function getPermissions() {
    // return axios.get(apiConstants.getPermissions)
    return request({ url: apiConstants.getPermissions });
}

function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
}

function createQRCode(val) {
    return request({
        url: apiConstants.createQRCode,
        data: val,
        method: 'post',
    });
}

function getDocument(id) {
    return request({
        url: apiConstants.getDocument + '/' + id,
    });
}

// Logs out a user that is logged in through email authentication
async function logoutUser() {
    return request({
        url: apiConstants.logoutUser,
        method: 'post',
    });
}
