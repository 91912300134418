import { commonService } from '../services';

const state = {
    cities: {},
    states: {},
    token: null,
    tokenRefreshCount: 0,
    signInIcon: true,
    showSuccess: false,
    showFailure: false,
    messageTitle: null,
    successToastMessage: null,
    failureToastMessage: null,
    notificationQueue: [],
    notificationBellRinging: false,
    refreshMap: false,
};
const actions = {
    getAllCities({ commit }) {
        commit('getCityRequest');

        commonService.getAllCities().then(
            (cities) => commit('getCitySuccess', cities),
            (error) => commit('getCityFailure', error)
        );
    },
    getAllStates({ commit }) {
        commit('getStateRequest');

        commonService.getAllStates().then(
            (states) => commit('getStateSuccess', states),
            (error) => commit('getStateFailure', error)
        );
    },
    async renewToken({ commit }) {
        const account = this._vm.$msalInstance.getAllAccounts()[0];
        if (account) {
            const accessTokenRequest = {
                scopes: [
                    `api://${process.env.VUE_APP_API_CLIENT_ID}/access_as_user`,
                ],
                account: account,
            };
            let resp = await this._vm.$msalInstance.acquireTokenSilent(
                accessTokenRequest
            );
            document.cookie = `accessToken=${resp.accessToken};path=/;`;
            commit('setToken', resp.accessToken);
            // commit('resetCount');
        } else {
            //nothing
        }
    },
    incrementTokenRenewCount({ commit }) {
        commit('incrementTokenRenewCount');
    },
    setForceRefreshMap({ commit }, value) {
        commit('mutateSetRefreshMap', value);
    },
};

const mutations = {
    setToastMessage(state, val) {
        if (val.showSuccess !== undefined) {
            state.showSuccess = val.showSuccess;
            state.successToastMessage = val.successToastMessage;
            state.messageTitle = val.messageTitle;
            setTimeout(() => {
                state.showSuccess = false;
            }, 3000);
        } else if (val.showFailure !== undefined) {
            state.showFailure = val.showFailure;
            state.failureToastMessage = val.failureToastMessage;
            setTimeout(() => {
                state.showFailure = false;
            }, 3000);
        } else if (val.queueService) {
            const serviceExist = state.notificationQueue.some((obj) => {
                return obj.queueService === val.queueService;
            });
            if (serviceExist) {
                const serviceIndex = state.notificationQueue.findIndex(
                    (obj) => obj.queueService === val.queueService
                );

                if (serviceIndex !== -1) {
                    val.queueState &&
                        (state.notificationQueue[serviceIndex].queueState =
                            val.queueState);
                    val.queueMessage &&
                        (state.notificationQueue[serviceIndex].queueMessage =
                            val.queueMessage);
                    'downloadLink' in val &&
                        (state.notificationQueue[serviceIndex].downloadLink =
                            val.downloadLink);
                    val.visible !== '' &&
                        (state.notificationQueue[serviceIndex].visible =
                            val.visible);

                    //move the service to the end of the notificationQueue for RecentNotifications.vue
                    const movedService = state.notificationQueue.splice(
                        serviceIndex,
                        1
                    )[0];
                    state.notificationQueue.unshift(movedService);
                }
            } else {
                state.notificationQueue.unshift(val);
            }
            (val.queueState == 'complete' || val.queueState == 'error') &&
                (state.notificationBellRinging = true);
        }
    },

    mutateSetRefreshMap(state, value) {
        state.refreshMap = value;
    },

    removeQueueMessage(state, val) {
        state.notificationQueue = state.notificationQueue.filter(
            (item) => item.queueService !== val
        );
    },
    clearNotificationQueue(state) {
        state.notificationQueue = [];
    },
    setNotificationBellOff(state) {
        state.notificationBellRinging = false;
    },
    //get cities
    getCityRequest(state) {
        state.cities = { loading: true };
    },
    getCitySuccess(state, cities) {
        state.cities = { items: cities.data.CityDetails };
    },
    getCityFailure(state, error) {
        state.cities = { error };
    },

    //get States
    getStateRequest(state) {
        state.states = { loading: true };
    },
    getStateSuccess(state, states) {
        state.states = { items: states.data.StateDetails };
    },
    getStateFailure(state, error) {
        state.states = { error };
    },
    setToken(state, token) {
        state.token = token;
    },
    incrementTokenRenewCount(state) {
        //
        state.tokenRefreshCount++;
    },
    resetCount(state) {
        state.tokenRefreshCount = 0;
    },
    setLoadingIconOn(state) {
        state.signInIcon = false;
    },
    setLoadingIconOff(state) {
        state.signInIcon = true;
    },
};

const getters = {
    getForceRefreshMap: (state) => {
        return state.refreshMap;
    },
};

const namespaced = true;

export const common = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
