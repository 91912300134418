<!-- 
    *A common modal that can be used to select a project when more than one project selected
    :prop - isShow - true if more than one project is selected
    @emit - setSelectedProject - emits when project is selected
 -->
<template>
    <b-modal
        ref="multi-projects-modal"
        class="model_style manage-atr"
        hide-header
        hide-footer
        v-model="isShow"
        no-close-on-backdrop
        no-close-on-esc
    >
        <div class="d-block text-center">
            <div class="text-right cursor-pointer">
                <img
                    src="../../assets/close_icon.svg"
                    @click="hideReviewModal"
                />
            </div>
            <h5 class="mt-2">
                Select one project to continue.
            </h5>

            <div class="my-3 upload-doc">
                <table class="table table-striped">
                    <tbody>
                        <tr
                            v-for="(projects, index) in selectedProjectsArr"
                            :key="index"
                        >
                            <td class="d-flex">
                                <b-form-radio
                                    class="mt-1"
                                    :value="projects.ProjectID"
                                    v-model="selectedProject"
                                    @change="setSelectedProject"
                                >
                                </b-form-radio
                                ><span>{{ projects.ProjectName }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-3 mb-4 delModal">
                <a @click="hideReviewModal" class="text-primary">Cancel</a>
                <button
                    class="ml-3 applyButtonSelect"
                    :disabled="selectedRadio <= 0"
                    @click="hideReviewModal"
                >
                    Proceed with selection
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        isShow: Boolean,
    },
    data() {
        return {
            selectedProject: null,
            selectedRadio: 0,
        };
    },
    computed: {
        ...mapState({
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
    },
    methods: {
        hideReviewModal() {
            this.isShow = false;
        },
        setSelectedProject() {
            this.$emit('setSelectedProject', this.selectedProject);
        },
    },
    mounted() {
        this.selectedProject = this.selectedProjectsArr[0].ProjectID;
        this.$emit('setSelectedProject', this.selectedProject);
    },
};
</script>

<style></style>
