<template>
    <div class="fileManager">
        <toast-messages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />
        <ejs-filemanager
            :id="fileManagerId"
            ref="file_instance"
            :beforeSend="beforeSend"
            :beforeImageLoad="beforeImageLoad"
            :beforeDownload="beforeDownload"
            :fileOpen="fileOpen"
            :contextMenuSettings="contextMenuSettings"
            :ajaxSettings="ajaxSettings"
            :uploading="onUpload"
            :navigationPaneSettings="navigationPaneSettings"
            :toolbarSettings="toolSettings"
            :allowDragAndDrop="allowDragAndDrop"
            :success="onSuccess"
            :created="created"
            :uploadSettings="{ autoClose: true, maxFileSize: 1073741824 }"
            :menuClick="menuClick"
        >
        </ejs-filemanager>
    </div>
</template>
<script>
import Vue from 'vue';
import ToastMessages from '../ToastMessages.vue';
import { commonService } from '../../services/common.service';
import { projectService } from '../../services/project.service';
import {
    FileManagerPlugin,
    DetailsView,
    NavigationPane,
    Toolbar,
} from '@syncfusion/ej2-vue-filemanager';

Vue.use(FileManagerPlugin);
export default {
    data() {
        return {
            directoryID: '',
            fileManagerId: '',
            contextMenuSettings: {
                file: ['Download', '|', 'Details'],
                layout: ['SortBy', 'View', 'Refresh', '|', 'Details', '|'],
                visible: true,
            },
            navigationPaneSettings: { visible: true },
            toolSettings: {
                items: [
                    'Refresh',
                    'View',
                    'Details',
                    'SortBy',
                    'NewFolder',
                    'Delete',
                    'Rename',
                ],
                visible: true,
            },
            projectID: '',
            allowDragAndDrop: true,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            previousPath: null,
        };
    },
    components: {
        ToastMessages,
    },
    provide: {
        filemanager: [DetailsView, NavigationPane, Toolbar],
    },
    mounted: function() {},
    created() {
        this.fileManagerId = 'file-manager' + this.ProjectID;
    },
    methods: {
        fileOpen: function(args) {
            this.directoryID = args.fileDetails.directoryID;
            if (args.module == 'LargeIconsView' && !args.fileDetails.isFile) {
                this.directoryID = args.fileDetails.directoryID;
            } else if (args.fileDetails.isFile) {
                this.$refs.file_instance.downloadFiles();
            }
        },
        created() {
            if (this.path === '/') {
                //root folder
                this.allowDragAndDrop = false;
            } else {
                //not root folder
                this.allowDragAndDrop = true;
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
            }
        },
        onSuccess: function(args) {
            if (args.action == 'Upload') {
                let directory = this.path;
                //remove first and last character if it's a slash
                directory =
                    directory.slice(-1) == '/'
                        ? directory.slice(0, -1)
                        : directory;
                directory =
                    directory.slice(0, 1) == '/'
                        ? directory.substring(1)
                        : directory;
                projectService
                    .UpdateTblDocuments(
                        directory,
                        this.ProjectID,
                        args.result.file.name
                    )
                    .then((res) => {});
            }
        },
        displayFile() {
            setTimeout(function() {
                document
                    .getElementById(this.fileManagerId)
                    .ej2_instances[0].refreshLayout();
            }, 1500);
        },
        onUpload: function(args) {
            let token = commonService.getCookie('accessToken');
            args.ajaxSettings.beforeSend = function(args) {
                //Setting authorization header
                args.httpRequest.setRequestHeader(
                    'Authorization',
                    'Bearer ' + token
                );
            };
        },
        beforeImageLoad: function(args) {
            let token = commonService.getCookie('accessToken');
            args.imageUrl = args.imageUrl + '&access_token=' + token;
        },
        beforeSend: function(args) {
            //set the upload path
            var data = JSON.parse(args.ajaxSettings.data);
            if (Array.isArray(data)) {
                this.path = data[0].path;
            } else {
                this.path = data.path;
            }
            if ( args.action == 'read') {
                if (this.previousPath == null) {
                    this.previousPath = this.path;
                }
                else if (this.previousPath == this.path) {
                    args.cancel = true;
                    this.previousPath = null;
                }
            }
            else {
                this.previousPath = null;
            }
            if (this.path === '/') {
                //do not allow upload button to appear at root folder
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                    ],
                    visible: true,
                };
                //do not allow drag and drop of existing files/folders at root
                this.allowDragAndDrop = false;
                //do not allow drag and drop upload of new files/folders at root
                if (args.action == 'Upload') {
                    args.cancel = true;
                    this.failureToastMessage =
                        'Please select a directory to upload';
                    this.showFailure = true;
                    this.showSuccess = false;
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                }
                setTimeout(() => {
                    //if no path is specified, change syncfusion text to match
                    let emptyMainDiv = document.querySelector(
                        '.e-empty-content'
                    );
                    let emptySubDiv = document.querySelector(
                        '.e-empty-inner-content'
                    );
                }, 600);
            } else {
                //folder selected, access to upload
                this.toolSettings = {
                    items: [
                        'Refresh',
                        'View',
                        'Details',
                        'SortBy',
                        'NewFolder',
                        'Delete',
                        'Rename',
                        'Upload',
                    ],
                    visible: true,
                };
                this.allowDragAndDrop = true;
            }
            // this.$refs.file_instance.toolbarSettings = {
            //   items:toolSettings,
            //   visible: true
            // }
            let token = commonService.getCookie('accessToken');

            data['ProjectId'] = this.ProjectID;
            data['DirectoryID'] = this.DirectoryID;

            args.ajaxSettings.beforeSend = function(args) {
                //Setting authorization header
                args.httpRequest.setRequestHeader(
                    'Authorization',
                    'Bearer ' + token
                );
            };

            args.ajaxSettings.data = JSON.stringify(data);
        },
        beforeDownload: function(args) {
            var data = args.data.data[0];
            var path = data.filterPath + data.name + '/';
            let names = args.data.names;
            data.data = [
                {
                    projectId: data.projectId || this.ProjectID,
                    directoryID: data.directoryID,
                    path: path,
                    action: data.action,
                    newName: data.newName,
                    names: args.data.names,
                    targetPath: data.targetPath,
                    name: data.name,
                    size: data.size,
                    previousName: data.previousName,
                    targetData: data.targetData,
                    dateModified: data.dateModified,
                    dateCreated: data.dateCreated,
                    hasChild: data.hasChild,
                    isFile: data.isFile,
                    type: data.type,
                    id: data.id,
                    filterPath: data.filterPath,
                    filterId: data.filterId,
                    uploadFiles: data.uploadFiles,
                    caseSensitive: data.caseSensitive,
                    searchString: data.searchString,
                    showHiddenItems: data.showHiddenItems,
                    iconClass: data.iconClass,
                    nodeId: data.nodeId,
                    parentID: data.parentID,
                    selected: data.selected,
                    icon: data.icon,
                    data: [{}],
                    renameFiles: data.renameFiles,
                },
            ];
            args.data = data;
            args.cancel = true;
            var arr = [];
            arr.push(this.data);
            var xhr = new XMLHttpRequest();
            xhr.open(
                'POST',
                process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
                true
            );
            xhr.responseType = 'blob';
            xhr.onload = function() {
                if (this.status === 200) {
                    var name = '';
                    let header = xhr.getResponseHeader('Content-Disposition');
                    if (header && header.indexOf('attachment') !== -1) {
                        var regex = /name[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = regex.exec(header);
                        if (matches != null && matches[1])
                            name = matches[1].replace(/['"]/g, '');
                    }
                    var blob = new Blob([this.response], {
                        type: xhr.getResponseHeader('Content-Type'),
                    });
                    var anchorUrl = window.URL.createObjectURL(blob);
                    if (name) {
                        var anchor = document.createElement('a');
                        anchor.href = anchorUrl;
                        anchor.download = name;
                        anchor.click();
                    } else {
                        window.location = anchorUrl;
                    }
                    setTimeout(async function() {
                        if (
                            args.data.type === '.pdf' ||
                            args.data.type === '.txt'
                        ) {
                            var type =
                                args.data.type === '.pdf'
                                    ? 'application/pdf'
                                    : 'text/plain';
                            let blobFIle = await fetch(anchorUrl)
                                .then((r) => r.blob())
                                .then(
                                    (blobFile) =>
                                        new File(
                                            [blobFile],
                                            'fileNameGoesHere',
                                            {
                                                type: type,
                                            }
                                        )
                                );
                            let ad = URL.createObjectURL(blobFIle);
                            window.open(ad, '_blank');
                        }
                        URL.revokeObjectURL(anchorUrl);
                    }, 100);
                }
            };
            var fdata = new FormData();
            fdata.append('downloadInput', JSON.stringify(data));
            let token = commonService.getCookie('accessToken');
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.send(fdata);
        },

        menuClick: function(args) {
            if (args.fileDetails.length) {
                this.testName = args.fileDetails[0].name;
            }
            if (
                args.item.text === 'Manage attributes' ||
                args.item.text === 'Edit attributes'
            ) {
                this.errattributeName = false;
                this.fileDetails = args.fileDetails[0];
                this.directoryID = args.fileDetails[0].directoryID;
                if (this.directoryID) {
                    projectService
                        .getDirectoryManageAttribute(this.directoryID)
                        .then((res) => {
                            if (res.data.length) {
                                this.editAttribute = true;
                                this.editCancel = true;
                                for (let i = 0; i < res.data.length; i++) {
                                    let attributeValues = [];
                                    for (
                                        let j = 0;
                                        j < res.data[i].AttributeValue.length;
                                        j++
                                    ) {
                                        attributeValues.push({
                                            AttributeValueText:
                                                res.data[i].AttributeValue[j]
                                                    .AttributeValueText,
                                            AttributeValueID:
                                                res.data[i].AttributeValue[j]
                                                    .AttributeValueID,
                                        });
                                    }
                                    this.managePreview.push({
                                        AttributeName:
                                            res.data[i].AttributeName,
                                        AttributeValues: attributeValues,
                                        isMultiSelect:
                                            res.data[i].IsMultiSelect,
                                        isFreeText: res.data[i].IsFreeText,
                                        DirectoryAttributeID:
                                            res.data[i].DirectoryAttributeID,
                                        projectId: this.fileDetails.projectId,
                                        DirectoryID: res.data[i].DirectoryID,
                                    });
                                }
                                this.appliedAttribute = true;
                                this.newDirectory = false;
                            } else {
                                this.appliedAttribute = false;
                                this.newDirectory = true;
                                this.editAttribute = false;
                            }
                        });
                }
                if (args.item.text === 'Manage attributes') {
                    this.attributeTitle = 'Manage attributes';
                } else if (args.item.text === 'Edit attributes') {
                    this.attributeTitle = 'Edit attributes';
                }

                this.$refs['attribute-modal'].show();

                this.attributeAssignedName = null;
                this.attributeName = null;
                this.assignedValue = false;
                this.firstTextValue = true;
                this.clicked = false;
                this.clicked2 = false;
                this.viewButton = false;
                // this.this.attrVal = [];
                this.enterAttribute = [{ value: '' }];
                this.attribute = {
                    AttributeName: null,
                    AttributeValues: [{ AttributeValueText: '' }],
                    isMultiSelect: false,
                };
                this.managePreview = [];
            } else if (args.item.text === 'View attributes') {
                this.fileDetails = args.fileDetails[0];
                var filename = this.fileDetails.name;
                projectService
                    .GetDocumentMetadataDetails(this.directoryID, filename)
                    .then((res) => {
                        this.viewAttributes = [];
                        for (var key in res.data) {
                            var splitView = res.data[key].split('|');
                            const newArr = splitView.filter((a) => a);
                            this.viewAttributes.push({
                                attribute: key,
                                attrVal: newArr,
                            });
                        }
                    });
                this.$refs['view-modal'].show();
            } else if (args.item.text === 'File attributes') {
                if (args.fileDetails[0].isFile) {
                    this.fileDetails = args.fileDetails[0];

                    this.filenameDirectory = this.fileDetails.name;
                    this.pathDirectoryEdit =
                        this.fileDetails.projectId +
                        this.fileDetails.filterPath;
                    this.DirectoryIdDirectoryEdit = this.directoryID;
                    this.getFileAttributes();
                    this.$refs['edit-modal'].show();
                } else {
                    args.fileDetails[0].path =
                        args.fileDetails[0].filterPath +
                        args.fileDetails[0].name +
                        '/';
                    args.fileDetails[0].data = [
                        {
                            projectId: args.fileDetails[0].projectId,
                            directoryID: args.fileDetails[0].directoryID,
                            path: args.fileDetails[0].path,
                            action: args.fileDetails[0].action,
                            newName: args.fileDetails[0].newName,
                            names: args.fileDetails[0].names,
                            targetPath: args.fileDetails[0].targetPath,
                            name: args.fileDetails[0].name,
                            size: args.fileDetails[0].size,
                            previousName: args.fileDetails[0].previousName,
                            targetData: args.fileDetails[0].targetData,
                            dateModified: args.fileDetails[0].dateModified,
                            dateCreated: args.fileDetails[0].dateCreated,
                            hasChild: args.fileDetails[0].hasChild,
                            isFile: args.fileDetails[0].isFile,
                            type: args.fileDetails[0].type,
                            id: args.fileDetails[0].id,
                            filterPath: args.fileDetails[0].filterPath,
                            filterId: args.fileDetails[0].filterId,
                            uploadFiles: args.fileDetails[0].uploadFiles,
                            caseSensitive: args.fileDetails[0].caseSensitive,
                            searchString: args.fileDetails[0].searchString,
                            showHiddenItems:
                                args.fileDetails[0].showHiddenItems,
                            iconClass: args.fileDetails[0].iconClass,
                            nodeId: args.fileDetails[0].nodeId,
                            parentID: args.fileDetails[0].parentID,
                            selected: args.fileDetails[0].selected,
                            icon: args.fileDetails[0].icon,
                            data: [{}],
                            renameFiles: args.fileDetails[0].renameFiles,
                        },
                    ];
                    this.currFileDetails = args.fileDetails[0];
                    this.getFileDetails();
                    this.$refs['file-modal'].show();
                }
            } else if (args.item.text === 'Open') {
                this.directoryID = args.fileDetails[0].directoryID;
            } else if (args.item.text === 'Rename') {
                this.DirectoryID = args.fileDetails[0].directoryID;
            }
        },
    },
    computed: {
        ProjectID() {
            return parseInt(
                this.$store.state.projects.selectedProjects[0].ProjectID
            );
        },
        // toolbarSettings(){
        //   this.toolSettings.push("upload");
        //   this.$refs.file_instance.toolbarSettings = {
        //     items:toolBarSettings,
        //     visible: true
        //   }
        //
        //
        //   return;
        // },
        ajaxSettings() {
            let output = {
                url:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureFileOperations',
                getImageUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureGetImage?projectID=' +
                    this.ProjectID,
                uploadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureUpload?projectID=' +
                    this.ProjectID,
                downloadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
            };
            return output;
        },
    },
};
</script>
<style scoped>
/* styles the upload response popup */
.e-popup.e-popup-open.e-dialog {
    width: 400px !important;
}
.e-upload .e-upload-files {
    width: 365px !important;
}
.e-upload .e-upload-files .e-upload-file-list {
    width: inherit !important;
}
.fileManager
    .e-upload
    .e-upload-files
    .e-upload-file-list
    .e-file-container
    .e-file-type {
    padding: 12px 0px 0px 0px !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-size {
    margin-top: 4px !important;
}
</style>
