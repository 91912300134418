<template>
    <l-control class="emControl" :position="position" @ready="identifyReady">
        <button
            :disabled="disabled"
            type="button"
            :class="isActive ? 'btn-black' : 'btn-white'"
            class="btn"
            @click="identify"
        >
            <slot name="identifyIcon">i</slot>
        </button>
    </l-control>
</template>

<script>
import { mapState } from 'vuex';
import { LControl } from 'vue2-leaflet';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { spatialService } from '../../../services/spatial.services';
import { commonService } from '../../../services/common.service';
import axios from 'axios';

export default {
    name: 'LControlIdentify',
    components: {
        LControl,
    },
    props: {
        position: {
            type: String,
            default: 'topright',
            required: false,
        },
        mapLayers: {
            required: true,
            type: Array,
            default: () => [],
        },
        mapZoom: {
            required: false,
            type: Number,
            default: 19,
        },
        projLayers: {
            type: Array,
            default: () => [],
            required: false,
        },
        isActive: Boolean,
        disabled: { type: Boolean, required: false, default: false },
    },
    computed: {
        ...mapState('projects', {
            projectName: (state) => state.selectedProjects[0].ProjectName,
        }),
    },
    mounted() {
        this.$parent.$parent.$on('map/click', this.handleMouseClick);
        this.$emit('ready', this.mapObject);
    },
    methods: {
        identify() {
            if (this.$parent.$parent.clickFunction === 'identify') {
                this.$parent.$parent.clickFunction = '';
                this.$emit('identifyIcon');
            } else {
                this.$parent.$parent.clickFunction = 'identify';
                this.$emit('identifyIcon');
            }
        },
        getIdentifyIDs() {
            //mapLayers:[{projectName: "projname" , projectLayers:[1, 2, 3], wmsLayers:[1, 2, 3]}]
            var idLayers = [];

            var mapLayerIDs = [];
            this.mapLayers.forEach((mapLayer) => {
                // check that the layer is turned on and has a mapLayerID
                if (mapLayer.isChecked && mapLayer.mapLayerID) {
                    mapLayerIDs.push(mapLayer.mapLayerID);
                }
            });

            // just using a lot of hardcoded structure here to wrap the older format return
            return [
                {
                    projectName: this.projectName,
                    projectLayers: [],
                    wmsLayers: mapLayerIDs,
                },
            ];
        },
        addIdBuffer(geom) {
            this.$parent.$parent.bufferMarkers.push(geom);
        },
        clearIdBuffer() {
            this.$emit('clearIdBuffer');
        },
        addIdentifyMarker(lat, lng) {
            let bufferGeom = {
                id: 'IdBuffer',
                geometry: {
                    coordinates: [lat, lng],
                },
                styling: {
                    PolygonBorderColour: 'blue',
                    PolygonColour: 'light blue',
                    PolygonStyle: '10,10',
                    PolygonTransparency: 0,
                    SymbologySize: 0.3 * Math.pow(2, 22 - this.mapZoom),
                },
            };
            this.clearIdBuffer();
            this.addIdBuffer(bufferGeom);
            this.$parent.$parent.clickFunction == '';
        },
        async handleMouseClick(event) {
            var [lat, lng] = spatialFunctions.validateCoordinates(event);

            if (this.$parent.$parent.clickFunction == 'identify') {
                var submitObj = {
                    type: 'Point',
                    bufferDistance: 0.3 * Math.pow(2, 22 - this.mapZoom),
                    geometry: spatialFunctions.convertToWKT({
                        geometry: {
                            coordinates: [lat, lng],
                            type: 'Point',
                            coordSys: 'LatLong',
                        },
                    }),
                    mapLayers: this.getIdentifyIDs(),
                    projectID: this.$store.state.emergencyManagement.projectID,
                };
                this.addIdentifyMarker(lat, lng);
                this.$parent.$parent.clickFunction = '';
                this.$emit('identifyIcon');
                let spatialServiceReturn = await spatialService.mapLayers(
                    submitObj
                );
                if (spatialServiceReturn.status == 200) {
                    let layerDescription = '';
                    for (let i = 0; i < spatialServiceReturn.data.length; i++) {
                        for (
                            let j = 0;
                            j < spatialServiceReturn.data[i].Geometry.length;
                            j++
                        ) {
                            //need new logic to decide if this geometry/layer is NS asset
                            spatialServiceReturn.data[i].Geometry[
                                j
                            ].properties.forEach((property) => {
                                if (property.label == 'LayerDescription') {
                                    layerDescription = property.value;
                                }
                            });
                            if (!layerDescription.startsWith('NS Asset')) {
                                //handle non-NS-Asset layer photos/documents
                                if (
                                    spatialServiceReturn.data[i].Geometry[j]
                                        .documents
                                ) {
                                    for (
                                        let y = 0;
                                        y <
                                        spatialServiceReturn.data[i].Geometry[j]
                                            .documents.length;
                                        y++
                                    ) {
                                        if (
                                            spatialServiceReturn.data[
                                                i
                                            ].Geometry[j].documents[
                                                y
                                            ].FileName.toLowerCase().indexOf(
                                                'png'
                                            ) > 0 ||
                                            spatialServiceReturn.data[
                                                i
                                            ].Geometry[j].documents[
                                                y
                                            ].FileName.toLowerCase().indexOf(
                                                'jpg'
                                            ) > 0 ||
                                            spatialServiceReturn.data[
                                                i
                                            ].Geometry[j].documents[
                                                y
                                            ].FileName.toLowerCase().indexOf(
                                                'jpeg'
                                            ) > 0
                                        ) {
                                            spatialServiceReturn.data[
                                                i
                                            ].Geometry[j].documents[y].src =
                                                'data:image/jpg; base64, iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEVBMTczNDg3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEVBMTczNDk3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRUExNzM0NjdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRUExNzM0NzdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjjUmssAAAGASURBVHjatJaxTsMwEIbpIzDA6FaMMPYJkDKzVYU+QFeEGPIKfYU8AETkCYI6wANkZQwIKRNDB1hA0Jrf0rk6WXZ8BvWkb4kv99vn89kDrfVexBSYgVNwDA7AN+jAK3gEd+AlGMGIBFDgFvzouK3JV/lihQTOwLtOtw9wIRG5pJn91Tbgqk9kSk7GViADrTD4HCyZ0NQnomi51sb0fUyCMQEbp2WpU67IjfNjwcYyoUDhjJVcZBjYBy40j4wXgaobWoe8Z6Y80CJBwFpunepIzt2AUgFjtXXshNXjVmMh+K+zzp/CMs0CqeuzrxSRpbOKfdCkiMTS1VBQ41uxMyQR2qbrXiiwYN3ACh1FDmsdK2Eu4J6Tlo31dYVtCY88h5ELZIJJ+IRMzBHfyJINrigNkt5VsRiub9nXICdsYyVd2NcVvA3ScE5t2rb5JuEeyZnAhmLt9NK63vX1O5Pe8XaPSuGq1uTrfUgMEp9EJ+CQvr+BJ/AAKvAcCiAR+bf9CjAAluzmdX4AEIIAAAAASUVORK5CYII=';
                                            let downloadDocumentByIDReturn = await commonService.downloadThumbnailByID(
                                                spatialServiceReturn.data[i]
                                                    .Geometry[j].documents[y]
                                                    .DocumentId,
                                                spatialServiceReturn.data[i]
                                                    .Geometry[j].documents[y]
                                                    .FileName,
                                                'image'
                                            );
                                            spatialServiceReturn.data[
                                                i
                                            ].Geometry[j].documents[y].src =
                                                'data:image/png; base64, ' +
                                                downloadDocumentByIDReturn;
                                        }
                                    }
                                    //sort documents array so photos show up first, then documents
                                    spatialServiceReturn.data[i].Geometry[
                                        j
                                    ].documents.sort((a, b) => {
                                        if (a.src) {
                                            return -1;
                                        }
                                        if (b.src) {
                                            return 1;
                                        }
                                        return 0;
                                    });
                                }
                            } else {
                                //handle NS asset layer photos/documents
                                let NSFeatureServiceLayerBaseUrl =
                                    'https://nsfederated.gis.nscorp.com/arcgis/rest/services/SafetyEnvironmental/SafetyEnvironmental_RO/FeatureServer';
                                let NSToken = this.$parent.$parent.NSToken;
                                //convert geometry.properties label/value array to an object
                                let geomObj = {};
                                spatialServiceReturn.data[i].Geometry[
                                    j
                                ].properties.forEach((prop) => {
                                    geomObj[prop.label] = prop.value;
                                });
                                //need reference from Adapt layer ID to NS feature service layer ID, but it is static
                                // properties[0] is the Description, which is 'NS Asset - <ns map service layer number>'
                                let NSFeatureServiceLayerID = parseInt(
                                    layerDescription.match(/\d+/)[0]
                                );
                                // properties[0] is the NS feature OBJECTID
                                geomObj.OBJECTID =
                                    spatialServiceReturn.data[i].Geometry[
                                        j
                                    ].properties[0].value;
                                //call NS feature service with token, layer ID, current geometry's OBJECTID to query if any attachments are present, and if so, push to documents array
                                let NSFeatureServiceReturn = await axios.get(
                                    `${NSFeatureServiceLayerBaseUrl}/${NSFeatureServiceLayerID}/${geomObj.FeatureID}/attachments/?token=${NSToken}&f=json`,
                                    {
                                        transformRequest: (data, headers) => {
                                            delete headers.common;
                                        },
                                    }
                                );
                                NSFeatureServiceReturn.data.attachmentInfos.forEach(
                                    (item) => {
                                        spatialServiceReturn.data[i].Geometry[
                                            j
                                        ].documents.push({
                                            name: item.name,
                                            src: `${NSFeatureServiceLayerBaseUrl}/${NSFeatureServiceLayerID}/${geomObj.FeatureID}/attachments/${item.id}?token=${NSToken}`,
                                        });
                                    }
                                );
                            }
                        }
                        this.$emit(
                            'identifyCompleted',
                            spatialServiceReturn.data
                        );
                    }

                    this.$parent.$parent.clickFunction = '';
                    this.$emit('identifyIcon');
                }
            }
        },
        identifyReady() {},
    },
};
</script>

<style scoped>
.btn-white {
    background-color: white;
}
.btn-black {
    background-color: black;
}
.btn {
    height: 45px;
    width: 45px;
    padding-left: 6px !important;
}
</style>
