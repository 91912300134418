<template>
    <div class="managedatasource" v-if="showPage">
        <Header v-bind:showVersionToggle="false" />
        <b-container fluid class="managedata sub_header_bg p-0">
            <b-container class="p-0">
                <b-row class="mb-3">
                    <b-col cols="12 my-1">
                        <a href="javascript:void(0)" @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a href="#" class="floar-left ml-1 back">
                                Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>
                <b-row
                    class="vh-10 text-left"
                    align-v="center"
                    v-if="manageHeader"
                >
                    <b-col cols="4" md="6" lg="6" class="pt-2">
                        <h1 class="">Datasources</h1>
                    </b-col>
                    <b-col cols="2" md="3" lg="4" class="p-0"> </b-col>
                    <b-col cols="6" md="3" lg="2" class="text-right pl-0">
                        <b-button
                            rounded
                            class="buttonSelect ml-3"
                            @click="addNewDataSource(true)"
                        >
                            Add datasource
                        </b-button>
                    </b-col>
                </b-row>
                <b-row
                    class="vh-10 text-left"
                    align-v="center"
                    v-if="saveHeader"
                >
                    <b-col cols="6" class="pt-2">
                        <h1 class="">Datasources</h1>
                    </b-col>
                    <b-col
                        cols="6"
                        class="text-right pl-0 m-none"
                        v-if="testConnection && floatLabelAdd == 'Add'"
                    >
                        <span
                            id="disabled-wrapper"
                            class="d-inline-block"
                            tabindex="0"
                        >
                            <span
                                rounded
                                variant="outline-primary"
                                class="buttonNew pointer mr-4 text-primary m-none"
                                @click="closeNewData()"
                                >Cancel</span
                            >
                            <b-button
                                rounded
                                class="buttonSelect"
                                @click="createDataSource('testConnection')"
                            >
                                Test connection
                            </b-button>
                        </span>
                        <b-tooltip
                            v-if="validateInput"
                            target="disabled-wrapper"
                            >Complete all fields to test</b-tooltip
                        >
                    </b-col>

                    <b-col class="text-right pl-0" v-if="createConnection">
                        <b-button
                            rounded
                            class="buttonSelect ml-3"
                            @click="createDataSource('createConnection')"
                        >
                            Save datasource
                        </b-button>
                    </b-col>
                    <b-col class="text-right pl-0" v-if="updateConnection">
                        <b-button
                            rounded
                            class="buttonSelect ml-3"
                            @click="updateDataSource('updateConnection')"
                        >
                            Save datasource
                        </b-button>
                    </b-col>
                    <b-col
                        class="text-right pl-0 m-none"
                        v-if="floatLabelEdit == 'Edit' && testConnection"
                    >
                        <span
                            rounded
                            variant="outline-primary"
                            class="buttonNew pointer mr-4 text-primary m-none"
                            @click="closeData()"
                            >Cancel</span
                        >
                        <b-button
                            rounded
                            class="buttonSelect ml-3"
                            :disabled="handleBlur() || isServerName"
                            @click="updateDataSource('testConnection')"
                        >
                            Test connection
                        </b-button>
                    </b-col>
                </b-row>

                <b-card no-body class="data-source-m">
                    <b-tabs
                        ref="tabs"
                        v-model="step"
                        class="tabs pt-2 manage-data"
                        active-nav-item-class="pl-0 pr-0 borderBlue"
                        v-on:activate-tab="selectedTab"
                        v-on:changed="activateTab"
                    >
                        <b-tab title="Datasources">
                            <div class="col-lg-12 control-section p-0">
                                <b-row class="my-2 m-row m-0 search-grid">
                                    <div class="filter-section m-shadow">
                                        <div class="float-left">
                                            <img
                                                class="pointer ml-2 mt-1"
                                                src="../../assets/search-outline.svg"
                                                v-if="
                                                    !filterDataSearch &&
                                                    !showSearchIcon
                                                        ? true
                                                        : false
                                                "
                                                @click="searchOpen"
                                            />
                                            <input
                                                ref="datasourceSearch"
                                                spellcheck="false"
                                                id="searchFilter"
                                                class="m-none"
                                                v-if="
                                                    filterDataSearch ||
                                                    showSearchIcon
                                                        ? true
                                                        : false
                                                "
                                                v-model="filterDataSearch"
                                                @click="searchinputenter()"
                                                @input="searchinputenter"
                                                :class="
                                                    filterDataSearch ||
                                                    showSearchIcon
                                                        ? 'test'
                                                        : 'searchinputList'
                                                "
                                                type="search"
                                                placeholder="Search for Datasource"
                                            />
                                            <span
                                                class="grey-bg pointer"
                                                v-if="searchClose"
                                                @click="closeBar"
                                                ><img
                                                    src="../../assets/close.svg"
                                            /></span>
                                            <span class="border-right list ml-3"
                                                >&nbsp;</span
                                            >
                                        </div>
                                        <div class="float-left filter-block1">
                                            <span
                                                class="ml-3 mr-3 pointer m-filter"
                                                @click="filterClick"
                                                ><img
                                                    src="../../assets/tooltip.svg"
                                            /></span>
                                            <span
                                                class="blue-text pointer m-none"
                                                @click="filterClick"
                                                >Filter</span
                                            >
                                            <b-list-group-item
                                                class="filter-group left-align p-0 filter-block"
                                                v-if="filterShow"
                                            >
                                                <div class="ml-4 mt-3 data-flt">
                                                    <div
                                                        class="mb-1 float-left"
                                                    >
                                                        Filter by
                                                    </div>
                                                    <div
                                                        class="text-right pr-3"
                                                    >
                                                        <a
                                                            href="javascript:void(0)"
                                                        >
                                                            <b-icon
                                                                icon="x"
                                                                font-scale="2"
                                                                @click="
                                                                    closeFilter
                                                                "
                                                            >
                                                            </b-icon>
                                                        </a>
                                                    </div>
                                                    <div class="mt-2">
                                                        <CustomFloatingLabel
                                                            :config="{
                                                                label:
                                                                    'Datasource name',
                                                            }"
                                                        >
                                                            <input
                                                                type="text"
                                                                class="normal_input filter_input"
                                                                v-model="
                                                                    DataSourceNameDrop
                                                                "
                                                            />
                                                        </CustomFloatingLabel>
                                                        <!-- <label for="">Datasource name</label>
                            <input type="text"   class="normal_input filter_input" v-model="DataSourceNameDrop"> -->
                                                        <!--<b-form-select :options="datasources.items" v-model="DataSourceNameDrop" text-field="DataSourceName" value-field="DataSourceName" class="filterSelect"></b-form-select>-->
                                                        <!-- <label for="">Connection type</label>
                            <b-form-select :options="dbType" v-model="ConnectionTool" text-field="Value" value-field="Value" class="filterSelect">
                              
                            </b-form-select> -->

                                                        <CustomFloatingLabel
                                                            class="FloatingTool"
                                                            :config="{
                                                                label:
                                                                    'Connection type',
                                                                name: 'wrapper',
                                                                line: false,
                                                                scale: false,
                                                            }"
                                                        >
                                                            <b-form-select
                                                                v-model="
                                                                    ConnectionTool
                                                                "
                                                                class="mb-3"
                                                                :options="
                                                                    dbType
                                                                "
                                                                text-field="Value"
                                                                value-field="Value"
                                                            >
                                                                <!-- <option disabled value="">Connection type</option> -->
                                                                <template
                                                                    #first
                                                                >
                                                                    <b-form-select-option
                                                                        :value="
                                                                            null
                                                                        "
                                                                        disabled
                                                                        >Connection
                                                                        type</b-form-select-option
                                                                    >
                                                                </template>
                                                            </b-form-select>
                                                        </CustomFloatingLabel>
                                                    </div>
                                                    <b-button
                                                        class="mb-3"
                                                        v-on:click="applyFilter"
                                                        >Apply filters</b-button
                                                    >
                                                </div>
                                            </b-list-group-item>
                                        </div>
                                    </div>
                                    <div
                                        class="col-12 col-lg-4 col-md-6"
                                        v-if="filterLegacy"
                                    >
                                        <div class="pb-2">
                                            <!-- <div style="background-color: #e9ecef;" >
                              <b-row class="pl-3" v-if="filterLegacy" >
                              Filtering By 
                              </b-row>
                          </div> -->
                                            <b-row
                                                class="ml-0"
                                                v-if="filterLegacy"
                                            >
                                                <div
                                                    class="px-0 py-1"
                                                    v-if="DataSourceNameDrop"
                                                >
                                                    <span
                                                        class="filteredItem px-2 py-1 mr-2 text-center"
                                                    >
                                                        {{ DataSourceNameDrop }}
                                                        <b-icon
                                                            icon="x"
                                                            font-scale="1"
                                                            @click="
                                                                backFilter(
                                                                    'DataSourceName'
                                                                )
                                                            "
                                                        >
                                                        </b-icon>
                                                    </span>
                                                </div>
                                                <div
                                                    class="px-0 py-1"
                                                    v-if="ConnectionTool"
                                                >
                                                    <span
                                                        class="filteredItem px-2 py-1 mr-2 text-center"
                                                    >
                                                        {{ ConnectionTool }}
                                                        <b-icon
                                                            icon="x"
                                                            font-scale="1"
                                                            @click="
                                                                backFilter(
                                                                    'ConnectionTool'
                                                                )
                                                            "
                                                        >
                                                        </b-icon>
                                                    </span>
                                                </div>
                                                <div
                                                    class="removeFilter blue-text"
                                                >
                                                    <span
                                                        @click="
                                                            removeFilterLegacy
                                                        "
                                                        v-if="
                                                            removeLegacyFilter
                                                        "
                                                    >
                                                        Remove all filters
                                                    </span>
                                                </div>
                                            </b-row>
                                        </div>
                                    </div>
                                </b-row>
                                <ejs-grid
                                    :dataSource="
                                        applyfilterDatasource
                                            ? dataSourceLegacy
                                            : datasources.items
                                    "
                                    class="sortingenabled"
                                    ref="grid"
                                    :pageSettings="pageSettings"
                                    :allowPaging="true"
                                    :allowSorting="true"
                                    :commandClick="gridRowData"
                                    :load="pageCenter"
                                    :dataBound="dataBound"
                                    :sortSettings="sortOptions"
                                    :allowResizing="true"
                                >
                                    <e-columns>
                                        <e-column
                                            headerText="Edit"
                                            title="Edit"
                                            width="50"
                                            :commands="editCommands"
                                        ></e-column>
                                        <!-- <e-column field='icon' headerText='Edit' :template='editColumn' width='60'></e-column> -->
                                        <!-- <e-column headerText='Acion' width=70 :commands='action'></e-column> -->
                                        <e-column
                                            field="DataSourceName"
                                            headerText="Name"
                                            width="150"
                                        ></e-column>
                                        <e-column
                                            field="DataSourceType"
                                            headerText="Type"
                                            width="100"
                                        ></e-column>
                                        <e-column
                                            field="ServerName"
                                            headerText="Server name"
                                            width="150"
                                        ></e-column>
                                        <e-column
                                            field="ConnectionTool"
                                            headerText="Connection tool"
                                            width="150"
                                        ></e-column>
                                        <e-column
                                            field="ConnectionString"
                                            headerText="Connection information"
                                            width="200"
                                        ></e-column>
                                        <e-column
                                            field="ProjectTable"
                                            headerText="Project table"
                                            width="150"
                                        ></e-column>
                                        <e-column
                                            field="ProjectColumn"
                                            headerText="Project field"
                                            width="150"
                                        ></e-column>
                                        <e-column
                                            field="ProjectDescColumn"
                                            headerText="Project description"
                                            width="200"
                                        ></e-column>
                                        <e-column
                                            field="Delete"
                                            title="Delete"
                                            textAlign="Center"
                                            freeze="Right"
                                            :commands="actionCommands"
                                            headerText="Delete"
                                            width="60"
                                        ></e-column>
                                    </e-columns>
                                </ejs-grid>
                            </div>
                        </b-tab>

                        <!-- New Tab -->
                        <b-tab
                            v-for="i in newtab"
                            :key="'datasource' + i"
                            @click="addNewDataSource(false)"
                        >
                            <template #title>
                                Add datasource
                                <img
                                    src="../../assets/close.svg"
                                    @click="closeTab(i)"
                                />
                            </template>
                            <b-container>
                                <b-row class="mt-5">
                                    <b-col
                                        cols="6"
                                        class="blue-color p-0 addDataCol"
                                    >
                                        <b-form
                                            class="addData"
                                            v-if="newDataSourse"
                                        >
                                            <!-- Gevindu -->
                                            <!-- <pre>{{$v}}</pre> -->
                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Datasource name',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    validated('DataSourceName')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'DataSourceName'
                                                        )
                                                    "
                                                    name="DataSourceName"
                                                    v-model="
                                                        $v.addItem
                                                            .DataSourceName
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="dataSourceName-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v.addItem
                                                            .DataSourceName
                                                            .required
                                                    "
                                                    class="invalid-text-custom"
                                                    id="dataSourceName-feedback"
                                                >
                                                    Please enter datasource name
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v.addItem
                                                            .DataSourceName
                                                            .isUnique
                                                    "
                                                    class="invalid-text-custom"
                                                    id="dataSourceName-feedback"
                                                >
                                                    Please enter a unique
                                                    datasource name
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomValidateInput
                                                :config="{
                                                    label:
                                                        'Datasource description',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    validated('DataSourceDesc')
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        validateState(
                                                            'DataSourceDesc'
                                                        )
                                                    "
                                                    name="DataSourceDesc"
                                                    v-model="
                                                        $v.addItem
                                                            .DataSourceDesc
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="dataSourceDesc-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="dataSourceDesc-feedback"
                                                >
                                                    Please enter datasource
                                                    description
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Datasource type',
                                                }"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        v-model="
                                                            $v.addItem
                                                                .DataSourceType
                                                                .$model
                                                        "
                                                        :options="dbType"
                                                        text-field="Value"
                                                        value-field="Value"
                                                        :state="
                                                            validateState(
                                                                'DataSourceType'
                                                            )
                                                        "
                                                        @change="
                                                            selectDbType(
                                                                addItem.DataSourceType
                                                            )
                                                        "
                                                        aria-describedby="dataSourceType-feedback"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >Datasource
                                                                type</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="dataSourceType-feedback"
                                                    >
                                                        Please select a
                                                        datasource type
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <div v-if="sqlType">
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Server name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated('ServerName')
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'ServerName'
                                                            )
                                                        "
                                                        name="ServerName"
                                                        v-model="
                                                            $v.addItem
                                                                .ServerName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="serverName-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="serverName-feedback"
                                                    >
                                                        Please enter server name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Database name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated(
                                                            'DatabaseName'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'DatabaseName'
                                                            )
                                                        "
                                                        name="DatabaseName"
                                                        v-model="
                                                            $v.addItem
                                                                .DatabaseName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="databaseName-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="databaseName-feedback"
                                                    >
                                                        Please enter database
                                                        name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Username',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated('Username')
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'Username'
                                                            )
                                                        "
                                                        name="Username"
                                                        v-model="
                                                            $v.addItem.Username
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="username-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="username-feedback"
                                                    >
                                                        Please enter username
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Password',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated('Password')
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'Password'
                                                            )
                                                        "
                                                        :type="inputType"
                                                        name="Password"
                                                        v-model="
                                                            $v.addItem.Password
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="password-feedback"
                                                    >
                                                    </b-form-input>
                                                    <span
                                                        class="password_eye"
                                                        v-if="
                                                            !$v.addItem.Password
                                                                .$error &&
                                                                shouldShowCustomIcon()
                                                        "
                                                        @click="showPassword"
                                                        ><img
                                                            src="../../assets/password_eye.svg"
                                                            style="cursor: pointer;"
                                                    /></span>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="password-feedback"
                                                    >
                                                        Please enter password
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <div class="admin mt-1">
                                                    <small
                                                        >Contact your
                                                        administrator if you
                                                        need to reset your
                                                        password.</small
                                                    >
                                                </div>
                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Legacy project table (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    v-bind:label="floatLabelAdd"
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="
                                                            addItem.ProjectTable
                                                        "
                                                        @blur="handleBlur"
                                                        autocomplete="off"
                                                        class="normal_input"
                                                    />
                                                </CustomFloatingLabel>
                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Legacy project column (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    v-bind:label="floatLabelAdd"
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="
                                                            addItem.ProjectColumn
                                                        "
                                                        autocomplete="off"
                                                        @blur="handleBlur"
                                                        class="normal_input"
                                                    />
                                                </CustomFloatingLabel>
                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Legacy project description column (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    v-bind:label="floatLabelAdd"
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="
                                                            addItem.ProjectDescColumn
                                                        "
                                                        autocomplete="off"
                                                        @blur="handleBlur"
                                                        class="normal_input"
                                                    />
                                                </CustomFloatingLabel>
                                            </div>

                                            <div v-if="geoDbType">
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Database name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated(
                                                            'DatabaseName'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'DatabaseName'
                                                            )
                                                        "
                                                        name="DatabaseName"
                                                        v-model="
                                                            $v.addItem
                                                                .DatabaseName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="databaseName-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="databaseName-feedback"
                                                    >
                                                        Please enter database
                                                        name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Database path',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated('Location')
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'Location'
                                                            )
                                                        "
                                                        name="Location"
                                                        v-model="
                                                            $v.addItem.Location
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="location-feedback"
                                                        @blur="handleBlur"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="location-feedback"
                                                    >
                                                        Please enter database
                                                        path
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>
                                            </div>
                                            <!-- PowerBI datasource type dropdown -->
                                            <div v-if="powerBIDbType">
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Workspace Name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated(
                                                            'WorkspaceName'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'WorkspaceName'
                                                            )
                                                        "
                                                        name="WorkspaceName"
                                                        v-model="
                                                            $v.addItem
                                                                .WorkspaceName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="location-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="location-feedback"
                                                    >
                                                        Please enter workspace
                                                        name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Workspace ID',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        validated('WorkspaceID')
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            validateState(
                                                                'WorkspaceID'
                                                            )
                                                        "
                                                        name="WorkspaceID"
                                                        v-model="
                                                            $v.addItem
                                                                .WorkspaceID
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="location-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="location-feedback"
                                                    >
                                                        Please enter workspace
                                                        ID
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>
                                            </div>
                                        </b-form>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-tab>
                        <b-tab
                            v-for="j in editTab"
                            :key="'editDatasource' + j"
                            @click="selectedEditTab(j.DataSourceId)"
                        >
                            <template #title>
                                Edit {{ j.DataSourceName }}
                                <img
                                    src="../../assets/close.svg"
                                    @click="closeEditTab(j)"
                                />
                            </template>
                            <b-container>
                                <b-row class="mt-5">
                                    <b-col
                                        cols="6"
                                        class="blue-color p-0 bottom-m-100"
                                    >
                                        <b-form v-if="!newDataSourse">
                                            <!-- Gevindu -->
                                            <CustomValidateInput
                                                :config="{
                                                    label: 'Datasource name',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    editValidated(
                                                        'DataSourceName'
                                                    )
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        edditValidateState(
                                                            'DataSourceName'
                                                        )
                                                    "
                                                    name="DataSourceName"
                                                    v-model="
                                                        $v.editItem
                                                            .DataSourceName
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="dataSourceName-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v.editItem
                                                            .DataSourceName
                                                            .required
                                                    "
                                                    class="invalid-text-custom"
                                                    id="dataSourceName-feedback"
                                                >
                                                    Please enter datasource name
                                                </b-form-invalid-feedback>
                                                <b-form-invalid-feedback
                                                    v-if="
                                                        !$v.editItem
                                                            .DataSourceName
                                                            .isUnique
                                                    "
                                                    class="invalid-text-custom"
                                                    id="dataSourceName-feedback"
                                                >
                                                    Please enter a unique
                                                    datasource name
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomValidateInput
                                                :config="{
                                                    label:
                                                        'Datasource description',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                :isValid="
                                                    editValidated(
                                                        'DataSourceDesc'
                                                    )
                                                "
                                            >
                                                <b-form-input
                                                    :state="
                                                        edditValidateState(
                                                            'DataSourceDesc'
                                                        )
                                                    "
                                                    name="DataSourceDesc"
                                                    v-model="
                                                        $v.editItem
                                                            .DataSourceDesc
                                                            .$model
                                                    "
                                                    autocomplete="off"
                                                    aria-describedby="dataSourceDesc-feedback"
                                                >
                                                </b-form-input>
                                                <b-form-invalid-feedback
                                                    class="invalid-text-custom"
                                                    id="dataSourceDesc-feedback"
                                                >
                                                    Please enter datasource
                                                    description
                                                </b-form-invalid-feedback>
                                            </CustomValidateInput>

                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Datasource type',
                                                }"
                                            >
                                                <b-form-group>
                                                    <b-form-select
                                                        disabled
                                                        v-model="
                                                            $v.editItem
                                                                .DataSourceType
                                                                .$model
                                                        "
                                                        :options="dbType"
                                                        text-field="Value"
                                                        value-field="Value"
                                                        :state="
                                                            edditValidateState(
                                                                'DataSourceType'
                                                            )
                                                        "
                                                        aria-describedby="dataSourceType-feedback"
                                                    >
                                                        <template #first>
                                                            <b-form-select-option
                                                                :value="null"
                                                                disabled
                                                                >Datasource
                                                                type</b-form-select-option
                                                            >
                                                        </template>
                                                    </b-form-select>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="dataSourceType-feedback"
                                                    >
                                                        Please select a
                                                        datasource type
                                                    </b-form-invalid-feedback>
                                                </b-form-group>
                                            </CustomFloatingLabel>

                                            <div
                                                v-if="
                                                    editItem.DataSourceType ==
                                                        'SqlServer'
                                                "
                                            >
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Server name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        editValidated(
                                                            'ServerName'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            edditValidateState(
                                                                'ServerName'
                                                            )
                                                        "
                                                        name="ServerName"
                                                        v-model="
                                                            $v.editItem
                                                                .ServerName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="serverName-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="serverName-feedback"
                                                    >
                                                        Please enter server name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Database name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        editValidated(
                                                            'DatabaseName'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            edditValidateState(
                                                                'DatabaseName'
                                                            )
                                                        "
                                                        name="DatabaseName"
                                                        v-model="
                                                            $v.editItem
                                                                .DatabaseName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="databaseName-feedback"
                                                        @blur="handleBlur"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="databaseName-feedback"
                                                    >
                                                        Please enter database
                                                        name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Username',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        editValidated(
                                                            'Username'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            edditValidateState(
                                                                'Username'
                                                            )
                                                        "
                                                        name="Username"
                                                        v-model="
                                                            $v.editItem.Username
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="username-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="username-feedback"
                                                    >
                                                        Please enter username
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Password',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        editValidated(
                                                            'Password'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            edditValidateState(
                                                                'Password'
                                                            )
                                                        "
                                                        :type="inputType"
                                                        name="Password"
                                                        v-model="
                                                            $v.editItem.Password
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="password-feedback"
                                                    >
                                                    </b-form-input>
                                                    <span
                                                        class="password_eye"
                                                        v-if="
                                                            !$v.editItem
                                                                .Password.$error
                                                        "
                                                        @click="showPassword"
                                                        ><img
                                                            src="../../assets/password_eye.svg"
                                                    /></span>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="password-feedback"
                                                    >
                                                        Please enter password
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Legacy project table (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    v-bind:label="
                                                        floatLabelEdit
                                                    "
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="
                                                            editItem.ProjectTable
                                                        "
                                                        autocomplete="off"
                                                        @blur="handleBlur"
                                                        class="normal_input"
                                                    />
                                                </CustomFloatingLabel>
                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Legacy project column (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    v-bind:label="
                                                        floatLabelEdit
                                                    "
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="
                                                            editItem.ProjectColumn
                                                        "
                                                        autocomplete="off"
                                                        @blur="handleBlur"
                                                        class="normal_input"
                                                    />
                                                </CustomFloatingLabel>
                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Legacy project description column (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    v-bind:label="
                                                        floatLabelEdit
                                                    "
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="
                                                            editItem.ProjectDescColumn
                                                        "
                                                        autocomplete="off"
                                                        @blur="handleBlur"
                                                        class="normal_input"
                                                    />
                                                </CustomFloatingLabel>
                                            </div>

                                            <div
                                                v-if="
                                                    editItem.DataSourceType ==
                                                        'GeoDB'
                                                "
                                            >
                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Database name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        editValidated(
                                                            'DatabaseName'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            edditValidateState(
                                                                'DatabaseName'
                                                            )
                                                        "
                                                        name="DatabaseName"
                                                        v-model="
                                                            $v.editItem
                                                                .DatabaseName
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="databaseName-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="databaseName-feedback"
                                                    >
                                                        Please enter database
                                                        name
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>

                                                <CustomValidateInput
                                                    :config="{
                                                        label: 'Database path',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                    :isValid="
                                                        editValidated(
                                                            'Location'
                                                        )
                                                    "
                                                >
                                                    <b-form-input
                                                        :state="
                                                            edditValidateState(
                                                                'Location'
                                                            )
                                                        "
                                                        name="Location"
                                                        v-model="
                                                            $v.editItem.Location
                                                                .$model
                                                        "
                                                        autocomplete="off"
                                                        aria-describedby="location-feedback"
                                                    >
                                                    </b-form-input>
                                                    <b-form-invalid-feedback
                                                        class="invalid-text-custom"
                                                        id="location-feedback"
                                                    >
                                                        Please enter location
                                                    </b-form-invalid-feedback>
                                                </CustomValidateInput>
                                            </div>
                                        </b-form>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-container>
        </b-container>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :showConnecting="showConnecting"
            :failureToastMessage="failureToastMessage"
            :connectingToastMessage="connectingToastMessage"
            :successToastMessage="successToastMessage"
        />
        <!-- <div class="successToastMsg" v-if="successToast">
                        <img src="../../assets/success_tick.svg" class="mr-3"/>
                        Success  <br/> {{successToastValue}} 
                        <img src="../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
                    </div>
                    <div class="failureToastMsg" v-if="failureToast">
                        <img src="../../assets/failure_cross.svg" class="mr-3"/>
                        <b>Connection failed</b>  <br/> <span class="failureMsg">{{failureToastValue}}</span>
                        <img src="../../assets/close_big.svg" class="mx-4 closeFailure" @click="closeFailureToast"/>
                    </div>
                    <div class="connectingToastMsg" v-if="connectingToast">
                        <img src="../../assets/connecting_icon.svg" class="mr-3"/>
                        Connectiing  <br/> {{connectingToastValue}}
                        <img src="../../assets/close_big.svg" class="mx-4"/>
                    </div> -->
        <b-modal
            ref="dataset-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right cursor-pointer">
                    <img src="../../assets/close_icon.svg" @click="hideModal" />
                </div>
                <h5 class="mt-2">Delete "{{ modalDataSourceName }}"</h5>
                <small
                    >{{ reviewDataSets.length }} datasets are using this
                    datasource definition.</small
                ><br />
                <div class="mt-3 mb-4">
                    <a
                        @click="deleteDataSource(modalDataSourceId)"
                        class="text-danger"
                        >Delete datasource</a
                    >
                    <button class="buttonSelect ml-3" @click="showReviewModal">
                        Review datasets
                    </button>
                </div>
            </div>
        </b-modal>
        <b-modal
            ref="review-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-left">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../assets/close_icon.svg"
                        @click="hideReviewModal"
                    />
                </div>
                <div class="text-center">
                    <h5 class="mt-2 text-center">
                        Review datasets using {{ modalDataSourceName }}
                    </h5>
                    <small class="text-center"
                        >{{ reviewDataSets.length }} datasets are using this
                        datasource definition.</small
                    >
                </div>
                <br />
                <div class="my-3 review">
                    <table class="table table-striped">
                        <tbody>
                            <tr
                                v-for="item in reviewDataSets"
                                :key="item.DatasetId"
                            >
                                <td>{{ item.DatasetName }}</td>
                                <td>{{ item.DatasetDesc }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 delModal text-center">
                    <a @click="hideReviewModal" class="text-primary"
                        >No, don't delete</a
                    >
                    <button
                        class="ml-3 buttonDelete"
                        @click="deleteDataSource(modalDataSourceId)"
                    >
                        Delete datasets
                    </button>
                </div>
            </div>
        </b-modal>
        <Footer />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import Header from '../Header';
import Footer from '../Footer';
import router from '../../router';
import { mapState, mapActions } from 'vuex';
import editTemplate from './template.vue';
import CustomFloatingLabel from '../CustomFloatingLabel';
// import CustomFloatingLabel from './Tabs_CustomFloatingLabel';
import { dataSourceService } from '../../services/datasources.service';

import Vue from 'vue';
//import { L10n, setCulture } from '@syncfusion/ej2-base';
import {
    GridPlugin,
    Sort,
    Page,
    Edit,
    CommandColumn,
    Toolbar,
    Search,
    Freeze,
    Resize,
} from '@syncfusion/ej2-vue-grids';

import AccessPermissionModal from '../AccessPermissionModal';
//import dashboard from '../../views/Dashboard';
import ToastMessages from '../ToastMessages.vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import CustomValidateInput from '../CustomValidateInput';

Vue.use(GridPlugin);
// Vue.prototype.$eventHub = new Vue();
export default {
    mixins: [validationMixin],
    data: () => {
        return {
            sortOptions: {
                columns: [
                    { field: 'DataSourceName', direction: 'Ascending' },
                    { field: 'DataSourceType', direction: 'Descending' },
                    { field: 'ServerName', direction: 'Descending' },
                    { field: 'ConnectionTool', direction: 'Descending' },
                    { field: 'ConnectionString', direction: 'Descending' },
                    { field: 'ProjectTable', direction: 'Descending' },
                    { field: 'ProjectColumn', direction: 'Descending' },
                    { field: 'ProjectDescColumn', direction: 'Descending' },
                ],
            },
            newDataSourse: null,
            editDbType: null,
            newtab: [],
            editTab: [],
            tabCounter: 0,
            dbType: '',
            sqlType: false,
            geoDbType: false,
            powerBIDbType: false,
            inputType: 'password',
            modalDataSourceName: '',
            modalDataSourceId: '',
            reviewDataSets: [],
            successToast: false,
            failureToast: false,
            connectingToast: false,
            successToastValue: '',
            failureToastValue: '',
            connectingToastValue: '',
            testConnection: true,
            createConnection: false,
            updateConnection: false,
            addDataS: true,
            validateInput: true,
            floatLabelAdd: null,
            floatLabelEdit: null,
            showSearchIcon: false,
            dataSourceNameExist: '',
            filterShow: false,
            addItem: {
                DataSourceName: null,
                DataSourceDesc: null,
                DataSourceType: null,
                ServerName: null,
                DatabaseName: null,
                ConnectionTool: null,
                Username: null,
                Password: null,
                Location: null,
                ProjectTable: null,
                ProjectColumn: null,
                ProjectDescColumn: null,
                WorkspaceName: null,
                WorkspaceID: null,
            },
            editItem: {
                DataSourceName: null,
                DataSourceDesc: null,
                DataSourceType: null,
                ConnectionTool: null,
                DatabaseName: null,
                ServerName: null,
                Port: null,
                Location: null,
                ProjectTable: null,
                ProjectColumn: null,
                ProjectDescColumn: null,
                CreationDate: null,
                WorkspaceName: null,
                WorkspaceID: null,
            },
            manageHeader: true,
            saveHeader: false,
            pageSettings: { pageSize: 10 },
            editSettings: { allowEditing: true, allowDeleting: true },
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            actionCommands: [
                {
                    type: 'Delete',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-delete e-icons',
                    },
                },
            ],
            action: [{ buttonOption: { iconCss: 'e-delete e-icons' } }],
            editColumn: function() {
                return {
                    template: editTemplate,
                };
            },
            prtPage: null,
            chkPresent: null,
            showPage: false,
            showpageLatest: 'successsssssss on manage PP',
            thisfilename: null,
            isServerName: false,
            applyfilterDatasource: false,
            removeLegacyFilter: false,
            dataSourceLegacy: [],
            DataSourceNameDrop: null,
            ConnectionTool: '',
            filterLegacy: false,
            filterDataSearch: null,
            showSuccess: false,
            showFailure: false,
            showConnecting: false,
            failureToastMessage: null,
            connectingToastMessage: null,
            successToastMessage: null,
            legacyProj: false,
            searchClose: false,
            step: 0,
        };
    },
    validations() {
        if (this.newDataSourse) {
            if (this.addItem.DataSourceType === null) {
                return {
                    addItem: {
                        DataSourceName: {
                            required,
                            isUnique(value) {
                                if (value === '') return true;
                                return new Promise((resolve, reject) => {
                                    dataSourceService
                                        .getDuplicateDataSource(
                                            this.addItem.DataSourceName
                                        )
                                        .then((res) => {
                                            resolve(!res.data.IsDuplicate);
                                        });
                                });
                            },
                        },
                        DataSourceDesc: {
                            required,
                        },
                        DataSourceType: {
                            required,
                        },
                    },
                };
            } else if (this.addItem.DataSourceType === 'SqlServer') {
                return {
                    addItem: {
                        DataSourceName: {
                            required,
                            isUnique(value) {
                                if (value === '') return true;
                                return new Promise((resolve, reject) => {
                                    dataSourceService
                                        .getDuplicateDataSource(
                                            this.addItem.DataSourceName
                                        )
                                        .then((res) => {
                                            resolve(!res.data.IsDuplicate);
                                        });
                                });
                            },
                        },
                        DataSourceDesc: {
                            required,
                        },
                        DataSourceType: {
                            required,
                        },
                        ServerName: {
                            required,
                        },
                        DatabaseName: {
                            required,
                        },
                        Username: {
                            required,
                        },
                        Password: {
                            required,
                        },
                    },
                };
            } else if (this.addItem.DataSourceType === 'GeoDB') {
                return {
                    addItem: {
                        DataSourceName: {
                            required,
                            isUnique(value) {
                                if (value === '') return true;
                                return new Promise((resolve, reject) => {
                                    dataSourceService
                                        .getDuplicateDataSource(
                                            this.addItem.DataSourceName
                                        )
                                        .then((res) => {
                                            resolve(!res.data.IsDuplicate);
                                        });
                                });
                            },
                        },
                        DataSourceDesc: {
                            required,
                        },
                        DataSourceType: {
                            required,
                        },
                        DatabaseName: {
                            required,
                        },
                        Location: {
                            required,
                        },
                    },
                };
            } else if (this.addItem.DataSourceType === 'PowerBI') {
                return {
                    addItem: {
                        DataSourceName: {
                            required,
                            isUnique(value) {
                                if (value === '') return true;
                                return new Promise((resolve, reject) => {
                                    dataSourceService
                                        .getDuplicateDataSource(
                                            this.addItem.DataSourceName
                                        )
                                        .then((res) => {
                                            resolve(!res.data.IsDuplicate);
                                        });
                                });
                            },
                        },
                        DataSourceDesc: {
                            required,
                        },
                        DataSourceType: {
                            required,
                        },
                        WorkspaceName: {
                            required,
                        },
                        WorkspaceID: {
                            required,
                        },
                    },
                };
            }
        } else {
            if (this.editItem.DataSourceType === 'SqlServer') {
                return {
                    editItem: {
                        DataSourceName: {
                            required,
                            isUnique(value) {
                                if (
                                    value === '' ||
                                    value === this.dataSourceNameExist
                                )
                                    return true;
                                return new Promise((resolve, reject) => {
                                    dataSourceService
                                        .getDuplicateDataSource(
                                            this.editItem.DataSourceName
                                        )
                                        .then((res) => {
                                            resolve(!res.data.IsDuplicate);
                                        });
                                });
                            },
                        },
                        DataSourceDesc: {
                            required,
                        },
                        DataSourceType: {
                            required,
                        },
                        ServerName: {
                            required,
                        },
                        DatabaseName: {
                            required,
                        },
                        Username: {
                            required,
                        },
                        Password: {
                            required,
                        },
                    },
                };
            } else if (this.editItem.DataSourceType === 'GeoDB') {
                return {
                    editItem: {
                        DataSourceName: {
                            required,
                            isUnique(value) {
                                if (value === '' || this.dataSourceNameExist)
                                    return true;
                                return new Promise((resolve, reject) => {
                                    dataSourceService
                                        .getDuplicateDataSource(
                                            this.editItem.DataSourceName
                                        )
                                        .then((res) => {
                                            resolve(!res.data.IsDuplicate);
                                        });
                                });
                            },
                        },
                        DataSourceDesc: {
                            required,
                        },
                        DataSourceType: {
                            required,
                        },
                        DatabaseName: {
                            required,
                        },
                        Location: {
                            required,
                        },
                    },
                };
            }
        }
    },
    provide: {
        grid: [
            Sort,
            Page,
            Edit,
            CommandColumn,
            Toolbar,
            Search,
            Freeze,
            Resize,
        ],
    },
    components: {
        Header,
        Footer,
        CustomFloatingLabel,
        AccessPermissionModal,
        // dashboard,
        ToastMessages,
        CustomValidateInput,
    },

    created() {
        this.getDataSources();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.Pagination) {
                this.pageSettings.pageSize = this.$store.state.store.portalsettings.Pagination;
            }
        }
    },
    computed: {
        ...mapState({
            datasources: (state) => state.datasources.datasources,
        }),
        filteredDataSource() {
            let vm = this;
            if (this.filterDataSearch) {
                return this.datasources.items.filter((item) => {
                    return (
                        item.DataSourceName.toLowerCase().indexOf(
                            vm.filterDataSearch.toLowerCase()
                        ) > -1
                    );
                });
            } else {
                return this.datasources.items;
            }
        },
        validated() {
            return (status) => {
                if (this.newDataSourse) {
                    this.selectDbType(this.addItem.DataSourceType);
                    const { $dirty } = this.$v.addItem[status];
                    if ($dirty && this.validateState(status) === true) {
                        return true;
                    } else if ($dirty && this.validateState(status) === false) {
                        return false;
                    }
                }
                return true;
            };
        },
        editValidated() {
            return (status) => {
                if (!this.newDataSourse) {
                    this.selectDbType(this.addItem.DataSourceType);
                    const { $dirty } = this.$v.editItem[status];
                    if ($dirty && this.edditValidateState(status) === true) {
                        return true;
                    } else if (
                        $dirty &&
                        this.edditValidateState(status) === false
                    ) {
                        return false;
                    }
                }
                return true;
            };
        },
    },
    mounted() {
        // this.getPermissions();
        // this.$store.dispatch("getPermissions");
        this.pagePermit();
        if (this.showPage) {
            this.getDataSources();
            this.dbTypeEnum();
        }
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        shouldShowCustomIcon() {
            const isEdge = navigator.userAgent.includes('Edg/');
            return !isEdge;
        },
        validateState(name) {
            if (this.newDataSourse) {
                this.selectDbType(this.addItem.DataSourceType);
                const { $dirty, $error } = this.$v.addItem[name];
                return $dirty ? !$error : null;
            }
            return null;
        },
        edditValidateState(name) {
            if (!this.newDataSourse) {
                this.selectDbType(this.addItem.DataSourceType);
                const { $dirty, $error } = this.$v.editItem[name];
                return $dirty ? !$error : null;
            }
            return null;
        },
        pageCenter: function() {
            this.$refs.grid.ej2Instances.pagerModule.element.align = 'center';
        },
        navigatetoDatasources() {
            router.push(`/datasource/managedatasource`);
        },
        navigateManagedatasources: function() {
            if (this.adminrole == 'admin') {
                // router.push(`/`);
                router.go(-1);
            } else {
                router.push(`/managedatasource`);
            }
        },
        dataBound: function() {
            this.$refs.grid.ej2Instances.pagerModule.pagerObj.pagerMessageModule.hideMessage();
            var grid = this.$refs.grid.$el.ej2_instances[0];
            if (grid.isFrozenGrid() && !grid.getCurrentViewRecords().length) {
                var td = grid.element
                    .querySelector('.e-movablecontent')
                    .querySelector('tr')
                    .querySelector('td');
                td.innerText = 'No records to display';
                td.setAttribute('colspan', 2);
                grid.element
                    .querySelector('.e-movablecontent')
                    .querySelector('tr').style.height = '30px';

                grid.element
                    .querySelector('.e-frozencontent')
                    .querySelector('tr')
                    .querySelector('td').innerText = '';
            }
        },
        handleBlur(type) {
            let vm = this;
            if (type == 'add') {
                if (vm.addItem.DataSourceName) {
                    dataSourceService
                        .getDuplicateDataSource(vm.addItem.DataSourceName)
                        .then((res) => {
                            res.data.IsDuplicate
                                ? (vm.isServerName = true)
                                : (vm.isServerName = false);
                        });
                }
            }
            if (type == 'edit') {
                if (vm.editItem.DataSourceName) {
                    dataSourceService
                        .getDuplicateDataSource(vm.editItem.DataSourceName)
                        .then((res) => {
                            if (
                                this.editItem.DataSourceName !==
                                this.dataSourceNameExist
                            ) {
                                res.data.IsDuplicate
                                    ? (vm.isServerName = true)
                                    : (vm.isServerName = false);
                            }
                        });
                }
            }
            if (vm.addItem.DataSourceType) {
                if (
                    vm.addItem.ProjectTable &&
                    vm.addItem.ProjectColumn &&
                    vm.addItem.ProjectDescColumn
                ) {
                    vm.legacyProj = true;
                } else if (
                    !vm.addItem.ProjectTable &&
                    !vm.addItem.ProjectColumn &&
                    !vm.addItem.ProjectDescColumn
                ) {
                    vm.legacyProj = true;
                } else if (
                    vm.addItem.ProjectTable ||
                    vm.addItem.ProjectColumn ||
                    vm.addItem.ProjectDescColumn
                ) {
                    vm.legacyProj = false;
                }
            }

            if (vm.editItem.DataSourceType) {
                if (
                    vm.editItem.ProjectTable &&
                    vm.editItem.ProjectColumn &&
                    vm.editItem.ProjectDescColumn
                ) {
                    vm.legacyProj = true;
                } else if (
                    !vm.editItem.ProjectTable &&
                    !vm.editItem.ProjectColumn &&
                    !vm.editItem.ProjectDescColumn
                ) {
                    vm.legacyProj = true;
                } else if (
                    vm.editItem.ProjectTable ||
                    vm.editItem.ProjectColumn ||
                    vm.editItem.ProjectDescColumn
                ) {
                    vm.legacyProj = false;
                }
            }
            if (vm.addItem.DataSourceType === 'SqlServer') {
                console.log(
                    'sue SqlServer',
                    vm.isServerName,
                    vm.addItem.DataSourceName,
                    vm.addItem.DataSourceDesc,
                    vm.addItem.DataSourceType,
                    vm.addItem.DatabaseName,
                    vm.addItem.Location
                );
                if (
                    !vm.isServerName &&
                    vm.addItem.DataSourceName &&
                    vm.addItem.DataSourceDesc != '' &&
                    vm.addItem.DataSourceType != '' &&
                    vm.addItem.ServerName != '' &&
                    vm.addItem.DatabaseName != '' &&
                    vm.addItem.ConnectionTool != '' &&
                    vm.addItem.Username != null &&
                    vm.addItem.Username != '' &&
                    vm.addItem.Password != null &&
                    vm.addItem.Password != '' &&
                    vm.legacyProj
                ) {
                    vm.validateInput = false;
                } else {
                    vm.validateInput = true;
                }
            } else if (vm.addItem.DataSourceType === 'GeoDB') {
                // if(!vm.isServerName && vm.addItem.DataSourceName != "" && vm.addItem.DataSourceDesc != "" && vm.addItem.DataSourceType != "" && vm.addItem.ServerName != "" && vm.addItem.DatabaseName != "" && vm.addItem.Location != "" && vm.addItem.ConnectionTool != "" && vm.addItem.ConnectionString != null || vm.addItem.ConnectionString != ""){
                console.log(
                    'sue gdb',
                    vm.isServerName,
                    vm.addItem.DataSourceName,
                    vm.addItem.DataSourceDesc,
                    vm.addItem.DataSourceType,
                    vm.addItem.DatabaseName,
                    vm.addItem.Location
                );
                if (
                    !vm.isServerName &&
                    vm.addItem.DataSourceName &&
                    vm.addItem.DataSourceDesc &&
                    vm.addItem.DataSourceType &&
                    vm.addItem.DatabaseName &&
                    vm.addItem.Location
                ) {
                    vm.validateInput = false;
                } else {
                    vm.validateInput = true;
                }
            } else if (vm.addItem.DataSourceType === 'PowerBI') {
                // if(!vm.isServerName && vm.addItem.DataSourceName != "" && vm.addItem.DataSourceDesc != "" && vm.addItem.DataSourceType != "" && vm.addItem.ServerName != "" && vm.addItem.DatabaseName != "" && vm.addItem.Location != "" && vm.addItem.ConnectionTool != "" && vm.addItem.ConnectionString != null || vm.addItem.ConnectionString != ""){
                if (
                    vm.addItem.DataSourceName &&
                    vm.addItem.DataSourceDesc &&
                    vm.addItem.DataSourceType
                ) {
                    vm.validateInput = false;
                } else {
                    vm.validateInput = true;
                }
            } else if (vm.editItem.DataSourceType === 'SqlServer') {
                console.log(
                    'sue SqlServer',
                    vm.isServerName,
                    vm.addItem.DataSourceName,
                    vm.addItem.DataSourceDesc,
                    vm.addItem.DataSourceType,
                    vm.addItem.DatabaseName,
                    vm.addItem.Location
                );
                if (
                    vm.editItem.DataSourceName &&
                    vm.editItem.DataSourceDesc &&
                    vm.editItem.DataSourceType &&
                    vm.editItem.ServerName &&
                    vm.editItem.DatabaseName &&
                    vm.editItem.Username &&
                    vm.legacyProj
                ) {
                    vm.validateInput = false;
                } else {
                    vm.validateInput = true;
                }
            } else if (vm.editItem.DataSourceType === 'GeoDB') {
                console.log(
                    'sue gdb',
                    vm.isServerName,
                    vm.addItem.DataSourceName,
                    vm.addItem.DataSourceDesc,
                    vm.addItem.DataSourceType,
                    vm.addItem.DatabaseName,
                    vm.addItem.Location
                );
                if (
                    vm.editItem.DataSourceName &&
                    vm.editItem.DataSourceDesc &&
                    vm.editItem.DataSourceType &&
                    vm.editItem.DatabaseName &&
                    vm.editItem.Location
                ) {
                    vm.validateInput = false;
                } else {
                    vm.validateInput = true;
                }
            }
            return vm.validateInput;
        },

        filterClick() {
            this.filterShow = true;
            /* document.querySelector("#outersearchinput").style.width = "35px";
    document.querySelector("#outersearchinput").style.borderColor ="none";
    document.querySelector("#outersearchinput .e-input").style.display ="none";*/
        },
        backFilter(item) {
            this.filterShowLegacy = false;
            this.filterLegacy = true;
            if (item == 'DataSourceName') {
                this.DataSourceNameDrop = '';
            }
            if (item == 'ConnectionTool') {
                this.ConnectionTool = '';
            }

            this.applyFilter();
            if (this.DataSourceNameDrop == '' && this.ConnectionTool == '') {
                this.filterLegacy = false;
            } else {
                this.filterLegacy = true;
            }
        },
        applyFilter() {
            this.filterShow = false;
            this.applyfilterDatasource = true;
            this.removeLegacyFilter = true;
            this.dataSourceLegacy = this.datasources.items;
            let vm = this;
            if (vm.DataSourceNameDrop) {
                this.filterLegacy = true;
                vm.dataSourceLegacy = vm.dataSourceLegacy.filter((item) =>
                    item.DataSourceName.toLowerCase().includes(
                        vm.DataSourceNameDrop.toLowerCase()
                    )
                );
            }
            if (vm.ConnectionTool) {
                this.filterLegacy = true;
                vm.dataSourceLegacy = vm.dataSourceLegacy.filter(
                    (item) => item.DataSourceType === vm.ConnectionTool
                );
            }

            this.closeFilter();
        },
        closeFilter() {
            this.filterShowLegacy = false;
            this.filterShow = false;
        },
        removeFilterLegacy() {
            this.applyfilterDatasource = false;
            this.DataSourceNameDrop = '';
            this.ConnectionTool = '';
            this.applyFilter();
            this.removeLegacyFilter = false;
            this.filterLegacy = false;
            //   this.removeProjectFilter();
        },
        ...mapActions('datasources', {
            getDataSources: 'getDataSources',
        }),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('ManageDataSource')) {
                    vm.showPage = true;
                }
            });
            if (!this.showPage) {
                this.$refs['permission-modal'].showPermissionModal();
            }
        },
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        gridRowData: function(args) {
            //Gevindu
            //if (args.commandColumn.type === "Delete") {
            if (args.target.getAttribute('title') === 'Delete') {
                this.showModal(args.rowData);
            }
            // this.showModal(args.rowData)
            // const id = args.rowData.DataSourceId
            // if (args.commandColumn.type === "Edit") {
            if (args.target.getAttribute('title') === 'Edit') {
                dataSourceService
                    .editDataSource(args.rowData.DataSourceId)
                    .then((res) => {
                        if (res.data) {
                            this.editItem = res.data;
                            this.editDbType = res.data.DataSourceType;
                            this.newDataSourse = false;
                            this.addDataS = false;
                            this.editTab.push(this.editItem);
                            this.floatLabelAdd = null;
                            this.floatLabelEdit = 'Edit';
                            this.isServerName = false;
                            this.testConnection = true;
                            this.createConnection = false;
                            this.dataSourceNameExist = this.editItem.DataSourceName;
                            this.updateConnection = false;
                        }
                    });
            }
        },
        selectedEditTab(dataSourceId) {
            dataSourceService.editDataSource(dataSourceId).then((res) => {
                if (res.data) {
                    this.editItem = res.data;
                    this.editDbType = res.data.DataSourceType;
                    this.newDataSourse = false;
                    this.addDataS = false;
                    this.floatLabelAdd = null;
                    this.floatLabelEdit = 'Edit';
                    this.isServerName = false;
                    this.testConnection = true;
                    this.updateConnection = false;
                }
            });
        },
        closeData() {
            this.editTab.pop();
            if (this.editTab.length === 0) {
                this.saveHeader = false;
                this.manageHeader = true;
            }
            if (this.editTab.length) {
                this.manageHeader = false;
            } else {
                if (this.newtab.length) {
                    this.addNewDataSource(false);
                }
            }
        },
        closeNewData() {
            this.newtab.pop();
            if (this.newtab.length === 0) {
                this.saveHeader = false;
                this.manageHeader = true;
            }
        },
        closeEditTab(x) {
            for (let j = 0; j < this.editTab.length; j++) {
                if (this.editTab[j] === x) {
                    this.editTab.splice(j, 1);
                }
            }
            if (this.editTab.length === 0) {
                this.saveHeader = false;
                this.manageHeader = true;
            }
            x = this.editTab.length > x ? x : this.editTab.length - 1;
            if (this.editTab.length) {
                this.manageHeader = false;
                this.selectedEditTab(this.editTab[x]['DataSourceId']);
            } else {
                if (this.newtab.length) {
                    this.addNewDataSource(false);
                }
            }
        },
        addNewDataSource(flag) {
            //Gevindu
            this.newDataSourse = true;
            this.editDbType = null;
            this.addDataS = false;
            this.floatLabelAdd = 'Add';
            this.testConnection = true;
            this.validateInput = true;
            this.isServerName = false;
            if (flag) {
                this.newtab.push(this.tabCounter++);
            }
            if (this.tabCounter) {
                this.floatLabelEdit = null;
                this.floatLabelAdd = 'Add';
            }
            this.addItem = {
                DataSourceName: null,
                DataSourceDesc: null,
                DataSourceType: null,
                ServerName: null,
                ConnectionTool: null,
                DatabaseName: null,
                Username: null,
                Password: null,
                ConnectionString: null,
                Location: null,
                ProjectTable: null,
                ProjectColumn: null,
                ProjectDescColumn: null,
            };
        },
        closeTab(x) {
            for (let i = 0; i < this.newtab.length; i++) {
                if (this.newtab[i] === x) {
                    this.newtab.splice(i, 1);
                    this.addItem = {
                        DataSourceName: null,
                        DataSourceDesc: null,
                        DataSourceType: null,
                        ServerName: null,
                        ConnectionTool: null,
                        DatabaseName: null,
                        Username: null,
                        Password: null,
                        ConnectionString: null,
                        Location: null,
                        ProjectTable: null,
                        ProjectColumn: null,
                        ProjectDescColumn: null,
                    };
                }
            }
            if (this.newtab.length === 0) {
                this.saveHeader = false;
                this.manageHeader = true;
            }
            let flag = this.newtab.length > x ? true : false;
            if (!flag && this.editTab.length) {
                this.selectedEditTab(this.editTab[0]['DataSourceId']);
            } else {
                this.addNewDataSource(false);
            }
        },
        dbTypeEnum() {
            //gets datasource types
            dataSourceService.getDataSourceDBType().then((res) => {
                this.dbType = res.data;
            });
        },
        selectDbType(type) {
            if (type === 'SqlServer') {
                this.sqlType = true;
                this.geoDbType = false;
                this.powerBIDbType = false;
            } else if (type === 'GeoDB') {
                this.geoDbType = true;
                this.sqlType = false;
                this.powerBIDbType = false;
            } else if (type === 'PowerBI') {
                this.powerBIDbType = true;
                this.geoDbType = false;
                this.sqlType = false;
            } else {
                this.geoDbType = false;
                this.sqlType = false;
                this.powerBIDbType = false;
            }
        },
        showPassword() {
            this.inputType =
                this.inputType === 'password' ? 'text' : 'password';
        },
        activateTab(currentTabs) {
            if (currentTabs.length === 2) {
                currentTabs[0].active = false;
                currentTabs[1].active = true;
            }
        },
        selectedTab(newTabIndex) {
            if (newTabIndex) {
                this.saveHeader = true;
                this.manageHeader = false;
            } else {
                this.saveHeader = false;
                this.manageHeader = true;
            }
        },
        showModal(data) {
            this.modalDataSourceName = data.DataSourceName;
            this.modalDataSourceId = data.DataSourceId;
            dataSourceService
                .dataSetsByDataSource(this.modalDataSourceId)
                .then((res) => (this.reviewDataSets = res.data[0].Datasets));
            this.$refs['dataset-modal'].show();
        },
        hideModal() {
            this.$refs['dataset-modal'].hide();
        },
        showReviewModal() {
            this.hideModal();
            this.$refs['review-modal'].show();
        },
        hideReviewModal() {
            this.$refs['review-modal'].hide();
        },
        hidePermissionModal() {
            router.push(`/dashboard`);
            this.$refs['permission-modal'].hide();
        },
        deleteDataSource(id) {
            if (this.filterDataSearch) {
                let x = this.filterDataSearch;
                this.filterDataSearch = x;
            }
            dataSourceService.deleteDataSource(id).then((res) => {
                if (res.data.StatusCode === 200) {
                    this.hideModal();
                    this.hideReviewModal();
                    this.showSuccess = true;
                    this.successToastMessage =
                        'Datasource deleted successfully';

                    // this.successToastValue = 'Datasource deleted successfully'
                    // this.successToast = true
                    setTimeout(() => {
                        this.showSuccess = false;
                        this.getDataSources();
                    }, 1000);
                    setTimeout(() => {
                        // this.getDataSources();
                    }, 500);
                }
            });
        },
        closeSuccessToast() {
            this.successToast = false;
        },
        closeFailureToast() {
            this.failureToast = false;
        },
        createDataSource(type) {
            //Gevindu
            this.$v.addItem.$touch();
            if (this.$v.addItem.$anyError) {
                this.failureToastMessage =
                    'Not all required fields are complete. Please check all sections, then retry.';
                this.showFailure = true;
                this.showSuccess = false;
                this.connectingToast = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
                return;
            }

            var CreatedBy = JSON.parse(localStorage.getItem('userDetails'));

            const data = {
                DataSourceName: this.addItem.DataSourceName,
                //"DataSourceName": type === "testConnection" ? this.addItem.DatabaseName : this.addItem.DataSourceName,
                DataSourceType: this.addItem.DataSourceType,
                ServerName: this.addItem.ServerName,
                ProjectTable: this.addItem.ProjectTable
                    ? this.addItem.ProjectTable
                    : '',
                ProjectColumn: this.addItem.ProjectColumn
                    ? this.addItem.ProjectColumn
                    : '',
                ProjectDescColumn: this.addItem.ProjectDescColumn
                    ? this.addItem.ProjectDescColumn
                    : '',
                DatabaseName: this.addItem.DatabaseName,
                Username: this.addItem.Username,
                Password: this.addItem.Password,
                DataSourceDesc: this.addItem.DataSourceDesc,
                Location: this.addItem.Location,
                CreatedBy: CreatedBy.name,
            };
            //
            if (type === 'testConnection') {
                this.connectingToastValue = 'Attempting to connect to server.';
                this.connectingToast = true;
                dataSourceService
                    .testConnection(data)
                    .then((res) => {
                        console.log("sue status", res.data)
                        if (res.data.StatusCode === 200) {
                            this.successToastMessage =
                                'Connected to server. Proceed to saving.';
                            this.showSuccess = true;
                            this.showFailure = false;
                            this.showConnecting = false;
                            this.testConnection = false;
                            this.createConnection = true;
                            this.updateConnection = false;
                            this.connectingToast = false;
                            setTimeout(() => {
                                this.showSuccess = false;
                            }, 2000);
                        }
                        else {
                            this.failureToastMessage =
                                'Edit information and try connecting again.';
                            this.showSuccess = false;
                            this.showFailure = true;
                            this.connectingToast = false;
                            this.updateConnection = false;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 2000);
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            this.failureToastMessage =
                                'Edit information and try connecting again.';
                            this.showSuccess = false;
                            this.showFailure = true;
                            this.connectingToast = false;
                            this.updateConnection = false;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 2000);
                        }
                    });
            }
            if (type === 'createConnection') {
                dataSourceService
                    .createDataSource(data)
                    .then((res) => {
                        if (res.data.StatusCode === 201) {
                            this.saveHeader = false;
                            this.manageHeader = true;
                            this.getDataSources();
                            this.successToastMessage = 'New datasource saved.';
                            this.showSuccess = true;
                            this.showFailure = false;
                            this.showConnecting = false;
                            this.createConnection = false;
                            this.updateConnection = false;
                            this.addDataS = true;
                            this.newtab.pop();
                            setTimeout(() => {
                                this.showSuccess = false;
                            }, 2000);
                        } else if (res.data.StatusCode === 500) {
                            //nothing
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            this.failureToastMessage =
                                'Datasource name already exists';
                            // this.failureToast = true;
                            this.showSuccess = false;
                            this.showFailure = true;
                            this.showConnecting = false;
                            this.updateConnection = false;
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 2000);
                        }
                    });
            }
        },
        updateDataSource(type) {
            //Gevindu
            const id = this.editItem.DataSourceId;
            var ModifiedBy = JSON.parse(localStorage.getItem('userDetails'));

            if (id) {
                let hiddenPassword = this.editItem.Password;

                if (hiddenPassword == '') {
                    hiddenPassword = null;
                }
                const data = {
                    DataSourceId: id,
                    DataSourceName: this.editItem.DataSourceName,
                    DataSourceType: this.editItem.DataSourceType,
                    ServerName: this.editItem.ServerName,
                    ProjectTable: this.editItem.ProjectTable,
                    ProjectColumn: this.editItem.ProjectColumn,
                    ProjectDescColumn: this.editItem.ProjectDescColumn,
                    DatabaseName: this.editItem.DatabaseName,
                    Username: this.editItem.Username,
                    Password: hiddenPassword,
                    DataSourceDesc: this.editItem.DataSourceDesc,
                    Location: this.editItem.Location,
                    ModifiedBy: ModifiedBy.name,
                    CreationDate: this.editItem.CreationDate,
                    CreatedBy: this.editItem.CreatedBy,
                };
                //
                if (type === 'testConnection') {
                    this.successToastMessage =
                        'Attempting to connect to server.';
                    this.showSuccess = true;
                    this.showFailure = false;
                    this.showConnecting = false;
                    this.connectingToast = true;
                    dataSourceService
                        .testConnection(data)
                        .then((res) => {
                            if (res.data.StatusCode === 200) {
                                this.successToastMessage =
                                    'Connected to server. Proceed saving.';
                                this.showSuccess = true;
                                this.showFailure = false;
                                this.showConnecting = false;
                                // this.successToast = true;
                                this.testConnection = false;
                                this.createConnection = false;
                                // this.connectingToast = false;
                                this.updateConnection = true;
                                setTimeout(() => {
                                    this.showSuccess = false;
                                }, 2000);
                            }
                        })
                        .catch((err) => {
                            if (err) {
                                this.failureToastMessage =
                                    'Edit information and try connecting again.';
                                this.showSuccess = false;
                                this.showFailure = true;
                                this.showConnecting = false;
                                // this.failureToast = true;
                                // this.connectingToast = false;
                                this.updateConnection = false;
                                setTimeout(() => {
                                    this.failureToast = false;
                                }, 2000);
                            }
                        });
                }
                if (type === 'updateConnection') {
                    dataSourceService
                        .updateDataSource(id, data)
                        .then((res) => {
                            if (res.data.StatusCode === 202) {
                                this.saveHeader = false;
                                this.manageHeader = true;
                                this.getDataSources();
                                // this.successToastValue = 'Datasource updated successfully';
                                this.successToastMessage =
                                    'Datasource updated successfully';
                                this.showSuccess = true;
                                this.showFailure = false;
                                this.showConnecting = false;
                                this.updateConnection = false;
                                let index = this.editTab.findIndex(
                                    (item) => item.DataSourceId == id
                                );
                                this.editTab.splice(index, 1);
                                index =
                                    this.editTab.length > index
                                        ? index
                                        : this.editTab.length - 1;
                                if (this.editTab.length) {
                                    this.manageHeader = false;
                                    this.selectedEditTab(
                                        this.editTab[index]['DataSourceId']
                                    );
                                } else {
                                    if (this.newtab.length) {
                                        this.manageHeader = false;
                                        this.addNewDataSource(false);
                                    }
                                }
                                setTimeout(() => {
                                    this.showSuccess = false;
                                    this.addDataS = true;
                                }, 2000);
                                this.addDataS = true;
                            }
                        })
                        .catch((err) => {
                            if (err) {
                                this.failureToastMessage =
                                    'Something went wrong';
                                this.showSuccess = false;
                                this.showFailure = true;
                                this.showConnecting = false;
                                // this.failureToast = true;
                                setTimeout(() => {
                                    this.showFailure = false;
                                }, 2000);
                            }
                        });
                }
            }
        },
        searchiconclick() {
            this.applyfilterDatasource = false;
            document.querySelector('#outersearchinput').style.width = '200px';
            document.querySelector('#outersearchinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outersearchinput .e-input').style.display =
                'block';
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.datasourceSearch.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.datasourceSearch.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.filterDataSearch = null;
            this.$refs.grid.$el.ej2_instances[0].search();
        },
        searchinputenter(args) {
            this.$refs.grid.$el.ej2_instances[0].search(
                args.currentTarget.value
            );
            this.showSearchIcon = false;
            if (!this.filterDataSearch) {
                this.searchClose = false;
            }
        },
        hideModalPermission() {
            this.$refs['dataset-modal'].hide();
        },
    },
};
</script>

<style scoped>
.e-pager {
    text-align: center !important;
}
.tooltipStyle {
    background: #000;
    color: white;
}
select {
    appearance: none;
    border-radius: 0px;
    height: 48px !important;
    width: 499px;
}
.container .min-height {
    min-height: 500px;
}
.admin {
    color: #000;
    margin-top: -15px;
    margin-bottom: 20px;
}
.password_eye {
    float: right;
    margin-right: 10px;
    margin-top: -35px;
    position: relative;
}
.password_input {
    width: 400px !important;
}

.filter_input {
    width: 265px;
    /* border: 1px solid #ced4da; */
    border-radius: 4px;
}
.control-section {
    padding-bottom: 100px !important;
}

.e-search-icon::before {
    content: '\e993';
}
#outersearchinput {
    width: 35px;
    border-right: 1px solid #c6c6c6;
    height: 25px;
}
#outersearchinput .e-input {
    display: none;
    padding: 0 !important;
}
.filterSelect {
    width: 226px !important;
    border-radius: 4px;
}

.e-grid .e-edit::before {
    content: url('../../assets/edit.svg') !important;
}
.e-grid .e-delete::before {
    content: url('../../assets/search_dots.svg') !important;
}
.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: White !important;
    border: none !important;
    text-align: center !important;
}
.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
    background-color: #f5f5f5 !important;
}
.e-grid .e-gridcontent tr td:first-child,
.e-grid .e-gridcontent tr td:last-child {
    text-align: center !important;
}
.e-input-group:not(.e-disabled) .e-input-group-icon:hover {
    background: #fff !important;
    color: #666;
}

button.buttonDelete {
    background-color: #da1e27;
    color: #eee;
    padding: 7px 15px;
    border: none;
}
.e-grid .e-gridheader .e-sortfilter .e-rightalign .e-headercelldiv,
.e-grid .e-gridheader .e-rightalign .e-stackedheadercelldiv {
    padding: 0 !important;
    text-align: left !important;
}
.e-pager .e-spacing,
.e-pager .e-numericitem:hover,
.e-pager .e-currentitem {
    border-radius: 4px !important;
    color: #000 !important;
}
.e-active {
    color: #fff !important;
}
.e-grid .e-table {
    border-bottom: 3px solid #1d1d1d;
}
.e-grid {
    text-align: left !important;
}
.errorMsg {
    position: relative;
    top: -20px;
    width: 499px;
}
.fieldErr {
    border: 1px solid #f00;
    border-radius: 3px;
}
.addData {
    width: fit-content;
    margin-bottom: 55px;
}
.errorMsg span {
    float: right;
    position: relative;
    top: -40px;
    right: 15px;
    /* right: 88px; */
}
.failureToastMsg {
    width: 486px;
    height: 98px;
    display: block;
    padding-top: 35px;
}
.failureToastMsg b {
    font: normal normal 600 18px/23px IBM Plex Sans;
    letter-spacing: 0.21px;
    color: #1d1d1d;
    top: -13px;
    position: relative;
}
.failureToastMsg .failureMsg {
    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0.21px;
    color: #626262;
    left: 43px;
    position: relative;
    bottom: 12px;
}
.closeFailure {
    position: relative;
    bottom: 25px;
    right: -50px;
}
.addDataCol {
    width: 499px;
}
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    background-color: #6c757d !important;
}
.removeFilter {
    cursor: pointer;
    margin: 4px 0 0 15px;
    font-size: 14px;
}
.removeFilter,
span {
    font: 14px;
    letter-spacing: 0.1px;
}
.pointer {
    cursor: pointer;
}
.filter-block {
    top: 15px !important;
}
input.searchinput {
    /* background: url("../../../assets/search-outline.svg") no-repeat !important;  border: none; */
    /* background: none !important; */
    margin-right: -151px;
    padding-left: 25px;
    border-bottom: 1px solid #ccc;
    width: 180px;
    margin-bottom: 10px;
    top: 3px;
    position: relative;
    cursor: pointer;
    z-index: 0;
}
input {
    border: 0;
}
/* input.test {
  border: 0 0 1px 0;
  z-index: 0;
} */
/* input.test {
  margin: 0 !important;
  background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
  padding-left: 30px;
  background-position: left 9px bottom 7px;
  font-size: 14px;
  color: #626262;
  width:512px;
} */
.searchFilter {
    width: 100% !important;
    height: 48px;
    background-color: #f3f3f3 !important;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.16px;
    color: #626262;
    border: none !important;
    border-radius: 0 !important;
    margin-top: -10px;
    margin-right: 0 !important;
    top: 22px !important;
    background: #f3f3f3 url('../../assets/search-outline.svg') 10px no-repeat !important;
    padding-left: 35px !important;
}
.filteredItem {
    font: normal normal medium 14px/24px IBM Plex Sans;
    letter-spacing: 0.1px;
    color: #000000de;
    height: 30px;
    background: #eaeaea 0% 0% no-repeat padding-box;
    border-radius: 2px;
}
input.test {
    margin: 0 0px 0 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    /* //margin-right: -20px!important; */
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid #e2e2e2;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem)
            calc(0.75em + 0.375rem) no-repeat !important;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #c6c6c6 !important;
    padding-right: calc(0.75em + 2.3125rem);
}
</style>
