export const mapMarkerFunctions = {
    getIcon,
    getIconAnchor,
};

function getIcon(symbol, iconColor, outlineColor) {
    if (outlineColor == null || outlineColor == '') {
        switch (symbol) {
            case 'arrow':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.5645px" viewBox="0 0 10.5645 5.1655" height="5.1655px">  <path data-name="Path 32096" d="M235.528,285.054l5.09-4.056a1.108,1.108,0,0,0,0-1.733l-5.09-4.056a1.108,1.108,0,0,0-1.8.866v1.591H221.015a1.108,1.108,0,0,0-1.108,1.108v2.713a1.108,1.108,0,0,0,1.108,1.108H233.73v1.591A1.108,1.108,0,0,0,235.528,285.054Z" transform="scale(0.5 0.5) translate(-219.906 -274.967)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'circle':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'cross':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="9.5px" viewBox="0 0 9.5 9.5" height="9.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Union_8" data-name="Union 8" d="M7,19V12H0V7H7V0h5V7h7v5H12v7Z" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '" stroke-width="3"/></svg>'
                );
            case 'cross3':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="8.958px" viewBox="0 0 8.958 10.5365" height="10.5365px">  <path data-name="Union 7" d="M.047,18.277l5.271-7.814L0,2.868,4.1,0,9.718,8.029h8.2v5H9.9l-.527.369L4.192,21.073Z" fill="' +
                    iconColor +
                    '" transform="scale(0.5 0.5)" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'cross5':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="8.0305px" viewBox="0 0 8.0305 8.426" height="8.426px">  <path data-name="Union 9" d="M7.14,8.372,7,9l.14-.628L7,8l.14.372L7.222,8h0L9,0,7.222,8h0l-.083.372L10,16ZM6,8,0,14ZM0,3,6,8Z" transform="scale(0.5 0.5) translate(1.061 0.325)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '" stroke-width="3"/></g></g></svg>'
                );
            case 'flag':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="6.6065px" viewBox="0 0 6.6065 8.3425" height="8.3425px">  <g data-name="Group 12386" transform="scale(0.5 0.5) translate(19439.5 10083.185)"> <line id="Line_1338" data-name="Line 1338" y1="16.676" transform="translate(-19438.5 -10083.177)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '" stroke-width="2"/> <path id="Path_32097" data-name="Path 32097" d="M-19641.287-10072.161h-12.227v-8.347h12.227l-4.859,4.028Z" transform="translate(215 -2.677)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/>  </g></svg>'
                );
            case 'polygon3':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 12.0" height="12.0px">  <path data-name="Polygon 5" d="M12,0,24,20H0Z" transform="scale(0.5 0.5) translate(20) rotate(90)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'polygon4':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.0" height="13.0px">  <path data-name="Polygon 6" d="M13,0,26,13,13,26,0,13Z" transform="scale(0.5 0.5)translate(26) rotate(90)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'polygon5':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.669" height="13.669px">  <path data-name="Polygon 7" d="M13.669,0,27.338,9.931,22.117,26H5.221L0,9.931Z" transform="scale(0.5 0.5) translate(26) rotate(90)"  fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'star':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="12.0px" viewBox="0 0 12.0 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_10" data-name="Polygon 10" d="M12,0l3,8.553,9,.232L16.854,14.3,19.416,23,12,17.857,4.584,23l2.562-8.7L0,8.785l9-.232Z" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></g></g></svg>'
                );
            case 'star3':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 11.5" height="11.5px">  <path data-name="Polygon 8" d="M13,0l3.9,13.033L26,23,13,19.933,0,23l9.1-9.967Z" transform="scale(0.5 0.5)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'star4':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="11.5px" viewBox="0 0 11.5 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_9" data-name="Polygon 9" d="M11.5,0l3.45,8.05L23,11.5l-8.05,3.45L11.5,23,8.05,14.95,0,11.5,8.05,8.05Z" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></g></g></svg>'
                );
            default:
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
        }
    } /*icon with outline*/ else {
        switch (symbol) {
            case 'arrow':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.5645px" viewBox="0 0 10.5645 5.1655" height="5.1655px">  <path data-name="Path 32096" d="M235.528,285.054l5.09-4.056a1.108,1.108,0,0,0,0-1.733l-5.09-4.056a1.108,1.108,0,0,0-1.8.866v1.591H221.015a1.108,1.108,0,0,0-1.108,1.108v2.713a1.108,1.108,0,0,0,1.108,1.108H233.73v1.591A1.108,1.108,0,0,0,235.528,285.054Z" transform="scale(0.5 0.5) translate(-219.906 -274.967)" fill="' +
                    iconColor +
                    '" stroke="' +
                    outlineColor +
                    '"/></svg>'
                );
            case 'circle':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" height="15.0px" width="15.0px" viewBox="0 0 15.0 15.0">' +
                    '<path id="circle839" transform="scale(0.6 0.6) translate(0.85036544,0.852078)" style="fill:' +
                    outlineColor +
                    ';fill-opacity:1;stroke-width:0.904317" d="M 9.7158957,-0.84833448 C 5.5070914,-0.78613644 1.50106,1.9770451 -0.04297807,5.8942342 -1.7065295,9.8155813 -0.73846089,14.66011 2.2997935,17.64461 c 2.966275,3.064198 7.8111555,4.064656 11.7478395,2.422787 3.929005,-1.52305 6.717799,-5.518007 6.799139,-9.730103 C 21.02725,6.1286562 18.492772,1.9681967 14.66639,0.20409384 13.130657,-0.53199649 11.418284,-0.89333775 9.7158957,-0.84833448 Z" />' +
                    '<circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5) translate(2.95036544,2.952078)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'cross':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" height="20.0px" width="20.0px" viewBox="0 0 20.0 20.0">' +
                    '<path id="path837" transform="scale(0.5 0.5) translate(2.5, 2.5)" style="fill:' +
                    outlineColor +
                    ';fill-opacity:1" d="m 5,0 c 0,1.6666667 0,3.3333333 0,5 -2.3333333,0 -4.66666667,0 -7,0 0,3 0,6 0,9 2.33333333,0 4.6666667,0 7,0 0,2.333333 0,4.666667 0,7 3,0 6,0 9,0 0,-2.333333 0,-4.666667 0,-7 2.333333,0 4.666667,0 7,0 0,-3 0,-6 0,-9 -2.333333,0 -4.666667,0 -7,0 0,-2.3333333 0,-4.66666667 0,-7 -3,0 -6,0 -9,0 0,0.6666667 0,1.33333333 0,2 z"/>' +
                    '<path id="Union_8" data-name="Union 8" transform="scale(0.5 0.5) translate(2.5, 2.5)" fill="' +
                    iconColor +
                    '" d="M 7,19 V 12 H 0 V 7 H 7 V 0 h 5 v 7 h 7 v 5 h -7 v 7 z" />'
                );
            case 'cross3':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" height="20.0px" width="20.0px" viewBox="0 0 20.0 20.0">' +
                    '<path id="path837" transform="scale(0.5 0.5) translate(2.5, 2.5)" style="fill:' +
                    outlineColor +
                    ';fill-opacity:1" d="M 2.953125,-1.6386719 C 1.0403377,-0.30081974 -0.87265918,1.0367329 -2.7851562,2.375 c 1.89135247,2.7035271 3.78387253,5.4062371 5.6757812,8.109375 -1.8737223,2.777327 -3.74676469,5.555113 -5.6210938,8.332031 2.48633096,1.678044 4.9732266,3.355253 7.4609376,5.03125 1.9859532,-2.938827 3.9700055,-5.878937 5.9550782,-8.818359 3.077474,0 6.154948,0 9.232422,0 0,-3 0,-6.0000001 0,-9.0000001 -3.052735,0 -6.105469,0 -9.158203,0 C 8.70379,3.0911361 6.6475318,0.15317295 4.5917969,-2.7851562 4.0455729,-2.4029948 3.499349,-2.0208333 2.953125,-1.6386719 Z" />' +
                    '<path id="Union_7" transform="scale(0.5 0.5) translate(2.5, 2.5)" fill="' +
                    iconColor +
                    '" data-name="Union 7" d="M 0.047,18.277 5.318,10.463 0,2.868 4.1,0 9.718,8.029 h 8.2 v 5 H 9.9 l -0.527,0.369 -5.181,7.675 z" />'
                );
            case 'cross5':
                return (
                    `<svg width="17.867188" height="21.816406" viewBox="0 0 17.867187 21.816407">
  <g inkscape:label="outline" style="display:inline" transform="scale(0.5, 0.5) translate(2.8276719,2.3878906)">
    <path
       style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:` +
                    outlineColor +
                    `;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000;stop-opacity:1"
       d="M 6.6430312,-0.43476562 C 6.4021458,0.64986979 6.1612604,1.7345052 5.920375,2.8191406 4.5356989,1.6647364 3.1511215,0.51021361 1.7660781,-0.64375 c -1.49352134,1.7928339 -2.988628,3.5843463 -4.4824219,5.3769531 1.5294458,1.2774246 3.0609385,2.5523945 4.5917969,3.828125 -1.56770831,1.5677089 -3.1354166,3.1354169 -4.703125,4.7031249 1.6497396,1.64974 3.29947919,3.299479 4.9492188,4.949219 1.4322916,-1.432292 2.8645833,-2.864583 4.296875,-4.296875 0.6893721,1.83727 1.3785671,3.674606 2.0683593,5.511719 2.1843718,-0.819976 4.3687108,-1.640036 6.5527348,-2.460938 -1.063647,-2.842343 -2.13022,-5.68359 -3.195313,-8.5253905 0.689455,-3.1028641 1.37882,-6.2057484 2.068359,-9.3085937 C 11.634265,-1.375129 9.3557834,-1.883035 7.076625,-2.3878906 6.9320938,-1.736849 6.7875625,-1.0858073 6.6430312,-0.43476562 Z" />
  </g>
  <g inkscape:label="icon" style="display:inline" transform="scale(0.5, 0.5) translate(2.8276719,2.3878906)">
    <path
       style="color:#000000;font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:medium;line-height:normal;font-family:sans-serif;font-variant-ligatures:normal;font-variant-position:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-alternates:normal;font-variant-east-asian:normal;font-feature-settings:normal;font-variation-settings:normal;text-indent:0;text-align:start;text-decoration:none;text-decoration-line:none;text-decoration-style:solid;text-decoration-color:#000000;letter-spacing:normal;word-spacing:normal;text-transform:none;writing-mode:lr-tb;direction:ltr;text-orientation:mixed;dominant-baseline:auto;baseline-shift:baseline;text-anchor:start;white-space:normal;shape-padding:0;shape-margin:0;inline-size:0;clip-rule:nonzero;display:inline;overflow:visible;visibility:visible;opacity:1;isolation:auto;mix-blend-mode:normal;color-interpolation:sRGB;color-interpolation-filters:linearRGB;solid-color:#000000;solid-opacity:1;vector-effect:none;fill:` +
                    iconColor +
                    `;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;color-rendering:auto;image-rendering:auto;shape-rendering:auto;text-rendering:auto;enable-background:accumulate;stop-color:#000000;stop-opacity:1"
       d="M 7.5351562,-0.32617188 6.1015625,6.1308594 0.9609375,1.8476562 -0.9609375,4.1523438 3.7773438,8.1015625 -1.0605469,12.939453 1.0605469,15.060547 6.140625,9.9804688 8.5957031,16.527344 11.404297,15.472656 8.7011719,8.265625 V 8.263675 8.261725 8.259775 L 10.464844,0.32617188 Z"
       transform="translate(1.061,0.325)" />
  </g>
</svg>`
                );
            case 'flag':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" width="18.786795" height="19.028999" viewBox="0 0 18.786794 19.029">' +
                    `<g inkscape:label="outline" transform="scale(0.5, 0.5) translate(1.0767951,1.345)">
    <g transform="translate(19439.5,10083.185)">
      <path style="fill:` +
                    outlineColor +
                    `;fill-opacity:1;stroke:` +
                    outlineColor +
                    `;stroke-width:4.15359;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;image-rendering:auto"
         d="m -19438.5,-10065.501 v -19.027"
         sodipodi:nodetypes="cc" />
      <path d="m -19439.464,-10082.742 c 0,3.083 0,6.167 0,9.25 5.806,0 11.611,0 17.418,0 -2.102,-1.868 -4.202,-3.735 -6.303,-5.603 2.187,-1.812 4.373,-3.623 6.559,-5.435 -5.891,0 -11.783,0 -17.674,0 0,0.597 0,1.193 0,1.788 z"
         style="fill:` +
                    outlineColor +
                    `;fill-opacity:1;stroke-width:0.893837" />
    </g>
  </g>
  <g
     inkscape:label="icon"
     style="display:inline"
     transform="scale(0.5, 0.5) translate(1.0767951,1.345)">
    <g transform="translate(19439.5,10083.185)">
      <line
         y1="16.676001"
         transform="translate(-19438.5,-10083.177)"
         fill="none"
         stroke="` +
                    iconColor +
                    `"
         stroke-width="2"/>
      <path
         d="m -19641.287,-10072.161 h -12.227 v -8.347 h 12.227 l -4.859,4.028 z"
         transform="translate(215,-2.677)"
         fill="` +
                    iconColor +
                    `" />
    </g>
  </g>`
                );
            case 'polygon3':
                return (
                    `<svg width="25.886719" height="31.066406" viewBox="0 0 25.886719 31.066406">
  <g style="display:inline" transform="scale(0.4, 0.4) translate(2,3.5332031)" fill="` +
                    outlineColor +
                    `">
    <path
              d="M 21.029297,10.285156 C 13.352934,5.6789212 5.675806,1.0739596 -2,-3.5332031 -2,6.8222656 -2,17.177734 -2,27.533203 6.628228,22.354338 15.258211,17.178399 23.886719,12 22.934245,11.428385 21.981771,10.856771 21.029297,10.285156 Z" />
  </g>
  <g inkscape:label="icon" style="display:inline" transform="scale(0.4, 0.4) translate(2,3.5332031)">
    <path
       d="M 12,0 24,20 H 0 Z"
       transform="rotate(90,10,10)"
       fill="` +
                    iconColor +
                    `" />
  </g>
</svg>`
                );
            case 'polygon4':
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg"  height="18.0px" width="18.0px" viewBox="0 0 18.0 18.0">' +
                    '<path id="path848" transform="scale(0.5 0.5) translate(28.828125, 2.828125) rotate(90)" style="display:inline;fill:' +
                    outlineColor +
                    ';fill-opacity:1" d="M 27.414062,11.585938 C 22.609375,6.78125 17.804687,1.9765625 13,-2.828125 7.723958,2.4479167 2.447917,7.7239583 -2.828125,13 2.447917,18.276042 7.723958,23.552083 13,28.828125 18.276042,23.552083 23.552083,18.276042 28.828125,13 28.356771,12.528646 27.885417,12.057292 27.414062,11.585938 Z" />' +
                    '<path data-name="Polygon 6" d="M13,0,26,13,13,26,0,13Z" transform="scale(0.5 0.5) translate(28.828125, 2.828125) rotate(90)" fill="' +
                    iconColor +
                    '"/></svg>'
                );
            case 'polygon5':
                return (
                    `<svg
   width="26" height="27.338" viewBox="0 0 26 27.338">
  <g inkscape:label="outline" style="display:inline">
    <path
      d="M 27.617187,12.494141 C 24.022268,7.5454764 20.426166,2.5976713 16.832031,-2.3515625 10.555007,-0.31086557 4.277332,1.7278289 -2,3.7675781 -2,10.36849 -2,16.969401 -2,23.570312 4.277333,25.610059 10.555006,27.64876 16.832031,29.689453 20.711366,24.348975 24.591212,19.008867 28.472656,13.669922 28.1875,13.277995 27.902344,12.886068 27.617187,12.494141 Z"
      style="fill:` +
                    outlineColor +
                    `;fill-opacity:1"
      transform="scale(0.5, 0.5) translate(5,2)"/>
  </g>
  <g inkscape:label="icon" style="display:inline">
    <path
       d="M 13.669,0 27.338,9.931 22.117,26 H 5.221 L 0,9.931 Z"
       transform="scale(0.5, 0.5) translate(5,2) rotate(90,13,13)"
       fill="` +
                    iconColor +
                    `" />
  </g>
</svg>`
                );
            case 'star':
                return (
                    `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg width="35.351562" height="33.917969" viewBox="0 0 35.351562 33.917969">
  <g inkscape:label="outline" style="display:inline" transform="scale(0.5, 0.5) translate(5.6757812,6.0429688)">
    <path
              d="M 10.113281,-0.66210938 C 9.265625,1.7545573 8.4179688,4.171224 7.5703125,6.5878906 3.1549382,6.702101 -1.2604345,6.8163709 -5.6757812,6.9316406 -2.1703773,9.6379599 1.3356926,12.343416 4.8417969,15.048828 3.5825369,19.324368 2.323202,23.599885 1.0625,27.875 4.7084908,25.347232 8.3542536,22.819136 12,20.291016 15.645746,22.819136 19.291509,25.347233 22.9375,27.875 21.676802,23.599884 20.417463,19.324368 19.158203,15.048828 22.664307,12.343416 26.170376,9.6379587 29.675781,6.9316406 25.260434,6.816371 20.845062,6.702101 16.429688,6.5878906 14.952883,2.3776889 13.476124,-1.8325286 12,-6.0429688 c -0.628906,1.7936198 -1.257813,3.5872396 -1.886719,5.38085942 z"
       style="fill:` +
                    outlineColor +
                    `;fill-opacity:1" />
  </g>
  <g inkscape:label="icon" style="display:inline" transform="scale(0.5, 0.5) translate(5.6757812,6.0429688)">
    <path
              data-name="Polygon 10"
       d="M 12,0 15,8.553 24,8.785 16.854,14.3 19.416,23 12,17.857 4.584,23 7.146,14.3 0,8.785 9,8.553 Z"
       fill="` +
                    iconColor +
                    `" />
  </g>
</svg>
`
                );
            case 'star3':
                return (
                    `<svg width="26" height="23" viewBox="0 0 26 23">
    <g inkscape:label="outline" style="display:inline" transform="scale(0.7, 0.7) translate(-2,-2)">
      <path
         d="M 13,0 16.9,13.033 26,23 13,19.933 0,23 9.1,13.033 Z"
         fill="` +
                    outlineColor +
                    `" />
  </g>
  <g inkscape:label="icon" style="display:inline" transform="scale(0.5, 0.5) translate(2.5, 3.5)">
      <path
         d="M 13,0 16.9,13.033 26,23 13,19.933 0,23 9.1,13.033 Z"
         fill="` +
                    iconColor +
                    `" />
  </g>
</svg>
`
                );
            case 'star4':
                return (
                    `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg width="33.15625" height="33.15625" viewBox="0 0 33.15625 33.15625">
  <g inkscape:label="outline" style="display:inline" transform="scale(0.5, 0.5) translate(5.078125,5.078125)">
    <path
       d="M 9.6621094,-0.78710938 C 8.6171875,1.6510417 7.5722656,4.0891927 6.5273438,6.5273438 2.658984,8.1851988 -1.2092412,9.843368 -5.078125,11.5 -1.2092415,13.156633 2.658984,14.814801 6.5273438,16.472656 8.1851988,20.341016 9.843368,24.209241 11.5,28.078125 13.156633,24.209241 14.814801,20.341016 16.472656,16.472656 20.341016,14.814801 24.209241,13.156632 28.078125,11.5 24.209241,9.8433674 20.341016,8.185199 16.472656,6.5273438 14.814801,2.658984 13.156632,-1.2092412 11.5,-5.078125 c -0.61263,1.4303385 -1.22526,2.8606771 -1.8378906,4.29101562 z"
       style="fill:` +
                    outlineColor +
                    `;fill-opacity:1" />
  </g>
  <g inkscape:label="icon" style="display:inline" transform="scale(0.5, 0.5) translate(5.078125,5.078125)">
    <path
       d="M 11.5,0 14.95,8.05 23,11.5 14.95,14.95 11.5,23 8.05,14.95 0,11.5 8.05,8.05 Z"
       fill="` +
                    iconColor +
                    `" />
  </g>
</svg>
`
                );
            default:
                return (
                    '<svg xmlns="http://www.w3.org/2000/svg" height="15.0px" width="15.0px" viewBox="0 0 15.0 15.0">' +
                    '<path id="circle839" transform="scale(0.6 0.6) translate(0.85036544,0.852078)" style="fill:' +
                    outlineColor +
                    ';fill-opacity:1;stroke-width:0.904317" d="M 9.7158957,-0.84833448 C 5.5070914,-0.78613644 1.50106,1.9770451 -0.04297807,5.8942342 -1.7065295,9.8155813 -0.73846089,14.66011 2.2997935,17.64461 c 2.966275,3.064198 7.8111555,4.064656 11.7478395,2.422787 3.929005,-1.52305 6.717799,-5.518007 6.799139,-9.730103 C 21.02725,6.1286562 18.492772,1.9681967 14.66639,0.20409384 13.130657,-0.53199649 11.418284,-0.89333775 9.7158957,-0.84833448 Z" />' +
                    '<circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5) translate(2.95036544,2.952078)" fill="' +
                    iconColor +
                    '" stroke="' +
                    iconColor +
                    '"/></svg>'
                );
        }
    }
}
function getIconAnchor(symbol, outlineColor) {
    if (outlineColor == null || outlineColor == '') {
        switch (symbol) {
            case 'arrow':
                return [5, 11];
            case 'circle':
                return [5, 8.5];
            case 'cross': //////// validate this one
                return [5, 5];
            case 'cross3':
                return [4, 8];
            case 'cross5':
                return [3, 9];
            case 'flag':
                return [2, 9];
            case 'polygon3':
                return [3, 8];
            case 'polygon4':
                return [6.5, 7];
            case 'polygon5':
                return [6, 7];
            case 'star':
                return [6, 8];
            case 'star3':
                return [6.5, 9];
            case 'star4':
                return [6, 8];
            default:
                return [5, 5];
        }
    } /*icon with outline*/ else {
        switch (symbol) {
            case 'arrow':
                return [5, 11];
            case 'circle':
                return [6.5, 6.5];
            case 'cross':
                return [6, 6];
            case 'cross3':
                return [5.125, 6.125];
            case 'cross5':
                return [5, 5.75];
            case 'flag':
                return [3, 4];
            case 'polygon3':
                return [3.5, 6.5];
            case 'polygon4': //done
                return [7.75, 7.75];
            case 'polygon5':
                return [8.5, 7.75];
            case 'star':
                return [9, 9];
            case 'star3':
                return [7.75, 9];
            case 'star4':
                return [8, 8];
            default:
                return [5, 5];
        }
    }
}
