import Vue from 'vue';
import Vuex from 'vuex';
import { projects } from './projects.module';
import { projectLayers } from './projectLayers.module';
import { common } from './common.module';
import { datasets } from './dataset.module';
import { datasources } from './datasource.module';
import { emergencyManagement } from './emergencyManagement.module';
import { store } from './store';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);
const vuexPersist = new VuexPersist({
    key: 'my-app',
    storage: window.localStorage,
    reducer: (state) => ({
        projects: state.projects,
        // projectLayers: state.projectLayers,
        common: state.common,
        datasets: state.datasets,
        datasources: state.datasources,
        emergencyManagement: state.emergencyManagement,
        store: state.store,
    }),
});
export default new Vuex.Store({
    modules: {
        projects,
        projectLayers,
        common,
        datasets,
        datasources,
        emergencyManagement,
        store,
    },
    plugins: [vuexPersist.plugin],
});
