<template>
    <div class="over">
        <b-container fluid class="footerBar"> </b-container>
    </div>
</template>

<script>
export default {};
</script>

<style>
.footerBar {
    height: 8px;
    background: linear-gradient(0.25turn, #3097cb, #b133a1);
    position: fixed;
    bottom: 0px;
    z-index: 9999;
    padding: 0;
    left: 0;
    /* margin-top: 115px !important; */
}
.over {
    z-index: 999;
}
</style>
