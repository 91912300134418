<template>
    <div id="filter-type-img">
        <img v-if="isDate" src="./images/date.svg" />
        <img v-if="isNumber" src="./images/numbers.svg" />
        <img v-if="isString" src="./images/text.svg" />
    </div>
</template>

<script>
export default {
    name: 'FilterTypeIcon',
    props: {
        filterType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isDate: false,
            isNumber: false,
            isString: false,
        };
    },
    mounted() {
        if (this.filterType === 'string') {
            this.isString = true;
        } else if (this.filterType === 'number') {
            this.isNumber = true;
        } else if (this.filterType === 'date') {
            this.isDate = true;
        }
    },
};
</script>

<style lang="scss" scoped></style>
