import { spatialFunctions } from './spatialFunctions';

export class Point {
    constructor(x, y) {
        this.x = parseFloat(x);
        this.y = parseFloat(y);
    }

    get as_coordinates() {
        return [this.y, this.x];
    }
}

export class Line {
    constructor(start_point, end_point) {
        this.start_point = start_point;
        this.end_point = end_point;
    }

    get length() {
        return Math.sqrt(
            (this.end_point.x - this.start_point.x) ** 2 +
                (this.end_point.y - this.start_point.y) ** 2
        );
    }

    get midpoint() {
        return new Point(
            (this.start_point.x + this.end_point.x) / 2,
            (this.start_point.y + this.end_point.y) / 2
        );
    }

    get coordinates() {
        return [
            [this.start_point.y, this.start_point.x],
            [this.end_point.y, this.end_point.x],
        ];
    }
}

export class Vector {
    constructor(start_point, velocity, speed_unit, hours, angle, type) {
        this.start_point = start_point;
        this.velocity = velocity;
        this.speed_unit = speed_unit;
        this.hours = hours;
        this.angle = this.convert_angle(angle);
        this.type = type;
        this.direction = 'N';
        this.show_direction_input = false;
        this.type_is_valid = true;
    }

    convert_angle(angle_degrees) {
        // return 90 - angle_degrees;
        return angle_degrees;
    }

    set_angle(angle_degrees) {
        this.angle = angle_degrees; //this.convert_angle(angle_degrees);
    }

    get color() {
        switch (this.type.toLowerCase()) {
            case 'wind':
                return '#00cc00';
            case 'current':
                return '#00a2e8';
            case 'tide':
                return '#3f48cc';
            default:
                return 'black';
        }
    }

    get radians() {
        return (this.angle * Math.PI) / 180;
    }

    get speed_meter_per_second() {
        switch (this.speed_unit) {
            case 'm/s':
                return this.velocity;
            case 'km/h':
                return this.velocity / 3.6;
            case 'mph':
                return this.velocity / 2.237;
            case 'ft/s':
                return this.velocity / 3.281;
            case 'knots':
                return this.velocity * 1.944;
            default:
                return this.velocity;
        }
    }

    get time_seconds() {
        return this.hours * 3600;
    }

    get end_point() {
        let x = spatialFunctions.meters2lng(
            spatialFunctions.lng2meters(this.start_point.x) +
                this.speed_meter_per_second *
                    this.time_seconds *
                    Math.sin(this.radians)
        );
        let y = spatialFunctions.meters2lat(
            spatialFunctions.lat2meters(this.start_point.y) +
                this.speed_meter_per_second *
                    this.time_seconds *
                    Math.cos(this.radians)
        );

        return new Point(x, y);
    }

    get length() {
        return Math.sqrt(
            (this.end_point.x - this.start_point.x) ** 2 +
                (this.end_point.y - this.start_point.y) ** 2
        );
    }

    get as_json() {
        return {
            type: 'Feature',
            properties: {
                type: this.type,
                velocity: this.velocity,
                speed_unit: this.speed_unit,
                time: this.time,
                angle: this.angle,
            },
            geometry: {
                type: 'LineString',
                coordinates: [
                    [
                        parseFloat(this.start_point.y),
                        parseFloat(this.start_point.x),
                    ],
                    [
                        parseFloat(this.end_point.y),
                        parseFloat(this.end_point.x),
                    ],
                ],
            },
        };
    }
}
