<template>
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <!-- main Div  -->
    <div class="dataset-export" v-else>
        <b-col class="text-right" v-if="showExportButton">
            <b-button rounded @click="exportItem" class="buttonSelect">
                Export <img src="../../assets/icon_down_circle.svg" class="pl-2"
            /></b-button>
        </b-col>
        <!--<ejs-grid
      ref="grid"
      id="Grid"
      style="display: none"
      :dataSource="dataSource"
      height="500"
      :allowExcelExport="true"
    >
      <e-columns>
        <e-column field="location" headerText="Location" width="100"></e-column>
        <e-column
          field="matrixdesc"
          headerText="MatrixDesc"
          width="120"
        ></e-column>
        <e-column field="result" headerText="Result" width="60"></e-column>
        <e-column field="test" headerText="Test" width="120"></e-column>
        <e-column field="chemical" headerText="Chemical" width="120"></e-column>
        <e-column field="link1" headerText="Link1" width="120"></e-column>
        <e-column
          field="collectiondate"
          headerText="Collection Date"
          width="120"
        ></e-column>
        <e-column field="welldepth" headerText="Welldepth" width="70"></e-column>
      </e-columns>
    </ejs-grid>-->

        <div v-if="hideheader != 1" class="row mt-4">
            <portal-bar-tool
                :disableDocIcon="disableDocIcon"
                :disablePostingIcon="disablePostingIcon"
                :disableSearchIcon="disableSearchIcon"
                :showReportingMenu="showReportingMenu"
                :hidetableSelect="hidetableSelect"
            ></portal-bar-tool>

            <!-- Map is Shown in this Part of Div -->

            <b-container>
                <!-- <div class="border-bottom py-3 mb-3"></div> -->

                <b-row>
                    <b-col>
                        <div>
                            Select the file type to export the information to.
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="4" lg="2" md="3" sm="4">
                        <div
                            class="csvBox"
                            v-bind:class="{
                                activeItem: activeExportItem === 'csv',
                            }"
                            @click="showExport('csv')"
                        >
                            <img
                                src="../../assets/icon_csv.svg"
                                font-scale="1.5"
                                class="mx-3"
                            />CSV
                        </div>
                    </b-col>
                    <b-col cols="4" lg="2" md="3" sm="4">
                        <div
                            class="csvBox"
                            v-bind:class="{
                                activeItem: activeExportItem === 'xls',
                            }"
                            @click="showExport('xls')"
                        >
                            <img
                                src="../../assets/icon_xlsx.svg"
                                font-scale="1.5"
                                class="mx-3"
                            />XLSX
                        </div>
                        <!-- <div class="col px-md-5"><div class="p-2 border">Custom column padding</div></div> -->
                    </b-col>
                    <b-col cols="4" lg="2" md="3" sm="4">
                        <div
                            class="csvBox"
                            v-bind:class="{
                                activeItem: activeExportItem === 'accdb',
                            }"
                            @click="showExport('accdb')"
                        >
                            <img
                                src="../../assets/icon_accdb.svg"
                                font-scale="1.5"
                                class="mx-3"
                            />ACCDB
                        </div>
                        <!-- <div class="col px-md-5"><div class="p-2 border">Custom column padding</div></div> -->
                    </b-col>
                </b-row>
            </b-container>
            <div id="exportGrid"><div id="Grid"></div></div>
            <ToastMessages
                :showSuccess="showSuccess"
                :showConnecting="showConnecting"
                :connectingToastMessage="connectingToastMessage"
                :successToastMessage="successToastMessage"
            />
            <!-- <div class="connectingToastMsg" v-if="processExport">
                        <img src="../../assets/connecting_icon.svg" class="mr-3"/>
                        50 % complete <br/> processing your request
                        <img src="../../assets/close_big.svg" class="mx-4" @click="closeProcessToast"/>
                    </div>
                    <div class="successToastMsg" v-if="successToast">
                        <img src="../../assets/success_tick.svg" class="mr-3"/>
                        Success  <br/> exported successfully 
                        <img src="../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
                    </div> -->
        </div>

        <!-- Div For Main Data Posting Tool After clicking data Posting Tool ENDS BELOW -->
    </div>
    <!-- Main Div Of this Component end below -->
</template>
<script>
import {
    GridPlugin,
    Toolbar,
    ExcelExport,
    Grid,
} from '@syncfusion/ej2-vue-grids';
import Vue from 'vue';
import { mapActions } from 'vuex';
import PortalBarTool from '../PortalBarTool';
import ToastMessages from '../ToastMessages.vue';
import AccessPermissionModal from '../AccessPermissionModal';
import { datasetService } from '../../services/dataset.service';
import { commonService } from '../../services/common.service';

Vue.use(GridPlugin);
export default {
    components: {
        PortalBarTool,
        ToastMessages,
        AccessPermissionModal,
    },
    name: 'datasetexport',
    props: {
        showReportingMenu: Boolean,
        hidetableSelect: Boolean,
        dataSource: Array,
    },
    data() {
        return {
            toolbarOptions: ['ExcelExport'],
            hideheader: 0,
            datasetname: '',
            isClicked: false,
            status: false,
            currentSwitchState: false,
            activeExportItem: '',
            showExportButton: false,
            // dataSource: [],
            displayFields: [],
            isRender: false,
            processExport: false,
            // successToast:false,
            toolParams: null,
            showSuccess: false,
            showConnecting: false,
            connectingToastMessage: null,
            successToastMessage: null,
            disableDocIcon: true,
            disableSearchIcon: true,
            disablePostingIcon: true,
            showPage: false,
        };
    },

    mounted: function() {
        // this.getPermissions();
        this.pagePermit();
    },
    created() {
        // this.pagePermit();
        this.getDatasetFilteredData();
        this.hideheader = localStorage.getItem('headerHide');
        //this.dataSource = JSON.parse(localStorage.getItem("FilteredData"));
        //  this.dataSource = JSON.parse(localStorage.getItem('FilteredDataExport'));
        //  this.dataSource = this.$store.state.filteroptions.filteroptions.items;

        // setTimeout(() =>{
        //   this.createDataGrid();
        // }, 5000)
    },
    provide: {
        grid: [Toolbar, ExcelExport],
    },

    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('DatasetExport')) {
                    vm.showPage = true;
                }
            });
            // this.$emit("datasetexportDash", this.showPage);
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        createDataGrid: function() {
            Grid.Inject(ExcelExport);
            this.grid = new Grid({
                allowExcelExport: true,
                height: 520,
            });

            document.querySelector('#exportGrid #Grid').innerHTML = '';
            this.grid.appendTo('#exportGrid #Grid');
            this.grid.dataSource = this.dataSource;
            this.grid.columns = this.displayFields;
        },
        getDatasetFilteredData() {
            var filterOptions = JSON.parse(localStorage.getItem('gridFilter'));
            datasetService
                .filteredData(filterOptions)
                .then((res) => {
                    this.dataSource = res.data;
                    this.displayFields = [];
                    for (
                        let i = 0;
                        i < Object.keys(this.dataSource[0]).length;
                        i++
                    ) {
                        this.displayFields.push({
                            field: Object.keys(this.dataSource[0])[i],
                            headerText: Object.keys(this.dataSource[0])[i],
                            width: '180px',
                        });
                    }
                })
                .catch((err) => {
                    if (
                        err.response.data.Message == 'ForbiddenSQLInjection' &&
                        err.response.data.StatusCode == 500
                    ) {
                        this.$emit(
                            'resForbiddenExport',
                            err.response.data.Message
                        );
                    } else {
                        this.showFailure = true;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
        },
        showExport(item) {
            this.showExportButton = true;
            this.activeExportItem = item;
        },
        //  closeSuccessToast(){
        //         this.successToast = false
        //     },
        //      closeProcessToast(){
        //         this.processExport = false
        //     },
        exportItem() {
            // this.createDataGrid();
            this.showConnecting = true;
            this.connectingToastMessage =
                '50 % complete processing your request';
            // setTimeout(() => {
            this.showConnecting = false;

            if (this.activeExportItem === 'xls') {
                this.$emit('makeExcel');
                this.showSuccess = true;
                this.successToastMessage = 'exported successfully ';
            } else if (this.activeExportItem === 'csv') {
                this.$emit('makeCsv');
                this.showSuccess = true;
                this.successToastMessage = 'exported successfully ';
            } else if (this.activeExportItem === 'accdb') {
                var activeFilters = JSON.parse(
                    localStorage.getItem('gridFilter')
                );
                datasetService.exportToAccessDB(activeFilters).then((res) => {
                    // download zip file
                    var blob = commonService.b64ToBlob(
                        res.data,
                        'application/zip'
                    );
                    const href = URL.createObjectURL(blob);
                    // create "a" HTML element with href to file & click
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', 'dataset.zip'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.showSuccess = true;
                    this.successToastMessage = 'exported successfully ';
                });
            }

            // }, 500);
            setTimeout(() => {
                this.showSuccess = false;
            }, 1000);
        },
    },
};
</script>
<style lang="scss" scoped>
.inputbox {
    width: 499px;
    height: 48px;
}
.custom-select {
    background: #fff url('../../../assets/arrow_down.svg') no-repeat right
        0.75rem center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.normal_input {
    height: 48px !important;
    border-radius: 3px;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-left: -7px;
    width: 500px;
    text-align: center;
    cursor: pointer;
}
.custom-control {
    display: inline;
    margin-left: 10px;
}
.cbox {
    display: block !important;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 29px;
    margin-top: 0px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.small_drop {
    width: 440px;
}
/* style for title block preview */
.block-2 {
    .preview_logo {
        .side_block {
            //border: 1px solid #dee2e6;
            display: block;
            border-radius: 3px;
            height: auto;
            width: 437px;
            //box-shadow: 0 1px 1px 1px #dee2e6 !important;
            .line_block {
                text-align: right;

                span {
                    font-size: 14px;
                    padding-right: 5px;
                }
            }
            .footer-section {
                border-top: 1px solid #dee2e6;
                span {
                    text-align: left;
                    font-size: 12px;
                }
                span.center {
                    text-align: center;
                }
            }
        }
    }
}
.editblock {
    height: 48px;
    width: 148px;
    background-color: #006395;
    color: #fff;
}
.preview {
    color: #161616;
    font-size: 18px;
    font-weight: bold;
}
ul li {
    list-style: none;
}
/* CSS used here will be applied after bootstrap.css */
.vmiddle {
    display: flexbox;
    vertical-align: middle;
    float: none;
    padding-right: 20px;
}
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}

.csvBox {
    border: 1px solid #c6c6c6;
    padding: 10px;
    width: 145px;
    height: 91px;
    display: flex;
    align-items: center;
    text-align: center;
}
.csvBox:hover {
    border: 1px solid #000;
    background-color: #eaeaea;
    padding: 10px;
    width: 145px;
    height: 91px;
    display: flex;
    align-items: center;
    text-align: center;
}
.buttonSelect {
    position: absolute;
    top: -156px;
    right: 66px;
}
.activeItem {
    border: 2px solid #1b1b1b;
    background-color: #eaeaea;
    padding: 10px;
    width: 145px;
    height: 91px;
    display: flex;
    align-items: center;
    text-align: center;
}
#exportGrid {
    visibility: hidden;
    height: 0;
    overflow: hidden;
}
</style>
