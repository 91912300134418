var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"mobile-map-bottom-bar"}},[_c('div',{class:_vm.showOptions ? 'show-options' : 'hide-options',attrs:{"id":"mobile-bottom-bar-content"}},[(_vm.showOptions)?_c('button',{staticClass:"bottom-bar-options-toggle",on:{"click":_vm.toggleOptions}},[_c('img',{attrs:{"src":require("../../../assets/EM_arrow_down.svg")}}),_c('br'),_vm._v(" Hide options ")]):_c('button',{staticClass:"bottom-bar-options-toggle",on:{"click":_vm.toggleOptions}},[_c('img',{attrs:{"src":require("../../../assets/EM_arrow_up.svg")}}),_c('br'),_vm._v("Show options ")]),_c('div',{staticClass:"toolbar"},[_c('button',{staticClass:"fixed-width",on:{"click":_vm.openProjectDetails}},[_c('img',{staticClass:"tool-button-icon",attrs:{"src":require("../../../assets/event_panel.svg")}}),_c('br'),_vm._v("Project details ")]),_c('button',{staticClass:"dynamic-width",on:{"click":_vm.openTableOfContents}},[_c('img',{staticClass:"tool-button-icon",attrs:{"src":require("../../../assets/layers-icon.svg")}}),_c('br'),_vm._v("Map layers ")]),_c('button',{staticClass:"fixed-width",on:{"click":_vm.openAttributes}},[_c('img',{staticClass:"tool-button-icon",attrs:{"src":require("../../../assets/identify.svg")}}),_c('br'),_vm._v("Attributes ")])])]),_c('mobile-bottom-pane',{attrs:{"componentName":_vm.paneComponent,"identifyResults":_vm.identifyResults},on:{"close":function($event){_vm.paneComponent = ''},"clearResults":function () {
                _vm.$emit('clearResults');
                _vm.$emit('clearHighlight');
            },"clearHighlight":function () {
                _vm.$emit('clearHighlight');
            },"zoomTo":function (feature) {
                _vm.$emit('zoomTo', feature);
            },"highlight":function (feature) {
                _vm.$emit('highlight', feature);
            },"reverseLookup":function (layerID, featureID) {
                _vm.$emit('reverseLookup', layerID, featureID);
            },"updateClickFunction":_vm.updateClickFunction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }