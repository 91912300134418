<template>
    <div class="titleblock">
        <b-container class="define-tool">
            <p class="my-3">Add styling to graph axis</p>
            <b-row
                v-for="(item, index) in axisDetails"
                :key="index"
                style="height:400px"
            >
                <b-col class="block-1">
                    <div class="line-block mt-6 pt-3">
                        <div class="ml-4 mt-4 style-block">
                            <span :style="getCss(item.style)">{{
                                item.axisAlias
                            }}</span>
                            <b-check
                                v-model="item.useDefaultStyle"
                                class="mt-2 mb-2 checkbox"
                                @change="loadDefaultStyle(item)"
                                >Load default GHD styles</b-check
                            >

                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Size',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="item.style.size"
                                    :options="styleDataSize"
                                    text-field="Size"
                                    value-field="Size"
                                    @change="checkDefault(item)"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Size</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>

                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Style',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="item.style.fontWeight"
                                    :options="styleDataforStyle"
                                    text-field="Style"
                                    value-field="Style"
                                    @change="checkDefault(item)"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Style</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>

                            <div class="pickColor wrap">
                                <div class="">
                                    <button class="btn-transparent">
                                        <img
                                            src="../../assets/ghd_colorpicker.svg"
                                            font-scale="1.5"
                                            class="pencil-icon"
                                        />
                                    </button>
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Color',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <ejs-colorpicker
                                            id="element"
                                            type="text"
                                            class="e-input"
                                            v-model="item.style.color"
                                            @change="checkDefault(item)"
                                        ></ejs-colorpicker>
                                    </CustomFloatingLabel>
                                </div>
                            </div>

                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Type',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="item.style.fontFamily"
                                    :options="styleDataType"
                                    text-field="Type"
                                    value-field="Type"
                                    @change="checkDefault(item)"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Type</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Vue from 'vue';
import CustomFloatingLabel from '../CustomFloatingLabel';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';
import { projectService } from '../../services/project.service';
Vue.use(ColorPickerPlugin);
export default {
    components: {
        CustomFloatingLabel,
    },
    props: {
        axisDetails: Array,
    },
    data() {
        return {
            defaultSize: '',
            defaultStyle: '',
            defaultType: '',
            defaultColor: '#000000',
            styleDataforStyle: [],
            styleDataType: [],
            styleDataSize: [],
        };
    },
    methods: {
        getStyleData() {
            projectService.getStyleStyle().then((res) => {
                this.styleDataforStyle = res.data;
                let defaultStyle = this.styleDataforStyle.find(
                    (element) => element.IsDefault === true
                );
                this.defaultStyle = defaultStyle.Style;
            });

            projectService.getStyleType().then((res) => {
                this.styleDataType = res.data;
                let defaultType = this.styleDataType.find(
                    (element) => element.IsDefault === true
                );
                this.defaultType = defaultType.Type;
            });

            projectService.getStyleSize().then((res) => {
                this.styleDataSize = res.data;
                let defaultSize = this.styleDataSize.find(
                    (element) => element.IsDefault === true
                );
                this.defaultSize = defaultSize.Size;
            });
        },
        saveAxisDetails() {
            this.$emit('redirectToScatterTab');
        },
        loadDefaultStyle(item) {
            item.style.fontFamily = this.defaultType;
            item.style.fontWeight = this.defaultStyle;
            item.style.size = 18;
            item.style.color = this.defaultColor;
        },
        getCss(style) {
            return {
                'font-size': style.size + 'px',
                'font-family': style.fontFamily,
                color: style.color,
                'font-weight': style.fontWeight,
            };
        },
        checkDefault(item) {
            item.useDefaultStyle = false;
        },
    },
    mounted() {
        this.getStyleData();
    },
    computed: {
        getCsss(style) {
            return {
                'font-size': style.size + 'px',
                'font-family': style.fontFamily,
                color: style.color,
                'font-weight': style.fontWeight,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
    background-color: white !important;
}
.define-tool {
    p {
        font-size: 16px;
        color: #515151;
        margin-left: -16px;
    }
    .custom-select {
        background: #fff url('../../assets/arrow_down.svg') no-repeat right
            0.75rem center/8px 10px !important;
        height: 48px;
    }
    button {
        border-radius: 3px;
    }
    .block-1 {
        display: block;
        border-radius: 3px;
        width: 100%;

        // h5 {
        //   font-size: 16px;
        // }
        .line-block {
            width: 100%;
            // margin-left: -15px;
            // margin-right: -15px;
            textarea {
                height: 100px;
                width: 400px;
                border-radius: 3px;
            }
            .small_drop {
                width: 333px;
            }
            .delete {
                width: 46px;
                height: 46px;
                float: right;

                /* margin-left: 9px; */
                margin-right: 29px;
                margin-top: 10px;
                background: url('../../assets/delete_icon.svg') no-repeat right !important;
            }
            .style-block {
                select {
                    width: 400px;
                }
                img {
                    min-width: 0px !important;
                    position: relative;
                    top: 5px;
                }
                span {
                    font-size: 18px;
                    font-weight: bold;
                }
                input {
                    width: 400px;
                    height: 48px;
                    margin-top: -7px;
                    margin-bottom: 8px;
                    border: 0px;
                    background: transparent;
                }
            }
            span {
                font-size: 16px;
            }
        }
        .upload_logo {
            span {
                margin-left: -15px;
                font-size: 14px;
            }

            .drag-drop {
                margin-left: -16px;
                margin-right: auto;
                margin-top: 10px;
                text-align: center;
                border: dotted #c6c6c6;
                background-color: #f8f8f8;
                width: 454px;
                height: 192px;
                p {
                    text-align: center;
                    // margin-top: 50px;
                    width: 250px;
                    margin-left: auto;
                    margin-right: auto;
                    a {
                        font-size: 16px;
                        color: #2761df !important;
                    }
                }
            }
            button {
                background-color: #006395;
                padding: 5px 15px;
                color: #fff;
                margin-left: -14px;
                border: 0 !important;
                margin-top: 10px;
                border-radius: 3px;
            }
        }
        .footer_text {
            margin-left: -15px;
            margin-top: 30px;
            textarea {
                width: 454px;
                height: 80px;
                border-radius: 3px;
                margin-bottom: 169px;
            }
            span {
                font-size: 18px;
                //font-family: Arial;
            }
        }
        .borderBlue {
            width: 50px;
        }
    }
    .row {
        display: block;
    }
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-left: 24px;
    width: 400px;
    text-align: center;
    padding: 10px 10px;
}
.normal_button {
    width: 454px;
    margin-left: -16px;
    padding: 10px;
}
.normal_button1 {
    padding: 10px;
}

.text-right.icons {
    margin-top: -10px;
    //margin-left: 40px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}

.open-toggle {
    float: right;
    margin-top: 8px;
}
.border {
    height: 40px;
    left: 5px;
    /* width: 987px; */
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}

.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    left: 1px;
    top: 12px;
    z-index: 9999999;
}

.reportingTool {
    width: 215px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}

.titleBlock {
    margin: 10px 15px;
    height: 30px;
}
.titleBlock small:hover {
    border: 3px solid #b034a1;
    height: 30px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 30px;
    width: 30px;
    text-align: center;
}

.graphing {
    margin: 10px 15px;
    height: 30px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 30px;
}
.graphing span {
    background-color: #8456b0;
    height: 30px;
    width: 30px;
    text-align: center;
}

.dataset {
    margin: 10px 15px;
    height: 30px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 30px;
}
.dataset span {
    background-color: #5878be;
    height: 30px;
    width: 30px;
    text-align: center;
}

.contouring {
    margin: 10px 15px;
    height: 30px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 30px;
}
.contouring span {
    background-color: #3296ca;
    height: 30px;
    width: 30px;
    text-align: center;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}

.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.preview {
    position: absolute;
    //bottom: 47%;
}
.title-m-view p {
    position: absolute;
    top: -95px;
    right: 15px;
}
.title-m-view-btn .savebtn {
    position: absolute;
    top: -105px;
    right: -225px;
}
#e-split-btn_0_dropdownbtn {
    right: 1px;
}
.wrap {
    display: inline-block;
    width: 400px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 15px;
}

/* Input element customization */
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px !important;
    margin: 4px 0 0 0;
    opacity: 1;
    //position: relative;
    width: 75px;

    // left:55px;
    //top: 4px;
}
#element .e-apply {
    color: #006395 !important;
    font-size: 14px !important;
}

.e-colorpicker-wrapper .e-colorpicker,
.e-colorpicker-container .e-colorpicker {
    opacity: 1;
    width: 499px;
    height: 48px;

    padding-left: 15px;
    font-size: 14px;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none;
}

/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    border-bottom-color: rgba(0, 0, 0, 0.42);
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}

.e-colorpicker-wrapper,
.e-colorpicker-container {
    display: inline-block;
    line-height: 0;
    outline: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.datasetcolumns
    .e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-split-colorpicker.e-split-btn,
.datasetcolumns .e-colorpicker-wrapper .e-btn.e-icon-btn {
    z-index: 9;
    position: relative;
    top: -34px;
    left: 5px;
    background: transparent !important;
}
.datasetcolumns
    .e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-split-colorpicker
    + .e-dropdown-btn {
    background-color: transparent !important;
    margin-left: -20px;
    z-index: 999;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    position: absolute !important;
    top: 7px !important;
    left: 73px !important;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    margin-top: 7px;
    bottom: 0 !important;
}
.e-icon-btn.e-split-btn {
    margin-left: 12px;
}
.e-split-btn {
    position: relative;
    top: 7px;
    left: 12px;
}
.iconplacing {
    text-align: center;
    padding-top: 13px;
    position: relative;
    top: 10px;
}
.saveHeader {
    height: 0;
}
.saveHeader .align-items-center {
    float: right;
}
.style-block .e-split-btn-wrapper {
    float: left !important;
}
.titleSave {
    float: right;
    margin-left: 190px !important;
    z-index: 0;
}
.fileError {
    color: #f00;
    margin-left: -16px;
}
@media only screen and (max-width: 991px) {
    .wrap {
        width: 325px;
    }
}

.footerText {
    padding: 5px 0;
    border-top: 1px solid #dee2e6;
}
.line_block:last-child {
    margin-bottom: 5px;
}

[id^='titleBlockText'] {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    margin: 24px;
    resize: vertical;
    overflow: auto;
    height: 70px;
    width: 400px;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .wrap {
        width: 100%;
    }
    .portalTabs ul.nav-tabs li a {
        height: 28px;
    }
}

.saveHeader {
    height: 0;
}
.saveHeader .align-items-center {
    float: right;
}
</style>
