<template>
    <div>
        <b-row class="nav nav-tabs m-0">
            <b-col class="p-0">
                <div active-nav-item-class=" pl-0 pr-4 ml-4 pos_layer ">
                    <button
                        class="posted_layer"
                        :class="{
                            borderBlue: selectedLayerAccordion == 'project',
                        }"
                        @click="updateSelectedLayerAccordion('project')"
                    >
                        Project layers
                    </button>
                </div>
            </b-col>
            <b-col class="nav-item proj_layer p-0">
                <div class="project_layers">
                    <button
                        class="px-0 project_layer"
                        :class="{
                            borderBlue: selectedLayerAccordion == 'posted',
                        }"
                        @click="updateSelectedLayerAccordion('posted')"
                    >
                        Posted layers
                    </button>
                </div>
            </b-col>
        </b-row>
        <!-- project layers -->
        <div
            v-if="selectedLayerAccordion == 'project'"
            :class="accordionHeight ? 'overflow' : 'modalOverflow'"
        >
            <div
                class="accordion accBorder"
                v-for="(item, index) in classic_tocProjectLayers"
                :key="item.id"
                role="tablist"
            >
                <b-card no-body>
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                            block
                            href="#"
                            v-b-toggle="'accordion-' + index"
                            class="text-left"
                            variant="light"
                        >
                            <b-row class="mr-0">
                                <b-col cols="10">
                                    {{ item.ProjectName }}
                                </b-col>
                                <b-col
                                    cols="1"
                                    class="d-flex align-items-center"
                                >
                                    <span class="when-opened">
                                        <a href="#"
                                            ><img
                                                src="../../../assets/minus_acc.svg"
                                                class="icon-20"
                                        /></a>
                                    </span>
                                    <span class="when-closed">
                                        <a href="#"
                                            ><img
                                                src="../../../assets/plus_acc.svg"
                                                class="icon-20"
                                        /></a>
                                    </span>
                                </b-col>
                            </b-row>
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        v-for="(lyr, idx) in item.Layers"
                        :key="lyr.lyrid"
                        :id="'accordion-' + index"
                        accordion="my-accordion"
                        role="tabpanel"
                        class="text-left"
                        visible
                    >
                        <b-card-body>
                            <b-card-text>
                                <b-row v-if="!lyr.isWMS">
                                    <b-col cols="10" class="pr-0">
                                        <b-row class="ml-1">
                                            <!-- loading icon -->
                                            <span
                                                class="spinner-border loadingIcon"
                                                role="status"
                                                v-if="
                                                    lyr.geomDataLoaded ==
                                                        false &&
                                                        lyr.Geometry.features
                                                            .length > 0
                                                "
                                            >
                                                <span class="sr-only"
                                                    >Loading...</span
                                                >
                                            </span>
                                            <!-- Project layer legend -->
                                            <span
                                                v-if="
                                                    lyr.Geometry.features
                                                        .length == 0
                                                "
                                                style="width: 13px;padding-right: 5px; "
                                            ></span>
                                            <span
                                                v-else-if="
                                                    lyr.Geometry.features[0]
                                                        .geometry.type ==
                                                        'Point'
                                                "
                                                style="padding-right: 5px; "
                                                v-html="
                                                    getIcon(
                                                        lyr.SymbologyColour,
                                                        lyr.Symbology
                                                    )
                                                "
                                            ></span>
                                            <span
                                                v-else-if="
                                                    lyr.Geometry.features[0]
                                                        .geometry.type ==
                                                        'LineString'
                                                "
                                                style="padding-right: 5px; "
                                                v-html="
                                                    getLineIcon(
                                                        lyr.LineColour,
                                                        lyr.LineWidth
                                                    )
                                                "
                                            ></span>
                                            <span
                                                v-else-if="
                                                    lyr.Geometry.features[0]
                                                        .geometry.type ==
                                                        'Polygon'
                                                "
                                                style="padding-right: 5px; "
                                                v-html="
                                                    getPolygonIcon(
                                                        lyr.PolygonColour,
                                                        lyr.PolygonBorderColour,
                                                        lyr.PolygonWidth
                                                    )
                                                "
                                            ></span>
                                            <span
                                                v-else
                                                style="width: 13px;padding-right: 5px"
                                            ></span>
                                            <h5>
                                                {{ lyr.DisplayName }}
                                            </h5>
                                        </b-row>
                                        <b-row
                                            class="ml-1"
                                            v-if="
                                                lyr.Geometry.features.length > 0
                                            "
                                        >
                                            <b-col
                                                cols="3"
                                                class="checkboxCol p-0"
                                            >
                                                <label class="rowData subFont">
                                                    Label
                                                    <input
                                                        :disabled="
                                                            !lyr.ShowLabels
                                                        "
                                                        type="checkbox"
                                                        v-model="
                                                            lyr.Geometry
                                                                .features[0]
                                                                .properties[
                                                                lyr.Geometry
                                                                    .labelVisibleIndex
                                                            ].tooltip
                                                        "
                                                        @click="
                                                            toggleProjectLayerLabels(
                                                                index,
                                                                idx
                                                            )
                                                        "
                                                    />
                                                </label>
                                            </b-col>
                                            <b-col cols="4" class="p-0">
                                                <label class="rowData subFont">
                                                    Cluster
                                                    <input
                                                        type="checkbox"
                                                        v-model="lyr.clustered"
                                                        @click="
                                                            toggleProjectLayerClustered(
                                                                index,
                                                                idx
                                                            )
                                                        "
                                                    />
                                                </label>
                                            </b-col>
                                            <label class="switch">
                                                <!-- disable show/hide toggle when geometry data is not fully loaded -->
                                                <!-- :disabled="!lyr.geomDataLoaded" -->
                                                <input
                                                    type="checkbox"
                                                    v-model="
                                                        lyr.Geometry.visible
                                                    "
                                                    @click="
                                                        toggleProjectLayer(
                                                            index,
                                                            idx
                                                        )
                                                    "
                                                />
                                                <span
                                                    class="slider round"
                                                ></span>
                                            </label>
                                            <span
                                                class="rowData showHide"
                                                v-if="lyr.Geometry.visible"
                                                >Show</span
                                            >
                                            <span
                                                class="rowData showHide"
                                                v-else
                                                >Hide</span
                                            >
                                        </b-row>
                                        <b-row
                                            class="ml-1"
                                            style="font-style: italic; font-size: 12px;"
                                            v-else
                                            >Coordinates not found in the data
                                            source. Please contact
                                            support.</b-row
                                        >
                                    </b-col>
                                    <b-col
                                        class="ml-2 p-0"
                                        v-if="lyr.Geometry.features.length > 0"
                                    >
                                        <button
                                            class="btn-side"
                                            @click="zoomToProject(lyr.Geometry)"
                                        >
                                            <img
                                                class="btn-zoom-proj"
                                                v-b-tooltip.hover.left
                                                title="Zoom to"
                                                src="../../../assets/search-outline-grey.svg"
                                            />
                                        </button>
                                    </b-col>
                                </b-row>
                                <b-row v-else>
                                    <b-col cols="10">
                                        <b-row class="ml-1">
                                            <h5>
                                                {{ lyr.WMSLabel }}
                                            </h5>
                                        </b-row>
                                        <b-row class="ml-1">
                                            <label class="switch ml-3 rowData">
                                                <input
                                                    type="checkbox"
                                                    v-model="lyr.IsVisible"
                                                    @click="
                                                        toggleWMSLayer(
                                                            index,
                                                            lyr.wmsLyrId
                                                        )
                                                    "
                                                />
                                                <span
                                                    class="slider round"
                                                ></span>
                                            </label>
                                            <span
                                                class="rowData showHide"
                                                v-if="lyr.visible"
                                                >Show</span
                                            >
                                            <span
                                                class="rowData showHide"
                                                v-else
                                                >Hide</span
                                            >
                                        </b-row>
                                    </b-col>
                                    <b-col> </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
        <!-- posted layers -->
        <div
            v-else-if="selectedLayerAccordion == 'posted'"
            :class="accordionHeight ? 'overflow' : 'modalOverflow'"
        >
            <div v-if="showLayer" class="w-100">
                <div
                    v-for="(item, index) in tocPostedLayers"
                    :key="index"
                    class="border-bottom w-100"
                >
                    <b-card-body>
                        <b-card-text>
                            <b-row v-if="!item.isWMS" class="m-0">
                                <b-col cols="10" class="p-0">
                                    <b-row class="ml-1">
                                        <span
                                            style="padding-right: 5px"
                                            v-html="
                                                getIcon(
                                                    item.color,
                                                    item.pointSymbology
                                                )
                                            "
                                        ></span>
                                        <h5>
                                            {{ item.DisplayName }}
                                        </h5>
                                    </b-row>
                                    <b-row class="ml-1">
                                        <b-col cols="3" class="checkboxCol p-0">
                                            <label class="rowData subFont">
                                                Label
                                                <input
                                                    type="checkbox"
                                                    v-model="
                                                        item.markerSettings11[0]
                                                            .dataSource
                                                            .features[0]
                                                            .properties[
                                                            item
                                                                .markerSettings11[0]
                                                                .dataSource
                                                                .labelVisibleIndex
                                                        ].label
                                                    "
                                                    @click="
                                                        togglePostedLayerLabels(
                                                            index
                                                        )
                                                    "
                                                />
                                            </label>
                                        </b-col>
                                        <b-col cols="4" class="p-0">
                                            <label class="rowData subFont">
                                                Cluster
                                                <input
                                                    type="checkbox"
                                                    v-model="item.clustered"
                                                    @click="
                                                        togglePostedLayerClustered(
                                                            index
                                                        )
                                                    "
                                                />
                                            </label>
                                        </b-col>
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                v-model="
                                                    item.markerSettings11[0]
                                                        .dataSource.visible
                                                "
                                                @click="
                                                    togglePostedLayer(index)
                                                "
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                        <span
                                            class="rowData showHide"
                                            v-if="
                                                item.markerSettings11[0]
                                                    .dataSource.visible
                                            "
                                            >Show</span
                                        >
                                        <span class="rowData showHide" v-else
                                            >Hide</span
                                        >
                                    </b-row>
                                </b-col>
                                <b-col class="ml-2 pr-0">
                                    <b-row>
                                        <button
                                            class="btn-side"
                                            @click="
                                                $emit('editLyr', item, index)
                                            "
                                        >
                                            <img
                                                class="btn-edit"
                                                v-b-tooltip.hover.left
                                                title="Edit layer"
                                                src="../../../assets/EM_edit.svg"
                                            />
                                        </button>
                                    </b-row>
                                    <b-row>
                                        <button
                                            class="btn-side"
                                            @click="
                                                zoomToPosted(
                                                    item.markerSettings11[0]
                                                        .dataSource
                                                )
                                            "
                                        >
                                            <img
                                                class="btn-zoom"
                                                v-b-tooltip.hover.left
                                                title="Zoom to"
                                                src="../../../assets/search-outline-grey.svg"
                                            />
                                        </button>
                                    </b-row>
                                    <b-row>
                                        <button
                                            class="btn-side"
                                            @click="
                                                showDeleteModal(item, index)
                                            "
                                        >
                                            <img
                                                class="btn-delete"
                                                width="40px"
                                                height="40px"
                                                v-b-tooltip.hover.left
                                                title="Delete layer"
                                                src="../../../assets/delete_icon-dark.svg"
                                            />
                                        </button>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row v-else class="m-0">
                                <b-col cols="10" class="p-0">
                                    <b-row class="ml-1">
                                        <span style="padding-right: 5px"></span>
                                        <h5>
                                            {{ item.DisplayName }}
                                        </h5>
                                    </b-row>
                                    <b-row class="ml-1">
                                        <label class="switch">
                                            <input
                                                type="checkbox"
                                                v-model="item.visible"
                                                @click="
                                                    togglePostedWMSLayer(index)
                                                "
                                            />
                                            <span class="slider round"></span>
                                        </label>
                                        <span
                                            class="rowData showHide"
                                            v-if="item.visible"
                                            >Show</span
                                        >
                                        <span class="rowData showHide" v-else
                                            >Hide</span
                                        >
                                    </b-row>
                                </b-col>
                                <b-col class="ml-2 pr-0">
                                    <b-row>
                                        <button
                                            class="btn-side"
                                            @click="
                                                $emit('editContourLyr', item)
                                            "
                                        >
                                            <img
                                                class="btn-edit"
                                                v-b-tooltip.hover.left
                                                title="Edit layer"
                                                src="../../../assets/EM_edit.svg"
                                            />
                                        </button>
                                    </b-row>
                                    <b-row>
                                        <!-- <button
                      class="btn-side"
                      @click="zoomToPosted(item)"
                    >
                      <img
                        class="btn-zoom"
                        v-b-tooltip.hover.left
                        title="Zoom to"
                        src="../../../assets/search-outline-grey.svg"
                      />
                    </button> -->
                                    </b-row>
                                    <b-row>
                                        <button
                                            class="btn-side"
                                            @click="
                                                showDeleteModal(item, index)
                                            "
                                        >
                                            <img
                                                class="btn-delete"
                                                width="40px"
                                                height="40px"
                                                v-b-tooltip.hover.left
                                                title="Delete layer"
                                                src="../../../assets/delete_icon-dark.svg"
                                            />
                                        </button>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-card-body>
                </div>
                <div v-if="tocPostedLayers.length < 1">
                    <b-card-body>
                        No user posted layers found
                    </b-card-body>
                </div>
            </div>
        </div>
        <div>
            <b-modal
                ref="deletePostedLyr-modal"
                class="model_style"
                hide-header
                hide-footer
                centered
            >
                <div class="d-block text-center">
                    <div class="text-right cursor-pointer">
                        <img
                            src="../../../assets/close_icon.svg"
                            @click="hideDeleteModal"
                        />
                    </div>
                    <h5 class="mt-2">Delete {{ modalLayerName }}</h5>
                    <small
                        >Are you sure you want to delete this layer? This action
                        cannot be undone.</small
                    ><br />
                    <div class="mt-3 mb-4 delModal">
                        <a class="text-primary" @click="hideDeleteModal"
                            >No, don't delete</a
                        >
                        <button class="del-btn ml-2" @click="deletePostedLyr()">
                            Delete Layer
                        </button>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { latLngBounds } from 'leaflet';
import { projectService } from '../../../services/project.service';
export default {
    name: 'LayerAccordion',
    props: {
        accordionHeight: Boolean,
    },
    data() {
        return {
            modalLayerId: null,
            modalLayerName: null,
            edit_clicked: false,
            mapExtend: false,
            showLayer: true,
        };
    },
    mounted() {
        if (this.switchToPosted == 1) {
            this.updateSelectedLayerAccordion('posted');
            this.$emit('switchedToPosted');
        }
    },
    methods: {
        ...mapActions('projects', ['updateSelectedLayerAccordion']),
        getIcon(colour, symbology) {
            return symbology == 'arrow'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.5645px" viewBox="0 0 10.5645 5.1655" height="5.1655px">  <path data-name="Path 32096" d="M235.528,285.054l5.09-4.056a1.108,1.108,0,0,0,0-1.733l-5.09-4.056a1.108,1.108,0,0,0-1.8.866v1.591H221.015a1.108,1.108,0,0,0-1.108,1.108v2.713a1.108,1.108,0,0,0,1.108,1.108H233.73v1.591A1.108,1.108,0,0,0,235.528,285.054Z" transform="scale(0.5 0.5) translate(-219.906 -274.967)" fill="' +
                      colour +
                      '" stroke="' +
                      colour +
                      '"/></svg>'
                : symbology == 'circle'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></svg>'
                : symbology == 'cross3'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="8.958px" viewBox="0 0 8.958 10.5365" height="10.5365px">  <path data-name="Union 7" d="M.047,18.277l5.271-7.814L0,2.868,4.1,0,9.718,8.029h8.2v5H9.9l-.527.369L4.192,21.073Z" fill="' +
                  colour +
                  '" transform="scale(0.5 0.5)" stroke="' +
                  colour +
                  '"/></svg>'
                : symbology == 'cross'
                ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="9.5px" viewBox="0 0 9.5 9.5" height="9.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Union_8" data-name="Union 8" d="M7,19V12H0V7H7V0h5V7h7v5H12v7Z" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '" stroke-width="3"/></svg>'
                : symbology == 'cross5'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="8.0305px" viewBox="0 0 8.0305 8.426" height="8.426px">  <path data-name="Union 9" d="M7.14,8.372,7,9l.14-.628L7,8l.14.372L7.222,8h0L9,0,7.222,8h0l-.083.372L10,16ZM6,8,0,14ZM0,3,6,8Z" transform="scale(0.5 0.5) translate(1.061 0.325)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '" stroke-width="3"/></g></g></svg>'
                : symbology == 'flag'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="6.6065px" viewBox="0 0 6.6065 8.3425" height="8.3425px">  <g data-name="Group 12386" transform="scale(0.5 0.5) translate(19439.5 10083.185)"> <line id="Line_1338" data-name="Line 1338" y1="16.676" transform="translate(-19438.5 -10083.177)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '" stroke-width="2"/> <path id="Path_32097" data-name="Path 32097" d="M-19641.287-10072.161h-12.227v-8.347h12.227l-4.859,4.028Z" transform="translate(215 -2.677)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/>  </g></svg>'
                : symbology == 'polygon3'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 12.0" height="12.0px">  <path data-name="Polygon 5" d="M12,0,24,20H0Z" transform="scale(0.5 0.5) translate(20) rotate(90)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></svg>'
                : symbology == 'polygon4'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.0" height="13.0px">  <path data-name="Polygon 6" d="M13,0,26,13,13,26,0,13Z" transform="scale(0.5 0.5)translate(26) rotate(90)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></svg>'
                : symbology == 'polygon5'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.669" height="13.669px">  <path data-name="Polygon 7" d="M13.669,0,27.338,9.931,22.117,26H5.221L0,9.931Z" transform="scale(0.5 0.5) translate(26) rotate(90)"  fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></svg>'
                : symbology == 'star'
                ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="12.0px" viewBox="0 0 12.0 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_10" data-name="Polygon 10" d="M12,0l3,8.553,9,.232L16.854,14.3,19.416,23,12,17.857,4.584,23l2.562-8.7L0,8.785l9-.232Z" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></g></g></svg>'
                : symbology == 'star3'
                ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 11.5" height="11.5px">  <path data-name="Polygon 8" d="M13,0l3.9,13.033L26,23,13,19.933,0,23l9.1-9.967Z" transform="scale(0.5 0.5)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></svg>'
                : symbology == 'star4'
                ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="11.5px" viewBox="0 0 11.5 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_9" data-name="Polygon 9" d="M11.5,0l3.45,8.05L23,11.5l-8.05,3.45L11.5,23,8.05,14.95,0,11.5,8.05,8.05Z" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></g></g></svg>'
                : '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                  colour +
                  '" stroke="' +
                  colour +
                  '"/></svg>';
        },
        getLineIcon(colour, width) {
            return (
                '<svg height="13px" width="13px"><line x1="0" y1="13" x2="13" y2="0" style="stroke-width:' +
                (width + 1.5) +
                ';fill:' +
                colour +
                ';stroke:' +
                colour +
                '"/></svg>'
            );
        },
        getPolygonIcon(fillColour, borderColour, borderWidth) {
            return (
                '<svg height="13" width="13"><polygon points="0,0 0,13 13,13 13,0" style="fill:' +
                fillColour +
                ';stroke:' +
                borderColour +
                ';stroke-width:' +
                borderWidth +
                '" /></svg>'
            );
        },
        togglePostedLayer(index) {
            this.$store.dispatch('projects/updatePostedVisibility', index);
            setTimeout(() => {
                this.$parent.$refs.mainLmap.reclusterPosted();
            }, 100);
        },
        togglePostedLayerLabels(index) {
            this.$store.dispatch('projects/togglePostedLayerLabels', index);
            // setTimeout(() => {
            //
            //   this.$parent.$refs.mainLmap.reclusterPosted();
            //   this.$parent.$refs.mainLmap.mapSizeRefresh();
            //   //a hack to change map extent very slightly to force posted layer changes
            //   let newCenter = this.$store.state.projects.mapData.mapCenter;
            //   newCenter.lng = newCenter.lng + 0.000001;
            //   this.$store.commit("projects/updateMapCenter", newCenter);
            // }, 100)
        },
        togglePostedLayerClustered(index) {
            this.$store.dispatch('projects/togglePostedLayerClusteredAction', {
                index,
            });
            setTimeout(() => {
                this.$parent.$refs.mainLmap.reclusterPosted();
            }, 100);
        },
        toggleProjectLayer(index, idx) {
            this.$store.dispatch('projectLayers/updateProjectVisibility', {
                index,
                idx,
            });
            setTimeout(() => {
                this.$parent.$refs.mainLmap.reclusterProject();
            }, 100);
        },
        toggleProjectLayerLabels(index, idx) {
            this.$store.dispatch('projectLayers/toggleProjectLayerLabels', {
                index,
                idx,
            });
            setTimeout(() => {
                this.$parent.$refs.mainLmap.reclusterProject();
            }, 100);
        },
        toggleWMSLayer(index, idx) {
            this.$store.commit('projectLayers/updateWMSVisibility', {
                index,
                idx,
            });
        },
        togglePostedWMSLayer(index) {
            this.$store.commit('projects/updatePostedWMSVisibility', { index });
        },
        toggleProjectLayerClustered(index, idx) {
            this.$store.dispatch(
                'projectLayers/toggleProjectLayerClusteredAction',
                { index, idx }
            );
            setTimeout(() => {
                this.$parent.$refs.mainLmap.reclusterProject();
            }, 100);
        },
        showDeleteModal(layerDetails, index) {
            // this.layerId = projectDetails.layerId;
            this.modalLayerId = index;
            this.modalLayerName = layerDetails.DisplayName;
            this.$refs['deletePostedLyr-modal'].show();
        },
        deletePostedLyr() {
            this.$store.dispatch(
                'projects/deletePostedLayer',
                this.modalLayerId
            );
            this.$refs['deletePostedLyr-modal'].hide();
            //
            // setTimeout(() => {
            //
            //   this.$parent.$refs.mainLmap.reclusterPosted();
            // }, 100)
        },
        hideDeleteModal() {
            this.$refs['deletePostedLyr-modal'].hide();
        },
        zoomToPosted(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;

            let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
            this.$emit('updateMapExtent', [
                [
                    spatialFunctions.correctLatitude(
                        minY - yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        minX - xRange * zoomCushion
                    ),
                ],
                [
                    spatialFunctions.correctLatitude(
                        maxY + yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        maxX + xRange * zoomCushion
                    ),
                ],
            ]);
        },
        zoomToProject(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;

            let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
            this.$emit('updateMapExtent', [
                [
                    spatialFunctions.correctLatitude(
                        minY - yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        minX - xRange * zoomCushion
                    ),
                ],
                [
                    spatialFunctions.correctLatitude(
                        maxY + yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        maxX + xRange * zoomCushion
                    ),
                ],
            ]);
        },
        zoomToWMS(layername) {
            projectService.GetWMSExtent(layername).then((res) => {
                let minX = 180;
                let minY = 90;
                let maxX = -180;
                let maxY = -90;
                var coords = [];
                var coordStrs = res.data.slice(10, -2).split(', ');
                coordStrs.forEach((coordStr) => {
                    coords.push(coordStr.split(' '));
                });
                coords.forEach((point) => {
                    let xCoord = point[0];
                    let yCoord = point[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(
                            minX,
                            spatialFunctions.meters2lng(parseFloat(xCoord))
                        );
                        maxX = Math.max(
                            maxX,
                            spatialFunctions.meters2lng(parseFloat(xCoord))
                        );
                        minY = Math.min(
                            minY,
                            spatialFunctions.meters2lat(parseFloat(yCoord))
                        );
                        maxY = Math.max(
                            maxY,
                            spatialFunctions.meters2lat(parseFloat(yCoord))
                        );
                    }
                });

                let xRange = maxX - minX;
                let yRange = maxY - minY;

                let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
                this.$emit('updateMapExtent', [
                    [
                        spatialFunctions.correctLatitude(
                            minY - yRange * zoomCushion
                        ),
                        spatialFunctions.correctLongitude(
                            minX - xRange * zoomCushion
                        ),
                    ],
                    [
                        spatialFunctions.correctLatitude(
                            maxY + yRange * zoomCushion
                        ),
                        spatialFunctions.correctLongitude(
                            maxX + xRange * zoomCushion
                        ),
                    ],
                ]);
            });
        },
    },
    computed: {
        ...mapState({
            projectMapLayers: (state) => state.projectLayers.projects,
            postedMapLayers: (state) =>
                state.projects.mapData.geojsonLayers.posted,
            selectedLayerAccordion: (state) =>
                state.projects.selectedLayerAccordion,
        }),
        ...mapGetters('projects', {
            tocPostedLayers: 'tocPostedLayers',
            wmsMapLayers: 'wmsLayers',
        }),
        ...mapGetters('projectLayers', {
            classic_tocProjectLayers: 'classic_tocProjectLayers',
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
.main-content {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;
    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }
    h3 {
        font-size: 22px;
    }
    .some-radios {
        font-size: 14px;
        color: #626262;
    }
    // .custom-select {
    //   background: #fff url("../../assets/arrow_down.svg") no-repeat right 0.75rem
    //     center/8px 10px !important;
    //   width:75%;
    //   height: 48px;
    // }
}
.aggr-edit,
.layer-post {
    width: 241px;
}
.main-content.edit-layer {
    max-height: 485px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    .inputbox-edit,
    .select-edit {
        width: 241px;
        border-radius: 3px;
        padding: 7px;
        height: 48px;
        border: 1px solid rgb(206, 212, 218);
    }
    .subSelect {
        width: 180px;
        float: left;
    }
    .dlt-icon {
        position: absolute;
        right: 20px;
    }
    .edit.select-edit {
        width: 203px;
        margin-left: 15px;
    }
}

// .inputbox {
//   width: 499px;
//   border-radius: 3px;
//   padding: 7px;
//   height: 48px;
//   border: 1px solid rgb(206, 212, 218);
// }
.inputbox :focus {
    border: none;
}
.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.inputbox-color {
    height: 48px;
    width: 100%;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}
.pencil-icon {
    position: absolute;
    left: 490px;
    margin-left: -24px;
    // margin-top: 59px;
    margin-top: 23px;
    cursor: pointer;
    width: 20px;
}
.buttonSelect {
    position: absolute;
    right: 0;
    top: -45px;
}
.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}
.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}
.aggrType {
    margin-left: 36px;
    margin-top: -23px;
    width: 400px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}
.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
.map_view_icon {
    background-color: grey;
    position: relative;
    bottom: 10px;
    border: 0 !important;
    // left: 8%;
    // float: right;
    .map_view_icon:focus {
        // outline: 1px dotted;
        outline: 0px;
    }
}
.search_element {
    background-image: url(../../../assets/search_dots.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
}
.pos_layer {
    width: auto;
    background-color: #f4f4f4;
    height: 80px;
    font-size: 0.9rem;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}
.pos_layer.hover {
    background-color: #f4f4f4;
}
.dataposting-icon {
    padding: 4px 2px 16px 15px;
    margin-left: -17px;
    background: #eaeaea;
}
.posted_layer {
    background: #f4f4f4;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}
.posted_layer.hover {
    background: white;
    color: #1d1d1d;
}
.project_layer {
    background: #f4f4f4;
    color: #1d1d1d;
    width: 100%;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.project_layer_active {
    background: transparent;
    color: #1d1d1d;
    width: 145px;
    margin-left: -38px;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.proj_layer {
    height: 43px;
    font-weight: 500;
    width: 100%;
}
/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}
.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}
.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}
.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}
.edit-position-wide .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 86.67px;
    height: 70px;
    border-radius: 0;
}
.edit-layer .btn-secondary:focus,
.edit-layer .btn-secondary:not(:disabled):not(.disabled):active {
    color: #1d1d1d !important;
    background-color: #eaeaea !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    //right: 15px;
    position: relative;
    //bottom: -4px;
    border-radius: 0;
}
.edit-layer .border-dark {
    height: 70px;
    padding: 0px;
    text-align: center;
    width: 80px;
}
.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}
.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}
.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}
.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}
.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}
.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
// .btn-secondary{
//   height:69px;
// }
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}
.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.edit-button {
    color: #007bff !important;
    margin-top: 0px;
    //margin-left: 96px;
}
.delete-datapost {
    margin-left: none !important;
    margin-top: 9px !important;
    font-size: 14px;
    color: #817070 !important;
}
.border-right {
    height: 20px;
}
.input-value {
    display: block;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 10px;
    padding: 10px;
    margin-top: -5px;
    width: 60px;
    text-align: center;
}
.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}
.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}
.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}
.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}
.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/* ------------------------------------------Position Button Css Ends Here------------------------------- */
.proj_layer.hover {
    background-color: white;
}
.slider_value {
    position: relative;
    bottom: 155px;
    left: 380px;
}
.slider {
    left: -3px !important;
}
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}
/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}
.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}
.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    //left: 20px;
    top: 18px;
    left: 35px;
    border: 0 !important;
}
// real call is in global.scss
// .leafletLabelClass {
//   background-color: transparent;
//   border: transparent;
//   box-shadow: none;
//   font-size: 12pt;
// }
.hidebtn {
    left: 50px;
}
.dataposting .portalbarMain {
    border-block: 0 !important;
}
.layer {
    width: 240px;
    .pencil-icon {
        left: 250px;
    }
    .inputrange.edit {
        width: 125px !important;
    }
}
.accBorder {
    // border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px#dee2e6;
}
/*.inputrange{
  width:145px!important;
}*/
.input-value {
    width: 61px;
    font-size: 14px;
}
.range-field {
    width: 310px !important;
}
.edit-layer .inputrange {
    width: 125px !important;
}
.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    width: 55px;
    font-size: 12px;
}
.layer-post .select-edit:not(:first-child) {
    width: 180px !important;
}
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    background: rgb(0, 99, 149) none repeat scroll 0% 0% !important;
    color: white !important;
    border: medium none;
    white-space: nowrap;
}
.edit-layer .bottom-btn .btn-secondary:focus,
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #006395 !important;
    width: auto;
    height: auto;
    //right: 15px;
    position: relative;
    border-radius: 0.25rem;
}
.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}
.overflow {
    overflow-y: scroll;
    max-height: 394px;
}
.modalOverflow {
    overflow-y: scroll;
    max-height: 392px;
}
.hideText {
    margin-right: 10px;
}
.modalScreen {
    height: 85vh;
    z-index: 999999;
}
.map_arrow {
    position: absolute;
    /* left: -13px; */
    z-index: 999;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}
.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}
.rowData {
    margin-top: 6px;
}
.backtopost {
    position: absolute;
    top: -200px;
    right: 0;
}
.card-body {
    padding: 1rem;
}
.collapsed .when-opened {
    display: none;
}
.not-collapsed .when-closed {
    display: none;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}
.dateAggregate {
    margin-top: -22px;
}

.dataPost-map-box {
    height: 440px;
    width: 885px;
}

.dataPost-map-box-expanded {
    height: 440px;
}

.dataPost-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }
    #lmap1 {
        opacity: 1;
        height: 10in;
        width: 15in;
    }
}
.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}
.showHide {
    font-size: 14px;
    margin-top: 6px;
    width: 43px;
    padding-left: 2px;
}
.zoomTo {
    color: #006395 !important;
    margin-top: 9px !important;
    padding-right: 5px !important;
    margin-right: 5px !important;
}
.project_layers {
    background-color: #f4f4f4;
    height: 44px;
}
.btn-side {
    background-color: transparent;
    border-radius: 6px;
    border: none;
    height: 20px;
}
.btn-zoom {
    position: absolute;
    top: 25px;
    left: 12px;
}
.btn-zoom-proj {
    position: absolute;
    top: 45px;
    left: 12px;
}
.btn-edit {
    position: absolute;
    top: -10px;
}
.btn-delete {
    position: absolute;
    left: 0px;
    top: 39px;
}
.loadingIcon {
    position: relative;
    left: 43%;
    top: 45%;
    z-index: 9999;
    height: 40px;
    width: 40px;
    opacity: 0.7;
    margin-right: 5px;
}
.loadingText {
    position: absolute;
    left: 34%;
    top: 55%;
    opacity: 0.7;
}
.checkboxCol {
    width: 100px;
    margin-left: -5px;
}
.borderBlue {
    background-color: white !important;
}
.subFont {
    font-size: 14px;
}
.loadingIcon {
    height: 15px;
    width: 15px;
    position: relative;
    top: 6px;
    left: 0px;
}
</style>
