import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const userService = {
    getAllUsers,
    createUsers,
    updateUser,
    deleteUser,
    getUserById,
    getAggregateOptions,
    getAggregateOptionsByType,
    savePortalsettings,
    updatePortalsettings,
    updatePaginationCount,
    getSavedaggregate,
    getUserGroupsforAccessManagement,
    getAggregateOptionPortal,
    getUserUiVersion,
    setUserUiVersion,
    getAuthTypes
};

// let userDetails=JSON.parse(localStorage.getItem('userDetails'))

function getAllUsers() {
    // return axios.get(apiConstants.getUserList +'?$expand=State($select=StateName),City($select=CityName),Country($select=CountryName)')
    // return axios.get(apiConstants.getUserList +'?$expand=State($select=StateName),Country($select=CountryName)')
    return request({
        url:
            apiConstants.getUserList +
            '?$expand=State($select=StateName),Country($select=CountryName)',
    });
}

function getAuthTypes(){
    return request({
        url: apiConstants.getAuthTypes
    });
}
// function getAllUsers(){
//     return axios.get(apiConstants.getUserList, {
//         params:{
//             username: userDetails.data.user.userName
//         }
//     })
// }

function createUsers(userData) {
    // return axios.post(apiConstants.createUser, userData)
    return request({
        url: apiConstants.createUser,
        data: userData,
        method: 'post',
    });
}

function updateUser(userData, userId) {
    // return axios.put(apiConstants.updateUser +'/'+userId, userData,)
    return request({
        url: apiConstants.updateUser + '/' + userId,
        data: userData,
        method: 'put',
    });
}
function getUserById(userId) {
    // return axios.get(apiConstants.getUserById +'/'+ userId)
    return request({ url: apiConstants.getUserById + '/' + userId });
}
function deleteUser(userId) {
    // return axios.delete(apiConstants.deleteUser +'/'+ userId)
    return request({
        url: apiConstants.deleteUser + '/' + userId,
        method: 'delete',
    });
}

function getAggregateOptions() {
    // return axios.get(apiConstants.getAggregateOption)
    return request({ url: apiConstants.getAggregateOption });
}

function getAggregateOptionPortal() {
    // return axios.get(apiConstants.getAggregateOptionPortal)
    return request({ url: apiConstants.getAggregateOptionPortal });
}

function savePortalsettings(settings) {
    // return axios.post(apiConstants.savePortalSettings,settings)
    return request({
        url: apiConstants.savePortalSettings,
        data: settings,
        method: 'post',
    });
}
function updatePortalsettings(settings, id) {
    // return axios.put(apiConstants.savePortalSettings +'/'+id,settings)
    return request({
        url: apiConstants.savePortalSettings + '/' + id,
        data: settings,
        method: 'put',
    });
}
function updatePaginationCount(paginationCount) {
    return request({
        url: apiConstants.updatePaginationCount + '?paginationCount=' + paginationCount,
        method: 'put',
    });
}
function getAggregateOptionsByType(dataType) {
    // return axios.get(apiConstants.getAggregateOptionByType +'?dataType='+ dataType)
    return request({
        url: apiConstants.getAggregateOptionByType + '?dataType=' + dataType,
    });
}

function getSavedaggregate() {
    // return axios.get(apiConstants.getSavedPortalsettings)
    return request({ url: apiConstants.getSavedPortalsettings });
}
function getUserGroupsforAccessManagement() {
    // return axios.get(apiConstants.getUserGroups)
    return request({ url: apiConstants.getUserGroups });
}

function getUserUiVersion() {
    return request({
        url: apiConstants.getUserUiVersion,
    });
}

function setUserUiVersion(version) {
    return request({
        url: apiConstants.setUserUiVersion + '?version=' + version,
        method: 'put',
    });
}
