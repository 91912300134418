import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const managementService = {
    saveAssociation,
    getProjects,
    getDataset,
    addOrUpdateUserToUserGroup,
    addOrUpdateProjectTool,
    addOrUpdateProjectDataSet,
    addOrUpdateTools,
    getProjectsTool,
    getTools,
    getGroupsTool,
    getToolsGroup,
    getGroupUser,
    getUsersGroup,
    getDatasetsGroup,
    getGroupDatasets,
    getGroupsOfgroup,
    getGroupList,
    getGroupPortal,
    getPortalGroup,
    addOrUpdateUserGroupPortalPermission,
    updateUserGroupsMapping,
    addOrUpdateUsersAuthorizations,
    setProjectInitialExtent,
    getProjectInitialExtent,
    clearProjectInitialExtent,
    getAnalytes,
    getUnits,
    getProjectTypeCriteriaLimits,
    getProjectCriteriaLimits,
    addCriteriaLimit,
    updateCriteriaLimit,
    deleteCriteriaLimits,
};

function saveAssociation(data) {
    // return axios.post(apiConstants.saveAssociation, data)
    return request({
        url: apiConstants.saveAssociation,
        data: data,
        method: 'post',
    });
}
function updateUserGroupsMapping(data) {
    // return axios.post(apiConstants.updateUserGroupsMapping, data)
    return request({
        url: apiConstants.updateUserGroupsMapping,
        data: data,
        method: 'post',
    });
}
function addOrUpdateUsersAuthorizations(data) {
    // return axios.post(apiConstants.addOrUpdateUsersAuthorizations, data)
    return request({
        url: apiConstants.addOrUpdateUsersAuthorizations,
        data: data,
        method: 'post',
    });
}
function getProjects() {
    // return axios.get(apiConstants.getProjectName)
    return request({ url: apiConstants.getProjectName });
}
function getDataset() {
    // return axios.get(apiConstants.getDatasetName)
    return request({ url: apiConstants.getDatasetName });
}
function getProjectsTool() {
    // return axios.get(apiConstants.getProjectToolName)
    return request({ url: apiConstants.getProjectToolName });
}
function getTools() {
    // return axios.get(apiConstants.getToolName)
    return request({ url: apiConstants.getToolName });
}
function getGroupsTool() {
    // return axios.get(apiConstants.getGroupsToolName)
    return request({ url: apiConstants.getGroupsToolName });
}
function getToolsGroup() {
    // return axios.get(apiConstants.getToolsGroupName)
    return request({ url: apiConstants.getToolsGroupName });
}
function getGroupUser() {
    // return axios.get(apiConstants.getGroupUserName)
    return request({ url: apiConstants.getGroupUserName });
}
function getUsersGroup() {
    // return axios.get(apiConstants.getUsersGroupName)
    return request({ url: apiConstants.getUsersGroupName });
}
function getGroupDatasets() {
    // return axios.get(apiConstants.getGroupDatasetName)
    return request({ url: apiConstants.getGroupDatasetName });
}
function getDatasetsGroup() {
    // return axios.get(apiConstants.getDatasetsGroupName)
    return request({ url: apiConstants.getDatasetsGroupName });
}
function addOrUpdateUserToUserGroup(data) {
    // return axios.post(apiConstants.addOrUpdateUserToUserGroup, data)
    return request({
        url: apiConstants.addOrUpdateUserToUserGroup,
        data: data,
        method: 'post',
    });
}
function addOrUpdateProjectTool(data) {
    // return axios.post(apiConstants.addOrUpdateProjectTool, data)
    return request({
        url: apiConstants.addOrUpdateProjectTool,
        data: data,
        method: 'post',
    });
}
function addOrUpdateProjectDataSet(data) {
    // return axios.post(apiConstants.addOrUpdateProjectDataSet, data)
    return request({
        url: apiConstants.addOrUpdateProjectDataSet,
        data: data,
        method: 'post',
    });
}
function addOrUpdateTools(data) {
    // return axios.post(apiConstants.addOrUpdateTools, data)
    return request({
        url: apiConstants.addOrUpdateTools,
        data: data,
        method: 'post',
    });
}
function getGroupList() {
    // return axios.get(apiConstants.getGroupList)
    return request({ url: apiConstants.getGroupList });
}
function getGroupsOfgroup() {
    // return axios.get(apiConstants.getGroupofGroupsName)
    return request({ url: apiConstants.getGroupofGroupsName });
}
function addOrUpdateUserGroupPortalPermission(data) {
    // return axios.post(apiConstants.addOrUpdateUserGroupPortalPermission, data)
    return request({
        url: apiConstants.addOrUpdateUserGroupPortalPermission,
        data: data,
        method: 'post',
    });
}
function getGroupPortal() {
    // return axios.get(apiConstants.getGroupPortalName)
    return request({ url: apiConstants.getGroupPortalName });
}
function getPortalGroup() {
    // return axios.get(apiConstants.getPortalGroupName)
    return request({ url: apiConstants.getPortalGroupName });
}

function setProjectInitialExtent(data) {
    return request({
        url: apiConstants.setProjectInitialExtent,
        data: data,
        method: 'post',
    });
}

function getProjectInitialExtent(projectID) {
    return request({
        url: apiConstants.getProjectInitialExtent + '?projectid=' + projectID,
    });
}

function clearProjectInitialExtent(projectID) {
    return request({
        url: apiConstants.clearProjectInitialExtent + '?projectid=' + projectID,
        method: 'delete',
    });
}
function getAnalytes() {
    return request({
        url: apiConstants.getAnalytes,
        method: 'get',
    });
}
function getUnits() {
    return request({
        url: apiConstants.getUnits,
        method: 'get',
    });
}

function getProjectTypeCriteriaLimits(projectTypeID) {
    return request({
        url: apiConstants.getProjectTypeCriteriaLimits + '?ProjectTypeId=' + projectTypeID,
        method: 'get'
    });
}
function getProjectCriteriaLimits(projectID) {
    return request({
        url: apiConstants.getProjectCriteriaLimits + '?ProjectId=' + projectID,
        method: 'get',
    });
}
function addCriteriaLimit(data) {
    return request({
        url: apiConstants.criteriaLimit,
        data: data,
        method: 'post',
    });
}
function updateCriteriaLimit(data) {
    return request({
        url: apiConstants.criteriaLimit,
        data: data,
        method: 'put',
    });
}
function deleteCriteriaLimits(projectID) {
    return request({

        url: apiConstants.criteriaLimit + '/' + projectID,
        method: 'delete',
    });
}

