<template>
    <l-control
        class="zoomControlWrapper"
        position="topleft"
        @ready="searchReady"
    >
        <div>
            <button
                :disabled="disabled"
                type="button"
                class="btn btn-white"
                :class="{ fullBorder: showAddressBar }"
                @click="toggleSearchBar"
            >
                <img class="img-layers" src="../../assets/search.svg" />
            </button>
            <input
                v-show="isExpanded || alwaysExpanded"
                placeholder="Search address or coordinates"
                class="addressInput"
                v-model="searchAddress"
                v-on:keyup.enter="query"
            />
            <button
                v-show="isExpanded || alwaysExpanded"
                id="searchButton"
                @click="query"
            >
                <img
                    class="img-layers"
                    src="../../assets/layers_arrow_white.svg"
                />
            </button>
        </div>
    </l-control>
</template>

<script>
import { latLngBounds } from 'leaflet';
import { LControl } from 'vue2-leaflet';
import axios from 'axios';

export default {
    props: {
        alwaysExpanded: {
            required: false,
            type: Boolean,
            default: false,
        },
        position: {
            required: false,
            type: String,
            default: 'topleft',
        },
        maxZoomLevel: {
            required: false,
            type: Number,
            default: 17,
        },
        isExpanded: {
            required: false,
            type: Boolean,
            default: false,
        },
        disabled: { type: Boolean, required: false, default: false },
    },
    name: 'LControlAddressSearch',
    components: {
        LControl,
    },
    data() {
        return {
            showAddressBar: false,
            searchAddress: '',
        };
    },
    mounted() {
        this.add();
    },
    beforeDestroy() {
        this.remove();
    },
    watch: {
        searchAddress(newVal) {
            //clear address search marker when searchAddress is deleted
            if (newVal == '') {
                this.$parent.$parent.drawMarkers = this.$parent.$parent.drawMarkers.filter(
                    (geom) => !geom.id.startsWith('A')
                );
            }
        },
    },
    methods: {
        searchReady() {},
        toggleSearchBar() {
            this.showAddressBar = !this.showAddressBar;
            this.$emit('toggleSearchBar');
        },
        remove() {
            if (this.markerCluster) {
                this.$parent.removeLayer(this.markerCluster);
            }
        },
        add() {
            this.$parent.mapObject.on('geosearch/showlocation', this.onSearch);
        },
        isCoordinatePair() {
            // check if the search address is a coordinate pair
            if (this.searchAddress.match(/-?\d+\.?\d+/g) !== null) {
                // if it is a coordinate pair, return the coordinates
                var [lat, lon] = this.searchAddress.match(/-?\d+\.?\d+/g);
                lat = parseFloat(lat);
                lon = parseFloat(lon);
                // create a structure that mimics the response from the nominatim API
                let coordinateStruct = [
                    {
                        lat: lat,
                        lon: lon,
                        boundingbox: [
                            lat - 0.01,
                            lat + 0.01,
                            lon - 0.01,
                            lon + 0.01,
                        ],
                    },
                ];
                return coordinateStruct;
            } else {
                // if it is not a coordinate pair, return false
                return false;
            }
        },
        query(e) {
            e.preventDefault();
            e.stopPropagation();
            // check if the search address is a coordinate pair
            const coords = this.isCoordinatePair();
            if (coords) {
                // if it is a coordinate pair, call onSearch with the coordinates
                this.onSearch(coords);
            } else {
                // if it is not a coordinate pair, call the nominatim API
                axios({
                    method: 'get',
                    headers: {
                        Authorization: 'none',
                    },
                    url:
                        'https://nominatim.openstreetmap.org/search?q=' +
                        this.searchAddress +
                        '&format=json&limit=1',
                }).then((response) => this.onSearch(response.data));
            }
        },
        onSearch(searchResult) {
            if (searchResult.length > 0) {
                var bbox = searchResult[0].boundingbox;
                this.$parent.mapObject.fitBounds(
                    latLngBounds([
                        [bbox[0], bbox[2]],
                        [bbox[1], bbox[3]],
                    ]),
                    { maxZoom: this.maxZoomLevel }
                );
                let currentMarkerLength = this.$parent.$parent.drawMarkers
                    .length;
                this.$parent.$parent.drawMarkers.push({
                    id: 'A' + Number(currentMarkerLength + 1),
                    geometry: {
                        type: 'Point',
                        coordinates: [searchResult[0].lat, searchResult[0].lon],
                        coordSys: 'LatLong',
                    },
                    properties: {},
                    styling: {
                        SymbologyColour: '#cc0000',
                        SymbologySize: 5,
                        PolygonColor: '#cc0000',
                        PolygonTransparency: 0,
                    },
                });
            }
        },
    },
};
</script>

<style scoped>
.btn-white {
    background-color: white !important;
    height: 45px;
    width: 45px;
    padding-left: 6px !important;
}
.addressInput {
    vertical-align: middle;
    width: 250px;
    font-size: 12pt;
    color: black;
    height: 45px;
    /* border-radius: 4px; */
    border-width: 0;
    border-bottom: 1px solid darkgray;
    border-top: 1px solid darkgray;
}
#searchButton {
    vertical-align: middle;
    background-color: black;
    margin-left: 2px;
    color: white;
    height: 45px;
    border: none;
}
.zoomControlWrapper {
    background-color: '#00000000';
}
.fullBorder {
    border-top: 1px solid darkgray !important;
    border-bottom: 1px solid darkgray !important;
    border-left: 1px solid darkgray !important;
    border-radius: 4px 0px 0px 4px !important;
}
</style>
