import { eTrackerService } from '../services/eTracker.service';

const state = {
    organizations: {},
    ContactTypes: {},
    Contacts: {},
}

const actions = {
    async getOrganizations({ commit }) {
        try {
            const organizations = await eTrackerService.getOrganizations();
            commit('orgSuccess', organizations);
            return organizations;
        } catch (error) {
            commit('orgFailure', error);
            throw error;
        }
    },
    async getContactTypes({ commit }) {
        try {
            const contactTypes = await eTrackerService.getContactTypes();
            commit('ContactTypesSuccess', contactTypes);
            return contactTypes;
        } catch (error) {
            commit('ContactTypesFailure', error);
            throw error;
        }
    },
    async getContacts({ commit }) {
        try {
            const contacts = await eTrackerService.getContacts();
            commit('ContactsSuccess', contacts);
            return contacts;
        } catch (error) {
            commit('ContactsFailure', error);
            throw error;
        }
    },
};
const mutations = {
    orgSuccess(state, organizations) {
        state.organizations = { items: organizations.data };
    },
    orgFailure(state, error) {
        state.organizations = { error };
    },
    ContactTypesSuccess(state, ContactTypes) {
        state.ContactTypes = { items: ContactTypes.data };
    },
    ContactTypesFailure(state, error) {
        state.ContactTypes = { error };
    },
    ContactsSuccess(state, Contacts) {
        state.Contacts = { items: Contacts.data };
    },
    ContactsFailure(state, error) {
        state.Contacts = { error };
    },
};

const namespaced = true

export const eTracker = {
    namespaced,
    state,
    actions,
    mutations
};