<template>
    <div>
        <div class="Que-btn">
            <small
                v-if="reviewItem.length"
                class="text-primary reviewQueueButton"
                @click="reviewQueue"
                >Review queue</small
            >
            <small
                v-if="!reviewItem.length"
                class="text-secondary reviewQueueButton"
                >Review queue</small
            >
            <div v-if="reviewItem.length">
                <b-button
                    rounded
                    class="buttonSelect queueChange ml-2"
                    @click="queueProjectChanges()"
                >
                    Queue changes
                </b-button>
            </div>
            <div v-if="!reviewItem.length">
                <b-button
                    v-if="groupIds.length || toolIds.length"
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueProjectChanges()"
                >
                    Queue changes
                </b-button>
                <b-button
                    v-if="!groupIds.length || !toolIds.length"
                    rounded
                    class="buttonSelect disableButton queueChange ml-3"
                >
                    Queue changes
                </b-button>
            </div>
        </div>

        <b-row class="access-blk">
            <b-col class="mt-2">
                <div class="card bg-light mb-3 border-grey">
                    <div class="card-header">Groups</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchGroup && !showSearchgroup
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroup"
                                    @mouseover="searchOpenGroup"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchGroup && !showSearchgroup
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroup"
                                    @mouseover="searchOpenGroup"
                                    >Search</span
                                >
                                <input
                                    ref="groups"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchGroup || showSearchgroup
                                            ? true
                                            : false
                                    "
                                    v-model="searchGroup"
                                    :class="
                                        searchGroup || showSearchgroup
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseGroup"
                                    @click="closeBarGroup"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    class="pl-3 ml-2"
                                    @click="selectAllGroups"
                                    v-model="allSelected"
                                /><span class="portal-lbl">Group name</span>
                            </div>
                            <b-row class="showProjectList tools">
                                <table
                                    class="table"
                                    striped
                                    id="groupTable"
                                    :per-page="perPage"
                                >
                                    <tbody :per-page="perPage">
                                        <tr
                                            v-for="(gname,
                                            index) in groupFilteredList"
                                            :key="index"
                                            :value="gname.UserGroupName"
                                        >
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            @click="
                                                                checkedGroup(
                                                                    gname.UserGroupID
                                                                )
                                                            "
                                                            :value="
                                                                gname.UserGroupID
                                                            "
                                                            v-model="groupIds"
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span
                                                                >{{
                                                                    gname.UserGroupName
                                                                }}
                                                                <!-- {{gname.Authorizations}}  -->
                                                            </span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesGroups[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            gname.Tool &&
                                                                                gname
                                                                                    .Tool
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchToolNameModel &&
                                                                                    !showSearchgroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpengroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpengroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchToolNameModel &&
                                                                                    !showSearchgroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpengroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpengroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="groupsInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width357"
                                                                                v-if="
                                                                                    searchToolNameModel ||
                                                                                    showSearchgroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchToolNameModel
                                                                                "
                                                                                :class="
                                                                                    searchToolNameModel ||
                                                                                    showSearchgroupsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchClosegroupsInner
                                                                                "
                                                                                @click="
                                                                                    closeBargroupsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(portg,
                                                                        index) in $options.filters.filterByProperty(
                                                                            gname.Tool,
                                                                            '',
                                                                            'ToolName',
                                                                            searchToolNameModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            portg.Tool
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                            v-if="
                                                                                portg
                                                                            "
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                v-model="
                                                                                    userGroupTools[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    portg.ToolID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        gname.UserGroupID,
                                                                                        portg.ToolID,
                                                                                        $event,
                                                                                        false
                                                                                    )
                                                                                "
                                                                                v-if="
                                                                                    portg.IsIncluded &&
                                                                                        userGroupToolsExclude[
                                                                                            gname
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            portg.ToolID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                            />
                                                                            <input
                                                                                type="checkbox"
                                                                                v-model="
                                                                                    userGroupTools[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    portg.ToolID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        gname.UserGroupID,
                                                                                        portg.ToolID,
                                                                                        $event,
                                                                                        true
                                                                                    )
                                                                                "
                                                                                :disabled="
                                                                                    userGroupToolsExclude[
                                                                                        gname
                                                                                            .UserGroupID
                                                                                    ].indexOf(
                                                                                        portg.ToolID
                                                                                    ) >
                                                                                        -1 &&
                                                                                        !portg.IsIncluded
                                                                                "
                                                                                v-if="
                                                                                    !portg.IsIncluded ||
                                                                                        userGroupToolsExclude[
                                                                                            gname
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            portg.ToolID
                                                                                        ) >
                                                                                            -1
                                                                                "
                                                                            />

                                                                            <span
                                                                                v-if="
                                                                                    portg.IsIncluded &&
                                                                                        userGroupToolsExclude[
                                                                                            gname
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            portg.ToolID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                                class="width68 pl-4 ml-1 mt-1 slash-checkbox1"
                                                                            >
                                                                                <img
                                                                                    src="../../../assets/exclude_black.svg"
                                                                                />
                                                                            </span>
                                                                            <span
                                                                                v-if="
                                                                                    !portg.IsIncluded ||
                                                                                        userGroupToolsExclude[
                                                                                            gname
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            portg.ToolID
                                                                                        ) >
                                                                                            -1
                                                                                "
                                                                            >
                                                                                <span
                                                                                    v-if="
                                                                                        !portg.IsIncluded &&
                                                                                            userGroupToolsExclude[
                                                                                                gname
                                                                                                    .UserGroupID
                                                                                            ].indexOf(
                                                                                                portg.ToolID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="width68 pl-4 ml-1 mt-1"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            gname.UserGroupID,
                                                                                            portg.ToolID,
                                                                                            'Groups',
                                                                                            portg
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        src="../../../assets/exclude_red.svg"
                                                                                    />
                                                                                </span>
                                                                                <span
                                                                                    v-if="
                                                                                        portg.IsIncluded &&
                                                                                            userGroupToolsExclude[
                                                                                                gname
                                                                                                    .UserGroupID
                                                                                            ].indexOf(
                                                                                                portg.ToolID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="width68 pl-4 mt-1"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            gname.UserGroupID,
                                                                                            portg.ToolID,
                                                                                            'Groups',
                                                                                            portg
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        src="../../../assets/exclude_black.svg"
                                                                                    />
                                                                                </span>
                                                                            </span>
                                                                            <span
                                                                                class="slash-text"
                                                                                >{{
                                                                                    portg.ToolName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                    <!-- <tr
                                        v-for="tname in gname.Tool"
                                        :key="tname.ToolName"
                                        :value="tname.ToolName"
                                    >
                                        <td class="">
                                        <input
                                            type="checkbox"
                                            checked
                                        />
                                        <span
                                            style="margin-left: 25px"
                                            >{{ tname.ToolName }}</span
                                        >
                                        </td>
                                    </tr> -->
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="groupToolsList"
                    @changePage="onChangeGroupPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div class="card-footer" v-if="pageOfGroups.length > 0">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="groupTable"
                            @change="collapseAllGroups"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>

            <b-col class="mt-2 pl-2 ml-5">
                <div class="card bg-light mb-3 border-grey maxWidth499">
                    <div class="card-header">Tools</div>
                    <div class="card-body tools-blog">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchGroupTools && !showSearchTools
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenTools"
                                    @mouseover="searchOpenTools"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchGroupTools && !showSearchTools
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenTools"
                                    @mouseover="searchOpenTools"
                                    >Search</span
                                >
                                <input
                                    ref="tools"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchGroupTools || showSearchTools
                                            ? true
                                            : false
                                    "
                                    v-model="searchGroupTools"
                                    :class="
                                        searchGroupTools || showSearchTools
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseTools"
                                    @click="closeBarTools"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header exclusion">
                                <input
                                    type="checkbox"
                                    class="ml-2"
                                    @click="selectAllTools"
                                    v-model="allSelectedTools"
                                />
                                <h5>Exclude</h5>
                                <h5>Name</h5>
                            </div>
                            <b-row class="showProjectList">
                                <table class="table" id="ToolsTable" striped>
                                    <tbody>
                                        <tr
                                            v-for="(tt,
                                            index) in toolsFilteredList"
                                            :key="index"
                                            :value="tt.ToolName"
                                        >
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            v-model="toolIds"
                                                            :value="tt.ToolId"
                                                            @click="
                                                                checkedTool(
                                                                    tt.ToolId
                                                                )
                                                            "
                                                            :disabled="
                                                                toolIdsExclude.indexOf(
                                                                    tt.ToolId
                                                                ) > -1
                                                            "
                                                        />

                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            class="accordion-button access-btn"
                                                        >
                                                            <span>{{
                                                                tt.ToolName
                                                            }}</span></b-button
                                                        >
                                                        <span
                                                            v-if="
                                                                toolIdsExclude.indexOf(
                                                                    tt.ToolId
                                                                ) == -1
                                                            "
                                                            class="pl-4 ml-4 mt-2 icon-include"
                                                            @click="
                                                                checkedExcludeTool(
                                                                    tt.ToolId
                                                                )
                                                            "
                                                        >
                                                            <img
                                                                src="../../../assets/exclude_black.svg"
                                                            />
                                                        </span>
                                                        <span
                                                            v-if="
                                                                toolIdsExclude.indexOf(
                                                                    tt.ToolId
                                                                ) > -1
                                                            "
                                                            class="pl-4 ml-4 mt-2 icon-include"
                                                            @click="
                                                                checkedExcludeTool(
                                                                    tt.ToolId
                                                                )
                                                            "
                                                        >
                                                            <img
                                                                src="../../../assets/exclude_red.svg"
                                                            />
                                                        </span>
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesTools[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            tt.UserGroup &&
                                                                                tt
                                                                                    .UserGroup
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchToolUserGroupNameModel &&
                                                                                    !showSearchtoolsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpentoolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpentoolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchToolUserGroupNameModel &&
                                                                                    !showSearchtoolsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpentoolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpentoolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="toolsInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width357"
                                                                                v-if="
                                                                                    searchToolUserGroupNameModel ||
                                                                                    showSearchtoolsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchToolUserGroupNameModel
                                                                                "
                                                                                :class="
                                                                                    searchToolUserGroupNameModel ||
                                                                                    showSearchtoolsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchClosetoolsInner
                                                                                "
                                                                                @click="
                                                                                    closeBartoolsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="t in $options.filters.filterByProperty(
                                                                            tt.UserGroup,
                                                                            '',
                                                                            'UserGroupName',
                                                                            searchToolUserGroupNameModel
                                                                        )"
                                                                        :key="
                                                                            t.UserGroupName
                                                                        "
                                                                        :value="
                                                                            t.UserGroupName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                v-model="
                                                                                    toolUserGroups[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    t.UserGroupID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        t.UserGroupID,
                                                                                        tt.ToolId,
                                                                                        $event,
                                                                                        false
                                                                                    )
                                                                                "
                                                                                v-if="
                                                                                    t.IsIncluded &&
                                                                                        toolUserGroupsExclude[
                                                                                            tt
                                                                                                .ToolId
                                                                                        ].indexOf(
                                                                                            t.UserGroupID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                            />

                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                v-model="
                                                                                    toolUserGroups[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    t.UserGroupID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        t.UserGroupID,
                                                                                        tt.ToolId,
                                                                                        $event,
                                                                                        true
                                                                                    )
                                                                                "
                                                                                :disabled="
                                                                                    toolUserGroupsExclude[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ].indexOf(
                                                                                        t.UserGroupID
                                                                                    ) >
                                                                                        -1 &&
                                                                                        !t.IsIncluded
                                                                                "
                                                                                v-if="
                                                                                    toolUserGroupsExclude[
                                                                                        tt
                                                                                            .ToolId
                                                                                    ].indexOf(
                                                                                        t.UserGroupID
                                                                                    ) >
                                                                                        -1 ||
                                                                                        !t.IsIncluded
                                                                                "
                                                                            />

                                                                            <span
                                                                                v-if="
                                                                                    t.IsIncluded &&
                                                                                        toolUserGroupsExclude[
                                                                                            tt
                                                                                                .ToolId
                                                                                        ].indexOf(
                                                                                            t.UserGroupID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                                class="width68 pl-4 ml-1 mt-1 slash-checkbox1"
                                                                            >
                                                                                <img
                                                                                    src="../../../assets/exclude_black.svg"
                                                                                />
                                                                            </span>
                                                                            <span
                                                                                v-if="
                                                                                    !t.IsIncluded ||
                                                                                        toolUserGroupsExclude[
                                                                                            tt
                                                                                                .ToolId
                                                                                        ].indexOf(
                                                                                            t.UserGroupID
                                                                                        ) >
                                                                                            -1
                                                                                "
                                                                            >
                                                                                <span
                                                                                    v-if="
                                                                                        !t.IsIncluded &&
                                                                                            toolUserGroupsExclude[
                                                                                                tt
                                                                                                    .ToolId
                                                                                            ].indexOf(
                                                                                                t.UserGroupID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="width68 pl-4 ml-1 mt-1"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            t.UserGroupID,
                                                                                            tt.ToolId,
                                                                                            'Tools',
                                                                                            t
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        src="../../../assets/exclude_red.svg"
                                                                                    />
                                                                                </span>
                                                                                <span
                                                                                    v-if="
                                                                                        t.IsIncluded &&
                                                                                            toolUserGroupsExclude[
                                                                                                tt
                                                                                                    .ToolId
                                                                                            ].indexOf(
                                                                                                t.UserGroupID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="width68 pl-4 mt-1"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            t.UserGroupID,
                                                                                            tt.ToolId,
                                                                                            'Tools',
                                                                                            t
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        src="../../../assets/exclude_black.svg"
                                                                                    />
                                                                                </span>
                                                                            </span>
                                                                            <span
                                                                                class="slash-text"
                                                                                >{{
                                                                                    t.UserGroupName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="toolsGroupList"
                    @changePage="onChangeGroupToolsPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div class="card-footer" v-if="pageOfGroupTools.length > 0">
                        <b-pagination
                            v-model="currentPageTools"
                            :total-rows="rowsTools"
                            :per-page="perPage"
                            aria-controls="ToolsTable"
                            @change="collapseAllTools"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="dataset-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="d-block text-center" v-if="!reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                There is no item to review.
            </div>
            <div class="d-block" v-if="reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <div class="text-center">
                    <h4 class="my-2">Change queue</h4>
                    <small
                        >Review {{ reviewItem.length }} changes pending before
                        commiting the changes.</small
                    >
                </div>
                <div class="my-3 tblScroll">
                    <table class="table table-striped dataSetModal">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Access</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr
                                v-for="(item, index) in reviewItem"
                                :key="index"
                            >
                                <td>{{ item.groupName }}</td>
                                <td>
                                    <span
                                        v-if="!item.isIncluded && item.isActive"
                                    >
                                        <img
                                            src="../../../assets/exclude_red.svg"
                                        />
                                    </span>

                                    <span
                                        v-else-if="
                                            item.isActive && item.isIncluded
                                        "
                                    >
                                        <img src="../../../assets/icon_tick.svg"
                                    /></span>

                                    <span v-else>
                                        <span v-if="!item.isActive">
                                            <img src="../../../assets/close.svg"
                                        /></span>
                                        <span
                                            v-if="
                                                item.isIncluded &&
                                                    item.hasOwnProperty(
                                                        isIncluded
                                                    )
                                            "
                                        >
                                            <img
                                                src="../../../assets/exclude_black.svg"
                                            />
                                        </span>
                                    </span>
                                </td>

                                <td>{{ item.toolName }}</td>
                                <td
                                    class="deleteCell"
                                    @click="
                                        delQueue(
                                            index,
                                            item.UserGroupID,
                                            item.ToolId
                                        )
                                    "
                                >
                                    Delete from queue&nbsp;
                                    <img src="../../../assets/close.svg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 text-center">
                    <a class="text-primary" @click="clearQueue">Clear queue </a>
                    <button class="buttonSelect ml-3" @click="commitChanges">
                        Commit changes
                    </button>
                </div>
            </div>
        </b-modal>
        <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../../assets/success_tick.svg" class="mr-3"/>
                <span v-if="reviewItem.length"> <b>Changes queued </b><br/>{{successToastValue}} <br/>All the requested changes to be inserted into the queue.</span>
                 <span v-if="!reviewItem.length">{{successToastValue}}</span>
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../../assets/failure_cross.svg" class="mr-3"/>
                {{failureToastValue}}
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
    </div>
</template>

<script>
// import router from "../../router";

import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import { mapActions, mapMutations } from 'vuex';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    props: ['groupCreated'],
    watch: {
        groupCreated: function(newVal, oldVal) {
            // watch it
            this.getGroupsTool();
            this.getToolsGroup();
        },
    },
    data() {
        return {
            searchText: true,
            searchTextTool: true,
            showTextData: false,
            customLabels,
            showText: false,
            searchGroup: '',
            searchGroupTools: '',
            allSelected: false,
            allSelectedTools: false,
            perPage: 5,
            groupToolsList: [],
            pageOfGroups: [],
            toolsGroupList: [],
            pageOfGroupTools: [],
            groupIds: [],
            toolIds: [],
            toolIdsExclude: [],
            reviewItem: [],
            // successToast: false,
            // failureToast:false,
            // successToastValue:'',
            // failureToastValue:'',
            showPage: false,
            thisfilename: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            currentPage: 1,
            groupToolsListFiltered: [],
            currentPageTools: 1,
            toolsGroupListFiltered: [],
            searchToolNameInput: true,
            searchToolNameModel: '',
            searchToolUserGroupNameInput: true,
            userGroupTools: {},
            userGroupToolsExclude: {},
            toolUserGroups: {},
            toolUserGroupsExclude: {},
            searchToolUserGroupNameModel: '',
            collapseStatesGroups: [],
            collapseStatesTools: [],
            searchCloseGroup: false,
            showSearchgroup: false,
            searchClosegroupsInner: false,
            showSearchgroupsInner: false,
            searchCloseTools: false,
            showSearchTools: false,
            searchClosetoolsInner: false,
            showSearchtoolsInner: false,
        };
    },
    created() {
        this.getGroupsTool();
        this.getToolsGroup();
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('GATools')) {
                    vm.showPage = true;
                }
            });
            this.$emit('clicked', this.showPage);
        },
        getGroupsTool() {
            managementService.getGroupsTool().then((res) => {
                this.groupToolsList = res.data;
                this.groupToolsList.forEach((item) => {
                    if (item.Tool) {
                        let temp = [];
                        let tempExclude = [];
                        item.Tool.forEach((subItem) => {
                            temp.push(subItem.ToolID);
                            if (!subItem.IsIncluded) {
                                tempExclude.push(subItem.ToolID);
                            }
                        });
                        this.userGroupTools[item.UserGroupID] = temp;
                        this.userGroupToolsExclude[
                            item.UserGroupID
                        ] = tempExclude;
                    }
                });

                this.groupToolsListFiltered = res.data;
            });
        },
        getToolsGroup() {
            managementService.getToolsGroup().then((res) => {
                this.toolsGroupList = res.data;
                this.toolsGroupList.forEach((item) => {
                    if (item.UserGroup) {
                        let temp = [];
                        let tempExclude = [];
                        item.UserGroup.forEach((subItem) => {
                            temp.push(subItem.UserGroupID);
                            if (!subItem.IsIncluded) {
                                tempExclude.push(subItem.UserGroupID);
                            }
                        });
                        this.toolUserGroups[item.ToolId] = temp;
                        this.toolUserGroupsExclude[item.ToolId] = tempExclude;
                    }
                });

                this.toolsGroupListFiltered = res.data;
            });
        },
        searchOpenGroup() {
            this.searchCloseGroup = !this.searchCloseGroup;
            this.showSearchgroup = true;
            this.$nextTick(() => {
                this.$refs.groups.focus();
            });
        },
        closeBarGroup() {
            this.searchCloseGroup = !this.searchCloseGroup;
            this.showSearchgroup = false;
            this.searchGroup = null;
        },

        closeBargroupsInner() {
            this.searchClosegroupsInner = !this.searchClosegroupsInner;
            this.showSearchgroupsInner = false;
            this.searchToolNameModel = null;
        },
        searchOpengroupsInner(index) {
            this.searchClosegroupsInner = !this.searchClosegroupsInner;
            this.showSearchgroupsInner = true;
            this.$nextTick(() => {
                this.$refs.groupsInner[index].focus();
            });
        },

        searchOpenTools() {
            this.searchCloseTools = !this.searchCloseTools;
            this.showSearchTools = true;
            this.$nextTick(() => {
                this.$refs.tools.focus();
            });
        },
        closeBarTools() {
            this.searchCloseTools = !this.searchCloseTools;
            this.showSearchTools = false;
            this.searchGroupTools = null;
        },

        closeBartoolsInner() {
            this.searchClosetoolsInner = !this.searchClosetoolsInner;
            this.showSearchtoolsInner = false;
            this.searchToolUserGroupNameModel = null;
        },
        searchOpentoolsInner(index) {
            this.searchClosetoolsInner = !this.searchClosetoolsInner;
            this.showSearchtoolsInner = true;
            this.$nextTick(() => {
                this.$refs.toolsInner[index].focus();
            });
        },

        collapseAllGroups() {
            this.collapseStatesGroups = this.groupToolsList.map(
                (x) => (x.open = false)
            );
        },
        collapseAllTools() {
            this.collapseStatesTools = this.toolsGroupList.map(
                (x) => (x.open = false)
            );
        },
        onChangeGroupPage(pageOfGroups) {
            this.pageOfGroups = pageOfGroups;
        },
        addSearchText() {
            if (this.searchGroup.length == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextTools() {
            if (this.searchGroupTools.length == 0) {
                this.searchTextTool = true;
                this.showTextData = false;
            }
        },
        removeSearchTextTool() {
            this.searchTextTool = false;
            this.showTextData = true;
        },
        // selectAll(p) {
        //     this.projectDatasetIds = [];

        //     if (!this.allSelected) {
        //         for (p in this.projectList) {
        //         this.projectDatasetIds.push(this.projectList[p].id);
        //         }
        //     }
        // },
        onChangeGroupToolsPage(pageOfGroupTools) {
            this.pageOfGroupTools = pageOfGroupTools;
        },

        checkedBox() {},
        checkedGroup(id) {
            if (this.groupIds.indexOf(id) > -1) {
                this.groupIds.splice(this.groupIds.indexOf(id), 1);
                this.allSelected = false;
            } else {
                this.groupIds.push(id);
            }
        },
        checkedTool(id) {
            if (this.toolIds.indexOf(id) > -1) {
                this.toolIds.splice(this.toolIds.indexOf(id), 1);
                this.allSelectedTools = false;
            } else {
                this.toolIds.push(id);
            }
        },
        checkedExcludeTool(id) {
            if (this.toolIdsExclude.indexOf(id) > -1) {
                this.toolIdsExclude.splice(this.toolIdsExclude.indexOf(id), 1);
                this.toolIds.splice(this.toolIds.indexOf(id), 1);
                //this.allSelectedTools = false
            } else {
                this.toolIdsExclude.push(id);
                if (this.toolIds.indexOf(id) == -1) {
                    this.toolIds.push(id);
                }
            }
        },

        selectAllGroups(p) {
            this.groupIds = [];
            if (!this.allSelected) {
                for (p in this.groupToolsList) {
                    this.groupIds.push(this.groupToolsList[p].UserGroupID);
                }
            }
        },
        selectAllTools(d) {
            this.toolIds = [];
            if (!this.allSelectedTools) {
                for (d in this.toolsGroupList) {
                    this.toolIds.push(this.toolsGroupList[d].ToolId);
                }
            }
        },
        reviewQueue() {
            // this.reviewItem=[]
            // this.groupIds.forEach((groupItem)=>{
            //     let groupName = this.groupToolsList.filter((item)=>{
            //         return item.UserGroupID == groupItem;
            //     })
            //     //
            //     this.toolIds.forEach((toolItem)=>{
            //         let toolName = this.toolsGroupList.filter((subItem)=>{
            //             return subItem.ToolId == toolItem;
            //         })
            //         this.reviewItem.push({
            //             "UserGroupID":groupItem,
            //             "ToolId":toolItem,
            //             "groupName": groupName[0].UserGroupName,
            //             "toolName": toolName[0].ToolName,
            //             "isActive": true
            //         })
            //     })
            // })
            this.$refs['dataset-modal'].show();
        },
        delQueue(index, groupID, toolID) {
            let removeItem = this.reviewItem[index];
            this.reviewItem.splice(index, 1);
            for (let i = 0; i < this.groupToolsList.length; i++) {
                if (this.groupToolsList[i].Tool) {
                    for (
                        let j = 0;
                        j < this.groupToolsList[i].Tool.length;
                        j++
                    ) {
                        if (removeItem.IsIncluded) {
                            if (
                                this.groupToolsList[i].Tool[j].ToolID ==
                                    removeItem.ToolId &&
                                this.groupToolsList[i].Tool[j].UserGroupID ==
                                    removeItem.UserGroupID
                            ) {
                                this.groupToolsList[i].Tool[
                                    j
                                ].IsIncluded = false;
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < this.toolsGroupList.length; i++) {
                if (this.toolsGroupList[i].UserGroup) {
                    for (
                        let j = 0;
                        j < this.toolsGroupList[i].UserGroup.length;
                        j++
                    ) {
                        if (removeItem.IsIncluded) {
                            if (
                                this.toolsGroupList[i].UserGroup[j]
                                    .UserGroupID == removeItem.UserGroupID
                            ) {
                                this.toolsGroupList[i].UserGroup[
                                    j
                                ].IsIncluded = false;
                            }
                        }
                    }
                }
            }
            let groupIdFound = this.reviewItem.findIndex(
                (item) => item.UserGroupID == removeItem.UserGroupID
            );
            if (groupIdFound == -1) {
                let groupId = this.groupIds.findIndex(
                    (item) => item == removeItem.UserGroupID
                );
                this.groupIds.splice(groupId, 1);
            }
            let toolIdFound = this.reviewItem.findIndex(
                (item) => item.ToolId == removeItem.ToolId
            );
            if (toolIdFound == -1) {
                let toolId = this.toolIds.findIndex(
                    (item) => item == removeItem.ToolId
                );
                this.toolIds.splice(toolId, 1);
                this.toolIdsExclude.splice(toolId, 1);
            }
            if (this.userGroupTools[groupID].indexOf(toolID) == -1) {
                this.userGroupTools[groupID].push(toolID);
            }
            if (this.toolUserGroups[toolID].indexOf(groupID) == -1) {
                this.toolUserGroups[toolID].push(groupID);
            }

            /*if(!this.reviewItem.length){
                this.$refs["dataset-modal"].hide();
            }*/
        },
        clearQueue() {
            this.groupIds = [];
            this.toolIds = [];
            this.toolIdsExclude = [];
            this.reviewItem = [];
            this.userGroupTools = {};
            this.toolUserGroups = {};
            this.getGroupsTool();
            this.getToolsGroup();
            this.$refs['dataset-modal'].hide();
        },
        hideModal() {
            this.$refs['dataset-modal'].hide();
        },
        //   closeSuccessToast(){
        //     this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                data.push({
                    UserGroupID: item.UserGroupID,
                    ResourceID: item.ToolId,
                    IsActive: item.isActive,
                    IsIncluded: item.isIncluded,
                });
            });

            managementService.addOrUpdateTools(data).then((res) => {
                if (res.data.StatusCode === 201) {
                    this.setToastMessage({
                        showSuccess: true,
                        showFailure: false,
                        successToastMessage: 'Association done successfully.',
                        failureToastMessage: null,
                        messageTitle: null,
                    });
                    this.reviewItem = [];
                    this.getGroupsTool();
                    this.getToolsGroup();
                    this.groupIds = [];
                    this.toolIds = [];
                    this.toolIdsExclude = [];
                    this.$refs['dataset-modal'].hide();
                }
            });
        },
        childExcludeCheck(groupId, toolId, type, item) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.UserGroupID == groupId && item.ToolId == toolId;
            });

            if (unCheckedItem > -1 && item.IsIncluded) {
                // if(this.userGroupTools[groupId].indexOf(toolId) == -1 || this.toolUserGroups[toolId].indexOf(groupId) == -1){
                this.reviewItem.splice(unCheckedItem, 1);
                // }
            } else {
                let groupName = this.groupToolsList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                //
                let toolName = this.toolsGroupList.filter((item) => {
                    return item.ToolId == toolId;
                });
                // if(this.userGroupTools[groupId].indexOf(toolId) > -1 || this.toolUserGroups[toolId].indexOf(groupId) > -1){
                let alreadyInserted = this.reviewItem.findIndex((item) => {
                    return item.UserGroupID == groupId && item.ToolId == toolId;
                });
                if (alreadyInserted == -1) {
                    this.reviewItem.push({
                        UserGroupID: groupId,
                        ToolId: toolId,
                        groupName: groupName[0].UserGroupName,
                        toolName: toolName[0].ToolName,
                        isIncluded: true,
                        isActive: false,
                    });
                }
                //  }
            }
            if (type == 'Groups') {
                item.IsIncluded = !item.IsIncluded;
                if (this.userGroupTools[groupId].indexOf(toolId) > -1) {
                    this.userGroupTools[groupId].splice(
                        this.userGroupTools[groupId].indexOf(toolId),
                        1
                    );
                } else {
                    this.userGroupTools[groupId].push(toolId);
                }
            }
            if (type == 'Tools') {
                item.IsIncluded = !item.IsIncluded;

                if (this.toolUserGroups[toolId].indexOf(groupId) > -1) {
                    this.toolUserGroups[toolId].splice(
                        this.toolUserGroups[toolId].indexOf(groupId),
                        1
                    );
                } else {
                    this.toolUserGroups[toolId].push(groupId);
                }
            }

            this.$forceUpdate();
        },

        childCheck(groupId, toolId, event, excluded) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.UserGroupID == groupId && item.ToolId == toolId;
            });
            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    if (excluded) {
                        this.reviewItem[unCheckedItem].isActive = true;
                    } else {
                        this.reviewItem.splice(unCheckedItem, 1);
                    }
                } else {
                    this.reviewItem[unCheckedItem].isActive = false;
                }
            } else {
                let groupName = this.groupToolsList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                //
                let toolName = this.toolsGroupList.filter((item) => {
                    return item.ToolId == toolId;
                });
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return (
                            item.UserGroupID == groupId && item.ToolId == toolId
                        );
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            UserGroupID: groupId,
                            ToolId: toolId,
                            groupName: groupName[0].UserGroupName,
                            toolName: toolName[0].ToolName,
                            isActive: false,
                        });
                    }
                }
            }
        },

        queueProjectChanges() {
            if (
                (this.groupIds.length && this.toolIds.length) ||
                this.reviewItem.length
            ) {
                // this.reviewItem=[]
                if (this.groupIds.length && this.toolIds.length) {
                    let selectedGroups = {};
                    this.groupIds.forEach((groupItem) => {
                        let selectedTools = [];
                        this.toolIds.forEach((toolItem) => {
                            selectedTools.push(toolItem);
                        });
                        selectedGroups[groupItem] = selectedTools;
                    });
                    for (let props in selectedGroups) {
                        let groupName = this.groupToolsList.filter((item) => {
                            return item.UserGroupID == props;
                        });
                        for (let i = 0; i < selectedGroups[props].length; i++) {
                            let toolName = this.toolsGroupList.filter(
                                (subItem) => {
                                    return (
                                        subItem.ToolId ==
                                        selectedGroups[props][i]
                                    );
                                }
                            );
                            let excludeTools = this.toolIdsExclude.filter(
                                (subItem) => {
                                    return subItem == selectedGroups[props][i];
                                }
                            );
                            let exist = this.reviewItem.findIndex(
                                (item) =>
                                    item.UserGroupID == props &&
                                    item.ToolId == selectedGroups[props][i]
                            );
                            if (exist === -1) {
                                if (excludeTools.length) {
                                    this.reviewItem.push({
                                        UserGroupID: props,
                                        ToolId: selectedGroups[props][i],
                                        groupName: groupName[0].UserGroupName,
                                        toolName: toolName[0].ToolName,
                                        isActive: true,
                                        isIncluded: false,
                                    });
                                } else {
                                    this.reviewItem.push({
                                        UserGroupID: props,
                                        ToolId: selectedGroups[props][i],
                                        groupName: groupName[0].UserGroupName,
                                        toolName: toolName[0].ToolName,
                                        isActive: true,
                                        isIncluded: true,
                                    });
                                }
                            }
                        }
                    }

                    /* this.groupIds.forEach((groupItem)=>{
                    let groupName = this.groupToolsList.filter((item)=>{
                        return item.UserGroupID == groupItem;
                    })
                    // 
                    this.toolIds.forEach((toolItem)=>{
                        let toolName = this.toolsGroupList.filter((subItem)=>{
                            return subItem.ToolId == toolItem;
                        })
                        this.reviewItem.push({
                            "UserGroupID":groupItem,
                            "ToolId":toolItem,
                            "groupName": groupName[0].UserGroupName,
                            "toolName": toolName[0].ToolName,
                            "isActive": true
                        })
                        this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.UserGroupID)))
                            .map(id=>{
                                return this.reviewItem.find(a=> a.UserGroupID ===id)
                            })
                    })
                    })*/
                }

                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';
                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    successToastMessage: null,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: null,
                });
            }
        },
    },
    computed: {
        groupFilteredList() {
            let vm = this;
            if (vm.searchGroup) {
                vm.pageOfGroups = vm.groupToolsListFiltered.filter((post) => {
                    return post.UserGroupName.toLowerCase().includes(
                        vm.searchGroup.toLowerCase()
                    );
                });
            } else {
                vm.pageOfGroups = vm.groupToolsListFiltered;
            }
            return vm.pageOfGroups.slice(
                (vm.currentPage - 1) * vm.perPage,
                vm.currentPage * vm.perPage
            );
        },
        toolsFilteredList() {
            let vm = this;
            if (this.searchGroupTools) {
                vm.pageOfGroupTools = vm.toolsGroupListFiltered.filter(
                    (post) => {
                        return post.ToolName.toLowerCase().includes(
                            vm.searchGroupTools.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfGroupTools = vm.toolsGroupListFiltered;
            }
            return vm.pageOfGroupTools.slice(
                (vm.currentPageTools - 1) * vm.perPage,
                vm.currentPageTools * vm.perPage
            );
        },
        rows() {
            return this.pageOfGroups.length;
        },
        rowsTools() {
            return this.pageOfGroupTools.length;
        },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   color: black;
//   text-align: left;
//   span {
//     margin-left: 25px;
//     margin-left: 30px;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
// button.access-btn{
//     width:309px;
//     float:left;
// }
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/blackminus.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   width: 388px;
// }
// .buttonSelect.queueChange{
//     position: absolute;
//     right: 20px;
//     top: -72px;
// }
// .disableButton.queueChange{
//     background-color: #6C757D !important;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
// .paginationStyle {
//   border: none;
//   /* margin: 0px auto !important; */
//   width: 200px;
//   margin: 0px;
//   padding: 0px;
//   display: contents !important;
// }
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
    color: #006395 !important;
}
// input.test {
//   width: 411px;
// }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 405px;
    font-family: var(--ffr);
    font-size: 16px;
    color: #373a3c;
    font-weight: normal;
}
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    //   .row1 {
    //     //border-bottom: 3px solid #eaeaea;
    //     padding-top: 10px;
    //     //background-color: #eaeaea;
    //     // h6.blue {
    //     //   color: #006395 !important;
    //     // }
    //   }
    //   .showProjectList {
    //     overflow: auto;
    //     height: 300px;
    //     width: 417px;
    //   }
    .header {
        padding: 10px;
        border-bottom: 3px solid #1d1d1d;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
//   .reviewQueueButton{
//     position: absolute;
//     top:-58px;
//     right:175px;
//     cursor: pointer;
// }
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
#ToolsTable button.btn.btn-secondary.btn-block.not-collapsed {
    background-color: #eaeaea !important;
    // position: absolute;
    // width: 100%;
}
.table-striped tbody tr:first-child {
    background-color: white;
}
.icon-include {
    position: absolute;
    top: 2px;
}
.userrightmanagement .width357 {
    width: 357px !important;
}
.slash-text {
    margin-left: 20px !important;
}
</style>
