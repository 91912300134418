var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"titleblock"},[_c('b-container',{staticClass:"define-tool"},[_c('p',{staticClass:"my-3"},[_vm._v("Add styling to graph axis")]),_vm._l((_vm.axisDetails),function(item,index){return _c('b-row',{key:index,staticStyle:{"height":"400px"}},[_c('b-col',{staticClass:"block-1"},[_c('div',{staticClass:"line-block mt-6 pt-3"},[_c('div',{staticClass:"ml-4 mt-4 style-block"},[_c('span',{style:(_vm.getCss(item.style))},[_vm._v(_vm._s(item.axisAlias))]),_c('b-check',{staticClass:"mt-2 mb-2 checkbox",on:{"change":function($event){return _vm.loadDefaultStyle(item)}},model:{value:(item.useDefaultStyle),callback:function ($$v) {_vm.$set(item, "useDefaultStyle", $$v)},expression:"item.useDefaultStyle"}},[_vm._v("Load default GHD styles")]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: 'Size',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"options":_vm.styleDataSize,"text-field":"Size","value-field":"Size"},on:{"change":function($event){return _vm.checkDefault(item)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Size")])]},proxy:true}],null,true),model:{value:(item.style.size),callback:function ($$v) {_vm.$set(item.style, "size", $$v)},expression:"item.style.size"}})],1),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: 'Style',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"options":_vm.styleDataforStyle,"text-field":"Style","value-field":"Style"},on:{"change":function($event){return _vm.checkDefault(item)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Style")])]},proxy:true}],null,true),model:{value:(item.style.fontWeight),callback:function ($$v) {_vm.$set(item.style, "fontWeight", $$v)},expression:"item.style.fontWeight"}})],1),_c('div',{staticClass:"pickColor wrap"},[_c('div',{},[_c('button',{staticClass:"btn-transparent"},[_c('img',{staticClass:"pencil-icon",attrs:{"src":require("../../assets/ghd_colorpicker.svg"),"font-scale":"1.5"}})]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                        label: 'Color',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }}},[_c('ejs-colorpicker',{staticClass:"e-input",attrs:{"id":"element","type":"text"},on:{"change":function($event){return _vm.checkDefault(item)}},model:{value:(item.style.color),callback:function ($$v) {_vm.$set(item.style, "color", $$v)},expression:"item.style.color"}})],1)],1)]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: 'Type',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"options":_vm.styleDataType,"text-field":"Type","value-field":"Type"},on:{"change":function($event){return _vm.checkDefault(item)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Type")])]},proxy:true}],null,true),model:{value:(item.style.fontFamily),callback:function ($$v) {_vm.$set(item.style, "fontFamily", $$v)},expression:"item.style.fontFamily"}})],1)],1)])])],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }