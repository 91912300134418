<template>
    <div class="login">
        <div class="bg_img">
            <!-- :class="{ backblur: loading }" -->
            <!-- <div class="bg-dark d-flex align-items-center text-white "  style="height:88px">
      <div class="ml-auto header_content"> -->
            <!-- <a href="#" @click="$msal.signIn()" class="text-white"><strong>Login</strong></a> -->
            <!-- <strong> GHD DATA ACCESS TOOL </strong></div>
    </div> -->

            <!--<div class="d-flex align-items-center justify-content-center" style="height:720px; margin:0px auto">
      <div class="form_card">
        <h2> Welcome back</h2>
        <p>Please sign in with your credentials.</p>
        <b-form-input v-model="text" class="mt-4 mb-4" placeholder="Username"></b-form-input>
        <b-form-input v-model="password" class="mb-2" placeholder="Password"></b-form-input>
        <a href="#">Forgot your password?</a>
        <div class="mt-4">
          <router-link to="/dashboard">
            <b-button variant="primary" class="px-5">Sign in</b-button>
          </router-link>
        </div>
      </div>
    </div>-->
            <div
                class="d-flex align-items-center justify-content-center grey-overlay"
            >
                <div class="overlay-title">
                    <img class="img-fluid" src="../assets/new_adapt_logo.png" />
                    <h6 class="title">
                        ADVANCED DATA ANALYTICS PORTAL & TOOLS
                    </h6>
                    <!-- <img class="img-fluid" src="../assets/below_title.png"/> -->
                </div>
                <b-overlay
                    class="d-flex align-items-center justify-content-center login"
                >
                    <b-card class="form_card" text-variant="#000000DE">
                        <!-- <div>hhhhhhhhhhh {{showState}}</div> -->
                        <!-- <div>SSSSSSSSSSSSSSSSSSSSSSSss{{showDataset}}</div>
        <div>RRRRRRRRRRRRRRRRRR{{showD}}</div> -->
                        <b-card-text class="text-center mb-3 welcome">
                            Welcome</b-card-text
                        >
                        <b-card-text class="text-center">
                            Sign in to visualize and manage your data from many
                            perspectives including spatial, graphical, and
                            tabular formats.
                        </b-card-text>

                        <b-row
                            class="d-flex align-items-center justify-content-center"
                        >
                            <b-button
                                v-if="signInIcon"
                                class="
                  d-flex
                  align-items-center
                  justify-content-center
                  sign-btn
                "
                                @click="SignIn()"
                            >
                                <img src="../assets/ms_signin_light.png" />
                            </b-button>
                            <button
                                v-else
                                class="loadingBtn btn my-3"
                                type="button"
                                disabled
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                Loading...
                            </button>
                        </b-row>

                        <b-row
                            class="text-center flex-column justify-content-center mt-8"
                        >
                            <!-- <a
                                class="mt-1 license"
                                href="https://ghdadaptresourcedev.blob.core.windows.net/resources/ADAPT_Rider_20220620.pdf"
                                target="_blank"
                                >License agreement</a
                            > -->
                            <a class="mt-1 support" @click="showContactModal">
                                Contact support team
                            </a>
                        </b-row>
                    </b-card>
                </b-overlay>
            </div>
        </div>
        <!-- <div class="container">
    <div class="overlay"> 
    <img class="img-fluid mt-3" src="../assets/ghd_logo_white.png"/>
    </div>
  </div> -->
        <!-- <AccessPermissionModal ref="permission-modal"/> -->
        <b-modal
            v-bind:no-close-on-backdrop="false"
            ref="permission-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right cursor-pointer">
                    <img
                        class="rounded-circle"
                        src="../assets/close_icon.svg"
                        @click="hidePermissionModal"
                    />
                </div>
                <h5><img src="../assets/alert-circle.svg" /></h5>
                <h2 class="mt-2">Access denied</h2>
                <p>
                    You do not have permission to view the requested content.
                    Contact your administrator to obtain access.
                </p>
            </div>
        </b-modal>
        <b-modal
            v-bind:no-close-on-backdrop="false"
            ref="contact-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center py-2 contactModal">
                <div class="text-right cursor-pointer mb-2">
                    <img
                        class="rounded-circle"
                        src="../assets/close_icon.svg"
                        @click="hideContactModal"
                    />
                </div>
                <h5><img class="adaptLogo" src="../assets/favicon.svg" /></h5>
                <h2 class="mt-3 contactSupport">Contact support</h2>
                <p class="contactText mt-4">
                    Please send all inquiries to:
                    <a class="contactText" href="mailto: adapt-support@ghd.com"
                        >adapt-support@ghd.com</a
                    >
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
//import { config } from "../../config";
import { mapActions } from 'vuex';
// import { mapState } from "vuex";
import router from '../../router';
import axios from 'axios';
import { commonService } from '../services/common.service';
// import AccessPermissionModal from '../components/AccessPermissionModal'
export default {
    name: 'Home',
    components: {
        // Header
    },
    created() {
        this.$store.commit('common/setLoadingIconOff');
        if (this.$route.fullPath.length > 1) {
            this.$store.commit('common/setLoadingIconOn');
        }
        localStorage.removeItem('aggreGateOption');
        localStorage.removeItem('postingStatus');
        this.$msalInstance
            .handleRedirectPromise()
            .then((response) => {
                if (response !== null) {
                    document.cookie = `accessToken=${response.accessToken};path=/;`;
                    const myAccounts = this.$msalInstance.getAllAccounts();
                    localStorage.setItem(
                        'userDetails',
                        JSON.stringify(myAccounts[0])
                    );
                    this.getAccessToken();

                    // router.push("/dashboard");
                }
            })
            .catch((error) => {});
    },
    data() {
        return {
            errData: null,
            notValidUser: null,
        };
    },

    computed: {
        signInIcon: function() {
            return this.$store.state.common.signInIcon;
        },
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),

        async hidePermissionModal() {
            this.$refs['permission-modal'].hide();
            document.cookie.split(';').forEach(function(c) {
                document.cookie = c
                    .replace(/^ +/, '')
                    .replace(/=.*/, '=;max-age=-1;path=/');
            });
            localStorage.removeItem('loginErr');
            await this.$msalInstance
                .logout({})
                .then(() => {
                    router.push(`/classic/`);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        showContactModal() {
            this.$refs['contact-modal'].show();
        },
        hideContactModal() {
            this.$refs['contact-modal'].hide();
        },
        async SignIn() {
            this.$store.commit('common/setLoadingIconOn');
            await this.$msalInstance.loginRedirect();
        },
        async getAccessToken() {
            const account = this.$msalInstance.getAllAccounts()[0];
            const accessTokenRequest = {
                scopes: [
                    `api://${process.env.VUE_APP_API_CLIENT_ID}/access_as_user`,
                ],
                account: account,
            };
            this.$msalInstance
                .acquireTokenSilent(accessTokenRequest)
                .then((resp) => {
                    this.setAxiosToken(resp);
                })
                .catch((err) => {
                    this.$msalInstance.acquireTokenRedirect(accessTokenRequest);
                });
        },
        setAxiosToken(resp) {
            document.cookie = `accessToken=${resp.accessToken};path=/;`;
            this.$store.commit('common/setToken', resp.accessToken);
            this.navigateToDashboard();
        },
        navigateToDashboard() {
            const thisModal = this.$refs['permission-modal'];
            this.$store.commit('common/setLoadingIconOn');

            commonService
                .getPermissions(thisModal)
                .then((response) => {
                    var permissions = response.data.AccessPagesInfoLst;
                    router.push(`/dashboard`);
                })
                .catch(function(error) {
                    if (error.response.status === 403) {
                        thisModal.show();
                    }
                });
        },
    },
};
</script>

<style scoped>
.bg_img {
    background: url(../assets/login_bg.png) no-repeat center center fixed;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: border-box;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
}

.header_content {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 15px;
    color: white;
}

.login .form_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 13px;
    background-color: rgba(215, 220, 222, 0.9) !important;
    padding-top: 5%;
    padding-left: 40px;
    padding-right: 40px;
    width: 400px;
    margin: 65% 0 0 0;
}

.login_overlay {
    padding: 45px;
    width: 415px;
    height: 558px;
}

.overlay {
    position: absolute;
    top: 5px;
}

.overlay-title {
    position: absolute;
    top: 90px;
    text-align: center;
    color: #fff;
}

/* .grey-overlay {
  height: 100vh;
} */
.title {
    margin: 16px 0;
    font-size: 12px;
    letter-spacing: 1px;
}

.welcome {
    font-size: 30px;
    font-weight: 600;
}

.sign-btn {
    background-color: #fff;
    margin: 12px 0 32px 0;
    padding: 0px;
    border: 0;
}

.loadingBtn {
    width: 215px;
    background-color: #fff;
    border: solid 1px #000000;
    border-radius: 0px;
}

.spinner-border {
    margin-bottom: 2px;
}

.support {
    font-weight: 500;
}

.position-relative {
    top: 10px;
}

@media screen and (max-width: 992px) {
    .form_card {
        padding-top: 2%;
        margin: 45% 0 0 0;
    }
}

.contactHeader {
    border-bottom: solid 2px black;
}

.adptEmail {
    color: #006395;
}

.contactText {
    font-size: 16px;
}

.contactModal {
    height: 225px;
    font-family: IBM Plex Sans;
}

.adaptLogo {
    height: 35px;
    width: 35px;
}

.contactSupport {
    font-size: 25px;
    font-weight: 450;
}
</style>
