<template lang="html">
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div v-else>
        <portal-bar :hideSearch="hideSearch"></portal-bar>

        <!-- <b-row>
            <a style="position: relative; top: 10px" href="#"
                ><img src="../../assets/search-outline.svg"
                /></a>
            <b-col cols="10" class="text-left icons border p-0">
                <a @click="openTableselectTool"
                    ><img
                    src="../../assets/ghd_list.svg"
                    font-scale="1.5"
                    class="pr-3 mt-2 border-right pl-2"
                    /></a>
                <a href="#" class="m-0 pl-2" @click="showReportingTool"
                    ><img
                    src="../../assets/ghd_word.svg"
                    class="mr-2 pr-2 mt-2"
                    /></a>
                <div
                    v-if="reportingTool"
                    style="
                    position: absolute;
                    left: 70px;
                    top: 51px;
                    z-index: 99;
                    "
                    >
                    <div class="reportingTool shadow">
                        <div class="pl-3 py-2">
                        <p class="d-inline">Reporting</p>
                        <span
                            class="float-right mr-3"
                            @click="closeReporting"
                            >
                        <a class="p-0"
                            ><img src="../../assets/close.svg"
                            /></a>
                        </span>
                        </div>
                        <div class="titleBlock row align-items-cente">
                        <span>
                        <img
                            src="../../assets/icon_title_block.svg"
                            class=""
                            />
                        </span>
                        <small class="col pt-1 pl-2"> Title Block </small>
                        </div>
                        <div class="graphing row align-items-cente">
                        <span>
                        <img
                            src="../../assets/icon_graphing.svg"
                            class="w-75"
                            />
                        </span>
                        <small class="col pt-1 pl-2"> Graphing </small>
                        </div>
                        <div class="dataset row align-items-cente">
                        <span>
                        <img src="../../assets/icon_dataset.svg" class="" />
                        </span>
                        <small class="col pt-1 pl-2"> Dataset Export </small>
                        </div>
                        <div class="contouring row align-items-cente">
                        <span>
                        <img
                            src="../../assets/icon_contouring.svg"
                            class="w-75"
                            />
                        </span>
                        <small class="col pt-1 pl-2"> Contouring Tool </small>
                        </div>
                    </div>
                </div>
                <a @click="showDocumentMgmt"
                    ><img
                    src="../../assets/file.svg"
                    font-scale="1.5"
                    class="pr-3 mt-2 border-right"
                    /></a>
                <div
                    v-if="documentUpload"
                    style="
                    position: absolute;
                    left: 70px;
                    top: 51px;
                    z-index: 99;
                    "
                    >
                    <div class="reportingTool shadow">
                        <div class="pl-3 py-2">
                        <p class="d-inline">Document management</p>
                        <span
                            class="float-right mr-3"
                            @click="closeDocumentupload"
                            >
                        <a class="p-0"
                            ><img src="../../assets/close.svg"
                            /></a>
                        </span>
                        </div>
                        <div
                        class="titleBlock row align-items-cente"
                        @click="showModal"
                        >
                        <span>
                        <img src="../../assets/upload-cloud.svg" class="" />
                        </span>
                        <small class="col pt-1 pl-2"> Upload document </small>
                        </div>
                    </div>
                </div>-->
        <!-- <router-link to="/listview" class="mr-2"> -->
        <!--<span class="switchlabel pt-2 mr-3 mt-0">
                <label class="router-link-active switch ml-4">
                <input
                    type="checkbox"
                    v-model="currentSwitchState"
                    checked
                    />
                <span class="slider round"></span>
                </label>
                Open selection in new tab</span>
                <router-link to="/"> </router-link>
            </b-col>
            <b-col cols="1" class="mb-3 text-right icons ml-45">
                <b-icon
                    icon="geo-alt-fill"
                    font-scale="1.5"
                    class="mr-3 pr-2 border-right"
                    @click="viewChange"
                    ></b-icon>
                <b-icon
                    icon="list-ul"
                    font-scale="1.2"
                    @click="viewChange"
                    ></b-icon>
            </b-col>
        </b-row> -->

        <div class="upload_logo directoryUpload" v-if="!nextscreen">
            <h5>Upload directory</h5>
            <p class="note mb-2">
                *Note that empty directories will not be created
            </p>
            <div class="drag-drop" :id="'dropAreaGlobal' + tabIndex" v-cloak>
                <div class="iconplacing">
                    <b-icon-cloud-arrow-up
                        class="uploadicon"
                    ></b-icon-cloud-arrow-up>
                </div>
                <div class="dragdrop">Drag and drop folder to upload</div>
                <br />
                <script :id="'treeTemplate' + tabIndex" type="text/x-template">
                    <div class="d-flex">
                     <div class="w-100">
                         <span class="dirFolder"></span>
                         <span class="ename">${title}</span>
                     </div>
                    <div id="clear" class="m-r-15">
                         <span onclick="removeFile(event)" class="fileClose"></span>
                     </div>
                     </div>
                </script>

                <div>
                    <div :id="'dropArea' + tabIndex">
                        <ejs-uploader
                            :ref="'uploadObj' + tabIndex"
                            :id="'defaultfileupload' + tabIndex"
                            name="UploadFiles"
                            :uploading="onFileUpload"
                            :asyncSettings="path"
                            :sequentialUpload="isSequential"
                            :directoryUpload="directoryUpload"
                            :autoUpload="isAuto"
                            :dropArea="dropElement + tabIndex"
                            :created="onCreate"
                            :selected="onFileSelect"
                            :success="onSuccess"
                            :failure="onFailure"
                            maxFileSize="1073741824"
                        ></ejs-uploader>
                    </div>

                    <!-- <div v-if="showAccordion">
                    <span class="switchlabel pt-2 mr-3 mt-0">
                    <label class="router-link-active switch mt-4 mb-4">
                    <input
                        type="checkbox"
                        checked
                        />
                    <span class="slider round"></span>
                    </label>
                    Include files within directories</span
                        >
                    </div> -->
                    <div class="col-lg-4 property-section pl-0 pb-4 mt-0">
                        <div id="property" title="Properties">
                            <div>
                                <!-- <ejs-button :id='"uploadbtn"+this.tabIndex' :disabled="!'showAccordion'+this.tabIndex" @click="onButtonClick()" class="btn buttonSelect btn-secondary"
                                >Upload</ejs-button
                                > -->
                                <button
                                    :id="'uploadbtn' + this.tabIndex"
                                    :disabled="!'showAccordion' + this.tabIndex"
                                    @click="
                                        onButtonClick(
                                            $event,
                                            filesDetailsNew,
                                            tabIndex
                                        )
                                    "
                                    class="btn buttonSelect btn-secondary"
                                >
                                    Upload
                                </button>
                            </div>
                            <div class="d-none">
                                <ejs-checkbox
                                    :id="'checkFileList' + this.tabIndex"
                                    label="Show FileList"
                                    :change="change"
                                    :checked="true"
                                    name="FileUploader"
                                ></ejs-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h5>Uploaded file</h5>
            <div
                class="accordion"
                v-for="(ff, index) in uploadedFiles"
                :key="index"
                role="tablist"
            >
                <b-card
                    no-body
                    class="mb-1"
                    v-for="(f, index) in ff"
                    :key="index"
                >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                            block
                            v-b-toggle="'imagedetails-accordion' + index"
                            variant="info"
                            >{{ f.name }}</b-button
                        >
                    </b-card-header>
                    <b-collapse
                        :id="'imagedetails-accordion' + index"
                        :accordion="'imagedetails-accordion' + index"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <b-card-text>
                                <div class="container">
                                    <div class="row">
                                        <div class="col">
                                            <h5>Document details34</h5>
                                            <div class="small-12 column mb-3">
                                                <CustomFloatingLabel
                                                    v-bind:editUserParams="30"
                                                    :config="{
                                                        label: 'Document name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="f.name"
                                                        autocomplete="off"
                                                        class="normal_input enterAddress"
                                                    />
                                                </CustomFloatingLabel>
                                                <textarea
                                                    name=""
                                                    id="enterComments"
                                                    v-model="f.description"
                                                    placeholder="Description(optional)"
                                                ></textarea>
                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Keywords(optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                >
                                                    <input
                                                        type="text"
                                                        autocomplete="off"
                                                        class="normal_input enterAddress"
                                                    />
                                                </CustomFloatingLabel>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <h5>Assign attributes</h5>
                                            <b-form-select
                                                v-model="selected"
                                                @change="getAssignedAttributes"
                                            >
                                                <option value="null"
                                                    >Directory</option
                                                >
                                                <option
                                                    v-for="d in directories"
                                                    :value="d.DirectoryID"
                                                    :key="d.DirectoryID"
                                                    >{{
                                                        d.DirectoryName
                                                    }}</option
                                                >
                                            </b-form-select>
                                            <div v-if="attributes">
                                                <div
                                                    v-for="attribute in attributes"
                                                    :key="attribute.DirectoryID"
                                                >
                                                    <div
                                                        v-if="
                                                            attribute.isFreeText
                                                        "
                                                    >
                                                        <CustomFloatingLabel
                                                            :config="{
                                                                label:
                                                                    'Attribute name',
                                                                name: 'wrapper',
                                                                line: false,
                                                                scale: false,
                                                            }"
                                                            v-bind:editUserParams="
                                                                1
                                                            "
                                                        >
                                                            <input
                                                                type="text"
                                                                v-model="
                                                                    attribute.AttributeName
                                                                "
                                                                autocomplete="off"
                                                                class="normal_input w-499"
                                                            />
                                                        </CustomFloatingLabel>
                                                    </div>
                                                    <div v-else>
                                                        <h5>
                                                            {{
                                                                attribute.AttributeName
                                                            }}
                                                        </h5>
                                                        <div
                                                            v-for="(a,
                                                            index) in attribute.AttributeValue"
                                                            :key="index"
                                                        >
                                                            <b-form-radio
                                                                :aria-describedby="
                                                                    ariaDescribedby
                                                                "
                                                                :value="
                                                                    a.AttributeValueID
                                                                "
                                                            >
                                                                {{
                                                                    a.AttributeValueText
                                                                }}
                                                            </b-form-radio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>

        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />
        <!-- <div id="sToast" class="successToastMsg" v-if="successToast">
                <img src="../../assets/success_tick.svg" class="mr-3" />
                <p class="ph5">Success</p> <br />
                <p class="ph6">Directory uploaded</p>
                <img
                  src="../../assets/close_big.svg"
                  class="ml-3 closeMsg"
                  @click="closeSuccessToast"
                />
              </div>
              <div class="failureToastMsg" v-if="failureToast">
                <img src="../../assets/failure_cross.svg" class="mr-3" />
                <p class="ph5">Failure</p> <br />
                <p class="ph6">Something went wrong.Please try again later.</p>
                <img
                  src="../../assets/close_big.svg"
                  class="ml-3 closeMsg"
                  @click="closeFailureToast"
                />
              </div>
              <div class="failureToastMsg" v-if="invalidToast">
                <img src="../../assets/failure_cross.svg" class="mr-3" />
                <p class="ph5">Failure</p> <br />
                <p class="ph6">File already Exists.</p>
                <img
                  src="../../assets/close_big.svg"
                  class="ml-3 closeMsg"
                  @click="closeInvalidToast"
                />
              </div> -->
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { AccordionPlugin } from '@syncfusion/ej2-vue-navigations';
import { TreeView } from '@syncfusion/ej2-navigations';
import { CheckBoxPlugin, classNames } from '@syncfusion/ej2-vue-buttons';
//import {config} from "../../../config";
import PortalBar from '../PortalBar';
import ToastMessages from '../ToastMessages.vue';
import { commonService } from '../../services/common.service';
import { projectService } from '../../services/project.service';
import AccessPermissionModal from '../AccessPermissionModal';
Vue.use(AccordionPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(UploaderPlugin);
Vue.use(ButtonPlugin);
Vue.use(classNames);
// let hostUrl = "http://localhost:54654/";
export default {
    name: 'DirectoryUpload',
    props: {
        showdirectory: Boolean,
        tabIndex: Number,
        fileDetailsCumulative: Array,
        documentDirecroyTab: Object,
        changedTabIndex: Number,
        dirTabClickType: Boolean,
    },
    components: {
        PortalBar,
        ToastMessages,
        AccessPermissionModal,
    },
    mounted: function() {
        // this.getPermissions();
        this.pagePermit();
        // document.getElementById("uploadbtn").onclick = () => {
        //     var proxy = this;
        //     if (proxy.$el.querySelector("ul") && proxy.filesDetails.length > 0) {
        //         proxy.$refs.uploadObj.upload(proxy.filesDetails, true);
        //     }
        // };
    },
    data() {
        let ajaxSettings = {
            url:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureFileOperations',
            getImageUrl:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureGetImage',
            uploadUrl:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureUpload',
            downloadUrl:
                process.env.VUE_APP_DOCUMENT_API_URI +
                'AzureProvider/AzureDownload',
        };
        return {
            path: {
                // saveUrl: "http://localhost:54654/api/Documents",
                //   saveUrl: "https://ej2.syncfusion.com/services/api/uploadbox/Save",
                saveUrl: ajaxSettings.uploadUrl,
                removeUrl:
                    'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
            },
            isSequential: false,
            directoryUpload: true,
            fileListDetails: [],
            filesList: [],
            filesDetails: [],
            fileTotalSize: 0,
            parentElement: '',
            directoryName: '',
            isAuto: false,
            dropElement: '#dropAreaGlobal',
            change: () => {
                this.$refs['uploadObj' + this.tabIndex].clearAll();
            },
            onUpload: () => {},
            // onButtonClick: () => {},
            showAccordion: false,
            nextscreen: false,
            showPage: true,
            // successToast: false,
            // failureToast: false,
            // invalidToast: false,
            hideSearch: true,
            showSuccess: false,
            uploadedDirectoryName: [],
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            defaultfileupload: '',
            uploadObj: null,
            newTabIndex: null,
            filesDetailsNew: [],
            counter: 0,
            uploadedFilesName: [],
            filesUpdatedCounter: 0,
        };
    },
    created() {
        // this.pagePermit();
        localStorage.setItem('newTabIndex', this.tabIndex);
        localStorage.setItem('indexToRemove', this.tabIndex);
    },
    methods: {
        pagePermit() {
            this.showPage = false;
            this.$emit('clicked', this.showPage);
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('Documents - Directory Upload')) {
                    vm.showPage = true;
                    this.$emit('clicked', vm.showPage);
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        removeFile(args) {
            var instance = document.getElementById('uploadRoot' + this.tabIndex)
                .ej2_instances[0];
            var liElement = args.target.closest('li');
            var removeFileData = [];

            var uploadObj = document.getElementById(this.defaultfileupload)
                .ej2_instances[0];
            var targetLiElement = args.target
                .closest('li')
                .querySelectorAll('li');
            for (
                var liele = 0;
                liele < uploadObj.uploadedFilesData.length;
                liele++
            ) {
                if (targetLiElement.length === 0) {
                    if (
                        args.target.closest('li').innerText ===
                        uploadObj.uploadedFilesData[liele].rawFile.name
                    ) {
                        removeFileData.push(uploadObj.uploadedFilesData[liele]);
                    }
                } else {
                    for (var j = 0; j < targetLiElement.length; j++) {
                        if (
                            targetLiElement[j].innerText ===
                            uploadObj.uploadedFilesData[liele].rawFile.name
                        ) {
                            removeFileData.push(
                                uploadObj.uploadedFilesData[liele]
                            );
                        }
                    }
                }
            }
            uploadObj.remove(removeFileData);
            this.removeFileFromFileList(liElement);
            if (
                liElement.parentElement.childElementCount == 1 &&
                liElement.parentElement.closest('li') &&
                liElement.parentElement
                    .closest('li')
                    .classList.contains('e-has-child')
            ) {
                liElement.parentElement.closest('li').remove(); // To remove the parent header when the last file is removed
            }
            instance.removeNode(liElement);
        },
        removeFileFromFileList(li) {
            const dt = new DataTransfer();
            const input = document.getElementById(this.defaultfileupload);
            const { files } = input;
            if (li.classList.contains('e-has-child')) {
                input.files = dt.files;
                return true;
            }
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.name !== li.innerText) {
                    dt.items.add(file);
                }
                input.files = dt.files;
            }
        },

        onButtonClick: function(event, args, index) {
            var targetId = event.currentTarget.id;

            var proxy = this;
            var currFiledetails = this.$root.$refs.A.getdirectoryFileDetails();
            var newTab = index;

            // var filesDetails = JSON.parse(localStorage.getItem("filesDetails"));
            if (
                proxy.$el.querySelector('ul') &&
                currFiledetails[newTab].length > 0
            ) {
                proxy.$refs['uploadObj' + newTab].upload(
                    currFiledetails[newTab],
                    true
                );
            }
        },

        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapActions('projects', {
            selectProjects: 'selectProjects',
        }),
        onFileUpload: function(args) {
            let updirname = args.fileData.name.substring(
                0,
                args.fileData.name.lastIndexOf('/')
            );
            if (!this.uploadedDirectoryName.includes(updirname)) {
                this.uploadedDirectoryName.push(updirname);
            }
            // add addition data as key-value pair.
            let token = commonService.getCookie('accessToken');
            args.currentRequest.setRequestHeader(
                'Authorization',
                'Bearer ' + token
            );
            args.customFormData = [
                { Action: 'save' },
                { Path: '/' },
                { ProjectId: localStorage.getItem('directoryProjectID') },
                { UploadType: 'uploadDirectory' },
            ];
            this.uploadedDirectoryName = [
                ...new Set(this.uploadedDirectoryName),
            ];
            this.uploadedFilesName.push({
                directory: args.fileData.name.substring(
                    0,
                    args.fileData.name.lastIndexOf('/')
                ),
                fileName: args.fileData.name.split('/').pop(),
            });
        },
        onSuccess: function(args) {
            if (args.response.statusCode == 200) {
                if (this.uploadedDirectoryName.length > this.counter) {
                    projectService
                        .createUploadedDirectory(
                            this.uploadedDirectoryName[this.counter],
                            localStorage.getItem('directoryProjectID')
                        )
                        .then((res) => {
                            if (res.status == 200) {
                                while (
                                    this.filesUpdatedCounter <
                                    this.uploadedFilesName.length
                                ) {
                                    projectService
                                        .UpdateTblDocuments(
                                            this.uploadedFilesName[
                                                this.filesUpdatedCounter
                                            ].directory,
                                            localStorage.getItem(
                                                'directoryProjectID'
                                            ),
                                            this.uploadedFilesName[
                                                this.filesUpdatedCounter
                                            ].fileName
                                        )
                                        .then((res) => {});
                                    this.filesUpdatedCounter++;
                                }
                            }
                        });
                    this.counter++;
                    document
                        .getElementById('uploadRoot' + this.tabIndex)
                        .remove();
                    this.showAccordion = false;
                    this.successToastMessage = 'Directory uploaded';
                    this.showSuccess = true;
                    this.showFailure = false;
                    //projectService.createUploadedDirectory
                    setTimeout(() => {
                        this.showSuccess = false;
                    }, 5000);
                }
            }
        },
        onFailure: function(args) {
            if (args.response.statusCode == 500) {
                this.failureToastMessage =
                    'Something went wrong. Please try again later.';
                this.showFailure = true;
                this.showSuccess = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
            } else if (args.response.statusCode == 406) {
                this.fileListDetails = [];
                this.filesDetails = [];
                document.getElementById('uploadRoot' + this.tabIndex).remove();
                this.showAccordion = false;
                this.failureToastMessage = 'Directory already exists.';
                this.showFailure = true;
                this.showSuccess = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
            }
        },
        // closeSuccessToast() {
        //     this.successToast = false;
        // },
        // closeFailureToast() {
        //     this.failureToast = false;
        // },
        // closeInvalidToast(){
        //     this.invalidToast = false;
        // },
        // pagePermit(){
        //     if(!this.showPage){
        //         this.$emit('renderDashboard', this.showPage);
        //         // this.$refs["permission-modal"].showPermissionModal();
        //     }
        // },
        // pagePermit(){
        //     const data = this.$store.state.store.posts.AccessPagesInfoLst;
        //     let vm = this;
        //     data.forEach((element) => {
        //         if (element.PageName == "DirectoryUpload") {
        //             vm.showPage = true;
        //     }
        //     });
        //     this.$emit('renderDashboard', this.showPage);
        // },
        onCreate: function() {
            var spanElement = document.createElement('div');
            var icon = document.createElement('i');
            spanElement.appendChild(icon);
            var content = document.createElement('span');
            content.id = 'content' + this.tabIndex;
            content.innerHTML = 'Or ';
            spanElement.appendChild(content);
            var link = document.createElement('a');
            link.id = 'browse' + this.tabIndex;
            link.innerHTML = 'Browse';
            let browse1 = 'browse' + this.tabIndex;
            spanElement.appendChild(link);
            setTimeout(function() {
                document
                    .getElementById(browse1)
                    .addEventListener('click', function() {
                        document
                            .querySelector(
                                '.directoryUpload .e-file-select-wrap .e-btn'
                            )
                            .click();
                    });
            }, 1000);
            document.querySelector('.e-upload .e-btn').style.visibility =
                'hidden';
            this.$refs[
                'uploadObj' + this.tabIndex
            ].$el.parentElement.parentElement.prepend(spanElement);
        },
        onFileSelect(args) {
            this.uploadedDirectoryName = [];
            this.counter = 0;
            this.filesUpdatedCounter = 0;
            var newTabIndex;
            if (this.dirTabClickType) {
                newTabIndex = localStorage.getItem('newTabIndex');
            } else {
                newTabIndex = this.changedTabIndex;
            }
            this.directoryName = args.filesData[0].name.split('/')[0];
            this.fileListDetails = args.filesData;
            var parentElement = document.createElement('div');
            parentElement.id = 'uploadRoot' + newTabIndex;
            // this.showAccordion = true;
            document.getElementById('uploadbtn' + newTabIndex).disabled = false;
            document
                .getElementById('dropArea' + newTabIndex)
                .appendChild(parentElement);
            this.formSelectedData(this.directoryName);
            this.filesDetails = this.filesDetails.concat(args.filesData);
            this.filesDetailsNew[newTabIndex] = this.filesDetails;
            this.$emit('getFileDetails', this.filesDetailsNew);
            localStorage.setItem(
                'filesDetails',
                JSON.stringify(this.filesDetails)
            );
            args.cancel = true;
            let filePaths = [];
            for (var file = 0; file < args.filesData.length; file++) {
                filePaths[file] = args.filesData[file].name;
            }
            var tree = {};
            filePaths.forEach((path) => {
                if (
                    document.getElementById('checkFileList' + this.tabIndex)
                        .ej2_instances[0].checked == false
                ) {
                    var currentNode = tree;
                    path.split('/').forEach((segment) => {
                        if (
                            currentNode[segment] === undefined &&
                            !segment.includes('.')
                        ) {
                            currentNode[segment] = {};
                        }
                        currentNode = currentNode[segment];
                    });
                } else {
                    currentNode = tree;
                    path.split('/').forEach((segment) => {
                        if (currentNode[segment] === undefined) {
                            currentNode[segment] = {};
                        }
                        currentNode = currentNode[segment];
                    });
                }
            });
            var treeData = this.toTreeData(tree);
            var data = JSON.stringify(treeData); // assign datasource to TreeView component.
            if (
                document.getElementById('uploadRoot' + newTabIndex)
                    .ej2_instances[0].fields.dataSource.length <= 0
            ) {
                document.getElementById(
                    'uploadRoot' + newTabIndex
                ).ej2_instances[0].fields = {
                    dataSource: JSON.parse(data),
                    id: 'id',
                    text: 'title',
                    iconCss: 'accFolder',
                    child: 'children',
                };
            } else {
                document
                    .getElementById('uploadRoot' + newTabIndex)
                    .ej2_instances[0].addNodes(JSON.parse(data));
            }
        },
        toTreeData: function(tree) {
            var proxy = this;
            //var i = 0;
            return Object.keys(tree).map(function(title, i) {
                var o = { title: title, id: title + i };
                if (Object.keys(tree[title]).length > 0) {
                    o.children = proxy.toTreeData(tree[title]);
                }
                return o;
            });
        },
        formSelectedData: function() {
            for (let i = 0; i < this.fileListDetails.length; i++) {
                this.fileTotalSize += this.fileListDetails[i].size;
            }
            var newIndex;
            if (this.dirTabClickType) {
                newIndex = localStorage.getItem('newTabIndex');
            } else {
                newIndex = this.changedTabIndex;
            }
            let treeview = new TreeView({
                fields: {
                    dataSource: [],
                    id: 'title',
                    text: 'title',
                    child: 'children',
                    iconCss: 'accFolder',
                },
                nodeTemplate: '#treeTemplate' + newIndex,
                expandOn: 'Click',
                expandOnType: 'Click',
                loadOnDemand: false,
                nodeExpanded: function() {},
                cssClass: 'accordiontree',
            });

            // Render initialized treeview
            treeview.appendTo('#uploadRoot' + newIndex);
            var accorObj = document.getElementById('uploadRoot' + newIndex)
                .ej2_instances[0];
            var checkObj = document.getElementById('checkFileList' + newIndex)
                .ej2_instances[0];
            if (!checkObj.checked) {
                var listcontent = document.createElement('ul');
                listcontent.id = 'listWrapper' + newIndex;
                for (let i = 0; i < this.fileListDetails.length; i++) {
                    var liElement = document.createElement('li');
                    liElement.innerText = this.fileListDetails[i].name;
                    listcontent.appendChild(liElement);
                }
                accorObj.content = listcontent.outerHTML;
            }
        },
    },
};
</script>

<style>
.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;
}

.drag-drop p {
    text-align: center;
    width: 248px;
    margin-left: 128px;
    font-size: 18px;
    color: #000;
}

p.note {
    white-space: nowrap;
    font-size: 14px !important;
    font-weight: normal;
    text-align: left;
    margin-left: 0px;
}

.drag-drop p a {
    font-size: 20px;
    color: #006395 !important;
}

.drag-drop .property-section {
    clear: both;
}

.iconplacing {
    text-align: center;
    padding-top: 13px;
    position: relative;
    top: 10px;
}

.dragdrop {
    text-align: center;
    color: #000;
    font-size: 20px;
    position: relative;
    top: 20px;
}

ul.e-upload-files {
    border-top: none !important;
    margin-top: 70px !important;
}

.e-file-container {
    align-items: last baseline !important;
}

/* .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name{
  padding-right:20px!important; 
} */

.e-hover {
    color: #000;
}

.uploadicon {
    height: 56px;
    width: 82px;
    margin-top: 25px;
}

.e-upload {
    float: none;
    padding-bottom: 1.8rem;
}

.e-upload {
    border: none !important;
    text-align: center;
}

.e-ul .dirFolder,
.e-icon-wrapper .dirFolder,
.fileClose {
    background-repeat: no-repeat;
    background-position: center;
    background-origin: content-box;
    padding: 19px;
    cursor: pointer;
}

.e-ul .dirFolder {
    background-image: url(../../assets/folder.svg);
}

.e-icon-wrapper .dirFolder {
    background-image: url(../../assets/folder-list.svg);
}

.fileClose {
    background-image: url(../../assets/close.svg);
}

.e-icon-wrapper .fileClose {
    background-image: url(../../assets/close-white.svg);
}

.e-icon-wrapper .ename {
    color: #fff;
}

[id^='uploadbtn'] {
    text-transform: capitalize;
    width: 140px;
    top: 0 !important;
    left: 0 !important;
}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
    color: #1d1d1d !important;
}

#content,
[id^='browse'] {
    font-size: 20px;
}

/* .successToastMsg {
  position: absolute;
  bottom: 50px;
  right: 20px;
  background: white;
  height: 70px;
  width: 350px;
  border-left: 6px solid #00db52;
  align-items: center;
  padding: 0px 0px 0px 15px;
  box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.successToastMsg p{
  display: inline;
}
.failureToastMsg {
  position: absolute;
  bottom: 50px;
  right: 20px;
  background: white;
  height: 70px;
  width: 350px;
  border-left: 6px solid #d23037;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 15px;
  box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.failureToastMsg,.successToastMsg{
    width: 486px !important;
    height: 98px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 3px;
} */
.closeMsg {
    position: absolute;
    right: 20px;
}

.ph5 {
    font: normal normal 600 18px/23px IBM Plex Sans;
    letter-spacing: 0.21px;
    color: #1d1d1d;
    position: relative;
    top: -20px;
}

.ph6 {
    font: normal normal normal 18px/23px IBM Plex Sans;
    letter-spacing: 0.21px;
    color: #626262;
    position: relative;
    top: 15px;
    float: left;
    left: -58px;
}
</style>
