<template>
  <b-container class="bv-example-row mb-3 mt-2">
    <b-row cols="3" class="positionBlock">
      <b-col class="border-bottom border-dark border-right left-1">
        <b-button
          v-bind:class="{
            'tl_button edit': ProjectLayer.Position != 'topLeft',
          }"
          @click="dataPosition('topLeft')"
        >
          <span class="textSmall">Top</span><br /><span class="textLarge"
            >Left</span
          >
        </b-button>
      </b-col>
      <b-col class="border-bottom border-dark">
        <b-button
          v-bind:class="{
            'tc_button edit': ProjectLayer.Position != 'topCenter',
          }"
          @click="dataPosition('topCenter')"
          ><span class="textSmall">Top</span><br /><span class="textLarge"
            >Center</span
          ></b-button
        >
      </b-col>
      <b-col class="border-left border-bottom border-dark right-1">
        <b-button
          v-bind:class="{
            'tr_button edit': ProjectLayer.Position != 'topRight',
          }"
          @click="dataPosition('topRight')"
          ><span class="textSmall">Top</span><br /><span class="textLarge"
            >Right</span
          ></b-button
        >
      </b-col>
      <b-col class="border-bottom border-dark">
        <b-button
          v-bind:class="{
            'ml_button edit': ProjectLayer.Position != 'middleLeft',
          }"
          @click="dataPosition('middleLeft')"
        >
          <span class="textSmall">Middle</span><br /><span class="textLarge"
            >Left</span
          >
        </b-button>
      </b-col>
      <b-col class="border-left border-right border-bottom border-dark">
        <b-button
          v-bind:class="{
            'mc_button edit': ProjectLayer.Position != 'middleCenter',
          }"
          @click="dataPosition('middleCenter')"
        >
          <span class="textSmall">Middle</span><br /><span class="textLarge"
            >Center</span
          >
        </b-button>
      </b-col>
      <b-col class="border-bottom border-dark">
        <b-button
          v-bind:class="{
            'mr_button edit': ProjectLayer.Position != 'middleRight',
          }"
          @click="dataPosition('middleRight')"
        >
          <span class="textSmall">Middle</span><br /><span class="textLarge"
            >Right</span
          >
        </b-button>
      </b-col>
      <b-col class="border-dark">
        <b-button
          v-bind:class="{
            'bl_button edit': ProjectLayer.Position != 'bottomLeft',
          }"
          @click="dataPosition('bottomLeft')"
        >
          <span class="textSmall">Bottom</span><br /><span class="textLarge"
            >Left</span
          >
        </b-button>
      </b-col>
      <b-col class="border-left border-right border-dark">
        <b-button
          v-bind:class="{
            'bc_button edit': ProjectLayer.Position != 'bottomCenter',
          }"
          @click="dataPosition('bottomCenter')"
        >
          <span class="textSmall">Bottom</span><br /><span class="textLarge"
            >Center</span
          >
        </b-button>
      </b-col>
      <b-col class="border-dark">
        <b-button
          v-bind:class="{
            'br_button edit': ProjectLayer.Position != 'bottomRight',
          }"
          @click="dataPosition('bottomRight')"
        >
          <span class="textSmall">Bottom</span><br /><span class="textLarge"
            >Right</span
          >
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    ProjectLayer: Object,
  },
  data() {
    return {};
  },
  methods: {
    dataPosition(item) {
      this.ProjectLayer.Position = item;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css";
@import "../../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css";
@import "../../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css";
@import "../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css";
@import "../../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css";
@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
.positionBlock {
  width: 264px;
  height: 192px;
  border: 2px solid transparent;
}
.positionBlock .border-dark {
  padding: 0;
}
.positionBlock .border-dark button {
  padding: 0 !important;
  margin: 0 !important;
  height: 65px !important;
  border-radius: 0;
}
.tl_button,
.tr_button,
.tc_button,
.ml_button,
.mr_button,
.mc_button,
.bl_button,
.br_button,
.bc_button {
  border: none !important;
}
.positionBlock .border-dark button:hover {
  border: 2px solid #1d1d1d !important;
  background: #eaeaea;
}

/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
  background-color: #eaeaea !important;
  border: black;
}
.tl_button {
  background-color: transparent !important;
  border: none;
  color: black;
  width: 86px;
  height: 65px;
  height: inherit;
  position: relative;
  padding: 0;
  // right: 8px;
  // bottom: 2px;
}
.tc_button {
  background-color: transparent !important;
  border: none;
  color: black;
  width: 86px;
  height: 65px;
  position: relative;
  // right: 12px;
  // bottom: -1px;
}
.tc_button:hover {
  background-color: #eaeaea !important;
  border: black;
}
.tr_button {
  background-color: transparent !important;
  border: none;
  color: black;
  position: relative;
  width: 86px;
  height: 70px;
}
.tr_button:hover {
  background-color: #eaeaea !important;
  border: black;
}
/*--------------------------------position for edit button css------------------------*/
.tl_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.tl_button.edit {
  background-color: transparent !important;
  border: none;
  color: black;
  width: 86px;
  height: 65px;
  position: relative;
}
.tc_button.edit {
  background-color: transparent !important;
  border: none;
  color: black;
  width: 86px;
  height: 65px;
  position: relative;
}
.tc_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.tr_button.edit {
  background-color: transparent !important;
  border: none;
  color: black;
  position: relative;
  width: 86px;
  height: 65px;
}
.tr_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.ml_button.edit {
  background-color: transparent !important;
  border: none;
  color: black;
  position: relative;
  width: 86px;
  height: 65px;
}
.ml_button.edit:hover {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: #eaeaea !important;
  border: black;
}
.mc_button.edit {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent !important;
  border: none;
  color: black;
}
.mc_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.mr_button.edit {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent !important;
  border: none;
  color: black;
}
.mr_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.bl_button.edit {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent !important;
  border: none;
  color: black;
}
.bl_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.bc_button.edit {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent !important;
  border: none;
  color: black;
}
.bc_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.br_button.edit {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent !important;
  border: none;
  color: black;
}
.br_button.edit:hover {
  background-color: #eaeaea !important;
  border: black;
}
.edit-button {
  color: #007bff !important;
  margin-top: 0px;
  margin-left: 96px;
}
.delete-button {
  margin-top: 5px;
  margin-left: 5px;
}
.input-value {
  display: block;
  background: #f3f3f3 0% 0% no-repeat padding-box;
  // margin-left: 21px;
  padding: 10px;
  margin-top: -5px;
  position: absolute;
  right: -256px;
  border-bottom: 1px solid #929292;
}
.showHide {
  margin-left: 82px;
  margin-top: -32px;
}
.editDelete {
  margin-left: 87px;
  margin-top: -45px;
}
.ml_button {
  background-color: transparent;
  border: none;
  color: black;
  position: relative;
  width: 86px;
  height: 70px;
}
.ml_button:hover {
  position: relative;
  width: 86px;
  height: 70px;
  background-color: #eaeaea;
  border: black;
}
.mc_button {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent;
  border: none;
  color: black;
}
.mc_button:hover {
  background-color: #eaeaea;
  border: black;
}
.mr_button {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent;
  border: none;
  color: black;
}
.mr_button:hover {
  background-color: #eaeaea;
  border: black;
}
.bl_button {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent;
  border: none;
  color: black;
}
.bl_button:hover {
  background-color: #eaeaea;
  border: black;
}
.bc_button {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent;
  border: none;
  color: black;
}
.bc_button:hover {
  background-color: #eaeaea;
  border: black;
}
.br_button {
  position: relative;
  width: 86px;
  height: 65px;
  background-color: transparent;
  border: none;
  color: black;
}
.br_button:hover {
  background-color: #eaeaea;
  border: black;
}

.positionBlock .border-dark button:hover {
  border: 2px solid #1d1d1d !important;
  background: #eaeaea;
}
.border-dark .btn-secondary {
  background: #eaeaea;
  border: 2px solid #1d1d1d;
  min-width: 86px !important;
}
</style>
