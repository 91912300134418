<template>
    <div class="">
        <div class="Que-btn">
            <small
                v-if="reviewItem.length"
                class="text-primary reviewQueueButton"
                @click="reviewQueue"
                >Review queue</small
            >
            <small
                v-if="!reviewItem.length"
                class="text-secondary reviewQueueButton"
                >Review queue</small
            >
            <div v-if="reviewItem.length">
                <b-button
                    rounded
                    class="buttonSelect queueChange ml-2"
                    @click="queuePortalChanges()"
                >
                    Queue changes
                </b-button>
            </div>
            <div v-if="!reviewItem.length">
                <b-button
                    v-if="groupIds.length || portalIds.length"
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queuePortalChanges()"
                >
                    Queue changes
                </b-button>
                <b-button
                    v-if="!groupIds.length || !portalIds.length"
                    rounded
                    class="buttonSelect disableButton queueChange ml-3"
                >
                    Queue changes
                </b-button>
            </div>
        </div>
        <b-row class="access-blk">
            <b-col class="mt-2">
                <div class="card bg-light mb-3 border-grey groups">
                    <div class="card-header">Groups</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchProject && !showSearchIconGroups
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroups"
                                    @mouseover="searchOpenGroups"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchProject && !showSearchIconGroups
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroups"
                                    @mouseover="searchOpenGroups"
                                    >Search</span
                                >
                                <input
                                    ref="groups"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchProject || showSearchIconGroups
                                            ? true
                                            : false
                                    "
                                    v-model="searchProject"
                                    :class="
                                        searchProject || showSearchIconGroups
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseGroups"
                                    @click="closeBarGroups"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    class="pl-3 ml-1"
                                    @click="selectAllGroups"
                                    v-model="allSelected"
                                /><span class="portal-lbl">Name</span>
                            </div>
                            <b-row class="showGroupPortalList">
                                <table
                                    class="table"
                                    striped
                                    id="groupTable"
                                    :per-page="perPage"
                                >
                                    <!-- <thead>
                                    <tr>
                                    
                                    <th scope="col">
                                    </tr>
                                </thead> -->
                                    <tbody :per-page="perPage">
                                        <tr
                                            v-for="(gp,
                                            index) in groupPortalFilteredList"
                                            :key="index"
                                            :value="gp.UserGroupName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            @click="
                                                                checkedGroup(
                                                                    gp.UserGroupID
                                                                )
                                                            "
                                                            :value="
                                                                gp.UserGroupID
                                                            "
                                                            v-model="groupIds"
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                gp.UserGroupName
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesGroups[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            gp.permi &&
                                                                                gp
                                                                                    .permi
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchPortalPageNameModel &&
                                                                                    !showSearchIconGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchPortalPageNameModel &&
                                                                                    !showSearchIconGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="groupInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width359"
                                                                                v-if="
                                                                                    searchPortalPageNameModel ||
                                                                                    showSearchIconGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchPortalPageNameModel
                                                                                "
                                                                                :class="
                                                                                    searchPortalPageNameModel ||
                                                                                    showSearchIconGroupsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseChildGroupsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarGroupsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(port,
                                                                        index) in $options.filters.filterByProperty(
                                                                            gp.permi,
                                                                            'PortalPage',
                                                                            'PortalPageName',
                                                                            searchPortalPageNameModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            port
                                                                                .PortalPage
                                                                                .PortalPageName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox access-icon"
                                                                                v-model="
                                                                                    userGroupPortals[
                                                                                        gp
                                                                                            .UserGroupID
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    port
                                                                                        .PortalPage
                                                                                        .PortalPageID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        gp.UserGroupID,
                                                                                        port
                                                                                            .PortalPage
                                                                                            .PortalPageID,
                                                                                        $event,
                                                                                        port,
                                                                                        false,
                                                                                        port.PortalPermissionID
                                                                                    )
                                                                                "
                                                                                v-if="
                                                                                    port.IsIncluded &&
                                                                                        userGroupPortalsExclude[
                                                                                            gp
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            port
                                                                                                .PortalPage
                                                                                                .PortalPageID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                            />
                                                                            <input
                                                                                class="access-icon"
                                                                                type="checkbox"
                                                                                v-model="
                                                                                    userGroupPortals[
                                                                                        gp
                                                                                            .UserGroupID
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    port
                                                                                        .PortalPage
                                                                                        .PortalPageID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        gp.UserGroupID,
                                                                                        port
                                                                                            .PortalPage
                                                                                            .PortalPageID,
                                                                                        $event,
                                                                                        port,
                                                                                        true,
                                                                                        port.PortalPermissionID
                                                                                    )
                                                                                "
                                                                                :disabled="
                                                                                    userGroupPortalsExclude[
                                                                                        gp
                                                                                            .UserGroupID
                                                                                    ].indexOf(
                                                                                        port
                                                                                            .PortalPage
                                                                                            .PortalPageID
                                                                                    ) >
                                                                                        -1 &&
                                                                                        !port.IsIncluded
                                                                                "
                                                                                v-if="
                                                                                    !port.IsIncluded ||
                                                                                        userGroupPortalsExclude[
                                                                                            gp
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            port
                                                                                                .PortalPage
                                                                                                .PortalPageID
                                                                                        ) >
                                                                                            -1
                                                                                "
                                                                            />
                                                                            <span
                                                                                v-if="
                                                                                    port.IsIncluded &&
                                                                                        userGroupPortalsExclude[
                                                                                            gp
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            port
                                                                                                .PortalPage
                                                                                                .PortalPageID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                                class="access-icon-wrapper ml-4 mt-1"
                                                                            >
                                                                                <img
                                                                                    src="../../../assets/exclude_black.svg"
                                                                                />
                                                                            </span>
                                                                            <span
                                                                                v-if="
                                                                                    !port.IsIncluded ||
                                                                                        userGroupPortalsExclude[
                                                                                            gp
                                                                                                .UserGroupID
                                                                                        ].indexOf(
                                                                                            port
                                                                                                .PortalPage
                                                                                                .PortalPageID
                                                                                        ) >
                                                                                            -1
                                                                                "
                                                                            >
                                                                                <span
                                                                                    v-if="
                                                                                        !port.IsIncluded &&
                                                                                            userGroupPortalsExclude[
                                                                                                gp
                                                                                                    .UserGroupID
                                                                                            ].indexOf(
                                                                                                port
                                                                                                    .PortalPage
                                                                                                    .PortalPageID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="access-icon-wrapper ml-4"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            gp.UserGroupID,
                                                                                            port
                                                                                                .PortalPage
                                                                                                .PortalPageID,
                                                                                            'Groups',
                                                                                            port
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        class="access-icon"
                                                                                        src="../../../assets/exclude_red.svg"
                                                                                    />
                                                                                </span>
                                                                                <span
                                                                                    v-if="
                                                                                        port.IsIncluded &&
                                                                                            userGroupPortalsExclude[
                                                                                                gp
                                                                                                    .UserGroupID
                                                                                            ].indexOf(
                                                                                                port
                                                                                                    .PortalPage
                                                                                                    .PortalPageID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="access-icon-wrapper ml-4"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            gp.UserGroupID,
                                                                                            port
                                                                                                .PortalPage
                                                                                                .PortalPageID,
                                                                                            'Groups',
                                                                                            port
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        class="access-icon"
                                                                                        src="../../../assets/exclude_black.svg"
                                                                                    />
                                                                                </span>
                                                                            </span>
                                                                            <span
                                                                                class="marginLeft25 portal-lbl slash-text"
                                                                                >{{
                                                                                    port
                                                                                        .PortalPage
                                                                                        .PortalPageName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                    <!-- <tr
                                        v-for="(port,index) in gp.permi"
                                        :key="index"
                                        :value="port.PortalPage.PortalPageName"
                                    >
                                        <td class="">
                                        <input
                                            type="checkbox"
                                            checked
                                        />
                                        <span
                                            style="margin-left: 25px"
                                            >{{ port.PortalPage.PortalPageName }}</span
                                        >
                                        </td>
                                    </tr> -->
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="groupPortalList"
                    @changePage="onChangeGroupPortal"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div
                        class="card-footer"
                        v-if="pageOfGroupPortal.length > 0"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="groupTable"
                            @change="collapseAllGroups"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>

            <b-col class="mt-2 pl-2 ml-5">
                <div class="card bg-light mb-3 border-grey maxWidth499 portal">
                    <div class="card-header">Portals</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchProjectDatasets &&
                                        !showSearchportal
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenPortal"
                                    @mouseover="searchOpenPortal"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchProjectDatasets &&
                                        !showSearchportal
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenPortal"
                                    @mouseover="searchOpenPortal"
                                    >Search</span
                                >
                                <input
                                    ref="portal"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchProjectDatasets ||
                                        showSearchportal
                                            ? true
                                            : false
                                    "
                                    v-model="searchProjectDatasets"
                                    :class="
                                        searchProjectDatasets ||
                                        showSearchportal
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchClosePortal"
                                    @click="closeBarPortal"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header exclusion">
                                <input
                                    type="checkbox"
                                    class="slash-checkbox"
                                    @click="selectAllPortals"
                                    v-model="allSelectedPortals"
                                />
                                <h5>Exclude</h5>
                                <h5>Dataset Name</h5>
                            </div>
                            <b-row class="showGroupPortalList">
                                <table class="table" id="PortalTable" striped>
                                    <tbody>
                                        <tr
                                            v-for="(dp,
                                            index) in portalFilteredList"
                                            :key="index"
                                            :value="dp.PortalPageName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            v-model="portalIds"
                                                            :value="
                                                                dp.PortalPageID
                                                            "
                                                            @click="
                                                                checkedPortal(
                                                                    dp.PortalPageID
                                                                )
                                                            "
                                                            :disabled="
                                                                portalIdsExclude.indexOf(
                                                                    dp.PortalPageID
                                                                ) > -1
                                                            "
                                                        />

                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span
                                                                class="portal-lbl"
                                                                >{{
                                                                    dp.PortalPageName
                                                                }}</span
                                                            ></b-button
                                                        >
                                                        <span
                                                            v-if="
                                                                portalIdsExclude.indexOf(
                                                                    dp.PortalPageID
                                                                ) == -1
                                                            "
                                                            class="pl-4 ml-4 mt-2 icon-include"
                                                            @click="
                                                                checkedPortalExclude(
                                                                    dp.PortalPageID
                                                                )
                                                            "
                                                        >
                                                            <img
                                                                src="../../../assets/exclude_black.svg"
                                                            />
                                                        </span>
                                                        <span
                                                            v-if="
                                                                portalIdsExclude.indexOf(
                                                                    dp.PortalPageID
                                                                ) > -1
                                                            "
                                                            class="pl-4 ml-4 mt-2 icon-include"
                                                            @click="
                                                                checkedPortalExclude(
                                                                    dp.PortalPageID
                                                                )
                                                            "
                                                        >
                                                            <img
                                                                src="../../../assets/exclude_red.svg"
                                                            />
                                                        </span>
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesPortals[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            dp.permi &&
                                                                                dp
                                                                                    .permi
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchPortalUserGroupNameModel &&
                                                                                    !showSearchPortalInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenPortalInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenPortalInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchPortalUserGroupNameModel &&
                                                                                    !showSearchPortalInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenPortalInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenPortalInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="portalInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width359"
                                                                                v-if="
                                                                                    searchPortalUserGroupNameModel ||
                                                                                    showSearchPortalInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchPortalUserGroupNameModel
                                                                                "
                                                                                :class="
                                                                                    searchPortalUserGroupNameModel ||
                                                                                    showSearchPortalInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseportalInner
                                                                                "
                                                                                @click="
                                                                                    closeBarportalInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                            <!-- <span class="border-right ml-2">&nbsp;</span> -->
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(portg,
                                                                        index) in $options.filters.filterByProperty(
                                                                            dp.permi,
                                                                            '',
                                                                            'UserGroupName',
                                                                            searchPortalUserGroupNameModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            portg.UserGroupName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox access-icon"
                                                                                v-model="
                                                                                    portalUserGroups[
                                                                                        dp
                                                                                            .PortalPageID
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    portg.UserGroupID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        portg.UserGroupID,
                                                                                        dp.PortalPageID,
                                                                                        $event,
                                                                                        portg,
                                                                                        false,
                                                                                        portg.PortalPermissionID
                                                                                    )
                                                                                "
                                                                                v-if="
                                                                                    portg.IsIncluded ||
                                                                                        portalUserGroups[
                                                                                            dp
                                                                                                .PortalPageID
                                                                                        ].indexOf(
                                                                                            portg.UserGroupID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                            />
                                                                            <input
                                                                                class="access-icon"
                                                                                type="checkbox"
                                                                                v-model="
                                                                                    portalUserGroups[
                                                                                        dp
                                                                                            .PortalPageID
                                                                                    ]
                                                                                "
                                                                                :value="
                                                                                    portg.UserGroupID
                                                                                "
                                                                                @click="
                                                                                    childCheck(
                                                                                        portg.UserGroupID,
                                                                                        dp.PortalPageID,
                                                                                        $event,
                                                                                        portg,
                                                                                        true,
                                                                                        portg.PortalPermissionID
                                                                                    )
                                                                                "
                                                                                :disabled="
                                                                                    portalUserGroupsExclude[
                                                                                        dp
                                                                                            .PortalPageID
                                                                                    ].indexOf(
                                                                                        portg.UserGroupID
                                                                                    ) >
                                                                                        -1 &&
                                                                                        !portg.IsIncluded
                                                                                "
                                                                                v-if="
                                                                                    portalUserGroupsExclude[
                                                                                        dp
                                                                                            .PortalPageID
                                                                                    ].indexOf(
                                                                                        portg.UserGroupID
                                                                                    ) >
                                                                                        -1 &&
                                                                                        !portg.IsIncluded
                                                                                "
                                                                            />
                                                                            <span
                                                                                v-if="
                                                                                    portg.IsIncluded &&
                                                                                        portalUserGroupsExclude[
                                                                                            dp
                                                                                                .PortalPageID
                                                                                        ].indexOf(
                                                                                            portg.UserGroupID
                                                                                        ) ==
                                                                                            -1
                                                                                "
                                                                                class="access-icon-wrapper mt-1 ml-4"
                                                                            >
                                                                                <img
                                                                                    class="access-icon"
                                                                                    src="../../../assets/exclude_black.svg"
                                                                                />
                                                                            </span>
                                                                            <span
                                                                                v-if="
                                                                                    !portg.IsIncluded ||
                                                                                        portalUserGroupsExclude[
                                                                                            dp
                                                                                                .PortalPageID
                                                                                        ].indexOf(
                                                                                            portg.UserGroupID
                                                                                        ) >
                                                                                            -1
                                                                                "
                                                                            >
                                                                                <span
                                                                                    v-if="
                                                                                        !portg.IsIncluded &&
                                                                                            portalUserGroupsExclude[
                                                                                                dp
                                                                                                    .PortalPageID
                                                                                            ].indexOf(
                                                                                                portg.UserGroupID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="access-icon-wrapper ml-4"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            portg.UserGroupID,
                                                                                            dp.PortalPageID,
                                                                                            'Portal',
                                                                                            portg
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        class="access-icon"
                                                                                        src="../../../assets/exclude_red.svg"
                                                                                    />
                                                                                </span>
                                                                                <span
                                                                                    v-if="
                                                                                        portg.IsIncluded &&
                                                                                            portalUserGroupsExclude[
                                                                                                dp
                                                                                                    .PortalPageID
                                                                                            ].indexOf(
                                                                                                portg.UserGroupID
                                                                                            ) >
                                                                                                -1
                                                                                    "
                                                                                    class="access-icon-wrapper mt-1 ml-4"
                                                                                    @click="
                                                                                        childExcludeCheck(
                                                                                            portg.UserGroupID,
                                                                                            dp.PortalPageID,
                                                                                            'Portal',
                                                                                            portg
                                                                                        )
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                        class="access-icon"
                                                                                        src="../../../assets/exclude_black.svg"
                                                                                    />
                                                                                </span>
                                                                            </span>
                                                                            <span
                                                                                class="marginLeft25 portal-lbl slash-text"
                                                                                >{{
                                                                                    portg.UserGroupName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="portalGroupList"
                    @changePage="onChangePortalGroupPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div
                        class="card-footer"
                        v-if="pageOfPortalGroup.length > 0"
                    >
                        <b-pagination
                            v-model="currentPagePortal"
                            :total-rows="rowsPortal"
                            :per-page="perPage"
                            aria-controls="PortalTable"
                            @change="collapseAllPortals"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="portal-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="d-block text-center" v-if="!reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                There is no item to review.
            </div>
            <div class="d-block" v-if="reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <div class="text-center">
                    <h4 class="my-2">Change queue</h4>
                    <small
                        >Review {{ reviewItem.length }} changes pending before
                        commiting the changes.</small
                    >
                </div>
                <div class="my-3 tblScroll">
                    <table class="table table-striped dataSetModal">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Access</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr
                                v-for="(item, index) in reviewItem"
                                :key="index"
                            >
                                <td>{{ item.groupName }}</td>
                                <td>
                                    <span
                                        v-if="!item.isIncluded && item.isActive"
                                    >
                                        <img
                                            src="../../../assets/exclude_red.svg"
                                        />
                                    </span>

                                    <span
                                        v-else-if="
                                            item.isActive && item.isIncluded
                                        "
                                    >
                                        <img src="../../../assets/icon_tick.svg"
                                    /></span>

                                    <span v-else>
                                        <span v-if="!item.isActive">
                                            <img src="../../../assets/close.svg"
                                        /></span>
                                        <span
                                            v-if="
                                                item.isIncluded &&
                                                    item.hasOwnProperty(
                                                        isIncluded
                                                    )
                                            "
                                        >
                                            <img
                                                src="../../../assets/exclude_black.svg"
                                            />
                                        </span>
                                    </span>
                                </td>
                                <td>{{ item.portalName }}</td>
                                <td
                                    class="deleteCell"
                                    @click="
                                        delQueue(
                                            index,
                                            item.groupId,
                                            item.portalId
                                        )
                                    "
                                >
                                    Delete from queue&nbsp;
                                    <img src="../../../assets/close.svg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 text-center">
                    <a class="text-primary" @click="clearQueue">Clear queue </a>
                    <button class="buttonSelect ml-3" @click="commitChanges">
                        Commit changes
                    </button>
                </div>
            </div>
        </b-modal>

        <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../../assets/success_tick.svg" class="mr-3"/>
                <span v-if="reviewItem.length"> <b>Changes queued</b> <br/>{{successToastValue}} <br/>All the requested changes to be inserted into the queue.</span>
                 <span v-if="!reviewItem.length">{{successToastValue}}</span>
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../../assets/failure_cross.svg" class="mr-3"/>
                {{failureToastValue}}
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
    </div>
</template>

<script>
// import router from "../../router";

import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import { mapActions, mapMutations } from 'vuex';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    props: ['groupCreated'],
    watch: {
        groupCreated: function(newVal, oldVal) {
            // watch it
            this.getGroupPortal();
            this.getPortalGroup();
        },
    },
    data() {
        return {
            searchText: true,
            searchTextData: true,
            showTextData: false,
            customLabels,
            showText: false,
            searchProject: '',
            searchProjectDatasets: '',
            allSelected: false,
            allSelectedPortals: false,
            perPage: 5,
            groupPortalList: [],
            groupPortalListPage: [],
            pageOfGroupPortal: [],
            portalGroupList: [],
            userGroupPortals: {},
            userGroupPortalsExclude: {},
            portalUserGroups: {},
            portalUserGroupsExclude: {},
            portalGroupListFiltered: [],
            pageOfPortalGroup: [],
            portalIdsExclude: [],
            groupIds: [],
            portalIds: [],
            reviewItem: [],
            // successToast: false,
            // failureToast:false,
            // successToastValue:'',
            // failureToastValue:'',
            showPage: false,
            thisfilename: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            currentPage: 1,
            currentPagePortal: 1,
            searchPortalPageNameInput: true,
            searchPortalPageNameModel: '',
            searchPortalUserGroupNameInput: true,
            searchPortalUserGroupNameModel: '',
            collapseStatesGroups: [],
            collapseStatesPortals: [],
            searchCloseGroups: false,
            showSearchIconGroups: false,
            showSearchIconGroupsInner: false,
            searchCloseChildGroupsInner: false,
            searchClosePortal: false,
            showSearchportal: false,
            searchCloseportalInner: false,
            showSearchPortalInner: false,
        };
    },
    created() {
        this.getGroupPortal();
        this.getPortalGroup();
    },
    mounted() {
        //  this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        pagePermit() {
            this.showPage = false;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('GAPortal')) {
                    vm.showPage = true;
                }
            });
            this.$emit('clickedPortal', this.showPage);
        },
        getGroupsTool() {
            managementService.getGroupsTool().then((res) => {
                this.groupToolsList = res.data;
            });
        },
        reviewQueue() {
            this.$refs['portal-modal'].show();
        },
        searchOpenGroups() {
            this.searchCloseGroups = !this.searchCloseGroups;
            this.showSearchIconGroups = true;
            this.$nextTick(() => {
                this.$refs.groups.focus();
            });
        },
        closeBarGroups() {
            this.searchCloseGroups = !this.searchCloseGroups;
            this.showSearchIconGroups = false;
            this.searchProject = null;
        },

        closeBarGroupsInner() {
            this.searchCloseChildGroupsInner = !this
                .searchCloseChildGroupsInner;
            this.showSearchIconGroupsInner = false;
            this.searchPortalPageNameModel = null;
        },
        searchOpenGroupsInner(index) {
            this.searchCloseChildGroupsInner = !this
                .searchCloseChildGroupsInner;
            this.showSearchIconGroupsInner = true;
            this.$nextTick(() => {
                this.$refs.groupInner[index].focus();
            });
        },
        searchOpenPortal() {
            this.searchClosePortal = !this.searchClosePortal;
            this.showSearchportal = true;
            this.$nextTick(() => {
                this.$refs.portal.focus();
            });
        },
        closeBarPortal() {
            this.searchClosePortal = !this.searchClosePortal;
            this.showSearchportal = false;
            this.searchProjectDatasets = null;
        },

        closeBarportalInner() {
            this.searchCloseportalInner = !this.searchCloseportalInner;
            this.showSearchPortalInner = false;
            this.searchPortalUserGroupNameModel = null;
        },
        searchOpenPortalInner(index) {
            this.searchCloseportalInner = !this.searchCloseportalInner;
            this.showSearchPortalInner = true;
            this.$nextTick(() => {
                this.$refs.portalInner[index].focus();
            });
        },

        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                if (item.PortalPermissionID) {
                    data.push({
                        UserGroupID: item.groupId,
                        PortalPageID: item.portalId,
                        isActive: item.isActive,
                        IsIncluded: item.isIncluded,
                        PortalPermissionID: item.PortalPermissionID,
                    });
                } else {
                    data.push({
                        UserGroupID: item.groupId,
                        PortalPageID: item.portalId,
                        isActive: item.isActive,
                        IsIncluded: item.isIncluded,
                    });
                }
            });

            managementService
                .addOrUpdateUserGroupPortalPermission(data)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.setToastMessage({
                            showSuccess: true,
                            showFailure: false,
                            successToastMessage:
                                'Association done successfully.',
                            failureToastMessage: null,
                            messageTitle: null,
                        });
                        this.reviewItem = [];
                        this.getGroupPortal();
                        this.getPortalGroup();
                        this.groupIds = [];
                        this.portalIds = [];
                        this.portalIdsExclude = [];
                        this.$refs['portal-modal'].hide();
                    } else if (res.data.StatusCode === 500) {
                        this.$refs['portal-modal'].hide();
                        this.setToastMessage({
                            showSuccess: false,
                            showFailure: true,
                            successToastMessage: null,
                            failureToastMessage: res.data.Message,
                            messageTitle: null,
                        });
                    }
                })
                .catch((err) => {
                    if (err) {
                        this.$refs['portal-modal'].hide();

                        this.setToastMessage({
                            showSuccess: false,
                            showFailure: true,
                            successToastMessage: null,
                            failureToastMessage: 'Something went wrong',
                            messageTitle: null,
                        });
                    }
                });
        },
        delQueue(index, groupID, portalID) {
            let removeItem = this.reviewItem[index];
            this.reviewItem.splice(index, 1);

            for (let i = 0; i < this.groupPortalList.length; i++) {
                if (this.groupPortalList[i].permi) {
                    for (
                        let j = 0;
                        j < this.groupPortalList[i].permi.length;
                        j++
                    ) {
                        if (removeItem.IsIncluded) {
                            if (
                                this.groupPortalList[i].permi[j].PortalPageID ==
                                    removeItem.portalId &&
                                this.groupPortalList[i].permi[j].UserGroupID ==
                                    removeItem.groupId
                            ) {
                                this.groupPortalList[i].permi[
                                    j
                                ].IsIncluded = false;
                            }
                        }
                    }
                }
            }

            for (let i = 0; i < this.portalGroupList.length; i++) {
                if (this.portalGroupList[i].permi) {
                    for (
                        let j = 0;
                        j < this.portalGroupList[i].permi.length;
                        j++
                    ) {
                        if (removeItem.IsIncluded) {
                            if (
                                this.portalGroupList[i].permi[j].PortalPageID ==
                                    removeItem.portalId &&
                                this.portalGroupList[i].permi[j].UserGroupID ==
                                    removeItem.groupId
                            ) {
                                this.portalGroupList[i].permi[
                                    j
                                ].IsIncluded = false;
                            }
                        }
                    }
                }
            }
            let groupIdFound = this.reviewItem.findIndex(
                (item) => item.groupId == removeItem.groupId
            );
            if (groupIdFound == -1) {
                let groupId = this.groupIds.findIndex(
                    (item) => item == removeItem.groupId
                );
                this.groupIds.splice(groupId, 1);
            }
            let portalIdFound = this.reviewItem.findIndex(
                (item) => item.portalId == removeItem.portalId
            );
            if (portalIdFound == -1) {
                let portalId = this.portalIds.findIndex(
                    (item) => item == removeItem.portalId
                );
                this.portalIds.splice(portalId, 1);
                this.portalIdsExclude.splice(portalId, 1);
            }
            if (this.userGroupPortals[groupID].indexOf(portalID) == -1) {
                this.userGroupPortals[groupID].push(portalID);
            }
            if (this.portalUserGroups[portalID].indexOf(groupID) == -1) {
                this.portalUserGroups[portalID].push(groupID);
            }

            /*if(!this.reviewItem.length){
                this.$refs["portal-modal"].hide();
            }*/
        },
        clearQueue() {
            this.groupIds = [];
            this.portalIds = [];
            this.reviewItem = [];
            this.portalIdsExclude = [];
            this.userGroupPortals = {};
            this.portalUserGroups = {};
            this.getGroupPortal();
            this.getPortalGroup();
            this.$refs['portal-modal'].hide();
        },
        queuePortalChanges() {
            if (
                (this.groupIds.length && this.portalIds.length) ||
                this.reviewItem.length
            ) {
                if (this.groupIds.length && this.portalIds.length) {
                    // this.reviewItem=[]
                    let selectedGroups = {};
                    this.groupIds.forEach((groupItem) => {
                        let selectedPortal = [];
                        this.portalIds.forEach((portalItem) => {
                            selectedPortal.push(portalItem);
                        });
                        selectedGroups[groupItem] = selectedPortal;
                    });
                    for (let props in selectedGroups) {
                        let groupName = this.groupPortalList.filter((item) => {
                            return item.UserGroupID == props;
                        });
                        for (let i = 0; i < selectedGroups[props].length; i++) {
                            let portalName = this.portalGroupList.filter(
                                (subItem) => {
                                    return (
                                        subItem.PortalPageID ==
                                        selectedGroups[props][i]
                                    );
                                }
                            );
                            let excludePortals = this.portalIdsExclude.filter(
                                (subItem) => {
                                    return subItem == selectedGroups[props][i];
                                }
                            );
                            let exist = this.reviewItem.findIndex(
                                (item) =>
                                    item.groupId == props &&
                                    item.portalId == selectedGroups[props][i]
                            );
                            if (exist === -1) {
                                if (excludePortals.length) {
                                    this.reviewItem.push({
                                        groupId: props,
                                        portalId: selectedGroups[props][i],
                                        groupName: groupName[0].UserGroupName,
                                        portalName:
                                            portalName[0].PortalPageName,
                                        isActive: true,
                                        isIncluded: false,
                                    });
                                } else {
                                    this.reviewItem.push({
                                        groupId: props,
                                        portalId: selectedGroups[props][i],
                                        groupName: groupName[0].UserGroupName,
                                        portalName:
                                            portalName[0].PortalPageName,
                                        isActive: true,
                                        isIncluded: true,
                                    });
                                }
                            }
                        }
                    }
                    /* this.groupIds.forEach((groupItem)=>{
                        let groupName = this.groupPortalList.filter((item)=>{
                            return item.UserGroupID == groupItem;
                        })
                        //
                        this.portalIds.forEach((portalItem)=>{
                            let portalName = this.portalGroupList.filter((subItem)=>{
                                return subItem.PortalPermissionID == portalItem;
                            })

                            this.reviewItem.push({
                                "groupId":groupItem,
                                "portalId":portalItem,
                                "groupName": groupName[0].UserGroupName,
                                "portalName": portalName[0].PortalPage.PortalPageName,
                                "isActive": true
                            })
                            this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.groupId)))
                            .map(id=>{
                                return this.reviewItem.find(a=> a.groupId ===id)
                            })
                        })
                    })*/
                }

                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';
                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    successToastMessage: null,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: null,
                });
            }
        },
        // closeSuccessToast(){
        //     this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        hideModal() {
            this.$refs['portal-modal'].hide();
        },
        selectAllGroups(gp) {
            this.groupIds = [];
            if (!this.allSelected) {
                for (gp in this.groupPortalList) {
                    this.groupIds.push(this.groupPortalList[gp].UserGroupID);
                }
            }
        },
        selectAllPortals(dp) {
            this.portalIds = [];
            if (!this.allSelectedPortals) {
                for (dp in this.portalGroupList) {
                    this.portalIds.push(this.portalGroupList[dp].PortalPageID);
                }
            }
        },
        checkedGroup(id) {
            if (this.groupIds.indexOf(id) > -1) {
                this.groupIds.splice(this.groupIds.indexOf(id), 1);
                this.allSelected = false;
            } else {
                this.groupIds.push(id);
            }
        },
        checkedPortal(id) {
            if (this.portalIds.indexOf(id) > -1) {
                this.portalIds.splice(this.portalIds.indexOf(id), 1);

                this.allSelectedPortals = false;
            } else {
                this.portalIds.push(id);
            }
        },
        checkedPortalExclude(id) {
            if (this.portalIds.indexOf(id) > -1) {
                this.portalIds.splice(this.portalIds.indexOf(id), 1);
                this.portalIdsExclude.splice(this.portalIds.indexOf(id), 1);
                this.allSelectedPortals = false;
            } else {
                this.portalIds.push(id);
                this.portalIdsExclude.push(id);
            }
        },
        childCheck(
            groupId,
            portalId,
            event,
            item,
            excluded,
            portalpermissionid
        ) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.groupId == groupId && item.portalId == portalId;
            });

            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    if (excluded) {
                        this.reviewItem[unCheckedItem].isActive = true;
                    } else {
                        this.reviewItem.splice(unCheckedItem, 1);
                    }
                } else {
                    this.reviewItem[unCheckedItem].isActive = false;
                }
            } else {
                let groupName = this.groupPortalList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                /*let portalName = this.portalGroupList.filter((subItem)=>{
                    return subItem.PortalPageID == portalId;
                })*/
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return (
                            item.UserGroupID == groupId &&
                            item.PortalId == portalId
                        );
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            groupId: groupId,
                            portalId: portalId,
                            groupName: groupName[0].UserGroupName,
                            portalName: item.PortalPage.PortalPageName,
                            isActive: false,
                            PortalPermissionID: portalpermissionid,
                        });
                    }
                }
            }
        },
        childExcludeCheck(groupId, portalId, type, item) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.groupId == groupId && item.portalId == portalId;
            });
            if (unCheckedItem > -1 && item.IsIncluded) {
                this.reviewItem.splice(unCheckedItem, 1);
            } else {
                let groupName = this.groupPortalList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                let alreadyInserted = this.reviewItem.findIndex((item) => {
                    return (
                        item.UserGroupID == groupId && item.PortalId == portalId
                    );
                });
                if (alreadyInserted == -1) {
                    this.reviewItem.push({
                        groupId: groupId,
                        portalId: portalId,
                        groupName: groupName[0].UserGroupName,
                        portalName: item.PortalPage.PortalPageName,
                        isIncluded: true,
                        isActive: false,
                    });
                }
            }
            if (type == 'Groups') {
                item.IsIncluded = !item.IsIncluded;
                if (this.userGroupPortals[groupId].indexOf(portalId) > -1) {
                    this.userGroupPortals[groupId].splice(
                        this.userGroupPortals[groupId].indexOf(portalId),
                        1
                    );
                } else {
                    this.userGroupPortals[groupId].push(portalId);
                }
            }
            if (type == 'Portal') {
                item.IsIncluded = !item.IsIncluded;
                if (this.portalUserGroups[portalId].indexOf(groupId) > -1) {
                    this.portalUserGroups[portalId].splice(
                        this.portalUserGroups[portalId].indexOf(groupId),
                        1
                    );
                } else {
                    this.portalUserGroups[portalId].push(groupId);
                }
            }
        },
        getGroupPortal() {
            managementService.getGroupPortal().then((res) => {
                this.groupPortalList = res.data;
                this.groupPortalList.forEach((item) => {
                    if (item.permi) {
                        let temp = [];
                        let tempExclude = [];
                        item.permi.forEach((subItem) => {
                            temp.push(subItem.PortalPageID);
                            if (!subItem.IsIncluded) {
                                tempExclude.push(subItem.PortalPageID);
                            }
                        });
                        this.userGroupPortals[item.UserGroupID] = temp;
                        this.userGroupPortalsExclude[
                            item.UserGroupID
                        ] = tempExclude;
                    }
                });
                this.groupPortalListPage = res.data;
            });
        },
        getPortalGroup() {
            managementService.getPortalGroup().then((res) => {
                this.portalGroupList = res.data;
                this.portalGroupList.forEach((item) => {
                    if (item.permi) {
                        let temp = [];
                        let tempExclude = [];
                        item.permi.forEach((subItem) => {
                            temp.push(subItem.UserGroupID);
                            if (!subItem.IsIncluded) {
                                tempExclude.push(subItem.UserGroupID);
                            }
                        });
                        this.portalUserGroups[item.PortalPageID] = temp;
                        this.portalUserGroupsExclude[
                            item.PortalPageID
                        ] = tempExclude;
                    }
                });
                this.portalGroupListFiltered = res.data;
            });
        },
        collapseAllGroups() {
            this.collapseStatesGroups = this.groupPortalList.map(
                (x) => (x.open = false)
            );
        },
        collapseAllPortals() {
            this.collapseStatesPortals = this.portalGroupList.map(
                (x) => (x.open = false)
            );
        },
        onChangeGroupPortal(pageOfGroupPortal) {
            this.pageOfGroupPortal = pageOfGroupPortal;
        },
        addSearchText() {
            if (this.searchProject.length == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextData() {
            if (this.searchProjectDatasets.length == 0) {
                this.searchTextData = true;
                this.showTextData = false;
            }
        },
        removeSearchTextData() {
            this.searchTextData = false;
            this.showTextData = true;
        },

        onChangePortalGroupPage(pageOfPortalGroup) {
            this.pageOfPortalGroup = pageOfPortalGroup;
        },
    },
    computed: {
        groupPortalFilteredList() {
            // return this.pageOfGroupPortal.filter((post) => {
            //     return post.UserGroupName.toLowerCase().includes(
            //     this.searchProject.toLowerCase()
            // );
            // });
            let vm = this;
            if (vm.searchProject) {
                // return vm.groupUserList.filter((post) => {
                //     return (
                //         post.UserGroupName.toLowerCase().includes(vm.searchProject.toLowerCase())
                //     );
                // });
                vm.pageOfGroupPortal = vm.groupPortalListPage.filter((post) => {
                    return post.UserGroupName.toLowerCase().includes(
                        vm.searchProject.toLowerCase()
                    );
                });
            } else {
                vm.pageOfGroupPortal = vm.groupPortalListPage;
            }
            return vm.pageOfGroupPortal.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
        portalFilteredList() {
            let vm = this;
            if (vm.searchProjectDatasets) {
                vm.pageOfPortalGroup = vm.portalGroupListFiltered.filter(
                    (post) => {
                        return post.PortalPageName.toLowerCase().includes(
                            vm.searchProjectDatasets.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfPortalGroup = vm.portalGroupListFiltered;
            }
            return vm.pageOfPortalGroup.slice(
                (vm.currentPagePortal - 1) * vm.perPage,
                vm.currentPagePortal * vm.perPage
            );
        },
        rows() {
            return this.pageOfGroupPortal.length;
        },
        rowsPortal() {
            return this.pageOfPortalGroup.length;
        },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   text-align: left;
//   span {
//     margin-left: 48px;
//     font-family: var(--ffr);
//   font-size: 16px;
//   color: #373A3C;
//   font-weight: normal;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
.portal .accordion-button {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
    span {
        margin-left: 10px;
        padding-left: 80px;
        display: block;
        width: 310px;
        // margin-top: -25px;
    }
}
.icon-include {
    position: absolute;
    top: 2px;
}
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
.portal button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
    //width: 300px;
}
.groups button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/blackminus.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   width: 388px;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
// .paginationStyle {
//   border: none;
//   /* margin: 0px auto !important; */
//   width: 200px;
//   margin: 0px;
//   padding: 0px;
//   display: contents !important;
// }
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
    color: #006395 !important;
}
// input.test {
//   width: 411px;
// }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 407px;
}
// .buttonSelect.queueChange{
//     position: absolute;
//     right: 20px;
//     top: -72px;
// }
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .row1 {
        border-bottom: 3px solid #eaeaea;
        // padding-top: 10px;
        // h6.blue {
        //   color: #006395 !important;
        // }
    }

    .header {
        padding: 10px 15px;
        border-bottom: 3px solid #1d1d1d;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
// .reviewQueueButton{
//     position: absolute;
//     top:-58px;
//     right:175px;
//     cursor: pointer;
// }

.model_style {
    width: 900px !important;
}
table.dataSetModal tr:first-child {
    background: white;
}
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
.userrightmanagement .width359 {
    width: 359px !important;
}
.access-icon-wrapper {
    width: 20px !important;
    position: relative;
    float: left;
}
.access-icon {
    position: relative;
    float: left;
}
</style>
