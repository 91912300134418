<template>
    <div>
        <li class="popupblock">
            <b-dropdown-header id="dropdown-header-label">
                <img src="../assets/user.svg" />
            </b-dropdown-header>
            <div>
                <p class="user">{{ userDetails.name }}</p>
                <p class="mailid">{{ userDetails.username }}</p>
                <b-button
                    variant="outline-primary mt-4 manage-btn"
                    @click="manageAccount"
                    >Manage account</b-button
                >
            </div>
            <div class="mt-4">
                <b-dropdown-divider></b-dropdown-divider>
                <button class="portal" @click="goToPortalSetting">
                    <img class="my-2 pb-1" src="../assets/settings.svg" />
                    Portal settings
                </button>
                <b-dropdown-divider></b-dropdown-divider>
                <b-button class="signout my-3" @click="SignOut"
                    >Sign out</b-button
                >
                <b-dropdown-divider></b-dropdown-divider>
                <div class="info-footer-wrapper">
                    <div class="info-footer">
                        <div>
                            <a class="linkText" @click="showAboutModal"
                                >About</a
                            >
                        </div>
                        <div>
                            <!-- <a
                                class="license"
                                href="https://ghdadaptresourcedev.blob.core.windows.net/resources/ADAPT_Rider_20220620.pdf"
                                target="_blank"
                                >License agreement</a
                            > -->
                        </div>
                        <div>
                            <a class="linkText" @click="showContactModal"
                                >Contact support</a
                            >
                        </div>
                        <div>
                            <span>&#169; GHD {{ currentYear }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <b-modal
            v-bind:no-close-on-backdrop="false"
            ref="contact-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center py-2 modelFont contactModalHeight">
                <div class="text-right cursor-pointer">
                    <img
                        class="rounded-circle"
                        src="../assets/close_icon.svg"
                        @click="hideContactModal"
                    />
                </div>
                <h5><img class="adaptLogo" src="../assets/favicon.svg" /></h5>
                <h2 class="mt-3 contactSupport">Contact support</h2>
                <p class="contactText mt-4">
                    Please send all inquiries to:
                    <a class="contactText" href="mailto: adapt-support@ghd.com"
                        >adapt-support@ghd.com</a
                    >
                </p>
            </div>
        </b-modal>
        <b-modal
            v-bind:no-close-on-backdrop="false"
            ref="about-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center py-2 modelFont aboutModalHeight">
                <div class="text-right cursor-pointer">
                    <img
                        class="rounded-circle"
                        src="../assets/close_icon.svg"
                        @click="hideAboutModal"
                    />
                </div>
                <h5><img class="" src="../assets/ghd_logo_black.png" /></h5>
                <p class="contactText mt-4">
                    Version <span>{{ appVersion }}</span>
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import router from '../../router';
import { mapState, mapActions } from 'vuex';
export default {
    data() {
        return {
            userProfile: [],
            userDetails: '',
            currentYear: '',
            appVersion: process.env.VUE_APP_VERSION,
        };
    },
    methods: {
        ...mapActions('users', {
            getAllUser: 'getAllUsers',
        }),
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        manageAccount() {
            router.push(
                `/classic/edituser/` + this.$store.state.store.posts.UserID
            );
            localStorage.setItem('adminrole', 'admin');
        },
        showContactModal() {
            this.$refs['contact-modal'].show();
        },
        hideContactModal() {
            this.$refs['contact-modal'].hide();
        },
        showAboutModal() {
            this.$refs['about-modal'].show();
        },
        hideAboutModal() {
            this.$refs['about-modal'].hide();
        },
        goToPortalSetting() {
            router.push(`/classic/portalsetting`);
        },
        async SignOut() {
            this.$store.commit('common/setLoadingIconOff');
            localStorage.removeItem('userDetails');
            document.cookie.split(';').forEach(function(c) {
                document.cookie = c
                    .replace(/^ +/, '')
                    .replace(/=.*/, '=;max-age=-1;path=/');
            });
            if (this.userDetails.isEmailLogin) {
                router.push(`/classic/`);
            } else {
                await this.$msalInstance
                    .logout({})
                    .then(() => {
                        router.push(`/classic/`);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
    },
    created() {
        var d = new Date();
        this.currentYear = d.getFullYear();
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    },
    computed: {
        ...mapState({
            users: (state) => state.users.users,
        }),
    },
};
</script>

<style scoped>
.linkText:hover {
    text-decoration: underline !important;
}

.adptEmail {
    color: #006395;
}

.contactText {
    font-size: 16px;
}

.modelFont {
    font-family: IBM Plex Sans;
}

.contactModalHeight {
    height: 225px;
}

.aboutModalHeight {
    height: 150px;
}

.adaptLogo {
    height: 35px;
    width: 35px;
}

.contactSupport {
    font-size: 25px;
    font-weight: 450;
}

.btn-outline-primary {
    color: #000 !important;
    border-color: #9d9fa2 !important;
}

.btn-outline-primary:hover {
    background-color: #fff !important;
}

.popupblock {
    width: 388px !important;
    height: 460px !important;
}

.signout {
    color: #1d1d1d;
    font-weight: 599;
    background-color: #eaeaea;
    border: none;
}

.info-footer-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.info-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    align-content: space-evenly;
    width: 80%;
}
</style>
