<template>
    <div class="datasset-list" v-if="showPage">
        <b-container fluid class="datasetlist sub_header_bg">
            <b-container class="p-0">
                <b-row class="mb-3">
                    <b-col cols="12 py-1">
                        <a @click="navigatetoDatasources">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <span class="floar-left ml-1">
                                Back to datasources</span
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="8" class="pt-2">
                        <h1 class="">{{ this.$route.params.sourceName }}</h1>
                    </b-col>
                    <b-col cols="4" class="text-right">
                        <b-button
                            rounded
                            class="buttonSelect"
                            @click="navigatetoaddData"
                            v-if="addDataSet"
                        >
                            Add dataset
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="pt-2">
                    <div class="borderBlue mt-3 ml-3">
                        <h5>Datasets</h5>
                    </div>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="p-0">
            <div v-if="totalRows">
                <div></div>

                <b-row class="my-1 m-row m-0 rel-position">
                    <div cols="12" class="py-2 m-shadow">
                        <div class="float-left h-45">
                            <img
                                class="pointer ml-2"
                                src="../../assets/search-outline.svg"
                                v-if="!search && !showSearchIcon ? true : false"
                                @click="searchOpen"
                            />
                            <input
                                ref="dataset"
                                spellcheck="false"
                                id="searchFilter"
                                class="test"
                                v-if="search || showSearchIcon ? true : false"
                                v-model="search"
                                @click="searchinputenter()"
                                @input="searchinputenter"
                                :class="
                                    search || showSearchIcon
                                        ? 'test'
                                        : 'searchinputList'
                                "
                                type="search"
                                placeholder="Search for datasets"
                            />
                            <span
                                class="grey-bg pointer"
                                v-if="searchClose"
                                @click="closeBar"
                                ><img src="../../assets/close.svg"
                            /></span>
                            <span class="border-right ml-2 m-none">&nbsp;</span>
                        </div>
                        <div class="float-left h-45 dataset-filterbox">
                            <a
                                href="#"
                                class="mx-2 pointer m-img"
                                @click="filterClick"
                                ><img src="../../assets/tooltip.svg"
                            /></a>
                            <span
                                class="blue-text pointer m-none"
                                @click="filterClick"
                                >Filter</span
                            >
                            <!-- <a href="javascript:void(0)" class="pl-3 clearFilter" v-if="enableFilter" @click="filterReset">Clear filter</a> -->
                            <b-list-group-item
                                class="filter-group filter-list-group left-align p-0 filter-group-m"
                                v-if="filterShow"
                            >
                                <div class="mx-3 mt-3">
                                    <div class="mb-1 float-left">
                                        Filter by
                                    </div>
                                    <div class="text-right">
                                        <a href="javascript:void(0)">
                                            <b-icon
                                                icon="x"
                                                font-scale="2"
                                                @click="closeFilter"
                                            >
                                            </b-icon>
                                        </a>
                                    </div>

                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Dataset Name',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="DatasetName"
                                            id="filter"
                                            class="mb-3 select-m"
                                            :options="datasets.items"
                                            text-field="DatasetAlias"
                                            value-field="DatasetAlias"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Dataset
                                                    Name</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </CustomFloatingLabel>

                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Dataset Desc',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="DatasetDesc"
                                            id="filter1"
                                            class="mb-3"
                                            :options="datasets.items"
                                            text-field="DatasetDesc"
                                            value-field="DatasetDesc"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Dataset
                                                    Desc</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </CustomFloatingLabel>

                                    <div class="d-flex">
                                        <b-button
                                            class="mb-3"
                                            v-on:click="filterDataSet"
                                            >Apply filters</b-button
                                        >
                                        <a
                                            href="javascript:void(0)"
                                            class="ml-2"
                                            @click="closeFilter"
                                            >Cancel</a
                                        >
                                    </div>
                                </div>
                            </b-list-group-item>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5 col-md-7 backfiltermargin">
                        <b-row v-if="enableFilter">
                            <div class="px-0 py-1" v-if="DatasetName">
                                <span
                                    class="filteredItem px-2 py-1 mr-2 text-center"
                                >
                                    {{ DatasetName }}
                                    <b-icon
                                        icon="x"
                                        font-scale="1"
                                        @click="backFilter('DatasetName')"
                                    >
                                    </b-icon>
                                </span>
                            </div>
                            <div class="px-0 py-1" v-if="DatasetDesc">
                                <span
                                    class="filteredItem px-2 py-1 mr-2 text-center"
                                >
                                    {{ DatasetDesc }}
                                    <b-icon
                                        icon="x"
                                        font-scale="1"
                                        @click="backFilter('DatasetDesc')"
                                    >
                                    </b-icon>
                                </span>
                            </div>

                            <b-col class="removeFilter blue-text">
                                <a @click="filterReset"> Remove All Filters </a>
                            </b-col>
                        </b-row>
                    </div>
                </b-row>
                <ejs-grid
                    :dataSource="enableFilter ? filterItems : datasets.items"
                    class="sortingenabled"
                    ref="grid"
                    :allowPaging="true"
                    :allowSorting="true"
                    :commandClick="gridRowData"
                    :load="pageCenter"
                    :dataBound="dataBound"
                    :sortSettings="sortOptions"
                    :pageSettings="pageSettings"
                    allowResizing="true"
                >
                    <e-columns>
                        <e-column
                            textAlign="Left"
                            headerText="Edit"
                            width="50"
                            :commands="editCommands"
                            title="Edit"
                            v-if="addDataSet"
                        ></e-column>
                        <e-column
                            field="DatasetAlias"
                            headerText="Dataset Name"
                            width="150"
                        ></e-column>
                        <e-column
                            field="DatasetDesc"
                            headerText="Description"
                            width="150"
                        ></e-column>
                        <e-column
                            field="DatasetName"
                            headerText="Source"
                            width="150"
                        ></e-column>
                        <e-column
                            field="CreationDate"
                            headerText="Date created"
                            width="150"
                        ></e-column>
                        <e-column
                            field="ModifiedDate"
                            headerText="Date modified"
                            width="150"
                        ></e-column>
                        <e-column
                            textAlign="Left"
                            headerText="Delete"
                            title="Delete"
                            freeze="Right"
                            width="60"
                            :commands="deleteCommands"
                            v-if="addDataSet"
                        ></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
            <div v-else>
                <div class="text_center">
                    <center>
                        <h3>No datasets added</h3>
                        <p>Add or open a dataset to begin.</p>
                    </center>
                </div>
            </div>
        </b-container>
        <ToastMessages
            :showSuccess="showSuccess"
            :successToastMessage="successToastMessage"
        />
        <b-modal
            ref="delete-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right">
                    <img src="../../assets/close_icon.svg" @click="hideModal" />
                </div>
                <h5 class="mt-2">Delete Dataset</h5>
                <small
                    >Are you sure you want to delete this dataset
                    <b>{{ nameDataset }}</b> ? This action cannot be
                    undone.</small
                ><br />
                <div class="mt-3 mb-4">
                    <a @click="hideModal" class="text-primary"
                        >No, don't delete</a
                    >
                    <button
                        class="buttonDelete ml-4"
                        @click="deleteDataset(idDataset)"
                    >
                        Delete dataset
                    </button>
                </div>
            </div>
        </b-modal>
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import router from '../../router';
import { mapState, mapActions } from 'vuex';
import Vue from 'vue';
import {
    GridPlugin,
    Sort,
    Page,
    Edit,
    CommandColumn,
    Toolbar,
    Search,
    Freeze,
    Resize,
} from '@syncfusion/ej2-vue-grids';
import AccessPermissionModal from '../AccessPermissionModal';
//import dashboard from '../../views/Dashboard';
import ToastMessages from '../ToastMessages.vue';
import CustomFloatingLabel from '../CustomFloatingLabel';
Vue.use(GridPlugin);

export default {
    computed: {
        ...mapState({
            datasets: (state) => state.datasets.datasets,
            totalRows: (state) => state.datasets.datasets.items?.length,
            deleteStatusCode: (state) => state.datasets.remove.StatusCode,
        }),
    },
    components: {
        AccessPermissionModal,
        CustomFloatingLabel,
        // dashboard,
        ToastMessages,
    },
    data() {
        return {
            sortOptions: {
                columns: [
                    { field: 'DatasetAlias', direction: 'Ascending' },
                    { field: 'DatasetDesc', direction: 'Descending' },
                    { field: 'CreationDate', direction: 'Descending' },
                    { field: 'ModifiedDate', direction: 'Descending' },
                ],
            },
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            filter: null,
            filterShow: false,
            applyfilter: false,
            addDataSet: false,
            datasetFilterd: [],
            name: '',
            desc: '',
            nameDataset: '',
            idDataset: 0,
            filterItems: [],
            enableFilter: false,
            search: '',
            DatasetName: null,
            DatasetDesc: null,
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            deleteCommands: [
                {
                    type: 'Delete',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-delete e-icons',
                    },
                },
            ],
            showPage: true,
            thisfilename: null,
            editData: true,
            pageSettings: { pageSize: 10 },
            showSuccess: false,
            successToastMessage: null,
            showSearchIcon: false,
            searchClose: false,
        };
    },
    provide: {
        grid: [
            Sort,
            Page,
            Edit,
            CommandColumn,
            Toolbar,
            Search,
            Freeze,
            Resize,
        ],
    },
    mounted() {
        // this.totalRows = totalRows;
        // this.getPermissions();
        this.pagePermit();
    },
    created() {
        this.getDatasets(this.$route.params.id);
        localStorage.setItem('sourceName', this.$route.params.sourceName);
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.Pagination) {
                this.pageSettings.pageSize = this.$store.state.store.portalsettings.Pagination;
            }
        }
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        pagePermit() {
            this.showPage = true;
            this.addDataSet = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('DataSetList')) {
                    vm.showPage = true;
                }
                if (element.PageName.includes('AddDataSet')) {
                    vm.addDataSet = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        pageCenter: function() {
            this.$refs.grid.ej2Instances.pagerModule.element.align = 'center';
        },
        dataBound: function() {
            this.$refs.grid.ej2Instances.pagerModule.pagerObj.pagerMessageModule.hideMessage();
        },
        searchiconclick() {
            document.querySelector('#outersearchinput').style.width = '300px';
            document.querySelector('#outersearchinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outersearchinput .e-input').style.display =
                'block';
        },
        filterClick() {
            this.filterShow = true;
            document.querySelector('#outersearchinput').style.width = '35px';
            document.querySelector('#outersearchinput').style.borderColor =
                'none';
            document.querySelector('#outersearchinput .e-input').style.display =
                'none';
            document.querySelector(
                '#outersearchinput .s-close-icon'
            ).style.display = 'none';
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.dataset.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.dataset.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.search = null;
            this.$refs.grid.$el.ej2_instances[0].search();
        },

        searchinputenter(args) {
            if (args) {
                this.$refs.grid.$el.ej2_instances[0].search(
                    args.currentTarget.value
                );
                this.showSearchIcon = false;
                if (!this.search) {
                    this.searchClose = false;
                }
            }
        },
        closeFilter() {
            this.filterShow = false;
        },
        clearSearch() {
            this.search = '';
            document.querySelector(
                '#outersearchinput .s-close-icon'
            ).style.display = 'none';
            this.$refs.grid.$el.ej2_instances[0].search(this.search);
        },
        filterDataSet() {
            this.filterShow = false;
            if (this.DatasetName || this.DatasetDesc) {
                this.enableFilter = true;
                if (
                    document.getElementById('filter').value &&
                    document.getElementById('filter1').value
                ) {
                    this.filterItems = this.datasets.items.filter(
                        (item) =>
                            item.DatasetAlias ==
                                document.getElementById('filter').value &&
                            item.DatasetDesc ==
                                document.getElementById('filter1').value
                    );
                } else if (document.getElementById('filter').value) {
                    this.filterItems = this.datasets.items.filter(
                        (item) =>
                            item.DatasetAlias ==
                            document.getElementById('filter').value
                    );
                } else if (document.getElementById('filter1').value) {
                    this.filterItems = this.datasets.items.filter(
                        (item) =>
                            item.DatasetDesc ==
                            document.getElementById('filter1').value
                    );
                }
            }
        },
        filterReset() {
            this.enableFilter = false;
            document.getElementById('filter').value = '';
            document.getElementById('filter1').value = '';
            this.DatasetName = null;
            this.DatasetDesc = null;
        },
        gridRowData: function(args) {
            //if (args.commandColumn.type === "Delete") {
            if (args.target.getAttribute('title') === 'Delete') {
                this.nameDataset = args.rowData.DatasetAlias;
                this.idDataset = args.rowData.DatasetId;
                this.$refs['delete-modal'].show();
            } else {
                router.push({
                    name: `datasetcolumnsNew`,
                    params: {
                        id: this.$route.params.id,
                        tablename: args.rowData.DatasetName,
                        tableAliasName: args.rowData.DatasetAlias,
                        datasetId: args.rowData.DatasetId,
                        type: args.rowData.DataSourceType,
                        sourceName: this.$route.params.sourceName,
                    },
                });
                //router.push(`/datasetdefinition/edit/datasetcolumns/`+ this.$route.params.id + `/` + args.rowData.DatasetName+ `/` +args.rowData.DatasetAlias+'/'+args.rowData.DatasetId);
            }
        },
        // navigateDataSetComumn(details){
        //     router.push(`/datasetdefinition/edit/datasetcolumns/`+ this.$route.params.id + `/` + details.item.DatasetName);
        // },
        navigatetoDatasources() {
            router.push(`/datasetdefinition/selectdatasources`);
        },
        ...mapActions('datasets', {
            getDatasets: 'getDatasets',
            remove: 'deleteDataset',
        }),

        navigatetoaddData() {
            router.push(
                `/datasetdefinition/adddataset/${this.$route.params.id}/${this.$route.params.sourceName}`
            );
        },
        filterdata() {
            this.filterShow = true;
        },
        backFilter(item) {
            this.filterShow = false;
            if (item == 'DatasetName') {
                this.DatasetName = '';
            }
            if (item == 'DatasetDesc') {
                this.DatasetDesc = '';
            }
            this.applyFilter();
            if (this.DatasetName == '' && this.DatasetDesc == '') {
                this.enableFilter = false;
            } else {
                this.enableFilter = true;
            }
        },
        //delete dataset
        showModal(datasetDetails) {
            this.nameDataset = datasetDetails.DatasetAlias;
            this.idDataset = datasetDetails.DatasetId;
            this.$refs['delete-modal'].show();
        },
        hideModal() {
            this.$refs['delete-modal'].hide();
        },
        /* onFiltered(filteredItems) { console.log("filteredItems",filteredItems)
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },*/
        applyFilter() {
            this.filterItems = this.datasets.items;

            if (this.DatasetName) {
                this.filterItems = this.filterItems.filter(
                    (item) => item.DatasetAlias === this.DatasetName
                );
            }
            if (this.DatasetDesc) {
                this.filterItems = this.filterItems.filter(
                    (item) => item.DatasetDesc === this.DatasetDesc
                );
            }

            this.applyfilter = true;
            this.filterShow = false;
        },
        removeFilter() {
            this.applyfilter = false;
            this.name = '';
            this.desc = '';
        },
        deleteDataset(dId) {
            this.remove(dId);
            setTimeout(() => {
                if (this.deleteStatusCode == 200) {
                    this.getDatasets(this.$route.params.id);
                }
            }, 1000);

            this.$refs['delete-modal'].hide();
            this.successToastMessage = 'Dataset deleted sucessfully.';
            this.showSuccess = true;
        },
        //  makeToast(variant = 'success') {s
        //         this.$bvToast.toast('Dataset Deleted Sucessfully.', {
        //           title: `Delete Message`,
        //           variant: variant,
        //           solid: true
        //         })
        //     }
    },
};
</script>

<style lang="scss" scoped>
.field_width {
    width: 226px !important;
}
.filter-group {
    width: 200px;
    margin-top: -27px;
}

span.edit_img img {
    margin-top: 13px;
    margin-top: 13px;
    width: 25px;
    /* margin-right: 10px; */
    /* margin-left: -14px; */
}
.edit_width {
    width: 60px !important;
}
.text_center {
    margin: 100px;
    margin-bottom: 200px;
}
.fixed_col {
    width: 15%px !important;
}
.dataset_table td {
    vertical-align: middle !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

input.searchinput {
    background: url('../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -151px;
}
.filter-option {
    margin-top: -22px;
}
.filter-option :focus {
    outline: none;
    border: none !important;
}
.list-group-item {
    padding: 8px 10px !important;
    position: absolute;
    top: 40px;
}
.form-control {
    display: block;
    width: auto !important;
    height: calc(1em + 0.75rem + 2px);

    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5;
    color: #000000 !important;
    background-color: #ced4da !important;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}
button {
    /* background-color: #006395; */
    color: #eee;
    border: none;
    padding: 7px;
}
.buttonSelect {
    background-color: #006395;
    padding: 11px 19px;
    color: white;
}
h5 {
    font-size: 18px;
}
.select_option {
    width: 168px;
}

p {
    font-size: 15px;
}

.page-item.active .page-link {
    color: #fff !important;
    background: #006395 !important;
    border-radius: 3px !important;
}

.e-search-icon::before {
    content: '\e993';
}
#outersearchinput {
    // width: 20px;
    // border-color: white;
    border-right: 1px solid #e0e0e0;
    width: 35px;
    height: auto;
}
#outersearchinput span {
    border: 0 !important;
}
#outersearchinput .e-input {
    display: none;
}
.filterSelect {
    width: 250px !important;
}
.e-grid .e-edit::before {
    content: url('../../assets/edit.svg') !important;
}
.e-grid .e-delete::before {
    content: url('../../assets/close_icon.svg') !important;
}
.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: White !important;
    border: none !important;
    text-align: center !important;
}
.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
    background-color: #f5f5f5 !important;
}
.e-grid .e-gridcontent tr td:first-child,
.e-grid .e-gridcontent tr td:last-child {
    text-align: left !important;
}

.e-grid .e-content {
    margin-left: 0px !important;
}

button.buttonDelete {
    background-color: #da1e27;
    color: #eee;
    padding: 7px 15px;
    border: none;
}
.clearFilter {
    top: 3px;
    position: relative;
}
.filter-list-group {
    z-index: 99;
    position: absolute;
    width: 300px;
    left: -5px !important;
    top: 92px;
}
.filteredItem {
    font: normal normal medium 14px/24px IBM Plex Sans;
    letter-spacing: 0.1px;
    color: #000000de;
    height: 30px;
    background: #eaeaea 0% 0% no-repeat padding-box;
    border-radius: 2px;
}
.backfiltermargin {
    margin: auto 0 auto 20px;
}
input.test {
    margin: 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    //margin-right: -20px!important;
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
</style>
