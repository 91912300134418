<template>
    <div>
        <div class="Que-btn">
            <small
                v-if="reviewItem.length"
                class="text-primary reviewQueueButton"
                @click="reviewQueue"
                >Review queue</small
            >
            <small
                v-if="!reviewItem.length"
                class="text-secondary reviewQueueButton"
                >Review queue</small
            >
            <div v-if="reviewItem.length">
                <b-button
                    rounded
                    class="buttonSelect queueChange ml-2"
                    @click="queueProjectToolChanges()"
                >
                    Queue changes
                </b-button>
            </div>
            <div v-if="!reviewItem.length">
                <b-button
                    v-if="projectToolsId.length || toolsId.length"
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueProjectToolChanges()"
                >
                    Queue changes
                </b-button>
                <b-button
                    v-if="!projectToolsId.length || !toolsId.length"
                    rounded
                    class="buttonSelect disableButton queueChange ml-3"
                >
                    Queue changes
                </b-button>
            </div>
        </div>

        <b-row class="access-blk">
            <b-col class="mt-2">
                <div class="card bg-light mb-3 border-grey">
                    <div class="card-header">Projects</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchProjectTools &&
                                        !showSearchProjects
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenProjects"
                                    @mouseover="searchOpenProjects"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchProjectTools &&
                                        !showSearchProjects
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenProjects"
                                    @mouseover="searchOpenProjects"
                                    >Search</span
                                >
                                <input
                                    ref="projects"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchProjectTools || showSearchProjects
                                            ? true
                                            : false
                                    "
                                    v-model="searchProjectTools"
                                    :class="
                                        searchProjectTools || showSearchProjects
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseProjects"
                                    @click="closeBarProjects"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    class="pl-3 ml-2"
                                    @click="selectAllProjects"
                                    v-model="allSelected"
                                /><span class="portal-lbl">Project name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table
                                    id="groupTable"
                                    class="table"
                                    striped
                                    :per-page="perPage"
                                >
                                    <!-- <thead>
                                              <tr>
                                                
                                                <th scope="col">
                                                </tr>
                                            </thead> -->
                                    <tbody :per-page="perPage">
                                        <tr
                                            v-for="(projectTool,
                                            index) in toolsProjectFiltered"
                                            :key="index"
                                            :value="projectTool.ProjectName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            @click="
                                                                checkedProject(
                                                                    projectTool.ProjectID
                                                                )
                                                            "
                                                            :value="
                                                                projectTool.ProjectID
                                                            "
                                                            v-model="
                                                                projectToolsId
                                                            "
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion2' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                projectTool.ProjectName
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion2' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion2' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesProjects[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            projectTool.Tool &&
                                                                                projectTool
                                                                                    .Tool
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchToolsNameModel &&
                                                                                    !showSearchProjectsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchToolsNameModel &&
                                                                                    !showSearchProjectsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="projectsInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="w-357"
                                                                                v-if="
                                                                                    searchToolsNameModel ||
                                                                                    showSearchProjectsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchToolsNameModel
                                                                                "
                                                                                :class="
                                                                                    searchToolsNameModel ||
                                                                                    showSearchProjectsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseProjectsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarProjectsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(tools,
                                                                        index) in $options.filters.filterByProperty(
                                                                            projectTool.Tool,
                                                                            '',
                                                                            'ToolName',
                                                                            searchToolsNameModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            tools.ToolName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                checked
                                                                                @click="
                                                                                    childCheck(
                                                                                        projectTool.ProjectID,
                                                                                        tools.ToolID,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="slash-text marginLeft25"
                                                                                >{{
                                                                                    tools.ToolName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                <jw-pagination
                  :items="projectToolsList"
                  @changePage="onChangeProjectToolsPage"
                  :labels="customLabels"
                  :maxPages="5"
                  class="paginationStyle"
                ></jw-pagination>
              </b-col> -->
                        </div>
                    </div>
                    <div
                        class="card-footer"
                        v-if="pageOfProjectTools.length > 0"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="groupTable"
                            @change="selectAllProjectsTab"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>

            <b-col class="mt-2 pl-2 ml-5">
                <div class="card bg-light mb-3 border-grey maxWidth499">
                    <div class="card-header">Tools</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchToolsList && !showSearchTools
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenTools"
                                    @mouseover="searchOpenTools"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchToolsList && !showSearchTools
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenTools"
                                    @mouseover="searchOpenTools"
                                    >Search</span
                                >
                                <input
                                    ref="tools"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchToolsList || showSearchTools
                                            ? true
                                            : false
                                    "
                                    v-model="searchToolsList"
                                    :class="
                                        searchToolsList || showSearchTools
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseTools"
                                    @click="closeBarTools"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    @click="selectAllTools"
                                    v-model="allSelectedTools"
                                    class="pl-3 ml-2"
                                />
                                <span class="portal-lbl">Name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table class="table" id="projectTable" striped>
                                    <tbody>
                                        <tr
                                            v-for="(tp,
                                            index) in toolsFilteredList"
                                            :key="index"
                                            :value="tp.ToolName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            v-model="toolsId"
                                                            :value="tp.ToolId"
                                                            @click="
                                                                checkedTools(
                                                                    tp.ToolId
                                                                )
                                                            "
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion3' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                tp.ToolName
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion3' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion3' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesDatasets[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            tp.Project &&
                                                                                tp
                                                                                    .Project
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchProjectNameModel &&
                                                                                    !showSearchToolsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenToolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenToolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchProjectNameModel &&
                                                                                    !showSearchToolsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenToolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenToolsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="toolsInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="w-357"
                                                                                v-if="
                                                                                    searchProjectNameModel ||
                                                                                    showSearchToolsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchProjectNameModel
                                                                                "
                                                                                :class="
                                                                                    searchProjectNameModel ||
                                                                                    showSearchToolsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseToolsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarToolsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(pr,
                                                                        index) in $options.filters.filterByProperty(
                                                                            tp.Project,
                                                                            '',
                                                                            'ProjectName',
                                                                            searchProjectNameModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            pr.ProjectName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                checked
                                                                                @click="
                                                                                    childCheck(
                                                                                        pr.ProjectID,
                                                                                        tp.ToolId,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="slash-text marginLeft25-d"
                                                                                >{{
                                                                                    pr.ProjectName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                <jw-pagination
                  :items="toolsList"
                  @changePage="onChangeToolListPage"
                  :labels="customLabels"
                  :maxPages="5"
                  class="paginationStyle"
                ></jw-pagination>
              </b-col> -->
                        </div>
                    </div>
                    <div class="card-footer" v-if="pageOfToolList.length > 0">
                        <b-pagination
                            v-model="currentPageProject"
                            :total-rows="rowsProject"
                            :per-page="perPage"
                            aria-controls="projectTable"
                            @change="selectAllDatasets"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="tool-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="d-block text-center" v-if="!reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                There is no item to review.
            </div>
            <div class="d-block" v-if="reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <div class="text-center">
                    <h4 class="my-2">Change queue</h4>
                    <small
                        >Review {{ reviewItem.length }} changes pending before
                        commiting the changes.</small
                    >
                </div>
                <div class="my-3 tblScroll">
                    <table class="table table-striped toolModal">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Access</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr
                                v-for="(item, index) in reviewItem"
                                :key="index"
                            >
                                <td>{{ item.projectName }}</td>
                                <td v-if="item.isActive">
                                    <img src="../../../assets/icon_tick.svg" />
                                </td>
                                <td v-if="!item.isActive">
                                    <img src="../../../assets/close.svg" />
                                </td>
                                <td>{{ item.toolName }}</td>
                                <td class="deleteCell" @click="delQueue(index)">
                                    Delete from queue&nbsp;
                                    <img src="../../../assets/close.svg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 text-center">
                    <a class="text-primary" @click="clearQueue">Clear queue </a>
                    <button class="buttonSelect ml-3" @click="commitChanges">
                        Commit changes
                    </button>
                </div>
            </div>
        </b-modal>
        <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../../assets/success_tick.svg" class="mr-3"/>
                <span v-if="reviewItem.length"> <b>Changes queued </b><br/> {{successToastValue}} <br/>All the requested changes to be inserted into the queue.</span>
                  <span v-if="!reviewItem.length">{{successToastValue}}</span>
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../../assets/failure_cross.svg" class="mr-3"/>
                 {{failureToastValue}}
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
    </div>
</template>

<script>
import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import { mapActions, mapMutations } from 'vuex';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};
export default {
    data() {
        return {
            allSelectedTools: false,
            allSelected: false,
            searchText: true,
            customLabels,
            showText: false,
            searchTextTools: true,
            showTextTools: false,
            searchProject: '',
            searchProjectTools: '',
            searchToolsList: '',
            projectToolsList: [],
            toolsList: [],
            projectToolsId: [],
            toolsId: [],
            pageOfProjectTools: [],
            pageOfToolList: [],
            projectName: '',
            perPage: 5,
            // successToast: false,
            //   failureToast:false,
            //   successToastValue:'',
            //   failureToastValue:'',
            reviewItem: [],
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            currentPage: 1,
            projectToolsListFiltered: [],
            toolsListFiltered: [],
            currentPageProject: 1,
            searchProjectNameInput: true,
            searchProjectNameModel: '',
            searchToolsNameInput: true,
            searchToolsNameModel: '',
            collapseStatesProjects: [],
            collapseStatesDatasets: [],
            searchCloseProjects: false,
            showSearchProjects: false,
            searchCloseProjectsInner: false,
            showSearchProjectsInner: false,
            searchCloseTools: false,
            showSearchTools: false,
            searchCloseToolsInner: false,
            showSearchToolsInner: false,
        };
    },
    created() {
        this.getProjectsTool();
        this.getTools();
    },
    mounted() {
        //  this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName == 'ProjectTools') {
                    vm.showPage = true;
                    return true;
                }
            });
            this.$emit('clickedTools', this.showPage);
        },
        //  closeSuccessToast(){
        //         this.successToast = false
        //     },
        //     closeFailureToast(){
        //         this.failureToast = false
        //     },
        hideModal() {
            this.$refs['tool-modal'].hide();
        },
        delQueue(index) {
            let removeItem = this.reviewItem[index];
            this.reviewItem.splice(index, 1);
            let projectIdFound = this.reviewItem.findIndex(
                (item) => item.projectId == removeItem.projectId
            );
            if (projectIdFound == -1) {
                let projectId = this.projectToolsId.findIndex(
                    (item) => item == removeItem.projectId
                );
                this.projectToolsId.splice(projectId, 1);
            }
            let toolIdFound = this.reviewItem.findIndex(
                (item) => item.toolId == removeItem.toolId
            );
            if (toolIdFound == -1) {
                let toolId = this.toolsId.findIndex(
                    (item) => item == removeItem.toolId
                );
                this.toolsId.splice(toolId, 1);
            }

            /*if(!this.reviewItem.length){
                this.$refs["tool-modal"].hide();
            }*/
        },

        searchOpenProjects() {
            this.searchCloseProjects = !this.searchCloseProjects;
            this.showSearchProjects = true;
            this.$nextTick(() => {
                this.$refs.projects.focus();
            });
        },
        closeBarProjects() {
            this.searchCloseProjects = !this.searchCloseProjects;
            this.showSearchProjects = false;
            this.searchProjectTools = null;
        },

        closeBarProjectsInner() {
            this.searchCloseProjectsInner = !this.searchCloseProjectsInner;
            this.showSearchProjectsInner = false;
            this.searchToolsNameModel = null;
        },
        searchOpenProjectsInner(index) {
            this.searchCloseProjectsInner = !this.searchCloseProjectsInner;
            this.showSearchProjectsInner = true;
            this.$nextTick(() => {
                this.$refs.projectsInner[index].focus();
            });
        },

        searchOpenTools() {
            this.searchCloseTools = !this.searchCloseTools;
            this.showSearchTools = true;
            this.$nextTick(() => {
                this.$refs.tools.focus();
            });
        },
        closeBarTools() {
            this.searchCloseTools = !this.searchCloseTools;
            this.showSearchTools = false;
            this.searchToolsList = null;
        },

        closeBarToolsInner() {
            this.searchCloseToolsInner = !this.searchCloseToolsInner;
            this.showSearchToolsInner = false;
            this.searchProjectNameModel = null;
        },
        searchOpenToolsInner(index) {
            this.searchCloseToolsInner = !this.searchCloseToolsInner;
            this.showSearchToolsInner = true;
            this.$nextTick(() => {
                this.$refs.toolsInner[index].focus();
            });
        },

        clearQueue() {
            this.projectToolsId = [];
            this.toolsId = [];
            this.reviewItem = [];
            this.$refs['tool-modal'].hide();
        },
        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                data.push({
                    ProjectID: item.projectId,
                    ToolID: item.toolId,
                    isActive: item.isActive,
                });
            });

            managementService.addOrUpdateProjectTool(data).then((res) => {
                if (res.data.StatusCode === 201) {
                    this.setToastMessage({
                        showSuccess: true,
                        showFailure: false,
                        successToastMessage: 'Association done successfully.',
                        failureToastMessage: null,
                        messageTitle: null,
                    });
                    this.reviewItem = [];
                    this.getProjectsTool();
                    this.getTools();
                    this.projectToolsId = [];
                    this.toolsId = [];
                    this.$refs['tool-modal'].hide();
                }
            });
        },
        reviewQueue() {
            this.$refs['tool-modal'].show();
        },
        queueProjectToolChanges() {
            if (
                (this.projectToolsId.length && this.toolsId.length) ||
                this.reviewItem.length
            ) {
                if (this.projectToolsId.length && this.toolsId.length) {
                    // this.reviewItem=[]
                    let selectedProject = {};
                    this.projectToolsId.forEach((projectToolItem) => {
                        let selectedTools = [];
                        this.toolsId.forEach((toolItem) => {
                            selectedTools.push(toolItem);
                        });
                        selectedProject[projectToolItem] = selectedTools;
                    });
                    for (let props in selectedProject) {
                        let projectName = this.projectToolsList.filter(
                            (item) => {
                                return item.ProjectID == props;
                            }
                        );
                        for (
                            let i = 0;
                            i < selectedProject[props].length;
                            i++
                        ) {
                            let toolName = this.toolsList.filter((subItem) => {
                                return (
                                    subItem.ToolId == selectedProject[props][i]
                                );
                            });
                            let exist = this.reviewItem.findIndex(
                                (item) =>
                                    item.projectId == props &&
                                    item.toolId == selectedProject[props][i]
                            );
                            if (exist === -1) {
                                this.reviewItem.push({
                                    projectId: props,
                                    toolId: selectedProject[props][i],
                                    projectName: projectName[0].ProjectName,
                                    toolName: toolName[0].ToolName,
                                    isActive: true,
                                });
                            }
                        }
                    }
                    /* this.projectToolsId.forEach((projectToolItem)=>{
                    let projectName = this.projectToolsList.filter((item)=>{
                        return item.ProjectID == projectToolItem;
                    })
                    // 
                    this.toolsId.forEach((toolItem)=>{
                        let toolName = this.toolsList.filter((subItem)=>{
                            return subItem.ToolId == toolItem;
                        })
                        this.reviewItem.push({
                            "projectId":projectToolItem,
                            "toolId":toolItem,
                            "projectName": projectName[0].ProjectName,
                            "toolName": toolName[0].ToolName,
                            "isActive": true
                        })
                        this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.projectId)))
                          .map(id=>{
                              return this.reviewItem.find(a=> a.projectId ===id)
                          })
                    })
                })*/
                }
                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';
                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    successToastMessage: null,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: null,
                });
            }
        },
        selectAllProjects(projectTool) {
            this.projectToolsId = [];
            if (!this.allSelected) {
                for (projectTool in this.projectToolsList) {
                    this.projectToolsId.push(
                        this.projectToolsList[projectTool].ProjectID
                    );
                }
            }
        },
        selectAllTools(tp) {
            this.toolsId = [];
            if (!this.allSelectedTools) {
                for (tp in this.toolsList) {
                    this.toolsId.push(this.toolsList[tp].ToolId);
                }
            }
        },
        checkedProject(id) {
            if (this.projectToolsId.indexOf(id) > -1) {
                this.projectToolsId.splice(this.projectToolsId.indexOf(id), 1);
                this.allSelected = false;
            } else {
                this.projectToolsId.push(id);
            }
        },
        checkedTools(id) {
            if (this.toolsId.indexOf(id) > -1) {
                this.toolsId.splice(this.toolsId.indexOf(id), 1);
                this.allSelectedTools = false;
            } else {
                this.toolsId.push(id);
            }
        },
        childCheck(projectId, toolId, event) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.ProjectID == projectId && item.ToolId == toolId;
            });
            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    this.reviewItem.splice(unCheckedItem, 1);
                }
            } else {
                let projectName = this.projectToolsList.filter((item) => {
                    return item.ProjectID == projectId;
                });
                //
                let toolName = this.toolsList.filter((subItem) => {
                    return subItem.ToolId == toolId;
                });
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return (
                            item.ProjectID == projectId && item.ToolId == toolId
                        );
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            projectId: projectId,
                            toolId: toolId,
                            projectName: projectName[0].ProjectName,
                            toolName: toolName[0].ToolName,
                            isActive: false,
                        });
                    }
                }
            }
        },
        getProjectsTool() {
            managementService.getProjectsTool().then((res) => {
                this.projectToolsList = res.data;
                this.projectToolsListFiltered = res.data;
            });
        },
        getTools() {
            managementService.getTools().then((res) => {
                this.toolsList = res.data;
                this.toolsListFiltered = res.data;
            });
        },
        selectAllProjectsTab() {
            this.collapseStatesProjects = this.projectToolsList.map(
                (x) => (x.open = false)
            );
        },
        selectAllDatasets() {
            this.collapseStatesDatasets = this.toolsList.map(
                (x) => (x.open = false)
            );
        },

        addSearchText() {
            if (this.searchProjectTools.length == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextTools() {
            if (this.searchToolsList.length == 0) {
                this.searchTextTools = true;
                this.showTextTools = false;
            }
        },
        removeSearchTextTools() {
            this.searchTextTools = false;
            this.showTextTools = true;
        },

        onChangeProjectToolsPage(pageOfProjectTools) {
            this.pageOfProjectTools = pageOfProjectTools;
        },
        onChangeToolListPage(pageOfToolList) {
            this.pageOfToolList = pageOfToolList;
        },

        checkedBox() {},
    },
    computed: {
        toolsProjectFiltered() {
            let vm = this;
            if (vm.searchProjectTools) {
                vm.pageOfProjectTools = vm.projectToolsListFiltered.filter(
                    (post) => {
                        return post.ProjectName.toLowerCase().includes(
                            vm.searchProjectTools.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfProjectTools = vm.projectToolsListFiltered;
            }
            return vm.pageOfProjectTools.slice(
                (vm.currentPage - 1) * vm.perPage,
                vm.currentPage * vm.perPage
            );
        },

        toolsFilteredList() {
            let vm = this;
            if (vm.searchToolsList) {
                vm.pageOfToolList = vm.toolsListFiltered.filter((post) => {
                    return post.ToolName.toLowerCase().includes(
                        vm.searchToolsList.toLowerCase()
                    );
                });
            } else {
                vm.pageOfToolList = vm.toolsListFiltered;
            }
            return vm.pageOfToolList.slice(
                (vm.currentPageProject - 1) * vm.perPage,
                vm.currentPageProject * vm.perPage
            );
        },
        rows() {
            return this.pageOfProjectTools.length;
        },
        rowsProject() {
            return this.pageOfToolList.length;
        },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   color: black;
//   text-align: left;
//   span {
//     margin-left: 25px;
//     margin-left: 30px;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/blackminus.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   width: 388px;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
// .paginationStyle {
//   border: none;
//   /* margin: 0px auto !important; */
//   width: 200px;
//   margin: 0px;
//   padding: 0px;
//   display: contents !important;
// }
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
}
// input.test {
//   width: 411px;
// }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 405px;
}
//    .buttonSelect.queueChange{
//     position: absolute;
//      right: 20px;
//      top: -50px;
//  }
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .row1 {
        border-bottom: 3px solid #eaeaea;
        // padding-top: 10px;
        // h6.blue {
        //   color: #006395 !important;
        // }
    }

    // .showProjectList {
    //   overflow: auto;
    //   height: 300px;
    //   width: 417px;
    // }
    .header {
        padding: 10px;
        border-bottom: 3px solid;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
// .reviewQueueButton{
//     position: absolute;
//     top:-35px;
//     right:175px;
//     cursor: pointer;
// }
table.toolModal tr:first-child {
    background: white;
}
.userrightmanagement .width357 {
    width: 357px !important;
}
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
</style>
