<template>
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="projects" v-else>
        <Header v-bind:showVersionToggle="false" />

        <b-container fluid class="user sub_header_bg">
            <b-container class="p-0">
                <b-row class="mb-3 m-none">
                    <b-col cols="12 my-1">
                        <a href="javascript:void(0)" @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a href="#" class="floar-left ml-1 back">
                                Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left py-2 m-pt-0" align-v="center">
                    <b-col cols="7" class="pt-2 pl-3 m-p-0">
                        <h1 class="">eTracker Projects</h1>
                    </b-col>
                    <b-col
                        cols="5"
                        class="text-right m-p-0 btn-top-user"
                        v-if="showAddeTrackerProject"
                    >
                        <b-button
                            rounded
                            id="addNewProject"
                            class="buttonSelect"
                            @click="addeTrackerProject"
                        >
                            Add eTracker Project
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="p-0">
            <eTrackerProjectList @clickedProjectList="listeTrackerProjects" />
            <router-view></router-view>
        </b-container>
        <Footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../../router';
import Header from '../Header';
import Footer from '../Footer';
import eTrackerProjectList from '../eTracker/eTrackerProjectList';
import AccessPermissionModal from '../AccessPermissionModal';

export default {
    name: 'eTracker',
    data() {
        return {
            showPage: true,
            showAddeTrackerProject: false,
        };
    },
    components: {
        Header,
        Footer,
        eTrackerProjectList,
        AccessPermissionModal,
    },
    mounted() {
        this.pagePermit();
    },
    methods: {
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        pagePermit() {
            this.showPage = true;
            this.showAddeTrackerProject = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('eTracker')) {
                    vm.showPage = true;
                }
                if (element.PageName.includes('AddeTrackerProject')) {
                    vm.showAddeTrackerProject = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        addeTrackerProject: function() {
            router.push(`/eTrackerAddProject`);
        },
        // ...mapActions("users", {
        //     removeSingleUser: "removeSingleUser"
        // }),
        listeTrackerProjects(value) {
            if (!value) {
                this.showPage = value;
                setTimeout(() => {
                    this.$refs['permission-modal'].showPermissionModal();
                });
            }
        },
    },
};
</script>

<style scoped>
/* .buttonSelect {
    background-color: #006395;
    padding: 10px 18px;
    width:150px;
  } */
.borderBlue {
    border-bottom: 5px solid rgba(0, 99, 149, 1);
    /* width: 55px !important; */
}

a {
    text-decoration: none !important;
    font-size: 14px;
}

a span {
    color: black;
}
@media only screen and (min-width: 768px) {
    .user.sub_header_bg {
        height: 120px !important;
    }
}
</style>
