<template>
    <div style="height:100%">
        <l-map
            ref="mainMap"
            :zoom="mapZoom"
            :center="mapCenter"
            :options="{ zoomControl: false }"
            style="height:100%"
            @update:zoom="updateZoom"
            @update:center="updateCenter"
            @baselayerchange="baseMapChanged"
            @click="handleMouseClick"
            @dblclick="handleMouseDblClick"
        >
            <!-- base map toggle -->
            <l-control-layers position="topright"></l-control-layers>
            <!-- print button -->
            <l-control class="print-map-control" position="topright">
                <b-button class="printButton" @click="print">
                    <img
                        src="../../assets/printer-outline-with-paper.svg"
                        class="icon-32"
                    />
                </b-button>
            </l-control>
            <l-control-scale unitSystem="both" v-bind:max-width="135" />
            <l-control position="bottomleft" v-if="isMapExpanded">
                <a>
                    <img
                        src="../../assets/minimize.svg"
                        class="icon-40"
                        @click="modalClose"
                    />
                </a>
            </l-control>
            <l-control position="bottomleft" v-else>
                <a v-b-modal.modal-screen>
                    <img
                        src="../../assets/maximize.svg"
                        class="icon-35"
                        @click="modalOpen"
                    />
                </a>
            </l-control>
            <l-control
                position="bottomleft"
                class="loadingText"
                v-if="!allLayersLoaded"
            >
                some layers on the map are still loading
            </l-control>
            <!-- base maps -->
            <l-tile-layer
                v-for="baseMap in mapData.baseMaps"
                :key="baseMap.name"
                :name="baseMap.name"
                :visible="baseMap.visible"
                :attribution="baseMap.attribution"
                :url="baseMap.url"
                layer-type="base"
                :options="baseMapOptions"
            ></l-tile-layer>

            <!-- marker  -->
            <l-marker
                v-for="(item, index) in mapData.markers"
                :lat-lng="item.coordinates"
                :key="index + '_marker'"
            >
                <!-- marker tool tip -->
                <l-tooltip v-if="item.tooltip"
                    ><span v-html="item.tooltip"></span
                ></l-tooltip>
                <!-- marker pop up -->
                <l-popup v-if="item.popup">
                    <span v-html="item.popup"></span>
                </l-popup>
            </l-marker>

            <!-- marker clusters  -->
            <l-marker-cluster v-if="mapData.markersClusters">
                <l-marker
                    v-for="(item, index) in mapData.markersClusters"
                    :lat-lng="item.coordinates"
                    :key="index + '_markerCluster'"
                >
                    <l-tooltip v-if="item.tooltip"
                        ><span v-html="item.tooltip"></span
                    ></l-tooltip>
                    <l-popup v-if="item.popup">
                        <span v-html="item.popup"></span>
                    </l-popup>
                </l-marker>
            </l-marker-cluster>

            <!-- geojson  -->
            <div>
                <l-geo-json
                    v-for="(item, index) in projectLyrs"
                    :key="index + '_projectGeojson'"
                    :visible="item.visible"
                    :geojson="item"
                    :options="projectTooltipOptions"
                    :options-style="geoJsonStyling"
                ></l-geo-json>
            </div>
            <div>
                <l-geo-json
                    v-for="(item, index) in postedLyrs"
                    :key="index + '_postedGeojson'"
                    :visible="item.visible"
                    :geojson="item"
                    :options="postedGeoJsonOptions"
                    :options-style="postedGeoJsonStyling"
                ></l-geo-json>
            </div>
            <l-marker-cluster
                ref="projectCluster"
                v-if="projectClusterLyrs.length"
                :options="clusterOptions"
            >
                <div>
                    <l-geo-json
                        ref="projectClusterGeoJson"
                        v-for="(item, index) in projectClusterLyrs"
                        :key="index + '_projectGeojsonCluster'"
                        :visible="item.visible"
                        :geojson="item"
                        :options="projectTooltipOptions"
                        :options-style="geoJsonStyling"
                    ></l-geo-json>
                </div>
            </l-marker-cluster>
            <l-marker-cluster
                ref="postedCluster"
                v-if="postedClusterLyrs.length"
                :options="clusterOptions"
            >
                <div>
                    <l-geo-json
                        ref="postedClusterGeoJson"
                        v-for="(item, index) in postedClusterLyrs"
                        :key="index + '_postedGeojsonCluster'"
                        :visible="item.visible"
                        :geojson="item"
                        :options="postedGeoJsonOptions"
                        :options-style="postedGeoJsonStyling"
                    ></l-geo-json>
                </div>
            </l-marker-cluster>

            <!-- wms layers  -->
            <l-wms-tile-layer
                v-for="layer in wmsLayers"
                :key="layer.name"
                :base-url="layer.url"
                :layers="layer.layers"
                :visible="layer.IsVisible"
                :name="layer.name"
                :format="layer.format"
                :transparent="layer.transparent"
                layer-type="overlay"
                :options="wmsLayerOptions"
            />
            <l-wms-tile-layer
                v-for="wmsLayer in mapData.contourWMSLyrs"
                :key="wmsLayer.id"
                :base-url="wmsLayer.url"
                :layers="wmsLayer.layers"
                :visible="wmsLayer.visible"
                :name="wmsLayer.DisplayName"
                :attribution="wmsLayer.attribution"
                :transparent="wmsLayer.transparent"
                :options="wmsLayer.options"
                :opacity="wmsLayer.opacity"
                :format="wmsLayer.format"
                :styles="wmsLayer.styles"
                layer-type="overlay"
            />

            <!-- user draw geometries -->
            <l-feature-group
                ref="editFeatureGroup"
                layer-type="overlay"
                name="Edit Layer"
            >
                <!-- circle markers radius is in pixels, vs circles which are in meters -->
                <l-circle-marker
                    v-for="marker in drawMarkers"
                    :key="marker.id"
                    :lat-lng="marker.geometry.coordinates"
                    :radius="marker.styling.SymbologySize"
                    :fillColor="marker.styling.SymbologyColour"
                    :fillOpacity="1 - marker.styling.PolygonTransparency"
                    :color="marker.styling.SymbologyColour"
                    @click="circleMarkerClick(marker.id)"
                />
                <l-polyline
                    v-for="line in drawLines"
                    :key="line.id"
                    :lat-lngs="line.geometry.coordinates[0]"
                    :color="line.styling.PolygonBorderColour"
                />
                <l-polygon
                    v-for="polygon in drawPolygons"
                    :key="polygon.id"
                    :lat-lngs="polygon.geometry.coordinates[0]"
                    :color="polygon.styling.PolygonBorderColour"
                    :fillColor="polygon.styling.PolygonFillColour"
                    :opacity="1 - polygon.styling.PolygonTransparency"
                />
            </l-feature-group>

            <!-- map zoom in and out -->
            <l-control-zoom position="bottomright"></l-control-zoom>
        </l-map>
        <div ref="popup" v-show="mapPopupVisible">
            <table class="table table-striped table-sm mt-3">
                <tbody>
                    <tr
                        v-for="item in clickedGeometry.properties"
                        :key="item.name"
                        v-show="item.popup"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                    <tr v-show="clickedGeometry.hasFile">
                        <td colspan="2">
                            <div
                                v-for="(item, index) in clickedGeometry.files"
                                :key="item.fileName"
                            >
                                <a @click="openDocument(index)">
                                    {{ item.fileName }}
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
    Icon,
    latLngBounds,
    icon,
    circleMarker,
    marker,
    divIcon,
} from 'leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('../../assets/leaflet_markers/marker-icon-2x-blue.png'),
    iconUrl: require('../../assets/leaflet_markers/marker-icon-blue.png'),
    shadowUrl: require('../../assets/leaflet_markers/marker-shadow.png'),
});
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    LControlLayers,
    LPopup,
    LControlZoom,
    LControl,
    LWMSTileLayer,
    LGeoJson,
    LCircle,
    LCircleMarker,
    LPolygon,
    LPolyline,
    LFeatureGroup,
} from 'vue2-leaflet';
import LControlScale from '../../components/EmergencyManagement/LeafletScale.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { commonService } from '../../services/common.service';
import { spatialFunctions } from '../../utilities/spatialFunctions';
import { spatialService } from '../../services/spatial.services.js';

export default {
    name: 'MainLeafletMap',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LTooltip,
        LControlLayers,
        LPopup,
        LControlZoom,
        LControl,
        'l-wms-tile-layer': LWMSTileLayer,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        LGeoJson,
        LControlScale,
        LCircle,
        LCircleMarker,
        LPolyline,
        LPolygon,
        LFeatureGroup,
    },
    created() {},
    mounted() {
        // grab the variables from the store, but we don't update them live
        this.mapCenter = this.$store.state.projects.mapData.mapCenter;
        this.mapZoom = this.$store.state.projects.mapData.mapZoom;
        setTimeout(() => {
            this.$refs.mainMap.mapObject.invalidateSize();
        }, 1000);
        setTimeout(() => {
            this.reclusterProject();
        }, 1000);
        setTimeout(() => {
            this.reclusterPosted();
        }, 1000);
    },
    props: {
        mapType: String,
        zoomMapExtent: {
            type: Array,
            required: false,
            default: () => {
                return [
                    [-123.77, 30.72],
                    [-59, 50],
                ];
                /*minX: -123.77,
          minY: 30.72,
          maxX: -59,
          maxY: 50.0*/
            },
        },
    },
    watch: {
        // when projects are added, zoom to all project layers
        projectLyrCount(newCount, oldCount) {
            if (oldCount == 0 && newCount > 0) {
                this.zoomToLayers();
            }
        },
        postedLyrCount(newCount, oldCount) {
            if (newCount > oldCount) {
                this.zoomToPosted(
                    this.mapData.geojsonLayers.posted[newCount - 1]
                        .markerSettings11[0].dataSource
                );
            }
        },
        zoomMapExtent(newExtent, oldExtent) {
            var mapBounds = latLngBounds(newExtent);
            var mapExtent = [mapBounds._northEast, mapBounds._southWest];
            this.$refs.mainMap.mapObject.fitBounds(mapExtent);
        },
    },
    data() {
        return {
            //retrieve most of the information from Vuex
            mapPopupVisible: false,
            clickedGeometry: { properties: [], hasFile: false, files: [] },
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
            wmsLayerOptions: {
                maxNativeZoom: 21,
                maxZoom: 21,
            },
            clusterOptions: {
                disableClusteringAtZoom: 19,
                showCoverageOnHover: false,
                chunkedLoading: true,
            },
            mapCenter: [0, 0],
            mapZoom: 15,
            //properties relating to drawing for 3D visualization
            drawMode: '',
            drawMarkers: [],
            drawLines: [],
            drawPolygons: [],
        };
    },
    computed: {
        ...mapState('projects', ['mapData']),
        ...mapState('projectLayers', {
            projects: (state) => state.projects,
        }),
        ...mapGetters('projects', [
            'postedLyrCount',
            'postedLyrs',
            'postedClusterLyrs',
        ]),
        ...mapGetters('projectLayers', [
            'projectLyrCount',
            'projectLyrs',
            'projectClusterLyrs',
            'wmsLayers',
        ]),
        projectGeojsonLayers() {
            let output = [];
            this.projects.forEach((proj) => {
                proj.ProjectLayers.forEach((lyr) => {
                    output.push(lyr.Geometry);
                });
            });
            return output;
        },
        postedGeojsonLayers() {
            let output = [];
            this.mapData.geojsonLayers.posted.forEach((proj) => {
                output.push(proj.markerSettings11[0].dataSource);
            });
            return output;
        },
        mapExtent() {
            let output;
            if (this.mapType == 'big') {
                output = latLngBounds(
                    this.mapData.expandedMapExtent[0],
                    this.mapData.expandedMapExtent[1]
                );
            } else {
                output = latLngBounds(
                    this.mapData.mapExtent[0],
                    this.mapData.mapExtent[1]
                );
            }
            return output;
        },
        allLayersLoaded() {
            let output = true;
            this.projects.forEach((proj) => {
                proj.ProjectLayers.forEach((lyr) => {
                    if (lyr.geomDataLoaded == false) {
                        output = false;
                    }
                });
            });
            return output;
        },
        isMapExpanded() {
            return this.$store.state.projects.mapExpanded;
        },
        //use l-map update bounds event to get map extent, and save it to the store
        //similar logic to handle base map selection
        projectTooltipOptions() {
            function makeLabelStyle(styling) {
                var tooltipStyle = '';
                if (styling.FontSize) {
                    tooltipStyle += 'font-size: ' + styling.FontSize + 'pt;';
                }
                switch (styling.FontStyle) {
                    case 'Bold':
                        tooltipStyle += 'font-weight: bold;';
                        break;
                    case 'Italic':
                        tooltipStyle += 'font-style: italic;';
                        break;
                }
                switch (styling.FontType) {
                    case 'Arial':
                        tooltipStyle += 'font-family: Arial, sans-serif;';
                        break;
                    case 'Helvetica':
                        tooltipStyle += 'font-family: Helvetica, sans-serif;';
                        break;
                    case 'Times New Roman':
                        tooltipStyle +=
                            'font-family: "Times New Roman", Times, serif;';
                        break;
                    case 'Georgia':
                        tooltipStyle += 'font-family: Georgia;';
                        break;
                    case 'Lucida Console':
                        tooltipStyle += 'font-family: "Lucida Sans";';
                        break;
                    case 'Courier New':
                        tooltipStyle +=
                            'font-family: "Courier New", Courier, monospace;';
                        break;
                }
                tooltipStyle += 'color: ' + styling.FontColour + ';';
                tooltipStyle += 'opacity: ' + styling.opacity / 100 + ';';
                return tooltipStyle;
            }
            return {
                onEachFeature: this.onEachProjectFeatureFunction,
                pointToLayer: function(feature, latlng) {
                    // const fillColor = this.fillColor
                    let labelDisplay = '';
                    let popupDisplay = `<table class="table table-striped table-sm mt-3">
                            <tbody>`;
                    feature.properties.forEach((item) => {
                        if (item.label) {
                            labelDisplay += `<span style='${makeLabelStyle(
                                feature.styling
                            )}'>${item.value}</span>`;
                        }
                        if (item.popup) {
                            popupDisplay += `<tr>
                                <td>${item.name}</td>
                                <td>${item.value}</td>
                            </tr>`;
                        }
                    });
                    popupDisplay += `  </tbody>
                        </table>`;

                    var tooltipPosition = '';
                    var tooltipOffset = [0, 0]; // x, y
                    switch (feature.styling.LabelPosition) {
                        case 'topLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, -20]; // x, y
                            break;
                        case 'topCenter':
                            tooltipPosition = 'top';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'topRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, -20]; // x, y
                            break;
                        case 'middleLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'middleCenter':
                            tooltipPosition = 'center';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'middleRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'bottomLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, 20]; // x, y
                            break;
                        case 'bottomCenter':
                            tooltipPosition = 'bottom';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'bottomRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, 20]; // x, y
                            break;
                    }
                    return marker(latlng, {
                        icon: divIcon({
                            html:
                                feature.styling.Symbology == 'arrow'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.5645px" viewBox="0 0 10.5645 5.1655" height="5.1655px">  <path data-name="Path 32096" d="M235.528,285.054l5.09-4.056a1.108,1.108,0,0,0,0-1.733l-5.09-4.056a1.108,1.108,0,0,0-1.8.866v1.591H221.015a1.108,1.108,0,0,0-1.108,1.108v2.713a1.108,1.108,0,0,0,1.108,1.108H233.73v1.591A1.108,1.108,0,0,0,235.528,285.054Z" transform="scale(0.5 0.5) translate(-219.906 -274.967)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'circle'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'cross3'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="8.958px" viewBox="0 0 8.958 10.5365" height="10.5365px">  <path data-name="Union 7" d="M.047,18.277l5.271-7.814L0,2.868,4.1,0,9.718,8.029h8.2v5H9.9l-.527.369L4.192,21.073Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" transform="scale(0.5 0.5)" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'cross'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="9.5px" viewBox="0 0 9.5 9.5" height="9.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Union_8" data-name="Union 8" d="M7,19V12H0V7H7V0h5V7h7v5H12v7Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke-width="3"/></svg>'
                                    : feature.styling.Symbology == 'cross5'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="8.0305px" viewBox="0 0 8.0305 8.426" height="8.426px">  <path data-name="Union 9" d="M7.14,8.372,7,9l.14-.628L7,8l.14.372L7.222,8h0L9,0,7.222,8h0l-.083.372L10,16ZM6,8,0,14ZM0,3,6,8Z" transform="scale(0.5 0.5) translate(1.061 0.325)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke-width="3"/></g></g></svg>'
                                    : feature.styling.Symbology == 'flag'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="6.6065px" viewBox="0 0 6.6065 8.3425" height="8.3425px">  <g data-name="Group 12386" transform="scale(0.5 0.5) translate(19439.5 10083.185)"> <line id="Line_1338" data-name="Line 1338" y1="16.676" transform="translate(-19438.5 -10083.177)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke-width="2"/> <path id="Path_32097" data-name="Path 32097" d="M-19641.287-10072.161h-12.227v-8.347h12.227l-4.859,4.028Z" transform="translate(215 -2.677)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/>  </g></svg>'
                                    : feature.styling.Symbology == 'polygon3'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 12.0" height="12.0px">  <path data-name="Polygon 5" d="M12,0,24,20H0Z" transform="scale(0.5 0.5) translate(20) rotate(90)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'polygon4'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.0" height="13.0px">  <path data-name="Polygon 6" d="M13,0,26,13,13,26,0,13Z" transform="scale(0.5 0.5)translate(26) rotate(90)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'polygon5'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.669" height="13.669px">  <path data-name="Polygon 7" d="M13.669,0,27.338,9.931,22.117,26H5.221L0,9.931Z" transform="scale(0.5 0.5) translate(26) rotate(90)"  fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'star'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="12.0px" viewBox="0 0 12.0 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_10" data-name="Polygon 10" d="M12,0l3,8.553,9,.232L16.854,14.3,19.416,23,12,17.857,4.584,23l2.562-8.7L0,8.785l9-.232Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></g></g></svg>'
                                    : feature.styling.Symbology == 'star3'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 11.5" height="11.5px">  <path data-name="Polygon 8" d="M13,0l3.9,13.033L26,23,13,19.933,0,23l9.1-9.967Z" transform="scale(0.5 0.5)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'star4'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="11.5px" viewBox="0 0 11.5 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_9" data-name="Polygon 9" d="M11.5,0l3.45,8.05L23,11.5l-8.05,3.45L11.5,23,8.05,14.95,0,11.5,8.05,8.05Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></g></g></svg>'
                                    : '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>',
                            iconAnchor:
                                feature.styling.Symbology == 'arrow'
                                    ? [5, 11]
                                    : feature.styling.Symbology == 'circle'
                                    ? [5, 7.5]
                                    : feature.styling.Symbology == 'cross3'
                                    ? [4, 8]
                                    : feature.styling.Symbology == 'cross5'
                                    ? [3, 9]
                                    : feature.styling.Symbology == 'flag'
                                    ? [2, 9]
                                    : feature.styling.Symbology == 'polygon3'
                                    ? [3, 8]
                                    : feature.styling.Symbology == 'polygon4'
                                    ? [6.5, 7]
                                    : feature.styling.Symbology == 'polygon5'
                                    ? [6, 7]
                                    : feature.styling.Symbology == 'star'
                                    ? [6, 8]
                                    : feature.styling.Symbology == 'star3'
                                    ? [6.5, 8]
                                    : feature.styling.Symbology == 'star4'
                                    ? [6, 8]
                                    : [5, 5],
                            className:
                                'ThisHasToBeSomethingToHideTheDefaultShadow',
                        }),
                        opacity: feature.styling.Opacity / 100,
                    })
                        .bindTooltip(labelDisplay, {
                            permanent: true,
                            direction: tooltipPosition,
                            className: 'leafletLabelClass',
                            offset: tooltipOffset,
                        })
                        .openTooltip()
                        .bindPopup(popupDisplay, {
                            permanent: false,
                            sticky: false,
                            className: 'leafletPopupClass',
                        });
                },
            };
        },
        onEachProjectFeatureFunction() {
            let v = this;
            function makeLabelStyle(styling) {
                var tooltipStyle = '';
                if (styling.FontSize) {
                    tooltipStyle += 'font-size: ' + styling.FontSize + 'pt;';
                }
                switch (styling.FontStyle) {
                    case 'Bold':
                        tooltipStyle += 'font-weight: bold;';
                        break;
                    case 'Italic':
                        tooltipStyle += 'font-style: italic;';
                        break;
                }
                switch (styling.FontType) {
                    case 'Arial':
                        tooltipStyle += 'font-family: Arial, sans-serif;';
                        break;
                    case 'Helvetica':
                        tooltipStyle += 'font-family: Helvetica, sans-serif;';
                        break;
                    case 'Times New Roman':
                        tooltipStyle +=
                            'font-family: "Times New Roman", Times, serif;';
                        break;
                    case 'Georgia':
                        tooltipStyle += 'font-family: Georgia;';
                        break;
                    case 'Lucida Console':
                        tooltipStyle += 'font-family: "Lucida Sans";';
                        break;
                    case 'Courier New':
                        tooltipStyle +=
                            'font-family: "Courier New", Courier, monospace;';
                        break;
                }
                tooltipStyle += 'color: ' + styling.FontColour + ';';
                tooltipStyle += 'opacity: ' + styling.Opacity / 100 + ';';
                return tooltipStyle;
            }
            return (feature, layer) => {
                layer.on('click', (e) => {
                    //e is the click event, returns screen pixel numbers in x and y, also return spatial lat/long

                    //feature returns the clicked geometry object, it ONLY returns the top geometry;

                    v.clickedGeometry = feature;
                    feature.properties.forEach((item) => {
                        if (item.name == 'Files') {
                            v.clickedGeometry.hasFile = true;
                            v.clickedGeometry.files = item.value;
                        }
                    });
                    v.mapPopupVisible = true;
                });
                let toolTipDisplay = '';
                let labelDisplay = '';
                let popupDisplay = `<table class="table table-striped table-sm mt-3">
                            <tbody>`;
                feature.properties.forEach((item) => {
                    if (item.label) {
                        labelDisplay += `<span style='${makeLabelStyle(
                            feature.styling
                        )}'>${item.value}</span>`;
                    }
                    if (item.tooltip) {
                        if (
                            (this.mapType == 'small' &&
                                this.mapData.mapZoom >= 17) ||
                            (this.mapType == 'big' &&
                                this.mapData.expandedMapZoom >= 17)
                        ) {
                            toolTipDisplay += `<span style='${makeLabelStyle(
                                feature.styling
                            )}'>${item.value}</span>`;
                        }
                    }
                    if (item.popup) {
                        popupDisplay += `<tr>
                                <td>${item.name}</td>
                                <td>${item.value}</td>
                            </tr>`;
                    }
                });
                popupDisplay += `  </tbody>
                        </table>`;
                layer.bindTooltip(toolTipDisplay, {
                    className: 'leafletLabelClass',
                    permanent: true,
                    sticky: true,
                });
                layer.bindPopup(v.$refs.popup, {
                    permanent: false,
                    sticky: false,
                    autoPanPaddingTopLeft: [20, 20],
                    maxWidth: 'auto',
                    minWith: '50px',
                    className: 'leafletPopupClass',
                });
            };
        },
        geoJsonStyling() {
            return (feature, layer) => {
                let outputStyle = {};
                if (feature.geometry.type == 'Polygon') {
                    outputStyle = {
                        weight: parseInt(feature.styling.PolygonWidth),
                        color: feature.styling.PolygonBorderColour,
                        opacity: 1 - feature.styling.PolygonTransparency / 100,
                        fillColor: feature.styling.PolygonColour,
                        fillOpacity:
                            0.5 *
                            (1 - feature.styling.PolygonTransparency / 100),
                        dashArray: '',
                    };
                    return outputStyle;
                } else if (feature.geometry.type == 'MultiPolygon') {
                    outputStyle = {
                        weight: parseInt(feature.styling.PolygonWidth),
                        color: feature.styling.PolygonBorderColour,
                        opacity: 1 - feature.styling.PolygonTransparency / 100,
                        fillColor: feature.styling.PolygonColour,
                        fillOpacity:
                            0.5 *
                            (1 - feature.styling.PolygonTransparency / 100),
                    };
                    return outputStyle;
                } else if (feature.geometry.type == 'LineString') {
                    outputStyle = {
                        weight: parseInt(feature.styling.LineWidth),
                        color: feature.styling.LineColour,
                        opacity: feature.styling.Opacity / 100,
                    };
                    return outputStyle;
                }
            };
        },
        postedGeoJsonStyling() {
            const polygonFillColour = this.polygonColour;
            const polygonBorderColour = this.polygonBorderColour;
            const polygonBorderWidth = this.polygonWidth;
            const polygonTransparency = this.polygonTransparency;
            const lineStrokeColour = this.lineColour;
            const lineStrokeWidth = this.lineWidth;
            var polygonDashArray = '';
            var polylineDashArray = '';
            if (this.polygonStyle == 'Dashed') {
                polygonDashArray = '4 4';
            } else if (this.polygonStyle == 'Dotted') {
                polygonDashArray = '1 2';
            }
            if (this.lineStyle == 'Dashed') {
                polylineDashArray = '4 4';
            } else if (this.lineStyle == 'Dotted') {
                polylineDashArray = '1 2';
            }
            return (feature, layer) => {
                //layer is undefined in console

                let outputStyle = {};
                //feature.styling contains styling information for each geometry
                if (feature.geometry.type == 'Polygon') {
                    outputStyle = {
                        weight: parseInt(polygonBorderWidth),
                        color: polygonBorderColour,
                        opacity: (100 - polygonTransparency) / 100,
                        fillColor: polygonFillColour,
                        fillOpacity: (0.5 * (100 - polygonTransparency)) / 100,
                        dashArray: polygonDashArray,
                    };
                } else if (feature.geometry.type == 'MultiPolygon') {
                    outputStyle = {
                        weight: parseInt(polygonBorderWidth),
                        color: polygonBorderColour,
                        opacity: (100 - polygonTransparency) / 100,
                        fillColor: polygonFillColour,
                        fillOpacity: (0.5 * (100 - polygonTransparency)) / 100,
                        dashArray: polygonDashArray,
                    };
                } else if (feature.geometry.type == 'LineString') {
                    outputStyle = {
                        weight: parseInt(lineStrokeWidth),
                        color: lineStrokeColour,
                        opacity: (100 - polygonTransparency) / 100,
                        dashArray: polylineDashArray,
                    };
                }
                return outputStyle;
            };
        },
        postedGeoJsonOptions() {
            function makeLabelStyle(styling) {
                var tooltipStyle = '';
                if (styling.FontSize) {
                    tooltipStyle += 'font-size: ' + styling.FontSize + 'pt;';
                }
                switch (styling.FontStyle) {
                    case 'Bold':
                        tooltipStyle += 'font-weight: bold;';
                        break;
                    case 'Italic':
                        tooltipStyle += 'font-style: italic;';
                        break;
                }
                switch (styling.FontType) {
                    case 'Arial':
                        tooltipStyle += 'font-family: Arial, sans-serif;';
                        break;
                    case 'Helvetica':
                        tooltipStyle += 'font-family: Helvetica, sans-serif;';
                        break;
                    case 'Times New Roman':
                        tooltipStyle +=
                            'font-family: "Times New Roman", Times, serif;';
                        break;
                    case 'Georgia':
                        tooltipStyle += 'font-family: Georgia;';
                        break;
                    case 'Lucida Console':
                        tooltipStyle += 'font-family: "Lucida Sans";';
                        break;
                    case 'Courier New':
                        tooltipStyle +=
                            'font-family: "Courier New", Courier, monospace;';
                        break;
                }
                tooltipStyle += 'color: ' + styling.FontColour + ';';
                tooltipStyle += 'opacity: ' + styling.opacity / 100 + ';';
                return tooltipStyle;
            }

            return {
                onEachFeature: this.onEachPostedFeatureFunction,
                pointToLayer: function(feature, latlng) {
                    // const fillColor = this.fillColor
                    let labelDisplay = '';
                    let popupDisplay = `<table class="table table-striped table-sm mt-3">
                            <tbody>`;
                    feature.properties.forEach((item) => {
                        if (item.label) {
                            labelDisplay += `<span style='${makeLabelStyle(
                                feature.styling
                            )}'>${item.value}</span>`;
                        }
                        if (item.popup) {
                            popupDisplay += `<tr>
                                <td>${item.name}</td>
                                <td>${item.value}</td>
                            </tr>`;
                        }
                    });
                    popupDisplay += `  </tbody>
                        </table>`;

                    var tooltipPosition = '';
                    var tooltipOffset = [0, 0]; // x, y
                    switch (feature.styling.LabelPosition) {
                        case 'topLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, -20]; // x, y
                            break;
                        case 'topCenter':
                            tooltipPosition = 'top';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'topRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, -20]; // x, y
                            break;
                        case 'middleLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'middleCenter':
                            tooltipPosition = 'center';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'middleRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'bottomLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, 20]; // x, y
                            break;
                        case 'bottomCenter':
                            tooltipPosition = 'bottom';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'bottomRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, 20]; // x, y
                            break;
                    }
                    return marker(latlng, {
                        icon: divIcon({
                            html:
                                feature.styling.Symbology == 'arrow'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.5645px" viewBox="0 0 10.5645 5.1655" height="5.1655px">  <path data-name="Path 32096" d="M235.528,285.054l5.09-4.056a1.108,1.108,0,0,0,0-1.733l-5.09-4.056a1.108,1.108,0,0,0-1.8.866v1.591H221.015a1.108,1.108,0,0,0-1.108,1.108v2.713a1.108,1.108,0,0,0,1.108,1.108H233.73v1.591A1.108,1.108,0,0,0,235.528,285.054Z" transform="scale(0.5 0.5) translate(-219.906 -274.967)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'circle'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'cross3'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="8.958px" viewBox="0 0 8.958 10.5365" height="10.5365px">  <path data-name="Union 7" d="M.047,18.277l5.271-7.814L0,2.868,4.1,0,9.718,8.029h8.2v5H9.9l-.527.369L4.192,21.073Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" transform="scale(0.5 0.5)" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'cross'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="9.5px" viewBox="0 0 9.5 9.5" height="9.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Union_8" data-name="Union 8" d="M7,19V12H0V7H7V0h5V7h7v5H12v7Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke-width="3"/></svg>'
                                    : feature.styling.Symbology == 'cross5'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="8.0305px" viewBox="0 0 8.0305 8.426" height="8.426px">  <path data-name="Union 9" d="M7.14,8.372,7,9l.14-.628L7,8l.14.372L7.222,8h0L9,0,7.222,8h0l-.083.372L10,16ZM6,8,0,14ZM0,3,6,8Z" transform="scale(0.5 0.5) translate(1.061 0.325)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke-width="3"/></g></g></svg>'
                                    : feature.styling.Symbology == 'flag'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="6.6065px" viewBox="0 0 6.6065 8.3425" height="8.3425px">  <g data-name="Group 12386" transform="scale(0.5 0.5) translate(19439.5 10083.185)"> <line id="Line_1338" data-name="Line 1338" y1="16.676" transform="translate(-19438.5 -10083.177)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke-width="2"/> <path id="Path_32097" data-name="Path 32097" d="M-19641.287-10072.161h-12.227v-8.347h12.227l-4.859,4.028Z" transform="translate(215 -2.677)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/>  </g></svg>'
                                    : feature.styling.Symbology == 'polygon3'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 12.0" height="12.0px">  <path data-name="Polygon 5" d="M12,0,24,20H0Z" transform="scale(0.5 0.5) translate(20) rotate(90)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'polygon4'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.0" height="13.0px">  <path data-name="Polygon 6" d="M13,0,26,13,13,26,0,13Z" transform="scale(0.5 0.5)translate(26) rotate(90)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'polygon5'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 13.669" height="13.669px">  <path data-name="Polygon 7" d="M13.669,0,27.338,9.931,22.117,26H5.221L0,9.931Z" transform="scale(0.5 0.5) translate(26) rotate(90)"  fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'star'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="12.0px" viewBox="0 0 12.0 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_10" data-name="Polygon 10" d="M12,0l3,8.553,9,.232L16.854,14.3,19.416,23,12,17.857,4.584,23l2.562-8.7L0,8.785l9-.232Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></g></g></svg>'
                                    : feature.styling.Symbology == 'star3'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" width="13.0px" viewBox="0 0 13.0 11.5" height="11.5px">  <path data-name="Polygon 8" d="M13,0l3.9,13.033L26,23,13,19.933,0,23l9.1-9.967Z" transform="scale(0.5 0.5)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>'
                                    : feature.styling.Symbology == 'star4'
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="11.5px" viewBox="0 0 11.5 11.5" height="11.5px"><g><g transform="translate(0, 0) scale(0.5 0.5) "><path id="Polygon_9" data-name="Polygon 9" d="M11.5,0l3.45,8.05L23,11.5l-8.05,3.45L11.5,23,8.05,14.95,0,11.5,8.05,8.05Z" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></g></g></svg>'
                                    : '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                                      feature.styling.SymbologyColour +
                                      '" stroke="' +
                                      feature.styling.SymbologyColour +
                                      '"/></svg>',
                            className:
                                'ThisHasToBeSomethingToHideTheDefaultShadow',
                        }),
                        opacity: feature.styling.Opacity / 100,
                    })
                        .bindTooltip(labelDisplay, {
                            permanent: true,
                            direction: tooltipPosition,
                            className: 'leafletLabelClass',
                            offset: tooltipOffset,
                        })
                        .openTooltip()
                        .bindPopup(popupDisplay, {
                            permanent: false,
                            sticky: false,
                            autoPanPaddingTopLeft: [20, 20],
                            maxWidth: 'auto',
                            minWith: '50px',
                            className: 'leafletPopupClass',
                        });
                },
            };
        },
    },
    methods: {
        mapSizeRefresh() {
            this.$refs.mainMap.mapObject.invalidateSize();
        },
        modalClose() {
            this.$store.commit('projects/setMapExpandedOff');
            this.$bvModal.hide('modal-screen');
            // this.$refs.mainMap.mapObject.invalidateSize();
        },
        convertLat(val) {
            val = val % 180;
            if (val < -90) {
                val += 180;
            } else if (val > 90) {
                val -= 180;
            }
            return parseFloat(val.toFixed(6));
        },
        convertLong(val) {
            val = val % 360;
            if (val < -180) {
                val += 360;
            } else if (val > 180) {
                val -= 360;
            }
            return parseFloat(val.toFixed(6));
        },
        zoomToPosted(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                let xCoord = location.geometry.coordinates[0];
                let yCoord = location.geometry.coordinates[1];
                minX = Math.min(minX, parseFloat(xCoord));
                maxX = Math.max(maxX, parseFloat(xCoord));
                minY = Math.min(minY, parseFloat(yCoord));
                maxY = Math.max(maxY, parseFloat(yCoord));
            });
            var mapBounds = latLngBounds([
                [minY, minX],
                [maxY, maxX],
            ]);
            this.$refs.mainMap.mapObject.fitBounds(mapBounds);
        },
        zoomToLayers() {
            if (this.projects.length == 0) {
                setTimeout(() => {
                    return this.zoomToLayers();
                }, 2000);
                return;
            }
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;

            this.projects.forEach((project) => {
                project.ProjectLayers.forEach((projLayer) => {
                    if (projLayer.Geometry.visible) {
                        projLayer.Geometry.features.forEach((location) => {
                            if (location.geometry.type == 'MultiPolygon') {
                                location.geometry.coordinates[0].forEach(
                                    (polygon) => {
                                        polygon.forEach((point) => {
                                            let xCoord = point[0];
                                            let yCoord = point[1];
                                            if (
                                                !isNaN(xCoord) &&
                                                !isNaN(yCoord)
                                            ) {
                                                minX = Math.min(
                                                    minX,
                                                    parseFloat(xCoord)
                                                );
                                                maxX = Math.max(
                                                    maxX,
                                                    parseFloat(xCoord)
                                                );
                                                minY = Math.min(
                                                    minY,
                                                    parseFloat(yCoord)
                                                );
                                                maxY = Math.max(
                                                    maxY,
                                                    parseFloat(yCoord)
                                                );
                                            }
                                        });
                                    }
                                );
                            } else if (
                                location.geometry.type == 'Polygon' ||
                                location.geometry.type == 'MultiLineString'
                            ) {
                                location.geometry.coordinates[0].forEach(
                                    (point) => {
                                        let xCoord = point[0];
                                        let yCoord = point[1];
                                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                                            minX = Math.min(
                                                minX,
                                                parseFloat(xCoord)
                                            );
                                            maxX = Math.max(
                                                maxX,
                                                parseFloat(xCoord)
                                            );
                                            minY = Math.min(
                                                minY,
                                                parseFloat(yCoord)
                                            );
                                            maxY = Math.max(
                                                maxY,
                                                parseFloat(yCoord)
                                            );
                                        }
                                    }
                                );
                            } else if (location.geometry.type == 'Point') {
                                let xCoord = location.geometry.coordinates[0];
                                let yCoord = location.geometry.coordinates[1];
                                if (!isNaN(xCoord) && !isNaN(yCoord)) {
                                    minX = Math.min(minX, parseFloat(xCoord));
                                    maxX = Math.max(maxX, parseFloat(xCoord));
                                    minY = Math.min(minY, parseFloat(yCoord));
                                    maxY = Math.max(maxY, parseFloat(yCoord));
                                }
                            } else if (location.geometry.type == 'LineString') {
                                location.geometry.coordinates.forEach(
                                    (point) => {
                                        let xCoord = point[0];
                                        let yCoord = point[1];
                                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                                            minX = Math.min(
                                                minX,
                                                parseFloat(xCoord)
                                            );
                                            maxX = Math.max(
                                                maxX,
                                                parseFloat(xCoord)
                                            );
                                            minY = Math.min(
                                                minY,
                                                parseFloat(yCoord)
                                            );
                                            maxY = Math.max(
                                                maxY,
                                                parseFloat(yCoord)
                                            );
                                        }
                                    }
                                );
                            }
                        });
                    }
                });
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
            var mapBounds = latLngBounds([
                [
                    this.convertLat(minY - yRange * zoomCushion),
                    this.convertLong(minX - xRange * zoomCushion),
                ],
                [
                    this.convertLat(maxY + yRange * zoomCushion),
                    this.convertLong(maxX + xRange * zoomCushion),
                ],
            ]);
            this.$refs.mainMap.mapObject.fitBounds(mapBounds);
        },
        modalOpen() {
            this.$store.commit('projects/setMapExpandedOn');
            this.$bvModal.show('modal-screen');
        },
        baseMapChanged(e) {
            //
            let basemaplayer = e.name;
            let payload = '';
            if (basemaplayer.toLowerCase().includes('topo')) {
                payload = 'topo';
            } else if (basemaplayer.toLowerCase().includes('imagery')) {
                payload = 'imagery';
            } else if (basemaplayer.toLowerCase().includes('open')) {
                payload = 'open';
            } else if (basemaplayer.toLowerCase().includes('nomap')) {
                payload = 'none';
            }
            this.$store.commit('projects/updateBaseMap', payload);
        },
        openDocument(index) {
            let fileName = '';
            let downloadType = '';
            let documentPath = '';
            this.clickedGeometry.properties.forEach((item) => {
                if (item.name == 'Files') {
                    fileName = item.value[index].fileName;
                    downloadType = item.value[index].source;
                    documentPath = item.value[index].path;
                    commonService.downloadDocument(
                        fileName,
                        downloadType,
                        documentPath
                    );
                }
            });
        },
        updateZoom(zoom) {
            this.$store.commit('projects/updateMapZoom', zoom);
            //get list of projects and their project layers with labels enabled, and toggle them on/off to trigger map label reactive-ness when zoom level changes
            try {
                this.projects.forEach((proj, index) => {
                    proj.ProjectLayers.forEach((lyr, idx) => {
                        if (
                            lyr.Geometry.labelVisibleIndex !== undefined &&
                            lyr.ShowLabels
                        ) {
                            this.$store.dispatch(
                                'projectLayers/toggleProjectLayerLabels',
                                { index: index, idx: idx }
                            );
                            this.$store.dispatch(
                                'projectLayers/toggleProjectLayerLabels',
                                { index: index, idx: idx }
                            );
                        }
                    });
                });
            } catch (error) {
                //nothing
            }
        },
        updateCenter(center) {
            this.$store.commit('projects/updateMapCenter', center);
        },
        getBrowserType() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (
                (navigator.userAgent.indexOf('Edg') ||
                    navigator.userAgent.indexOf('Edge')) != -1
            ) {
                return 'Edge';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                //IF IE > 10
                return 'IE';
            } else {
                return 'unknown';
            }
        },
        print() {
            //TO-DO: this function needs to be patched
            setTimeout(function() {
                this.$refs.mainMap.mapObject.invalidateSize();
            }, 500);
            const modal = document.getElementById('mapContainer');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');
            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }
            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            var userBrowser = this.getBrowserType();
            var topMargin = '0';
            var leftMargin = '0';
            switch (userBrowser) {
                case 'Chrome':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
                case 'Firefox':
                    topMargin = '0.5in';
                    leftMargin = '0';
                    break;
                case 'Edge':
                    topMargin = '0.1in';
                    leftMargin = '0.1in';
                    break;
            }
            css =
                '@page { size: landscape; } @media print {#mapContainer{position:absolute; height:7.5in; width:10in; top:' +
                topMargin +
                ';left:' +
                leftMargin +
                ';}}';
            this.$refs.mainMap.mapObject.invalidateSize();
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            setTimeout(function() {
                window.print();
            }, 500);
            this.closeModal();
        },
        reclusterProject() {
            // remove all the layers from the cluster
            if (this.projectClusterLyrs.length > 0) {
                this.$refs.projectCluster.mapObject.clearLayers();
                this.$refs.projectClusterGeoJson.forEach((layer) => {
                    //  for each project layer, if visible, add back to the cluster
                    if (layer.visible == true) {
                        this.$refs.projectCluster.mapObject.addLayers(
                            layer.mapObject.getLayers()
                        );
                    } else {
                        this.$refs.projectCluster.mapObject.removeLayers(
                            layer.mapObject.getLayers()
                        );
                    }
                });
            }
        },
        reclusterPosted() {
            if (this.postedClusterLyrs.length > 0) {
                this.$refs.postedCluster.mapObject.clearLayers();
                this.$refs.postedClusterGeoJson.forEach((layer) => {
                    if (layer.visible == true) {
                        this.$refs.postedCluster.mapObject.addLayers(
                            layer.mapObject.getLayers()
                        );
                    } else {
                        this.$refs.postedCluster.mapObject.removeLayers(
                            layer.mapObject.getLayers()
                        );
                    }
                });
            }
        },
        cleanupShapes() {
            var i = this.drawLines.length;
            while (i--) {
                if (this.drawLines.at(i).vertices.length < 2) {
                    this.drawLines.splice(i, 1);
                }
            }
            i = this.drawPolygons.length;
            while (i--) {
                if (this.drawPolygons.at(i).vertices.length < 3) {
                    this.drawPolygons.splice(i, 1);
                }
            }
        },
        drawPolygon() {
            this.cleanupShapes();
            this.drawMode = 'polygon';
            this.drawLines.push({
                id: 'L' + this.drawLines.length,
                geometry: {
                    coordinates: [[]],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.drawShapeColor,
                },
            });
            this.drawPolygons.push({
                id: 'P' + this.drawPolygons.length,
                geometry: {
                    coordinates: [[[]]],
                    type: 'Polygon',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonColor: this.drawShapeColor,
                    PolygonBorderColour: this.drawShapeColor,
                    PolygonTransparency: 0.8,
                },
            });
        },
        handleMouseClick(event) {
            var [lat, lng] = spatialFunctions.validateCoordinates(event);
            let markerFeature = {
                // using letters and the count to create unique values between different object arrays
                //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                //    but not errors that will cause issues in the actual execution/functionality
                id: 'C' + this.drawMarkers.length,
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lng],
                    coordSys: 'LatLong',
                },
                properties: {},
                styling: {
                    SymbologyColour: this.drawShapeColor,
                    SymbologySize: 1,
                    PolygonColor: this.drawShapeColor,
                    PolygonTransparency: 0,
                },
            };
            switch (this.drawMode) {
                case 'point':
                    this.drawMarkers.push(markerFeature);
                    // only want one point at a time
                    spatialService
                        .validateGeometry({
                            Geometry: spatialFunctions.convertToWKT(
                                this.drawMarkers.at(-1)
                            ),
                        })
                        .then((isValid) => {
                            if (
                                isValid &&
                                this.saveDrawing &&
                                !this.drawMode.includes('measure')
                            ) {
                                this.showAttributeModal(
                                    spatialFunctions.convertToWKT(
                                        this.drawMarkers.at(-1)
                                    )
                                );
                            }
                        });
                    this.drawMode = '';
                    break;

                case 'line':
                    // if it is the first point, draw a point
                    if (
                        this.drawLines.at(-1).geometry.coordinates[0].length ==
                        0
                    ) {
                        this.drawMarkers.push(markerFeature);
                    } else if (
                        this.drawLines.at(-1).geometry.coordinates[0].length ==
                        1
                    ) {
                        this.drawMarkers.pop();
                    }
                    this.drawLines
                        .at(-1)
                        .geometry.coordinates[0].push([lat, lng]);
                    break;

                case 'polygon':
                    // if it is the first point, draw a point
                    if (
                        this.drawPolygons.at(-1).geometry.coordinates[0][0]
                            .length == 0
                    ) {
                        this.drawMarkers.push(markerFeature);
                        this.drawPolygons
                            .at(-1)
                            .geometry.coordinates[0][0].push([lat, lng]);
                    } else if (
                        this.drawLines.at(-1).geometry.coordinates[0].length ==
                        1
                    ) {
                        this.drawMarkers.pop();
                    }
                    this.drawLines
                        .at(-1)
                        .geometry.coordinates[0].push([lat, lng]);
                    this.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0][0].push([lat, lng]);
                    break;
            }
        },
        handleMouseDblClick(event) {
            var wktGeom = '';
            switch (this.drawMode) {
                case 'point':
                    this.drawMode = '';
                    wktGeom = spatialFunctions.convertToWKT(
                        this.drawMarkers.at(-1)
                    );
                    break;
                case 'line':
                    this.drawMode = '';
                    // get rid of the vertex added from the 2nd click in the double
                    this.drawLines.at(-1).geometry.coordinates[0].pop();
                    wktGeom = spatialFunctions.convertToWKT(
                        this.drawLines.at(-1)
                    );
                    break;
                case 'polygon':
                    this.drawLines.pop();
                    // get rid of the vertex added from the 2nd click in the double click
                    this.drawPolygons.at(-1).geometry.coordinates[0].pop();
                    this.drawPolygons.at(-1).styling.PolygonTransparency = 0;
                    this.drawMode = '';
                    wktGeom = spatialFunctions.convertToWKT(
                        this.drawPolygons.at(-1)
                    );
                    break;
            }
            if (wktGeom) {
                spatialService
                    .validateGeometry({ Geometry: wktGeom })
                    .then((isValid) => {
                        //any other handling needed here?
                        this.$parent.$emit('threeDVisDrawGeom', wktGeom);
                    });
            }
        },
        clearGeometry() {
            //clear 3D visualiztion user drawing
            this.drawMarkers = [];
            this.drawLines = [];
            this.drawPolygons = [];
        },
    },
};
</script>

<style scoped>
.loadingText {
    position: absolute;
    left: 100px;
    width: 230px;
    bottom: 10px;
    font-size: 10px;
}
</style>

<style>
.leafletPopupClass {
    min-width: 50px;
    max-width: 300px;
    width: fit-content;
    white-space: nowrap;
}
</style>
