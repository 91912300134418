<template>
    <div class="weatherInfo">
        <div
            class="position"
            v-if="displayMode == 'simple' && weatherData.current"
        >
            <img
                class="img-weather"
                :src="weatherIconUrl(weatherData.current.weather[0].icon)"
            />
            <span class="weatherContent" v-if="tempUnits == '°C'">
                {{ KelvinToCelsius(weatherData.current.temp) }}</span
            >
            <span class="weatherContent" v-else>
                {{ KelvinToFahrenheit(weatherData.current.temp) }}</span
            >
            <span class="weatherContent">{{ tempUnits }}</span>
            <span>
                <button class="tempButtons" @click="changeTempUnits('°C')">
                    ˚C
                </button>
                |
                <button class="tempButtons" @click="changeTempUnits('°F')">
                    ˚F
                </button>
            </span>
        </div>
        <!-- detailed weather view starts here -->
        <div v-else-if="weatherData.current">
            <div>
                <div class="accordion" role="tablist">
                    <b-card no-body class="mb-1">
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                class="btn-weather d-flex justify-content-start"
                                block
                                v-b-toggle.accordion-1
                                >Current conditions</b-button
                            >
                        </b-card-header>
                        <b-collapse
                            id="accordion-1"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                            <b-card-body>
                                <b-row class="mobileRowAlignCenter ml-1">
                                    <b-col class="">
                                        <img
                                            class="img-weather"
                                            :src="
                                                weatherIconUrl(
                                                    weatherData.current
                                                        .weather[0].icon
                                                )
                                            "
                                        />
                                        <br />
                                        <span class="pl-1">
                                            {{ formatDescription }}</span
                                        >
                                    </b-col>
                                    <b-col class="mt-4">
                                        <b-row class="mobileRowAlignCenter">
                                            <h1 v-if="tempUnits == '°C'">
                                                {{
                                                    KelvinToCelsius(
                                                        weatherData.current.temp
                                                    )
                                                }}
                                            </h1>
                                            <h1 v-else>
                                                {{
                                                    KelvinToFahrenheit(
                                                        weatherData.current.temp
                                                    )
                                                }}
                                            </h1>
                                            <h1>{{ tempUnits }}</h1>
                                        </b-row>
                                        <b-row class="mobileRowAlignCenter">
                                            <button
                                                class="tempButtons"
                                                @click="changeTempUnits('°C')"
                                            >
                                                ˚C
                                            </button>
                                            |
                                            <button
                                                class="tempButtons"
                                                @click="changeTempUnits('°F')"
                                            >
                                                ˚F
                                            </button>
                                        </b-row>
                                    </b-col>
                                    <b-col class="mt-4">
                                        <h5>Wind:</h5>
                                        <h4 v-if="tempUnits == '°C'">
                                            {{ compassDirection }}
                                            {{ windSpeed }}km/h
                                        </h4>
                                        <h4 v-else>
                                            {{ compassDirection }}
                                            {{ KmhToMph(windSpeed) }}mph
                                        </h4>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-4">
                                    <b-col>
                                        <h5>Sunrise:</h5>
                                        <h4>{{ sunrise }}</h4>
                                    </b-col>
                                    <b-col>
                                        <h5>Sunset:</h5>
                                        <h4>{{ sunset }}</h4>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                class="btn-weather d-flex justify-content-start"
                                block
                                v-b-toggle.accordion-2
                                >More details</b-button
                            >
                        </b-card-header>
                        <b-collapse
                            id="accordion-2"
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                            <b-card-body>
                                <b-row class="ml-1 mt-2">
                                    <b-col>
                                        <b-row>
                                            <h5>Temperature:</h5>
                                        </b-row>
                                        <b-row>
                                            <h1 v-if="tempUnits == '°C'">
                                                {{
                                                    KelvinToCelsius(
                                                        weatherData.current.temp
                                                    )
                                                }}
                                            </h1>
                                            <h1 v-else>
                                                {{
                                                    KelvinToFahrenheit(
                                                        weatherData.current.temp
                                                    )
                                                }}
                                            </h1>
                                            <h1>{{ tempUnits }}</h1>
                                        </b-row>
                                    </b-col>
                                    <b-col>
                                        <b-row>
                                            <h5>Pressure:</h5>
                                        </b-row>
                                        <b-row>
                                            <h1>
                                                {{
                                                    KelvinToFahrenheit(
                                                        weatherData.current
                                                            .pressure
                                                    )
                                                }}
                                            </h1>
                                            <h1>kPa</h1>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="ml-1 mt-2">
                                    <b-col>
                                        <b-row>
                                            <h5>Dew Point:</h5>
                                        </b-row>
                                        <b-row>
                                            <h1 v-if="tempUnits == '°C'">
                                                {{
                                                    KelvinToCelsius(
                                                        weatherData.current
                                                            .dew_point
                                                    )
                                                }}
                                            </h1>
                                            <h1 v-else>
                                                {{
                                                    KelvinToFahrenheit(
                                                        weatherData.current
                                                            .dew_point
                                                    )
                                                }}
                                            </h1>
                                            <h1>{{ tempUnits }}</h1>
                                        </b-row>
                                    </b-col>
                                    <b-col>
                                        <b-row>
                                            <h5>Visibility:</h5>
                                        </b-row>
                                        <b-row>
                                            <h1 v-if="tempUnits == '°C'">
                                                {{
                                                    mToKm(
                                                        weatherData.current
                                                            .visibility
                                                    )
                                                }}
                                            </h1>
                                            <h1 v-else>
                                                {{
                                                    mToMiles(
                                                        weatherData.current
                                                            .visibility
                                                    )
                                                }}
                                            </h1>
                                            <h1 v-if="tempUnits == '°C'">km</h1>
                                            <h1 v-else>miles</h1>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="ml-1 mt-2">
                                    <b-col>
                                        <b-row>
                                            <h5>Humidity:</h5>
                                        </b-row>
                                        <b-row>
                                            <h1>
                                                {{
                                                    weatherData.current.humidity
                                                }}
                                            </h1>
                                            <h1>%</h1>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                class="btn-weather d-flex justify-content-start"
                                block
                                v-b-toggle.accordion-3
                                >Forecast</b-button
                            >
                        </b-card-header>
                        <b-collapse
                            id="accordion-3"
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                            <b-card-body class="noPadding topPadding">
                                <b-container class="noPadding">
                                    <b-row>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[0] }}
                                                <br />
                                                {{ currentDate }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[0]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[0]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[0]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[0]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[1] }}
                                                <br />
                                                {{ daysArray[0] }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[1]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[1]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[1]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[1]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek  d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[2] }}
                                                <br />
                                                {{ daysArray[1] }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[2]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[2]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[2]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[2]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[3] }}
                                                <br />
                                                {{ daysArray[2] }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[3]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[3]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[3]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[3]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[4] }}
                                                <br />
                                                {{ daysArray[3] }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[4]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[4]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[4]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[4]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[5] }}
                                                <br />
                                                {{ daysArray[4] }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[5]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[5]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[5]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[5]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                        <b-col
                                            class="dayColumn col-4 col-md-6 col-lg-3"
                                        >
                                            <b-row
                                                class="dayWeek d-flex justify-content-center"
                                            >
                                                {{ dayNameArray[6] }}
                                                <br />
                                                {{ daysArray[5] }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <img
                                                    class="img-weather"
                                                    :src="
                                                        weatherIconUrl(
                                                            weatherData.daily[6]
                                                                .weather[0].icon
                                                        )
                                                    "
                                                />
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span v-if="tempUnits == '°C'">
                                                    {{
                                                        KelvinToCelsius(
                                                            weatherData.daily[6]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                <span v-else>
                                                    {{
                                                        KelvinToFahrenheit(
                                                            weatherData.daily[6]
                                                                .temp.day
                                                        )
                                                    }}
                                                </span>
                                                {{ tempUnits }}
                                            </b-row>
                                            <b-row
                                                class="d-flex justify-content-center"
                                            >
                                                <span class="weatherDesc">
                                                    {{
                                                        formatForecastDescription[6]
                                                    }}
                                                </span>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-container>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                class="btn-weather d-flex justify-content-start"
                                block
                                v-b-toggle.accordion-4
                                >Windy.com</b-button
                            >
                        </b-card-header>
                        <b-collapse
                            id="accordion-4"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                            <b-card-body>
                                <b-row class="pl-3">
                                    <b-col
                                        md="5"
                                        lg="5"
                                        class="mobileColAlignCenter pr-0"
                                    >
                                        <a
                                            :href="windyUrl"
                                            class="windyLink"
                                            target="_blank"
                                            ><img
                                                src="../../assets/tide-forecast-logo.png"
                                                class="img mb-md-5 mb-2"
                                        /></a>
                                    </b-col>
                                    <b-col class="mobileColAlignCenter pl-0">
                                        <a
                                            :href="windyUrl"
                                            class="windyLink"
                                            target="_blank"
                                        >
                                            View more weather forecast
                                            information and maps in Windy.com</a
                                        >
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>

                    <b-card no-body class="mb-1">
                        <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                        >
                            <b-button
                                class="btn-weather d-flex justify-content-start"
                                block
                                v-b-toggle.accordion-5
                                >Tide-Forecast</b-button
                            >
                        </b-card-header>
                        <b-collapse
                            id="accordion-5"
                            visible
                            accordion="my-accordion"
                            role="tabpanel"
                        >
                            <b-card-body>
                                <b-row class="pl-3">
                                    <b-col
                                        md="8"
                                        lg="5"
                                        class="mobileColAlignCenter pr-0"
                                    >
                                        <a
                                            href="https://www.tide-forecast.com"
                                            class="windyLink"
                                            target="_blank"
                                            ><img
                                                src="../../assets/tide-forecast-logo.svg"
                                                class="img"
                                        /></a>
                                    </b-col>
                                    <b-col class="mobileColAlignCenter pl-0">
                                        <a
                                            href="https://www.tide-forecast.com"
                                            class="windyLink"
                                            target="_blank"
                                        >
                                            View tide information and maps</a
                                        >
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'Weather',
    props: {
        displayMode: {
            //determine whether to show weather in Simple or Details mode
            type: String,
            // default: function () {
            //   return "Simple";
            // },
        },
        // tempUnit: {
        //   //determine whether to show weather in Simple or Details mode
        //   type: String,
        //   default: function () {
        //     return "°C"; // or "°F"
        //   },
        // },
        //32.744134340543475, -117.17425773080255
        lat: {
            //WGS84 format
            type: Number,
            default: function() {
                return 32.744134;
            },
        },
        long: {
            //WGS84 format
            type: Number,
            default: function() {
                return -117.174257;
            },
        },
    },
    created() {
        setTimeout(() => {
            this.getWeatherData();
            this.startInterval();
            this.setTempUnit();
        }, 1000);
    },
    beforeDestroy() {
        this.clearInterval();
    },
    data() {
        return {
            weatherData: {
                current: {
                    clouds: 100,
                    dew_point: 268.44,
                    dt: 1644555595,
                    feels_like: 266.23,
                    humidity: 80,
                    pressure: 1009,
                    sunrise: 1644496035,
                    sunset: 1644533107,
                    temp: 271.08,
                    uvi: 0,
                    visibility: 10000,
                    weather: [
                        {
                            id: 804,
                            main: 'Clouds',
                            description: 'overcast clouds',
                            icon: '04n',
                        },
                    ],
                    wind_deg: 220,
                    wind_speed: 4.12,
                },
            },
        };
    },
    computed: {
        currentDate: function() {
            var date = new Date();
            var dd = String(date.getDate()).padStart(2, '0');
            var month = date.toLocaleString('default', { month: 'long' });
            const todaysDate = dd + ' ' + month;
            return todaysDate;
        },
        // number of the month
        daysArray: function() {
            var arrayOfDays = [];
            var date = new Date();
            for (let days = 0; days < 7; days++) {
                date.setDate(date.getDate() + 1);
                var dd = String(date.getDate()).padStart(2, '0');
                var month = date.toLocaleString('default', { month: 'long' });
                const todaysDate = dd + ' ' + month;
                arrayOfDays.push(todaysDate);
            }
            return arrayOfDays;
        },
        // day of the week
        dayNameArray: function() {
            var dateNames = [];
            for (var i = 0; i <= 6; i++) {
                var currentDate = new Date();
                currentDate.setDate(currentDate.getDate() + i);
                var day = currentDate.getDay();
                switch (day) {
                    case 0:
                        day = 'Sunday';
                        break;
                    case 1:
                        day = 'Monday';
                        break;
                    case 2:
                        day = 'Tuesday';
                        break;
                    case 3:
                        day = 'Wednesday';
                        break;
                    case 4:
                        day = 'Thursday';
                        break;
                    case 5:
                        day = 'Friday';
                        break;
                    case 6:
                        day = 'Saturday';
                }
                dateNames.push(day);
            }
            return dateNames;
        },
        // format the description in current conditions
        formatDescription: function() {
            var stringToUpper = this.weatherData.current.weather[0].description;
            return stringToUpper[0].toUpperCase() + stringToUpper.slice(1);
        },
        // format the descriptions in the 7 day forecast
        formatForecastDescription: function() {
            var arrayOfDesc = [];
            for (let i = 0; i < 7; i++) {
                var stringToUpper = this.weatherData.daily[i].weather[0]
                    .description;
                var formatted =
                    stringToUpper[0].toUpperCase() + stringToUpper.slice(1);
                arrayOfDesc.push(formatted);
            }
            return arrayOfDesc;
        },
        // convert from degrees to compass direction
        compassDirection: function() {
            var num = this.weatherData.current.wind_deg;
            var val = Math.floor(num / 22.5 + 0.5);
            var arr = [
                'N',
                'NNE',
                'NE',
                'ENE',
                'E',
                'ESE',
                'SE',
                'SSE',
                'S',
                'SSW',
                'SW',
                'WSW',
                'W',
                'WNW',
                'NW',
                'NNW',
            ];
            return arr[val % 16];
        },
        // convert from meters per second to kilometers per hour
        windSpeed: function() {
            var currentSpeed = this.weatherData.current.wind_speed;
            var convertedSpeed = currentSpeed * 3.6;
            var roundedSpeed = Math.round(convertedSpeed);
            return roundedSpeed;
        },
        windyUrl() {
            return 'https://windy.com/?namConus,' + this.lat + ',' + this.long;
        },
        sunrise: function() {
            var sunriseEpoch = this.weatherData.current.sunrise;
            var date = new Date(sunriseEpoch * 1000);
            // Hours part from the timestamp
            var time = 0;
            var hours = date.getHours();
            if (hours > 12) {
                time = 'PM';
            } else {
                time = 'AM';
            }
            // Minutes part from the timestamp
            var minutes = '0' + date.getMinutes();
            // Seconds part from the timestamp
            var seconds = '0' + date.getSeconds();
            // Will display time in 10:30:23 format
            var formattedTime =
                hours +
                ':' +
                minutes.substr(-2) +
                ':' +
                seconds.substr(-2) +
                ' ' +
                time;
            return formattedTime;
        },
        sunset: function() {
            var sunsetEpoch = this.weatherData.current.sunset;
            var date = new Date(sunsetEpoch * 1000);
            // Hours part from the timestamp
            var time = 0;
            var hours = date.getHours();
            if (hours > 12) {
                time = 'PM';
            } else {
                time = 'AM';
            }
            // Minutes part from the timestamp
            var minutes = '0' + date.getMinutes();
            // Seconds part from the timestamp
            var seconds = '0' + date.getSeconds();
            // Will display time in 10:30:23 format
            var formattedTime =
                hours +
                ':' +
                minutes.substr(-2) +
                ':' +
                seconds.substr(-2) +
                ' ' +
                time;
            return formattedTime;
        },
        tempUnits: function() {
            return this.$store.state.emergencyManagement.weatherUnits;
        },
    },
    methods: {
        weatherIconUrl(val) {
            return `https://openweathermap.org/img/wn/${val}@2x.png`;
        },
        getWeatherData() {
            axios
                .get(
                    `https://djangodi-prod.azurewebsites.net/common/openweatherapi/?lat=${this.lat}&lon=${this.long}&token=6QSAQutj5r`,
                    {
                        headers: {
                            Authorization: 'none',
                        },
                    }
                )
                .then((res) => {
                    if (res.status == 200) {
                        this.weatherData = res.data;
                    }
                });
        },
        KelvinToCelsius(val) {
            return Math.round(val - 273.15, 0);
        },
        KelvinToFahrenheit(val) {
            return Math.round(((val - 273.15) * 9) / 5 + 32, 0);
        },
        KmhToMph(val) {
            return Math.round(val * 0.621371, 2);
        },
        mToKm(val) {
            return Math.round(val * 0.001, 6);
        },
        mToMiles(val) {
            return Math.round(val * 0.0006);
        },
        clearInterval: function() {
            clearTimeout(this.refreshData);
        },
        startInterval: function() {
            //refresh weather data every 20 minutes
            this.refreshData = setInterval(() => {
                this.getWeatherData();
            }, 12000000);
        },
        setTempUnit() {
            try {
                let countryName = this.$store.state.projects.selectedProjects[0]
                    .CountryName;
                if (countryName == 'USA') {
                    this.changeTempUnits('°F');
                } else {
                    this.changeTempUnits('°C');
                }
            } catch (error) {
                //nothing
            }
        },
        changeTempUnits(units) {
            this.$store.commit('emergencyManagement/toggleWeatherUnits', units);
        },
    },
};
</script>

<style scoped>
.tempButtons {
    background-color: white;
    border: none;
}
.tempButtons:hover {
    text-decoration: underline;
}
.btn-weather {
    background-color: white !important;
    color: black;
}
.btn-weather:hover {
    background-color: white !important;
    color: gray;
}
.btn-weather:focus {
    background-color: white !important;
    color: gray;
}
.dayColumn {
    height: 200px;
    min-width: 100px !important;
    padding-left: 5px;
    padding-right: 5px;
}
/* .dayWeek:hover{
  font-weight: bold;
} */
.noPadding {
    padding-left: none;
    padding-right: none;
}
.topPadding {
    padding-top: 15px !important;
}
.weatherDesc {
    font-size: 13px;
}
.not-collapsed {
    border: 2px solid #29d9f6 !important;
}
.img {
    height: 40px;
}

/* Desktop */
@media screen and (min-width: 769px) {
    .weatherInfo {
        height: 40px;
    }
    .position {
        position: relative;
        top: -26px;
    }
    .tideLink {
        position: relative;
        top: 40px;
    }
    .img-weather {
        width: 90px;
        height: 90px;
    }
    .weatherContent {
        font-size: 20px;
    }
}
/* Mobile */
@media screen and (max-width: 768px) {
    .weatherInfo {
        overflow-y: auto;
        max-height: 60vh;
        width: 91vw;
    }
    .img-weather {
        width: 70px;
        height: 70px;
    }
    .mobileRowAlignCenter {
        /* display: flex;   */
        justify-content: center;
        align-items: center;
    }
    .mobileColAlignCenter {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        text-align: center;
    }
    .weatherContent {
        font-size: 20px;
    }
}
</style>
