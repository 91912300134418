import { dataSourceService } from '../services';

const state = {
    datasources: {},
    dataGrid: {},
};

const actions = {
    getDataSources({ commit }) {
        commit('getAllRequest');

        dataSourceService.getDataSources().then(
            (datasources) => commit('getAllSuccess', datasources),
            (error) => commit('getAllFailure', error)
        );
    },
};
const mutations = {
    // get datasets
    getAllRequest(state) {
        state.datasources = { loading: true };
    },
    getAllSuccess(state, datasources) {
        state.datasources = { items: datasources.data };
    },
    getAllFailure(state, error) {
        state.datasources = { error };
    },
    mutateDatagrid(state, dataGrid) {
        state.dataGrid = {
            datasourceId: dataGrid.DataSourceId,
            datasetId: dataGrid.DatasetId,
            tablename: dataGrid.DatasetName,
            legacyProjectId: dataGrid.LegacyProjectIds[0],
        };
    },
};

const namespaced = true;

export const datasources = {
    namespaced,
    state,
    actions,
    mutations,
};
