<template>
    <div class="my-custom-floating-label">
        <VuelidateFloatingLabel
            @focus="focus = true"
            @blur="focus = false"
            v-bind:editUserParams1="editUserParams"
            :config="config"
            :errorOnBlank="errorOnBlank"
            :isValid="isValid"
        >
            <slot></slot>
        </VuelidateFloatingLabel>
    </div>
</template>

<script>
import VuelidateFloatingLabel from '../components/VuelidateFloatingLabel.vue';
export default {
    props: {
        config: {
            line: false,
            name: 'wrapper',
            scale: false,
        },
        editUserParams: {
            type: String,
            required: false,
            default: '',
        },
        errorOnBlank: {
            type: Boolean,
            required: false,
            default: false,
        },
        isValid: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    components: {
        VuelidateFloatingLabel,
    },
    created() {},
};
</script>
<style lang="scss">
.my-custom-floating-label {
    .input__container {
        border-radius: 3px;
        box-shadow: 0 0 0 1px #c6c6c6;
        .slot-container {
            position: relative;
            height: 48px;
        }
        &.input__container--focus {
            box-shadow: 0 0 0 2px #c6c6c6;
        }
    }
}
.label__placeholder {
    top: 50%;
    transform: translate(0, -50%);
    color: #626262;
    left: 17px;
    font-family: var(--ffr);
}
</style>
