var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"portal-settings"},[_c('Header'),_c('b-container',{staticClass:"sub_header_bg p-0",attrs:{"fluid":""}},[_c('b-container',{staticClass:"p-0"},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.navigateUsers}},[_c('img',{attrs:{"src":require("../../assets/arrow_left.svg")}}),_c('a',{staticClass:"floar-left ml-1 back",attrs:{"href":"#"}},[_vm._v("Back to dashboard")])])])],1),_c('b-row',{staticClass:"vh-10 text-left mr-0",attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"pt-2",attrs:{"cols":"7","lg":"7","md":"7"}},[_c('h4',[_vm._v("Portal settings")])]),_c('b-col',{staticClass:"m-view-b mrg-y top-z",attrs:{"cols":"2","lg":"3","md":"3"}},[_c('p',{staticClass:"float-right pl-3 m-0 sm-cancel cancel-link-portal",on:{"click":_vm.canceltoDashboard}},[_vm._v(" Cancel ")])]),_c('b-col',{staticClass:"p-0 text-right m-bottom-btn mrg-y",attrs:{"cols":"3","lg":"2","md":"2"}},[_c('b-button',{staticClass:"buttonSelect",attrs:{"rounded":"","disabled":!_vm.isValid || !_vm.isValidOption},on:{"click":_vm.saveSettings}},[_vm._v(" Save changes ")])],1)],1),_c('b-tabs',{staticClass:"pt-4 portal-content",attrs:{"content-class":"mt-3","active-nav-item-class":"pl-0 borderBlue"}},[_c('b-tab',{staticClass:"mt-4",attrs:{"title":"General","active":""}},[_c('h4',{staticClass:"portal-heading"},[_vm._v("Pagination")]),_c('p',{staticClass:"py-2"},[_vm._v(" Modify the number of records to display per page. ")]),_c('div',[_c('div',[_c('CustomFloatingLabel',{attrs:{"config":{
                                    label: 'Records per page',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.edit.Pagination),expression:"edit.Pagination"}],staticClass:"normal_input",attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.edit.Pagination)},on:{"keyup":function($event){return _vm.change($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.edit, "Pagination", $event.target.value)}}})]),(!_vm.isValid && _vm.edit.Pagination)?_c('small',{staticClass:"error"},[_vm._v("Please enter valid number")]):_vm._e(),_c('h4',{staticClass:"portal-heading"},[_vm._v(" Additional options ")]),_c('p',{staticClass:"py-2"},[_vm._v(" Modify the number of seconds before a warning occurs. ")]),_c('CustomFloatingLabel',{attrs:{"config":{
                                    label: 'Seconds',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.edit.AdditionalOptions),expression:"edit.AdditionalOptions"}],staticClass:"normal_input",attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(_vm.edit.AdditionalOptions)},on:{"keyup":function($event){return _vm.changeOption($event)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.edit, "AdditionalOptions", $event.target.value)}}})]),(
                                    !_vm.isValidOption && _vm.edit.AdditionalOptions
                                )?_c('small',{staticClass:"error"},[_vm._v("Please enter valid number")]):_vm._e()],1)])]),_c('b-tab',{attrs:{"title":"Tool settings"}},[_c('div',{staticClass:"col-md-6 mt-4 p-0 FloatingTool"},[_c('h4',[_vm._v("Posting tool")]),_c('p',{staticClass:"py-2"},[_vm._v("Default settings for uniqueness")]),_c('div',[_c('CustomFloatingLabel',{attrs:{"config":{
                                    label: 'Number',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }}},[_c('b-form-select',{key:_vm.edit.ProfileId,model:{value:(_vm.edit.Number),callback:function ($$v) {_vm.$set(_vm.edit, "Number", $$v)},expression:"edit.Number"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Number")]),_vm._l((_vm.numberArray),function(op){return _c('option',{key:op.id,domProps:{"value":op.id}},[_vm._v(" "+_vm._s(op.name)+" ")])})],2)],1),_c('CustomFloatingLabel',{attrs:{"config":{
                                    label: 'Date',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }}},[_c('b-form-select',{key:_vm.edit.ProfileId,model:{value:(_vm.edit.Date),callback:function ($$v) {_vm.$set(_vm.edit, "Date", $$v)},expression:"edit.Date"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Date")]),_vm._l((_vm.dateArray),function(op){return _c('option',{key:op.id,domProps:{"value":op.id}},[_vm._v(" "+_vm._s(op.name)+" ")])})],2)],1),_c('CustomFloatingLabel',{attrs:{"config":{
                                    label: 'Text',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }}},[_c('b-form-select',{key:_vm.edit.ProfileId,model:{value:(_vm.edit.Text),callback:function ($$v) {_vm.$set(_vm.edit, "Text", $$v)},expression:"edit.Text"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Text")]),_vm._l((_vm.textArray),function(op){return _c('option',{key:op.id,domProps:{"value":op.id}},[_vm._v(" "+_vm._s(op.name)+" ")])})],2)],1)],1),_c('h4',{staticClass:"mt-2 portal-heading"},[_vm._v("Graphing tool")]),_c('p',{staticClass:"py-1"},[_vm._v(" Select the default graph template orientation. ")]),_c('div',{staticClass:"input-group-prepend normal_input mt-3 mb-5"},[_c('input',{staticClass:"graphic",class:!_vm.isLandscape ||
                                    (!_vm.showhoverL && _vm.showhoverP)
                                        ? 'selectedGraphictool'
                                        : '',attrs:{"placeholder":"Portrait","value":"Portrait","type":"text","readonly":""},on:{"click":_vm.graphing}}),_c('input',{staticClass:"graphic",class:_vm.isLandscape ||
                                    (!_vm.showhoverP && _vm.showhoverL)
                                        ? 'selectedGraphictool'
                                        : '',attrs:{"placeholder":"Landscape","value":"Landscape","type":"text","readonly":""},on:{"click":_vm.graphing}})])])]),_c('ToastMessages',{attrs:{"showSuccess":_vm.showSuccess,"showFailure":_vm.showFailure,"failureToastMessage":_vm.failureToastMessage,"successToastMessage":_vm.successToastMessage}})],1)],1)],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }