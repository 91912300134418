<template>
    <div id="color-picker">
        <span id="circle-icon">
            <input
                type="color"
                ref="colorPicker"
                name="color"
                id="color"
                v-bind:value="value"
                @input="$emit('input', $event.target.value)"
            />
        </span>
        <span id="color-text">{{ value }}</span>
        <button id="eyedropper" @click="open" class="btn-open">
            <img src="../../assets/eyedropper.svg" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'ColorPicker',
    props: {
        value: {
            type: String,
            default: '#000000',
        },
    },
    computed: {},
    methods: {
        open(evt) {
            this.$refs.colorPicker.click();
            evt.stopPropagation();
        },
    },
};
</script>

<style scoped>
input[type='color'] {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    height: 200%;
    width: 200%;
    transform: translate(-25%, -25%);
}

#color-picker {
    height: 24px;
    width: 98%;
    display: flex;
    align-items: center;
    position: relative;
    top: 20px;
    left: 10px;
}

#eyedropper {
    background: none;
    border: none;
    position: absolute;
    right: 0;
    top: -9px;
    img {
        height: 30px;
        width: 30px;
    }
}
#circle-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    overflow: hidden;
}

#color-text {
    margin-left: 4px;
}
</style>
