import Vue from 'vue';
import Vuex from 'vuex';
import { projects } from './projects.module';
import { projectLayers } from './projectLayers.module';
import { common } from './common.module';
import { datasets } from './dataset.module';
import { datasources } from './datasource.module';
import { emergencyManagement } from './emergencyManagement.module';
import { oneMap } from './onemap.module';
import { gridFilters } from './gridFilters.module';
import { dreams } from './dreams.module';
import { store } from './store';
import { eTracker } from './eTracker.module';
import { dataCollection } from './dataCollection.module';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);
const vuexPersist = new VuexPersist({
    key: 'my-app',
    storage: window.localStorage,
    reducer: (state) => ({
        projects: state.projects,
        gridFilters: state.gridFilters,
        dreams: state.dreams,
        // projectLayers: state.projectLayers,
        common: state.common,
        datasets: state.datasets,
        datasources: state.datasources,
        // emergencyManagement: state.emergencyManagement,
        store: state.store,
    }),
});
export default new Vuex.Store({
    modules: {
        projects,
        projectLayers,
        common,
        datasets,
        datasources,
        emergencyManagement,
        store,
        oneMap,
        gridFilters,
        dreams,
        eTracker,
        dataCollection,
    },
    plugins: [vuexPersist.plugin],
});
