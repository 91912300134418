import { spatialFunctions } from '../utilities/spatialFunctions.js';
import { projectService } from '../services/project.service';
import Vue from 'vue';
const state = {
    drawingAttributes: {
        geoID: 0,
        geom: null,
        geomWKT: null,
        geomLocName: null,
        geomLocType: null,
        geomLocDesc: null,
        geomComment: null,
        geomLocIcon: null,
    },
    layersStruct: {},
    layersStructValues: [],
    showAttributeModal: false,
    projectID: null,
    weatherUnits: '°C',
    mapbookJobIDs: [],

    erDataLayerIDs: [],
    EventLogPositions: [],
    updateWMSLayersFlag: false,
    geowebcacheToken: null,
    vectors: [],
    vector_start_point: { Latitude: 0, Longitude: 0 },
    cumulative_vector: null,
};

// creates an array of children using the child's parentID
function getChildren(parentID, tree) {
    var children = [];
    tree.forEach((branch) => {
        if (branch.parentID == parentID) {
            children.push(branch.id);
        }
    });
    return children;
}
// updates down the tree, recurses through the nodes
function updateTreeBranches(startID, newVal, tree) {
    tree[startID].isChecked = newVal;
    tree[startID].children.forEach((child) => {
        tree[child].isChecked = newVal;
        if (tree[child].children.length > 0) {
            tree = updateTreeBranches(child, newVal, tree);
        }
    });
    return tree;
}

// updates up the tree, recurses through parents
function updateTreeTrunk(startID, newVal, tree) {
    var parent = tree[startID].parentID;
    if (parent > 0) {
        tree[parent].isChecked = newVal;
        tree = updateTreeTrunk(parent, newVal, tree);
    }
    return tree;
}

function buildLegendURL(wmsLayer, layer) {
    layer.legend = `${wmsLayer.WMSUrl}?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=${wmsLayer.WMSName}&STYLE=${wmsLayer.Style}`;

    if (wmsLayer.authkey !== '') {
        layer.options.authkey = wmsLayer.authkey;
        layer.legend += '&authkey=' + wmsLayer.authkey;
    } else {
        layer.options.geokey = wmsLayer.geokey;
        layer.legend += '&geokey=' + wmsLayer.geokey;
    }
    return layer;
}
const actions = {
    setProjectID({ commit }, projectID) {
        commit('mutateProjectID', projectID);
    },
    setDrawingAttributes({ commit }, feature = {}) {
        let newFeat = JSON.parse(JSON.stringify(feature));
        if (Object.keys(newFeat).length == 0) {
            commit('clearDrawingAttributes');
        } else {
            let featureObj = {};
            newFeat.properties.forEach((item) => {
                featureObj[item.label] = item.value;
            });
            // if the geometry is already a WKT string, no need to modify
            if (
                newFeat.geometry instanceof String ||
                typeof newFeat.geometry == 'string'
            ) {
                featureObj.geomWKT = feature.geometry;
            } else {
                featureObj.geomWKT = spatialFunctions.convertToWKT(
                    feature,
                    feature.geometry.coordSys == 'LatLong'
                );
            }
            featureObj.geom = feature.geometry;
            featureObj.projectID = feature.projectID;
            commit('loadDrawingAttributes', featureObj);
        }
    },
    updateDrawingGeometry({ commit }, geometry) {
        commit('mutateDrawingGeometry', geometry);
    },
    setShowAttributeModal({ commit }, showVal) {
        commit('updateShowAttributeModal', showVal);
    },
    resetStore({ commit, dispatch }) {
        commit('mutateProjectID', null);
        commit('setLayersStruct', null);
        dispatch('calculateLayerStructValues');
        commit('updateShowAttributeModal', false);
        commit('clearDrawingAttributes');
        commit('setWMSLayersFlag', false);
    },
    activateERLayers({ commit, state }) {
        state.erDataLayerIDs.forEach((layerID) => {
            commit('updateIsChecked', { id: layerID, visibility: 'show' });
        });
    },
    buildLayersStruct({ commit, dispatch }, payload) {
        const projectID = payload.projectID;
        const authkeys = payload.authkeys;
        var layersStruct = [];
        var erLayerIDs = [];
        projectService.getProjectLayers(projectID).then((res) => {
            var projectLayers = res.data;
            if (projectLayers[0].WMSLayer === null) {
                commit('setLayersStruct', {});
                dispatch('calculateLayerStructValues');
                return;
            }
            projectLayers[0].WMSLayer.forEach((wmsLayer) => {
                if (wmsLayer.WMSName.includes('vw_ER')) {
                    erLayerIDs.push(wmsLayer.WMSLayerId);
                }
                var layer = {
                    id: wmsLayer.WMSLayerId,
                    url: wmsLayer.WMSUrl,
                    name: wmsLayer.WMSLabel,
                    isChecked: wmsLayer.IsVisible,
                    format: wmsLayer.WMSFormat,
                    layers: wmsLayer.WMSName,
                    styles: wmsLayer.Style,
                    transparent: true,
                    attribution: '',
                    options: {
                        maxZoom: 21,
                        maxNativeZoom: 18,
                        editTime: 0,
                    },
                    legend: '',
                    hasChild: wmsLayer.WMSUrl == '',
                    isExpanded: false,
                    mapLayerID: wmsLayer.MapLayerID,
                    zIndex: wmsLayer.DrawOrder,
                    tileSize: wmsLayer.TileSize,
                    externalCache: wmsLayer.ExternalCache,
                    internalCache: wmsLayer.InternalCache,           
                    minZoom: wmsLayer.MinZoom,
                    maxZoom: wmsLayer.MaxZoom,
                    toc_groupOrder: wmsLayer.toc_groupOrder,
                    toc_layerOrder: wmsLayer.toc_layerOrder,
                };
                if (wmsLayer.BranchID !== undefined) {
                    layer.parentID = wmsLayer.BranchID;
                }
                if (wmsLayer.query !== undefined) {
                    layer.options['CQL_Filter'] = wmsLayer.query;
                }
                if (wmsLayer.WMSName.includes(':vw_ER_')) {
                    layer.options['editTime'] = Date.now();
                }
                // Build URL
                if (wmsLayer.WMSUrl != '') {
                    layer = buildLegendURL(wmsLayer, layer);
                }
                layersStruct.push(layer);
            });
            layersStruct.forEach((branch) => {
                branch['children'] = getChildren(branch.id, layersStruct);
            });
            var newLayersStruct = {};
            layersStruct.forEach((branch) => {
                newLayersStruct[branch.id] = branch;
            });
            commit('setERLayerIDs', erLayerIDs);
            commit('setLayersStruct', newLayersStruct);
            dispatch('calculateLayerStructValues');
        });
    },
    applyQuickTimeFilter({ commit, state }, filterTime) {
        filterTime = filterTime.replace(/[0-9]Z/, '');
        state.erDataLayerIDs.forEach((lyrID) => {
            let query = state.layersStruct[lyrID].options.CQL_Filter;
            if ((query === null || query.length) == 0) {
                commit('setCQL_Filter', {
                    lyrID: lyrID,
                    query: 'SampleDate >= ' + filterTime,
                });
            } else {
                if (query.includes('SampleDate')) {
                    query = query.replace(
                        /SampleDate >= [0-9]{4}(-[0-9]{2}){2}T[0-9]{2}(:[0-9]{2}){2}\.[0-9]{2}/g,
                        'SampleDate >= ' + filterTime
                    );
                } else {
                    query += ' AND SampleDate >= ' + filterTime;
                }
                commit('setCQL_Filter', {
                    lyrID: lyrID,
                    query: query,
                });
            }
            let legend_url = state.layersStruct[lyrID].legend;
            if (legend_url.includes('SampleDate')) {
                legend_url = legend_url.replace(
                    /SampleDate >= [0-9]{4}(-[0-9]{2}){2}T[0-9]{2}(:[0-9]{2}){2}\.[0-9]{2}/g,
                    'SampleDate >= ' + filterTime
                );
            } else {
                legend_url += ' AND SampleDate >= ' + filterTime;
            }
            commit('setLegendURL', {
                lyrID: lyrID,
                url: legend_url,
            });
        });
        commit('setWMSLayersFlag', true);
    },
    clearQuickTimeFilter({ commit, state }) {
        state.erDataLayerIDs.forEach((lyrID) => {
            let query = state.layersStruct[lyrID].options.CQL_Filter;
            query = query.replace(
                / AND SampleDate >= [0-9]{4}(-[0-9]{2}){2}T[0-9]{2}(:[0-9]{2}){2}\.[0-9]{2}/g,
                ''
            );
            let legend_url = state.layersStruct[lyrID].legend;
            legend_url = legend_url.replace(
                / AND SampleDate >= [0-9]{4}(-[0-9]{2}){2}T[0-9]{2}(:[0-9]{2}){2}\.[0-9]{2}/g,
                ''
            );

            commit('setCQL_Filter', {
                lyrID: lyrID,
                query: query,
            });
            commit('setLegendURL', {
                lyrID: lyrID,
                url: legend_url,
            });
        });
        commit('setWMSLayersFlag', true);
    },
    calculateLayerStructValues({ commit, state }) {
        var layersStructValues = [];
        if (state.layersStruct !== null && state.layersStruct !== undefined) {
            Object.keys(state.layersStruct).forEach((key) => {
                layersStructValues.push(state.layersStruct[key]);
            });
            commit('setLayersStructValues', layersStructValues);
        }
    },
    updateLayersStruct({ commit, dispatch }, payload) {
        commit('setLayersStruct', payload);
        dispatch('calculateLayerStructValues');
    },
    updateAuthkeys({ state, commit }, payload) {
        if (state.layersStruct !== null && state.layersStruct !== undefined) {
            Object.keys(state.layersStruct).forEach((layerID) => {
                commit('updateLegendURL', {
                    url: state.layersStruct[layerID].legend.replace(
                        /&authkey=[^&]*/,
                        payload.authkey
                    ),
                    layerID: layerID,
                });
            });
        }
    },
};
const mutations = {
    updateLegendURL(state, payload) {
        state.layersStruct[payload.layerID].legend = payload.url;
    },
    mutateProjectID(state, projectID) {
        state.projectID = projectID;
    },
    // get datasets
    clearDrawingAttributes(state) {
        state.drawingAttributes = {
            geoID: 0,
            geom: null,
            geomWKT: null,
            geomLocName: null,
            geomLocType: null,
            geomLocDesc: null,
            geomComment: null,
            geomLocIcon: null,
        };
    },
    setERLayerIDs(state, payload) {
        state.erDataLayerIDs = payload;
    },
    setEditTime(state, payload) {
        Vue.set(
            state.layersStruct[payload.layerID].options,
            'editTime',
            payload.editTime
        );
        //state.layersStruct[payload.layerID].options.editTime = payload.editTime
    },
    loadDrawingAttributes(state, feature) {
        state.drawingAttributes = {
            projectID: parseInt(feature.projectID),
            // if we have a geoid, use it, else 0
            geoID: feature.geoID > 0 ? feature.geoID : 0,
            geom: feature.geometry,
            geomWKT: feature.geomWKT,
            geomLocName: feature.geomLocName,
            geomLocType: feature.geomLocType,
            geomLocDesc: feature.geomLocDesc,
            geomComment: feature.geomComment,
            geomLocIcon: feature.geomLocIcon,
        };
        if (feature.geoID) {
            state.drawingAttributes.geoID = parseInt(feature.geoID);
        } else if (feature.FeatureID) {
            state.drawingAttributes.geoID = parseInt(feature.FeatureID);
        }
    },
    mutateDrawingGeometry(state, feature) {
        state.drawingAttributes.geom = feature.geometry;
        state.drawingAttributes.geomWKT = spatialFunctions.convertToWKT(
            feature
        );
    },
    setLayersStruct(state, struct) {
        state.layersStruct = struct;
    },
    toggleExpanded(state, itemID) {
        state.layersStruct[itemID].isExpanded = !state.layersStruct[itemID]
            .isExpanded;
    },
    // update the checked values when one checkbox is clicked
    // visibility options are [toggle, show, hide]
    updateIsChecked(state, { id, visibility }) {
        // don't want to call this in every if
        visibility = visibility.toLowerCase();
        if (visibility == 'toggle') {
            state.layersStruct[id].isChecked = !state.layersStruct[id]
                .isChecked;
        } else if (visibility == 'show') {
            state.layersStruct[id].isChecked = true;
        } else if (visibility == 'hide') {
            state.layersStruct[id].isChecked = false;
        }
        if (state.layersStruct[id].isChecked) {
            // setting isChecked true only goes up the tree so that the parents are visible
            state.layersStruct = updateTreeTrunk(
                id,
                state.layersStruct[id].isChecked,
                state.layersStruct
            );
        }
        // updates show-hide of layers down-tree from the selected node
        state.layersStruct = updateTreeBranches(
            id,
            state.layersStruct[id].isChecked,
            state.layersStruct
        );
    },
    updateLayerVisibility(state, node) {
        state.layersStruct[node.id].isChecked = node.value;
    },
    updateShowAttributeModal(state, value) {
        state.showAttributeModal = value;
    },
    toggleWeatherUnits(state, value) {
        state.weatherUnits = value;
    },
    setMapbookJobIDs(state, jobIDs) {
        state.mapbookJobIDs = jobIDs;
    },
    clearMapbookJobIDs(state) {
        state.mapbookJobIDs = [];
    },
    setEventLogPositions(state, eventLogPositions) {
        state.EventLogPositions = eventLogPositions;
    },
    setCQL_Filter(state, payload) {
        let lyrID = payload.lyrID;
        let query = payload.query;
        state.layersStruct[lyrID].options.CQL_Filter = query;
    },
    setLegendURL(state, payload) {
        let lyrID = payload.lyrID;
        let legendUrl = payload.url;
        state.layersStruct[lyrID].legend = legendUrl;
    },
    setWMSLayersFlag(state, payload) {
        state.updateWMSLayersFlag = payload;
    },
    setGeowebcacheToken(state, payload) {
        state.geowebcacheToken = payload;
    },
    setLayersStructValues(state, payload) {
        state.layersStructValues = payload;
    },
    setVectors(state, payload) {
        state.vectors = payload;
    },
    setCumulativeVector(state, payload) {
        state.cumulative_vector = payload;
    },
};

const getters = {
    getShowAttributeModal: (state) => state.showAttributeModal,
    getLayersStruct: (state) => state.layersStruct,
    getERLayerNames: (state) =>
        Object.values(state.layersStruct)
            .filter((layer) => state.erDataLayerIDs.includes(layer.id))
            .map((layer) => layer.name.replace(/ /g, '')),
    // getLayer: (state) => (id) => {
    //     return state.layersStruct[id];
    // },
};

const namespaced = true;

export const emergencyManagement = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
