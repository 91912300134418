<template>
    <div class="my-3 mx-2">
        <h3>Editing {{ ContourOptions.DisplayName }}</h3>
        <hr />
        <div class="editContourLayer">
            <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
            <h5>Contour layer name</h5>
            <b-form-group class="md-4 mb-0" role="group">
                <CustomFloatingLabel
                    class="postingName"
                    :config="{
                        label: 'Name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <input
                        placeholder="Name"
                        type="text"
                        class="inputbox mb-4"
                        v-model="ContourOptions.DisplayName"
                        autocomplete="off"
                    />
                </CustomFloatingLabel>
            </b-form-group>
            <div>
                <!-- editing page -->
                <div>
                    <b-form-group
                        class="smallWidth"
                        id="input-group-3"
                        label-for="input-3"
                    >
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Select the field to contour',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fieldName"
                                    :options="fieldData"
                                    text-field="fieldData"
                                    value-field="fieldData"
                                    @change="updateContourField"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Field value</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <h5>Aggregate method</h5>
                            <div class="aggr mb-3">
                                <div
                                    v-for="(item, index) in aggregateOption"
                                    :key="index"
                                >
                                    <img
                                        src="../../../assets/OverflowMenu.svg"
                                        font-scale="1.5"
                                        class="overflow-icon"
                                        v-if="overflowMenu"
                                    />
                                    <div class="aggregate-row">
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Aggregate field',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="
                                                    item.aggregateOptionField
                                                "
                                                @change="addAggrType(index)"
                                                id="inline-form-custom-select-pref"
                                                :options="
                                                    fieldAggregateDataUnique
                                                "
                                                text-field="fieldAggregateDataUnique"
                                                value-field="fieldAggregateDataUnique"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Aggregate
                                                        field</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <span
                                            v-if="index != 0"
                                            @click="deleteAggr(index)"
                                            ><img
                                                src="../../../assets/delete_icon.svg"
                                                class="ml-2"
                                        /></span>
                                    </div>
                                    <div
                                        v-for="(subItem,
                                        subIndex) in item.aggregateType"
                                        :key="subIndex"
                                    >
                                        <img
                                            src="../../../assets/L-shaped.svg"
                                        />
                                        <CustomFloatingLabel
                                            class="FloatingTool floatingIndent"
                                            :config="{
                                                label: 'Aggregate type',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                class="aggrType m-r-36"
                                                id="inline-form-custom-select-pref"
                                                v-model="
                                                    subItem.aggregateTypeName
                                                "
                                                :options="
                                                    aggregateTypeData[index]
                                                "
                                                text-field="AggregateOptions"
                                                value-field="AggregateOptions"
                                                @change="updateAggregateField"
                                            >
                                                <!-- <option v-for="j in aggregateType" :key="j">{{ j }}</option> -->
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Aggregate
                                                        type</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <div
                                            class="dateAggregate"
                                            v-if="
                                                subItem.aggregateTypeName ==
                                                    'Closest to a provided date'
                                            "
                                        >
                                            <img
                                                src="../../../assets/L-shaped.svg"
                                                class="imgIndent"
                                            />
                                            <CustomFloatingLabel
                                                class="FloatingTool floatingdate"
                                                :config="{
                                                    label: 'Provided date',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <input
                                                    class="avlFilter mt-2 mb-4"
                                                    type="date"
                                                    v-if="
                                                        subItem.aggregateTypeName ==
                                                            'Closest to a provided date'
                                                    "
                                                    v-model="
                                                        subItem.aggregateDate
                                                    "
                                                />
                                            </CustomFloatingLabel>
                                            <a
                                                @click="
                                                    deleteAggr(index, subIndex)
                                                "
                                                class="deletebtn"
                                            ></a>
                                        </div>
                                    </div>
                                    <!--  -->
                                </div>
                            </div>
                        </b-form-group>
                        <p
                            class="normal_input headergroup mt-1 add-btn"
                            @click="addAggr()"
                        >
                            Add another aggregate option +
                        </p>
                        <h5 class="py-1">Kriging method</h5>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Kriging Method',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="ContourOptions.KrigingMethod"
                                :options="krigingOptions"
                                text-field="Kriging Method"
                                value-field="Kriging Method"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Kriging Method</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>
                        <h5 class="py-1">Line Range</h5>
                        The 'Max line' value must be greater than 'Min line'
                        value,<br />
                        and the 'Contour interval' must be between the 'Min
                        line' and 'Max line' values
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="postingName value-cfl"
                                :config="{
                                    label: minLabel,
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="number"
                                    step="0.01"
                                    class="inputbox mb-4"
                                    :class="{
                                        'is-invalid': !minValid,
                                    }"
                                    v-model.number="ContourOptions.MinValue"
                                    autocomplete="off"
                                />
                                <invalid-icon :show="!minValid" />
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="postingName value-cfl"
                                :config="{
                                    label: maxLabel,
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="number"
                                    step="0.01"
                                    class="inputbox mb-4"
                                    :class="{
                                        'is-invalid': !maxValid,
                                    }"
                                    v-model.number="ContourOptions.MaxValue"
                                    autocomplete="off"
                                />
                                <invalid-icon :show="!maxValid" />
                            </CustomFloatingLabel>
                        </b-form-group>
                        <b-form-group class="md-4 mb-0" role="group">
                            <CustomFloatingLabel
                                class="postingName value-cfl"
                                :config="{
                                    label: intervalLabel,
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    type="number"
                                    step="0.01"
                                    class="inputbox mb-4"
                                    :class="{
                                        'is-invalid': !intervalValid,
                                    }"
                                    v-model.number="ContourOptions.Interval"
                                    autocomplete="off"
                                />
                                <invalid-icon :show="!intervalValid" />
                            </CustomFloatingLabel>
                        </b-form-group>
                        <!-- <h5>Label Styles</h5>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Size',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="fontSize"
                                :options="fontSizeOptions"
                                text-field="Size"
                                value-field="Size"
                                class="smallWidth"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Size</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Style',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="fontStyle"
                                :options="fontStyleOptions"
                                text-field="Style"
                                value-field="Style"
                                class="smallWidth"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Style</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Font name',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="fontName"
                                :options="fontNameOptions"
                                text-field="Type"
                                value-field="Type"
                                class="smallWidth"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Font name</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel> -->
                        <h5>Line style</h5>
                        <div class="pickColor wrap smallWidth">
                            <CustomFloatingLabel
                                class="FloatingTool editContourLayer"
                                :config="{
                                    label: 'Color',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <color-picker
                                    v-model="ContourOptions.LineColor"
                                />
                            </CustomFloatingLabel>
                        </div>
                        <div>
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Stroke width',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    id="inline-form-custom-select-pref"
                                    :value="1"
                                    v-model="ContourOptions.LineWidth"
                                    class="smallWidth"
                                    :options="[
                                        //{ text: 'Stroke width', value: null },
                                        '1',
                                        '2',
                                        '3',
                                        '4',
                                        '5',
                                        '6',
                                        '7',
                                        '8',
                                        '9',
                                        '10',
                                    ]"
                                >
                                </b-form-select>
                            </CustomFloatingLabel>
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Line style',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    id="inline-form-custom-select-pref"
                                    :value="null"
                                    v-model="ContourOptions.LineStyle"
                                    class="smallWidth"
                                >
                                    <b-form-select-option :value="null"
                                        >Line style</b-form-select-option
                                    >
                                    <b-form-select-option value="Solid"
                                        >Solid</b-form-select-option
                                    >
                                    <b-form-select-option value="Dashed"
                                        >Dashed</b-form-select-option
                                    >
                                </b-form-select>
                            </CustomFloatingLabel>
                        </div>
                    </b-form-group>
                    <h5 class="trans-sm">Transparency</h5>
                    <div class="d-flex my-4">
                        <span class="font-weight-bold indigo-text mr-2 mt-1"
                            >0</span
                        >
                        <form class="range-field grey-color">
                            <input
                                class="border-0 inputrange smallInput"
                                type="range"
                                v-model="ContourOptions.Transparency"
                                min="0"
                                step="1"
                                max="100"
                            />
                        </form>
                        <span class="font-weight-bold indigo-text ml-2 mt-1"
                            >100</span
                        >
                        <p margin-top="50px">
                            <input
                                type="text"
                                margin-top="50px"
                                class="input-value-minimap"
                                display="inline-block"
                                min="0"
                                max="100"
                                maxlength="3"
                                size="2.5ch"
                                padding-top="5px"
                                v-model.lazy="ContourOptions.Transparency"
                                v-on:keyup.enter="validateTransparency()"
                                v-click-outside="validateTransparency()"
                            />%
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <b-container class="bottom-btn p-0">
            <div class="d-flex justify-content-center py-2 btn-row">
                <b-button @click="$emit('close')" class="grey-none">
                    Cancel
                </b-button>
                <b-button
                    class="mx-1 white-none"
                    @click="saveLayerChanges(false)"
                    :disabled="!allValid"
                >
                    Save Changes
                </b-button>
                <b-button
                    @click="saveLayerChanges(true)"
                    class="mx-1 white-none"
                    :disabled="!allValid"
                >
                    Save as Duplicate
                </b-button>
            </div>
        </b-container>
    </div>
</template>

<script>
import Vue from 'vue';
import CustomFloatingLabel from '../../CustomFloatingLabel';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';

import { projectService } from '../../../services/project.service';
import { userService } from '../../../services/users.service';
import { reportingService } from '../../../services/reporting.service';
import { mapState, mapMutations, mapGetters } from 'vuex';

import { datapostingMixin } from './dataposting.mixin';
import InvalidIcon from '../InvalidIcon.vue';
import ColorPicker from '../ColorPicker.vue';

Vue.use(ColorPickerPlugin);

export default {
    name: 'ContourLayerEdit',
    components: {
        CustomFloatingLabel,
        InvalidIcon,
        ColorPicker,
    },
    mixins: [datapostingMixin],
    props: {
        jobID: {
            required: true,
            type: Number,
            default: 0,
        },
        panelWidth: {
            required: false,
            type: String,
            default: 'large',
        },
        datasetIdx: {
            required: false,
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            fontSize: 10,
            fontStyle: 'Normal',
            fontName: 'Arial',
            lineColor: '#000000',
            lineWidth: 1,
            lineStyle: 'Solid',
            pickColor: false,
            colorSelected: null,
            rotation: '0°',
            editRotation: null,
            transparencyValue: 0,
            editPosition: null,
            editAggregateOptionField: '',
            fontSizeOptions: [],
            fontNameOptions: [],
            fontStyleOptions: [],
            krigingOptions: ['Inverse Distance Weighting'],
            fieldData: [],
            aggregateTypeData: [],
            aggregateOption: [
                {
                    aggregateType: [],
                    aggregateOptionField: '',
                },
            ],
            overflowMenu: false,

            editDataset: {},
            ContourOptions: {},
            minValue: 0,
            maxValue: 0,
            minMaxValues: { min: 0, max: 0 },
        };
    },
    created() {
        this.editDataset = JSON.parse(
            JSON.stringify(this.tocPostedLayers.at(this.datasetIdx))
        );
    },
    mounted() {
        this.getFontSize();
        this.getFontStyle();
        this.getFontNames();
        this.ContourOptions = {
            ...this.tocPostedLayers.at(this.datasetIdx).ContourOptions,
        };
        this.datasetId = this.ContourOptions.DatasetId;
        this.aggregateOption = [...this.ContourOptions.AggregateOption];
        this.lineColor = this.ContourOptions.LineColor.toString();
        this.selectField('Contourable');
        this.selectAggregateField('Contour');
        this.aggregateOption[0].aggregateOptionField = this.ContourOptions.AggregateOption[0].aggregateOptionField;
        // this.addAggrType(0);
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        ...mapMutations('oneMap', ['setContourInProgress']),
        addAggrType(index) {
            if (this.fieldAggregateData.length == 0) {
                return;
            }
            let aggFieldType = this.fieldAggregateData.find((item) => {
                let fieldName = item.DatasetColumn.ColumnAlias;
                if (fieldName == null) {
                    fieldName = item.DatasetColumn.ColumnName;
                }
                return (
                    this.aggregateOption[index].aggregateOptionField ===
                    fieldName
                );
            }).DatasetColumn.Datatype;
            userService.getAggregateOptionsByType(aggFieldType).then((res) => {
                this.aggregateTypeData[index] = res.data;
                this.$forceUpdate();
            });
        },
        colorPicker(value) {
            if (value === 'small') {
                document.getElementById('element1').ej2_instances[0].toggle();
            } else {
                document.getElementById('element2').ej2_instances[0].toggle();
            }
        },
        updateColor(value) {
            this.lineColor = value;
            this.ContourOptions.LineColor = value;
        },
        getFontSize() {
            projectService.getStyleSize().then((res) => {
                this.fontSizeOptions = res.data;
            });
        },
        getFontStyle() {
            projectService
                .getStyleStyle()
                .then((res) => (this.fontStyleOptions = res.data));
        },
        getFontNames() {
            projectService
                .getStyleType()
                .then((res) => (this.fontNameOptions = res.data));
        },
        updateMinMaxContourOptions() {
            this.ContourOptions.MinValue = this.minMaxValues.min;
            this.ContourOptions.MaxValue = this.minMaxValues.max;
            this.ContourOptions.Interval = (
                (this.minMaxValues.max - this.minMaxValues.min) /
                5
            ).toFixed(2);
            this.ContourOptions.MinMaxValues = this.minMaxValues;
        },
        updateContourField() {
            this.ContourOptions.ContourField = this.fieldName;
            this.getContourDataRange();
        },
        updateAggregateField() {
            this.getContourDataRange();
        },
        addContourRequest() {
            const projectIds = this.$store.state.projects.selectedProjects.map(
                (project) => project.ProjectID
            );
            var contourParams = {
                dataManager: this.ContourOptions.dataManager,
                contourOptions: this.ContourOptions,
            };

            reportingService
                .submitContourRequest(
                    this.ContourOptions.DatasetId,
                    this.buildAggregateString(),
                    this.ContourOptions.ContourField,
                    projectIds,
                    contourParams
                )
                .then((res) => {
                    if (res.status == 200) {
                        this.setToastMessage({
                            queueService: 'Contour',
                            queueState: 'progress',
                            queueMessage: 'In progress...',
                            visible: true,
                        });
                        this.$store.commit('projects/addContourLayer', {
                            jobID: res.data,
                            name: this.layerName,
                            ContourOptions: contourParams.contourOptions,
                        });
                        this.$store.commit('oneMap/mutateCloseSidebars', true);
                        this.setContourInProgress(res.data);
                    }
                })
                .catch((err) => {
                    this.setToastMessage({
                        queueService: 'Contour',
                        queueState: 'error',
                        queueMessage: 'Error!',
                        visible: true,
                    });
                });
        },
        saveLayerChanges(copy = false) {
            if (copy) {
                this.addContourRequest();
            } else if (this.haveToReSubmit()) {
                if (!copy) {
                    this.$store.commit(
                        'projects/deleteContourLayer',
                        this.datasetIdx
                    );
                }
                this.addContourRequest();
            } else {
                let data = this.ContourOptions;
                projectService
                    .SaveContourStyle(this.jobID, data)
                    .then((res) => {
                        this.$store.commit('projects/updateContour', {
                            jobID: this.editDataset.jobID,
                            options: this.ContourOptions,
                        });
                    });
            }
            this.$store.commit(
                'projects/setUpdateContourLayersFlag',
                this.editDataset.jobID
            );
            this.$emit('close');
        },

        validateTransparency() {
            if (isNaN(this.transparencyValue)) {
                this.transparencyValue = 50;
            } else if (this.transparencyValue < 0) {
                this.transparencyValue = 0;
            } else if (this.transparencyValue > 100) {
                this.transparencyValue = 100;
            }
        },
        haveToReSubmit() {
            let haveToReSubmit = false;
            this.ContourOptions.ContourField = this.fieldName;
            this.ContourOptions.AggregateOption = [...this.aggregateOption];
            Object.keys(this.ContourOptions).forEach((key) => {
                if (
                    [
                        'MinValue',
                        'MaxValue',
                        'Interval',
                        'KrigingMethod',
                        'ContourField',
                        'AggregateOption',
                    ].includes(key)
                ) {
                    if (typeof this.ContourOptions[key] === 'object') {
                        let newVal = JSON.stringify(this.ContourOptions[key]);
                        let oldVal = JSON.stringify(
                            this.editDataset.ContourOptions[key]
                        );
                        if (newVal !== oldVal) {
                            haveToReSubmit = true;
                        }
                    } else if (
                        this.ContourOptions[key] !==
                        this.editDataset.ContourOptions[key]
                    ) {
                        haveToReSubmit = true;
                    }
                }
            });
            return haveToReSubmit;
        },
    },
    computed: {
        ...mapState({
            mapData: (state) => state.projects.mapData,
            gridFilteroptions: (state) => state.gridFilters.gridFilter,
        }),
        ...mapGetters({
            tocPostedLayers: 'projects/tocPostedLayers',
        }),
        allValid() {
            return this.minValid && this.maxValid && this.intervalValid;
        },
        minLabel() {
            let label = 'Min line (>= ';
            if (this.ContourOptions.MinMaxValues) {
                label += this.ContourOptions.MinMaxValues.min + ')';
            } else {
                label += '0)';
            }
            return label;
        },
        minValid() {
            if (this.ContourOptions.MinMaxValues) {
                return (
                    this.ContourOptions.MinValue >=
                        this.ContourOptions.MinMaxValues.min &&
                    this.ContourOptions.MinValue <= this.ContourOptions.MaxValue
                );
            } else {
                return true;
            }
        },
        maxLabel() {
            let label = 'Max line (<= ';
            if (this.ContourOptions.MinMaxValues) {
                label += this.ContourOptions.MinMaxValues.max + ')';
            } else {
                label += '0)';
            }
            return label;
        },
        maxValid() {
            if (this.ContourOptions.MinMaxValues) {
                return (
                    this.ContourOptions.MaxValue <=
                        this.ContourOptions.MinMaxValues.max &&
                    this.ContourOptions.MaxValue >= this.ContourOptions.MinValue
                );
            } else {
                return true;
            }
        },
        intervalLabel() {
            let label = 'Contour Interval (>0 - ';
            label +=
                (
                    this.ContourOptions.MaxValue - this.ContourOptions.MinValue
                ).toFixed(2) + ')';
            return label;
        },
        intervalValid() {
            if (
                this.ContourOptions.MaxValue == 0 &&
                this.ContourOptions.MinValue == 0
            ) {
                return this.ContourOptions.Interval >= 0;
            } else {
                return (
                    this.ContourOptions.Interval <=
                        this.ContourOptions.MaxValue -
                            this.ContourOptions.MinValue &&
                    this.ContourOptions.Interval > 0
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
.mainContent {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;
    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }
    h3 {
        font-size: 22px;
    }
    .some-radios {
        font-size: 14px;
        color: #626262;
    }
    // .custom-select {
    //   background: #fff url("../../assets/arrow_down.svg") no-repeat right 0.75rem
    //     center/8px 10px !important;
    //   width:75%;
    //   height: 48px;
    // }
}
.aggr-edit,
.layer-post {
    width: 241px;
}
.mainContent.edit-layer {
    max-height: 440px !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    // .inputbox-edit,
    .mediumWidth {
        width: 376px !important;
    }
    .smallWidth {
        width: 241px !important;
    }
    .mediumSecondary {
        width: 331px !important;
        left: 3px;
    }
    .smallSecondary {
        width: 200px !important;
        left: 3px;
    }
    .mediumAgg {
        width: 350px;
    }
    .smallAgg {
        width: 216px;
    }
}
.mainContent.edit-layer {
    max-height: 440px !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    .inputbox-edit,
    .select-edit {
        border-radius: 3px;
        padding: 7px;
        height: 48px;
        border: 1px solid rgb(206, 212, 218);
    }
    .subSelect {
        width: 180px;
        float: left;
    }
    .dlt-icon {
        position: absolute;
        right: 20px;
    }
    .edit.select-edit {
        width: 203px;
        margin-left: 15px;
    }
}

// .inputbox {
//   width: 499px;
//   border-radius: 3px;
//   padding: 7px;
//   height: 48px;
//   border: 1px solid rgb(206, 212, 218);
// }
.inputbox :focus {
    border: none;
}
.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.inputbox-color {
    height: 48px;
    width: 100%;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}
.pencil-icon {
    cursor: pointer;
}
.buttonSelect {
    position: absolute;
    right: 0;
    top: -45px;
}
.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}
.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}
.aggrType {
    margin-left: 36px;
    // margin-top: -23px;
    width: 400px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}
.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
.map_view_icon {
    background-color: grey;
    position: relative;
    bottom: 10px;
    border: 0 !important;
    // left: 8%;
    // float: right;
    .map_view_icon:focus {
        // outline: 1px dotted;
        outline: 0px;
    }
}
.search_element {
    background-image: url(../../../assets/search_dots.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
}
.pos_layer {
    width: auto;
    background-color: #f4f4f4;
    height: 80px;
    font-size: 0.9rem;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}
.pos_layer.hover {
    background-color: #f4f4f4;
}
.dataposting-icon {
    padding: 4px 2px 16px 15px;
    margin-left: -17px;
    background: #eaeaea;
}
.posted_layer {
    background: transparent;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}
.posted_layer.hover {
    background: white;
    color: #1d1d1d;
}
.project_layer {
    background: transparent;
    color: #1d1d1d;
    width: 144px;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.project_layer_active {
    background: transparent;
    color: #1d1d1d;
    width: 145px;
    margin-left: -38px;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.proj_layer {
    height: 43px;
    font-weight: 500;
    width: 100%;
}
/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}
.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}
.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}
.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}
.edit-position-wide .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 86.67px;
    height: 70px;
    border-radius: 0;
}
.edit-layer .btn-secondary:focus,
.edit-layer .btn-secondary:not(:disabled):not(.disabled):active {
    color: #1d1d1d !important;
    background-color: #eaeaea !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    //right: 15px;
    position: relative;
    //bottom: -4px;
    border-radius: 0;
}
.edit-layer .border-dark {
    height: 70px;
    padding: 0px;
    text-align: center;
    width: 80px;
}
.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}
.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}
.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}
.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}
.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}
.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
// .btn-secondary{
//   height:69px;
// }
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}
.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.edit-button {
    color: #007bff !important;
    margin-top: 0px;
    //margin-left: 96px;
}
.delete-datapost {
    margin-left: none !important;
    margin-top: 9px !important;
    font-size: 14px;
    color: #817070 !important;
}
.border-right {
    height: 20px;
}
.input-value {
    display: block;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 10px;
    padding: 10px;
    margin-top: -5px;
    width: 60px;
    text-align: center;
}

.input-value-minimap {
    display: inline-flex;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 4px;
    margin-right: 0px;
    text-align: center;
    border-style: hidden;
}
.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}
.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}
.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}
.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}
.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/* ------------------------------------------Position Button Css Ends Here------------------------------- */
.proj_layer.hover {
    background-color: white;
}
.slider_value {
    position: relative;
    bottom: 155px;
    left: 380px;
}
.slider {
    left: -3px !important;
}
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

.e-colorpicker-wrapper {
    position: relative !important;
    top: -10px !important;
    left: 40px !important;
}
/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}
/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}
.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}
.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}
// .btn-transparent{
//   position: inherit;
//   top: 20px;
//   right: 270px;
//   height: 40px;
//   width: 40px;
// }
.btnMed {
    position: inherit;
    top: 28px;
    right: 125px;
    height: 40px;
    width: 40px;
}
.btnSm {
    position: inherit;
    top: 25px;
    right: 270px;
    height: 40px;
    width: 40px;
    background: none !important;
}
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    //left: 20px;
    top: 20px;
    left: 60px;
    border: 0 !important;
}
.e-split-btn-wrapper {
    position: relative !important;
    left: 40px !important;
    top: -10px !important;
    background-color: transparent !important;
}
.hidebtn {
    left: 50px;
}
.dataposting .portalbarMain {
    border-block: 0 !important;
}

.accBorder {
    // border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px#dee2e6;
}

.input-value {
    width: 61px;
    font-size: 14px;
}
.range-field {
    width: 370px !important;
}
.edit-layer {
    .mediumInput {
        width: 250px !important;
    }
    .smallInput {
        width: 125px !important;
    }
}

.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    width: 55px;
    font-size: 12px;
}
.layer-post .select-edit:not(:first-child) {
    width: 180px !important;
}
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    background: rgb(0, 99, 149) none repeat scroll 0% 0% !important;
    color: white !important;
    border: medium none;
    white-space: nowrap;
}
.edit-layer .bottom-btn .btn-secondary:focus,
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #006395 !important;
    width: auto;
    height: auto;
    //right: 15px;
    position: relative;
    border-radius: 0.25rem;
}
.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}
.overflow {
    overflow-y: scroll;
    max-height: 394px;
}
.hideText {
    margin-right: 10px;
}
.modalScreen {
    height: 85vh;
    z-index: 999999;
}
.map_arrow {
    position: absolute;
    /* left: -13px; */
    z-index: 999;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}
.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}
.rowData {
    margin-top: 6px;
}
.backtopost {
    position: absolute;
    top: -200px;
    right: 0;
}
.card-body {
    padding: 1rem;
}
.collapsed .when-opened {
    display: none;
}
.not-collapsed .when-closed {
    display: none;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}
.dateAggregate {
    margin-top: -22px;
}

.dataPost-map-box {
    height: 440px;
    width: 885px;
}

.dataPost-map-box-expanded {
    height: 440px;
}

.dataPost-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }
    #lmap1 {
        opacity: 1;
        height: 10in;
        width: 15in;
    }
}
.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}
.showHide {
    font-size: 14px;
    margin-top: 11px;
}
.zoomTo {
    color: #006395 !important;
    margin-top: 9px !important;
    padding-right: 5px !important;
    margin-right: 5px !important;
}
.project_layers {
    background-color: #f4f4f4;
    height: 44px;
}
.btn-side {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
}
.btn-zoom {
    position: absolute;
    top: 25px;
    left: 12px;
}
.btn-edit {
    position: absolute;
    top: -10px;
}
.btn-delete {
    position: absolute;
    left: 0px;
    top: 39px;
}
.e-split-btn-wrapper {
    position: relative !important;
    top: -12px !important;
    left: 50px !important;
}
.img-l {
    position: relative;
    top: -12px;
}
.e-colorpicker-wrapper *,
.e-colorpicker-container * {
    padding-left: 5px;
}

.btn-row {
    justify-content: end !important;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .range-field {
        width: 85px;
    }

    .smallInput {
        width: 85px;
    }
}

.is-invalid {
    border-color: #dc3545;
}
.value-cfl {
    max-height: 50px;
}
</style>
