import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { userService } from '../../../services/users.service';
import { projectService } from '../../../services/project.service';
import { mapState, mapGetters } from 'vuex';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';
import { latLngBounds } from 'leaflet';
import { reportingService } from '../../../services/reporting.service';

import Vue from 'vue';
Vue.use(ColorPickerPlugin);

export const datapostingMixin = {
    data() {
        return {
            styleData: [],
            fontSizeData: [],
            fontNameData: [],
            fontTypeData: [],
            fontColorData: [],
            fontStyleData: [],
            fontSize: 10,
            fontType: 'Arial',
            fontStyle: 'Normal',
            featureColor: '#000000',
            fieldName: null,
            fieldAggregateData: [],
            fieldAggregateDataUnique: [],
            // aggregateOption: [
            //     {
            //         aggregateType: [],
            //         aggregateOptionField: '',
            //     },
            // ],
            layerList: [],
            dptData: [],
            projectDataLayers: [],
        };
    },
    methods: {
        // TODO: HAVE TO UPDATE THESE TO BE REAL
        getGridFilter() {
            return [
                {
                    filter: '1040005008',
                    filterColName: 'SiteCode',
                    dataSetId: this.datasetId,
                    projectId: [this.selectedProjects[0].ProjectID],
                },
            ];
        },
        getStyleBlock() {
            projectService
                .getStyleBlock()
                .then((res) => (this.styleData = res.data));
        },
        getFontSize() {
            projectService.getStyleSize().then((res) => {
                if (res.status === 200) {
                    this.fontSizeData = res.data;
                } else {
                    this.fontSizeData = [
                        { Size: 8 },
                        { Size: 10 },
                        { Size: 12 },
                    ];
                }
            });
        },
        getFontStyle() {
            projectService.getStyleStyle().then((res) => {
                if (res.status === 200) {
                    this.fontStyleData = res.data;
                } else {
                    this.fontStyleData = [
                        { Style: 'Normal' },
                        { Style: 'Bold' },
                        { Style: 'Italic' },
                    ];
                }
            });
        },
        getFontColor() {
            projectService
                .getStyleColor()
                .then((res) => (this.fontColorData = res.data));
        },
        getFontType() {
            projectService.getStyleType().then((res) => {
                if (res.status === 200) {
                    this.fontTypeData = res.data;
                } else {
                    this.fontTypeData = [{ Type: 'Arial' }];
                }
            });
        },
        getfontName() {
            projectService.getStyleType().then((res) => {
                if (res.status === 200) {
                    this.fontNameData = res.data;
                } else {
                    this.fontNameData = [{ Type: 'Arial' }];
                }
            });
        },
        selectField(fieldType) {
            projectService
                .selectField(this.datasetId, fieldType)
                .then((res) => {
                    let dataField = res.data;
                    if (dataField) {
                        dataField.forEach((item) => {
                            if (item.DatasetColumn !== null) {
                                if (item.AttributeType === 'Default') {
                                    this.fieldName = item.DatasetColumn
                                        .ColumnAlias
                                        ? item.DatasetColumn.ColumnAlias
                                        : item.DatasetColumn.ColumnName;
                                }
                                this.fieldData.push(
                                    item.DatasetColumn.ColumnAlias
                                        ? item.DatasetColumn.ColumnAlias
                                        : item.DatasetColumn.ColumnName
                                );
                            }
                        });
                        this.fieldData = [...new Set(this.fieldData)];
                        /* let data=this.fieldData.find((item)=>item.AttributeType==="Default");
             this.fieldName=data.DatasetColumn.ColumnName;*/
                    }
                });
        },
        buildAggregateString() {
            let aggregateData = [];
            this.aggregateOption.forEach((item) => {
                if (item.aggregateType[0].aggregateDate) {
                    let x = this.formatDate(
                        item.aggregateType[0].aggregateDate
                    );
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggregateType[0].aggregateTypeName +
                            '(' +
                            x +
                            ')'
                    );
                } else {
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggregateType[0].aggregateTypeName
                    );
                }
            });
            return aggregateData.toString();
        },
        // null datasetId is to work with edit, datasetID and the posted DatasetID are different
        selectAggregateField(toolName, datasetId = null, useDefaults = true) {
            if (datasetId === null) {
                datasetId = this.datasetId;
            }
            projectService
                .selectField(datasetId, 'Aggregate options', toolName)
                .then((res) => {
                    this.fieldAggregateData = res.data;

                    if (this.fieldAggregateData) {
                        this.fieldAggregateData.forEach((item) => {
                            if (item.DatasetColumn !== null) {
                                if (
                                    useDefaults &&
                                    item.AttributeType === 'Default'
                                ) {
                                    this.aggregateOption[0].aggregateOptionField = item
                                        .DatasetColumn.ColumnAlias
                                        ? item.DatasetColumn.ColumnAlias
                                        : item.DatasetColumn.ColumnName;
                                    this.addAggrType(0);
                                }
                                this.fieldAggregateDataUnique.push(
                                    item.DatasetColumn.ColumnAlias
                                        ? item.DatasetColumn.ColumnAlias
                                        : item.DatasetColumn.ColumnName
                                );
                            }
                        });
                        this.fieldAggregateDataUnique = [
                            ...new Set(this.fieldAggregateDataUnique),
                        ];
                    }
                })
                .then(() => this.addAggrType(0));
        },

        allAggregateOption() {
            let vm = this;
            userService.getAggregateOptions().then((res) => {
                this.options = res.data;
            });
        },
        deleteAggr(index) {
            this.aggregateOption.splice(index, 1);
        },
        colorPicker(value) {
            document.getElementById('element').ej2_instances[0].toggle();
        },
        addAggr() {
            this.aggregateOption.push({
                aggregateField: '',
                aggregateType: [],
            });
            this.fieldAggregateData.forEach((item) => {
                if (item.AttributeType === 'Default') {
                    this.aggregateOption[
                        this.aggregateOption.length - 1
                    ].aggregateOptionField = item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName;
                    this.addAggrType(this.aggregateOption.length - 1);
                }
            });
        },
        addAggrType(index) {
            let dataTypeSetId;
            this.aggregateOption[index].aggregateType = [];
            dataTypeSetId = this.fieldAggregateData.find(
                (item) =>
                    this.aggregateOption[index].aggregateOptionField ===
                    (item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName)
            );
            if (this.aggregateOption[index].aggregateType.length === 0) {
                this.aggregateOption[index].aggregateType.push({
                    aggregateTypeName: '',
                    aggregateDate: '',
                });
                userService
                    .getAggregateOptionsByType(
                        dataTypeSetId.DatasetColumn.Datatype
                    )
                    .then((res) => {
                        this.options = res.data;
                        this.aggregateTypeData[index] = this.options;
                        if (
                            this.$store.state.store.portalsettings !== undefined
                        ) {
                            let numberPortalData = this.$store.state.store
                                .portalsettings.Number;
                            let datePortalData = this.$store.state.store
                                .portalsettings.Date;
                            let textPortalData = this.$store.state.store
                                .portalsettings.Text;
                            let result = this.options.filter(
                                (ele) =>
                                    ele.AggregateOptionID == numberPortalData ||
                                    ele.AggregateOptionID == datePortalData ||
                                    ele.AggregateOptionID == textPortalData
                            );
                            if (result.length) {
                                this.aggregateOption[
                                    index
                                ].aggregateType[0].aggregateTypeName =
                                    result[0].AggregateOptions;
                                this.validateInput = false;
                            }
                        }
                        this.$forceUpdate();
                    });
            }
        },
        validateTransparency() {
            if (isNaN(this.transparencyValue)) {
                this.transparencyValue = 50;
            } else if (this.transparencyValue < 0) {
                this.transparencyValue = 0;
            } else if (this.transparencyValue > 100) {
                this.transparencyValue = 100;
            }
        },
        postDataPoints(aggregateData, displayName) {
            const projectIds = this.$store.state.projects.selectedProjects.map(
                (project) => project.ProjectID
            );
            this.postedDataLayerParams = {
                datasetId: this.datasetId,
                aggregateOption: aggregateData,
                fieldName: this.fieldName,
                projectIds: projectIds,
                displayName: displayName,
                filters: JSON.parse(this.gridFilteroptions),
            };
            projectService
                .getDataPostToolData_2(
                    this.datasetId,
                    aggregateData,
                    this.fieldName,
                    projectIds,
                    JSON.parse(this.gridFilteroptions)
                )
                .then((res) => {
                    if (res.data.features === null) {
                        this.showFailure = true;
                        this.failureToastMessage =
                            'No data available to post. Please review options then retry';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    } else {
                        this.postedDataLayers = res.data;
                        this.processData(displayName);
                        this.$emit('close');
                    }
                })
                .catch((err) => {
                    console.error(err.response.data);
                    if (
                        err.response.data.Message == 'ForbiddenSQLInjection' &&
                        err.response.data.StatusCode == 500
                    ) {
                        this.$emit(
                            'resForbiddenPosting',
                            err.response.data.Message
                        );
                    } else if (
                        err.response.data.includes(
                            'Cannot implicitly convert type'
                        ) &&
                        err.response.status == 500
                    ) {
                        this.showFailure = true;
                        this.failureToastMessage =
                            "We've run into an error. Please contact your system administrator for further assistance.";
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    } else {
                        this.showFailure = true;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
        },
        processData(displayName) {
            let dataSources = [];

            let aggregateFieldData = [];

            this.aggregateOption.forEach((item) => {
                aggregateFieldData.push({
                    aggregateField: item.aggregateOptionField,
                    aggregateType: item.aggregateType[0].aggregateTypeName,
                    aggregateDate: item.aggregateType[0].aggregateDate,
                });
            });

            let obj = {};
            this.dptData.forEach((ds) => {
                if (ds.geometry[0].x && ds.geometry[0].y) {
                    obj['result'] = ds.geometry[0].result;
                    obj['latitude'] = spatialFunctions.meters2lat(
                        ds.geometry[0].y
                    );
                    obj['longitude'] = spatialFunctions.meters2lng(
                        ds.geometry[0].x
                    );
                    dataSources.push(obj);
                }
            });

            let styleCode;
            let fontSize;
            let fontFamily;
            let fontWeight;
            let rotation;
            let transparency;
            if (this.styleSize) {
                fontSize = this.styleSize + 'px';
            } else {
                fontSize = '8px';
            }
            if (this.styleType) {
                fontFamily = this.styleType;
            } else {
                fontFamily = 'IBM Plex Sans Regular';
            }
            if (this.styleStyle) {
                fontWeight = this.styleStyle;
            } else {
                fontWeight = 'normal';
            }
            if (this.rotation) {
                rotation = 'rotate(' + this.rotation + 'deg)';
            } else {
                rotation = 'rotate(0deg)';
            }
            if (this.transparencyValue) {
                transparency = this.transparencyValue;
            } else {
                transparency = '0';
            }

            switch (this.dataLabelPosition) {
                case 'topLeft':
                    styleCode =
                        '<div><div style="margin-bottom: 30px;padding-right:60px;';
                    break;
                case 'topCenter':
                    styleCode =
                        '<div><div style="margin-bottom: 30px;padding-right:25px;';
                    break;
                case 'topRight':
                    styleCode = '<div><div style="margin-bottom: 30px;';
                    break;
                case 'bottomLeft':
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:50px;';
                    break;
                case 'bottomCenter':
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:20px;';
                    break;
                case 'bottomRight':
                    styleCode = '<div><div style="margin-top: 11px;';
                    break;
                case 'middleLeft':
                    styleCode =
                        '<div><div style="margin-bottom: 3px;padding-right:60px;';
                    break;
                case 'middleCenter':
                    styleCode =
                        '<div><div style="margin-bottom: 3px;padding-right:25px;';
                    break;
                case 'middleRight':
                    styleCode = '<div><div style="margin-bottom: 3px;';
                    break;
                default:
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:20px;';
                    break;
            }
            styleCode += 'font-family:';
            fontFamily +
                ';font-size:' +
                fontSize +
                ';font-weight:' +
                fontWeight +
                ';font-style:' +
                fontWeight +
                ';transform:' +
                rotation +
                ';opacity:' +
                1 -
                transparency / 100 +
                '">${result}</div></div>';

            let markerSettings = [
                {
                    visible: true,
                    dataSource: this.postedDataLayers,
                    shape: 'Image',
                    imageUrl:
                        this.symbology == 'arrow'
                            ? '../assets/arrow_halved.svg'
                            : this.symbology == 'flag'
                            ? '../assets/flag_halved.svg'
                            : this.symbology == 'circle'
                            ? '../assets/circle_halved.svg'
                            : this.symbology == 'star'
                            ? '../assets/star_5_halved.svg'
                            : this.symbology == 'star3'
                            ? '../assets/star_3_halved.svg'
                            : this.symbology == 'star4'
                            ? '../assets/star_4_halved.svg'
                            : this.symbology == 'cross3'
                            ? '../assets/cross_3_halved.svg'
                            : this.symbology == 'cross5'
                            ? '../assets/cross_5_halved.svg'
                            : this.symbology == 'polygon3'
                            ? '../assets/polygon_3_halved.svg'
                            : this.symbology == 'polygon4'
                            ? '../assets/polygon_4_halved.svg'
                            : this.symbology == 'polygon5'
                            ? '../assets/polygon_5_halved.svg'
                            : '../assets/circle_halved.svg',

                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        // valuePath: "sitecode",
                        textStyle: {
                            size: this.styleSize
                                ? this.styleSize + 'px'
                                : '10px',
                            fontFamily: this.styleType
                                ? this.styleType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.styleStyle
                                ? this.styleStyle
                                : 'normal',
                        },
                    },
                },
                {
                    visible: true,
                    dataSource: dataSources,
                    template: styleCode,
                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        //  valuePath: "sitecode",
                        textStyle: {
                            size: this.styleSize
                                ? this.styleSize + 'px'
                                : '10px',
                            fontFamily: this.styleType
                                ? this.styleType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.styleStyle
                                ? this.styleStyle
                                : 'normal',
                        },
                    },
                },
            ];
            this.layerDetails = {
                // PostingId: this.postingId,
                Visible: true,
                DisplayName: displayName,
                Geometry: markerSettings[0].dataSource,
                FieldName: this.fieldName,
                AggregateOptions: aggregateFieldData,
                RadioSource: this.postingSource,
                SymbologyColor: this.featureColor,
                Symbology: this.symbology,
                FontSize: this.styleSize,
                FontStyle: this.styleStyle,
                FontType: this.styleType,
                FontRotation: this.rotation,
                Transparency: this.transparencyValue,
                MarkerSettings: markerSettings,
                LabelPosition: this.dataLabelPosition,
                DatasetID: this.datasetId,
                FilterOptions: this.filterOptions,
                DataPostParams: this.postedDataLayerParams,
            };

            if (this.editLayer) {
                this.layerDetails.postingId = this.editedPostingId;
                this.layerList[this.layerEditIndex] = this.layerDetails;

                this.layerEditIndex = '';
                this.editLayer = false;
            } else {
                this.layerDetails.postingId = this.postingId;
                this.postingId = this.postingId + 1;
                this.layerList.push(this.layerDetails);
            }

            //code that handles the initial setting of the show/hide toggle
            this.layerList.forEach((layer) => {
                if (layer.visible == true) {
                    layer.MarkerSettings[0].dataSource.visible = true;
                    layer.MarkerSettings[1].dataSource.visible = true;
                    layer.MarkerSettings[0].visible = true;
                    layer.MarkerSettings[1].visible = true;
                } else {
                    layer.MarkerSettings[0].dataSource.visible = false;
                    layer.MarkerSettings[1].dataSource.visible = false;
                    layer.MarkerSettings[0].visible = false;
                    layer.MarkerSettings[1].visible = false;
                }
            });
            this.aggregateOption = [
                {
                    aggregateOptionField: '',
                    aggregateType: [],
                },
            ];
            this.layerName = '';
            this.fieldName = null;
            this.colorSelected = null;
            // this.symbology = null;
            // this.styleSize = null;
            // this.styleStyle = null;
            // this.styleType = null;
            // this.featureColor=null;
            // this.transparencyValue = 100;
            this.edit_clicked = false;
            this.selectedPostingId = '';
            this.addDataToMainMap();
            this.$store.commit('oneMap/mutateCloseSidebars', true);
        },
        addDataToMainMap() {
            //this posts the layer GEOMETRY to the store
            let payload = [];
            payload = this.projectGeojson.postedLayers;
            payload.forEach((item) => {
                item.id = item.postingId;
            });
            // this.$store.commit('projects/addPostedGeojson', payload);

            //this posts the ATTRIBUTES to the store
            let payLoad2 = [];
            payLoad2 = this.layerList;
            payLoad2.forEach((item) => {
                item.id = item.postingId;
                let dataSource = item.MarkerSettings[0].dataSource;
                if (!dataSource.labelVisibleIndex) {
                    for (
                        let i = 0;
                        i < dataSource.features[0].properties.length;
                        i++
                    ) {
                        if (dataSource.features[0].properties[i].label) {
                            dataSource.labelVisibleIndex = i;
                        }
                    }
                }

                // change to true to cluster user posted layers
                item.Clustered = false;
            });
            this.$store.commit('projects/addPostedLayerData', payLoad2);
        },
        zoomToPosted(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;

            let bufferPercentage = 10;
            this.$store.dispatch(
                'oneMap/setZoomToExtent',
                latLngBounds([
                    [
                        spatialFunctions.correctLatitude(
                            minY - (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            minX - (xRange * bufferPercentage) / 100
                        ),
                    ],
                    [
                        spatialFunctions.correctLatitude(
                            maxY + (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            maxX + (xRange * bufferPercentage) / 100
                        ),
                    ],
                ])
            );
        },
        getContourDataRange() {
            var datasetId = this.gridDatasetId;
            let aggregateDataFinal = this.buildAggregateString();
            const projectIds = this.$store.state.projects.selectedProjects.map(
                (project) => project.ProjectID
            );

            let dataManager = JSON.parse(this.gridFilteroptions);
            reportingService
                .getContourValueRange(
                    datasetId,
                    aggregateDataFinal,
                    this.fieldName,
                    projectIds,
                    dataManager
                )
                .then((res) => {
                    this.minMaxValues = res.data;
                    this.minValue = res.data.min;
                    this.maxValue = res.data.max;
                    this.contourInterval = (
                        (this.maxValue - this.minValue) /
                        5
                    ).toFixed(2);
                });
        },
    },
    computed: {
        ...mapState({
            selectedProjects: (state) => state.projects.selectedProjects,
            secondFilterOptions: (state) => state.gridFilters.secondLevelFilter,
        }),
        ...mapGetters('projects', { gridDatasetId: 'getGridDatasetId' }),
        projectGeojson() {
            let output1 = [];
            let output2 = [];
            this.layerList.forEach((item) => {
                let postFeatures = item.MarkerSettings[0].dataSource;
                let postStyling = {
                    FontColor: item.SymbologyColor,
                    FontRotation: item.FontRotation,
                    FontSize: item.FontSize,
                    FontStyle: item.FontStyle,
                    FontTransparency: parseInt(item.Transparency),
                    FontType: item.FontType,
                    LabelPosition: item.LabelPosition,
                    LineColor: item.SymbologyColor,
                    // LineStyle: lyr.LineStyle,
                    LineWidth: 1, //lyr.LineWidth,
                    PolygonColor: item.SymbologyColor,
                    // PolygonStyle: lyr.PolygonStyle,
                    PolygonTransparency: parseInt(item.Transparency),
                    PolygonBorderColor: item.SymbologyColor,
                    PolygonWidth: 1, //lyr.PolygonWidth,
                    Symbology: item.Symbology,
                    SymbologyColor: item.SymbologyColor,
                    Opacity: 100 - parseInt(item.Transparency),
                };
                postFeatures.features.forEach((feature) => {
                    feature.styling = postStyling;
                });
                postFeatures.postingId = item.postingId;
                output2.push(postFeatures);
                this.zoomToPosted(postFeatures);
            });
            try {
                this.projectDataLayers.forEach((item) => {
                    if (item.ProjectLayers) {
                        item.ProjectLayers.sort(
                            (a, b) => a.lyrorder - b.lyrorder
                        );
                        item.ProjectLayers.forEach((lyr) => {
                            lyr.Geometry.id = lyr.lyrorder;
                            output1.push(lyr.Geometry);
                        });
                    }
                });
            } catch (err) {
                err;
            }
            return { projectLayers: output1, postedLayers: output2 };
        },
    },
    watch: {},
};
