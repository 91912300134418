<template>
    <b-container>
        <portal-bar v-if="showPortalBar" :hideSearch="hideSearch"></portal-bar>
        <b-row class="shift">
            <b-col
                cols="12 my-1"
                @mouseover="isHovered = true"
                @mouseout="isHovered = false"
            >
                <span @click="fullscreen" class="fullscreen-icon"></span>
                <img
                    v-if="isHovered"
                    class="icon"
                    src="../../assets/fullscreen-grey.svg"
                />
                <section :id="container" class="dashboard" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import * as pbi from 'powerbi-client';
import router from '../../../router';
import PortalBar from '../PortalBar';
import { v4 as uuidv4 } from 'uuid';
import { dashboardService } from '../../services/dashboard.service';
export default {
    name: 'PowerBI',
    props: {
        showPortalBar: {
            type: Boolean,
            default: function() {
                return true;
            },
        },
    },
    data() {
        return {
            reportId: null,
            dashboardParams: null,
            show: true,
            dashboard: null,
            loading: true,
            config: null,
            showPage: false,
            container: uuidv4(),
            hideSearch: true,
            isHovered: false,
            basicFilter: null,
            projectId: 0,
        };
    },
    components: {
        PortalBar,
    },
    mounted() {
        this.reportId = localStorage.getItem('reportId');
        this.projectId = JSON.parse(localStorage.getItem('projectId'));
        this.pagePermit();
        this.getDashboard(this.reportId);
    },
    methods: {
        fullscreen() {
            var embedContainer = document.getElementById(this.container);
            let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
            );
            let report = powerbi.get(embedContainer);
            report.fullscreen();
        },
        getDashboard(reportId) {
            dashboardService.getReportFilter(this.projectId).then((res) => {
                new Promise((resolve) => {
                    this.basicFilter = {
                        $schema: 'http://powerbi.com/product/schema#basic',
                        target: {
                            table: res.data.table,
                            column: res.data.column,
                        },
                        operator: 'In',
                        values: res.data.filter,
                        filterType: 1,
                    };
                    resolve(this.basicFilter);
                }).then((filter) => {
                    dashboardService
                        .getDashboardParams(reportId)
                        .then((res) => {
                            this.dashboardParams = res.data;
                            new Promise((resolve) => {
                                const permissions = pbi.models.Permissions.All;
                                this.config = {
                                    type: 'report',
                                    tokenType: pbi.models.TokenType.Embed,
                                    accessToken: this.dashboardParams.EmbedToken
                                        .token,
                                    embedUrl:
                                        this.dashboardParams.EmbedUrl +
                                        // optionally remove bottom navigation pane or filter pane
                                        //"&navContentPaneEnabled=false" +
                                        '&filterPaneEnabled=false',
                                    id: this.dashboardParams.ReportId,
                                    pageView: 'fitToWidth',
                                    permissions: permissions,
                                    filters: [this.basicFilter],
                                };
                                resolve(this.config);
                            }).then((config) => {
                                let powerbi = new pbi.service.Service(
                                    pbi.factories.hpmFactory,
                                    pbi.factories.wpmpFactory,
                                    pbi.factories.routerFactory
                                );
                                const dashboardContainer = document.getElementById(
                                    this.container
                                );
                                this.dashboard = powerbi.embed(
                                    dashboardContainer,
                                    this.config
                                );
                                this.loading = false;
                            });
                        });
                });
            });
        },
        expandDashboard() {
            this.container = 'mcontainer';
            this.getDashboard(this.reportId);
        },
        pagePermit() {
            this.showPage = true;
            this.showOpenProject = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('MapView')) {
                    vm.showPage = true;
                } else if (element.PageName.includes('SelectedProjects')) {
                    vm.showOpenProject = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },

        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
    },
};
</script>

<style scoped>
button {
    width: 10%;
    height: 10%;
}

.list-view {
    top: 20px;
}

.shift {
    padding-top: 1em;
}

.dashboard {
    height: 650px;
}

.expand {
    background: url('../../assets/export-icon.png');
    height: 22px;
    width: 10px;
}

.popout {
    width: 750px;
    height: 750px;
}

.modal-dialog {
    max-width: 100%;
    max-height: 100%;
}
.fullscreen-icon {
    position: absolute;
    right: 20px;
    bottom: 2px;
    width: 40px;
    height: 40px;
    z-index: 99;
}
.icon {
    position: absolute;
    right: 20px;
    bottom: 2px;
    height: 30px;
    width: 30px;
    z-index: 1;
}
.hoveredClass {
    color: pink;
}
</style>
