import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const gdbService = {
    convertGDBtoSQL,
    deleteFromSQL,
};

//convert
function convertGDBtoSQL(dataSourceId, tableName) {
    // return axios.post(apiConstants.convertGDBtoSQL + '?id=' + dataSourceId + '&layerName=' + tableName)
    return request({
        url:
            apiConstants.convertGDBtoSQL +
            '?id=' +
            dataSourceId +
            '&layerName=' +
            tableName,
        method: 'post',
    });
}
function deleteFromSQL(id) {
    // return axios.delete(apiConstants.deleteFromSQL + '/' + id)
    return request({
        url: apiConstants.deleteFromSQL + '/' + id,
        method: 'delete',
    });
}
