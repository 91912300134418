import { commonService } from '../services';

const state = {
    cities: {},
    states: {},
    token: null,
    tokenRefreshCount: 0,
    signInIcon: true,
};

const actions = {
    getAllCities({ commit }) {
        commit('getCityRequest');

        commonService.getAllCities().then(
            (cities) => commit('getCitySuccess', cities),
            (error) => commit('getCityFailure', error)
        );
    },
    getAllStates({ commit }) {
        commit('getStateRequest');

        commonService.getAllStates().then(
            (states) => commit('getStateSuccess', states),
            (error) => commit('getStateFailure', error)
        );
    },
    async renewToken({ commit }) {
        const account = this._vm.$msalInstance.getAllAccounts()[0];
        if (account) {
            const accessTokenRequest = {
                scopes: [
                    `api://${process.env.VUE_APP_API_CLIENT_ID}/access_as_user`,
                ],
                account: account,
            };
            let resp = await this._vm.$msalInstance.acquireTokenSilent(
                accessTokenRequest
            );
            document.cookie = `accessToken=${resp.accessToken};path=/;`;
            commit('setToken', resp.accessToken);
            // commit('resetCount');
        } else {
            //nothing
        }
    },
    incrementTokenRenewCount({ commit }) {
        //
        commit('incrementTokenRenewCount');
    },
};

const mutations = {
    //get cities
    getCityRequest(state) {
        state.cities = { loading: true };
    },
    getCitySuccess(state, cities) {
        state.cities = { items: cities.data.CityDetails };
    },
    getCityFailure(state, error) {
        state.cities = { error };
    },

    //get States
    getStateRequest(state) {
        state.states = { loading: true };
    },
    getStateSuccess(state, states) {
        state.states = { items: states.data.StateDetails };
    },
    getStateFailure(state, error) {
        state.states = { error };
    },
    setToken(state, token) {
        state.token = token;
    },
    incrementTokenRenewCount(state) {
        //
        state.tokenRefreshCount++;
    },
    resetCount(state) {
        state.tokenRefreshCount = 0;
    },
    setLoadingIconOn(state) {
        state.signInIcon = false;
    },
    setLoadingIconOff(state) {
        state.signInIcon = true;
    },
};

const namespaced = true;

export const common = {
    namespaced,
    state,
    actions,
    mutations,
};
