import { spatialFunctions } from '../utilities/spatialFunctions.js';
import { projectService } from '../services/project.service';
import Vue from 'vue';
const state = {
    drawingAttributes: {
        geoID: 0,
        geom: null,
        geomWKT: null,
        geomLocName: null,
        geomLocType: null,
        geomLocDesc: null,
        geomComment: null,
    },
    layersStruct: {},
    showAttributeModal: false,
    projectID: null,
    weatherUnits: '°C',
    mapbookJobIDs: [],

    erDataLayerIDs: [],
    EventLogPositions: [],
};

// creates an array of children using the child's parentID
function getChildren(parentID, tree) {
    var children = [];
    tree.forEach((branch) => {
        if (branch.parentID == parentID) {
            children.push(branch.id);
        }
    });
    return children;
}

// updates down the tree, recurses through the nodes
function updateTreeBranches(startID, newVal, tree) {
    tree[startID].isChecked = newVal;
    tree[startID].children.forEach((child) => {
        tree[child].isChecked = newVal;
        if (tree[child].children.length > 0) {
            tree = updateTreeBranches(child, newVal, tree);
        }
    });
    return tree;
}

// updates up the tree, recurses through parents
function updateTreeTrunk(startID, newVal, tree) {
    var parent = tree[startID].parentID;
    if (parent > 0) {
        tree[parent].isChecked = newVal;
        tree = updateTreeTrunk(parent, newVal, tree);
    }
    return tree;
}

function buildLegendURL(wmsLayer, layer) {
    layer.legend = `${wmsLayer.WMSUrl}?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image/png&LAYER=${wmsLayer.WMSName}&STYLE=${wmsLayer.Style}`;
    layer.printLegend = `${wmsLayer.WMSUrl}?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetLegendGraphic&FORMAT=image/png&width=20&height=20&LAYER=${wmsLayer.WMSName}&srs=EPSG:4326&legend_options=forceLabels:on;countMatched:true;hideEmptyRules:true;fontAntiAliasing:true`;

    if (wmsLayer.authkey !== '') {
        layer.options.authkey = wmsLayer.authkey;
        layer.legend += '&authkey=' + wmsLayer.authkey;
        layer.printLegend += '&authkey=' + wmsLayer.authkey;
    } else {
        layer.options.geokey = wmsLayer.geokey;
        layer.legend += '&geokey=' + wmsLayer.geokey;
        layer.printLegend += '&geokey=' + wmsLayer.geokey;
    }
    return layer;
}

const actions = {
    setProjectID({ commit }, projectID) {
        commit('mutateProjectID', projectID);
    },
    setDrawingAttributes({ commit }, feature = {}) {
        if (Object.keys(feature).length == 0) {
            commit('clearDrawingAttributes');
        } else {
            let featureObj = {};
            feature.properties.forEach((item) => {
                featureObj[item.key] = item.value;
            });
            let newFeature = {};
            newFeature.projectID = state.projectID;
            newFeature.geom = feature.geometry;
            newFeature.FeatureID = featureObj.geoID;
            newFeature.filler = featureObj.filler;
            newFeature.LocName = featureObj.geomLocName;
            newFeature.LocType = featureObj.geomLocType;
            newFeature.GeoDescription = featureObj.geomLocDesc;
            newFeature.Comment = featureObj.geomComment;
            newFeature.geomWKT = spatialFunctions.convertToWKT(
                feature.geometry
            );
            feature.properties.forEach((property) => {
                newFeature[property.label] = property.value;
            });
            commit('loadDrawingAttributes', newFeature);
        }
    },
    updateDrawingGeometry({ commit }, geometry) {
        commit('mutateDrawingGeometry', geometry);
    },
    setShowAttributeModal({ commit }, showVal) {
        commit('updateShowAttributeModal', showVal);
    },
    buildLayersStruct({ commit }, projectID) {
        var layersStruct = [];
        var erLayerIDs = [];
        projectService.getProjectLayers(projectID).then((res) => {
            var projectLayers = res.data;
            if (projectLayers[0].WMSLayer === null) {
                commit('setLayersStruct', {});
                return;
            }
            projectLayers[0].WMSLayer.forEach((wmsLayer) => {
                if (wmsLayer.WMSName.includes('vw_ER')) {
                    erLayerIDs.push(wmsLayer.WMSLayerId);
                }
                var layer = {
                    id: wmsLayer.WMSLayerId,
                    url: wmsLayer.WMSUrl,
                    name: wmsLayer.WMSLabel,
                    isChecked: wmsLayer.IsVisible,
                    format: wmsLayer.WMSFormat,
                    layers: wmsLayer.WMSName,
                    styles: wmsLayer.Style,
                    transparent: true,
                    attribution: '',
                    options: {
                        maxZoom: 21,
                        maxNativeZoom: 18,
                        editTime: 0,
                    },
                    legend: '',
                    printLegend: '',
                    hasChild: wmsLayer.WMSUrl == '',
                    isExpanded: false,
                    mapLayerID: wmsLayer.MapLayerID,
                    zIndex: 10000 - wmsLayer.LayerOrder,
                    tileSize: wmsLayer.TileSize,
                    externalCache: wmsLayer.ExternalCache,
                    internalCache: wmsLayer.InternalCache,                        
                    minZoom: wmsLayer.MinZoom,
                    maxZoom: wmsLayer.MaxZoom,
                    toc_groupOrder: wmsLayer.toc_groupOrder,
                    toc_layerOrder: wmsLayer.toc_layerOrder,
                };
                if (wmsLayer.BranchID !== undefined) {
                    layer.parentID = wmsLayer.BranchID;
                }
                if (wmsLayer.query !== undefined) {
                    layer.options['CQL_Filter'] = wmsLayer.query;
                }
                if (wmsLayer.WMSName.includes(':vw_ER_')) {
                    layer.options['editTime'] = Date.now();
                }
                // Build URL
                if (wmsLayer.WMSUrl != '') {
                    layer = buildLegendURL(wmsLayer, layer);
                }
                layersStruct.push(layer);
            });
            layersStruct.forEach((branch) => {
                branch['children'] = getChildren(branch.id, layersStruct);
            });
            var newLayersStruct = {};
            layersStruct.forEach((branch) => {
                newLayersStruct[branch.id] = branch;
            });
            commit('setERLayerIDs', erLayerIDs);
            commit('setLayersStruct', newLayersStruct);
        });
    },
    resetStore({ commit }) {
        commit('mutateProjectID', null);
        commit('setLayersStruct', null);
        commit('updateShowAttributeModal', false);
        commit('clearDrawingAttributes');
    },
    activateERLayers({ commit, state }) {
        state.erDataLayerIDs.forEach((layerID) => {
            commit('updateIsChecked', { id: layerID, visibility: 'show' });
        });
    },
};
const mutations = {
    mutateProjectID(state, projectID) {
        state.projectID = projectID;
    },
    // get datasets
    clearDrawingAttributes(state) {
        state.drawingAttributes = {
            geoID: 0,
            geom: null,
            geomWKT: null,
            geomLocName: null,
            geomLocType: null,
            geomLocDesc: null,
            geomComment: null,
        };
    },
    setERLayerIDs(state, payload) {
        state.erDataLayerIDs = payload;
    },
    setEditTime(state, payload) {
        Vue.set(
            state.layersStruct[payload.layerID].options,
            'editTime',
            payload.editTime
        );
        //state.layersStruct[payload.layerID].options.editTime = payload.editTime
    },
    loadDrawingAttributes(state, feature) {
        state.drawingAttributes = {
            projectID: state.projectID,
            geoID: feature.FeatureID,
            geom: feature.geom,
            geomWKT: feature.geomWKT,
            geomLocName: feature.LocName,
            geomLocType: feature.LocType,
            geomLocDesc: feature.GeoDescription,
            geomComment: feature.Comment,
        };
    },
    mutateDrawingGeometry(state, geom) {
        state.drawingAttributes.geom = geom;
        state.drawingAttributes.geomWKT = spatialFunctions.convertToWKT(geom);
    },
    setLayersStruct(state, struct) {
        state.layersStruct = struct;
    },
    toggleExpanded(state, itemID) {
        state.layersStruct[itemID].isExpanded = !state.layersStruct[itemID]
            .isExpanded;
    },
    // update the checked values when one checkbox is clicked
    // visibility options are [toggle, show, hide]
    updateIsChecked(state, { id, visibility }) {
        // don't want to call this in every if
        visibility = visibility.toLowerCase();
        if (visibility == 'toggle') {
            state.layersStruct[id].isChecked = !state.layersStruct[id]
                .isChecked;
        } else if (visibility == 'show') {
            state.layersStruct[id].isChecked = true;
        } else if (visibility == 'hide') {
            state.layersStruct[id].isChecked = false;
        }
        if (state.layersStruct[id].isChecked) {
            // setting isChecked true only goes up the tree so that the parents are visible
            state.layersStruct = updateTreeTrunk(
                id,
                state.layersStruct[id].isChecked,
                state.layersStruct
            );
        }
        // updates show-hide of layers down-tree from the selected node
        state.layersStruct = updateTreeBranches(
            id,
            state.layersStruct[id].isChecked,
            state.layersStruct
        );
    },
    updateLayerVisibility(state, node) {
        state.layersStruct[node.id].isChecked = node.value;
    },
    updateShowAttributeModal(state, value) {
        state.showAttributeModal = value;
    },
    toggleWeatherUnits(state, value) {
        state.weatherUnits = value;
    },
    setMapbookJobIDs(state, jobIDs) {
        state.mapbookJobIDs = jobIDs;
    },
    clearMapbookJobIDs(state) {
        state.mapbookJobIDs = [];
    },
    setEventLogPositions(state, eventLogPositions) {
        state.EventLogPositions = eventLogPositions;
    },
};

const getters = {
    getShowAttributeModal: (state) => state.showAttributeModal,
    getLayersStruct: (state) => state.layersStruct,
    getERLayerNames: (state) =>
        Object.values(state.layersStruct)
            .filter((layer) => state.erDataLayerIDs.includes(layer.id))
            .map((layer) => layer.name.replace(/ /g, '')),

    getLayer: (state) => (id) => {
        return state.layersStruct[id];
    },
};

const namespaced = true;

export const emergencyManagement = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
