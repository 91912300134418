<script>
import { propsBinder } from 'vue2-leaflet';
import { control } from 'leaflet';

/**
 * Add any custom component as a leaflet control-scale
 */
export default {
    name: 'LControlScale',
    components: {
        control,
    },
    mixins: [],
    props: {
        position: {
            type: String,
            default: 'bottomleft',
        },
        unitSystem: {
            type: String,
            default: 'both',
        },
        maxWidth: {
            type: Number,
            default: 100,
        },
    },
    mounted() {
        const options = {
            position: this.position,
            maxWidth: this.maxWidth,
            metric: ['metric', 'both'].includes(this.unitSystem.toLowerCase()),
            imperial: ['imperial', 'both'].includes(
                this.unitSystem.toLowerCase()
            ),
            updateWhenIdle: this.updateWhenIdle,
        };
        this.mapObject = control.scale(options);
        //
        propsBinder(this, this.mapObject, this.options);
        this.mapObject.addTo(this.$parent.mapObject);
        this.$nextTick(() => {
            /**
             * Triggers when the component is ready
             * @type {object}
             * @property {object} mapObject - reference to leaflet map object
             */
            this.$emit('ready', this.mapObject);
        });
    },
    render() {
        return null;
    },
    methods: {
        scaleReady() {},
    },
    computed: {},
};
</script>
