<template>
    <div class="login">
        <div class="bg_img">
            <div
                class="d-flex align-items-center justify-content-center grey-overlay"
            >
                <div class="overlay-title">
                    <img class="img-fluid" src="../assets/new_adapt_logo.png" />
                    <h6 class="title">
                        ADVANCED DATA ANALYTICS PORTAL & TOOLS
                    </h6>
                </div>
                <b-overlay
                    class="d-flex align-items-center justify-content-center login"
                >
                    <b-card class="form_card" text-variant="#000000DE">
                        <b-card-text class="text-center mb-3 welcome">
                            Welcome
                        </b-card-text>
                        <b-card-text class="text-center">
                            Sign in to visualize and manage your data from many
                            perspectives including spatial, graphical, and
                            tabular formats.
                        </b-card-text>
                        <b-card-text
                            v-if="sentOtp"
                            class="otp-message text-center"
                        >
                            *If an account exists, we will send a code. Please
                            check your inbox.
                        </b-card-text>
                        <b-card-body>
                            <b-form @submit.prevent="submit">
                                <b-form-group
                                    id="input-group-1"
                                    label="Email address:"
                                    label-for="input-1"
                                >
                                    <b-form-input
                                        id="input-1"
                                        v-model="email"
                                        type="email"
                                        placeholder="Enter email"
                                        required
                                        :disabled="isDisabled"
                                    ></b-form-input>
                                </b-form-group>

                                <b-form-group
                                    id="input-group-2"
                                    label="OTP:"
                                    label-for="input-2"
                                    v-if="sentOtp"
                                >
                                    <b-form-input
                                        id="input-2"
                                        v-model="otp"
                                        type="text"
                                        placeholder="Enter OTP"
                                        required
                                    ></b-form-input>
                                </b-form-group>
                                <b-button
                                    rounded
                                    class="btn-color"
                                    type="submit"
                                    v-if="!sentOtp"
                                    :disabled="disabled"
                                >
                                    Request OTP
                                </b-button>
                                <b-button
                                    rounded
                                    class="btn-color"
                                    type="submit"
                                    v-else
                                >
                                    Login
                                </b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-overlay>
            </div>
        </div>
        <div class="modal_unauthorized">
            <b-modal
                v-bind:no-close-on-backdrop="false"
                ref="unauthorized-modal"
                class="model_style"
                hide-header
                hide-footer
                centered
            >
                <div class="d-block text-center">
                    <div
                        class="text-right cursor-pointer d-flex flex-row justify-content-between"
                    >
                        <button
                            class="return-btn btn border-0 btn-light p-2"
                            @click="backToHome()"
                        >
                            <a href="#" class="pr-2">
                                <img
                                    class=""
                                    src="../assets/arrow_left.svg"
                                    alt="Close Icon"
                            /></a>
                            <span>Back to Login</span>
                        </button>

                        <img
                            class="rounded btn-light  px-2"
                            src="../assets/close_icon.svg"
                            @click="closeModal()"
                        />
                    </div>
                    <img class="mt-1" src="../assets/alert-circle.svg" />
                    <h2 class="mt-2">Unauthorized</h2>
                    <p class="modal-context py-2 mt-3 ">
                        {{ unauthorizedMessage }}
                    </p>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import router from '../router';
export default {
    data() {
        return {
            email: '',
            otp: '',
            sentOtp: false,
            disabled: false,
            unauthorizedMessage: '',
        };
    },
    methods: {
        submit() {
            if (this.sentOtp) {
                this.login();
            } else {
                this.requestOTP();
            }
        },
        requestOTP() {
            this.disabled = true;
            const apiUrl =
                process.env.VUE_APP_API_URI +
                'UserToken/GetUserCode?email=' +
                this.email;
            axios
                .post(apiUrl)
                .then((response) => {
                    this.sentOtp = true;
                })
                .catch((error) => {
                    this.unauthorizedMessage =
                        error.response.data.message.Message;
                    this.$refs['unauthorized-modal'].show();
                    this.disabled = false;
                });
        },
        login() {
            const apiUrl =
                process.env.VUE_APP_API_URI +
                'UserToken/GetUserToken?email=' +
                this.email +
                '&code=' +
                this.otp;
            axios
                .post(apiUrl)
                .then((response) => {
                    var userDetails = {
                        name: response.data.UserName,
                        username: response.data.EmailID,
                        isEmailLogin: true,
                    };
                    localStorage.setItem(
                        'userDetails',
                        JSON.stringify(userDetails)
                    );
                    document.cookie = `accessToken=${response.data.Access};path=/;`;
                    document.cookie = `refreshToken=${response.data.Refresh}; path=/;`;
                    this.$store.commit('common/setToken', response.data.Access);
                    router.push('/dashboard');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        closeModal() {
            this.$refs['unauthorized-modal'].hide();
        },
        backToHome() {
            this.$router.push('/');
        },
    },
    computed: {
        isDisabled() {
            return this.sentOtp;
        },
    },
};
</script>

<style scoped>
.otp-message {
    color: #ff1e00; /* Red color */
}

.bg_img {
    background: url(../assets/login_bg.png) no-repeat center center fixed;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: border-box;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
}
.login .form_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 13px;
    background-color: rgba(215, 220, 222, 0.9) !important;
    padding-top: 5%;
    padding-left: 40px;
    padding-right: 40px;
    width: 400px;
    margin: 65% 0 0 0;
}
.welcome {
    font-size: 30px;
    font-weight: 600;
}
.overlay-title {
    position: absolute;
    top: 90px;
    text-align: center;
    color: #fff;
}
.title {
    margin: 16px 0;
    font-size: 12px;
    letter-spacing: 1px;
}
input {
    width: 100% !important;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.form_card button {
    width: 100%;
    padding: 10px;
    background: #00496d !important;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}
.modal-context {
    font-size: 16px;
}
@media screen and (max-width: 992px) {
    .form_card {
        padding-top: 2%;
        margin: 45% 0 0 0;
    }
}
</style>
