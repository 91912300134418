import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const datasetService = {
    getDatasets,
    getAdddataSets,
    getDatasetColumnAliasName,
    getDataTypeColumn,
    deleteDataset,
    getEditingDataSet,
    updateLayerOrder,
    defineDataset,
    getProcedureParameters,
    legacyProjectInfo,
    getTableselectools,
    getFilterOptions,
    getFilterOptionsTable,
    filterDataset,
    filterDatasetLegacy,
    filteredData,
    GetExternalDataSorted,
    exportToAccessDB,
    createDocManagerRequest,
    getDataforChartelement,
    getAttributes,
    getDataTypes,
    getToolattributesUsingId,
    saveDataSet,
    getScatterplotData,
    editDataset,
    updateDataset,
    getminMaxInterval,
    GetDistinctFilteredvalues,
    getProjectLayerOrder,
    getSavedWork,
    savefilters,
    updateSavedWork,
    deleteSaveWork,
    getPowerBIDatasets,
    getStoredProcedureResults,
    UpdateUserPortalOptionsDetails,
    getPagedDataGrid,
    GetTitleBlockDistinctFilteredvalues,
    getDataforCrosstab,
    submitCrosstabInfo,
    initializeRequest,
    getStatus,
    getQueue,
    getToolId,
    getStoredProcedureColumns,
    saveStoredProcedure,
    updateStoredProcedure,
    updateGeoDataBase,
};

function getminMaxInterval(
    projectIds,
    datasetID,
    getminMaxInterval,
    yAxisChecked,
    filterOptions
) {
    return request({
        url:
            apiConstants.getminMaxInterval +
            '?projectIds=' +
            projectIds +
            '&dataSetId=' +
            datasetID +
            '&query=' +
            getminMaxInterval +
            '&isexist=' +
            yAxisChecked,
        data: filterOptions,
        method: 'post',
        headers: { 'api-version': '2.0' },
    });
}
//getting datasets from database
function getDatasets(datasetID = null) {
    if (datasetID) {
        return request({
            url:
                apiConstants.getDatasetList +
                '?$filter=DataSourceId eq ' +
                datasetID,
        });
    } else {
        return request({
            url: apiConstants.getDatasetList,
        });
    }
}

function getDatasetColumnAliasName(datasetID, projectId) {
    if (!projectId) {
        projectId = 0;
    }
    return request({
        url:
            apiConstants.getDatasetColumnAliasName +
            '?DatasetId=' +
            datasetID +
            '&ProjectId=' +
            projectId,
    });
}
function getDataTypeColumn(datasetID, projectId) {
    if (!projectId) {
        projectId = 0;
    }
    return request({
        url:
            apiConstants.getDataTypeColumn +
            '?DatasetId=' +
            datasetID +
            '&ProjectId=' +
            projectId,
    });
}

function getEditingDataSet(datasetId) {
    return request({
        url: apiConstants.getEditingDataSet + '?datasetId=' + datasetId,
    });
}

function getProjectLayerOrder(projectId) {
    return request({
        url: apiConstants.getProjectLayerOrder + '?projectId=' + projectId,
    });
}

function updateLayerOrder(data) {
    return request({
        url: apiConstants.updateLayerOrder,
        data: data,
        method: 'put',
    });
}
//getting datasets from external source
function getAdddataSets(externalId) {
    return request({
        url: apiConstants.addDatasetexternal + '?dataSourceId=' + externalId,
    });
}

//deleting dataset
function deleteDataset(dID) {
    return request({
        url: apiConstants.deleteDataset + '/' + dID,
        method: 'delete',
    });
}

//getting dataset in dataset columns page for add functionality
function defineDataset(params) {
    return request({
        url: apiConstants.defineDatasetColumns,
        params: params,
        method: 'get',
    });
}

function getProcedureParameters(params) {
    return request({
        url: apiConstants.getProcedureParameters,
        params: params,
        method: 'get',
    });
}

function legacyProjectInfo(datasourceid, table, columnProj, columnDesc) {
    if (columnDesc) {
        return request({
            url:
                apiConstants.legacyProjectInfoFiltered +
                '?dataSourceId=' +
                datasourceid +
                '&tableName=' +
                table +
                '&distinctColumnName=' +
                columnProj +
                '&columnDescription=' +
                columnDesc,
        });
    } else {
        return request({
            url:
                apiConstants.legacyProjectInfoFiltered +
                '?dataSourceId=' +
                datasourceid +
                '&tableName=' +
                table +
                '&distinctColumnName=' +
                columnProj,
        });
    }
}
function getTableselectools(params) {
    return request({
        url: apiConstants.tableselectToollist + '?projectIds=' + params,
    });
}
function getFilterOptions(datasetid, projectId) {
    return request({
        url:
            apiConstants.getFilterOption +
            '?dataSetId=' +
            datasetid +
            '&projectIds=' +
            projectId +
            '&$expand=DatasetFilterGroup,DatasetColumn',
    });
}
function getFilterOptionsTable(datasetid, projectId) {
    return request({
        url:
            apiConstants.getFilterOption +
            '?dataSetId=' +
            datasetid +
            '&projectIds=' +
            projectId,
    });
}
function filterDataset(sourceid, tablename, columnname) {
    return request({
        url:
            apiConstants.filterDatasetCol +
            '?dataSourceId=' +
            sourceid +
            '&tableName=' +
            tablename +
            '&distinctColumnName=' +
            columnname,
    });
}

function filterDatasetLegacy(
    sourceid,
    tablename,
    columnname,
    legacyProjectId,
    ProjectIDs
) {
    return request({
        url:
            apiConstants.filterDatasetColLegacy +
            '?dataSourceId=' +
            sourceid +
            '&tableName=' +
            tablename +
            '&distinctColumnName=' +
            columnname +
            '&LegacyProjectIds=' +
            legacyProjectId +
            '&projectId=' +
            ProjectIDs,
    });
}

function filteredData(listCheckedOptions) {
    return request({
        url: apiConstants.filteredData,
        data: listCheckedOptions,
        method: 'post',
    });
}

function GetExternalDataSorted(listCheckedOptions) {
    return request({
        url: apiConstants.GetExternalDataSorted,
        data: listCheckedOptions,
        method: 'post',
    });
}

function exportToAccessDB(filters, datasetId, projectIds) {
    return request({
        url:
            apiConstants.exportAccessDB +
            '?dataSetId=' +
            datasetId +
            '&projectIds=' +
            projectIds,
        data: filters,
        method: 'post',
    });
}

function createDocManagerRequest(SiteCode, PPIN, DocType) {
    return request({
        url: apiConstants.exportToDocManager,
        data: {
            SiteCode: SiteCode,
            PPIN: PPIN,
            DocType: DocType,
        },
        method: 'post',
    });
}

function getDataforChartelement(datasetId) {
    return request({
        url:
            apiConstants.datasetToolattributes +
            '?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/ToolId eq 4 and DatasetColumn/DatasetId eq ' +
            datasetId,
    });
}
function getDataforCrosstab(datasetId, toolId) {
    return request({
        url:
            apiConstants.datasetToolattributes +
            '?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/ToolId eq ' +
            toolId +
            ' and DatasetColumn/DatasetId eq ' +
            datasetId,
    });
}

function editDataset(datasetId) {
    return request({
        url:
            apiConstants.editDataSet +
            '?$expand=DatasetColumns($expand=DatasetToolAttributes,DatasetFilterColumns($select=DatasetColumnId))&filter=DatasetId eq ' +
            datasetId +
            ')',
    });
}

function getAttributes() {
    return request({
        url: apiConstants.getToolattributes + '?$expand=ToolAttributes',
    });
}

function getDataTypes() {
    return request({ url: apiConstants.getDatatypes + '?$expand=Datatypes' });
}
function getToolattributesUsingId(id) {
    return request({
        url:
            apiConstants.getToolattributes +
            '?$expand=ToolAttributes($filter=ToolId eq ' +
            id +
            ')',
    });
}

function saveDataSet(data) {
    if (data.ProjectLayer && !data.ProjectLayer.UsePointOutline) {
        data.ProjectLayer.PointOutlineColour = null;
    }
    return request({
        url: apiConstants.saveDataSet,
        data: data,
        method: 'post',
    });
}

function updateDataset(id, data) {
    if (data.ProjectLayer && !data.ProjectLayer.UsePointOutline) {
        data.ProjectLayer.PointOutlineColour = null;
    }
    return request({
        url: apiConstants.updateDataset + '/' + id,
        data: data,
        method: 'put',
    });
}

function getScatterplotData(
    projectIds,
    datasetid,
    xcol,
    ycol,
    seriescol,
    filterOptions
) {
    return request({
        url:
            apiConstants.getScatterPlot +
            '?projectIds=' +
            projectIds +
            '&dataSetId=' +
            datasetid +
            '&xColumnName=' +
            xcol +
            '&yColumn=' +
            ycol +
            '&seriescolumns=' +
            seriescol,
        data: filterOptions,
        method: 'post',
        headers: { 'api-version': '2.0' },
    });
}

function GetDistinctFilteredvalues(
    projectIds,
    datasetid,
    column,
    filterOptions,
    yaxis = ''
) {
    return request({
        url:
            apiConstants.GetDistinctFilteredvalues +
            '?projectIds=' +
            projectIds +
            '&dataSetId=' +
            datasetid +
            '&column=' +
            column +
            '&yaxis=' +
            yaxis,
        data: filterOptions,
        method: 'post',
        headers: { 'api-version': '2.0' },
    });
}

function GetTitleBlockDistinctFilteredvalues(datasetid, column, filterOptions) {
    return request({
        url:
            apiConstants.GetDistinctFilteredvalues +
            '?dataSetId=' +
            datasetid +
            '&column=' +
            column,
        data: filterOptions,
        method: 'post',
    });
}

function getSavedWork(projectID) {
    return request({
        url:
            apiConstants.getSavedWork +
            '?projectId=' +
            projectID +
            '&toolId=' +
            6,
    });
}

function savefilters(filters) {
    return request({
        url: apiConstants.saveFilters,
        data: filters,
        method: 'post',
    });
}

function UpdateUserPortalOptionsDetails(details) {
    return request({
        url: apiConstants.UpdateUserPortalOptionsDetails,
        data: details,
        method: 'put',
    });
}

function updateSavedWork(id) {
    return request({
        url: apiConstants.updateSavedWork + '/' + id,
        method: 'put',
    });
}

function deleteSaveWork(id) {
    return request({
        url: apiConstants.updateSavedWork + '/' + id,
        method: 'delete',
    });
}

function getPowerBIDatasets(params) {
    return request({
        url: apiConstants.getPowerBIDatasets + '?projectIds=' + params,
    });
}

function getStoredProcedureResults(data) {
    return request({
        url: apiConstants.getStoredProcedureResults,
        data: data,
        method: 'post',
    });
}
function getPagedDataGrid(projectIds, datasetId) {
    return (
        apiConstants.getPagedDataGrid +
        '?projectIds=' +
        projectIds +
        '&datasetId=' +
        datasetId
    );
}

function submitCrosstabInfo(data) {
    return request({
        url: apiConstants.makeXTab,
        data: data,
        method: 'post',
    });
}
function initializeRequest(projectId, requestType, requestBody = null) {
    if (requestBody === null) {
        return request({
            url:
                apiConstants.initializeRequest +
                '?projectId=' +
                projectId +
                '&requestType=' +
                requestType,
        });
    } else {
        return request({
            url: apiConstants.initializeRequest + '?requestType=' + requestType,
            data: requestBody,
            method: 'post',
        });
    }
}
function getStatus(JobID) {
    return request({
        url: apiConstants.getStatus + '?JobID=' + JobID,
    });
}
function getQueue(JobID) {
    return request({
        url: apiConstants.getQueue + '?JobID=' + JobID,
    });
}
function getToolId(toolName) {
    return request({
        url:
            apiConstants.getToolsList +
            "?$filter=ToolName eq '" +
            toolName +
            "'&$select=ToolId",
    });
}
function getStoredProcedureColumns(data) {
    return request({
        url: apiConstants.getStoredProcedureColumns,
        data: data,
        method: 'post',
    });
}
function saveStoredProcedure(data) {
    return request({
        url: apiConstants.saveStoredProcedure,
        data: data,
        method: 'post',
    });
}
function updateStoredProcedure(data) {
    return request({
        url: apiConstants.updateStoredProcedure,
        data: data,
        method: 'put',
    });
}
function updateGeoDataBase(data) {
    return request({
        url: apiConstants.UpdateGeoDB + '?datasetID=' + data,
        method: 'post',
    });
}
