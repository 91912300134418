<template>
    <div class="dynamicDataGrid" id="wrapper">
        <div v-if="Object.keys(tbl).length === 0 && tbl.constructor === Object">
            No tables to display
        </div>
        <div v-else style="height: 90%">
            <b-tabs
                class="selecting tabs title-b"
                active-nav-item-class=" pl-0 pr-0 borderBlue"
                v-model="selectedTab"
                id="tabs"
            >
                <b-tab
                    v-for="(t, idx) in tabNames"
                    :key="idx"
                    :title="t.split('|').reverse()[0]"
                >
                </b-tab>
                <b-button
                    v-show="hasDropdownEntries"
                    @click="toggleDropdown"
                    id="btn-dropdown"
                    class="my-1 btn-dropdown"
                >
                    More
                    <img src="../../assets/arrow_down.svg" class="" />
                </b-button>
                <div
                    v-show="hasDropdownEntries"
                    id="dropdown"
                    class="dropdown-content"
                >
                    <ul
                        id="dropdownItems"
                        aria-labelledby="dropdownMenuButton"
                    ></ul>
                </div>
            </b-tabs>
            <ejs-grid
                ref="grid"
                class="sortingenabled"
                :dataSource="tabData"
                :columns="columns"
                :allowExcelExport="true"
                :allowPdfExport="true"
                :allowPaging="true"
                :pageSettings="pageOptions"
                :allowResizing="true"
                :allowSorting="true"
                :allowFiltering="true"
                :filterSettings="filterOptions"
                :commandClick="commandClick"
                :editSettings="editSettings"
                :load="pageCenter"
                :toolbar="toolbarOptions"
                :toolbarClick="toolbarClick"
                enableStickyHeader="true"
            >
            </ejs-grid>
        </div>
    </div>
</template>
<script>
import {
    CommandColumn,
    Edit,
    ExcelExport,
    Filter,
    GridPlugin,
    Page,
    PdfExport,
    Resize,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-vue-grids';
import Vue from 'vue';
import { mapActions } from 'vuex';
import { spatialService } from '../../services/spatial.services';
import { spatialFunctions } from '../../utilities/spatialFunctions';
Vue.use(GridPlugin);

export default {
    name: 'DynamicDataGrid',
    props: {
        tbl: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            selectedTab: 0,
            editSettings: { allowEditing: true, allowDeleting: true },
            commands: [
                {
                    buttonOption: {
                        content: 'Zoom',
                        cssClass: 'btn btn-identify btn-zoom',
                        iconCss: 'e-zoomTo e-icons',
                    },
                }, // bind the button here
            ],
            filterOptions: {
                type: 'Excel',
            },
            pageOptions: {
                pageSize: 12,
            },
        };
    },
    provide: {
        grid: [
            Page,
            Edit,
            Toolbar,
            CommandColumn,
            ExcelExport,
            Filter,
            Resize,
            PdfExport,
            Sort,
        ],
    },
    methods: {
        ...mapActions('emergencyManagement', ['activateERLayers']),
        createPointFeature(row, latFieldName, lngFieldName) {
            let feature = {
                geometry: {
                    type: 'Point',
                    coordinates: [row[latFieldName], row[lngFieldName]],
                },
            };
            return feature;
        },
        commandClick: function(args) {
            let row = args.rowData;
            if ('SampleID' in row) {
                this.activateERLayers();
            }
            if ('LAT' in row && 'LNG' in row) {
                let feature = this.createPointFeature(row, 'LAT', 'LNG');
                this.$emit('zoomTo', feature);
                this.$emit('highlight', feature);
            } else if ('Lat' in row && 'Long' in row) {
                let feature = this.createPointFeature(row, 'Lat', 'Long');
                this.$emit('zoomTo', feature);
                this.$emit('highlight', feature);
            } else if ('Latitude' in row && 'Longitude' in row) {
                let feature = this.createPointFeature(
                    row,
                    'Latitude',
                    'Longitude'
                );
                this.$emit('zoomTo', feature);
                this.$emit('highlight', feature);
            } else {
                spatialService
                    .reverseLookupGeometry(
                        this.mapLayers[this.selectedTab],
                        args.rowData.ID
                    )
                    .then((res) => {
                        var bbox = { geometry: { type: 'Polygon' } };
                        // have to use lng-lat coords for this, drawing needs lat-lng
                        this.$emit(
                            'zoomTo',
                            spatialFunctions.convertWktToObject(
                                res.data.BoundingBox
                            ),
                            4326
                        );
                        this.$emit(
                            'highlight',
                            spatialFunctions.convertWktToObject(res.data.Geom)
                        );
                    });
            }
            this.$emit('close');
        },
        toolbarClick: function(args) {
            if (args.item.text === 'PDF Export') {
                //Pdf Export
            } else if (args.item.text === 'Excel Export') {
                var exportData = this.tabData;
                this.$refs.grid.excelExport(exportData);
            }
        },
        tabOverflow() {
            //this method needs to be updated
            var tabs = document.getElementById('tabs__BV_tab_controls_');
            var height = tabs.clientHeight;
            if (height > 50) {
                //tabs too long, overflow to dropdown menu
                document
                    .getElementById('btn-dropdown')
                    .classList.remove('d-none');
                while (height > 50) {
                    var dropdown = document.querySelector('#dropdownItems');
                    var children = tabs.children;
                    var length = children.length - 1;
                    dropdown.prepend(children[length]);
                    height = document.getElementById('tabs__BV_tab_controls_')
                        .clientHeight;
                }
            } else {
                while (
                    height < 50 &&
                    document.querySelector('#dropdownItems').children.length > 0
                ) {
                    //tabs too short, append items from dropdown back to tabs
                    dropdown = document.querySelector('#dropdownItems');
                    children = dropdown.children;
                    length = children.length - 1;
                    if (length > 0) {
                        tabs.prepend(children[length]);
                    }
                    height = document.getElementById('tabs__BV_tab_controls_')
                        .clientHeight;
                }
            }
        },
        toggleDropdown() {
            document.getElementById('dropdown').classList.toggle('d-none');
        },
        pageCenter: function() {
            //this.$refs.grid.ej2Instances.pagerModule.element.align = 'center' ;
            if (this.$refs.grid) {
                this.$refs.grid.ej2Instances.pagerModule.element.align =
                    'center';
            } else if (this.grid) {
                this.grid.pagerModule.element.align = 'center';
            }
        },
    },
    computed: {
        tabNames() {
            return Object.keys(this.tbl);
        },
        hasDropdownEntries() {
            let tabDropdownItems = document.querySelector('#dropdownItems');
            if (
                tabDropdownItems &&
                document.querySelector('#dropdownItems').children
            ) {
                return tabDropdownItems.children.length > 0;
            } else {
                return false;
            }
        },
        headers() {
            return Object.keys(
                this.tbl[this.tabNames[this.selectedTab]].data[0]
            );
        },
        columns() {
            var cols = [];
            if (this.tbl[this.tabNames[this.selectedTab]].hasGeometry) {
                cols.push({ width: 110, commands: this.commands });
            }
            this.headers.forEach((element) => {
                cols.push({
                    field: element,
                    headerText: element,
                    width: element.length * 15 + (element.length <= 5 ? 15 : 0),
                });
            });
            return cols;
        },
        tabData() {
            return this.tbl[this.tabNames[this.selectedTab]].data;
        },
        mapLayers() {
            var lyrs = [];
            for (var i = 0; i < this.tabNames.length; i++) {
                lyrs.push(this.tabNames[i].split('|')[0]);
            }
            return lyrs;
        },
        toolbarOptions() {
            return this.tbl[this.tabNames[this.selectedTab]].exportOption;
        },
    },
    created() {
        setTimeout(() => {
            // this.tabOverflow();
        }, 1000);
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.tabOverflow();
        });
        // this.$refs.grid.autoFitColumns();
    },
};
</script>
<style lang="scss" scoped>
#wrapper {
    height: 100%;
}
.e-grid {
    height: 100%;
    overflow-y: scroll;
}
.btn {
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 40px;
    /* need correct font-family */
}
.sortingenabled {
    border: none;
}

.btn-dropdown {
    color: #006395 !important;
    background-color: white !important;
    border: solid 1px darkgray !important;
    position: absolute;
    right: 20px;
    top: 7px;
}
.dropdown-content {
    background-color: white;
    display: block;
    position: absolute;
    right: 20px;
    top: 50px;
    min-width: 160px;
    height: auto;
    z-index: 1;
    border-top: solid 1px darkgray !important;
    border-left: solid 1px darkgray !important;
    border-right: solid 1px darkgray !important;
    border-radius: 4px;
}
#dropdownItems {
    padding-left: 0px !important;
    margin-bottom: 0 !important;
}
</style>

<style lang="scss">
.btn-identify {
    background-color: rgb(129, 129, 129);
    color: white;
    width: 90px;
}
.e-grid .e-zoomTo {
    content: url('../../assets/search-outline-grey.svg') !important;
    left: 30px !important;
}
.btn-zoom {
    width: 100px !important;
    margin-left: 10px;
    margin-right: 10px;
}
</style>
