<template>
    <div class="ProjectList mt-3">
        <b-row class="m-0">
            <b-col cols="12" class="border-top pb-2 m-shadow m-b-10">
                <b-row class="m-0 mt-2 m-mrg-top-20">
                    <input
                        ref="projectsSearch"
                        spellcheck="false"
                        id="searchFilter"
                        v-if="
                            filterProjectSearch || showSearchIcon ? true : false
                        "
                        v-model="filterProjectSearch"
                        @click="searchinputenter()"
                        @input="searchinputenter"
                        :class="
                            filterProjectSearch || showSearchIcon
                                ? 'test'
                                : 'searchinputList'
                        "
                        type="search"
                        placeholder="Start typing to search for a project"
                    />
                    <span class="grey-bg" v-if="searchClose" @click="closeBar"
                        ><img src="../../assets/close.svg"
                    /></span>
                    <b-col cols="0" class="noPadding mt-2 m-mt-2">
                        <img
                            class="pointer  ml-2  "
                            src="../../assets/search-outline.svg"
                            v-if="
                                !searchQuery && !showSearchIcon ? true : false
                            "
                            @click="searchOpen"
                        />

                        <span class="border-right list ml-2">&nbsp;</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <div>
            <ejs-grid
                id="tblProject"
                :dataSource="projects"
                class="sortingenabled"
                ref="grid"
                :allowPaging="true"
                :allowSorting="true"
                :commandClick="gridRowData"
                :load="pageCenter"
                :dataBound="dataBound"
                :sortSettings="sortOptions"
                :pageSettings="pageSettings"
                :allowResizing="true"
            >
                <e-columns>
                    <e-column
                        headerText="Edit"
                        title="Edit"
                        width="80"
                        :commands="editCommands"
                    ></e-column>
                    <e-column
                        field="Project_Number"
                        textAlign="Center"
                        headerText="Project Number"
                        width="150"
                    ></e-column>
                    <e-column
                        field="CRA_Task_Number"
                        headerText="Task"
                        width="150"
                    ></e-column>
                    <e-column
                        field="CRA_SubCode"
                        headerText="Subtask"
                        width="150"
                    ></e-column>
                    <e-column
                        field="Project_Name"
                        headerText="Project Name"
                        width="100"
                    ></e-column>
                    <e-column
                        field="org_name"
                        headerText="Client"
                        width="150"
                    ></e-column>
                    <e-column
                        field="External_Consultant_Project_Reference"
                        headerText="Consultant"
                        width="150"
                    ></e-column>
                    <e-column
                        field="Comments"
                        headerText="Comments"
                        width="150"
                    ></e-column>
                    <e-column
                        field="SiteCode"
                        headerText="Sitecode"
                        width="150"
                    ></e-column>
                </e-columns>
            </ejs-grid>
        </div>

        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />

        <b-modal
            ref="my-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
        >
            <div class="d-block text-center">
                <div class="text-right">
                    <img src="../../assets/close_icon.svg" @click="hideModal" />
                </div>
                <h5 class="mt-2">Delete Project "{{ modalProjectNumber }}"</h5>
                <small
                    >Are you sure you want to delete this project? This action
                    cannot be undone.</small
                ><br />
                <div class="mt-3 mb-4">
                    <a @click="hideModal" class="text-primary"
                        >No, don't delete</a
                    >
                    <button
                        class="buttonDelete ml-4"
                        @click="deleteProject(modalProjectId)"
                    >
                        Delete project
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '../../router';
//import { commonService } from "../../services/common.service";
import { eTrackerService } from '../../services/eTracker.service';
import Vue from 'vue';
import {
    GridPlugin,
    Sort,
    Page,
    Edit,
    CommandColumn,
    Toolbar,
    Search,
    Freeze,
    Resize,
} from '@syncfusion/ej2-vue-grids';
import ToastMessages from '../ToastMessages.vue';
Vue.use(GridPlugin);

export default {
    data() {
        return {
            sortOptions: {
                columns: [
                    { field: 'Project_Number', direction: 'Ascending' },
                    { field: 'CRA_Task_Number', direction: 'Ascending' },
                    { field: 'CRA_SubCode', direction: 'Descending' },
                    { field: 'Project_Name', direction: 'Descending' },
                    { field: 'org_name', direction: 'Descending' },
                    {
                        field: 'External_Consultant_Project_Reference',
                        direction: 'Descending',
                    },
                    { field: 'Comments', direction: 'Descending' },
                    { field: 'SiteCode', direction: 'Descending' },
                ],
            },
            modalProjectId: '',
            modalProjectNumber: '',
            currentPage: 1,
            perPage: 15,
            pageOptions: [5, 10, 15],
            search: null,
            projects: [],
            showSuccess: false,
            showFailure: false,
            successToastMessage: '',
            failureToastMessage: '',
            pageSettings: { pageSize: 10 },
            findState: null,
            editCommands: [
                {
                    type: 'Edit',
                    buttonOption: {
                        cssClass: 'e-flat',
                        iconCss: 'e-edit e-icons',
                    },
                },
            ],
            showPage: true,
            thisfilename: null,
            showSearchIcon: false,
            searchQuery: false,
            searchClose: false,
            filterProjectSearch: null,
            totalRows: 0,
        };
    },
    components: {
        ToastMessages,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Edit,
            CommandColumn,
            Toolbar,
            Search,
            Freeze,
            Resize,
        ],
    },
    mounted() {
        this.pagePermit();
    },
    created() {
        this.getProjects();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.Pagination) {
                this.pageSettings.pageSize = this.$store.state.store.portalsettings.Pagination;
            }
        }
    },
    methods: {
        /*
        ...mapActions("store", {
          getPermissions: "getPermissions",
        }),*/
        getProjects() {
            eTrackerService.getProjects().then((res) => {
                if (res) {
                    this.projects = res.data;
                    this.totalRows = this.projects.length;
                }
            });
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            this.thisfilename = this.$route.matched[0].components.default.__file
                .replace(/^.*[\\/]/, '')
                .split('.')
                .slice(0, -1)
                .join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes(vm.thisfilename)) {
                    vm.showPage = true;
                }
            });
            this.$emit('clickedeTrackerProjectList', this.showPage);
        },
        pageCenter: function() {
            this.$refs.grid.ej2Instances.pagerModule.element.align = 'center';
        },
        dataBound: function() {
            this.$refs.grid.ej2Instances.pagerModule.pagerObj.pagerMessageModule.hideMessage();
        },
        searchiconclick() {
            document.querySelector('#outersearchinput').style.width = 'auto';
            document.querySelector('#outersearchinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outersearchinput .e-input').style.display =
                'block';
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.projectsSearch.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.projectsSearch.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.filterProjectSearch = null;
            this.$refs.grid.$el.ej2_instances[0].search();
        },

        searchinputenter(args) {
            if (args) {
                this.$refs.grid.$el.ej2_instances[0].search(
                    args.currentTarget.value
                );
                this.showSearchIcon = false;
                if (!this.filterProjectSearch) {
                    this.searchClose = false;
                }
            }
        },
        gridRowData: function(args) {
            if (args.target.getAttribute('title') === 'Delete') {
                this.modalProjectId = args.rowData.Project_Id;
                this.modalProject_Number = args.rowData.Project_Number;
                this.$refs['my-modal'].show();
            }
            if (args.target.getAttribute('title') === 'Edit') {
                router.push({
                    path: `/editeTrackerProject/${args.rowData.Project_Id}`,
                });
            }
        },
        deleteProject(projectId) {
            eTrackerService.deleteProject(projectId).then((res) => {
                setTimeout(() => {
                    if (res.data.StatusCode === 200) {
                        this.hideModal();
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage =
                            'Project deleted successfully';
                        setTimeout(() => {
                            this.showSuccess = false;
                            this.getAllUser();
                        }, 5000);
                    } else {
                        this.hideModal();
                        this.showSuccess = false;
                        this.showFailure = true;
                        this.failureToastMessage =
                            'Something went wrong.Please try again later.';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                }, 1000);
                this.$refs['my-modal'].hide();
            });
        },
        showModal(projectDetails) {
            this.modalProjectNumber = projectDetails.Project_Number;
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },
    },
    computed: {
        /*
        ...mapState({
          TotalRows: (state) => state.projects.projects.items?.length,
          deleteStatusCode: (state) => state.projects.remove.StatusCode,
        }),*/
    },
};
</script>
<style lang="scss" scoped>
.fixed_col {
    position: absolute;
    top: auto;
    right: 10%;
    width: 100px !important;

    border-bottom: 0px !important;
}

table th {
    border-bottom: 3px solid black !important;
}
.table > tbody > tr:first-child > td {
    border: none;
}

th.fixed_col {
    border-bottom: 3px solid black !important;
    border-top: 0px solid #dee2e6 !important;
    border-top: 1px solid #dee2e6 !important;
    height: 47px;
}

.button-group .btn-secondary {
    background-color: transparent !important ;
    border-color: transparent !important ;
}
.button-dropdown .btn-secondary {
    background-color: transparent !important;
    border-color: transparent !important;
}

.button-dropdown .dropdown-toggle {
    color: #fff;
    background-color: transparent !important;
    border-color: transparent !important;
}

.show a.option {
    border-bottom: 0px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f3f3f3 !important;
    margin-left: 2px;
    padding-left: 10px;
    padding-right: 11px;
    border: 1px solid #dee2e6 !important;
    box-shadow: 0 0 0 0 transparent !important;
}
.button-dropdown .btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    box-shadow: 0 0 0 0 transparent !important;
}
.button-dropdown .dropdown-menu {
    transform: translate3d(-143px, 36px, 0px) !important;
    border-top-right-radius: 0px !important;
    background-color: #f3f3f3 !important;
    border: 1px solid #dee2e6 !important;
}

.button-dropdown .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: transparent !important;
}
.zip_postal {
    min-width: 150px;
}

.address_width {
    width: 280px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.state_width {
    width: auto;
}
.email_width {
    min-width: 120px;
}

.edit-text {
    margin-top: -13px;
    display: block;
    position: absolute;
    // margin-left: 30px;
}

.test_width {
    width: 733px !important;
}

.edit {
    margin: -10px 0 -10px 0;
    padding: 8px 8px 8px 13px;
}
.delete {
    padding: 8px 8px 8px 13px;
    margin: -10px 0 -10px 0;
    a:hover {
        color: white !important;
    }
}
.edit:hover {
    background-color: #e0e0e0;
    padding: 8px 8px 8px 13px;
}
.delete:hover {
    background-color: red;
    padding: 8px 8px 8px 13px;
}

a {
    //padding: 8px 8px 8px 13px;
    font: normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.16px;
    color: #525252;
    opacity: 1;

    text-align: left;
    /* border-bottom:1px solid; */
    cursor: pointer;
}

button.buttonDelete {
    background-color: #da1e27;
    color: #eee;
    padding: 7px 15px;
    border: none;
}

.modal-content {
    width: 97% !important;
    border-radius: 0px !important;
}
.email_width {
    min-width: 200px;
}
.e-input {
    padding-top: 10px !important;
}
.filteredItem {
    background-color: #eaeaea;
    width: auto;
    padding: 0px 10x;
    margin-right: 10px;
}
.removeFilter a {
    color: #006395 !important;
}

.e-search-icon::before {
    content: '\e993';
    top: 0px !important;
}
#outersearchinput {
    width: 35px;
    border-color: white;
    height: auto !important;
}
#outersearchinput .e-input {
    display: none;
    padding: 0 !important;
}
.filterSelect {
    width: 250px !important;
}
.e-grid .e-edit::before {
    content: url('../../assets/edit.svg') !important;
}
.e-grid .e-delete::before {
    content: url('../../assets/close_icon.svg') !important;
}
.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: White !important;
    border: none !important;
    text-align: center !important;
}
.e-btn.e-flat:hover,
.e-css.e-btn.e-flat:hover {
    background-color: #f5f5f5 !important;
}
.e-grid .e-gridcontent tr td:first-child {
    text-align: left !important;
}
.e-grid .e-gridcontent tr td:last-child {
    text-align: center !important;
}
.filterBar {
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 45px;
}
input.searchinputList {
    background: none !important;
    margin-right: -151px;
    padding-left: 25px;
    width: 180px;
    margin-bottom: 10px;
    top: 3px;
    position: relative;
    cursor: pointer;
    z-index: 0;
}
input.test {
    margin: 0 0px 0 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.filter-group.filter-m {
    left: 0 !important;
    top: 40px !important;
}
.mal-1 {
    margin-left: -10px !important;
}
</style>
