<template>
    <div
        v-show="showPane"
        ref="measurePane"
        :style="measurePaneStyle"
        class="buttonContainer"
    >
        <div class="descText">
            Please use the following buttons to pick a measurement type and
            unit. Then click measure to begin measuring on the map.
        </div>
        <!-- row for the radiobuttons to change between tabs -->
        <div id="radioRow">
            <span>
                <input
                    class="radioButton"
                    id="areaRadio"
                    type="radio"
                    value="Area"
                    v-model="measureType"
                    @click="measurement = ''"
                />
                <label class="label" for="areaRadio">Area</label>
            </span>
            <span>
                <input
                    class="radioButton"
                    id="distanceRadio"
                    type="radio"
                    value="Distance"
                    v-model="measureType"
                    @click="measurement = ''"
                />
                <label class="label" for="distanceRadio">Distance</label>
            </span>
            <span>
                <input
                    class="radioButton"
                    id="locationRadio"
                    type="radio"
                    value="Location"
                    v-model="measureType"
                    @click="measurement = ''"
                />
                <label class="label" for="pointRadio">Location</label>
            </span>
        </div>
        <select
            ref="areaUnitSelect"
            v-show="measureType == 'Area'"
            class="unitSelect"
            @change="setMeasurementUnits"
        >
            <option
                v-for="(unit, index) in units['area']"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option>
        </select>
        <select
            ref="distanceUnitSelect"
            v-show="measureType == 'Distance'"
            class="unitSelect"
            @change="setMeasurementUnits"
        >
            <option
                v-for="(unit, index) in units['distance']"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option>
        </select>
        <select
            ref="locationUnitSelect"
            v-show="measureType == 'Location'"
            class="unitSelect"
            @change="setMeasurementUnits"
        >
            <option
                v-for="(unit, index) in units['location']"
                :key="index"
                :value="unit"
            >
                {{ unit }}
            </option> </select
        ><br />
        <!-- Measure Area tab -->
        {{ convertedMeasurement }}
        <br />
        <div class="d-flex justify-content-between pb-1">
            <button
                class="btn btn-gray "
                v-bind:class="
                    clickFunction == 'measureArea' ? 'btn-blue' : 'btn-gray'
                "
                @click="startMeasure"
            >
                Measure
            </button>
            <button
                v-if="clickFunction != ''"
                class="btn btn-clear btn-buffer"
                @click="cancelMeasure"
            >
                Cancel
            </button>
            <button class="btn btn-clear btn-buffer" @click="clearMeasure">
                Clear
            </button>
        </div>
    </div>
</template>

<script>
import { getPathLength, getAreaOfPolygon } from 'geolib';
import { spatialFunctions } from '../../utilities/spatialFunctions.js';

/**
 * Add any custom component as a leaflet control
 */
export default {
    name: 'MeasurePane',
    components: {},
    mixins: [],
    props: {
        showPane: {
            type: Boolean,
            default: false,
            required: true,
        },
        midXPosition: {
            type: Number,
            default: 0,
            required: false,
        },
        clickFunction: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            measureType: 'Area',
            measureShapeColor: 'orange',
            startPointRadius: 2,
            measurement: '',
            measureUnits: 'acres',
            width: 300,
            units: {
                location: ['Decimal Degrees', 'D° M\' S"'],
                distance: ['Meters', 'Feet', 'Kilometers', 'Miles'],
                area: [
                    'Acres',
                    'Hectares',
                    'Square Meters',
                    'Square Feet',
                    'Square Kilometers',
                    'Square Miles',
                ],
            },
        };
    },
    mounted() {
        window.addEventListener('resize', this.updateDrawPanePosition);
        this.$parent.$on('map/click', this.handleMouseClick);
        this.$parent.$on('map/dblClick', this.handleMouseDblClick);
        this.$parent.$on('ready', this.measureReady);
        this.$parent.$emit('ready', this.mapObject);
    },
    render() {
        return null;
    },
    methods: {
        convertLocation(coordinates, target_unit) {
            const conversions = {
                'Decimal Degrees':
                    coordinates.lat.toFixed(6) +
                    ', ' +
                    coordinates.lng.toFixed(6),

                'D° M\' S"': this.dd2dms(coordinates.lat, coordinates.lng),
            };
            return conversions[target_unit];
        },
        convertDistanceFromMeters(distance_meters, target_unit) {
            const conversions = {
                meters: 1,
                feet: 3.28084,
                kilometers: 0.001,
                miles: 0.000621371,
            };
            var convertedDistance =
                distance_meters * conversions[target_unit.toLowerCase()];
            return convertedDistance.toFixed(6);
        },
        convertAreaFromMeters(area_meters, target_unit) {
            const conversions = {
                acres: 0.000247105,
                hectares: 0.0001,
                'square meters': 1,
                'square feet': 10.7639,
                'square kilometers': 0.000001,
                'square miles': 0.000000386102,
            };
            var convertedArea =
                area_meters * conversions[target_unit.toLowerCase()];
            return convertedArea.toFixed(6);
        },
        startMeasure() {
            this.clearBufferShapes();
            this.measurement = '';
            if (this.measureType == 'Location') {
                this.measureUnits = this.$refs.locationUnitSelect.value;
                this.measureLocation();
            } else if (this.measureType == 'Distance') {
                this.measureUnits = this.$refs.distanceUnitSelect.value;
                this.measureDistance();
            } else if (this.measureType == 'Area') {
                this.measureUnits = this.$refs.areaUnitSelect.value;
                this.measureArea();
            }
            this.$emit('updateClickFunction', 'measure' + this.measureType);
        },
        cancelMeasure() {
            this.$emit('updateClickFunction', '');
        },
        clearMeasure() {
            this.measurement = '';
            this.$parent.drawMarkers = this.$parent.drawMarkers.filter(
                (feature) => !feature.id.startsWith('MC')
            );
            this.$parent.drawLines = this.$parent.drawLines.filter(
                (feature) => !feature.id.startsWith('ML')
            );
            this.$parent.drawPolygons = this.$parent.drawPolygons.filter(
                (feature) => !feature.id.startsWith('MP')
            );
            this.$emit('updateClickFunction', '');
        },
        setMeasurementUnits(evt) {
            this.measureUnits = evt.target.value;
        },
        clearBufferShapes() {
            var i = this.$parent.drawMarkers.length;
            while (i--) {
                if (this.$parent.drawMarkers.at(i).id.startsWith('M')) {
                    this.$parent.drawMarkers.splice(i, 1);
                }
            }
            i = this.$parent.drawLines.length;
            while (i--) {
                if (this.$parent.drawLines.at(i).id.startsWith('M')) {
                    this.$parent.drawLines.splice(i, 1);
                }
            }
            i = this.$parent.drawPolygons.length;
            while (i--) {
                if (this.$parent.drawPolygons.at(i).id.startsWith('M')) {
                    this.$parent.drawPolygons.splice(i, 1);
                }
            }
        },
        cleanupShapes() {
            var i = this.$parent.drawLines.length;
            while (i--) {
                if (
                    this.$parent.drawLines.at(i).geometry.coordinates[0]
                        .length < 2
                ) {
                    this.$emit('updateClickFunction', '');
                }
            }
            i = this.$parent.drawPolygons.length;
            while (i--) {
                if (
                    this.$parent.drawPolygons.at(i).geometry.coordinates[0]
                        .length < 3
                ) {
                    this.$parent.drawPolygons.splice(i, 1);
                }
            }
        }, // changes an array of arrays to an array of objects
        convertCoordinateStruct(coordinates) {
            let convertedCoordinates = [];
            coordinates.forEach((coordinate) => {
                convertedCoordinates.push({
                    latitude: coordinate[0],
                    longitude: coordinate[1],
                });
            });
            return convertedCoordinates;
        },
        handleMouseClick(event) {
            var [lat, lng] = spatialFunctions.validateCoordinates(event);
            let markerFeature = {
                // using letters and the count to create unique values between different object arrays
                //    since point 1 and line 1 would be overlapping and throw lots of warnings in the console
                //    but not errors that will cause issues in the actual execution/functionality
                // for this tool we use MC, ML, MP for Measure-Circle, Measure-Line, and Polygon
                //    this separates it from the drawtools C, L, P for Circle, Line Polygon
                id: 'MC',
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lng],
                    coordSys: 'LatLong',
                },
                properties: {},
                styling: {
                    SymbologyColour: this.measureShapeColor,
                    SymbologySize: 3,
                    PolygonColor: this.measureShapeColor,
                    PolygonTransparency: 0,
                },
            };
            switch (this.clickFunction) {
                case 'measureLocation':
                    this.$parent.drawMarkers.push(markerFeature);
                    // only want one point at a time
                    this.$emit('updateClickFunction', '');
                    this.measurement = { lat: lat, lng: lng };
                    break;

                case 'measureDistance':
                    if (
                        this.$parent.drawLines.at(-1).geometry.coordinates
                            .length == 0
                    ) {
                        this.$parent.drawMarkers.push(markerFeature);
                    } else if (
                        this.$parent.drawLines.at(-1).geometry.coordinates
                            .length == 1
                    ) {
                        this.$parent.drawMarkers.pop();
                    }
                    this.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.push([lat, lng]);
                    this.measurement = getPathLength(
                        this.convertCoordinateStruct(
                            this.$parent.drawLines.at(-1).geometry.coordinates
                        )
                    );
                    break;

                case 'measureArea':
                    if (
                        this.$parent.drawPolygons.at(-1).geometry.coordinates[0]
                            .length == 0
                    ) {
                        this.$parent.drawMarkers.push(markerFeature);
                        this.$parent.drawPolygons
                            .at(-1)
                            .geometry.coordinates[0].push([lat, lng]);
                    } else if (
                        this.$parent.drawLines.at(-1).geometry.coordinates
                            .length == 1
                    ) {
                        this.$parent.drawMarkers.pop();
                    }
                    this.$parent.drawLines
                        .at(-1)
                        .geometry.coordinates.push([lat, lng]);
                    this.$parent.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0].push([lat, lng]);
                    this.measurement = getAreaOfPolygon(
                        this.convertCoordinateStruct(
                            this.$parent.drawPolygons.at(-1).geometry
                                .coordinates[0]
                        )
                    ).toFixed(2);
                    break;
            }
        },
        // disables the no-unused-vars error for the next line, want to keep event for future changes, not used now
        // eslint-disable-next-line no-unused-vars
        handleMouseDblClick(event) {
            switch (this.clickFunction) {
                case 'measureLocation':
                    break;
                case 'measureDistance':
                    this.$parent.drawLines.at(-1).geometry.coordinates.pop();
                    break;

                case 'measureArea':
                    this.$parent.drawLines.pop();
                    this.$parent.drawPolygons
                        .at(-1)
                        .geometry.coordinates[0].pop();
                    this.$parent.drawPolygons.at(
                        -1
                    ).styling.PolygonTransparency = 0;
                    break;
            }
            this.$emit('updateClickFunction', '');
        },
        measureLocation() {
            this.cleanupShapes('point');
            this.$emit('updateClickFunction', 'measureLocation');
            this.measurement = '';
        },
        measureDistance() {
            this.cleanupShapes('line');
            this.$emit('updateClickFunction', 'measureDistance');
            this.$parent.drawLines.push({
                id: 'ML' + this.$parent.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.measureShapeColor,
                },
            });
            this.measurement = '';
        },
        measureArea() {
            this.cleanupShapes('polygon');
            this.$emit('updateClickFunction', 'measureArea');
            this.$parent.drawLines.push({
                id: 'ML' + this.$parent.drawLines.length,
                geometry: {
                    coordinates: [],
                    type: 'LineString',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonBorderColour: this.measureShapeColor,
                },
            });
            this.$parent.drawPolygons.push({
                id: 'MP' + this.$parent.drawPolygons.length,
                geometry: {
                    coordinates: [[]],
                    type: 'Polygon',
                    coordSys: 'LatLong',
                },
                properties: {
                    length: 0,
                },
                styling: {
                    PolygonColor: this.measureShapeColor,
                    PolygonBorderColour: this.measureShapeColor,
                    PolygonTransparency: 0.8,
                },
            });
            this.measurement = '';
        },
        toDMS(coordinate) {
            var absolute = Math.abs(coordinate);
            var degrees = Math.floor(absolute);
            var minutesNotTruncated = (absolute - degrees) * 60;
            var minutes = Math.floor(minutesNotTruncated);
            var seconds = Math.floor((minutesNotTruncated - minutes) * 60);

            return degrees + '° ' + minutes + "' " + seconds + '"';
        },
        dd2dms(lat, lng) {
            var newCoord = '';
            if (lat > 0) {
                newCoord += this.toDMS(lat) + 'N ';
            } else {
                newCoord += this.toDMS(Math.abs(lat)) + 'S ';
            }
            if (lng > 0) {
                newCoord += this.toDMS(lng) + 'E ';
            } else {
                newCoord += this.toDMS(Math.abs(lng)) + 'W ';
            }
            return newCoord;
        },
        measureReady() {},
    },
    computed: {
        measurePaneStyle: function() {
            return {
                position: 'absolute',
                zIndex: 2,
                // top: "6vh",
                // height: "210px",
                width: this.width + 'px',
                left:
                    Math.max(
                        Math.round(this.midXPosition - this.width / 2),
                        53
                    ) + 'px',
            };
        },
        convertedMeasurement: function() {
            if (this.measurement == '' || parseFloat(this.measurement) == 0) {
                return '';
            } else if (this.measureType == 'Location') {
                return this.convertLocation(
                    this.measurement,
                    this.measureUnits
                );
            } else if (this.measureType == 'Distance') {
                return this.convertDistanceFromMeters(
                    parseFloat(this.measurement),
                    this.measureUnits
                );
            } else if (this.measureType == 'Area') {
                return this.convertAreaFromMeters(
                    parseFloat(this.measurement),
                    this.measureUnits
                );
            }
            return '';
        },
    },
    watch: {
        clickFunction(newVal, oldVal) {},
    },
};
</script>

<style scoped>
.buttonContainer {
    padding: 20px;
    background-color: white;
    border: solid 1px darkgray;
}
.radioButton {
    display: inline-block;
}
#radioRow {
    padding-bottom: 7px;
    display: flex;
    justify-content: space-around;
}
.unitSelect {
    width: 260px;
    height: 36px;
    border-radius: 4px;
}
.distanceEntry {
    width: 60px;
}
.label {
    margin-left: 5px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    height: 42px;
    width: 100px;
}
.btn-blue {
    background-color: rgb(77, 194, 233) !important;
}
.btn-clear {
    border: 1px solid gray;
}
.descText {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}
</style>
