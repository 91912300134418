import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const directoryService = {
    getDirectoryChild,
    cloneSelectedDirectories,
    getDirectory,
};

function getDirectoryChild(id) {
    // return axios.get(apiConstants.getDirectoryChild+'?ProjectId='+id)
    return request({
        url: apiConstants.getDirectoryChild + '?ProjectId=' + id,
    });
}
function cloneSelectedDirectories(data) {
    // return axios.post(apiConstants.cloneSelectedDirectories, data)
    return request({
        url: apiConstants.cloneSelectedDirectories,
        data: data,
        method: 'post',
    });
}
function getDirectory(id) {
    // return axios.get(apiConstants.getDirectoryName+'?id='+id)
    return request({ url: apiConstants.getDirectoryName + '?id=' + id });
}
