<template>
    <div>
        <b-modal
            hide-footer
            ref="message-modal"
            id="message-modal"
            :title="title"
        >
            <p class="my-1">{{ text }}</p>
            <div class="mt-2 float-right">
                <b-button class="mx-1" @click="confirm">
                    YES
                </b-button>
                <b-button variant="danger" class="mx-1" @click="cancel">
                    NO
                </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    methods: {
        openModal() {
            this.$bvModal.show('message-modal');
        },
        confirm() {
            this.$emit('confirm', true);
            this.$bvModal.hide('message-modal');
        },
        cancel() {
            this.$emit('confirm', false);
            this.$bvModal.hide('message-modal');
        },
    },
    props: {
        title: String,
        text: String,
    },
};
</script>
