<template>
    <b-col
        id="resizeBar"
        ref="resizeBar"
        v-show="showBar"
        class="no-padding resize-bar"
        @mousedown="dragMouseDown"
    ></b-col>
</template>

<script>
export default {
    name: 'ResizeBar',
    components: {},
    props: {
        showBar: Boolean,
    },
    data() {
        return {
            clientX: null,
            movementX: null,
        };
    },
    methods: {
        getPixelValue(pixels) {
            if (typeof pixels == 'number') {
                return pixels;
            } else if (typeof pixels == 'string') {
                return parseInt(pixels.replace('px', ''));
            } else {
                return pixels;
            }
        },
        dragMouseDown(event) {
            this.$emit('mouseDragStart');
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.clientX = event.clientX;
            document.getElementById('resizeBar').classList.add('dragging');
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag(event) {
            event.preventDefault();
            this.movementX = this.clientX - event.clientX;
            this.clientX = event.clientX;
            this.$emit('updateWidth', -1 * this.movementX);
        },
        closeDragElement() {
            this.$emit('mouseDragStop');
            if (this.$refs.resizeBar.style.left < 0) {
                this.$refs.resizeBar.style.left = 0;
            }
            document.getElementById('resizeBar').classList.remove('dragging');
            document.onmouseup = null;
            document.onmousemove = null;
        },
    },
    computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resize-bar {
    width: 5px;
    max-width: 5px;
    background-color: blue;
}
.dragging {
    cursor: ew-resize;
}
</style>
