var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showPage)?_c('div',[_c('AccessPermissionModal',{ref:"permission-modal"})],1):_c('div',[_c('portal-bar',{attrs:{"hideSearch":_vm.hideSearch}}),(!_vm.nextscreen)?_c('div',{staticClass:"upload_logo directoryUpload"},[_c('h5',[_vm._v("Upload directory")]),_c('p',{staticClass:"note mb-2"},[_vm._v(" *Note that empty directories will not be created ")]),_c('div',{staticClass:"drag-drop",attrs:{"id":'dropAreaGlobal' + _vm.tabIndex}},[_c('div',{staticClass:"iconplacing"},[_c('b-icon-cloud-arrow-up',{staticClass:"uploadicon"})],1),_c('div',{staticClass:"dragdrop"},[_vm._v("Drag and drop folder to upload")]),_c('br'),_c('script',{attrs:{"id":'treeTemplate' + _vm.tabIndex,"type":"text/x-template"}},[_vm._v(" <div class=\"d-flex\"> <div class=\"w-100\"> <span class=\"dirFolder\"></span> <span class=\"ename\">${title}</span> </div> <div id=\"clear\" class=\"m-r-15\"> <span onclick=\"removeFile(event)\" class=\"fileClose\"></span> </div> </div> ")]),_c('div',[_c('div',{attrs:{"id":'dropArea' + _vm.tabIndex}},[_c('ejs-uploader',{ref:'uploadObj' + _vm.tabIndex,attrs:{"id":'defaultfileupload' + _vm.tabIndex,"name":"UploadFiles","uploading":_vm.onFileUpload,"asyncSettings":_vm.path,"sequentialUpload":_vm.isSequential,"directoryUpload":_vm.directoryUpload,"autoUpload":_vm.isAuto,"dropArea":_vm.dropElement + _vm.tabIndex,"created":_vm.onCreate,"selected":_vm.onFileSelect,"success":_vm.onSuccess,"failure":_vm.onFailure,"maxFileSize":"1073741824"}})],1),_c('div',{staticClass:"col-lg-4 property-section pl-0 pb-4 mt-0"},[_c('div',{attrs:{"id":"property","title":"Properties"}},[_c('div',[_c('button',{staticClass:"btn buttonSelect btn-secondary",attrs:{"id":'uploadbtn' + this.tabIndex,"disabled":!'showAccordion' + this.tabIndex},on:{"click":function($event){return _vm.onButtonClick(
                                        $event,
                                        _vm.filesDetailsNew,
                                        _vm.tabIndex
                                    )}}},[_vm._v(" Upload ")])]),_c('div',{staticClass:"d-none"},[_c('ejs-checkbox',{attrs:{"id":'checkFileList' + this.tabIndex,"label":"Show FileList","change":_vm.change,"checked":true,"name":"FileUploader"}})],1)])])])])]):_c('div',[_c('h5',[_vm._v("Uploaded file")]),_vm._l((_vm.uploadedFiles),function(ff,index){return _c('div',{key:index,staticClass:"accordion",attrs:{"role":"tablist"}},_vm._l((ff),function(f,index){return _c('b-card',{key:index,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('imagedetails-accordion' + index),expression:"'imagedetails-accordion' + index"}],attrs:{"block":"","variant":"info"}},[_vm._v(_vm._s(f.name))])],1),_c('b-collapse',{attrs:{"id":'imagedetails-accordion' + index,"accordion":'imagedetails-accordion' + index,"role":"tabpanel"}},[_c('b-card-body',[_c('b-card-text',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',[_vm._v("Document details34")]),_c('div',{staticClass:"small-12 column mb-3"},[_c('CustomFloatingLabel',{attrs:{"editUserParams":30,"config":{
                                                    label: 'Document name',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(f.name),expression:"f.name"}],staticClass:"normal_input enterAddress",attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(f.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(f, "name", $event.target.value)}}})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(f.description),expression:"f.description"}],attrs:{"name":"","id":"enterComments","placeholder":"Description(optional)"},domProps:{"value":(f.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(f, "description", $event.target.value)}}}),_c('CustomFloatingLabel',{attrs:{"config":{
                                                    label:
                                                        'Keywords(optional)',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }}},[_c('input',{staticClass:"normal_input enterAddress",attrs:{"type":"text","autocomplete":"off"}})])],1)]),_c('div',{staticClass:"col"},[_c('h5',[_vm._v("Assign attributes")]),_c('b-form-select',{on:{"change":_vm.getAssignedAttributes},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('option',{attrs:{"value":"null"}},[_vm._v("Directory")]),_vm._l((_vm.directories),function(d){return _c('option',{key:d.DirectoryID,domProps:{"value":d.DirectoryID}},[_vm._v(_vm._s(d.DirectoryName))])})],2),(_vm.attributes)?_c('div',_vm._l((_vm.attributes),function(attribute){return _c('div',{key:attribute.DirectoryID},[(
                                                        attribute.isFreeText
                                                    )?_c('div',[_c('CustomFloatingLabel',{attrs:{"config":{
                                                            label:
                                                                'Attribute name',
                                                            name: 'wrapper',
                                                            line: false,
                                                            scale: false,
                                                        },"editUserParams":1}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                                attribute.AttributeName
                                                            ),expression:"\n                                                                attribute.AttributeName\n                                                            "}],staticClass:"normal_input w-499",attrs:{"type":"text","autocomplete":"off"},domProps:{"value":(
                                                                attribute.AttributeName
                                                            )},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(attribute, "AttributeName", $event.target.value)}}})])],1):_c('div',[_c('h5',[_vm._v(" "+_vm._s(attribute.AttributeName)+" ")]),_vm._l((attribute.AttributeValue),function(a,index){return _c('div',{key:index},[_c('b-form-radio',{attrs:{"aria-describedby":_vm.ariaDescribedby,"value":a.AttributeValueID}},[_vm._v(" "+_vm._s(a.AttributeValueText)+" ")])],1)})],2)])}),0):_vm._e()],1)])])])],1)],1)],1)}),1)})],2),_c('ToastMessages',{attrs:{"showSuccess":_vm.showSuccess,"showFailure":_vm.showFailure,"failureToastMessage":_vm.failureToastMessage,"successToastMessage":_vm.successToastMessage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }