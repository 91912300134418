<template lang="html">
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="docManagment" v-else>
        <div class="docUpload">
            <b-col class="text-right" @click="afterUplod" v-if="!saveButton">
                <b-button
                    rounded
                    class="buttonSelect nextbutton"
                    :disabled="validateUpload"
                >
                    Next
                </b-button>
            </b-col>
            <b-col
                class="text-right"
                @click="saveUploadedImages"
                v-if="documentTabopen && saveButton"
            >
                <b-button rounded class="buttonSelect bottom-p-75">
                    Upload
                </b-button>
            </b-col>

            <b-col cols="3" class="m-none navigate">
                <p
                    class="text-center sm-cancel pr-2 m-0"
                    style="cursor:pointer"
                    @click="navigatetoSelectedProjects"
                >
                    Cancel
                </p>
            </b-col>
        </div>
        <portal-bar :hideSearch="hideSearch"></portal-bar>
        <div class="upload_logo documentUpload" v-if="!nextscreen">
            <div class="form-group has-search upload-desk">
                <span class="upload-search"
                    ><img src="../../assets/search-outline-grey.svg"
                /></span>
                <input
                    type="text"
                    class="form-control"
                    placeholder="Search by document name, key words or attributes"
                />
            </div>
            <h5 class="mt-2">Select document</h5>
            <div id="droparea" class="drag-drop" v-cloak>
                <div class="iconplacing">
                    <b-icon-cloud-arrow-up
                        class="uploadicon"
                    ></b-icon-cloud-arrow-up>
                </div>
                <span class="dragdrop mb-4">Drag and drop file to upload</span>
                <br />
                <span class="dragdrop l-45">Or </span
                ><span class="mt-2">
                    <ejs-uploader
                        ref="uploadObj"
                        id="defaultfileupload"
                        :selected="onSelect"
                        name="UploadFiles"
                        :autoUpload="autoUpload"
                        :dropArea="dropElement"
                        :removing="onFileRemove"
                        :fileListRendering="fileListHandler"
                        maxFileSize="1073741824"
                    ></ejs-uploader>
                </span>
            </div>
        </div>

        <div v-else>
            <h5>Documents</h5>
            <div class="accordion" role="tablist">
                <b-card
                    no-body
                    class="mb-1"
                    v-for="(f, index) in uploadedFiles"
                    :key="index"
                >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                            block
                            v-b-toggle="'imagedetails-accordion' + index"
                            variant="info"
                            >{{ f.name }}</b-button
                        >
                    </b-card-header>
                    <b-collapse
                        :id="'imagedetails-accordion' + index"
                        :accordion="'imagedetails-accordion' + index"
                        role="tabpanel"
                    >
                        <b-card-body>
                            <b-card-text
                                ><div class="container p-0">
                                    <div class="row">
                                        <div class="col-7 p-0">
                                            <h5>Document details</h5>
                                            <div class="small-12 column mb-3">
                                                <CustomFloatingLabel
                                                    v-bind:editUserParams="30"
                                                    :config="{
                                                        label: 'Document name',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                >
                                                    <input
                                                        type="text"
                                                        v-model="f.name"
                                                        autocomplete="off"
                                                        class="normal_input enterAddress"
                                                        disabled
                                                    />
                                                </CustomFloatingLabel>

                                                <CustomFloatingLabel
                                                    :config="{
                                                        label:
                                                            'Keywords (optional)',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                >
                                                    <input
                                                        type="text"
                                                        autocomplete="off"
                                                        v-model="f.keyword"
                                                        class="normal_input enterAddress"
                                                    />
                                                </CustomFloatingLabel>
                                                <small class="docSmall"
                                                    >Keywords help with
                                                    searching. Separate keywords
                                                    with comma.</small
                                                >
                                                <textarea
                                                    id="enterComments"
                                                    v-model="f.description"
                                                    placeholder="Description (optional)"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div class="col p-0 assign">
                                            <h5>Select directory</h5>
                                            <CustomFloatingLabel
                                                :config="{
                                                    label: 'Directory',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                                class="directoryLbl"
                                            >
                                                <b-form-select
                                                    class="directorySelect"
                                                    v-model="f.directory"
                                                    @change="
                                                        getAssignedAttributes(
                                                            f.directory,
                                                            index
                                                        )
                                                    "
                                                >
                                                    <option
                                                        v-for="d in directories"
                                                        :value="d.DirectoryID"
                                                        :key="d.DirectoryID"
                                                    >
                                                        {{ d.dirFullName }}
                                                    </option>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                            <span
                                                class="errManage"
                                                v-if="directoryPresent"
                                            >
                                                <small v-if="f.directory == ''"
                                                    >Please select directory
                                                    before uploading
                                                    document</small
                                                >
                                            </span>
                                            <div v-if="f.attributes.length > 0">
                                                <h5>Assign attributes</h5>
                                                <div
                                                    v-for="(attribute,
                                                    indexAttr) in f.attributes"
                                                    :key="indexAttr"
                                                    class="document-upload"
                                                >
                                                    <div
                                                        v-if="
                                                            attribute.IsFreeText
                                                        "
                                                        class="attrBox mb-2"
                                                    >
                                                        <h6>
                                                            {{
                                                                attribute.AttributeName
                                                            }}
                                                        </h6>
                                                        <CustomFloatingLabel
                                                            :config="{
                                                                label:
                                                                    'Attribute value',
                                                                name: 'wrapper',
                                                                line: false,
                                                                scale: false,
                                                            }"
                                                            v-bind:editUserParams="
                                                                1
                                                            "
                                                            class="mb-0"
                                                        >
                                                            <input
                                                                type="text"
                                                                v-model="
                                                                    attribute
                                                                        .docAttributes
                                                                        .freetext
                                                                "
                                                                autocomplete="off"
                                                                class="normal_input"
                                                            />
                                                        </CustomFloatingLabel>
                                                    </div>
                                                    <div
                                                        v-else
                                                        class="attrBox mb-2"
                                                    >
                                                        <h6>
                                                            {{
                                                                attribute.AttributeName
                                                            }}
                                                        </h6>
                                                        <div
                                                            v-if="
                                                                attribute.IsMultiSelect
                                                            "
                                                            class="multiBox"
                                                        >
                                                            <!-- <b-form-group>{{attribute.docAttributes.check}}
                                            {{attribute.AttributeValue.AttributeValueID}}
                                            <b-form-checkbox-group
                                                id="flavors"                                                
                                                v-model="checked"
                                                :options="attribute.AttributeValue"
                                                text-field="AttributeValueText"
                                                value-field="AttributeValueID"
                                                :name="index"
                                                class="ml-4"
                                                aria-label="Individual flavours"
                                                @change="checkSelected($event)"
                                                stacked
                                              ></b-form-checkbox-group>
                                              
                                            </b-form-group> -->
                                                            <!-- <b-form-group class="mb-0">
                                            {{attribute.AttributeValue.AttributeValueID}}
                                            <b-form-checkbox-group
                                                :id="'flavors'+indexAttr"                                                
                                                v-model="attribute.docAttributes.check"
                                                :options="attribute.AttributeValue"
                                                text-field="AttributeValueText"
                                                value-field="AttributeValueID"
                                                :name="indexAttr"
                                                aria-label="Individual flavours"
                                              ></b-form-checkbox-group>
                                              
                                            </b-form-group> -->
                                                            <b-form-group
                                                                class="mb-0"
                                                            >
                                                                <div
                                                                    v-for="(permission,
                                                                    permIndex) in attribute.AttributeValue"
                                                                    :key="
                                                                        permIndex
                                                                    "
                                                                >
                                                                    <b-form-checkbox
                                                                        v-model="
                                                                            permission.ckeckElement
                                                                        "
                                                                        class="mr-3"
                                                                    >
                                                                        {{
                                                                            permission.AttributeValueText
                                                                        }}
                                                                    </b-form-checkbox>
                                                                </div>
                                                            </b-form-group>
                                                        </div>
                                                        <div v-else>
                                                            <!-- <span v-for="(answers,pr) in attribute.AttributeValue" :key="pr">
                                                    
                                                  <input
                                                      class="singleSelect"
                                                      type="radio"
                                                      :value="answers.AttributeValueID"
                                                      v-model="attribute.docAttributes.radio"
                                                      @Change="singleSelect()"
                                                    />
                                                    <label class="ml-2" for="one">{{answers.AttributeValueText}}</label>
                                                    <br />
                                                  </span> -->

                                                            <span
                                                                v-for="(answers,
                                                                pr) in attribute.AttributeValue"
                                                                :key="pr"
                                                            >
                                                                <input
                                                                    class="singleSelect"
                                                                    type="radio"
                                                                    :value="
                                                                        answers.AttributeValueID
                                                                    "
                                                                    :checked="
                                                                        answers.AttributeValueID ==
                                                                            attribute.radioElement
                                                                    "
                                                                    @change="
                                                                        getSelected(
                                                                            answers.AttributeValueID,
                                                                            index,
                                                                            indexAttr,
                                                                            pr
                                                                        )
                                                                    "
                                                                />
                                                                <label
                                                                    class="ml-2"
                                                                    for="one"
                                                                    >{{
                                                                        answers.AttributeValueText
                                                                    }}</label
                                                                >
                                                                <br />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div></div
                            ></b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :showConnecting="showConnecting"
            :failureToastMessage="failureToastMessage"
            :connectingToastMessage="connectingToastMessage"
            :successToastMessage="successToastMessage"
        />
    </div>
</template>

<script>
import CustomFloatingLabel from '../CustomFloatingLabel';
import router from '../../router';
import { projectService } from '../../services/project.service';
import PortalBar from '../PortalBar';
import { getUniqueID } from '@syncfusion/ej2-base';
import ToastMessages from '../ToastMessages.vue';
import AccessPermissionModal from '../AccessPermissionModal';

export default {
    name: 'DocumentUpload',
    components: {
        CustomFloatingLabel,
        PortalBar,
        ToastMessages,
        AccessPermissionModal,
    },
    props: {
        showdocument: Boolean,
    },
    data() {
        return {
            loadedGames: [
                {
                    id: 4,
                    question: 'Packers vs Bears',
                    matchId: 'BearsVsChicago',
                    home: 'Bears',
                    homeCity: 'Chicago',
                    visitor: 'Packers',
                    visitorCity: 'Green Bay',
                    favorite: 'Chicago',
                    pollanswers: [
                        {
                            id: 8,
                            poll_id: 4,
                            answer: 'Packers',
                        },
                        {
                            id: 9,
                            poll_id: 4,
                            answer: 'Bears',
                        },
                    ],
                },
                {
                    id: 5,
                    question: 'Titans vs Browns',
                    matchId: 'BrownsVsCleveland',
                    home: 'Browns',
                    homeCity: 'Cleveland',
                    visitor: 'Titans',
                    visitorCity: 'Tenesse',
                    favorite: 'Browns',
                    pollanswers: [
                        {
                            id: 10,
                            poll_id: 5,
                            answer: 'Titans',
                        },
                        {
                            id: 11,
                            poll_id: 5,
                            answer: 'Browns',
                        },
                    ],
                },
                {
                    id: 6,
                    question: 'Ravens vs Dolphins',
                    matchId: 'DolphinsVsMiami',
                    home: 'Dolphins',
                    homeCity: 'Miami',
                    visitor: 'Ravens',
                    visitorCity: 'Baltimore',
                    favorite: 'Baltimore',
                    pollanswers: [
                        {
                            id: 12,
                            poll_id: 6,
                            answer: 'Ravens',
                        },
                        {
                            id: 13,
                            poll_id: 6,
                            answer: 'Dolphins',
                        },
                    ],
                },
            ],
            picked: '',
            selectedGames: [],
            selected: '',
            mydata: [],

            nextscreen: false,
            saveButton: false,
            uploadedFiles: [
                // {
                //   name: "",
                //   description: "",
                //   keyword: "",
                //   directory: "",
                //   AttributeName: "",
                //   attributes: [],
                // },
            ],
            validateUpload: true,
            autoUpload: false,
            attributes: '',
            documentTabopen: false,
            Directories: [],
            hideSearch: true,
            showSuccess: false,
            showFailure: false,
            showConnecting: false,
            failureToastMessage: null,
            connectingToastMessage: null,
            successToastMessage: null,
            showPage: false,
            // selected:[],
            projectId: null,
            attValues: [],
            selectedRadio: 0,
            checkedBox: [],
            dropElement: '#droparea',
        };
    },
    created() {
        // this.pagePermit();
        this.getDirectories();
    },
    mounted() {
        this.pagePermit();
    },
    methods: {
        pagePermit() {
            this.showPage = false;
            // this.$emit('clicked', this.showPage);
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('Documents - Upload')) {
                    vm.showPage = true;
                    this.$emit('clicked', vm.showPage);
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        singleSelect() {
            this.$forceUpdate();
        },
        onFileRemove: function(args) {
            args.postRawFile = false;
            this.uploadedFiles.splice(
                this.uploadedFiles.indexOf(args.filesData[0]),
                1
            );
        },
        fileListHandler: function(args) {
            if (args.fileInfo.size < 1000) {
                args.element.getElementsByClassName(
                    'e-file-size'
                )[0].innerText = args.fileInfo.size / 1000 + ' KB';
            }
        },
        onSelect(args) {
            // check whether the file is uploading from paste.
            this.nextscreen = false;
            this.saveButton = false;
            for (let i = 0; i < args.filesData.length; i++) {
                args.filesData[i]['description'] = '';
                args.filesData[i]['keyword'] = '';
                args.filesData[i]['directory'] = '';
                args.filesData[i]['AttributeName'] = '';
                args.filesData[i]['attributes'] = [];
                this.uploadedFiles.push(args.filesData[i]);
            }
            // this.uploadedFiles = args.filesData;
            if (this.uploadedFiles) {
                this.validateUpload = false;
            } else {
                this.validateUpload = true;
            }

            //
            //this.uploadedFiles.push(args.filesData);
        },
        navigatetoSelectedProjects: function() {
            // this.clearSelectedProjects();
            // router.push(`/classic/project/selectedprojects');
            this.nextscreen = false;
            this.saveButton = false;
            this.documentTabopen = false;
            this.uploadedFiles = [];
            this.directoryPresent = false;
            this.validateUpload = true;
            this.$emit('closeTabD');
        },
        getAssignedAttributes(directoryId, index) {
            // projectService
            //   .getAssignedAttributes(directoryId)
            //   .then((res) => {
            //     this.attributes = res.data;
            //
            //     });
            projectService
                .GetDirectoryManageAttribute(directoryId)
                .then((res) => {
                    this.uploadedFiles[index].attributes = res.data;
                    // this.attributes = res.data;
                    this.directoryPresent = false;
                    let vm = this;
                    for (let att in this.uploadedFiles[index].attributes) {
                        this.uploadedFiles[index].attributes[att][
                            'radioElement'
                        ] = false;
                    }
                    for (
                        var i = 0;
                        i < vm.uploadedFiles[index].attributes.length;
                        i++
                    ) {
                        if (
                            vm.uploadedFiles[index].attributes[i].IsMultiSelect
                        ) {
                            for (let chkEle in vm.uploadedFiles[index]
                                .attributes[i].AttributeValue) {
                                vm.uploadedFiles[index].attributes[
                                    i
                                ].AttributeValue[chkEle][
                                    'ckeckElement'
                                ] = false;
                            }
                        }
                        this.uploadedFiles[index].attributes[i][
                            'docAttributes'
                        ] = {
                            freetext: '',
                            check: [],
                            radio: [],
                        };
                        this.uploadedFiles[index].attributes[i]['radio'] =
                            'test' + i;
                    }
                    //
                    this.$forceUpdate();
                });
        },
        // getDirectories() {
        //   projectService
        //     .getDirectories()
        //     .then((res) => (this.directories = res.data));
        // },

        getDirectories() {
            this.projectId = localStorage.getItem('documentProjectID');
            projectService
                .getListofProjectDirectories(this.projectId)
                .then((res) => {
                    this.directories = res.data
                        .map((dir) => {
                            let fName = dir.BlobPath + dir.DirectoryName;
                            let dirfName = fName.replace(
                                this.projectId + '/',
                                ''
                            );
                            dir['dirFullName'] = dirfName;
                            return dir;
                        })
                        .sort((a, b) =>
                            a.dirFullName.localeCompare(b.dirFullName)
                        );
                });
        },
        // gotToDashboard() {
        //   router.push(`/classic/');
        // },
        onUploadBegin: function(args) {
            // check whether the file is uploading from paste.

            if (args.filesData.fileSource === 'paste') {
                let newName =
                    getUniqueID(
                        args.filesData.name.substring(
                            0,
                            args.filesData.name.lastIndexOf('.')
                        )
                    ) + '.png';
                args.customFormData = [{ fileName: newName }];
            }
        },

        afterUplod() {
            this.nextscreen = true;
            this.saveButton = true;
            this.documentTabopen = true;
        },

        getSelected(value1, indexParent, indexSubParent, index) {
            // this.editFileAttributes[indexParent].selectRadio=value1;
            //
            // this.uploadedFiles[indexParent].attributes[indexSubParent].AttributeValue[index]
            this.uploadedFiles[indexParent].attributes[
                indexSubParent
            ].radioElement = value1;
            for (let temp in this.uploadedFiles[indexParent].attributes[
                indexSubParent
            ].AttributeValue) {
                if (temp == index) {
                    this.uploadedFiles[indexParent].attributes[
                        indexSubParent
                    ].AttributeValue[temp][
                        'AttributeValueTextSelection'
                    ] = true;
                } else {
                    this.uploadedFiles[indexParent].attributes[
                        indexSubParent
                    ].AttributeValue[temp][
                        'AttributeValueTextSelection'
                    ] = false;
                }
            }
            this.$forceUpdate();
        },

        saveUploadedImages() {
            let uploadTempData = [];
            const data = new FormData();
            for (let item in this.uploadedFiles) {
                if (this.uploadedFiles[item].directory == '') {
                    this.directoryPresent = true;
                    this.nextscreen = true;
                    uploadTempData.push(this.uploadedFiles[item].name);
                    this.$forceUpdate();
                }
            }
            if (!this.directoryPresent) {
                this.nextscreen = false;
                this.saveButton = true;
                this.uploadedFiles.forEach((item, index) => {
                    if (item.attributes.length > 0) {
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.FileName',
                            item.name
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.description',
                            item.description
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.FormFile',
                            item.rawFile,
                            item.rawFile.name
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.keyword',
                            item.keyword
                        );
                        // data.append("documentAttributeUploadDTO[" + index + "].documents.DocumentAttribute.DocumentAtributeID", item.attributes[0].DirectoryAttributeID);

                        for (let i = 0; i < item.attributes.length; i++) {
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo[' +
                                    i +
                                    '].DirectoryID',
                                item.attributes[0].DirectoryID
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo[' +
                                    i +
                                    '].ProjectId',
                                parseInt(this.projectId)
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo[' +
                                    i +
                                    '].DirectoryAttributeID',
                                item.attributes[i].DirectoryAttributeID
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo[' +
                                    i +
                                    '].AttributeName',
                                item.attributes[i].AttributeName
                            );

                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo[' +
                                    i +
                                    '].IsFreeText',
                                item.attributes[i].IsFreeText
                            );
                            data.append(
                                'documentAttributeUploadDTO[' +
                                    index +
                                    '].AttributeTransactInfo[' +
                                    i +
                                    '].IsMultiSelect',
                                item.attributes[i].IsMultiSelect
                            );
                            if (item.attributes[i].IsFreeText == true) {
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].FreeTextVal',
                                    item.attributes[i].docAttributes.freetext
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].AttributeValueTransact',
                                    []
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].DirectoryID',
                                    item.directory
                                );
                            } else {
                                for (
                                    let m = 0;
                                    m <
                                    item.attributes[i].AttributeValue.length;
                                    m++
                                ) {
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].AttributeTransactInfo[' +
                                            i +
                                            '].AttributeValueTransact[' +
                                            m +
                                            '].AttributeValueID',
                                        item.attributes[i].AttributeValue[m]
                                            .AttributeValueID
                                    );
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].AttributeTransactInfo[' +
                                            i +
                                            '].AttributeValueTransact[' +
                                            m +
                                            '].AttributeValueText',
                                        item.attributes[i].AttributeValue[m]
                                            .AttributeValueText
                                    );
                                    data.append(
                                        'documentAttributeUploadDTO[' +
                                            index +
                                            '].AttributeTransactInfo[' +
                                            i +
                                            '].AttributeValueTransact[' +
                                            m +
                                            '].DirectoryAttributeID',
                                        item.attributes[i].AttributeValue[m]
                                            .DirectoryAttributeID
                                    );

                                    if (item.attributes[i].IsMultiSelect) {
                                        data.append(
                                            'documentAttributeUploadDTO[' +
                                                index +
                                                '].AttributeTransactInfo[' +
                                                i +
                                                '].AttributeValueTransact[' +
                                                m +
                                                '].AttributeValueTextSelection',
                                            item.attributes[i].AttributeValue[m]
                                                .ckeckElement
                                        );
                                    } else {
                                        if (
                                            item.attributes[i].AttributeValue[m]
                                                .AttributeValueTextSelection ==
                                            undefined
                                        ) {
                                            data.append(
                                                'documentAttributeUploadDTO[' +
                                                    index +
                                                    '].AttributeTransactInfo[' +
                                                    i +
                                                    '].AttributeValueTransact[' +
                                                    m +
                                                    '].AttributeValueTextSelection',
                                                false
                                            );
                                        } else {
                                            data.append(
                                                'documentAttributeUploadDTO[' +
                                                    index +
                                                    '].AttributeTransactInfo[' +
                                                    i +
                                                    '].AttributeValueTransact[' +
                                                    m +
                                                    '].AttributeValueTextSelection',
                                                item.attributes[i]
                                                    .AttributeValue[m]
                                                    .AttributeValueTextSelection
                                            );
                                        }
                                    }
                                }
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].AttributeTransactInfo[' +
                                        i +
                                        '].FreeTextVal',
                                    null
                                );
                                data.append(
                                    'documentAttributeUploadDTO[' +
                                        index +
                                        '].DirectoryID',
                                    item.directory
                                );
                            }
                        }
                    } else {
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.FileName',
                            item.name
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.description',
                            item.description
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.FormFile',
                            item.rawFile,
                            item.rawFile.name
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].documents.keyword',
                            item.keyword
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].DirectoryID',
                            item.directory
                        );
                        data.append(
                            'documentAttributeUploadDTO[' +
                                index +
                                '].AttributeTransactInfo',
                            []
                        );
                    }
                });

                //
                projectService
                    .uploadDocuments(data)
                    .then((res) => {
                        if (res.data.StatusCode === 202) {
                            this.showConnecting = true;
                            var fileNames = null;
                            for (
                                var i = 0;
                                i < this.uploadedFiles.length;
                                i++
                            ) {
                                fileNames =
                                    fileNames +
                                    ', ' +
                                    this.uploadedFiles[i].name;
                            }
                            if (this.uploadedFiles.length > 1) {
                                this.connectingToastMessage = 'Uploading files';
                            } else {
                                this.connectingToastMessage = 'Uploading file';
                            }

                            this.showSuccess = false;
                            // this.showFailure = false;
                            setTimeout(() => {
                                this.showConnecting = false;
                                this.showSuccess = true;
                                if (this.uploadedFiles.length > 1) {
                                    this.successToastMessage =
                                        'Documents uploaded';
                                } else {
                                    this.successToastMessage =
                                        'Document uploaded';
                                }
                                setTimeout(() => {
                                    this.showSuccess = false;
                                }, 2000);
                            }, 500);
                        }
                    })
                    .catch((res) => {
                        this.failureToastMessage = res.response.data.Message;
                        this.showFailure = true;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    });
                this.uploadedFiles = [];
            } else {
                this.failureToastMessage =
                    'Please select directory for ' + uploadTempData.join(', ');
                this.showFailure = true;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.my-custom-floating-label {
    .input__container {
        width: 400px;
    }
}
.breadcrumb {
    background-color: white !important;
}
.text-right.icons {
    //margin-top: -10px;
    max-width: 100px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}

.open-toggle {
    float: right;
    margin-top: 8px;
}
.border {
    height: 48px;
    padding-top: 3px !important;
    border-radius: 4px;
    //left: 5px;
    /* width: 987px; */
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}
.childCheckBox {
    padding-left: 20px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.leaflet-popup-content-wrapper {
    background-color: black !important;
    padding: 1px !important;
    min-width: 100px !important;
    margin: 0 !important;
    color: #eee !important;
    border-radius: 0 !important;
}
.leaflet-popup-content {
    margin: 3px 6px !important;
}
.leaflet-popup-tip-container {
    display: none;
}
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}
.leaflet-control-attribution a {
    display: none;
}

.leaflet-control-container {
    display: none;
}
.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    left: 1px;
    top: 12px;
    z-index: 9999999;
}
/* .border-right {
  margin-left: -36px !important;
} */

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
    padding: 15px 5px;
    font-size: 16px !important;
}

.DocManagement {
    height: auto !important;
}

.reportingTool p.wide-50 {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    width: 120px !important;
    height: 49px;
    opacity: 1 !important;
}

.titleBlock {
    margin: 16px 15px;
    height: 48px;
    font-size: 16px !important;
}
.titleBlock small {
    font-size: 16px !important;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
}
.titleBlock small:hover {
    border: 3spx solid #b034a1;
    height: 48px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 48px;
    width: 48px;
    text-align: center;
}

.graphing {
    margin: 16px 15px;
    height: 48px;
}

.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.graphing small {
    font-size: 16px !important;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d;
    padding-top: 10px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 48px;
}
.graphing span {
    background-color: #8456b0;
    height: 48px;
    width: 48px;
    text-align: center;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.directoryAssign {
    background: #dddddd;
    //margin-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.directoryItem {
    background-color: white !important;
    height: 48px !important;
    width: 499px !important;
    margin-left: 48px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -10px;
}
.test {
    margin-bottom: 20px;
}
.row1 {
    margin-bottom: 20px;
}
.searchinputIcon {
    background-color: #eaeaea;
    width: 197px;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
}
button#__BVID__31__BV_toggle_ {
    background: none !important;
    border: none !important;
}
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}

.dataset {
    margin: 10px 15px;
    height: 48px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 48px;
}
.dataset span {
    background-color: #5878be;
    height: 48px;
    width: 48px;
    text-align: center;
}
.graphic {
    text-align: center;
    width: 253px;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
}
.blue {
    color: #fff !important;
    background-color: #000 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
// .graphic:active {
//   color: #fff !important;
//   border-color: #626262;
//   background-color: #000;
// }
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
    /* margin-top: 23px; */
    //margin-left: 104px;
}
.graphic:hover {
    border-color: #626262;
    background-color: #000;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 28px;
    /* margin-left: 24px; */
    margin-top: 0px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.assignedTabs {
    width: 499px;
    margin: auto;
    .normal_input.smallTabs {
        width: 408px !important;
    }
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 35%;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        //margin-left: 312px;
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        cursor: pointer;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: 154px;
    //   margin-top: 30px;
    // margin-left: 127px;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}

.cancelButton {
    background: transparent;
    color: #006395;
    border: none;
    float: left;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
}

.selectedGraphictool {
    color: #fff !important;
    border-color: #626262;
    background-color: #000;
}
.contouring {
    margin: 16px 15px;
    height: 48px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 48px;
}
.contouring span {
    background-color: #3296ca;
    height: 48px;
    width: 48px;
    text-align: center;
}

.mapToolAttribute {
    width: 225px;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 40%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.normal_select {
    width: 475px;
    height: 52px;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 3px;
}
.normal_select:focus {
    border: 1px solid #c6c6c6 !important;
}
.mapToolAttribute h6 {
    color: #006395;
    font-weight: bold;
}
.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;
    p {
        text-align: center;

        width: 248px;
        margin-left: 128px;
        font-size: 18px;
        color: #000;
        a {
            font-size: 20px;
            color: #006395 !important;
        }
    }
}

.uploadButton {
    background-color: #3296ca;
    width: 83px;

    margin-top: 10px;
    border-radius: 3px;
}
.uploadicon {
    height: 56px;
    width: 82px;
}
.iconplacing {
    //padding-left: 200px;
    padding-top: 13px;
}
.attrTabs .tabs {
    border: 2px solid #eaeaea !important;
    margin-bottom: 135px;
}
.content {
    // background-color: #eaeaea !important;
    min-height: 350px;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    //padding: 30px;
    //text-align: center;
}

.my-custom-floating-label {
    background-color: white !important;
    // width: 400px !important;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    height: 48px;
    text-align: center;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    // margin-top: 67px;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.reportingTool span img {
    margin-top: 13px;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.uploadToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 450px;
    border-left: 6px solid #933d9b;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}

.assignAttr img {
    margin-top: 10%;
}

.tabs {
    padding-top: 10px;
}

ul.e-upload-files {
    border-top: none !important;
    margin-top: 70px !important;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

.card-header {
    margin-bottom: 20px;
    background: transparent;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
}
.custom-select {
    background: #fff url('../../../assets/arrow_down.svg') no-repeat right
        0.75rem center/8px 10px !important;
    width: 499px;
}
.custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 5%;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    letter-spacing: 0px;
    color: #626262;
    padding-left: 20px;
}
.custom-select {
    background: #fff url('../../../assets/arrow_down.svg') no-repeat right
        0.75rem center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
    background: #eaeaea url('../../../assets/big_plus.svg') no-repeat right
        0.75rem center !important;
}
.btn-info:focus,
.btn-info.focus {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:hover {
    color: #000;
    background-color: #eaeaea;
}
.normal_inputButton {
    height: 48px !important;
    border-radius: 3px;
    //margin-left: 104px;
    width: 499px;
    margin: auto;
}
button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/minus_sign_big.svg') no-repeat
        right 0.75rem center !important;
}

.dragdrop {
    margin-left: 140px;
    text-align: center;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000;
}
.nextbutton {
    width: 80px;
}

.nextbutton:disabled:hover {
    background-color: #eaeaea !important;
}

//style for file manager
.file-overview .sample-container {
    margin: 10px 10px 10px 10px;
}
//listing file in toast message after document upload
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}
.applyButtonSelect {
    background: #006395;
    color: white;
    border: none;
    cursor: pointer;
}
.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}
.model_style {
    width: 900px !important;
}
.modal-dialog {
    max-width: 700px !important;
}
button:disabled,
button[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
input:disabled,
input[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
div#__BVID__62___BV_modal_backdrop_ {
    opacity: 1 !important;
    background-color: #8a8a8a !important;
}
.e-file-container {
    align-items: last baseline !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name {
    padding: 0 !important;
}
.e-list-text {
    color: #fff !important;
}
.e-hover {
    color: #000;
}

#file-manager {
    display: block !important;
    border: 1px solid red !important;
}

#file-manager_layout {
    display: block !important;
}
.upload-doc .custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 30px;
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
#outersearchinput {
    height: 40px !important;
    padding-top: 5px;
}
.icon-right {
    position: absolute;
    right: 0;
}
.subheader-smalltext {
    margin-right: -80px;
}
.mAttribute {
    width: 130px !important;
    height: 20px !important;
    background-color: transparent !important;
}
.docUpload {
    text-align: right;
    position: absolute;
    top: -47px;
    height: 0 !important;
    float: right;
    right: 0;
}
.navigate {
    float: right;
    right: 210px;
    top: -35px;
}
.docSmall {
    display: block;
    position: relative;
    top: -15px;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0.32px;
    color: #6e6e6e;
}

.attrBox {
    border: 1px solid #ccc;
    padding: 10px;
    width: 499px;
    border-radius: 5px;
    background-color: #fbfbfb;
}
.attrBox label {
    font-size: 14px;
}
.singleSelect {
    position: relative;
    top: 2px;
}
.multiBox {
    font-size: 14px;
}
.errManage {
    color: #f00;
    float: left;
    top: -20px;
    position: relative;
    display: block;
}
.directoryLbl,
.directorySelect {
    display: block;
}
</style>
