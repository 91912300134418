const toolsInfo = [
    {
        toolName: 'Data Posting Tool',
        iconPath: require('../assets/onemapicons/dataposting-icon.svg'),
        componentName: 'data-post-sidebar',
        initialPanelWidth: 25,
    },
    {
        toolName: 'Data',
        iconPath: require('../assets/onemapicons/data-table-icon.svg'),
        componentName: 'project-datasets',
        initialPanelWidth: 65,
    },
    {
        toolName: 'Measure',
        iconPath: require('../assets/onemapicons/measure-icon.svg'),
        componentName: 'measure-sidebar',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Draw',
        iconPath: require('../assets/onemapicons/edit-pencil-icon.svg'),
        componentName: 'draw-side-bar',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Meeting schedule',
        iconPath: require('../assets/onemapicons/meeting-schedule-icon.svg'),
        componentName: '',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Weather',
        iconPath: require('../assets/onemapicons/weather-cloud-lightning-icon.svg'),
        componentName: 'Weather',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Contact list',
        iconPath: require('../assets/onemapicons/contactlist-icon.svg'),
        componentName: '',
        initialPanelWidth: 20,
    },
    {
        toolName: 'File manager',
        iconPath: require('../assets/onemapicons/file-icon.svg'),
        componentName: 'document-management',
        initialPanelWidth: 75,
    },
    {
        toolName: 'Events',
        iconPath: require('../assets/onemapicons/calendar-icon.svg'),
        componentName: 'common-operating-picture',
        initialPanelWidth: 26.3,
    },
    {
        toolName: 'Print',
        iconPath: require('../assets/onemapicons/printer-icon.svg'),
        componentName: 'print-map',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Layers',
        iconPath: require('../assets/onemapicons/layers-icon.svg'),
        componentName: 'layers-wrapper',
        initialPanelWidth: 20,
    },
    {
        toolName: '3D',
        iconPath: require('../assets/onemapicons/box-icon.svg'),
        componentName: 'map3d-wrapper',
        initialPanelWidth: 26.3,
    },
    {
        toolName: 'Dreams',
        iconPath: require('../assets/onemapicons/layers-icon.svg'),
        componentName: 'dreams-wrapper',
        initialPanelWidth: 20,
    },
    {
        toolName: 'PowerBI',
        iconPath: require('../assets/onemapicons/powerbi-icon.svg'),
        componentName: 'power-bIReports-container',
        initialPanelWidth: 75,
    },
    {
        toolName: 'Emergency Management',
        iconPath: require('../assets/onemapicons/file-question.svg'),
        componentName: '',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Table select tool',
        iconPath: require('../assets/onemapicons/tableselect-icon.svg'),
        componentName: '',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Dataset Export',
        iconPath: require('../assets/onemapicons/datasetexport-icon.svg'),
        componentName: 'dataset-to-file-export',
        initialPanelWidth: 20,
    },
    {
        toolName: 'X/Y Scatter Plot',
        iconPath: require('../assets/onemapicons/scatterplot-icon.svg'),
        componentName: 'xyscatter-plot',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Contour',
        iconPath: require('../assets/onemapicons/contour-icon.svg'),
        componentName: 'contour-sidebar',
        initialPanelWidth: 60,
    },
    {
        toolName: 'Title Block',
        iconPath: require('../assets/onemapicons/titleblock-icon.svg'),
        componentName: 'title-block',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Identify',
        iconPath: require('../assets/onemapicons/identify-icon.svg'),
        componentName: 'identify-sidebar',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Projects',
        iconPath: require('../assets/onemapicons/folder-icon.svg'),
        componentName: 'project-side-bar',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Milepost search',
        iconPath: require('../assets/searchmilepost.svg'),
        componentName: 'search-and-zoom',
    },
    {
        toolName: 'Data Table',
        iconPath: require('../assets/onemapicons/data-table-icon.svg'),
        componentName: 'paged-data-grid',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Retrieve work',
        iconPath: require('../assets/onemapicons/folder-close.svg'),
        componentName: 'retrieve-work',
        initialPanelWidth: 60,
    },
    {
        toolName: 'iDat export',
        iconPath: require('../assets/onemapicons/database-download.svg'),
        componentName: 'idat-export-sidebar',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Saved filters',
        iconPath: '',
        componentName: 'saved-filters',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Advanced Filters',
        iconPath: require('../assets/onemapicons/data-table-icon.svg'),
        componentName: 'advanced-filters',
        initialPanelWidth: 20,
    },
    {
        toolName: 'History',
        iconPath: '',
        componentName: 'saved-history',
    },
    {
        toolName: 'Standard Crosstab',
        iconPath: '',
        componentName: 'standard-crosstab',
        initialPanelWidth: 20,
    },
    {
        toolName: 'GIS Data Collection',
        iconPath: require('../assets/data-collection.svg'),
        componentName: 'data-collection-sidebar',
        initialPanelWidth: 20,
    },
    {
        toolName: 'Geo Vectors',
        iconPath: require('../assets/onemapicons/geovector-icon.svg'),
        componentName: 'vector-planning',
        initialPanelWidth: 20,
    },
    {
        toolName: 'InSite',
        iconPath: require('../assets/onemapicons/analysis.svg'),
        componentName: '',
        initialPanelWidth: 0,
    },
    {
        toolName: 'InSite Dev',
        iconPath: require('../assets/onemapicons/analysis.svg'),
        componentName: '',
        initialPanelWidth: 0,
    },
];
export function toolIconPath(tool) {
    // this happens for some of the nested buttons for now.
    try {
        return toolsInfo.find((icon) => icon.toolName === tool).iconPath !==
            'Data Table'
            ? toolsInfo.find((icon) => icon.toolName === tool).iconPath
            : null;
    } catch (e) {
        console.log('no iconPath for ' + tool);
    }
}
export function getComponentName(tool) {
    try {
        return toolsInfo.find((icon) => icon.toolName === tool).componentName;
    } catch (e) {
        console.log('no componentName for ' + tool);
    }
}
export function getInitialPanelWidthtool(tool) {
    try {
        return toolsInfo.find((icon) => icon.toolName === tool)
            .initialPanelWidth;
    } catch (e) {
        console.log('no initialPanelWidth for ' + tool);
    }
}
