<template>
    <div>
        <Header />
        <!-- <Header v-if="showHeaderSelectedProject"/> -->
        <img
            id="referenceCircleImage"
            src="../../assets/circle.svg"
            class="d-none"
        />
        <img
            id="referenceSiteTickImage"
            src="../../assets/icon_tick_lg1.svg"
            class="d-none"
        />

        <!-- <SubHeader Heading='Projects' TabHeading='Projects' v-bind:clearNeeded='true' v-bind:ButtonNeeded='true' ButtonText='Open projects' ButtonRoute='/dashboard' /> -->
        <!-- <b-container fluid class="sub_header_bg">
      <b-container>
        <b-row class="mb-3">
          <b-col cols="12">
            <a  @click="navigateDashboard">
              <img src="../../assets/arrow_left.svg" class=""/>
              <span class="floar-left ml-1"> Back to dashboard</span>
            </a>
          </b-col>
        </b-row>


        <b-row class="vh-10 text-left" align-v="center">
          <b-col cols="4" class="pt-2">
            <h4 class="">Projects</h4>
          </b-col>
          <b-col cols="6" >
            <p class="float-right pl-3 m-0">Clear selection</p>
          </b-col>
          <b-col class="text-right" :to="{path:'/filteroptions'}">
            <b-button rounded class="buttonSelect">
                Open projects
            </b-button>
          </b-col>
        </b-row>

        <b-row class="pt-2">
          <div class="borderBlue mt-3 ml-3">
            <h5> Projects</h5>
          </div>
        </b-row>
        
        
      </b-container>
    </b-container> -->
        <router-view></router-view>

        <Footer />
        <!-- <Footer  v-if="showHeaderSelectedProject"/> -->
    </div>
</template>

<script>
// import router from '../../router';
import Header from '../Header';
// import SubHeader from '../SubHeader';
import Footer from '../Footer';

export default {
    name: 'Projects',
    components: {
        Header,
        // SubHeader,
        Footer,
    },
    data() {
        return {
            mapView: false,
            SelectedProjects: false,
            showHeaderMap: false,
            showHeaderSelectedProject: false,
        };
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        // navigateDashboard:function() {
        //   router.push(`/dashboard')
        // },

        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            vm.mapView = true;
            vm.showHeaderMap = true;
            data.forEach((element) => {
                if (element.PageName.includes('MapView')) {
                    vm.mapView = true;
                }
                if (element.PageName.includes('SelectedProjects')) {
                    vm.SelectedProjects = true;
                }
            });
            if (
                (this.$route.name === 'map' && this.mapView) ||
                (this.$route.name === 'selectedprojects' &&
                    this.SelectedProjects)
            ) {
                this.showHeaderMap = true;
                this.showHeaderSelectedProject = false;
            }
            if (this.$route.name === 'map' && this.mapView) {
                this.showHeaderMap = true;
                this.showHeaderSelectedProject = false;
            }
            if (this.$route.name === 'map' && !this.mapView) {
                this.showHeaderMap = false;
                this.showHeaderSelectedProject = false;
            }
            if (
                this.$route.name === 'selectedprojects' &&
                !this.SelectedProjects
            ) {
                this.showHeaderSelectedProject = false;
                this.showHeaderMap = false;
            }
        },
    },
};
</script>

<style scoped>
.breadcrumb {
    background-color: white !important;
}

.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}
</style>
