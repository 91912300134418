<template>
    <div v-if="showPage">
        <b-container fluid class="adddataset sub_header_bg">
            <b-container class="d-p-0">
                <b-row class="mb-3">
                    <b-col cols="12 py-1">
                        <a
                            href="javascript:void(0)"
                            @click="navigatetoDatasets"
                        >
                            <img src="../../assets/arrow_left.svg" />
                            <span class="floar-left ml-1">
                                Back to datasets</span
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="8" class="pt-2">
                        <h1 class="">Add a dataset</h1>
                    </b-col>
                    <b-col cols="4" class="text-right">
                        <b-button
                            :disabled="btnDefineDataset"
                            rounded
                            class="buttonSelect"
                            @click="defineDataset"
                        >
                            Define dataset
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="pt-2">
                    <div class="borderBlue mt-3 ml-3">
                        <h5>Tables</h5>
                    </div>
                </b-row>
            </b-container>
        </b-container>
        <b-container class="p-0 adddatasetContent">
            <div class="border-top">
                <b-col class="py-3 px-0 border-bottom dflex tab-flex">
                    <b-col
                        class="blue-color p-0 dataset-m m-shadow mt-1 data-wide"
                    >
                        <div class="float-left h-45 m-input">
                            <a href="#" class="m-set">
                                <img
                                    class="pointer ml-2 mt-20 mr-2"
                                    src="../../assets/search-outline.svg"
                                    v-if="
                                        !filter && !showSearchIcon
                                            ? true
                                            : false
                                    "
                                    @click="searchOpen"/>
                                <input
                                    ref="adddataset"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        filter || showSearchIcon ? true : false
                                    "
                                    v-model="filter"
                                    @click="searchinputenter()"
                                    @input="searchinputenter"
                                    :class="
                                        filter || showSearchIcon
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Search for dataset"
                            /></a>

                            <span
                                class="grey-bg"
                                v-if="searchClose"
                                @click="closeBar"
                                ><img src="../../assets/close.svg"
                            /></span>
                            <span class="border-right m-none">&nbsp;</span>
                        </div>
                        <div class="float-right h-45 filter-blk m-filter-icon">
                            <a
                                href="#"
                                @click="filterdata"
                                class="ml-1 mr-1 dataset-flt-m-view"
                                ><img src="../../assets/tooltip.svg"
                            /></a>
                            <span
                                class="font-weight-300 m-none pointer"
                                @click="filterdata"
                                >Filter</span
                            >
                            <b-list-group>
                                <b-list-group-item
                                    class="filter-group left-align p-0 adddata-set"
                                    v-if="filterShow"
                                >
                                    <div class="ml-4 mp-10">
                                        <div class="mb-1 addset mrg-btm">
                                            Filter by
                                            <b-icon
                                                icon="x"
                                                font-scale="1"
                                                @click="backFilter('tablename')"
                                            >
                                            </b-icon>
                                        </div>

                                        <div class="ml-5 mbt">
                                            <select
                                                v-model="tablename"
                                                class="select_option"
                                            >
                                                <option value=""
                                                    >Dataset Name</option
                                                >
                                                <option
                                                    v-for="(item,
                                                    index) in filteredItems"
                                                    :key="index"
                                                    v-bind:value="
                                                        item.TableName
                                                    "
                                                >
                                                    {{ item.TableName }}
                                                </option>
                                            </select>
                                        </div>
                                        <b-button
                                            class="mb-3"
                                            @click="applyFilter"
                                            >Apply filters</b-button
                                        >
                                    </div></b-list-group-item
                                >
                            </b-list-group>
                        </div>
                    </b-col>
                    <b-col
                        lg="5"
                        md="12"
                        class=" filter-option  clearfix h-45"
                        v-if="applyfilter"
                    >
                        <div v-if="tablename" class="float-left">
                            <span class="filteredItem px-3 py-1 text-center">
                                {{ tablename }}
                                <b-icon
                                    icon="x"
                                    font-scale="1"
                                    @click="backFilter('tablename')"
                                >
                                </b-icon>
                            </span>
                        </div>
                        <div class="removeFilter float-left">
                            <a v-if="rmFilter" @click="removeFilter">
                                Remove All Filters
                            </a>
                        </div>
                    </b-col>
                </b-col>
            </div>
            <div></div>
            <div
                id="adddataset"
                class="data-list pl-4 p-3 border-bottom"
                v-for="item in applyfilter ? datasetFilterd : filteredItems"
                :key="item"
                :style="
                    selectedDataset == item.TableName && isClicked
                        ? {
                              'background-color': '#e6e6e6',
                              border: '2px solid #212121 !important',
                          }
                        : null
                "
                @click="toggleIsClicked(item)"
            >
                <h6 class="tick-mark">
                    {{ item.TableName
                    }}<img
                        v-if="selectedDataset == item.TableName && isClicked"
                        src="../../assets/tick_mark.svg"
                    />
                </h6>
            </div>

            <p v-if="externalDtasetsData.length == 0" class="py-2">
                No records to display
            </p>
            <b-row v-if="externalDtasetsData.length > 0">
                <div class="py-4 col-center">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="adddataset"
                    ></b-pagination>
                </div>
            </b-row>
        </b-container>
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import router from '../../router';
import { mapState, mapActions } from 'vuex';
import { datasetService } from '../../services/dataset.service';
import AccessPermissionModal from '../AccessPermissionModal';
//import dashboard from '../../views/Dashboard';

export default {
    computed: {
        ...mapState({
            //externalDtasets: state => state.datasets.externalDtasets.items
        }),
        filteredItems() {
            let vm = this;
            vm.externalDtasetsData = vm.externalDtasets;
            if (vm.filter) {
                vm.externalDtasetsData = vm.externalDtasetsFiltered.filter(
                    (item) => {
                        return (
                            item.TableName.toLowerCase().indexOf(
                                vm.filter.toLowerCase()
                            ) > -1
                        );
                    }
                );
            } else {
                vm.externalDtasetsData = vm.externalDtasetsFiltered;
            }
            return vm.externalDtasetsData.slice(
                (vm.currentPage - 1) * vm.perPage,
                vm.currentPage * vm.perPage
            );
        },
        rows() {
            return this.externalDtasetsData.length;
        },
    },
    components: {
        // dashboard,
        AccessPermissionModal,
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },

    data() {
        return {
            isClicked: false,
            selectedDataset: '',
            selectedTableFullName: '',
            tableType: '',
            selectedReportId: '',
            filter: null,
            filterShow: false,
            applyfilter: false,
            tablename: '',
            datasetFilterd: [],
            showPage: true,
            btnDefineDataset: true,
            rmFilter: false,
            showSearchIcon: false,
            currentPage: 1,
            perPage: 4,
            externalDtasetsFiltered: [],
            externalDtasetsData: [],
        };
    },
    created() {
        //this.getAdddataSets(this.$route.params.id);
        this.getList();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.Pagination) {
                this.perPage = this.$store.state.store.portalsettings.Pagination;
            }
        }
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        getList() {
            datasetService.getAdddataSets(this.$route.params.id).then((res) => {
                if (res) {
                    this.externalDtasetsFiltered = res.data;
                    this.externalDtasets = res.data;
                }
            });
            //this.externalDtasetsFiltered = this.$store.state.datasets.externalDtasets.items;
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('AddDataSet')) {
                    vm.showPage = true;
                }
            });

            if (!this.showPage) {
                setTimeout(() => {
                    this.$refs['permission-modal'].showPermissionModal();
                });
            }
        },
        toggleIsClicked(id) {
            this.isClicked = !this.isClicked;
            this.selectedDataset = id.TableName;
            this.tableType = id.TableType;
            this.selectedTableFullName = id.TableFullName
                ? id.TableFullName
                : id.TableName;
            this.selectedReportId = id.id ? id.id : null;
            this.isClicked == true
                ? (this.btnDefineDataset = false)
                : (this.btnDefineDataset = true);
        },
        removeFilter() {
            this.applyfilter = false;
            this.tablename = '';
            this.rmFilter = false;
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.adddataset.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.adddataset.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.filter = null;
        },

        searchinputenter(args) {
            if (args) {
                this.showSearchIcon = false;
                if (!this.filter) {
                    this.searchClose = false;
                }
            }
        },
        navigatetoDatasets() {
            router.push(
                `/datasetdefinition/datasetlist/${
                    this.$route.params.id
                }/${localStorage.getItem('sourceName')}`
            );
        },
        ...mapActions('datasets', {
            getAdddataSets: 'getAdddataSets',
        }),
        filterdata() {
            this.filterShow = true;
        },
        backFilter(item) {
            this.filterShow = false;
            if (item == 'tablename') {
                this.tablename = '';
            }
            this.applyFilter();
        },
        applyFilter() {
            this.datasetFilterd = this.externalDtasetsData;

            if (this.tablename) {
                this.datasetFilterd = this.datasetFilterd.filter(
                    (item) => item.TableName === this.tablename
                );
                this.rmFilter = true;
            } else {
                this.rmFilter = false;
            }

            this.applyfilter = true;
            this.filterShow = false;
            this.externalDtasetsData = this.externalDtasetsData.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
        defineDataset() {
            router.push({
                name: `datasetcolumnsNew`,
                params: {
                    id: this.$route.params.id,
                    tablename: this.selectedTableFullName,
                    tableAliasName: this.selectedDataset,
                    type: this.tableType,
                    reportId: this.selectedReportId,
                    sourceName: this.$route.params.sourceName,
                },
            });
        },
    },
};
</script>

<style scoped>
.tick-mark img {
    float: right;
}
h6 {
    font-family: 'IBMPlexSans-Medium';
    font-weight: 500;
}
input.searchinput {
    background: url('../../assets/search-outline.svg') no-repeat !important;
    border: none;
    width: 25px;
    margin-top: 11px;
    position: relative;
    top: 5px;
}
.list-group-item {
    position: absolute;
    top: 50px;
}
.form-control {
    display: block;
    width: auto !important;
    height: calc(1em + 0.75rem + 2px);

    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.5;
    color: #000000 !important;
    background-color: #ced4da !important;
    background-clip: padding-box;
    border: 1px solid #ced4da;
}
.filteredItem {
    background-color: #eaeaea;
    width: auto;
    padding: 0px 10x;
    margin-right: 10px;
}
.removeFilter a {
    color: #006395 !important;
}
.blue-color a {
    padding: 0 0 0 10px;
}
.btn-secondary.disabled:hover,
.btn-secondary:disabled:hover {
    background-color: #6c757d !important;
}
.select_option {
    width: 275px;
    height: 48px;
    border-radius: 4px;
    padding: 5px 8px;
    border: 1px solid #ced4da;
}
input.test {
    margin: 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    /* //margin-right: -20px!important; */
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
</style>
