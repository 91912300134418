var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bv-example-row mb-3 mt-2"},[_c('b-row',{staticClass:"positionBlock",attrs:{"cols":"3"}},[_c('b-col',{staticClass:"border-bottom border-dark border-right left-1"},[_c('b-button',{class:{
          'tl_button edit': _vm.ProjectLayer.Position != 'topLeft',
        },on:{"click":function($event){return _vm.dataPosition('topLeft')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Top")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Left")])])],1),_c('b-col',{staticClass:"border-bottom border-dark"},[_c('b-button',{class:{
          'tc_button edit': _vm.ProjectLayer.Position != 'topCenter',
        },on:{"click":function($event){return _vm.dataPosition('topCenter')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Top")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Center")])])],1),_c('b-col',{staticClass:"border-left border-bottom border-dark right-1"},[_c('b-button',{class:{
          'tr_button edit': _vm.ProjectLayer.Position != 'topRight',
        },on:{"click":function($event){return _vm.dataPosition('topRight')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Top")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Right")])])],1),_c('b-col',{staticClass:"border-bottom border-dark"},[_c('b-button',{class:{
          'ml_button edit': _vm.ProjectLayer.Position != 'middleLeft',
        },on:{"click":function($event){return _vm.dataPosition('middleLeft')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Middle")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Left")])])],1),_c('b-col',{staticClass:"border-left border-right border-bottom border-dark"},[_c('b-button',{class:{
          'mc_button edit': _vm.ProjectLayer.Position != 'middleCenter',
        },on:{"click":function($event){return _vm.dataPosition('middleCenter')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Middle")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Center")])])],1),_c('b-col',{staticClass:"border-bottom border-dark"},[_c('b-button',{class:{
          'mr_button edit': _vm.ProjectLayer.Position != 'middleRight',
        },on:{"click":function($event){return _vm.dataPosition('middleRight')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Middle")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Right")])])],1),_c('b-col',{staticClass:"border-dark"},[_c('b-button',{class:{
          'bl_button edit': _vm.ProjectLayer.Position != 'bottomLeft',
        },on:{"click":function($event){return _vm.dataPosition('bottomLeft')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Bottom")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Left")])])],1),_c('b-col',{staticClass:"border-left border-right border-dark"},[_c('b-button',{class:{
          'bc_button edit': _vm.ProjectLayer.Position != 'bottomCenter',
        },on:{"click":function($event){return _vm.dataPosition('bottomCenter')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Bottom")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Center")])])],1),_c('b-col',{staticClass:"border-dark"},[_c('b-button',{class:{
          'br_button edit': _vm.ProjectLayer.Position != 'bottomRight',
        },on:{"click":function($event){return _vm.dataPosition('bottomRight')}}},[_c('span',{staticClass:"textSmall"},[_vm._v("Bottom")]),_c('br'),_c('span',{staticClass:"textLarge"},[_vm._v("Right")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }