<template>
    <b-container fluid>
        <b-row class="shift">
            <please-wait-spinner v-if="loading" />
            <b-col
                v-show="!loading"
                cols="12 my-1"
                @mouseover="isHovered = true"
                @mouseout="isHovered = false"
            >
                <span @click="fullscreen" class="fullscreen-icon"></span>
                <img
                    v-if="isHovered"
                    class="icon"
                    src="../../assets/fullscreen-grey.svg"
                />
                <section :id="container" class="dashboard" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import * as pbi from 'powerbi-client';
import router from '../../router';
import { v4 as uuidv4 } from 'uuid';
import { dashboardService } from '../../services/dashboard.service';
import PleaseWaitSpinner from '../CommonComponents/PleaseWaitSpinner.vue';
import { mapState, mapGetters } from 'vuex';
export default {
    name: 'PowerBI',
    props: {
        reportId: Number,
        projectId: Number,
    },
    components: {
        PleaseWaitSpinner,
    },
    data() {
        return {
            dashboardParams: null,
            show: true,
            dashboard: null,
            loading: true,
            config: null,
            showPage: false,
            container: uuidv4(),
            isHovered: false,
            basicFilter: null,
        };
    },
    mounted() {
        this.pagePermit();
        this.getDashboard(this.reportId);
    },
    methods: {
        fullscreen() {
            var embedContainer = document.getElementById(this.container);
            let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
            );
            let report = powerbi.get(embedContainer);
            report.fullscreen();
        },
        getDashboard(reportId) {
            let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
            );
            const dashboardContainer = document.getElementById(this.container);
            powerbi.reset(dashboardContainer);
            if (
                this.projectType === 'Emergency management' ||
                this.projectType === 'Emergency response'
            ) {
                this.basicFilter = {
                    $schema: 'http://powerbi.com/product/schema#basic',
                    target: {
                        table: 'tbl_ProjectDetails',
                        column: 'ProjectID',
                    },
                    operator: 'In',
                    values: [+this.ProjectID],
                    filterType: 1,
                };
                dashboardService.getDashboardParams(reportId).then((res) => {
                    this.dashboardParams = res.data;
                    new Promise((resolve) => {
                        const permissions = pbi.models.Permissions.All;
                        this.config = {
                            type: 'report',
                            tokenType: pbi.models.TokenType.Embed,
                            accessToken: this.dashboardParams.EmbedToken.token,
                            embedUrl:
                                this.dashboardParams.EmbedUrl +
                                '&filterPaneEnabled=false',
                            id: this.dashboardParams.ReportId,
                            pageView: 'fitToWidth',
                            permissions: permissions,
                            filters: [this.basicFilter],
                        };
                        resolve(this.config);
                    }).then((config) => {
                        powerbi.reset(dashboardContainer);

                        this.dashboard = powerbi.embed(
                            dashboardContainer,
                            this.config
                        );
                        this.loading = false;
                    });
                });
            } else {
                dashboardService.getReportFilter(this.projectId).then((res) => {
                    new Promise((resolve, reject) => {
                        if (
                            res.data.Message &&
                            res.data.Message.includes('Multiple')
                        ) {
                            reject(this.basicFilter);
                        }
                        if (res.data.table && res.data.column) {
                            this.basicFilter = {
                                $schema:
                                    'http://powerbi.com/product/schema#basic',
                                target: {
                                    table: res.data.table,
                                    column: res.data.column,
                                },
                                operator: 'In',
                                values: res.data.filter,
                                filterType: 1,
                            };
                        }
                        resolve(this.basicFilter);
                    }).then((filter) => {
                        dashboardService
                            .getDashboardParams(reportId)
                            .then((res) => {
                                this.dashboardParams = res.data;
                                new Promise((resolve) => {
                                    const permissions =
                                        pbi.models.Permissions.All;
                                    this.config = {
                                        type: 'report',
                                        tokenType: pbi.models.TokenType.Embed,
                                        accessToken: this.dashboardParams
                                            .EmbedToken.token,
                                        embedUrl:
                                            this.dashboardParams.EmbedUrl +
                                            // optionally remove bottom navigation pane or filter pane
                                            //"&navContentPaneEnabled=false" +
                                            '&filterPaneEnabled=false',
                                        id: this.dashboardParams.ReportId,
                                        pageView: 'fitToWidth',
                                        permissions: permissions,
                                        filters: [this.basicFilter],
                                    };
                                    resolve(this.config);
                                }).then((config) => {
                                    powerbi.reset(dashboardContainer);

                                    this.dashboard = powerbi.embed(
                                        dashboardContainer,
                                        this.config
                                    );
                                    this.loading = false;
                                });
                            });
                    });
                });
            }
        },
        expandDashboard() {
            this.container = 'mcontainer';
            this.getDashboard(this.reportId);
        },
        pagePermit() {
            this.showPage = true;
            this.showOpenProject = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('MapView')) {
                    vm.showPage = true;
                } else if (element.PageName.includes('SelectedProjects')) {
                    vm.showOpenProject = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },

        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
    },
    watch: {
        reportId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.loading = true;
                this.getDashboard(this.reportId);
                //this.container = uuidv4();
            }
        },
    },
    computed: {
        ...mapState('projects', ['selectedProjects']),
        ...mapGetters('projects', { ProjectID: 'ProjectID' }),
        projectType() {
            return this.selectedProjects[0].ProjectTypeName;
        },
    },
};
</script>

<style scoped>
button {
    width: 10%;
    height: 10%;
}

.list-view {
    top: 20px;
}

.shift {
    padding-top: 1em;
}

.dashboard {
    height: 80vh;
}

.expand {
    background: url('../../assets/export-icon.png');
    height: 22px;
    width: 10px;
}

.popout {
    width: 750px;
    height: 750px;
}

.modal-dialog {
    max-width: 100%;
    max-height: 100%;
}
.fullscreen-icon {
    position: absolute;
    right: 20px;
    bottom: 2px;
    width: 40px;
    height: 40px;
    z-index: 99;
}
.icon {
    position: absolute;
    right: 20px;
    bottom: 2px;
    height: 30px;
    width: 30px;
    z-index: 1;
}
.hoveredClass {
    color: pink;
}
</style>
