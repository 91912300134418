<template>
    <div v-if="showPage">
        <Header v-bind:showVersionToggle="false" />
        <!-- <SubHeader Heading='Add new user' TabHeading='User Details' v-bind:clearNeeded='false' v-bind:ButtonNeeded='false' ButtonText='Open projects' ButtonRoute='/dashboard' /> -->
        <b-container
            fluid
            class="adduser sub_header_bg"
            :class="adminrole != 'admin' ? 'admin1' : ''"
        >
            <b-container class="p-0">
                <b-row class="mb-3">
                    <b-col cols="12" class="m-p-0">
                        <a href="javascript:void(0)" @click="navigateUsers">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a
                                v-if="adminrole == 'admin'"
                                class="floar-left ml-1 back"
                            >
                                Back to dashboard</a
                            >
                            <span v-else class="floar-left ml-1">
                                Back to users</span
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="12" lg="9" md="7" sm="6" class="pt-2 m-p-0">
                        <h1
                            class=""
                            v-if="adminrole == 'admin' || $route.params.id"
                        >
                            {{ userFirstName }}
                            <span class="mr-1">{{ userLastName }}</span>
                            <small class="font14">{{ users.EmailId }}</small>
                        </h1>
                        <h1 v-else>Add new user</h1>
                    </b-col>
                    <b-col cols="2" lg="1" md="2" sm="2" class="m-view-b">
                        <p
                            class="text-center text-primary sm-cancel pr-2 m-0"
                            @click="navigateUsers"
                        >
                            Cancel
                        </p>
                    </b-col>
                    <b-col
                        cols="4"
                        lg="2"
                        md="3"
                        sm="4"
                        class="text-right adduser pl-0 m-bottom-btn"
                    >
                        <b-button
                            rounded
                            class="buttonSelect"
                            id="saveUser"
                            @click="addUser()"
                        >
                            {{ saveButtonText }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="pt-2">
                    <div
                        v-if="adminrole == 'admin'"
                        class="borderBlue mt-4 ml-3 mrg-0"
                    >
                        <h5>Manage account</h5>
                    </div>
                    <!-- <div v-else class="borderBlue mt-3 ml-3 m-left-0 m-pt-10">
              <h5>User details</h5>
              </div> -->
                </b-row>
            </b-container>
        </b-container>

        <div class="container p-0 mt-5 m-mt-10">
            <b-container class="adduser">
                <b-row class="pb-1 mb-5">
                    <b-col cols="5" class="blue-color p-0">
                        <b-form>
                            <div class="top-add">
                                <CustomValidateInput
                                    :config="{
                                        label: 'First name',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('FirstName')"
                                >
                                    <b-form-input
                                        :state="validateState('FirstName')"
                                        name="FirstName"
                                        v-model="$v.users.FirstName.$model"
                                        autocomplete="off"
                                        aria-describedby="firstName-feedback"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="firstName-feedback"
                                    >
                                        Please enter first name
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Last name',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('LastName')"
                                >
                                    <b-form-input
                                        :state="validateState('LastName')"
                                        name="LastName"
                                        v-model="$v.users.LastName.$model"
                                        autocomplete="off"
                                        aria-describedby="lastName-feedback"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="lastName-feedback"
                                    >
                                        Please enter last name
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>

                                <CustomValidateInput
                                    :config="{
                                        label: 'Email address',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                    :isValid="validated('EmailId')"
                                >
                                    <b-form-input
                                        :state="validateState('EmailId')"
                                        name="EmailId"
                                        v-model="$v.users.EmailId.$model"
                                        autocomplete="off"
                                        aria-describedby="emailId-feedback"
                                    >
                                    </b-form-input>
                                    <b-form-invalid-feedback
                                        class="invalid-text-custom"
                                        id="emailId-feedback"
                                    >
                                        Please enter email
                                    </b-form-invalid-feedback>
                                </CustomValidateInput>

                                <CustomFloatingLabel
                                    class="FloatingTool"
                                    :config="{ label: 'Company' }"
                                >
                                    <b-form-group>
                                        <b-form-select
                                            v-model="$v.users.CompanyId.$model"
                                            :options="companies"
                                            text-field="CompanyName"
                                            value-field="CompanyId"
                                            :state="validateState('CompanyId')"
                                            aria-describedby="companyId-feedback"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="''"
                                                    disabled
                                                    >Company</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback
                                            class="invalid-text-custom"
                                            id="countryName-feedback"
                                        >
                                            Please select a company
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </CustomFloatingLabel>

                                <CustomFloatingLabel
                                    class="FloatingTool"
                                    :config="{ label: 'Corporation' }"
                                >
                                    <b-form-group>
                                        <b-form-select
                                            v-model="
                                                $v.users.CorporationId.$model
                                            "
                                            :options="corporations"
                                            text-field="CorporationName"
                                            value-field="CorporationId"
                                            :state="
                                                validateState('CorporationId')
                                            "
                                            aria-describedby="corporationId-feedback"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="''"
                                                    disabled
                                                    >Corporation</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                        <b-form-invalid-feedback
                                            class="invalid-text-custom"
                                            id="corporationId-feedback"
                                        >
                                            Please select a corporation
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </CustomFloatingLabel>

                                <!-- <CustomFloatingLabel :config="{label: 'Comments(optional)'}">
                        <input type="text" v-model="users.Comments" autocomplete="off" class="textarea_input">
                    </CustomFloatingLabel> -->

                                <!-- <div class="mt-5">
                        <h5 class="text-dark float-left mb-3">Contact Information</h5>
                        <span class="float-left">(optional)</span>
                    </div>
                    <div class="clearfix"></div> -->

                                <div class="small-12 column mb-3">
                                    <div class="form-floating-label">
                                        <label for="textarea"
                                            >Comments (optional)</label
                                        >
                                        <textarea
                                            name=""
                                            id="enterComments"
                                            v-model="users.Comments"
                                            rows="5"
                                            placeholder=""
                                        ></textarea>
                                    </div>
                                </div>

                                <h4 class="contactinfo">
                                    Contact information<small>
                                        (optional)</small
                                    >
                                </h4>
                                <CustomFloatingLabel
                                    :config="{ label: 'Address' }"
                                    v-bind:editUserParams="$route.params.id"
                                >
                                    <input
                                        itemid="address"
                                        type="text"
                                        v-model="users.Address"
                                        autocomplete="off"
                                        id="enterAddress"
                                        class="normal_input"
                                    />
                                </CustomFloatingLabel>

                                <div id="country">
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{ label: 'Country' }"
                                    >
                                        <b-form-select
                                            v-model="users.CountryId"
                                            id="selectCountry"
                                            class="mb-3"
                                            :options="countries"
                                            text-field="CountryName"
                                            value-field="CountryId"
                                            @change="stateByCountry"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    value=""
                                                    disabled
                                                    >Country</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                </div>

                                <div id="state">
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{ label: 'State/Province' }"
                                    >
                                        <b-form-select
                                            v-model="users.StateId"
                                            id="selectState"
                                            class="mb-3"
                                            :options="states"
                                            text-field="StateName"
                                            value-field="StateId"
                                            @change="cityByState"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    value=""
                                                    disabled
                                                    >State/Province</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                </div>

                                <!-- <div id="city" v-if="$route.params.id && !cityTypeAhead">
                        <b-form-select v-model="users.CityId" class="mb-3" id="selectCity" :options="cities" text-field="CityName" value-field="CityId">

                        <template #first>
                            <b-form-select-option :value="null" disabled>City</b-form-select-option>
                        </template>
                        </b-form-select>
                    </div> -->
                            </div>
                            <div class="add">
                                <CustomFloatingLabel
                                    :config="{ label: 'City' }"
                                    v-bind:editUserParams="$route.params.id"
                                >
                                    <input
                                        type="text"
                                        v-model="users.CityName"
                                        id="enterCity"
                                        autocomplete="off"
                                        class="normal_input"
                                    />
                                </CustomFloatingLabel>

                                <CustomFloatingLabel
                                    :config="{ label: 'Zip/Postal code' }"
                                    v-bind:editUserParams="$route.params.id"
                                >
                                    <input
                                        type="text"
                                        itemid="zipCode"
                                        v-model="users.PostalCode"
                                        id="enterZip"
                                        autocomplete="off"
                                        class="normal_input"
                                    />
                                </CustomFloatingLabel>

                                <CustomFloatingLabel
                                    :config="{ label: 'Phone number' }"
                                    v-bind:editUserParams="$route.params.id"
                                >
                                    <ejs-maskedtextbox
                                        v-model="users.Phone"
                                        class="normal_input_mask"
                                        mask="000-000-0000"
                                    ></ejs-maskedtextbox>
                                </CustomFloatingLabel>

                                <CustomFloatingLabel
                                    :config="{ label: 'Cell phone number' }"
                                    v-bind:editUserParams="$route.params.id"
                                >
                                    <ejs-maskedtextbox
                                        v-model="users.Cell"
                                        class="normal_input_mask"
                                        mask="000-000-0000"
                                    ></ejs-maskedtextbox>
                                </CustomFloatingLabel>
                            </div>
                            <h4 class="contactinfo">
                                User Authentication<small> (optional)</small>
                            </h4>
                            <b-radio
                                v-for="item in authTypes"
                                v-bind:key="item.id"
                                v-model="users.AuthenticationTypeId"
                                :aria-describedby="ariaDescribedby"
                                name="some-radios"
                                :value="item.AuthenticationTypeId"
                                >{{ item.AuthenticationTypeName }}</b-radio
                            >
                            <h4 class="contactinfo mt-3">
                                Default ADAPT version<small> (optional)</small>
                            </h4>
                            <b-radio
                                v-for="item in defaultVersion"
                                v-bind:key="item.key"
                                v-model="users.Version"
                                :aria-describedby="ariaDescribedby"
                                name="version-radios"
                                :value="item.key"
                                >{{ item.value }}</b-radio
                            >
                        </b-form>
                    </b-col>
                </b-row>
            </b-container>

            <ToastMessages
                :showSuccess="showSuccess"
                :showFailure="showFailure"
                :failureToastMessage="failureToastMessage"
                :successToastMessage="successToastMessage"
            />
            <!-- <div class="successToastMsg" v-if="successToast">
                        <img src="../../assets/success_tick.svg" class="mr-3"/>
                        Success  <br/> Changes saved.
                    <img src="../../assets/close_big.svg" class="crossIcon" @click="closeSuccessToast"/>
                    </div> -->
            <b-modal
                ref="my-modal"
                class="model_style"
                hide-header
                hide-footer
                centered
            >
                <div class="d-block text-center">
                    <div class="text-right" @click="hideModal">
                        <img src="../../assets/close_icon.svg" />
                    </div>
                    <h5 class="mt-1 mb-1">Email id already exists</h5>
                    <br />
                    <div class="mt-1 mb-4">
                        <!-- <a class="text-primary">No, don't delete</a> -->
                        <button @click="hideModal" class="cancel-btn">
                            Cancel
                        </button>
                    </div>
                </div>
            </b-modal>
        </div>
        <Footer />
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import Vue from 'vue';
import router from '../../router';
import { mapState, mapActions } from 'vuex';
import CustomFloatingLabel from '../CustomFloatingLabel';
// import BFormInvalidFeedback from 'bootstrap-vue';
import Header from '../Header';
import Footer from '../../components/Footer';
import { commonService } from '../../services/common.service';
// import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import AccessPermissionModal from '../AccessPermissionModal';
//import dashboard from '../../views/Dashboard';
import { userService } from '../../services';
import ToastMessages from '../ToastMessages.vue';
import { MaskedTextBoxPlugin } from '@syncfusion/ej2-vue-inputs';
Vue.use(MaskedTextBoxPlugin);
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import CustomValidateInput from '../CustomValidateInput';

export default {
    mixins: [validationMixin],
    name: 'User_add',
    components: {
        Header,
        Footer,
        CustomFloatingLabel,
        // BFormInvalidFeedback,
        AccessPermissionModal,
        // dashboard,
        ToastMessages,
        CustomValidateInput,
    },
    beforeDestroy() {
        localStorage.removeItem('adminrole');
    },
    validations() {
        return {
            users: {
                FirstName: {
                    required,
                },
                LastName: {
                    required,
                },
                EmailId: {
                    required,
                    email,
                },
                CompanyId: {
                    required,
                },
                CorporationId: {
                    required,
                },
            },
        };
    },
    data() {
        return {
            disabled: 0,
            buttonStatus: true,
            validateInput: [],
            saveButtonText: 'Save new user',
            cancelShow: false,
            companies: null,
            corporations: null,
            countries: null,
            states: null,
            cities: [],
            initialCityChange: 0,
            cityTypeAhead: false,
            authTypes: [],
            defaultVersion: [
                { key: '1', value: 'ADAPT Classic' },
                { key: '2', value: 'ADAPT 2.0' },
            ],
            users: {
                FirstName: null,
                LastName: null,
                EmailId: null,
                CompanyId: '',
                CorporationId: '',
                Comments: null,
                Address: null,
                CountryId: '',
                StateId: '',
                // CityId:null,
                CityName: null,
                PostalCode: null,
                Phone: null,
                Cell: null,
                AuthenticationTypeId: 1,
                Version: '1',
            },
            adminrole: null,
            loggedinUser: [],
            singleUser: [],
            successToast: false,
            showPage: true,
            thisfilename: null,
            usersEdit: null,
            buttonStatusSave: true,
            userFirstName: null,
            userLastName: null,
            AllInput: [],
            counter: 0,
            disableBtn: false,
            showSuccess: false,
            showFailure: false,
            successToastMessage: null,
            emailErr: false,
        };
    },
    created() {
        this.adminrole = localStorage.getItem('adminrole');
        this.loggedinUser = JSON.parse(localStorage.getItem('userDetails'));
        this.companyList();
        this.countryList();
        this.corporationList();
        this.countryList();
        this.getAuthTypes();
        if (this.$route.params.id) {
            this.cityTypeAhead = false;
            // this.cancelShow=true
            this.saveButtonText = 'Save changes';
            userService.getUserById(this.$route.params.id).then((res) => {
                this.singleUser = res.data;
                this.usersEdit = this.users;
                this.users = this.singleUser;
                this.userFirstName = this.users.FirstName;
                this.userLastName = this.users.LastName;
                this.stateByCountry();
                this.cityByState();
                if (
                    this.users.FirstName &&
                    this.users.LastName &&
                    this.users.EmailId &&
                    this.users.CompanyId &&
                    this.users.CorporationId
                ) {
                    this.buttonStatusSave = false;
                }

                if (this.users.FirstName) {
                    this.AllInput.push('firstname');
                }
                if (this.users.LastName) {
                    this.AllInput.push('lastname');
                }
                if (this.users.EmailId) {
                    this.AllInput.push('email');
                }
                if (this.users.CompanyId) {
                    this.AllInput.push('company');
                }
                if (this.users.CorporationId) {
                    this.AllInput.push('corporation');
                }
            });
        } else {
            this.cityTypeAhead = true;
        }
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        validateState(name) {
            const { $dirty, $error } = this.$v.users[name];
            return $dirty ? !$error : null;
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('AddUser')) {
                    vm.showPage = true;
                }
            });
            if (!this.showPage) {
                this.$refs['permission-modal'].showPermissionModal();
            }
        },
        companyList() {
            commonService
                .getCompanies()
                .then((res) => (this.companies = res.data));
        },
        corporationList() {
            commonService
                .getCorporations()
                .then((res) => (this.corporations = res.data));
        },
        countryList() {
            commonService
                .getCountries()
                .then((res) => (this.countries = res.data));
        },
        stateByCountry() {
            commonService
                .getStates(this.users.CountryId)
                .then((res) => (this.states = res.data));
        },
        cityByState() {
            if (this.$route.params.id && this.initialCityChange !== 0) {
                this.cityTypeAhead = true;
            }
            this.initialCityChange++;
            commonService
                .getCities(this.users.StateId)
                .then((res) => (this.cities = res.data));
        },

        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        navigateUsers: function() {
            if (this.adminrole == 'admin') {
                // router.push(`/`);
                router.go(-1);
            } else {
                router.push(`/users`);
            }
        },

        handleBlur(item, event) {
            let value;
            value = event.target.value;
            if (item && !value) {
                if (!this.validateInput.includes(item)) {
                    this.validateInput.push(item);
                }
                if (this.AllInput.includes(item)) {
                    this.AllInput.splice(this.AllInput.indexOf(item), 1);
                }
                this.counter++;
            } else {
                if (!this.AllInput.includes(item)) {
                    this.AllInput.push(item);
                }
                if (this.validateInput.includes(item)) {
                    this.validateInput.splice(
                        this.validateInput.indexOf(item),
                        1
                    );
                }
                this.counter++;
            }
            // if(this.validateInput.length<3){
            //     this.buttonStatus=false
            // }

            if (item === 'email') {
                this.emailErr = false;
                var pattern = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (!pattern.test(value)) {
                    if (!this.validateInput.includes(item)) {
                        this.validateInput.push(item);
                    }
                    this.disableBtn = true;
                    if (this.AllInput.includes(item)) {
                        this.AllInput.splice(this.AllInput.indexOf(item), 1);
                    }
                } else {
                    if (this.validateInput.includes(item)) {
                        this.validateInput.splice(
                            this.validateInput.indexOf(item),
                            1
                        );
                    }
                    if (!this.AllInput.includes(item)) {
                        this.AllInput.push(item);
                    }
                    this.disableBtn = false;
                }
            }
            if (
                this.validateInput.length == 0 &&
                this.AllInput.length == 5 &&
                !this.disableBtn
            ) {
                this.buttonStatusSave = false;
            } else {
                this.buttonStatusSave = true;
            }
            this.cancelShow = true;
        },
        addUser() {
            this.$v.users.$touch();
            if (this.$v.users.$anyError) {
                this.failureToastMessage =
                    'Not all required fields are complete. Please check all sections, then retry.';
                this.showFailure = true;
                this.showSuccess = false;
                this.connectingToast = false;
                setTimeout(() => {
                    this.showFailure = false;
                }, 5000);
                return;
            }
            const userData = this.users;
            if (this.$route.params.id) {
                const userId = this.$route.params.id;

                this.$delete(this.users, 'State');
                this.$delete(this.users, 'Country');
                this.$delete(this.users, 'AuthenticationType');
                this.users.UserName = this.users.EmailId;

                userService.updateUser(userData, userId).then((res) => {
                    if (
                        res.data.StatusCode === 202 &&
                        this.adminrole == 'admin'
                    ) {
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage = 'User updated successfully';
                        router.push(`/users`);
                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 5000);
                    } else if (res.data.StatusCode === 202) {
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage = 'User updated successfully';

                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 5000);
                        setTimeout(() => {
                            router.push(`/users`);
                        }, 1500);
                    }
                });
                //
                /* this.update({userData, userId})
                setTimeout(() => {
                if (this.updateStatusCode === 202 && this.adminrole == "admin") {
                    this.showSuccess = true;
                    this.showFailure = false;
                    this.successToastMessage = "User updated successfully";
                    setTimeout(() => {
                    this.showSuccess = false;
                    }, 5000);
                } else if (this.updateStatusCode === 202) {
                    this.showSuccess = true;
                    this.showFailure = false;
                    this.successToastMessage = "User updated successfully";
                    setTimeout(() => {
                    this.showSuccess = false;
                    }, 5000);
                    router.push(`/users');
                }
                }, 1000);*/
            } else {
                //
                userService.createUsers(userData).then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage = 'User created successfully';
                        setTimeout(() => {
                            this.showSuccess = false;
                            router.push(`/users`);
                        }, 2000);
                    } else if (res.data.StatusCode === 302) {
                        this.emailErr = true;
                        // this.showModal();
                    }
                });
                // this.create(this.users);
                // setTimeout(()=>{
                //     if (this.createStatusCode === 201) {
                //         this.showSuccess = true;
                //         this.showFailure = false;
                //         this.successToastMessage = "User created successfully";
                //         setTimeout(() => {
                //         this.showSuccess = false;
                //         }, 5000);
                //         router.push(`/users');
                //     } else if (this.createStatusCode === 500) {
                //         this.showModal();
                //     }
                // },1000)
            }
        },
        showModal() {
            this.$refs['my-modal'].show();
        },
        hideModal() {
            this.$refs['my-modal'].hide();
        },

        closeSuccessToast() {
            this.successToast = false;
        },
        getAuthTypes() {
            userService.getAuthTypes().then((res) => {
                this.authTypes = res.data;
            });
        },
    },

    computed: {
        ...mapState({
            users: (state) => state.users.users,
            Message: (state) => state.users.create.Message,
            createStatusCode: (state) => state.users.create.StatusCode,
            updateStatusCode: (state) => state.users.update.StatusCode,
            singleUser: (state) => state.users.singleUser,
        }),
        validated() {
            return (status) => {
                const { $dirty } = this.$v.users[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
    },
};
</script>

<style scoped>
.my-custom-floating-label {
    margin-bottom: 20px;
    display: inline-block;
}

.errorMsg {
    margin-top: -20px;
    margin-bottom: 20px;
}

.errorMsg_input {
    position: relative;
    top: -20px;
    width: 499px;
}

.errorMsg_input span {
    float: right;
    position: relative;
    top: -40px;
    right: 15px;
    /* right: 88px; */
}

.normal_input {
    height: 48px !important;
}

.textarea_input {
    height: 95px !important;
}

/* .buttonSelect{
  background-color: #006395;
  padding:10px 18px;

} */
select {
    appearance: none;
    border-radius: 0px;
    height: 48px !important;
    width: 475px;
}

select.selectbox {
    border: 1px solid #c6c6c6;
    padding-left: 7px;
    color: -internal-light-dark(black, white);
}

.selectbox:hover {
    border: 1px solid #c6c6c6;
}

.smalltext {
    color: #000;
    margin: -15px 0px 20px;
    font-size: 13px;
}

.typeAhead {
    border-radius: 0px !important;
    height: 48px !important;
    width: 475px;
}

.form-floating-label textarea {
    margin-bottom: 0;
    color: #626262;
    font-size: 16px;
    /* font-weight: 300; */
    padding: 35px 1rem 1rem 20px !important;
    width: 475px;
    height: 134px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
    border-radius: 3px;
}
.form-floating-label label {
    color: #626262;
    font-size: 16px;
    position: absolute;
    left: 17px;
    padding-top: 10px;
    transition: all 0.2s ease-in;
    font-family: var(--ffr);
}

.form-floating-label textarea ~ label {
    font-size: 16px;
    font-weight: 400;
}
.form-floating-label textarea:focus,
.form-floating-label.has-value textarea {
    border-radius: 0px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
}
.form-floating-label textarea:focus ~ label,
.form-floating-label.has-value textarea ~ label {
    font-size: 12px;
    color: rgb(98, 98, 98);
    font-weight: 400;
}

.contactinfo {
    color: #161616;
    font-size: 25px;
}

.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.crossIcon {
    margin-left: 150px;
    margin-top: -25px;
    width: 15px;
}
.input__container.input__container--content input,
.input__container.input__container--content .label__placeholder {
    transform: translate3d(0, 6px, 0);
    /* width: 94% !important; */
    /* left:12px; */
}
.sm-text {
    margin-top: 0 !important;
}
small {
    color: #515151;
    font-family: var(--ffr);
    font-size: 14px;
}
.nameColor {
    color: #515151;
}
.emailColor {
    color: #1d1d1d;
}
.sm-cancel {
    cursor: pointer;
    margin-right: -65px;
}
.normal_input_mask {
    height: 36px !important;
    font-size: 16px !important;
    top: 4px;
    color: #000;
}
.font14 {
    font-size: 14px !important;
}
@media only screen and (min-width: 768px) {
    .admin1.sub_header_bg {
        height: 120px !important;
    }
}
/* .adduser select{
   padding-left: 17px!important;
} */
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            right 0.75rem center/8px 10px no-repeat,
        #fff
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
            center right 1.75rem / calc(0.75em + 0.375rem)
            calc(0.75em + 0.375rem) no-repeat !important;
}
.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #c6c6c6 !important;
    padding-right: calc(0.75em + 2.3125rem);
}
</style>
