<template
    ><b-modal
        id="filemaker-upload"
        title="FileMaker Upload"
        hide-footer
        no-close-on-backdrop
    >
        <div class="fileMakerUpload-container">
            <b-form-group>
                <p class="mb-1 pl-1">
                    FileMaker ER template
                </p>
                <p style="font-size: 24px; color: red; font-weight: bold;">
                    Project ID: {{ projectID }}
                </p>
                <b-form-file
                    accept=".fmp12"
                    v-model="$v.projectFile.$model"
                    :state="validateFileState('projectFile')"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    class="mb-1 text-start fileMaker-upload"
                    id="fileMaker-file"
                >
                </b-form-file
                ><b-form-invalid-feedback
                    class="invalid-text-custom"
                    id="file-feedback"
                >
                    The valid extension for the FileMaker file is fmp12.
                </b-form-invalid-feedback>
                <b-button
                    @click="projectFile = null"
                    variant="secondary"
                    class="mt-2"
                    >Reset the file</b-button
                >
            </b-form-group>
        </div>
        <div class="my-2 d-flex justify-content-end">
            <b-button class="mx-1" variant="danger" @click="hideUploadModal()">
                Cancel
            </b-button>
            <b-button
                class="mx-1"
                variant="outline-secondary"
                @click="uploadFMTemplate(projectFile)"
                :disabled="uploadInProgress || projectFile == null"
            >
                Upload
            </b-button>
        </div>
    </b-modal>
</template>
<script>
export default {
    name: 'fileMakerModal',
    validations() {
        return {
            projectFile: {
                extension: (value) => {
                    if (!value) {
                        return true;
                    }
                    const allowedExtensions = /\.fmp12/i;
                    return allowedExtensions.exec(value.name) ? true : false;
                },
            },
        };
    },
    props: {
        projectID: Number,
        uploadInProgress: Boolean,
    },
    data() {
        return { projectFile: null };
    },
    methods: {
        validateFileState(name) {
            const { $dirty, $error } = this.$v[name];
            return $dirty ? !$error : null;
        },
        uploadFMTemplate(file) {
            this.$v.projectFile.$touch();
            if (this.$v.projectFile.$anyError) {
                this.projectFile = null;
                return;
            } else {
                this.$emit('uploadFMTemplate', file);
            }
        },
        showUploadModal() {
            this.projectFile = null;
            this.$bvModal.show('filemaker-upload');
        },
        hideUploadModal() {
            this.projectFile = null;
            this.$bvModal.hide('filemaker-upload');
        },
    },
};
</script>
<style lang="scss" scoped>
.uploadFileMaker-form .custom-file-label {
    border: 1px solid #c6c6c6;
    color: #626262 !important;
    font-size: 16px;
    font-family: var(--ffr);
    padding-left: 18px;
    border-radius: 3px;
    &::after {
        height: 100%;
        line-height: 2;
        color: #626262 !important;
        font-size: 16px;
        font-family: var(--ffr);
    }
}

.uploadFileMaker-form .form-file-text {
    line-height: 2;
}

.custom-file-input.is-valid ~ .custom-file-label {
    border-color: #c6c6c6 !important;
}
.uploadFileMaker-form .custom-file-label {
    width: 499px;
    height: calc(2.25rem + 12px);
}
</style>
