<template>
    <div>
        <div class="badge leftmargin m-1 columns show-all-main-wrapper">
            <div class="show-all-wrapper">
                <label class="switch ml-4">
                    <input type="checkbox" v-model="selectAll" />
                    <span class="slider round"></span>
                </label>
                <span class="content ml-1" v-if="selectAll"
                    >Show all columns
                </span>
                <span class="content ml-1" v-else>
                    Hide all columns
                </span>
            </div>
        </div>
        <draggable
            class="list-group"
            tag="ul"
            v-model="allDataSetColoumns"
            v-bind="dragOptions"
            :move="onMove"
            @start="isDragging = true"
            @end="isDragging = false"
        >
            <div
                class="list-group over-cont"
                v-for="(element, index) in allDataSetColoumns"
                :key="index"
            >
                <span class="badge"
                    ><img src="../../../../assets/Overflow Menu.svg" />
                    <CustomFloatingLabel
                        :config="{
                            label: element.name,
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <input
                            type="text"
                            class="inputbox normal_input"
                            autocomplete="off"
                            name="element.name"
                            v-model="element.ColumnAlias"
                        />
                    </CustomFloatingLabel>
                    <label class="switch ml-4">
                        <input
                            type="checkbox"
                            v-model="element.IsShown"
                            @click="selectColoumns(element)"
                        />
                        <span class="slider round"></span>
                    </label>
                    <span class="content ml-1" v-if="element.IsShown"
                        >Show column
                    </span>
                    <span class="content ml-1" v-else>
                        Hide column
                    </span></span
                >
            </div>
        </draggable>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import CustomFloatingLabel from '../../../CustomFloatingLabel';
export default {
    components: {
        draggable,
        CustomFloatingLabel,
    },
    props: {
        DatasetDTO: Object,
        allDataSetColoumns: Array,
        selectedColoumns: Array,
    },
    data() {
        return {
            isDragging: false,
            editable: true,
        };
    },
    methods: {
        selectColoumns(coloumn) {
            let tempSelectedColoumns = [...this.selectedColoumns];
            let indxExists = tempSelectedColoumns.findIndex(
                (items) => items.name === coloumn.name
            );
            if (indxExists > -1) {
                tempSelectedColoumns.splice(indxExists, 1);
            } else {
                tempSelectedColoumns.push(coloumn);
            }
            this.setSelectColoumns(tempSelectedColoumns);
        },
        onMove({ relatedContext, draggedContext }) {
            setTimeout(() => {
                this.allDataSetColoumns.forEach((item, index) => {
                    item.id = index;
                    item.order = index;
                });
            }, 1000);
        },
        setSelectColoumns(tempSelectedColoumns) {
            this.$emit('setSelectColoumns', tempSelectedColoumns);
        },
    },
    computed: {
        selectAll: {
            get: function() {
                return this.selectedColoumns
                    ? this.allDataSetColoumns.length ==
                          this.selectedColoumns.length
                    : false;
            },
            set: function(value) {
                //if value === true ---->  add all allDataSetColoumns to  selectedColoumns
                //if value === false --->  set selectedColoumns.length === 0
                let tempColsArray = [...this.allDataSetColoumns];
                let tempSelectedColoumns = [];
                tempColsArray.forEach((item) => {
                    item.IsShown = value;
                    let indxExists = tempSelectedColoumns.findIndex(
                        (coloumn) => item.name === coloumn.name
                    );
                    if (value) {
                        var editedItem = this.selectedColoumns.find((col) => {
                            return col.name === item.name;
                        });
                        if (editedItem) {
                            tempSelectedColoumns.push(editedItem);
                        } else {
                            tempSelectedColoumns.push(item);
                        }
                    }
                });
                this.setSelectColoumns(tempSelectedColoumns);
            },
        },
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: !this.editable,
                ghostClass: 'ghost',
            };
        },
    },
};
</script>

<style scoped>
.badge {
    text-align: left;
    padding: 0 !important;
}
.badge.tree-input {
    padding-left: 0 !important;
}
.leftmargin {
    margin-left: 5px;
}
.show-all-main-wrapper {
    width: 681px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    height: 28px;
    width: 57px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
input:checked + .slider {
    background-color: #2196f3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
.inputbox {
    width: 95%;
    height: 48px;
    border: 1px solid #c6c6c6;
    font-size: 16px;
    font-family: var(--ff);
    color: #373a3c;
}
.inputbox-color {
    height: 48px;
    width: 499px;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .inputbox {
        widows: 95% !important;
    }
}
.modal-body .normal_input {
    height: 48px !important;
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 45px;
    border-top: 1px solid #dddddd;
    background: no-repeat;
}

.modal-body .list-group {
    height: 47px;
    padding: 0px 0 0 10px;
}
.modal-body .list-group:nth-child(odd) {
    background-color: #f9f9f9 !important;
}
.list-group {
    height: 75px;
    margin-left: -29px;
    padding: 5px 15px;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.show-all-wrapper {
    width: 168px;
    position: relative;
    float: right;
}
</style>
