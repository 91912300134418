<template>
    <!-- https://dev.to/mandrewcito/vue-js-draggable-div-3mee -->
    <div
        ref="draggableContainer"
        :id="containerId"
        @mousedown="dragMouseDown"
        class="draggable-container"
    >
        <div
            id="headerId"
            class="draggable-header"
            :class="{ blueHeader: pId === 'modelB' }"
        >
            <slot name="header"></slot>
            <div
                id="btn-wrapper"
                class="d-flex justify-content-center align-items-center"
            >
                <button
                    v-if="allowToggleMain"
                    :id="showHideId"
                    @click="toggleSettingsVisibility"
                    class="showHideButton expandToggleBtn-legend border-left justify-content-center"
                    :class="{ blueHeader: pId === 'modelB' }"
                >
                    <img
                        v-if="isSettings"
                        src="../../assets/settings-cog-icon.svg"
                        class="my-auto"
                    />
                    <img
                        v-else
                        src="../../assets/settings-active.svg"
                        class="my-auto"
                    />
                </button>
                <button
                    v-if="allowToggleMain"
                    :id="showHideId"
                    @click="toggleMainVisibility"
                    class="showHideButton expandToggleBtn-legend border-left justify-content-center"
                    :class="{ blueHeader: pId === 'modelB' }"
                    :disabled="!isSettings"
                >
                    <img
                        v-if="showMain"
                        src="../../assets/minus.svg"
                        class="my-auto"
                    />
                    <img
                        v-else
                        src="../../assets/plus_sign.svg"
                        class="my-auto"
                    />
                </button>
            </div>
        </div>
        <div :id="mainId" class="draggable-main border-top">
            <div v-if="!isSettings">
                <div class="p-2 d-flex justify-content-left align-items-center">
                    <div
                        v-for="(item, index) in settings"
                        :key="index"
                        class="p-2 d-flex justify-content-left align-items-center"
                    >
                        <b-form-checkbox
                            :id="'checkbox-' + index"
                            v-model="item.status"
                            :name="'checkbox-' + index"
                            :value="true"
                            :unchecked-value="false"
                        ></b-form-checkbox>
                        <span>{{ item.label }}</span>
                    </div>
                </div>
            </div>
            <div v-else>
                <slot v-if="showMain" name="main"></slot>
            </div>
        </div>
        <div :id="footerId" class="draggable-footer border-top">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DraggableDiv',
    data: function() {
        return {
            positions: {
                clientX: null,
                clientY: null,
                movementX: 0,
                movementY: 0,
            },
            showMain: true,
            isSettings: true,
            status: false,
        };
    },
    props: {
        allowToggleMain: {
            type: Boolean,
            default: false,
        },
        startX: {
            type: Number,
            default: 0,
        },
        startY: {
            type: Number,
            default: 0,
        },
        rightBound: {
            type: Number,
            default: 99999,
        },
        bottomBound: {
            type: Number,
            default: 99999,
        },
        pId: {
            type: String,
            default: 'default',
        },
        settings: {
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        this.clientX = this.startX;
        this.clientY = this.startY;
    },
    methods: {
        // removes the 'px' from a css location
        getPixelValue(pixels) {
            if (typeof pixels == 'number') {
                return pixels;
            } else if (typeof pixels == 'string') {
                return parseInt(pixels.replace('px', ''));
            } else {
                return pixels;
            }
        },
        toggleMainVisibility() {
            this.showMain = !this.showMain;
        },
        toggleSettingsVisibility() {
            this.isSettings = !this.isSettings;
        },
        dragMouseDown: function(event) {
            this.$emit('mouseDragStart');
            event.preventDefault();
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            document.getElementById(this.containerId).classList.add('dragging');
            document.onmousemove = this.elementDrag;
            document.onmouseup = this.closeDragElement;
        },
        elementDrag: function(event) {
            event.preventDefault();
            this.positions.movementX = this.positions.clientX - event.clientX;
            this.positions.movementY = this.positions.clientY - event.clientY;
            this.positions.clientX = event.clientX;
            this.positions.clientY = event.clientY;
            // set the element's new position:
            let newY =
                this.$refs.draggableContainer.offsetTop -
                this.positions.movementY;
            let newX =
                this.$refs.draggableContainer.offsetLeft -
                this.positions.movementX;
            if (newY < 0) {
                newY = 0;
            } else if (
                newY +
                    this.getPixelValue(
                        this.$refs.draggableContainer.clientHeight
                    ) >
                this.bottomBound
            ) {
                newY =
                    this.bottomBound -
                    this.$refs.draggableContainer.clientHeight;
            }
            if (newX < 0) {
                newX = 0;
            } else if (
                newX +
                    this.getPixelValue(
                        this.$refs.draggableContainer.clientWidth
                    ) >
                this.rightBound
            ) {
                newX =
                    this.rightBound - this.$refs.draggableContainer.clientWidth;
            }
            this.$refs.draggableContainer.style.top = newY + 'px';
            this.$refs.draggableContainer.style.left = newX + 'px';
        },
        closeDragElement() {
            this.$emit('mouseDragStop');
            if (this.$refs.draggableContainer.style.left < 0) {
                this.$refs.draggableContainer.style.left = 0;
            }
            if (this.$refs.draggableContainer.style.top < 0) {
                this.$refs.draggableContainer.style.top = 0;
            }
            document
                .getElementById(this.containerId)
                .classList.remove('dragging');
            document.onmouseup = null;
            document.onmousemove = null;
        },
    },
    computed: {
        containerId: function() {
            return `draggable-container-${this.pId}`;
        },
        headerId: function() {
            return `draggable-header-${this.pId}`;
        },
        mainId: function() {
            return `draggable-main-${this.pId}`;
        },
        footerId: function() {
            return `draggable-footer-${this.pId}`;
        },
        showHideId: function() {
            return `showHideButton-${this.pId}`;
        },
    },
};
</script>

<style scoped>
.draggable-header {
    z-index: 100;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font: normal normal normal 18px/30px IBM Plex Sans;
    padding-left: 20px;
    height: 45px;
}
.showHideButton {
    display: flex;
    align-items: flex-end !important;
}
.draggable-container {
    z-index: 90;
    height: fit-content;
    display: block;
}
.draggable-main {
    height: calc(100% - 77px);
}
.draggable-footer {
    height: 32px;
    display: block;
    z-index: 100;
    background-color: #f2f2f2;
    font: normal normal normal 12px/26px IBM Plex Sans;
    color: #777777;
    padding-left: 20px;
}
.expandToggleBtn-legend {
    width: 40px;
    display: flex;
    align-items: center;
    justify-items: center;
    background-color: #f2f2f2;
    border: none;
    padding: 0;
    height: 100%;
}
.dragging {
    cursor: grabbing;
}
.blueHeader {
    background-color: #0078d4;
    color: white;
}
.custom-dropdown-menu-class {
    z-index: 9999;
}
</style>
