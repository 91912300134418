<template>
  <div>
    <p>
      Add a description for the selected columns
    </p>
    <b-form>
      <b-form-group>
        <div class="container-warp">
          <CustomFloatingLabel
            :config="{
              label: element.name,
              name: 'wrapper',
              line: false,
              scale: false,
            }"
            v-for="element in selectedColoumns"
            :key="element.name"
          >
            <input
              type="text"
              class="inputbox normal_input"
              autocomplete="off"
              name="element.name"
              :value="element.description"
              @change="setDescription($event.target.value, element)"
            />
          </CustomFloatingLabel>
        </div>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import CustomFloatingLabel from "../../../CustomFloatingLabel";
export default {
  name: "ToolDescription",
  components: {
    CustomFloatingLabel,
  },
  props: { selectedColoumns: Array },
  data() {
    return {};
  },
  mounted() {},
  beforeUpdate() {},
  created() {},
  methods: {
    setDescription(value, element) {
      let temp = [...this.selectedColoumns];
      temp.forEach((item) => {
        if (item.name === element.name) {
          item.description = value;
        }
      });
      this.$emit("setSelectColoumns", temp);
    },
  },
  computed: {},
};
</script>

<style>
.container-warp {
  display: flex;
  flex-direction: column;
  height: 500px;
}
</style>
