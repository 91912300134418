<template>
    <div v-if="showPage">
        <b-container fluid class="selectedprojects sub_header_bg p-0">
            <div v-if="powerBILoading">
                <div class="spinner-border powerBIIcon" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="powerBIText">Please wait...</div>
            </div>
            <b-container class="p-0" v-if="showHeader">
                <b-row class="mb-3 pt-1">
                    <b-col cols="12">
                        <a @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <span class="floar-left ml-1">
                                Back to projects</span
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="7" class="pt-2">
                        <h1 style="height: 34.8px" v-if="!documentTabopen">
                            {{ inMapProjectsTitle }}
                        </h1>
                        <h1 class="" v-if="documentTabopen">
                            Document management
                        </h1>
                    </b-col>
                </b-row>
                <b-card no-body>
                    <b-tabs
                        class="tabs portalTabs pt-4 m-pt-15"
                        active-nav-item-class=" pl-0 pr-0 borderBlue"
                        v-on:changed="activateTab"
                        v-model="step"
                        v-on:activate-tab="tabActivated"
                    >
                        <b-tab
                            title="Project map"
                            v-if="showProjects"
                            @click="showDoc('Projects selected')"
                            lazy
                        >
                            <b-container
                                class="w-100 containerPadding w-h-100"
                                s
                            >
                                <div class="w-100">
                                    <!-- hideSearch was a variable, but it never changed, and was just hardcoded false -->
                                    <portal-bar
                                        :hideSearch="false"
                                        :documentUpload="documentUpload"
                                        :markerSettings="
                                            markerSettings[0].dataSource.length
                                        "
                                        @clicked="onClickOpenSelection"
                                        @ThreeDVisDraw="start3DVisDraw"
                                    ></portal-bar>

                                    <div class="selectMap bottom-m-100">
                                        <div class="w-100">
                                            <div class="select-map">
                                                <div v-if="loading">
                                                    <div
                                                        class="spinner-border loadingIcon"
                                                        role="status"
                                                    >
                                                        <span class="sr-only"
                                                            >Loading...</span
                                                        >
                                                    </div>
                                                    <div class="loadingText">
                                                        Please wait...
                                                    </div>
                                                </div>
                                                <map-container
                                                    ref="mapcontainer"
                                                    v-bind:showMap="true"
                                                    @threeDVisDrawGeom="
                                                        save3DVisDrawGeom
                                                    "
                                                ></map-container>
                                                <div
                                                    class="mapToolAttribute"
                                                    id="closepopup"
                                                >
                                                    <div class="pl-2 pt-2">
                                                        <h6
                                                            class="d-inline text-center"
                                                        >
                                                            <small
                                                                id="projectNamePopup"
                                                            ></small>
                                                        </h6>
                                                        <span
                                                            class="float-right mr-3"
                                                            @click="closePopup"
                                                        >
                                                            <a class="p-0"
                                                                ><img
                                                                    src="../../assets/close.svg"
                                                            /></a>
                                                        </span>
                                                    </div>
                                                    <table
                                                        class="table table-striped table-sm mt-3"
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td>State</td>
                                                                <td>
                                                                    <span
                                                                        id="stateNamePopup"
                                                                    ></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>City</td>
                                                                <td>
                                                                    <span
                                                                        id="cityNamePopup"
                                                                    ></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Country</td>
                                                                <td>
                                                                    <span
                                                                        id="countryNamePopup"
                                                                    ></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>XCoord</td>
                                                                <td>
                                                                    <span
                                                                        id="latPopup"
                                                                    ></span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>YCoord</td>
                                                                <td>
                                                                    <span
                                                                        id="longPopup"
                                                                    ></span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-container>
                        </b-tab>
                        <b-tab
                            v-for="z in browseFilesTab"
                            :key="'dyn-tab-' + z"
                            title="Browse files"
                            @click="showDoc('Browse files')"
                            active
                        >
                            <template #title>
                                File manager
                                <img
                                    src="../../assets/close.svg"
                                    class=""
                                    @click="closeTabBrowse(z)"
                                />
                            </template>

                            <!-- <div class="control-section file-overview">
         <div class="sample-container">
            <ejs-filemanager id="overview_file" :ajaxSettings='ajaxSettings' :view='view' >
            </ejs-filemanager>
           
        </div>
          </div> -->
                            <browse-files
                                :getProject="getProject"
                                :tabIndex="z"
                                class="m-browse"
                            ></browse-files>
                            <!-- <template>
                  <div id="app">
                    <ejs-filemanager
                      id="file-manager"
                      ref="file_instance"
                      :contextMenuSettings="contextMenuSettings"
                      :ajaxSettings="ajaxSettings"
                      :menuClick="menuClick"
                      :beforeSend="beforeSend"
                    >
                    </ejs-filemanager>
                  </div>
                </template>
              </template> -->
                        </b-tab>

                        <div class="successToastMsg" v-if="successToast">
                            <img
                                src="../../assets/success_tick.svg"
                                class="mr-3"
                            />
                            Success <br />
                            Attributes added successfully
                            <img
                                src="../../assets/close_big.svg"
                                class="ml-3"
                                @click="closeSuccessToast"
                            />
                        </div>
                        <div class="failureToastMsg" v-if="failureToast">
                            <img
                                src="../../assets/failure_cross.svg"
                                class="mr-3"
                            />
                            Failure <br />
                            Something went wrong.Please try again later.
                            <img
                                src="../../assets/close_big.svg"
                                class="ml-3"
                                @click="closeFailureToast"
                            />
                        </div>
                        <div class="failureToastMsg" v-if="invalidToast">
                            <img
                                src="../../assets/failure_cross.svg"
                                class="mr-3"
                            />
                            Invalid <br />
                            This Project has no Directory.Please try again
                            later.
                            <img
                                src="../../assets/close_big.svg"
                                class="ml-3"
                                @click="closeInvalidToast"
                            />
                        </div>
                        <!--- browse files tab -->
                        <!-- upload doucment Tab -->
                        <b-tab
                            v-for="z in documentTab"
                            :key="'dyn-tab-' + z"
                            title="Upload document"
                            @click="showDoc('Upload document')"
                            active
                        >
                            <!-- <img
                src="../../assets/close.svg"
                class=""
                @click="closeTabD(z)"
                style="position: relative; top: -31px; left: 295px"
              /> -->
                            <template #title>
                                Upload document
                                <img
                                    src="../../assets/close.svg"
                                    @click="closeTabD(z)"
                                />
                            </template>

                            <document-upload
                                :showdocument="showdocument"
                                @closeTabD="closeTabD(z)"
                            ></document-upload>
                        </b-tab>
                        <!--upload directory Tab-->
                        <b-tab
                            v-for="z in documentDirecroyTab"
                            :key="'dyn-tab-' + z"
                            title="Upload directory"
                            @click="
                                showDoc('Upload directory');
                                getDirIndex(z);
                            "
                            active
                        >
                            <!-- <template>
                <img
                  src="../../assets/close.svg"
                  class="mt-2"
                  @click="closeTabDirectory(z)"
                  style="position: relative; top: -31px; left: 282px"
                /> -->
                            <template #title>
                                Upload directory
                                <img
                                    src="../../assets/close.svg"
                                    @click="closeTabDirectory(z)"
                                />
                            </template>
                            <directory-upload
                                :showdirectory="showdirectory"
                                :tabIndex="z"
                                @getFileDetails="getFileDetails"
                                :fileDetailsCumulative="fileDetailsCumulative"
                                :documentDirecroyTab="documentDirecroyTab"
                                :changedTabIndex="dirIndex"
                                :dirTabClickType="dirTabClickType"
                            ></directory-upload>
                        </b-tab>
                        <!-- Manage attribute -->

                        <b-tab
                            v-for="i in manageAttrTab"
                            :key="'manageAttrTab' + i"
                            @click="
                                selectManageAttribute,
                                    showDoc('Manage attribute')
                            "
                            title="Manage attribute"
                            active
                        >
                            <template #title>
                                Clone directory
                                <img
                                    src="../../assets/close.svg"
                                    @click="closeManageAttrTab(i)"
                                />
                            </template>

                            <manage-attributes
                                :projectSelected="projectSelected"
                                :tabIndexClone="i"
                                :selectedProjectList="selectedProjectList"
                                :documentTabopen="documentTabopen"
                                @closeManageAttrTab="closeManageAttrTab(i)"
                            ></manage-attributes>
                        </b-tab>

                        <b-tab
                            v-for="(i, index) in PowerBIReports"
                            :key="index"
                            title="PowerBI"
                            active
                        >
                            <template #title>
                                PowerBI Report
                                <img
                                    src="../../assets/close.svg"
                                    @click="removePowerBIReports(i)"
                                />
                            </template>
                            <PowerBI :key="index"></PowerBI>
                        </b-tab>

                        <b-tab
                            v-for="i in historyTabs"
                            :key="'historyTabs' + i"
                            title="History"
                            active
                        >
                            <template #title>
                                History
                                <img
                                    src="../../assets/close.svg"
                                    @click="removeHistoryTable(i)"
                                />
                            </template>
                            <load-history></load-history>
                        </b-tab>

                        <b-tab
                            v-for="i in savedWorkTabs"
                            :key="'savedWorkTabs' + i"
                            title="Saved Work"
                            active
                        >
                            <template #title>
                                Saved Work
                                <img
                                    src="../../assets/close.svg"
                                    @click="removeSavedWorkTab(i)"
                                />
                            </template>
                            <saved-work></saved-work>
                        </b-tab>
                    </b-tabs>
                </b-card>
                <!-- <b-row class="pt-2">
          <div class="borderBlue mt-3 ml-4">
            <h5>Projects</h5>
          </div>
        </b-row>-->
                <b-modal
                    ref="projects-modal"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideReviewModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select which project documents to manage.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in markerSettings[0].dataSource"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="
                                                    projects.element.ProjectID
                                                "
                                                v-model="selectedRadio"
                                            >
                                            </b-form-radio
                                            ><span>{{ projects.name }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a @click="hideReviewModal" class="text-primary"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="selectedRadio <= 0"
                                @click="uplodDocument"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    ref="projects-modal-3dvis"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideReviewModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select which project to add a 3D visualization
                            model.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in markerSettings[0].dataSource"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="
                                                    projects.element.ProjectID
                                                "
                                                v-model="userSelectedProjectID"
                                            >
                                            </b-form-radio
                                            ><span>{{ projects.name }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a @click="hideReviewModal" class="text-primary"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="userSelectedProjectID == null"
                                @click="selectWhichProjectToOperateOn"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    ref="browse-modal"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideBrowseFilesModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select which project documents to manage.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in markerSettings[0].dataSource"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="
                                                    projects.element.ProjectID
                                                "
                                                v-model="selectedRadio"
                                            >
                                            </b-form-radio
                                            ><span>{{ projects.name }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a
                                @click="hideBrowseFilesModal"
                                class="text-primary"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="selectedRadio <= 0"
                                @click="openBrowse"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    ref="directory-modal"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideDirectoryModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select which project documents to manage.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in markerSettings[0].dataSource"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="
                                                    projects.element.ProjectID
                                                "
                                                v-model="selectedRadio"
                                            >
                                            </b-form-radio
                                            ><span>{{ projects.name }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a @click="hideDirectoryModal" class="text-primary"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="selectedRadio <= 0"
                                @click="displayDirectory"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    ref="cloneDirectory-modal"
                    class="model_style manage-atr"
                    hide-header
                    hide-footer
                >
                    <div class="d-block text-center">
                        <div class="text-right cursor-pointer">
                            <img
                                src="../../assets/close_icon.svg"
                                @click="hideCloneDirectoryModal"
                            />
                        </div>
                        <h5 class="mt-2">
                            Select which project documents to manage.
                        </h5>

                        <div class="my-3 upload-doc">
                            <table class="table table-striped">
                                <tbody>
                                    <tr
                                        v-for="(projects,
                                        index) in markerSettings[0].dataSource"
                                        :key="index"
                                    >
                                        <td class="d-flex">
                                            <b-form-radio
                                                class="mt-1"
                                                :value="
                                                    projects.element.ProjectID
                                                "
                                                v-model="selectedRadio"
                                            >
                                            </b-form-radio
                                            ><span>{{ projects.name }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="mt-3 mb-4 delModal">
                            <a
                                @click="hideCloneDirectoryModal"
                                class="text-primary"
                                >Cancel</a
                            >
                            <button
                                class="ml-3 applyButtonSelect"
                                :disabled="selectedRadio <= 0"
                                @click="displayCloneDirectory"
                            >
                                Proceed with selection
                            </button>
                        </div>
                    </div>
                </b-modal>
                <b-modal
                    size="lg"
                    id="save3d-vis-geom"
                    title="Submit 3D model"
                    ref="3D-modal"
                    class="p-1"
                    hide-header
                    hide-footer
                    @ok="submit3DVisModel"
                    @cancel="cancel3DVisModel"
                >
                    <div class="text-right cursor-pointer">
                        <img
                            class="pointer"
                            src="../../assets/close_icon.svg"
                            @click="close3Dmodal"
                        />
                    </div>
                    <h5 class="my-2 title text-center">
                        Upload 3D visualization
                    </h5>

                    <!-- make this modal and buttons/entry boxes in it prettier  -->
                    <div class="manage-form threeDvis-modal">
                        <div class="modal-content">
                            <CustomFloatingLabel
                                :config="{
                                    label: 'Name',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                                v-bind:label="floatLabelAdd"
                            >
                                <input
                                    id="threeDVisName"
                                    ref="threeDVisName"
                                    autocomplete="off"
                                    type="text"
                                    v-model="threeDVisName"
                                    class="normal_input"
                                    min="0"
                                />
                            </CustomFloatingLabel>
                            <CustomFloatingLabel
                                :config="{
                                    label: 'Description',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                                v-bind:label="floatLabelAdd"
                            >
                                <input
                                    id="threeDVisDescription"
                                    ref="threeDVisDescription"
                                    autocomplete="off"
                                    type="text"
                                    v-model="threeDVisDescription"
                                />
                            </CustomFloatingLabel>
                            <b-form-file
                                accept=".ctws"
                                class="mb-1 text-start"
                                id="new3DModelUpload"
                                name="new3DModelUpload"
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                            ></b-form-file>
                            <p class="mb-4 ml-2 text-left text-secondary">
                                *Accepted file type: .ctws only
                            </p>

                            <div class="d-flex justify-content-end">
                                <b-button
                                    class="cancelButton mr-2"
                                    @click="cancel3DVisModel"
                                >
                                    Cancel </b-button
                                ><b-button @click="submit3DVisModel">
                                    Submit
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </b-container>
        </b-container>
        <AccessPermissionModal ref="permission-modal" />
        <div ref="popup" v-show="mapPopupVisible">
            <table class="table table-striped table-sm mt-3">
                <tbody>
                    <tr
                        v-for="item in clickedGeometry.properties"
                        :key="item.name"
                        v-show="item.popup"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                    <tr v-show="clickedGeometry.hasFile">
                        <td colspan="2">
                            <div
                                v-for="(item, index) in clickedGeometry.files"
                                :key="item.fileName"
                            >
                                <a @click="openDocument(index, item)">
                                    <!-- v-if statements to handle correct class by type -->
                                    <span
                                        v-if="item.source === 'Web'"
                                        class="WebType"
                                    >
                                        {{ item.fileName }}
                                    </span>
                                    <span
                                        v-else-if="item.source === 'Blob'"
                                        class="BlobType"
                                    >
                                        {{ item.fileName }}
                                    </span>
                                    <span v-else>
                                        {{ item.fileName }}
                                    </span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <toast-messages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
        <dashboard />
    </div>
</template>

<script>
import router from '../../router';
// import draggable from "vuedraggable";
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue';
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { getUniqueID } from '@syncfusion/ej2-base';
import { directoryService } from '../../services/directory.service';
import ToastMessages from '../ToastMessages.vue';
//import { projectService } from "../../services/project.service";
Vue.use(UploaderPlugin);
import CustomFloatingLabel from '../CustomFloatingLabel';
import { projectService } from '../../services/project.service';
//import { config } from "../../../config";
import {
    FileManagerPlugin,
    NavigationPane,
    Toolbar,
    DetailsView,
} from '@syncfusion/ej2-vue-filemanager';
import DirectoryUpload from './DirectoryUpload';
import dashboard from '../../views/Dashboard';
import AccessPermissionModal from '../AccessPermissionModal';
import MapContainer from './MapContainer.vue';
import PowerBI from '../PowerBI/PowerBI';
import LoadHistory from './LoadHistory.vue';
import SavedWork from './SavedWork.vue';

Vue.use(FileManagerPlugin);
// let hostUrl = "http://localhost:54654/";
// import {
//   MapsPlugin,
//   Bubble,
//   Zoom,
//   MapsTooltip,
//   Marker,
// } from "@syncfusion/ej2-vue-maps";
import ManageAttributes from '../projects/ManageAttributes';
import BrowseFiles from './BrowseFiles.vue';
import PortalBar from '../PortalBar.vue';
import DocumentUpload from './DocumentUpload.vue';
import { commonService } from '../../services/common.service';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import { userService } from '../../services/users.service';
import { mapLayerFunctions } from '../../utilities/mapLayerFunctions';
// import PostedLayerEdit from "./postedLayers/EditPostedLayer.vue"
Vue.use(FileManagerPlugin);

import { circleMarker, Icon, latLngBounds, divIcon, marker } from 'leaflet';
//fix the leaflet marker issue
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
    name: 'Projects',
    components: {
        CustomFloatingLabel,
        DirectoryUpload,
        dashboard,
        AccessPermissionModal,
        PortalBar,
        ManageAttributes,
        BrowseFiles,
        DocumentUpload,
        MapContainer,
        PowerBI,
        LoadHistory,
        SavedWork,
        ToastMessages,
    },
    provide: {
        filemanager: [NavigationPane, DetailsView, Toolbar],
    },
    props: ['btnText'],
    created() {
        this.getAllSelectedProjects();
        //  this.getDirectory();
        // this.getDirectories();
        // this.getProjectlist();
        this.$root.$refs.A = this;
        this.newSelectionTab();
    },
    data() {
        return {
            //project layer data, remove after api call is completed
            expandedMapBounds: null,
            mapPopupVisible: false,
            clickedGeometry: { properties: [], hasFile: false, files: [] },
            projectDataLayers: [],
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 8,
            baseMaps: [
                {
                    name: 'OpenStreetMap',
                    visible: false,
                    attribution:
                        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                    name: 'Satellite_Imagery',
                    visible: false,
                    url:
                        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'NoMap',
                    visible: false,
                    url: '',
                    attribution: '',
                },
            ],
            wmsLayers: [],
            childSelected: [],
            clusterOptions: {
                disableClusteringAtZoom: 20,
                showCoverageOnHover: false,
                chunkedLoading: true,
            },
            validateUpload: true,
            editFreeTextContent: false,
            searchText: true,
            showText: false,
            proList: [],
            projectSelected: true,
            filledBox: true,
            testName: '',
            newName: '',
            viewButton: false,
            openNewAssign: false,
            dirArray: [],
            successToast: false,
            failureToast: false,
            invalidToast: false,
            uploadToast: false,
            noLayers: false,
            failureToastClone: false,
            successToastClone: false,
            assignedTextValue: '',
            newAttribute: {
                directoryItem: '',
                attributeAssignedName: '',
                attributeName: '',
            },
            assignedValue: false,
            addAttributeArray: [],
            directoryList: [],
            myDirectory: '',
            selectedDirectory: [],
            openProjectSection: true,
            saveManageAttribute: false,
            saveAssignedAttribute: false,
            documentUpload: false,
            saveCloneDirectory: false,
            createDirectoryTab: true,
            attributeAssignedName: '',
            firstTextValue: true,
            isDragging: false,
            editable: true,
            attributeName: '',
            attributeItems: [],
            directoryCollection: [],
            attributeCollection: [],
            clicked: false,
            clicked2: false,
            undragPage: [0],
            directoryCounter: 0,
            createDirectoryCounter: 0,
            pushDirectory: [{ value: '' }],
            enterAttribute: [{ value: '' }],
            assignedItemsArray: [{ value: '' }],
            directoryItems: null,
            firstPage: true,
            validateInput: [],
            buttonStatus: false,
            showPlusIcon: true,
            showMinusIcon: false,
            freeText: '',
            submitSelectedDirectory: false,
            cloneSelected: true,
            allSelected: false,
            editAssignedAttribute: false,
            appliedAttribute: false,
            noDirectory: true,
            newDirectory: true,
            mapExtend: false,
            addAtrributeButton: false,
            oldAttributeButton: true,
            projectDirectoryList: [],
            projectListName: [],
            projLayerActive: false,
            layerType: 'OSM',
            urlTemplate:
                'https://tiles.wmflabs.org/bw-mapnik/level/tileX/tileY.png',
            centerPosition: {
                latitude: 40.7209,
                longitude: -73.968,
            },
            center: [49.28, -122.99],
            mapBounds: null,
            animationDelay: 0,
            zoomSettings: {
                zoomFactor: 2,
                maxZoom: 21,
                enable: true,
                pinchZooming: true,
                enablePanning: true,
                mouseWheelZoom: true,
            },

            uploadedFiles: [
                {
                    name: '',
                    description: '',
                    keyword: '',
                    directory: '',
                    AttributeName: '',
                },
            ],
            reportingTool: false,
            currentSwitchState: false,
            documentTab: [],
            documentTabCounter: 0,
            documentDirecroyTab: [],
            documentDirectoryTabCounter: 0,
            documentTabopen: false,
            nextscreen: false,
            directories: [],
            projectList: [],
            path: {
                //saveUrl: 'http://localhost:54654/api/Documents',
                removeUrl:
                    'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
            },
            autoUpload: false,
            directory: true,
            manageAttrTab: [],
            manageAttrTabCounter: 0,
            selected: null,
            saveButton: false,
            results: [],
            files: [],
            attributes: '',
            description: '',
            browseFilesTab: [],
            browseFilesTabCounter: 0,
            ajaxSettings: {
                url:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureFileOperations',
                getImageUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureGetImage',
                uploadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureUpload',
                downloadUrl:
                    process.env.VUE_APP_DOCUMENT_API_URI +
                    'AzureProvider/AzureDownload',
            },
            contextMenuSettings: {
                file: [
                    'Manage attribute',
                    'Open',
                    '|',
                    'Delete',
                    'Rename',
                    '|',
                    'Details',
                ],
                folder: [
                    'Manage attribute',
                    'Open',
                    '|',
                    'Delete',
                    'Rename',
                    '|',
                    'Details',
                ],
                layout: [
                    'Manage attribute',
                    'SortBy',
                    'View',
                    'Refresh',
                    '|',
                    'NewFolder',
                    'Upload',
                    '|',
                    'Details',
                    '|',
                    'SelectAll',
                ],
                visible: true,
            },
            view: 'Details',
            selectedRadio: 0,
            searchQuery: null,
            showPage: true,
            thisfilename: null,
            listCheckedOptions: [],
            showProjects: true,
            step: 0,
            showdocument: false,
            showdirectory: false,
            dirIndex: '',
            dirTabClickType: true,
            mapClass: 'selectedProj-map-box',
            loading: false,
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
            // adding this since there are 2 scale bars, can change them both here
            scalebarUnits: 'both', // options are 'metric','imperial','both'
            PowerBIReports: [],
            historyTabs: [],
            savedWorkTabs: [],
            downloadDoc: false,
            powerBILoading: false,
            threeDVisDescription: '',
            threeDVisGeom: '',
            threeDVisName: '',
            userSelectedProjectID: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
        };
    },
    mounted() {
        if (this.$store.state.projectLayers.projects.length === 0) {
            this.loading = true;
        }
        this.getProjectLayerData();
    },
    beforeDestroy() {
        localStorage.removeItem('toolsdata');
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        modalClose() {
            this.$bvModal.hide('modal-screen');
            this.$store.commit('projects/setMapExpandedOff');
        },
        close3Dmodal() {
            this.$refs['3D-modal'].hide();
        },
        getProjectLayerData() {
            //API call
            let projectIDs = [];
            this.markerSettings[0].dataSource.forEach((item) => {
                projectIDs.push(item.element.ProjectID);
                localStorage.setItem('projectId', item.element.ProjectID);
            });

            //can ProjectName be used as unique identifier? better to use projectID
            let projectNamesSelected = this.$store.state.projects.selectedProjects.map(
                (proj) => {
                    return proj.ProjectName;
                }
            );
            let projectNamesInMapData = this.$store.state.projectLayers.projects.map(
                (proj) => {
                    return proj.ProjectName;
                }
            );
            //check if any project in Selected array, but not in mapData array
            let allSelectedProjectInMapData = projectNamesSelected.every(
                (proj) => {
                    return projectNamesInMapData.includes(proj);
                }
            );
            //combine with getProjectLayersFromApi local storage item, which is set by router
            //getProjectLayersFromApi will decide whether to run the api call based on not all selected projects are in store, OR when navigating from mapview to selectedprojects
            let getProjectLayersFromApi =
                !allSelectedProjectInMapData ||
                JSON.parse(localStorage.getItem('getProjectLayersFromApi'));

            if (projectIDs.length > 0 && getProjectLayersFromApi) {
                mapLayerFunctions.getSpatialLayers(projectIDs.toString());
            } else {
                this.loading = false;
            }
        },
        addPowerBIReports() {
            this.powerBILoading = true;
            this.PowerBIReports.push(1);
            setTimeout(() => {
                this.step =
                    this.browseFilesTab.length +
                    this.documentTab.length +
                    this.documentDirecroyTab.length +
                    this.manageAttrTab.length +
                    this.PowerBIReports.length;
                this.powerBILoading = false;
            });
        },
        removePowerBIReports(i) {
            this.PowerBIReports.splice(0, 1);
        },
        addHistoryTable() {
            this.historyTabs.push(1);
            setTimeout(() => {
                this.step =
                    this.browseFilesTab.length +
                    this.documentTab.length +
                    this.documentDirecroyTab.length +
                    this.manageAttrTab.length +
                    this.PowerBIReports.length +
                    this.historyTabs.length;
            });
        },
        addSavedWork() {
            this.savedWorkTabs.push(1);
            setTimeout(() => {
                this.step =
                    this.browseFilesTab.length +
                    this.documentTab.length +
                    this.documentDirecroyTab.length +
                    this.manageAttrTab.length +
                    this.PowerBIReports.length +
                    this.historyTabs.length +
                    this.savedWorkTabs.length;
            });
        },
        removeHistoryTable(i) {
            this.historyTabs.splice(0, 1);
        },
        removeSavedWorkTab() {
            this.savedWorkTabs.splice(0, 1);
        },
        pagePermit() {
            this.showPage = true;
            this.showdocument = true;
            this.showdirectory = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('SelectedProjects')) {
                    vm.showPage = true;
                }
                if (element.PageName.includes('uploaddocument')) {
                    vm.showdocument = true;
                }
                if (element.PageName.includes('DirectoryUpload')) {
                    vm.showdirectory = true;
                }
            });
            // this.$emit('clickedUserList', this.showPage);
            if (!this.showPage) {
                vm.$refs['permission-modal'].showPermissionModal();
            }
        },
        getDirIndex(dirIndex) {
            this.dirIndex = dirIndex;
            this.dirTabClickType = false;
            localStorage.setItem('indexToRemove', dirIndex);
            // this.tabIndexSwitched = dirIndex;
        },
        getFileDetails(value) {
            this.fileDetailsCumulative = value;
        },
        getdirectoryFileDetails() {
            return this.fileDetailsCumulative;
        },
        showHeader(value) {
            this.showHeader = value;
        },
        // Icon added to custom menu item in menuOpen event
        newSelectionTab() {
            if (this.$route.name == 'manageattributes') {
                this.manageAttrTab.push(this.manageAttrTabCounter++);
                this.showProjects = false;
            } else if (this.$route.name == 'browsefiles') {
                this.browseFilesTab.push(this.browseFilesTabCounter++);
                this.showProjects = false;
            } else if (this.$route.name == 'DirectoryUpload') {
                this.documentDirecroyTab.push(
                    this.documentDirectoryTabCounter++
                );
                this.showProjects = false;
            } else if (this.$route.name == 'documentupload') {
                this.documentTab.push(this.documentTabCounter++);
                this.showProjects = false;
            }
        },
        toggleProjectLayer(index) {
            this.$store.dispatch(
                'projectLayers/updateProjectVisibility',
                index
            );
        },
        // Icon added to custom menu item in menuOpen event
        toggleProjectLayerLabels(index, idx) {
            this.$store.dispatch('projectLayers/toggleProjectLayerLabels', {
                index,
                idx,
            });
        },
        togglePostedLayerLabels(index) {
            this.$store.dispatch('projects/togglePostedLayerLabels', index);
        },
        togglePostedLayer(index) {
            this.$store.dispatch('projects/updatePostedVisibility', index);
        },
        refreshFiles(id) {},

        showDoc(getTab) {
            getTab == 'Upload document'
                ? (this.documentTabopen = true)
                : (this.documentTabopen = false);
        },
        //  zoom:function(args) {
        //     if (args.tileZoomLevel.current >= args.maps.zoomSettings.maxZoom) {
        //       alert("you have reached the maximum zoom");
        //     }
        // },
        manageAttribute(type) {
            this.currentSwitchState = type;
            if (this.markerSettings[0].dataSource.length > 1) {
                this.$refs['cloneDirectory-modal'].show();
            } else {
                localStorage.setItem(
                    'directoryCloneProjectID',
                    this.markerSettings[0].dataSource[0].element.ProjectID
                );
                if (this.currentSwitchState) {
                    // let routeData = this.$router.push({ name: "manageattributes", params: {currentSwitchState: this.currentSwitchState} });
                    let routeData = this.$router.resolve({
                        name: 'manageattributes',
                        params: { id: 5 },
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    this.manageAttrTab.push(this.manageAttrTabCounter++);
                    setTimeout(() => {
                        this.step =
                            this.browseFilesTab.length +
                            this.documentTab.length +
                            this.documentDirecroyTab.length +
                            this.manageAttrTab.length;
                    });
                    this.documentTabopen = false;
                }
            }
        },
        displayCloneDirectory() {
            this.$refs['cloneDirectory-modal'].hide();
            localStorage.setItem('directoryCloneProjectID', this.selectedRadio);
            if (this.currentSwitchState) {
                // let routeData = this.$router.push({ name: "manageattributes", params: {currentSwitchState: this.currentSwitchState} });
                let routeData = this.$router.resolve({
                    name: 'manageattributes',
                    params: { id: 5 },
                });
                window.open(routeData.href, '_blank');
            } else {
                this.manageAttrTab.push(this.manageAttrTabCounter++);
                setTimeout(() => {
                    this.step =
                        this.browseFilesTab.length +
                        this.documentTab.length +
                        this.documentDirecroyTab.length +
                        this.manageAttrTab.length;
                });
                this.documentTabopen = false;
            }
        },
        hideCloneDirectoryModal() {
            this.$refs['cloneDirectory-modal'].hide();
        },
        //  selectManageAttribute() {
        //     this.saveManageAttribute = true;
        //     this.openProjectSection = false;
        //   },

        onCheck() {
            let vm = this;

            vm.directories.filter((item) => {
                if (item.ProjectId == vm.selectedProjectList) {
                    if (item.ChildDirectories.length == 0) {
                        vm.invalidToast = true;
                        vm.cloneSelected = false;
                    } else {
                        directoryService
                            .getDirectory(vm.selectedProjectList)
                            .then((res) => {
                                vm.projectDirectoryList = res.data;
                            });
                        vm.cloneSelected = false;
                    }
                } else {
                    //nothing
                }
            });
        },

        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchText() {
            this.searchText = true;
            this.showText = false;
        },

        menuOpen: function() {},
        // Displaying alert for custom menu in menuClick event
        menuClick: function(args) {
            //
            this.testName = args.fileDetails[0].name;

            if (args.item.text === 'Manage attribute') {
                this.$refs['attribute-modal'].show();
                this.appliedAttribute = false;
                this.newDirectory = true;
                this.attributeAssignedName = null;
                this.attributeName = null;
                this.assignedValue = false;
                this.firstTextValue = true;
                this.clicked = false;
                this.clicked2 = false;
            }
        },

        // manage Attribute
        addNewAttribute() {
            this.viewButton = true;
            this.oldAttributeButton = true;
            this.newDirectory = true;
            // this.oldAttributeButton = false;
            // this.addAtrributeButton = true;
            this.directoryItems = null;
            this.attributeAssignedName = '';
            this.attributeName = '';
            this.appliedAttribute = false;
        },
        saveAttribute() {
            const data = {
                AttributeName: this.firstTextValue
                    ? this.attributeName
                    : this.attributeAssignedName,
                DirectoryID: 1,

                isFreeText: this.firstTextValue ? true : false,
                isMultiSelect: this.assignedValue ? true : false,
                AttributeValue: [
                    {
                        AttributeValueText: 'test123',
                    },
                ],
            };
            // this.firstTextValue;

            projectService
                .createDirectory(data)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.successToast = true;
                        setTimeout(() => {
                            this.successToast = false;
                        }, 5000);
                    }
                })

                .catch((err) => {
                    if (err) {
                        this.failureToast = true;
                        setTimeout(() => {
                            this.failureToast = false;
                        }, 5000);
                    }
                });
            this.$refs['attribute-modal'].hide();
        },

        saveClone() {
            const id = this.selectedProjectList;
            const dirName = this.directoryList.toString();
            let cloneData = [];
            this.selectedDirectory.forEach((dirItem) => {
                this.directoryList.find((subItem) => {
                    return subItem.DirectoryName == dirItem;
                });

                cloneData.push({
                    DirectoryName: dirItem,
                    ProjectId: id,
                    DirectoryParentID: 4,
                });
            });
            projectService
                .cloneDirectory(cloneData)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.successToast = true;
                        setTimeout(() => {
                            this.successToast = false;
                        }, 5000);
                    }
                })

                .catch((err) => {
                    if (err) {
                        this.failureToast = true;
                        setTimeout(() => {
                            this.failureToast = false;
                        }, 5000);
                    }
                });
        },
        checkedProject(id) {
            if (this.directoryList.indexOf(id) > -1) {
                this.directoryList.splice(this.directoryList.indexOf(id), 1);
                this.allSelected = false;
            } else {
                this.directoryList.push(id);
            }
        },
        closeSuccessToast() {
            this.successToast = false;
        },
        closeFailureToast() {
            this.failureToast = false;
        },
        closeInvalidToast() {
            this.invalidToast = false;
        },
        selectManageAttribute() {
            this.saveManageAttribute = true;
            this.openProjectSection = false;
        },
        mouseover(id) {
            if (this.selectedProjectList == id) {
                this.cloneSelected = true;
            } else if (this.selectedProjectList == null) {
                alert('wrong');
            }
        },
        // mouseleave() {
        //
        //   this.submitSelectedDirectory = true;
        //   this.cloneSelected = false;
        //   this.directoryList.push(this.selectedDirectory);
        // },
        mouseleave() {
            //
            this.submitSelectedDirectory = true;
            this.cloneSelected = false;
            this.projectSelected = false;

            var directoryIndex;

            this.selectedDirectory.forEach((dirInd) => {
                directoryIndex = this.projectDirectoryList.find((item) => {
                    return item.DirectoryName == dirInd;
                });
                // this.dirArray.push(directoryIndex);
                this.dirArray.push(directoryIndex);
            });

            this.directoryList.push(this.selectedDirectory);
        },
        openProject() {
            this.openProjectSection = true;
        },
        cancelEdit() {
            this.appliedAttribute = true;
            this.editFreeTextContent = false;
        },

        showContent() {
            this.showMinusIcon = true;
            this.showPlusIcon = false;
        },
        addDirectory() {
            this.pushDirectory.push({ value: '' });
            // if (this.buttonStatus == true) {
            //   this.pushDirectory.push(this.directoryCounter++);
            // } else if (this.undragPage == true) {
            //   this.enterDirectory.push(this.createDirectoryCounter++);
            // }
        },
        addAttribute() {
            this.enterAttribute.push({ value: '' });
        },
        deleteDirectory(index) {
            this.pushDirectory.splice(index);
            // this.buttonStatus=false;
        },
        deleteAttribute(index) {
            this.enterAttribute.splice(index);
        },
        activeAssignedvalue() {
            this.clicked = false;
            this.clicked2 = true;
            this.assignedValue = true;
            this.firstTextValue = false;
            this.viewButton = true;
        },
        activeFreeText() {
            this.clicked = true;
            this.clicked2 = false;
            this.assignedValue = false;
            this.firstTextValue = true;
            this.viewButton = true;
        },
        deleteFirst() {
            (this.undragPage = true), (this.buttonStatus = false);
            this.directoryItems = '';
        },
        handleBlur() {
            (this.undragPage = true), (this.buttonStatus = true);
        },
        handleClone() {
            this.submitSelectedDirectory = true;
            // this.cloneSelected = false;
        },
        createDirectory() {
            this.createDirectoryTab = true;
            this.noDirectory = false;
            // this.undragPage=true;
        },
        tabSelected() {
            this.freeText = false;
        },
        mapExtendSize() {
            this.mapExtend = !this.mapExtend;
            if (this.mapExtend == true) {
                this.mapClass = 'selectedProj-map-box-expanded';
            }
            setTimeout(() => {
                this.$refs.lmap1.mapObject.invalidateSize();
            }, 100);
        },
        editAttribute() {
            this.newDirectory = true;
            this.appliedAttribute = false;
            this.editAssignedAttribute = false;
            this.assignedValue = false;
            this.firstTextValue = false;
            this.clicked = false;
            this.clicked2 = false;
        },
        editFreeText() {
            this.editFreeTextContent = true;
            this.appliedAttribute = false;
            this.clicked = true;
            this.clicked2 = false;
        },
        editAssignAttribute() {
            this.editAssignedAttribute = true;
            this.appliedAttribute = false;
            this.clicked = false;
            this.clicked2 = true;
        },
        showChanges() {
            this.editAssignedAttribute = false;
            this.appliedAttribute = true;
            this.editFreeTextContent = false;
        },
        submitDirectory() {
            this.submitSelectedDirectory = true;
            this.cloneSelected = false;
        },

        saveDirectory() {
            this.directoryCollection.push(this.directoryItems);
        },
        applyAttribute(args) {
            //  this.testName = args.fileDetails[0].name;
            this.newName = args;

            // this.testName = ""
            this.appliedAttribute = true;
            this.newDirectory = false;
            this.attributeCollection.push(this.attributeItems);
            this.addAttributeArray.push(this.addNewAttribute);
        },
        applyNewAttribute() {
            this.appliedAttribute = true;
            this.newDirectory = false;
            this.addAttributeArray.push(this.addNewAttribute);
        },

        openAttriuteDetails() {
            if (this.pushDirectory.length > 1) {
                this.noDirectory = false;
                this.newDirectory = true;
            } else {
                this.newDirectory = false;
                this.noDirectory = true;
            }
            this.saveAssignedAttribute = true;
            this.saveCloneDirectory = false;
        },
        openCloneDirectory() {
            this.saveCloneDirectory = true;
            this.saveAssignedAttribute = false;
        },
        //manage Attribute ended
        ...mapActions('projects', {
            selectProjects: 'selectProjects',
            getAllSelectedProjects: 'getSelectedProjects',
            clearSelectedProjects: 'clearSelectedProjects',
        }),

        navigateDashboard: function() {
            router.push(`/project/mapview`);
        },
        navigatetoSelectedProjects: function() {
            // this.clearSelectedProjects();
            router.push(`/project/selectedprojects`);
        },
        showModal() {
            this.newswitchState = this.currentSwitchState;
            if (this.markerSettings[0].dataSource.length > 1) {
                this.$refs['projects-modal'].show();
            } else {
                localStorage.setItem(
                    'documentProjectID',
                    this.markerSettings[0].dataSource[0].element.ProjectID
                );
                if (this.newswitchState) {
                    let routeData = this.$router.resolve({
                        name: 'documentupload',
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    this.documentTab.push(this.documentTabCounter++);
                    setTimeout(() => {
                        this.step =
                            this.browseFilesTab.length +
                            this.documentTab.length;
                    });
                    this.documentTabopen = true;
                }
            }
            this.selectedRadio = 0;
        },
        hideReviewModal() {
            this.$refs['projects-modal'].hide();
        },
        showModalBrowseFiles() {
            this.$refs['browse-modal'].show();
            this.selectedRadio = 0;
            this.newswitchState = this.currentSwitchState;
        },
        hideBrowseFilesModal() {
            this.$refs['browse-modal'].hide();
        },
        markerClick(args) {
            document.getElementById('closepopup').style.display = 'block';
            document.getElementById('projectNamePopup').innerText =
                args.data.name;
            document.getElementById('stateNamePopup').innerText =
                args.data.Province;
            document.getElementById('cityNamePopup').innerText = args.data.City;
            document.getElementById('countryNamePopup').innerText =
                args.data.CountryName;
            document.getElementById('latPopup').innerText = args.data.latitude;
            document.getElementById('longPopup').innerText =
                args.data.longitude;
        },
        closePopup() {
            document.getElementById('closepopup').style.display = 'none';
        },
        getBrowserType() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (
                (navigator.userAgent.indexOf('Edg') ||
                    navigator.userAgent.indexOf('Edge')) != -1
            ) {
                return 'Edge';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                //IF IE > 10
                return 'IE';
            } else {
                return 'unknown';
            }
        },
        print() {
            this.mapClass = 'selectedProj-map-box-print';
            setTimeout(function() {
                this.$refs.lmap.mapObject.invalidateSize();
            }, 500);
            const modal = document.getElementById('mapContainer');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');
            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }

            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            var userBrowser = this.getBrowserType();
            var topMargin = '0';
            var leftMargin = '0';
            switch (userBrowser) {
                case 'Chrome':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
                case 'Firefox':
                    topMargin = '0.5in';
                    leftMargin = '0';
                    break;
                case 'Edge':
                    topMargin = '0.1in';
                    leftMargin = '0.1in';
                    break;
            }
            css =
                '@page { size: landscape; } @media print {#mapContainer{position:absolute; height:7.5in; width:10in; top:' +
                topMargin +
                ';left:' +
                leftMargin +
                ';}';
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            setTimeout(function() {
                window.print();
            }, 500);
            window.onafterprint = () => {
                this.mapClass = 'selectedProj-map-box';
            };
            this.closeModal();
        },
        resizeModal() {
            // this.mapExtend = !this.mapExtend;
            this.toggleBasemap();
            this.expandedMapBounds = this.mapBounds;
            setTimeout(() => {
                this.$refs.lmap.mapObject.invalidateSize();
                this.$refs.lmap.mapObject.fitBounds(this.expandedMapBounds);
            }, 100);
        },

        // modalClose(){
        //   this.mapBounds = this.expandedMapBounds
        //   this.$bvModal.hide('modal-screen')
        //   //
        //   let basemaplayerID = Object.keys(this.$refs.lmap.mapObject._layers)[0]
        //
        //   let basemaplayer = this.$refs.lmap.mapObject._layers[basemaplayerID]._url
        //   if (basemaplayer.includes("Topo")){
        //     this.baseMaps[0].visible = false;
        //     this.baseMaps[1].visible = false;
        //     this.baseMaps[3].visible = false;
        //     this.baseMaps[2].visible = true;
        //   } else if (basemaplayer.includes("Imagery")){
        //     this.baseMaps[0].visible = false;
        //     this.baseMaps[2].visible = false;
        //     this.baseMaps[3].visible = false;
        //     this.baseMaps[1].visible = true;
        //   } else if (basemaplayer.includes("open")){
        //     this.baseMaps[2].visible = false;
        //     this.baseMaps[1].visible = false;
        //     this.baseMaps[3].visible = false;
        //     this.baseMaps[0].visible = true;
        //   } else { //no map
        //     this.baseMaps[0].visible = false;
        //     this.baseMaps[1].visible = false;
        //     this.baseMaps[2].visible = false;
        //     this.baseMaps[3].visible = true;
        //   }
        // },
        deleteDataSet(index, indexdataset) {
            this.titleBlock[index]['dataSet'].splice(indexdataset, 1);
        },
        openTableselectTool: function() {
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({
                    name: 'tableselectiontool',
                });
                window.open(routeData.href, '_blank');
            } else {
                router.push(`/tableselectiontool`);
            }
        },
        showReportingTool() {
            this.reportingTool = !this.reportingTool;
        },
        showDocumentMgmt() {
            this.documentUpload = !this.documentUpload;
        },
        closeReporting() {
            this.reportingTool = false;
        },
        closeDocumentupload() {
            this.documentUpload = false;
        },
        uplodDocument() {
            this.documentTabopen = true;
            this.documentUpload = !this.documentUpload;
            // this.documentTab.push(this.documentTabCounter++);
            this.hideReviewModal();
            localStorage.setItem('documentProjectID', this.selectedRadio);
            if (this.newswitchState) {
                let routeData = this.$router.resolve({
                    name: 'documentupload',
                });
                window.open(routeData.href, '_blank');
            } else {
                this.documentTab.push(this.documentTabCounter++);
                setTimeout(() => {
                    this.step =
                        this.browseFilesTab.length + this.documentTab.length;
                });
                this.documentTabopen = true;
            }
        },
        uplodDirectory(type) {
            this.documentUpload = !this.documentUpload;
            this.currentSwitchState = type;
            if (this.markerSettings[0].dataSource.length > 1) {
                this.$refs['directory-modal'].show();
            } else {
                localStorage.setItem(
                    'directoryProjectID',
                    this.markerSettings[0].dataSource[0].element.ProjectID
                );
                if (this.currentSwitchState) {
                    let routeData = this.$router.resolve({
                        name: 'DirectoryUpload',
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    this.documentDirecroyTab.push(
                        this.documentDirectoryTabCounter++
                    );
                    setTimeout(() => {
                        this.step =
                            this.browseFilesTab.length +
                            this.documentTab.length +
                            this.documentDirecroyTab.length;
                    });
                    this.documentTabopen = false;
                }
            }
            this.dirTabClickType = true;
            //this.selectedRadio = 0;
        },
        displayDirectory() {
            this.$refs['directory-modal'].hide();
            localStorage.setItem('directoryProjectID', this.selectedRadio);
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({
                    name: 'DirectoryUpload',
                });
                window.open(routeData.href, '_blank');
            } else {
                this.documentDirecroyTab.push(
                    this.documentDirectoryTabCounter++
                );
                setTimeout(() => {
                    this.step =
                        this.browseFilesTab.length +
                        this.documentTab.length +
                        this.documentDirecroyTab.length;
                });
                this.documentTabopen = false;
            }
        },
        hideDirectoryModal() {
            this.$refs['directory-modal'].hide();
        },
        // uplodDirectory() {
        //   this.documentUpload = !this.documentUpload;
        //   this.documentDirecroyTab.push(this.documentDirectoryTabCounter++);
        //   // if (this.currentSwitchState) {
        //   //   let routeData = this.$router.resolve({ name: "DirectoryUpload" });
        //   //   window.open(routeData.href, "_blank");
        //   // } else {
        //   //   router.push(`/DirectoryUpload`);
        //   // }
        // },
        closeTabD(y) {
            this.documentTabopen = false;
            this.datatool = false;
            for (let j = 0; j < this.documentTab.length; j++) {
                if (this.documentTab[j] === y) {
                    this.documentTab.splice(j, 1);
                }
            }
            this.invalidToast = false;
            this.nextscreen = false;
            this.saveButton = false;
            this.validateUpload = true;
        },
        closeTabBrowse(y) {
            for (let j = 0; j < this.browseFilesTab.length; j++) {
                if (this.browseFilesTab[j] === y) {
                    this.browseFilesTab.splice(j, 1);
                }
            }
            this.invalidToast = false;
        },
        closeTabDirectory(y) {
            this.datatool = false;
            for (let j = 0; j < this.documentDirecroyTab.length; j++) {
                if (this.documentDirecroyTab[j] === y) {
                    this.documentDirecroyTab.splice(j, 1);
                }
            }
            this.invalidToast = false;
        },
        closeManageAttrTab(x) {
            for (let i = 0; i < this.manageAttrTab.length; i++) {
                if (this.manageAttrTab[i] === x) {
                    this.manageAttrTab.splice(i, 1);
                }
            }
            this.invalidToast = false;
        },
        activateTab(currentTabs) {
            if (currentTabs.length === 2) {
                currentTabs[0].active = false;
                currentTabs[1].active = true;
            }
        },

        // browseFiles() {
        //   this.closeDocumentupload();
        //   this.browseFilesTab.push(this.browseFilesTabCounter++);

        //   setTimeout(function () {
        //     document
        //       .getElementById("file-manager")
        //       .ej2_instances[0].refreshLayout();
        //   }, 1500);
        //   //setTimeout(()=>this.$refs.file_instance.ej2Instances.refresh(),3000);
        // },
        browseFiles(type) {
            // this.closeDocumentupload();
            this.documentUpload = false;
            this.currentSwitchState = type;
            if (this.markerSettings[0].dataSource.length > 1) {
                this.$refs['browse-modal'].show();
            } else {
                setTimeout(function() {
                    document
                        .getElementById('file-manager')
                        .ej2_instances[0].refreshLayout();
                }, 1500);

                localStorage.setItem(
                    'browseProjectID',
                    this.markerSettings[0].dataSource[0].element.ProjectID
                );
                // this.currentSwitchState = type;

                if (this.currentSwitchState) {
                    let routeData = this.$router.resolve({
                        name: 'browsefiles',
                        params: { id: this.selectedRadio },
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    this.browseFilesTab.push(this.browseFilesTabCounter++);
                    setTimeout(() => {
                        this.step = this.browseFilesTab.length;
                    });
                    this.documentTabopen = false;
                }
            }

            //this.selectedRadio = 0;

            //this.newswitchState = this.currentSwitchState;

            //setTimeout(()=>this.$refs.file_instance.ej2Instances.refresh(),3000);
        },
        openBrowse() {
            this.$refs['browse-modal'].hide();
            setTimeout(function() {
                document
                    .getElementById('file-manager')
                    .ej2_instances[0].refreshLayout();
            }, 1500);
            localStorage.setItem('browseProjectID', this.selectedRadio);
            // this.currentSwitchState = type;

            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({
                    name: 'browsefiles',
                    params: { id: this.selectedRadio },
                });
                window.open(routeData.href, '_blank');
            } else {
                this.browseFilesTab.push(this.browseFilesTabCounter++);
                setTimeout(() => {
                    this.step = this.browseFilesTab.length;
                });
                this.documentTabopen = false;
            }
        },
        onCreate() {
            this.$refs.file_instance.refreshLayout();
        },
        onSelect(args) {
            // check whether the file is uploading from paste.
            this.nextscreen = false;
            this.saveButton = false;
            this.uploadedFiles = args.filesData;
            if (this.uploadedFiles) {
                this.validateUpload = false;
            } else {
                this.validateUpload = true;
            }

            //
            //this.uploadedFiles.push(args.filesData);
        },
        onUploadBegin: function(args) {
            // check whether the file is uploading from paste.

            if (args.filesData.fileSource === 'paste') {
                let newName =
                    getUniqueID(
                        args.filesData.name.substring(
                            0,
                            args.filesData.name.lastIndexOf('.')
                        )
                    ) + '.png';
                args.customFormData = [{ fileName: newName }];
            }
        },
        afterUplod() {
            this.nextscreen = true;
            this.saveButton = true;
        },
        getDirectories() {
            projectService
                .getDirectories()
                .then((res) => (this.directories = res.data));
        },
        // getDirectory() {
        //   directoryService
        //     .getDirectory()
        //     .then((res) => (this.projectDirectoryList = res.data));
        // },
        getProjectlist() {
            projectService
                .getAllProjectList()
                .then((res) => (this.projectList = res.data));
        },
        saveUploadedImages() {
            this.nextscreen = false;
            this.saveButton = true;
            //let uploadTempData = [];
            const data = new FormData();
            this.uploadedFiles.forEach((item, index) => {
                data.append('documents[' + index + '].FileName', item.name);
                data.append(
                    'documents[' + index + '].description',
                    item.description
                );
                data.append(
                    'documents[' + index + '].FormFile',
                    item.rawFile,
                    item.rawFile.name
                );
                data.append('documents[' + index + '].keyword', item.keyword);
                data.append(
                    'documents[' +
                        index +
                        '].DocumentAttribute.DirectoryAttributeID',
                    2
                );
                data.append(
                    'documents[' + index + '].DocumentAttribute.AttributeValue',
                    item.AttributeName
                );
            });

            projectService.uploadDocuments(data).then((res) => {
                if (res.data.StatusCode === 201) {
                    this.uploadToast = true;
                    setTimeout(() => {
                        this.uploadToast = false;
                        this.successToast = true;
                        setTimeout(() => {
                            this.successToast = false;
                        }, 10000);
                    }, 5000);
                }
            });
        },
        getAssignedAttributes() {
            projectService
                .getAssignedAttributes(this.selected)
                .then((res) => (this.attributes = res.data));
        },
        gotToDashboard() {
            router.push(`/`);
        },
        searchiconclick() {
            document.querySelector('#outerserachinput').style.width = '300px';
            document.querySelector('#outerserachinput').style.borderColor =
                'rgba(0, 0, 0, 0.2)';
            document.querySelector('#outerserachinput .e-input').style.display =
                'block';
            this.closeFilter();
        },
        tabActivated(newTabIndex, oldTabIndex /*, event*/) {
            this.invalidToast = false;
        },
        onClickOpenSelection(value) {
            this.currentSwitchState = value;
        },
        getProjectLocationBounds() {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            //
            this.projLocations.forEach((projLocation) => {
                let xCoord = projLocation.coord[1]; //this.meters2lng(location.coord[0]);
                let yCoord = projLocation.coord[0]; //this.meters2lat(location.coord[1]);
                minX = Math.min(minX, parseFloat(xCoord));
                maxX = Math.max(maxX, parseFloat(xCoord));
                minY = Math.min(minY, parseFloat(yCoord));
                maxY = Math.max(maxY, parseFloat(yCoord));
            });
            this.$refs.lmap1.mapObject.fitBounds(
                latLngBounds([
                    [minY, minX],
                    [maxY, maxX],
                ]),
                { maxZoom: 16 }
            );
        },
        toggleBasemap() {
            let basemaplayerID = Object.keys(
                this.$refs.lmap1.mapObject._layers
            )[0];
            let basemaplayer = this.$refs.lmap1.mapObject._layers[
                basemaplayerID
            ]._url;
            if (basemaplayer.includes('Topo')) {
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[3].visible = false;
                this.baseMaps[2].visible = true;
            } else if (basemaplayer.includes('Imagery')) {
                this.baseMaps[0].visible = false;
                this.baseMaps[2].visible = false;
                this.baseMaps[3].visible = false;
                this.baseMaps[1].visible = true;
            } else if (basemaplayer.includes('open')) {
                this.baseMaps[2].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[3].visible = false;
                this.baseMaps[0].visible = true;
            } else {
                //no map
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[2].visible = false;
                this.baseMaps[3].visible = true;
            }
        },
        openDocument(index, item) {
            this.downloadDoc = true;
            if (item.source == 'Web') {
                // alert("web");
                let url = item.path;
                window.open(url, '_blank');
            }
            let fileName = '';
            let downloadType = '';
            let documentPath = '';
            this.clickedGeometry.properties.forEach((item) => {
                if (item.name == 'Files') {
                    fileName = item.value[index].fileName;
                    downloadType = item.value[index].source;
                    documentPath = item.value[index].path;
                    commonService.downloadDocument(
                        fileName,
                        downloadType,
                        documentPath
                    );
                }
            });
            setTimeout(() => {
                this.downloadDoc = false;
            }, 2000);
        },
        start3DVisDraw(val) {
            if (val) {
                this.userSelectedProjectID = null;
                this.$refs['mapcontainer'].$refs.mainLmap.drawPolygon();
            }
        },
        save3DVisDrawGeom(val) {
            this.threeDVisGeom = val;
            //show model, allow user to select a 3D model file
            this.selectWhichProjectToOperateOn();
        },
        selectWhichProjectToOperateOn() {
            if (
                this.markerSettings[0].dataSource.length > 1 &&
                this.userSelectedProjectID == null
            ) {
                this.$refs['projects-modal-3dvis'].show();
            } else if (
                this.markerSettings[0].dataSource.length > 1 &&
                this.userSelectedProjectID !== null
            ) {
                this.$refs['projects-modal-3dvis'].hide();
                this.$bvModal.show('save3d-vis-geom');
            } else {
                this.userSelectedProjectID = this.$store.state.projects.selectedProjects[0].ProjectID;
                this.$refs['projects-modal-3dvis'].hide();
                this.$bvModal.show('save3d-vis-geom');
            }
        },
        submit3DVisModel() {
            let payload = new FormData();
            //add logic to check which project to save 3D model to if multiple were selected,
            let ProjectID = this.userSelectedProjectID;
            payload.append('ProjectId', ProjectID);
            payload.append('Description', this.threeDVisDescription);
            payload.append('Geometry', this.threeDVisGeom);
            payload.append('Name', this.threeDVisName);
            try {
                payload.append(
                    'File',
                    document.getElementById('new3DModelUpload').files[0]
                );
            } catch (error) {
                console.log('No file attached');
            }
            this.$refs.mapcontainer.$refs.mainLmap.clearGeometry();
            this.$bvModal.hide('save3d-vis-geom');
            projectService
                .Upload3DModel(payload)
                .then((res) => {
                    this.showSuccess = true;
                    this.successToastMessage =
                        '3D visualization model is submitted';
                    //call getProjectLayer api to refresh the map with just submitted 3D vis geometry
                    this.getProjectLayerData();
                })
                .catch((err) => {
                    this.showFailure = true;
                    this.failureToastMessage = err.Message; //need api to return a more user friendly error message
                });
        },
        cancel3DVisModel() {
            this.$refs.mapcontainer.$refs.mainLmap.clearGeometry();
            this.$bvModal.hide('save3d-vis-geom');
        },
    },
    computed: {
        ...mapState({
            projectMapLayers: (state) => state.projectLayers.projects,
            postedMapLayers: (state) =>
                state.projects.mapData.geojsonLayers.posted,
        }),
        ...mapGetters({
            inMapProjectsTitle: 'projects/selectedProjectsTitle',
        }),
        projectGeojson() {
            let output1 = [];
            this.projectDataLayers.forEach((item) => {
                if (item.ProjectLayers) {
                    item.ProjectLayers.sort((a, b) => a.lyrorder - b.lyrorder); //sort ProjectLayers by lyrorder in assending order
                    item.ProjectLayers.forEach((lyr) => {
                        lyr.Geometry.id = lyr.lyrorder;
                        output1.push(lyr.Geometry);
                    });
                }
            });
            return { projectLayers: output1 };
        },
        projLocations() {
            let temp_arry = [];
            for (var i = 0; i < this.markerSettings[0].dataSource.length; i++) {
                temp_arry.push({
                    siteid: this.markerSettings[0].dataSource[i].id,
                    coord: [
                        this.markerSettings[0].dataSource[i].latitude,
                        this.markerSettings[0].dataSource[i].longitude,
                    ],
                    ProjectName: this.markerSettings[0].dataSource[i].name,
                    City: this.markerSettings[0].dataSource[i].City,
                    State: this.markerSettings[0].dataSource[i].Province,
                    Country: this.markerSettings[0].dataSource[i].Country,
                });
            }
            return temp_arry;
        },
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                disabled: !this.editable,
                ghostClass: 'ghost',
            };
        },
        ...mapState({
            markerSettings: (state) => {
                if (state.projects.selectedProjects) {
                    const data = state.projects.selectedProjects;
                    const markersData = [];

                    data.forEach((element, index) => {
                        markersData.push({
                            id: index,
                            name: element.ProjectName,
                            latitude: element.Latitude,
                            longitude: element.Longitude,
                            element: element,
                            City: element.City,
                            Province: element.StateName,
                            Comments: element.Comments,
                            Country: element.CountryName,
                            shape: 'Circle',
                            color: 'blue',
                            draggable: true,
                            visible: true,
                        });
                    });
                    let markerSettings = [
                        {
                            visible: true,
                            animationDelay: 0,
                            dataSource: markersData,
                            shapeValuePath: 'shape',
                            colorValuePath: 'color',
                            tooltipSettings: {
                                visible: true,
                                valuePath: 'name',
                            },
                        },
                    ];

                    return markerSettings;
                }
            },
        }),
        filteredItems() {
            if (this.searchQuery) {
                return this.markerSettings[0].dataSource.filter((item) => {
                    return (
                        item.name
                            .toLowerCase()
                            .indexOf(this.searchQuery.toLowerCase()) > -1
                    );
                });
            } else {
                return this.markerSettings[0].dataSource;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';
@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-layouts/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-grids/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-vue-filemanager/styles/bootstrap4.css';

.my-custom-floating-label {
    .input__container {
        width: 400px;
    }
}
.breadcrumb {
    background-color: white !important;
}
.text-right.icons {
    //margin-top: -10px;
    max-width: 100px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}
.saveManageButton {
    margin-top: -107px;
}
.cancelManage {
    position: absolute;
    /* text-align: right !important; */
    top: -33px;
    left: -97px;
    a {
        color: #006395 !important;
        font-size: 16px !important;
    }
}
.text-right.saveManage {
    position: absolute;
    top: -40px;
}
.open-toggle {
    float: right;
    margin-top: 8px;
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}
.childCheckBox {
    padding-left: 20px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.nav-item {
    // background-color: #eaeaea !important;
    width: 100%;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.leaflet-popup-content-wrapper {
    background-color: black !important;
    padding: 1px !important;
    min-width: 100px !important;
    margin: 0 !important;
    color: #eee !important;
    border-radius: 0 !important;
    overflow-y: auto !important;
    max-height: 300px !important;
    overflow-y: auto !important;
    max-height: 300px !important;
}
.leaflet-popup-content {
    margin: 3px 6px !important;
}
.leaflet-popup-tip-container {
    display: inblock;
}
.leaflet-container a.leaflet-popup-close-button {
    display: inblock;
}
.leaflet-control-attribution a {
    display: inblock;
}

.leaflet-control-container {
    display: inblock;
}
.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    // left: 1px;
    // top: 12px;
    z-index: 999;
}
/* .border-right {
  margin-left: -36px !important;
} */

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
    padding: 15px 5px;
    font-size: 16px !important;
}

.DocManagement {
    height: auto !important;
}

.reportingTool p.wide-50 {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    width: 120px !important;
    height: 49px;
    opacity: 1 !important;
}

.titleBlock {
    margin: 16px 15px;
    height: 48px;
    font-size: 16px !important;
}
.titleBlock small {
    font-size: 16px !important;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
}
.titleBlock small:hover {
    border: 3spx solid #b034a1;
    height: 48px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 48px;
    width: 48px;
    text-align: center;
}

.graphing {
    margin: 16px 15px;
    height: 48px;
}

.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.graphing small {
    font-size: 16px !important;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d;
    padding-top: 10px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 48px;
}
.graphing span {
    background-color: #8456b0;
    height: 48px;
    width: 48px;
    text-align: center;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.directoryAssign {
    background: #dddddd;
    //margin-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.directoryItem {
    background-color: white !important;
    height: 50px !important;
    width: 500px !important;
    margin-left: 48px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -10px;
}
.test {
    margin-bottom: 20px;
}
.row1 {
    margin-bottom: 20px;
}
.searchinputIcon {
    background-color: #eaeaea;
    width: 197px;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: transparent !important;
}
.arrayLength {
    position: absolute;
    /* left: 20px; */
    right: 84px;
    top: 107px;
    font-weight: bold;
}

button#__BVID__31__BV_toggle_ {
    background: none !important;
    border: none !important;
}
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}

.dataset {
    margin: 10px 15px;
    height: 48px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 48px;
}
.dataset span {
    background-color: #5878be;
    height: 48px;
    width: 48px;
    text-align: center;
}
.graphic {
    text-align: center;
    width: 253px;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
}
.blue {
    color: #fff !important;
    background-color: #000 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
// .graphic:active {
//   color: #fff !important;
//   border-color: #626262;
//   background-color: #000;
// }
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
    /* margin-top: 23px; */
    //margin-left: 104px;
}
.graphic:hover {
    border-color: #626262;
    background-color: #000;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 28px;
    /* margin-left: 24px; */
    margin-top: 0px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.assignedTabs {
    width: 499px;
    margin: auto;
    .normal_input.smallTabs {
        width: 408px !important;
    }
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 35%;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        //margin-left: 312px;
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        cursor: pointer;
        padding: 14px 23.46px !important;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: 154px;
    //   margin-top: 30px;
    // margin-left: 127px;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}

.cancelButton {
    background: transparent;
    color: #006395;
    float: left;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
}

.selectedGraphictool {
    color: #fff !important;
    border-color: #626262;
    background-color: #000;
}
.contouring {
    margin: 16px 15px;
    height: 48px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 48px;
}
.contouring span {
    background-color: #3296ca;
    height: 48px;
    width: 48px;
    text-align: center;
}

.mapToolAttribute {
    width: 225px;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 40%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.normal_select {
    width: 475px;
    height: 52px;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 3px;
}
.normal_select:focus {
    border: 1px solid #c6c6c6 !important;
}
.mapToolAttribute h6 {
    color: #006395;
    font-weight: bold;
}
.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;
    p {
        text-align: center;

        width: 248px;
        margin-left: 128px;
        font-size: 18px;
        color: #000;
        a {
            font-size: 20px;
            color: #006395 !important;
        }
    }
}

.uploadButton {
    background-color: #3296ca;
    width: 83px;

    margin-top: 10px;
    border-radius: 3px;
}
.uploadicon {
    height: 56px;
    width: 82px;
}
.iconplacing {
    //padding-left: 200px;
    padding-top: 13px;
}
.attrTabs .tabs {
    border: 2px solid #eaeaea !important;
    margin-bottom: 135px;
}
.content {
    // background-color: #eaeaea !important;
    min-height: 350px;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    //padding: 30px;
    //text-align: center;
}
.content-clone {
    background-color: #eaeaea !important;
    min-height: 350px;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    padding: 30px;
    text-align: center;
}
.my-custom-floating-label {
    background-color: white !important;
    // width: 400px !important;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    height: 48px;
    text-align: center;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    // margin-top: 67px;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.reportingTool span img {
    margin-top: 13px;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.uploadToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 450px;
    border-left: 6px solid #933d9b;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}

.assignAttr img {
    margin-top: 10%;
}

.tabs {
    padding-top: 10px;
}

ul.e-upload-files {
    border-top: none !important;
    margin-top: 70px !important;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

button.btn.btn-secondary.btn-block.not-collapsed {
    background: #626262 url('../../assets/minusIcon.svg') no-repeat right
        0.75rem center !important;
    color: #fff;
    text-align: left;
    border-radius: 0;
    border: none;
    height: 50px !important;
    // width: 516px;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    // width: 516px;
    border-radius: 0;
    border: none;
    color: #000;
    background: #fff url('../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
    height: 50px !important;
}
.card-header {
    margin-bottom: 20px;
    background: transparent;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
}
.custom-select {
    background: #fff url('../../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
}
.custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 5%;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
}
.custom-select {
    background: #fff url('../../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
    background: #eaeaea url('../../assets/big_plus.svg') no-repeat right 0.75rem
        center !important;
}
.btn-info:focus,
.btn-info.focus {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:hover {
    color: #000;
    background-color: #eaeaea;
}
.normal_inputButton {
    height: 48px !important;
    border-radius: 3px;
    //margin-left: 104px;
    width: 499px;
    margin: auto;
}
button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../../assets/minus_sign_big.svg') no-repeat right
        0.75rem center !important;
}

.dragdrop {
    margin-left: 140px;
    text-align: center;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000;
}
// .nextbutton {
//   margin-right: 50px;
// }

.nextbutton:disabled:hover {
    background-color: #eaeaea !important;
}
// Manage attribute style
.saveManage {
    button {
        width: 80px;
    }
}
.cancelManage {
    position: absolute;
    /* text-align: right !important; */
    top: -33px;
    left: -82px;
    a {
        color: #006395 !important;
        font-size: 16px !important;
    }
}
.text-right.saveManage {
    position: absolute;
    top: -40px;
}
.childCheckBox {
    padding-left: 20px;
    overflow-x: auto;
    height: 130px;
}
.legacyBox {
    height: 261px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -10px;
}
.searchinputIcon {
    background-color: #eaeaea;
    width: 197px;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
.arrayLength {
    position: absolute;
    /* left: 20px; */
    right: 84px;
    top: 107px;
    font-weight: bold;
}
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #c6c6c6;
}
.blue {
    color: #fff !important;
    background-color: #161616 !important;
    border: 0 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #c6c6c6;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
    /* margin-top: 23px; */
    margin-bottom: 20px;
    border-radius: 3px !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 50%;
    float: right;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        /*margin-left: 312px;*/
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        /*margin-right: -295px;*/
    }
}
.main-button {
    margin-right: 0;
    /*margin-left: -56px;*/
    margin-top: 20px;
    width: 215px;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        /*margin-left: 312px;*/
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}

.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
    li {
        border-top: none !important;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: 154px;
    //  margin-top: 30px;
    // margin-left: 127px;
}
.contentEdit {
    width: 498px;
    text-align: center;
    //margin-left: 132px;
    margin-top: 30px;
}
.title {
    text-align: center;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    // display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.successToastMsg p {
    display: inline;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #626262 url('../../assets/minusIcon.svg') no-repeat right
        0.75rem center !important;
    color: #fff;
    text-align: left;
    border-radius: 0;
    border: none;
    // width: 516px;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    // width: 516px;
    border-radius: 0;
    border: none;
    color: #000;
    background: #fff url('../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}

.card-header {
    margin-bottom: 20px;
    background: transparent;
}
//manage attribute style ended
//style for file manager
.file-overview .sample-container {
    margin: 10px 10px 10px 10px;
}
//listing file in toast message after document upload
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    // overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}
.applyButtonSelect {
    background: #006395;
    color: white;
    border: none;
    cursor: pointer;
}
.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}
.model_style {
    width: 900px !important;
}
.modal-dialog {
    max-width: 700px !important;
}

button:disabled,
button[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
input:disabled,
input[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3;
}
div#__BVID__62___BV_modal_backdrop_ {
    opacity: 1 !important;
    background-color: #8a8a8a !important;
}
.e-file-container {
    align-items: last baseline !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name {
    padding: 0 !important;
}
.e-list-text {
    color: #fff !important;
}
.e-hover {
    color: #000;
}

#file-manager {
    display: block !important;
    border: 1px solid red !important;
}

#file-manager_layout {
    display: block !important;
}
.upload-doc .custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 30px;
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
#outerserachinput {
    height: 40px !important;
    padding-top: 5px;
}
.icon-right {
    position: absolute;
    right: 0;
}
.subheader-smalltext {
    margin-right: -80px;
}
.mAttribute {
    width: 130px !important;
    height: 20px !important;
    background-color: transparent !important;
}
.selectMap {
    position: relative;
    z-index: 0;
}
.project_layer {
    background: transparent;
    color: black;
    width: 144px;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.project_layer_active {
    background: transparent;
    color: black;
    width: 100%;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 43px;
}
.proj_layer {
    width: 100%;
    height: 50px;
    font-weight: 500;
}
.overflow {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 390px;
    width: 100%;
}
.sticky {
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: white;
}
.stickyMain {
    position: sticky;
    top: 0px;
    z-index: 2;
}
.vertical-center {
    padding-top: 9px;
}
.slider-center {
    text-align: center;
}
.hideBtn {
    padding-right: 5px;
}
.borderBlue {
    border-bottom: 5px solid #006395 !important;
    border-radius: 0 !important;
}
.modalScreen {
    height: 85vh;
    z-index: 999999;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}
.projBorder {
    border-bottom: 5px solid #006395 !important;
    overflow-x: hidden;
    border-radius: 0 !important;
}
.containerPadding {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.mapPadding {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.card-body {
    padding: 1rem;
}
.collapsed .when-opened {
    display: none;
}
.not-collapsed .when-closed {
    display: none;
}
.nolayers {
    padding-left: 15px;
}
.selectedProj-map-box {
    height: 440px;
    width: 885px;
}

.selectedProj-map-box-expanded {
    height: 440px;
}

.selectedProj-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }
    #lmap1 {
        opacity: 1;
        height: 10in;
        width: 15in;
    }
    // #mapContainer{
    //   position: absolute;
    //   height: 7.5in;
    //   width: 10in;
    //   top: 0.5in;
    //   left: 0.5in;
    // }
    // .selectedProj-map-box-print{
    //   position: absolute;
    //   height: 7.5in;
    //   width: 10in;
    //   top: 0.5in;
    //   left: 0.5in;
    // }
}
.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.visibilityText {
    position: absolute;
    left: 43%;
    top: 55%;
    opacity: 0.7;
    z-index: 9999;
}
.visibilityIcon {
    position: absolute;
    left: 47%;
    top: 45%;
    z-index: 9999;
    height: 40px;
    width: 40px;
    opacity: 0.7;
}
.btn-side {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
}
.btn-zoom {
    position: absolute;
    top: 25px;
    left: 12px;
}
.btn-edit {
    position: absolute;
    top: -10px;
}
.btn-delete {
    position: absolute;
    left: 0px;
    top: 39px;
}
.posted_layer {
    background: transparent;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}
.project_layers {
    background-color: #f4f4f4;
    height: 44px;
}
.loadingIcon {
    position: absolute;
    top: 150px;
    left: 126px;
    z-index: 2;
}
.loadingText {
    position: absolute;
    top: 180px;
    left: 100px;
    z-index: 2;
}
.WebType {
    color: purple !important;
}
.BlobType {
    color: #006395 !important;
}
.powerBIText {
    position: absolute;
    top: 600px;
    left: 43%;
}
.powerBIIcon {
    position: absolute;
    top: 570px;
    left: 45%;
}
.modal-content {
    border: none;
}
.modal-content p {
    font-family: 'IBMPlexSans-Regular';
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
</style>
<style>
.threeDvis-modal .b-form-file .form-file-text {
    padding: 5px 0;
}
.threeDvis-modal label.custom-file-label::after {
    padding: 10px 0.75rem;
}
.threeDvis-modal .b-form-file,
.threeDvis-modal .custom-file-label,
.threeDvis-modal #new3DModelUpload,
.threeDvis-modal .custom-file-input ~ .custom-file-label[data-browse]::after {
    height: 50.67px !important;
}
.threeDvis-modal .b-form-file label,
.threeDvis-modal .b-form-file label span {
    font-size: 16px;
    font-family: 'IBMPlexSans-Regular';
    font-weight: normal;
}
.threeDvis-modal .b-form-file label span {
    text-align: start;
    color: #626262;
    left: 17px;
}
.threeDvis-modal .custom-file-label {
    border: 1px solid #c6c6c6;
}
</style>
