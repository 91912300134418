<template>
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="titleblock" v-else>
        <!--   <b-container fluid class="tblock sub_header_bg">
     <b-container>
       <b-row class="mb-3">
          <b-col cols="12 my-1">
            <a @click="navigateDashboard">
              <img src="../../assets/arrow_left.svg" class="" />
              <span class="floar-left ml-1"> Back to dashboard</span>
            </a>
          </b-col>
        </b-row> -->
        <b-col cols="12" class="saveHeader">
            <b-row class="vh-10 text-left" align-v="center">
                <!-- <b-col cols="3" lg="2" class="title-m-view">
            <p class="float-right m-0 cancel-link" @click="cancelTitle">Cancel</p>
          </b-col> -->
                <!-- <b-col cols="4" md="2" lg="1" class="text-right title-m-view-btn titleSave"> -->
                <b-button
                    :disabled="disableSave || imageWidthErr || btnDisable"
                    rounded
                    class="title-m-view-btn titleSave savebtn buttonSelect "
                    @click="
                        toGo = true;
                        saveTitleBlock();
                    "
                >
                    Save
                </b-button>
                <!-- </b-col> -->
            </b-row>
        </b-col>

        <!-- <b-row class="pt-2">
          <div class="borderBlue mt-3 ml-3">
            <h5>Title block</h5>
          </div>
        </b-row>
      </b-container>
    </b-container> -->

        <b-container class="p-0">
            <div class="pb-3">
                <!-- <b-row class="mb-3">
          <b-col cols="1" class="pt-2 m-none text-center mx-50">
          <a href="#" class="search-link pt-2"><img src="../../assets/search-outline.svg" /></a>
          </b-col>
          <b-col cols="11" class="text-left icons border p-0 m-align m-shadow">
            <a @click="openTableselectTool"
              ><img
                src="../../assets/ghd_list.svg"
                font-scale="1.5"
                class="px-2 mt-2 border-right"
            /></a>

            <a href="#" class="m-0 pl-2" @click="showReportingTool"
              ><img src="../../assets/ghd_word.svg" class="mr-2 pr-2 mt-2"
            /></a>
            <div
              v-if="reportingTool"
              style="position: absolute; left: 70px; top: 51px; z-index: 99"
            >
              <div class="reportingTool">
                <div class="pl-3 py-2">
                  <p class="d-inline">Reporting</p>
                  <span class="float-right mr-3" @click="closeReporting">
                    <a class="p-0"><img src="../../assets/close.svg" /></a>
                  </span>
                </div>
                <div class="titleBlock row align-items-cente">
                  <span>
                    <img src="../../assets/icon_title_block.svg" class="" />
                  </span>
                  <small class="col pt-1 pl-2"> Title Block </small>
                </div>
                <div class="graphing row align-items-cente">
                  <span>
                    <img src="../../assets/icon_graphing.svg" class="w-75" />
                  </span>
                  <small class="col pt-1 pl-2"> Graphing </small>
                </div>
                <div class="dataset row align-items-cente">
                  <span>
                    <img src="../../assets/icon_dataset.svg" class="" />
                  </span>
                  <small class="col pt-1 pl-2"> Dataset Export </small>
                </div>
                <div class="contouring row align-items-cente">
                  <span>
                    <img src="../../assets/icon_contouring.svg" class="w-75" />
                  </span>
                  <small class="col pt-1 pl-2"> Contouring Tool </small>
                </div>
              </div>
            </div>
            <span class="switchlabel pt-2 mr-3 mt-0 m-none">
              <label class="router-link-active switch ml-4 mr-1">
                <input type="checkbox" v-model="currentSwitchState" checked />
                <span class="slider round"></span>
              </label>
              <span class="m-none">Open Selection in new tab</span></span>
            <label>                          
                         <a href="#"><img id="searchBtn" class="filtericonM desk-none" src="../../assets/filter1.svg" /></a>
                        </label>

            <router-link to="/"> </router-link>
          </b-col>

        </b-row> -->
                <b-container class="define-tool">
                    <p class="my-3">
                        Enter title block details for {{ getDatasetName }}
                    </p>
                    <b-row bordered>
                        <b-col lg="5" md="8" class="block-1">
                            <div
                                v-for="(item, index) in titleBlock"
                                :key="item.id"
                            >
                                <div class="borderBlue mt-3 ml-2">
                                    <h5>Line {{ index + 1 }}</h5>
                                </div>
                                <div class="line-block mt-6 pt-3">
                                    <span class="ml-4"
                                        >Text<small v-if="index !== 0"
                                            >(Optional)</small
                                        ></span
                                    >
                                    <div
                                        onkeypress="return /[0-9a-zA-Z, _/().#\\&]/i.test(event.key)"
                                        onpaste="return false;"
                                        ondrop="return false;"
                                        :id="'titleBlockText' + index"
                                        class="px-2"
                                        contenteditable="true"
                                        pattern="[a-zA-Z0-9!@#$%^*_|]{6,25}"
                                        :value="titleBlock[index].textFormat"
                                        @input="
                                            updateValue(
                                                $event.target.innerHTML,
                                                index
                                            )
                                        "
                                    ></div>
                                    <div
                                        class="sm-input-container"
                                        v-for="(dataset,
                                        indexdataset) in item.dataSet"
                                        :key="indexdataset"
                                    >
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Dataset field',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                class="ml-4 mt-2 small_drop"
                                                v-model="dataset.dataSetName"
                                                @change="
                                                    appendText(
                                                        index,
                                                        indexdataset
                                                    )
                                                "
                                                :disabled="
                                                    titleBlock[index][
                                                        'dataSet'
                                                    ][indexdataset]
                                                        .dataSetName !== ''
                                                "
                                                value-field="Dataset field"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        value=""
                                                        disabled
                                                        >Dataset field
                                                        (optional)</b-form-select-option
                                                    >
                                                </template>
                                                <option
                                                    v-for="column in datasetCoumn"
                                                    :key="
                                                        column.DatasetColumnId
                                                    "
                                                    :value="column.ColumnName"
                                                >
                                                    {{ column.ColumnName }}
                                                </option>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <a
                                            class="delete"
                                            :data-id="indexdataset"
                                            @click="
                                                deleteDataSet(
                                                    index,
                                                    indexdataset,
                                                    $event
                                                )
                                            "
                                        ></a>
                                    </div>
                                    <button
                                        class="mt-3 headergroup block"
                                        value="Add a header group +"
                                        autocomplete="off"
                                        @click="addDataSet(index)"
                                        v-if="datasetCoumn.length"
                                    >
                                        Insert field from dataset +
                                    </button>
                                    <div class="ml-4 mt-4 style-block">
                                        <span>Style</span>
                                        <b-check
                                            v-model="item.styleGHD"
                                            class="mt-2 mb-2 checkbox"
                                            @change="
                                                loadDefaultStyle(index);
                                                disableBtnSave();
                                            "
                                            >Load default GHD styles</b-check
                                        >
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Size',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="item.textSize"
                                                @change="
                                                    resetDefaultStyle(index)
                                                "
                                                :options="styleDataSize"
                                                text-field="Size"
                                                value-field="Size"
                                                @blur.native="
                                                    item.textSize == null
                                                        ? (titleBlockError[
                                                              index
                                                          ].textSize = true)
                                                        : (titleBlockError[
                                                              index
                                                          ].textSize = false)
                                                "
                                            >
                                                <!--<option
                        v-for="user in styleData"
                        :key="user.StyleId"
                        :value="user.StyleSize"
                      >
                        {{ user.StyleSize }}
                      </option>-->

                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Size</b-form-select-option
                                                    >
                                                </template></b-form-select
                                            >
                                        </CustomFloatingLabel>
                                        <span v-if="titleBlockError[index]">
                                            <span
                                                class="style-alert-msg"
                                                v-if="
                                                    titleBlockError[index]
                                                        .textSize
                                                "
                                                >please select size</span
                                            ></span
                                        >
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Style',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="item.textStyle"
                                                @change="
                                                    resetDefaultStyle(index)
                                                "
                                                :options="styleDataforStyle"
                                                text-field="Style"
                                                value-field="Style"
                                                @blur.native="
                                                    item.textStyle == null
                                                        ? (titleBlockError[
                                                              index
                                                          ].textStyle = true)
                                                        : (titleBlockError[
                                                              index
                                                          ].textStyle = false)
                                                "
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Style</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <span v-if="titleBlockError[index]">
                                            <span
                                                class="style-alert-msg"
                                                v-if="
                                                    titleBlockError[index]
                                                        .textStyle
                                                "
                                                >please select style</span
                                            ></span
                                        >

                                        <div class="pickColor wrap">
                                            <div class="">
                                                <button
                                                    @click="colorPicker"
                                                    class="btn-transparent"
                                                >
                                                    <img
                                                        src="../../assets/ghd_colorpicker.svg"
                                                        font-scale="1.5"
                                                        class="pencil-icon"
                                                    />
                                                </button>
                                                <CustomFloatingLabel
                                                    class="FloatingTool"
                                                    :config="{
                                                        label: 'Color',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }"
                                                >
                                                    <ejs-colorpicker
                                                        id="element"
                                                        type="text"
                                                        class="e-input"
                                                        v-model="item.textColor"
                                                        @change="
                                                            resetDefaultStyle(
                                                                index
                                                            )
                                                        "
                                                    ></ejs-colorpicker>
                                                    <span></span>
                                                </CustomFloatingLabel>
                                            </div>
                                        </div>
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Type',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="item.textType"
                                                @change="
                                                    resetDefaultStyle(index)
                                                "
                                                :options="styleDataType"
                                                text-field="Type"
                                                value-field="Type"
                                                @blur.native="
                                                    item.textType == null
                                                        ? (titleBlockError[
                                                              index
                                                          ].textType = true)
                                                        : (titleBlockError[
                                                              index
                                                          ].textType = false)
                                                "
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Type</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <span v-if="titleBlockError[index]">
                                            <span
                                                class="style-alert-msg"
                                                v-if="
                                                    titleBlockError[index]
                                                        .textType
                                                "
                                                >please select type
                                            </span>
                                        </span>
                                    </div>
                                    <button
                                        v-if="index !== 0"
                                        :data-id="index"
                                        ref="removeButton"
                                        class="m-3 headergroup block lineRemoveButton"
                                        @click="removeLine(index)"
                                    >
                                        Remove this Line of text
                                    </button>
                                </div>
                            </div>
                            <button
                                class="mt-3 mb-3 normal_button headergroup"
                                value="Add a header group +"
                                autocomplete="off"
                                @click="addLine()"
                                v-if="titleBlock.length < 5"
                            >
                                Add another line of text +
                            </button>
                            <div class="upload_logo">
                                <span class="bold-text">Select logo </span
                                ><small class="optional"> (optional)</small
                                ><br />

                                <div
                                    class="drag-drop"
                                    v-cloak
                                    @drop.prevent="
                                        onFileChanged($event, 'drop')
                                    "
                                    @dragover.prevent
                                >
                                    <b-icon-cloud-arrow-up
                                        class="uploadicon"
                                    ></b-icon-cloud-arrow-up>
                                    <p>
                                        <span v-if="!fileName" class="drag-text"
                                            >Drag and drop file to upload</span
                                        >
                                        <span v-if="fileName">{{
                                            fileName
                                        }}</span>
                                        <br />Or
                                        <input
                                            type="file"
                                            name="image"
                                            id="inputFile"
                                            @change="
                                                onFileChanged($event, 'select')
                                            "
                                            ref="myFile"
                                            accept="image/*"
                                        />
                                    </p>
                                </div>
                                <small v-if="imageWidthErr" class="fileError"
                                    >Please select image of dimensions below
                                    5000 x 5000 pixels</small
                                >
                                <small v-if="disableSave" class="fileError"
                                    >Please select only image</small
                                >
                                <!--<div class="upload file btn btn-lg btn-primary mt-3">
							Upload
							<input class="btnupload" type="file" name="file"/>
						</div>-->
                            </div>
                            <div class="footer_text mb-4">
                                <span class="bold-text">Footer text</span>
                                <small class="optional"> (optional)</small
                                ><br />
                                <textarea
                                    class="mt-2 pl-2"
                                    placeholder="Start typing here"
                                    v-model="footerText"
                                ></textarea>
                            </div>
                        </b-col>
                        <hr class="br-right" />
                        <b-col cols="5" class="block-2">
                            <div class="preview_logo">
                                <span
                                    v-if="titleBlock[0].text"
                                    class="logo-title"
                                    >Title block preview
                                </span>
                                <div class="side_block row pb-2 pt-3 px-3">
                                    <div class="content-panel">
                                        <div
                                            class="line_block -xs-4 vmiddle word-brk row m-0 d-flex justify-content-between"
                                        >
                                            <div
                                                class="-xs-4 col-xs-offset-4 vmiddle"
                                            >
                                                <img
                                                    id="image-preview"
                                                    :src="blobPath"
                                                    style="width:40px; height: 40px"
                                                    class="my-auto"
                                                    v-if="blobPath"
                                                />
                                            </div>
                                            <div class="col">
                                                <div
                                                    v-for="(item,
                                                    index) in titleBlock"
                                                    :key="item.id"
                                                >
                                                    <div
                                                        v-if="item.styleGHD"
                                                        class="text-optional"
                                                        v-bind:style="{
                                                            fontSize:
                                                                item.textSize +
                                                                'px',
                                                            color:
                                                                item.textColor,
                                                            fontFamily:
                                                                item.textType,
                                                            fontWeight:
                                                                item.textStyle,
                                                        }"
                                                        :id="
                                                            'previewDataset' +
                                                                index
                                                        "
                                                    >
                                                        {{ item.text }}
                                                    </div>
                                                    <div
                                                        v-bind:style="{
                                                            fontSize:
                                                                item.textSize +
                                                                'px',
                                                            color:
                                                                item.textColor,
                                                            fontFamily:
                                                                item.textType,
                                                            fontWeight:
                                                                item.textStyle,
                                                        }"
                                                        v-if="
                                                            !item.styleGHD &&
                                                                item.textStyle ===
                                                                    'Bold'
                                                        "
                                                        class="top-text b-text"
                                                        :id="
                                                            'previewDataset' +
                                                                index
                                                        "
                                                    >
                                                        {{ item.text }}
                                                    </div>
                                                    <div
                                                        v-bind:style="{
                                                            fontSize:
                                                                item.textSize +
                                                                'px',
                                                            color:
                                                                item.textColor,
                                                            fontFamily:
                                                                item.textType,
                                                            fontWeight:
                                                                item.textStyle,
                                                        }"
                                                        v-if="
                                                            !item.styleGHD &&
                                                                item.textStyle !==
                                                                    'Bold'
                                                        "
                                                        class="top-text b-text"
                                                        :id="
                                                            'previewDataset' +
                                                                index
                                                        "
                                                    >
                                                        {{ item.text }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="footer-section"
                                        :class="footerText ? 'footerText' : ''"
                                    >
                                        <span>{{ footerText }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </b-container>
        <ToastMessages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />
        <div class="successToastMsg" v-if="successToast">
            <img src="../../assets/success_tick.svg" class="mr-3" />
            Success <br />
            Title Block created/updated successfully
            <img
                src="../../assets/close_big.svg"
                class="ml-5"
                @click="closeSuccessToast"
            />
        </div>
        <div class="failureToastMsg" v-if="failureToast">
            <img src="../../assets/failure_cross.svg" class="mr-3" />
            Failure <br />
            Title Block already exists
            <img
                src="../../assets/close_big.svg"
                class="ml-5"
                @click="closeFailureToast"
            />
        </div>
        <Footer />
    </div>
</template>

<script>
import Vue from 'vue';
import router from '../../router';
import Footer from '../Footer';
import { projectService } from '../../services/project.service';
import { reportingService } from '../../services/reporting.service';
import { icon } from 'leaflet';
import { mapActions } from 'vuex';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';
import ToastMessages from '../ToastMessages.vue';
import AccessPermissionModal from '../AccessPermissionModal';
import CustomFloatingLabel from '../CustomFloatingLabel';
import { datasetService } from '../../services/dataset.service';
Vue.use(ColorPickerPlugin);
export default {
    name: 'TitleBlock',
    props: {
        // titleIndex: Boolean,
        renderTitle1: Boolean,
    },
    components: {
        Footer,
        ToastMessages,
        AccessPermissionModal,
        CustomFloatingLabel,
    },
    created() {
        //this.getStyleBlock();
        //this.blobPath='https://ghdadaptdev.blob.core.windows.net/project-documents/GHD_Logo_Black_RGB.png';
        this.getStyleData();
        this.getDataSetColumn();
        this.getTitleBlock();
        // setTimeout(() =>{
        //   this.disableBtnSave();
        // }, 1500);
    },
    data() {
        return {
            mapView: true,
            token: '',
            successToast: false,
            failureToast: false,
            icon: icon({
                iconUrl: require('../../../assets/marker.svg'),
            }),
            image: {
                size: '',
                height: '',
                width: '',
            },
            titleBlockCurrentId: 0,
            titleBlock: [
                {
                    text: '',
                    dataSet: [],
                    textSize: null,
                    textStyle: null,
                    textColor: '#000000',
                    textType: null,
                    styleGHD: true,
                    TitleId: '',
                    BlockDataset: '',
                    BlockId: '',
                    DatasetId: '',
                    StyleId: '',
                },
            ],
            titleBlockError: [
                {
                    text: false,
                    textSize: false,
                    textStyle: false,
                    textType: false,
                },
            ],
            footerText: '',
            currentSwitchState: false,
            selected: null,
            fileName: '',
            files: [],
            styleData: [],
            styleDataType: [],
            styleDataTypeSorted: [],
            styleDataforStyle: [],
            styleDataSize: [],
            datasetCoumn: [],
            titleBlockData: [],
            blobPath: '',
            imageId: '',
            font_options: [
                { value: '16', text: '16' },
                { value: '14', text: '14' },
                { value: '12', text: '12' },

                { value: 'd', text: 'This one is disabled', disabled: true },
            ],
            dataset_options: [
                { value: 'Location', text: 'Location' },
                { value: 'Test', text: 'Test' },
            ],
            pickColor: false,
            getDatasetName: localStorage.getItem('datasetName'),
            defaultSize: '',
            defaultStyle: '',
            defaultType: '',
            sortDirection: 'asc',
            disableSave: false,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            showPage: false,
            imageWidthErr: false,
            titleBlockSavedData: [],
            toGo: false,
            showError: false,
            btnDisable: false,
            datasetForDec: [],
            filterOptions: JSON.parse(localStorage.getItem('gridFilter')),
        };
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('TitleBlock')) {
                    vm.showPage = true;
                }
            });
            // this.$emit('clickedTitleBlock', this.showPage);
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },

        addLine() {
            this.titleBlockCurrentId++;
            this.titleBlock.push({
                id: this.titleBlockCurrentId,
                text: '',
                textFormat: '',
                dataSet: [],
                textSize: null,
                textStyle: null,
                textColor: '',
                textType: null,
                styleGHD: this.titleBlock[this.titleBlock.length - 1].styleGHD
                    ? true
                    : false,
                TitleId: '',
                BlockDataset: '',
                BlockId: '',
                DatasetId: '',
                StyleId: '',
            });
            this.titleBlockError.push({
                text: false,
                textSize: false,
                textStyle: false,
                textType: false,
            });

            this.loadDefaultStyle(this.titleBlock.length - 1);
        },
        removeLine(index) {
            this.titleBlock.splice(index, 1);
        },
        onFileChanged(event, param) {
            this.fileName = '';
            this.disableSave = false;
            this.imageWidthErr = false;

            if (param === 'select') {
                this.files = event.target.files[0];
            } else {
                this.files = event.dataTransfer.files[0];
            }
            let reader = new FileReader();
            reader.readAsDataURL(this.files);
            reader.onload = () => {
                let imgPrev = document.getElementById('image-preview');
                this.blobPath = reader.result;
                imgPrev.src = reader.result;
            };
        },

        getStyleData() {
            projectService.getStyleStyle().then((res) => {
                this.styleDataforStyle = res.data;
                let defaultStyle = this.styleDataforStyle.find(
                    (element) => element.IsDefault === true
                );
                this.defaultStyle = defaultStyle.Style;
            });
            projectService.getStyleType().then((res) => {
                this.styleDataType = res.data;
                let defaultType = this.styleDataType.find(
                    (element) => element.IsDefault === true
                );
                this.defaultType = defaultType.Type;
            });
            projectService.getStyleSize().then((res) => {
                this.styleDataSize = res.data;
                let defaultSize = this.styleDataSize.find(
                    (element) => element.IsDefault === true
                );
                this.defaultSize = defaultSize.Size;
            });
        },

        getStyleBlock() {
            projectService.getStyleBlock().then((res) => {
                this.styleDataRaw = res.data;

                const filteredArr = this.styleDataRaw.reduce((acc, current) => {
                    const x = acc.find(
                        (item) => item.StyleStyle === current.StyleStyle
                    );
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);

                this.styleData = res.data;
                this.styleDataType = res.data;
                this.styleDataforStyle = filteredArr;
                this.styleDataSize = res.data;
            });
        },

        getDataSetColumn() {
            reportingService
                .getDataSetColumn(localStorage.getItem('datasetid'))
                .then((res) => (this.datasetCoumn = res.data));
        },
        disableBtnSave() {
            this.btnDisable = false;
            for (var item in this.titleBlock) {
                if (this.titleBlockError[item]) {
                    if (this.titleBlock[item].text == '') {
                        this.btnDisable = true;
                    }
                    if (this.titleBlock[item].textSize == '') {
                        this.btnDisable = true;
                    }
                    if (this.titleBlock[item].textStyle == '') {
                        this.btnDisable = true;
                    }
                    if (this.titleBlock[item].textType == '') {
                        this.btnDisable = true;
                    }
                }
            }
        },
        displayError() {
            this.showError = false;
            for (var item in this.titleBlock) {
                if (this.titleBlockError[item]) {
                    if (this.titleBlock[item].textSize !== null) {
                        this.titleBlockError[item].textSize = false;
                    } else {
                        this.showError = true;
                        this.titleBlockError[item].textSize = true;
                    }
                    if (this.titleBlock[item].textStyle !== null) {
                        this.titleBlockError[item].textStyle = false;
                    } else {
                        this.showError = true;
                        this.titleBlockError[item].textStyle = true;
                    }
                    if (this.titleBlock[item].textType !== null) {
                        this.titleBlockError[item].textType = false;
                    } else {
                        this.showError = true;
                        this.titleBlockError[item].textType = true;
                    }
                }
            }
        },
        loadDefaultStyle(index) {
            // this.titleBlock[index].styleGHD=!this.titleBlock[index].styleGHD;

            if (this.titleBlock[index].styleGHD) {
                this.titleBlock[index].textSize = this.defaultSize;
                this.titleBlock[index].textColor = '#000000';
                this.titleBlock[index].textStyle = this.defaultStyle;
                this.titleBlock[index].textType = this.defaultType;
            } else {
                this.titleBlock[index].textSize = this.titleBlock[
                    index - 1
                ].textSize;
                this.titleBlock[index].textColor = this.titleBlock[
                    index - 1
                ].textColor;
                this.titleBlock[index].textStyle = this.titleBlock[
                    index - 1
                ].textStyle;
                this.titleBlock[index].textType = this.titleBlock[
                    index - 1
                ].textType;
            }
        },
        handleBlur(index) {
            if (this.titleBlockError[index]) {
                if (this.titleBlock[index].text === '') {
                    this.titleBlockError[index].text = true;
                } else {
                    this.titleBlockError[index].text = false;
                }
            }
        },
        resetDefaultStyle(index) {
            if (this.titleBlock[index].styleGHD) {
                this.titleBlock[index].styleGHD = false;
            }
        },

        saveTitleBlock() {
            this.displayError();
            if (this.showError == false) {
                let titleBlockTemp = [];
                let titleBlockData = {};
                let sizeId;
                let styleId;
                let typeId;

                this.titleBlock.forEach((item, index) => {
                    item.text = document
                        .getElementById('titleBlockText' + index)
                        .innerText.replace(/\s/gim, ' ');
                    item.text = item.text
                        .replace(/</gim, '&lt;')
                        .replace(/>/gim, '&gt;');
                    if (item.text === '') {
                        this.titleBlockError[index].text = true;
                    }
                    if (item.textSize === null) {
                        this.titleBlockError[index].textSize = true;
                    }
                    if (item.textStyle === null) {
                        this.titleBlockError[index].textStyle = true;
                    }
                    if (item.textType === null) {
                        this.titleBlockError[index].textType = true;
                    }
                    if (
                        item.textType !== null &&
                        item.textStyle !== null &&
                        item.textSize !== null &&
                        item.text !== ''
                    ) {
                        this.titleBlockError[index].textSize = false;
                        this.titleBlockError[index].textStyle = false;
                        this.titleBlockError[index].textType = false;
                        this.titleBlockError[index].text = false;
                    }
                });

                let errorFound = this.titleBlockError.filter(
                    (e) => e.textSize || e.textStyle || e.textType || e.text
                );

                if (errorFound.length == 0) {
                    if (this.titleBlock[0].TitleBlockId) {
                        this.titleBlock.forEach((item) => {
                            if (item.textSize) {
                                sizeId = this.styleDataSize.find(
                                    (element) => element.Size === item.textSize
                                );
                            }
                            if (item.textStyle) {
                                styleId = this.styleDataforStyle.find(
                                    (element) =>
                                        element.Style === item.textStyle
                                );
                            }
                            if (item.textType) {
                                typeId = this.styleDataType.find(
                                    (element) => element.Type === item.textType
                                );
                            }
                            if (item.text) {
                                titleBlockTemp.push({
                                    BlockText: item.text,
                                    BlockDataset: item.BlockDataset
                                        ? item.BlockDataset
                                        : '',
                                    StyleDetailId: item.StyleDetailId,
                                    TitleBlockDetailId: item.TitleBlockDetailId,
                                    DatasetColumns: '',
                                    StyleDetails: {
                                        FontTypeId: typeId ? typeId.TypeId : '',
                                        FontStyleId: styleId
                                            ? styleId.StyleId
                                            : '',
                                        FontSizeId: sizeId ? sizeId.SizeId : '',
                                        StyleColor: item.textColor
                                            ? item.textColor
                                            : '#000000',
                                        StyleDetailId: item.StyleDetailId,
                                        IsActive: true,
                                    },
                                });
                            }
                        });

                        titleBlockData = {
                            DatasetId: localStorage.getItem('datasetid'),
                            UserId: 1,
                            FooterText: this.footerText,
                            TitleBlockId: this.titleBlock[0].TitleBlockId,
                            TitleBlockDetails: titleBlockTemp,
                            IsActive: true,
                        };
                        let vm = this;
                        reportingService
                            .updateTitleBlock(
                                titleBlockData,
                                vm.titleBlock[0].TitleBlockId
                            )
                            .then((res) => {
                                if (res) {
                                    if (res.data.StatusCode === 202) {
                                        if (vm.files.name) {
                                            const formData = new FormData();

                                            formData.append(
                                                'FormFile',
                                                vm.files,
                                                vm.files.name
                                            );
                                            formData.append(
                                                'TitleBlockId',
                                                vm.titleBlock[0].TitleBlockId
                                            );
                                            formData.append(
                                                'ImageId',
                                                vm.imageId ? vm.imageId : 0
                                            );
                                            formData.append(
                                                'BlobPath',
                                                vm.blobPath
                                            );
                                            formData.append(
                                                'ImageName',
                                                vm.files.name
                                            );
                                            reportingService
                                                .updateTitleBlockImage(
                                                    formData,
                                                    vm.imageId ? vm.imageId : 0
                                                )
                                                .then((res) => {
                                                    vm.getTitleBlock();
                                                });
                                        }

                                        vm.showSuccess = true;
                                        vm.showFailure = false;
                                        vm.successToastMessage =
                                            'Title Block updated successfully';
                                        setTimeout(() => {
                                            vm.showSuccess = false;
                                        }, 5000);
                                    } else if (res.data.StatusCode === 500) {
                                        vm.showFailure = true;
                                        vm.showSuccess = false;
                                        vm.failureToastMessage =
                                            'Title Block already exists';
                                        setTimeout(() => {
                                            vm.showFailure = false;
                                        }, 5000);
                                    }
                                }
                            });
                    } else {
                        this.titleBlock.forEach((item) => {
                            let sizeId = this.styleDataSize.find(
                                (element) => element.Size === item.textSize
                            );
                            let styleId = this.styleDataforStyle.find(
                                (element) => element.Style === item.textStyle
                            );
                            let typeId = this.styleDataType.find(
                                (element) => element.Type === item.textType
                            );

                            if (item.text) {
                                titleBlockTemp.push({
                                    BlockText: item.text,
                                    BlockDataset: item.BlockDataset
                                        ? item.BlockDataset
                                        : '',
                                    DatasetColumns: '',
                                    StyleDetailId: item.StyleDetailId,
                                    TitleBlockDetailId: item.TitleBlockDetailId,
                                    StyleDetails: {
                                        FontTypeId: typeId ? typeId.TypeId : '',
                                        FontStyleId: styleId
                                            ? styleId.StyleId
                                            : '',
                                        FontSizeId: sizeId ? sizeId.SizeId : '',
                                        StyleDetailId: item.StyleDetailId,
                                        StyleColor: item.textColor
                                            ? item.textColor
                                            : '#000000',
                                        IsActive: true,
                                    },
                                });
                            }
                        });
                        titleBlockData = {
                            DatasetId: localStorage.getItem('datasetid'),
                            UserId: 1,
                            FooterText: this.footerText,
                            TitleBlockDetails: titleBlockTemp,
                        };

                        reportingService
                            .saveTitleBlock(titleBlockData)
                            .then((res) => {
                                if (res) {
                                    if (res.data.StatusCode === 201) {
                                        if (this.files.name) {
                                            const formData = new FormData();
                                            formData.append(
                                                'FormFile',
                                                this.files,
                                                this.files.name
                                            );
                                            formData.append(
                                                'TitleBlockId',
                                                res.data.Data.TitleBlockId
                                            );
                                            reportingService
                                                .saveTitleBlockImage(formData)
                                                .then((res) => {
                                                    this.getTitleBlock();
                                                });
                                        }

                                        this.getTitleBlock();

                                        this.showSuccess = true;
                                        this.showFailure = false;
                                        this.successToastMessage =
                                            'Title Block created successfully';
                                        setTimeout(() => {
                                            this.successToast = false;
                                        }, 5000);
                                    } else if (res.data.StatusCode === 500) {
                                        this.showFailure = true;
                                        this.showSuccess = false;
                                        this.failureToastMessage =
                                            'Title Block already exists';
                                        setTimeout(() => {
                                            this.showFailure = false;
                                        }, 5000);
                                    }
                                }
                            });
                    }
                }
            }
        },

        getTitleBlock() {
            this.titleBlock = [
                {
                    text: '',
                    dataSet: [],
                    textSize: null,
                    textStyle: null,
                    textColor: '#000000',
                    textType: null,
                    styleGHD: true,
                    TitleId: '',
                    BlockDataset: '',
                    BlockId: '',
                    DatasetId: '',
                    StyleId: '',
                },
            ];
            this.footerText = '';

            reportingService
                .getTitleBlock(localStorage.getItem('datasetid'))
                .then((res) => {
                    this.titleBlockData = res.data;
                    if (this.titleBlockData) {
                        this.titleBlockData.forEach((element, index) => {
                            if (this.titleBlock.length < 5) {
                                if (index === 0) {
                                    this.footerText = element.FooterText;
                                    this.imageId = element.ImageId;
                                    this.blobPath = element.BlobPath
                                        ? element.BlobPath
                                        : '';
                                    this.titleBlock[index].id = index;
                                    this.titleBlock[index].text =
                                        element.BlockText;
                                    this.titleBlock[index].textFormat =
                                        element.BlockText;
                                    this.titleBlock[index].textSize =
                                        element.FontSize.Size;
                                    this.titleBlock[index].textStyle =
                                        element.FontStyle.Style;
                                    this.titleBlock[index].textColor =
                                        element.StyleColor;
                                    this.titleBlock[index].textType =
                                        element.FontType.Type;
                                    this.titleBlock[index].TitleBlockId =
                                        element.TitleBlockId;
                                    this.titleBlock[index].BlockDataset =
                                        element.BlockDataset;
                                    this.titleBlock[index].DatasetId =
                                        element.DatasetId;
                                    this.titleBlock[index].StyleId =
                                        element.StyleId;
                                    this.titleBlock[index].styleGHD =
                                        element.FontType.IsDefault &&
                                        element.FontStyle.IsDefault &&
                                        element.FontSize.IsDefault &&
                                        element.StyleColor == '#000000'
                                            ? true
                                            : false;
                                    this.titleBlock[index].StyleDetailId =
                                        element.StyleDetailId;
                                    this.titleBlock[index].TitleBlockDetailId =
                                        element.TitleBlockDetailId;
                                } else {
                                    this.titleBlock.push({
                                        id: index,
                                        text: element.BlockText,
                                        textFormat: element.BlockText,
                                        dataSet: [],
                                        textSize: element.FontSize.Size,
                                        textStyle: element.FontStyle.Style,
                                        textColor: element.StyleColor,
                                        textType: element.FontType.Type,
                                        styleGHD:
                                            element.FontType.IsDefault &&
                                            element.FontStyle.IsDefault &&
                                            element.FontSize.IsDefault
                                                ? true
                                                : false,
                                        StyleDetailId: element.StyleDetailId,
                                        TitleBlockDetailId:
                                            element.TitleBlockDetailId,
                                    });

                                    this.titleBlockError.push({
                                        text: false,
                                        textSize: false,
                                        textStyle: false,
                                        textType: false,
                                    });
                                }

                                let datasetString = element.BlockText.match(
                                    /<<[\w\d]+>>/g
                                );
                                let datasetString1 = element.BlockText.match(
                                    /<<[\w\d]+>>/g
                                );
                                let textareaContent = element.BlockText;
                                let previewContent = element.BlockText;

                                if (datasetString) {
                                    for (
                                        let r = 0;
                                        r < datasetString.length;
                                        r++
                                    ) {
                                        datasetString[r] = datasetString[
                                            r
                                        ].replace(/[<>]/g, '');
                                    }

                                    for (
                                        let v = 0;
                                        v < datasetString.length;
                                        v++
                                    ) {
                                        textareaContent = textareaContent.replace(
                                            datasetString1[v],
                                            '<span id="new_btn' +
                                                index +
                                                v +
                                                '" class="p-0 new_btn' +
                                                index +
                                                v +
                                                '" disabled="disabled" readonly="readonly" contenteditable="false">' +
                                                '&lt;&lt;' +
                                                datasetString[v] +
                                                '&gt;&gt;' +
                                                '</span>'
                                        );

                                        if (
                                            this.datasetForDec[index] ==
                                            undefined
                                        ) {
                                            this.datasetForDec.push({});
                                        }
                                        datasetService
                                            .GetDistinctFilteredvalues(
                                                localStorage.getItem(
                                                    'datasetid'
                                                ),
                                                datasetString[v],
                                                this.filterOptions
                                            )
                                            .then((res) => {
                                                let columnData = res.data;
                                                this.datasetForDec[index][
                                                    datasetString[v]
                                                ] = columnData[0];
                                                previewContent = previewContent.replace(
                                                    datasetString1[v],
                                                    '<span id="new_btn' +
                                                        index +
                                                        v +
                                                        '" class="new_btn' +
                                                        index +
                                                        v +
                                                        '" contenteditable="false">' +
                                                        columnData[0] +
                                                        '</span>'
                                                );
                                                document.getElementById(
                                                    'previewDataset' + index
                                                ).innerHTML = previewContent;
                                            })
                                            .catch((err) => {
                                                if (
                                                    err.response.data.Message ==
                                                    'ForbiddenSQLInjection'
                                                ) {
                                                    this.$emit(
                                                        'resForbiddenTitle',
                                                        err.response.data
                                                            .Message
                                                    );
                                                }
                                            });

                                        this.titleBlock[index].dataSet.push({
                                            dataSetName: datasetString[v],
                                        });
                                    }
                                }
                                setTimeout(() => {
                                    document.getElementById(
                                        'titleBlockText' + index
                                    ).innerHTML = textareaContent;
                                }, 500);

                                this.$forceUpdate();
                            }
                            this.titleBlockCurrentId = index;
                        });

                        this.titleBlockSavedData = this.titleBlockData.map(
                            (item) => {
                                return {
                                    size: item.FontSize.Size,
                                    style: item.FontStyle.Style,
                                    stylecolor: item.StyleColor,
                                    type: item.FontType.Type,
                                };
                            }
                        );
                    }
                });

            setTimeout(() => {
                if (this.titleBlockData.length == 0) {
                    this.loadDefaultStyle(0);
                }
            }, 1000);
            if (this.toGo) {
                if (this.renderTitle1) {
                    // this.renderTitle = false;
                    this.$emit('redirectScatter', true);
                }
            }
        },
        addDataSet(index) {
            this.titleBlock[index].dataSet.push({ dataSetName: '' });
        },
        deleteDataSet(index, indexdataset, event) {
            this.titleBlock[index]['text'] = this.titleBlock[index][
                'text'
            ].replace(
                '<<' +
                    this.titleBlock[index]['dataSet'][indexdataset]
                        .dataSetName +
                    '>>',
                ''
            );
            this.titleBlock[index]['dataSet'].splice(indexdataset, 1);
            let tagIndex = event.target.getAttribute('data-id');
            document
                .querySelectorAll('.new_btn' + index + tagIndex)
                .forEach((e) => e.remove());
            var getBtn = document.getElementById('titleBlockText' + index);
            var getSpan = document.getElementById('previewDataset' + index);
            const allSpanElements = getBtn.querySelectorAll('span');
            const allSpanElementsPreview = getSpan.querySelectorAll('span');
            var columnIndex = index;
            allSpanElements.forEach((btnElement, index) => {
                btnElement.className = 'new_btn' + columnIndex + index;
            });
            allSpanElementsPreview.forEach((btnElement, index) => {
                btnElement.className = 'new_btn' + columnIndex + index;
            });
            this.disableBtnSave();
        },
        updateValue(value, index) {
            let valP = value;
            if (valP.length <= 1) {
                this.disableBtnSave();
            }
            document.getElementById(
                'previewDataset' + index
            ).innerHTML = valP
                .replace(/(<span)/gim, '<span')
                .replace(/<\/span>/gim, '</span>');
            for (let span of document
                .getElementById('previewDataset' + index)
                .querySelectorAll('span')) {
                span.innerHTML = this.datasetForDec[index][
                    span.innerText.replace(/[<>]/g, '')
                ];
            }
            this.titleBlock[index].text = document.getElementById(
                'previewDataset' + index
            ).innerText;
            this.titleBlock[index].textFormat = document.getElementById(
                'titleBlockText' + index
            ).innerHTML;

            this.$forceUpdate();
        },
        appendText(index, indexdataset) {
            /*if (
        !this.titleBlock[index]["text"].includes(
          this.titleBlock[index]["dataSet"][indexdataset].dataSetName
        )
      ) {*/
            // this.titleBlock[index]["text"] =
            //   this.titleBlock[index]["text"] +
            //   "<<" +
            //   this.titleBlock[index]["dataSet"][indexdataset].dataSetName +
            //   ">>";

            var parent = document.getElementById('titleBlockText' + index);

            // Add div
            var div_obj = document.createElement('span');

            // Set attribute for div, such as id
            div_obj.setAttribute('id', 'new_btn' + index + indexdataset);
            div_obj.setAttribute('class', 'new_btn' + index + indexdataset);
            div_obj.setAttribute('disabled', 'disabled');
            div_obj.setAttribute('readonly', 'readonly');
            div_obj.setAttribute('contenteditable', false);
            div_obj.innerText =
                '<<' +
                this.titleBlock[index]['dataSet'][indexdataset].dataSetName +
                '>>';
            parent.appendChild(div_obj);

            datasetService
                .GetDistinctFilteredvalues(
                    localStorage.getItem('datasetid'),
                    this.titleBlock[index]['dataSet'][indexdataset].dataSetName,
                    this.filterOptions
                )
                .then((res) => {
                    let columnDataAded = res.data;
                    if (this.datasetForDec.length > 0) {
                        if (this.datasetForDec[index] == undefined) {
                            // this.datasetForDec.insert(index, {});
                            this.datasetForDec.splice(index, 0, {});
                            this.datasetForDec[index][
                                this.titleBlock[index]['dataSet'][
                                    indexdataset
                                ].dataSetName
                            ] = columnDataAded[0];
                        } else {
                            this.datasetForDec[index][
                                this.titleBlock[index]['dataSet'][
                                    indexdataset
                                ].dataSetName
                            ] = columnDataAded[0];
                        }
                    } else {
                        this.datasetForDec.push({});
                        this.datasetForDec[index][
                            this.titleBlock[index]['dataSet'][
                                indexdataset
                            ].dataSetName
                        ] = columnDataAded[0];
                    }
                })
                .catch((err) => {
                    if (err.response.data.Message == 'ForbiddenSQLInjection') {
                        this.$emit(
                            'resForbiddenTitle',
                            err.response.data.Message
                        );
                    }
                });

            var dropDownVal = document.getElementById('titleBlockText' + index)
                .innerHTML;
            setTimeout(() => {
                this.updateValue(dropDownVal, index);
            }, 2000);
            this.titleBlock[index].text = document.getElementById(
                'titleBlockText' + index
            ).innerText;
            this.disableBtnSave();
            // }
        },
        addFile(e) {
            e.stopPropagation();
            e.preventDefault();
            this.files = [];
            this.disableSave = false;
            this.imageWidthErr = false;
            this.$refs.myFile.value = null;
            let droppedFiles = e.dataTransfer.files;
            let droppedFilesType = droppedFiles[0].type;
            let imageType = /image.*/;
            if (droppedFilesType.match(imageType)) {
                this.fileName = droppedFiles[0].name;
                let vm = this;
                [...droppedFiles].forEach((f) => {
                    vm.files.push(f);
                    vm.files = vm.files[0];
                });
            } else {
                this.fileName = '';
                this.disableSave = true;
            }
            this.$refs.myFile = e.dataTransfer.files;
            let file = this.$refs.myFile;
            let vm = this;

            let reader = new FileReader();

            reader.readAsDataURL(file[0]);

            reader.onload = (evt) => {
                let img = new Image();
                img.onload = () => {
                    vm.image.width = img.width;
                    vm.image.height = img.height;
                    vm.imageLoaded = true;
                    if (img.width > 5000 || img.height > 5000) {
                        vm.imageWidthErr = true;
                        // document.querySelector("#uploadImage").value = "";
                    } else {
                        vm.imageWidthErr = false;
                    }
                    document.querySelector("input[type='file']").value = '';
                };
                img.src = evt.target.result;
            };
        },

        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        viewChange() {
            this.mapView = !this.mapView;
        },
        openTableselectTool: function() {
            if (this.currentSwitchState) {
                let routeData = this.$router.resolve({
                    name: 'tableselectiontool',
                });
                window.open(routeData.href, '_blank');
            } else {
                router.push(`/classic/tableselectiontool`);
            }
        },
        closeSuccessToast() {
            this.successToast = false;
        },
        closeFailureToast() {
            this.failureToast = false;
        },
        colorPicker() {
            this.pickColor = true;
        },
        // cancelTitleBloc(){
        //   this.$emit("closetitleBlock", true)
        // }
    },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
    background-color: white !important;
}
.define-tool {
    // border-top: 2px solid #dee2e6;

    p {
        font-size: 16px;
        color: #515151;
        margin-left: -16px;
        //white-space: nowrap;
    }
    .custom-select {
        background: #fff url('../../../assets/arrow_down.svg') no-repeat right
            0.75rem center/8px 10px !important;

        height: 48px;
    }
    button {
        border-radius: 3px;
    }
    .block-1 {
        display: block;
        border: 1px solid #dee2e6;
        height: 200px;
        border-radius: 3px;

        // h5 {
        //   font-size: 16px;
        // }
        .line-block {
            background-color: #eaeaea;
            margin-left: -15px;
            margin-right: -15px;
            textarea {
                height: 100px;
                width: 400px;
                border-radius: 3px;
            }
            .small_drop {
                width: 333px;
            }
            .delete {
                width: 46px;
                height: 46px;
                float: right;

                /* margin-left: 9px; */
                margin-right: 29px;
                margin-top: 10px;
                background: url('../../../assets/delete_icon.svg') no-repeat
                    right !important;
            }
            .style-block {
                select {
                    width: 400px;
                }
                img {
                    min-width: 0px !important;
                    position: relative;
                    top: 5px;
                }
                span {
                    font-size: 18px;
                    font-weight: bold;
                }
                input {
                    width: 400px;
                    height: 48px;
                    margin-top: -7px;
                    margin-bottom: 8px;
                    border: 0px;
                    background: transparent;
                }
            }
            span {
                font-size: 16px;
            }
        }
        .upload_logo {
            span {
                margin-left: -15px;
                font-size: 14px;
            }

            .drag-drop {
                margin-left: -16px;
                margin-right: auto;
                margin-top: 10px;
                text-align: center;
                border: dotted #c6c6c6;
                background-color: #f8f8f8;
                width: 454px;
                height: 192px;
                p {
                    text-align: center;
                    // margin-top: 50px;
                    width: 250px;
                    margin-left: auto;
                    margin-right: auto;
                    a {
                        font-size: 16px;
                        color: #2761df !important;
                    }
                }
            }
            button {
                background-color: #006395;
                padding: 5px 15px;
                color: #fff;
                margin-left: -14px;
                border: 0 !important;
                margin-top: 10px;
                border-radius: 3px;
            }
        }
        .footer_text {
            margin-left: -15px;
            margin-top: 30px;
            textarea {
                width: 454px;
                height: 80px;
                border-radius: 3px;
                margin-bottom: 169px;
            }
            span {
                font-size: 18px;
                //font-family: Arial;
            }
        }
        .borderBlue {
            width: 50px;
        }
    }
    .block-2 {
        .preview_logo {
            position: fixed;
            top: 285px;

            .side_block {
                // border: 1px solid #dee2e6;
                display: block;
                border-radius: 3px;
                // min-height: 137px;
                height: auto;
                width: 499px;
                word-break: break-all;
                padding-right: 10px;
                // box-shadow: 0 1px 1px 1px #dee2e6 !important;
                background-color: #fff;
                /*.content-panel{
        min-height: 80px;
        }*/
                .vmiddle {
                    img {
                        bottom: 33%;
                    }
                }
                .line_block {
                    text-align: right;
                    // padding-top: 10px;
                    span {
                        font-size: 14px;
                        padding-top: 10px;
                        padding-right: 5px;
                    }
                }
                .footer-section {
                    // min-height:40px;
                    height: auto;
                    position: relative;
                    bottom: 0;
                    width: 100%;
                    span {
                        text-align: left;
                        font-size: 10px;
                    }
                    span.center {
                        text-align: center;
                    }
                }
            }
        }
    }
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-left: 24px;
    width: 400px;
    text-align: center;
    padding: 10px 10px;
}
.normal_button {
    width: 454px;
    margin-left: -16px;
    padding: 10px;
}
.normal_button1 {
    padding: 10px;
}

.text-right.icons {
    margin-top: -10px;
    //margin-left: 40px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}

.open-toggle {
    float: right;
    margin-top: 8px;
}
.border {
    height: 40px;
    left: 5px;
    /* width: 987px; */
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}

.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    left: 1px;
    top: 12px;
    z-index: 9999999;
}
/* .border-right {
  margin-left: -36px !important;
} */

.titleBlock {
    margin: 10px 15px;
    height: 30px;
}
.titleBlock small:hover {
    border: 3px solid #b034a1;
    height: 30px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 30px;
    width: 30px;
    text-align: center;
}

.graphing {
    margin: 10px 15px;
    height: 30px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 30px;
}
.graphing span {
    background-color: #8456b0;
    height: 30px;
    width: 30px;
    text-align: center;
}

.dataset {
    margin: 10px 15px;
    height: 30px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 30px;
}
.dataset span {
    background-color: #5878be;
    height: 30px;
    width: 30px;
    text-align: center;
}

.contouring {
    margin: 10px 15px;
    height: 30px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 30px;
}
.contouring span {
    background-color: #3296ca;
    height: 30px;
    width: 30px;
    text-align: center;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}

.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.preview {
    position: absolute;
    //bottom: 47%;
}
.title-m-view p {
    position: absolute;
    top: -95px;
    right: 15px;
}
.title-m-view-btn .savebtn {
    position: absolute;
    top: -105px;
    right: -225px;
}
#e-split-btn_0_dropdownbtn {
    right: 1px;
}
.wrap {
    display: inline-block;
    width: 400px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 15px;
}

/* Input element customization */
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px !important;
    margin: 4px 0 0 0;
    opacity: 1;
    //position: relative;
    width: 75px;

    // left:55px;
    //top: 4px;
}
#element .e-apply {
    color: #006395 !important;
    font-size: 14px !important;
}

.e-colorpicker-wrapper .e-colorpicker,
.e-colorpicker-container .e-colorpicker {
    opacity: 1;
    width: 499px;
    height: 48px;

    padding-left: 15px;
    font-size: 14px;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none;
}

/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    border-bottom-color: rgba(0, 0, 0, 0.42);
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}

.e-colorpicker-wrapper,
.e-colorpicker-container {
    display: inline-block;
    line-height: 0;
    outline: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.datasetcolumns
    .e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-split-colorpicker.e-split-btn,
.datasetcolumns .e-colorpicker-wrapper .e-btn.e-icon-btn {
    z-index: 9;
    position: relative;
    top: -34px;
    left: 5px;
    background: transparent !important;
}
.datasetcolumns
    .e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-split-colorpicker
    + .e-dropdown-btn {
    background-color: transparent !important;
    margin-left: -20px;
    z-index: 999;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    position: absolute !important;
    top: 7px !important;
    left: 73px !important;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    margin-top: 7px;
    bottom: 0 !important;
}
.e-icon-btn.e-split-btn {
    margin-left: 12px;
}
.e-split-btn {
    position: relative;
    top: 7px;
    left: 12px;
}
.iconplacing {
    text-align: center;
    padding-top: 13px;
    position: relative;
    top: 10px;
}
.saveHeader {
    height: 0;
}
.saveHeader .align-items-center {
    float: right;
    margin-top: -117px;
    margin-right: -12px;
}
.style-block .e-split-btn-wrapper {
    float: left !important;
}
.titleSave {
    float: right;
    margin-left: 190px !important;
    z-index: 0;
}
.fileError {
    color: #f00;
    margin-left: -16px;
}
@media only screen and (max-width: 991px) {
    .wrap {
        width: 325px;
    }
}

.footerText {
    padding: 5px 0;
    border-top: 1px solid #dee2e6;
}
.line_block:last-child {
    margin-bottom: 5px;
}

[id^='titleBlockText'] {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(204, 204, 204);
    margin: 24px;
    resize: vertical;
    overflow: auto;
    height: 70px;
    width: 400px;
}
@media only screen and (min-width: 375px) and (max-width: 767px) {
    .wrap {
        width: 295px;
    }
    .portalTabs ul.nav-tabs li a {
        height: 28px;
    }
}
.text-optional {
    font-family: 'IBMPlexSans-Medium';
    font-size: 10px;
}
.lineRemoveButton {
    margin: 16px 24px !important;
}
</style>
