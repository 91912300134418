<template>
    <div id="mobile-map-bottom-bar">
        <div
            id="mobile-bottom-bar-content"
            :class="showOptions ? 'show-options' : 'hide-options'"
        >
            <button
                v-if="showOptions"
                class="bottom-bar-options-toggle"
                @click="toggleOptions"
            >
                <img src="../../../assets/EM_arrow_down.svg" /><br />
                Hide options
            </button>
            <button
                v-else
                class="bottom-bar-options-toggle"
                @click="toggleOptions"
            >
                <img src="../../../assets/EM_arrow_up.svg" /><br />Show options
            </button>
            <div class="toolbar">
                <!-- this button is disabled since Event Log is Phase 2 -->
                <button @click="openProjectDetails" class="fixed-width">
                    <img
                        src="../../../assets/event_panel.svg"
                        class="tool-button-icon"
                    /><br />Project details
                </button>
                <button @click="openTableOfContents" class="dynamic-width">
                    <img
                        src="../../../assets/layers-icon.svg"
                        class="tool-button-icon"
                    /><br />Map layers
                </button>
                <button @click="openAttributes" class="fixed-width">
                    <img
                        src="../../../assets/identify.svg"
                        class="tool-button-icon"
                    /><br />Attributes
                </button>
            </div>
        </div>
        <mobile-bottom-pane
            :componentName="paneComponent"
            :identifyResults="identifyResults"
            @close="paneComponent = ''"
            @clearResults="
                () => {
                    $emit('clearResults');
                    $emit('clearHighlight');
                }
            "
            @clearHighlight="
                () => {
                    $emit('clearHighlight');
                }
            "
            @zoomTo="
                (feature) => {
                    $emit('zoomTo', feature);
                }
            "
            @highlight="
                (feature) => {
                    $emit('highlight', feature);
                }
            "
            @reverseLookup="
                (layerID, featureID) => {
                    $emit('reverseLookup', layerID, featureID);
                }
            "
            @updateClickFunction="updateClickFunction"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import MobileBottomPane from './MobileBottomPane.vue';
export default {
    name: 'MobileMapBottomBar',
    components: {
        MobileBottomPane,
    },
    props: {
        identifyResults: { type: Array, required: false, default: () => [] },
    },
    data() {
        return {
            paneComponent: '',
            showOptions: false,
        };
    },
    created() {},
    mounted() {},
    methods: {
        expandBottomMenu() {},
        toggleOptions() {
            this.showOptions = !this.showOptions;
        },
        openProjectDetails() {
            this.paneComponent = 'CommonOperatingPicture';
        },
        openTableOfContents() {
            this.paneComponent = 'ContentTable';
        },
        openAttributes() {
            this.paneComponent = 'MapIdentifyResults';
        },
        openPrintPane() {
            this.paneComponent = 'PrintPane';
        },
        openMeasurePane() {
            this.paneComponent = 'MeasurePane';
        },
        openDrawPane() {
            this.paneComponent = 'DrawPane';
        },
        clearPaneComponent() {
            this.paneComponent = '';
        },
        updateClickFunction(clickFunction) {
            this.$emit('updateClickFunction', clickFunction);
            if (['point', 'line', 'polygon'].includes(clickFunction)) {
                this.clearPaneComponent();
            }
        },
    },
    computed: {},
};
</script>

<style scoped>
#mobile-map-bottom-bar {
    transition: 300ms;
}

#mobile-bottom-bar-content {
    transition: 300ms;
    padding: 0;
    height: 192px;
    background-color: white;
    pointer-events: all;
}

.hide-options {
    transform: translateY(128px);
}

.show-options {
    transform: translateY(0px);
}

.bottom-bar-options-toggle {
    background-color: transparent;
    border: none;
    text-align: center;
    width: 100%;
    height: 64px;
}

.toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.toolbar > button {
    border: 1px solid grey;
    background-color: inherit;
    height: 128px;
    width: 33.3%;
    padding: 0;
}

.toolbar > button:active {
    border-color: darkslategray;
    background-color: rgb(222, 222, 222);
}
.tool-button-icon {
    height: 32px;
}

.fixed-width {
    flex-grow: 0;
}

.dynamic-width {
    flex-grow: 1;
}
</style>
