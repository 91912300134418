<template>
    <div v-if="showPage">
        <b-container fluid class="selectdatasource sub_header_bg">
            <b-container class="d-p-0">
                <b-row class="mb-3">
                    <b-col cols="12 my-1">
                        <a @click="navigateDashboard">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a href="#" class="floar-left ml-1 back">
                                Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>

                <b-row class="vh-10 text-left datasource" align-v="center">
                    <b-col cols="8" class="pt-2">
                        <h1 class="">Datasources</h1>
                    </b-col>
                    <b-col cols="4" class="text-right">
                        <b-button
                            v-if="isClicked && dataSetList"
                            rounded
                            class="buttonSelect"
                            @click="navigateDataSetList"
                        >
                            Open datasource
                        </b-button>
                    </b-col>
                </b-row>

                <b-row class="pt-2">
                    <div class="borderBlue mt-3 ml-3">
                        <h5>Datasources</h5>
                    </div>
                </b-row>
            </b-container>
        </b-container>

        <b-container class="p-0 dataset-defination">
            <b-col lg="8" class="m-row m-0 m-shadow p-0 mb-2">
                <div class="py-2 filter-bar">
                    <!-- <b-col cols="11" md="6" class="blue-color p-0 dataset-m m-shadow ml-3"> -->
                    <!-- <a href="#" class="ml-3"
              ><input v-model="filter" :class="filter ? 'test':'searchinput'" type="search" /></a> -->
                    <div class="float-left">
                        <img
                            class="pointer ml-2 mt-0"
                            src="../../assets/search-outline.svg"
                            v-if="!filter && !showSearchIcon ? true : false"
                            @click="searchOpen"
                        />
                        <input
                            ref="datasetSearch"
                            spellcheck="false"
                            id="searchFilter"
                            class=""
                            v-if="filter || showSearchIcon ? true : false"
                            v-model="filter"
                            @click="searchinputenter()"
                            @input="searchinputenter"
                            :class="
                                filter || showSearchIcon
                                    ? 'test'
                                    : 'searchinputList'
                            "
                            type="search"
                            placeholder="Search for datasources"
                        />
                        <span
                            class="grey-bg"
                            v-if="searchClose"
                            @click="closeBar"
                            ><img src="../../assets/close.svg"
                        /></span>
                        <span class="border-right ml-2">&nbsp;</span>
                    </div>
                    <!-- <a href="#" class="ml-2 mr-1 flt-m-view"  @click="filterdata"
              ><img src="../../assets/tooltip.svg"
            /></a>
            <span class="blue-text m-none"  @click="filterdata">Filter</span> -->
                    <div class="float-left">
                        <span
                            class="ml-3 mr-3 pointer m-filter"
                            @click="filterdata"
                            ><img src="../../assets/tooltip.svg"
                        /></span>
                        <span
                            class="blue-text pointer m-none"
                            @click="filterdata"
                            >Filter</span
                        >

                        <b-list-group-item
                            class="filter-group left-align p-0"
                            v-if="filterShow"
                        >
                            <div class="ml-4 mp-10">
                                <div class="mb-1 mb-rem">
                                    Filter by
                                </div>
                                <div class="ml-5 mbt">
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Datasource Name',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="datasurcename"
                                            class="select_option"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    value=""
                                                    disabled
                                                    >Datasource
                                                    Name</b-form-select-option
                                                >
                                            </template>
                                            <option
                                                v-for="(item,
                                                index) in datasources"
                                                :key="index"
                                                v-bind:value="
                                                    item.DataSourceName
                                                "
                                            >
                                                {{ item.DataSourceName }}
                                            </option>
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                    <CustomFloatingLabel
                                        class="FloatingTool"
                                        :config="{
                                            label: 'Datasource Description',
                                            name: 'wrapper',
                                            line: false,
                                            scale: false,
                                        }"
                                    >
                                        <b-form-select
                                            v-model="desc"
                                            class="select_option"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    value=""
                                                    disabled
                                                    >Datasource
                                                    Description</b-form-select-option
                                                >
                                            </template>
                                            <option
                                                v-for="(item,
                                                index) in datasources"
                                                :key="index"
                                                v-bind:value="
                                                    item.DataSourceDesc
                                                "
                                            >
                                                {{ item.DataSourceDesc }}
                                            </option>
                                        </b-form-select>
                                    </CustomFloatingLabel>
                                    <a href="javascript:void(0)">
                                        <b-icon
                                            icon="x"
                                            class="abs-top"
                                            font-scale="2"
                                            @click="backFilter('desc')"
                                        >
                                        </b-icon>
                                    </a>
                                </div>
                                <b-button class="mb-3" @click="applyFilter"
                                    >Apply filters</b-button
                                >
                            </div>
                        </b-list-group-item>
                    </div>

                    <!-- </b-col> -->
                </div>

                <!-- <b-col class="searchzindexM">
              <a href="#"><img id="searchBtn" class="filtericonM" src="../../assets/filter1.svg" /></a>
              <label class="position-relative d-block filterborder">
    <b-icon icon="search" class="position-absolute h-100 text-muted ml-2"></b-icon>
        <b-input class="inputsearchM m-shadow" placeholder="Search"></b-input>
   
  </label>

            </b-col> -->
            </b-col>
            <div class="col-lg-4 col-md-12 backfiltermargin p-0">
                <b-row v-if="applyfilter" class="pl-3">
                    <div class="px-0 py-1" v-if="datasurcename">
                        <span class="filteredItem px-2 py-1 mr-2 text-center">
                            {{ datasurcename }}
                            <b-icon
                                icon="x"
                                font-scale="1"
                                @click="backFilter('datasurcename')"
                            >
                            </b-icon>
                        </span>
                    </div>
                    <div class="px-0 py-1" v-if="desc">
                        <span class="filteredItem px-2 py-1 mr-2 text-center">
                            {{ desc }}
                            <b-icon
                                icon="x"
                                font-scale="1"
                                @click="backFilter('desc')"
                            >
                            </b-icon>
                        </span>
                    </div>

                    <b-col class="removeFilter blue-text">
                        <a @click="removeFilter"> Remove All Filters </a>
                    </b-col>
                </b-row>
            </div>
        </b-container>

        <b-container class="p-0 list-block">
            <div v-if="datasources" class="p-0 border-top mt-2">
                <div
                    id="datasetList"
                    class="pl-4 p-3 border-bottom data-list"
                    :style="
                        selectedSource == item.DataSourceId && isClicked
                            ? {
                                  'background-color': '#e6e6e6',
                                  border: '2px solid #212121 !important',
                              }
                            : null
                    "
                    @click="
                        toggleIsClicked(item.DataSourceId, item.DataSourceName)
                    "
                    :per-page="perPage"
                    v-for="item in applyfilter
                        ? datasourceFilterd
                        : filteredItems"
                    :key="item.DataSourceId"
                >
                    <h6 class="tick-mark">
                        {{ item.DataSourceName }}
                        <img
                            v-if="
                                selectedSource == item.DataSourceId && isClicked
                            "
                            src="../../assets/tick_mark.svg"
                        />
                    </h6>

                    <p class="mb-0">{{ item.DataSourceDesc }}</p>
                </div>
                <p v-if="datasources.length == 0" class="py-2">
                    No records to display
                </p>
                <b-row v-if="datasources.length > 0">
                    <div class="py-4 col-center">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="datasetList"
                        ></b-pagination>
                    </div>
                </b-row>

                <!-- <jw-pagination
                    :items="applyfilter?datasourceFilterd:datasources"
                    @changePage="onChangePage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination> -->

                <!-- <paginate-links for="datasources" :async="true"></paginate-links> -->
                <!-- <paginate-links for="datasourceFilterd" :async="true"></paginate-links> -->
            </div>
        </b-container>
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import router from '../../router';
import { mapActions } from 'vuex';
import Vue from 'vue';
import JwPagination from 'jw-vue-pagination';
import { dataSourceService } from '../../services/datasources.service';
Vue.component('jw-pagination', JwPagination);
import VuePaginate from 'vue-paginate';
import AccessPermissionModal from '../AccessPermissionModal';
import CustomFloatingLabel from '../CustomFloatingLabel';
//import dashboard from '../../views/Dashboard';
Vue.use(VuePaginate);

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    data() {
        return {
            isClicked: false,
            selectedSource: 0,
            selectedSourceName: '',
            filter: null,
            filterShow: false,
            applyfilter: false,
            datasurcename: '',
            desc: '',
            datasourceFilterd: [],
            customLabels,
            pageOfItems: [],
            datasources: [],
            filterd: false,
            perPage: 4,
            paginate: ['datasources'],
            showPage: true,
            dataSetList: false,
            thisfilename: null,
            currentPage: 1,
            datasourcesFiltered: [],
            showSearchIcon: false,
            searchClose: false,
        };
    },
    components: {
        AccessPermissionModal,
        CustomFloatingLabel,
        // dashboard,
    },
    created() {
        this.getDataSources();
        if (this.$store.state.store.portalsettings !== undefined) {
            if (this.$store.state.store.portalsettings.Pagination) {
                this.perPage = this.$store.state.store.portalsettings.Pagination;
            }
        }
    },
    computed: {
        //  ...mapState({
        //   datasources: state => state.datasources.datasources
        // }),
        filteredItems() {
            let vm = this;
            if (vm.filter) {
                // return this.pageOfItems.filter(item => {
                vm.datasources = vm.datasourcesFiltered.filter((item) => {
                    return (
                        item.DataSourceName.toLowerCase().indexOf(
                            vm.filter.toLowerCase()
                        ) > -1
                    );
                });
            } else {
                vm.datasources = vm.datasourcesFiltered;
            }
            return vm.datasources.slice(
                (vm.currentPage - 1) * vm.perPage,
                vm.currentPage * vm.perPage
            );
        },
        rows() {
            return this.datasources.length;
        },
    },
    mounted() {
        // this.getPermissions();
        //this.$store.dispatch("getPermissions");
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        pagePermit() {
            this.showPage = true;
            this.dataSetList = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName == 'SelectDataSources') {
                    vm.showPage = true;
                }
                if (element.PageName == 'DataSetList') {
                    vm.dataSetList = true;
                }
            });
            if (!this.showPage) {
                this.$refs['permission-modal'].showPermissionModal();
            }
        },
        navigateDataSetList() {
            router.push({
                path:
                    `/datasetdefinition/datasetlist/` +
                    this.selectedSource +
                    `/` +
                    this.selectedSourceName,
            });
        },
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.datasetSearch.focus();
            });
        },
        searchOpen() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = true;
            this.$nextTick(() => {
                this.$refs.datasetSearch.focus();
            });
        },
        closeBar() {
            this.searchClose = !this.searchClose;
            this.showSearchIcon = false;
            this.filter = null;
        },

        searchinputenter(args) {
            if (args) {
                this.showSearchIcon = false;
                if (!this.filter) {
                    this.searchClose = false;
                }
            }
        },
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        toggleIsClicked(id, sourceName) {
            this.isClicked = !this.isClicked;
            this.selectedSource = id;
            this.selectedSourceName = sourceName;
        },
        filterdata() {
            this.filterShow = true;
        },
        backFilter(item) {
            this.filterShow = false;
            if (item == 'datasurcename') {
                this.datasurcename = '';
            }
            if (item == 'desc') {
                this.desc = '';
            }
            this.applyFilter();
            if (this.datasurcename == '' && this.desc == '') {
                this.applyfilter = false;
            } else {
                this.applyfilter = true;
            }
        },
        applyFilter() {
            this.filterShow = false;
            if (this.datasurcename || this.desc) {
                this.datasourceFilterd = this.datasources;

                if (this.datasurcename) {
                    this.datasourceFilterd = this.datasourceFilterd.filter(
                        (item) => item.DataSourceName === this.datasurcename
                    );
                }
                if (this.desc) {
                    this.datasourceFilterd = this.datasourceFilterd.filter(
                        (item) => item.DataSourceDesc === this.desc
                    );
                }

                this.applyfilter = true;

                this.datasources = this.datasources.slice(
                    (this.currentPage - 1) * this.perPage,
                    this.currentPage * this.perPage
                );
            }
        },
        removeFilter() {
            this.applyfilter = false;
            this.datasurcename = '';
            this.desc = '';
        },
        onChangePage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        getDataSources() {
            dataSourceService.getDataSources().then((res) => {
                this.datasources = res.data;
                this.datasourcesFiltered = res.data;
            });
        },
        clickedSearch() {
            this.filterd = true;
        },

        //  ...mapActions("datasources", {
        //getDataSources: "getDataSources"
        // })
    },
};
</script>

<style scoped>
h6 {
    font-family: 'IBMPlexSans-Medium';
    font-weight: 500;
}
small {
    font-size: 12px !important;
}
p {
    font-size: 16px !important;
    font-family: 'IBMPlexSans-Regular';
}
.tick-mark img {
    float: right;
}
.select_option {
    width: 275px;
    height: 48px;
    border-radius: 4px;
    padding: 5px 8px;
}
input.searchinput {
    background: url('../../assets/search-outline.svg') no-repeat !important;
    border: none;
    width: 25px;
    position: relative;
    top: 5px;
}
.filter-option {
    margin-top: -22px;
}
.filter-group {
    width: 200px;
}

/* :focus {outline:none; border:none !important;} */
.paginationStyle {
    border: none;
    /* margin: 0px auto !important; */
    width: 200px !important;
    margin: 20px 0px !important;
    padding: 0px;
    position: relative;
    left: 40%;
}

.filter-option .form-control {
    max-width: 125px;
}
form-control:disabled,
.form-control {
    background-color: #e9ecef !important;
    opacity: 1;
}
a.nav-tabs .nav-link {
    color: #1d1d1d !important;
    font-size: 16px !important;
}
.dataset-defination .col-center {
    margin: 0 auto;
}
.dataset-defination {
    position: relative;
}
.filteredItem {
    font: normal normal medium 14px/24px IBM Plex Sans;
    letter-spacing: 0.1px;
    color: #000000de;
    height: 30px;
    background: #eaeaea 0% 0% no-repeat padding-box;
    border-radius: 2px;
}
.backfiltermargin {
    margin: 0 0 0 20px;
    float: left;
    display: contents;
    line-height: 15px;
}
input.test {
    margin: 0 0px 0 0px !important;
    background: #f3f3f3 url(../../assets/search-outline-grey.svg) no-repeat left;
    padding-left: 40px;
    background-position: left 9px bottom 14px;
    font-size: 14px;
    color: #626262;
    width: 464px !important;
    /* //margin-right: -20px!important; */
    height: 48px;
    border-top: 0;
    border-left: 0px;
    border-right: 0px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
@media only screen and (max-width: 767px) {
    .blue-color.dataset-m img.pointer {
        margin-top: 14px;
    }
    .blue-color.dataset-m input[type='search'] {
        margin-top: 10px !important;
    }
}
</style>
