var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',_vm._l((_vm.attributes),function(att){return _c('div',{key:att.ToolIdsavedToolAttributeArray,staticClass:"pl-2 mb-3"},[(att.ToolAttributes.length)?_c('div',[_c('h4',[_vm._v(_vm._s(att.ToolName))]),_c('b-form-checkbox',{staticClass:"checkbox",attrs:{"checked":_vm.isChecked(att.ToolId)},on:{"change":function($event){return _vm.savedToolData(att.ToolId)}}},[_vm._v("Assign tool to dataset")]),_c('hr'),_c('div',{staticClass:"division col-md-6 mt-2"},[(att.ToolAttributes.length)?_c('h5',[_vm._v("Defaults")]):_vm._e(),_vm._l((att.ToolAttributes),function(tool){return _c('div',{key:tool.ToolAttributes},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                            label: tool.AttributeName,
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }}},[_c('b-form-select',{attrs:{"disabled":!_vm.isChecked(att.ToolId)},on:{"change":function($event){return _vm.changedValDef(att, tool)}},model:{value:(tool.AttributeNameValue),callback:function ($$v) {_vm.$set(tool, "AttributeNameValue", $$v)},expression:"tool.AttributeNameValue"}},[_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select a value")]),_vm._l((_vm.filterColTypes(
                                    tool.DatatypeSetID
                                )),function(op,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(op.text)+" ")])})],2)],1)],1)}),_c('h5',{staticClass:"mt-2"},[_vm._v(" Allowable ")]),_vm._l((att.allowableToolAttributes),function(allowableTool,indexAllowable){return _c('div',{key:indexAllowable,staticClass:"allowable"},[_c('div',{staticStyle:{"width":"650px"}},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                label: allowableTool.AttributeName,
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }}},[_c('b-form-select',{attrs:{"disabled":!_vm.isChecked(att.ToolId),"plaeholder":"Select a value"},on:{"change":function($event){return _vm.changedValAllow(att, allowableTool)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("None")])]},proxy:true}],null,true),model:{value:(
                                    allowableTool.AllowAttributeNameValue
                                ),callback:function ($$v) {_vm.$set(allowableTool, "AllowAttributeNameValue", $$v)},expression:"\n                                    allowableTool.AllowAttributeNameValue\n                                "}},_vm._l((_vm.filterColTypes(
                                        allowableTool.DatatypeSetID
                                    )),function(op,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(op.text)+" ")])}),0)],1),_c('div',{staticStyle:{"position":"relative","float":"right","width":"150px"}},[_c('div',{staticClass:"ml-2",staticStyle:{"position":"relative","float":"left"}},[_c('img',{staticClass:"ml-1",attrs:{"src":require("../../../../assets/plusIcon-greyBG.svg")},on:{"click":function($event){return _vm.addAllowAttribute(
                                            allowableTool,
                                            indexAllowable
                                        )}}})]),(allowableTool.isRemoveAllow)?_c('div',{staticClass:"ml-2",staticStyle:{"position":"relative","float":"left"},on:{"click":function($event){return _vm.removeAllowAttrubutes(
                                        allowableTool,
                                        indexAllowable
                                    )}}},[_c('img',{staticClass:"ml-1",attrs:{"src":require("../../../../assets/Delete_Aggr.svg")}})]):_vm._e()])],1)])})],2)],1):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }