import axios from 'axios';
//import { config } from '../../config';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const datasetService = {
    getDatasets,
    getAdddataSets,
    getDatasetColumnAliasName,
    getDataTypeColumn,
    deleteDataset,
    getEditingDataSet,
    updateLayerOrder,
    defineDataset,
    getProcedureParameters,
    legacyProjectInfo,
    getTableselectools,
    getFilterOptions,
    getFilterOptionsTable,
    filterDataset,
    filterDatasetLegacy,
    filteredData,
    GetExternalDataSorted,
    exportToAccessDB,
    getDataforChartelement,
    getAttributes,
    getDataTypes,
    getToolattributesUsingId,
    saveDataSet,
    getScatterplotData,
    editDataset,
    updateDataset,
    getminMaxInterval,
    GetDistinctFilteredvalues,
    getProjectLayerOrder,
    getSavedWork,
    savefilters,
    updateSavedWork,
    deleteSaveWork,
    getPowerBIDatasets,
    getStoredProcedureResults,
    UpdateUserPortalOptionsDetails,
    initializeRequest,
    getStatus,
    getQueue
};

function getminMaxInterval(
    datasetID,
    getminMaxInterval,
    yAxisChecked,
    filterOptions
) {
    //  return axios.post(apiConstants.getminMaxInterval+"?dataSetId=" + datasetID + "&query=" + getminMaxInterval + "&isexist=" + yAxisChecked, filterOptions);
    return request({
        url:
            apiConstants.getminMaxInterval +
            '?dataSetId=' +
            datasetID +
            '&query=' +
            getminMaxInterval +
            '&isexist=' +
            yAxisChecked,
        data: filterOptions,
        method: 'post',
    });
}
//getting datasets from database
function getDatasets(datasetID) {
    /* return axios.get(apiConstants.getDatasetList,{
        params:{
            id: datasetID
        }
    })*/
    // return axios.get(apiConstants.getDatasetList+"?$filter=DataSourceId eq " + datasetID );
    return request({
        url:
            apiConstants.getDatasetList +
            '?$filter=DataSourceId eq ' +
            datasetID,
    });
}

function getDatasetColumnAliasName(datasetID) {
    return request({
        url: apiConstants.getDatasetColumnAliasName + '?DatasetId=' + datasetID,
    });
}
function getDataTypeColumn(datasetID) {
    // return axios.get(apiConstants.getDataTypeColumn+'?DatasetId='+ datasetID);
    return request({
        url: apiConstants.getDataTypeColumn + '?DatasetId=' + datasetID,
    });
}

function getEditingDataSet(datasetId) {
    // return axios.get(apiConstants.getEditingDataSet+"?datasetId="+datasetId);
    return request({
        url: apiConstants.getEditingDataSet + '?datasetId=' + datasetId,
    });
}

function getProjectLayerOrder(projectId) {
    // return axios.get(apiConstants.getEditingDataSet+"?datasetId="+datasetId);
    return request({
        url: apiConstants.getProjectLayerOrder + '?projectId=' + projectId,
    });
}

function updateLayerOrder(data) {
    // return axios.put(apiConstants.updateLayerOrder,data);
    return request({
        url: apiConstants.updateLayerOrder,
        data: data,
        method: 'put',
    });
}
//getting datasets from external source
function getAdddataSets(externalId) {
    // return axios.get(apiConstants.addDatasetexternal,{params:{dataSourceId:externalId}})
    return request({
        url: apiConstants.addDatasetexternal + '?dataSourceId=' + externalId,
    });
}

//deleting dataset
function deleteDataset(dID) {
    // return axios.delete(apiConstants.deleteDataset +'/'+ dID)
    return request({
        url: apiConstants.deleteDataset + '/' + dID,
        method: 'delete',
    });
}

//getting dataset in dataset columns page for add functionality
function defineDataset(params) {
    // return axios.get(apiConstants.defineDatasetColumns,{params})
    return request({
        url: apiConstants.defineDatasetColumns,
        params: params,
        method: 'get',
    });
}

function getProcedureParameters(params) {
    return request({
        url: apiConstants.getProcedureParameters,
        params: params,
        method: 'get',
    });
}

//Legacy project information for projct Mangement
// function legacyProjectInfo(dataSourceItem){
//     const dataSourceId = dataSourceItem.DataSourceId;
//     const tableName = dataSourceItem.ProjectTable;
//     return axios.get(apiConstants.legacyProjectInfo+'?dataSourceId=' +dataSourceId+ '&tableName=' +tableName
//     )
// }
function legacyProjectInfo(datasourceid, table, columnProj, columnDesc) {
    // return axios.get(apiConstants.legacyProjectInfoFiltered+'?dataSourceId='+datasourceid+'&tableName='+table+'&distinctColumnName='+columnProj+"&columnDescription="+columnDesc)
    // return axios.get(apiConstants.legacyProjectInfoFiltered+'?dataSourceId='+datasourceid+'&tableName='+table+'&distinctColumnNameDec=Leena&projectDesc=SiteCode'
    return request({
        url:
            apiConstants.legacyProjectInfoFiltered +
            '?dataSourceId=' +
            datasourceid +
            '&tableName=' +
            table +
            '&distinctColumnName=' +
            columnProj +
            '&columnDescription=' +
            columnDesc,
    });
}

function getTableselectools(params) {
    // return axios.get(apiConstants.tableselectToollist+'?projectIds='+ params)
    return request({
        url: apiConstants.tableselectToollist + '?projectIds=' + params,
    });
}
function getFilterOptions(datasetid, projectId) {
    //
    // return axios.get(apiConstants.getFilterOption+'?dataSetId='+datasetid+'&$expand=DatasetFilterGroup,DatasetColumn')
    // return axios.get(apiConstants.getFilterOption+'?dataSetId=13&$expand=DatasetFilterGroup,DatasetColumn')
    return request({
        url:
            apiConstants.getFilterOption +
            '?dataSetId=' +
            datasetid +
            '&projectIds=' +
            projectId +
            '&$expand=DatasetFilterGroup,DatasetColumn',
    });
}
function getFilterOptionsTable(datasetid, projectId) {
    //
    // return axios.get(apiConstants.getFilterOption+'?dataSetId='+datasetid+'&$expand=DatasetFilterGroup,DatasetColumn')
    // return axios.get(apiConstants.getFilterOption+'?dataSetId=13&$expand=DatasetFilterGroup,DatasetColumn')
    return request({
        url:
            apiConstants.getFilterOption +
            '?dataSetId=' +
            datasetid +
            '&projectIds=' +
            projectId,
    });
}
function filterDataset(sourceid, tablename, columnname) {
    // return axios.get(apiConstants.filterDatasetCol+"?dataSourceId=1&tableName="+ tablename + "&distinctColumnName="+ " ' " + columnname + " ' ")
    // return axios.get(apiConstants.filterDatasetCol+"?dataSourceId="+sourceid+"&tableName=edatlive.tblLocation&distinctColumnName="+columnname)
    // return axios.get(apiConstants.filterDatasetCol+"?dataSourceId="+sourceid+"&tableName="+ tablename + "&distinctColumnName="+columnname)
    return request({
        url:
            apiConstants.filterDatasetCol +
            '?dataSourceId=' +
            sourceid +
            '&tableName=' +
            tablename +
            '&distinctColumnName=' +
            columnname,
    });
}

function filterDatasetLegacy(
    sourceid,
    tablename,
    columnname,
    legacyProjectId,
    ProjectIDs
) {
    // if(ProjectIDs){
    // return axios.get(apiConstants.filterDatasetColLegacy+"?dataSourceId="+sourceid+"&tableName="+ tablename + "&distinctColumnName="+columnname + "&LegacyProjectIds=" + legacyProjectId)
    return request({
        url:
            apiConstants.filterDatasetColLegacy +
            '?dataSourceId=' +
            sourceid +
            '&tableName=' +
            tablename +
            '&distinctColumnName=' +
            columnname +
            '&LegacyProjectIds=' +
            legacyProjectId +
            '&projectId=' +
            ProjectIDs,
    });
    // }else{
    //     // return axios.get(apiConstants.filterDatasetColLegacy+"?dataSourceId="+sourceid+"&tableName="+ tablename + "&distinctColumnName="+columnname)
    //     return request({url: apiConstants.filterDatasetColLegacy+"?dataSourceId="+sourceid+"&tableName="+ tablename + "&distinctColumnName="+columnname})
    // }
}

// function filteredData(listCheckedOptions, datasetId,legacyProjectId){
//     if(listCheckedOptions != null){
//         //legacyprojectId
//        if(legacyProjectId){
//         return axios.get(apiConstants.filteredData+"?dataSetId="+datasetId+"&"+listCheckedOptions+"&SiteCode in (" + legacyProjectId + ")");
//        }else{
//         return axios.get(apiConstants.filteredData+"?dataSetId="+datasetId+"&"+listCheckedOptions);
//        }

//     }else{
//          //legacyprojectId
//         if(legacyProjectId){
//         return axios.get(apiConstants.filteredData+"?dataSetId="+datasetId+"&SiteCode in (" + legacyProjectId + ")");
//         }else{
//             return axios.get(apiConstants.filteredData+"?dataSetId="+datasetId+"");
//         }

//     }
//     // return axios.get(apiConstants.filteredData+"?dataSetId=13&Location in ('MW-001','Mw-003')")
// }

function filteredData(listCheckedOptions) {
    // return axios.post(apiConstants.filteredData, listCheckedOptions)
    return request({
        url: apiConstants.filteredData,
        data: listCheckedOptions,
        method: 'post',
    });
}

function GetExternalDataSorted(listCheckedOptions) {
    return request({
        url: apiConstants.GetExternalDataSorted,
        data: listCheckedOptions,
        method: 'post',
    });
}

function exportToAccessDB(filters) {
    return request({
        url: apiConstants.exportAccessDB,
        data: filters,
        method: 'post',
    });
}

function getDataforChartelement(datasetId) {
    // return axios.get(apiConstants.datasetToolattributes+"?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/ToolId eq 4 and DatasetColumn/DatasetId eq "+datasetId);
    return request({
        url:
            apiConstants.datasetToolattributes +
            '?$expand=DatasetColumn,ToolAttribute&$filter=ToolAttribute/ToolId eq 4 and DatasetColumn/DatasetId eq ' +
            datasetId,
    });
}

function editDataset(datasetId) {
    // return axios.get(apiConstants.editDataSet+"?$expand=DatasetColumns($expand=DatasetToolAttributes,DatasetFilterColumns($select=DatasetColumnId))&filter=DatasetId eq " + datasetId + ")");
    // return axios.get(apiConstants.editDataSet+"?$expand=DatasetColumns($expand=DatasetToolAttributes,DatasetFilterColumns($select=DatasetColumnId))&filter=DatasetId%20%20eq%2013");
    return request({
        url:
            apiConstants.editDataSet +
            '?$expand=DatasetColumns($expand=DatasetToolAttributes,DatasetFilterColumns($select=DatasetColumnId))&filter=DatasetId eq ' +
            datasetId +
            ')',
    });
}

function getAttributes() {
    // return axios.get(apiConstants.getToolattributes+"?$expand=ToolAttributes")
    return request({
        url: apiConstants.getToolattributes + '?$expand=ToolAttributes',
    });
}

function getDataTypes() {
    // return axios.get(apiConstants.getDatatypes+"?$expand=Datatypes")
    return request({ url: apiConstants.getDatatypes + '?$expand=Datatypes' });
}
function getToolattributesUsingId(id) {
    // return axios.get(apiConstants.getToolattributes+"?$expand=ToolAttributes($filter=ToolId eq " + id + ")")
    return request({
        url:
            apiConstants.getToolattributes +
            '?$expand=ToolAttributes($filter=ToolId eq ' +
            id +
            ')',
    });
}

function saveDataSet(data) {
    //
    // return axios.post(apiConstants.saveDataSet, data)
    return request({
        url: apiConstants.saveDataSet,
        data: data,
        method: 'post',
    });
}

function updateDataset(id, data) {
    //
    // return axios.put(apiConstants.updateDataset + "/" + id, data)
    return request({
        url: apiConstants.updateDataset + '/' + id,
        data: data,
        method: 'put',
    });
}

function getScatterplotData(datasetid, xcol, ycol, seriescol, filterOptions) {
    // return axios.post(apiConstants.getScatterPlot+"?dataSetId="+datasetid+"&xColumnName="+xcol+"&yColumn="+ycol+"&seriescolumns="+seriescol, filterOptions)
    // return axios.get(apiConstants.getScatterPlot+"?dataSetId=13&xColumnName="+xcol+
    // "&yColumn="+ycol+"&seriescolumns="+seriescol)
    return request({
        url:
            apiConstants.getScatterPlot +
            '?dataSetId=' +
            datasetid +
            '&xColumnName=' +
            xcol +
            '&yColumn=' +
            ycol +
            '&seriescolumns=' +
            seriescol,
        data: filterOptions,
        method: 'post',
    });
}

function GetDistinctFilteredvalues(datasetid, column, filterOptions) {
    // return axios.post(apiConstants.GetDistinctFilteredvalues+"?dataSetId="+datasetid+"&column="+column, filterOptions)
    return request({
        url:
            apiConstants.GetDistinctFilteredvalues +
            '?dataSetId=' +
            datasetid +
            '&column=' +
            column,
        data: filterOptions,
        method: 'post',
    });
}

function getSavedWork(projectID) {
    return request({
        url:
            apiConstants.getSavedWork +
            '?projectId=' +
            projectID +
            '&toolId=' +
            6,
    });
}

function savefilters(filters) {
    return request({
        url: apiConstants.saveFilters,
        data: filters,
        method: 'post',
    });
}

function UpdateUserPortalOptionsDetails(details) {
    return request({
        url: apiConstants.UpdateUserPortalOptionsDetails,
        data: details,
        method: 'put',
    });
}

function updateSavedWork(id) {
    return request({
        url: apiConstants.updateSavedWork + '/' + id,
        method: 'put',
    });
}

function deleteSaveWork(id) {
    return request({
        url: apiConstants.updateSavedWork + '/' + id,
        method: 'delete',
    });
}

function getPowerBIDatasets(params) {
    return request({
        url: apiConstants.getPowerBIDatasets + '?projectIds=' + params,
    });
}

function getStoredProcedureResults(data) {
    return request({
        url: apiConstants.getStoredProcedureResults,
        data: data,
        method: 'post',
    });
}

function initializeRequest(projectId, requestType, requestBody = null) {
    if (requestBody === null) {
        return request({
            url:
                apiConstants.initializeRequest +
                '?projectId=' +
                projectId +
                '&requestType=' +
                requestType,
        });
    }
    else {
        return request({
            url:
                apiConstants.initializeRequest +
                '?requestType=' +
                requestType,
            data: requestBody,
            method: 'post',                
        });
    }
}

function getStatus(JobID) {
    return request({
        url: apiConstants.getStatus + '?JobID=' + JobID,
    });
}
function getQueue(JobID) {
    return request({
        url: apiConstants.getQueue + '?JobID=' + JobID,
    });
}