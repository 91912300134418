<template>
    <div class="col-md-3 leftmargin m-1 columns">
        <b-form>
            <b-form-group>
                <CustomValidateInput
                    :config="{
                        label: 'Dataset name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                    :isValid="validated('DatasetAlias')"
                >
                    <b-form-input
                        :state="validateState('DatasetAlias')"
                        name="DatasetAlias"
                        v-model="
                            validation.DatasetDTO.Dataset.DatasetAlias.$model
                        "
                        autocomplete="off"
                        aria-describedby="DatasetAlias-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="DatasetAlias-feedback"
                    >
                        Please enter dataset name
                    </b-form-invalid-feedback>
                </CustomValidateInput>

                <CustomValidateInput
                    :config="{
                        label: 'Display name',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                    :isValid="validated('DisplayName')"
                >
                    <b-form-input
                        :state="validateState('DisplayName')"
                        name="DisplayName"
                        v-model="
                            validation.DatasetDTO.Dataset.DisplayName.$model
                        "
                        autocomplete="off"
                        aria-describedby="DisplayName-feedback"
                        @change="dispNameChange"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="DisplayName-feedback"
                    >
                        Please enter display name
                    </b-form-invalid-feedback>
                </CustomValidateInput>

                <CustomValidateInput
                    :config="{
                        label: 'Dataset description',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                    :isValid="validated('DatasetDesc')"
                >
                    <b-form-input
                        :state="validateState('DatasetDesc')"
                        name="dispname"
                        v-model="
                            validation.DatasetDTO.Dataset.DatasetDesc.$model
                        "
                        autocomplete="off"
                        aria-describedby="DatasetDesc-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                        class="invalid-text-custom"
                        id="DatasetDesc-feedback"
                    >
                        Please enter dataset description
                    </b-form-invalid-feedback>
                </CustomValidateInput>
                <div v-if="$route.params.sourceName === 'ATLAS'">
                    <CustomValidateInput
                        :config="{
                            label: 'Wild card',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                        :isValid="validated('WildCard')"
                        v-if="$route.params.sourceName === 'ATLAS'"
                    >
                        <b-form-input
                            :state="validateState('WildCard')"
                            name="WildCard"
                            v-model="
                                validation.DatasetDTO.Dataset.WildCard.$model
                            "
                            autocomplete="off"
                            aria-describedby="WildCard-feedback"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                            class="invalid-text-custom"
                            id="WildCard-feedback"
                        >
                            Please enter wild card
                        </b-form-invalid-feedback>
                    </CustomValidateInput>
                    <p>{{ wildCardString }}</p>
                </div>
            </b-form-group>
        </b-form>
        <b-form v-if="isStoredProcedure">
            <procedureselect
                ref="procedureSelectRef"
                :DatasetDTO="DatasetDTO"
                :procedureParams="procedureParams"
                @setSPallDatasetColumns="setSPallDatasetColumns"
                @sendParamsToSave="sendParamsToSave"
            ></procedureselect
        ></b-form>
        <div v-if="allDataSetColoumns.length > 0">
            <b-form>
                <p class="quickDate-desc mb-2">
                    To utilize Quick Date Filters for a column, choose a dataset
                    column with a Date data type.
                </p>
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{ label: 'Quick Date Filter Column' }"
                    ><b-form-group>
                        <b-form-select
                            v-model="DatasetDTO.Dataset.DateFilterColumn"
                            :options="quickDateOptions"
                            text-field="columnName"
                            value-field="value"
                            :disabled="quickDateOptions.length === 0"
                        >
                            <template #first
                                ><b-form-select-option
                                    :value="null"
                                    disabled
                                    v-if="quickDateOptions.length === 0"
                                    >No Date column
                                    available</b-form-select-option
                                >
                            </template>
                        </b-form-select></b-form-group
                    >
                </CustomFloatingLabel>
            </b-form>
        </div>
        <div
            v-if="isPowerBI"
            class="col-md-3 leftmargin m-1 columns"
            style="padding:0px"
        >
            <b-form>
                <b-form-group>
                    <CustomFloatingLabel
                        :config="{
                            label: 'Roles',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <input
                            type="text"
                            class="inputbox normal_input"
                            autocomplete="off"
                            name="roles"
                            v-model="DatasetDTO.Dataset.Roles"
                        />
                    </CustomFloatingLabel>

                    <CustomFloatingLabel
                        :config="{
                            label: 'Section ID',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <input
                            type="text"
                            class="inputbox normal_input"
                            autocomplete="off"
                            name="sectionID"
                            v-model="DatasetDTO.Dataset.SectionId"
                        />
                    </CustomFloatingLabel>
                </b-form-group>
            </b-form>
        </div>
        <div v-if="allDataSetColoumns.length > 0">
            <div v-if="!isPowerBI">
                <h5>Type of dataset</h5>
                <div class="dataset-type-btn-group">
                    <b-button
                        class="dataset-type-btn"
                        @click="enableBtnColumn('isTable')"
                        :class="
                            isTable && !isMap && !isBoth
                                ? 'selectedGraphictool'
                                : ''
                        "
                        >Table</b-button
                    >
                    <b-button
                        class="dataset-type-btn"
                        @click="enableBtnColumn('isMap')"
                        v-if="isGeometryAvailable"
                        :class="
                            !isTable && isMap && !isBoth
                                ? 'selectedGraphictool'
                                : ''
                        "
                        >Map</b-button
                    >
                    <b-button
                        class="dataset-type-btn"
                        @click="enableBtnColumn('isBoth')"
                        v-if="isGeometryAvailable"
                        :class="
                            !isTable && !isMap && isBoth
                                ? 'selectedGraphictool'
                                : ''
                        "
                        >Both</b-button
                    >
                </div>
            </div>

            <SelectColoumns
                v-if="!isPowerBI"
                :DatasetDTO="DatasetDTO"
                :allDataSetColoumns="allDataSetColoumns"
                :selectedColoumns="selectedColoumns"
                @setSelectColoumns="setSelectColoumns"
            ></SelectColoumns>
        </div>
    </div>
</template>

<script>
import SelectColoumns from './SelectColoumns';
import procedureselect from './ProcedureSelect.vue';
import CustomValidateInput from '../../../CustomValidateInput';
import CustomFloatingLabel from '../../../CustomFloatingLabel';

export default {
    name: 'DatasetColumns',
    props: {
        DatasetDTO: Object,
        isPowerBI: Boolean,
        isTable: Boolean,
        isMap: Boolean,
        isBoth: Boolean,
        isGeometryAvailable: Boolean,
        allDataSetColoumns: Array,
        selectedColoumns: Array,
        quickDateOptions: Array,
        isStoredProcedure: Boolean,
        isEditDatasetSP: Boolean,
        procedureParams: Array,
    },
    data() {
        return {};
    },
    inject: ['validation'],
    components: {
        SelectColoumns,
        procedureselect,
        CustomValidateInput,
        CustomFloatingLabel,
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.validation.DatasetDTO.Dataset[name];
            return $dirty ? !$error : null;
        },
        enableBtnColumn(type) {
            let isTable = type === 'isTable' ? true : false;
            let isMap = type === 'isMap' ? true : false;
            let isBoth = type === 'isBoth' ? true : false;
            this.$emit('setDatasetType', isTable, isMap, isBoth);
        },
        dispNameChange() {},
        setSelectColoumns(selectedColoumns) {
            this.$emit('setSelectColoumns', selectedColoumns);
        },
        setSPallDatasetColumns(SPColumns) {
            this.$emit('setSPallDatasetColumns', SPColumns);
        },
        sendParamsToSave(userParams) {
            this.$emit('saveParamsToDB', userParams);
        },
    },
    computed: {
        validated() {
            return (status) => {
                const { $dirty } = this.validation.DatasetDTO.Dataset[status];
                if ($dirty && this.validateState(status) === true) {
                    return true;
                } else if ($dirty && this.validateState(status) === false) {
                    return false;
                }
                return true;
            };
        },
        wildCardString() {
            let toReturn = '';
            if (this.validation.DatasetDTO.Dataset.WildCard.$model) {
                toReturn = `*${this.validation.DatasetDTO.Dataset.WildCard.$model}*`;
            }
            return toReturn;
        },
    },
};
</script>

<style scoped>
.dataset-type-btn-group {
    display: flex; /* or inline-flex */
    flex-direction: row;
    width: 800px;
}
.dataset-type-btn {
    text-align: center;
    width: 167px;
    height: 48px;
    border: none !important;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #c6c6c6;
    border: 1px solid #c6c6c6 !important;
    background: #fff;
    color: #000;
}
.dataset-type-btn:hover {
    color: #fff !important;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #c6c6c6;
    background-color: #000;
    cursor: pointer;
}
.selectedGraphictool {
    background-color: #000 !important;
    color: #fff;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.leftmargin {
    margin-left: 5px;
}
.quickDate-desc {
    width: 499px;
}
</style>
