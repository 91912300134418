import { datasetService } from '../services';
import { gdbService } from '../services/gdb.service';

const state = {
    datasets: {},
    externalDtasets: {},
    defineDataset: {},
};

const actions = {
    getDatasets({ commit }, datasetID) {
        commit('getAllRequest');

        datasetService.getDatasets(datasetID).then(
            (datasets) => commit('getAllSuccess', datasets),
            (error) => commit('getAllFailure', error)
        );
    },
    getAdddataSets({ commit }, sourceID) {
        commit('addExternal');
        datasetService.getAdddataSets(sourceID).then(
            (externalDtasets) => commit('getExternalDatasets', externalDtasets)
            //error => commit('getAllFailure', error)
        );
    },
    deleteDataset({ commit }, dId) {
        commit('deleteRequest');
        gdbService.deleteFromSQL(dId).then(
            datasetService.deleteDataset(dId).then(
                (remove) => commit('deleteSuccess', remove),
                (error) => commit('deleteFailure', error)
            )
        );
    },
    defineDataset({ commit }, params) {
        commit('definedataset');
        datasetService.defineDataset(params).then(
            (defineDataset) => commit('defineSuccess', defineDataset)
            //error => commit('deleteFailure', error)
        );
    },
};

const mutations = {
    // get datasets
    getAllRequest(state) {
        state.datasets = { loading: true };
    },
    getAllSuccess(state, datasets) {
        state.datasets = { items: datasets.data };
    },
    getAllFailure(state, error) {
        state.datasets = { error };
    },
    getExternalDatasets(state, externalDtasets) {
        state.externalDtasets = { items: externalDtasets.data };
    },

    addExternal(state) {
        state.update = { loading: true };
    },
    deleteDataset(state) {
        state.remove = { loading: true };
    },
    deleteRequest(state) {
        state.remove = { loading: true };
    },
    deleteSuccess(state, remove) {
        state.remove = {
            StatusCode: remove.data.StatusCode,
        };
    },
    defineSuccess(state, defineDataset) {
        state.defineDataset = { items: defineDataset.data };
        //state.define = {   StatusCode: define.data.StatusCode }
    },
    deleteFailure(state, error) {
        state.remove = { error };
    },
    definedataset(state) {
        state.define = { loading: true };
    },
};

const namespaced = true;

export const datasets = {
    namespaced,
    state,
    actions,
    mutations,
};
