<template>
    <div class="list-group over-cont">
        <div>
            <p>Procedure parameters</p>
            <p class="text-help">
                To retrieve the columns, click on 'Test Results' after entering
                the necessary inputs for procedure parameters. Then, proceed to
                save the dataset.
            </p>
        </div>
        <ul>
            <li v-for="(param, index) in procedureParams" :key="index">
                <span class="badge d-flex">
                    <div class="d-flex flex-column">
                        <CustomFloatingLabel
                            :config="{
                                label: param.ParameterName,
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                type="text"
                                class="inputbox normal_input"
                                autocomplete="off"
                                :name="param.ParameterName"
                                v-model="param.Value"
                                @input="validateInputForParam(param, index)"
                            />
                        </CustomFloatingLabel>

                        <div
                            v-if="param.errorMessage"
                            class="error-message text-danger d-flex align-items-start pl-1"
                        >
                            {{ param.errorMessage }}
                        </div>
                    </div>
                    <span
                        ><label class="switch ml-4">
                            <input type="checkbox" v-model="param.Required" />
                            <span class="slider round"></span>
                        </label>
                        <span class="content ml-1" v-if="param.Required"
                            >Required
                        </span>
                        <span class="content ml-1" v-else>
                            Optional
                        </span>
                    </span>
                    <span
                        ><label class="switch ml-4">
                            <input
                                type="checkbox"
                                v-model="param.StaticValue"
                            />
                            <span class="slider round"></span>
                        </label>
                        <span class="content ml-1" v-if="param.StaticValue"
                            >Static
                        </span>
                        <span class="content ml-1" v-else>
                            Non-Static
                        </span>
                    </span>
                </span>
            </li>
        </ul>
        <div>
            <b-button @click="getStoredProcedureResults">Test Results</b-button>
        </div>
    </div>
</template>

<script>
import CustomFloatingLabel from '../../../CustomFloatingLabel';
import { datasetService } from '../../../../services';
import { DatasetColumnsUtils } from '../Utils';

export default {
    name: 'procedureselect',
    components: {
        CustomFloatingLabel,
    },
    props: {
        procedureParams: Array,
    },
    data() {
        return {
            datasourceID: null,
            procedureName: '',
        };
    },
    methods: {
        validateInputForParam(param, index) {
            const errorMessage = DatasetColumnsUtils.validateInput(
                param.ParameterName,
                param.Value,
                param.DataType
            );
            if (errorMessage) {
                this.$set(
                    this.procedureParams[index],
                    'errorMessage',
                    errorMessage
                );

                setTimeout(() => {
                    this.$set(this.procedureParams[index], 'Value', null);
                    this.$set(
                        this.procedureParams[index],
                        'errorMessage',
                        null
                    );
                }, 1000);
            } else {
                // If no error, clear the errorMessage
                this.$set(this.procedureParams[index], 'errorMessage', null);
            }
        },

        async getStoredProcedureResults() {
            const procedureParamsObj = {};

            this.procedureParams.forEach((arr) => {
                if (arr.Value) {
                    procedureParamsObj[arr.ParameterName] = arr.Value;
                }
            });

            let payload = {
                DatasourceID: this.datasourceID,
                ProcedureName: this.procedureName,
                Parameters: procedureParamsObj,
            };
            try {
                let res = await datasetService.getStoredProcedureColumns(
                    payload
                );
                if (res.data.StatusCode == 500) {
                    console.log(res.data.Message);
                } else {
                    let storedProcedureResults = res.data;
                    if (storedProcedureResults.length > 0) {
                        this.$emit(
                            'setSPallDatasetColumns',
                            storedProcedureResults
                        );
                    } else {
                        console.log('There is no result to show');
                    }
                }
            } catch (error) {
                console.error(
                    'Error fetching stored procedure results:',
                    error
                );
            }
        },
        sendParamstoUpdateSP() {
            const paramsWithValues = this.procedureParams.map((param) => {
                return {
                    DataType: param.DataType,
                    parameterTypeID: param.ParameterType || null,
                    ParameterName: param.ParameterName,
                    Position: param.Ordinal || param.Position,
                    Required: param.Required,
                    StaticValue: param.StaticValue,
                    Value: param.Value ? param.Value : null,
                    DatasetParameterID: param.DatasetParameterID,
                };
            });

            this.$emit('sendParamsToSave', paramsWithValues);
        },
    },
    created() {
        this.datasourceID = this.$route.params.id;
        this.procedureName = this.$route.params.tablename.split('.')[1];
    },
};
</script>

<style scoped>
.badge {
    width: fit-content;
    align-items: baseline;
    height: 75px;
}
.text-help {
    width: 500px;
}
.my-custom-floating-label {
    margin-bottom: 5px;
}
.error-message {
    font-size: 11px;
}
</style>
