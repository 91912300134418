var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showPage)?_c('div',[_c('AccessPermissionModal',{ref:"permission-modal"})],1):_c('b-container',[_c('div',{staticClass:"titleblock"},[_c('b-container',{staticClass:"p-0"},[_c('div',{staticClass:"pb-3"},[_c('b-container',{staticClass:"define-tool"},[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('span',{staticClass:"my-3 title-block-header"},[_vm._v(" Enter title block details for "+_vm._s(_vm.dataSetName)+" ")])]),_c('b-col',{attrs:{"cols":"4"}},[_c('b-button',{staticClass:"buttonSelect mt-3",staticStyle:{"float":"right"},attrs:{"rounded":""},on:{"click":function($event){_vm.toGo = true;
                                    _vm.saveTitleBlock();}}},[_vm._v(" Save ")])],1)],1),_c('div',{staticClass:"title-block-wrapper"},[_c('div',{staticClass:"title-block-settings-wrapper"},[_c('b-col',{staticClass:"block-1"},[_vm._l((_vm.titleBlock),function(item,index){return _c('div',{key:item.id},[_c('div',{staticClass:"borderBlue mt-3 ml-2"},[_c('h5',[_vm._v("Line "+_vm._s(index + 1))])]),_c('div',{staticClass:"line-block mt-6 pt-3"},[_c('span',{staticClass:"ml-4"},[_vm._v("Text"),(index !== 0)?_c('small',[_vm._v("(Optional)")]):_vm._e()]),_c('div',{staticClass:"px-2",attrs:{"onkeypress":"return /[0-9a-zA-Z, _/().#\\\\&]/i.test(event.key)","onpaste":"return false;","ondrop":"return false;","id":'titleBlockText' + index,"contenteditable":"true","pattern":"[a-zA-Z0-9!@#$%^*_|]{6,25}","value":_vm.titleBlock[index].textFormat},on:{"input":function($event){return _vm.updateValue(
                                                    $event.target.innerHTML,
                                                    index
                                                )}}}),_c('div',{staticClass:"dataset-field-wrapper"},_vm._l((item.dataSet),function(dataset,indexdataset){return _c('div',{key:indexdataset,staticClass:"dataset-field-item ml-4"},[_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                                        label:
                                                            'Dataset field',
                                                        name: 'wrapper',
                                                        line: false,
                                                        scale: false,
                                                    }}},[_c('b-form-select',{staticClass:"mt-2 small_drop",attrs:{"disabled":_vm.titleBlock[
                                                                index
                                                            ]['dataSet'][
                                                                indexdataset
                                                            ]
                                                                .dataSetName !==
                                                                '',"value-field":"Dataset field"},on:{"change":function($event){return _vm.appendText(
                                                                index,
                                                                indexdataset
                                                            )}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Dataset field (optional)")])]},proxy:true}],null,true),model:{value:(
                                                            dataset.dataSetName
                                                        ),callback:function ($$v) {_vm.$set(dataset, "dataSetName", $$v)},expression:"\n                                                            dataset.dataSetName\n                                                        "}},_vm._l((_vm.datasetCoumn),function(column){return _c('option',{key:column.DatasetColumnId,domProps:{"value":column.ColumnName}},[_vm._v(" "+_vm._s(column.ColumnName)+" ")])}),0)],1),_c('a',{staticClass:"delete",attrs:{"data-id":indexdataset},on:{"click":function($event){return _vm.deleteDataSet(
                                                            index,
                                                            indexdataset,
                                                            $event
                                                        )}}})],1)}),0),_c('div',{staticClass:"header-group-wrap"},[(_vm.datasetCoumn.length)?_c('button',{staticClass:"mt-3 headergroup block",attrs:{"value":"Add a header group +","autocomplete":"off"},on:{"click":function($event){return _vm.addDataSet(index)}}},[_vm._v(" Insert field from dataset + ")]):_vm._e()]),_c('div',{staticClass:"ml-4 mt-4 style-block"},[_c('span',[_vm._v("Style")]),_c('b-check',{staticClass:"mt-2 mb-2 checkbox",on:{"change":function($event){_vm.loadDefaultStyle(index);
                                                    _vm.disableBtnSave();}},model:{value:(item.styleGHD),callback:function ($$v) {_vm.$set(item, "styleGHD", $$v)},expression:"item.styleGHD"}},[_vm._v("Load default GHD styles")]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                                    label: 'Size',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }}},[_c('b-form-select',{attrs:{"options":_vm.styleDataSize,"text-field":"Size","value-field":"Size"},on:{"change":function($event){return _vm.resetDefaultStyle(
                                                            index
                                                        )}},nativeOn:{"blur":function($event){item.textSize ==
                                                        null
                                                            ? (_vm.titleBlockError[
                                                                  index
                                                              ].textSize = true)
                                                            : (_vm.titleBlockError[
                                                                  index
                                                              ].textSize = false)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Size")])]},proxy:true}],null,true),model:{value:(item.textSize),callback:function ($$v) {_vm.$set(item, "textSize", $$v)},expression:"item.textSize"}})],1),(
                                                    _vm.titleBlockError[index]
                                                )?_c('span',[(
                                                        _vm.titleBlockError[
                                                            index
                                                        ].textSize
                                                    )?_c('span',{staticClass:"style-alert-msg"},[_vm._v("please select size")]):_vm._e()]):_vm._e(),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                                    label: 'Style',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }}},[_c('b-form-select',{attrs:{"options":_vm.styleDataforStyle,"text-field":"Style","value-field":"Style"},on:{"change":function($event){return _vm.resetDefaultStyle(
                                                            index
                                                        )}},nativeOn:{"blur":function($event){item.textStyle ==
                                                        null
                                                            ? (_vm.titleBlockError[
                                                                  index
                                                              ].textStyle = true)
                                                            : (_vm.titleBlockError[
                                                                  index
                                                              ].textStyle = false)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Style")])]},proxy:true}],null,true),model:{value:(item.textStyle),callback:function ($$v) {_vm.$set(item, "textStyle", $$v)},expression:"item.textStyle"}})],1),(
                                                    _vm.titleBlockError[index]
                                                )?_c('span',[(
                                                        _vm.titleBlockError[
                                                            index
                                                        ].textStyle
                                                    )?_c('span',{staticClass:"style-alert-msg"},[_vm._v("please select style")]):_vm._e()]):_vm._e(),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                                    label: 'Type',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }}},[_c('b-form-select',{attrs:{"options":_vm.styleDataType,"text-field":"Type","value-field":"Type"},on:{"change":function($event){return _vm.resetDefaultStyle(
                                                            index
                                                        )}},nativeOn:{"blur":function($event){item.textType ==
                                                        null
                                                            ? (_vm.titleBlockError[
                                                                  index
                                                              ].textType = true)
                                                            : (_vm.titleBlockError[
                                                                  index
                                                              ].textType = false)}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Type")])]},proxy:true}],null,true),model:{value:(item.textType),callback:function ($$v) {_vm.$set(item, "textType", $$v)},expression:"item.textType"}})],1),_c('div',{staticClass:"pickColor wrap"},[_c('div',{},[_c('button',{staticClass:"btn-transparent",on:{"click":_vm.colorPicker}},[_c('img',{staticClass:"pencil-icon",attrs:{"src":require("../../assets/ghd_colorpicker.svg"),"font-scale":"1.5"}})]),_c('CustomFloatingLabel',{staticClass:"FloatingTool",attrs:{"config":{
                                                            label: 'Color',
                                                            name: 'wrapper',
                                                            line: false,
                                                            scale: false,
                                                        }}},[_c('ejs-colorpicker',{staticClass:"e-input",attrs:{"id":"element","type":"text"},on:{"change":function($event){return _vm.resetDefaultStyle(
                                                                    index
                                                                )}},model:{value:(
                                                                item.textColor
                                                            ),callback:function ($$v) {_vm.$set(item, "textColor", $$v)},expression:"\n                                                                item.textColor\n                                                            "}}),_c('span')],1)],1)]),(
                                                    _vm.titleBlockError[index]
                                                )?_c('span',[(
                                                        _vm.titleBlockError[
                                                            index
                                                        ].textType
                                                    )?_c('span',{staticClass:"style-alert-msg"},[_vm._v("please select type ")]):_vm._e()]):_vm._e()],1),(index !== 0)?_c('button',{ref:"removeButton",refInFor:true,staticClass:"m-3 headergroup block lineRemoveButton",attrs:{"data-id":index},on:{"click":function($event){return _vm.removeLine(index)}}},[_vm._v(" Remove this Line of text ")]):_vm._e()])])}),_c('b-container',{staticClass:"p-0"},[_c('b-row',{attrs:{"no-gutters":"true"}},[_c('b-col',[(_vm.titleBlock.length < 5)?_c('button',{staticClass:"mt-3 mb-3 normal_button add_line_btn",attrs:{"value":"Add a header group +","autocomplete":"off"},on:{"click":function($event){return _vm.addLine()}}},[_vm._v(" Add another line of text + ")]):_vm._e(),_c('div',{staticClass:"upload_logo"},[_c('span',{staticClass:"bold-text"},[_vm._v("Select logo ")]),_c('small',{staticClass:"optional"},[_vm._v(" (optional)")]),_c('br'),_c('div',{staticClass:"drag-drop",on:{"drop":function($event){$event.preventDefault();return _vm.onFileChanged(
                                                            $event,
                                                            'drop'
                                                        )},"dragover":function($event){$event.preventDefault();}}},[_c('b-icon-cloud-arrow-up',{staticClass:"uploadicon"}),_c('p',[(!_vm.fileName)?_c('span',{staticClass:"drag-text"},[_vm._v("Drag and drop file to upload")]):_vm._e(),(_vm.fileName)?_c('span',[_vm._v(_vm._s(_vm.fileName))]):_vm._e(),_c('br'),_vm._v("Or "),_c('input',{ref:"myFile",attrs:{"type":"file","name":"image","id":"inputFile","accept":"image/*"},on:{"change":function($event){return _vm.onFileChanged(
                                                                    $event,
                                                                    'select'
                                                                )}}})])],1),(_vm.imageWidthErr)?_c('small',{staticClass:"fileError"},[_vm._v("Please select image of dimensions below 5000 x 5000 pixels")]):_vm._e(),(_vm.disableSave)?_c('small',{staticClass:"fileError"},[_vm._v("Please select only image")]):_vm._e()]),_c('div',{staticClass:"footer_text mb-4"},[_c('span',{staticClass:"bold-text"},[_vm._v("Footer text")]),_c('small',{staticClass:"optional"},[_vm._v(" (optional)")]),_c('br'),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.footerText),expression:"footerText"}],staticClass:"mt-2 pl-2",attrs:{"placeholder":"Start typing here"},domProps:{"value":(_vm.footerText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.footerText=$event.target.value}}})])])],1)],1)],2)],1),_c('div',{staticClass:"title-block-preview-wrapper"},[(_vm.titleBlock[0].text)?_c('span',{staticClass:"logo-title",staticStyle:{"margin-left":"0 !important"}},[_vm._v("Title block preview ")]):_vm._e(),_c('div',{staticClass:"content-panel mt-3"},[_c('div',{staticClass:"line_block  word-brk"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"vmiddle"},[(_vm.blobPath)?_c('img',{staticClass:"my-auto",staticStyle:{"width":"40px","height":"40px"},attrs:{"id":"image-preview","src":_vm.blobPath}}):_vm._e()])]),_c('b-col',{attrs:{"cols":"8"}},_vm._l((_vm.titleBlock),function(item,index){return _c('div',{key:item.id},[(item.styleGHD)?_c('div',{staticClass:"preview-text text-optional",style:({
                                                        fontSize:
                                                            item.textSize +
                                                            'px',
                                                        color:
                                                            item.textColor,
                                                        fontFamily:
                                                            item.textType,
                                                        fontWeight:
                                                            item.textStyle,
                                                    }),attrs:{"id":'previewDataset' +
                                                            index}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
                                                        !item.styleGHD &&
                                                            item.textStyle ===
                                                                'Bold'
                                                    )?_c('div',{staticClass:"preview-text b-text",style:({
                                                        fontSize:
                                                            item.textSize +
                                                            'px',
                                                        color:
                                                            item.textColor,
                                                        fontFamily:
                                                            item.textType,
                                                        fontWeight:
                                                            item.textStyle,
                                                    }),attrs:{"id":'previewDataset' +
                                                            index}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
                                                        !item.styleGHD &&
                                                            item.textStyle !==
                                                                'Bold'
                                                    )?_c('div',{staticClass:"preview-text b-text",style:({
                                                        fontSize:
                                                            item.textSize +
                                                            'px',
                                                        color:
                                                            item.textColor,
                                                        fontFamily:
                                                            item.textType,
                                                        fontWeight:
                                                            item.textStyle,
                                                    }),attrs:{"id":'previewDataset' +
                                                            index}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e()])}),0)],1)],1)]),_c('div',{staticClass:"footer-section",class:_vm.footerText ? 'footerText' : '',staticStyle:{"font-size":"10px"}},[_c('span',[_vm._v(_vm._s(_vm.footerText))])])])]),_c('b-row',{staticClass:"mt-3",attrs:{"bordered":"","no-gutters":"true"}},[_c('hr',{staticClass:"br-right"})])],1)],1)]),_c('ToastMessages',{attrs:{"showSuccess":_vm.showSuccess,"showFailure":_vm.showFailure,"failureToastMessage":_vm.failureToastMessage,"successToastMessage":_vm.successToastMessage}}),(_vm.successToast)?_c('div',{staticClass:"successToastMsg"},[_c('img',{staticClass:"mr-3",attrs:{"src":require("../../assets/success_tick.svg")}}),_vm._v(" Success "),_c('br'),_vm._v(" Title Block created/updated successfully "),_c('img',{staticClass:"ml-5",attrs:{"src":require("../../assets/close_big.svg")},on:{"click":_vm.closeSuccessToast}})]):_vm._e(),(_vm.failureToast)?_c('div',{staticClass:"failureToastMsg"},[_c('img',{staticClass:"mr-3",attrs:{"src":require("../../assets/failure_cross.svg")}}),_vm._v(" Failure "),_c('br'),_vm._v(" Title Block already exists "),_c('img',{staticClass:"ml-5",attrs:{"src":require("../../assets/close_big.svg")},on:{"click":_vm.closeFailureToast}})]):_vm._e(),_c('Footer')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }