<template>
    <b-container class="frame-container full-height" fluid="true">
        <Header class="header" />
        <b-row
            class="frame d-flex d-align-items-stretch d-flex-grow-1 viewer-row d-flex"
        >
            <sidebar
                ref="leftSidebar"
                class="viewer-sidebar"
                v-bind:side="'left'"
                :taskbarButtons="leftTaskbarButtons"
                @toolChanged="handleSidebarWidth"
                @openProjects="handleNewProjects"
                @openTool="openTool"
            ></sidebar>
            <b-col id="viewer" class="frame frame-filler no-padding"> </b-col>
            <sidebar
                ref="rightSidebar"
                class="viewer-sidebar"
                v-bind:side="'right'"
                :taskbarButtons="rightTaskbarButtons"
                @toolChanged="handleSidebarWidth"
                @openTool="openTool"
                :toolToOpen="toolToOpen"
                @revertSideValue="revertSideValue"
            ></sidebar>
            <toast-messages
                :showSuccess="showSuccess"
                :showFailure="showFailure"
                :successToastMessage="successToastMessage"
                :failureToastMessage="failureToastMessage"
                :notificationQueue="notificationQueue"
                @hideToast="hideToast"
            />
        </b-row>
        <div id="viewer-content-pane" class="viewer no-padding">
            <map-pane
                ref="map"
                :isIdentifyAvailable="isIdentifyAvailable"
            ></map-pane>
        </div>
    </b-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Header from '../components/Header.vue';
import Sidebar from '../components/CommonComponents/Sidebar.vue';
// cannot call it map and use it as a component since map is an HTML tag already
import MapPane from '../components/Map.vue';
import { commonService } from '../services/common.service';
import ToastMessages from '../components/ToastMessages.vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import { projectService } from '../services';
import router from './../router';

export default {
    name: 'OneMap',
    components: {
        Header,
        MapPane,
        Sidebar,
        ToastMessages,
    },
    data() {
        return {
            toolsArr: [],
            leftSidebarWidth: 0,
            rightSidebarWidth: 0,
            toolToOpen: '',
        };
    },
    created() {
        if (this.$route.query.projectID !== undefined) {
            // clear the store and get the project info for that project
            this.$store.commit('projects/removeSelectedProjects');
            this.$store.dispatch('emergencyManagement/resetStore');
            projectService
                .GetProjectInfo(this.$route.query.projectID)
                .then((res) => {
                    this.$store.commit(
                        'projects/setSelectedProjects',
                        res.data[0]
                    );
                    this.$refs.map.zoomToLayers();
                });
        }
        let redirectPath = this.$store.state.store.goToPage;
        if (redirectPath !== null) {
            this.$store.commit('store/clearGoToPage');
            router.push(redirectPath);
        }
    },
    async mounted() {
        await this.userToolOption();
    },
    methods: {
        ...mapActions('projects', {
            clearSelectedProjects: 'clearSelectedProjects',
            setSelectedProjects: 'selectProjects',
        }),
        projectNotSelected(validProjects) {
            let validProjectsArr = validProjects.split(',').map(Number);
            return this.selectedProjects.some((selected) =>
                validProjectsArr.includes(selected.ProjectID)
            );
        },
        openTool(side) {
            if (side === 'left') {
                this.toolToOpen = 'right';
            } else {
                this.toolToOpen = 'left';
            }
        },
        revertSideValue() {
            this.toolToOpen = '';
        },
        /*
        payload to setToastMessage mutation
            {
                showSuccess: true,
                successToastMessage: 'test test',
            }
            or
            {
                showFailure: true,
                failureToastMessage: 'test test',
            }
        */
        ...mapMutations('common', ['setToastMessage']),

        buildToolLevels(toolsArr) {
            var toolLevels = {};
            var tempChildArr = [];
            toolsArr.forEach((tool) => {
                // assign new tool to structure, don't want to overwrite any children that are there,
                //  so we store the array in a temp variable for a bit
                if (tool.ToolID in toolLevels) {
                    tempChildArr = toolLevels[tool.ToolID].children;
                    toolLevels[tool.ToolID] = tool;
                    toolLevels[tool.ToolID].children = tempChildArr;
                } else {
                    toolLevels[tool.ToolID] = tool;
                    toolLevels[tool.ToolID].children = [];
                }
                // assign children to parents
                // if parent already exists
                if (tool.ParentToolID in toolLevels) {
                    toolLevels[tool.ParentToolID].children.push(tool.ToolID);
                } else {
                    toolLevels[tool.ParentToolID] = {};
                    toolLevels[tool.ParentToolID].children = [tool.ToolID];
                }
            });
            this.$store.commit('oneMap/mutateToolLevels', toolLevels);
        },

        async userToolOption() {
            await commonService.getUserToolOption().then((res) => {
                let userToolOptions = res.data;
                userToolOptions.sort((a, b) => a.SortOrder - b.SortOrder);
                this.toolsArr = userToolOptions;
            });
        },
        updateButtonsArrLeft() {
            this.leftTaskbarButtons.forEach((element, index) => {
                element.Location = 'left';
                element.SortOrder = index;
            });
            commonService
                .updateUserToolOption(this.leftTaskbarButtons)
                .then((res) => {})
                .catch((err) => {});
        },
        updateButtonsArrRight() {
            this.rightTaskbarButtons.forEach((element, index) => {
                element.Location = 'right';
                element.SortOrder = index;
            });
            commonService
                .updateUserToolOption(this.rightTaskbarButtons)
                .then((res) => {})
                .catch((err) => {});
        },
        handleSidebarWidth(evt) {
            if (evt.side == 'left') {
                // regex to match any numbers in a row, with a decimal
                // 20vw -> 20
                // 26.3vw -> 26.3
                this.leftSidebarWidth = parseFloat(
                    evt.width.match(/[0-9.]+/g).at(0)
                );
                if (this.leftSidebarWidth + this.rightSidebarWidth > 99) {
                    this.$refs.rightSidebar.close();
                }
            } else if (evt.side == 'right') {
                this.rightSidebarWidth = parseFloat(
                    evt.width.match(/[0-9.]+/g).at(0)
                );
                if (this.leftSidebarWidth + this.rightSidebarWidth > 99) {
                    this.$refs.leftSidebar.close();
                }
            }
        },
        handleNewProjects(evt) {
            this.$refs.map.zoomToLayers();
        },
        hideToast() {
            this.setToastMessage({
                showFailure: false,
            });
            this.setToastMessage({
                showSuccess: false,
            });
        },
    },
    computed: {
        ...mapState('common', [
            'showSuccess',
            'showFailure',
            'successToastMessage',
            'failureToastMessage',
            'notificationQueue',
        ]),
        ...mapState('projects', ['selectedProjects']),
        ...mapState({
            allProjects: function(state) {
                return state.projects.projectsWithGeo;
            },
        }),
        isIdentifyAvailable() {
            return this.selectedProjects.some((selected) => {
                if (selected && selected.ProjectID) {
                    return this.identifyEnabledProjects.includes(
                        selected.ProjectID
                    );
                }
                return false;
            });
        },
        identifyEnabledProjects() {
            let identifyProjectList = '';
            this.toolsArr.forEach((tool) => {
                if (tool.ToolName == 'Identify') {
                    identifyProjectList += ',' + tool.ProjectList;
                }
            });
            return identifyProjectList;
        },
        leftTaskbarButtons() {
            let taskbarButtons = [];
            this.toolsArr.forEach((element) => {
                if (element.Location === 'left') {
                    taskbarButtons.push(element);
                }
            });
            return taskbarButtons;
        },
        rightTaskbarButtons() {
            let taskbarButtons = [];
            this.toolsArr.forEach((element) => {
                if (element.Location === 'right') {
                    taskbarButtons.push(element);
                }
            });
            return taskbarButtons;
        },
    },
    watch: {
        allProjects: {
            handler(newVal) {
                if (newVal && typeof newVal === 'object') {
                    if (
                        !Object.prototype.hasOwnProperty.call(newVal, 'loading')
                    ) {
                        if (newVal.items && newVal.items.length === 1) {
                            let item = newVal.items[0];
                            projectService
                                .getProjectLogo(item.ProjectID)
                                .then((res) => {
                                    item.Logo = res.data;
                                    this.clearSelectedProjects();
                                    this.setSelectedProjects(item);
                                    let availTools = this.toolsArr.filter(
                                        (tool) =>
                                            typeof tool.Location === 'string' &&
                                            this.projectNotSelected(
                                                tool.ProjectList
                                            )
                                    );
                                    if (availTools.length === 1) {
                                        if (
                                            availTools[0].iFrame &&
                                            (localStorage.getItem(
                                                'isExternalPath'
                                            ) == null ||
                                                localStorage.getItem(
                                                    'isExternalPath'
                                                ) === 'false')
                                        ) {
                                            localStorage.setItem(
                                                'isExternalPath',
                                                true
                                            );
                                            this.$router.push({
                                                name: availTools[0].ToolName,
                                                params: {
                                                    toolId:
                                                        availTools[0].ToolID,
                                                    url: availTools[0].Url,
                                                },
                                            });
                                        }
                                    }
                                });
                        }
                    }
                }
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.header {
    position: relative;
}
.viewer {
    pointer-events: all;
    z-index: 2;
    bottom: 0;
    position: fixed;
    top: 65px;
    height: (100vh - 65px) !important;
    left: 59px;
    right: 59px;
    /* border-top: 1px lightgrey solid; */
}

.viewer-sidebar {
    z-index: 5 !important;
    height: (100vh - 65px);
    background-color: buttonface;
}

.frame-filler {
    z-index: 1 !important;
    pointer-events: none;
    background-color: buttonface;
    border-top: 1px buttonface solid;
    opacity: 0.3;
}

.full-height {
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
}
</style>
<style>
.frame {
    position: relative;
    /* border: 1px lightgrey solid; */
    opacity: 1;
    height: 100%;
}
.viewer-row {
}
.no-padding {
    padding: 0 0 0 0 !important;
}
.no-margin {
    margin: 0 0 0 0 !important;
}
</style>
