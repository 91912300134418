<template>
    <b-container>
        <div>
            <b-row class="left-shadow mapContainerRow border">
                <!-- *** Below Column shows the left hand side container for projects **** -->
                <b-col
                    lg="3"
                    md="5"
                    class="p-0 edit-layer"
                    :class="{ mainContent: edit_clicked }"
                >
                    <layer-accordion
                        id="LayerAccordion"
                        :accordionHeight="true"
                        @editLyr="openPostedEdit"
                        @editContourLyr="openContourEdit"
                        @updateMapExtent="updateMapExtent"
                        v-if="!mapExtend && !edit_clicked"
                    />
                    <posted-layer-edit
                        id="PostedLayerEdit"
                        panelWidth="small"
                        :dataset="editDataset"
                        :datasetIdx="editDatasetIdx"
                        @cancel="cancel_edit"
                        v-if="edit_clicked && edit_type == 'posted'"
                    />
                    <contour-layer-edit
                        id="ContourLayerEdit"
                        panelWidth="small"
                        :jobID="editDataset.jobID"
                        @cancel="cancel_edit"
                        v-if="edit_clicked && edit_type == 'contour'"
                    />
                </b-col>
                <!-- Below Column handles Code of Map present on Right Hand Side in View Layer -->
                <b-col
                    class="p-0 full-view"
                    v-bind:cols="mapExtend ? 12 : edit_clicked ? 6 : 9"
                    v-if="showMap"
                >
                    <a
                        href="#"
                        v-if="!mapExtend"
                        @click="mapExtendSize()"
                        class="mr-2 map_arrow"
                        ><img src="../../assets/map_toggle_left.svg"
                    /></a>
                    <a
                        href="#"
                        v-if="mapExtend"
                        @click="mapExtendSize()"
                        class="mr-2 map_arrow"
                        ><img src="../../assets/map_toggle_right.svg"
                    /></a>
                    <div id="mapContainer" v-bind:class="mapClass">
                        <!-- code for screen resize modal -->
                        <b-modal
                            id="modal-screen"
                            ok-only
                            hide-footer
                            hide-header
                            @hide="modalClose"
                            modal-class="modalFullscreen"
                        >
                            <div class="modalScreen">
                                <!-- modal map in here -->
                                <modal-leaflet-map />
                            </div>
                        </b-modal>
                        <!-- regular map below -->
                        <main-leaflet-map
                            v-if="!mapExpanded"
                            ref="mainLmap"
                            mapType="small"
                            :zoomMapExtent="mapExtent"
                        />
                    </div>
                </b-col>
            </b-row>
        </div>
    </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MainLeafletMap from '../CommonComponents/MainLeafletMap.vue';
import ModalLeafletMap from '../CommonComponents/ModalLeafletMap.vue';
import LayerAccordion from './layers/LayerAccordion.vue';
import PostedLayerEdit from '../CommonComponents/DataPostingTools/EditPostedLayer.vue';
import ContourLayerEdit from '../CommonComponents/DataPostingTools/ContouringEdit.vue';

import { projectService } from '../../services/project.service';
import { userService } from '../../services/users.service';
export default {
    name: 'MapContainer',
    components: {
        MainLeafletMap,
        ModalLeafletMap,
        LayerAccordion,
        PostedLayerEdit,
        ContourLayerEdit,
    },
    props: {
        showMap: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            aggregateTypeData: [],
            editAggrTypeRevised: [],
            fieldAggregateData: [],
            fieldAggregateDataUnique: [],
            modalLayerId: null,
            modalLayerName: null,
            mapClass: 'map-box',
            mapExtend: false,
            mapExtent: [
                [-123.77, 30.72],
                [-59, 50],
            ],
            edit_clicked: false,
            editDataset: {},
            editDatasetIdx: -1,
            edit_type: null,
        };
    },
    mounted() {
        this.$store.commit('projects/setMapExpandedOff');
        this.screenResize();
        window.onresize = () => {
            this.screenResize();
        };
    },
    methods: {
        ...mapActions('projects', ['updateSelectedLayerAccordion']),
        mapExtendSize() {
            this.mapExtend = !this.mapExtend;
            if (this.mapExtend == true) {
                this.mapClass = 'map-box-expanded';
            } else {
                this.mapClass = 'map-box';
            }
        },
        openEdit(layerDetails, index) {
            this.edit_clicked = true;
            this.editDataset = layerDetails;
            this.editDatasetIdx = index;
        },
        openPostedEdit(layerDetails, index) {
            this.edit_type = 'posted';
            this.openEdit(layerDetails, index);
        },
        openContourEdit(layerDetails, index) {
            this.edit_type = 'contour';
            this.openEdit(layerDetails, index);
        },
        cancel_edit() {
            this.edit_clicked = false;
            this.edit_type = null;
            this.postLayerActive = true;
            this.selectedPostingId = '';
            this.editDataset = {};
            this.editDatasetIdx = -1;
            this.mapClass = 'map-box';
            this.updateSelectedLayerAccordion('posted');
        },
        updateMapExtent(e) {
            this.mapExtent = e;
        },
        addAggrType(index) {
            //
            let dataTypeSetId;

            this.aggregateOption[index].aggregateType = [];
            dataTypeSetId = this.fieldAggregateData.find(
                (item) =>
                    this.aggregateOption[index].aggregateOptionField ===
                    (item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName)
            );
            if (this.aggregateOption[index].aggregateType.length === 0) {
                this.aggregateOption[index].aggregateType.push({
                    aggregateTypeName: '',
                    aggregateDate: '',
                });
                userService
                    .getAggregateOptionsByType(
                        dataTypeSetId.DatasetColumn.Datatype
                    )
                    .then((res) => {
                        this.options = res.data;
                        this.aggregateTypeData[index] = this.options;
                        if (
                            this.$store.state.store.portalsettings !== undefined
                        ) {
                            let numberPortalData = this.$store.state.store
                                .portalsettings.Number;
                            let datePortalData = this.$store.state.store
                                .portalsettings.Date;
                            let textPortalData = this.$store.state.store
                                .portalsettings.Text;
                            let result = this.options.filter(
                                (ele) =>
                                    ele.AggregateOptionID == numberPortalData ||
                                    ele.AggregateOptionID == datePortalData ||
                                    ele.AggregateOptionID == textPortalData
                            );
                            if (result.length) {
                                this.aggregateOption[
                                    index
                                ].aggregateType[0].aggregateTypeName =
                                    result[0].AggregateOptions;
                                this.validateInput = false;
                            }
                        }
                        this.$forceUpdate();
                    });
            }
        },
        modalClose() {
            this.$store.commit('projects/setMapExpandedOff');
            this.$bvModal.hide('modal-screen');
            // this.$refs.mainMap.mapObject.invalidateSize();
        },
        toggleProjectLayer(index) {
            this.$store.dispatch(
                'projectLayers/updateProjectVisibility',
                index
            );
        },
        screenResize() {
            this.winWidth = window.innerWidth;
            if (this.winWidth <= 1100) {
                this.mapExtend = true;
            } else {
                this.mapExtend = false;
            }
        },
    },
    computed: {
        ...mapState({
            mapExpanded: (state) => state.projects.mapExpanded,
            selectedLayerAccordion: (state) =>
                state.projects.selectedLayerAccordion,
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '../../assets/style/_variables.scss';

@import '../../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-layouts/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-grids/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-vue-filemanager/styles/bootstrap4.css';

@import '../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
.mainContent {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;
    padding-left: 0.75rem !important;
    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }
    h3 {
        font-size: 22px;
    }
    .some-radios {
        font-size: 14px;
        color: #626262;
    }
}
.aggr-edit,
.layer-post {
    width: 241px;
}
.mainContent.edit-layer {
    max-height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    .inputbox-edit,
    .select-edit {
        width: 241px;
        border-radius: 3px;
        padding: 7px;
        height: 48px;
        border: 1px solid rgb(206, 212, 218);
    }
    .subSelect {
        width: 180px;
        float: left;
    }
    .dlt-icon {
        position: absolute;
        right: 20px;
    }
    .edit.select-edit {
        width: 203px;
        margin-left: 15px;
    }
}
.inputbox :focus {
    border: none;
}
.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../assets/delete_icon.svg') no-repeat right !important;
}
.inputbox-color {
    height: 48px;
    width: 100%;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}
.pencil-icon {
    position: absolute;
    left: 490px;
    margin-left: -24px;
    // margin-top: 59px;
    margin-top: 23px;
    cursor: pointer;
    width: 20px;
}
.buttonSelect {
    position: absolute;
    right: 0;
    top: -45px;
}
.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}
.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}
.aggrType {
    margin-left: 36px;
    margin-top: -23px;
    width: 400px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}
.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
.map_view_icon {
    background-color: grey;
    position: relative;
    bottom: 10px;
    border: 0 !important;
    // left: 8%;
    // float: right;
    .map_view_icon:focus {
        // outline: 1px dotted;
        outline: 0px;
    }
}
.search_element {
    background-image: url(../../assets/search_dots.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
}
.pos_layer {
    width: auto;
    background-color: #f4f4f4;
    height: 80px;
    font-size: 0.9rem;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}
.pos_layer.hover {
    background-color: #f4f4f4;
}
.dataposting-icon {
    padding: 4px 2px 16px 15px;
    margin-left: -17px;
    background: #eaeaea;
}
.posted_layer {
    background: transparent;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}
.posted_layer.hover {
    background: white;
    color: #1d1d1d;
}
.project_layer {
    background: transparent;
    color: #1d1d1d;
    width: 144px;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.project_layer_active {
    background: transparent;
    color: #1d1d1d;
    width: 145px;
    margin-left: -38px;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.proj_layer {
    height: 43px;
    font-weight: 500;
    width: 100%;
}
/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}
.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}
.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}
.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}
.edit-position-wide .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 86.67px;
    height: 70px;
    border-radius: 0;
}
.edit-layer .btn-secondary:focus,
.edit-layer .btn-secondary:not(:disabled):not(.disabled):active {
    color: #1d1d1d !important;
    background-color: #eaeaea !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    //right: 15px;
    position: relative;
    //bottom: -4px;
    border-radius: 0;
}
.edit-layer .border-dark {
    height: 70px;
    padding: 0px;
    text-align: center;
    width: 80px;
}
.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}
.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}
.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}
.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}
.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}
.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
// .btn-secondary{
//   height:69px;
// }
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}
.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.edit-button {
    color: #007bff !important;
    margin-top: 0px;
    //margin-left: 96px;
}
.delete-datapost {
    margin-left: none !important;
    margin-top: 9px !important;
    font-size: 14px;
    color: #817070 !important;
}
.border-right {
    height: 20px;
}
.input-value {
    display: block;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 10px;
    padding: 10px;
    margin-top: -5px;
    width: 60px;
    text-align: center;
}
.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}
.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}
.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}
.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}
.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/* ------------------------------------------Position Button Css Ends Here------------------------------- */
.proj_layer.hover {
    background-color: white;
}
.slider_value {
    position: relative;
    bottom: 155px;
    left: 380px;
}
.slider {
    left: -3px !important;
}
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}
/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}
.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}
.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    //left: 20px;
    top: 18px;
    left: 35px;
    border: 0 !important;
}
.hidebtn {
    left: 50px;
}
.dataposting .portalbarMain {
    border-block: 0 !important;
}
.layer {
    width: 240px;
    .pencil-icon {
        left: 250px;
    }
    .inputrange.edit {
        width: 125px !important;
    }
}
.accBorder {
    // border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px#dee2e6;
}
/*.inputrange{
  width:145px!important;
}*/
.input-value {
    width: 61px;
    font-size: 14px;
}
.range-field {
    width: 310px !important;
}
.edit-layer .inputrange {
    width: 125px !important;
}
.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    width: 55px;
    font-size: 12px;
}
.layer-post .select-edit:not(:first-child) {
    width: 180px !important;
}
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    background: rgb(0, 99, 149) none repeat scroll 0% 0% !important;
    color: white !important;
    border: medium none;
    white-space: nowrap;
}
.edit-layer .bottom-btn .btn-secondary:focus,
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #006395 !important;
    width: auto;
    height: auto;
    //right: 15px;
    position: relative;
    border-radius: 0.25rem;
}
.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}
.overflow {
    overflow-y: scroll;
    max-height: 394px;
}
.hideText {
    margin-right: 10px;
}
.modalScreen {
    height: 85vh;
    z-index: 999999;
}
.map_arrow {
    position: absolute;
    /* left: -13px; */
    z-index: 999;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}
.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}
.rowData {
    margin-top: 6px;
}
.backtopost {
    position: absolute;
    top: -200px;
    right: 0;
}
.card-body {
    padding: 1rem;
}
.collapsed .when-opened {
    display: none;
}
.not-collapsed .when-closed {
    display: none;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}
.dateAggregate {
    margin-top: -22px;
}

.mapContainerRow {
    min-height: 440px;
}

.map-box {
    height: 440px;
    // min-width: 855px;
    left: auto !important;
}

.map-box-expanded {
    height: 440px;
}
.map-box-collapsed {
    width: 570px;
    height: 440px;
    left: auto !important;
}
.map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }
    #lmap1 {
        opacity: 1;
        height: 10in;
        width: 15in;
    }
    // #mapContainer{
    //   position: absolute;
    //   height: 7.5in;
    //   width: 10in;
    //   top: 0.5in;
    //   left: 0.5in;
    // }
    // .dataPost-map-box-print{
    //   position: absolute;
    //   height: 7.5in;
    //   width: 10in;
    //   top: 0.5in;
    //   left: 0.5in;
    // }
}
.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}
.showHide {
    font-size: 14px;
    margin-top: 11px;
}
.zoomTo {
    color: #006395 !important;
    margin-top: 9px !important;
    padding-right: 5px !important;
    margin-right: 5px !important;
}
.project_layers {
    background-color: #f4f4f4;
    height: 44px;
}
.btn-side {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
}
.btn-zoom {
    position: absolute;
    top: 25px;
    left: 12px;
}
.btn-edit {
    position: absolute;
    top: -10px;
}
.btn-delete {
    position: absolute;
    left: 0px;
    top: 39px;
}
</style>
