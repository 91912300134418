import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const spatialService = {
    saveToDatabase,
    deleteFromDatabase,
    mapLayers,
    featureGeometry,
    validateGeometry,
    queryLookup,
    reverseLookup,
    reverseLookupGeometry,
    queueMapbookRequest,
    getMapbookStatus,
    getDrawTypes,
    hasNewDrawings,
};

function saveToDatabase(geometry) {
    return request({
        url: apiConstants.drawingSaveToDatabase,
        data: geometry,
        method: 'post',
    });
}

function deleteFromDatabase(GeoID) {
    return request({
        url: apiConstants.drawingDeleteFromDatabase + '/' + GeoID,
        data: GeoID,
        method: 'delete',
    });
}

function mapLayers(geometry) {
    return request({
        url: apiConstants.mapLayers,
        data: geometry,
        method: 'post',
    });
}

function featureGeometry(layerId, featureId) {
    return request({
        url:
            apiConstants.featureGeometry +
            '?layerId=' +
            layerId +
            '&featureId=' +
            featureId,
        method: 'get',
    });
}

function validateGeometry(geometry) {
    return request({
        url: apiConstants.validateDrawing,
        data: geometry,
        method: 'post',
    });
}

function queryLookup(projectID, params) {
    return request({
        url: apiConstants.queryLookup,
        params: { projectId: projectID },
        data: params,
        method: 'post',
    });
}

function reverseLookup(MapLayerID, featureID) {
    return request({
        url: apiConstants.reverseLookupQuery,
        params: { MapLayerID: MapLayerID, featureID: featureID },
        method: 'get',
    });
}
function reverseLookupGeometry(MapLayerID, featureID) {
    return request({
        url: apiConstants.reverseLookupGeom,
        params: { MapLayerID: MapLayerID, featureID: featureID },
        method: 'get',
    });
}

// mapbook endpoints
function queueMapbookRequest(
    projectId,
    centerX,
    centerY,
    mapZoom,
    minX,
    minY,
    maxX,
    maxY
) {
    return request({
        url: apiConstants.queueMapbookRequest,
        params: {
            projectId: projectId,
            centerX: centerX,
            centerY: centerY,
            mapZoom: mapZoom,
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
        },
        method: 'get',
    });
}
function getMapbookStatus(jobIDs) {
    return request({
        url: apiConstants.getMapbookStatus,
        data: jobIDs,
        method: 'post',
    });
}

function getDrawTypes(projectId) {
    return request({
        url: apiConstants.getDrawTypes,
        params: { ProjectId: projectId },
        method: 'get',
    });
}

function hasNewDrawings(projectId, lastCheckTime) {
    return request({
        url: apiConstants.hasNewDrawings,
        params: { projectID: projectId, lastCheckTime: lastCheckTime },
        method: 'get',
    });
}
