import { render, staticRenderFns } from "./DataSetList.vue?vue&type=template&id=526ddcce&scoped=true&"
import script from "./DataSetList.vue?vue&type=script&lang=js&"
export * from "./DataSetList.vue?vue&type=script&lang=js&"
import style0 from "./DataSetList.vue?vue&type=style&index=0&id=526ddcce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "526ddcce",
  null
  
)

export default component.exports