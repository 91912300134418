<template>
    <div>
        <div class="Que-btn">
            <small
                v-if="reviewItem.length"
                class="text-primary reviewQueueButton"
                @click="reviewQueue"
                >Review queue</small
            >
            <small
                v-if="!reviewItem.length"
                class="text-secondary reviewQueueButton"
                >Review queue</small
            >
            <div v-if="reviewItem.length">
                <b-button
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueGroupChanges()"
                    >Queue changes
                </b-button>
            </div>
            <div v-if="!reviewItem.length">
                <b-button
                    v-if="parentGroupIds.length || childGroupIds.length"
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueGroupChanges()"
                    >Queue changes
                </b-button>
                <b-button
                    v-if="!parentGroupIds.length || !childGroupIds.length"
                    rounded
                    class="buttonSelect disableButton queueChange ml-3"
                    >Queue changes
                </b-button>
            </div>
        </div>

        <b-row class="access-blk">
            <b-col class="mt-2">
                <div class="card bg-light mb-3 border-grey">
                    <div class="card-header">Groups</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchGroups && !showSearchIconGroups
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroups"
                                    @mouseover="searchOpenGroups"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchGroups && !showSearchIconGroups
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroups"
                                    @mouseover="searchOpenGroups"
                                    >Search</span
                                >
                                <input
                                    ref="groups"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchGroups || showSearchIconGroups
                                            ? true
                                            : false
                                    "
                                    v-model="searchGroups"
                                    :class="
                                        searchGroups || showSearchIconGroups
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseGroups"
                                    @click="closeBarGroups"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <!--<input
                    type="checkbox"
                    class="pl-3 ml-2"
                   @click="selectAllParentGroup"
                    v-model="allSelected"
                    />--><span class="portal-lbl">Name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table
                                    class="table"
                                    striped
                                    id="groupToolTable"
                                    :per-page="perPage"
                                >
                                    <!-- <thead>
                                    <tr>
                                    
                                    <th scope="col">
                                    </tr>
                                </thead> -->
                                    <tbody :per-page="perPage">
                                        <tr
                                            v-for="(pg,
                                            index) in groupsFilteredList"
                                            :key="index"
                                            :value="pg.UserGroupName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate slash-checkbox"
                                                            @click="
                                                                checkedParentGroup(
                                                                    pg.UserGroupID
                                                                )
                                                            "
                                                            :value="
                                                                pg.UserGroupID
                                                            "
                                                            v-model="
                                                                parentGroupIds
                                                            "
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                pg.UserGroupName
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesGroups[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            pg.ParentGroups &&
                                                                                pg
                                                                                    .ParentGroups
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchUserGroupNameModel &&
                                                                                    !showSearchIconGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchUserGroupNameModel &&
                                                                                    !showSearchIconGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="groupInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width357"
                                                                                v-if="
                                                                                    searchUserGroupNameModel ||
                                                                                    showSearchIconGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchUserGroupNameModel
                                                                                "
                                                                                :class="
                                                                                    searchUserGroupNameModel ||
                                                                                    showSearchIconGroupsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseGroupsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarGroupsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                            <!-- <span class="border-right ml-2">&nbsp;</span> -->
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="kg in $options.filters.filterByProperty(
                                                                            pg.ParentGroups,
                                                                            '',
                                                                            'UserGroupName',
                                                                            searchUserGroupNameModel
                                                                        )"
                                                                        :key="
                                                                            kg.UserGroupName
                                                                        "
                                                                        :value="
                                                                            kg.UserGroupName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                checked
                                                                                @click="
                                                                                    childCheck(
                                                                                        pg.UserGroupID,
                                                                                        kg.UserGroupID,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="marginLeft25"
                                                                                >{{
                                                                                    kg.UserGroupName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="groupList"
                    @changePage="onChangePage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div class="card-footer" v-if="pageOfItems.length > 0">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="groupToolTable"
                            @change="collapseAllGroups"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>

            <b-col class="mt-2 pl-2 ml-5">
                <div class="card bg-light maxWidth499 mb-3 border-grey">
                    <div class="card-header">Child groups</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchGroupofGroups &&
                                        !showSearchIconChildGroups
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenChildGroups"
                                    @mouseover="searchOpenChildGroups"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchGroupofGroups &&
                                        !showSearchIconChildGroups
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenChildGroups"
                                    @mouseover="searchOpenChildGroups"
                                    >Search</span
                                >
                                <input
                                    ref="childGroup"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchGroupofGroups ||
                                        showSearchIconChildGroups
                                            ? true
                                            : false
                                    "
                                    v-model="searchGroupofGroups"
                                    :class="
                                        searchGroupofGroups ||
                                        showSearchIconChildGroups
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseChildGroups"
                                    @click="closeBarChildGroups"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    class="pl-3 ml-2"
                                    @click="selectAllChildGroup"
                                    v-model="allSelectedGroups"
                                />
                                <span class="portal-lbl">Name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table
                                    class="table"
                                    striped
                                    id="ToolTable"
                                    :per-page="perPageGroups"
                                >
                                    <tbody>
                                        <tr
                                            v-for="(dc,
                                            index) in groupofGroupsFilteredList"
                                            :key="index"
                                            :value="dc.UserGroupName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate slash-checkbox"
                                                            @click="
                                                                checkedChildGroup(
                                                                    dc.UserGroupID
                                                                )
                                                            "
                                                            :value="
                                                                dc.UserGroupID
                                                            "
                                                            v-model="
                                                                childGroupIds
                                                            "
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                dc.UserGroupName
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesChildGroups[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            dc.ParentGroups &&
                                                                                dc
                                                                                    .ParentGroups
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchChildUserGroupNameModel &&
                                                                                    !showSearchIconChildGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenChildGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenChildGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchChildUserGroupNameModel &&
                                                                                    !showSearchIconChildGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenChildGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenChildGroupsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="childGroupInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width357"
                                                                                v-if="
                                                                                    searchChildUserGroupNameModel ||
                                                                                    showSearchIconChildGroupsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchChildUserGroupNameModel
                                                                                "
                                                                                :class="
                                                                                    searchChildUserGroupNameModel ||
                                                                                    showSearchIconChildGroupsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseChildGroupsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarChildGroupsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                            <!-- <span class="border-right ml-2">&nbsp;</span> -->
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="t in $options.filters.filterByProperty(
                                                                            dc.ParentGroups,
                                                                            '',
                                                                            'UserGroupName',
                                                                            searchChildUserGroupNameModel
                                                                        )"
                                                                        :key="
                                                                            t.UserGroupName
                                                                        "
                                                                        :value="
                                                                            t.UserGroupName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                checked
                                                                                @click="
                                                                                    childCheck(
                                                                                        t.UserGroupID,
                                                                                        dc.UserGroupID,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="marginLeft25"
                                                                                >{{
                                                                                    t.UserGroupName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>

                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="groupOfGroupsList"
                    @changePage="onChangeGroupofGroupsPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div
                        class="card-footer"
                        v-if="pageOfItemsGroups.length > 0"
                    >
                        <b-pagination
                            v-model="currentPageGroups"
                            :total-rows="rowsGroups"
                            :per-page="perPageGroups"
                            aria-controls="ToolTable"
                            @change="collapseAllChildGroups"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="group-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="d-block text-center" v-if="!reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                There is no item to review.
            </div>
            <div class="d-block" v-if="reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <div class="text-center">
                    <h4 class="my-2">Change queue</h4>
                    <small
                        >Review {{ parentGroupIds.length }} changes pending
                        before commiting the changes.</small
                    >
                </div>
                <div class="my-3 tblScroll">
                    <table class="table table-striped dataSetModal">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Access</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr
                                v-for="(item, index) in reviewItem"
                                :key="index"
                            >
                                <td>{{ item.parentGroupName }}</td>
                                <td v-if="item.isActive">
                                    <img src="../../../assets/icon_tick.svg" />
                                </td>
                                <td v-if="!item.isActive">
                                    <img src="../../../assets/close.svg" />
                                </td>
                                <td>{{ item.childGroupName }}</td>
                                <td class="deleteCell" @click="delQueue(index)">
                                    Delete from queue&nbsp;
                                    <img src="../../../assets/close.svg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 text-center">
                    <a class="text-primary" @click="clearQueue">Clear queue </a>
                    <button class="buttonSelect ml-3" @click="commitChanges">
                        Commit changes
                    </button>
                </div>
            </div>
        </b-modal>
        <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../../assets/success_tick.svg" class="mr-3"/>
               <span v-if="reviewItem.length"> <b>Changes queued </b><br/>{{successToastValue}} <br/>All the requested changes to be inserted into the queue.</span>
                <span v-if="!reviewItem.length">{{successToastValue}}</span>
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../../assets/failure_cross.svg" class="mr-3"/>
                {{failureToastValue}}
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
    </div>
</template>

<script>
// import router from "../../router";

import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import { mapActions, mapMutations } from 'vuex';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    props: ['groupCreated'],
    watch: {
        groupCreated: function(newVal, oldVal) {
            // watch it
            this.getGroupList();
            this.getGroupsOfgroup();
        },
    },
    data() {
        return {
            parentGroupIds: [],
            childGroupIds: [],
            // successToast: false,
            // failureToast:false,
            // successToastValue:'',
            // failureToastValue:'',
            reviewItem: [],
            searchText: true,
            searchTextData: true,
            showTextData: false,
            customLabels,
            showText: false,
            searchGroups: '',
            searchGroupofGroups: '',
            allSelected: false,
            allSelectedGroups: false,
            perPage: 5,
            perPageGroups: 5,
            groupList: [],
            pageOfItems: [],
            groupOfGroupsList: [],
            pageOfItemsGroups: [],
            pageOfGroupofGroups: [],
            showPage: false,
            thisfilename: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            currentPage: 1,
            currentPageGroups: 1,
            groupListFiltered: [],
            groupOfGroupsListFiltered: [],
            searchUserGroupNameModel: '',
            searchUserGroupNameInput: true,
            searchChildUserGroupNameInput: true,
            searchChildUserGroupNameModel: '',
            collapseStatesGroups: [],
            collapseStatesChildGroups: [],
            searchCloseGroups: false,
            showSearchIconGroups: false,
            searchCloseGroupsInner: false,
            showSearchIconGroupsInner: false,
            showSearchIconChildGroups: false,
            searchCloseChildGroups: false,
            showSearchIconChildGroupsInner: false,
            searchCloseChildGroupsInner: false,
        };
    },
    created() {
        this.getGroupList();
        this.getGroupsOfgroup();
    },
    mounted() {
        //  this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            vm.showPage = true;
            data.forEach((element) => {
                if (element.PageName.includes('GAGroups')) {
                    vm.showPage = true;
                }
            });
            this.$emit('clickedGroups', this.showPage);
        },
        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                data.push({
                    UserGroupId: item.parentGroupId,
                    ChildGroupId: item.childGroupId,
                    isActive: item.isActive,
                });
            });

            managementService.updateUserGroupsMapping(data).then((res) => {
                if (res.data.StatusCode === 201) {
                    this.setToastMessage({
                        showSuccess: true,
                        showFailure: false,
                        successToastMessage: 'Association done successfully.',
                        failureToastMessage: null,
                        messageTitle: null,
                    });

                    this.reviewItem = [];
                    this.getGroupList();
                    this.getGroupsOfgroup();
                    this.parentGroupIds = [];
                    this.childGroupIds = [];
                    this.$refs['group-modal'].hide();
                } else if (res.data.StatusCode === 500) {
                    this.$refs['group-modal'].hide();

                    this.setToastMessage({
                        showSuccess: false,
                        showFailure: true,
                        successToastMessage: null,
                        failureToastMessage: res.data.Message,
                        messageTitle: null,
                    });
                }
            });
        },
        searchOpenGroups() {
            this.searchCloseGroups = !this.searchCloseGroups;
            this.showSearchIconGroups = true;
            this.$nextTick(() => {
                this.$refs.groups.focus();
            });
        },
        closeBarGroups() {
            this.searchCloseGroups = !this.searchCloseGroups;
            this.showSearchIconGroups = false;
            this.searchGroups = null;
        },

        searchOpenGroupsInner(index) {
            this.searchCloseGroupsInner = !this.searchCloseGroupsInner;
            this.showSearchIconGroupsInner = true;
            this.$nextTick(() => {
                this.$refs.groupInner[index].focus();
            });
        },
        closeBarGroupsInner() {
            this.searchCloseGroupsInner = !this.searchCloseGroupsInner;
            this.showSearchIconGroupsInner = false;
            this.searchUserGroupNameModel = null;
        },

        searchOpenChildGroups() {
            this.searchCloseChildGroups = !this.searchCloseChildGroups;
            this.showSearchIconChildGroups = true;
            this.$nextTick(() => {
                this.$refs.childGroup.focus();
            });
        },
        closeBarChildGroups() {
            this.searchCloseChildGroups = !this.searchCloseChildGroups;
            this.showSearchIconChildGroups = false;
            this.searchGroupofGroups = null;
        },

        closeBarChildGroupsInner() {
            this.searchCloseChildGroupsInner = !this
                .searchCloseChildGroupsInner;
            this.showSearchIconChildGroupsInner = false;
            this.searchChildUserGroupNameModel = null;
        },
        searchOpenChildGroupsInner(index) {
            this.searchCloseChildGroupsInner = !this
                .searchCloseChildGroupsInner;
            this.showSearchIconChildGroupsInner = true;
            this.$nextTick(() => {
                this.$refs.childGroupInner[index].focus();
            });
        },

        reviewQueue() {
            this.$refs['group-modal'].show();
        },
        collapseAllGroups() {
            this.collapseStatesGroups = this.groupList.map(
                (x) => (x.open = false)
            );
        },
        collapseAllChildGroups() {
            this.collapseStatesChildGroups = this.groupOfGroupsList.map(
                (x) => (x.open = false)
            );
        },
        selectAllParentGroup(pg) {
            this.parentGroupIds = [];
            if (!this.allSelected) {
                for (pg in this.groupList) {
                    this.parentGroupIds.push(this.groupList[pg].UserGroupID);
                }
            }
        },
        selectAllChildGroup(d) {
            this.childGroupIds = [];
            if (!this.allSelectedGroups) {
                for (d in this.groupOfGroupsList) {
                    this.childGroupIds.push(
                        this.groupOfGroupsList[d].UserGroupID
                    );
                }
            }
        },
        checkedParentGroup(id) {
            if (this.parentGroupIds.length == 0) {
                this.parentGroupIds.push(id);
            } else {
                let found;
                found = this.parentGroupIds.find((item) => item === id);
                if (found) {
                    this.parentGroupIds.splice(
                        this.parentGroupIds.indexOf(id),
                        1
                    );
                } else {
                    this.parentGroupIds.push(id);

                    setTimeout(() => {
                        this.parentGroupIds.splice(
                            this.parentGroupIds.indexOf(id),
                            1
                        );
                    }, 1500);

                    this.setToastMessage({
                        showSuccess: false,
                        showFailure: true,
                        successToastMessage: null,
                        failureToastMessage:
                            'Please select only one group from the left panel for association',
                        messageTitle: null,
                    });
                }
            }
            /*if(this.parentGroupIds.indexOf(id)>-1){
                this.parentGroupIds.splice(this.parentGroupIds.indexOf(id),1)
                this.allSelected = false
            } else { 
                this.parentGroupIds.push(id)
            }*/
        },
        checkedChildGroup(id) {
            if (this.childGroupIds.indexOf(id) > -1) {
                this.childGroupIds.splice(this.childGroupIds.indexOf(id), 1);
                this.allSelectedGroups = false;
            } else {
                this.childGroupIds.push(id);
            }
        },
        delQueue(index) {
            let removeItem = this.reviewItem[index];
            this.reviewItem.splice(index, 1);
            let groupIdFound = this.reviewItem.findIndex(
                (item) => item.parentGroupId == removeItem.parentGroupId
            );
            if (groupIdFound == -1) {
                let groupId = this.parentGroupIds.findIndex(
                    (item) => item == removeItem.parentGroupId
                );
                this.parentGroupIds.splice(groupId, 1);
            }
            let childIdFound = this.reviewItem.findIndex(
                (item) => item.childGroupId == removeItem.childGroupId
            );
            if (childIdFound == -1) {
                let childGroupId = this.childGroupIds.findIndex(
                    (item) => item == removeItem.childGroupId
                );
                this.childGroupIds.splice(childGroupId, 1);
            }

            /* if(!this.reviewItem.length){
                this.$refs["group-model"].hide();
            }*/
        },
        clearQueue() {
            this.parentGroupIds = [];
            this.childGroupIds = [];
            this.reviewItem = [];
            this.$refs['group-modal'].hide();
        },
        childCheck(groupId, userId, event) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return item.groupID == groupId && item.userId == userId;
            });
            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    this.reviewItem.splice(unCheckedItem, 1);
                }
            } else {
                let groupName = this.groupList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                //
                let toolName = this.groupOfGroupsList.filter((item) => {
                    return item.UserGroupID == userId;
                });
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return (
                            item.UserGroupID == groupId &&
                            item.UserGroupID == userId
                        );
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            parentGroupId: groupId,
                            childGroupId: userId,
                            parentGroupName: groupName[0].UserGroupName,
                            childGroupName: toolName[0].UserGroupName,
                            isActive: false,
                        });
                    }
                }
            }
        },
        queueGroupChanges() {
            if (
                (this.parentGroupIds.length && this.childGroupIds.length) ||
                this.reviewItem.length
            ) {
                if (this.parentGroupIds.length && this.childGroupIds.length) {
                    // this.reviewItem=[]
                    let selectedGroups = {};
                    this.parentGroupIds.forEach((groupItem) => {
                        let selectedChildGroup = [];
                        this.childGroupIds.forEach((childItem) => {
                            selectedChildGroup.push(childItem);
                        });
                        selectedGroups[groupItem] = selectedChildGroup;
                    });
                    for (let props in selectedGroups) {
                        let parentGroupName = this.groupList.filter((item) => {
                            // return item.UserGroupParentID == props;
                            return item.UserGroupID == props;
                        });
                        for (let i = 0; i < selectedGroups[props].length; i++) {
                            let childGroupName = this.groupOfGroupsList.filter(
                                (subItem) => {
                                    return (
                                        subItem.UserGroupID ==
                                        selectedGroups[props][i]
                                    );
                                }
                            );
                            let exist = this.reviewItem.findIndex(
                                (item) =>
                                    item.parentGroupId == props &&
                                    item.childGroupId ==
                                        selectedGroups[props][i]
                            );
                            if (exist === -1) {
                                this.reviewItem.push({
                                    parentGroupId: props,
                                    childGroupId: selectedGroups[props][i],
                                    parentGroupName:
                                        parentGroupName[0].UserGroupName,
                                    childGroupName:
                                        childGroupName[0].UserGroupName,
                                    isActive: true,
                                });
                            }
                        }
                    }
                    /* this.parentGroupIds.forEach((parentGroupItem)=>{ 
                        let parentGroupName = this.groupList.filter((item)=>{
                            return item.UserGroupParentID == parentGroupItem;
                        })
                        // 
                        this.childGroupIds.forEach((childGroupItem)=>{
                            let childGroupName = this.groupOfGroupsList.filter((subItem)=>{
                                return subItem.ChildGroupID == childGroupItem;
                            })
                            this.reviewItem.push({
                                "parentGroupId":parentGroupItem,
                                "childGroupId":childGroupItem,
                                "parentGroupName": parentGroupName[0].UserGroupName,
                                "childGroupName": childGroupName[0].DatasetName,
                                "isActive": true
                            })
                            this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.parentGroupId)))
                            .map(id=>{
                                return this.reviewItem.find(a=> a.parentGroupId ===id)
                            })
                        })
                    })*/
                }
                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';
                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    successToastMessage: null,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: null,
                });
            }
        },
        // closeSuccessToast(){
        //     this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        hideModal() {
            this.$refs['group-modal'].hide();
        },
        getGroupList() {
            managementService.getGroupList().then((res) => {
                this.groupList = res.data;
                this.groupListFiltered = res.data;
            });
        },
        getGroupsOfgroup() {
            managementService.getGroupsOfgroup().then((res) => {
                this.groupOfGroupsList = res.data;
                this.groupOfGroupsListFiltered = res.data;
            });
        },
        onChangePage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        addSearchText() {
            if (this.searchGroups == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextData() {
            if (this.searchGroupofGroups.length == 0) {
                this.searchTextData = true;
                this.showTextData = false;
            }
        },
        removeSearchTextData() {
            this.searchTextData = false;
            this.showTextData = true;
        },
        // selectAll(p) {
        //     this.projectchildGroupIds = [];

        //     if (!this.allSelected) {
        //         for (p in this.groupList) {
        //         this.projectchildGroupIds.push(this.groupList[p].id);
        //         }
        //     }
        // },
        onChangeGroupofGroupsPage(pageOfGroupofGroups) {
            this.pageOfGroupofGroups = pageOfGroupofGroups;
        },

        checkedBox() {},
    },
    computed: {
        groupsFilteredList() {
            let vm = this;
            if (this.searchGroups) {
                vm.pageOfItems = vm.groupListFiltered.filter((post) => {
                    return post.UserGroupName.toLowerCase().includes(
                        vm.searchGroups.toLowerCase()
                    );
                });
            } else {
                vm.pageOfItems = vm.groupListFiltered;
            }
            return vm.pageOfItems.slice(
                (vm.currentPage - 1) * vm.perPage,
                vm.currentPage * vm.perPage
            );
        },
        groupofGroupsFilteredList() {
            // return this.pageOfGroupofGroups.filter((post) => {
            //     return post.UserGroupName.toLowerCase().includes(
            //     this.searchGroupofGroups.toLowerCase()
            //     );
            // });
            let vm = this;
            if (this.searchGroupofGroups) {
                vm.pageOfItemsGroups = vm.groupOfGroupsListFiltered.filter(
                    (post) => {
                        return post.UserGroupName.toLowerCase().includes(
                            vm.searchGroupofGroups.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfItemsGroups = vm.groupOfGroupsListFiltered;
            }
            return vm.pageOfItemsGroups.slice(
                (vm.currentPageGroups - 1) * vm.perPageGroups,
                vm.currentPageGroups * vm.perPageGroups
            );
        },
        rows() {
            return this.pageOfItems.length;
        },
        rowsGroups() {
            return this.pageOfItemsGroups.length;
        },
        // rowsPortal() {
        //     return this.pageOfPortalGroup.length
        // },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   color: black;
//   text-align: left;
//   span {
//     margin-left: 25px;
//     margin-left: 30px;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plus_icon.svg') no-repeat right 0.75rem
        center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/blackminus.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   //width: 388px;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
// .paginationStyle {
//   border: none;
//   /* margin: 0px auto !important; */
//   width: 200px;
//   margin: 0px;
//   padding: 0px;
//   display: contents !important;
// }
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
    color: #006395 !important;
}
// input.test {
//   width: 411px;
// }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 405px;
}
// .buttonSelect.queueChange{
//     position: absolute;
//     right: 20px;
//     top: -72px;
// }
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .row1 {
        border-bottom: 3px solid #eaeaea;
        // padding-top: 10px;
        // h6.blue {
        //   color: #006395 !important;
        // }
    }

    .header {
        padding: 10px;
        border-bottom: 3px solid;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
// .reviewQueueButton{
//     position: absolute;
//     top:-58px;
//     right:175px;
//     cursor: pointer;
// }

.model_style {
    width: 900px !important;
}
table.dataSetModal tr:first-child {
    background: white;
}
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
.userrightmanagement .width357 {
    width: 357px !important;
}
</style>
