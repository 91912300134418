<template>
    <l-control
        class="zoomControlWrapper"
        :position="position"
        @ready="zoomReady"
    >
        <button class="plus btn Icons" @click="increaseZoom">
            <slot name="plusIcon">+</slot></button
        ><br />
        <button class="minus btn Icons" @click="decreaseZoom">
            <slot name="minusIcon">-</slot></button
        ><br />
    </l-control>
</template>

<script>
import { LControl } from 'vue2-leaflet';

export default {
    name: 'LControlZoom',
    components: {
        LControl,
    },
    props: {
        position: {
            type: String,
            default: 'topright',
        },
    },
    mounted() {
        this.controlOptions = {
            position: this.position,
        };
        this.$emit('ready', this.mapObject);
    },
    methods: {
        increaseZoom() {
            if (this.$parent.$parent.zoom < this.$parent.$parent.maxZoom) {
                this.$parent.$parent.zoom++;
            }
        },
        // removes empty geometries and removes previous measure results
        decreaseZoom() {
            if (this.$parent.$parent.zoom > this.$parent.$parent.minZoom) {
                this.$parent.$parent.zoom--;
            }
        },
        zoomReady() {},
    },
};
</script>

<style>
.zoomControlWrapper {
    background-color: '#00000000';
}
.Icons {
    width: 62px;
    height: 62px;
    background: white !important;
    border-bottom: 1px black !important;
    padding: 0 !important;
}
.zoomControlWrapper .plus {
    margin-bottom: 10px;
}
.Icons img {
    width: 21.56px;
    height: 21.56px;
}
</style>
