<template>
    <div id="app" class="carportal">
        <div id="nav">
            <!-- <router-link to="/">Home</router-link> | -->
            <!-- <router-link to="/about">About</router-link> -->
        </div>
        <router-view />
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'App',
    created() {
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                if (error.response.status === 401) {
                    document.cookie.split(';').forEach(function(c) {
                        document.cookie = c
                            .replace(/^ +/, '')
                            .replace(/=.*/, '=;max-age=-1;path=/');
                    });
                    this.$router.push(`/`).catch(() => {});
                }
                return Promise.reject(error);
            }
        );
    },
};
</script>
<style>
@import '../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap4.css';
@import 'https://cdn.syncfusion.com/ej2/bootstrap.css';
/* #app, .e-grid{
  font-family: 'IBM Plex Sans', Medium,sans-serif;
} */

.e-grid .e-headercell {
    height: 50px;
}

/* .badge-primary{
  background-color: #006395 !important;
} */

.blue-color {
    color: #006395 !important;
}
</style>
