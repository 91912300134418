<template>
    <div class="checkboxImg">
        <input
            type="checkbox"
            class="checkBox"
            v-model="layer.isChecked"
            @change="updateIsChecked({ id: layer.id, visibility: 'noChange' })"
            :disabled="
                (layer.maxZoom < zoomLevel || layer.minZoom > zoomLevel) &&
                    layer.children.length == 0
            "
        />
        <span v-if="showLabel" class="entryLabel">{{ layer.name }}</span>
        <div v-if="layer.children.length == 0" class="imgDiv">
            <img :src="layer.legend" />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
    name: 'imgNode',
    props: {
        // want the object with the data that is being rendered
        id: {
            required: true,
            type: Number,
        },
        // whether or not to show the label for an item, can set false to hide labels
        showLabel: {
            required: false,
            type: Boolean,
            default: true,
        },
        zoomLevel: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    methods: {
        ...mapMutations('emergencyManagement', ['updateIsChecked']),
        // loads the image
        //  https://stackoverflow.com/a/47480286/8061390
    },
    computed: {
        ...mapState('emergencyManagement', ['layersStruct']),
        layer() {
            if (this.layersStruct && this.layersStruct[this.id]) {
                return this.layersStruct[this.id];
            } else {
                return {};
            }
        },
    },
};
</script>

<style>
.imgDiv {
    padding-left: 15px;
}

.entryLabel {
    color: black;
    font-size: 12pt;
}
.checkboxImg {
    padding-top: 4px;
}
.checkBox {
    margin-right: 5px;
}
</style>
