import axios from 'axios';
import { apiConstants } from '../common/apiConst';
import { request } from './request';

export const eTrackerService = {
    getOrganizations,
    getContactTypes,
    getContacts,
    saveProject,
    getProjects,
    getProjectById,
    getProjectContactsForProject,
};

function getOrganizations() {
    return request({ url: apiConstants.getOrganizations })
}

function getContactTypes() {
    return request({ url: apiConstants.getContactTypes })
}

function getContacts() {
    return request({ url: apiConstants.getContacts })
}

function saveProject(projectData) {
    return request({ url: apiConstants.saveProject, data: projectData, method: 'post' })
}

function getProjects() {
    return request({ url: apiConstants.getProjects })
}

function getProjectById(projectId) {
    return request({ url: apiConstants.getProjectById + '/' + projectId })
}

function getProjectContactsForProject(projectId) {
    return request({ url: apiConstants.getProjectContactsForProject + '/' + projectId })
}