<template>
    <div>
        <div class="Que-btn">
            <small
                v-if="reviewItem.length"
                class="text-primary reviewQueueButton"
                @click="reviewQueue"
                >Review queue</small
            >
            <small
                v-if="!reviewItem.length"
                class="text-secondary reviewQueueButton"
                >Review queue</small
            >
            <div v-if="reviewItem.length">
                <b-button
                    rounded
                    class="buttonSelect queueChange ml-2"
                    @click="queueProjectChanges()"
                >
                    Queue changes
                </b-button>
            </div>
            <div v-if="!reviewItem.length">
                <b-button
                    v-if="projectIds.length || dataSetIds.length"
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueProjectChanges()"
                >
                    Queue changes
                </b-button>
                <b-button
                    v-if="!projectIds.length || !dataSetIds.length"
                    rounded
                    class="buttonSelect disableButton queueChange ml-3"
                >
                    Queue changes
                </b-button>
            </div>
        </div>
        <b-row class="access-blk">
            <b-col class="mt-2">
                <div class="card bg-light mb-3 border-grey">
                    <div class="card-header">Projects</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchProject && !showSearchProjects
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenProjects"
                                    @mouseover="searchOpenProjects"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchProject && !showSearchProjects
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenProjects"
                                    @mouseover="searchOpenProjects"
                                    >Search</span
                                >
                                <input
                                    ref="projects"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchProject || showSearchProjects
                                            ? true
                                            : false
                                    "
                                    v-model="searchProject"
                                    :class="
                                        searchProject || showSearchProjects
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseProjectls"
                                    @click="closeBarProjects"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    class="pl-3 ml-2"
                                    @click="selectAllProjects"
                                    v-model="allSelected"
                                /><span class="portal-lbl">Project name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table
                                    class="table"
                                    striped
                                    id="groupTable"
                                    :per-page="perPage"
                                >
                                    <tbody :per-page="perPage">
                                        <tr
                                            v-for="(p,
                                            index) in projectFilteredList"
                                            :key="index"
                                            :value="p.ProjectName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            @click="
                                                                checkedProject(
                                                                    p.ProjectID
                                                                )
                                                            "
                                                            :value="p.ProjectID"
                                                            v-model="projectIds"
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                p.ProjectName
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesProjects[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            p.Dataset &&
                                                                                p
                                                                                    .Dataset
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchDatasetAliasModel &&
                                                                                    !showSearchProjectsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchDatasetAliasModel &&
                                                                                    !showSearchProjectsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenProjectsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="projectsInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="w-357"
                                                                                v-if="
                                                                                    searchDatasetAliasModel ||
                                                                                    showSearchProjectsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchDatasetAliasModel
                                                                                "
                                                                                :class="
                                                                                    searchDatasetAliasModel ||
                                                                                    showSearchProjectsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchClosetoolsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarProjectsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(k,
                                                                        index) in $options.filters.filterByProperty(
                                                                            p.Dataset,
                                                                            '',
                                                                            'DatasetAlias',
                                                                            searchDatasetAliasModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            k.DatasetName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                checked
                                                                                @click="
                                                                                    childCheck(
                                                                                        p.ProjectID,
                                                                                        k.DatasetID,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="slash-text marginLeft25"
                                                                                >{{
                                                                                    k.DatasetAlias
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="projectList"
                    @changePage="onChangePage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div class="card-footer" v-if="pageOfItems.length > 0">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="groupTable"
                            @change="selectAllProjectsTab"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>

            <b-col class="mt-2 pl-2 ml-5">
                <div class="card bg-light mb-3 border-grey maxWidth499">
                    <div class="card-header">Datasets</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchProjectDatasets &&
                                        !showSearchDatasets
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenDatasets"
                                    @mouseover="searchOpenDatasets"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchProjectDatasets &&
                                        !showSearchDatasets
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenDatasets"
                                    @mouseover="searchOpenDatasets"
                                    >Search</span
                                >
                                <input
                                    ref="datasets"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchProjectDatasets ||
                                        showSearchDatasets
                                            ? true
                                            : false
                                    "
                                    v-model="searchProjectDatasets"
                                    :class="
                                        searchProjectDatasets ||
                                        showSearchDatasets
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseDatasets"
                                    @click="closeBarDatasets"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <input
                                    type="checkbox"
                                    @click="selectAllDatasets"
                                    v-model="allSelectedDataSets"
                                    class="pl-3 ml-2"
                                />
                                <span class="portal-lbl">Name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table class="table" id="DatasetTable" striped>
                                    <tbody>
                                        <tr
                                            v-for="(d,
                                            index) in datasetsFilteredList"
                                            :key="index"
                                            :value="d.DatasetName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td v-if="d.DatasetAlias">
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                        ><input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            v-model="dataSetIds"
                                                            :value="d.DatasetId"
                                                            @click="
                                                                checkedDataset(
                                                                    d.DatasetId
                                                                )
                                                            "
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                d.DatasetAlias
                                                            }}</span></b-button
                                                        >
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesDatasets[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            d.Project &&
                                                                                d
                                                                                    .Project
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchProjectNameModel &&
                                                                                    !showSearchDatasetsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenDatasetsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenDatasetsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchProjectNameModel &&
                                                                                    !showSearchDatasetsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenDatasetsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenDatasetsInner(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="datasetsInner"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="w-357"
                                                                                v-if="
                                                                                    searchProjectNameModel ||
                                                                                    showSearchDatasetsInner
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchProjectNameModel
                                                                                "
                                                                                :class="
                                                                                    searchProjectNameModel ||
                                                                                    showSearchDatasetsInner
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchClosedatasetsInner
                                                                                "
                                                                                @click="
                                                                                    closeBarDatasetsInner
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(t,
                                                                        index) in $options.filters.filterByProperty(
                                                                            d.Project,
                                                                            '',
                                                                            'ProjectName',
                                                                            searchProjectNameModel
                                                                        )"
                                                                        :key="
                                                                            index
                                                                        "
                                                                        :value="
                                                                            t.ProjectName
                                                                        "
                                                                    >
                                                                        <td
                                                                            class=""
                                                                        >
                                                                            <input
                                                                                type="checkbox"
                                                                                class="slash-checkbox"
                                                                                checked
                                                                                @click="
                                                                                    childCheck(
                                                                                        t.ProjectID,
                                                                                        d.DatasetId,
                                                                                        $event
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="slash-text marginLeft25"
                                                                                >{{
                                                                                    t.ProjectName
                                                                                }}</span
                                                                            >
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="datasetList"
                    @changePage="onChangeProjectDatasetPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div
                        class="card-footer"
                        v-if="pageOfProjectDataset.length > 0"
                    >
                        <b-pagination
                            v-model="currentPageDataset"
                            :total-rows="rowsDataset"
                            :per-page="perPage"
                            aria-controls="DatasetTable"
                            @change="selectAllDatasetsTab"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal
            ref="dataset-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="d-block text-center" v-if="!reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                There is no item to review.
            </div>
            <div class="d-block" v-if="reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <div class="text-center">
                    <h4 class="my-2">Change queue</h4>
                    <small
                        >Review {{ reviewItem.length }} changes pending before
                        commiting the changes.</small
                    >
                </div>
                <div class="my-3 tblScroll">
                    <table class="table table-striped dataSetModal">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Access</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr
                                v-for="(item, index) in reviewItem"
                                :key="index"
                            >
                                <td>{{ item.projectName }}</td>
                                <td v-if="item.isActive">
                                    <img src="../../../assets/icon_tick.svg" />
                                </td>
                                <td v-if="!item.isActive">
                                    <img src="../../../assets/close.svg" />
                                </td>
                                <td>{{ item.dataSetName }}</td>
                                <td class="deleteCell" @click="delQueue(index)">
                                    Delete from queue&nbsp;
                                    <img src="../../../assets/close.svg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 text-center">
                    <a class="text-primary" @click="clearQueue">Clear queue </a>
                    <button class="buttonSelect ml-3" @click="commitChanges">
                        Commit changes
                    </button>
                </div>
            </div>
        </b-modal>

        <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../../assets/success_tick.svg" class="mr-3"/>
               <span v-if="reviewItem.length"> <b>Changes queued </b><br/>{{successToastValue}} <br/>All the requested changes to be inserted into the queue.</span>
                <span v-if="!reviewItem.length">{{successToastValue}}</span>
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeSuccessToast"/>
            </div>
            <div class="failureToastMsg" v-if="failureToast">
                <img src="../../../assets/failure_cross.svg" class="mr-3"/>
                 <br/> {{failureToastValue}}
                <img src="../../../assets/close_big.svg" class="mx-4" @click="closeFailureToast"/>
            </div> -->
    </div>
</template>

<script>
// import router from "../../router";

import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import { mapActions, mapMutations } from 'vuex';

const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    data() {
        return {
            searchText: true,
            searchTextData: true,
            showTextData: false,
            customLabels,
            showText: false,
            searchProject: '',
            searchProjectDatasets: '',
            allSelected: false,
            allSelectedDataSets: false,
            perPage: 5,
            projectList: [],
            pageOfItems: [],
            datasetList: [],
            pageOfProjectDataset: [],
            projectIds: [],
            dataSetIds: [],
            reviewItem: [],
            // successToast: false,
            // failureToast:false,
            // successToastValue:'',
            // failureToastValue:'',
            showPage: false,
            thisfilename: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            currentPage: 1,
            projectListFiltered: [],
            currentPageDataset: 1,
            datasetListFiltered: [],
            searchDatasetAliasInput: true,
            searchDatasetAliasModel: '',
            searchProjectNameInput: true,
            searchProjectNameModel: '',
            collapseStatesProjects: [],
            collapseStatesDatasets: [],
            searchCloseProjectls: false,
            showSearchProjects: false,
            searchClosetoolsInner: false,
            showSearchProjectsInner: false,
            searchCloseDatasets: false,
            showSearchDatasets: false,
            searchClosedatasetsInner: false,
            showSearchDatasetsInner: false,
        };
    },
    created() {
        this.getProjects();
        this.getDataset();
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapMutations('common', ['setToastMessage']),
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            data.forEach((element) => {
                if (element.PageName == 'Datasets') {
                    vm.showPage = true;
                }
            });
            this.$emit('clickedDatasets', this.showPage);
        },
        reviewQueue() {
            this.$refs['dataset-modal'].show();
        },
        searchOpenProjects() {
            this.searchCloseProjectls = !this.searchCloseProjectls;
            this.showSearchProjects = true;
            this.$nextTick(() => {
                this.$refs.projects.focus();
            });
        },
        closeBarProjects() {
            this.searchCloseProjectls = !this.searchCloseProjectls;
            this.showSearchProjects = false;
            this.searchProject = null;
        },

        closeBarProjectsInner() {
            this.searchClosetoolsInner = !this.searchClosetoolsInner;
            this.showSearchProjectsInner = false;
            this.searchDatasetAliasModel = null;
        },
        searchOpenProjectsInner(index) {
            this.searchClosetoolsInner = !this.searchClosetoolsInner;
            this.showSearchProjectsInner = true;
            this.$nextTick(() => {
                this.$refs.projectsInner[index].focus();
            });
        },

        searchOpenDatasets() {
            this.searchCloseDatasets = !this.searchCloseDatasets;
            this.showSearchDatasets = true;
            this.$nextTick(() => {
                this.$refs.datasets.focus();
            });
        },
        closeBarDatasets() {
            this.searchCloseDatasets = !this.searchCloseDatasets;
            this.showSearchDatasets = false;
            this.searchProjectDatasets = null;
        },

        closeBarDatasetsInner() {
            this.searchClosedatasetsInner = !this.searchClosedatasetsInner;
            this.showSearchDatasetsInner = false;
            this.searchProjectNameModel = null;
        },
        searchOpenDatasetsInner(index) {
            this.searchClosedatasetsInner = !this.searchClosedatasetsInner;
            this.showSearchDatasetsInner = true;
            this.$nextTick(() => {
                this.$refs.datasetsInner[index].focus();
            });
        },

        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                data.push({
                    ProjectID: item.projectId,
                    DatasetID: item.datasetId,
                    isActive: item.isActive,
                });
            });

            managementService.addOrUpdateProjectDataSet(data).then((res) => {
                if (res.data.StatusCode === 201) {
                    this.setToastMessage({
                        showSuccess: true,
                        showFailure: false,
                        successToastMessage: 'Association done successfully.',
                        failureToastMessage: null,
                        messageTitle: null,
                    });
                    this.reviewItem = [];
                    this.getProjects();
                    this.getDataset();
                    this.projectIds = [];
                    this.dataSetIds = [];
                    this.$refs['dataset-modal'].hide();
                }
            });
        },
        delQueue(index) {
            let removeItem = this.reviewItem[index];
            this.reviewItem.splice(index, 1);
            let projectIdFound = this.reviewItem.findIndex(
                (item) => item.projectId == removeItem.projectId
            );
            if (projectIdFound == -1) {
                let projectId = this.projectIds.findIndex(
                    (item) => item == removeItem.projectId
                );
                this.projectIds.splice(projectId, 1);
            }
            let datasetIdFound = this.reviewItem.findIndex(
                (item) => item.datasetId == removeItem.datasetId
            );
            if (datasetIdFound == -1) {
                let datasetId = this.dataSetIds.findIndex(
                    (item) => item == removeItem.datasetId
                );
                this.dataSetIds.splice(datasetId, 1);
            }

            /* if(!this.reviewItem.length){
                this.$refs["dataset-modal"].hide();
            }*/
        },
        clearQueue() {
            this.projectIds = [];
            this.dataSetIds = [];
            this.reviewItem = [];
            this.$refs['dataset-modal'].hide();
        },

        childCheck(projectId, datasetId, event) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return (
                    item.projectId == projectId && item.datasetId == datasetId
                );
            });
            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    this.reviewItem.splice(unCheckedItem, 1);
                }
            } else {
                let projectName = this.projectList.filter((item) => {
                    return item.ProjectID == projectId;
                });
                //
                let datasetName = this.datasetList.filter((item) => {
                    return item.DatasetId == datasetId;
                });
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return (
                            item.projectId == projectId &&
                            item.datasetId == datasetId
                        );
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            projectId: projectId,
                            datasetId: datasetId,
                            projectName: projectName[0].ProjectName,
                            dataSetName: datasetName[0].DatasetAlias,
                            isActive: false,
                        });
                    }
                }
            }
        },

        queueProjectChanges() {
            if (
                (this.projectIds.length && this.dataSetIds.length) ||
                this.reviewItem.length
            ) {
                if (this.projectIds.length && this.dataSetIds.length) {
                    // this.reviewItem=[]
                    let selectedProject = {};

                    this.projectIds.forEach((projectId) => {
                        let selectedDataset = [];
                        this.dataSetIds.forEach((dataSetItem) => {
                            selectedDataset.push(dataSetItem);
                        });

                        selectedProject[projectId] = selectedDataset;
                    });
                    for (let props in selectedProject) {
                        let projectName = this.projectList.filter((item) => {
                            return item.ProjectID == props;
                        });
                        for (
                            let i = 0;
                            i < selectedProject[props].length;
                            i++
                        ) {
                            let dataSetName = this.datasetList.filter(
                                (subItem) => {
                                    return (
                                        subItem.DatasetId ==
                                        selectedProject[props][i]
                                    );
                                }
                            );
                            let exist = this.reviewItem.findIndex(
                                (item) =>
                                    item.projectId == props &&
                                    item.datasetId == selectedProject[props][i]
                            );
                            if (exist === -1) {
                                this.reviewItem.push({
                                    projectId: props,
                                    datasetId: selectedProject[props][i],
                                    projectName: projectName[0].ProjectName,
                                    dataSetName: dataSetName[0].DatasetAlias,
                                    isActive: true,
                                });
                            }
                        }
                    }
                    /* this.projectIds.forEach((projectItem)=>{
                        let projectName = this.projectList.filter((item)=>{
                            return item.ProjectID == projectItem;
                        })
                        // 
                        this.dataSetIds.forEach((dataSetItem)=>{
                            let dataSetName = this.datasetList.filter((subItem)=>{
                                return subItem.DatasetId == dataSetItem;
                            })
                            this.reviewItem.push({
                                "projectId":projectItem,
                                "datasetId":dataSetItem,
                                "projectName": projectName[0].ProjectName,
                                "dataSetName": dataSetName[0].DatasetName,
                                "isActive": true
                            })
                            this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.projectId)))
                            .map(id=>{
                                return this.reviewItem.find(a=> a.projectId ===id)
                            })
                        })
                    })*/
                }
                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';
                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    successToastMessage: null,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: null,
                });
            }
        },
        // closeSuccessToast(){
        //     this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        hideModal() {
            this.$refs['dataset-modal'].hide();
        },
        getProjects() {
            managementService.getProjects().then((res) => {
                this.projectList = res.data;
                this.projectListFiltered = res.data;
            });
        },
        getDataset() {
            managementService.getDataset().then((res) => {
                this.datasetList = res.data;
                this.datasetListFiltered = res.data;
            });
        },
        selectAllProjectsTab() {
            this.collapseStatesProjects = this.projectList.map(
                (x) => (x.open = false)
            );
        },
        selectAllDatasetsTab() {
            this.collapseStatesDatasets = this.datasetList.map(
                (x) => (x.open = false)
            );
        },
        onChangePage(pageOfItems) {
            this.pageOfItems = pageOfItems;
        },
        addSearchText() {
            if (this.searchProject.length == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextData() {
            if (this.searchProjectDatasets.length == 0) {
                this.searchTextData = true;
                this.showTextData = false;
            }
        },
        removeSearchTextData() {
            this.searchTextData = false;
            this.showTextData = true;
        },
        selectAllProjects(p) {
            this.projectIds = [];
            if (!this.allSelected) {
                for (p in this.projectList) {
                    this.projectIds.push(this.projectList[p].ProjectID);
                }
            }
        },
        selectAllDatasets(d) {
            this.dataSetIds = [];
            if (!this.allSelectedDataSets) {
                for (d in this.datasetList) {
                    this.dataSetIds.push(this.datasetList[d].DatasetId);
                }
            }
        },
        onChangeProjectDatasetPage(pageOfProjectDataset) {
            this.pageOfProjectDataset = pageOfProjectDataset;
        },

        checkedProject(id) {
            if (this.projectIds.indexOf(id) > -1) {
                this.projectIds.splice(this.projectIds.indexOf(id), 1);
                this.allSelected = false;
            } else {
                this.projectIds.push(id);
            }
        },
        checkedDataset(id) {
            if (this.dataSetIds.indexOf(id) > -1) {
                this.dataSetIds.splice(this.dataSetIds.indexOf(id), 1);
                this.allSelectedDataSets = false;
            } else {
                this.dataSetIds.push(id);
            }
        },
    },
    computed: {
        projectFilteredList() {
            let vm = this;
            if (vm.searchProject) {
                vm.pageOfItems = vm.projectListFiltered.filter((post) => {
                    return post.ProjectName.toLowerCase().includes(
                        vm.searchProject.toLowerCase()
                    );
                });
            } else {
                vm.pageOfItems = vm.projectListFiltered;
            }
            return vm.pageOfItems.slice(
                (vm.currentPage - 1) * vm.perPage,
                vm.currentPage * vm.perPage
            );
        },
        datasetsFilteredList() {
            let vm = this;
            if (this.searchProjectDatasets) {
                vm.pageOfProjectDataset = vm.datasetListFiltered.filter(
                    (post) => {
                        return post.DatasetAlias?.toLowerCase().includes(
                            vm.searchProjectDatasets.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfProjectDataset = vm.datasetListFiltered;
            }
            return vm.pageOfProjectDataset.slice(
                (vm.currentPageDataset - 1) * vm.perPage,
                vm.currentPageDataset * vm.perPage
            );
        },
        rows() {
            return this.pageOfItems.length;
        },
        rowsDataset() {
            return this.pageOfProjectDataset.length;
        },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   color: black;
//   text-align: left;
//   span {
//     margin-left: 25px;
//     margin-left: 30px;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/blackminus.svg') no-repeat right
        0.75rem center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   width: 388px;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
// .paginationStyle {
//   border: none;
//   /* margin: 0px auto !important; */
//   width: 200px;
//   margin: 0px;
//   padding: 0px;
//   display: contents !important;
// }
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
}
// input.test {
//   width: 411px;
// }
//  .buttonSelect.queueChange{
//      position: absolute;
//      right: 20px;
//      top: -50px;
//  }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -10px;
    margin-right: -10px;
    width: 405px;
}
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    .row1 {
        border-bottom: 3px solid #eaeaea;
        // padding-top: 10px;
        // h6.blue {
        //   color: #006395 !important;
        // }
    }
    //   .showProjectList {
    //     overflow: auto;
    //     height: 300px;
    //     width: 417px;
    //   }
    .header {
        padding: 10px;
        border-bottom: 3px solid;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
// .reviewQueueButton{
//     position: absolute;
//     top:-35px;
//     right:175px;
//     cursor: pointer;
// }

.model_style {
    width: 900px !important;
}
table.dataSetModal tr:first-child {
    background: white;
}
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
</style>
