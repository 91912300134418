<template>
    <div>
        <div class="docUpload">
            <b-button
                class="buttonSelect nextbutton"
                rounded
                :disabled="validateUpload"
                @click="loadHistory"
            >
                Load
            </b-button>
        </div>
        <div class="cancel-p">
            <p
                class="text-center sm-cancel pr-2 m-0 rounded-circle"
                @click="removeHistory"
            >
                Remove
            </p>
        </div>
        <b-row>
            <b-col>
                <portal-bar :hideSearch="hideSearch"></portal-bar>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <ejs-grid
                    :dataSource="dataSource"
                    :rowSelected="dataSetRowSelected"
                >
                    <e-columns>
                        <e-column
                            field="DateCreated"
                            headerText="Date Created"
                            :format="dateFormat"
                        ></e-column>
                        <e-column field="Name" headerText="Name"></e-column>
                        <e-column
                            field="Description"
                            headerText="Description"
                        ></e-column>
                        <!-- <e-column field='Source' headerText='Source' ></e-column> -->
                        <!-- <e-column field='Tool' headerText='Tool' ></e-column>
                        <e-column field='Category' headerText='Category' ></e-column> -->
                        <e-column
                            field="DateUsed"
                            headerText="Date Used"
                            :format="dateFormat"
                        ></e-column>
                    </e-columns>
                </ejs-grid>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PortalBar from '../PortalBar';
import Vue from 'vue';
import moment from 'moment';
import {
    GridPlugin,
    Sort,
    Page,
    Filter,
    Freeze,
    CommandColumn,
    Search,
    Resize,
    Toolbar,
    Grid,
    Reorder,
    ExcelExport,
} from '@syncfusion/ej2-vue-grids';
Vue.use(GridPlugin);
import router from '../../router';
export default {
    name: 'LoadHistory',
    data() {
        return {
            dataSource: [],
            dateFormat: 'MM/dd/yyyy hh:mm:ss',
            selectedRow: null,
            updatedHistroty: [],
            selectedRowIndex: null,
        };
    },
    components: {
        PortalBar,
    },
    provide: {
        grid: [
            Sort,
            Page,
            Filter,
            Freeze,
            CommandColumn,
            Search,
            Toolbar,
            Grid,
            Resize,
            ExcelExport,
        ],
    },
    created() {},
    mounted() {
        this.loadTable();
    },
    methods: {
        getUTCDate(dateParm) {
            var stillUtc = moment.utc(dateParm).toDate();

            return (
                moment(stillUtc)
                    .local()
                    .format('MM/DD/yyyy') +
                ' ' +
                moment(stillUtc)
                    .local()
                    .format('LT')
            );
        },
        dataSetRowSelected(args) {
            let tempFilterData = JSON.parse(
                localStorage.getItem('filterHistory')
            );
            this.selectedRow = tempFilterData.find((item, index) => {
                this.selectedRowIndex = index;
                return index === args.rowIndex;
            });
            tempFilterData.forEach((item, index) => {
                if (index === args.rowIndex) {
                    item.dateUsed = new Date();
                }
            });
            this.updatedHistroty = [...tempFilterData];
        },
        loadHistory() {
            localStorage.setItem(
                'filterHistory',
                JSON.stringify(this.updatedHistroty)
            );

            router.push({
                name: `tableselectiontoolLoadHistory`,
                params: {
                    args: JSON.stringify(this.selectedRow),
                    isSavedWork: false,
                },
            });
        },
        removeHistory() {
            let tempFilterData = JSON.parse(
                localStorage.getItem('filterHistory')
            );
            tempFilterData.splice(this.selectedRowIndex, 1);
            localStorage.setItem(
                'filterHistory',
                JSON.stringify(tempFilterData)
            );
            this.loadTable();
        },
        loadTable() {
            let tempTableData = [];
            let tempFilterData = [];
            tempFilterData = JSON.parse(localStorage.getItem('filterHistory'));
            tempFilterData.forEach((element, inx) => {
                if (
                    element.availableFilterArr.length > 0 ||
                    element.gridFilters.length > 0
                ) {
                    if (element.projectId !== localStorage.getItem('projectId'))
                        return;
                    let decs = Object.keys(element.status).join(', ');
                    let tempRow = {
                        Name:
                            element.tableRowSelectArgs.data.DisplayName +
                            ' - ' +
                            decs,
                        Description: decs,
                        Source: element.tableRowSelectArgs.data.DatasetName,
                        Tools: '',
                        Category: '',
                        DateCreated: element.dateCreated
                            ? this.getUTCDate(element.dateCreated)
                            : element.dateCreated,
                        DateUsed: element.dateUsed
                            ? this.getUTCDate(element.dateUsed)
                            : element.dateUsed,
                    };
                    tempTableData.push(tempRow);
                } else {
                    let tempArr = [...tempFilterData];
                    tempArr.splice(inx, 1);
                    localStorage.setItem(
                        'filterHistory',
                        JSON.stringify(tempArr.length > 0 ? tempArr : [])
                    );
                }
            });
            this.dataSource = [...tempTableData];
        },
    },
};
</script>

<style>
.docUpload {
    text-align: right;
    position: absolute;
    top: -47px;
    height: 0 !important;
    float: right;
    right: 0;
}

.cancel-p {
    text-align: right;
    position: absolute;
    top: -33px;
    height: 0 !important;
    float: right;
    right: 223px;
}
</style>
