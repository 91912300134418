var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.componentName === '' ? 'hide-pane' : 'show-pane',attrs:{"id":"mobile-bottom-pane"}},[_c('div',{attrs:{"id":"pane-header"}},[_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("../../../assets/arrow_left.svg")}}),_c('span',{attrs:{"id":"back-label"}},[_vm._v("Back")])])]),_c(_vm.componentName,_vm._b({tag:"component",attrs:{"id":"pane-content"},on:{"close":function($event){return _vm.$emit('close')},"clearResults":function () {
                _vm.$emit('clearResults');
            },"clearHighlight":function () {
                _vm.$emit('clearHighlight');
            },"zoomTo":function (feature) {
                _vm.$emit('zoomTo', feature);
            },"highlight":function (feature) {
                _vm.$emit('highlight', feature);
            },"reverseLookup":function (layerID, featureID) {
                _vm.$emit('reverseLookup', layerID, featureID);
            },"updateClickFunction":_vm.updateClickFunction}},'component',_vm.dynamicProperties,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }