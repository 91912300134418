export const spatialFunctions = {
    lat2meters,
    lng2meters,
    meters2lat,
    meters2lng,
    correctLatitude,
    correctLongitude,
    validateCoordinates,
    calculateBounds,
    reverseCoordinateOrder,
    dd2WebMerc,
    webMerc2DD,
    convertToWKT,
    convertWktToObject,
    extractCoords,
    convertToPgGeo,
};

function lat2meters(lat) {
    lat = parseFloat(lat);
    var y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180);
    y = (y * 20037508.34) / 180.0;
    return y;
}

function lng2meters(lng) {
    lng = parseFloat(lng);
    var x = (lng * 20037508.34) / 180;
    return x;
}

function meters2lng(x) {
    x = parseFloat(x);
    //convert web mercator (meters x) to longitude (x)
    return parseFloat(
        (
            (x / 6378137) * 57.29577951308232 -
            Math.floor(((x / 6378137) * 57.29577951308232 + 180.0) / 360.0) *
                360.0
        ).toFixed(6)
    );
}

function meters2lat(y) {
    y = parseFloat(y);
    //convert web mercator (meters y) to latitude (y)
    return parseFloat(
        (
            (1.5707963267948966 -
                2.0 * Math.atan(Math.exp((-1.0 * y) / 6378137))) *
            (180 / Math.PI)
        ).toFixed(6)
    );
}

function correctLatitude(latitude, decimals = 6) {
    while (latitude > 90) {
        latitude -= 180;
    }
    while (latitude < -90) {
        latitude += 180;
    }
    return parseFloat(latitude.toFixed(decimals));
}

function correctLongitude(longitude, decimals = 6) {
    while (longitude > 180) {
        longitude -= 360;
    }
    while (longitude < -180) {
        longitude += 360;
    }
    return parseFloat(longitude.toFixed(decimals));
}

function validateCoordinates(clickEvent) {
    return [
        correctLatitude(clickEvent.latlng.lat),
        correctLongitude(clickEvent.latlng.lng),
    ];
}

function calculateBounds(feature) {
    let minX = 180;
    let minY = 90;
    let maxX = -180;
    let maxY = -90;
    if (feature.geometry.coordSys == 'WebMerc') {
        feature = webMerc2DD(feature, true);
    }
    // if (!('geometry' in feature)){
    //   feature.geometry = feature
    // }
    //
    if (feature.geometry.type == 'MultiPolygon') {
        feature.geometry.coordinates[0].forEach((polygon) => {
            polygon.forEach((point) => {
                let yCoord = point[0];
                let xCoord = point[1];
                if (!isNaN(xCoord) && !isNaN(yCoord)) {
                    minX = Math.min(minX, parseFloat(xCoord));
                    maxX = Math.max(maxX, parseFloat(xCoord));
                    minY = Math.min(minY, parseFloat(yCoord));
                    maxY = Math.max(maxY, parseFloat(yCoord));
                }
            });
        });
    } else if (feature.geometry.type == 'Polygon') {
        feature.geometry.coordinates[0].forEach((point) => {
            let yCoord = point[0];
            let xCoord = point[1];
            if (!isNaN(xCoord) && !isNaN(yCoord)) {
                minX = Math.min(minX, parseFloat(xCoord));
                maxX = Math.max(maxX, parseFloat(xCoord));
                minY = Math.min(minY, parseFloat(yCoord));
                maxY = Math.max(maxY, parseFloat(yCoord));
            }
        });
    } else if (feature.geometry.type == 'Point') {
        let yCoord = feature.geometry.coordinates[0];
        let xCoord = feature.geometry.coordinates[1];
        if (!isNaN(xCoord) && !isNaN(yCoord)) {
            minX = Math.min(minX, parseFloat(xCoord));
            maxX = Math.max(maxX, parseFloat(xCoord));
            minY = Math.min(minY, parseFloat(yCoord));
            maxY = Math.max(maxY, parseFloat(yCoord));
        }
    } else if (feature.geometry.type == 'LineString') {
        feature.geometry.coordinates.forEach((point) => {
            let yCoord = point[0];
            let xCoord = point[1];
            if (!isNaN(xCoord) && !isNaN(yCoord)) {
                minX = Math.min(minX, parseFloat(xCoord));
                maxX = Math.max(maxX, parseFloat(xCoord));
                minY = Math.min(minY, parseFloat(yCoord));
                maxY = Math.max(maxY, parseFloat(yCoord));
            }
        });
    }
    let xRange = maxX - minX;
    let yRange = maxY - minY;
    let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
    return [
        [minY - yRange * zoomCushion, minX - xRange * zoomCushion],
        [maxY + yRange * zoomCushion, maxX + xRange * zoomCushion],
    ];
}

// SQL Server stores geometry in Lng Lat, leaflet uses Lat Lng, this converts between the two
function reverseCoordinateOrder(feature) {
    if (feature.geometry.type == 'MultiPolygon') {
        feature.geomtry.coordinates.forEach((polygon) => {
            polygon.forEach((point) => {
                let xCoord = point[0];
                let yCoord = point[1];
                point[0] = yCoord;
                point[1] = xCoord;
            });
        });
    } else if (feature.geometry.type == 'Polygon') {
        feature.geometry.coordinates.forEach((point) => {
            // if variable is an array
            if (point[0] instanceof Array) {
                point.forEach((subPoint) => {
                    let xCoord = subPoint[0];
                    let yCoord = subPoint[1];
                    subPoint[0] = yCoord;
                    subPoint[1] = xCoord;
                });
            } else {
                let xCoord = point[0];
                let yCoord = point[1];
                point[0] = yCoord;
                point[1] = xCoord;
            }
        });
    } else if (feature.geometry.type == 'LineString') {
        feature.geometry.coordinates.forEach((point) => {
            let xCoord = point[0];
            let yCoord = point[1];
            point[0] = yCoord;
            point[1] = xCoord;
        });
    } else if (feature.geometry.type == 'Point') {
        let xCoord = feature.geometry.coordinates[0];
        let yCoord = feature.geometry.coordinates[1];
        feature.geometry.coordinates[0] = yCoord;
        feature.geometry.coordinates[1] = xCoord;
    }
    return feature;
}

function dd2WebMerc(feature, reverse = false) {
    if (feature.geometry.coordSys == 'WebMerc') {
        return feature;
    }
    if (reverse) {
        feature = reverseCoordinateOrder(feature);
    }
    if (feature.geometry.type == 'MultiPolygon') {
        feature.geometry.coordinates[0].forEach((polygon) => {
            if (polygon instanceof Array) {
                polygon.forEach((point) => {
                    point[0] = lng2meters(point[0]);
                    point[1] = lat2meters(point[1]);
                });
            }
        });
    } else if (feature.geometry.type == 'Polygon') {
        feature.geometry.coordinates[0].forEach((point) => {
            point[0] = lng2meters(point[0]);
            point[1] = lat2meters(point[1]);
        });
    } else if (feature.geometry.type == 'LineString') {
        feature.geometry.coordinates.forEach((point) => {
            point[0] = lng2meters(point[0]);
            point[1] = lat2meters(point[1]);
        });
    } else if (feature.geometry.type == 'Point') {
        feature.geometry.coordinates[0] = lng2meters(
            feature.geometry.coordinates[0]
        );
        feature.geometry.coordinates[1] = lat2meters(
            feature.geometry.coordinates[1]
        );
    }
    feature.geometry.coordSys = 'WebMerc';
    return feature;
}

function webMerc2DD(feature, reverse = false) {
    if (feature.geometry.coordSys == 'LatLong') {
        return feature;
    }
    if (feature.geometry.type == 'MultiPolygon') {
        feature.geometry.coordinates.forEach((polygon) => {
            if (polygon instanceof Array) {
                polygon.forEach((point) => {
                    if (point instanceof Array) {
                        point[0] = meters2lng(point[0]);
                        point[1] = meters2lat(point[1]);
                    }
                });
            }
        });
    } else if (feature.geometry.type == 'Polygon') {
        feature.geometry.coordinates[0].forEach((point) => {
            point[0] = meters2lng(point[0]);
            point[1] = meters2lat(point[1]);
        });
    } else if (feature.geometry.type == 'LineString') {
        feature.geometry.coordinates.forEach((point) => {
            point[0] = meters2lng(point[0]);
            point[1] = meters2lat(point[1]);
        });
    } else if (feature.geometry.type == 'Point') {
        feature.geometry.coordinates[0] = meters2lng(
            feature.geometry.coordinates[0]
        );
        feature.geometry.coordinates[1] = meters2lat(
            feature.geometry.coordinates[1]
        );
    }
    feature.geometry.coordSys = 'LatLong';
    if (reverse) {
        feature = reverseCoordinateOrder(feature);
    }
    return feature;
}

function convertToWKT(feature) {
    // error handling if the feature is already a wktString
    if (
        typeof feature.geometry === 'string' ||
        feature.geometry instanceof String
    ) {
        return feature;
    }
    var wktGeom = '';
    if (feature.geometry.coordSys == 'LatLong') {
        feature = dd2WebMerc(feature, true);
    }
    switch (feature.geometry.type.toLowerCase()) {
        case 'point':
            wktGeom +=
                'POINT (' +
                feature.geometry.coordinates.at(0) +
                ' ' +
                feature.geometry.coordinates.at(1) +
                ')';
            break;

        case 'linestring':
            wktGeom += 'LINESTRING (';
            feature.geometry.coordinates.forEach((coordinate) => {
                if (coordinate.length == 2) {
                    wktGeom += coordinate.at(0) + ' ' + coordinate.at(1) + ', ';
                }
            });
            wktGeom = wktGeom.substring(0, wktGeom.lastIndexOf(','));
            wktGeom += ')';
            break;

        case 'polygon':
            wktGeom += 'POLYGON ((';
            feature.geometry.coordinates[0].forEach((coordinate) => {
                wktGeom += coordinate.at(0) + ' ' + coordinate.at(1) + ', ';
            });
            // add the first point again to close the polygon
            {
                wktGeom +=
                    feature.geometry.coordinates[0].at(0).at(0) +
                    ' ' +
                    feature.geometry.coordinates[0].at(0).at(1);
            }

            wktGeom += '))';
            break;
    }
    return wktGeom;
}

function convertWktToObject(wktText, lngThenLat = false) {
    var feature = {};
    if (wktText.startsWith('POINT')) {
        feature.geometry = {
            type: 'Point',
            coordinates: extractCoords(wktText, true, lngThenLat)[0],
            coordSys: 'LatLong',
        };
    } else if (wktText.startsWith('LINESTRING')) {
        feature['geometry'] = {
            type: 'LineString',
            coordinates: extractCoords(wktText, true, lngThenLat),
            coordSys: 'LatLong',
        };
    } else if (wktText.startsWith('POLYGON')) {
        feature['geometry'] = {
            type: 'Polygon',
            coordinates: [extractCoords(wktText, true, lngThenLat)],
            coordSys: 'LatLong',
        };
    }
    return feature;
}

function extractCoords(wktString, convertToDD, reverseOrder) {
    // this regex matches coordinate pairs separated by a space
    // will match:
    // -84.387 -12.4576
    // 42.125 12.45
    var coords = Array.from(
        wktString.matchAll(/-?[0-9]+.?[0-9]* -?[0-9]+.?[0-9]+/g),
        (c) => c[0].split(' ')
    );
    for (var i = 0; i < coords.length; i++) {
        var coord = coords[i];
        if (reverseOrder) {
            if (convertToDD) {
                coords[i] = [
                    meters2lng(parseFloat(coord[0])),
                    meters2lat(parseFloat(coord[1])),
                ];
            } else [(coords[i] = [parseFloat(coord[1]), parseFloat(coord[0])])];
        } else {
            if (convertToDD) {
                coords[i] = [
                    meters2lat(parseFloat(coord[1])),
                    meters2lng(parseFloat(coord[0])),
                ];
            } else [(coords[i] = [parseFloat(coord[0]), parseFloat(coord[1])])];
        }
    }
    return coords;
}

function convertToPgGeo(feature) {
    // error handling if the feature is already a wktString
    if (
        typeof feature.geometry === 'string' ||
        feature.geometry instanceof String
    ) {
        return feature;
    }
    var wktGeom = '';
    switch (feature.geometry.type.toLowerCase()) {
        case 'point':
            wktGeom +=
                'POINT (' +
                feature.geometry.coordinates.at(0) +
                ' ' +
                feature.geometry.coordinates.at(1) +
                ')';
            break;

        case 'linestring':
            wktGeom += 'LINESTRING (';
            feature.geometry.coordinates.forEach((coordinate) => {
                if (coordinate.length == 2) {
                    wktGeom += coordinate.at(0) + ' ' + coordinate.at(1) + ', ';
                }
            });
            wktGeom = wktGeom.substring(0, wktGeom.lastIndexOf(','));
            wktGeom += ')';
            break;

        case 'polygon':
            wktGeom += 'POLYGON ((';
            //LongLat to LatLong for PostgreSQL geometry
            feature.geometry.coordinates[0].forEach((coordinate) => {
                wktGeom += coordinate.at(1) + ' ' + coordinate.at(0) + ', ';
            });
            // add the first point again to close the polygon
            {
                wktGeom +=
                    feature.geometry.coordinates[0].at(0).at(1) +
                    ' ' +
                    feature.geometry.coordinates[0].at(0).at(0);
            }

            wktGeom += '))';
            break;
    }
    return wktGeom;
}
