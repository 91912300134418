<template>
    <div class="portal-settings">
        <Header />
        <b-container fluid class="sub_header_bg p-0">
            <b-container class="p-0">
                <b-row class="mb-3">
                    <b-col cols="12">
                        <a href="javascript:void(0)" @click="navigateUsers">
                            <img src="../../assets/arrow_left.svg" class="" />
                            <a href="#" class="floar-left ml-1 back"
                                >Back to dashboard</a
                            >
                        </a>
                    </b-col>
                </b-row>
                <b-row class="vh-10 text-left mr-0" align-v="center">
                    <b-col cols="7" lg="7" md="7" class="pt-2">
                        <h4>Portal settings</h4>
                    </b-col>
                    <b-col cols="2" lg="3" md="3" class="m-view-b mrg-y top-z">
                        <p
                            class="float-right pl-3 m-0 sm-cancel cancel-link-portal"
                            @click="canceltoDashboard"
                        >
                            Cancel
                        </p>
                    </b-col>
                    <b-col
                        cols="3"
                        lg="2"
                        md="2"
                        class="p-0 text-right m-bottom-btn mrg-y"
                    >
                        <b-button
                            rounded
                            class="buttonSelect"
                            @click="saveSettings"
                            :disabled="!isValid || !isValidOption"
                        >
                            Save changes
                        </b-button>
                    </b-col>
                </b-row>

                <b-tabs
                    content-class="mt-3"
                    class="pt-4 portal-content"
                    active-nav-item-class="pl-0 borderBlue"
                >
                    <b-tab title="General" active class="mt-4">
                        <!-- <div class="successToastMsg" v-if="successToast">
                        <img src="../../assets/success_tick.svg" class="mr-3"/>
                       <p style="display:inline"><b> Success </b></p> <br/> <p style="width:80%;diplay:inline;">Portal settings added successfully</p>
                        <img src="../../assets/close_big.svg" class="ml-3 toastLogo" @click="closeSuccessToast"/>
                    </div>
                    <div class="failureToastMsg" v-if="failureToast">
                        <img src="../../assets/failure_cross.svg" class="mr-3"/>
                        <b>Failure</b>  <br/> Something went wrong.Please try again later.
                        <img src="../../assets/close_big.svg" class="ml-3" @click="closeFailureToast"/>
                    </div> -->
                        <h4 class="portal-heading">Pagination</h4>
                        <p class="py-2">
                            Modify the number of records to display per page.
                        </p>
                        <div>
                            <div>
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Records per page',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <input
                                        type="text"
                                        @keyup="change($event)"
                                        v-model="edit.Pagination"
                                        autocomplete="off"
                                        class="normal_input"
                                    />
                                    <!-- <input type="text"  v-model="edit.Pagination" autocomplete="off" class="normal_input"> -->
                                </CustomFloatingLabel>
                                <small
                                    class="error"
                                    v-if="!isValid && edit.Pagination"
                                    >Please enter valid number</small
                                >
                                <h4 class="portal-heading">
                                    Additional options
                                </h4>
                                <p class="py-2">
                                    Modify the number of seconds before a
                                    warning occurs.
                                </p>
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Seconds',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <input
                                        type="text"
                                        @keyup="changeOption($event)"
                                        v-model="edit.AdditionalOptions"
                                        autocomplete="off"
                                        class="normal_input"
                                    />
                                </CustomFloatingLabel>
                                <small
                                    class="error"
                                    v-if="
                                        !isValidOption && edit.AdditionalOptions
                                    "
                                    >Please enter valid number</small
                                >
                            </div>
                        </div>
                        <!-- <div v-else>
            <CustomFloatingLabel  :config="{label: 'Records per page', name: 'wrapper', line: false, scale: false}" >
            <input type="text"  v-model="edit.Pagination" autocomplete="off" class="normal_input">
        </CustomFloatingLabel>
        <h4>Additional options</h4>
        <p>Modify the number of records before a warning occurs.</p>
         <CustomFloatingLabel :config="{label: 'Seconds', name: 'wrapper', line: false, scale: false}" >
            <input type="text"  v-model="edit.AdditionalOptions" autocomplete="off" class="normal_input">
        </CustomFloatingLabel>
        </div>-->
                    </b-tab>
                    <b-tab title="Tool settings">
                        <div class="col-md-6 mt-4 p-0 FloatingTool">
                            <h4>Posting tool</h4>
                            <p class="py-2">Default settings for uniqueness</p>
                            <div>
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Number',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <b-form-select
                                        v-model="edit.Number"
                                        :key="edit.ProfileId"
                                    >
                                        <!-- <option value="null">Number</option> -->
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Number</b-form-select-option
                                        >
                                        <option
                                            v-for="op in numberArray"
                                            :value="op.id"
                                            :key="op.id"
                                        >
                                            {{ op.name }}
                                        </option>
                                    </b-form-select>
                                </CustomFloatingLabel>
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Date',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <b-form-select
                                        v-model="edit.Date"
                                        :key="edit.ProfileId"
                                    >
                                        <!-- <option value="null">Date</option> -->
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Date</b-form-select-option
                                        >
                                        <option
                                            v-for="op in dateArray"
                                            :value="op.id"
                                            :key="op.id"
                                        >
                                            {{ op.name }}
                                        </option>
                                    </b-form-select>
                                </CustomFloatingLabel>
                                <CustomFloatingLabel
                                    :config="{
                                        label: 'Text',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <b-form-select
                                        v-model="edit.Text"
                                        :key="edit.ProfileId"
                                    >
                                        <!-- <option value="null">Text</option> -->
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Text</b-form-select-option
                                        >
                                        <option
                                            v-for="op in textArray"
                                            :value="op.id"
                                            :key="op.id"
                                        >
                                            {{ op.name }}
                                        </option>
                                    </b-form-select>
                                </CustomFloatingLabel>
                            </div>
                            <!--<div v-else>
          <CustomFloatingLabel  :config="{label: 'Number', name: 'wrapper', line: false, scale: false}" >
          <b-form-select v-model="edit.Number" >
            
              <option  v-for="op in numberArray" :value="op.id"  :key="op.id">
                {{op.name}}
              </option>
          </b-form-select>
        </CustomFloatingLabel>
        <CustomFloatingLabel  :config="{label: 'Date', name: 'wrapper', line: false, scale: false}" >
          <b-form-select  v-model="edit.Date" >
           
                <option v-for="op in dateArray" :value="op.id"  :key="op.id">
                {{op.name}}
              </option>
          </b-form-select>
        </CustomFloatingLabel>
        <CustomFloatingLabel  :config="{label: 'Text', name: 'wrapper', line: false, scale: false}" >
          <b-form-select  v-model="edit.Text" >
           
                <option v-for="op in textArray" :value="op.id" :key="op.id">
                {{op.name}}
              </option>
          </b-form-select>
        </CustomFloatingLabel>
        </div>-->
                            <h4 class="mt-2 portal-heading">Graphing tool</h4>
                            <p class="py-1">
                                Select the default graph template orientation.
                            </p>
                            <div
                                class="input-group-prepend normal_input mt-3 mb-5"
                            >
                                <input
                                    class="graphic"
                                    placeholder="Portrait"
                                    @click="graphing"
                                    :class="
                                        !isLandscape ||
                                        (!showhoverL && showhoverP)
                                            ? 'selectedGraphictool'
                                            : ''
                                    "
                                    value="Portrait"
                                    type="text"
                                    readonly
                                />
                                <input
                                    class="graphic"
                                    placeholder="Landscape"
                                    @click="graphing"
                                    :class="
                                        isLandscape ||
                                        (!showhoverP && showhoverL)
                                            ? 'selectedGraphictool'
                                            : ''
                                    "
                                    value="Landscape"
                                    type="text"
                                    readonly
                                />
                            </div>
                        </div>
                    </b-tab>
                    <ToastMessages
                        :showSuccess="showSuccess"
                        :showFailure="showFailure"
                        :failureToastMessage="failureToastMessage"
                        :successToastMessage="successToastMessage"
                    />
                </b-tabs>
            </b-container>
        </b-container>
        <Footer />
    </div>
</template>
<script>
import Header from '../Header';
import router from '../../router';
import Footer from '../../components/Footer';
import CustomFloatingLabel from '../CustomFloatingLabel';
import { userService } from '../../services/users.service';
import ToastMessages from '../ToastMessages.vue';

export default {
    name: 'portalsetting',
    components: {
        Header,
        Footer,
        CustomFloatingLabel,
        ToastMessages,
    },

    created() {
        this.allAggregateOption();
        this.getSavedaggregates();
    },

    data() {
        return {
            selected: null,
            options: [],
            successToast: false,
            failureToast: false,
            savedData: [],
            numberArray: [],
            dateArray: [],
            textArray: [],
            showhoverP: true,
            showhoverL: true,
            settings: {
                UserId: '',
                Pagination: '',
                AdditionalOptions: '',
                Number: null,
                Date: null,
                Text: null,

                isLandScape: false,
            },
            edit: {
                Pagination: '',
                AdditionalOptions: '',
                Number: null,
                Date: null,
                Text: null,
                isLandScape: true,
            },
            profileId: '',
            validateInput: [],
            isValid: true,
            isValidOption: true,
            regex: /[0-9]/,
            isLandscape: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
        };
    },
    methods: {
        navigateDashboard: function() {
            router.push(`/dashboard`);
        },
        allAggregateOption() {
            userService.getAggregateOptionPortal().then((res) => {
                this.options = res.data;
                this.options.forEach((element) => {
                    if (element.DatatypeSetID === 1) {
                        this.numberArray.push({
                            name: element.AggregateOptions,
                            id: element.AggregateOptionID,
                        });
                    } else if (element.DatatypeSetID === 3) {
                        this.dateArray.push({
                            name: element.AggregateOptions,
                            id: element.AggregateOptionID,
                        });
                    } else if (element.DatatypeSetID === 2) {
                        this.textArray.push({
                            name: element.AggregateOptions,
                            id: element.AggregateOptionID,
                        });
                    }
                });
            });
        },
        getSavedaggregates() {
            let vm = this;
            userService.getSavedaggregate().then((res) => {
                vm.dataOfaggregate = res.data;
                vm.savedData = res.data;
                this.profileId = vm.savedData[0].ProfileId;
                this.edit = {
                    Pagination: vm.savedData[0].Pagination,
                    AdditionalOptions: vm.savedData[0].AdditionalOptions,
                    Number: vm.savedData[0].Number,
                    Date: vm.savedData[0].Date,
                    Text: vm.savedData[0].Text,
                    isLandScape: vm.savedData[0].IsLandScape,
                };

                if (this.edit.isLandScape) {
                    localStorage.setItem('graphingtool', 'Landscape');
                    this.edit.isLandScape = true;
                    this.showhoverL = true;
                    this.showhoverP = false;
                    this.isLandscape = true;
                } else {
                    localStorage.setItem('graphingtool', 'Portrait');
                    this.edit.isLandScape = false;
                    this.showhoverP = true;
                    this.showhoverL = false;
                    this.isLandscape = false;
                }
                /* if( Object.keys(this.dataOfaggregate).length == 1)
            {
                 vm.savedData = res.data;

                 vm.isLandscape = vm.savedData[0].IsLandScape;
            }
            else
            {
               vm.savedData = vm.settings
            }*/
                // this.profileId=vm.savedData[0].ProfileId;
            });
        },
        canceltoDashboard() {
            router.push(`/dashboard`);
        },
        navigateUsers() {
            router.push(`/dashboard`);
        },
        saveSettings() {
            /* if(Object.keys(this.savedData).length == 1){
            this.savingData = {

	            Pagination : this.savedData[0].Pagination,
              AdditionalOptions: this.savedData[0].AdditionalOptions,
              Number: this.savedData[0].Number,
              Date: this.savedData[0].Date,
              Text: this.savedData[0].Text,
              isLandScape: this.edit.isLandScape,
          }
          }
          else{
            this.savingData = this.settings;
          }*/
            if (this.profileId) {
                this.savingData = {
                    Pagination: this.edit.Pagination,
                    AdditionalOptions: this.edit.AdditionalOptions,
                    Number: this.edit.Number,
                    Date: this.edit.Date,
                    Text: this.edit.Text,
                    isLandScape: this.edit.isLandScape,
                    ProfileId: this.profileId,
                };
            } else {
                this.savingData = {
                    Pagination: this.edit.Pagination,
                    AdditionalOptions: this.edit.AdditionalOptions,
                    Number: this.edit.Number,
                    Date: this.edit.Date,
                    Text: this.edit.Text,
                    isLandScape: this.edit.isLandScape,
                };
            }
            if (this.profileId) {
                userService
                    .updatePortalsettings(this.savingData, this.profileId)
                    .then((res) => {
                        if (res.data.StatusCode === 202) {
                            this.showSuccess = true;
                            this.showFailure = false;
                            this.successToastMessage =
                                'Portal settings updated successfully';
                            //this.allAggregateOption();
                            this.getSavedaggregates();
                            setTimeout(() => {
                                this.showSuccess = false;
                            }, 5000);
                        } else if (res.data.StatusCode === 500) {
                            this.showSuccess = false;
                            this.showFailure = true;
                            this.failureToastMessage =
                                'Something went wrong.Please try again later.';
                            setTimeout(() => {
                                this.showFailure = false;
                            }, 5000);
                        }
                    });
            } else {
                userService.savePortalsettings(this.savingData).then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage =
                            'Portal settings added successfully';
                        // this.allAggregateOption();
                        this.getSavedaggregates();
                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 5000);
                    } else if (res.data.StatusCode === 500) {
                        this.showSuccess = false;
                        this.showFailure = true;
                        this.failureToastMessage =
                            'Something went wrong.Please try again later.';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
            }
        },
        //      closeSuccessToast(){
        //     this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        graphing(evt) {
            // this.isLandscape = '';
            if (evt.target.value == 'Portrait') {
                localStorage.setItem('graphingtool', 'Portrait');
                this.edit.isLandScape = false;
                this.showhoverP = true;
                this.showhoverL = false;
                this.isLandscape = false;
            } else {
                localStorage.setItem('graphingtool', 'Landscape');
                this.edit.isLandScape = true;
                this.showhoverL = true;
                this.showhoverP = false;
                this.isLandscape = true;
            }
        },
        handleBlur(item, event) {
            const value = event.target.value;
            if (item && !value) {
                this.validateInput.push(item);
            } else {
                this.validateInput.splice(this.validateInput.indexOf(item), 1);
            }
            /*  if(this.validateInput.length<3){
                this.buttonStatus=false
            }
            if(item==="email"){
                var pattern = new RegExp(
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );
                if (!pattern.test(value)) {
                    this.validateInput.push("emailError")
                } else {
                    this.validateInput.splice(this.validateInput.indexOf("emailError"),1)
                }
            } */
            /* this.cancelShow = true; */
        },
        change(e) {
            const number = e.target.value;
            this.isNumberValid(number);
        },
        changeOption(e) {
            const number = e.target.value;
            this.isNumberValidOption(number);
        },
        isNumberValid(inputNumber) {
            this.isValid = this.regex.test(inputNumber);
        },
        isNumberValidOption(inputNumber) {
            this.isValidOption = this.regex.test(inputNumber);
        },
    },
};
</script>
<style scoped>
.my-custom-floating-label {
    margin-bottom: 20px;
}

.normal_input {
    height: 48px !important;
    width: 499px;
}

.custom-select {
    background: #fff url('../../assets/arrow_down.svg') no-repeat right 0.75rem
        center/8px 10px !important;
    width: 499px;
    height: 50px;
    padding-top: 1.2rem;
    padding-left: 0.5rem;
    font-size: 16px !important;
    margin-bottom: 0 !important;
}
.graphic {
    text-align: center;
    width: 249px;
    border: 1px solid #c6c6c6;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.graphic:hover {
    color: #fff !important;
    border-color: #c6c6c6;
    background-color: #000;
}
.selectedGraphictool {
    background-color: #000 !important;
    color: #fff;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: block;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.successToastMsg img.toastLogo {
    float: right;
    top: 0;
    position: relative;
    margin-top: -42px;
    margin-right: 10px;
}

.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: block;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.my-custom-floating-label {
    margin-bottom: 20px !important;
}
.form-floating-label label {
    color: #626262;
    font-size: 16px;
    position: absolute;
    left: 17px;
    transition: all 0.2s ease-in;
}

.form-floating-label textarea ~ label {
    margin-top: -132px;
    font-size: 16px;
    font-weight: 400;
}
.form-floating-label textarea:focus,
.form-floating-label.has-value textarea {
    border-radius: 0px;
    border: 0px solid #c6c6c6 !important ;
    box-shadow: 0 0 0 1px #c6c6c6 !important;
}
.form-floating-label textarea:focus ~ label,
.form-floating-label.has-value textarea ~ label {
    font-size: 12px;
    color: rgb(98, 98, 98);
    font-weight: 400;
}
.error {
    color: #f00;
    display: block;
    top: -15px;
    position: relative;
}
.cancel-link-portal {
    color: #006395;
    cursor: pointer;
    margin-right: -50px !important;
}
.top-z {
    z-index: 999;
}
</style>
