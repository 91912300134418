<template>
    <div>
        <div v-if="resultsDataJson.length == 0">
            No identified features. Please click the "i" button below the
            magnifying glass button, then click on the map to identify
            geometries available in that area.
        </div>
        <div v-else>
            <div id="btn-row">
                <button class="btn btn-gray" @click="clearIDResults()">
                    Clear Results
                </button>
                <button
                    class="btn btn-gray"
                    v-hide="!hasHighlight"
                    @click="clearHighlight()"
                >
                    Clear Highlight
                </button>
            </div>
            <div id="cards-wrapper">
                <div v-for="(item, index) in resultsDataJson" :key="index">
                    <span
                        id="buttonRow"
                        class="d-flex justify-content-between rowBtns"
                    >
                    </span>
                    <div
                        style="margin: 10px; border-radius: 5px;border: 1px solid black; margin-top: 5PX; "
                        class="p-2"
                        v-for="(geom, idx) in item.Geometry"
                        :key="idx"
                    >
                        <div class="identifyTitle">{{ item.geomType }}</div>
                        <div>
                            <b-row
                                v-for="(subItem, subIndex) in geom.properties"
                                :key="subIndex"
                                class="property-row"
                                no-gutters
                            >
                                <b-col cols="6" class="identifyLabel">{{
                                    subItem.label
                                }}</b-col>
                                <b-col cols="6" class="property-value">
                                    <a
                                        v-if="
                                            subItem.label.toLowerCase() ===
                                                'file_link'
                                        "
                                        target="_blank"
                                        :href="subItem.value"
                                    >
                                        {{
                                            subItem.value
                                                .split('/')
                                                .reverse()[0]
                                                .replace(/%20/g, ' ')
                                        }}
                                    </a>
                                    <span v-else>{{ subItem.value }}</span>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <span
                                        v-for="(fileItem,
                                        fileIndex) in geom.documents"
                                        :key="fileIndex"
                                    >
                                        <a
                                            v-if="!fileItem.src"
                                            class="fileName"
                                            @click="downloadFile(fileItem)"
                                            >{{ fileItem.FileName }}</a
                                        >
                                        <img
                                            v-if="fileItem.src"
                                            :src="fileItem.src"
                                            style="width: 300px;"
                                        />
                                        <br />
                                    </span>
                                </b-col>
                            </b-row>
                            <b-row class="rowBtn">
                                <b-col>
                                    <button
                                        class="btn btn-identify btn-zoom"
                                        v-show="item.zoom"
                                        @click="zoomToIdentifyResult(geom)"
                                    >
                                        Zoom To
                                    </button>
                                    <button
                                        class="btn btn-reverselookup btn-zoom"
                                        v-show="item.canReverse"
                                        @click="
                                            reverseLookup(
                                                item.layerID,
                                                getFeatureID(geom.properties)
                                            )
                                        "
                                    >
                                        Receptor Lookup
                                    </button>
                                    <button
                                        class="btn btn-identify"
                                        v-show="item.editable"
                                        @click="editIdentifyResult(geom)"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        class="btn btn-identify"
                                        v-show="item.HCALookup"
                                        @click="runHCAQuery(geom)"
                                    >
                                        HCA Lookup
                                    </button>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import hide from '../../../directives/hide.js';
import { mapState, mapActions, mapMutations } from 'vuex';
import { commonService } from '../../services/common.service';
import { spatialService } from '../../services/spatial.services.js';
import { spatialFunctions } from '../../utilities/spatialFunctions';
export default {
    name: 'MapIdentifyResults',
    props: {
        identifiedDataJson: {
            type: Array,
            default: () => [],
        },
        isEditing: {
            type: Boolean,
            default: false,
        },
        hasHighlight: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            i: 0,
            // isActive: true
        };
    },
    computed: {
        resultsDataJson() {
            let resultJson = [];
            try {
                this.identifiedDataJson.forEach((layer) => {
                    if (layer && layer.Geometry.length > 0) {
                        resultJson.push(layer);
                    }
                });
            } catch (e) {
                return [];
            }
            return resultJson;
        },
    },
    methods: {
        ...mapActions('emergencyManagement', ['setDrawingAttributes']),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
            loadDrawingAttributes: 'loadDrawingAttributes',
        }),
        zoomToIdentifyResult(feature) {
            let featureId = 0;
            let mapLayerId = 0;
            feature.properties.forEach((prop) => {
                if (prop.label == 'MapLayerID') {
                    mapLayerId = prop.value;
                } else if (prop.label == 'FeatureID') {
                    featureId = prop.value;
                }
            });
            spatialService
                .featureGeometry(mapLayerId, featureId)
                .then((res) => {
                    feature.geometry = res.data.geometry;
                    this.$emit('zoomTo', feature);
                    this.$emit('highlight', feature);
                });
        },
        editIdentifyResult(feature) {
            let attributes = {};
            let featureId = 0;
            let mapLayerId = 0;
            feature.properties.forEach((prop) => {
                if (prop.label == 'MapLayerID') {
                    mapLayerId = prop.value;
                } else if (prop.label == 'FeatureID') {
                    featureId = prop.value;
                }
                switch (prop.label) {
                    case 'LayerDescription':
                        break;
                    case 'FeatureID':
                        attributes['geoID'] = parseInt(prop.value);
                        break;
                    case 'LocName':
                        attributes['geomLocName'] = prop.value;
                        break;
                    case 'LocType':
                        attributes['geomLocType'] = prop.value;
                        break;
                    case 'GeoDescription':
                        attributes['geomLocDesc'] = prop.value;
                        break;
                    case 'Comment':
                        attributes['geomComment'] = prop.value;
                        break;
                    case 'ProjectID':
                        attributes['projectID'] = parseInt(prop.value);
                        break;
                }
            });
            attributes.geomLocIcon = '';
            if (!Object.keys(feature).includes('geometry')) {
                spatialService
                    .featureGeometry(mapLayerId, featureId)
                    .then((res) => {
                        feature.geometry = res.data.geometry;
                        attributes.geomWKT = spatialFunctions.convertToWKT(
                            feature,
                            feature.geometry.coordSys == 'LatLong'
                        );
                        attributes.geometry = feature.geometry;
                        this.loadDrawingAttributes(attributes);
                        this.setShowAttributeModal(true);
                    });
            } else {
                attributes.geomWKT = spatialFunctions.convertToWKT(
                    feature,
                    feature.geometry.coordSys == 'LatLong'
                );
                attributes.geometry = feature.geometry;
                this.loadDrawingAttributes(attributes);
                this.setShowAttributeModal(true);
            }
        },
        getFeatureID(properties) {
            let featureId = -1;

            let i = 0;
            while (featureId < 0 && i < properties.length) {
                if (properties[i].label.toLowerCase() == 'featureid') {
                    featureId = parseInt(properties[i].value);
                }
                i++;
            }
            return featureId;
        },
        reverseLookup(layerID, featureID) {
            this.$emit('reverseLookup', layerID, featureID);
        },
        runHCAQuery(feature) {
            this.$emit('runHCA', feature, 'identify');
        },
        incrementResultCount() {
            this.i++;
        },
        downloadFile(file) {
            //
            commonService.downloadThumbnailByID(file.DocumentId, file.FileName);
        },
        clearIDResults() {
            this.$emit('clearResults');
            if (this.hasHighlight) {
                this.$emit('clearHighlight');
            }
        },
        clearHighlight() {
            this.$emit('clearHighlight');
        },
    },
};
</script>

<style scoped>
.btn {
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 40px;
    /* need correct font-family */
}
.btn-identify {
    background-color: rgb(129, 129, 129);
    color: white;
    width: 90px;
    height: 40px;
}
.btn-reverselookup {
    background-color: rgb(129, 129, 129);
    color: white;
    width: 120px;
    text-wrap: wrap;
    height: 60px;
}
.btn-zoom {
    margin-right: 10px;
}
.identifyTitle {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    padding-bottom: 5px;
    font-family: 'IBMPlexSans-Regular';
}
.identifyLabel {
    font-weight: bold;
}
.card {
    border: solid 1px gray !important;
    margin-bottom: 10px;
}
.card:hover {
    border: solid 2px rgb(77, 194, 233) !important;
    transition: 0.1s;
}
.container {
    padding-left: 0;
}
.rowBtn {
    padding-top: 10px;
}
.fileName {
    color: #006395 !important;
    text-decoration: underline !important;
    font-weight: bold;
}
.rowBtns {
    padding-left: 10px;
    padding-right: 10px;
}
.btn-gray {
    background-color: gray !important;
    color: white;
    /* height: 42px; */
}
.btn-gray:active {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:hover {
    background-color: darkgrey;
}

@media (max-width: 769px) {
    #cards-wrapper {
        overflow-y: auto;
        max-height: 65vh;
    }
    .identify-result-card {
        max-width: 88vw;
    }
    #btn-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px 15px 0px 10px;
    }
}

.identifyLabel {
    font-weight: bold;
    font-size: 13px;
    font-family: 'IBMPlexSans-Regular';
}
.property-value {
    display: flex;
    align-items: center;
    font-size: 14px;
    word-break: break-all;
}

.property-value a {
    text-decoration: none;
    color: #007bff;
}
</style>
