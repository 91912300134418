import { dataSourceService } from '../services';

const state = {
    dreams: {Risk:{}, HydraulicsBase:{}, HydraulicsSecondary:{}},
    riskAttribute: [],
    colorRange: [],
    showColorRange: ['legend0','legend1','legend2','legend3','legend4',],
    model: '',
    version: 1,
    showModel: false,
    refreshMap: false,
};

const actions = {
    setRiskAttribute({ commit }, payload) {
        commit('mutateSetRiskAttribute', payload);
    },
    addRiskAttribute({ commit }, payload) {
        commit('mutateAddRiskAttribute', payload);
    },
    clearRiskAttribute({ commit }) {
        commit('mutateClearRiskAttribute');
    },
    setModel({ commit }, value) {
        commit('mutateSetModel', value);
    },
    setRiskModel({ commit }, payload) {
        commit('mutateSetRiskModel', payload);
    },
    setHydraulicsBase({ commit }, payload) {
        commit('mutateSetHydraulicsBase', payload);
    },
    setHydraulicsSecondary({ commit }, payload) {
        commit('mutateSetHydraulicsSecondary', payload);
    },
    clearHydraulicsBase({ commit }) {
        commit('mutateClearHydraulicsBase');
    },
    clearHydraulicsSecondary({ commit }) {
        commit('mutateClearHydraulicsSecondary');
    },
    setColorRange({ commit }, payload) {
        commit('mutateSetColorRange', payload);
    },
    clearColorRange({ commit }) {
        commit('mutateClearColorRange');
    },
    setShowColorRange({ commit }, payload) {
        commit('mutateSetShowColorRange', payload);
    },
    clearShowColorRange({ commit }) {
        commit('mutateClearShowColorRange');
    },
    clearDreams({ commit }) {
        commit('mutateClearDreams');
    },
    setVersion({ commit }, value) {
        commit('mutateSetVersion', value);
    },
    setShowModel({ commit }, value) {
        commit('mutateSetShowModel', value);
    },
    setRefreshMap({ commit }, value) {
        commit('mutateSetRefreshMap', value);
    },
};
const mutations = {
    mutateSetRiskAttribute(state, payload) {
        state.riskAttribute = payload;
    },
    mutateAddRiskAttribute(state, payload) {
        const i = state.riskAttribute.findIndex(
            (_element) => _element.id === payload.id
        );
        if (i > -1) state.riskAttribute[i] = payload;
        else {  
            state.riskAttribute.push(payload);
        }

    },
    mutateClearRiskAttribute(state) {
        state.riskAttribute = [];
    },
    mutateSetModel(state, value) {
        state.model = value;
    },
    mutateSetRiskModel(state, payload) {
        state.dreams.Risk = payload;
    },
    mutateSetHydraulicsBase(state, payload) {
        state.dreams.HydraulicsBase = payload;
    },
    mutateSetHydraulicsSecondary(state, payload) {
        state.dreams.HydraulicsSecondary = payload;
    },
    mutateClearHydraulicsBase(state) {
        state.dreams.HydraulicsBase = {};
    },
    mutateClearHydraulicsSecondary(state) {
        state.dreams.HydraulicsSecondary = {};
    },
    mutateSetColorRange(state, payload) {
        state.colorRange = payload;
    },
    mutateClearColorRange(state) {
        state.colorRange = [];
    },
    mutateSetShowColorRange(state, payload) {
        state.showColorRange = payload;
    },
    mutateClearShowColorRange(state) {
        state.showColorRange = [];
    },
    mutateClearDreams(state){
        state.dreams.Risk = {};
        state.dreams.HydraulicsBase = {};
        state.dreams.HydraulicsSecondary={};
        state.riskAttribute = [];
        state.colorRange = [];
        state.showColorRange = ['legend0','legend1','legend2','legend3','legend4',];
        state.model = '';
    },
    mutateSetVersion(state, value) {
        state.version = value;
    },
    mutateSetShowModel(state, value) {
        state.showModel = value;
    },
    mutateSetRefreshMap(state, value) {
        state.refreshMap = value;
    },
};

const getters = {
    version: (state) => {
        return state.version;
    },
    dreamsModel: (state) => {
        return state.model;
    },
    riskRange: (state) => {
        let result = state.colorRange.filter((obj) => {return state.showColorRange.includes(obj.id)});        
        return result.map(r => r.min);
    },
    dreamsShowModel: (state) => {
        return state.showModel;
    },
    dreamsRefreshMap: (state) => {
        return state.refreshMap;
    },
}

const namespaced = true;

export const dreams = {
    namespaced,
    state,
    actions,
    mutations,
    getters,
};
