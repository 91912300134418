<template>
    <div class="my-3 mx-2">
        <h3>Editing {{ layerName }}</h3>
        <hr />
        <div class="editpostedlayer">
            <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
            <h5>Posting layer name</h5>
            <div class="md-4 mb-0" role="group">
                <input
                    type="text"
                    class="inputbox-edit mb-4 smallWidth"
                    autocomplete="off"
                    v-model="layerName"
                />
            </div>
            <div>
                <b-form-group
                    id="input-group-3"
                    label-for="input-3"
                    class="smallWidth"
                >
                    <h5>Select field to display</h5>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Field value',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <b-form-select
                            id="inline-form-custom-select-pref"
                            class="smallWidth"
                            v-model="fieldName"
                            :options="fieldData"
                            text-field="fieldData"
                            value-field="fieldData"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>{{
                                    editFieldName
                                }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </CustomFloatingLabel>
                </b-form-group>
            </div>
            <div>
                <b-form-group
                    id="input-group-3"
                    label-for="input-3"
                    class="layer-new FloatingTool smallWidth"
                >
                    <h5>Aggregate options</h5>
                    <div v-for="(item, index) in aggreGateOption" :key="index">
                        <img
                            src="../../../assets/OverflowMenu.svg"
                            font-scale="1.5"
                            class="overflow-icon"
                            v-if="overflowMenu"
                        />
                        <div class="aggr-edit">
                            <CustomFloatingLabel
                                class="smallWidth"
                                :config="{
                                    label: 'Aggregate Field',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="item.aggregateOptionField"
                                    @change="addAggrType(index)"
                                    id="inline-form-custom-select-pref"
                                    class="float-left smallWidth"
                                    :class="{
                                        subSelect: index != 0,
                                    }"
                                    :options="fieldAggregateDataUnique"
                                    text-field="fieldAggregateDataUnique"
                                    value-field="fieldAggregateDataUnique"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Aggregate
                                            field</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                            <span
                                class="dlt-icon"
                                v-if="index != 0"
                                @click="deleteAggr(index)"
                                ><img
                                    src="../../../assets/delete_icon.svg"
                                    class="ml-2 float-left"
                            /></span>
                        </div>
                        <b-row
                            class="sub ml-0"
                            v-for="(subItem, subIndex) in item.aggreGateType"
                            :key="subIndex"
                        >
                            <img
                                src="../../../assets/L-shaped.svg"
                                class="img-l"
                            />
                            <!-- v-if="aggrfieldType" -->
                            <CustomFloatingLabel
                                class="FloatingTool editAggrType smallSecondary"
                                :config="{
                                    label: 'Aggregate type',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    class="smallAgg"
                                    id="inline-form-custom-select-pref"
                                    v-model="subItem.aggreGateTypeName"
                                    :options="aggreGateTypeData[index]"
                                    text-field="AggregateOptions"
                                    value-field="AggregateOptions"
                                >
                                    <!-- <option v-for="j in aggregateType" :key="j">{{ j }}</option> -->
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Aggregate
                                            type</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                            <img
                                src="../../../assets/L-shaped.svg"
                                v-if="
                                    subItem.aggreGateTypeName ==
                                        'Closest to a provided date'
                                "
                                class="imgIndent imgEditProvidedDate"
                            />
                            <CustomFloatingLabel
                                v-if="
                                    subItem.aggreGateTypeName ==
                                        'Closest to a provided date'
                                "
                                class="FloatingTool layer-post editProvidedDate"
                                :config="{
                                    label: 'Provided date',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <input
                                    class="edit avlFilter mt-2 mb-4"
                                    type="date"
                                    v-if="
                                        subItem.aggreGateTypeName ==
                                            'Closest to a provided date'
                                    "
                                    v-model="subItem.aggreGateDate"
                                />
                            </CustomFloatingLabel>
                        </b-row>
                        <a
                            @click="deleteAggr(index, subIndex)"
                            class="deletebtn"
                        ></a>
                        <!--  -->
                    </div>
                </b-form-group>

                <p
                    class="headergroup add-btn select-edit smallWidth"
                    @click="addAggr()"
                >
                    Add another aggregate option +
                </p>
                <!-- editing page -->
                <h5>Position <span class="grey-text-sm">(optional) </span></h5>
                <b-container class="bv-example-row mt-3 mb-5">
                    <b-row
                        cols="3"
                        class="edit-positon smallWidth"
                        v-model="editPosition"
                    >
                        <b-col class="border-bottom border-dark p-0 h-70">
                            <b-button
                                v-bind:class="{
                                    'tl_button edit':
                                        dataLabelPosition != 'topLeft',
                                }"
                                @click="dataPosition('topLeft')"
                            >
                                <span class="grey-text-sm">Top</span><br />
                                Left
                            </b-button>
                        </b-col>
                        <b-col class="border-bottom border-dark border-left">
                            <b-button
                                v-bind:class="{
                                    'tc_button edit':
                                        dataLabelPosition != 'topCenter',
                                }"
                                @click="dataPosition('topCenter')"
                            >
                                <span class="grey-text-sm">Top</span><br />
                                Center
                            </b-button>
                        </b-col>
                        <b-col
                            class="border-left border-bottom border-dark right-1"
                        >
                            <b-button
                                v-bind:class="{
                                    'tr_button edit':
                                        dataLabelPosition != 'topRight',
                                }"
                                @click="dataPosition('topRight')"
                            >
                                <span class="grey-text-sm">Top</span><br />
                                Right
                            </b-button>
                        </b-col>
                        <b-col class="border-bottom border-dark">
                            <b-button
                                v-bind:class="{
                                    'ml_button edit':
                                        dataLabelPosition != 'middleLeft',
                                }"
                                @click="dataPosition('middleLeft')"
                            >
                                <span class="grey-text-sm">Middle</span
                                ><br />Left
                            </b-button>
                        </b-col>
                        <b-col
                            class="border-left border-right border-bottom border-dark h-71"
                        >
                            <b-button
                                v-bind:class="{
                                    'mc_button edit':
                                        dataLabelPosition != 'middleCenter',
                                }"
                                @click="dataPosition('middleCenter')"
                            >
                                <span class="grey-text-sm">Middle</span><br />
                                Center
                            </b-button>
                        </b-col>
                        <b-col class="border-bottom border-dark">
                            <b-button
                                v-bind:class="{
                                    'mr_button edit':
                                        dataLabelPosition != 'middleRight',
                                }"
                                @click="dataPosition('middleRight')"
                            >
                                <span class="grey-text-sm">Middle</span
                                ><br />Right
                            </b-button>
                        </b-col>
                        <b-col class="border-dark h-71">
                            <b-button
                                v-bind:class="{
                                    'bl_button edit':
                                        dataLabelPosition != 'bottomLeft',
                                }"
                                @click="dataPosition('bottomLeft')"
                            >
                                <span class="grey-text-sm">Bottom</span><br />
                                Left
                            </b-button>
                        </b-col>
                        <b-col class="border-left border-right border-dark">
                            <b-button
                                v-bind:class="{
                                    'bc_button edit':
                                        dataLabelPosition != 'bottomCenter',
                                }"
                                @click="dataPosition('bottomCenter')"
                            >
                                <span class="grey-text-sm">Bottom</span
                                ><br />Center
                            </b-button>
                        </b-col>
                        <b-col class="border-dark">
                            <b-button
                                v-bind:class="{
                                    'br_button edit':
                                        dataLabelPosition != 'bottomRight',
                                }"
                                @click="dataPosition('bottomRight')"
                            >
                                <span class="grey-text-sm">Bottom</span><br />
                                Right
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>
                <div>
                    <b-form-group
                        class="smallWidth"
                        id="input-group-3"
                        label-for="input-3"
                    >
                        <h5>
                            Font options
                            <span class="grey-text-sm">(optional)</span>
                        </h5>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Size',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="styleSize"
                                :options="styleSizeData"
                                text-field="Size"
                                value-field="Size"
                                class="smallWidth"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Size</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Style',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="styleStyle"
                                :options="styleStyleData"
                                text-field="Style"
                                value-field="Style"
                                class="smallWidth"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Style</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>

                        <div class="pickColor wrap smallWidth">
                            <div>
                                <CustomFloatingLabel
                                    class="FloatingTool EditPostedLayer"
                                    :config="{
                                        label: 'Color',
                                        name: 'wrapper',
                                        line: false,
                                        scale: false,
                                    }"
                                >
                                    <button
                                        v-if="panelWidth === 'small'"
                                        @click="colorPicker('small')"
                                        class="btn-transparent btnSm"
                                    >
                                        <img
                                            src="../../../assets/ghd_colorpicker.svg"
                                            font-scale="1.5"
                                            class="pencil-icon"
                                        />
                                    </button>
                                    <button
                                        v-else
                                        @click="colorPicker('medium')"
                                        class="btn-transparent btnSm"
                                    >
                                        <img
                                            src="../../../assets/ghd_colorpicker.svg"
                                            font-scale="1.5"
                                            class="pencil-icon"
                                        />
                                    </button>
                                    <ejs-colorpicker
                                        v-if="panelWidth === 'small'"
                                        id="element1"
                                        type="text"
                                        class="e-input"
                                        v-model="fillColor"
                                        @change="updateColor(fillColor)"
                                        readonly
                                    ></ejs-colorpicker>
                                    <ejs-colorpicker
                                        v-else
                                        id="element2"
                                        type="text"
                                        class="e-input"
                                        v-model="fillColor"
                                        @change="updateColor(fillColor)"
                                        readonly
                                    ></ejs-colorpicker>
                                </CustomFloatingLabel>
                            </div>
                        </div>
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: 'Type',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="styleType"
                                :options="styleTypeData"
                                text-field="Type"
                                value-field="Type"
                                class="smallWidth"
                            >
                                <template #first>
                                    <b-form-select-option :value="null" disabled
                                        >Type</b-form-select-option
                                    >
                                </template>
                            </b-form-select>
                        </CustomFloatingLabel>

                        <!-- <CustomFloatingLabel class="FloatingTool" :config="{label: 'Rotation', name: 'wrapper', line: false, scale: false}" > 
                          <b-form-select
                            id="inline-form-custom-select-pref"
                            v-model="rotation"
                            :options="[
                              { text: '0°', value: '0°' },
                              { text: '90°', value: '90°' },
                              { text: '180°', value: '180°' },
                              { text: '-90°', value: '-90°' },
                            ]"
                            :value="'0°'"
                          >
                          </b-form-select>
                        </CustomFloatingLabel> -->
                    </b-form-group>
                    <h5 class="trans-sm">Transparency</h5>
                    <div class="d-flex my-4">
                        <span class="font-weight-bold indigo-text mr-2 mt-1"
                            >0</span
                        >
                        <form class="range-field grey-color">
                            <input
                                class="border-0 inputrange smallInput"
                                type="range"
                                v-model="transparencyValue"
                                min="0"
                                step="1"
                                max="100"
                            />
                        </form>
                        <span class="font-weight-bold indigo-text ml-2 mt-1"
                            >100</span
                        >
                        <p margin-top="50px">
                            <input
                                type="text"
                                margin-top="50px"
                                class="input-value-minimap"
                                display="inline-block"
                                min="0"
                                max="100"
                                maxlength="3"
                                size="2.5ch"
                                padding-top="5px"
                                v-model.lazy="transparencyValue"
                                v-on:keyup.enter="validateTransparency()"
                                v-click-outside="validateTransparency()"
                            />%
                        </p>
                    </div>
                </div>
                <b-form-group
                    id="input-group-3"
                    label-for="input-3"
                    class="bottom-m-75 smallWidth"
                >
                    <h5>
                        Symbology
                        <span class="grey-text-sm">(optional ) </span>
                    </h5>
                    <b-dropdown class="symbology smallWidth" text="Symbology">
                        <template slot="button-content">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/circle.svg"
                                v-if="symbology == 'circle'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/arrow.svg"
                                v-if="symbology == 'arrow'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/flag.svg"
                                v-if="symbology == 'flag'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/star_5.svg"
                                v-if="symbology == 'star'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/cross_4.svg"
                                v-if="symbology == 'cross'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/cross_3.svg"
                                v-if="symbology == 'cross3'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/cross_5.svg"
                                v-if="symbology == 'cross5'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/star_3.svg"
                                v-if="symbology == 'star3'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/star_4.svg"
                                v-if="symbology == 'star4'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/polygon_5.svg"
                                v-if="symbology == 'polygon5'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/polygon_4.svg"
                                v-if="symbology == 'polygon4'"
                            />
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/polygon_3.svg"
                                v-if="symbology == 'polygon3'"
                            />
                        </template>
                        <b-dropdown-item @click.prevent="symbology = 'circle'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/circle.svg"
                            />
                        </b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'arrow'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/arrow.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'flag'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/flag.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'star'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/star_5.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'cross'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/cross_4.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'cross3'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/cross_3.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'cross5'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/cross_5.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'star3'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/star_3.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item @click.prevent="symbology = 'star4'">
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/star_4.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item
                            @click.prevent="symbology = 'polygon3'"
                        >
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/polygon_3.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item
                            @click.prevent="symbology = 'polygon4'"
                        >
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/polygon_4.svg"
                        /></b-dropdown-item>
                        <b-dropdown-item
                            @click.prevent="symbology = 'polygon5'"
                        >
                            <img
                                class="img-fluid p-2"
                                src="../../../assets/polygon_5.svg"
                        /></b-dropdown-item>
                    </b-dropdown>
                </b-form-group>
            </div>
        </div>
        <b-container class="bottom-btn p-0">
            <div class="d-flex justify-content-center py-2">
                <b-button @click="$emit('cancel')" class="grey-none">
                    Cancel
                </b-button>
                <b-button
                    class="mx-1 white-none"
                    @click="saveLayerChanges(false)"
                >
                    Save Changes
                </b-button>
                <b-button
                    @click="saveLayerChanges(true)"
                    class="mx-1 white-none"
                >
                    Save as Duplicate
                </b-button>
            </div>
        </b-container>
    </div>
</template>

<script>
import Vue from 'vue';
import CustomFloatingLabel from '../../CustomFloatingLabel';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';

import { spatialFunctions } from '../../../utilities/spatialFunctions';
import { projectService } from '../../../services/project.service';
import { userService } from '../../../services/users.service';

Vue.use(ColorPickerPlugin);

export default {
    name: 'PostedLayerEdit',
    components: {
        CustomFloatingLabel,
    },
    props: {
        dataset: {
            required: true,
            type: Object,
            default: () => {},
        },
        datasetIdx: {
            required: true,
            type: Number,
            default: 0,
        },
        panelWidth: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            aggreGateOption: [
                {
                    aggreGateField: '',
                    aggreGateType: [],
                },
            ],
            layerName: '',
            fillColor: '#000000ff',
            dataLabelPosition: null,
            editSymbology: null,
            symbology: null,
            styleSize: 10,
            styleColor: '#000000ff',
            editSize: null,
            editType: null,
            styleType: 'Arial',
            styleStyle: 'Normal',
            editStyle: null,
            pickColor: false,
            editPostingSource: null,
            colorSelected: null,
            rotation: '0°',
            editRotation: null,
            transparencyValue: 0,
            editTransparencyValue: null,
            editPosition: null,
            editAggregateOptionField: '',
            styleData: [],
            styleSizeData: [],
            styleTypeData: [],
            styleColorData: [],
            styleStyleData: [],
            fieldAggregateData: [],
            fieldAggregateDataUnique: [],
            fieldName: null,
            editFieldName: '',
            overflowMenu: false,
            fieldData: [],
            aggreGateTypeData: [],
            dptData: [],
            updatedDataset: {},
        };
    },
    mounted() {
        this.populateData();
    },
    methods: {
        allAggregateOption() {
            userService.getAggregateOptions().then((res) => {
                this.options = res.data;
            });
        },
        populateData() {
            if (Object.keys(this.dataset).length === 0) {
                return;
            }
            this.editLayer = true;
            this.aggreGateOption = [];
            //this.selectedPostingId = this.dataset.postingId;
            if (this.dataset.layerName === '') {
                // don't have to hadle no-name datasets, means that it has been cleared
                return;
            }
            this.dataset.aggrOption.forEach((item, index) => {
                this.aggreGateOption.push({
                    aggregateOptionField: item.aggreGateField,
                    aggreGateType: [],
                });
                this.aggreGateOption[index].aggreGateType.push({
                    aggreGateTypeName: item.aggreGateType,
                    aggreGateDate: item.aggreGateDate,
                });
                // item.aggregateOptionField=item.aggreGateField;
            });
            // this.postLayerActive = false;
            this.selectAggregateField(this.dataset.datasetID);
            this.editPostingSource = this.dataset.radioSource;
            this.layerName = this.dataset.DisplayName;
            this.styleSize = this.dataset.fontSize;
            this.styleStyle = this.dataset.fontStyle;
            this.styleType = this.dataset.fontType;
            this.fieldName = this.dataset.FieldName;
            this.rotation = this.dataset.fontRotation;
            this.fillColor = this.dataset.color;
            this.editAggregateOptionField = this.dataset.aggrOption;
            this.symbology = this.dataset.pointSymbology;
            this.transparencyValue = this.dataset.transparency;
            this.dataLabelPosition = this.dataset.position;
            this.getStyleSize();
            this.getStyleType();
            this.getStyleStyle();
            this.allAggregateOption();
            this.editAggrType(
                this.dataset.aggrOption[0].aggreGateField,
                this.datasetIdx
            );
            this.selectField(this.dataset);
        },
        deleteAggr(index) {
            this.aggreGateOption.splice(index, 1);
            // this.aggrfieldType = false;
        },
        colorPicker(value) {
            if (value === 'small') {
                document.getElementById('element1').ej2_instances[0].toggle();
            } else {
                document.getElementById('element2').ej2_instances[0].toggle();
            }
        },
        updateColor(value) {
            this.fillColor = value;
        },
        dataPosition(item) {
            this.dataLabelPosition = item;
        },
        selectField(dataset) {
            projectService.selectField(dataset.datasetID).then((res) => {
                let dataField = res.data;
                if (dataField) {
                    dataField.forEach((item) => {
                        if (item.AttributeType === 'Default') {
                            this.fieldName = item.DatasetColumn.ColumnAlias
                                ? item.DatasetColumn.ColumnAlias
                                : item.DatasetColumn.ColumnName;
                        }
                        this.fieldData.push(
                            item.DatasetColumn.ColumnAlias
                                ? item.DatasetColumn.ColumnAlias
                                : item.DatasetColumn.ColumnName
                        );
                    });
                    this.fieldData = [...new Set(this.fieldData)];
                } else {
                    this.fieldData = [dataset.FieldName];
                }
            });
        },
        selectAggregateField(datasetID) {
            projectService.selectAggregateField(datasetID).then((res) => {
                this.fieldAggregateData = res.data;
                if (this.fieldAggregateData) {
                    this.fieldAggregateData.forEach((item) => {
                        if (item.AttributeType === 'Default') {
                            this.aggreGateOption[0].aggregateOptionField = item
                                .DatasetColumn.ColumnAlias
                                ? item.DatasetColumn.ColumnAlias
                                : item.DatasetColumn.ColumnName;
                            this.addAggrType(0);
                        }
                        this.fieldAggregateDataUnique.push(
                            item.DatasetColumn.ColumnAlias
                                ? item.DatasetColumn.ColumnAlias
                                : item.DatasetColumn.ColumnName
                        );
                    });
                    this.fieldAggregateDataUnique = [
                        ...new Set(this.fieldAggregateDataUnique),
                    ];
                }
            });
        },
        addAggrType(index) {
            let dataTypeSetId;

            this.aggreGateOption[index].aggreGateType = [];
            dataTypeSetId = this.fieldAggregateData.find(
                (item) =>
                    this.aggreGateOption[index].aggregateOptionField ===
                    (item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName)
            );
            if (this.aggreGateOption[index].aggreGateType.length === 0) {
                this.aggreGateOption[index].aggreGateType.push({
                    aggreGateTypeName: '',
                    aggreGateDate: '',
                });
                userService
                    .getAggregateOptionsByType(
                        dataTypeSetId.DatasetColumn.Datatype
                    )
                    .then((res) => {
                        this.options = res.data;
                        this.aggreGateTypeData[index] = this.options;
                        if (
                            this.$store.state.store.portalsettings !== undefined
                        ) {
                            let numberPortalData = this.$store.state.store
                                .portalsettings.Number;
                            let datePortalData = this.$store.state.store
                                .portalsettings.Date;
                            let textPortalData = this.$store.state.store
                                .portalsettings.Text;
                            let result = this.options.filter(
                                (ele) =>
                                    ele.AggregateOptionID == numberPortalData ||
                                    ele.AggregateOptionID == datePortalData ||
                                    ele.AggregateOptionID == textPortalData
                            );
                            if (result.length) {
                                this.aggreGateOption[
                                    index
                                ].aggreGateType[0].aggreGateTypeName =
                                    result[0].AggregateOptions;
                                this.validateInput = false;
                            }
                        }
                        if (
                            this.aggreGateTypeData[index]
                                .map((a) => a.AggregateOptions)
                                .includes(
                                    this.dataset.aggrOption[index].aggreGateType
                                )
                        ) {
                            this.aggreGateOption[
                                index
                            ].aggreGateType[0].aggreGateTypeName = this.dataset.aggrOption[
                                index
                            ].aggreGateType;
                        }
                        this.$forceUpdate();
                    });
            }
        },
        getStyleBlock() {
            projectService
                .getStyleBlock()
                .then((res) => (this.styleData = res.data));
        },
        getStyleSize() {
            projectService.getStyleSize().then((res) => {
                this.styleSizeData = res.data;
            });
        },
        getStyleStyle() {
            projectService
                .getStyleStyle()
                .then((res) => (this.styleStyleData = res.data));
        },
        getStyleColor() {
            projectService
                .getStyleColor()
                .then((res) => (this.styleColorData = res.data));
        },
        getStyleType() {
            projectService
                .getStyleType()
                .then((res) => (this.styleTypeData = res.data));
        },
        editAggrType(datatype, index) {
            let dataTypeSetId;
            if (this.fieldAggregateData.length > 0) {
                dataTypeSetId = this.fieldAggregateData.find(
                    (item) =>
                        (item.DatasetColumn.ColumnAlias
                            ? item.DatasetColumn.ColumnAlias
                            : item.DatasetColumn.ColumnName) === datatype
                );
                userService
                    .getAggregateOptionsByType(
                        dataTypeSetId.DatasetColumn.Datatype
                    )
                    .then((res) => {
                        this.options = res.data;
                        this.aggreGateTypeData[index] = this.options;
                        this.editAggrTypeRevised = this.aggreGateTypeData[
                            index
                        ];
                        this.$forceUpdate();
                    });
            } else {
                setTimeout(() => {
                    dataTypeSetId = this.fieldAggregateData.find(
                        (item) =>
                            (item.DatasetColumn.ColumnAlias
                                ? item.DatasetColumn.ColumnAlias
                                : item.DatasetColumn.ColumnName) === datatype
                    );
                    userService
                        .getAggregateOptionsByType(
                            dataTypeSetId.DatasetColumn.Datatype
                        )
                        .then((res) => {
                            this.options = res.data;
                            this.aggreGateTypeData[index] = this.options;
                            this.editAggrTypeRevised = this.aggreGateTypeData[
                                index
                            ];
                            this.$forceUpdate();
                        });
                }, 1000);
            }
        },

        // added duplicate as an arg, if true, inserts a copy of the layer, else edits layer in place
        processData(duplicate) {
            /*if (this.selectedPostingId) {
        let layerId = this.layerList.findIndex((item) => {
          return item.postingId == this.selectedPostingId;
        });
        this.layerList.splice(layerId, 1);
      }*/
            let dataSources = [];

            let aggreGateFieldData = [];

            this.aggreGateOption.forEach((item) => {
                aggreGateFieldData.push({
                    aggreGateField: item.aggregateOptionField,
                    aggreGateType: item.aggreGateType[0].aggreGateTypeName,
                    aggreGateDate: item.aggreGateType[0].aggreGateDate,
                });
            });

            let obj = {};
            this.dptData.forEach((ds) => {
                if (ds.geometry[0].x && ds.geometry[0].y) {
                    obj['result'] = ds.geometry[0].result;
                    obj['latitude'] = spatialFunctions.meters2lat(
                        ds.geometry[0].y
                    );
                    obj['longitude'] = spatialFunctions.meters2lng(
                        ds.geometry[0].x
                    );
                    dataSources.push(obj);
                }
            });

            let styleCode;
            let fontSize;
            let fontFamily;
            let fontWeight;
            let rotation;
            let transparency;
            if (this.styleSize) {
                fontSize = this.styleSize + 'px';
            } else {
                fontSize = '8px';
            }
            if (this.styleType) {
                fontFamily = this.styleType;
            } else {
                fontFamily = 'IBM Plex Sans Regular';
            }
            if (this.styleStyle) {
                fontWeight = this.styleStyle;
            } else {
                fontWeight = 'normal';
            }
            if (this.rotation) {
                rotation = 'rotate(' + this.rotation + 'deg)';
            } else {
                rotation = 'rotate(0deg)';
            }
            if (this.transparencyValue) {
                transparency = parseInt(this.transparencyValue);
            } else {
                transparency = '0';
            }

            switch (this.dataLabelPosition) {
                case 'topLeft':
                    styleCode =
                        '<div><div style="margin-bottom: 30px;padding-right:60px;';
                    break;
                case 'topCenter':
                    styleCode =
                        '<div><div style="margin-bottom: 30px;padding-right:25px;';
                    break;
                case 'topRight':
                    styleCode = '<div><div style="margin-bottom: 30px;';
                    break;
                case 'bottomLeft':
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:50px;';
                    break;
                case 'bottomCenter':
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:20px;';
                    break;
                case 'bottomRight':
                    styleCode = '<div><div style="margin-top: 11px;';
                    break;
                case 'middleLeft':
                    styleCode =
                        '<div><div style="margin-bottom: 3px;padding-right:60px;';
                    break;
                case 'middleCenter':
                    styleCode =
                        '<div><div style="margin-bottom: 3px;padding-right:25px;';
                    break;
                case 'middleRight':
                    styleCode = '<div><div style="margin-bottom: 3px;';
                    break;
                default:
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:20px;';
                    break;
            }
            styleCode += 'font-family:';
            fontFamily +
                ';font-size:' +
                fontSize +
                ';font-weight:' +
                fontWeight +
                ';font-style:' +
                fontWeight +
                ';transform:' +
                rotation +
                ';opacity:' +
                1 -
                transparency / 100 +
                '">${result}</div></div>';
            let postStyling = {
                FontColour: this.fillColor,
                FontRotation: this.rotation,
                FontSize: this.styleSize,
                FontStyle: this.styleStyle,
                FontTransparency: parseInt(this.transparencyValue),
                FontType: this.styleType,
                LabelPosition: this.dataLabelPosition,
                LineColour: this.fillColor,
                // LineStyle: lyr.LineStyle,
                LineWidth: 1, //lyr.LineWidth,
                PolygonColour: this.fillColor,
                // PolygonStyle: lyr.PolygonStyle,
                PolygonTransparency: parseInt(this.transparencyValue),
                PolygonBorderColour: this.fillColor,
                PolygonWidth: 1, //lyr.PolygonWidth,
                Symbology: this.symbology,
                SymbologyColour: this.fillColor,
                Opacity: 100 - parseInt(this.transparencyValue),
            };
            this.postedDataLayers.features.forEach((feature) => {
                feature.styling = postStyling;
            });

            if (!this.postedDataLayers.labelVisibleIndex) {
                for (
                    let i = 0;
                    i < this.postedDataLayers.features[0].properties.length;
                    i++
                ) {
                    if (this.postedDataLayers.features[0].properties[i].label) {
                        this.postedDataLayers.labelVisibleIndex = i;
                    }
                }
            }

            let markerSettings = [
                {
                    visible: true,
                    dataSource: this.postedDataLayers,
                    shape: 'Image',
                    imageUrl:
                        this.symbology == 'arrow'
                            ? '../../assets/arrow_halved.svg'
                            : this.symbology == 'flag'
                            ? '../../assets/flag_halved.svg'
                            : this.symbology == 'circle'
                            ? '../../assets/circle_halved.svg'
                            : this.symbology == 'star'
                            ? '../../assets/star_5_halved.svg'
                            : this.symbology == 'star3'
                            ? '../../assets/star_3_halved.svg'
                            : this.symbology == 'star4'
                            ? '../../assets/star_4_halved.svg'
                            : this.symbology == 'cross3'
                            ? '../../assets/cross_3_halved.svg'
                            : this.symbology == 'cross5'
                            ? '../../assets/cross_5_halved.svg'
                            : this.symbology == 'polygon3'
                            ? '../../assets/polygon_3_halved.svg'
                            : this.symbology == 'polygon4'
                            ? '../../assets/polygon_4_halved.svg'
                            : this.symbology == 'polygon5'
                            ? '../../assets/polygon_5_halved.svg'
                            : '../../assets/circle_halved.svg',

                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        // valuePath: "sitecode",
                        textStyle: {
                            size: this.styleSize
                                ? this.styleSize + 'px'
                                : '10px',
                            fontFamily: this.styleType
                                ? this.styleType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.styleStyle
                                ? this.styleStyle
                                : 'normal',
                        },
                    },
                },
                {
                    visible: true,
                    dataSource: dataSources,
                    template: styleCode,
                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        //  valuePath: "sitecode",
                        textStyle: {
                            size: this.styleSize
                                ? this.styleSize + 'px'
                                : '10px',
                            fontFamily: this.styleType
                                ? this.styleType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.styleStyle
                                ? this.styleStyle
                                : 'normal',
                        },
                    },
                },
            ];
            this.updatedDataset = {
                postingId: this.dataset.postingId,
                visible: true,
                DisplayName: this.layerName,
                clustered: this.dataset.clustered,
                id: this.dataset.id,
                FieldName: this.fieldName,
                aggrOption: aggreGateFieldData,
                radioSource: this.postingSource,
                color: this.fillColor,
                pointSymbology: this.symbology,
                fontSize: this.styleSize,
                fontStyle: this.styleStyle,
                fontType: this.styleType,
                fontRotation: this.rotation,
                transparency: this.transparencyValue,
                markerSettings11: markerSettings,
                position: this.dataLabelPosition,
                datasetID: this.dataset.datasetID,
                filterOptions: this.dataset.filterOptions,
            };

            this.aggreGateOption = [];
            this.aggreGateOption.push({
                aggreGateField: '',
                aggreGateType: [],
            });
            this.layerName = '';
            this.fieldName = null;
            this.colorSelected = null;
            this.selectedPostingId = '';

            var payload = {
                editIdx: this.datasetIdx,
                editDataset: this.updatedDataset,
            };
            if (duplicate) {
                payload.editDataset.DisplayName += ' - Copy';
                this.$store.commit('projects/duplicatePostedLayer', payload);
            } else {
                this.$store.commit('projects/updatePostedLayer', payload);
            }
            setTimeout(() => {
                this.$parent.$refs.mainLmap.reclusterPosted();
            }, 500);
            this.$emit('cancel');
        },

        saveLayerChanges(duplicate) {
            let aggregateData = [];
            this.aggreGateOption.forEach((item) => {
                if (item.aggreGateType[0].aggreGateDate) {
                    let x = this.formatDate(
                        item.aggreGateType[0].aggreGateDate
                    );
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggreGateType[0].aggreGateTypeName +
                            '(' +
                            x +
                            ')'
                    );
                } else {
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggreGateType[0].aggreGateTypeName
                    );
                }
            });
            let aggregateDataFinal = aggregateData.toString();
            projectService
                .getDataPostToolData(
                    this.dataset.datasetID,
                    aggregateDataFinal,
                    this.fieldName,
                    this.dataset.filterOptions
                )
                .then((res) => {
                    this.postedDataLayers = res.data;
                    this.postedDataLayers.visible = true;
                    this.processData(duplicate);
                })
                .catch((err) => {
                    if (
                        err.response.data.Message == 'ForbiddenSQLInjection' &&
                        err.response.data.StatusCode == 500
                    ) {
                        this.$emit(
                            'resForbiddenPosting',
                            err.response.data.Message
                        );
                    } else {
                        this.showFailure = true;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });

            let aggreGateFieldData = [];
            this.aggreGateOption.forEach((item) => {
                aggreGateFieldData.push({
                    aggreGateField: item.aggregateOptionField,
                    aggreGateType: item.aggreGateType[0].aggreGateTypeName,
                    aggreGateDate: item.aggreGateType[0].aggreGateDate,
                });
            });
            // clears the dataset so that the watch works
            this.editPostingSource = '';
            this.postLayerActive = true;
        },
        validateTransparency() {
            if (isNaN(this.transparencyValue)) {
                this.transparencyValue = 50;
            } else if (this.transparencyValue < 0) {
                this.transparencyValue = 0;
            } else if (this.transparencyValue > 100) {
                this.transparencyValue = 100;
            }
        },
    },
    watch: {
        dataset(newDataset, oldDataset) {
            if (Object.keys(newDataset).length === 0) {
                return;
            }
            this.editLayer = true;
            this.aggreGateOption = [];
            //this.selectedPostingId = newDataset.postingId;
            if (newDataset.layerName === '') {
                // don't have to hadle no-name datasets, means that it has been cleared
                return;
            }
            newDataset.aggrOption.forEach((item, index) => {
                this.aggreGateOption.push({
                    aggregateOptionField: item.aggreGateField,
                    aggreGateType: [],
                });
                this.aggreGateOption[index].aggreGateType.push({
                    aggreGateTypeName: item.aggreGateType,
                    aggreGateDate: item.aggreGateDate,
                });
                // item.aggregateOptionField=item.aggreGateField;
            });
            // this.postLayerActive = false;
            this.selectAggregateField(newDataset.datasetID);
            this.editPostingSource = newDataset.radioSource;
            this.layerName = newDataset.DisplayName;
            this.styleSize = newDataset.fontSize;
            this.styleStyle = newDataset.fontStyle;
            this.styleType = newDataset.fontType;
            this.fieldName = newDataset.FieldName;
            this.rotation = newDataset.fontRotation;
            this.fillColor = newDataset.color;
            this.editAggregateOptionField = newDataset.aggrOption;
            this.symbology = newDataset.pointSymbology;
            this.transparencyValue = newDataset.transparency;
            this.dataLabelPosition = newDataset.position;
            this.getStyleSize();
            this.getStyleType();
            this.getStyleStyle();
            this.allAggregateOption();
            this.editAggrType(
                newDataset.aggrOption[0].aggreGateField,
                this.datasetIdx
            );
            this.selectField(newDataset);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';
.mainContent {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;
    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }
    h3 {
        font-size: 22px;
    }
    .some-radios {
        font-size: 14px;
        color: #626262;
    }
    // .custom-select {
    //   background: #fff url("../../assets/arrow_down.svg") no-repeat right 0.75rem
    //     center/8px 10px !important;
    //   width:75%;
    //   height: 48px;
    // }
}
.aggr-edit,
.layer-post {
    width: 241px;
}
.mainContent.edit-layer {
    max-height: 440px !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    // .inputbox-edit,
    .mediumWidth {
        width: 376px !important;
    }
    .smallWidth {
        width: 241px !important;
    }
    .mediumSecondary {
        width: 331px !important;
        left: 3px;
    }
    .smallSecondary {
        width: 200px !important;
        left: 3px;
    }
    .mediumAgg {
        width: 350px;
    }
    .smallAgg {
        width: 216px;
    }
}
.mainContent.edit-layer {
    max-height: 440px !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;
    .inputbox-edit,
    .select-edit {
        border-radius: 3px;
        padding: 7px;
        height: 48px;
        border: 1px solid rgb(206, 212, 218);
    }
    .subSelect {
        width: 180px;
        float: left;
    }
    .dlt-icon {
        position: absolute;
        right: 20px;
    }
    .edit.select-edit {
        width: 203px;
        margin-left: 15px;
    }
}

// .inputbox {
//   width: 499px;
//   border-radius: 3px;
//   padding: 7px;
//   height: 48px;
//   border: 1px solid rgb(206, 212, 218);
// }
.inputbox :focus {
    border: none;
}
.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.inputbox-color {
    height: 48px;
    width: 100%;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}
.pencil-icon {
    cursor: pointer;
}
.buttonSelect {
    position: absolute;
    right: 0;
    top: -45px;
}
.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}
.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}
.aggrType {
    margin-left: 36px;
    // margin-top: -23px;
    width: 400px;
}
.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}
.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}
.map_view_icon {
    background-color: grey;
    position: relative;
    bottom: 10px;
    border: 0 !important;
    // left: 8%;
    // float: right;
    .map_view_icon:focus {
        // outline: 1px dotted;
        outline: 0px;
    }
}
.search_element {
    background-image: url(../../../assets/search_dots.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
}
.pos_layer {
    width: auto;
    background-color: #f4f4f4;
    height: 80px;
    font-size: 0.9rem;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}
.pos_layer.hover {
    background-color: #f4f4f4;
}
.dataposting-icon {
    padding: 4px 2px 16px 15px;
    margin-left: -17px;
    background: #eaeaea;
}
.posted_layer {
    background: transparent;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}
.posted_layer.hover {
    background: white;
    color: #1d1d1d;
}
.project_layer {
    background: transparent;
    color: #1d1d1d;
    width: 144px;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.project_layer_active {
    background: transparent;
    color: #1d1d1d;
    width: 145px;
    margin-left: -38px;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 45px;
}
.proj_layer {
    height: 43px;
    font-weight: 500;
    width: 100%;
}
/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}
.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}
.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}
.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}
.edit-position-wide .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 86.67px;
    height: 70px;
    border-radius: 0;
}
.edit-layer .btn-secondary:focus,
.edit-layer .btn-secondary:not(:disabled):not(.disabled):active {
    color: #1d1d1d !important;
    background-color: #eaeaea !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    //right: 15px;
    position: relative;
    //bottom: -4px;
    border-radius: 0;
}
.edit-layer .border-dark {
    height: 70px;
    padding: 0px;
    text-align: center;
    width: 80px;
}
.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}
.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}
.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}
.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}
.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}
.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
// .btn-secondary{
//   height:69px;
// }
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}
.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.edit-button {
    color: #007bff !important;
    margin-top: 0px;
    //margin-left: 96px;
}
.delete-datapost {
    margin-left: none !important;
    margin-top: 9px !important;
    font-size: 14px;
    color: #817070 !important;
}
.border-right {
    height: 20px;
}
.input-value {
    display: block;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 10px;
    padding: 10px;
    margin-top: -5px;
    width: 60px;
    text-align: center;
}

.input-value-minimap {
    display: inline-flex;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    //padding: 10px;
    margin-top: 4px;
    margin-right: 0px;
    //width: 0px;
    //size: 2.5ch;
    text-align: center;
    border-style: hidden;
    //word-wrap: unset;
}
.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}
.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}
.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}
.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}
.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}
.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}
.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}
/* ------------------------------------------Position Button Css Ends Here------------------------------- */
.proj_layer.hover {
    background-color: white;
}
.slider_value {
    position: relative;
    bottom: 155px;
    left: 380px;
}
.slider {
    left: -3px !important;
}
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

.e-colorpicker-wrapper {
    position: relative !important;
    top: -10px !important;
    left: 40px !important;
}
/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}
/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}
/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}
.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}
.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    // padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}
.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}
// .btn-transparent{
//   position: inherit;
//   top: 20px;
//   right: 270px;
//   height: 40px;
//   width: 40px;
// }
.btnMed {
    position: inherit;
    top: 28px;
    right: 125px;
    height: 40px;
    width: 40px;
}
.btnSm {
    position: inherit;
    top: 25px;
    right: 270px;
    height: 40px;
    width: 40px;
    background: none !important;
}
// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    //left: 20px;
    top: 20px;
    left: 60px;
    border: 0 !important;
}
// real call is in global.scss
// .leafletLabelClass {
//   background-color: transparent;
//   border: transparent;
//   box-shadow: none;
//   font-size: 12pt;
// }
.e-split-btn-wrapper {
    position: relative !important;
    left: 40px !important;
    top: -10px !important;
    background-color: transparent !important;
}
.hidebtn {
    left: 50px;
}
.dataposting .portalbarMain {
    border-block: 0 !important;
}

.accBorder {
    // border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px#dee2e6;
}

.input-value {
    width: 61px;
    font-size: 14px;
}
.range-field {
    width: 100px;
}

.smallInput {
    width: 100px;
}

.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    width: 55px;
    font-size: 12px;
}
.layer-post .select-edit:not(:first-child) {
    width: 180px !important;
}
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    background: rgb(0, 99, 149) none repeat scroll 0% 0% !important;
    color: white !important;
    border: medium none;
    white-space: nowrap;
}
.edit-layer .bottom-btn .btn-secondary:focus,
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #006395 !important;
    width: auto;
    height: auto;
    //right: 15px;
    position: relative;
    border-radius: 0.25rem;
}
.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}
.overflow {
    overflow-y: scroll;
    max-height: 394px;
}
.hideText {
    margin-right: 10px;
}
.modalScreen {
    height: 85vh;
    z-index: 999999;
}
.map_arrow {
    position: absolute;
    /* left: -13px; */
    z-index: 999;
}
.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}
.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}
.rowData {
    margin-top: 6px;
}
.backtopost {
    position: absolute;
    top: -200px;
    right: 0;
}
.card-body {
    padding: 1rem;
}
.collapsed .when-opened {
    display: none;
}
.not-collapsed .when-closed {
    display: none;
}
.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}
.dateAggregate {
    margin-top: -22px;
}

.dataPost-map-box {
    height: 440px;
    width: 885px;
}

.dataPost-map-box-expanded {
    height: 440px;
}

.dataPost-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }
    #lmap1 {
        opacity: 1;
        height: 10in;
        width: 15in;
    }
}
.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}
.showHide {
    font-size: 14px;
    margin-top: 11px;
}
.zoomTo {
    color: #006395 !important;
    margin-top: 9px !important;
    padding-right: 5px !important;
    margin-right: 5px !important;
}
.project_layers {
    background-color: #f4f4f4;
    height: 44px;
}
.btn-side {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
}
.btn-zoom {
    position: absolute;
    top: 25px;
    left: 12px;
}
.btn-edit {
    position: absolute;
    top: -10px;
}
.btn-delete {
    position: absolute;
    left: 0px;
    top: 39px;
}
.e-split-btn-wrapper {
    position: relative !important;
    top: -12px !important;
    left: 50px !important;
}
.img-l {
    position: relative;
    top: -12px;
}
.e-colorpicker-wrapper *,
.e-colorpicker-container * {
    padding-left: 5px;
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .range-field {
        width: 85px;
    }

    .smallInput {
        width: 85px;
    }
}
</style>
