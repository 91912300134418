<template>
    <div style="height: 100%; width: 100%;">
        <l-map
            ref="map"
            class="mapClass"
            :options="mapOptions"
            :min-zoom="minZoom"
            :max-zoom="maxZoom"
            :zoom.sync="zoom"
            :center.sync="center"
            :class="{
                identifyCursor: isIdentifyActive,
                drawCursor:
                    [
                        'drawPolygon',
                        'drawLine',
                        'drawPoint',
                        'vectorStartPoint',
                    ].includes(activeMapTool) ||
                    activeMapTool.startsWith('measure'),
            }"
            @mousemove="updateMouseCoords"
            @ready="initializeMap"
            @click="handleClick"
            @dblclick="handleDblClick"
            @update:bounds="updateBounds"
            @update:center="updateCenter"
            @update:zoom="updateZoom"
            @locationfound="handleGeolocation"
        >
            <div class="bottomright-controls">
                <l-control-attribution
                    :position="attributionPosition"
                    :prefix="attributionPrefix"
                />
                <l-control-zoom position="bottomright">
                    <template v-slot:plusIcon
                        ><img
                            class="img-layers"
                            src="../assets/zoom-in-icon.svg"
                        />
                        <p>Zoom in</p>
                    </template>
                    <template v-slot:minusIcon
                        ><img
                            class="img-layers"
                            src="../assets/zoom-out-icon.svg"
                        />
                        <p>Zoom out</p>
                    </template>
                </l-control-zoom>
                <l-control-my-location position="bottomright" mapRef="map">
                    <template v-slot:myLocationIcon ref="getMyLocation">
                        <img
                            class="img-layers"
                            src="../assets/crosshairs-icon.svg"
                        />
                        <!-- Image to be displayed on the button-->
                        <p>Center</p>
                    </template>
                </l-control-my-location>
                <l-control-identify
                    position="bottomright"
                    :isActive="isIdentifyActive"
                    :mapLayers="wmsLayers"
                    :projLayers="identifyProjectLayers"
                    :mapZoom="zoom"
                    :enabled="isIdentifyAvailable"
                    @identifyCompleted="populateAttributesPane"
                >
                    <template v-slot:identifyIcon>
                        <img
                            v-if="isIdentifyActive"
                            class="img-layers identifyActive"
                            src="../assets/info-icon-inverted.svg"
                        />
                        <img
                            v-else
                            class="img-layers"
                            src="../assets/info-icon.svg"
                        />
                        <p :style="isIdentifyActive ? 'color:white;' : ''">
                            Identify
                        </p>
                    </template>
                </l-control-identify>
                <l-control position="bottomright">
                    <button
                        type="button"
                        ref="layersButton"
                        class="btn btn-layers"
                        @click="togglePane('layers')"
                    >
                        <img
                            v-if="!mapLayersOpen"
                            class="img-layers"
                            src="../assets/map-icon.svg"
                        />
                        <img
                            v-if="mapLayersOpen"
                            class="img-layers"
                            src="../assets/layers_arrow_white.svg"
                            style="transform: scaleX(-1);"
                        />
                        <p>Basemap</p>
                    </button>
                </l-control>
                <l-control position="bottomright">
                    <button
                        type="button"
                        ref="layersButton"
                        class="btn btn-layers"
                        @click="togglePane('addressSearch')"
                    >
                        <img
                            class="img-layers"
                            src="../assets/search-icon.svg"
                        />
                        <p>Search</p>
                    </button>
                </l-control>
            </div>
            <l-control-address-search
                position="bottomright"
                :isExpanded="paneVisibility['addressSearch']"
                @toggleSearchBar="togglePane('addressSearch')"
            ></l-control-address-search>

            <l-control-edit
                position="topright"
                :editing="isEditingGeometry"
                :editingGeometry="editGeometry"
                @updateGeometry="updateEditGeometry"
                @stopGeometryEdit="clearEditGeometry"
            >
            </l-control-edit>
            <l-control-mouse-coords :mouseCoords="mouseCoords" />

            <l-control-scale
                :unitSystem="scalebarUnits"
                v-bind:max-width="135"
            />
            <legend-pane
                ref="legendPane"
                :wmsLayers="wmsLayers"
                :bbox="mapBounds"
                :scalebarUnits="scalebarUnits"
                :wmsFirst="false"
                :zoomLevel="currentMapZoom"
                @dragStart="legendDragStart"
                @dragEnd="legendDragEnd"
            >
            </legend-pane>

            <model-b
                :bbox="mapBounds"
                :scalebarUnits="scalebarUnits"
                @dragStart="legendDragStart"
                @dragEnd="legendDragEnd"
            >
            </model-b>

            <map-layers
                ref="layersPane"
                :showPane="paneVisibility['layers']"
                :mapLayers="wmsLayers"
            >
            </map-layers>
            <div
                v-for="(item, index) in projectLyrs"
                :key="index + '_projectGeojson'"
            >
                <l-geo-json
                    :geojson="item"
                    :options="geoJsonOptions"
                    :options-style="geoJsonStyling"
                    @click="handleGeoJsonClick"
                ></l-geo-json>
            </div>
            <div
                v-for="(item, index) in postedLyrs"
                :key="index + '_postedGeojson'"
            >
                <l-geo-json
                    :visible="item.Visible"
                    :geojson="item"
                    :options="geoJsonOptions"
                    :options-style="geoJsonStyling"
                    @click="handleGeoJsonClick"
                ></l-geo-json>
            </div>
            <div
                v-for="(item, index) in projectClusterLyrs"
                :key="index + '_projectClusterDiv'"
            >
                <l-marker-cluster
                    v-bind:ref="index + '_projectGeojsonCluster'"
                    @click="handleGeoJsonClick"
                    @clusterclick="handleClusterClick"
                    :options="clusterOptions"
                >
                    <l-geo-json
                        v-bind:ref="index + '_projectClusteredLayer'"
                        :geojson="item"
                        :options="geoJsonOptions"
                        :options-style="geoJsonStyling"
                        @click="handleGeoJsonClick"
                    ></l-geo-json>
                </l-marker-cluster>
            </div>
            <div
                v-for="(item, index) in postedClusterLyrs"
                :key="index + '_postedClusterDiv'"
            >
                <l-marker-cluster
                    v-bind:ref="index + '_postedGeojsonCluster'"
                    :options="clusterOptions"
                    @click="handleGeoJsonClick"
                    @clusterclick="handleClusterClick"
                >
                    <l-geo-json
                        v-bind:ref="index + '_postedClusteredLayer'"
                        :visible="item.Visible"
                        :geojson="item"
                        :options="geoJsonOptions"
                        :options-style="geoJsonStyling"
                        @click="handleGeoJsonClick"
                    ></l-geo-json>
                </l-marker-cluster>
            </div>
            <l-wms-tile-layer
                v-for="wms in mapData.contourWMSLyrs"
                :ref="'contourWMSLayer' + wms.jobID"
                :key="wms.jobID + 10000"
                :base-url="wms.url"
                :layers="wms.layers"
                :visible="wms.visible"
                :name="wms.ContourOptions.DisplayName"
                :attribution="wms.attribution"
                :transparent="wms.transparent"
                :options="wms.options"
                :opacity="1 - wms.ContourOptions.Transparency / 100"
                :format="wms.format"
                :styles="wms.styles"
                :tileSize="wms.tileSize"
                :z-index="20"
                layer-type="overlay"
            />
            <l-tile-layer
                v-for="referenceLayer in baseMapReferences"
                :key="referenceLayer.name"
                :name="referenceLayer.name"
                :visible="selectedBasemap == 1"
                :attribution="referenceLayer.attribution"
                :url="referenceLayer.url"
                layer-type="base"
            ></l-tile-layer>
            <l-tile-layer
                :key="baseMap.name"
                :name="baseMap.name"
                :visible="baseMap.visible"
                :attribution="baseMap.attribution"
                :url="baseMap.url"
                layer-type="base"
                :options="baseMapOptions"
            ></l-tile-layer>
            <!-- WMTS requests -->
            <l-tile-layer
                v-for="wmts in WMTS_requests"
                :key="wmts.name"
                :name="wmts.name"
                :visible="wmts.visible"
                :attribution="wmts.attribution"
                :url="wmts.wmts_url"
                :tileSize="2048"
                layer-type="overlay"
                :options="{ zIndex: wmts.zIndex }"
            ></l-tile-layer>
            <!-- WMS requests -->
            <l-wms-tile-layer
                v-for="wms in WMS_requests"
                :ref="'wms-layer-' + wms.name.replace(/ /g, '')"
                :key="wms.id"
                :name="wms.name"
                :base-url="wms.url"
                :visible="wms.isChecked"
                :layers="wms.layers"
                :styles="wms.styles"
                :options="wms.options"
                :format="wms.format"
                :opacity="wms.opacity"
                :z-index="wms.zIndex"
                :tileSize="wms.tileSize"
                :transparent="wms.transparent"
                :attribution="wms.attribution"
                layer-type="overlay"
            ></l-wms-tile-layer>
            <LMarker
                v-for="item in selectedProjectsArr"
                :key="item.id"
                :lat-lng="[item.Latitude, item.Longitude]"
                :icon="selectedIcon"
                @click="addToSelectedProjects(item)"
            />
            <div v-if="showUnselectedProjects">
                <LMarker
                    v-for="item in unselectedProjects"
                    :key="item.id"
                    :lat-lng="[item.Latitude, item.Longitude]"
                    :icon="unselectedIcon"
                    @click="addToSelectedProjects(item)"
                />
            </div>
            <l-feature-group
                ref="editFeatureGroup"
                layer-type="overlay"
                name="Edit Layer"
            >
                <!-- circle is for the buffer(s) tool, circles maintain their correct radius, 
                                circlemarkers depend on the zoom level-->
                <l-circle
                    v-for="marker in bufferMarkers"
                    :key="marker.id"
                    :lat-lng="marker.geometry.coordinates"
                    :radius="parseFloat(marker.styling.SymbologySize)"
                    :color="marker.styling.PolygonBorderColor"
                    :fillColor="marker.styling.PolygonColor"
                    :dashArray="marker.styling.PolygonStyle"
                />
                <l-marker
                    v-for="marker in editVertices"
                    :key="marker.id"
                    :lat-lng="marker.geometry.coordinates"
                    :icon="marker.icon"
                    v-bind:draggable="true"
                    @drag="editVertexDrag(marker.id, $event.target._latlng)"
                    z-index="1000000"
                />
                <l-marker
                    v-if="sampleLat !== null && sampleLong !== null"
                    :lat-lng="[sampleLat, sampleLong]"
                    v-bind:draggable="true"
                    @drag="sampleMarkerDrag($event.target._latlng)"
                    z-index="1000000"
                />
                <!-- circle markers radius is in pixels, vs circles which are in meters -->
                <l-circle-marker
                    v-for="marker in drawMarkers"
                    :key="marker.id"
                    :lat-lng="marker.geometry.coordinates"
                    :radius="marker.styling.SymbologySize"
                    :fillColor="marker.styling.SymbologyColor"
                    :fillOpacity="1 - marker.styling.PolygonTransparency"
                    :color="marker.styling.SymbologyColor"
                    zIndex="1000000"
                />
                <l-polyline
                    v-for="line in drawLines"
                    :key="line.id"
                    :lat-lngs="line.geometry.coordinates"
                    :color="line.styling.PolygonBorderColor"
                    zIndex="1000000"
                />
                <l-polygon
                    v-for="polygon in drawPolygons"
                    :key="polygon.id"
                    :lat-lngs="polygon.geometry.coordinates[0]"
                    :color="polygon.styling.PolygonBorderColor"
                    :fillColor="polygon.styling.PolygonFillColor"
                    :opacity="1 - polygon.styling.PolygonTransparency"
                    zIndex="1000000"
                />
            </l-feature-group>
            <vector-features />
        </l-map>
        <ics-attribute-modal
            ref="attributePane"
            :showEditButtons="isEditingAttributes"
            @updateGeometryLayer="updateGeometryLayer"
            @deleteGeometry="refreshERLayers"
            @editGeometry="startEditGeometry"
        ></ics-attribute-modal>
        <div ref="popup" v-show="mapPopupVisible">
            <table class="table table-striped table-sm mt-3">
                <tbody
                    v-if="
                        !clickedGeometry.ThreeDModel &&
                            clickedGeometry.popupContent == undefined
                    "
                >
                    <tr
                        v-for="item in clickedGeometry.properties"
                        :key="item.value"
                        v-show="item.popup && !item.name.includes('url')"
                    >
                        <td>{{ item.name }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                    <tr v-show="clickedGeometry.hasFile">
                        <td colspan="2" class="popup-link">
                            <div
                                v-for="(item, index) in clickedGeometry.files"
                                :key="item.fileName"
                                class="d-flex flex-column"
                            >
                                <h6>{{ item.fileName }}</h6>
                                <div v-if="item.source">
                                    <div
                                        v-if="item.iframeIsLoading"
                                        class="spinner-container d-flex flex-column justify-content-center align-items-center"
                                    >
                                        <div
                                            class="spinner-border loadingIcon mb-1"
                                            role="status"
                                        ></div>
                                        <div class="loadingText">
                                            Loading...
                                        </div>
                                    </div>
                                    <div v-else>
                                        <template v-if="item.previewPath">
                                            <iframe
                                                :src="item.previewPath"
                                                class="preview-resource w-100"
                                                height="100%"
                                                ref="iframeRef"
                                                @load="
                                                    applyClassToIFrameContent()
                                                "
                                            ></iframe>

                                            <b-button
                                                class="w-50"
                                                variant="light"
                                                @click="
                                                    openDocument(
                                                        index,
                                                        'Download'
                                                    )
                                                "
                                                >Download
                                                <img
                                                    class="mb-1"
                                                    :src="downloadIconPath"
                                                    alt="Download"
                                            /></b-button>
                                            <b-button
                                                class="w-50"
                                                variant="light"
                                                ><a
                                                    :href="item.previewPath"
                                                    target="_blank"
                                                    class="btn-open"
                                                    >Open
                                                    <img
                                                        class="mb-1"
                                                        :src="
                                                            externalLinkIconPath
                                                        "
                                                        alt="Open in new tab"/></a
                                            ></b-button>
                                        </template>
                                        <template v-else>
                                            <b-button
                                                class="w-50"
                                                variant="light"
                                                @click="
                                                    openDocument(
                                                        index,
                                                        'Download'
                                                    )
                                                "
                                            >
                                                <span
                                                    v-if="
                                                        item.source == 'Blob' &&
                                                            item.previewPath ===
                                                                null
                                                    "
                                                    >Download<img
                                                        class="mb-1"
                                                        :src="downloadIconPath"
                                                        alt="Download"
                                                    />
                                                </span>
                                                <span v-else>
                                                    Open
                                                    <img
                                                        class="mb-1"
                                                        :src="
                                                            externalLinkIconPath
                                                        "
                                                        alt="Open in new tab"
                                                /></span>
                                            </b-button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <div
                    class="popup-visModel"
                    v-else-if="clickedGeometry.ThreeDModel"
                >
                    <p
                        v-if="clickedGeometry.properties[0].name == '3D Name'"
                        class="font-weight-bold text-primary text-dark"
                    >
                        {{ clickedGeometry.properties[0].value }}
                    </p>
                    <p
                        v-if="
                            (clickedGeometry.properties[0].name =
                                '3D Description')
                        "
                        class="text-info text-dark"
                    >
                        {{ clickedGeometry.properties[0].value }}
                    </p>
                    <div class="d-flex justify-content-center">
                        <b-button
                            class="w-50"
                            variant="light"
                            @click="openThreeDModal"
                        >
                            Open
                            <!-- The icon should be replaced with internal link icon -->
                            <img
                                class="mb-1"
                                src="../assets/external-link.svg"
                            />
                        </b-button>
                    </div>
                </div>
            </table>
        </div>
        <div>
            <b-modal
                ref="3Dmodal"
                class="model_style"
                hide-header
                hide-footer
                centered
                id="threeD-Modal"
            >
                <iframe :src="clickedGeometry.Url"></iframe>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { marker, latLngBounds, divIcon, Icon, icon, latLng } from 'leaflet';
import Vue from 'vue';
Vue.component('modal', {
    template: '#modal-template',
});
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { spatialFunctions } from '../utilities/spatialFunctions.js';
import LControlAddressSearch from './EmergencyManagement/FloatingAddressSearch.vue';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import LControlZoom from './CommonComponents/Mapping/LeafletZoomControl.vue';
import LControlIdentify from './CommonComponents/Mapping/LeafletIdentifyControl.vue';
import LControlMyLocation from './CommonComponents/Mapping/LeafletGetMyLocationControl.vue';
//import DrawPane from '../components/EmergencyManagement/DrawPane.vue';
import MapLayers from './EmergencyManagement/MapLayers.vue';
import LegendPane from './EmergencyManagement/Legend/LegendPane.vue';
import LControlMouseCoords from './EmergencyManagement/LeafletMouseCoordinates.vue';
import LControlScale from './EmergencyManagement/LeafletScale.vue';
import LControlEdit from './CommonComponents/Mapping/LeafletGeometryEdit.vue';
import {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LCircle,
    LCircleMarker,
    LPolygon,
    LPolyline,
    LFeatureGroup,
    LWMSTileLayer,
    LControlAttribution,
    LGeoJson,
} from 'vue2-leaflet';
import { spatialService } from '../services/spatial.services';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import IcsAttributeModal from './CommonComponents/DrawTool/ICSAttributeModal.vue';
import { projectService } from '../services/project.service';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('../assets/check-circle-icon_2X.svg'),
    iconUrl: require('../assets/check-circle-icon.svg'),
    shadowSize: [0, 0],
});
import { mapLayerFunctions } from '../utilities/mapLayerFunctions';
import { mapMarkerFunctions } from '../utilities/mapMarkerFunctions';
import { commonService } from '../services/common.service';
// import { SidebarComponent, SidebarPlugin } from '@syncfusion/ej2-vue-navigations'
import ModelB from './Dreams/ModelB.vue';
import VectorFeatures from './projects/layers/vectors/VectorFeatures.vue';

// Vue.component(SidebarPlugin.name, SidebarComponent)
export default {
    components: {
        LMap,
        LTileLayer,
        LGeoJson,
        LControl,
        LCircle,
        LCircleMarker,
        LPolyline,
        LControlAttribution,
        LPolygon,
        LFeatureGroup,
        LControlMyLocation,
        LControlZoom,
        LControlAddressSearch,
        LControlIdentify,
        LControlMouseCoords,
        LControlScale,
        MapLayers,
        LegendPane,
        'l-wms-tile-layer': LWMSTileLayer,
        'l-marker-cluster': Vue2LeafletMarkerCluster,
        LControlEdit,
        LMarker,
        IcsAttributeModal,
        ModelB,
        VectorFeatures,
    },
    name: 'MapPane',
    props: {
        isIdentifyAvailable: Boolean,
    },
    data() {
        return {
            downloadIconPath: require('@/assets/download-icon.svg'),
            externalLinkIconPath: require('@/assets/external-link.svg'),
            max_tile_zoom: 14,

            // change to update the intial extent if zooming to project point
            initialProjectZoom: 13,
            // maximum height or width of a project extent before we zoom to the project point
            maxProjectExtent: 0.5, // decimal degrees

            mapOptions: {
                preferCanvas: true,
                zoomControl: false,
                dragging: true,
                attributionControl: false,
                doubleClickZoom: false,
            },
            geosearchOptions: {
                position: 'topleft',
                provider: new OpenStreetMapProvider(),
                keepResult: true,
                showMarker: false,
                showPopup: false,
                searchLabel: 'Search Map',
            },
            mapPopupVisible: false,
            clickedGeometry: { properties: [], hasFile: false, files: [] },
            isLayersModalVisible: false,
            isEditingAttributes: false,
            mapLayersOpen: false,
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
                '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            mapBounds: {
                _northEast: { lat: 68.813939, lng: -29.970703 },
                _southWest: { lat: -11.011305, lng: -172.88086 },
            },
            mouseCoords: '',
            polylineLength: 0,
            polygonArea: 0,
            editGeometryGeom: {},
            isEditingGeometry: false,
            editArray: null,
            editGeometry: null,
            // the legend is separate since we can have it and other top-panes visible at the same time
            identifyAttributes: [],
            showRadar: false,
            zoom: 5,
            minZoom: 1,
            maxZoom: 29,
            center: { lat: 37.23278, lng: -81.958008 },
            paneVisibility: {
                addressSearch: false,
                searchAndZoom: false,
                layers: false,
            },
            // the legend is separate since we can have it and other top-panes visible at the same time
            attributionPosition: 'bottomright',
            attributionPrefix: 'GHD',
            baseMaps: [
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'Satellite_Imagery',
                    visible: true,
                    url:
                        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
            ],
            baseMapReferences: [
                {
                    name: 'transportation',
                    url:
                        'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
                    attribution: '',
                },
                {
                    name: 'boundaries_and_places',
                    url:
                        'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
                    attribution: '',
                },
            ],
            showSearchAndZoom: true,
            selectedBasemap: 0,
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 29,
            },
            fileManagerOpen: false,
            scalebarUnits: 'both', // both, metric, imperial
            openMenuTab: 'Event Overview',
            reverseLookupTable: [],
            datasetList: [],
            clusterOptions: {
                disableClusteringAtZoom: 19,
                showCoverageOnHover: false,
                chunkedLoading: true,
            },
            sideBarTool: { left: null, right: null },
            isRight: true,
            unselectedIcon: icon({
                iconUrl: require('../assets/marker.svg'),
                iconSize: [14, 14],
            }),
            selectedIcon: icon({
                iconRetinaUrl: require('../assets/check-circle-icon_2X.svg'),
                iconUrl: require('../assets/check-circle-icon.svg'),
                shadowSize: [0, 0],
                iconSize: [24, 24],
            }),
            contourStatusInterval: null,
            // there is also a local labelZoomLimit in
            //  an onEachFeatureFunction that has to be edited
            labelZoomLimit: 15,
            editableAttributes: [
                'Modeled Material',
                'Install Year (GIS)',
                'Modeled Diameter',
            ],
            hiddenAttributes: ['user_id', 'proj_id', 'version'],
            authKeyInterval: null,
            gwcTokenInterval: null,
        };
    },
    created() {
        // gets the geowebcache token for the first time
        projectService.getGeowebcacheToken().then((res) => {
            this.$store.commit(
                'emergencyManagement/setGeowebcacheToken',
                res.data
            );
        });
        /**
         * Sets up a timer to periodically fetch a Geowebcache token and update the store.
         * The timer is set to run every 24 hours starting at midnight.
         * adds 1 second to ensure that it gets the token for the new day
         */
        let intervalTime = 1000 * 60 * 60 * 24;
        let delay = this.calculateMilliSecondsUntilMidnight() + 1000;
        this.gwcTokenInterval = setTimeout(() => {
            projectService.getGeowebcacheToken().then((res) => {
                this.$store.commit(
                    'emergencyManagement/setGeowebcacheToken',
                    res.data
                );
            });
            setInterval(() => {
                projectService.getGeowebcacheToken().then((res) => {
                    this.$store.commit(
                        'emergencyManagement/setGeowebcacheToken',
                        res.data
                    );
                });
            }, intervalTime);
        }, delay);
    },
    methods: {
        ...mapActions('emergencyManagement', ['setProjectID']),
        ...mapActions('projects', {
            getAllProjects: 'getAllWithGeo',
            clearSelectedProjects: 'clearSelectedProjects',
            setSelectedProjects: 'selectProjects',
            removeSelectedProject: 'deselectProject',
        }),
        ...mapMutations('emergencyManagement', {
            setShowAttributeModal: 'updateShowAttributeModal',
        }),
        ...mapMutations('dataCollection', [
            'clearGetCoordinatesFlag',
            'setSampleLat',
            'setSampleLong',
        ]),
        ...mapMutations('common', ['setToastMessage']),
        ...mapActions('oneMap', [
            'setZoomToGeom',
            'clearZoomToGeom',
            'clearHighlightGeom',
        ]),
        ...mapMutations('oneMap', ['clearContourInProgress']),
        ...mapActions('dreams', ['setRefreshMap']),
        ...mapActions('dataCollection', ['setDCRefreshMap']),
        ...mapActions('common', ['setForceRefreshMap']),
        applyClassToIFrameContent() {
            const iframes = this.$refs.iframeRef;
            iframes.forEach((iframe) => {
                iframe.contentWindow.document.body.children[0].style.width =
                    '100%';
            });
        },
        projectVertices(vertices) {
            let projected = [];
            vertices.forEach((vertex) => {
                projected.push([
                    parseFloat(spatialFunctions.meters2lat(vertex[1])),
                    parseFloat(spatialFunctions.meters2lng(vertex[0])),
                ]);
            });
            return projected;
        },
        legendDragStart(event) {
            this.$refs.map.mapObject.dragging.disable();
        },
        legendDragEnd(event) {
            this.$refs.map.mapObject.dragging.enable();
        },
        zoomToPosted(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;

            let bufferPercentage = 10; // zoomCushion*100 is the %buffer
            let mapBounds = latLngBounds([
                [
                    spatialFunctions.correctLatitude(
                        minY - (yRange * bufferPercentage) / 100
                    ),
                    spatialFunctions.correctLongitude(
                        minX - (xRange * bufferPercentage) / 100
                    ),
                ],
                [
                    spatialFunctions.correctLatitude(
                        maxY + (yRange * bufferPercentage) / 100
                    ),
                    spatialFunctions.correctLongitude(
                        maxX + (xRange * bufferPercentage) / 100
                    ),
                ],
            ]);
            this.$refs.map.mapObject.fitBounds(mapBounds);
        },
        startEditGeometry(feature) {
            this.setShowAttributeModal(false);
            this.$store.commit('oneMap/mutateCloseSidebars', true);
            this.editGeometry = {
                id: 'E1',
                geometry: feature.geom,
                styling: {
                    PolygonBorderColor: 'green',
                    PolygonTransparency: 0.8,
                },
            };
            this.isEditingGeometry = true;
            this.isEditingAttributes = true;
        },
        refreshContourLayers() {
            this.mapData.contourWMSLyrs.forEach((lyr) => {
                this.$refs[
                    'contourWMSLayer' + lyr.jobID
                ][0].mapObject.wmsParams['editTime'] = Math.floor(
                    Date.now() / 1000
                );
                this.$refs['contourWMSLayer' + lyr.jobID][0].mapObject.redraw();
            });
        },
        refreshERLayers() {
            this.erLayerRefs.forEach((lyrRef) => {
                let fullRef = 'wms-layer-' + lyrRef;

                if (this.$refs[fullRef].length > 0) {
                    this.$refs[fullRef][0].mapObject.wmsParams[
                        'editTime'
                    ] = this.erUpdateTime;
                    this.$refs[fullRef][0].mapObject.redraw();
                }
            });
            //Refresh WMS layers for risk model
            if (this.dreamsRefreshMap) {
                this.layersStruct.forEach((lyrRef) => {
                    let fullRef = 'wms-layer-' + lyrRef.name.replace(/ /g, '');
                    if (this.$refs[fullRef]?.[0]) {
                        this.$refs[fullRef][0].mapObject.setParams(
                            { forceUpdate: Date.now() },
                            false
                        );
                    }
                });
                this.setRefreshMap(false);
            }
        },
        updateERLayerFilters() {
            this.erLayerRefs.forEach((lyrRef) => {
                let fullRef = 'wms-layer-' + lyrRef;
                this.wmsLayers.forEach((wmsLyr) => {
                    if (wmsLyr.name.replace(/ /g, '') == lyrRef) {
                        try {
                            this.$refs[
                                fullRef
                            ][0].mapObject.wmsParams.CQL_Filter =
                                wmsLyr.options.CQL_Filter;
                        } catch (e) {
                            e;
                        }
                    }
                });
                try {
                    this.$refs[fullRef][0].mapObject.redraw();
                } catch (error) {
                    error;
                }
            });
            this.$store.commit('emergencyManagement/setWMSLayersFlag', false);
        },
        updateContourLayers(jobID = -1) {
            if (jobID == -1) {
                this.contourLayerRefs.forEach((lyrRef) => {
                    this.$refs[lyrRef][0].mapObject.wmsParams[
                        'editTime'
                    ] = Math.floor(Date.now() / 1000);
                    this.$refs[lyrRef][0].mapObject.redraw();
                });
            } else {
                const lyrRef = 'contourWMSLayer' + jobID;
                this.$refs[lyrRef][0].mapObject.wmsParams[
                    'editTime'
                ] = Math.floor(Date.now() / 1000);
                this.$refs[lyrRef][0].mapObject.redraw();
            }
        },
        zoomToContourExtent(jobId) {
            mapLayerFunctions.getContoursExtent(jobId, (bounds) => {
                this.$refs.map.mapObject.fitBounds(bounds);
            });
        },
        startERDataRefresh() {
            //refresh weather data every 20 minutes
            this.erUpdateTime = Math.floor(Date.now() / 1000);
            this.refreshERData = setInterval(() => {
                spatialService
                    .hasNewDrawings(
                        this.selectedProjectsArr[0].ProjectID,
                        this.erUpdateTime
                    )
                    .then((res) => {
                        let thereIsNewData = res.data;
                        if (thereIsNewData || this.dreamsRefreshMap) {
                            this.erUpdateTime = Math.floor(Date.now() / 1000);
                            this.refreshERLayers();
                        }
                    });
            }, 30000);
        },
        clearInterval: function() {
            clearTimeout(this.refreshData);
        },
        updateBounds(bounds) {
            this.mapBounds = bounds;
            this.$store.commit('projects/updateMapExtent', bounds);
        },
        updateCenter(center) {
            this.$store.commit('projects/updateMapCenter', center);
        },
        updateZoom(zoom) {
            this.$store.commit('projects/updateMapZoom', zoom);
        },
        zoomTo(feature) {
            let bounds = latLngBounds(
                spatialFunctions.calculateBounds(feature)
            );
            this.$refs.map.mapObject.fitBounds(bounds, {
                maxZoom: 17,
            });
            this.clearZoomToGeom();
        },
        highlightGeometry(feature) {
            this.clearHighlightGeom();
            feature.styling = {
                SymbologyColor: '#d4fc16',
                SymbologySize: 3,
                PolygonColor: '#d4fc16',
                PolygonTransparency: 0.2,
            };
            switch (feature.geometry.type) {
                case 'Point':
                    feature.id = 'H' + this.drawMarkers.length;
                    this.drawMarkers.push(feature);
                    break;
                case 'LineString':
                    feature.id = 'H' + this.drawLines.length;
                    this.drawLines.push(feature);
                    break;
                case 'Polygon':
                    feature.id = 'H' + this.drawPolygons.length;
                    this.drawPolygons.push(feature);
                    break;
            }
        },
        updateEditGeometry(coordinates) {
            switch (this.editArray) {
                case 'drawMarkers':
                    this.editVertices[this.editIndex].vertices = coordinates;
                    break;
                case 'drawLines':
                    this.drawLines[this.editIndex].vertices = coordinates;
                    break;
                case 'drawPolygons':
                    this.drawPolygons[this.editIndex].vertices = coordinates;
                    break;
            }
            this.editGeometry.vertices = coordinates;
        },
        updateMouseCoords(event) {
            var lng = event.latlng.lng;
            while (lng > 180) {
                lng -= 360;
            }
            while (lng < -180) {
                lng += 360;
            }
            this.mouseCoords =
                event.latlng.lat.toFixed(6) + ' ' + lng.toFixed(6);
        },
        initializeMouseCoords() {
            this.mouseCoords = this.center[0] + ' ' + this.center[1];
        },
        initializeMap() {
            this.initializeMouseCoords();
            this.updateMapSize();
            window.addEventListener('resize', this.updateMapSize);
        },
        handleClick(event) {
            if (this.activeMapTool !== '') {
                this.mapPopupVisible = false;
                this.$store.commit('oneMap/mutateClickCoords', event.latlng);
            }
        },
        updateHasProperties() {
            let hasProperties = false;
            this.clickedGeometry.properties.forEach((property) => {
                // check that there is a name value, could have popup=true but no values displayed
                if (
                    (this.clickedGeometry.hasFile || property.popup) &&
                    property.name !== ''
                ) {
                    hasProperties = true;
                }
            });
            return hasProperties;
        },
        handleGeoJsonClick(event) {
            if (this.activeMapTool === '') {
                let layer = event.layer;
                let feature = layer.feature;
                //e is the click event, returns screen pixel numbers in x and y, also return spatial lat/long
                //feature returns the clicked geometry object, it ONLY returns the top geometry;
                this.clickedGeometry = feature;
                feature.properties.forEach((item) => {
                    if (item.name == 'Files') {
                        this.clickedGeometry.hasFile = true;
                        this.clickedGeometry.files = item.value;
                        this.clickedGeometry.files.forEach((element, index) => {
                            this.openDocument(index, 'Preview');
                        });
                    }
                    if (
                        item.value.includes(
                            'https://viewer.ctech.com/inline_viewer.html'
                        )
                    ) {
                        this.clickedGeometry.ThreeDModel = true;
                        this.clickedGeometry.Url = item.value;
                    } else {
                        this.clickedGeometry.ThreeDModel = false;
                    }
                });
                this.mapPopupVisible = true;
                var popupLatLng = event.latlng;
                if (this.clickedGeometry.geometry.type === 'Point') {
                    popupLatLng = latLng(
                        this.clickedGeometry.geometry.coordinates[1],
                        this.clickedGeometry.geometry.coordinates[0]
                    );
                }
                if (this.updateHasProperties()) {
                    this.$refs.map.mapObject.openPopup(
                        this.$refs.popup,
                        popupLatLng,
                        {
                            permanent: false,
                            sticky: false,
                            minWidth: 300,
                        }
                    );
                }
            } else {
                this.handleClick(event);
            }
        },
        handleClusterClick(event) {},
        handleDblClick(event) {
            this.$store.commit('oneMap/mutateHasDoubleClicked', true);
        },
        clearEditGeometry(geom) {
            this.updateIdentifiedGeometry(geom);
            this.editGeometry = {};
            this.isEditingGeometry = false;
        },
        editVertexDrag(id, coords) {
            const updatedVertex = {
                id: id,
                lat: coords.lat,
                lng: coords.lng,
            };
            this.$emit('editVertex/updateLatLng', updatedVertex);
        },
        sampleMarkerDrag(coords) {
            this.setSampleLat(coords.lat.toFixed(6));
            this.setSampleLong(coords.lng.toFixed(6));
        },
        // moved the math to a separate function to make it more readable and more easily callable
        getButtonMidX(buttonRef) {
            return Math.round(
                this.$refs[buttonRef][0].getBoundingClientRect().left +
                    (this.$refs[buttonRef][0].getBoundingClientRect().right -
                        this.$refs[buttonRef][0].getBoundingClientRect().left) /
                        2
            );
        },
        togglePane(triggeringPane) {
            // the legend pane is far away from the others, can just toggle it independently
            Object.entries(this.paneVisibility).map(([key, value]) => {
                // just want to toggle the calling pane, not always show or hide
                if (key != triggeringPane) {
                    this.paneVisibility[key] = false;
                    //add the active class and remove hover class
                    try {
                        this.$refs[key + 'Border'].classList.add('btn-hover');
                        this.$refs[key + 'Border'].classList.remove(
                            'btn-active'
                        );
                    } catch (e) {
                        e;
                    }
                } else {
                    this.paneVisibility[key] = !value;
                    //add the hover class and remove active class
                    try {
                        if (
                            this.$refs[key + 'Border'].classList.contains(
                                'btn-active'
                            )
                        ) {
                            this.$refs[key + 'Border'].classList.add(
                                'btn-hover'
                            );
                            this.$refs[key + 'Border'].classList.remove(
                                'btn-active'
                            );
                        } else {
                            this.$refs[key + 'Border'].classList.add(
                                'btn-active'
                            );
                            this.$refs[key + 'Border'].classList.remove(
                                'btn-hover'
                            );
                        }
                    } catch (e) {
                        e;
                    }
                }
            });
        },
        reclusterPosted() {
            if (this.postedClusterLyrs.length > 0) {
                this.$refs.postedCluster.mapObject.clearLayers();
                this.$refs.postedClusterGeoJson.forEach((layer) => {
                    if (layer.visible == true) {
                        this.$refs.postedCluster.mapObject.addLayers(
                            layer.mapObject.getLayers()
                        );
                    } else {
                        this.$refs.postedCluster.mapObject.removeLayers(
                            layer.mapObject.getLayers()
                        );
                    }
                });
            }
        },
        updateIdentifiedGeometry(geom) {
            this.identifyAttributes.forEach((result) => {
                result.Geometry.forEach((feature) => {
                    feature.properties.forEach((prop) => {
                        if (
                            prop.label == 'GeoID' &&
                            prop.value == this.drawingAttributes.geoID
                        ) {
                            feature.geometry.coordinates =
                                geom.geometry.coordinates;
                        }
                    });
                });
            });
            this.identifiedGeometry = geom;
        },
        populateAttributesPane(identifyResults) {
            this.identifyAttributes = identifyResults.filter(function(result) {
                return result !== null;
            });
            this.identifyAttributes.forEach((layer) => {
                layer.Geometry.forEach((feature) => {
                    feature.geometry.coordSys = 'WebMerc';
                    feature = spatialFunctions.webMerc2DD(feature, true);
                });
            });
            this.$refs.attributeTab.activate();

            // have to make the map change quickly to toggle the geoJson layer popups
            this.$parent.zoom++;
            this.$nextTick();
            this.$parent.zoom--;
        },
        reverseLookupQuery(layerID, featureID) {
            spatialService.reverseLookup(layerID, featureID).then((res) => {
                this.reverseLookupTable = res.data;
                this.$refs['modal-revLook'].show();
            });
        },
        zoomToProjectPoint() {
            let selectedProject = this.selectedProjectsArr[0];
            let project_lat = selectedProject.Latitude;
            let project_long = selectedProject.Longitude;
            this.$refs.map.mapObject.setView(
                [project_lat, project_long],
                this.initialProjectZoom
            );
        },
        zoomToLayers(bufferPercentage = 10, retry = true) {
            if (this.projects.length == 0) {
                if (retry) {
                    setTimeout(() => {
                        return this.zoomToLayers(bufferPercentage, retry);
                    }, 2000);
                    return;
                } else {
                    return;
                }
            }
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            if (
                this.selectedProjectsArr[0].ProjectTypeName ==
                'Contamination and remediation'
            ) {
                projectService
                    .getProjectExtent(this.selectedProjectsArr[0].ProjectID)
                    .then((res) => {
                        if (Object.keys(res.data).length) {
                            let projectExtent = res.data;
                            minX = Math.min(minX, projectExtent.minX);
                            maxX = Math.max(maxX, projectExtent.maxX);
                            minY = Math.min(minY, projectExtent.minY);
                            maxY = Math.max(maxY, projectExtent.maxY);
                            this.zoomToBounds(
                                minX,
                                minY,
                                maxX,
                                maxY,
                                bufferPercentage
                            );
                        } else {
                            Object.values(this.projects).forEach((project) => {
                                if (
                                    Object.keys(project).includes(
                                        'ProjectLayers'
                                    )
                                ) {
                                    Object.values(
                                        project.ProjectLayers
                                    ).forEach((projLayer) => {
                                        if (
                                            projLayer.Geometry &&
                                            projLayer.visible
                                        ) {
                                            projLayer.Geometry.features.forEach(
                                                (location) => {
                                                    if (
                                                        location.geometry
                                                            .type ==
                                                        'MultiPolygon'
                                                    ) {
                                                        location.geometry.coordinates[0].forEach(
                                                            (polygon) => {
                                                                polygon.forEach(
                                                                    (point) => {
                                                                        let xCoord =
                                                                            point[0];
                                                                        let yCoord =
                                                                            point[1];
                                                                        if (
                                                                            !isNaN(
                                                                                xCoord
                                                                            ) &&
                                                                            !isNaN(
                                                                                yCoord
                                                                            )
                                                                        ) {
                                                                            minX = Math.min(
                                                                                minX,
                                                                                parseFloat(
                                                                                    xCoord
                                                                                )
                                                                            );
                                                                            maxX = Math.max(
                                                                                maxX,
                                                                                parseFloat(
                                                                                    xCoord
                                                                                )
                                                                            );
                                                                            minY = Math.min(
                                                                                minY,
                                                                                parseFloat(
                                                                                    yCoord
                                                                                )
                                                                            );
                                                                            maxY = Math.max(
                                                                                maxY,
                                                                                parseFloat(
                                                                                    yCoord
                                                                                )
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                            }
                                                        );
                                                    } else if (
                                                        location.geometry
                                                            .type ==
                                                            'Polygon' ||
                                                        location.geometry
                                                            .type ==
                                                            'MultiLineString'
                                                    ) {
                                                        location.geometry.coordinates[0].forEach(
                                                            (point) => {
                                                                let xCoord =
                                                                    point[0];
                                                                let yCoord =
                                                                    point[1];
                                                                if (
                                                                    !isNaN(
                                                                        xCoord
                                                                    ) &&
                                                                    !isNaN(
                                                                        yCoord
                                                                    )
                                                                ) {
                                                                    minX = Math.min(
                                                                        minX,
                                                                        parseFloat(
                                                                            xCoord
                                                                        )
                                                                    );
                                                                    maxX = Math.max(
                                                                        maxX,
                                                                        parseFloat(
                                                                            xCoord
                                                                        )
                                                                    );
                                                                    minY = Math.min(
                                                                        minY,
                                                                        parseFloat(
                                                                            yCoord
                                                                        )
                                                                    );
                                                                    maxY = Math.max(
                                                                        maxY,
                                                                        parseFloat(
                                                                            yCoord
                                                                        )
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    } else if (
                                                        location.geometry
                                                            .type == 'Point'
                                                    ) {
                                                        let xCoord =
                                                            location.geometry
                                                                .coordinates[0];
                                                        let yCoord =
                                                            location.geometry
                                                                .coordinates[1];
                                                        if (
                                                            !isNaN(xCoord) &&
                                                            !isNaN(yCoord)
                                                        ) {
                                                            minX = Math.min(
                                                                minX,
                                                                parseFloat(
                                                                    xCoord
                                                                )
                                                            );
                                                            maxX = Math.max(
                                                                maxX,
                                                                parseFloat(
                                                                    xCoord
                                                                )
                                                            );
                                                            minY = Math.min(
                                                                minY,
                                                                parseFloat(
                                                                    yCoord
                                                                )
                                                            );
                                                            maxY = Math.max(
                                                                maxY,
                                                                parseFloat(
                                                                    yCoord
                                                                )
                                                            );
                                                        }
                                                    } else if (
                                                        location.geometry
                                                            .type ==
                                                        'LineString'
                                                    ) {
                                                        location.geometry.coordinates.forEach(
                                                            (point) => {
                                                                let xCoord =
                                                                    point[0];
                                                                let yCoord =
                                                                    point[1];
                                                                if (
                                                                    !isNaN(
                                                                        xCoord
                                                                    ) &&
                                                                    !isNaN(
                                                                        yCoord
                                                                    )
                                                                ) {
                                                                    minX = Math.min(
                                                                        minX,
                                                                        parseFloat(
                                                                            xCoord
                                                                        )
                                                                    );
                                                                    maxX = Math.max(
                                                                        maxX,
                                                                        parseFloat(
                                                                            xCoord
                                                                        )
                                                                    );
                                                                    minY = Math.min(
                                                                        minY,
                                                                        parseFloat(
                                                                            yCoord
                                                                        )
                                                                    );
                                                                    maxY = Math.max(
                                                                        maxY,
                                                                        parseFloat(
                                                                            yCoord
                                                                        )
                                                                    );
                                                                }
                                                            }
                                                        );
                                                    }
                                                }
                                            );
                                        }
                                    });
                                }
                            });
                            projectService
                                .getProjectWMSExtent(
                                    this.selectedProjectsArr[0].ProjectID
                                )
                                .then((res) => {
                                    let wmsExtent = res.data;
                                    if (Object.keys(wmsExtent).length > 0) {
                                        minX = Math.min(minX, wmsExtent.minX);
                                        maxX = Math.max(maxX, wmsExtent.maxX);
                                        minY = Math.min(minY, wmsExtent.minY);
                                        maxY = Math.max(maxY, wmsExtent.maxY);
                                    }
                                    if (minX == 180 && minY == 90) {
                                        let selectedProject = this
                                            .selectedProjectsArr[0];
                                        let project_lat =
                                            selectedProject.Latitude;
                                        let project_long =
                                            selectedProject.Longitude;
                                        this.$refs.map.mapObject.setView(
                                            [project_lat, project_long],
                                            this.initialProjectZoom
                                        );
                                    } else {
                                        this.zoomToBounds(
                                            minX,
                                            minY,
                                            maxX,
                                            maxY,
                                            bufferPercentage
                                        );
                                    }
                                });
                        }
                    });
            } else {
                // EM or ER project
                projectService
                    .getERDataExtent(this.selectedProjectsArr[0].ProjectID)
                    .then((res) => {
                        // if no er data extent, get project extent
                        if (!Object.keys(res.data).length) {
                            projectService
                                .getProjectExtent(
                                    this.selectedProjectsArr[0].ProjectID
                                )
                                .then((proj_extent_res) => {
                                    if (
                                        Object.keys(proj_extent_res.data).length
                                    ) {
                                        let projectExtent =
                                            proj_extent_res.data;
                                        minX = Math.min(
                                            minX,
                                            projectExtent.minX
                                        );
                                        maxX = Math.max(
                                            maxX,
                                            projectExtent.maxX
                                        );
                                        minY = Math.min(
                                            minY,
                                            projectExtent.minY
                                        );
                                        maxY = Math.max(
                                            maxY,
                                            projectExtent.maxY
                                        );
                                        this.zoomToBounds(
                                            minX,
                                            minY,
                                            maxX,
                                            maxY,
                                            bufferPercentage
                                        );
                                        // if no project extent: zoom to project point
                                    } else {
                                        this.zoomToProjectPoint();
                                    }
                                });
                        } else {
                            let erDataExtent = res.data;
                            minX = Math.min(minX, erDataExtent.minX);
                            maxX = Math.max(maxX, erDataExtent.maxX);
                            minY = Math.min(minY, erDataExtent.minY);
                            maxY = Math.max(maxY, erDataExtent.maxY);
                            this.zoomToBounds(
                                minX,
                                minY,
                                maxX,
                                maxY,
                                bufferPercentage,
                                true
                            );
                        }
                    });
            }
        },
        zoomToBounds(
            minX,
            minY,
            maxX,
            maxY,
            bufferPercentage,
            isERExtent = false
        ) {
            let xRange = maxX - minX;
            let yRange = maxY - minY;

            // don't want to override the zoom if it is based on the drawn features
            if (
                !isERExtent &&
                (xRange >= this.maxProjectExtent ||
                    yRange >= this.maxProjectExtent)
            ) {
                this.zoomToProjectPoint();
            } else {
                var mapBounds = latLngBounds([
                    [
                        spatialFunctions.correctLatitude(
                            minY - (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            minX - (xRange * bufferPercentage) / 100
                        ),
                    ],
                    [
                        spatialFunctions.correctLatitude(
                            maxY + (yRange * bufferPercentage) / 100
                        ),
                        spatialFunctions.correctLongitude(
                            maxX + (xRange * bufferPercentage) / 100
                        ),
                    ],
                ]);
                this.$refs.map.mapObject.fitBounds(mapBounds, { maxZoom: 16 });
            }
        },

        updateGeometryLayer(geometry) {
            switch (geometry.geomType) {
                case 'POLYGON':
                    this.drawPolygons.pop();
                    break;
                case 'LINESTRING':
                    this.drawLines.pop();
                    break;
                case 'POINT':
                    this.drawMarkers.pop();
                    break;
            }
            this.erUpdateTime = Math.floor(Date.now() / 1000);
            this.refreshERLayers();
        },
        /**
         * Updates the Geoserver authentication keys.
         * Retrieves the authentication keys from the project service based on the selected projects.
         * Dispatches an action to update the authentication keys in the emergencyManagement store module.
         * Calculates the refresh time for the authentication keys.
         * @returns {Promise<void>} A promise that resolves when the authentication keys are updated.
         */
        async updateGeoserverAuthkeys() {
            const authkeys = await projectService.getGeoserverAuthkeys(
                this.$store.getters['projects/ProjectID']
            );
            this.$store.commit('projects/setAuthKeys', authkeys.data.AuthKeys);
            if (authkeys.data.Expiry !== null) {
                this.$store.dispatch(
                    'emergencyManagement/updateAuthkeys',
                    authkeys.data
                );
                this.$store.commit(
                    'projects/setAuthKeys',
                    authkeys.data.AuthKeys
                );
                this.calculateAuthkeyRefresh(authkeys.data.Expiry);
                return authkeys.data;
            }
        },

        /**
         * Calculates the refresh interval for the authentication key, and sets the interval to refresh the authkeys
         * @param {string} authkeyExpiry - The expiration date of the authentication key.
         */
        calculateAuthkeyRefresh(authkeyExpiry) {
            if (this.authKeyInterval == null) {
                const now = new Date();
                const nowUTC = new Date(
                    now.getUTCFullYear(),
                    now.getUTCMonth(),
                    now.getUTCDate(),
                    now.getUTCHours(),
                    now.getUTCMinutes(),
                    now.getUTCSeconds()
                );
                const expirationUTC = new Date(Date.parse(authkeyExpiry));
                if (expirationUTC && nowUTC) {
                    this.authKeyInterval = setInterval(() => {
                        clearInterval(this.authKeyInterval);
                        this.authKeyInterval = null;
                        this.updateGeoserverAuthkeys();
                    }, expirationUTC - nowUTC);
                }
            }
        },

        getAllLayersDetails() {
            let projects = this.$store.state.projects.selectedProjects;
            /**
             * Fetches geoserver authentication keys for selected projects and then retrieves spatial layers for each project.
             * @param {Array} projects - The array of selected projects.
             */
            if (projects.length > 0) {
                projects.forEach((element) => {
                    mapLayerFunctions.getSpatialLayers(element.ProjectID, {
                        ver: this.version,
                        user: this.$store.state.store.posts.UserID,
                    });
                });
            }
        },

        addToSelectedProjects(item) {
            projectService.getProjectLogo(item.ProjectID).then((res) => {
                item.Logo = res.data;
                let project = this.selectedProjectsArr.find((e) => {
                    return e.ProjectName === item.ProjectName;
                });
                if (project) {
                    this.removeSelectedProject(project.ProjectID);
                } else {
                    if (!this.$store.state.projects.projSelectionMultiple) {
                        this.clearSelectedProjects();
                        this.setSelectedProjects(item);
                    } else {
                        this.setSelectedProjects(item);
                    }
                }
            });
            this.getAllLayersDetails();
        },
        updateMapSize() {
            setTimeout(() => {
                try {
                    this.$store.commit(
                        'projects/mutateMapSize',
                        this.$refs.map.mapObject._size
                    );
                } catch (e) {
                    // can we skip this?
                }
            }, 500);
        },
        openDocument(index, operation) {
            let fileName = '';
            let downloadType = '';
            let documentPath = '';
            this.clickedGeometry.properties.forEach((item) => {
                if (item.name == 'Files') {
                    fileName = item.value[index].fileName;
                    downloadType = item.value[index].source;
                    documentPath = item.value[index].path;
                    if (operation == 'Download') {
                        commonService.downloadDocument(
                            fileName,
                            downloadType,
                            documentPath
                        );
                        //item.value[index].iframeIsLoading = false;
                    } else if (operation == 'Preview') {
                        if (!item.value[index].previewPath) {
                            item.value[index].iframeIsLoading = true;
                            commonService
                                .downloadLink(
                                    fileName,
                                    downloadType,
                                    documentPath
                                )
                                .then((previewPath) => {
                                    item.value[index].previewPath = previewPath;
                                    item.value[index].iframeIsLoading = false;
                                });
                        }
                    }
                }
            });
        },
        openThreeDModal() {
            this.$refs['3Dmodal'].show();
        },
        forceMapUpdate() {
            this.layersStruct.forEach((lyrRef) => {
                if (lyrRef.name.includes('Emergency Response')) {
                    let fullRef = 'wms-layer-' + lyrRef.name.replace(/ /g, '');
                    if (this.$refs[fullRef]?.[0]) {
                        this.$refs[fullRef][0].mapObject.setParams(
                            { forceUpdate: Date.now() },
                            false
                        );
                    }
                }
            });
        },
        handleGeolocation(evt) {
            if (this.getCoordinatesFlag) {
                this.setSampleLat(evt.latitude);
                this.setSampleLong(evt.longitude);
                this.clearGetCoordinatesFlag();
            }
        },
        /**
         * Calculates the number of milliseconds until midnight.
         *
         * @returns {number} The number of milliseconds until midnight.
         */
        calculateMilliSecondsUntilMidnight() {
            // get the current time
            const now = new Date();

            const nowUTC = new Date(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate(),
                now.getUTCHours(),
                now.getUTCMinutes(),
                now.getUTCSeconds()
            );
            const midnightUTC = new Date(
                now.getUTCFullYear(),
                now.getUTCMonth(),
                now.getUTCDate() + 1,
                0,
                0,
                0
            );
            const millisecondsUntilMidnight = midnightUTC - nowUTC;
            return millisecondsUntilMidnight;
        },
        /**
         * Converts milliseconds to time string in the format "HH:mm:ss".
         * This is exclusively for debugging purposes.
         * @param {number} milliseconds - The number of milliseconds to convert.
         * @returns {string} - The time string in the format "HH:mm:ss".
         */
        convertMillisecondsToTime(milliseconds) {
            const seconds = Math.floor((milliseconds / 1000) % 60);
            const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
            const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

            const timeString = `${hours
                .toString()
                .padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

            return timeString;
        },
        arraysAreEqual(array1, array2) {
            // Check if the arrays are the same length
            if (array1.length !== array2.length) {
                return false;
            }
            // Check each element
            for (let i = 0; i < array1.length; i++) {
                if (array1[i].ProjectID !== array2[i].ProjectID) {
                    return false; // Found elements that are not the same
                }
            }
            // No differences found
            return true;
        },
    },
    mounted() {
        setTimeout(() => {
            this.getAllProjects();
            this.setShowAttributeModal(false); // I cannot find where this is set to true, so we set it to false here
            document.getElementById('app').style.paddingBottom = 0;
            this.getAllLayersDetails();
        }, 500);
        mapLayerFunctions.dateFilterWMSLayers();
        this.startERDataRefresh();
        this.zoomToLayers(0, false);
        this.forceMapUpdate();
    },
    beforeDestroy() {
        clearInterval(this.refreshERData);
        clearInterval(this.gwcTokenInterval);
        clearInterval(this.authKeyInterval);
    },

    watch: {
        // when projects are added, zoom to all project layers
        projectLyrCount(newCount, oldCount) {
            if (newCount > oldCount && newCount > 0) {
                this.zoomToLayers(10);
            }
        },
        postedLyrCount(newCount, oldCount) {
            if (newCount > oldCount) {
                this.zoomToPosted(
                    this.mapData.geojsonLayers.posted[newCount - 1].Geometry
                );
            }
        },
        updateWMSLayersFlag(newVal) {
            if (newVal) {
                this.updateERLayerFilters();
                this.$store.commit(
                    'emergencyManagement/setWMSLayersFlag',
                    false
                );
            }
        },
        zoomMapExtent(newExtent, oldExtent) {
            var mapBounds = latLngBounds(newExtent);
            var mapExtent = [mapBounds._northEast, mapBounds._southWest];
            this.$refs.map.mapObject.fitBounds(mapExtent);
        },
        zoom(newZoomLevel, oldZoomLevel) {
            // only recluster when we pass the zoom threshold for labels to appear/hide
            if (
                (newZoomLevel >= this.labelZoomLimit &&
                    oldZoomLevel < this.labelZoomLimit) ||
                (newZoomLevel <= this.labelZoomLimit - 1 &&
                    oldZoomLevel > this.labelZoomLimit - 1)
            ) {
                // have to delay long enough that the computed style updates first
                setTimeout(() => {
                    this.$store.commit('oneMap/mutateReclusterProject', true);
                    this.reclusterPosted();
                }, 50);
            }
        },
        zoomToGeom(newGeom, oldGeom) {
            // check that there is geometry and it is not what we already zoomed to and highlighted
            if (newGeom != '') {
                // && newGeom != oldGeom) {
                this.zoomTo(newGeom);
                this.highlightGeometry(newGeom);
                // clear the highlight after 3 seconds
                setTimeout(() => {
                    this.clearHighlightGeom();
                }, 3000);
            }
        },
        reclusterProject(newVal) {
            if (newVal && Object.keys(this.projectClusterLyrs).length > 0) {
                Object.keys(this.projectClusterLyrs).forEach((key) => {
                    // remove the layer from the cluster, then add it back
                    this.$refs[
                        key + '_projectGeojsonCluster'
                    ][0].mapObject.clearLayers();
                    this.$refs[
                        key + '_projectGeojsonCluster'
                    ][0].mapObject.addLayers(
                        this.$refs[
                            key + '_projectClusteredLayer'
                        ][0].mapObject.getLayers()
                    );
                });
            }
            this.$store.commit('oneMap/mutateReclusterProject', false);
        },
        zoomToExtent(newExtent) {
            if (newExtent) {
                this.$refs.map.mapObject.fitBounds(newExtent);
            }
        },
        contourInProgress(newJobId) {
            if (newJobId) {
                this.contourStatusInterval = setInterval(() => {
                    projectService.getContourStatus(newJobId).then((res) => {
                        if (res.data.status == 'completed') {
                            this.clearContourInProgress();
                            clearInterval(this.contourStatusInterval);
                            this.zoomToContourExtent(newJobId);
                            this.setToastMessage({
                                queueService: 'Contour',
                                queueState: 'complete',
                                queueMessage: 'Completed!',
                                visible: true,
                            });
                            this.updateContourLayers(newJobId);
                        } else if (res.data.status == 'error') {
                            // should probably have some error handling here
                            this.clearContourInProgress();
                            this.setToastMessage({
                                queueService: 'Contour',
                                queueState: 'error',
                                queueMessage: 'Error.',
                                visible: true,
                            });
                        } else {
                            // progress or queued, don't really have to do anything
                        }
                    });
                }, 1000);
            } else {
                try {
                    clearInterval(this.contourStatusInterval);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        selectedProjectsArr(newArr, oldArr) {
            if (!this.arraysAreEqual(newArr, oldArr) && newArr.length > 0) {
                newArr.forEach((element) => {
                    mapLayerFunctions.getSpatialLayers(element.ProjectID);
                });
                this.$store.state.projectLayers.projectLayersReady = false;
                this.selectedBasemap = newArr[0].DefaultBaseMapName
                    ? this.baseMaps.findIndex(
                          (item) => newArr[0].DefaultBaseMapName === item.name
                      )
                    : 0;
            } else {
                //
            }
        },

        projectLayerAdded(newVal) {
            if (newVal) {
                this.forceMapUpdate();
            }
        },
        getCoordinatesFlag(newVal) {
            if (newVal) {
                this.$refs.map.mapObject.locate({
                    setView: true,
                    maxZoom: 15,
                    enableHighAccuracy: true,
                });
            }
        },
        dataCollectionRefreshMap(newVal) {
            if (newVal) {
                this.layersStruct.forEach((lyrRef) => {
                    let fullRef = 'wms-layer-' + lyrRef.name;
                    if (this.$refs[fullRef]?.[0]) {
                        this.$refs[fullRef][0].mapObject.setParams(
                            { forceUpdate: Date.now() },
                            false
                        );
                    }
                });
                this.setDCRefreshMap(false);
            }
        },
        setRefreshMap(newVal) {
            if (newVal) {
                this.layersStruct.forEach((lyrRef) => {
                    let fullRef = 'wms-layer-' + lyrRef.name.replace(/ /g, '');
                    if (this.$refs[fullRef]?.[0]) {
                        this.$refs[fullRef][0].mapObject.setParams(
                            { forceUpdate: Date.now() },
                            false
                        );
                    }
                });
                this.setRefreshMap(false);
            }
        },
        getForceRefreshMap(newVal) {
            if (newVal) {
                this.layersStruct.forEach((lyrRef) => {
                    if (lyrRef.name.includes('Emergency Response')) {
                        let fullRef =
                            'wms-layer-' + lyrRef.name.replace(/\s/g, '');
                        if (this.$refs[fullRef]?.[0]) {
                            this.$refs[fullRef][0].mapObject.setParams(
                                { forceUpdate: Date.now() },
                                false
                            );
                        }
                    }
                });
                this.setForceRefreshMap(false);
            }
        },
        updateContourLayersFlag(jobID) {
            if (jobID > 0) {
                console.log('refreshing contour layers');
                this.updateContourLayers(jobID);
                this.$store.commit('projects/setUpdateContourLayersFlag', -1);
            }
        },
    },
    computed: {
        showUnselectedProjects() {
            if (this.projectsPanelOpen == true) {
                return true;
            } else if (this.selectedProjectsArr.length == 0) {
                return true;
            } else {
                return false;
            }
        },
        ...mapState('emergencyManagement', {
            drawingAttributes: 'drawingAttributes',
            updateWMSLayersFlag: 'updateWMSLayersFlag',
            layersStruct: 'layersStructValues',
            geowebcacheToken: 'geowebcacheToken',
        }),
        ...mapState('dataCollection', [
            'getCoordinatesFlag',
            'sampleLat',
            'sampleLong',
        ]),
        ...mapState('projects', {
            allProjects: (state) => state.projectsWithGeo,
            mapData: (state) => state.mapData,
            mapSize: (state) => state.mapData.mapSize,
            selectedProjectsArr: (state) => state.selectedProjects,
            updateContourLayersFlag: (state) => state.updateContourLayersFlag,
        }),
        ...mapState('projectLayers', {
            projects: (state) => state.projects,
            projectLayerAdded: (state) => state.projectLayerAdded,
        }),
        ...mapState('oneMap', [
            'activeMapTool',
            'bufferMarkers',
            'drawMarkers',
            'drawLines',
            'drawPolygons',
            'editVertices',
            'drawingProjectID',
            'zoomToGeom',
            'reclusterProject',
            'zoomToExtent',
            'contourInProgress',
            'editIndex',
        ]),
        ...mapState({
            projectLyrs: (state) => state.projectLayers.projectLyrs,
            projectClusterLyrs: (state) =>
                state.projectLayers.projectClusterLyrs,
        }),
        ...mapGetters('emergencyManagement', {
            erLayerRefs: 'getERLayerNames',
        }),
        ...mapGetters('projects', [
            'postedLyrCount',
            'postedLyrs',
            'postedClusterLyrs',
        ]),
        ...mapGetters('projectLayers', [
            'projectLyrCount',
            'identifyProjectLayers',
        ]),
        ...mapGetters('projects', {
            contourLayerRefs: 'getContourLayerRefs',
        }),
        ...mapState('oneMap', {
            projectsPanelOpen: (state) => state.projectsPanelOpen,
        }),
        ...mapGetters('dreams', {
            version: 'version',
            dreamsModel: 'dreamsModel',
            dreamsRefreshMap: 'dreamsRefreshMap',
        }),
        ...mapGetters('dataCollection', {
            dataCollectionRefreshMap: 'dataCollectionRefreshMap',
        }),
        ...mapGetters('common', {
            getForceRefreshMap: 'getForceRefreshMap',
        }),
        currentMapZoom() {
            return this.zoom;
        },
        baseMap: function() {
            return this.baseMaps[this.selectedBasemap];
        },
        isIdentifyActive() {
            return this.activeMapTool.startsWith('identify');
        },
        wmsLayers: function() {
            var layers = [
                {
                    url:
                        'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/wms',
                    name: 'NOAA Radar Base Reflectivity',
                    isChecked: this.showRadar,
                    format: 'image/png',
                    layers: 'conus_bref_qcd',
                    transparent: true,
                    attribution: 'National Weather Service',
                    opacity: 0.6,
                },
            ];
            try {
                this.layersStruct.forEach((layer) => {
                    if (layer.layers != '' && layer.url != '') {
                        layers.push(layer);
                    }
                });
            } catch (e) {
                return [];
            }
            return layers;
        },
        geoJsonStyling() {
            return (feature, layer) => {
                let outputStyle = {};
                var polygonDashArray = '';
                var polylineDashArray = '';
                if (feature.styling.PolygonStyle == 'Dashed') {
                    polygonDashArray = '4 4';
                } else if (feature.styling.PolygonStyle == 'Dotted') {
                    polygonDashArray = '1 2';
                }
                if (feature.styling.PolygonStyle == 'Dashed') {
                    polylineDashArray = '4 4';
                } else if (feature.styling.PolygonStyle == 'Dotted') {
                    polylineDashArray = '1 2';
                }
                if (
                    ['Polygon', 'MultiPolygon'].includes(feature.geometry.type)
                ) {
                    outputStyle = {
                        weight: parseInt(feature.styling.PolygonWidth),
                        color: feature.styling.PolygonBorderColor,
                        opacity: 1 - feature.styling.PolygonTransparency / 100,
                        fillColor: feature.styling.PolygonColor,
                        fillOpacity:
                            0.5 *
                            (1 - feature.styling.PolygonTransparency / 100),
                        dashArray: polygonDashArray,
                    };
                    return outputStyle;
                } else if (
                    ['LineString', 'MultiLineString'].includes(
                        feature.geometry.type
                    )
                ) {
                    outputStyle = {
                        weight: parseInt(feature.styling.LineWidth),
                        color: feature.styling.LineColor,
                        opacity: feature.styling.Opacity / 100,
                        dashArray: polylineDashArray,
                    };
                    return outputStyle;
                }
            };
        },
        onEachFeatureFunction() {
            let v = this;
            // using a new variable because the return function callback below sometimes
            //  has scope issues getting the this.mapData
            let mapZoom = this.currentMapZoom;
            function makeLabelStyle(styling) {
                var tooltipStyle = '';
                if (styling.FontSize) {
                    tooltipStyle += 'font-size: ' + styling.FontSize + 'pt;';
                }
                switch (styling.FontStyle) {
                    case 'Bold':
                        tooltipStyle += 'font-weight: bold;';
                        break;
                    case 'Italic':
                        tooltipStyle += 'font-style: italic;';
                        break;
                }
                switch (styling.FontType) {
                    case 'Arial':
                        tooltipStyle += 'font-family: Arial, sans-serif;';
                        break;
                    case 'Helvetica':
                        tooltipStyle += 'font-family: Helvetica, sans-serif;';
                        break;
                    case 'Times New Roman':
                        tooltipStyle +=
                            'font-family: "Times New Roman", Times, serif;';
                        break;
                    case 'Georgia':
                        tooltipStyle += 'font-family: Georgia;';
                        break;
                    case 'Lucida Console':
                        tooltipStyle += 'font-family: "Lucida Sans";';
                        break;
                    case 'Courier New':
                        tooltipStyle +=
                            'font-family: "Courier New", Courier, monospace;';
                        break;
                }
                tooltipStyle += 'color: ' + styling.FontColor + ';';
                tooltipStyle += 'opacity: ' + styling.Opacity / 100 + ';';
                return tooltipStyle;
            }
            return (feature, layer) => {
                let labelDisplay = '';
                let popupDisplay = `<table class="table table-striped table-sm mt-3">
                            <tbody>`;
                feature.properties.forEach((property) => {
                    if (
                        property.label &&
                        ((mapZoom >= this.labelZoomLimit &&
                            feature.geometry.type === 'Point') ||
                            (feature.geometry.type != 'Point' && mapZoom >= 10))
                    ) {
                        labelDisplay += `<span style='${makeLabelStyle(
                            feature.styling
                        )}'>${property.value}</span>`;
                    }
                    if (property.popup) {
                        popupDisplay += `<tr>
                                <td>${property.name}</td>
                                <td>${property.value}</td>
                            </tr>`;
                    }
                });
                var tooltipPosition = '';
                var tooltipOffset = [0, 0]; // x, y
                switch (feature.styling.LabelPosition) {
                    case 'topLeft':
                        tooltipPosition = 'left';
                        tooltipOffset = [0, -20]; // x, y
                        break;
                    case 'topCenter':
                        tooltipPosition = 'top';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'topRight':
                        tooltipPosition = 'right';
                        tooltipOffset = [0, -20]; // x, y
                        break;
                    case 'middleLeft':
                        tooltipPosition = 'left';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'middleCenter':
                        tooltipPosition = 'center';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'middleRight':
                        tooltipPosition = 'right';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'bottomLeft':
                        tooltipPosition = 'left';
                        tooltipOffset = [0, 20]; // x, y
                        break;
                    case 'bottomCenter':
                        tooltipPosition = 'bottom';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'bottomRight':
                        tooltipPosition = 'right';
                        tooltipOffset = [0, 20]; // x, y
                        break;
                }
                popupDisplay += `  </tbody>
                        </table>`;
                if (!this.isIdentifyActive) {
                    if (labelDisplay) {
                        layer.bindTooltip(labelDisplay, {
                            className: 'leafletLabelClass',
                            permanent: true,
                            sticky: true,
                            direction: tooltipPosition,
                            offset: tooltipOffset,
                        });
                    }
                    // layer.bindPopup(v.$refs.popup, {
                    //     permanent: false,
                    //     sticky: false,
                    //     minWidth: 300,
                    // });
                }
            };
        },
        geoJsonOptions() {
            let v = this;
            // using a new variable because the return function callback below sometimes
            //  has scope issues getting the this.mapData
            let mapZoom = this.currentMapZoom;
            function makeLabelStyle(styling) {
                var tooltipStyle = '';
                if (styling.FontSize) {
                    tooltipStyle += 'font-size: ' + styling.FontSize + 'pt;';
                }
                switch (styling.FontStyle) {
                    case 'Bold':
                        tooltipStyle += 'font-weight: bold;';
                        break;
                    case 'Italic':
                        tooltipStyle += 'font-style: italic;';
                        break;
                }
                switch (styling.FontType) {
                    case 'Arial':
                        tooltipStyle += 'font-family: Arial, sans-serif;';
                        break;
                    case 'Helvetica':
                        tooltipStyle += 'font-family: Helvetica, sans-serif;';
                        break;
                    case 'Times New Roman':
                        tooltipStyle +=
                            'font-family: "Times New Roman", Times, serif;';
                        break;
                    case 'Georgia':
                        tooltipStyle += 'font-family: Georgia;';
                        break;
                    case 'Lucida Console':
                        tooltipStyle += 'font-family: "Lucida Sans";';
                        break;
                    case 'Courier New':
                        tooltipStyle +=
                            'font-family: "Courier New", Courier, monospace;';
                        break;
                }
                tooltipStyle += 'color: ' + styling.FontColor + ';';
                tooltipStyle += 'opacity: ' + styling.Opacity / 100 + ';';
                return tooltipStyle;
            }

            return {
                onEachFeature: this.onEachFeatureFunction,
                pointToLayer: function(feature, latlng) {
                    let labelDisplay = '';
                    let popupDisplay = `<table class="table table-striped table-sm mt-3">
                            <tbody>`;
                    feature.properties.forEach((item) => {
                        // have to declare here, cannot access 'this.' namespace in this function at this time
                        let labelZoomLimit = 15;
                        if (item.label && mapZoom >= labelZoomLimit) {
                            labelDisplay += `<span style='${makeLabelStyle(
                                feature.styling
                            )}'>${item.value}</span>`;
                        }
                        if (item.popup) {
                            popupDisplay += `<tr>
                                <td>${item.name}</td>
                                <td>${item.value}</td>
                            </tr>`;
                        }
                    });
                    popupDisplay += `  </tbody>
                        </table>`;

                    var tooltipPosition = '';
                    var tooltipOffset = [0, 0]; // x, y
                    switch (feature.styling.LabelPosition) {
                        case 'topLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, -20]; // x, y
                            break;
                        case 'topCenter':
                            tooltipPosition = 'top';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'topRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, -20]; // x, y
                            break;
                        case 'middleLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'middleCenter':
                            tooltipPosition = 'center';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'middleRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'bottomLeft':
                            tooltipPosition = 'left';
                            tooltipOffset = [0, 20]; // x, y
                            break;
                        case 'bottomCenter':
                            tooltipPosition = 'bottom';
                            tooltipOffset = [0, 0]; // x, y
                            break;
                        case 'bottomRight':
                            tooltipPosition = 'right';
                            tooltipOffset = [0, 20]; // x, y
                            break;
                    }
                    return marker(latlng, {
                        icon: divIcon({
                            html: mapMarkerFunctions.getIcon(
                                feature.styling.Symbology,
                                feature.styling.SymbologyColor,
                                feature.styling.PointOutlineColor
                            ),
                            iconAnchor: mapMarkerFunctions.getIconAnchor(
                                feature.styling.Symbology,
                                feature.styling.PointOutlineColor
                            ),
                            className:
                                'ThisHasToBeSomethingToHideTheDefaultShadow',
                        }),
                        opacity: feature.styling.Opacity / 100,
                    })
                        .bindTooltip(labelDisplay, {
                            permanent: true,
                            direction: tooltipPosition,
                            className: 'leafletLabelClass',
                            offset: tooltipOffset,
                        })
                        .openTooltip();
                    // .bindPopup(popupDisplay, {
                    //     permanent: false,
                    //     sticky: false,
                    //     autoPanPaddingTopLeft: [20, 20],
                    //     maxWidth: 400,
                    //     minWidth: 50,
                    //     className: 'leafletPopupClass',
                    // });
                },
            };
        },
        unselectedProjects() {
            if (this.allProjects.items) {
                return this.allProjects.items.filter(
                    (a) =>
                        !this.selectedProjectsArr
                            .map((b) => b.ProjectID)
                            .includes(a.ProjectID)
                );
            } else {
                return [];
            }
        },
        WMS_requests: function() {
            var layers = [
                {
                    url:
                        'https://opengeo.ncep.noaa.gov/geoserver/conus/conus_bref_qcd/wms',
                    name: 'NOAA Radar Base Reflectivity',
                    isChecked: this.showRadar,
                    format: 'image/png',
                    layers: 'conus_bref_qcd',
                    transparent: true,
                    attribution: 'National Weather Service',
                    opacity: 0.6,
                },
            ];
            try {
                this.layersStruct.forEach((layer) => {
                    if (
                        layer.url &&
                        layer.isChecked &&
                        (this.zoom > this.max_tile_zoom ||
                            (layer.minZoom <= this.zoom &&
                                this.zoom <= this.max_tile_zoom &&
                                layer.externalCache == false))
                    ) {
                        if (layer.internalCache) {
                            layers.push({
                                name: layer.name,
                                url: layer.url,
                                layers: layer.layers,
                                isChecked: layer.isChecked,
                                attribution: layer.attribution,
                                transparent: layer.transparent,
                                maxZoom: layer.maxZoom,
                                opacity: layer.opacity,
                                format: layer.format,
                                styles: layer.styles,
                                tileSize: 512,
                                zIndex: layer.zIndex,
                                minZoom: layer.minZoom,
                                options: {
                                    CQL_Filter: layer.options.CQL_Filter,
                                    authkey: layer.options.authkey,
                                    editTime: layer.options.editTime,
                                    height: 512,
                                    width: 512,
                                    tileSize: 512,
                                    maxNativeZoom: layer.options.maxNativeZoom,
                                    maxZoom: layer.options.maxZoom,
                                    tiled: layer.internalCache,
                                },
                            });
                        } else {
                            layers.push(layer);
                        }
                    }
                });
            } catch (e) {
                return [];
            }
            return layers;
        },
        WMTS_requests: function() {
            var layers = [];
            // No tile requests past zoom level 15
            if (this.zoom > this.max_tile_zoom) {
                return [];
            }
            // Add requests for tile layers
            this.layersStruct.forEach((layer) => {
                if (
                    layer.layers != '' &&
                    layer.url != '' &&
                    layer.isChecked &&
                    layer.externalCache &&
                    layer.minZoom <= this.zoom &&
                    this.zoom <= layer.maxZoom
                ) {
                    layer.wmts_url =
                        'https://geowebcache.adapt.ghd-digital.com/geowebcache/service/wmts/rest/' +
                        layer.layers +
                        '/' +
                        layer.styles +
                        '/' +
                        'EPSG:3857:2048/EPSG:3857:2048:{z}/{y}/{x}' +
                        '?format=' +
                        layer.format +
                        '&token=' +
                        this.geowebcacheToken;
                    layers.push(layer);
                }
            });
            return layers;
        },
    },
};
</script>

<style scoped>
/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: 0.25s;
}
#leftToolBar {
    transition: 0.25s;
}
#rightToolBar {
    transition: 0.25s;
}
.mapClass {
    z-index: 0; /* I needed this to be 0 to draw panes on top of it */
    cursor: grab;
}
.btn-layers {
    background-color: white;
    width: 62px;
    height: 62px;
}
.btn-white {
    background-color: white;
    width: 62px;
    height: 62px;
}
.identifyActive {
    background-color: black;
}
.drawCursor {
    cursor: crosshair !important;
}
.identifyCursor {
    cursor: pointer !important;
}

.btn-open {
    color: #212529;
}
.spinner-container {
    width: 291.41px;
    height: 200px;
}
iframe.preview-resource img {
    width: 150px;
}

.iframe-content-class {
    width: 150px;
}
</style>

<style>
/* this has to be outside of the scoped style, I don't know why, it just has to */

.mapClass .leaflet-control-attribution {
    font-size: 8px !important;
}
.powerbiButton img {
    height: 30px;
    width: 30px;
}
.full-height #topToolbar-tools .printButton {
    border: none !important;
    padding: 6px 12px !important;
}
.mapClass .leaflet-bottom .btn,
.mapClass .leaflet-top .btn {
    padding: 0 !important;
}
.mapClass .leaflet-bottom button p,
.mapClass .leaflet-top button p {
    font: normal normal normal 10px/13px IBM Plex Sans;
    letter-spacing: 0px;
    color: #1d1d1d;
}
.mapClass .leaflet-bottom .btn img,
.mapClass .leaflet-top .btn img {
    margin-bottom: 8px;
}
.mapClass div.leaflet-control-attribution.leaflet-control {
    margin-bottom: 10px;
}
.mapClass .img-layers {
    height: 24px;
    width: 24px;
}
#threeD-Modal .modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}

#threeD-Modal .modal-dialog .modal-content {
    height: 85vh;
}

#threeD-Modal .modal-body iframe {
    width: 100%;
    height: 100%;
}

#threeD-Modal .modal-dialog .modal-content .modal-body {
    padding: 0;
}

@media (min-width: 576px) {
    #threeD-Modal .modal-dialog {
        min-width: 85vw;
    }
}
.popup-link {
    background-color: white;
    border: none !important;
    text-align: center;
}
.mapClass .leaflet-popup-tip {
    box-shadow: none !important;
}
</style>
