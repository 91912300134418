<template>
    <div>
        <div ref="successToastMsg" class="successToastMsg" v-if="showSuccess">
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/success_tick.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left pr-0">
                    <p class="toast-head" v-if="messageTitle">
                        {{ messageTitle }}
                    </p>
                    <p class="toast-head" v-else>Success</p>
                    <p class="toast-p">{{ successToastMessage }}</p>
                </b-col>
                <b-col cols="2" class="float-left text-center pl-0">
                    <img
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                        @click="closeSuccessToast"
                    />
                </b-col>
            </b-col>
        </div>

        <div
            ref="failureToastMsg"
            class="failureToastMsg pb-3"
            v-if="showFailure"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/failure_cross.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left">
                    <p class="toast-head">Failure</p>
                    <p class="toast-p">{{ failureToastMessage }}</p>
                </b-col>
                <b-col cols="1" class="float-left text-center">
                    <img
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                        @click="closeFailureToast"
                    />
                </b-col>
            </b-col>
        </div>

        <div
            ref="failureToastMsg"
            class="failureToastMsg pb-3"
            v-if="showError"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/failure_cross.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left">
                    <p class="toast-head">Error</p>
                    <p class="toast-p">{{ errorToastMessage }}</p>
                </b-col>
                <b-col cols="1" class="float-left text-center">
                    <img
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                        @click="closeFailureToast"
                    />
                </b-col>
            </b-col>
        </div>

        <div
            ref="connectingToastMsg"
            class="connectingToastMsg pb-3"
            v-if="showConnecting"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/connecting_icon.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left">
                    <p class="toast-head">Connection status</p>
                    <p class="toast-p">{{ connectingToastMessage }}</p>
                </b-col>
                <b-col cols="1" class="float-left text-center">
                    <img
                        @click="closeConnectingToast"
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                    />
                </b-col>
            </b-col>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ToastMessages',

    data() {
        return {
            // showConnecting: false,
        };
    },

    props: {
        showSuccess: {
            type: Boolean,
            default: false,
        },
        showFailure: {
            type: Boolean,
            default: false,
        },
        showConnecting: {
            type: Boolean,
            default: false,
        },
        successToastMessage: String,
        failureToastMessage: String,
        connectingToastMessage: String,
        messageTitle: String,
        showError: {
            type: Boolean,
            default: false,
        },
        errorToastMessage: String,
    },
    methods: {
        showSample() {},
        showFailureToastMessage() {
            this.$refs['failureToastMsg'].show();
        },
        closeConnectingToast() {
            this.showConnecting = false;
        },
        closeFailureToast() {
            this.showFailure = false;
        },
        closeSuccessToast() {
            this.showSuccess = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.successToastMsg {
    border-left: 6px solid #00db52;
}
.failureToastMsg {
    border-left: 6px solid #d23037;
}
.connectingToastMsg {
    border-left: 6px solid #933d9b;
}
.failureToastMsg,
.successToastMsg,
.connectingToastMsg {
    font-weight: normal;
    position: fixed;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 98px;
    width: 486px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    // padding: 20px 20px 0px 10px;
    box-shadow: 1px 1px 1px 1px #c6c6c6 !important;
}
.failureToastMsg {
    height: auto;
    white-space: pre-wrap;
}
.toast-head {
    color: #1d1d1d !important;
    display: block !important;
    font-size: 18px;
}
.toast-p {
    color: #626262 !important;
    display: block !important;
    font-size: 16px;
}
.toastImg {
    margin-top: 1rem;
}
.pointer {
    cursor: pointer;
}
.float-right img {
    position: relative;
    right: 10px;
}
</style>
