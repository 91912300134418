<template lang="html">
    <b-modal
        v-bind:no-close-on-backdrop="false"
        ref="permission-modal"
        content-class="model_style"
        hide-header
        hide-footer
        centered
    >
        <div class="d-block text-center">
            <div class="text-right cursor-pointer">
                <img
                    class="rounded-circle"
                    src="../assets/close_icon.svg"
                    @click="hidePermissionModal"
                />
            </div>
            <h5><img src="../assets/alert-circle.svg" /></h5>
            <h2 class="mt-2">Access denied</h2>
            <p>
                You do not have permission to view the requested content.
                Contact your administrator to obtain access.
            </p>
        </div>
    </b-modal>
</template>

<script>
// import Vue from "vue";
import router from '../../router';
export default {
    name: 'AccessPermissionModal',
    props: [],
    mounted: function() {},
    data() {
        return {};
    },
    methods: {
        hidePermissionModal() {
            router.push(`/dashboard`);
            this.$refs['permission-modal'].hide();
        },
        showPermissionModal() {
            this.$refs['permission-modal'].show();
            setTimeout(() => {
                document.querySelector('.show').style.background = '#8c8989';
            }, 50);
        },
    },
    computed: {},
};
</script>

<style></style>
