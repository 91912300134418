<template>
    <b-container>
        <div
            class="pl-2 mb-3"
            v-for="att in attributes"
            :key="att.ToolIdsavedToolAttributeArray"
        >
            <div v-if="att.ToolAttributes.length">
                <h4>{{ att.ToolName }}</h4>
                <b-form-checkbox
                    class="checkbox"
                    :checked="isChecked(att.ToolId)"
                    @change="savedToolData(att.ToolId)"
                    >Assign tool to dataset</b-form-checkbox
                >
                <hr />
                <div class="division col-md-6 mt-2">
                    <h5 v-if="att.ToolAttributes.length">Defaults</h5>
                    <div
                        v-for="tool in att.ToolAttributes"
                        :key="tool.ToolAttributes"
                    >
                        <CustomFloatingLabel
                            class="FloatingTool"
                            :config="{
                                label: tool.AttributeName,
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <b-form-select
                                v-model="tool.AttributeNameValue"
                                :disabled="!isChecked(att.ToolId)"
                                @change="changedValDef(att, tool)"
                            >
                                <b-form-select-option :value="null" disabled
                                    >Select a value</b-form-select-option
                                >
                                <option
                                    v-for="(op, index) in filterColTypes(
                                        tool.DatatypeSetID
                                    )"
                                    :key="index"
                                >
                                    {{ op.text }}
                                </option>
                            </b-form-select>
                        </CustomFloatingLabel>
                    </div>
                    <h5 class="mt-2">
                        Allowable
                    </h5>
                    <div
                        v-for="(allowableTool,
                        indexAllowable) in att.allowableToolAttributes"
                        :key="indexAllowable"
                        class="allowable"
                    >
                        <div style="width: 650px">
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: allowableTool.AttributeName,
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="
                                        allowableTool.AllowAttributeNameValue
                                    "
                                    :disabled="!isChecked(att.ToolId)"
                                    @change="
                                        changedValAllow(att, allowableTool)
                                    "
                                    plaeholder="Select a value"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null"
                                            >None</b-form-select-option
                                        >
                                    </template>
                                    <option
                                        v-for="(op, index) in filterColTypes(
                                            allowableTool.DatatypeSetID
                                        )"
                                        :key="index"
                                    >
                                        {{ op.text }}
                                    </option>
                                </b-form-select>
                            </CustomFloatingLabel>
                            <div
                                style="position: relative;float: right;width:150px;"
                            >
                                <div
                                    class="ml-2"
                                    style="position: relative;float: left"
                                >
                                    <img
                                        @click="
                                            addAllowAttribute(
                                                allowableTool,
                                                indexAllowable
                                            )
                                        "
                                        src="../../../../assets/plusIcon-greyBG.svg"
                                        class="ml-1"
                                    />
                                </div>
                                <div
                                    class="ml-2"
                                    style="position: relative;float: left"
                                    v-if="allowableTool.isRemoveAllow"
                                    @click="
                                        removeAllowAttrubutes(
                                            allowableTool,
                                            indexAllowable
                                        )
                                    "
                                >
                                    <img
                                        src="../../../../assets/Delete_Aggr.svg"
                                        class="ml-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import CustomFloatingLabel from '../../../CustomFloatingLabel';
export default {
    components: {
        CustomFloatingLabel,
    },
    props: {
        attributes: Array,
        toolAttributes: Array,
        selectedColoumns: Array,
        datatypes: Array,
        DatasetTools: Array,
    },
    data() {
        return {};
    },
    methods: {
        changedValDef(tool, attr) {
            if (tool.ToolName !== 'Standard Crosstab') {
                return;
            }
            let foundDef, foundAlow;
            let atibuteNameToCheck =
                attr.AttributeName === 'Columns' ? 'Rows' : 'Columns';

            foundDef = tool.ToolAttributes.filter(
                (itm) => itm.AttributeName === atibuteNameToCheck
            ).find((itm) => itm.AttributeNameValue === attr.AttributeNameValue);
            foundAlow = tool.allowableToolAttributes
                .filter((itm) => itm.AttributeName === atibuteNameToCheck)
                .find(
                    (itm) =>
                        itm.AllowAttributeNameValue === attr.AttributeNameValue
                );
            if (foundDef || foundAlow) {
                attr.AttributeNameValue = null;
            }
        },
        changedValAllow(tool, attr) {
            if (tool.ToolName !== 'Standard Crosstab') {
                return;
            }
            let foundDef, foundAlow;
            let atibuteNameToCheck =
                attr.AttributeName === 'Columns' ? 'Rows' : 'Columns';
            foundAlow = tool.allowableToolAttributes
                .filter((itm) => itm.AttributeName === atibuteNameToCheck)
                .find(
                    (itm) =>
                        itm.AllowAttributeNameValue ===
                        attr.AllowAttributeNameValue
                );
            foundDef = tool.ToolAttributes.filter(
                (itm) => itm.AttributeName === atibuteNameToCheck
            ).find(
                (itm) => itm.AttributeNameValue === attr.AllowAttributeNameValue
            );
            if (foundDef || foundAlow) {
                attr.AllowAttributeNameValue = null;
            }
        },
        savedToolData(toolID) {
            let tempArr = [...this.DatasetTools];
            if (tempArr.includes(toolID)) {
                tempArr.splice(tempArr.indexOf(toolID), 1);
            } else {
                tempArr.push(toolID);
            }
            this.$emit('setDatasetTools', tempArr);
        },
        addAllowAttribute(allowableTool, indexAllowable) {
            let tempAttArray = [...this.attributes];
            tempAttArray.forEach((att) => {
                if (att.ToolId === allowableTool.ToolId) {
                    let arrayFirst = att.allowableToolAttributes.slice(
                        0,
                        indexAllowable + 1
                    );
                    let arraySecond = att.allowableToolAttributes.slice(
                        indexAllowable + 1
                    );
                    let itmToClone =
                        att.allowableToolAttributes[indexAllowable];
                    let itmToInsert = {
                        AttributeName: itmToClone.AttributeName,
                        DatatypeSetID: itmToClone.DatatypeSetID,
                        ToolAttributeId: itmToClone.ToolAttributeId,
                        ToolId: itmToClone.ToolId,
                        AllowAttributeNameValue: null,
                        isRemoveAllow: true,
                    };
                    arrayFirst.push(itmToInsert);
                    att.allowableToolAttributes = [
                        ...arrayFirst,
                        ...arraySecond,
                    ];
                }
            });
            this.attributes = [...tempAttArray];
        },
        removeAllowAttrubutes(allowableTool, indexAllowable) {
            let tempAttArray = [...this.attributes];
            tempAttArray.forEach((att) => {
                if (att.ToolId === allowableTool.ToolId) {
                    att.allowableToolAttributes.splice(indexAllowable, 1);
                }
            });
            this.attributes = [...tempAttArray];
        },
    },
    computed: {
        isChecked() {
            return (value) => this.DatasetTools.includes(value);
        },
        filterColTypes() {
            return (DatatypeSetID) => {
                let dataCols = [];
                if (DatatypeSetID === 0) {
                    this.selectedColoumns.forEach((col) => {
                        dataCols.push({ text: col.name, value: col.name });
                    });
                } else {
                    let dataTypes = this.datatypes.find(
                        (col) => col.DatatypeSetID === DatatypeSetID
                    ).Datatypes;
                    let dataTypesStrings = dataTypes.map((col) => {
                        return col.DatatypeDesc;
                    });
                    this.selectedColoumns.forEach((col) => {
                        if (dataTypesStrings.includes(col.dataType)) {
                            dataCols.push({ text: col.name, value: col.name });
                        }
                    });
                }
                return dataCols;
            };
        },
    },
    created() {},
    watch: {},
};
</script>

<style>
.checkbox {
    text-align: right;
    margin-top: -40px;
}
.division {
    margin-left: -22px;
}
.allowable {
    position: relative;
    width: 600px;
}
.allowable .FloatingTool .custom-select {
    float: left;
}
</style>

//4030
