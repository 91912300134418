<!-- allowed states for queue notification: start, progress, complete, error -->
<template>
    <div class="toastMsg-container">
        <div
            ref="successToastMsg"
            class="successToastMsg  mb-2"
            v-if="showSuccess"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/success_tick.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left pr-0">
                    <p class="toast-head" v-if="messageTitle">
                        {{ messageTitle }}
                    </p>
                    <p class="toast-head" v-else>Success</p>
                    <p class="toast-p">{{ successToastMessage }}</p>
                </b-col>
                <b-col cols="2" class="float-left text-center pl-0">
                    <img
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                        @click="closeSuccessToast"
                    />
                </b-col>
            </b-col>
        </div>

        <div
            ref="failureToastMsg"
            class="failureToastMsg  mb-2"
            v-if="showFailure"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/failure_cross.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left">
                    <p class="toast-head">Failure</p>
                    <p class="toast-p">{{ failureToastMessage }}</p>
                </b-col>
                <b-col cols="1" class="float-left text-center">
                    <img
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                        @click="closeFailureToast"
                    />
                </b-col>
            </b-col>
        </div>

        <div
            ref="failureToastMsg"
            class="failureToastMsg  mb-2"
            v-if="showError"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/failure_cross.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left">
                    <p class="toast-head">Error</p>
                    <p class="toast-p">{{ errorToastMessage }}</p>
                </b-col>
                <b-col cols="1" class="float-left text-center">
                    <img
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                        @click="closeFailureToast"
                    />
                </b-col>
            </b-col>
        </div>

        <div
            ref="connectingToastMsg"
            class="connectingToastMsg  mb-2"
            v-if="showConnecting"
        >
            <b-col cols="12 p-0">
                <b-col cols="1" class="float-left">
                    <img
                        src="../assets/connecting_icon.svg"
                        class="mr-2 toastImg"
                    />
                </b-col>
                <b-col cols="9" class="float-left">
                    <p class="toast-head">Connection status</p>
                    <p class="toast-p">{{ connectingToastMessage }}</p>
                </b-col>
                <b-col cols="1" class="float-left text-center">
                    <img
                        @click="closeConnectingToast"
                        src="../assets/close_big.svg"
                        class="ml-1 pointer toastImg"
                    />
                </b-col>
            </b-col>
        </div>
        <div v-for="(service, index) in notificationQueue" :key="index">
            <div
                v-if="service.visible === true"
                class="queueToastMsg mb-2"
                :ref="'queueToastMsg-' + index"
                :class="{
                    startToastMsg: service.queueState === 'start',
                    progressToastMsg: service.queueState === 'progress',
                    completeToastMsg: service.queueState === 'complete',
                    errorToastMsg: service.queueState === 'error',
                    queuedToastMsg: service.queueState === 'queue',
                }"
            >
                <b-col cols="12 p-0">
                    <b-col cols="1" class="float-left">
                        <img
                            :src="getImgSrc(service.queueState)"
                            class="mr-2 stateImg"
                        />
                    </b-col>
                    <b-col cols="9" class="float-left">
                        <div>
                            <p class="queueMsg">
                                <span class="queue-head">
                                    {{ service.queueService }}</span
                                >
                                {{ service.queueMessage }}
                            </p>
                            <span
                                class="queue-link"
                                v-html="service.downloadLink"
                                v-if="service.downloadLink !== ''"
                            ></span>
                        </div>
                    </b-col>
                    <b-col cols="1" class="float-left text-center">
                        <img
                            @click="closeQueueToast(notificationQueue, index)"
                            src="../assets/close_big.svg"
                            class="ml-1 pointer stateImg"
                        />
                    </b-col>
                </b-col>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
    name: 'ToastMessages',
    data() {
        return {};
    },
    props: {
        showSuccess: {
            type: Boolean,
            default: false,
        },
        showFailure: {
            type: Boolean,
            default: false,
        },
        showConnecting: {
            type: Boolean,
            default: false,
        },

        showError: {
            type: Boolean,
            default: false,
        },
        timeOutValue: {
            type: Number,
            default: 3000,
        },
        messageTitle: String,

        successToastMessage: String,
        failureToastMessage: {
            type: String,
            default: 'Something went wrong, please try again.',
        },
        connectingToastMessage: String,
        errorToastMessage: String,
        notificationQueue: {
            type: Array,
        },
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        showSample() {},
        showFailureToastMessage() {
            this.$refs['failureToastMsg'].show();
        },
        closeConnectingToast() {
            this.showConnecting = false;
        },
        closeFailureToast() {
            this.showFailure = false;
        },
        closeSuccessToast() {
            this.showSuccess = false;
        },
        closeProgressToast() {
            this.showProgress = false;
        },
        closeQueueToast(notificationQueue, index) {
            this.setToastMessage({
                queueService: notificationQueue[index].queueService,
                visible: false,
            });
        },
        closeCompleteToast() {
            this.showComplete = false;
        },

        getImgSrc(queueState) {
            switch (queueState) {
                case 'start':
                    return require('../assets/start.svg');
                case 'progress':
                    return require('../assets/progress.svg');
                case 'complete':
                    return require('../assets/complete.svg');
                case 'error':
                    return require('../assets/error.svg');
                case 'queue':
                    return require('../assets/queue.svg');
                default:
                    return '';
            }
        },
    },
    computed: {},
    watch: {
        showSuccess(newVal) {
            if (newVal === true) {
                setTimeout(() => {
                    this.$emit('hideToast');
                }, this.timeOutValue);
            }
        },
        showFailure(newVal) {
            if (newVal === true) {
                setTimeout(() => {
                    this.$emit('hideToast');
                }, this.timeOutValue);
            }
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.successToastMsg {
    border-left: 6px solid #00db52;
}
.failureToastMsg {
    border-left: 6px solid #d23037;
}
.connectingToastMsg {
    border-left: 6px solid #933d9b;
}
.startToastMsg {
    border-left: 6px solid #ffc107;
}
.progressToastMsg {
    border-left: 6px solid #17a2b8;
}
.completeToastMsg {
    border-left: 6px solid #28a745;
}
.errorToastMsg {
    border-left: 6px solid #dc3545;
}
.queuedToastMsg {
    border-left: 6px solid #9d9d9d;
}
.queueToastMsg,
.failureToastMsg,
.successToastMsg,
.connectingToastMsg {
    font-weight: normal;
    background: white;
    height: 98px;
    width: 486px;
    display: flex;
    align-items: center;
    border-radius: 3px;
    box-shadow: 0 0 8px #c6c6c6 !important;
}
.queueToastMsg {
    overflow-y: auto;
}
.queueMsg {
    max-height: 40px;
}
.toast-head {
    color: #1d1d1d !important;
    display: block !important;
    font-size: 18px;
    //font-weight: 600;
}
.queue-head {
    color: #1d1d1d !important;
}
.queueMsg {
    color: #626262 !important;
    font-size: 18px;
}
.toast-p {
    color: #626262 !important;
    display: block !important;
    font-size: 16px;
}
.toastImg {
    margin-top: 1rem;
}
.stateImg {
    margin-top: 0.2rem;
}
.pointer {
    cursor: pointer;
}
.float-right img {
    position: relative;
    right: 10px;
}
// select all direct children of toastMsg-container with a div tag
.toastMsg-container > div {
    z-index: 100;
}
.toastMsg-container {
    position: absolute;
    bottom: 64px;
    right: 80px;
    height: fit-content;
    width: 486px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}
</style>
