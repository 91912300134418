<template>
    <div
        v-show="showPane"
        ref="layerspane"
        :style="layersPaneStyle"
        class="layersPane"
    >
        <b-card>
            <button
                type="button"
                class="btn btn-layers py-1"
                v-bind:class="[
                    $parent.$parent.showRadar
                        ? 'layerButtonChecked'
                        : 'layerButtonUnchecked',
                ]"
                @click="toggleRadarLayer()"
            >
                <img
                    v-bind:class="[
                        $parent.$parent.showRadar
                            ? 'layerIconChecked'
                            : 'layerButtonUnchecked',
                    ]"
                    src="../../assets/map_layers_radar.svg"
                />
                Radar
            </button>
            <br />
            <button
                type="button"
                class="btn btn-layers py-1"
                v-bind:class="[
                    $parent.$parent.selectedBasemap == 1
                        ? 'layerButtonChecked'
                        : 'layerButtonUnchecked',
                ]"
                @click="toggleBasemap()"
            >
                <img
                    v-bind:class="[
                        $parent.$parent.selectedBasemap == 1
                            ? 'layerIconChecked'
                            : 'layerButtonUnchecked',
                    ]"
                    src="../../assets/map_layers_satellite.svg"
                />
                Satellite
            </button>
            <br />
            <div v-if="drivetimeLayers.length > 0">
                <b-button v-b-toggle.collapse-1 class="btn btn-layers py-1">
                    <img src="../../assets/map_layers_drivetime.svg" /> Drive
                    Time Analysis
                </b-button>
                <b-collapse id="collapse-1" class="mt-2 pl-2">
                    <div v-for="(layer, index) in drivetimeLayers" :key="index">
                        <button
                            v-bind:class="[
                                layer.isChecked
                                    ? 'layerButtonChecked'
                                    : 'layerButtonUnchecked',
                            ]"
                            @click="updateLayer(layer, 'drivetime', true)"
                            class="btn btn-layers"
                        >
                            {{ layer.name }}
                        </button>
                        <br />
                    </div>
                </b-collapse>
            </div>
            <div v-if="hcaLayers.length > 0">
                <b-button v-b-toggle.collapse-hca class="btn btn-layers py-1">
                    HCA
                </b-button>
                <b-collapse id="collapse-hca" class="mt-2 pl-3">
                    <button
                        v-for="(layer, index) in hcaLayers"
                        v-bind:class="[
                            layer.isChecked
                                ? 'layerButtonChecked'
                                : 'layerButtonUnchecked',
                        ]"
                        @click="updateLayer(layer, 'hca')"
                        class="btn btn-layers"
                        :key="index"
                    >
                        {{ layer.name }}
                    </button>
                </b-collapse>
            </div>
            <div v-if="ercLayers.length > 0">
                <b-button
                    type="button"
                    v-b-toggle.collapse-erc
                    class="btn btn-layers py-1"
                >
                    ERC Layers
                </b-button>
                <b-collapse id="collapse-erc" class="mt-2 pl-3">
                    <button
                        v-for="(layer, index) in ercLayers"
                        v-bind:class="[
                            layer.isChecked
                                ? 'layerButtonChecked'
                                : 'layerButtonUnchecked',
                        ]"
                        @click="updateLayer(layer, 'erc')"
                        class="btn btn-layers"
                        :key="index"
                    >
                        {{ layer.name }}
                    </button>
                </b-collapse>
            </div>
            <div v-if="osrpLayers.length > 0">
                <b-button
                    type="button"
                    v-b-toggle.collapse-osrp
                    class="btn btn-layers py-1"
                >
                    OSRP
                </b-button>
                <b-collapse id="collapse-osrp" class="mt-2 pl-3">
                    <button
                        v-for="(layer, index) in osrpLayers"
                        v-bind:class="[
                            layer.isChecked
                                ? 'layerButtonChecked'
                                : 'layerButtonUnchecked',
                        ]"
                        @click="updateLayer(layer, 'osrp')"
                        class="btn btn-layers"
                        :key="index"
                    >
                        {{ layer.name }}
                    </button>
                </b-collapse>
            </div>
            <div v-if="aarLayers.length > 0">
                <!-- aar is just a button, since there should only be 1 layer -->
                <button
                    v-bind:class="[
                        aarLayers[0].isChecked
                            ? 'layerButtonChecked'
                            : 'layerButtonUnchecked',
                    ]"
                    @click="updateLayer(aarLayers[0], 'aar')"
                    class="btn btn-layers"
                >
                    AAR
                </button>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        showPane: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data() {
        return {
            width: 250,
        };
    },
    mounted() {
        document.getElementById('app').style.paddingBottom = 100;
    },
    computed: {
        ...mapState('emergencyManagement', {
            layersStruct: 'layersStructValues',
        }),
        // should only contain AAR layer
        aarLayers: function() {
            if (this.layersStruct.length == 0) return [];
            return this.layersStruct.filter(
                (layer) =>
                    layer.name.toLowerCase().includes('aar') ||
                    layer.name
                        .toLowerCase()
                        .includes('american association of railroads')
            );
        },
        // layers should be TERC/SERC
        ercLayers: function() {
            if (this.layersStruct.length == 0) return [];
            return this.layersStruct.filter((layer) =>
                layer.name.toLowerCase().endsWith('erc')
            );
        },
        // isolates HCA results and impact areas
        hcaLayers: function() {
            if (this.layersStruct.length == 0) return [];
            return this.layersStruct.filter(
                (layer) =>
                    layer.name.toLowerCase().includes('hca') ||
                    layer.layers.toLowerCase().includes('impactarea')
            );
        },
        // need clarification from M. Jasper on what layers they want in here
        osrpLayers: function() {
            if (this.layersStruct.length == 0) return [];
            return this.layersStruct.filter((layer) =>
                layer.name.toLowerCase().includes('osrp')
            );
        },
        // gets drivetime layers and excludes the parent item
        drivetimeLayers: function() {
            if (this.layersStruct.length == 0) return [];
            return this.layersStruct.filter(
                (layer) =>
                    layer.layers.toLowerCase().includes('drive') &&
                    layer.layers.toLowerCase().includes('time') &&
                    !layer.hasChild
            );
        },
        responseZoneLayers: function() {
            if (this.layersStruct.length == 0) return [];
            return this.layersStruct.filter(
                (layer) =>
                    layer.name.toLowerCase().includes('response') &&
                    layer.name.toLowerCase().includes('zone') &&
                    !layer.hasChild
            );
        },
        layersPaneStyle: function() {
            return {
                position: 'absolute',
                zIndex: 9999,
                top: '10px',
                width: this.width + 'px',
                left: '64px', //doesn't need to update MidX Position, will always sit px from left
            };
        },
    },
    methods: {
        ...mapMutations('emergencyManagement', ['updateIsChecked']),
        hidePane() {
            this.$parent.$parent.paneVisibility['layers'] = false;
        },
        // updates the checked status in the TOC and layer visibility
        updateLayer(layer, sourceArr, hideResponseZones = false) {
            this.updateIsChecked({ id: layer.id, visibility: 'toggle' });
            // only want 1 drivetime layer on at a time
            if (sourceArr == 'drivetime') {
                this.drivetimeLayers.forEach((lyr) => {
                    if (lyr.id != layer.id) {
                        this.updateIsChecked({
                            id: lyr.id,
                            visibility: 'hide',
                        });
                    }
                });
            }
            // sometimes want to hide response zones since they make
            //   it difficult to see underlying layers
            if (hideResponseZones) {
                this.responseZoneLayers.forEach((lyr) => {
                    this.updateIsChecked({ id: lyr.id, visibility: 'hide' });
                });
            }
            this.hidePane();
        },
        toggleBasemap() {
            this.$parent.$parent.selectedBasemap = Math.abs(
                this.$parent.$parent.selectedBasemap - 1
            );
            this.hidePane();
        },
        toggleRadarLayer() {
            this.$parent.$parent.showRadar = !this.$parent.$parent.showRadar;
            this.hidePane();
        },
    },
};
</script>
<style scoped>
.layersPane {
    border-radius: 4px;
    background-color: rgb(32, 32, 32);
}
.btn-layers {
    color: white;
    background-color: rgb(32, 32, 32) !important;
    border: none;
    padding-left: 0px !important;
    padding-right: 0px;
    text-align: left;
}
.btn-layers:hover {
    color: lightblue;
}
.layerIconChecked {
    filter: invert(64%) sepia(94%) saturate(652%) hue-rotate(151deg)
        brightness(102%) contrast(95%);
}
.layerButtonChecked {
    border: 1px solid #29d9f6;
    color: #29d9f6;
    padding-right: 5px !important;
}
</style>
