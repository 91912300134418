<template>
    <div
        class="address-search"
        @click="
            (e) => {
                e.stopPropagation();
            }
        "
    >
        <input
            v-show="isExpanded || alwaysExpanded"
            placeholder="Search address or coordinates"
            class="addressInput pl-1"
            v-model="searchAddress"
            v-on:keyup.enter="query"
        />
        <button
            v-show="isExpanded || alwaysExpanded"
            id="searchButton"
            @click="query"
        >
            <img class="img-search" src="../../assets/layers_arrow_white.svg" />
            <span id="btn-label">Search</span>
        </button>
    </div>
</template>

<script>
import { latLngBounds } from 'leaflet';
import axios from 'axios';

export default {
    props: {
        alwaysExpanded: {
            required: false,
            type: Boolean,
            default: false,
        },
        position: {
            required: false,
            type: String,
            default: 'topleft',
        },
        maxZoomLevel: {
            required: false,
            type: Number,
            default: 17,
        },
        isExpanded: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    name: 'LControlAddressSearch',
    components: {},
    data() {
        return {
            showAddressBar: false,
            searchAddress: '',
        };
    },
    mounted() {
        this.add();
    },
    beforeDestroy() {
        this.remove();
    },
    watch: {
        searchAddress(newVal) {
            //clear address search marker when searchAddress is deleted
            if (newVal == '') {
                this.$parent.$parent.drawMarkers = this.$parent.$parent.drawMarkers.filter(
                    (geom) => !geom.id.startsWith('A')
                );
            }
        },
    },
    methods: {
        searchReady() {},
        toggleSearchBar() {
            this.showAddressBar = !this.showAddressBar;
            this.$emit('toggleSearchBar');
        },
        remove() {
            if (this.markerCluster) {
                this.$parent.removeLayer(this.markerCluster);
            }
        },
        add() {
            this.$parent.mapObject.on('geosearch/showlocation', this.onSearch);
        },
        isCoordinatePair() {
            // check if the search address is a coordinate pair
            if (this.searchAddress.match(/-?\d+\.?\d+/g) !== null) {
                // if it is a coordinate pair, return the coordinates
                var [lat, lon] = this.searchAddress.match(/-?\d+\.?\d+/g);
                lat = parseFloat(lat);
                lon = parseFloat(lon);
                // create a structure that mimics the response from the nominatim API
                let coordinateStruct = [
                    {
                        lat: lat,
                        lon: lon,
                        boundingbox: [
                            lat - 0.01,
                            lat + 0.01,
                            lon - 0.01,
                            lon + 0.01,
                        ],
                    },
                ];
                return coordinateStruct;
            } else {
                // if it is not a coordinate pair, return false
                return false;
            }
        },
        query(e) {
            e.preventDefault();
            e.stopPropagation();
            // check if the search address is a coordinate pair
            const coords = this.isCoordinatePair();
            if (coords) {
                // if it is a coordinate pair, call onSearch with the coordinates
                this.onSearch(coords);
            } else {
                // if it is not a coordinate pair, call the nominatim API
                axios({
                    method: 'get',
                    headers: {
                        Authorization: 'none',
                    },
                    url:
                        'https://nominatim.openstreetmap.org/search?q=' +
                        this.searchAddress +
                        '&format=json&limit=1',
                }).then((response) => this.onSearch(response.data));
            }
        },
        onSearch(searchResult) {
            if (searchResult.length > 0) {
                var bbox = searchResult[0].boundingbox;
                this.$parent.mapObject.fitBounds(
                    latLngBounds([
                        [bbox[0], bbox[2]],
                        [bbox[1], bbox[3]],
                    ]),
                    { maxZoom: this.maxZoomLevel }
                );
                let currentMarkerLength = this.$parent.$parent.drawMarkers
                    .length;
                this.$parent.$parent.drawMarkers.push({
                    id: 'A' + Number(currentMarkerLength + 1),
                    geometry: {
                        type: 'Point',
                        coordinates: [searchResult[0].lat, searchResult[0].lon],
                        coordSys: 'LatLong',
                    },
                    properties: {},
                    styling: {
                        SymbologyColour: '#cc0000',
                        SymbologySize: 5,
                        PolygonColor: '#cc0000',
                        PolygonTransparency: 0,
                    },
                });
            }
        },
    },
};
</script>

<style>
.btn-white {
    background-color: white !important;
    height: 62px;
    width: 62px;
}
.addressInput {
    vertical-align: middle;
    width: 250px;
    font-size: 12pt;
    color: black;
    height: 62px;
    /* border-radius: 4px; */
    border-width: 0;
    /* border-left: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    border-top: 1px solid darkgray; */
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
}
#searchButton {
    vertical-align: middle;
    background-color: black;
    margin-right: 0;
    color: white;
    height: 62px;
    border: none;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
}
.address-search {
    z-index: 1000;
    position: absolute;
    right: 90px;
    bottom: 391px;
    border-radius: 0.25em;
    box-shadow: 2px 2px 6px 2px #00000029 !important;
}
.fullBorder {
    border-top: 1px solid darkgray !important;
    border-bottom: 1px solid darkgray !important;
    border-left: 1px solid darkgray !important;
    border-radius: 4px 0px 0px 4px !important;
}
.img-search {
    display: block;
    margin-left: 5px;
}
#btn-label {
    display: block;
    font-size: 10px;
}
</style>
