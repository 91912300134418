<template>
    <l-control
        class="custom-control"
        :position="position"
        @ready="editControlReady"
        v-show="editing"
    >
        <!-- v-show="editing" -->
        <button class="edit-btn btn-gray" @click="stopEditing">
            Stop Editing
        </button>
    </l-control>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { LControl } from 'vue2-leaflet';
import { divIcon } from 'leaflet';
import { spatialFunctions } from '../../../utilities/spatialFunctions';

export default {
    name: 'LControlEdit',
    components: {
        LControl,
    },
    mixins: [],
    props: {
        position: {
            type: String,
            default: 'topright',
        },
        editing: {
            type: Boolean,
            default: false,
        },
        editingGeometry: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            editShapeColour: '#FFBD33',
            editVertexSize: 5,
        };
    },
    mounted() {
        this.controlOptions = {
            position: this.position,
            imageSources: this.imageSources,
        };
        this.$emit('ready', this.mapObject);
        this.$parent.$parent.$on('editVertex/updateLatLng', this.updateVertex);
    },
    render() {
        return null;
    },
    watch: {
        editingGeometry: function createEditVertexCircles(feature) {
            feature = spatialFunctions.reverseCoordinateOrder(feature);
            var pointID = 0;
            switch (feature.geometry.type) {
                case 'Point':
                    this.$parent.$parent.editIndex = this.$parent.$parent.drawMarkers.length;
                    this.$parent.$parent.editVertices.push(
                        this.createPoint(pointID, feature.geometry.coordinates)
                    );
                    this.$parent.$parent.editArray = 'drawMarkers';

                    break;
                case 'LineString':
                    this.$parent.$parent.editIndex = this.$parent.$parent.drawLines.length;
                    this.$parent.$parent.drawLines.push({
                        id: 'LE',
                        geometry: feature.geometry,
                        styling: {
                            PolygonBorderColour: this.editShapeColour,
                            PolygonFillColour: this.editShapeColour,
                            PolygonTransparency: 0.8,
                        },
                        length: 0,
                    });
                    this.$parent.$parent.editArray = 'drawLines';

                    feature.geometry.coordinates.forEach((vertex) => {
                        this.$parent.$parent.editVertices.push(
                            this.createPoint(pointID, vertex)
                        );

                        pointID++;
                    });
                    break;
                case 'Polygon':
                    this.$parent.$parent.editIndex = this.$parent.$parent.drawPolygons.length;
                    this.$parent.$parent.drawPolygons.push({
                        id: 'PE',
                        geometry: feature.geometry,
                        styling: {
                            PolygonBorderColour: this.editShapeColour,
                            PolygonFillColour: this.editShapeColour,
                            PolygonTransparency: 0.8,
                        },
                    });
                    this.$parent.$parent.editArray = 'drawPolygons';

                    feature.geometry.coordinates[0]
                        .slice(0, -1)
                        .forEach((vertex) => {
                            this.$parent.$parent.editVertices.push(
                                this.createPoint(pointID, vertex)
                            );
                            pointID++;
                        });
                    break;
            }
        },
    },
    methods: {
        ...mapActions('emergencyManagement', ['updateDrawingGeometry']),
        // removes the editing geometry from being drawn, returns the feature
        cleanUpEditingGeometries() {
            var editedFeature = {};
            switch (this.$parent.$parent.editArray) {
                case 'drawMarkers':
                    editedFeature = this.$parent.$parent.editVertices.at(0);
                    break;
                case 'drawLines':
                    editedFeature = this.$parent.$parent.drawLines.at(-1);
                    this.$parent.$parent.drawLines = this.$parent.$parent.drawLines.filter(
                        (feature) => feature.id !== 'LE'
                    );
                    break;
                case 'drawPolygons':
                    editedFeature = this.$parent.$parent.drawPolygons.at(-1);
                    this.$parent.$parent.drawPolygons = this.$parent.$parent.drawPolygons.filter(
                        (feature) => feature.id !== 'PE'
                    );
                    break;
            }
            this.$parent.$parent.drawMarkers = this.$parent.$parent.drawMarkers.filter(
                (feature) => !feature.id.startsWith('E')
            );
            this.$parent.$parent.editVertices = [];
            return editedFeature;
        },
        updateGeometry(vertex) {
            var newVertices = [];
            if (this.editingGeometry.geometry.type == 'Point') {
                // only have to return the 1 point
                return [vertex.lat, vertex.lng];
            } else if (this.editingGeometry.geometry.type == 'LineString') {
                this.editingGeometry.geometry.coordinates.forEach(
                    (coordinate, i) => {
                        if (vertex.id == 'CE' + i) {
                            newVertices.push([vertex.lat, vertex.lng]);
                        } else {
                            newVertices.push(coordinate);
                        }
                    }
                );
            } else if (this.editingGeometry.geometry.type == 'Polygon') {
                this.editingGeometry.geometry.coordinates[0].forEach(
                    (coordinate, i) => {
                        if (vertex.id == 'CE' + i) {
                            newVertices.push([vertex.lat, vertex.lng]);
                        } else {
                            newVertices.push(coordinate);
                        }
                    }
                );
                // only need to account for E0 since we do not draw a circle at the close of the polygon
                if (vertex.id == 'CE0') {
                    newVertices[newVertices.length - 1] = newVertices[0];
                }
            }
            if (this.editingGeometry.geometry.type == 'Polygon') {
                return [newVertices];
            } else {
                return newVertices;
            }
        },
        getEditIcon() {
            return divIcon({
                className: 'ThisHasToBeSomethingToHideTheDefaultShadow',
                html:
                    '<svg xmlns="http://www.w3.org/2000/svg" width="10.0px" viewBox="0 0 10.0 10.0" height="10.0px">  <circle id="Ellipse_453" data-name="Ellipse 453" cx="10" cy="10" r="10" transform="scale(0.5 0.5)" fill="' +
                    this.editShapeColour +
                    '" stroke="' +
                    this.editShapeColour +
                    '"/></svg>',
                iconAnchor: [5, 13],
                iconSize: [10, 10],
            });
        },
        createPoint(pointID, coordinates) {
            return {
                id: 'CE' + pointID,
                geometry: {
                    coordinates: coordinates,
                    coordSys: 'LatLong',
                    type: 'Point',
                },
                styling: {
                    PolygonColor: this.editShapeColour,
                    PolygonTransparency: 0.8,
                    SymbologyColour: this.editShapeColour,
                    SymbologySize: this.editVertexSize,
                },
                icon: this.getEditIcon(),
            };
        },
        updateVertex(vertex) {
            let newCoordinates = this.updateGeometry(vertex);
            this.$emit('updateGeometry', newCoordinates);
        },
        stopEditing() {
            let editedFeature = this.cleanUpEditingGeometries();
            this.updateDrawingGeometry(editedFeature);
            this.$emit(
                'stopGeometryEdit',
                spatialFunctions.dd2WebMerc(editedFeature)
            );
        },
        editControlReady() {},
    },
    computed: {},
};
</script>

<style scoped>
.edit-btn {
    border-radius: 6px;
    border: none;
    height: 40px;
    padding: 6px 12px 6px 12px !important;
    font-weight: 400;
    font-size: 1rem;

    /* need correct font-family */
}

.btn-gray {
    background-color: gray !important;
    color: white;
    /* height: 42px; */
}
.btn-gray:active {
    background-color: rgb(77, 194, 233) !important;
}
.btn-gray:hover {
    color: black !important;
}
</style>
