<template>
    <div v-if="!showPage">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="dataposting" v-else>
        <ToastMessages
            :messageTitle="messageTitle"
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :failureToastMessage="failureToastMessage"
            :successToastMessage="successToastMessage"
        />
        <div v-if="hideheader != 1 && mapview" class="py-2 m-lr-0 rel-position">
            <b-button
                @click="backToCreate"
                class="mb-3 mb-m backtopost btn-post"
            >
                Back to posting</b-button
            >
            <portal-bar-tool
                :showReportingMenu="showReportingMenu"
                :hidetableSelect="hidetableSelect"
            ></portal-bar-tool>
            <span><img id="filterBtn" src="../../assets/filter1.svg"/></span>
            <map-container v-bind:showMap="true"></map-container>
            <div ref="popup" v-show="mapPopupVisible">
                <table class="table table-striped table-sm mt-3">
                    <tbody>
                        <tr
                            v-for="item in clickedGeometry.properties"
                            :key="item.name"
                            v-show="item.popup"
                        >
                            <td>{{ item.name }}</td>
                            <td>{{ item.value }}</td>
                        </tr>
                        <tr v-show="clickedGeometry.hasFile">
                            <td colspan="2">
                                <div
                                    v-for="(item,
                                    index) in clickedGeometry.files"
                                    :key="item.fileName"
                                >
                                    <a @click="openDocument(index)">
                                        {{ item.fileName }}
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool -->
        <b-row>
            <b-container class="main-content mt-3 p-0 mb-4" v-if="!mapview">
                <portal-bar-tool
                    :showReportingMenu="showReportingMenu"
                    :hidetableSelect="hidetableSelect"
                ></portal-bar-tool>
                <!-- <div class="my-3">Text Instructions Here</div> -->
                <b-button
                    rounded
                    class="buttonSelect"
                    :disabled="validateInput"
                    @click="openMapView()"
                >
                    Apply options
                </b-button>
                <div>
                    <h5 for="input-invalid">Posting layer name</h5>

                    <b-form-group class="md-4 mb-0" role="group">
                        <CustomFloatingLabel
                            class="postingName"
                            :config="{
                                label: 'Name',
                                name: 'wrapper',
                                line: false,
                                scale: false,
                            }"
                        >
                            <input
                                placeholder="Name"
                                type="text"
                                class="inputbox mb-4"
                                v-model="layerName"
                                autocomplete="off"
                            />
                        </CustomFloatingLabel>
                    </b-form-group>
                    <div>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <h5>Select field to display</h5>
                            <CustomFloatingLabel
                                class="FloatingTool"
                                :config="{
                                    label: 'Field value',
                                    name: 'wrapper',
                                    line: false,
                                    scale: false,
                                }"
                            >
                                <b-form-select
                                    v-model="fieldName"
                                    :options="fieldData"
                                    text-field="fieldData"
                                    value-field="fieldData"
                                >
                                    <template #first>
                                        <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Field value</b-form-select-option
                                        >
                                    </template>
                                </b-form-select>
                            </CustomFloatingLabel>
                        </b-form-group>
                    </div>
                    <div>
                        <b-form-group
                            class="m-r-36"
                            id="input-group-3"
                            label-for="input-3"
                        >
                            <h5>Group by options</h5>
                            <div class="aggr mb-3">
                                <div
                                    v-for="(item, index) in aggreGateOption"
                                    :key="index"
                                >
                                    <img
                                        src="../../assets/OverflowMenu.svg"
                                        font-scale="1.5"
                                        class="overflow-icon"
                                        v-if="overflowMenu"
                                    />
                                    <div class="aggregate-row">
                                        <CustomFloatingLabel
                                            class="FloatingTool"
                                            :config="{
                                                label: 'Group by field',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                v-model="
                                                    item.aggregateOptionField
                                                "
                                                @change="addAggrType(index)"
                                                id="inline-form-custom-select-pref"
                                                :options="
                                                    fieldAggregateDataUnique
                                                "
                                                text-field="fieldAggregateDataUnique"
                                                value-field="fieldAggregateDataUnique"
                                            >
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Group by
                                                        field</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <span
                                            v-if="index != 0"
                                            @click="deleteAggr(index)"
                                            ><img
                                                src="../../assets/delete_icon.svg"
                                                class="ml-2"
                                        /></span>
                                    </div>
                                    <div
                                        v-for="(subItem,
                                        subIndex) in item.aggreGateType"
                                        :key="subIndex"
                                    >
                                        <img src="../../assets/L-shaped.svg" />
                                        <!-- v-if="aggrfieldType" -->
                                        <CustomFloatingLabel
                                            class="FloatingTool floatingIndent"
                                            :config="{
                                                label: 'Grouping method',
                                                name: 'wrapper',
                                                line: false,
                                                scale: false,
                                            }"
                                        >
                                            <b-form-select
                                                class="aggrType m-r-36"
                                                id="inline-form-custom-select-pref"
                                                v-model="
                                                    subItem.aggreGateTypeName
                                                "
                                                :options="
                                                    aggreGateTypeData[index]
                                                "
                                                text-field="AggregateOptions"
                                                value-field="AggregateOptions"
                                            >
                                                <!-- <option v-for="j in aggregateType" :key="j">{{ j }}</option> -->
                                                <template #first>
                                                    <b-form-select-option
                                                        :value="null"
                                                        disabled
                                                        >Group
                                                        method</b-form-select-option
                                                    >
                                                </template>
                                            </b-form-select>
                                        </CustomFloatingLabel>
                                        <div
                                            class="dateAggregate"
                                            v-if="
                                                subItem.aggreGateTypeName ==
                                                    'Closest to a provided date'
                                            "
                                        >
                                            <img
                                                src="../../assets/L-shaped.svg"
                                                class="imgIndent"
                                            />
                                            <CustomFloatingLabel
                                                class="FloatingTool floatingdate"
                                                :config="{
                                                    label: 'Provided date',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <input
                                                    class="avlFilter mt-2 mb-4"
                                                    type="date"
                                                    v-if="
                                                        subItem.aggreGateTypeName ==
                                                            'Closest to a provided date'
                                                    "
                                                    v-model="
                                                        subItem.aggreGateDate
                                                    "
                                                />
                                            </CustomFloatingLabel>
                                            <a
                                                @click="
                                                    deleteAggr(index, subIndex)
                                                "
                                                class="deletebtn"
                                            ></a>
                                        </div>
                                    </div>
                                    <!--  -->
                                </div>
                            </div>
                        </b-form-group>
                    </div>
                    <div>
                        <p
                            class="normal_input headergroup mt-1 add-btn"
                            @click="addAggr()"
                        >
                            Add another grouping option +
                        </p>
                    </div>
                    <div>
                        <b-card no-body class="mb-1 accordion-more">
                            <b-card-header
                                header-tag="header"
                                class="p-0 mb-2"
                                role="tab"
                            >
                                <b-button
                                    class="view-more"
                                    block
                                    v-b-toggle.accordion-1
                                    ><span class="when-closed">Expand</span
                                    ><span class="when-open">Collapse</span>
                                    advanced options</b-button
                                >
                            </b-card-header>
                            <b-collapse
                                id="accordion-1"
                                accordion="my-accordion"
                                role="tabpanel"
                            >
                                <b-card-body>
                                    <h5>
                                        Position
                                        <span class="grey-text-sm"
                                            >(optional)
                                        </span>
                                    </h5>
                                    <b-container
                                        class="bv-example-row mt-3 mb-5"
                                    >
                                        <b-row
                                            cols="3"
                                            class="edit-position-wide w-260"
                                            v-model="editPosition"
                                        >
                                            <b-col
                                                class="border-bottom border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'tl_button edit':
                                                            dataLabelPosition !=
                                                            'topLeft',
                                                    }"
                                                    @click="
                                                        dataPosition('topLeft')
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Top</span
                                                    ><br />
                                                    Left
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-bottom border-dark border-left p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'tc_button edit':
                                                            dataLabelPosition !=
                                                            'topCenter',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'topCenter'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Top</span
                                                    ><br />
                                                    Center
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-left border-bottom border-dark right-1 p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'tr_button edit':
                                                            dataLabelPosition !=
                                                            'topRight',
                                                    }"
                                                    @click="
                                                        dataPosition('topRight')
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Top</span
                                                    ><br />
                                                    Right
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-bottom border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'ml_button edit':
                                                            dataLabelPosition !=
                                                            'middleLeft',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'middleLeft'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Middle</span
                                                    ><br />Left
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-left border-bottom border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'mc_button edit':
                                                            dataLabelPosition !=
                                                            'middleCenter',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'middleCenter'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Middle</span
                                                    ><br />
                                                    Center
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-bottom border-left border-dark  p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'mr_button edit':
                                                            dataLabelPosition !=
                                                            'middleRight',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'middleRight'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Middle</span
                                                    ><br />Right
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-dark  p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'bl_button edit':
                                                            dataLabelPosition !=
                                                            'bottomLeft',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'bottomLeft'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Bottom</span
                                                    ><br />
                                                    Left
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-left border-dark p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'bc_button edit':
                                                            dataLabelPosition !=
                                                            'bottomCenter',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'bottomCenter'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Bottom</span
                                                    ><br />Center
                                                </b-button>
                                            </b-col>
                                            <b-col
                                                class="border-dark border-left p-0 h-70-1"
                                            >
                                                <b-button
                                                    v-bind:class="{
                                                        'br_button edit':
                                                            dataLabelPosition !=
                                                            'bottomRight',
                                                    }"
                                                    @click="
                                                        dataPosition(
                                                            'bottomRight'
                                                        )
                                                    "
                                                >
                                                    <span class="grey-text-sm"
                                                        >Bottom</span
                                                    ><br />
                                                    Right
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                    <div class="font-option-m">
                                        <b-form-group
                                            id="input-group-3"
                                            label-for="input-3"
                                        >
                                            <h5>
                                                Font options
                                                <span class="grey-text-sm"
                                                    >(optional)</span
                                                >
                                            </h5>
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Size',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="styleSize"
                                                    :options="styleSizeData"
                                                    text-field="Size"
                                                    value-field="Size"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Size</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Style',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="styleStyle"
                                                    :options="styleStyleData"
                                                    text-field="Style"
                                                    value-field="Style"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Style</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                            <div class="pickColor wrap">
                                                <div class="">
                                                    <CustomFloatingLabel
                                                        class="FloatingTool DataPosting"
                                                        :config="{
                                                            label: 'Color',
                                                            name: 'wrapper',
                                                            line: false,
                                                            scale: false,
                                                        }"
                                                    >
                                                        <button
                                                            @click="colorPicker"
                                                            class="btn-transparent"
                                                        >
                                                            <img
                                                                src="../../assets/ghd_colorpicker.svg"
                                                                font-scale="1.5"
                                                                class="pencil-icon"
                                                            />
                                                        </button>
                                                        <ejs-colorpicker
                                                            id="element"
                                                            type="text"
                                                            class="e-input"
                                                            v-model="fillColor"
                                                            readonly
                                                        >
                                                        </ejs-colorpicker>
                                                    </CustomFloatingLabel>
                                                </div>
                                            </div>
                                            <CustomFloatingLabel
                                                class="FloatingTool"
                                                :config="{
                                                    label: 'Type',
                                                    name: 'wrapper',
                                                    line: false,
                                                    scale: false,
                                                }"
                                            >
                                                <b-form-select
                                                    v-model="styleType"
                                                    :options="styleTypeData"
                                                    text-field="Type"
                                                    value-field="Type"
                                                >
                                                    <template #first>
                                                        <b-form-select-option
                                                            :value="null"
                                                            disabled
                                                            >Type</b-form-select-option
                                                        >
                                                    </template>
                                                </b-form-select>
                                            </CustomFloatingLabel>
                                        </b-form-group>
                                        <h5 class="trans-sm">Transparency</h5>
                                        <div class="d-flex my-4">
                                            <span
                                                class="font-weight-bold indigo-text mr-2 mt-1"
                                                >0</span
                                            >
                                            <form
                                                class="range-field grey-color"
                                                width="125px"
                                            >
                                                <!--This is the form that handles the slider for the mini stuff/mini map edit-->
                                                <input
                                                    class="border-0 inputrange"
                                                    type="range"
                                                    v-model="transparencyValue"
                                                    min="0"
                                                    step="1"
                                                    max="100"
                                                />
                                            </form>
                                            <span
                                                class="font-weight-bold indigo-text ml-2 mt-1"
                                                margin-left="-25px"
                                                padding-left="0px"
                                                >100</span
                                            >
                                            <p margin-top="50px">
                                                <input
                                                    type="text"
                                                    margin-top="50px"
                                                    class="input-value"
                                                    display="inline-block"
                                                    min="0"
                                                    max="100"
                                                    maxlength="3"
                                                    size="3ch"
                                                    padding-top="5px"
                                                    v-model.lazy="
                                                        transparencyValue
                                                    "
                                                    v-on:keyup.enter="
                                                        validateTransparency()
                                                    "
                                                    v-click-outside="
                                                        validateTransparency()
                                                    "
                                                />%
                                            </p>
                                        </div>
                                    </div>
                                    <b-form-group
                                        id="input-group-3"
                                        label-for="input-3"
                                        class="bottom-p-75 m-r-36"
                                    >
                                        <h5>
                                            Symbology
                                            <span class="grey-text-sm"
                                                >(optional)</span
                                            >
                                        </h5>
                                        <b-dropdown
                                            class="symbology text-left mb-4"
                                            text="Symbology"
                                        >
                                            <template slot="button-content">
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/circle.svg"
                                                    v-if="symbology == 'circle'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/arrow.svg"
                                                    v-if="symbology == 'arrow'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/flag.svg"
                                                    v-if="symbology == 'flag'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/star_5.svg"
                                                    v-if="symbology == 'star'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/cross_4.svg"
                                                    v-if="symbology == 'cross'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/cross_3.svg"
                                                    v-if="symbology == 'cross3'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/cross_5.svg"
                                                    v-if="symbology == 'cross5'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/star_3.svg"
                                                    v-if="symbology == 'star3'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/star_4.svg"
                                                    v-if="symbology == 'star4'"
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/polygon_5.svg"
                                                    v-if="
                                                        symbology == 'polygon5'
                                                    "
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/polygon_4.svg"
                                                    v-if="
                                                        symbology == 'polygon4'
                                                    "
                                                />
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/polygon_3.svg"
                                                    v-if="
                                                        symbology == 'polygon3'
                                                    "
                                                />
                                            </template>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'circle'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/circle.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'arrow'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/arrow.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'flag'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/flag.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'star'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/star_5.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'cross'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/cross_4.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'cross3'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/cross_3.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'cross5'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/cross_5.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'star3'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/star_3.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'star4'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/star_4.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'polygon3'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/polygon_3.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'polygon4'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/polygon_4.svg"
                                                />
                                            </b-dropdown-item>
                                            <b-dropdown-item
                                                @click.prevent="
                                                    symbology = 'polygon5'
                                                "
                                            >
                                                <img
                                                    class="img-fluid p-2"
                                                    src="../../assets/polygon_5.svg"
                                                />
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </b-form-group>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </b-container>
        </b-row>
        <!-- Div For Main Data Posting Tool After clicking data Posting Tool ENDS BELOW -->
        <img
            id="referenceCircleImage"
            src="../../assets/circle.svg"
            class="d-none"
        />
        <img
            id="referenceArrowImage"
            src="../../assets/arrow.svg"
            class="d-none"
        />
        <img
            id="referenceFlagImage"
            src="../../assets/flag.svg"
            class="d-none"
        />
        <img
            id="referenceStarImage"
            src="../../assets/star_5.svg"
            class="d-none"
        />
        <img
            id="referencePlusImage"
            src="../../assets/cross_4.svg"
            class="d-none"
        />
        <img
            id="referenceCross3Image"
            src="../../assets/cross_3.svg"
            class="d-none"
        />
        <img
            id="referenceCross5Image"
            src="../../assets/cross_5.svg"
            class="d-none"
        />
        <img
            id="referenceStar3Image"
            src="../../assets/star_3.svg"
            class="d-none"
        />
        <img
            id="referenceStar4Image"
            src="../../assets/star_4.svg"
            class="d-none"
        />
        <img
            id="referencePolygon3Image"
            src="../../assets/polygon_3.svg"
            class="d-none"
        />
        <img
            id="referencePolygon4Image"
            src="../../assets/polygon_4.svg"
            class="d-none"
        />
        <img
            id="referencePolygon5Image"
            src="../../assets/polygon_5.svg"
            class="d-none"
        />
    </div>
    <!-- Main Div Of this Component end below -->
</template>
<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import router from '../../router';

import { latLngBounds } from 'leaflet';

import { userService } from '../../services/users.service';
import { projectService } from '../../services/project.service';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';
import { spatialFunctions } from '../../utilities/spatialFunctions';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import LControlScale from '../../components/EmergencyManagement/LeafletScale.vue';
import { commonService } from '../../services/common.service';
import PortalBarTool from '../PortalBarTool';
import AccessPermissionModal from '../AccessPermissionModal';
import CustomFloatingLabel from '../CustomFloatingLabel';
import MapContainer from './MapContainer.vue';
import ToastMessages from '../ToastMessages.vue';

//Vue.use(MapsPlugin);
Vue.use(ColorPickerPlugin);

export default {
    components: {
        PortalBarTool,
        CustomFloatingLabel,
        AccessPermissionModal,
        MapContainer,
        ToastMessages,
    },
    name: 'datapostingtools',
    props: {
        showReportingMenu: Boolean,
        hidetableSelect: Boolean,
        // aggreGateOptionFromTable: Array,
        currentSwitchState: Boolean,
    },
    data() {
        return {
            //project layer data, remove after api call is completed
            clustersHaveBeenRefreshed: false,
            projectDataLayers: [],
            postedDataLayers: null,
            mapPostingLayerData: null,
            //end
            sitecode: null,
            markerpopup: false,
            mapBounds: null,
            fillColor: '#000000',
            postingSource: null,
            colorSelected: null,
            rotation: '0°',
            transparencyValue: 0,
            //displayedTransparencyValue: 0,
            editTransparencyValue: null,
            editFieldName: '',
            editPosition: null,
            createLayerPage: true,
            layerDetails: {},
            aggreGateOption: [
                {
                    aggreGateField: '',
                    aggreGateType: [],
                    aggregateOptionField: '',
                },
            ],
            aggreGateTypeData: [],
            modalLayerName: '',
            modalLayerId: '',
            layerId: '',
            value: 0,
            options: [],
            // validateInput: true,
            localLayer: [],
            layerList: [],
            styleStyle: 'Normal',
            pickColor: false,
            postLayerActive: true,
            layerName: '',
            editedPostingId: null,
            fieldName: null,
            symbology: null,
            styleSize: 10,
            styleType: 'Arial',
            overflowMenu: false,
            //aggregateOption: [],
            // layerId: "",
            selected: null,
            hideheader: 0,
            aggrfieldCounter: 0,
            aggrfield: [],
            mapview: false,
            dataLabelPosition: null,
            edit_clicked: false,
            postingId: 0,
            selectedPostingId: '',
            styleData: [],
            styleSizeData: [],
            styleTypeData: [],
            styleColorData: [],
            styleStyleData: [],
            fieldData: [],
            layerEditIndex: '',
            editLayer: false,
            fieldAggregateData: [],
            fieldAggregateDataUnique: [],
            dptData: [],
            showLayer: true,
            aggrfieldType: false,
            thisfilename: null,
            reportingTool: false,
            AggregateOptions: null,
            showPage: false,
            filterOptions: [],
            editAggrTypeRevised: [],
            clickedGeometry: { properties: [], hasFile: false, files: [] },
            clusterOptions: {
                disableClusteringAtZoom: 20,
                showCoverageOnHover: false,
                chunkedLoading: true,
            },
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
            showFailure: false,
            showSuccess: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
        };
    },
    created() {
        // this.getAllLayer();

        //this.getStyleBlock();
        this.getStyleSize();
        this.getStyleType();
        this.getStyleStyle();
        this.selectField();
        this.selectAggregateField();
        this.allAggregateOption();

        this.aggreGateOptionFromTable = JSON.parse(
            localStorage.getItem('aggreGateOption')
        );

        if (localStorage.getItem('postingStatus') == 'true') {
            if (this.aggreGateOptionFromTable) {
                this.aggreGateOption = this.aggreGateOptionFromTable;
                this.displayMap();
            }
        }
    },
    computed: {
        ...mapState({
            projectMapLayers: (state) => state.projectLayers.projects,
            postedMapLayers: (state) =>
                state.projects.mapData.geojsonLayers.posted,
        }),
        validateInput() {
            let disableApply = false;
            if (this.layerName == '' || this.layerName == null) {
                disableApply = true;
            }
            if (this.fieldName == null || this.fieldName == '') {
                disableApply = true;
            }
            for (let i in this.aggreGateOption) {
                if (
                    this.aggreGateOption[i].aggregateOptionField == '' ||
                    this.aggreGateOption[i].aggregateOptionField == null
                ) {
                    disableApply = true;
                } else {
                    if (this.aggreGateOption[i].aggreGateType.length > 0) {
                        for (let m in this.aggreGateOption[i].aggreGateType) {
                            if (
                                this.aggreGateOption[i].aggreGateType[m]
                                    .aggreGateTypeName == '' ||
                                this.aggreGateOption[i].aggreGateType[m]
                                    .aggreGateTypeName == null
                            ) {
                                disableApply = true;
                            } else if (
                                this.aggreGateOption[i].aggreGateType[m]
                                    .aggreGateTypeName ==
                                'Closest to a provided date'
                            ) {
                                if (
                                    this.aggreGateOption[i].aggreGateType[m]
                                        .aggreGateDate == ''
                                ) {
                                    disableApply = true;
                                }
                            }
                        }
                    }
                }
            }

            return disableApply;
        },
        projectGeojson() {
            let output1 = [];
            let output2 = [];
            this.layerList.forEach((item) => {
                let postFeatures = item.markerSettings11[0].dataSource;
                let postStyling = {
                    FontColour: item.color,
                    FontRotation: item.fontRotation,
                    FontSize: item.fontSize,
                    FontStyle: item.fontStyle,
                    FontTransparency: parseInt(item.transparency),
                    FontType: item.fontType,
                    LabelPosition: item.position,
                    LineColour: item.color,
                    // LineStyle: lyr.LineStyle,
                    LineWidth: 1, //lyr.LineWidth,
                    PolygonColour: item.color,
                    // PolygonStyle: lyr.PolygonStyle,
                    PolygonTransparency: parseInt(item.transparency),
                    PolygonBorderColour: item.color,
                    PolygonWidth: 1, //lyr.PolygonWidth,
                    Symbology: item.pointSymbology,
                    SymbologyColour: item.color,
                    Opacity: 100 - parseInt(item.transparency),
                };
                postFeatures.features.forEach((feature) => {
                    feature.styling = postStyling;
                });
                postFeatures.postingId = item.postingId;
                output2.push(postFeatures);
                this.zoomToPosted(postFeatures);
            });

            this.projectDataLayers.forEach((item) => {
                if (item.ProjectLayers) {
                    item.ProjectLayers.sort((a, b) => a.lyrorder - b.lyrorder);
                    item.ProjectLayers.forEach((lyr) => {
                        lyr.Geometry.id = lyr.lyrorder;
                        output1.push(lyr.Geometry);
                    });
                }
            });
            return { projectLayers: output1, postedLayers: output2 };
        },

        displayedTransparencyValue() {
            //maybe can use this to better separate the slider and input box?
            return this.transparencyValue + '%';
        },
    },
    mounted: function() {
        this.pagePermit();
        this.getProjectLayerData();
        this.layerName = 'layer' + this.postedMapLayers.length + 1;
        this.$root.$on('clickedFilterData', function(value) {
            this.currentSwitchState = value;
            localStorage.setItem('postingStatus', this.currentSwitchState);
        });
        this.localLayer = this.postedMapLayers;
        this.layerList = this.postedMapLayers;
        localStorage.setItem('layerDetails', JSON.stringify(this.localLayer));
        this.postingId = Date.now();
    },
    methods: {
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        ...mapActions('projects', {
            updateSelectedLayerAccordion: 'updateSelectedLayerAccordion',
        }),
        addDataToMainMap() {
            //this posts the layer GEOMETRY to the store
            let payload = [];
            payload = this.projectGeojson.postedLayers;
            payload.forEach((item) => {
                item.id = item.postingId;
            });
            // this.$store.commit('projects/addPostedGeojson', payload);

            //this posts the ATTRIBUTES to the store
            let payLoad2 = [];
            payLoad2 = this.layerList;
            payLoad2.forEach((item) => {
                item.id = item.postingId;
                let dataSource = item.markerSettings11[0].dataSource;
                if (!dataSource.labelVisibleIndex) {
                    for (
                        let i = 0;
                        i < dataSource.features[0].properties.length;
                        i++
                    ) {
                        if (dataSource.features[0].properties[i].label) {
                            dataSource.labelVisibleIndex = i;
                        }
                    }
                }

                // change to true to cluster user posted layers
                item.clustered = false;
            });

            this.$store.commit('projects/addPostedLayerData', payLoad2);
        },
        clickedFilterData() {},
        openDocument(index) {
            let fileName = '';
            let downloadType = '';
            let documentPath = '';
            this.clickedGeometry.properties.forEach((item) => {
                if (item.name == 'Files') {
                    fileName = item.value[index].fileName;
                    downloadType = item.value[index].source;
                    documentPath = item.value[index].path;
                    commonService.downloadDocument(
                        fileName,
                        downloadType,
                        documentPath
                    );
                }
            });
        },
        getBounds() {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            //
            this.projectGeojson.postedLayers.forEach((postLayer) => {
                postLayer.features.forEach((location) => {
                    minX = Math.min(
                        minX,
                        parseFloat(location.geometry.coordinates[0])
                    );
                    maxX = Math.max(
                        maxX,
                        parseFloat(location.geometry.coordinates[0])
                    );
                    minY = Math.min(
                        minY,
                        parseFloat(location.geometry.coordinates[1])
                    );
                    maxY = Math.max(
                        maxY,
                        parseFloat(location.geometry.coordinates[1])
                    );
                });
            });
            this.$refs.lmap1.mapObject.fitBounds(
                latLngBounds([
                    [minY, minX],
                    [maxY, maxX],
                ]),
                { maxZoom: 16 }
            );
        },
        toggleBasemap() {
            let basemaplayerID = Object.keys(
                this.$refs.lmap1.mapObject._layers
            )[0];
            // get current basemap of large map
            let basemaplayer = this.$refs.lmap1.mapObject._layers[
                basemaplayerID
            ]._url;
            if (basemaplayer.includes('Topo')) {
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[3].visible = false;
                this.baseMaps[2].visible = true;
            } else if (basemaplayer.includes('Imagery')) {
                this.baseMaps[0].visible = false;
                this.baseMaps[2].visible = false;
                this.baseMaps[3].visible = false;
                this.baseMaps[1].visible = true;
            } else if (basemaplayer.includes('open')) {
                this.baseMaps[2].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[3].visible = false;
                this.baseMaps[0].visible = true;
            } else {
                //no map
                this.baseMaps[0].visible = false;
                this.baseMaps[1].visible = false;
                this.baseMaps[2].visible = false;
                this.baseMaps[3].visible = true;
            }
        },
        getBrowserType() {
            if (
                (navigator.userAgent.indexOf('Opera') ||
                    navigator.userAgent.indexOf('OPR')) != -1
            ) {
                return 'Opera';
            } else if (
                (navigator.userAgent.indexOf('Edg') ||
                    navigator.userAgent.indexOf('Edge')) != -1
            ) {
                return 'Edge';
            } else if (navigator.userAgent.indexOf('Chrome') != -1) {
                return 'Chrome';
            } else if (navigator.userAgent.indexOf('Safari') != -1) {
                return 'Safari';
            } else if (navigator.userAgent.indexOf('Firefox') != -1) {
                return 'Firefox';
            } else if (
                navigator.userAgent.indexOf('MSIE') != -1 ||
                !!document.documentMode == true
            ) {
                //IF IE > 10
                return 'IE';
            } else {
                return 'unknown';
            }
        },
        print() {
            this.mapClass = 'dataPost-map-box-print';
            this.resizeModal();
            const modal = document.getElementById('mapContainer');
            const cloned = modal.cloneNode(true);
            let section = document.getElementById('print');
            if (!section) {
                section = document.createElement('div');
                section.id = 'print';
                document.body.appendChild(section);
            }

            section.innerHTML = '';
            section.appendChild(cloned);
            var css;
            var userBrowser = this.getBrowserType();
            var topMargin = '0';
            var leftMargin = '0';
            switch (userBrowser) {
                case 'Chrome':
                    topMargin = '0.5in';
                    leftMargin = '0.1in';
                    break;
                case 'Firefox':
                    topMargin = '0.5in';
                    leftMargin = '0';
                    break;
                case 'Edge':
                    topMargin = '0.1in';
                    leftMargin = '0.1in';
                    break;
            }
            css =
                '@page { size: landscape; } @media print {#mapContainer{position:absolute; height:7.5in; width:10in; top:' +
                topMargin +
                ';left:' +
                leftMargin +
                ';}';
            var head =
                document.head || document.getElementsByTagName('head')[0];
            var style = document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }

            head.appendChild(style);

            setTimeout(function() {
                window.print();
            }, 500);
            window.onafterprint = () => {
                this.mapClass = 'dataPost-map-box';
            };
            this.closeModal();
        },
        getProjectLayerData() {
            let projectIDs = [];
            this.$store.state.projects.selectedProjects.forEach((proj) => {
                projectIDs.push(proj.ProjectID);
            });
            if (projectIDs.length > 0) {
                projectService
                    .getProjectLayers(projectIDs.toString())
                    .then((res) => {
                        this.projectDataLayers = res.data;

                        let payload2 = [];
                        let allWmsLayers = [];
                        this.projectDataLayers.forEach((proj) => {
                            if (
                                proj.ProjectLayers == undefined ||
                                proj.ProjectLayers.length == 0
                            ) {
                                const emptyLayers = [
                                    {
                                        DisplayName:
                                            'No layers defined for this project',
                                        Geometry: {
                                            features: [],
                                            type: 'FeatureCollection',
                                            visible: true,
                                        },
                                    },
                                ];
                                proj.ProjectLayers = emptyLayers;
                            } else if (proj.WMSLayer != null) {
                                let payload2 = [];
                                let allWmsLayers = [];
                                this.projectDataLayers.forEach((item) => {
                                    allWmsLayers = [...item.WMSLayer];
                                });
                                allWmsLayers.forEach((item) => {
                                    if (item.WMSUrl) {
                                        payload2.push({
                                            url:
                                                item.WMSUrl +
                                                `?authkey=${item.authkey}`,
                                            name: item.WMSLabel,
                                            visible: item.IsVisible,
                                            format: item.WMSFormat,
                                            layers: item.WMSName,
                                            transparent: true,
                                            attribution: '',
                                            options: {},
                                        });
                                    }
                                });
                                this.wmsLayers = payload2;
                            }
                        });

                        //projectDataLayers styling data management
                        this.projectDataLayers.forEach((proj) => {
                            proj.ProjectLayers.forEach((lyr) => {
                                let projStyling = {
                                    FontColour: lyr.FontColour,
                                    FontRotation: lyr.FontRotation,
                                    FontSize: lyr.FontSize,
                                    FontStyle: lyr.FontStyle,
                                    FontTransparency: lyr.FontTransparency,
                                    FontType: lyr.FontType,
                                    LineColour: lyr.LineColour,
                                    LineStyle: lyr.LineStyle,
                                    LineWidth: lyr.LineWidth,
                                    PolygonColour: lyr.PolygonColour,
                                    PolygonStyle: lyr.PolygonStyle,
                                    PolygonTransparency:
                                        lyr.PolygonTransparency,
                                    PolygonBorderColour:
                                        lyr.PolygonBorderColour,
                                    PolygonWidth: lyr.PolygonWidth,
                                    Symbology: lyr.Symbology,
                                    SymbologyColour: lyr.SymbologyColour,
                                };
                                lyr.Geometry.features.forEach((geom) => {
                                    geom.styling = projStyling;
                                });
                            });
                        });
                    });
            }
        },
        pagePermit() {
            this.showPage = true;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('DataPostingTool')) {
                    vm.showPage = true;
                }
            });
            // this.$emit('clickedDataPosting', this.showPage);
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        getStyleBlock() {
            projectService
                .getStyleBlock()
                .then((res) => (this.styleData = res.data));
        },
        getStyleSize() {
            projectService.getStyleSize().then((res) => {
                this.styleSizeData = res.data;
            });
        },
        getStyleStyle() {
            projectService
                .getStyleStyle()
                .then((res) => (this.styleStyleData = res.data));
        },
        getStyleColor() {
            projectService
                .getStyleColor()
                .then((res) => (this.styleColorData = res.data));
        },
        getStyleType() {
            projectService
                .getStyleType()
                .then((res) => (this.styleTypeData = res.data));
        },
        selectField() {
            projectService
                .selectField(localStorage.getItem('datasetid'))
                .then((res) => {
                    let dataField = res.data;
                    if (dataField) {
                        dataField.forEach((item) => {
                            if (item.AttributeType === 'Default') {
                                10;
                                this.fieldName = item.DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName;
                            }
                            this.fieldData.push(
                                item.DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName
                            );
                        });
                        this.fieldData = [...new Set(this.fieldData)];
                        /* let data=this.fieldData.find((item)=>item.AttributeType==="Default");
             this.fieldName=data.DatasetColumn.ColumnName;*/
                    }
                });
        },
        selectAggregateField() {
            projectService
                .selectAggregateField(localStorage.getItem('datasetid'))
                .then((res) => {
                    this.fieldAggregateData = res.data;

                    if (this.fieldAggregateData) {
                        this.fieldAggregateData.forEach((item) => {
                            if (item.AttributeType === 'Default') {
                                this.aggreGateOption[0].aggregateOptionField = item
                                    .DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName;
                                this.addAggrType(0);
                            }
                            this.fieldAggregateDataUnique.push(
                                item.DatasetColumn.ColumnAlias
                                    ? item.DatasetColumn.ColumnAlias
                                    : item.DatasetColumn.ColumnName
                            );
                        });
                        this.fieldAggregateDataUnique = [
                            ...new Set(this.fieldAggregateDataUnique),
                        ];
                    }
                });
        },

        allAggregateOption() {
            let vm = this;
            userService.getAggregateOptions().then((res) => {
                this.options = res.data;
            });
        },
        deleteAggr(index) {
            this.aggreGateOption.splice(index, 1);
            // this.aggrfieldType = false;
        },
        colorPicker() {
            document.getElementById('element').ej2_instances[0].toggle();
        },
        dataPosition(item) {
            this.dataLabelPosition = item;
        },
        addAggr() {
            // this.aggrfield = true;
            /* this.aggrfield.push(this.aggrfieldCounter++);
      this.overflowMenu = true;*/
            this.aggreGateOption.push({
                aggreGateField: '',
                aggreGateType: [],
            });
            this.fieldAggregateData.forEach((item) => {
                if (item.AttributeType === 'Default') {
                    this.aggreGateOption[
                        this.aggreGateOption.length - 1
                    ].aggregateOptionField = item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName;
                    this.addAggrType(this.aggreGateOption.length - 1);
                }
            });
        },

        addAggrType(index) {
            //
            let dataTypeSetId;

            this.aggreGateOption[index].aggreGateType = [];
            dataTypeSetId = this.fieldAggregateData.find(
                (item) =>
                    this.aggreGateOption[index].aggregateOptionField ===
                    (item.DatasetColumn.ColumnAlias
                        ? item.DatasetColumn.ColumnAlias
                        : item.DatasetColumn.ColumnName)
            );
            if (this.aggreGateOption[index].aggreGateType.length === 0) {
                this.aggreGateOption[index].aggreGateType.push({
                    aggreGateTypeName: '',
                    aggreGateDate: '',
                });
                /* let tempType;
     if(dataTypeSetId.ToolAttribute.DatatypeSetID!==0){
     tempType=this.options.filter((item)=> item.DatatypeSetID===dataTypeSetId.ToolAttribute.DatatypeSetID);
     }else{
     tempType=this.options;

     }*/

                userService
                    .getAggregateOptionsByType(
                        dataTypeSetId.DatasetColumn.Datatype
                    )
                    .then((res) => {
                        this.options = res.data;
                        this.aggreGateTypeData[index] = this.options;
                        if (
                            this.$store.state.store.portalsettings !== undefined
                        ) {
                            let numberPortalData = this.$store.state.store
                                .portalsettings.Number;
                            let datePortalData = this.$store.state.store
                                .portalsettings.Date;
                            let textPortalData = this.$store.state.store
                                .portalsettings.Text;
                            let result = this.options.filter(
                                (ele) =>
                                    ele.AggregateOptionID == numberPortalData ||
                                    ele.AggregateOptionID == datePortalData ||
                                    ele.AggregateOptionID == textPortalData
                            );
                            if (result.length) {
                                this.aggreGateOption[
                                    index
                                ].aggreGateType[0].aggreGateTypeName =
                                    result[0].AggregateOptions;
                                this.validateInput = false;
                            }
                        }
                        this.$forceUpdate();
                    });
            }

            /* this.selected = true;
      this.aggrfieldType = true;

      if (evt == "Result") {
        this.aggregateType = ["a"];
      } else if (evt == "ColectionDate") {
        this.aggregateType = ["a1", "b2", "c3", "d4"];
      } else if (evt == "MatrixDesc") {
        this.aggregateType = ["a5", "b6", "c6", "d6"];
      }*/
        },
        backToCreate() {
            this.mapview = !this.mapview;
            this.postLayerActive = true;
            this.aggreGateOption = [];
            this.aggreGateOption.push({
                aggreGateField: '',
                aggreGateType: [],
            });
            this.layerName = '';
            this.fieldName = null;
            this.fillColor = '#000000';
            this.symbology = null;
            this.styleSize = 10;
            this.styleStyle = 'Normal';
            this.styleType = 'Arial';
            this.transparencyValue = 0;
            this.rotation = '0°';
            this.edit_clicked = false;
            this.selectedPostingId = '';
            this.layerEditIndex = '';
            this.editLayer = false;
            this.localLayer = this.postedMapLayers;
            this.layerList = this.postedMapLayers;
            localStorage.setItem(
                'layerDetails',
                JSON.stringify(this.localLayer)
            );
        },
        openMapView() {
            this.updateSelectedLayerAccordion('posted');
            this.clustersHaveBeenRefreshed = false;
            if (localStorage.getItem('postingStatus') == 'true') {
                this.$emit(
                    'clickedState',
                    localStorage.getItem('postingStatus'),
                    this.aggreGateOption
                );
                this.$root.$refs.B.dataToolTab(true);
            } else {
                this.displayMap();
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [month, day, year].join('-');
        },
        displayMap() {
            this.showLayer = true;
            let aggregateData = [];
            this.aggreGateOption.forEach((item) => {
                if (item.aggreGateType[0].aggreGateDate) {
                    let x = this.formatDate(
                        item.aggreGateType[0].aggreGateDate
                    );
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggreGateType[0].aggreGateTypeName +
                            '(' +
                            x +
                            ')'
                    );
                } else {
                    aggregateData.push(
                        item.aggregateOptionField +
                            ':' +
                            item.aggreGateType[0].aggreGateTypeName
                    );
                }
            });
            let aggregateDataFinal = aggregateData.toString();
            this.filterOptions = JSON.parse(localStorage.getItem('gridFilter'));
            projectService
                .getDataPostToolData(
                    localStorage.getItem('datasetid'),
                    aggregateDataFinal,
                    this.fieldName,
                    this.filterOptions
                )
                .then((res) => {
                    if (res.data.Data === null) {
                        this.showFailure = true;
                        this.failureToastMessage =
                            'No data available to post. Please review options then retry';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    } else {
                        this.mapview = true;
                        this.postedDataLayers = res.data;
                        this.processData();
                    }
                })
                .catch((err) => {
                    if (
                        err.response.data.Message == 'ForbiddenSQLInjection' &&
                        err.response.data.StatusCode == 500
                    ) {
                        this.$emit(
                            'resForbiddenPosting',
                            err.response.data.Message
                        );
                    } else if (
                        err.response.data.includes(
                            'Cannot implicitly convert type'
                        ) &&
                        err.response.status == 500
                    ) {
                        this.showFailure = true;
                        this.failureToastMessage =
                            "We've run into an error. Please contact your system administrator for further assistance.";
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    } else {
                        this.showFailure = true;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
        },
        processData() {
            /*if (this.selectedPostingId) {
        let layerId = this.layerList.findIndex((item) => {
          return item.postingId == this.selectedPostingId;
        });
        this.layerList.splice(layerId, 1);
      }*/
            let dataSources = [];

            let aggreGateFieldData = [];

            this.aggreGateOption.forEach((item) => {
                aggreGateFieldData.push({
                    aggreGateField: item.aggregateOptionField,
                    aggreGateType: item.aggreGateType[0].aggreGateTypeName,
                    aggreGateDate: item.aggreGateType[0].aggreGateDate,
                });
            });

            let obj = {};
            this.dptData.forEach((ds) => {
                if (ds.geometry[0].x && ds.geometry[0].y) {
                    obj['result'] = ds.geometry[0].result;
                    obj['latitude'] = spatialFunctions.meters2lat(
                        ds.geometry[0].y
                    );
                    obj['longitude'] = spatialFunctions.meters2lng(
                        ds.geometry[0].x
                    );
                    dataSources.push(obj);
                }
            });

            let styleCode;
            let fontSize;
            let fontFamily;
            let fontWeight;
            let rotation;
            let transparency;
            if (this.styleSize) {
                fontSize = this.styleSize + 'px';
            } else {
                fontSize = '8px';
            }
            if (this.styleType) {
                fontFamily = this.styleType;
            } else {
                fontFamily = 'IBM Plex Sans Regular';
            }
            if (this.styleStyle) {
                fontWeight = this.styleStyle;
            } else {
                fontWeight = 'normal';
            }
            if (this.rotation) {
                rotation = 'rotate(' + this.rotation + 'deg)';
            } else {
                rotation = 'rotate(0deg)';
            }
            if (this.transparencyValue) {
                transparency = this.transparencyValue;
            } else {
                transparency = '0';
            }

            switch (this.dataLabelPosition) {
                case 'topLeft':
                    styleCode =
                        '<div><div style="margin-bottom: 30px;padding-right:60px;';
                    break;
                case 'topCenter':
                    styleCode =
                        '<div><div style="margin-bottom: 30px;padding-right:25px;';
                    break;
                case 'topRight':
                    styleCode = '<div><div style="margin-bottom: 30px;';
                    break;
                case 'bottomLeft':
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:50px;';
                    break;
                case 'bottomCenter':
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:20px;';
                    break;
                case 'bottomRight':
                    styleCode = '<div><div style="margin-top: 11px;';
                    break;
                case 'middleLeft':
                    styleCode =
                        '<div><div style="margin-bottom: 3px;padding-right:60px;';
                    break;
                case 'middleCenter':
                    styleCode =
                        '<div><div style="margin-bottom: 3px;padding-right:25px;';
                    break;
                case 'middleRight':
                    styleCode = '<div><div style="margin-bottom: 3px;';
                    break;
                default:
                    styleCode =
                        '<div><div style="margin-top: 11px;padding-right:20px;';
                    break;
            }
            styleCode += 'font-family:';
            fontFamily +
                ';font-size:' +
                fontSize +
                ';font-weight:' +
                fontWeight +
                ';font-style:' +
                fontWeight +
                ';transform:' +
                rotation +
                ';opacity:' +
                1 -
                transparency / 100 +
                '">${result}</div></div>';

            let markerSettings = [
                {
                    visible: true,
                    dataSource: this.postedDataLayers,
                    shape: 'Image',
                    imageUrl:
                        this.symbology == 'arrow'
                            ? '../../../assets/arrow_halved.svg'
                            : this.symbology == 'flag'
                            ? '../../../assets/flag_halved.svg'
                            : this.symbology == 'circle'
                            ? '../../../assets/circle_halved.svg'
                            : this.symbology == 'star'
                            ? '../../../assets/star_5_halved.svg'
                            : this.symbology == 'star3'
                            ? '../../../assets/star_3_halved.svg'
                            : this.symbology == 'star4'
                            ? '../../../assets/star_4_halved.svg'
                            : this.symbology == 'cross3'
                            ? '../../../assets/cross_3_halved.svg'
                            : this.symbology == 'cross5'
                            ? '../../../assets/cross_5_halved.svg'
                            : this.symbology == 'polygon3'
                            ? '../../../assets/polygon_3_halved.svg'
                            : this.symbology == 'polygon4'
                            ? '../../../assets/polygon_4_halved.svg'
                            : this.symbology == 'polygon5'
                            ? '../../../assets/polygon_5_halved.svg'
                            : '../../../assets/circle_halved.svg',

                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        // valuePath: "sitecode",
                        textStyle: {
                            size: this.styleSize
                                ? this.styleSize + 'px'
                                : '10px',
                            fontFamily: this.styleType
                                ? this.styleType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.styleStyle
                                ? this.styleStyle
                                : 'normal',
                        },
                    },
                },
                {
                    visible: true,
                    dataSource: dataSources,
                    template: styleCode,
                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        //  valuePath: "sitecode",
                        textStyle: {
                            size: this.styleSize
                                ? this.styleSize + 'px'
                                : '10px',
                            fontFamily: this.styleType
                                ? this.styleType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.styleStyle
                                ? this.styleStyle
                                : 'normal',
                        },
                    },
                },
            ];
            if (JSON.parse(localStorage.getItem('layerDetails'))) {
                this.localLayer = JSON.parse(
                    localStorage.getItem('layerDetails')
                );
            }
            this.layerDetails = {
                // postingId: this.postingId,
                visible: true,
                DisplayName: this.layerName,
                FieldName: this.fieldName,
                aggrOption: aggreGateFieldData,
                radioSource: this.postingSource,
                color: this.fillColor,
                pointSymbology: this.symbology,
                fontSize: this.styleSize,
                fontStyle: this.styleStyle,
                fontType: this.styleType,
                fontRotation: this.rotation,
                transparency: this.transparencyValue,
                markerSettings11: markerSettings,
                position: this.dataLabelPosition,
                datasetID: localStorage.getItem('datasetid'),
                filterOptions: this.filterOptions,
            };

            if (this.editLayer) {
                this.layerDetails.postingId = this.editedPostingId;
                this.localLayer[this.layerEditIndex] = this.layerDetails;

                this.layerEditIndex = '';
                this.editLayer = false;
            } else {
                this.layerDetails.postingId = this.postingId;
                this.postingId = this.postingId + 1;
                this.localLayer.push(this.layerDetails);
            }

            localStorage.setItem(
                'layerDetails',
                JSON.stringify(this.localLayer)
            );

            this.layerList = JSON.parse(localStorage.getItem('layerDetails'));
            //code that handles the initial setting of the show/hide toggle
            this.layerList.forEach((layer) => {
                if (layer.visible == true) {
                    layer.markerSettings11[0].dataSource.visible = true;
                    layer.markerSettings11[1].dataSource.visible = true;
                    layer.markerSettings11[0].visible = true;
                    layer.markerSettings11[1].visible = true;
                } else {
                    layer.markerSettings11[0].dataSource.visible = false;
                    layer.markerSettings11[1].dataSource.visible = false;
                    layer.markerSettings11[0].visible = false;
                    layer.markerSettings11[1].visible = false;
                }
            });
            this.aggreGateOption = [];
            this.aggreGateOption.push({
                aggreGateField: '',
                aggreGateType: [],
            });
            this.layerName = '';
            this.fieldName = null;
            this.colorSelected = null;
            // this.symbology = null;
            // this.styleSize = null;
            // this.styleStyle = null;
            // this.styleType = null;
            // this.fillColor=null;
            // this.transparencyValue = 100;
            this.edit_clicked = false;
            this.selectedPostingId = '';
            this.addDataToMainMap();
            // need this for the computed to update properly, hopefully there is a better option
            localStorage.setItem(
                'layerDetails',
                JSON.stringify(this.layerList)
            );
            this.layerList = JSON.parse(localStorage.getItem('layerDetails'));
        },
        togglePostedLayer(index) {
            //code toggles the visibility of the layer details localstorage item
            // let yesArray = [];
            // yesArray = JSON.parse(localStorage.getItem("layerDetails"));
            this.layerList[index].visible = !this.layerList[index].visible;
            // localStorage.setItem("layerDetails", JSON.stringify(yesArray));
            //end of code

            this.layerList[index].markerSettings11[0].dataSource.visible = !this
                .layerList[index].markerSettings11[0].dataSource.visible;
            this.layerList[index].markerSettings11[1].dataSource.visible = !this
                .layerList[index].markerSettings11[1].dataSource.visible;
            setTimeout(() => {
                this.reclusterPosted();
            }, 500);
            // this.layerList[index].visible = !this.layerList[index].visible;
            //
            // this.layerList[index].visible =
            //   !this.layerList[index].visible;
            // this.layerList[index].markerSettings11[1].dataSource.visible =
            //   !this.layerList[index].markerSettings11[1].dataSource.visible;
            //
            //
        },
        togglePostedLayerLabels(index) {
            this.layerList[
                index
            ].markerSettings11[0].dataSource.features.forEach((feature) => {
                feature.properties[0].label = !feature.properties[0].label;
            });
            // setTimeout(()=>{
            //   this.reclusterPosted()
            // }, 500)
        },
        toggleProjectLayerLabels(index) {
            this.projectDataLayers[0].ProjectLayers[
                index
            ].Geometry.features.forEach((feature) => {
                feature.properties[0].label = !feature.properties[0].label;
            });
            setTimeout(() => {
                this.reclusterProject();
            }, 500);
        },
        mapExtendSize() {
            this.mapExtend = !this.mapExtend;
            if (this.mapExtend == true) {
                this.mapClass = 'dataPost-map-box-expanded';
            }
            setTimeout(() => {
                this.$refs.lmap1.mapObject.invalidateSize();
            }, 100);
        },
        toggleProjectLayer(index) {
            this.projectDataLayers[0].ProjectLayers[
                index
            ].Geometry.visible = !this.projectDataLayers[0].ProjectLayers[index]
                .Geometry.visible;
            setTimeout(() => {
                this.reclusterProject();
            }, 500);
        },
        updateBounds(bounds) {
            this.mapBounds = bounds;
        },
        updateExpandedBounds(bounds) {
            this.expandedMapBounds = bounds;
        },
        zoomToPosted(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    minX = Math.min(minX, parseFloat(xCoord));
                    maxX = Math.max(maxX, parseFloat(xCoord));
                    minY = Math.min(minY, parseFloat(yCoord));
                    maxY = Math.max(maxY, parseFloat(yCoord));
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
            this.mapBounds = latLngBounds([
                [
                    spatialFunctions.correctLatitude(
                        minY - yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        minX - xRange * zoomCushion
                    ),
                ],
                [
                    spatialFunctions.correctLatitude(
                        maxY + yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        maxX + xRange * zoomCushion
                    ),
                ],
            ]);
            let mapExtent = [
                this.mapBounds._northEast,
                this.mapBounds._southWest,
            ];
            this.$store.commit('projects/updateMapExtent', mapExtent);
        },
        zoomToProject(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
            this.mapBounds = latLngBounds([
                [
                    spatialFunctions.correctLatitude(
                        minY - yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        minX - xRange * zoomCushion
                    ),
                ],
                [
                    spatialFunctions.correctLatitude(
                        maxY + yRange * zoomCushion
                    ),
                    spatialFunctions.correctLongitude(
                        maxX + xRange * zoomCushion
                    ),
                ],
            ]);
            let mapExtent = [
                this.mapBounds._northEast,
                this.mapBounds._southWest,
            ];
            this.$store.commit('projects/updateMapExtent', mapExtent);
        },
        validateTransparency() {
            if (isNaN(this.transparencyValue)) {
                this.transparencyValue = 50;
            } else if (this.transparencyValue < 0) {
                this.transparencyValue = 0;
            } else if (this.transparencyValue > 100) {
                this.transparencyValue = 100;
            }
        },
    },
};

Vue.directive('click-outside', {
    bind: function(element, binding, vnode) {
        element.clickOutsideEvent = function(event) {
            if (!(element === event.target || element.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', element.clickOutSideEvent);
    },
    unbind: function(element) {
        document.body.removeEventListener('click', element.clickOutsideEvent);
    },
});
</script>
<style lang="scss" scoped>
@import '../../../../node_modules/@syncfusion/ej2-base/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap4.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap4.css';

.main-content {
    // border-top: 1px solid #dee2e6;
    // width: 1140px;
    overflow: hidden;

    h5 {
        font-size: 18px;
        white-space: nowrap;
        color: #161616;
    }

    h3 {
        font-size: 22px;
    }

    .some-radios {
        font-size: 14px;
        color: #626262;
    }

    // .custom-select {
    //   background: #fff url("../../assets/arrow_down.svg") no-repeat right 0.75rem
    //     center/8px 10px !important;
    //   width:75%;
    //   height: 48px;
    // }
}

.aggr-edit,
.layer-post {
    width: 241px;
}

.main-content.edit-layer {
    max-height: 485px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 0px;

    .inputbox-edit,
    .select-edit {
        width: 241px;
        border-radius: 3px;
        padding: 7px;
        height: 48px;
        border: 1px solid rgb(206, 212, 218);
    }

    .subSelect {
        width: 180px;
        float: left;
    }

    .dlt-icon {
        position: absolute;
        right: 20px;
    }

    .edit.select-edit {
        width: 203px;
        margin-left: 15px;
    }
}

// .inputbox {
//   width: 499px;
//   border-radius: 3px;
//   padding: 7px;
//   height: 48px;
//   border: 1px solid rgb(206, 212, 218);
// }
.inputbox :focus {
    border: none;
}

.deletebtn {
    width: 48px;
    height: 48px;
    //float: right;
    margin-top: -63px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}

.inputbox-color {
    height: 48px;
    width: 100%;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}

.pencil-icon {
    cursor: pointer;
}

.buttonSelect {
    position: absolute;
    right: 0;
    top: -45px;
}

.overflow-icon {
    position: absolute;
    /* left: 128px; */
    margin-left: -37px;
    margin-top: 2px;
    cursor: pointer;
    width: 43px;
}

.inputbox :focus {
    outline: none;
    border: 1px solid !important;
}

.aggrType {
    margin-left: 36px;
    margin-top: -23px;
    width: 400px;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
}

.headergroup.edit-header {
    width: 241px;
    margin-bottom: 15px;
}

.map_view_icon {
    background-color: grey;
    position: relative;
    bottom: 10px;
    border: 0 !important;

    // left: 8%;
    // float: right;
    .map_view_icon:focus {
        // outline: 1px dotted;
        outline: 0px;
    }
}

.search_element {
    background-image: url(../../assets/search_dots.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
}

.pos_layer {
    width: auto;
    background-color: #f4f4f4;
    height: 80px;
    font-size: 0.9rem;
    font-weight: 500;
    // border-bottom-color: #006395;
    // border-bottom-style: solid;
    // border-bottom-width: 4px;
}

.pos_layer.hover {
    background-color: #f4f4f4;
}

.dataposting-icon {
    padding: 4px 2px 16px 15px;
    margin-left: -17px;
    background: #eaeaea;
}

.posted_layer {
    background: transparent;
    color: #1d1d1d;
    font-weight: 500;
    position: relative;
    top: 0px;
    width: 100%;
    font-size: 16px;
    border: 0px;
    height: 45px;
    white-space: nowrap;
}

.posted_layer.hover {
    background: white;
    color: #1d1d1d;
}

.project_layer {
    background: transparent;
    color: #1d1d1d;
    width: 144px;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    border: 0;
    height: 45px;
}

.project_layer_active {
    background: transparent;
    color: #1d1d1d;
    width: 145px;
    margin-left: -38px;
    margin-top: 0px;
    font-size: 16px;
    border: 0;
    height: 45px;
}

.proj_layer {
    height: 43px;
    font-weight: 500;
    width: 100%;
}

/* ---------------------------------------------Position Button Css------------------------------------- */
.tl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tl_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
}

.tc_button {
    background-color: transparent;
    border: none;
    color: black;
    width: 86px;
    height: 70px;
    position: relative;
    // right: 12px;
    // bottom: -1px;
}

.tc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tr_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 90px;
    // right: 13px;
    // bottom: -2px;
    height: 70px;
}

.tr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

/*--------------------------------position for edit button css------------------------*/
.edit-positon .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    border-radius: 0;
}

.edit-position-wide .btn-secondary {
    color: #1d1d1d !important;
    background-color: transparent !important;
    border: 2px solid #1d1d1d;
    width: 86.67px;
    height: 70px;
    border-radius: 0;
}

.edit-layer .btn-secondary:focus,
.edit-layer .btn-secondary:not(:disabled):not(.disabled):active {
    color: #1d1d1d !important;
    background-color: #eaeaea !important;
    border: 2px solid #1d1d1d;
    width: 80px;
    height: 70px;
    //right: 15px;
    position: relative;
    //bottom: -4px;
    border-radius: 0;
}

.edit-layer .border-dark {
    height: 70px;
    padding: 0px;
    text-align: center;
    width: 80px;
}

.tl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tl_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    // right: 8px;
    // bottom: -5px;
}

.tc_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    width: 80px;
    height: 70px;
    position: relative;
    //right: 15px;
    //bottom: -4px;
}

.tc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.tr_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    // right: 22px;
    // bottom: -10px;
    height: 70px;
}

.tr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.ml_button.edit {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    //left: -16px;
    //top: 12px;
    width: 80px;
    height: 70px;
}

.ml_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    width: 80px;
    height: 70px;
}

.mc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.mc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

// .btn-secondary{
//   height:69px;
// }
.mr_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -9px;
    background-color: transparent;
    border: none;
    color: black;
}

.mr_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bl_button.edit {
    position: relative;
    // bottom: -13px;
    // right: 21px;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bl_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bc_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bc_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.br_button.edit {
    position: relative;
    width: 80px;
    height: 70px;
    //right: 14px;
    // top: 11px;
    background-color: transparent;
    border: none;
    color: black;
}

.br_button.edit:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.edit-button {
    color: #007bff !important;
    margin-top: 0px;
    //margin-left: 96px;
}

.delete-datapost {
    margin-left: none !important;
    margin-top: 9px !important;
    font-size: 14px;
    color: #817070 !important;
}

.border-right {
    height: 20px;
}

.input-value {
    display: inline-flex;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    margin-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 4px;
    margin-right: 0px;
    size: 3ch;
    text-align: center;
    border-style: hidden;
}

@media only screen and (max-width: 767px) {
    .input-value {
        margin-top: 7px;
    }
}

.ml_button {
    background-color: transparent;
    border: none;
    color: black;
    position: relative;
    width: 80px;
    height: 70px;
}

.ml_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    ////border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
    position: relative;
    width: 80px;
    height: 70px;
    //height: 70px;
}

.mc_button {
    position: relative;

    width: 80px;
    height: 70px;

    background-color: transparent;
    border: none;
    color: black;
}

.mc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.mr_button {
    position: relative;
    width: 80px;
    height: 70px;
    // right: 13px;
    // bottom: -2px;
    background-color: transparent;
    border: none;
    color: black;
}

.mr_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bl_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bl_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.bc_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
}

.bc_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

.br_button {
    position: relative;
    width: 80px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: black;
    // right:14px;
}

.br_button:hover {
    background-color: #eaeaea;
    color: #1d1d1d !important;
    //border: 1px solid #1d1d1d !important;
    border-radius: 0 !important;
}

/* ------------------------------------------Position Button Css Ends Here------------------------------- */
.proj_layer.hover {
    background-color: white;
}

.slider_value {
    position: relative;
    bottom: 155px;
    left: 380px;
}

.slider {
    left: -3px !important;
}

/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 300px;
    text-align: center;
}

/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    /* margin: 0 !important; */
    // margin-left: -140px !important;
    /* margin-right: 20px; */
    // margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    //width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}

.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}

/* To hide primary button */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
    display: none !important;
}

/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    padding-bottom: 3px;
    border-bottom-width: 2px;
    border-bottom-color: #e3165b;
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}

.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}

.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}

.mapToolAttribute {
    width: auto;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 33%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
}

.wrap {
    display: inline-block;
    width: 499px;
    height: 48px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background: #fff;
    vertical-align: middle;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 20px;
    text-align: left;
}

.pickColor button {
    float: right;
    background: #fff;
    border: 0;
    margin-top: -17px;
}

.btn-transparent {
    position: inherit;
    top: 20px;
    right: 10px;
    height: 40px;
    width: 40px;
}

// .e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
#element.e-input {
    height: 16px;
    margin: 0;
    opacity: 1;
    position: relative;
    width: 75px;
    //left: 20px;
    top: 18px;
    left: 45px;
    border: 0 !important;
}

// real call is in global.scss
// .leafletLabelClass {
//   background-color: transparent;
//   border: transparent;
//   box-shadow: none;
//   font-size: 12pt;
// }
.hidebtn {
    left: 50px;
}

.dataposting .portalbarMain {
    border-block: 0 !important;
}

.layer {
    width: 240px;

    .inputrange.edit {
        width: 125px !important;
    }
}

.range-field {
    width: 365px;
}

@media only screen and (max-width: 767px) {
    .inputrange {
        width: 215px;
    }

    .range-field {
        width: 215px;
    }
}

.accBorder {
    // border-left: solid 1px #dee2e6;
    border-right: solid 1px #dee2e6;
    border-bottom: solid 1px#dee2e6;
}

.edit-layer .inputrange {
    width: 125px !important;
}

.edit-layer .headergroup {
    font-size: 14px;
}
.edit-layer .input-value {
    font-size: 12px;
    size: 3ch;
}

.layer-post .select-edit:not(:first-child) {
    width: 180px !important;
}

.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    background: rgb(0, 99, 149) none repeat scroll 0% 0% !important;
    color: white !important;
    border: medium none;
    white-space: nowrap;
}

.edit-layer .bottom-btn .btn-secondary:focus,
.edit-layer .bottom-btn .btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff !important;
    background-color: #006395 !important;
    width: auto;
    height: auto;
    //right: 15px;
    position: relative;
    border-radius: 0.25rem;
}

.add-btn {
    padding-top: 10px !important;
    border-radius: 3px !important;
}

.overflow {
    overflow-y: scroll;
    max-height: 394px;
}

.hideText {
    margin-right: 10px;
}

.modalScreen {
    height: 85vh;
    z-index: 999999;
}

.map_arrow {
    position: absolute;
    /* left: -13px; */
    z-index: 999;
}

.modalFullscreen .modal-dialog {
    max-width: none !important;
    padding: 25px;
}

.dataposting .edit-button {
    margin-top: 9px !important;
    color: #007bff !important;
    margin-right: 0px !important;
}

.rowData {
    margin-top: 6px;
}

.backtopost {
    position: absolute;
    top: -100px;
    right: 0;
}

.card-body {
    padding: 0;
}

.collapsed .when-opened {
    display: none;
}

.not-collapsed .when-closed {
    display: none;
}

.imgIndent {
    position: relative;
    left: 35px;
    top: 27px;
}

.dateAggregate {
    margin-top: -22px;
}

.dataPost-map-box {
    height: 440px;
    width: 885px;
}

.dataPost-map-box-expanded {
    height: 440px;
}

.dataPost-map-box-print {
    position: absolute;
    height: 7.5in;
    width: 10in;
    top: 0.5in;
    left: 0.5in;
}

@media print {
    .map-control-button,
    .leaflet-control-layers,
    .leaflet-control {
        opacity: 0;
    }

    #lmap1 {
        opacity: 1;
        height: 10in;
        width: 15in;
    }
}

.printButton {
    background-color: #fff !important;
    padding: 6px !important;
    border-radius: 5px;
    border: 2px solid #bbb !important;
    min-width: 44px;
    min-height: 44px;
}

.print-map-control {
    background: none;
    padding: 0;
    border: none;
    border-radius: 5px;
}

.printerSVG {
    fill: #000;
    stroke: #fff;
}

.showHide {
    font-size: 14px;
    margin-top: 11px;
}

.zoomTo {
    color: #006395 !important;
    margin-top: 9px !important;
    padding-right: 5px !important;
    margin-right: 5px !important;
}

.project_layers {
    background-color: #f4f4f4;
    height: 44px;
}

.btn-side {
    background-color: transparent;
    background-color: white;
    border-radius: 6px;
    border: none;
    height: 20px;
}

.btn-zoom {
    position: absolute;
    top: 25px;
    left: 12px;
}

.btn-edit {
    position: absolute;
    top: -10px;
}

.btn-delete {
    position: absolute;
    left: 0px;
    top: 39px;
}

.dataposting .pickColor .e-colorpicker-wrapper .e-btn.e-btn.e-icon-btn {
    width: 45px !important;
}

.accordion-more {
    width: 500px;
}

.view-more {
    height: 50px;
}

.view-more.not-collapsed:hover {
    background-color: #006395;
}

.view-more.collapsed {
    background-color: #006395;
}
.view-more.collapsed:hover {
    background-color: #00496d !important;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
</style>
