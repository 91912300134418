<template>
    <div class="project-select-page">
        <Header />
        <img
            id="referenceCircleImage"
            src="../../assets/circle.svg"
            class="d-none"
        />
        <img
            id="referenceSiteTickImage"
            src="../../assets/icon_tick_lg1.svg"
            class="d-none"
        />
        <router-view></router-view>

        <Footer />
    </div>
</template>

<script>
// import router from '../../router';
import Header from '../Header';
// import SubHeader from '../SubHeader';
import Footer from '../Footer';

export default {
    name: 'Projects',
    components: {
        Header,
        // SubHeader,
        Footer,
    },
    data() {
        return {
            mapView: false,
            SelectedProjects: false,
            showHeaderMap: false,
            showHeaderSelectedProject: false,
        };
    },
    mounted() {
        // this.getPermissions();
        this.pagePermit();
    },
    methods: {
        // navigateDashboard:function() {
        //   router.push(`/classic/dashboard')
        // },

        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            vm.mapView = true;
            vm.showHeaderMap = true;
            data.forEach((element) => {
                if (element.PageName.includes('MapView')) {
                    vm.mapView = true;
                }
                if (element.PageName.includes('SelectedProjects')) {
                    vm.SelectedProjects = true;
                }
            });
            if (
                (this.$route.name === 'map' && this.mapView) ||
                (this.$route.name === 'selectedprojects' &&
                    this.SelectedProjects)
            ) {
                this.showHeaderMap = true;
                this.showHeaderSelectedProject = false;
            }
            if (this.$route.name === 'map' && this.mapView) {
                this.showHeaderMap = true;
                this.showHeaderSelectedProject = false;
            }
            if (this.$route.name === 'map' && !this.mapView) {
                this.showHeaderMap = false;
                this.showHeaderSelectedProject = false;
            }
            if (
                this.$route.name === 'selectedprojects' &&
                !this.SelectedProjects
            ) {
                this.showHeaderSelectedProject = false;
                this.showHeaderMap = false;
            }
        },
    },
};
</script>

<style scoped>
.breadcrumb {
    background-color: white !important;
}

.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

@media screen and (max-width: 768px) {
    .project-select-page {
        overflow: hidden;
        bottom: 0;
        top: 0;
        position: absolute;
    }
}
</style>
