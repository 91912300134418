import axios from 'axios';
import { commonService } from '../services/common.service';
import store from './../store';
import router from './../router';

export function request(config) {
    const instance = axios.create({
        // timeout: 5000 //and other settings available can be used here;
    });
    instance.interceptors.request.use(
        (config) => {
            let token = commonService.getCookie('accessToken');
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (err) => {}
    );

    instance.interceptors.response.use(
        (res) => {
            //reset token renew count to 0 when a good response is made
            store.commit('common/resetCount');
            return res;
        },
        async (err) => {
            const originalRequest = err.config;
            var userDetails = JSON.parse(localStorage.getItem('userDetails'));
            //perform token renew request and replay original request, but up to 10 times
            if (userDetails.isEmailLogin && err.response.status === 401) {
                let refreshToken = commonService.getCookie('refreshToken');
                const apiUrl =
                    process.env.VUE_APP_API_URI +
                    'UserToken/RefreshToken?refresh=' +
                    refreshToken;
                return axios
                    .post(apiUrl)
                    .then((response) => {
                        document.cookie = `accessToken=${response.data};path=/;`;
                        return instance(originalRequest);
                    })
                    .catch((error) => {
                        localStorage.removeItem('userDetails');
                        document.cookie.split(';').forEach(function(c) {
                            document.cookie = c
                                .replace(/^ +/, '')
                                .replace(/=.*/, '=;max-age=-1;path=/');
                        });
                        router.push(`/`);
                    });
            } else if (
                err.response.status === 401 &&
                store._vm.$msalInstance.getAllAccounts()[0] &&
                store.state.common.tokenRefreshCount < 15
            ) {
                store.dispatch('common/incrementTokenRenewCount');
                await store.dispatch('common/renewToken');
                return instance(originalRequest);
            }
            //clear accessToken cookie and send user to Login page
            else if (
                err.response.status === 401 &&
                store._vm.$msalInstance.getAllAccounts()[0] &&
                store.state.common.tokenRefreshCount >= 15
            ) {
                document.cookie =
                    'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
                router.push(`/`);
                return Promise.reject(err);
            } else if (err.response.status === 403) {
                return Promise.reject(err);
            } else if (err.response.status === 500) {
                // return the error response so that we can error handle api errors
                return err.response;
            } else {
                console.log(err.response.status);
                return Promise.reject(err);
            }
        }
    );

    return instance(config);
}
