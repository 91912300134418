<template>
    <div class="button">
        <img src="../../assets/edit.svg" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            data: {},
        };
    },
    methods: {
        // click: function (e) {
        //     this.$eventHub.$emit('detail', e);
        // }
    },
};
</script>
