<template lang="">
    <div class="iframe-container">
        <iframe :src="iframeSrc" frameborder="0"></iframe>
    </div>
</template>
<script>
import { projectService } from '../services';
import { commonService } from '../services/common.service';
import router from './../router';
import store from './../store';

export default {
    name: 'iFrame',
    data() {
        return {
            iframeSrcData: this.$route.params.url,
            accessToken: '',
            refreshToken: '',
        };
    },
    methods: {
        async openInSite() {
            if (this.$route.params.toolId === undefined) {
                await this.userToolOption();
            }
            const res = await projectService.GetExternalAppContent({
                toolId: this.$route.params.toolId,
            });
            this.accessToken = res.data.access;
            this.refreshToken = res.data.refresh;
        },
        async userToolOption() {
            const res = await commonService.getUserToolOption();
            const insiteTool = res.data.find(
                (option) => option.ToolName === 'InSite'
            );
            this.$route.params.toolId = insiteTool.ToolID;
            this.iframeSrcData = insiteTool.Url;
        },
        setupMessageListener() {
            window.addEventListener('message', this.handleMessageEvent);
        },
        handleMessageEvent(event) {
            if (event.data.message === 'Invalid') {
                this.updatePathWithSiteCode(event.data.sitecode);
                this.openInSite();
            } else if (event.data.message === 'sitecode_change') {
                this.updatePathWithSiteCode(event.data.sitecode);
                let redirectPath = this.$store.state.store.goToPage;
                if (redirectPath !== null) {
                    this.$store.commit('store/clearGoToPage');
                    this.$router.replace(redirectPath);
                }
            }
        },
        updatePathWithSiteCode(sitecode) {
            const currentpath = this.$route.path;
            if (sitecode) {
                let path = currentpath;
                const newSiteCode = `/SiteCode/${sitecode}`;
                const siteCodeIndex = path.includes('/SiteCode')
                    ? path.indexOf('/SiteCode')
                    : path.length;
                path = `${path.substring(0, siteCodeIndex)}${newSiteCode}`;
                if (path != currentpath)
                    this.$store.commit('store/setGoToPage', path);
            }
        },
    },
    created() {
        this.openInSite();
    },
    computed: {
        iframeSrc() {
            let baseurl = this.iframeSrcData;
            const sitecode = this.$route.params.id;
            if (baseurl != undefined) {
                if (baseurl.endsWith('/')) baseurl = baseurl.slice(0, -1);
                baseurl = `${baseurl}?access=${this.accessToken}&refresh=${this.refreshToken}`;
                if (sitecode) baseurl = `${baseurl}&sitecode=${sitecode}`;
            }
            return baseurl;
        },
    },
    mounted() {
        this.setupMessageListener();
    },
    beforeDestroy() {
        window.removeEventListener('message', this.handleMessageEvent);
    },
};
</script>
<style scoped>
.iframe-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.iframe-container iframe {
    width: 100vw;
    height: 100vh;
}
</style>
