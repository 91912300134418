<!-- 
    This component will appear on the header if there are atleast one project selected
    if selected projects are < 3, list will appear in a row
    else selected projects will be displayed in a dropdown 
    Communicate with vue store to read selected project list and remove values from it
 -->
<template>
    <div
        class="outer-container container-row"
        v-if="selectedProjectsArr.length > 0 && $route.name == 'Map'"
    >
        <div
            class="child-topic-text d-none d-sm-none d-md-none d-lg-block d-xl-block"
        >
            <span class="selected-projects-text pl-3"
                >Selected Project(s):</span
            >
        </div>
        <div
            class="projects-name-wrapper"
            v-if="selectedProjectsArr.length < 4"
        >
            <div
                class="child"
                v-for="(project, index) in selectedProjectsArr"
                :key="index"
            >
                <div class="child-detail-container">
                    <span class="project-name-text">{{
                        project.ProjectName
                    }}</span>
                    <div
                        class="close-img-div"
                        @click="removeProject($event, project.ProjectID)"
                    >
                        <img class="close-img" src="../assets/close.svg" />
                    </div>
                </div>
            </div>
        </div>
        <div
            class="view-projects-outer-container"
            v-else
            v-click-outside="hide"
        >
            <div class="view-projects-text-container">
                <span class="view-projects-text">{{
                    `View projects(${selectedProjectsArr.length})`
                }}</span>
                <span
                    @click="expandList"
                    :class="{
                        'view-projects-img-click': isExpand,
                        'view-projects-img': !isExpand,
                    }"
                    >></span
                >
            </div>
            <div class="project-list-hidden-container" v-if="isExpand">
                <div
                    class="hidden-project-list"
                    v-for="(project, index) in selectedProjectsArr"
                    :key="index"
                >
                    <span class="project-name-text">{{
                        project.ProjectName
                    }}</span>
                    <div @click="removeProject($event, project.ProjectID)">
                        <img class="list-close-img" src="../assets/close.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import clickOutside from '../directives/clickOutside';
export default {
    directives: {
        clickOutside,
    },
    data() {
        return {
            isExpand: false,
        };
    },
    methods: {
        ...mapActions('projects', {
            removeSelectedProject: 'deselectProject',
        }),
        ...mapActions('emergencyManagement', {
            resetEMStore: 'resetStore',
        }),
        expandList(ev) {
            ev.preventDefault();
            ev.stopPropagation();
            this.isExpand = !this.isExpand;
        },
        hide() {
            this.isExpand = false;
        },
        removeProject(ev, projectId) {
            ev.preventDefault();
            ev.stopPropagation();
            this.removeSelectedProject(projectId);
            this.resetEMStore();
            this.$store.commit('projectLayers/clearMapData');
        },
    },
    computed: {
        ...mapState({
            selectedProjectsArr: function(state) {
                return state.projects.selectedProjects;
            },
        }),
    },
};
</script>

<style scoped>
.outer-container {
    min-width: fit-content;
    border-left: 1px solid #707070;
    padding: 0;
    position: relative;
    float: left;
    margin-left: 5px;
    height: 31px;
    display: flex;
}

.projects-name-wrapper {
    position: relative;
    float: left;
    width: fit-content;
    display: flex;
    flex-direction: row;
}
.container-row {
    flex-direction: row;
}
.child-topic-text {
    position: relative;
    float: left;
    width: fit-content;
    align-self: center;
}
.child {
    position: relative;
    min-width: 5%;
    overflow: hidden;
}
.child-detail-container {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    position: relative;
    min-width: 95%;
    overflow: hidden;
    height: 38px;
    float: left;
    margin-left: 10px;
}
.selected-projects-text {
    min-width: max-content;
    position: relative;
    float: left;
    font-size: 1em;
    letter-spacing: 0px;
    color: #212121;
}
.project-name-text {
    position: relative;
    float: left;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    margin-top: 5px;
    color: #000000de;
}
.close-img-div {
    position: relative;
    float: right;
    margin-left: 15px;
    width: 10px;
    height: 10px;
    margin-right: 16px;
    cursor: pointer;
    margin-top: 2px;
}
.view-projects-outer-container {
    background: #f8f8f8 0% 0% no-repeat padding-box;
    position: relative;
    height: 38px;
    float: left;
    width: 165px;
    margin-top: -3px;
}
.view-projects-text-container {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 38px;
    cursor: pointer;
}
.view-projects-text {
    color: #000000de;
    font-size: 14px;
    font-weight: bold;
}
.view-projects-img {
    color: #000000de;
    font-size: 14px;
    font-weight: bold;
    transform: rotate(90deg);
    cursor: pointer;
}
.view-projects-img-click {
    color: #000000de;
    font-size: 14px;
    font-weight: bold;
    transform: rotate(270deg);
    cursor: pointer;
}
.project-list-hidden-container {
    position: absolute;
    width: 313px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    z-index: 7;
    top: 38px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 198px;
    max-height: 396px;
    overflow: auto;
    box-shadow: 0px 3px 6px #00000029 !important;
}
.hidden-project-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #d1d1d1;
    width: 100%;
    min-height: 66px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
}
.list-close-img {
    float: right;
    margin-right: 20px;
}
@media only screen and (max-width: 600px) {
    .outer-container {
        display: none;
    }
}
</style>
