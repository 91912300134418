<template>
    <div>
        <div class="Que-btn">
            <small
                v-if="reviewItem.length"
                class="text-primary reviewQueueButton"
                @click="reviewQueue"
                >Review queue</small
            >
            <small
                v-if="!reviewItem.length"
                class="text-secondary reviewQueueButton"
                >Review queue</small
            >
            <div v-if="reviewItem.length">
                <b-button
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueChanges()"
                    >Queue changes
                </b-button>
            </div>
            <div v-if="!reviewItem.length">
                <b-button
                    v-if="groupIds.length || dataSetIds.length"
                    rounded
                    class="buttonSelect queueChange ml-3"
                    @click="queueChanges()"
                    >Queue changes
                </b-button>
                <b-button
                    v-if="!groupIds.length || !dataSetIds.length"
                    rounded
                    class="buttonSelect disableButton queueChange ml-3"
                    >Queue changes
                </b-button>
            </div>
        </div>

        <b-row class="access-blk">
            <b-col class="mt-2">
                <div class="card bg-light mb-3 border-grey">
                    <div class="card-header">Groups</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchGroups && !showSearchgroup
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroup"
                                    @mouseover="searchOpenGroup"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchGroups && !showSearchgroup
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenGroup"
                                    @mouseover="searchOpenGroup"
                                    >Search</span
                                >
                                <input
                                    ref="groups"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchGroups || showSearchgroup
                                            ? true
                                            : false
                                    "
                                    v-model="searchGroups"
                                    :class="
                                        searchGroups || showSearchgroup
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseGroup"
                                    @click="closeBarGroup"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header">
                                <!-- <input
                    type="checkbox"
                    class="pl-3 ml-2"
                    @click="selectAllGroups"
                    v-model="allSelectedGroups"
                    />
                    <span class="portal-lbl"    
                    >Name</span
                    > -->
                                <input
                                    type="checkbox"
                                    class="pl-3 ml-2"
                                    @click="selectAllGroups"
                                    v-model="allSelectedGroups"
                                />
                                <span class="portal-lbl">Name</span>
                            </div>
                            <b-row class="showProjectList">
                                <table
                                    class="table"
                                    striped
                                    :per-page="perPage"
                                >
                                    <!-- <thead>
                                    <tr>
                                    
                                    <th scope="col">
                                    </tr>
                                </thead> -->
                                    <tbody :per-page="perPage">
                                        <tr
                                            v-for="(g,
                                            index) in groupDataFilteredList"
                                            :key="index"
                                            :value="g.UserGroupName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card
                                                    no-body
                                                    class="mb-1 card-wide"
                                                >
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            @click="
                                                                checkedGroups(
                                                                    g.UserGroupID
                                                                )
                                                            "
                                                            :value="
                                                                g.UserGroupID
                                                            "
                                                            v-model="groupIds"
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion1' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                g.UserGroupName
                                                            }}</span>
                                                        </b-button>
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion1' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesGroups[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <b-card-body
                                                            class="contentCollapse"
                                                        >
                                                            <table
                                                                class="text-card tableOne inner-btn"
                                                                striped
                                                            >
                                                                <tbody>
                                                                    <tr
                                                                        v-if="
                                                                            g.Projects &&
                                                                                g
                                                                                    .Projects
                                                                                    .length
                                                                        "
                                                                    >
                                                                        <b-row
                                                                            class="row1"
                                                                        >
                                                                            <img
                                                                                class="pointer ml-2 my-3"
                                                                                src="../../../assets/search-outline.svg"
                                                                                v-if="
                                                                                    !searchDatasetProjectModel &&
                                                                                    !showSearchProject
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenGrouplProject(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenGrouplProject(
                                                                                        index
                                                                                    )
                                                                                "
                                                                            />
                                                                            <span
                                                                                class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                v-if="
                                                                                    !searchDatasetProjectModel &&
                                                                                    !showSearchProject
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                @click="
                                                                                    searchOpenGrouplProject(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                @mouseover="
                                                                                    searchOpenGrouplProject(
                                                                                        index
                                                                                    )
                                                                                "
                                                                                >Search</span
                                                                            >
                                                                            <input
                                                                                ref="groupsInnerProjectM"
                                                                                spellcheck="false"
                                                                                id="searchFilter"
                                                                                class="width365"
                                                                                v-if="
                                                                                    searchDatasetProjectModel ||
                                                                                    showSearchProject
                                                                                        ? true
                                                                                        : false
                                                                                "
                                                                                v-model="
                                                                                    searchDatasetProjectModel
                                                                                "
                                                                                :class="
                                                                                    searchDatasetProjectModel ||
                                                                                    showSearchProject
                                                                                        ? 'test'
                                                                                        : 'searchinputList'
                                                                                "
                                                                                type="search"
                                                                                placeholder="Start typing and hit enter to search"
                                                                            />
                                                                            <span
                                                                                class="grey-bg pointer"
                                                                                v-if="
                                                                                    searchCloseProjectsMain
                                                                                "
                                                                                @click="
                                                                                    closeBargroupProject
                                                                                "
                                                                                ><img
                                                                                    src="../../../assets/close.svg"
                                                                            /></span>
                                                                        </b-row>
                                                                    </tr>
                                                                    <tr
                                                                        v-for="(gp,
                                                                        indexProjects) in $options.filters.filterByProperty(
                                                                            g.Projects,
                                                                            '',
                                                                            'ProjectName',
                                                                            searchDatasetProjectModel
                                                                        )"
                                                                        :key="
                                                                            indexProjects
                                                                        "
                                                                    >
                                                                        <!-- <tr v-for="(gp, indexProjects) in g.Projects"  :key="indexProjects"> -->
                                                                        <td>
                                                                            <b-card
                                                                                no-body
                                                                                class="mb-1"
                                                                            >
                                                                                <b-card-header
                                                                                    header-tag="header"
                                                                                    class="p-0 accordian-header"
                                                                                    role="tab"
                                                                                >
                                                                                    <b-button
                                                                                        block
                                                                                        v-b-toggle="
                                                                                            'imagedetails-accordion4' +
                                                                                                index +
                                                                                                indexProjects
                                                                                        "
                                                                                        class="accordion-button inside"
                                                                                    >
                                                                                        <span
                                                                                            class="marg-left-20"
                                                                                            >{{
                                                                                                gp.ProjectName
                                                                                            }}</span
                                                                                        >
                                                                                    </b-button>
                                                                                </b-card-header>
                                                                                <b-collapse
                                                                                    :id="
                                                                                        'imagedetails-accordion4' +
                                                                                            index +
                                                                                            indexProjects
                                                                                    "
                                                                                    :accordion="
                                                                                        'imagedetails-accordion4' +
                                                                                            index +
                                                                                            indexProjects
                                                                                    "
                                                                                    role="tabpanel"
                                                                                    class="collapseLevel2"
                                                                                >
                                                                                    <b-card-body
                                                                                        class="contentCollapse tblLevel2"
                                                                                    >
                                                                                        <table
                                                                                            class="text-card"
                                                                                            striped
                                                                                        >
                                                                                            <tbody>
                                                                                                <tr
                                                                                                    v-if="
                                                                                                        gp.Datasets &&
                                                                                                            gp
                                                                                                                .Datasets
                                                                                                                .length
                                                                                                    "
                                                                                                >
                                                                                                    <b-row
                                                                                                        class="row1"
                                                                                                    >
                                                                                                        <img
                                                                                                            class="pointer ml-2 my-3"
                                                                                                            src="../../../assets/search-outline.svg"
                                                                                                            v-if="
                                                                                                                !searchDatasetNameModel &&
                                                                                                                !showSearchGroupsInner
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            "
                                                                                                            @click="
                                                                                                                searchOpenGrouplInner(
                                                                                                                    index
                                                                                                                )
                                                                                                            "
                                                                                                            @mouseover="
                                                                                                                searchOpenGrouplInner(
                                                                                                                    index
                                                                                                                )
                                                                                                            "
                                                                                                        />
                                                                                                        <span
                                                                                                            class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                                            v-if="
                                                                                                                !searchDatasetNameModel &&
                                                                                                                !showSearchGroupsInner
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            "
                                                                                                            @click="
                                                                                                                searchOpenGrouplInner(
                                                                                                                    index
                                                                                                                )
                                                                                                            "
                                                                                                            @mouseover="
                                                                                                                searchOpenGrouplInner(
                                                                                                                    index
                                                                                                                )
                                                                                                            "
                                                                                                            >Search</span
                                                                                                        >
                                                                                                        <input
                                                                                                            ref="groupsInner"
                                                                                                            spellcheck="false"
                                                                                                            id="searchFilter"
                                                                                                            class="width365"
                                                                                                            v-if="
                                                                                                                searchDatasetNameModel ||
                                                                                                                showSearchGroupsInner
                                                                                                                    ? true
                                                                                                                    : false
                                                                                                            "
                                                                                                            v-model="
                                                                                                                searchDatasetNameModel
                                                                                                            "
                                                                                                            :class="
                                                                                                                searchDatasetNameModel ||
                                                                                                                showSearchGroupsInner
                                                                                                                    ? 'test'
                                                                                                                    : 'searchinputList'
                                                                                                            "
                                                                                                            type="search"
                                                                                                            placeholder="Start typing and hit enter to search"
                                                                                                        />
                                                                                                        <span
                                                                                                            class="grey-bg pointer"
                                                                                                            v-if="
                                                                                                                searchCloseGroupInner
                                                                                                            "
                                                                                                            @click="
                                                                                                                closeBargroupInner
                                                                                                            "
                                                                                                            ><img
                                                                                                                src="../../../assets/close.svg"
                                                                                                        /></span>
                                                                                                    </b-row>
                                                                                                </tr>
                                                                                                <tr
                                                                                                    v-for="dk in $options.filters.filterByProperty(
                                                                                                        gp.Datasets,
                                                                                                        '',
                                                                                                        'DatasetAlias',
                                                                                                        searchDatasetNameModel
                                                                                                    )"
                                                                                                    :key="
                                                                                                        dk.DatasetAlias
                                                                                                    "
                                                                                                    :value="
                                                                                                        dk.DatasetAlias
                                                                                                    "
                                                                                                >
                                                                                                    <td
                                                                                                        class=""
                                                                                                    >
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            class="slash-checkbox ml-5"
                                                                                                            checked
                                                                                                            @click="
                                                                                                                childCheck(
                                                                                                                    g.UserGroupID,
                                                                                                                    dk.ProjectDatasetID,
                                                                                                                    $event,
                                                                                                                    false
                                                                                                                )
                                                                                                            "
                                                                                                            v-if="
                                                                                                                dk.IsIncluded &&
                                                                                                                    userGroupDatasetExclude[
                                                                                                                        g
                                                                                                                            .UserGroupID
                                                                                                                    ].indexOf(
                                                                                                                        dk.DatasetId
                                                                                                                    ) ==
                                                                                                                        -1
                                                                                                            "
                                                                                                        />
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            class="slash-checkbox"
                                                                                                            @click="
                                                                                                                childCheck(
                                                                                                                    g.UserGroupID,
                                                                                                                    gp.ProjectDatasetID,
                                                                                                                    $event,
                                                                                                                    true
                                                                                                                )
                                                                                                            "
                                                                                                            :disabled="
                                                                                                                userGroupDatasetExclude[
                                                                                                                    g
                                                                                                                        .UserGroupID
                                                                                                                ].indexOf(
                                                                                                                    dk.DatasetId
                                                                                                                ) >
                                                                                                                    -1 &&
                                                                                                                    !dk.IsIncluded
                                                                                                            "
                                                                                                            v-if="
                                                                                                                userGroupDatasetExclude[
                                                                                                                    g
                                                                                                                        .UserGroupID
                                                                                                                ].indexOf(
                                                                                                                    dk.DatasetId
                                                                                                                ) >
                                                                                                                    -1 &&
                                                                                                                    dk.IsIncluded
                                                                                                            "
                                                                                                        />
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            checked
                                                                                                            class="slash-checkbox3"
                                                                                                            @click="
                                                                                                                childCheck(
                                                                                                                    g.UserGroupID,
                                                                                                                    dk.ProjectDatasetID,
                                                                                                                    $event,
                                                                                                                    true
                                                                                                                )
                                                                                                            "
                                                                                                            :disabled="
                                                                                                                userGroupDatasetExclude[
                                                                                                                    g
                                                                                                                        .UserGroupID
                                                                                                                ].indexOf(
                                                                                                                    dk.DatasetId
                                                                                                                ) >
                                                                                                                    -1 &&
                                                                                                                    !dk.IsIncluded
                                                                                                            "
                                                                                                            v-if="
                                                                                                                userGroupDatasetExclude[
                                                                                                                    g
                                                                                                                        .UserGroupID
                                                                                                                ].indexOf(
                                                                                                                    dk.DatasetId
                                                                                                                ) >
                                                                                                                    -1 &&
                                                                                                                    !dk.IsIncluded
                                                                                                            "
                                                                                                        />

                                                                                                        <span
                                                                                                            v-if="
                                                                                                                dk.IsIncluded &&
                                                                                                                    userGroupDatasetExclude[
                                                                                                                        g
                                                                                                                            .UserGroupID
                                                                                                                    ].indexOf(
                                                                                                                        dk.DatasetId
                                                                                                                    ) ==
                                                                                                                        -1
                                                                                                            "
                                                                                                            class="width68 pl-3"
                                                                                                        >
                                                                                                            <img
                                                                                                                src="../../../assets/exclude_black.svg"
                                                                                                            />
                                                                                                        </span>
                                                                                                        <span
                                                                                                            v-if="
                                                                                                                !dk.IsIncluded ||
                                                                                                                    userGroupDatasetExclude[
                                                                                                                        g
                                                                                                                            .UserGroupID
                                                                                                                    ].indexOf(
                                                                                                                        dk.DatasetId
                                                                                                                    ) >
                                                                                                                        -1
                                                                                                            "
                                                                                                        >
                                                                                                            <span
                                                                                                                v-if="
                                                                                                                    !dk.IsIncluded &&
                                                                                                                        userGroupDatasetExclude[
                                                                                                                            g
                                                                                                                                .UserGroupID
                                                                                                                        ].indexOf(
                                                                                                                            dk.DatasetId
                                                                                                                        ) >
                                                                                                                            -1
                                                                                                                "
                                                                                                                class="width68 pl-3 slash-checkbox1"
                                                                                                                @click="
                                                                                                                    childExcludeCheck(
                                                                                                                        g.UserGroupID,
                                                                                                                        dk.ProjectDatasetID,
                                                                                                                        'Groups',
                                                                                                                        dk
                                                                                                                    )
                                                                                                                "
                                                                                                            >
                                                                                                                <img
                                                                                                                    src="../../../assets/exclude_red.svg"
                                                                                                                />
                                                                                                            </span>
                                                                                                            <span
                                                                                                                v-if="
                                                                                                                    dk.IsIncluded &&
                                                                                                                        userGroupDatasetExclude[
                                                                                                                            g
                                                                                                                                .UserGroupID
                                                                                                                        ].indexOf(
                                                                                                                            dk.DatasetId
                                                                                                                        ) >
                                                                                                                            -1
                                                                                                                "
                                                                                                                class="width68 pl-3"
                                                                                                                @click="
                                                                                                                    childExcludeCheck(
                                                                                                                        g.UserGroupID,
                                                                                                                        dk.ProjectDatasetID,
                                                                                                                        'Groups',
                                                                                                                        dk
                                                                                                                    )
                                                                                                                "
                                                                                                            >
                                                                                                                <img
                                                                                                                    src="../../../assets/exclude_black.svg"
                                                                                                                />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span
                                                                                                            class="slash-text"
                                                                                                            >{{
                                                                                                                dk.DatasetAlias
                                                                                                            }}</span
                                                                                                        >
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </b-card-body>
                                                                                </b-collapse>
                                                                            </b-card>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="groupDatasetList"
                    @changePage="onChangeGroupPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div class="card-footer" v-if="pageOfGroupData.length > 0">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            aria-controls="groupTable"
                            @change="collapseAllGroups"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
            <b-col class="mt-2 pl-2 ml-5 ">
                <div class="card bg-light maxWidth499 mb-3 border-grey">
                    <div class="card-header mb-20">Project Datasets</div>
                    <div class="card-body">
                        <div class="internal-body">
                            <b-row class="row1">
                                <img
                                    class="pointer ml-2 my-3"
                                    src="../../../assets/search-outline.svg"
                                    v-if="
                                        !searchGroupDatasets &&
                                        !showSearchdataset
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenDataset"
                                    @mouseover="searchOpenDataset"
                                />
                                <span
                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                    v-if="
                                        !searchGroupDatasets &&
                                        !showSearchdataset
                                            ? true
                                            : false
                                    "
                                    @click="searchOpenDataset"
                                    @mouseover="searchOpenDataset"
                                    >Search</span
                                >
                                <input
                                    ref="dataset"
                                    spellcheck="false"
                                    id="searchFilter"
                                    class=""
                                    v-if="
                                        searchGroupDatasets || showSearchdataset
                                            ? true
                                            : false
                                    "
                                    v-model="searchGroupDatasets"
                                    :class="
                                        searchGroupDatasets || showSearchdataset
                                            ? 'test'
                                            : 'searchinputList'
                                    "
                                    type="search"
                                    placeholder="Start typing and hit enter to search"
                                />
                                <span
                                    class="grey-bg pointer"
                                    v-if="searchCloseDataset"
                                    @click="closeBarDataset"
                                    ><img src="../../../assets/close.svg"
                                /></span>
                            </b-row>
                            <div class="header exclusion">
                                <input
                                    type="checkbox"
                                    class="ml-2 slash-checkbox"
                                    @click="selectAllDatasets($event)"
                                    v-model="allSelectedDataSets"
                                />
                                <h5>Exclude</h5>
                                <h5>Project Name</h5>
                            </div>
                            <b-row class="showProjectList projectDataset">
                                <table class="table" id="ProjectTable" striped>
                                    <tbody>
                                        <tr
                                            v-for="(dg,
                                            index) in datasetsGroupFilteredList"
                                            :key="index"
                                            :value="dg.DatasetName"
                                        >
                                            <!-- <td><input type="checkbox" v-model="selected"></td> -->
                                            <td>
                                                <b-card no-body class="mb-1">
                                                    <b-card-header
                                                        header-tag="header"
                                                        class="p-0 accordian-header"
                                                        role="tab"
                                                    >
                                                        <!-- Gevindu -->
                                                        <input
                                                            type="checkbox"
                                                            class="checkbox-allocate"
                                                            :checked="isChecked"
                                                            @click="
                                                                addAllDataSets(
                                                                    $event,
                                                                    dg
                                                                )
                                                            "
                                                        />
                                                        <b-button
                                                            block
                                                            v-b-toggle="
                                                                'imagedetails-accordion3' +
                                                                    index
                                                            "
                                                            class="accordion-button"
                                                        >
                                                            <span>{{
                                                                dg.ProjectName
                                                            }}</span>
                                                        </b-button>
                                                    </b-card-header>

                                                    <b-collapse
                                                        :id="
                                                            'imagedetails-accordion3' +
                                                                index
                                                        "
                                                        :accordion="
                                                            'imagedetails-accordion3' +
                                                                index
                                                        "
                                                        role="tabpanel"
                                                        v-model="
                                                            collapseStatesDatasets[
                                                                index
                                                            ]
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                dg.Datasets &&
                                                                    dg.Datasets
                                                                        .length
                                                            "
                                                        >
                                                            <b-row class="row1">
                                                                <img
                                                                    class="pointer ml-2 my-3"
                                                                    src="../../../assets/search-outline.svg"
                                                                    v-if="
                                                                        !dg.filterModel &&
                                                                        !showSearchDatasetsInner
                                                                            ? true
                                                                            : false
                                                                    "
                                                                    @click="
                                                                        searchOpenDatasetInner(
                                                                            index
                                                                        )
                                                                    "
                                                                    @mouseover="
                                                                        searchOpenDatasetInner(
                                                                            index
                                                                        )
                                                                    "
                                                                />
                                                                <span
                                                                    class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                    v-if="
                                                                        !dg.filterModel &&
                                                                        !showSearchDatasetsInner
                                                                            ? true
                                                                            : false
                                                                    "
                                                                    @click="
                                                                        searchOpenDatasetInner(
                                                                            index
                                                                        )
                                                                    "
                                                                    @mouseover="
                                                                        searchOpenDatasetInner(
                                                                            index
                                                                        )
                                                                    "
                                                                    >Search</span
                                                                >
                                                                <input
                                                                    ref="datasetsInner"
                                                                    spellcheck="false"
                                                                    id="searchFilter"
                                                                    class="width390"
                                                                    v-if="
                                                                        dg.filterModel ||
                                                                        showSearchDatasetsInner
                                                                            ? true
                                                                            : false
                                                                    "
                                                                    v-model="
                                                                        dg.filterModel
                                                                    "
                                                                    :class="
                                                                        dg.filterModel ||
                                                                        showSearchDatasetsInner
                                                                            ? 'test'
                                                                            : 'searchinputList'
                                                                    "
                                                                    type="search"
                                                                    placeholder="Start typing and hit enter to search"
                                                                />
                                                                <span
                                                                    class="grey-bg pointer"
                                                                    v-if="
                                                                        searchCloseDatasetInner
                                                                    "
                                                                    @click="
                                                                        closeBarDatasetInner
                                                                    "
                                                                    ><img
                                                                        src="../../../assets/close.svg"
                                                                /></span>
                                                            </b-row>
                                                        </span>
                                                        <span
                                                            v-for="(ds,
                                                            indexDataset) in $options.filters.filterByProperty(
                                                                dg.Datasets,
                                                                '',
                                                                'DatasetAlias',
                                                                dg.filterModel
                                                            )"
                                                            :key="indexDataset"
                                                        >
                                                            <b-card-body
                                                                class="contentCollapse"
                                                            >
                                                                <table
                                                                    class="text-card tableOne"
                                                                    striped
                                                                >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                <div
                                                                                    class="dataset-clm"
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        class="checkbox-allocate float-left mt-1"
                                                                                        v-model="
                                                                                            dataSetIds
                                                                                        "
                                                                                        :value="
                                                                                            ds.ProjectDatasetID
                                                                                        "
                                                                                        :disabled="
                                                                                            projectDatasetExclude.indexOf(
                                                                                                ds.ProjectDatasetID
                                                                                            ) >
                                                                                                -1
                                                                                        "
                                                                                        @click="
                                                                                            checkedDataset(
                                                                                                ds.ProjectDatasetID
                                                                                            )
                                                                                        "
                                                                                    />
                                                                                    <span
                                                                                        v-if="
                                                                                            projectDatasetExclude.indexOf(
                                                                                                ds.ProjectDatasetID
                                                                                            ) ==
                                                                                                -1
                                                                                        "
                                                                                        class="width68 px-3 float-left slash-checkbox1"
                                                                                        @click="
                                                                                            checkedDatasetExclude(
                                                                                                ds.ProjectDatasetID
                                                                                            )
                                                                                        "
                                                                                    >
                                                                                        <img
                                                                                            src="../../../assets/exclude_black.svg"
                                                                                        />
                                                                                    </span>
                                                                                    <span
                                                                                        v-if="
                                                                                            projectDatasetExclude.indexOf(
                                                                                                ds.ProjectDatasetID
                                                                                            ) >
                                                                                                -1
                                                                                        "
                                                                                        class="width68 px-3 float-left slash-checkbox1"
                                                                                        @click="
                                                                                            checkedDatasetExclude(
                                                                                                ds.ProjectDatasetID
                                                                                            )
                                                                                        "
                                                                                    >
                                                                                        <img
                                                                                            src="../../../assets/exclude_red.svg"
                                                                                        />
                                                                                    </span>
                                                                                    <span
                                                                                        class="float-left"
                                                                                    >
                                                                                        {{
                                                                                            ds.DatasetAlias
                                                                                        }}
                                                                                    </span>

                                                                                    <b-button
                                                                                        block
                                                                                        v-b-toggle="
                                                                                            'imagedetails-accordion2' +
                                                                                                index +
                                                                                                indexDataset
                                                                                        "
                                                                                        class="accordion-button float-left"
                                                                                    >
                                                                                    </b-button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                        <!-- <tr
                                        v-for="tg in dg.UserGroup"
                                        :key="tg.UserGroupName"
                                        :value="tg.UserGroupName"
                                    >
                                        <td class="">
                                        <input
                                            type="checkbox"
                                            checked
                                        />
                                        <span
                                            style="margin-left: 25px"
                                            >{{ tg.UserGroupName }}</span
                                        >
                                        </td>
                                    </tr> -->
                                                                    </tbody>
                                                                </table>
                                                            </b-card-body>

                                                            <b-collapse
                                                                :id="
                                                                    'imagedetails-accordion2' +
                                                                        index +
                                                                        indexDataset
                                                                "
                                                                :accordion="
                                                                    'imagedetails-accordion2' +
                                                                        index +
                                                                        indexDataset
                                                                "
                                                                role="tabpanel"
                                                            >
                                                                <b-card-body
                                                                    class="contentCollapse"
                                                                >
                                                                    <table
                                                                        class="text-card"
                                                                        striped
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <b-row
                                                                                    class="row1"
                                                                                >
                                                                                    <img
                                                                                        class="pointer ml-2 my-3"
                                                                                        src="../../../assets/search-outline.svg"
                                                                                        v-if="
                                                                                            !searchProjectUserGroupNameModel &&
                                                                                            !showSearchProjectDatasetSub
                                                                                                ? true
                                                                                                : false
                                                                                        "
                                                                                        @click="
                                                                                            searchOpenToolsInner(
                                                                                                indexDataset
                                                                                            )
                                                                                        "
                                                                                        @mouseover="
                                                                                            searchOpenToolsInner(
                                                                                                indexDataset
                                                                                            )
                                                                                        "
                                                                                    />
                                                                                    <span
                                                                                        class="blue-text ml-2 mb-1 searchPlaceholder"
                                                                                        v-if="
                                                                                            !searchProjectUserGroupNameModel &&
                                                                                            !showSearchProjectDatasetSub
                                                                                                ? true
                                                                                                : false
                                                                                        "
                                                                                        @click="
                                                                                            searchOpenToolsInner(
                                                                                                indexDataset
                                                                                            )
                                                                                        "
                                                                                        @mouseover="
                                                                                            searchOpenToolsInner(
                                                                                                indexDataset
                                                                                            )
                                                                                        "
                                                                                        >Search</span
                                                                                    >
                                                                                    <input
                                                                                        ref="projectDatasetSub"
                                                                                        spellcheck="false"
                                                                                        id="searchFilter"
                                                                                        class="width365"
                                                                                        v-if="
                                                                                            searchProjectUserGroupNameModel ||
                                                                                            showSearchProjectDatasetSub
                                                                                                ? true
                                                                                                : false
                                                                                        "
                                                                                        v-model="
                                                                                            searchProjectUserGroupNameModel
                                                                                        "
                                                                                        :class="
                                                                                            searchProjectUserGroupNameModel ||
                                                                                            showSearchProjectDatasetSub
                                                                                                ? 'test'
                                                                                                : 'searchinputList'
                                                                                        "
                                                                                        type="search"
                                                                                        placeholder="Start typing and hit enter to search"
                                                                                    />
                                                                                    <span
                                                                                        class="grey-bg pointer"
                                                                                        v-if="
                                                                                            searchCloseProjectDatasetSub
                                                                                        "
                                                                                        @click="
                                                                                            closeBarProjectDatasetSub
                                                                                        "
                                                                                        ><img
                                                                                            src="../../../assets/close.svg"
                                                                                    /></span>
                                                                                </b-row>
                                                                            </tr>
                                                                            <tr
                                                                                v-for="(ug,
                                                                                index1) in $options.filters.filterByProperty(
                                                                                    ds.Usergroups,
                                                                                    '',
                                                                                    'UserGroupName',
                                                                                    searchProjectUserGroupNameModel
                                                                                )"
                                                                                :key="
                                                                                    index1
                                                                                "
                                                                            >
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        class="slash-checkbox3 ml-5"
                                                                                        checked
                                                                                        @click="
                                                                                            childCheck(
                                                                                                ug.UserGroupID,
                                                                                                ds.ProjectDatasetID,
                                                                                                $event,
                                                                                                false
                                                                                            )
                                                                                        "
                                                                                        v-if="
                                                                                            ug.IsIncluded &&
                                                                                                projectGroupDatasetExclude[
                                                                                                    dg
                                                                                                        .ProjectID
                                                                                                ].indexOf(
                                                                                                    ug.UserGroupID
                                                                                                ) ==
                                                                                                    -1
                                                                                        "
                                                                                    />
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        class="slash-checkbox3 ml-5"
                                                                                        @click="
                                                                                            childCheck(
                                                                                                ug.UserGroupID,
                                                                                                ds.ProjectDatasetID,
                                                                                                $event,
                                                                                                true
                                                                                            )
                                                                                        "
                                                                                        :disabled="
                                                                                            projectGroupDatasetExclude[
                                                                                                dg
                                                                                                    .ProjectID
                                                                                            ].indexOf(
                                                                                                ug.UserGroupID
                                                                                            ) >
                                                                                                -1 &&
                                                                                                ug.IsIncluded
                                                                                        "
                                                                                        v-if="
                                                                                            ug.IsIncluded &&
                                                                                                projectGroupDatasetExclude[
                                                                                                    dg
                                                                                                        .ProjectID
                                                                                                ].indexOf(
                                                                                                    ug.UserGroupID
                                                                                                ) >
                                                                                                    -1
                                                                                        "
                                                                                    />
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked
                                                                                        class="slash-checkbox3 ml-5"
                                                                                        @click="
                                                                                            childCheck(
                                                                                                ug.UserGroupID,
                                                                                                ds.ProjectDatasetID,
                                                                                                $event,
                                                                                                true
                                                                                            )
                                                                                        "
                                                                                        :disabled="
                                                                                            projectGroupDatasetExclude[
                                                                                                dg
                                                                                                    .ProjectID
                                                                                            ].indexOf(
                                                                                                ug.UserGroupID
                                                                                            ) >
                                                                                                -1 &&
                                                                                                !ug.IsIncluded
                                                                                        "
                                                                                        v-if="
                                                                                            !ug.IsIncluded &&
                                                                                                projectGroupDatasetExclude[
                                                                                                    dg
                                                                                                        .ProjectID
                                                                                                ].indexOf(
                                                                                                    ug.UserGroupID
                                                                                                ) >
                                                                                                    -1
                                                                                        "
                                                                                    />

                                                                                    <span
                                                                                        v-if="
                                                                                            ug.IsIncluded &&
                                                                                                projectGroupDatasetExclude[
                                                                                                    dg
                                                                                                        .ProjectID
                                                                                                ].indexOf(
                                                                                                    ug.UserGroupID
                                                                                                ) ==
                                                                                                    -1
                                                                                        "
                                                                                        class="width68 pl-3 ml-1 slash-checkbox1"
                                                                                    >
                                                                                        <img
                                                                                            src="../../../assets/exclude_black.svg"
                                                                                        />
                                                                                    </span>
                                                                                    <span
                                                                                        v-if="
                                                                                            !ug.IsIncluded ||
                                                                                                projectGroupDatasetExclude[
                                                                                                    dg
                                                                                                        .ProjectID
                                                                                                ].indexOf(
                                                                                                    ug.UserGroupID
                                                                                                ) >
                                                                                                    -1
                                                                                        "
                                                                                    >
                                                                                        <span
                                                                                            v-if="
                                                                                                !ug.IsIncluded &&
                                                                                                    projectGroupDatasetExclude[
                                                                                                        dg
                                                                                                            .ProjectID
                                                                                                    ].indexOf(
                                                                                                        ug.UserGroupID
                                                                                                    ) >
                                                                                                        -1
                                                                                            "
                                                                                            class="width68 pl-3 slash-checkbox2 mt-2"
                                                                                            @click="
                                                                                                childExcludeCheck(
                                                                                                    ug.UserGroupID,
                                                                                                    ds.ProjectDatasetID,
                                                                                                    'Project',
                                                                                                    ug
                                                                                                )
                                                                                            "
                                                                                        >
                                                                                            <img
                                                                                                src="../../../assets/exclude_red.svg"
                                                                                            />
                                                                                        </span>
                                                                                        <span
                                                                                            v-if="
                                                                                                ug.IsIncluded &&
                                                                                                    projectGroupDatasetExclude[
                                                                                                        dg
                                                                                                            .ProjectID
                                                                                                    ].indexOf(
                                                                                                        ug.UserGroupID
                                                                                                    ) >
                                                                                                        -1
                                                                                            "
                                                                                            class="width68 pl-3 slash-checkbox2 mt-2"
                                                                                            @click="
                                                                                                childExcludeCheck(
                                                                                                    ug.UserGroupID,
                                                                                                    ds.ProjectDatasetID,
                                                                                                    'Project',
                                                                                                    ug
                                                                                                )
                                                                                            "
                                                                                        >
                                                                                            <img
                                                                                                src="../../../assets/exclude_black.svg"
                                                                                            />
                                                                                        </span>
                                                                                    </span>
                                                                                    <span
                                                                                        class="slash-text"
                                                                                    >
                                                                                        {{
                                                                                            ug.UserGroupName
                                                                                        }}
                                                                                    </span>
                                                                                </td>
                                                                            </tr>

                                                                            <!-- <tr
                                        v-for="tg in dg.UserGroup"
                                        :key="tg.UserGroupName"
                                        :value="tg.UserGroupName"
                                    >
                                        <td class="">
                                        <input
                                            type="checkbox"
                                            checked
                                        />
                                        <span
                                            style="margin-left: 25px"
                                            >{{ tg.UserGroupName }}</span
                                        >
                                        </td>
                                    </tr> -->
                                                                        </tbody>
                                                                    </table>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </span>
                                                    </b-collapse>
                                                </b-card>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-row>
                            <!-- <b-col md="12" class="my-3">
                    <jw-pagination
                    :items="datasetGroupList"
                    @changePage="onChangeGroupDatasetPage"
                    :labels="customLabels"
                    :maxPages="5"
                    class="paginationStyle"
                    ></jw-pagination>
                </b-col> -->
                        </div>
                    </div>
                    <div
                        class="card-footer"
                        v-if="pageOfGroupDataset.length > 0"
                    >
                        <b-pagination
                            v-model="currentPageProj"
                            :total-rows="rowsProj"
                            :per-page="perPage"
                            aria-controls="ProjectTable"
                            @change="collapseAllDatasets"
                        ></b-pagination>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-modal
            ref="dataset-modal"
            class="model_style"
            hide-header
            hide-footer
            centered
            size="lg"
        >
            <div class="d-block text-center" v-if="!reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                There is no item to review.
            </div>

            <div class="d-block" v-if="reviewItem.length">
                <div class="text-right cursor-pointer">
                    <img
                        src="../../../assets/close_icon.svg"
                        @click="hideModal"
                    />
                </div>
                <div class="text-center">
                    <h4 class="my-2">Change queue</h4>
                    <small
                        >Review {{ reviewItem.length }} changes pending before
                        commiting the changes.</small
                    >
                </div>
                <div class="my-3 tblScroll">
                    <table class="table table-striped dataSetModal">
                        <tbody>
                            <tr>
                                <th></th>
                                <th>Access</th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr
                                v-for="(item, index) in reviewItem"
                                :key="index"
                            >
                                <td>{{ item.groupName }}</td>
                                <td>
                                    <span
                                        v-if="!item.isIncluded && item.isActive"
                                    >
                                        <img
                                            src="../../../assets/exclude_red.svg"
                                        />
                                    </span>
                                    <span
                                        v-else-if="
                                            item.isActive && item.isIncluded
                                        "
                                    >
                                        <img src="../../../assets/icon_tick.svg"
                                    /></span>

                                    <span v-else>
                                        <span v-if="!item.isActive">
                                            <img src="../../../assets/close.svg"
                                        /></span>
                                        <span
                                            v-if="
                                                item.isIncluded &&
                                                    item.hasOwnProperty(
                                                        isIncluded
                                                    )
                                            "
                                        >
                                            <img
                                                src="../../../assets/exclude_black.svg"
                                            />
                                        </span>
                                    </span>
                                </td>

                                <td>{{ item.datasetAlias }}</td>
                                <td>{{ item.ProjectName }}</td>
                                <td class="deleteCell" @click="delQueue(index)">
                                    Delete from queue&nbsp;
                                    <img src="../../../assets/close.svg" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="mt-3 mb-4 text-center">
                    <a class="text-primary" @click="clearQueue">Clear queue </a>
                    <button
                        class="buttonSelect ml-3 text-white"
                        @click="commitChanges"
                    >
                        Commit changes
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { managementService } from '../../../services/management.service';
import Vue from 'vue';
import { mapActions, mapMutations } from 'vuex';
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import ToastMessages from '../../ToastMessages.vue';
const customLabels = {
    first: '<<',
    last: '>>',
    previous: '<',
    next: '>',
};

export default {
    components: {
    },
    props: ['groupCreated'],
    watch: {
        groupCreated: function(newVal, oldVal) {
            // watch it
            this.getGroupDatasets();
            this.getDatasetsGroup();
        },
    },
    data() {
        return {
            isChecked: false,
            searchText: true,
            searchTextData: true,
            showTextData: false,
            customLabels,
            showText: false,
            searchGroups: '',
            searchGroupDatasets: '',
            allSelectedGroups: false,
            allSelectedDataSets: false,
            perPage: 5,
            groupDatasetList: [],
            pageOfGroupData: [],
            datasetGroupList: [],
            pageOfGroupDataset: [],
            groupIds: [],
            dataSetIds: [],
            reviewItem: [],
            // successToast: false,
            // failureToast:false,
            // successToastValue:'',
            // failureToastValue:'',
            showPage: false,
            selectedProjectId: {},
            thisfilename: null,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            messageTitle: null,
            currentPage: 1,
            groupDatasetListFiltered: [],
            currentPageProj: 1,
            datasetGroupListFiltered: [],
            projectDatasetExclude: [],
            userGroupDatasetExclude: {},
            userGroupDataset: {},
            projectGroupDatasetExclude: {},
            searchDatasetNameInput: true,
            searchDatasetNameModel: '',
            searchDatasetProjectModel: '',
            searchProjectUserGroupNameInput: true,
            searchProjectUserGroupInput: true,
            searchProjectUserGroupNameModel: '',
            searchProjectUserGroupName: '',
            collapseStatesGroups: [],
            collapseStatesDatasets: [],
            searchCloseGroup: false,
            showSearchgroup: false,
            earchCloseGroupInner: false,
            showSearchGroupsInner: false,
            searchCloseDataset: false,
            showSearchdataset: false,
            searchCloseDatasetInner: false,
            showSearchDatasetsInner: false,
            searchCloseProjectDatasetSub: false,
            showSearchProjectDatasetSub: false,
            searchCloseGroupInner: false,
            searchCloseProjectsMain: false,
            showSearchProject: false,
        };
    },
    created() {
        this.getGroupDatasets();
        this.getDatasetsGroup();
    },
    mounted() {
        //  this.getPermissions();
        // this.$store.dispatch("getPermissions");
        this.pagePermit();
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        addAllDataSets(event, project) {
            project.Datasets.forEach((el) => {
                if (!event.srcElement.checked) {
                    this.dataSetIds.splice(
                        this.dataSetIds.indexOf(el.ProjectDatasetID),
                        1
                    );
                    this.allSelectedDataSets = false;
                } else {
                    this.dataSetIds.push(el.ProjectDatasetID);
                }
            });
        },
        ...mapActions('store', {
            getPermissions: 'getPermissions',
        }),
        pagePermit() {
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            let vm = this;
            vm.showPage = true;
            data.forEach((element) => {
                if (element.PageName.includes('GADataset')) {
                    vm.showPage = true;
                }
            });
            if (!this.showPage) {
                this.$refs['permission-modal'].showPermissionModal();
            }
            this.$emit('clickedDataset', this.showPage);
        },
        reviewQueue() {
            this.$refs['dataset-modal'].show();
        },
        // closeSuccessToast(){
        //     this.successToast = false
        // },
        // closeFailureToast(){
        //     this.failureToast = false
        // },
        hideModal() {
            this.$refs['dataset-modal'].hide();
        },
        searchOpenGroup() {
            this.searchCloseGroup = !this.searchCloseGroup;
            this.showSearchgroup = true;
            this.$nextTick(() => {
                this.$refs.groups.focus();
            });
        },
        closeBarGroup() {
            this.searchCloseGroup = !this.searchCloseGroup;
            this.showSearchgroup = false;
            this.searchGroups = null;
        },

        closeBargroupInner() {
            this.searchCloseGroupInner = !this.searchCloseGroupInner;
            this.showSearchGroupsInner = false;
            this.searchDatasetNameModel = null;
        },
        searchOpenGrouplInner(index) {
            this.searchCloseGroupInner = !this.searchCloseGroupInner;
            this.showSearchGroupsInner = true;
            this.$nextTick(() => {
                this.$refs.groupsInner[index].focus();
            });
        },

        searchOpenDataset() {
            this.searchCloseDataset = !this.searchCloseDataset;
            this.showSearchdataset = true;
            this.$nextTick(() => {
                this.$refs.dataset.focus();
            });
        },
        closeBarDataset() {
            this.searchCloseDataset = !this.searchCloseDataset;
            this.showSearchdataset = false;
            this.searchGroupDatasets = null;
        },

        closeBarDatasetInner() {
            this.searchCloseDatasetInner = !this.searchCloseDatasetInner;
            this.showSearchDatasetsInner = false;
            this.searchProjectUserGroupName = null;
        },
        searchOpenDatasetInner(index) {
            this.searchCloseDatasetInner = !this.searchCloseDatasetInner;
            this.showSearchDatasetsInner = true;
            this.$nextTick(() => {
                this.$refs.datasetsInner[index].focus();
            });
        },

        closeBarProjectDatasetSub() {
            this.searchCloseProjectDatasetSub = !this
                .searchCloseProjectDatasetSub;
            this.showSearchProjectDatasetSub = false;
            this.searchProjectUserGroupNameModel = null;
        },
        searchOpenToolsInner(indexDataset) {
            this.searchCloseProjectDatasetSub = !this
                .searchCloseProjectDatasetSub;
            this.showSearchProjectDatasetSub = true;
            this.$nextTick(() => {
                this.$refs.projectDatasetSub[indexDataset].focus();
            });
        },

        searchOpenGrouplProject(index) {
            this.searchCloseProjectsMain = !this.searchCloseProjectsMain;
            this.showSearchProject = true;
            this.$nextTick(() => {
                this.$refs.groupsInnerProjectM[index].focus();
            });
        },

        closeBargroupProject() {
            this.searchCloseProjectsMain = !this.searchCloseProjectsMain;
            this.showSearchProject = false;
            this.searchDatasetProjectModel = null;
        },

        commitChanges() {
            let data = [];
            this.reviewItem.forEach((item) => {
                data.push({
                    // "UserGroupID": item.groupId,
                    // "DatasetID": item.datasetId,
                    // "ProjectID":item.ProjectID,
                    // "isActive": item.isActive,

                    ResourceTypeID: 2,
                    ResourceID: item.ProjectDatasetID,
                    UserGroupID: item.groupId,
                    IsIncluded: item.isIncluded,
                    IsActive: item.isActive,
                });
            });

            managementService
                .addOrUpdateUsersAuthorizations(data)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.setToastMessage({
                            showSuccess: true,
                            showFailure: false,
                            successToastMessage:
                                'Association done successfully.',
                            messageTitle: null,
                        });

                        this.reviewItem = [];
                        this.projectDatasetExclude = [];
                        this.getGroupDatasets();
                        this.getDatasetsGroup();
                        this.groupIds = [];
                        this.dataSetIds = [];
                        this.$refs['dataset-modal'].hide();
                    }
                });
        },
        delQueue(index) {
            let removeItem = this.reviewItem[index];

            this.reviewItem.splice(index, 1);

            for (let j = 0; j < this.datasetGroupList.length; j++) {
                if (this.datasetGroupList[j].Datasets) {
                    for (
                        let k = 0;
                        k < this.datasetGroupList[j].Datasets.length;
                        k++
                    ) {
                        if (removeItem.IsIncluded) {
                            if (
                                this.datasetGroupList[j].Datasets[k]
                                    .ProjectDatasetID ==
                                removeItem.ProjectDatasetID
                            ) {
                                for (
                                    let jk = 0;
                                    jk <
                                    this.datasetGroupList[j].Datasets[k]
                                        .Usergroups.length;
                                    jk++
                                ) {
                                    if (
                                        this.datasetGroupList[j].Datasets[k]
                                            .Usergroups[jk].UserGroupID ==
                                        removeItem.groupId
                                    ) {
                                        this.datasetGroupList[j].Datasets[
                                            k
                                        ].Usergroups[jk].IsIncluded = false;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            for (let j = 0; j < this.groupDatasetList.length; j++) {
                if (this.groupDatasetList[j].Projects) {
                    for (
                        let k = 0;
                        k < this.groupDatasetList[j].Projects.length;
                        k++
                    ) {
                        if (removeItem.IsIncluded) {
                            if (
                                this.groupDatasetList[j].Projects[k]
                                    .ProjectDatasetID ==
                                removeItem.ProjectDatasetID
                            ) {
                                for (
                                    let jk = 0;
                                    jk <
                                    this.groupDatasetList[j].Projects[k]
                                        .Datasets.length;
                                    jk++
                                ) {
                                    if (
                                        this.groupDatasetList[j].Projects[k]
                                            .Datasets[jk].DatasetName ==
                                        removeItem.dataSetName
                                    ) {
                                        this.groupDatasetList[j].Projects[
                                            k
                                        ].Datasets[jk].IsIncluded = false;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            let groupIdFound = this.reviewItem.findIndex(
                (item) => item.groupId == removeItem.groupId
            );
            if (groupIdFound == -1) {
                let groupId = this.groupIds.findIndex(
                    (item) => item == removeItem.groupId
                );
                this.groupIds.splice(groupId, 1);
            }
            let datasetIdFound = this.reviewItem.findIndex(
                (item) => item.ProjectDatasetID == removeItem.ProjectDatasetID
            );

            if (datasetIdFound == -1) {
                let datasetId = this.dataSetIds.findIndex(
                    (item) =>
                        item.ProjectDatasetID == removeItem.ProjectDatasetID
                );
                this.dataSetIds.splice(datasetId, 1);
                this.projectDatasetExclude.splice(datasetId, 1);
                //delete this.selectedProjectId[removeItem.ProjectID];
            }
            this.$forceUpdate();

            /* if(!this.reviewItem.length){
                this.$refs["dataset-modal"].hide();
            }*/
        },
        clearQueue() {
            this.groupIds = [];
            this.projectDatasetExclude = [];
            this.dataSetIds = [];
            this.selectedProjectId = {};
            this.reviewItem = [];
            this.allSelectedDataSets = false;
            this.getGroupDatasets();
            this.getDatasetsGroup();
            this.$refs['dataset-modal'].hide();
        },
        childCheck(groupId, dataSetID, event, excluded) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return (
                    item.groupId == groupId &&
                    item.ProjectDatasetID == dataSetID
                );
            });

            if (unCheckedItem > -1) {
                if (event.target.checked) {
                    if (excluded) {
                        this.reviewItem[unCheckedItem].isActive = true;
                    } else {
                        this.reviewItem.splice(unCheckedItem, 1);
                    }
                } else {
                    this.reviewItem[unCheckedItem].isActive = false;
                }
            } else {
                let groupName = this.groupDatasetList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                let dataSetName;
                for (let j = 0; j < this.datasetGroupList.length; j++) {
                    if (this.datasetGroupList[j].Datasets) {
                        for (
                            let k = 0;
                            k < this.datasetGroupList[j].Datasets.length;
                            k++
                        ) {
                            if (
                                this.datasetGroupList[j].Datasets[k]
                                    .ProjectDatasetID == dataSetID
                            ) {
                                dataSetName = this.datasetGroupList[j].Datasets[
                                    k
                                ];
                            }
                        }
                    }
                }
                if (!event.target.checked) {
                    let alreadyInserted = this.reviewItem.findIndex((item) => {
                        return (
                            item.UserGroupID == groupId &&
                            item.ProjectDatasetID == dataSetID
                        );
                    });
                    if (alreadyInserted == -1) {
                        this.reviewItem.push({
                            groupId: groupId,
                            groupName: groupName[0].UserGroupName,
                            dataSetName: dataSetName.DatasetName,
                            ProjectName: dataSetName.ProjectName,
                            datasetAlias: dataSetName.DatasetAlias,
                            ProjectDatasetID: dataSetName.ProjectDatasetID,
                            isActive: false,
                        });
                    }
                }
            }
        },
        childExcludeCheck(groupId, dataSetID, type, item) {
            let unCheckedItem = this.reviewItem.findIndex((item) => {
                return (
                    item.groupId == groupId &&
                    item.ProjectDatasetID == dataSetID
                );
            });

            if (unCheckedItem > -1 && item.IsIncluded) {
                // if(this.userGroupTools[groupId].indexOf(toolId) == -1 || this.toolUserGroups[toolId].indexOf(groupId) == -1){
                this.reviewItem.splice(unCheckedItem, 1);
                // }
            } else {
                let groupName = this.groupDatasetList.filter((item) => {
                    return item.UserGroupID == groupId;
                });
                /*let dataSetName = this.datasetGroupList.filter((subItem)=>{
                                return subItem.ProjectDatasetID ==  dataSetID;
                            });*/
                let dataSetName;
                for (let j = 0; j < this.datasetGroupList.length; j++) {
                    if (this.datasetGroupList[j].Datasets) {
                        for (
                            let k = 0;
                            k < this.datasetGroupList[j].Datasets.length;
                            k++
                        ) {
                            if (
                                this.datasetGroupList[j].Datasets[k]
                                    .ProjectDatasetID == dataSetID
                            ) {
                                dataSetName = this.datasetGroupList[j].Datasets[
                                    k
                                ];
                            }
                        }
                    }
                }

                let alreadyInserted = this.reviewItem.findIndex((item) => {
                    return (
                        item.UserGroupID == groupId &&
                        item.ProjectDatasetID == dataSetID
                    );
                });
                if (alreadyInserted == -1) {
                    this.reviewItem.push({
                        groupId: groupId,
                        groupName: groupName[0].UserGroupName,
                        dataSetName: dataSetName.DatasetName,
                        ProjectName: dataSetName.ProjectName,
                        datasetAlias: dataSetName.DatasetAlias,
                        ProjectDatasetID: dataSetName.ProjectDatasetID,
                        isIncluded: true,
                        isActive: false,
                    });
                }
            }
            if (type == 'Groups') {
                item.IsIncluded = !item.IsIncluded;
            }
            if (type == 'Project') {
                item.IsIncluded = !item.IsIncluded;
            }

            this.$forceUpdate();
        },
        queueChanges() {
            if (
                (this.groupIds.length && this.dataSetIds.length) ||
                this.reviewItem.length
            ) {
                if (this.groupIds.length && this.dataSetIds.length) {
                    // this.reviewItem=[]
                    let selectedGroups = {};
                    this.groupIds.forEach((groupItem) => {
                        let selectedDataset = [];
                        this.dataSetIds.forEach((dataSetItem) => {
                            selectedDataset.push(dataSetItem);
                        });
                        /* for(let props in this.selectedProjectId){
                          for(let i=0;i<this.selectedProjectId[props].length;i++){

                                selectedDataset.push({
                                "projectId":props,
                                "datasetId":this.selectedProjectId[props][i]
                                });
                                }
                     }*/
                        selectedGroups[groupItem] = selectedDataset;
                    });

                    for (let props in selectedGroups) {
                        let projectName = this.groupDatasetList.filter(
                            (item) => {
                                return item.UserGroupID == props;
                            }
                        );
                        let dataSetName;
                        for (let i = 0; i < selectedGroups[props].length; i++) {
                            for (
                                let j = 0;
                                j < this.datasetGroupList.length;
                                j++
                            ) {
                                if (this.datasetGroupList[j].Datasets) {
                                    for (
                                        let k = 0;
                                        k <
                                        this.datasetGroupList[j].Datasets
                                            .length;
                                        k++
                                    ) {
                                        if (
                                            this.datasetGroupList[j].Datasets[k]
                                                .ProjectDatasetID ==
                                            selectedGroups[props][i]
                                        ) {
                                            dataSetName = this.datasetGroupList[
                                                j
                                            ].Datasets[k];
                                        }
                                    }
                                }
                            }
                            /*let dataSetName = this.datasetGroupList.filter((item)=>{
                                 if(item.Datasets){
                                    return item.Datasets.filter((subItem)=>{
                                    return subItem.ProjectDatasetID ==  selectedGroups[props][i];
                                     })

                                 }
                            });*/

                            let excludeTools = this.projectDatasetExclude.filter(
                                (subItem) => {
                                    return subItem == selectedGroups[props][i];
                                }
                            );
                            let exist = this.reviewItem.findIndex(
                                (item) =>
                                    item.groupId == props &&
                                    item.ProjectDatasetID ==
                                        selectedGroups[props][i]
                            );
                            if (exist === -1) {
                                if (excludeTools.length) {
                                    this.reviewItem.push({
                                        groupId: props,
                                        // "datasetId":selectedGroups[props][i].datasetId,
                                        // "ProjectID":selectedGroups[props][i].projectId,
                                        groupName: projectName[0].UserGroupName,
                                        dataSetName: dataSetName.DatasetName,
                                        ProjectName: dataSetName.ProjectName,
                                        datasetAlias: dataSetName.DatasetAlias,
                                        ProjectDatasetID:
                                            dataSetName.ProjectDatasetID,
                                        isActive: true,
                                        isIncluded: false,
                                    });
                                } else {
                                    this.reviewItem.push({
                                        groupId: props,
                                        // "datasetId":selectedGroups[props][i].datasetId,
                                        // "ProjectID":selectedGroups[props][i].projectId,
                                        groupName: projectName[0].UserGroupName,
                                        dataSetName: dataSetName.DatasetName,
                                        ProjectName: dataSetName.ProjectName,
                                        datasetAlias: dataSetName.DatasetAlias,
                                        ProjectDatasetID:
                                            dataSetName.ProjectDatasetID,
                                        isActive: true,
                                        isIncluded: true,
                                    });
                                }
                            }
                        }
                    }
                    /* this.groupIds.forEach((groupItem)=>{
                        let projectName = this.groupDatasetList.filter((item)=>{
                            return item.UserGroupID == groupItem;
                        })
                        //
                        this.dataSetIds.forEach((dataSetItem)=>{
                            let dataSetName = this.datasetGroupList.filter((subItem)=>{
                                return subItem.DatasetID == dataSetItem;
                            })
                            //
                            this.reviewItem.push({
                                "groupId":groupItem,
                                "datasetId":dataSetItem,
                                "groupName": projectName[0].UserGroupName,
                                "dataSetName": dataSetName[0].DatasetName,
                                "isActive": true
                            })
                            this.reviewItem = Array.from(new Set(this.reviewItem.map(a=> a.groupId)))
                            .map(id=>{
                                return this.reviewItem.find(a=> a.groupId ===id)
                            })
                        })
                    })*/
                }
                if (this.reviewItem.length) {
                    this.successToastMessage =
                        'Review changes before commiting.';
                } else {
                    this.successToastMessage =
                        'Review changes before commiting.';
                }
                this.messageTitle = 'Changes queued';

                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: this.successToastMessage,
                    messageTitle: this.messageTitle,
                });
            } else {
                this.failureToastMessage = 'Please select the association';
                this.messageTitle = null;
                this.setToastMessage({
                    showSuccess: false,
                    showFailure: true,
                    failureToastMessage: this.failureToastMessage,
                    messageTitle: this.messageTitle,
                });
            }
        },
        getGroupDatasets() {
            managementService.getGroupDatasets().then((res) => {
                this.groupDatasetList = res.data;
                this.groupDatasetList.forEach((item) => {
                    let tempExclude = [];
                    if (item.Projects) {
                        item.Projects.forEach((subItem) => {
                            if (subItem.Datasets) {
                                subItem.Datasets.forEach((innerItem) => {
                                    if (!innerItem.IsIncluded) {
                                        tempExclude.push(innerItem.DatasetId);
                                    }
                                });
                            }
                        });
                    }
                    this.userGroupDatasetExclude[
                        item.UserGroupID
                    ] = tempExclude;
                });

                this.groupDatasetListFiltered = res.data;
            });
        },
        getDatasetsGroup() {
            managementService.getDatasetsGroup().then((res) => {
                this.datasetGroupList = res.data;
                this.datasetGroupList.forEach((item) => {
                    let tempExclude = [];
                    if (item.Datasets) {
                        item.Datasets.forEach((subItem) => {
                            if (subItem.Usergroups) {
                                subItem.Usergroups.forEach((innerItem) => {
                                    if (!innerItem.IsIncluded) {
                                        tempExclude.push(innerItem.UserGroupID);
                                    }
                                });
                            }
                        });
                    }
                    this.projectGroupDatasetExclude[
                        item.ProjectID
                    ] = tempExclude;
                });

                this.datasetGroupListFiltered = res.data;
            });
        },
        collapseAllGroups() {
            this.collapseStatesGroups = this.groupDatasetList.map(
                (x) => (x.open = false)
            );
        },
        collapseAllDatasets() {
            this.collapseStatesDatasets = this.datasetGroupList.map(
                (x) => (x.open = false)
            );
        },
        onChangeGroupPage(pageOfGroupData) {
            this.pageOfGroupData = pageOfGroupData;
        },
        addSearchText() {
            if (this.searchGroups.length == 0) {
                this.searchText = true;
                this.showText = false;
            }
        },
        removeSearchText() {
            this.searchText = false;
            this.showText = true;
        },
        addSearchTextData() {
            if (this.searchGroupDatasets.length == 0) {
                this.searchTextData = true;
                this.showTextData = false;
            }
        },
        removeSearchTextData() {
            this.searchTextData = false;
            this.showTextData = true;
        },
        selectAllGroups(p) {
            this.groupIds = [];
            if (!this.allSelectedGroups) {
                for (p in this.groupDatasetList) {
                    this.groupIds.push(this.groupDatasetList[p].UserGroupID);
                }
            }
        },
        selectAllDatasets(event) {
            /* this.selectedProjectId = {};
            if (!this.allSelectedDataSets) {
                for (d in this.datasetGroupList) {
                   // this.dataSetIds.push(this.datasetGroupList[d].DatasetID);
                    this.selectedProjectId[this.datasetGroupList[d].ProjectID]=[this.datasetGroupList[d].DatasetID];
                }
            }*/
            if (event.srcElement.checked) {
                this.isChecked = true;
            } else {
                this.isChecked = false;
            }

            this.dataSetIds = [];
            if (!this.allSelectedDataSets) {
                this.pageOfGroupDataset.forEach((item) => {
                    if (item.Datasets) {
                        if (item.filterModel) {
                            var temp = this.$options.filters.filterByProperty(
                                item.Datasets,
                                '',
                                'DatasetAlias',
                                item.filterModel
                            );
                            temp.forEach((subItem) => {
                                this.dataSetIds.push(subItem.ProjectDatasetID);
                            });
                        } else {
                            item.Datasets.forEach((subItem) => {
                                this.dataSetIds.push(subItem.ProjectDatasetID);
                            });
                        }
                    }
                });
            }
        },
        onChangeGroupDatasetPage(pageOfGroupDataset) {
            this.pageOfGroupDataset = pageOfGroupDataset;
        },

        checkedGroups(id) {
            if (this.groupIds.indexOf(id) > -1) {
                this.groupIds.splice(this.groupIds.indexOf(id), 1);
                this.allSelectedGroups = false;
            } else {
                this.groupIds.push(id);
            }
        },
        checkedDatasetExclude(id) {
            if (this.projectDatasetExclude.indexOf(id) > -1) {
                this.projectDatasetExclude.splice(
                    this.projectDatasetExclude.indexOf(id),
                    1
                );
                this.dataSetIds.splice(this.dataSetIds.indexOf(id), 1);
                // this.allSelectedGroups = false
            } else {
                this.projectDatasetExclude.push(id);
                this.dataSetIds.push(id);
            }
            this.$forceUpdate();
        },
        checkedDataset(id) {
            /*if(this.selectedProjectId[projectId]){
                delete this.selectedProjectId[projectId];
            }else{
              this.selectedProjectId[projectId]=[id];
            }*/

            if (this.dataSetIds.indexOf(id) > -1) {
                this.dataSetIds.splice(this.dataSetIds.indexOf(id), 1);
                this.allSelectedDataSets = false;
            } else {
                this.dataSetIds.push(id);
            }
            this.$forceUpdate();
        },
    },
    computed: {
        selectAllDataSets: {
            get: function() {
                return this.users
                    ? this.selected.length == this.users.length
                    : false;
            },
            set: function(value) {
                var selected = [];

                if (value) {
                    this.users.forEach(function(user) {
                        selected.push(user.id);
                    });
                }

                this.selected = selected;
            },
        },
        groupDataFilteredList() {
            let vm = this;
            if (vm.searchGroups) {
                vm.pageOfGroupData = vm.groupDatasetListFiltered.filter(
                    (post) => {
                        return post.UserGroupName.toLowerCase().includes(
                            vm.searchGroups.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfGroupData = vm.groupDatasetListFiltered;
            }
            return vm.pageOfGroupData.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
        datasetsGroupFilteredList() {
            let vm = this;
            if (this.searchGroupDatasets) {
                vm.pageOfGroupDataset = vm.datasetGroupListFiltered.filter(
                    (post) => {
                        return post.ProjectName.toLowerCase().includes(
                            this.searchGroupDatasets.toLowerCase()
                        );
                    }
                );
            } else {
                vm.pageOfGroupDataset = vm.datasetGroupListFiltered;
            }
            return vm.pageOfGroupDataset.slice(
                (this.currentPageProj - 1) * this.perPage,
                this.currentPageProj * this.perPage
            );
        },
        rows() {
            return this.pageOfGroupData.length;
        },
        rowsProj() {
            return this.pageOfGroupDataset.length;
        },
    },
};
</script>

<style lang="scss" scoped>
// .accordion-button {
//   background-color: transparent !important;
//   border: none;
//   color: black;
//   text-align: left;
//   span {
//     margin-left: 25px;
//     margin-left: 30px;
//     display: block;
//     width: 310px;
//     // margin-top: -25px;
//   }
// }
.accordion-button.btn-secondary:hover {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: left;
}
.accordion-button.btn-secondary:focus {
    box-shadow: 0 0 0 0 !important;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    /* width: 516px; */
    border-radius: 0;
    border: none;
    color: #000;
    background: url('../../../assets/plus_icon.svg') no-repeat right 0.2rem
        center !important;
}
.btn.accordion-button.inside.btn-secondary.btn-block.collapsed {
    background: #fff url('../../../assets/plus_icon.svg') no-repeat right 0.8rem
        center !important;
    width: 100% !important;
    height: 50px;
}

.btn.accordion-button.inside.btn-secondary.btn-block.not-collapsed {
    background: #d4d4d4 url('../../../assets/blackminus.svg') no-repeat right
        0.8rem center !important;
    width: 100% !important;
    height: 50px;
}
table.inner-btn tr:first-child td {
    padding: 0 !important;
    height: 45px !important;
}
table.inner-btn td {
    padding: 0 !important;
    height: 55px !important;
}

button.btn.btn-secondary.btn-block.not-collapsed {
    background: url('../../../assets/blackminus.svg') no-repeat right 0.75rem
        center !important;
    color: #000;
    text-align: left;
    //  margin-top: -36px;
    //     margin-left: -14px;
    //     width: 414px;
    border-bottom: 2px solid #dee2e6;
}
// .contentCollapse {
//   background-color: #eaeaea !important;
//   width: 388px;
// }
// .accordian-header {
//   background-color: transparent !important;
//   border-bottom: none !important;
//   .checkbox-allocate {
//     position: absolute;
//     left: 10px;
//     top: 11px;
//   }
// }
// .paginationStyle {
//   border: none;
//   /* margin: 0px auto !important; */
//   width: 200px;
//   margin: 0px;
//   padding: 0px;
//   //display: contents !important;
// }
input.searchinput {
    background: url('../../../assets/search-outline.svg') no-repeat !important;
    border: none;
    margin-right: -146px;
    margin-top: 4px;
    color: #006395 !important;
}
// input.test {
//   width: 411px;
// }
.text-card {
    background-color: #fff !important;
    padding: 10px;
    margin-left: -13px;
    margin-right: -10px;
    width: 413px;
}
// .buttonSelect.queueChange{
//     position: absolute;
//     right: 20px;
//     top: -100px;
// }
.internal-body {
    background-color: #fff;
    margin-left: 0px !important;
    margin-right: 0px !important;

    //   .row1 {
    //     //border-bottom: 3px solid #eaeaea;
    //     padding-top: 10px;
    //     //background-color: #eaeaea;

    //     // h6.blue {
    //     //   color: #006395 !important;
    //     // }
    //   }
    //   .showProjectList {
    //     overflow: auto;
    //     height: 300px;
    //     width: 457px;
    //   }
    .header {
        padding: 10px;
        border-bottom: 3px solid;
    }
    .row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
// .reviewQueueButton{
//     position: absolute;
//     top:-58px;
//     right:175px;
//     cursor: pointer;
// }

.model_style {
    width: 900px !important;
}
table.dataSetModal tr:first-child {
    background: white;
}
// input[type="search"]{
//     padding:11px;
//     // border-radius:2px;
// }
.tableOne button.btn.btn-secondary.btn-block.not-collapsed {
    //background-color: transparent!important;
    border-bottom: 0px;
    border-radius: 0;
}
.tableOne tr,
[id^='imagedetails-accordion2'] tr {
    background: #fff;
    border-top: 1px solid #dee2e6;
}
.tableOne button {
    position: relative;
    width: 385px;
    display: inline;
}
[id^='imagedetails-accordion2'] .card-body,
#imagedetails-accordion1 .card-body {
    padding: 0 1.25rem 0.5rem;
}
[id^='imagedetails-accordion2'] .card-body .text-card td {
    width: 50px;
}
// [id^=imagedetails-accordion2] .card-body .text-card span{
//     // position: relative;
//     // top: 11px;
// }
// .projectDataset .accordion-button span{
//     margin-left: 0 !important;
// }
// .projectDataset .accordion-button span{
//     top: 5px;
//     position: relative;
// }
.inline {
    display: inline !important;
}
.dataset-clm {
    display: inline-flex;
}
.tableOne .dataset-clm button {
    width: auto !important;
    float: right;
}
.tblLevel2 {
    width: 415px;
    margin: 0px;
}
.tblLevel2 .text-card {
    width: 410px;
}
.collapseLevel2 {
    top: 16px;
    position: relative;
}
.card-wide {
    width: 427px;
}
.userrightmanagement .width362 {
    width: 362px !important;
}
.userrightmanagement .width365.test {
    width: 365px !important;
}
// .userrightmanagement .row1 .width365.test{
//     width: 362px !important;
// }
.userrightmanagement .width381 {
    width: 381px !important;
}
.userrightmanagement .width390 {
    width: 390px !important;
}
.slash-text {
    margin-left: 25px;
}
.project-name {
    position: relative;
    float: left;
    margin-left: 30px !important;
}
</style>
