<template lang="html">
    <div v-if="!showPage" class="manage-attributes">
        <AccessPermissionModal ref="permission-modal" />
    </div>
    <div class="manageTemplate" v-else>
        <b-container class="d-p-0"
            ><p>
                <!-- <b-row class="mb-3 pt-1">
          <b-col cols="12">
            <a @click="navigateDashboard">
              <img src="../../assets/arrow_left.svg" class="" />
              <span class="floar-left ml-1"> Back to projects</span>
            </a>
          </b-col>
        </b-row>
<b-row class="vh-10 text-left" align-v="center" v-if="currentSwitchState">
          <b-col cols="7" class="pt-2">
            <h4 class="" v-if="documentTabopen">Projects</h4>
            <h4 class="" v-if="!documentTabopen">Document management</h4>
          </b-col>
          
    </b-row> -->
            </p>

            <div
                v-if="saveManageAttribute || saveAssignedAttribute"
                class="openManageSelection col-lg-5 float-right p-0 m-none"
            >
                <b-col class="cancelManage col-lg-6">
                    <span
                        class="buttonNew pointer float-right m-0"
                        @click="cancelClone"
                    >
                        Cancel
                    </span>
                    <!-- <a class="float-right m-0">Cancel</a> -->
                </b-col>
                <b-col class="text-right saveManage col-lg-6">
                    <b-button
                        rounded
                        class="buttonSelect"
                        :disabled="cloneDataResult.length == 0"
                        @click="saveClone"
                        >Save</b-button
                    >
                </b-col>
            </div>
            <!-- <div v-if="saveCloneDirectory" class="openManageSelection">
                  <b-col class="cancelManage">
                    <a class="float-right pl-3 m-0 subheader-smalltext">Cancel</a>
                  </b-col>
                  <b-col class="text-right saveManage">
                    <b-button rounded class="buttonSelect" @click="saveClone"
                      >Save</b-button
                    >
                  </b-col>
                </div> -->
            <portal-bar
                @clicked="onClickOpenSelection"
                :hideSearch="hideSearch"
            ></portal-bar>
            <!-- <p class="my-3">Text instructions here.</p> -->
            <!-- <b-row class="mt-5 min-height">
                <b-col cols="6" class="blue-color p-0">
                    <p>Text instructions here.</p>Agg
                </b-col>
              </b-row> -->
            <b-row class="attrTabs">
                <b-col cols="12" lg="7" md="9" class="manage-attr">
                    <b-card no-body>
                        <b-tabs
                            active-nav-item-class="borderBlue mx-2"
                            v-on:activate-tab="tabActivated"
                        >
                            <b-tab
                                title="Clone directory"
                                @click="openCloneDirectory"
                            >
                                <div class="content-clone">
                                    <div class="" v-if="projectSelected">
                                        <small
                                            style="position: relative;float: left;margin-left: 46px;"
                                            >Select project to clone from</small
                                        >
                                        <b-form-select
                                            v-model="selectedProjectList"
                                            :options="projectList"
                                            text-field="ProjectName"
                                            value-field="ProjectID"
                                            @change="getDirectoriesByProject()"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select
                                                    Project</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>
                                    </div>
                                    <br />
                                    <small
                                        style="position: relative;float: left;margin-left: 46px;"
                                        >Select folders to duplicate</small
                                    >
                                    <ejs-dropdowntree
                                        :id="'dropdowntree' + tabIndexClone"
                                        :ref="'treeObj' + tabIndexClone"
                                        :fields="fields"
                                        :showCheckBox="true"
                                        :select="select"
                                        cssClass="dropdowntree"
                                    ></ejs-dropdowntree>
                                    <div class="">
                                        <div
                                            class="directoryItem search_element"
                                        >
                                            <h6
                                                class="float-left pl-3 bold"
                                                v-if="
                                                    submitSelectedDirectory &&
                                                        !cloneSelected
                                                "
                                            >
                                                Directory
                                            </h6>
                                            <span
                                                class="arrayLength"
                                                v-if="
                                                    submitSelectedDirectory &&
                                                        dirArray.length > 0
                                                "
                                                >({{ dirArray.length }})</span
                                            >
                                            <!-- <span
               class="arrayLength"
               v-if="submitSelectedDirectory"
               >({{ directoryList.length }})</span
               > -->

                                            <!-- <span class="float-left" v-if="cloneSelected">
                        Select directory
                      </span>
                      <b-button-group class="button-group float-right">
                        <b-dropdown class="clone-btn" right>
                          <div
                            class="legacyBox"
                         
                          >
                            <input
                              type="search"
                              class="searchinputIcon"
                              placeholder="Search"
                            />
                            <img
                              src="../../assets/search-outline.svg"
                            class="search-icon"
                            />-->
                                            <!--<b-form-group class="checkDirectory">
                              <template #label>
                                <b-form-checkbox
                                  >{{ projectName }}
                                </b-form-checkbox>
                                <hr />
                                <b-form-checkbox-group
                                  class="childCheckBox"
                                  :options="projectDirectoryList"
                                  v-model="selectedDirectory"
                                  text-field="DirectoryName"
                                  value-field="DirectoryID"
                                  @input="selectedDirectoryForClone"
                                >
                                </b-form-checkbox-group>
                              </template>
                            </b-form-group>-->

                                            <!--  </div>
                        </b-dropdown>
                      </b-button-group>-->
                                            <!-- <b-form-select
               v-model="selectedDirectory"
               class="childCheckBox"
               :options="projectDirectoryList"
               multiple :select-size="4"
               text-field="DirectoryName"
               value-field="DirectoryName" 
               @change="mouseleave"
               
                       >
                         <template #first>
               <b-form-select-option :value="null" disabled
               >Select Directory</b-form-select-option>
               </template>
               
                            
                       </b-form-select> -->
                                        </div>
                                    </div>
                                    <div
                                        v-if="submitSelectedDirectory"
                                        class="m-t-73"
                                    >
                                        <!-- <span
                      class="result"
                      >Results</span
                    > -->
                                        <div>
                                            <b-card
                                                no-body
                                                class="mb-1 result-card"
                                                v-for="(selectedDirectory,
                                                index) in dirArray"
                                                :key="index"
                                            >
                                                <b-card-header
                                                    header-tag="header"
                                                    class="p-0"
                                                    role="tab"
                                                >
                                                    <!-- <input type="checkbox" class="checkbox-allocate"
                     @click="checkedProject(selectedDirectory.DirectoryID)"
                     :value="selectedDirectory.DirectoryID"
                     v-model="directoryList"
                      /> -->
                                                    <b-button
                                                        block
                                                        v-b-toggle="
                                                            'imagedetails-accordion' +
                                                                index
                                                        "
                                                        @click="showContent"
                                                        >{{
                                                            selectedDirectory.DirectoryName
                                                        }}
                                                    </b-button>
                                                </b-card-header>
                                                <b-collapse
                                                    :id="
                                                        'imagedetails-accordion' +
                                                            index
                                                    "
                                                    :accordion="
                                                        'imagedetails-accordion' +
                                                            index
                                                    "
                                                    role="tabpanel"
                                                >
                                                    <b-card-body
                                                        class="contentCollapse"
                                                    >
                                                        <b-card-text
                                                            class="py-2"
                                                        >
                                                            <b-form-checkbox
                                                                v-model="
                                                                    selected[
                                                                        selectedDirectory
                                                                            .DirectoryID
                                                                    ]
                                                                "
                                                                value-field="selectedDirectory.DirectoryID"
                                                                @input="
                                                                    selectAllDirectoryForClone(
                                                                        selectedDirectory
                                                                    )
                                                                "
                                                            >
                                                                Clone entire
                                                                directory
                                                            </b-form-checkbox>
                                                        </b-card-text>
                                                        <b-card-text>
                                                            <div>
                                                                <!-- <b-table striped hover :items="items"></b-table> -->
                                                                <table
                                                                    class="table"
                                                                    striped
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                Directory
                                                                                name
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                            >
                                                                                Clone
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr
                                                                            v-for="(dd,
                                                                            index) in selectedDirectory.ChildDirectories"
                                                                            :key="
                                                                                index
                                                                            "
                                                                        >
                                                                            <td
                                                                                scope="row"
                                                                            >
                                                                                {{
                                                                                    dd.DirectoryName
                                                                                }}
                                                                            </td>
                                                                            <td
                                                                                class="clone-left"
                                                                            >
                                                                                <b-form-checkbox
                                                                                    v-model="
                                                                                        cloneSelectedDirectory[
                                                                                            dd
                                                                                                .DirectoryParentID
                                                                                        ]
                                                                                    "
                                                                                    value-field="dd.DirectoryID"
                                                                                    @input="
                                                                                        checkedDirectoryForClone(
                                                                                            dd.DirectoryParentID,
                                                                                            dd.DirectoryID
                                                                                        )
                                                                                    "
                                                                                >
                                                                                </b-form-checkbox>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </b-card-text>
                                                        <b-card-text>
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                            <ToastMessages
                                :showSuccess="showSuccess"
                                :showFailure="showFailure"
                                :failureToastMessage="failureToastMessage"
                                :successToastMessage="successToastMessage"
                            />
                            <!-- <div class="successToastMsg" v-if="successToast">
                <img src="../../assets/success_tick.svg" class="mr-3" />
                Success <br />
                Attributes added successfully
                <img
                  src="../../assets/close_big.svg"
                  class="ml-3"
                  @click="closeSuccessToast"
                />
              </div>
              <div class="failureToastMsg" v-if="failureToast">
                <img src="../../assets/failure_cross.svg" class="mr-3" />
                Failure <br />
                Something went wrong.Please try again later.
                <img
                  src="../../assets/close_big.svg"
                  class="ml-3"
                  @click="closeFailureToast"
                />
              </div>
              <div class="failureToastMsg" v-if="invalidToast">
                <img src="../../assets/failure_cross.svg" class="mr-3" />
                Invalid <br />
                This Project has no Directory.Please try again later.
                <img
                  src="../../assets/close_big.svg"
                  class="ml-3"
                  @click="closeInvalidToast"
                />
              </div> -->
                            <!-- <div class="successToastMsg" v-if="successToast">
                          <img
                            src="../../assets/success_tick.svg"
                            class="mr-3"
                          />
                          Success <br />
                          Directory added successfully
                          <img
                            src="../../assets/close_big.svg"
                            class="ml-3"
                            @click="closeSuccessToast"
                          />
                        </div>
                        <div class="failureToastMsg" v-if="failureToast">
                          <img
                            src="../../assets/failure_cross.svg"
                            class="mr-3"
                          />
                          Failure <br />
                          Something went wrong.Please try again later.
                          <img
                            src="../../assets/close_big.svg"
                            class="ml-3"
                            @click="closeFailureToast"
                          />
                        </div> -->
                        </b-tabs>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// import Vue from "vue";
import { mapActions } from 'vuex';
import { directoryService } from '../../services/directory.service';
import { projectService } from '../../services/project.service';
import PortalBar from '../PortalBar';
import { DropDownTreePlugin } from '@syncfusion/ej2-vue-dropdowns';
import router from '../../router';
import ToastMessages from '../ToastMessages.vue';
import AccessPermissionModal from '../AccessPermissionModal';
import { detach } from '@syncfusion/ej2-base';
import Vue from 'vue';

Vue.use(DropDownTreePlugin);
var selectedData = [];

export default {
    name: 'ManageAttributes',
    components: {
        PortalBar,
        ToastMessages,
        AccessPermissionModal,
    },
    props: {
        documentTabopen: Boolean,
        tabIndexClone: Number,
        // currentSwitchStateRR: Boolean,
        // projectSelected: Boolean,
        // selectedProjectList: String,
    },
    data() {
        return {
            fields: {
                dataSource: [],
                value: 'id',
                text: 'name',
                parentValue: 'pid',
                hasChildren: 'hasChild',
            },
            treeSettings: { autoCheck: true },
            directories: [],
            projectList: [],
            submitSelectedDirectory: false,
            projectDirectoryList: [],
            cloneSelected: false,
            dirArray: [],
            projectName: '',
            cloneSelectedDirectory: {},
            selectedDirectory: [],
            saveManageAttribute: true,
            openProjectSection: false,
            projectSelected: true,
            selectedProjectList: null,
            cloneDataDirectory: [],
            cloneDataResult: [],
            saveCloneDirectory: false,
            // successToast: false,
            // failureToast: false,
            // invalidToast: false,
            currentSwitchState: false,
            directoryList: [],
            showPlusIcon: true,
            showMinusIcon: false,
            selected: [],
            hideSearch: true,
            showSuccess: false,
            showFailure: false,
            failureToastMessage: null,
            successToastMessage: null,
            showPage: false,
        };
    },
    created() {
        // this.pagePermit();
        this.getDirectories();
        this.getProjectlist();
        this.getCurrentSwitchstate();
    },
    mounted() {
        this.pagePermit();
    },
    methods: {
        ...mapActions('projects', {
            selectProjects: 'selectProjects',
            clearSelectedProjects: 'clearSelectedProjects',
        }),
        pagePermit() {
            this.showPage = false;
            const data = this.$store.state.store.posts.AccessPagesInfoLst;
            // this.thisfilename = this.$route.matched[0].components.default.__file.replace(/^.*[\\/]/, '').split('.').slice(0, -1).join('.');
            let vm = this;
            data.forEach((element) => {
                if (element.PageName.includes('Documents - Clone directory')) {
                    vm.showPage = true;
                }
            });
            setTimeout(() => {
                if (!this.showPage) {
                    this.$refs['permission-modal'].showPermissionModal();
                }
            });
        },
        select: function(args) {
            if (args.isInteracted) {
                //Gets the corresponding original data
                var itemData = this.projectDirectoryList.find(
                    (e) => e.id.toString() === args.itemData.id.toString()
                );
                //add / remove the item in the selected data array based on selection
                if (args.action === 'select') {
                    selectedData.push(itemData);
                } else if (args.action === 'un-select') {
                    selectedData.splice(
                        selectedData.findIndex((e) => e.id === itemData.id),
                        1
                    );
                }
                //Get the CheckedNodes list from teh tree Objedt inside dropdowntree.
                var ddt1 = this.$refs['treeObj' + this.tabIndexClone]
                    .ej2Instances;
                var tree = ddt1.treeObj;
                var checkedNodes = tree.checkedNodes.slice();

                //Action on parent start

                //Gets the parentItem of the selectednode
                var parentItem = itemData.pid
                    ? this.projectDirectoryList.find(
                          (e) => e.id === itemData.pid
                      )
                    : null;
                //Loop continues till the top level parent
                while (parentItem) {
                    //Checks whether the parent is in checked list and adds it if not present

                    var parentid = parentItem.id;
                    var index = checkedNodes.indexOf(parentid.toString());
                    if (args.action === 'select' && index === -1) {
                        //Adds to the checked list if it's select action and parent is not in list
                        checkedNodes.push(parentid.toString());
                        selectedData.push(parentItem);
                    } else if (args.action === 'select' && index !== -1) {
                        //Breaks the loop if it's select action and parent is in list
                        break;
                    } else if (args.action === 'un-select' && index !== -1) {
                        //This If case can be ignored, if you don't want to unselect parent based on child, you can ignore this block
                        //Checks whether the parent has any other selected child it's un-select action and parent is in list
                        var childIndex = selectedData.findIndex(
                            (e) => e.pid === parentid
                        );
                        if (childIndex === -1) {
                            //removes the parent if there is no selected child
                            checkedNodes.splice(index, 1);
                            selectedData.splice(
                                selectedData.findIndex(
                                    (e) => e.id === parentid
                                ),
                                1
                            );
                        } else {
                            //breaks the loop if the parent has another selected child
                            break;
                        }
                    }
                    //Gets the next level parent if the loop is not broken.
                    parentItem = this.projectDirectoryList.find(
                        (e) => e.id === parentItem.pid
                    );
                }

                //Action on parent end
                //Action on Child start

                //Gets the child of the item
                var ChildItems = this.projectDirectoryList.filter(
                    (e) => e.pid === itemData.id
                );
                //loops if there is a child
                while (ChildItems.length !== 0) {
                    var subChilds = [];
                    ChildItems.forEach((element) => {
                        if (args.action === 'select') {
                            //if select action adds the child to the list
                            checkedNodes.push(element.id.toString());
                            selectedData.push(element);
                        } else {
                            //if un-select action , checks if the child present in the list and removes
                            var index = checkedNodes.indexOf(
                                element.id.toString()
                            );
                            if (index !== -1) {
                                checkedNodes.splice(index, 1);
                                selectedData.splice(
                                    selectedData.findIndex(
                                        (e) => e.id === element.id
                                    ),
                                    1
                                );
                            }
                        }
                        //Gets the children of the child and adds in the subchild array
                        var Children = this.projectDirectoryList.filter(
                            (e) => e.pid === element.id
                        );
                        subChilds = subChilds.concat(Children);
                    });
                    //after processing all child assign sub-childs to the child list and repeat the prcoess at next level child
                    ChildItems = subChilds;
                }

                //Action on Child end

                //assign the checkednodes list ot the treeObj of dropdowntree
                tree.checkedNodes = checkedNodes;
                tree.refresh();
            }
            var ddt = document.getElementById(
                'dropdowntree' + this.tabIndexClone
            ).ej2_instances[0];
            //
            var selection = ddt.treeObj.getAllCheckedNodes();

            var cloneData = [];
            this.cloneDataDirectory = [];
            for (var i = 0; i < selection.length; i++) {
                var parentdata = ddt.treeObj.getNode(selection[i]).parentID;

                //
                /*
          "selected parent node data-",
          ddt.treeObj.getNode(parentdata)

        );*/
                let parentData = ddt.treeObj.getNode(parentdata);
                let filterNodeData = ddt.treeObj.getNode(selection[i]);
                let filterParentData = this.cloneDataDirectory.filter(
                    (item) => item.id == parentData.id
                );
                let filternodeData = this.cloneDataDirectory.filter(
                    (item) => item.id == filterNodeData.id
                );
                if (filterParentData.length == 0) {
                    this.cloneDataDirectory.push(
                        ddt.treeObj.getNode(parentdata)
                    );
                }
                if (filternodeData.length == 0) {
                    this.cloneDataDirectory.push(
                        ddt.treeObj.getNode(selection[i])
                    );
                }
            }
            //
            this.cloneDataResult = this.getNestedChildren(
                this.cloneDataDirectory
            );
        },
        getNestedChildren(arr, parent) {
            var out = [];
            for (var i in arr) {
                if (arr[i].parentID == parent) {
                    arr[i].projectId = Number(
                        localStorage.getItem('directoryCloneProjectID')
                    );
                    var children = this.getNestedChildren(arr, arr[i].id);

                    if (children.length) {
                        arr[i].children = children;
                    }
                    arr[i].id = Number(arr[i].id);
                    arr[i].parentID = Number(arr[i].parentID);
                    out.push(arr[i]);
                }
            }
            return out;
        },
        navigateDashboard: function() {
            this.clearSelectedProjects();
            router.push(`/classic/project/mapview`);
        },
        getCurrentSwitchstate() {
            this.currentSwitchState = this.$route.params.currentSwitchState;
        },
        getDirectories() {
            projectService
                .getDirectories()
                .then((res) => (this.directories = res.data));
        },
        tabActivated() {
            this.$parent.$parent.$parent.tabActivated();
        },
        getProjectlist() {
            projectService
                .getAllProjectList()
                .then((res) => (this.projectList = res.data));
        },
        showContent() {
            this.showMinusIcon = true;
            this.showPlusIcon = false;
        },
        manageAttribute() {
            this.$parent.$parent.selectManageAttribute();
            // if (this.currentSwitchState) {
            //     let routeData = this.$router.resolve({ name: "manageattributes" });
            //     window.open(routeData.href, "_blank");
            //     } else {
            //         this.manageAttrTab.push(this.manageAttrTabCounter++);
            //     }
        },
        selectManageAttribute() {
            this.saveManageAttribute = true;
            this.openProjectSection = false;
        },
        openCloneDirectory() {
            this.saveCloneDirectory = true;
            this.saveAssignedAttribute = false;
        },
        onClickOpenSelection(value) {
            this.currentSwitchState = value;
        },
        cancelClone() {
            this.$emit('closeManageAttrTab');
            this.projectDirectoryList = [];
            this.selectedProjectList = null;
            var treeObj = document.getElementById(
                'dropdowntree' + this.tabIndexClone
            ).ej2_instances[0];
            treeObj.fields.dataSource = '';
            treeObj.refresh();
        },
        saveClone() {
            //const id = localStorage.getItem("directoryCloneProjectID");
            // const dirName = this.directoryList.toString();
            //let cloneData = [];
            /* let vm = this;
          vm.selectedDirectory.forEach((dirItem) => {
            vm.directoryList.find((subItem) => {
              return subItem.DirectoryName == dirItem;
            });

            cloneData.push({
              DirectoryName: dirItem,
              ProjectId: id,
              DirectoryParentID: 4,
            });
          });*/

            /*for(let props in this.cloneSelectedDirectory){

            for(let i=0;i<this.cloneSelectedDirectory[props].length;i++){
            let directoryName=this.dirArray.filter((dir)=> {
              return dir.ChildDirectories.filter(subDir => subDir.DirectoryID==this.cloneSelectedDirectory[props][i])

            }
            );
                                      cloneData.push({
                                      DirectoryName: directoryName[0].ChildDirectories[0].DirectoryName,
                                      ProjectId: id,
                                      DirectoryParentID: directoryName[0].ChildDirectories[0].DirectoryParentID,
                                       });
                                }
          }*/

            directoryService
                .cloneSelectedDirectories(this.cloneDataResult)
                .then((res) => {
                    if (res.data.StatusCode === 201) {
                        this.showSuccess = true;
                        this.showFailure = false;
                        this.successToastMessage =
                            'Directory cloned successfully';
                        this.projectDirectoryList = [];
                        this.selectedProjectList = null;
                        var treeObj = document.getElementById(
                            'dropdowntree' + this.tabIndexClone
                        ).ej2_instances[0];
                        treeObj.fields = {
                            dataSource: [],
                            value: 'id',
                            text: 'name',
                            parentValue: 'pid',
                            hasChildren: 'hasChild',
                        };
                        treeObj.dataBind();
                        const isTree = document.querySelector(
                            '#' + treeObj.element.id + '_tree'
                        );
                        if (isTree) {
                            const popupDiv = document.querySelector(
                                '#' + treeObj.element.id + '_popup'
                            );
                            detach(popupDiv ? popupDiv : isTree.parentElement);
                        }
                        setTimeout(() => {
                            this.showSuccess = false;
                        }, 5000);
                    }
                    if (res.data.StatusCode === 500) {
                        this.showFailure = true;
                        this.showSuccess = false;
                        this.failureToastMessage = res.data.Message;
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                })

                .catch((err) => {
                    if (err) {
                        this.showFailure = true;
                        this.showSuccess = false;
                        this.failureToastMessage =
                            'Something went wrong.Please try again later.';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
        },
        getDirectoriesByProject() {
            let projectName = this.projectList.filter(
                (item) => item.ProjectID == this.selectedProjectList
            );
            this.projectName = projectName[0].ProjectName;
            directoryService
                .getDirectoryChild(this.selectedProjectList)
                .then((res) => {
                    this.projectDirectoryList = res.data;
                    var treeObj = document.getElementById(
                        'dropdowntree' + this.tabIndexClone
                    ).ej2_instances[0];
                    treeObj.fields = {
                        dataSource: res.data,
                        value: 'id',
                        text: 'name',
                        parentValue: 'pid',
                        hasChildren: 'hasChild',
                    };
                    treeObj.dataBind();
                    const isTree = document.querySelector(
                        '#' + treeObj.element.id + '_tree'
                    );
                    if (isTree) {
                        const popupDiv = document.querySelector(
                            '#' + treeObj.element.id + '_popup'
                        );
                        detach(popupDiv ? popupDiv : isTree.parentElement);
                    }

                    this.submitSelectedDirectory = true;
                    this.cloneSelected = true;
                    this.invalidToast = false;
                })
                .catch((err) => {
                    if (err) {
                        this.showFailure = true;
                        this.showSuccess = false;
                        this.failureToastMessage =
                            'Something went wrong.Please try again later.';
                        setTimeout(() => {
                            this.showFailure = false;
                        }, 5000);
                    }
                });
            /* vm.directories.filter((item) => {
              if(item.ProjectId == vm.selectedProjectList){
                  if(item.ChildDirectories.length == 0){
                      vm.invalidToast = true;
                      vm.cloneSelected = false;
                  }else{

              directoryService
                      .getDirectory(vm.selectedProjectList)
                      .then((res) => {
                        vm.projectDirectoryList = res.data;
                        this.submitSelectedDirectory = true;
                        this.cloneSelected = true;
                        vm.invalidToast = false;
                      });
                      vm.cloneSelected = false;
                  }
              }
              else{

              }
            });*/

            //
            // const id = this.selectedProjectList;
            //       if(this.selectedProjectList != 25){
            //          this.invalidToast = true;
            //          this.cloneSelected = false;

            //     }

            //     else{

            //
            //       directoryService
            //
            // directoryService
            //         .getDirectory(id)
            //         .then((res) => (this.projectDirectoryList = res.data));
            //         this.cloneSelected = false;
            //     }
        },
        submitDirectory() {
            this.submitSelectedDirectory = true;
            this.cloneSelected = false;
        },
        selectedDirectoryForClone() {
            //
            this.dirArray = [];
            this.submitSelectedDirectory = true;
            this.cloneSelected = false;
            this.projectSelected = false;

            var directoryIndex;

            this.selectedDirectory.forEach((dirInd) => {
                directoryIndex = this.projectDirectoryList.find((item) => {
                    return item.DirectoryID == dirInd;
                });
                // this.dirArray.push(directoryIndex);
                this.dirArray.push(directoryIndex);
            });

            this.directoryList.push(this.selectedDirectory);
        },
        checkedDirectoryForClone(parentId, childDirectoryId) {
            if (this.cloneSelectedDirectory[parentId]) {
                if (
                    this.cloneSelectedDirectory[parentId].indexOf(
                        childDirectoryId
                    ) > -1
                ) {
                    this.cloneSelectedDirectory[parentId].splice(
                        this.cloneSelectedDirectory[parentId].indexOf(
                            childDirectoryId
                        ),
                        1
                    );
                } else {
                    this.cloneSelectedDirectory[parentId].push(
                        childDirectoryId
                    );
                }
            } else {
                this.cloneSelectedDirectory[parentId] = [childDirectoryId];
            }
            if (this.cloneSelectedDirectory[parentId].length == 0) {
                delete this.cloneSelectedDirectory[parentId];
            }
        },
        selectAllDirectoryForClone(item) {
            // this.cloneSelectedDirectory = {};
            if (!this.selected[item.DirectoryID]) {
                for (let i = 0; i < item.ChildDirectories.length; i++) {
                    if (
                        this.cloneSelectedDirectory[
                            item.ChildDirectories[i].DirectoryParentID
                        ]
                    ) {
                        this.cloneSelectedDirectory[
                            item.ChildDirectories[i].DirectoryParentID
                        ].push(item.ChildDirectories[i].DirectoryID);
                    } else {
                        this.cloneSelectedDirectory[
                            item.ChildDirectories[i].DirectoryParentID
                        ] = [item.ChildDirectories[i].DirectoryID];
                    }
                }
            } else {
                if (
                    Object.keys(this.cloneSelectedDirectory[item.DirectoryID])
                ) {
                    delete this.cloneSelectedDirectory[item.DirectoryID];
                }
            }
        },
        mouseover(id) {
            if (this.selectedProjectList == id) {
                this.cloneSelected = true;
            } else if (this.selectedProjectList == null) {
                alert('wrong');
            }
        },
        handleClone() {
            this.submitSelectedDirectory = true;
            // this.cloneSelected = false;
        },
        // closeSuccessToast() {
        //   this.successToast = false;
        // },
        // closeFailureToast() {
        //   this.failureToast = false;
        // },
        // closeInvalidToast(){
        //   this.invalidToast = false;
        // },
    },
};
</script>

<style lang="scss" scoped>
[id^='dropdowntree'] {
    //background: #fff!important;
    min-height: 30px !important;
    border-radius: 4px;
    // width: 499px;
    // display: block;
}
.dropdowntree .e-checkbox-wrapper {
    float: right;
    padding-top: 5px;
}
.content-clone {
    background-color: #eaeaea !important;
    min-height: 350px;
    padding: 30px;
    text-align: center;
    //padding-left:45px;
}
// Manage attribute style
.saveManage {
    button {
        width: 80px;
    }
}
.cancelManage {
    // position: absolute;
    // /* text-align: right !important; */
    // top: -33px;
    // left: -82px;
    a {
        color: #006395 !important;
        font-size: 16px !important;
    }
}
.text-right.saveManage {
    //position: absolute;
    top: -40px;
}
.childCheckBox {
    padding-left: 20px;
    overflow-x: hidden;
    height: 150px;
}
.legacyBox {
    height: 261px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff !important;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}
.checkDirectory {
    padding: 20px;
    margin-top: -20px;
}
.searchinputIcon {
    background-color: #eaeaea;
    height: 34px;
    margin: 0 5px;
    border: none;
    padding-left: 40px;
    margin-bottom: 20px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
// .arrayLength {
//   position: absolute;
//   /* left: 20px; */
//   right: 84px;
//   top: 107px;
//   font-weight: bold;
// }
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #c6c6c6;
}
.blue {
    color: #fff !important;
    background-color: #161616 !important;
    border: 0 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #c6c6c6;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
    /* margin-top: 23px; */
    margin-bottom: 20px;
    border-radius: 3px !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 50%;
    float: right;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        /*margin-left: 312px;*/
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        /*margin-right: -295px;*/
    }
}
.main-button {
    margin-right: 0;
    /*margin-left: -56px;*/
    margin-top: 20px;
    width: 215px;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        /*margin-left: 312px;*/
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
    }
}

.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    span {
        color: #373a3c;
        font-weight: 500;
    }
    li {
        border-top: none !important;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    height: 154px;
}
.contentEdit {
    width: 498px;
    text-align: center;
    margin-top: 30px;
}
.title {
    text-align: center;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    color: #6e6e6e;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.successToastMsg p {
    display: inline;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #626262 url('../../../assets/minusIcon.svg') no-repeat right
        0.75rem center !important;
    color: #fff;
    text-align: left;
    border-radius: 0;
    border: none;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    border-radius: 0;
    border: none;
    color: #373a3c !important;
    background: #fff url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
}
.directoryItem.search_element button {
    background-image: url('../../../assets/search_dots.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
    cursor: pointer;
}

.card-header {
    margin-bottom: 20px;
    background: transparent;
}
/* manage attribute style ended */

.attrTabs .tabs {
    border: 2px solid #eaeaea !important;
    margin-bottom: 135px;
}
.tabs {
    padding-top: 10px;
}
.custom-select {
    background: #fff url('../../../assets/arrow_down.svg') no-repeat right
        0.75rem center/8px 10px !important;
    width: 499px;
    height: 48px;
}
// .directoryItem {
//   background-color: #fff !important;
//   height: 48px !important;
//   width: 499px !important;
//   //margin-left: 45px;
//   border: 1px solid #ced4da;
//   border-radius: 0.25rem;
//   h6 {
//     padding: 0.85rem;
//   }
//   .button-group {
//     height: 100%;
//   }
//   span.float-left {
//     padding: 0.7rem;
//   }
// }
.custom-control-inline {
    display: block !important;
}
button.btn.btn-secondary.btn-block.not-collapsed {
    background: #626262 url('../../../assets/minusIcon.svg') no-repeat right
        0.75rem center !important;
    color: #fff;
    text-align: left;
    border-radius: 0;
    border: none;
    height: 50px !important;
    // width: 516px;
}
button.btn.btn-secondary.btn-block.collapsed {
    text-align: left;
    // width: 516px;
    border-radius: 0;
    border: none;
    color: #373a3c !important;
    background: #fff url('../../../assets/plusIcon.svg') no-repeat right 0.75rem
        center !important;
    height: 50px !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 0 !important;
    margin-bottom: 20px;
    position: relative;
    .card-text {
        text-align: left;
        color: #373a3c !important;
        font-size: 16px;
    }
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.arrayLength {
    position: relative;
    // left: 115px;
    right: -170px;
    top: 11px;
    font-weight: bold;
}
.saveManageButton {
    margin-top: -107px;
}
.cancelManage {
    position: relative;
    left: -50px;
    top: -15px;
    a {
        color: #006395 !important;
        font-size: 16px !important;
    }
}
.text-right.saveManage {
    position: absolute;
    top: -17px;
}

.my-custom-floating-label {
    .input__container {
        width: 400px;
    }
}
.breadcrumb {
    background-color: white !important;
}
.text-right.icons {
    //margin-top: -10px;
    max-width: 100px;
}
.add-tool {
    margin-left: -39px;
    color: black !important;
}

.open-toggle {
    float: right;
    margin-top: 8px;
}
.openManageSelection {
    text-align: right;
    top: -100px;
    position: relative;
    max-width: 155px;
}
.border {
    height: 48px;
    padding-top: 3px !important;
    border-radius: 4px;
    //left: 5px;
    /* width: 987px; */
}
.add-icons {
    width: 143px;
    height: 42.5px;
    float: right;
}
.childCheckBox {
    padding-left: 20px;
}
.badge {
    img {
        margin-left: -30px;
    }
}
.badge {
    margin-right: -11px !important;
}

.badge.contentBadge {
    margin-left: -67px;
}
.nav-item {
    background-color: #eaeaea !important;
    /* padding: 7px; */
}
.text {
    margin-left: -44px;
    position: absolute;
}

.icons a.router-link-active {
    color: black !important;
}
.icons a {
    color: #c2c2c2 !important;
}

.leaflet-popup-content-wrapper {
    background-color: black !important;
    padding: 1px !important;
    min-width: 100px !important;
    margin: 0 !important;
    color: #eee !important;
    border-radius: 0 !important;
}
.leaflet-popup-content {
    margin: 3px 6px !important;
}
.leaflet-popup-tip-container {
    display: none;
}
.leaflet-container a.leaflet-popup-close-button {
    display: none;
}
.leaflet-control-attribution a {
    display: none;
}

.leaflet-control-container {
    display: none;
}
.pr-4 {
    padding-right: 0.9rem !important;
}

.map_arrow {
    position: absolute;
    left: 1px;
    top: 12px;
    z-index: 9999999;
}
/* .border-right {
  margin-left: -36px !important;
} */

.reportingTool {
    width: 279px;
    height: auto;
    background: white;
    box-shadow: 0px 3px 6px #00000029 !important;
    -moz-box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 4px;
    padding: 15px 5px;
    font-size: 16px !important;
}

.DocManagement {
    height: auto !important;
}

.reportingTool p.wide-50 {
    font: normal normal normal 20px/24px IBM Plex Sans;
    letter-spacing: 0.15px;
    color: #000000de;
    width: 120px !important;
    height: 49px;
    opacity: 1 !important;
}

.titleBlock {
    margin: 16px 15px;
    height: 48px;
    font-size: 16px !important;
}
.titleBlock small {
    font-size: 16px !important;
    padding-top: 10px;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d !important;
}
.titleBlock small:hover {
    border: 3spx solid #b034a1;
    height: 48px;
}
.titleBlock span {
    background-color: #b034a1;
    height: 48px;
    width: 48px;
    text-align: center;
}

.graphing {
    margin: 16px 15px;
    height: 48px;
}

.submitButton {
    border: 2px solid black !important;
    background-color: blue !important;
}
.graphing small {
    font-size: 16px !important;
    font: normal normal normal 16px/24px IBM Plex Sans;
    color: #1d1d1d;
    padding-top: 10px;
}
.graphing small:hover {
    border: 3px solid #8456b0;
    height: 48px;
}
.graphing span {
    background-color: #8456b0;
    height: 48px;
    width: 48px;
    text-align: center;
}
.displayName {
    font-weight: bold !important;
    margin-left: -12px;
}
.directoryAssign {
    background: #dddddd;
    //margin-left: 130px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.tabTextContent {
    background-color: white !important;
    /* border: 20px solid #eaeaea; */

    height: 50px !important;
    padding-left: 10px;
    width: 83.6%;
    left: 36px;
    position: absolute;
}
.directoryItem {
    background-color: white !important;
    height: 48px !important;
    width: 499px !important;
    margin-left: 45px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    display: none;
}
.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: none !important ;
    border-bottom: none !important;
}
.contentCollapse {
    background-color: #fff;
    // width: 516px;
    margin-top: -23px;
    margin-left: 4px;
    margin-bottom: 20px;
    position: relative;
}
.accordianField .btn-secondary {
    color: #000;
    background-color: #fff;
    border-color: none !important;
    border-radius: 0 !important;
    // width: 516px;
}
.accordianField .btn-secondary:focus {
    color: #000 !important;
    background-color: #fff !important;
    border-color: none !important;
}
.accordianField button.btn.btn-secondary.btn-block.not-collapsed {
    color: #fff !important;
    background-color: #6e6e6e !important;
    border-color: none !important;
    border-radius: 0 !important;
}
.show a.option {
    // margin-left: 50px;
    box-shadow: 0px 0px 0px 0px !important;
    padding: 10px;
    margin-left: 170px !important;
    background-color: #fff !important;
}
.show > .btn-secondary.dropdown-toggle {
    top: -15px;
    position: absolute;
}
a.option {
    margin-left: 170px !important;
}

.test {
    margin-bottom: 20px;
}
.row1 {
    margin-bottom: 20px;
}
.searchinputIcon {
    background-color: #eaeaea;
    height: 34px;
    margin-left: 9px;
    border: none;
    padding-left: 40px;
}
ul.dropdown-menu.dropdown-menu-right.show {
    width: 200px;
    height: 200px;
}
// .btn-secondary:not(:disabled):not(.disabled):active {
//   background-color: transparent !important;
// }
button#__BVID__31__BV_toggle_ {
    background: none !important;
    border: none !important;
}
ul.dropdown-menu.dropdown-menu-right.show {
    margin-left: -28px !important;
    border-radius: 0px !important;
    border: none !important;
    margin-top: 0px !important;
    box-shadow: 0px 0 4px -2px !important;
}

.dataset {
    margin: 10px 15px;
    height: 48px;
}
.dataset small:hover {
    border: 3px solid #5878be;
    height: 48px;
}
.dataset span {
    background-color: #5878be;
    height: 48px;
    width: 48px;
    text-align: center;
}
.graphic {
    text-align: center;
    width: 253px;
}
.white {
    background-color: transparent !important;
    color: #000 !important;
}
.blue {
    color: #fff !important;
    background-color: #000 !important;
}
.whiteAssigned {
    background-color: transparent !important;
    color: #000 !important;
}
.blueAssigned {
    color: #fff !important;
    background-color: #000 !important;
}
.normal_input button {
    border: 1px solid gray;
}
// .graphic:active {
//   color: #fff !important;
//   border-color: #626262;
//   background-color: #000;
// }
.input-content {
    width: 412px !important;
    background-color: white !important;
    height: 48px;
    /* margin-top: 23px; */
    //margin-left: 104px;
}
.graphic:hover {
    border-color: #626262;
    background-color: #000;
}
.delete {
    width: 48px;
    height: 48px;
    float: right;
    margin-right: 28px;
    /* margin-left: 24px; */
    margin-top: 0px;
    background: url('../../../assets/delete_icon.svg') no-repeat right !important;
}
.delete.attribute {
    margin-top: -68px;
    margin-right: 0px;
}
.delete.contentDelete {
    margin-right: -61px;
    margin-top: -49px;
}
.assignedTabs {
    width: 499px;
    margin: auto;
    .normal_input.smallTabs {
        width: 408px !important;
    }
}
.main-button {
    margin-right: 0px;
    //margin-left: -56px;
    margin-top: 20px;
    width: 35%;
    .cancelButton {
        background: transparent;
        color: #006395;
        border: none;
        //margin-left: 312px;
    }
    .applyButton {
        background: #006395;
        color: white;
        border: none;
        float: right;
        cursor: pointer;
    }
}
.editButton {
    float: right;
    border-radius: 0;
    width: 70px;
    margin-top: -34px;
    margin-right: 40px;
}

.first-row {
    border-bottom: 1px solid #c6c6c6;
    margin-right: 0;
    border-top: 1px solid #c6c6c6;
    margin-left: 0;
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.second-row {
    margin-right: 0;
    margin-left: 0;
    border-bottom: 1px solid #c6c6c6;
    li {
        border-top: none !important;
    }
    span {
        color: #373a3c;
        font-weight: 500;
        // font-size: 16px IBM Plex Sans;
    }
}
.assigned-data {
    display: block;
    background-color: #fff;
    // width: 465px;
    height: 154px;
    //   margin-top: 30px;
    // margin-left: 127px;
}
.input-year {
    background-color: #eaeaea !important;
    border: none;
    margin-left: 122px;
}

.cancelButton {
    background: transparent;
    color: #006395;
    border: none;
    float: left;
}
.applyButton {
    background: #006395;
    color: white;
    border: none;
    float: right;
}

.selectedGraphictool {
    color: #fff !important;
    border-color: #626262;
    background-color: #000;
}
.contouring {
    margin: 16px 15px;
    height: 48px;
}
.contouring small:hover {
    border: 3px solid #3296ca;
    height: 48px;
}
.contouring span {
    background-color: #3296ca;
    height: 48px;
    width: 48px;
    text-align: center;
}

.mapToolAttribute {
    width: 225px;
    height: auto;
    border-radius: 4px;
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 1px solid #00000029; */
    margin: 0px;
    display: none;
    background-color: #fff;
    position: absolute;
    top: 40%;
    padding: 5px 10px;
    left: 30%;
    z-index: 999;
}
.normal_select {
    width: 475px;
    height: 52px;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    padding: 3px;
}
.normal_select:focus {
    border: 1px solid #c6c6c6 !important;
}
.mapToolAttribute h6 {
    color: #006395;
    font-weight: bold;
}
.drag-drop {
    margin-top: 10px;
    border: dotted #c6c6c6;
    background-color: #f8f8f8;
    width: 499px;
    height: 192px;
    p {
        text-align: center;

        width: 248px;
        margin-left: 128px;
        font-size: 18px;
        color: #000;
        a {
            font-size: 20px;
            color: #006395 !important;
        }
    }
}

.uploadButton {
    background-color: #3296ca;
    width: 83px;

    margin-top: 10px;
    border-radius: 3px;
}
.uploadicon {
    height: 56px;
    width: 82px;
}
.iconplacing {
    //padding-left: 200px;
    padding-top: 13px;
}
.attrTabs .tabs {
    border: 2px solid #eaeaea !important;
    margin-bottom: 135px;
}
.content {
    // background-color: #eaeaea !important;
    min-height: 350px;
    // padding: 30px 20px 20px 30px;
    // margin:auto;
    //padding: 30px;
    //text-align: center;
}

.my-custom-floating-label {
    background-color: white !important;
    // width: 400px !important;
}

.headergroup {
    background-color: #6e6e6e;
    color: #fff;
    border: none;
    margin-bottom: 15px;
    width: 499px;
    height: 48px;
    text-align: center;
}
.headergroup.assign-header {
    background-color: transparent;
    border: 2px solid #6e6e6e;
    // margin-top: 67px;
    // width: 471px;
    /* text-align: center; */
    // margin-left: 130px;
    color: #6e6e6e;
}
.reportingTool span img {
    margin-top: 13px;
}
.successToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #00db52;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.uploadToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 450px;
    border-left: 6px solid #933d9b;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.failureToastMsg {
    position: absolute;
    bottom: 50px;
    right: 20px;
    background: white;
    height: 70px;
    width: 350px;
    border-left: 6px solid #d23037;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 15px;
    box-shadow: 1px 1px 1px 1px #c6c6c6;
}
.headergroup.edit-header {
    width: 537px;
}

.assignAttr img {
    margin-top: 10%;
}

.tabs {
    padding-top: 10px;
}

ul.e-upload-files {
    border-top: none !important;
    margin-top: 70px !important;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

.card-header {
    margin-bottom: 20px;
    background: transparent;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
}
// .custom-select {
//   background: #fff url("../../assets/arrow_down.svg") no-repeat right 0.75rem
//     center/8px 10px !important;
//   width: 499px;
// }
.custom-control {
    padding: 0 !important;
    //float: left;
    margin-left: 8%;
    line-height: 27px;
}
#enterComments {
    width: 499px;
    height: 95px;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
}
.custom-select {
    background: #fff url('../../../assets/arrow_down.svg') no-repeat right
        0.75rem center/8px 10px !important;
    width: 499px;
    height: 48px;
}
.btn-info {
    color: #000;

    height: 82px;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    border: none;
    background: #eaeaea url('../../../assets/big_plus.svg') no-repeat right
        0.75rem center !important;
}
.btn-info:focus,
.btn-info.focus {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #eaeaea;
}
.btn-info:hover {
    color: #000;
    background-color: #eaeaea;
}
.normal_inputButton {
    height: 48px !important;
    border-radius: 3px;
    //margin-left: 104px;
    width: 499px;
    margin: auto;
}
button.btn.btn-info.btn-block.not-collapsed {
    background: #eaeaea url('../../../assets/minus_sign_big.svg') no-repeat
        right 0.75rem center !important;
}

.dragdrop {
    margin-left: 140px;
    text-align: center;
    font: normal normal normal 20px/25px IBM Plex Sans;
    letter-spacing: 0px;
    color: #000000;
}
// .nextbutton {
//   margin-right: 50px;
// }

.nextbutton:disabled:hover {
    background-color: #eaeaea !important;
}

//style for file manager
.file-overview .sample-container {
    margin: 10px 10px 10px 10px;
}
//listing file in toast message after document upload
/*css used here for graph options checkboxes */
#nav {
    /* width: 12em; */
    height: 20em;
    line-height: 2em;
    border: none;
    /* padding: 0; */
    /* margin: 0; */
    overflow: auto;
    /* overflow-x: hidden;*/
}

li {
    border-top: 1px solid #ccc;
}

ul ul {
    text-indent: 1em;
}

ul ul ul {
    text-indent: 2em;
}
.applyButtonSelect {
    background: #006395;
    color: white;
    border: none;
    cursor: pointer;
}
.delModal button {
    padding: 10px 20px;
    border-radius: 4px;
    margin-left: 20px;
}
.model_style {
    width: 900px !important;
}
.modal-dialog {
    max-width: 700px !important;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
input:disabled,
input[disabled] {
    background-color: #eaeaea;
    color: #bfc1c3 !important;
}
div#__BVID__62___BV_modal_backdrop_ {
    opacity: 1 !important;
    background-color: #8a8a8a !important;
}
.e-file-container {
    align-items: last baseline !important;
}
.e-upload .e-upload-files .e-upload-file-list .e-file-container .e-file-name {
    padding: 0 !important;
}
.e-list-text {
    color: #fff !important;
}
.e-hover {
    color: #000;
}

#file-manager {
    display: block !important;
    border: 1px solid red !important;
}

#file-manager_layout {
    display: block !important;
}
.upload-doc .custom-control {
    padding: 0 !important;
    float: left;
    margin-left: 30px;
}
.manage-form {
    margin: 0 auto;
    text-align: center;
    width: 499px;
}
#outersearchinput {
    height: 40px !important;
    padding-top: 5px;
}
// .icon-right {
//   position: absolute;
//   right: 0;
// }
.subheader-smalltext {
    margin-right: -80px;
}
.mAttribute {
    width: 130px !important;
    height: 20px !important;
    background-color: transparent !important;
}
.buttonNew {
    border: none;
    padding: 10px 18px;
    color: #006395 !important;
}
</style>
