<template>
    <b-container class="pt-3 pl-0 dataset-form">
        <div id="mapContainer" class="map-box">
            <l-map
                v-if="renderMap"
                ref="lmap"
                :zoom="zoom"
                :center="center"
                :bounds="mapBounds"
                @update:bounds="updateBounds"
                :options="{ zoomControl: false }"
                v-bind:max-zoom="21"
            >
                <l-control-layers position="topright"></l-control-layers>
                <l-tile-layer
                    v-for="baseMap in baseMaps"
                    :key="baseMap.name"
                    :name="baseMap.name"
                    :visible="baseMap.visible"
                    :attribution="baseMap.attribution"
                    :url="baseMap.url"
                    :options="baseMapOptions"
                    layer-type="base"
                >
                </l-tile-layer>
                <l-geo-json
                    :geojson="projectGeojson.postedLayers[0]"
                    :visible="projectGeojson.postedLayers[0].visible"
                    :options="postedGeoJsonOptions"
                    :options-style="postedGeoJsonStyling"
                    @ready="zoomToPosted"
                ></l-geo-json>
                <!-- </l-marker-cluster> -->
                <l-control position="bottomright">
                    <div class="map-control-button">
                        <a href="#" @click="zoomToPosted()">
                            Zoom To Layer
                        </a>
                    </div>
                </l-control>
                <l-control-zoom position="bottomright"></l-control-zoom>
            </l-map>
            <div v-else class="no-map-box">
                <div class="dataset-block">Save for Preview</div>
            </div>
        </div>
        <h5 class="mt-3">Posting layer display name</h5>
        <div class="leftmargin1 mr-1">
            <CustomFloatingLabel
                :config="{
                    label: 'Name',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <input
                    type="text"
                    class="normal_input"
                    name="displayName"
                    v-model="ProjectLayer.DisplayName"
                    autocomplete="off"
                />
            </CustomFloatingLabel>
            <h5 class="my-3">Default display option</h5>
            <div class="input-group-prepend">
                <b-button
                    class="graphic normal_input"
                    @click="changeDisplayOption('show')"
                    :class="
                        ProjectLayer.DisplayOption ? 'selectedGraphictool' : ''
                    "
                    >Show</b-button
                >
                <b-button
                    class="graphic normal_input"
                    @click="changeDisplayOption('hide')"
                    :class="
                        !ProjectLayer.DisplayOption ? 'selectedGraphictool' : ''
                    "
                    >Hide</b-button
                >
            </div>
        </div>
        <div class="mt-4 bottom-mrg">
            <h5>Select field to display</h5>
            <CustomFloatingLabel
                class="FloatingTool"
                :config="{
                    label: 'Field value',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <b-form-select
                    :options="selectedColoumns"
                    text-field="name"
                    value-field="name"
                    v-model="ProjectLayer.DisplayField"
                    @change="openMapView"
                >
                    <template #first>
                        <b-form-select-option :value="null"
                            >None</b-form-select-option
                        >
                    </template>
                </b-form-select>
            </CustomFloatingLabel>
        </div>
        <h5>Position <span class="l-grey">(optional) </span></h5>
        <PositionSelect :ProjectLayer="ProjectLayer"></PositionSelect>
        <div>
            <b-form-group
                class="optional-prnt"
                id="input-group-3"
                label-for="input-3"
            >
                <h5 class="mt-3">
                    Font options
                    <span class="l-grey">(optional)</span>
                </h5>
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Size',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <b-form-select v-model="ProjectLayer.FontSize">
                        <template #first>
                            <b-form-select-option :value="null" disabled
                                >Size</b-form-select-option
                            >
                        </template>

                        <option
                            v-for="user in styleDataSize"
                            :key="user.Size"
                            :value="user.Size"
                            >{{ user.Size }}</option
                        >
                    </b-form-select>
                </CustomFloatingLabel>
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Style',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <b-form-select v-model="ProjectLayer.FontStyle">
                        <b-form-select-option :value="null" disabled
                            >Style</b-form-select-option
                        >
                        <option
                            v-for="user in styleDataforStyle"
                            :key="user.Style"
                            :value="user.Style"
                            >{{ user.Style }}</option
                        >
                    </b-form-select>
                </CustomFloatingLabel>
                <div class="wrap">
                    <button class="btn-transparent">
                        <img
                            src="../../../../assets/ghd_colorpicker.svg"
                            font-scale="1.5"
                            class="pencil-icon colopicker-wrapper"
                        />
                    </button>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Color',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <ejs-colorpicker
                            id="element"
                            type="text"
                            class="inputbox-color"
                            v-model="ProjectLayer.FontColour"
                            readonly
                        ></ejs-colorpicker>
                    </CustomFloatingLabel>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Type',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <b-form-select v-model="ProjectLayer.FontType">
                            <b-form-select-option :value="null" disabled
                                >Type</b-form-select-option
                            >
                            <option
                                v-for="user in styleDataType"
                                :key="user.Type"
                                :value="user.Type"
                                >{{ user.Type }}</option
                            >
                        </b-form-select>
                    </CustomFloatingLabel>
                </div>
            </b-form-group>
            <p class="transparency">Transparency</p>
            <div>
                <div class="d-flex mb-3 col-6">
                    <span class="font-weight-bold indigo-text mr-2 mt-1"
                        >0</span
                    >
                    <form class="range-field grey-color">
                        <input
                            class="border-0 inputrange"
                            type="range"
                            v-model="ProjectLayer.FontTransparency"
                            min="0"
                            max="100"
                        />
                    </form>
                    <span class="font-weight-bold indigo-text ml-2 mt-1"
                        >100</span
                    >
                    <div class="input-value">
                        {{ ProjectLayer.FontTransparency }}%
                    </div>
                </div>
            </div>
            <b-form-group id="input-group-3" label-for="input-3">
                <h5>Symbology <span class="l-grey">(optional)</span></h5>
                <b-dropdown class="symbology mb-3" text="Symbology">
                    <template slot="button-content">
                        <img
                            class="img-fluid"
                            src="../../../../assets/circle.svg"
                            v-if="ProjectLayer.Symbology == 'circle'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/arrow.svg"
                            v-if="ProjectLayer.Symbology == 'arrow'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/flag.svg"
                            v-if="ProjectLayer.Symbology == 'flag'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/star_5.svg"
                            v-if="ProjectLayer.Symbology == 'star'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/cross_4.svg"
                            v-if="ProjectLayer.Symbology == 'cross'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/cross_3.svg"
                            v-if="ProjectLayer.Symbology == 'cross3'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/cross_5.svg"
                            v-if="ProjectLayer.Symbology == 'cross5'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/star_3.svg"
                            v-if="ProjectLayer.Symbology == 'star3'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/star_4.svg"
                            v-if="ProjectLayer.Symbology == 'star4'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/polygon_5.svg"
                            v-if="ProjectLayer.Symbology == 'polygon5'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/polygon_4.svg"
                            v-if="ProjectLayer.Symbology == 'polygon4'"
                        />
                        <img
                            class="img-fluid"
                            src="../../../../assets/polygon_3.svg"
                            v-if="ProjectLayer.Symbology == 'polygon3'"
                        />
                    </template>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'circle'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/circle.svg"
                        />
                    </b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'arrow'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/arrow.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'flag'"
                    >
                        <img class="img-fluid" src="../../../../assets/flag.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'star'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/star_5.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'cross'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/cross_4.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'cross3'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/cross_3.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'cross5'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/cross_5.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'star3'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/star_3.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'star4'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/star_4.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'polygon3'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/polygon_3.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'polygon4'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/polygon_4.svg"
                    /></b-dropdown-item>
                    <b-dropdown-item
                        @click.prevent="ProjectLayer.Symbology = 'polygon5'"
                    >
                        <img
                            class="img-fluid"
                            src="../../../../assets/polygon_5.svg"
                    /></b-dropdown-item>
                </b-dropdown>
                <div class="wrap">
                    <button class="btn-transparent">
                        <img
                            src="../../../../assets/ghd_colorpicker.svg"
                            font-scale="1.5"
                            class="pencil-icon colopicker-wrapper"
                        />
                    </button>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Color',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <ejs-colorpicker
                            id="element"
                            type="text"
                            class="inputbox-color"
                            v-model="ProjectLayer.SymbologyColour"
                            readonly
                        ></ejs-colorpicker>
                    </CustomFloatingLabel>
                </div>
                <div class="wrap">
                    <button class="btn-transparent">
                        <img
                            src="../../../../assets/ghd_colorpicker.svg"
                            font-scale="1.5"
                            class="pencil-icon colopicker-wrapper"
                        />
                    </button>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Outline color',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <ejs-colorpicker
                            id="element"
                            type="text"
                            class="inputbox-color"
                            v-model="ProjectLayer.PointOutlineColour"
                            readonly
                        ></ejs-colorpicker>
                    </CustomFloatingLabel>
                    <label class="switch ml-4">
                        <input
                            type="checkbox"
                            v-model="ProjectLayer.UsePointOutline"
                        />
                        <!-- @click="selectColoumns(element)" -->
                        <span class="slider round"></span>
                    </label>
                    <span
                        class="content ml-1"
                        v-if="ProjectLayer.UsePointOutline"
                        >Use outline colour
                    </span>
                    <span class="content ml-1" v-else>
                        No outline colour
                    </span>
                </div>
                <h5>Line styles <span class="l-grey">(optional)</span></h5>
                <div class="wrap">
                    <button class="btn-transparent">
                        <img
                            src="../../../../assets/ghd_colorpicker.svg"
                            font-scale="1.5"
                            class="pencil-icon colopicker-wrapper"
                        />
                    </button>
                    <CustomFloatingLabel
                        class="FloatingTool"
                        :config="{
                            label: 'Color',
                            name: 'wrapper',
                            line: false,
                            scale: false,
                        }"
                    >
                        <ejs-colorpicker
                            id="element"
                            type="text"
                            class="inputbox-color"
                            v-model="ProjectLayer.LineColour"
                            readonly
                        ></ejs-colorpicker>
                    </CustomFloatingLabel>
                </div>
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Line style',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <b-form-select
                        id="inline-form-custom-select-pref"
                        :value="null"
                        v-model="ProjectLayer.LineStyle"
                    >
                        <b-form-select-option :value="null"
                            >Line style</b-form-select-option
                        >
                        <b-form-select-option value="Solid"
                            >Solid</b-form-select-option
                        >
                        <b-form-select-option value="Dashed"
                            >Dashed</b-form-select-option
                        >
                        <b-form-select-option value="Dotted"
                            >Dotted</b-form-select-option
                        >
                    </b-form-select>
                </CustomFloatingLabel>
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Stroke width',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <b-form-select
                        id="inline-form-custom-select-pref"
                        :value="1"
                        v-model="ProjectLayer.LineWidth"
                        :options="[
                            //{ text: 'Stroke width', value: null },
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            '6',
                            '7',
                            '8',
                            '9',
                            '10',
                        ]"
                    >
                    </b-form-select>
                </CustomFloatingLabel>
            </b-form-group>
            <h5>Polygon options <span class="l-grey">(optional)</span></h5>
            <div class="wrap">
                <button class="btn-transparent">
                    <img
                        src="../../../../assets/ghd_colorpicker.svg"
                        font-scale="1.5"
                        class="pencil-icon colopicker-wrapper"
                    />
                </button>
                <CustomFloatingLabel
                    class="FloatingTool"
                    :config="{
                        label: 'Fill color',
                        name: 'wrapper',
                        line: false,
                        scale: false,
                    }"
                >
                    <ejs-colorpicker
                        id="element"
                        type="text"
                        class="inputbox-color"
                        v-model="ProjectLayer.PolygonColour"
                        readonly
                    ></ejs-colorpicker>
                </CustomFloatingLabel>
            </div>
            <CustomFloatingLabel
                class="FloatingTool"
                :config="{
                    label: 'Line style',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <b-form-select
                    id="inline-form-custom-select-pref"
                    :value="null"
                    v-model="ProjectLayer.PolygonStyle"
                >
                    <b-form-select-option :value="null"
                        >Line style</b-form-select-option
                    >
                    <b-form-select-option value="Solid"
                        >Solid</b-form-select-option
                    >
                    <b-form-select-option value="Dashed"
                        >Dashed</b-form-select-option
                    >
                    <b-form-select-option value="Dotted"
                        >Dotted</b-form-select-option
                    >
                </b-form-select>
            </CustomFloatingLabel>
            <CustomFloatingLabel
                class="FloatingTool"
                :config="{
                    label: 'Stroke width',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <b-form-select
                    id="inline-form-custom-select-pref"
                    :value="1"
                    v-model="ProjectLayer.PolygonWidth"
                    :options="[
                        //{ text: 'Stroke width', value: null },
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                    ]"
                >
                </b-form-select>
            </CustomFloatingLabel>
        </div>
        <div class="wrap">
            <button class="btn-transparent">
                <img
                    src="../../../../assets/ghd_colorpicker.svg"
                    font-scale="1.5"
                    class="pencil-icon colopicker-wrapper"
                />
            </button>
            <CustomFloatingLabel
                class="FloatingTool"
                :config="{
                    label: 'Border color',
                    name: 'wrapper',
                    line: false,
                    scale: false,
                }"
            >
                <ejs-colorpicker
                    id="element"
                    type="text"
                    class="inputbox-color"
                    v-model="ProjectLayer.PolygonBorderColour"
                    readonly
                ></ejs-colorpicker>
            </CustomFloatingLabel>
        </div>
        <p class="transparency">Fill transparency</p>
        <div class="d-flex mb-4 col-6 bottom-m-100">
            <span class="font-weight-bold indigo-text mr-2 mt-1">0</span>
            <form class="range-field grey-color">
                <input
                    class="border-0 inputrange"
                    type="range"
                    v-model="ProjectLayer.PolygonTransparency"
                    min="0"
                    max="100"
                />
            </form>
            <span class="font-weight-bold indigo-text ml-2 mt-1">100</span>
            <div class="input-value">
                {{ ProjectLayer.PolygonTransparency }}%
            </div>
        </div>
    </b-container>
</template>

<script>
import Vue from 'vue';
import CustomFloatingLabel from '../../../CustomFloatingLabel';
import { mapMarkerFunctions } from '../../../../utilities/mapMarkerFunctions';
import { marker, latLngBounds, circleMarker, divIcon } from 'leaflet';
import PositionSelect from './PositionSelect.vue';
import { projectService } from '../../../../services/project.service';
import { ColorPickerPlugin } from '@syncfusion/ej2-vue-inputs';

Vue.use(ColorPickerPlugin);

import {
    LMap,
    LTileLayer,
    LGeoJson,
    LControlLayers,
    LControlZoom,
    LControl,
} from 'vue2-leaflet';
export default {
    components: {
        CustomFloatingLabel,
        LMap,
        LTileLayer,
        LGeoJson,
        LControlLayers,
        LControlZoom,
        LControl,
        PositionSelect,
    },
    props: {
        selectedColoumns: Array,
        ProjectLayer: Object,
        DatasetId: Number,
        showPreview: Boolean,
        DisplayName: String,
    },
    data() {
        return {
            symbology: null,
            styleDataforStyle: [],
            styleDataType: [],
            styleDataSize: [],
            renderMap: false,
            zoom: 8,
            center: [49.28, -122.99],
            mapBounds: null,
            baseMaps: [
                {
                    name: 'OpenStreetMap',
                    visible: false,
                    attribution:
                        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                },
                {
                    name: 'Satellite_Imagery',
                    visible: false,
                    url:
                        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'NoMap',
                    visible: false,
                    url: '',
                    attribution: '',
                },
            ],
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 21,
            },
            layerList: [],
            postedDataLayers: [],
        };
    },
    methods: {
        zoomToPosted() {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            this.projectGeojson.postedLayers[0].features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                }
            });
            let xRange = maxX - minX;
            let yRange = maxY - minY;
            let zoomCushion = 0.1; // zoomCushion*100 is the %buffer
            this.mapBounds = latLngBounds([
                [
                    this.convertLat(minY - yRange * zoomCushion),
                    this.convertLong(minX - xRange * zoomCushion),
                ],
                [
                    this.convertLat(maxY + yRange * zoomCushion),
                    this.convertLong(maxX + xRange * zoomCushion),
                ],
            ]);
        },
        openMapView() {
            if (!this.DatasetId) {
                return;
            }
            projectService
                .getDataCoordinates(
                    this.DatasetId,
                    this.ProjectLayer.DisplayField || null
                )
                .then((res) => {
                    this.postedDataLayers = res.data;
                    this.processData();
                    this.renderMap = true;
                })
                .catch((err) => {
                    this.failureToastMessage = err.response.data.Message;
                    this.showSuccess = false;
                    this.showFailure = true;
                    this.showError = false;
                    setTimeout(() => {
                        this.showFailure = false;
                    }, 5000);
                });
        },
        getStyleBlock() {
            projectService.getStyleStyle().then((res) => {
                this.styleDataforStyle = res.data;
            });
            projectService.getStyleType().then((res) => {
                this.styleDataType = res.data;
            });
            projectService.getStyleSize().then((res) => {
                this.styleDataSize = res.data;
            });
        },
        updateBounds(bounds) {
            this.mapBounds = bounds;
        },
        changeDisplayOption(state) {
            if (state == 'show') {
                this.ProjectLayer.DisplayOption = true;
            } else {
                this.ProjectLayer.DisplayOption = false;
            }
        },
        getBounds(layer) {
            let minX = 180;
            let minY = 90;
            let maxX = -180;
            let maxY = -90;
            layer[0].features.forEach((location) => {
                if (location.geometry.type == 'Polygon') {
                    location.geometry.coordinates[0].forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'LineString') {
                    location.geometry.coordinates.forEach((point) => {
                        let xCoord = point[0];
                        let yCoord = point[1];
                        if (!isNaN(xCoord) && !isNaN(yCoord)) {
                            minX = Math.min(minX, parseFloat(xCoord));
                            maxX = Math.max(maxX, parseFloat(xCoord));
                            minY = Math.min(minY, parseFloat(yCoord));
                            maxY = Math.max(maxY, parseFloat(yCoord));
                        }
                    });
                } else if (location.geometry.type == 'Point') {
                    let xCoord = location.geometry.coordinates[0];
                    let yCoord = location.geometry.coordinates[1];
                    if (!isNaN(xCoord) && !isNaN(yCoord)) {
                        minX = Math.min(minX, parseFloat(xCoord));
                        maxX = Math.max(maxX, parseFloat(xCoord));
                        minY = Math.min(minY, parseFloat(yCoord));
                        maxY = Math.max(maxY, parseFloat(yCoord));
                    }
                }
            });
            this.mapBounds = latLngBounds([
                [this.convertLat(minY), this.convertLong(minX)],
                [this.convertLat(maxY), this.convertLong(maxX)],
            ]);
        },
        convertLat(val) {
            val = val % 180;
            if (val < -90) {
                val += 180;
            } else if (val > 90) {
                val -= 180;
            }
            return parseFloat(val.toFixed(6));
        },
        convertLong(val) {
            val = val % 360;
            if (val < -180) {
                val += 360;
            } else if (val > 180) {
                val -= 360;
            }
            return parseFloat(val.toFixed(6));
        },
        processData() {
            let dataSources = [];
            if (this.postedDataLayers.length > 0) {
                dataSources = this.postedDataLayers;
            }

            let styleCode;
            let fontSize;
            let fontFamily;
            let fontWeight;
            let rotation;
            let transparency;
            if (this.ProjectLayer.FontSize) {
                fontSize = this.ProjectLayer.FontSize + 'px';
            } else {
                fontSize = '8px';
            }
            if (this.ProjectLayer.FontType) {
                fontFamily = this.ProjectLayer.FontType;
            } else {
                fontFamily = 'IBM Plex Sans Regular';
            }
            if (this.ProjectLayer.FontStyle) {
                fontWeight = this.ProjectLayer.FontStyle;
            } else {
                fontWeight = 'normal';
            }
            if (this.ProjectLayer.FontRotation) {
                rotation = 'rotate(' + this.ProjectLayer.FontRotation + 'deg)';
            } else {
                rotation = 'rotate(0deg)';
            }
            if (this.ProjectLayer.FontTransparency) {
                transparency = this.ProjectLayer.FontTransparency;
            } else {
                transparency = '1';
            }
            if (this.ProjectLayer.Position === 'topLeft') {
                styleCode =
                    '<div><div style="margin-bottom: 30px;padding-right:60px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'topRight') {
                styleCode =
                    '<div><div style="margin-bottom: 30px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'topCenter') {
                styleCode =
                    '<div><div style="margin-bottom: 30px;padding-right:25px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'bottomLeft') {
                styleCode =
                    '<div><div style="margin-top: 11px;padding-right:50px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'bottomCenter') {
                styleCode =
                    '<div><div style="margin-top: 11px;padding-right:20px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'bottomRight') {
                styleCode =
                    '<div><div style="margin-top: 11px;font-family:' +
                    fontFamily +
                    'font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'middleLeft') {
                styleCode =
                    '<div><div style="margin-bottom: 3px;padding-right:60px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'middleCenter') {
                styleCode =
                    '<div><div style="margin-bottom: 3px;padding-right:25px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else if (this.ProjectLayer.Position === 'middleRight') {
                styleCode =
                    '<div><div style="margin-bottom: 3px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    '">${result}</div></div>';
            } else {
                styleCode =
                    '<div><div style="margin-top: 11px;padding-right:20px;font-family:' +
                    fontFamily +
                    ';font-size:' +
                    fontSize +
                    ';font-weight:' +
                    fontWeight +
                    ';font-style:' +
                    fontWeight +
                    ';transform:' +
                    rotation +
                    ';opacity:' +
                    transparency +
                    '">${result}</div></div>';
            }
            let markerSettings = [
                {
                    visible: true,
                    dataSource: this.postedDataLayers,
                    shape: 'Image',
                    imageUrl:
                        this.ProjectLayer.Symbology == 'arrow'
                            ? '../../../assets/arrow.svg' //document.getElementById("referenceArrowImage").src
                            : this.ProjectLayer.Symbology == 'flag'
                            ? '../../../assets/flag.svg' //document.getElementById("referenceFlagImage").src
                            : this.ProjectLayer.Symbology == 'circle'
                            ? '../../../assets/circle.svg' //document.getElementById("referenceCircleImage").src
                            : this.ProjectLayer.Symbology == 'star'
                            ? '../../../assets/star_5.svg' //document.getElementById("referenceStarImage").src
                            : this.ProjectLayer.Symbology == 'star3'
                            ? '../../../assets/star_3.svg' //document.getElementById("referenceStar3Image").src
                            : this.ProjectLayer.Symbology == 'star4'
                            ? '../../../assets/star_4.svg' //document.getElementById("referenceStar4Image").src
                            : this.ProjectLayer.Symbology == 'cross3'
                            ? '../../../assets/cross_3.svg' //document.getElementById("referenceCross3Image").src
                            : this.ProjectLayer.Symbology == 'cross5'
                            ? '../../../assets/cross_5.svg' //document.getElementById("referenceCross5Image").src
                            : this.ProjectLayer.Symbology == 'polygon3'
                            ? '../../../assets/polygon_3.svg' //document.getElementById("referencePolygon3Image").src
                            : this.ProjectLayer.Symbology == 'polygon4'
                            ? '../../../assets/polygon_4.svg' //document.getElementById("referencePolygon4Image").src
                            : this.ProjectLayer.Symbology == 'polygon5'
                            ? '../../../assets/polygon_5.svg' //document.getElementById("referencePolygon5Image").src
                            : '../../../assets/circle.svg', //document.getElementById("referenceCircleImage").src,

                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        // valuePath: "sitecode",
                        textStyle: {
                            size: this.ProjectLayer.FontSize
                                ? this.ProjectLayer.FontSize + 'px'
                                : '10px',
                            fontFamily: this.ProjectLayer.FontType
                                ? this.ProjectLayer.FontType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.ProjectLayer.FontStyle
                                ? this.ProjectLayer.FontStyle
                                : 'normal',
                        },
                    },
                },
                {
                    visible: true,
                    dataSource: dataSources,
                    template: styleCode,
                    tooltipSettings: {
                        visible: true,
                        valuePath: 'result',
                        //  valuePath: "sitecode",
                        textStyle: {
                            size: this.ProjectLayer.FontSize
                                ? this.ProjectLayer.FontSize + 'px'
                                : '10px',
                            fontFamily: this.ProjectLayer.FontType
                                ? this.ProjectLayer.FontType
                                : 'IBM Plex Sans Regular',
                            fontWeight: this.ProjectLayer.FontStyle
                                ? this.ProjectLayer.FontStyle
                                : 'normal',
                        },
                    },
                },
            ];
            let postingId = 1;
            let layerDetails = {
                postingId: postingId,
                visible: true,
                DisplayName: this.layerName,
                FIELDNAME: this.fieldName,
                radioSource: this.postingSource,
                color: this.colorSelected,
                SYMBOLOGY: this.ProjectLayer.Symbology,
                SIZE: this.ProjectLayer.FontSize,
                STYLE: this.ProjectLayer.FontStyle,
                TYPE: this.ProjectLayer.FontType,
                ROTATION: this.ProjectLayer.FontRotation,
                TRANPARENCY: this.ProjectLayer.FontTransparency,
                markerSettings11: markerSettings,
                position: this.ProjectLayer.Position,
            };

            this.localLayer = [layerDetails];
            this.layerList = this.localLayer;
        },
    },
    created() {
        this.getStyleBlock();
    },
    computed: {
        projectGeojson() {
            let output1 = [];
            let output2 = [];
            if (this.layerList) {
                this.layerList.forEach((item) => {
                    output2.push(item.markerSettings11[0].dataSource);
                });
            }
            this.getBounds(output2);
            return { projectLayers: output1, postedLayers: output2 };
        },
        postedGeoJsonOptions() {
            const labelStyles = this.labelStyleText;
            const iconSVG = mapMarkerFunctions.getIcon(
                this.ProjectLayer.Symbology,
                this.ProjectLayer.SymbologyColour,
                this.ProjectLayer.UsePointOutline
                    ? this.ProjectLayer.PointOutlineColour
                    : ''
            );
            var tooltipPosition = '';
            var tooltipOffset = mapMarkerFunctions.getIconAnchor(
                this.ProjectLayer.Symbology,
                this.ProjectLayer.UsePointOutline
                    ? this.ProjectLayer.PointOutlineColour
                    : ''
            );

            return {
                onEachFeature: this.onEachPostedFeatureFunction,
                pointToLayer: function(feature, latlng) {
                    // const fillColor = this.fillColor
                    return marker(latlng, {
                        icon: divIcon({
                            html: iconSVG,
                            className:
                                'ThisHasToBeSomethingToHideTheDefaultShadow',
                        }),
                        opacity: 1,
                    })
                        .bindTooltip(
                            '<span style="' +
                                labelStyles +
                                '">' +
                                feature.properties.result +
                                '</span>',
                            {
                                permanent: true,
                                direction: tooltipPosition,
                                className: 'leafletLabelClass',
                                //            x, y
                                offset: tooltipOffset,
                                // opacity: 0.1,
                            }
                        )
                        .openTooltip();
                },
                // style: this.styleFunction,
            };
        },

        postedGeoJsonStyling() {
            const polygonFillColour = this.ProjectLayer.PolygonColour;
            const polygonBorderColour = this.ProjectLayer.PolygonBorderColour;
            const polygonBorderWidth = this.ProjectLayer.PolygonWidth;
            const polygonTransparency = this.ProjectLayer.PolygonTransparency;
            const lineStrokeColour = this.ProjectLayer.LineColour;
            const lineStrokeWidth = this.ProjectLayer.LineWidth;
            var polygonDashArray = '';
            var polylineDashArray = '';
            if (this.ProjectLayer.PolygonStyle == 'Dashed') {
                polygonDashArray = '4 4';
            } else if (this.ProjectLayer.PolygonStyle == 'Dotted') {
                polygonDashArray = '1 2';
            }
            if (this.ProjectLayer.PolygonStyle == 'Dashed') {
                polylineDashArray = '4 4';
            } else if (this.ProjectLayer.PolygonStyle == 'Dotted') {
                polylineDashArray = '1 2';
            }
            return (feature, layer) => {
                let outputStyle = {};
                if (feature.geometry.type == 'Polygon') {
                    outputStyle = {
                        weight: parseInt(polygonBorderWidth),
                        color: polygonBorderColour,
                        opacity: 100 - polygonTransparency,
                        fillColor: polygonFillColour,
                        fillOpacity: 100 - polygonTransparency,
                        dashArray: polygonDashArray,
                    };
                } else if (feature.geometry.type == 'MultiPolygon') {
                    outputStyle = {
                        weight: parseInt(polygonBorderWidth),
                        color: polygonBorderColour,
                        opacity: 100 - polygonTransparency,
                        fillColor: polygonFillColour,
                        fillOpacity: 100 - polygonTransparency,
                        dashArray: polygonDashArray,
                    };
                } else if (feature.geometry.type == 'LineString') {
                    outputStyle = {
                        weight: parseInt(lineStrokeWidth),
                        color: lineStrokeColour,
                        opacity: 100,
                        dashArray: polylineDashArray,
                    };
                }
                return outputStyle;
            };
        },
        onEachPostedFeatureFunction() {
            const fontColour = this.ProjectLayer.FontColour;
            const fontSize = this.ProjectLayer.FontSize;
            const fontType = this.ProjectLayer.FontType;
            const fontStyle = this.ProjectLayer.FontStyle;
            const labelPosition = this.ProjectLayer.Position;
            return (feature, layer) => {
                layer.on('click', (e) => {});
                let toolTipDisplay = '';
                let labelDisplay = '';
                let popupDisplay = `<table class="table table-striped table-sm mt-3">
                            <tbody>`;
                feature.properties.forEach((item) => {
                    if (item.label == this.ProjectLayer.DisplayField) {
                        labelDisplay += `<span style='color: ${fontColour}; font-size: ${fontSize}pt;
            font-family: ${fontType}'>${item.value}</span>`;
                    }
                    if (
                        item.tooltip &&
                        item.name == this.ProjectLayer.DisplayField
                    ) {
                        toolTipDisplay += `<span style='color: ${fontColour}; font-size: ${fontSize}pt;
            font-family: ${fontType};`;
                        if (fontStyle == 'Bold') {
                            toolTipDisplay += ' font-weight:bold;';
                        } else if (fontStyle == 'Italic') {
                            toolTipDisplay += ' font-style: italic;';
                        }
                        if (labelPosition != null) {
                            if (labelPosition.includes('Left')) {
                                toolTipDisplay += 'text-align: right;';
                            } else if (labelPosition.includes('Center')) {
                                toolTipDisplay += 'text-align: center;';
                            } else if (labelPosition.includes('Right')) {
                                toolTipDisplay += 'text-align: left;';
                            } else if (this.dataLabelPosition.includes('top')) {
                                toolTipDisplay += '';
                            } else if (
                                this.dataLabelPosition.includes('middle')
                            ) {
                                toolTipDisplay += '';
                            } else if (
                                this.dataLabelPosition.includes('bottom')
                            ) {
                                toolTipDisplay += '';
                            }
                        }
                        toolTipDisplay += `'>${item.value}</span>`;
                    }
                    if (item.popup) {
                        popupDisplay += `<tr>
                                <td>${item.name}</td>
                                <td>${item.value}</td>
                            </tr>`;
                    }
                });
                popupDisplay += `  </tbody>
                        </table>`;
                var tooltipOffset = [0, 0]; // x, y
                var tooltipPosition = '';
                switch (this.ProjectLayer.Position) {
                    case 'topLeft':
                        tooltipPosition = 'left';
                        tooltipOffset = [0, -20]; // x, y
                        break;
                    case 'topCenter':
                        tooltipPosition = 'top';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'topRight':
                        tooltipPosition = 'right';
                        tooltipOffset = [0, -20]; // x, y
                        break;
                    case 'middleLeft':
                        tooltipPosition = 'left';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'middleCenter':
                        tooltipPosition = 'center';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'middleRight':
                        tooltipPosition = 'right';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'bottomLeft':
                        tooltipPosition = 'left';
                        tooltipOffset = [0, 20]; // x, y
                        break;
                    case 'bottomCenter':
                        tooltipPosition = 'bottom';
                        tooltipOffset = [0, 0]; // x, y
                        break;
                    case 'bottomRight':
                        tooltipPosition = 'right';
                        tooltipOffset = [0, -20]; // x, y
                        break;
                }
                layer.bindTooltip(toolTipDisplay, {
                    permanent: true,
                    sticky: true,
                    direction: tooltipPosition,
                    className: 'leafletLabelClass',
                    offset: tooltipOffset,
                });
                layer.bindPopup(popupDisplay, {
                    permanent: false,
                    sticky: false,
                });
            };
        },
        labelStyleText() {
            var tooltipStyle = '';
            if (this.ProjectLayer.FontSize) {
                tooltipStyle +=
                    'font-size: ' + this.ProjectLayer.FontSize + 'pt;';
            }
            switch (this.ProjectLayer.FontStyle) {
                case 'Bold':
                    tooltipStyle += 'font-weight: bold;';
                    break;
                case 'Italic':
                    tooltipStyle += 'font-style: italic;';
                    break;
            }
            switch (this.ProjectLayer.FontType) {
                case 'Arial':
                    tooltipStyle += 'font-family: Arial, sans-serif;';
                    break;
                case 'Helvetica':
                    tooltipStyle += 'font-family: Helvetica, sans-serif;';
                    break;
                case 'Times New Roman':
                    tooltipStyle +=
                        "font-family: 'Times New Roman', Times, serif;";
                    break;
                case 'Georgia':
                    tooltipStyle += 'font-family: Georgia;';
                    break;
                case 'Lucida Console':
                    tooltipStyle += "font-family: 'Lucida Sans';";
                    break;
                case 'Courier New':
                    tooltipStyle +=
                        "font-family: 'Courier New', Courier, monospace;";
                    break;
            }
            tooltipStyle += 'color: ' + this.ProjectLayer.FontColour + ';';
            if (this.ProjectLayer.Position != null) {
                if (this.ProjectLayer.Position.includes('top')) {
                    tooltipStyle += '';
                } else if (this.ProjectLayer.Position.includes('middle')) {
                    tooltipStyle += '';
                } else if (this.ProjectLayer.Position.includes('bottom')) {
                    tooltipStyle += '';
                }
                if (this.ProjectLayer.Position.includes('Left')) {
                    tooltipStyle += 'text-align: right;';
                } else if (this.ProjectLayer.Position.includes('Center')) {
                    tooltipStyle += 'text-align: center;';
                } else if (this.ProjectLayer.Position.includes('Right')) {
                    tooltipStyle += 'text-align: left;';
                }
            }
            return tooltipStyle;
        },
    },
    mounted() {
        if (this.DatasetId) {
            setTimeout(() => {
                this.openMapView();
            });
            if (!this.ProjectLayer.DisplayOption) {
                this.ProjectLayer.DisplayOption = true;
            }
        }

        if (!this.ProjectLayer.DisplayName) {
            this.ProjectLayer.DisplayName = this.DisplayName;
        }
    },
    watch: {
        ProjectLayer(oldVal, newVal) {
            this.openMapView();
        },
        showPreview(NewVal) {
            if (NewVal) {
                this.renderMap = false;
                this.openMapView();
                if (!this.ProjectLayer.DisplayName) {
                    this.ProjectLayer.DisplayName = this.DisplayName;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.graphic {
    text-align: center;
    width: 250px;
    height: 48px;
    border: none !important;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #c6c6c6;
    border: 1px solid #c6c6c6 !important;
    color: #000 !important;
    background: #fff !important;
}
.datasetColumn .graphic {
    width: 166.5px !important;
}
.graphic:hover {
    color: #fff !important;
    border-radius: 3px;
    box-shadow: 0 0 0 1px #c6c6c6;
    background-color: #000 !important;
    cursor: pointer;
}
.selectedGraphictool {
    background-color: #000 !important;
    color: #fff !important;
    border-color: #c6c6c6;
    border: 1px solid #c6c6c6;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.form-group.optional-prnt {
    margin-bottom: 0 !important;
}
/* ------------------------------------------Position Button Css Ends Here------------------------------- */
/* Colorpicker style*/
.wrap {
    // margin: 0 auto;
    width: 345px;
    position: relative;
}
/* Input element customization */
.e-colorpicker-wrapper .e-split-btn-wrapper #element.e-input {
    height: 48px !important;
    margin: 0 !important;
    margin-left: -140px !important;
    margin-right: 20px;
    margin-top: -20px;
    opacity: 1 !important;
    position: initial !important;
    width: 499px;
    margin-left: -35px;
    padding-left: 30px;
}
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    background-color: #fff !important;
    color: #fff !important;
    border: none !important;
    display: none;
}
/* To hide primary button */
// .e-colorpicker-wrapper .e-split-btn-wrapper .e-split-btn {
//   display: none !important;
// }
/* Secondary button customization */
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn {
    background: transparent;
    border-color: transparent;
    bottom: 44px !important;
    // border-bottom-color: rgba(0, 0, 0, 0.42);
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-split-colorpicker
    + .e-dropdown-btn {
    background-color: transparent !important;
    margin-left: -20px;
    z-index: 999;
}
.e-dropdown-btn .e-caret::before,
.e-dropdown-btn.e-btn .e-caret::before {
    color: transparent;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn,
.e-colorpicker-wrapper .e-btn.e-icon-btn {
    z-index: 9;
    position: relative;
    top: -34px;
    left: 5px;
    background: transparent !important;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-input:focus
    + .e-btn.e-dropdown-btn {
    border-bottom-color: #e3165b;
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-btn.e-dropdown-btn .e-caret {
    transform: rotate(0deg);
    transition: transform 200ms ease-in-out;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-btn.e-dropdown-btn.e-active
    .e-caret {
    transform: rotate(180deg);
}
.e-colorpicker-wrapper .e-split-btn-wrapper .e-split-colorpicker.e-split-btn {
    background-color: none !important;
    border-color: none !important;
    border: 0 !important;
}
.e-split-btn-wrapper {
    position: relative !important;
    top: -20px !important;
    left: 10px !important;
}
/* Secondary button customization */
.e-colorpicker-wrapper .e-colorpicker,
.e-colorpicker-container .e-colorpicker {
    opacity: 1;
    width: 499px;
    height: 48px;
    position: static;
    padding-left: 40px;
    font-size: 14px;
}
.e-colorpicker-wrapper
    .e-split-btn-wrapper
    .e-split-colorpicker.e-split-btn
    .e-selected-color {
    bottom: 40px !important;
}
.e-split-btn-wrapper {
    height: 0 !important;
}
#e-split-btn_0_dropdownbtn {
    left: 430px !important;
    bottom: 47px !important;
}
.e-split-btn .e-keyboard {
    bottom: 44px !important;
}
.inputbox-color {
    height: 48px;
    width: 499px;
    border: 1px solid rgb(206, 212, 218) !important;
    padding-left: 12px;
    border-radius: 3px;
    color: #495057;
}

.e-btn.e-flat,
.e-css.e-btn.e-flat {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
}

.applyButton {
    background: #006395;
    color: white;
    border: none;
    padding: 11px;
    border-radius: 3px;
    bottom: 20px;
}
.e-grid .e-content {
    margin-left: 5px;
}
.e-fullrow {
    background-color: #eee;
}
.e-treeview .e-list-item.e-hover > .e-fullrow,
.e-treeview .e-list-item.e-active > .e-fullrow {
    background-color: #eee;
}
.d-flex {
    padding: 0 !important;
}
.input-value {
    display: block;
    background: #f3f3f3 0% 0% no-repeat padding-box;
    // margin-left: 21px;
    padding: 10px;
    margin-top: -5px;
    position: absolute;
    right: -256px;
    border-bottom: 1px solid #929292;
}

.no-map-box {
    height: 440px;
    position: relative;
    border: 1px solid #c6c6c6;
    border-radius: 3px;
    width: 483px;
}
.map-box {
    height: 440px;
    left: 50%;
    border: 1px solid #c6c6c6;
}
.colopicker-wrapper {
    position: relative !important;
    float: right !important;
    margin-top: 47px !important;
    margin-right: 24px !important;
}
</style>
