<template>
    <div v-if="modelVisible">
        <draggable-div
            :style="modelPaneStyle"
            :rightBound="parentBounds.width"
            :bottomBound="parentBounds.height"
            v-bind:allowToggleMain="true"
            class="modelPane"
            @mouseDragStart="onDragStart"
            @mouseDragStop="onDragEnd"
            pId="modelB"
        >
            <template v-slot:header>
                <span class="py-2">
                    <span class="modelTitle">Model B</span>
                </span>
            </template>
            <template v-slot:main>
                <div class="popup-dreams">
                    <div class="maprow">
                        <l-map
                            ref="mapb"
                            class="mapClass"
                            :options="mapOptions"
                            :min-zoom="minZoom"
                            :max-zoom="maxZoom"
                            :zoom.sync="mapData.mapZoom"
                            :center.sync="mapData.mapCenter"
                            @ready="initializeMap"
                            @update:bounds="updateBounds"
                        >
                            <model-legend
                                ref="legendPane"
                                :bbox="bbox"
                                :scalebarUnits="scalebarUnits"
                                :wmsFirst="false"
                                :zoomLevel="mapData.mapZoom"
                                :wmsLayers="WMS_requests"
                            >
                            </model-legend>
                            <l-tile-layer
                                :key="baseMap.name"
                                :name="baseMap.name"
                                :visible="baseMap.visible"
                                :attribution="baseMap.attribution"
                                :url="baseMap.url"
                                layer-type="base"
                                :options="baseMapOptions"
                            ></l-tile-layer>
                            <l-wms-tile-layer
                                v-for="wms in WMS_requests"
                                :ref="'wms-layer-' + wms.name.replace(/ /g, '')"
                                :key="wms.id"
                                :name="wms.name"
                                :base-url="wms.url"
                                :visible="wms.isChecked"
                                :layers="wms.layers"
                                :styles="wms.styles"
                                :options="wms.options"
                                :format="wms.format"
                                :opacity="wms.opacity"
                                :z-index="wms.zIndex"
                                :tileSize="wms.tileSize"
                                :transparent="wms.transparent"
                                :attribution="wms.attribution"
                                layer-type="overlay"
                            ></l-wms-tile-layer>
                        </l-map>
                    </div>
                </div>
            </template>
            <template v-slot:footer>
                <span class="modelPaneFooter">
                    <span class="helpText">Click and drag to reposition</span>
                </span>
            </template>
        </draggable-div>
    </div>
</template>

<script>
import DraggableDiv from '../CommonComponents/DraggableWrapper.vue';
import { mapState, mapGetters } from 'vuex';
import { LMap, LTileLayer, LWMSTileLayer } from 'vue2-leaflet';
import ModelLegend from './ModelLegend.vue';

import { latLngBounds } from 'leaflet';

export default {
    name: 'ModelB',
    components: {
        DraggableDiv,
        ModelLegend,
        LMap,
        LTileLayer,
        'l-wms-tile-layer': LWMSTileLayer,
    },
    mixins: [],
    props: {
        bbox: {
            type: Object,
            default: () => {},
        },
        scalebarUnits: {
            type: String,
            default: 'Metric',
        },
    },
    data() {
        return {
            max_tile_zoom: 15,
            mapOptions: {
                zoomControl: false,
                dragging: true,
                attributionControl: false,
                doubleClickZoom: false,
            },
            minZoom: 1,
            maxZoom: 29,
            selectedBasemap: 0,
            baseMapOptions: {
                maxNativeZoom: 18,
                maxZoom: 29,
            },
            baseMaps: [
                {
                    name: 'TopoMap',
                    visible: true,
                    url:
                        'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
                {
                    name: 'Satellite_Imagery',
                    visible: true,
                    url:
                        'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                },
            ],
            baseMapReferences: [
                {
                    name: 'transportation',
                    url:
                        'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}',
                    attribution: '',
                },
                {
                    name: 'boundaries_and_places',
                    url:
                        'https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}',
                    attribution: '',
                },
            ],
        };
    },
    created() {
        this.parentBounds = this.$parent.$el.getBoundingClientRect();
    },
    methods: {
        onDragStart() {
            this.$emit('dragStart');
        },
        onDragEnd() {
            this.$emit('dragEnd');
        },
        initializeMouseCoords() {
            this.mouseCoords = this.center[0] + ' ' + this.center[1];
        },
        updateMapSize() {
            setTimeout(() => {
                try {
                    this.$store.commit(
                        'projects/mutateMapSize',
                        this.$refs.mapb.mapObject._size
                    );
                } catch (e) {
                    // can we skip this?
                }
            }, 500);
        },
        initializeMap() {
            this.initializeMouseCoords();
            this.updateMapSize();
            window.addEventListener('resize', this.updateMapSize);
        },
        updateBounds(bounds) {
            this.mapBounds = bounds;
        },
    },
    watch: {},
    computed: {
        ...mapGetters('dreams', {
            modelVisible: 'dreamsShowModel',
        }),
        ...mapState('emergencyManagement', {
            layersStruct: 'layersStructValues',
        }),
        ...mapState('projects', ['mapData']),
        ...mapState('oneMap', ['zoomToExtent']),
        baseMap: function() {
            return this.baseMaps[this.selectedBasemap];
        },
        modelPaneStyle: function() {
            return {
                position: 'absolute',
                zIndex: 9999,
                width: this.width + 'px',
                left: 'calc(60% - 70px)',
                top: '200px',
            };
        },
        WMS_requests: function() {
            var layers = [];
            try {
                var checkZoom = this.zoom > this.max_tile_zoom;
                this.layersStruct.forEach((layer) => {
                    // additional handling for tiled layers when zoom level > 15
                    if (this.zoom > this.max_tile_zoom) {
                        if (
                            layer.layers != '' &&
                            layer.url != '' &&
                            layer.isChecked &&
                            layer.minZoom <= this.zoom &&
                            this.zoom <= layer.maxZoom
                        ) {
                            if (layer.options.tiled) {
                                // tile converted to wms layer
                                layers.push({
                                    name: layer.name,
                                    url: layer.url,
                                    layers: layer.layers,
                                    isChecked: layer.isChecked,
                                    attribution: layer.attribution,
                                    transparent: layer.transparent,
                                    maxZoom: layer.maxZoom,
                                    opacity: layer.opacity,
                                    format: layer.format,
                                    styles: layer.styles,
                                    tileSize: 512,
                                    zIndex: layer.zIndex,
                                    minZoom: layer.minZoom,
                                    options: {
                                        CQL_Filter: "model in ('model_b')",
                                        authkey: layer.options.authkey,
                                        editTime: layer.options.editTime,
                                        height: 512,
                                        width: 512,
                                        tileSize: 512,
                                        maxNativeZoom:
                                            layer.options.maxNativeZoom,
                                        maxZoom: layer.options.maxZoom,
                                        tiled: false,
                                    },
                                });
                            } else {
                                // Regular wms layer
                                layers.push(layer);
                            }
                        }
                        // only push wms layers when zoom level <= 15
                    } else {
                        if (
                            layer.layers != '' &&
                            layer.url != '' &&
                            layer.isChecked &&
                            layer.options.tiled != true &&
                            layer.minZoom <= this.zoom &&
                            this.zoom <= layer.maxZoom
                        ) {
                            layer.options.CQL_Filter = "model in ('model_b')";
                            layers.push(layer);
                        }
                    }
                });
            } catch (e) {
                return [];
            }
            return layers;
        },
        zoom() {
            return this.mapData.mapZoom;
        },
        center() {
            return this.mapData.mapCenter;
        },
    },
};
</script>

<style scoped>
.popup-dreams {
    padding: 1px !important;
    width: 100%;
    height: 500px;
    margin: 0 !important;
    border-radius: 0 !important;
    overflow-y: auto !important;
    overflow-x: auto !important;
}
.popup-dreams > .maprow {
    width: 100%;
    height: 100%;
    margin: 0px !important;
}

.modelPane {
    border-radius: 3px;
    width: 40%;
    bottom: 75px;
    background-color: white;
    border: solid 3px #0078d4;
}
.modelTitle {
    font-weight: bold;
    font-size: 16px;
}
.modelPaneFooter {
    position: relative;
}
</style>
