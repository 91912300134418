<template>
    <div v-if="showPage" class="data-set-col">
        <b-container fluid class="datasetcolumns sub_header_bg">
            <b-container class="pt-0 pl-0 pr-0">
                <b-row class="mb-3">
                    <b-col cols="12 py-1">
                        <a @click="navigatetoDatasetdefinition">
                            <img
                                src="../../../assets/arrow_left.svg"
                                class=""
                            />
                            <span class="floar-left ml-1">
                                Back to dataset definition</span
                            >
                        </a>
                    </b-col>
                </b-row>
                <b-row class="vh-10 text-left" align-v="center">
                    <b-col cols="12" lg="6" md="6" class="pt-2">
                        <h1 class="">{{ pageHeader }}</h1>
                    </b-col>
                    <b-col cols="6" class="text-right m-none">
                        <span
                            rounded
                            variant="outline-primary"
                            class="buttonNew pointer"
                            @click="navigatetoDatasetdefinition"
                        >
                            Cancel
                        </span>

                        <b-button
                            rounded
                            class="buttonSelect ml-2"
                            @click="updateDataBase"
                            v-if="this.$route.params.type === 'GeoDB'"
                        >
                            Sync database
                        </b-button>
                        <b-button
                            rounded
                            class="buttonSelect ml-2"
                            @click="saveDataSet"
                        >
                            {{ saveBtnText }}
                        </b-button>
                    </b-col>
                </b-row>
                <div class="pt-2 pl-3">
                    <b-card class="tabs-view" no-body>
                        <b-tabs
                            class="tabs row"
                            v-on:activate-tab="tabActivated"
                            active-nav-item-class="pr-0 pl-0 borderBlue"
                            justified
                            @activate-tab="tabChanged"
                        >
                            <b-tab
                                :title="
                                    isPowerBI ? 'Power BI' : 'Dataset columns'
                                "
                                class="datasetColumn tabs-view bottom-p-75"
                                active
                            >
                                <b-container class="pl-0  setolumn mx-o">
                                    <p class="my-1 ml-1 p-1">
                                        To alias a field name, enter the new
                                        name. Leave the field blank to keep it
                                        the same.
                                    </p>
                                    <DatasetColumns
                                        ref="datasetColumnsRef"
                                        :DatasetDTO="DatasetDTO"
                                        :isPowerBI="isPowerBI"
                                        :isTable="DatasetDTO.Dataset.isTable"
                                        :isMap="DatasetDTO.Dataset.isMap"
                                        :isBoth="DatasetDTO.Dataset.isBoth"
                                        :allDataSetColoumns="allDataSetColoumns"
                                        :quickDateOptions="quickDateOptions"
                                        :isGeometryAvailable="
                                            isGeometryAvailable
                                        "
                                        :selectedColoumns="selectedColoumns"
                                        @setSelectColoumns="setSelectColoumns"
                                        @setDatasetType="setDatasetType"
                                        :isStoredProcedure="isStoredProcedure"
                                        :isEditDatasetSP="isEditDatasetSP"
                                        :procedureParams="procedureParams"
                                        @setSPallDatasetColumns="
                                            setSPallDatasetColumns
                                        "
                                        @saveParamsToDB="saveParamsToDB"
                                    ></DatasetColumns>
                                </b-container>
                            </b-tab>

                            <b-tab
                                title="Column descriptions"
                                v-if="isTableVisible && !isPowerBI"
                            >
                                <ColumnDescription
                                    :selectedColoumns="selectedColoumns"
                                    @setSelectColoumns="setSelectColoumns"
                                ></ColumnDescription>
                            </b-tab>

                            <b-tab
                                title="Tool attributes"
                                v-if="
                                    isTableVisible &&
                                        !isPowerBI &&
                                        (!isEditDatasetSP || !isStoredProcedure)
                                "
                            >
                                <p class="my-2">
                                    Select the tools to assign to the
                                    {{ this.$route.params.tableAliasName }}
                                    dataset.
                                </p>
                                <ToolAttributes
                                    :attributes="attributes"
                                    :toolAttributes="toolAttributes"
                                    :selectedColoumns="selectedColoumns"
                                    :datatypes="datatypes"
                                    :DatasetTools="TempDatasetTools"
                                    @setDatasetTools="setDatasetTools"
                                ></ToolAttributes>
                            </b-tab>

                            <b-tab
                                title="Filter options"
                                v-if="
                                    isTableVisible &&
                                        !isPowerBI &&
                                        (!isEditDatasetSP || !isStoredProcedure)
                                "
                            >
                                <FilterOptions
                                    :selectedColoumns="selectedColoumns"
                                    :showFilterOptions="showFilterOptions"
                                    :hiddenFilterOptions="hiddenFilterOptions"
                                    :filterCols="filterCols"
                                    :dataSetCols="dataSetCols"
                                    @setFilterOptionArrays="
                                        setFilterOptionArrays
                                    "
                                ></FilterOptions>
                            </b-tab>

                            <b-tab
                                title="Map configuration"
                                class="mapPosting"
                                v-if="isMapVisible"
                            >
                                <MapConfiguration
                                    :selectedColoumns="selectedColoumns"
                                    :ProjectLayer="DatasetDTO.ProjectLayer"
                                    :DatasetId="DatasetDTO.Dataset.DatasetId"
                                    :postedDataLayers="postedDataLayers"
                                    :showPreview="showPreview"
                                    :DisplayName="
                                        DatasetDTO.Dataset.DisplayName
                                    "
                                ></MapConfiguration>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>
            </b-container>
        </b-container>
        <toast-messages
            :showSuccess="showSuccess"
            :showFailure="showFailure"
            :successToastMessage="successToastMessage"
            :failureToastMessage="failureToastMessage"
            :notificationQueue="notificationQueue"
            @hideToast="hideToast"
        />
    </div>
    <div v-else>
        <AccessPermissionModal ref="permission-modal" />
    </div>
</template>

<script>
import router from '../../../router';
import { DatasetColumnsUtils } from './Utils';
import DatasetColumns from './TabComponents/DataSetColumns';
import { required } from 'vuelidate/lib/validators';
import ToastMessages from '../../ToastMessages.vue'; // TODO: Please refactor the ToastMessages
import ColumnDescription from './TabComponents/ColumnDescription';
import FilterOptions from './TabComponents/FilterOptionsNew';
import { gdbService } from '../../../services/gdb.service';
import { datasetService } from '../../../services/dataset.service';
import ToolAttributes from './TabComponents/ToolAttributes.vue';
import MapConfiguration from './TabComponents/MapConfiguration.vue';
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'DataSetColumnsNew',
    components: {
        DatasetColumns,
        ToastMessages,
        ColumnDescription,
        FilterOptions,
        ToolAttributes,
        MapConfiguration,
    },
    provide() {
        return {
            validation: this.$v,
        };
    },
    validations() {
        if (localStorage.getItem('sourceName') === 'ATLAS') {
            return {
                DatasetDTO: {
                    Dataset: {
                        DatasetAlias: {
                            required,
                        },
                        DisplayName: {
                            required,
                        },
                        DatasetDesc: {
                            required,
                        },
                        WildCard: {
                            required,
                        },
                    },
                },
            };
        } else {
            return {
                DatasetDTO: {
                    Dataset: {
                        DatasetAlias: {
                            required,
                        },
                        DisplayName: {
                            required,
                        },
                        DatasetDesc: {
                            required,
                        },
                    },
                },
            };
        }
    },
    data() {
        return {
            showPage: true,
            showPreview: false,
            renderMap: false,
            isPowerBI: false,
            // showSuccess: false,
            // showFailure: false,
            // showError: false,
            // errorToastMessage: null,
            // failureToastMessage: null,
            // successToastMessage: null,
            connectingToast: false,
            DatasetDTO: {
                Dataset: {
                    DatasetId: null,
                    DataSourceId: null,
                    DatasetName: null,
                    DatasetDesc: null,
                    DatasetAlias: null,
                    DatasetType: null,
                    DisplayName: null,
                    WildCard: null,
                    isTable: true,
                    isMap: false,
                    isBoth: false,
                    ReportId: null,
                    SectionId: null,
                    Roles: null,
                    DatasetColumns: [],
                    GDBLayerID: null,
                    CreatedBy: null,
                    ModifiedBy: null,
                    CreationDate: null,
                    DateFilterColumn: null,
                },
                ProjectLayer: {
                    ProjectLayerID: 0,
                    DisplayField: null,
                    DisplayName: null,
                    DisplayOption: true,
                    Position: null,
                    FontStyle: 'Normal',
                    FontSize: 10,
                    FontColour: '#000000',
                    FontType: 'Arial',
                    FontRotation: '0°',
                    FontTransparency: 0,
                    Symbology: null,
                    SymbologyColour: '#000000',
                    UsePointOutline: true,
                    PointOutlineColour: '#ffffffff',
                    LineColour: '#000000',
                    LineStyle: 'Solid',
                    LineWidth: 1,
                    PolygonColour: '#000000',
                    PolygonBorderColour: '#000000',
                    PolygonStyle: 'Solid',
                    PolygonWidth: 1,
                    PolygonTransparency: 0,
                },
                datasetTool: [],
                datasetParameter: [],
            },
            isGeometryAvailable: false,
            allDataSetColoumns: [], //colms withoute geo
            geometryColumns: [], //all geo cols
            selectedColoumns: [],
            showFilterOptions: [],
            hiddenFilterOptions: [],
            filterCols: [],
            dataSetCols: [],
            attributes: [],
            datatypes: [],
            toolAttributes: [],
            postedDataLayers: {},
            TempDatasetTools: [],
            quickDateOptions: [],
            tableType: '',
            tableName: '',
            dataSourceId: '',
            isStoredProcedure: false,
            procedureParams: [],
            SPallDataSetColoumns: [],
            procedureParamsData: {},
            isEditDatasetSP: false,
            queueService: 'Geoserver Database update',
        };
    },
    methods: {
        ...mapMutations('common', ['setToastMessage']),
        tabActivated() {},
        tabChanged(newTabIndx, prevTabIndx) {
            if (this.DatasetDTO.Dataset.isTable) {
                this.showPreview = false;
            } else if (this.DatasetDTO.Dataset.isMap && newTabIndx === 1) {
                this.showPreview = true;
            } else if (this.DatasetDTO.Dataset.isBoth && newTabIndx === 4) {
                this.showPreview = true;
            } else {
                this.showPreview = false;
            }
        },
        navigatetoDatasetdefinition() {
            router.push(
                `/datasetdefinition/datasetlist/${
                    this.DatasetDTO.Dataset.DataSourceId
                }/${localStorage.getItem('sourceName')}`
            );
            this.isEditDatasetSP = false;
        },
        cancelDataSet() {},
        async saveDataSet() {
            this.$v.DatasetDTO.Dataset.$touch();
            if (this.$v.DatasetDTO.Dataset.$anyError) {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage:
                        'Not all required fields are complete. Please check all sections, then retry.',
                });

                return;
            }
            this.DatasetDTO.Dataset.DatasetId = this.DatasetDTO.Dataset
                .DatasetId
                ? this.DatasetDTO.Dataset.DatasetId
                : 0;
            //TODO: Dataset name is the table name in the databaae, display name is what the user sees in table select tool, datasetalias is the name the admin assigns to the dataset in adapt
            this.DatasetDTO.Dataset.DatasetName = this.$route.params.tablename;
            this.DatasetDTO.Dataset.CreatedBy = this.DatasetDTO.Dataset
                .CreatedBy
                ? this.DatasetDTO.Dataset.CreatedBy
                : JSON.parse(localStorage.getItem('userDetails')).name;

            this.DatasetDTO.Dataset.DatasetColumns = DatasetColumnsUtils.getDatasetColumnsForRequest(
                this.selectedColoumns,
                this.showFilterOptions,
                this.hiddenFilterOptions,
                this.DatasetDTO.Dataset.DatasetId,
                this.attributes,
                this.TempDatasetTools,
                this.geometryColumns,
                this.allDataSetColoumns,
                this.isStoredProcedure
            );
            this.DatasetDTO.datasetTool = this.TempDatasetTools.map((tool) => {
                return { ToolID: tool };
            });
            this.DatasetDTO.Dataset.CreationDate = this.DatasetDTO.Dataset
                .CreationDate
                ? this.DatasetDTO.Dataset.CreationDate
                : DatasetColumnsUtils.getTodayDate();

            if (
                this.DatasetDTO.Dataset.isTable ||
                this.DatasetDTO.Dataset.isBoth
            ) {
                let GDBLayerIDrRspond = await gdbService.convertGDBtoSQL(
                    this.DatasetDTO.Dataset.DataSourceId,
                    this.$route.params.tablename
                );
                //TODO: handle any errors form the response else set the value
                this.DatasetDTO.Dataset.GDBLayerID = GDBLayerIDrRspond.data;
            } else {
                this.DatasetDTO.Dataset.GDBLayerID = 0;
            }
            if (this.isPowerBI) {
                delete this.DatasetDTO.Dataset['GDBLayerID'];
                this.DatasetDTO.Dataset.ReportId = this.$route.params.reportId;
            }
            if (
                (this.DatasetDTO.ProjectLayer &&
                    this.DatasetDTO.ProjectLayer.DisplayName === null) ||
                this.DatasetDTO.Dataset.isTable
            ) {
                delete this.DatasetDTO['ProjectLayer'];
            }

            if (!this.DatasetDTO.Dataset.DatasetId) {
                await this.createDataSet(this.DatasetDTO);
            } else {
                await this.saveChanges(this.DatasetDTO);
            }
            if (this.isStoredProcedure || this.isEditDatasetSP) {
                this.$refs.datasetColumnsRef.$refs.procedureSelectRef.sendParamstoUpdateSP();
            }
        },
        getDatatypeID(datatypeName) {
            for (const datatypeSet of this.datatypes) {
                for (const datatype of datatypeSet.Datatypes) {
                    if (datatype.DatatypeName === datatypeName) {
                        return datatype.DatatypeID;
                    }
                }
            }
            return null; // Return null if DatatypeName is not found
        },
        async saveParamsToDB(paramsData) {
            this.procedureParamsData = paramsData.map((param) => ({
                DatasetID: this.DatasetDTO.Dataset.DatasetId,
                ParameterType:
                    param.parameterTypeID !== undefined &&
                    param.parameterTypeID !== null
                        ? param.parameterTypeID
                        : this.getDatatypeID(param.DataType),
                Ordinal: param.Position,
                StaticValue: param.StaticValue,
                Value: param.Value,
                Parameter: param.ParameterName,
                Required: param.Required,
                ...(param.DatasetParameterID && {
                    DatasetParameterID: param.DatasetParameterID,
                }),
            }));
            if (!this.isEditDatasetSP) {
                await datasetService
                    .saveStoredProcedure(this.procedureParamsData)
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((error) => {
                        console.error(
                            `Error saving stored procedure for ${this.procedureParamsData}:`,
                            error
                        );
                    });
                this.isEditDatasetSP = true;
            } else {
                await datasetService
                    .updateStoredProcedure(this.procedureParamsData)
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((error) => {
                        console.error(
                            `Error saving stored procedure for ${this.procedureParamsData}:`,
                            error
                        );
                    });
            }
        },
        async saveChanges(data) {
            if (this.isEditDatasetSP) {
                this.$refs.datasetColumnsRef.$refs.procedureSelectRef.sendParamstoUpdateSP();
            }
            let updateDataResponse = await datasetService.updateDataset(
                this.DatasetDTO.Dataset.DatasetId,
                this.DatasetDTO
            );
            if (updateDataResponse.data.StatusCode === 202) {
                await this.doCreate();

                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: 'Dataset updated successfully',
                });
            }
            if (updateDataResponse.data.StatusCode === 500) {
                this.setToastMessage({
                    showFailure: true,
                    successToastMessage: updateDataResponse.data.Message,
                });
            }
        },
        async createDataSet(data) {
            let createDataResponse = await datasetService.saveDataSet(data);
            if (createDataResponse.data.StatusCode === 201) {
                this.DatasetDTO.Dataset.DatasetId =
                    createDataResponse.data.Data;

                this.setToastMessage({
                    showSuccess: true,
                    successToastMessage: 'Dataset added successfully',
                });
            }
            if (createDataResponse.data.StatusCode === 500) {
                this.setToastMessage({
                    showFailure: true,
                    successToastMessage: createDataResponse.data.Message,
                });
            }
        },
        setSelectColoumns(selectedColoumnsArray) {
            selectedColoumnsArray.forEach((item) => {
                item.description = item.description ? item.description : '';
            });
            this.selectedColoumns = [...selectedColoumnsArray];
        },
        setFilterOptionArrays(showFilterOptions_new, hiddenFilterOptions_new) {
            this.showFilterOptions = [...showFilterOptions_new];
            this.hiddenFilterOptions = [...hiddenFilterOptions_new];
        },
        setDatasetType(isTable, isMap, isBoth) {
            this.DatasetDTO.Dataset.isTable = isTable;
            this.DatasetDTO.Dataset.isMap = isMap;
            this.DatasetDTO.Dataset.isBoth = isBoth;
        },
        createProjectLayerObj(layerData) {
            this.DatasetDTO.ProjectLayer = { ...layerData };
        },
        setDatasetTools(DatasetTools) {
            this.TempDatasetTools = [...DatasetTools];
        },
        async getPowerBI() {
            if (this.DatasetDTO.Dataset.DatasetId) {
                await datasetService
                    .getEditingDataSet(this.DatasetDTO.Dataset.DatasetId)
                    .then((res) => {
                        this.DatasetDTO.Dataset.DisplayName =
                            res.data[0].dataset.DisplayName;

                        this.DatasetDTO.Dataset.DatasetAlias =
                            res.data[0].dataset.DatasetAlias;
                        this.DatasetDTO.Dataset.DatasetDesc =
                            res.data[0].dataset.DatasetDesc;
                        this.DatasetDTO.Dataset.DateFilterColumn =
                            res.data[0].dataset.DateFilterColumn;
                        this.DatasetDTO.Dataset.ReportId =
                            res.data[0].dataset.ReportId;
                        this.DatasetDTO.Dataset.Roles =
                            res.data[0].dataset.Roles;
                        this.DatasetDTO.Dataset.SectionId =
                            res.data[0].dataset.SectionId;
                    });
            }
        },
        addAlias(datasetColumns) {
            this.allDataSetColoumns.forEach((col) => {
                let column = datasetColumns.find((colA) => {
                    return col.name === colA.ColumnName;
                });
                col['ColumnAlias'] = column ? column.ColumnAlias : '';
            });
        },
        async getStoredProcedureResults(datasourceID) {
            const procedureParamsObj = {};

            this.procedureParams.forEach((arr) => {
                if (arr.Value) {
                    procedureParamsObj[arr.ParameterName] = arr.Value;
                }
            });
            let procedureName = this.$route.params.tablename.split('.')[1];

            let payload = {
                DatasourceID: datasourceID,
                ProcedureName: procedureName,
                Parameters: procedureParamsObj,
            };
            try {
                let res = await datasetService.getStoredProcedureColumns(
                    payload
                );
                if (res.data.StatusCode == 500) {
                    console.log(res.data.Message);
                } else {
                    let storedProcedureResults = res.data;
                    if (storedProcedureResults.length > 0) {
                        await this.setSPallDatasetColumns(
                            storedProcedureResults
                        );
                    } else {
                        console.log('There is no result to show');
                    }
                }
            } catch (error) {
                console.error(
                    'Error fetching stored procedure results:',
                    error
                );
            }
        },
        async getEditDataSet() {
            //TODO: take this body to utils.js
            let editDataResponse = await datasetService.getEditingDataSet(
                this.DatasetDTO.Dataset.DatasetId
            );
            let createDate = editDataResponse.data[0];
            this.DatasetDTO.Dataset.DataSourceId =
                createDate.dataset.DataSourceId;

            if (createDate.datasetParameter.length !== 0) {
                this.isEditDatasetSP = true;
                this.DatasetDTO.datasetParameter = createDate.datasetParameter;
                this.procedureParams = createDate.datasetParameter.map(
                    ({ Parameter, ...rest }) => ({
                        ParameterName: Parameter, // Renamed Parameter to ParameterName
                        ...rest,
                    })
                );
                await this.getStoredProcedureResults(
                    this.DatasetDTO.Dataset.DataSourceId
                );
            }
            this.dataSetCols = createDate.dtSetColumn;
            this.addAlias(this.dataSetCols);
            this.createProjectLayerObj(createDate.projectLayer);
            this.DatasetDTO.Dataset.DatasetId = createDate.dataset.DatasetId;

            this.DatasetDTO.Dataset.DatasetName =
                createDate.dataset.DatasetName;
            this.DatasetDTO.Dataset.DatasetDesc =
                createDate.dataset.DatasetDesc;
            this.DatasetDTO.Dataset.DatasetType =
                createDate.dataset.DatasetType;
            this.DatasetDTO.Dataset.DateFilterColumn =
                createDate.dataset.DateFilterColumn;
            this.DatasetDTO.Dataset.DatasetAlias =
                createDate.dataset.DatasetAlias;
            this.DatasetDTO.Dataset.DisplayName =
                createDate.dataset.DisplayName;
            this.DatasetDTO.Dataset.isTable = createDate.dataset.isTable;
            this.DatasetDTO.Dataset.isMap = createDate.dataset.isMap;
            this.DatasetDTO.Dataset.isBoth = createDate.dataset.isBoth;
            this.DatasetDTO.Dataset.GDBLayerID = createDate.dataset.GDBLayerID;
            this.DatasetDTO.Dataset.CreatedBy = createDate.dataset.CreatedBy;
            this.DatasetDTO.Dataset.ModifiedBy = createDate.dataset.ModifiedBy;
            this.DatasetDTO.Dataset.CreationDate =
                createDate.dataset.CreationDate;
            this.filterCols = createDate.dFilterCol;

            this.DatasetDTO.Dataset.DatasetColumns =
                createDate.dataset.DatasetColumns;

            //TODO:Set col desc
            // get all cols, get cols from response - set values
            // create selected cols props
            let tempArray = [];
            this.allDataSetColoumns.forEach((allCols, index) => {
                createDate.dataset.DatasetColumns.forEach((dataSetCols) => {
                    if (dataSetCols.ColumnName === allCols.name) {
                        allCols.IsShown = true;
                        let tempSelectedCOl = {
                            id: index,
                            order: allCols.order,
                            description: dataSetCols.ColumnDescription,
                            name: dataSetCols.ColumnName,
                            IsShown: allCols.IsShown,
                            IsVisible: allCols.IsVisible,
                            fixed: allCols.fixed,
                            dataType: allCols.dataType,
                        };
                        tempArray.push(tempSelectedCOl);
                    }
                });
            });

            this.selectedColoumns = [...tempArray];
            let tempToolsArr = [];
            createDate.datasetTool.forEach((tool) => {
                tempToolsArr.push(tool.ToolId);
            });
            this.TempDatasetTools = [...tempToolsArr];
            this.toolAttributes = [...createDate.dToolAttr];
            createDate.dToolAttr.forEach((dTA) => {
                if (this.TempDatasetTools.includes(dTA.ToolId)) {
                    let currAttribute = this.attributes.find(
                        (att) => att.ToolId === dTA.ToolId
                    );

                    if (dTA.AttributeType === 'Default') {
                        let currentDefaultAttr = currAttribute.ToolAttributes.find(
                            (att) => att.ToolAttributeId === dTA.ToolAttributeId
                        );
                        currentDefaultAttr.AttributeNameValue =
                            dTA.DatasetColumn.ColumnName;
                    } else {
                        let currAllowAttribute = currAttribute.allowableToolAttributes.find(
                            (alAttr) =>
                                alAttr.ToolAttributeId === dTA.ToolAttributeId
                        );
                        if (
                            currAllowAttribute.AllowAttributeNameValue === null
                        ) {
                            currAllowAttribute.AllowAttributeNameValue =
                                dTA.DatasetColumn.ColumnName;
                        } else {
                            currAttribute.allowableToolAttributes.push({
                                AttributeName: currAllowAttribute.AttributeName,
                                DatatypeSetID: currAllowAttribute.DatatypeSetID,
                                ToolAttributeId:
                                    currAllowAttribute.ToolAttributeId,
                                ToolId: currAllowAttribute.ToolId,
                                AllowAttributeNameValue:
                                    dTA.DatasetColumn.ColumnName,
                                isRemoveAllow: true,
                            });
                        }

                        currAttribute.allowableToolAttributes.sort(
                            (a, b) => a.ToolAttributeId - b.ToolAttributeId
                        );
                    }
                }
            });

            if (createDate.projectLayer) {
                this.DatasetDTO.ProjectLayer.ProjectLayerID =
                    createDate.projectLayer.ProjectLayerID;
                this.DatasetDTO.ProjectLayer.DisplayField =
                    createDate.projectLayer.DisplayField;
                this.DatasetDTO.ProjectLayer.DisplayName =
                    createDate.projectLayer.DisplayName;
                this.DatasetDTO.ProjectLayer.DisplayOption =
                    createDate.projectLayer.DisplayOption;
                this.DatasetDTO.ProjectLayer.Position =
                    createDate.projectLayer.Position;
                this.DatasetDTO.ProjectLayer.FontStyle =
                    createDate.projectLayer.FontStyle;
                this.DatasetDTO.ProjectLayer.FontSize =
                    createDate.projectLayer.FontSize;
                this.DatasetDTO.ProjectLayer.FontColour =
                    createDate.projectLayer.FontColour;
                this.DatasetDTO.ProjectLayer.FontType =
                    createDate.projectLayer.FontType;
                this.DatasetDTO.ProjectLayer.FontRotation =
                    createDate.projectLayer.FontRotation;
                this.DatasetDTO.ProjectLayer.FontTransparency =
                    createDate.projectLayer.FontTransparency;
                this.DatasetDTO.ProjectLayer.Symbology =
                    createDate.projectLayer.Symbology;
                this.DatasetDTO.ProjectLayer.SymbologyColour =
                    createDate.projectLayer.SymbologyColour;
                this.DatasetDTO.ProjectLayer.LineColour =
                    createDate.projectLayer.LineColour;
                this.DatasetDTO.ProjectLayer.LineStyle =
                    createDate.projectLayer.LineStyle;
                this.DatasetDTO.ProjectLayer.LineWidth =
                    createDate.projectLayer.LineWidth;
                this.DatasetDTO.ProjectLayer.PolygonColour =
                    createDate.projectLayer.PolygonColour;
                this.DatasetDTO.ProjectLayer.PolygonBorderColour =
                    createDate.projectLayer.PolygonBorderColour;
                this.DatasetDTO.ProjectLayer.PolygonStyle =
                    createDate.projectLayer.PolygonStyle;
                this.DatasetDTO.ProjectLayer.PolygonWidth =
                    createDate.projectLayer.PolygonWidth;
                this.DatasetDTO.ProjectLayer.PolygonTransparency =
                    createDate.projectLayer.PolygonTransparency;

                if (createDate.projectLayer.PointOutlineColour == null) {
                    this.DatasetDTO.ProjectLayer.UsePointOutline = false;
                    this.DatasetDTO.ProjectLayer.PointOutlineColour = '#ffffff';
                } else {
                    this.DatasetDTO.ProjectLayer.UsePointOutline = true;
                    this.DatasetDTO.ProjectLayer.PointOutlineColour =
                        createDate.projectLayer.PointOutlineColour;
                }
            }
        },
        setQuickDateOptions() {
            this.quickDateOptions = this.allDataSetColoumns
                .filter((column) =>
                    [
                        'datetime',
                        'datetime2',
                        'smalldatetime',
                        'date',
                        'time',
                        'datetimeoffset',
                        'timestamp',
                    ].includes(column.dataType)
                )
                .map((column) => ({
                    value: column.name,
                    columnName: column.name,
                }));
            this.DatasetDTO.Dataset.DateFilterColumn =
                this.quickDateOptions.length > 0
                    ? this.quickDateOptions[0].value
                    : null;
        },
        getProcedureParameters(params) {
            return new Promise((resolve) => {
                datasetService
                    .getProcedureParameters(params)
                    .then((res) => {
                        this.procedureParams = res.data.map((param) => ({
                            ...param,
                            Required: false, // Default to 'false' for required
                            StaticValue: false, // Default to 'false' for static
                        }));
                        resolve('resolved');
                    })
                    .catch(() => {
                        resolve('rejected');
                    });
            });
        },

        async setSPallDatasetColumns(storedprocedureColumns) {
            this.SPallDataSetColoumns = storedprocedureColumns;
            this.DatasetDTO.Dataset.DataSourceId = this.$route.params.id;
            this.DatasetDTO.Dataset.DatasetId = this.DatasetDTO.Dataset
                .DatasetId
                ? this.DatasetDTO.Dataset.DatasetId
                : this.$route.params.datasetId;
            let param = DatasetColumnsUtils.getInitParams(
                this.DatasetDTO.Dataset.DatasetId,
                this.DatasetDTO.Dataset.DataSourceId,
                this.$route.params.tablename,
                this.$route.params.type
            );
            let {
                coloumns,
                isGeometryAvailable,
                geometryColumns,
                attributes,
                datatypes,
            } = await DatasetColumnsUtils.doSPInitLoad(
                this.$route.params.type,
                param,
                storedprocedureColumns
            );
            this.allDataSetColoumns = [...coloumns];
            this.isGeometryAvailable = isGeometryAvailable;
            this.geometryColumns = [...geometryColumns];

            this.attributes = [...attributes];
            this.datatypes = [...datatypes];
        },
        sortToolAttr(att) {
            let tempArray = [...att];
            let index = tempArray.findIndex(
                (item) => item.ToolName === 'Standard Crosstab'
            );
            let item = tempArray.find(
                (item) => item.ToolName === 'Standard Crosstab'
            );
            item.allowableToolAttributes.forEach((atr) => {
                let toolAtr = this.toolAttributes.find(
                    (tatr) =>
                        atr.ToolAttributeId === tatr.ToolAttributeId &&
                        atr.AllowAttributeNameValue ===
                            tatr.DatasetColumn.ColumnName
                );
                if (
                    atr.AttributeName === 'Columns' ||
                    atr.AttributeName === 'row'
                ) {
                    atr['SortOrder'] = toolAtr.SortOrder;
                } else {
                    atr['SortOrder'] = 0;
                }
            });
            let colAry = item.allowableToolAttributes
                .filter((it) => it.AttributeName === 'Columns')
                .sort((a, b) => a.SortOrder - b.SortOrder);
            let rowAry = item.allowableToolAttributes
                .filter((it) => it.AttributeName === 'Rows')
                .sort((a, b) => a.SortOrder - b.SortOrder);
            let val = item.allowableToolAttributes.filter(
                (it) => it.AttributeName === 'Values'
            );

            att[index].allowableToolAttributes = [...colAry, ...rowAry, ...val];
            return att;
        },
        async doCreate() {
            if (
                (this.$route.params.tablename &&
                    this.$route.params.tablename.includes('dbo.sp_')) ||
                this.$route.params.type == 'PROCEDURE'
            ) {
                this.isStoredProcedure = true;
                if (this.DatasetDTO.Dataset.DatasetId) {
                    this.isEditDatasetSP = true;
                }
            }
            this.DatasetDTO.Dataset.DataSourceId = this.$route.params.id;
            this.DatasetDTO.Dataset.DatasetId = this.$route.params.datasetId;

            let param = DatasetColumnsUtils.getInitParams(
                this.DatasetDTO.Dataset.DatasetId,
                this.DatasetDTO.Dataset.DataSourceId,
                this.$route.params.tablename,
                this.$route.params.type
            );
            if (this.$route.params.type !== 'PowerBI') {
                if (
                    this.isStoredProcedure &&
                    !this.DatasetDTO.Dataset.DatasetId
                ) {
                    this.DatasetDTO.Dataset.DatasetType = 'StoredProcedure';
                    await this.getProcedureParameters(param);
                } else {
                    if (!this.isStoredProcedure) {
                        let {
                            coloumns,
                            isGeometryAvailable,
                            geometryColumns,
                            attributes,
                            datatypes,
                        } = await DatasetColumnsUtils.doInitLoad(
                            this.$route.params.type,
                            param
                        );

                        this.allDataSetColoumns = [...coloumns];
                        this.setQuickDateOptions();
                        this.isGeometryAvailable = isGeometryAvailable;
                        this.geometryColumns = [...geometryColumns];

                        this.attributes = [...attributes];

                        this.datatypes = [...datatypes];

                        console.log(this.DatasetDTO.Dataset.DatasetId);
                        if (this.DatasetDTO.Dataset.DatasetId) {
                            await this.getEditDataSet();

                            this.attributes = [
                                ...this.sortToolAttr(attributes),
                            ];
                        }
                    } else {
                        if (this.DatasetDTO.Dataset.DatasetId) {
                            await this.getEditDataSet();
                        }
                    }
                }
            } else {
                this.isPowerBI = true;
                this.getPowerBI();
            }
        },
        async updateDataBase() {
            try {
                const response = await datasetService.updateGeoDataBase(
                    this.$route.params.datasetId
                );
                const jobId = response.data;
                await this.checkStatusUntilComplete(jobId);
            } catch (err) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: 'failed!',
                    visible: true,
                    downloadLink: '',
                });
            }
        },
        async checkStatusUntilComplete(jobId) {
            let continueLoop = true;

            try {
                while (continueLoop) {
                    const statusResponse = await datasetService.getStatus(
                        jobId
                    );
                    const status = statusResponse.data;
                    switch (status) {
                        case 'Completed':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'complete',
                                queueMessage: 'completed!',
                                downloadLink: '',
                                visible: true,
                            });
                            continueLoop = false;
                            break;
                        case 'Error':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                        case 'Unkown JobID':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: 'failed!',
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                        case 'Queued':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'queue',
                                queueMessage: 'in queue!',
                                visible: true,
                                downloadLink: '',
                            });
                            break;
                        case 'Processing':
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'progress',
                                queueMessage: 'in progress!',
                                visible: true,
                                downloadLink: '',
                            });
                            break;
                        default:
                            this.setToastMessage({
                                queueService: this.queueService,
                                queueState: 'error',
                                queueMessage: status,
                                visible: true,
                                downloadLink: '',
                            });
                            continueLoop = false;
                            break;
                    }

                    await new Promise((resolve) => setTimeout(resolve, 30000));
                }
            } catch (error) {
                this.setToastMessage({
                    queueService: this.queueService,
                    queueState: 'error',
                    queueMessage: error,
                    visible: true,
                    downloadLink: '',
                });
            }
        },
    },

    computed: {
        pageHeader() {
            const tempArrray = this.$route.params.tablename.split('.');
            return tempArrray.length < 2 ? tempArrray[0] : tempArrray[1];
        },
        saveBtnText() {
            return this.DatasetDTO.Dataset.DatasetId
                ? this.showPreview
                    ? 'Save and preview'
                    : 'Save changes'
                : this.showPreview
                ? 'Save and preview'
                : 'Save';
        },
        isTableVisible() {
            if (
                this.DatasetDTO.Dataset.isTable ||
                this.DatasetDTO.Dataset.isBoth
            ) {
                return true;
            } else {
                return false;
            }
        },
        isMapVisible() {
            if (this.isGeometryAvailable) {
                if (
                    this.DatasetDTO.Dataset.isMap ||
                    this.DatasetDTO.Dataset.isBoth
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        ...mapState('common', [
            'showSuccess',
            'showFailure',
            'successToastMessage',
            'failureToastMessage',
            'notificationQueue',
        ]),
    },
    async created() {
        await this.doCreate();
    },
    mounted() {},
};
</script>

<style scoped>
.buttonNew {
    border: none;
    padding: 10px 18px;
    color: #006395 !important;
}
.buttonNew:focus {
    box-shadow: 0 0 0 0.2rem transparent;
}
.buttonNew:hover {
    background-color: transparent !important;
}
</style>
