<template>
    <ul class="tocList">
        <li v-for="(item, idx) in treeItems" :key="idx">
            <div class="tocRow">
                <span
                    v-hide="item.children.length == 0"
                    class="parent expandCollapse"
                >
                    <button
                        class="expandToggleBtn"
                        @click="toggleExpanded(item.id)"
                    >
                        <span v-if="item.isExpanded"
                            ><img src="../../../assets/minus.svg"
                        /></span>
                        <span v-else
                            ><img src="../../../assets/plus_sign.svg"
                        /></span>
                    </button>
                </span>
                <img-node :id="item.id" :zoomLevel="zoomLevel" />
            </div>
            <tree-view
                v-if="item.children.length > 0"
                v-show="item.isExpanded"
                :treeParent="item.id"
                :zoomLevel="zoomLevel"
            />
        </li>
    </ul>
</template>

<script>
import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import imgNode from './imgNode.vue';

Vue.directive('hide', function(el, binding) {
    el.style.display = !binding.value ? 'display' : 'none';
});

export default {
    name: 'TreeView',
    components: {
        imgNode,
    },
    props: {
        treeParent: {
            required: false,
            type: Number,
            default: 0,
        },
        zoomLevel: {
            type: Number,
            required: true,
            default: 0,
        },
    },
    data() {
        return {};
    },
    mounted: function() {},
    methods: {
        ...mapMutations('emergencyManagement', ['toggleExpanded']),
        logMessage() {},
        checked(e) {},
    },
    computed: {
        ...mapState('emergencyManagement', {
            layersStruct: 'layersStructValues',
        }),
        // sort the items in the treeItems list, ternary operation below sorts the array
        //   by the name element of the objects contained in the array
        treeItems: function() {
            var layers = this.layersStruct.filter(
                (treeItem) => treeItem.parentID == this.treeParent
            );
            return layers.sort(
                (a, b) =>
                    a.toc_groupOrder - b.toc_groupOrder ||
                    a.toc_layerOrder - b.toc_layerOrder ||
                    a.name.localeCompare(b.name)
            );
        },
    },
};
</script>

<style>
/* Remove default bullets */
ul,
#myUL {
    list-style-type: none;
}

/* Remove margins and padding from the parent ul */
#myUL {
    margin: 0;
    padding: 0;
}

.parent {
    left: -5px;
}

.tocList {
    padding-left: 15px;
}

.tocRow {
    display: flex;
}

.box {
    cursor: pointer;
    -webkit-user-select: none; /* Safari 3.1+ */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
}

.box::before {
    content: '\2610';
    color: black;
    display: inline-block;
    margin-right: 6px;
}

.check-box::before {
    content: '\2611';
    color: dodgerblue;
}

/* Style the caret/arrow */
.caret {
    cursor: pointer;
    user-select: none; /* Prevent text selection */
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
    content: '\25B6';
    color: black;
    display: inline-block;
    margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
    transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
    display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
    display: block;
}

.expandToggleBtn {
    width: 21px;
    height: 21px;
    padding: 8px 2px 2px 2px;
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 4px;
    background-color: white;
    border: none;
    margin-right: 8px;
}
</style>
